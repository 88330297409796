import React from 'react';
import { connect } from 'react-redux';

import {
  createStudyEnrollmentActionCreator,
  updateStudyEnrollmentsActionCreator
} from '../../../../../actions/finance/forecasting/forecastingActions';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import StudyEnrollmentPredicitonReasonDialog from '../StudyEnrollmentPredicitonReasonDialog/StudyEnrollmentPredicitonReasonDialog';

import './SaveStudyEnrollment.scss';

function SaveStudyEnrollment({ updateEnrollmentState, createEnrollmentState, enrollment }) {
  const openReasonDialog = function() {
    ModalBoxes.open({
      title: `${enrollment.study.studyName} ${enrollment.site.siteName}`,
      component: <StudyEnrollmentPredicitonReasonDialog {...enrollment} />
    });
  };

  function checkIfDataIsValidToSave() {
    if (
      enrollment.enrollmentPredictionValues.reduce(
        (accumulate, epv) => accumulate + parseInt(epv.value),
        enrollment.enrolledCount
      ) !== enrollment.expectedCount
    ) {
      openReasonDialog();
    } else if (enrollment.id === '') {
      createEnrollmentState({ ...enrollment, ...{ reason: '' } });
    } else {
      updateEnrollmentState({ ...enrollment, ...{ reason: '' } });
    }
  }

  return (
    <div className="edit-btn-group  pull-right plcustom ml-auto">
      <i className="material-icons float-right" title="Save" onClick={checkIfDataIsValidToSave}>
        save
      </i>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    updateEnrollmentState: studyEnrollment => {
      dispatch(updateStudyEnrollmentsActionCreator(studyEnrollment));
    },
    createEnrollmentState: studyEnrollment => {
      dispatch(createStudyEnrollmentActionCreator(studyEnrollment));
    }
  };
};

export default connect(null, mapDispatchToProps)(SaveStudyEnrollment);
