import React, { useCallback, useEffect, useState } from 'react';
import ReactTable from 'react-table';
import cx from 'classnames';
import { groupBy } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

import { HomePageDashboardsConfigApi, TaskApi } from '../../../../../api';
import Loader from '../../../../../common/elements/Loader/Loader';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import Icon from '../../../../../common/general/Icon';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../constants/dateFormat';
import { useActiveTask } from '../../../../../store/activeTask';
import { CellFormattedDate } from '../../../../CellFormattedDate/CellFormattedDate';
import { TasksTypes } from '../../../../root/Container/Layout/Tasks/Task/taskConstants';
import TasksSupervisor from '../../../../root/Container/Layout/Tasks/TasksSupervisor/TasksSupervisor';

import TopTenTasksWidgetList from './TopTenTasksWidgetList';
import TopTenTasksWidgetTableConfigModal from './TopTenTasksWidgetTableConfigModal';

import '../Widgets.scss';

const DEFAULT_COLUMNS = ['type', 'id', 'title', 'study', 'site', 'assignee', 'dueDate'];
export default function TopTenTasksWidget({ widget, ssus }) {
  const activeTask = useActiveTask();
  const [tasks, setTasks] = useState([]);
  const [groupedTasks, setGroupedTasks] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [showColumnFlags, setShowColumnFlags] = useState(widget?.configJson?.columns || DEFAULT_COLUMNS);
  const [listViewMode, setListViewMode] = useState(widget?.configJson?.listView || false);
  const [showIconsMode, setShowIconsMode] = useState(widget?.configJson?.showIcons || false);

  const changeSettings = (newColumns, listView, showIcons) => {
    HomePageDashboardsConfigApi.updateDashboardWidgetState({
      dashboardId: widget.dashboardId,
      type: widget.type,
      active: widget.active,
      configChanges: { columns: newColumns, listView: listView, showIcons: showIcons }
    }).then(resp => {
      setShowColumnFlags(resp.data.configJson.columns);
      setListViewMode(resp.data.configJson.listView);
      setShowIconsMode(resp.data.configJson.showIcons);
    });
  };

  const fetchData = useCallback(function(ssus) {
    const filter = {
      page: 0,
      pageSize: 10,
      ssuIds: ssus.map(e => e.id),
      sortedBy: { property: 'task.sequence_number', direction: 'DESC' }
    };
    setIsFetching(true);
    TaskApi.getAllTasksByFilter(filter, true).then(({ data: tasks }) => {
      setTasks(tasks.content);
      setIsFetching(false);

      const grouped = groupBy(tasks.content, e => e.dueDate);
      setGroupedTasks(grouped);
    });
  }, []);

  const configureTable = () => {
    ModalBoxes.open({
      component: (
        <TopTenTasksWidgetTableConfigModal
          columns={resolveShowColumns()}
          columnFlags={showColumnFlags}
          changeSettings={changeSettings}
          listViewMode={listViewMode}
          showIconsMode={showIconsMode}
        />
      )
    });
  };

  useEffect(() => {
    !isEmpty(ssus) && fetchData(ssus);
  }, [ssus, fetchData]);

  useEffect(() => {
    fetchData(ssus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTask.updateTimestamp, fetchData]);

  function resolveShowColumns() {
    return columns.map(e => ({ ...e, show: showColumnFlags.includes(e.accessor) }));
  }

  return (
    <div className={cx('p-3 widget-container', 'size-m')} key={5}>
      <div className="widget">
        <div className="widget-header p-2 px-3">
          <h5 className="title">{widget.name}</h5>
          <span>
            <Button
              size="h28"
              disabled={isFetching}
              onClick={() => {
                configureTable();
              }}
              className="mx-2"
              priority="medium"
            >
              <Icon suit="material-outline">settings</Icon>
            </Button>
            <Button
              size="h28"
              disabled={isFetching}
              onClick={() => {
                fetchData(ssus);
              }}
              priority="medium"
            >
              <Icon>restart_alt</Icon>
            </Button>
          </span>
        </div>
        {isFetching && <Loader dark={true} />}
        {!isFetching && listViewMode && (
          <TopTenTasksWidgetList
            groupedTasks={groupedTasks}
            activeTaskId={activeTask.taskId}
            showIcons={showIconsMode}
            showColumnFlags={showColumnFlags}
          />
        )}
        {!isFetching && !listViewMode && (
          <ReactTable
            data={tasks}
            columns={resolveShowColumns()}
            minRows={1}
            sortable={false}
            defaultPageSize={10}
            className="px-3 pb-3"
            style={{ minHeight: 100 }}
            showPagination={false}
            getTrProps={(state, row = {}) => ({
              onClick: () => {
                TasksSupervisor.open(row.original.id, row.original.studySiteId);
              },
              className: cx({ highlight: row?.original?.id === activeTask.taskId })
            })}
          />
        )}
      </div>
    </div>
  );
}
const resolveCellValue = value => (value ? <span> {value}</span> : '--');

const columns = [
  {
    Header: 'Type',
    accessor: 'type',
    id: 'task.type',
    width: 75,
    Cell: row => {
      return resolveCellValue(TasksTypes.find(e => e.id === row.original.type)?.name);
    }
  },
  {
    Header: 'ID',
    accessor: 'id',
    id: 'task.sequence_number',
    width: 50,
    Cell: row => {
      return resolveCellValue(row.original.sequenceNumber);
    }
  },
  {
    Header: 'Title',
    accessor: 'title',
    id: 'task.title',
    minWidth: 100,
    Cell: row => {
      return resolveCellValue(row.original.title);
    }
  },
  {
    Header: 'Study',
    accessor: 'study',
    id: 'study.name',
    minWidth: 100,
    Cell: row => {
      return resolveCellValue(row.original.studyName);
    }
  },
  {
    Header: 'Site',
    accessor: 'site',
    id: 'site.name',
    minWidth: 100,
    Cell: row => {
      return resolveCellValue(row.original.siteName);
    }
  },
  {
    Header: 'Assignee',
    accessor: 'assignee',
    id: 'assignee_quantity_type',
    minWidth: 100,
    Cell: row => {
      return resolveCellValue(row.original.assignee);
    }
  },
  {
    Header: 'Due Date',
    accessor: 'dueDate',
    id: 'task.dueDate',
    className: 'summary-cell',
    minWidth: 100,
    Cell: row => (
      <CellFormattedDate
        className="rt-td"
        date={new Date(row.original.dueDate)}
        format={DD_SLASH_MMM_SLASH_YYYY}
        defaultValue={'--'}
      />
    )
  }
];
