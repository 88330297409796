import React, { useMemo } from 'react';

import { AccountCard } from '../AccountCard/AccountCard';

import './AccountsList.scss';

export const AccountsList = ({ type, selectedLetter, openAddAccountModal, accountsList, updateAccounts }) => {
  const filteredAccountList = useMemo(() => {
    const sortedAccounts = accountsList.sort((a, b) => a?.name?.localeCompare(b?.name));
    if (selectedLetter) {
      return selectedLetter === '#'
        ? sortedAccounts.filter(item => !isNaN(+Array.from(item.name)[0]))
        : sortedAccounts.filter(item => Array.from(item.name)[0].toUpperCase() === selectedLetter);
    }
    return sortedAccounts;
  }, [accountsList, selectedLetter]);

  return (
    <div className="accounts-section">
      {filteredAccountList.map(account => (
        <AccountCard
          type={type}
          account={account}
          key={account.id}
          updateAccounts={updateAccounts}
          openAddAccountModal={openAddAccountModal}
        />
      ))}
    </div>
  );
};
