import React from 'react';

import CalendarSingleSelect from './CalendarSingleSelect';

export default function SSUFilterEventDialogView({
  studies,
  sites,
  handleSiteChange,
  handleStudyChange,
  initialStudy,
  initialSite,
  studyValidationMessage,
  siteValidationMessage,
  hideStudy,
  studySiteValidation
}) {
  return (
    <React.Fragment>
      {!hideStudy && (
        <CalendarSingleSelect
          controlId="study-selector"
          label={'Study'}
          clearSearchOnSelection
          dataSource={studies}
          optionLabelKey="studyName"
          optionValueKey="uniqueIdentifier"
          onChange={handleStudyChange}
          searchable
          initialValue={initialStudy}
          validationMessage={studyValidationMessage}
          required={studySiteValidation?.isStudyRequired}
        />
      )}
      <CalendarSingleSelect
        controlId="site-selector"
        label={'Site'}
        clearSearchOnSelection
        optionLabelKey="siteName"
        optionValueKey="uniqueIdentifier"
        dataSource={sites}
        onChange={handleSiteChange}
        searchable
        initialValue={initialSite}
        validationMessage={siteValidationMessage}
        required={studySiteValidation?.isSiteRequired}
      />
    </React.Fragment>
  );
}
