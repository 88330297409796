import React, { Component } from 'react';
import cx from 'classnames';
import { isEmpty, isFunction } from 'lodash/lang';
import { get } from 'lodash/object';
import * as PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';

import Checkbox from '../../../../../../common/data-entry/InputSelectors/Checkbox';
import Icon from '../../../../../../common/general/Icon';
import { scYellow100 } from '../../../../../../constants/systemColors';
import { ROLE_PRINCIPAL_INVESTIGATOR, ROLE_SYSTEM_ADMINISTRATOR } from '../../../../../../constants/userRoles';
import { isAdverseEventLog, isInvestigatorFieldAvailable } from '../../../../../../services/itemGroupQuestionService';
import store from '../../../../../../store';
import GenerateHTML from '../../../../setup/shared/GenerateHtml/GenerateHtml';
import PatientStatusProgress from '../../../Patients/PatientStatusChange/PatientStatusProgress/PatientStatusProgress';
import { AE_NOT_REPORTED } from '../../Worklist/constants';
import { getChildParentsCountMap, isNewAlgorithmForConditionalQuestions } from '../ConditionalLogicHelperService';
import EncounterElementHeader from '../EncounterElementHeader';
import { showIcfSpecificFields } from '../InformedConcent/InformedConsentUtils';

import { getClassesForItemGroupDataFormInputsByFlag, isQuestionConditionalAndRequired } from './itemGroupService';

import './ItemGroupDataForm.scss';

export class ItemGroupDataForm extends Component {
  static propTypes = {
    isItemGroupWithTableView: PropTypes.bool,
    formGroupIdentifier: PropTypes.string,
    additionNotes: PropTypes.string,
    filledQuestionsAns: PropTypes.array,
    patientInfo: PropTypes.object,
    formData: PropTypes.object,
    formValidationRules: PropTypes.any,
    onChangeTextHandler: PropTypes.func,
    onChangeDropDownHandler: PropTypes.func,
    onDateChangeHandler: PropTypes.func,
    onChangeRadioHandler: PropTypes.func,
    onchangeTimeHandler: PropTypes.func,
    onChangeFileHandler: PropTypes.func,
    onCloseSelectedFile: PropTypes.func,
    deleteFile: PropTypes.func,
    deleteFileForSingleFileUpload: PropTypes.func,
    onchangeCheckboxHandler: PropTypes.func,
    onTblCovalChangeHandeler: PropTypes.func,
    onChangeRequireAttention: PropTypes.func,
    isAbleToEdit: PropTypes.func,
    ssuPatientId: PropTypes.string,
    adverseEventLogRowStatus: PropTypes.object
  };

  state = {
    tooltipLocation: 'top'
  };
  isRequireAttention = questionData => {
    try {
      const data = this.props.filledQuestionsAns;
      const savedDataItem = data.find(data => data.elementEncounterIdentifier === questionData.uniqueIdentifier);
      return savedDataItem.isRequireAttention;
    } catch (e) {
      return false;
    }
  };

  getPatientStipendWarningMessage(formData, patientInfo) {
    const itemGroupTypeIsStipend = formData.domainCode === 'EPSTP';
    const patientDoesNotHavePrepaidCard = !get(patientInfo, 'hasActiveCard');
    const stipendQuestion = formData.labelList && formData.labelList.find(this.questionIsPayStipendQuestion);
    let stipendAlreadyPayed = stipendQuestion && stipendQuestion.inputValue === 'true';
    if (itemGroupTypeIsStipend && stipendAlreadyPayed) {
      return (
        <span className="text-danger mb-4">
          Stipend payment issued.
          <br />
          Contact the finance department ONLY IF this payment needs to be reversed.
        </span>
      );
    }
    if (itemGroupTypeIsStipend && patientDoesNotHavePrepaidCard) {
      return (
        <span className="text-danger mb-4">
          Valid PID not found. A valid PID (Proxy ID) must exist on the profile in order to complete this form. Please
          edit the patient profile and add a valid PID before issuing a stipend.
        </span>
      );
    }
  }

  itemGroupTypeIsStipendAndPatientDoesNotHavePrepaidCard = (formData, patientInfo, question) => {
    const itemGroupTypeIsStipend = formData.domainCode === 'EPSTP';
    const patientDoesNotHavePrepaidCard = !get(patientInfo, 'hasActiveCard');
    const stipendAlreadyPayed = question.inputValue === 'true';
    return (
      itemGroupTypeIsStipend &&
      this.questionIsPayStipendQuestion(question) &&
      (patientDoesNotHavePrepaidCard || stipendAlreadyPayed)
    );
  };

  questionIsPayStipendQuestion = item => {
    return item.question === 'Stipend is to be paid';
  };

  checkForFormValidation(quesId, filledQuesAnsData) {
    const questionForm = filledQuesAnsData.find(form => form.elementEncounterIdentifier === quesId);
    return !(questionForm && !questionForm.show);
  }

  render() {
    const { props } = this;
    const { isItemGroupWithTableView, patientInfo, formData, adverseEventLogRowStatus } = props;
    const highlightForReviewCheckboxNeeded = isAdverseEventLog(formData.domainCode, formData.elementIcon)
      ? false
      : !['EDO', 'ECR'].includes(formData.domainCode);
    const disabled = isFunction(props.isAbleToEdit) && !props.isAbleToEdit();

    const allConditionalParents = Object.keys(props.formValidationRules);
    const childParentsCountMap = getChildParentsCountMap(props.formValidationRules);
    const useNewAlgorithm = isNewAlgorithmForConditionalQuestions(props.formData, props.formValidationRules);
    const userRole = get(store.getState(), 'currentUser.activeRole');
    const getStyle = item =>
      useNewAlgorithm
        ? {
            fontWeight: allConditionalParents.includes(item.uniqueIdentifier) ? 'bold' : 'normal',
            paddingLeft: childParentsCountMap[item.uniqueIdentifier] * 24 + 'px'
          }
        : {};
    const commentsDisabled =
      isAdverseEventLog(formData.domainCode, formData.elementIcon) &&
      adverseEventLogRowStatus &&
      adverseEventLogRowStatus.piStatus === AE_NOT_REPORTED &&
      adverseEventLogRowStatus.smStatus === AE_NOT_REPORTED;
    return (
      <fieldset disabled={disabled}>
        <div className={`eds-item-group-data-form px-3 pt-3 ${formData && formData.name}`}>
          <div className="item-group-field-row highlight-row">
            <div className="item-group-field-label">
              {!isItemGroupWithTableView && this.getPatientStipendWarningMessage(formData, patientInfo)}
            </div>
            {highlightForReviewCheckboxNeeded && (
              <React.Fragment>
                <div className="item-group-field-input mb-4" style={{ textAlign: 'right', fontWeight: 600 }}>
                  Highlight for Review
                </div>
                <Checkbox
                  color={scYellow100}
                  className="eds-highlight-for-review-general mb-4"
                  label=""
                  checked={true}
                />
              </React.Fragment>
            )}
          </div>
          {formData &&
            formData.labelList &&
            formData.labelList.length > 0 &&
            formData.labelList.map((item, index) => {
              const isAnyAdverseEventDisabled =
                isAdverseEventLog(formData.domainCode, formData.elementIcon) && item.cdashAliasName === 'AEYN';
              const notAbleToEdit =
                (isAdverseEventLog(formData.domainCode, formData.elementIcon) &&
                  adverseEventLogRowStatus &&
                  adverseEventLogRowStatus.piStatus === AE_NOT_REPORTED &&
                  adverseEventLogRowStatus.smStatus === AE_NOT_REPORTED) ||
                (item.investigatorField &&
                  isInvestigatorFieldAvailable(formData.domainCode) &&
                  ![ROLE_PRINCIPAL_INVESTIGATOR, ROLE_SYSTEM_ADMINISTRATOR].includes(userRole));

              const showInvestigatorFieldIcon =
                item.investigatorField && isInvestigatorFieldAvailable(formData.domainCode);
              const showInvestigatorFieldTooltip =
                item.investigatorField &&
                isInvestigatorFieldAvailable(formData.domainCode) &&
                ![ROLE_PRINCIPAL_INVESTIGATOR, ROLE_SYSTEM_ADMINISTRATOR].includes(userRole);

              let defaultProps = {
                labelText: item.label,
                data: item,
                customClassObject: getClassesForItemGroupDataFormInputsByFlag(isItemGroupWithTableView),
                itemDefIndex: index,
                onChangeTextHandler: this.props.onChangeTextHandler,
                onChangeDropDownHandler: this.props.onChangeDropDownHandler,
                onDateChangeHandler: this.props.onDateChangeHandler,
                onChangeRadioHandler: this.props.onChangeRadioHandler,
                onchangeTimeHandler: this.props.onchangeTimeHandler,
                onChangeFileHandler: this.props.onChangeFileHandler,
                onCloseSelectedFile: this.props.onCloseSelectedFile,
                isRequireAttention: this.isRequireAttention,
                onChangeRequireAttention: this.props.onChangeRequireAttention,
                deleteFile: this.props.deleteFile,
                deleteFileForSingleFileUpload: this.props.deleteFileForSingleFileUpload,
                onchangeCheckboxHandler: this.props.onchangeCheckboxHandler,
                fromWhichPage: 'encounterDetail',
                isAbleToEdit: notAbleToEdit || isAnyAdverseEventDisabled ? () => false : this.props.isAbleToEdit,
                showInvestigatorFieldIcon: showInvestigatorFieldIcon,
                formGroupIdentifier: this.props.formGroupIdentifier,
                highlightForReviewCheckboxNeeded,
                validation: this.props.validation,
                styleForItem: getStyle(item),
                groupAssignPermissionType: this.props.groupAssignPermissionType
              };

              if (isItemGroupWithTableView) {
                defaultProps = Object.assign({}, defaultProps, {
                  formId: formData.formId,
                  formLabelId: formData.formLableId
                });
              } else {
                if (
                  item.hasOwnProperty('codeDefinationList') &&
                  Object.keys(item.codeDefinationList).length > 0 &&
                  item.codeDefinationList &&
                  item.codeDefinationList[0].inputType === 'radio' &&
                  (item.inputValue === '0' || item.inputValue === '')
                ) {
                  // Try to stay purely functional & avoid modifying `item`,
                  // which will accidentally change state in getDomainDetailElements
                  defaultProps.data = { ...defaultProps.data, inputValue: 0 };
                }

                const fieldIsDisabled = this.itemGroupTypeIsStipendAndPatientDoesNotHavePrepaidCard(
                  formData,
                  patientInfo,
                  defaultProps.data
                );
                defaultProps = Object.assign({}, defaultProps, {
                  disabled: notAbleToEdit ? true : fieldIsDisabled
                });
              }

              return (
                this.checkForFormValidation(item.uniqueIdentifier, this.props.filledQuestionsAns) &&
                showIcfSpecificFields(formData, item, props.headerProps?.informedConsentForm) &&
                item.isChecked &&
                item.isChecked === true && (
                  <div key={index}>
                    <Tooltip
                      destroyTooltipOnHide={true}
                      placement={this.state.tooltipLocation ? this.state.tooltipLocation : 'top'}
                      trigger={showInvestigatorFieldTooltip ? ['hover'] : []}
                      overlayClassName="eds-rc-tooltip"
                      mouseEnterDelay={0}
                      mouseLeaveDelay={0}
                      overlay={<span>Investigator credentials are required to make a selection.</span>}
                    >
                      <div
                        className={cx(
                          `item-group-field-row`,
                          showInvestigatorFieldIcon && notAbleToEdit ? 'forbidden-field' : ''
                        )}
                      >
                        {!['reminder', 'checkbox'].includes(item.inputType) && (
                          <label
                            className={cx(
                              `${item.inputType === 'textBlockLong' ? 'width100' : 'item-group-field-label'}`,
                              {
                                form_required_class: isQuestionConditionalAndRequired(
                                  item.uniqueIdentifier,
                                  this.props.formValidationRules
                                )
                              }
                            )}
                            onMouseOver={() => {
                              this.setState({ tooltipLocation: 'topLeft' });
                            }}
                            onMouseOut={() => {
                              this.setState({ tooltipLocation: 'top' });
                            }}
                            style={getStyle(item)}
                          >
                            {showInvestigatorFieldIcon && (
                              <Icon suit="material" style={{ fontSize: '25px' }}>
                                medical_services
                              </Icon>
                            )}
                            {item.updatedQuestion ? item.updatedQuestion : item.question}
                          </label>
                        )}

                        <GenerateHTML {...defaultProps} />
                      </div>
                    </Tooltip>
                    {item.question === 'Patient Status' && (
                      <div className="status-progress-section item-groups">
                        <PatientStatusProgress ssuPatientId={this.props.ssuPatientId} />
                      </div>
                    )}
                  </div>
                )
              );
            })}
          {formData && formData.labelList && formData.labelList.length > 0 && (
            <div className={cx('form-group comment-field', { 'forbidden-field': commentsDisabled })}>
              <label>Comment</label>
              <textarea
                name="COVAL"
                maxLength="3000"
                disabled={commentsDisabled}
                className="form-control"
                value={this.props.additionNotes}
                onChange={e => {
                  this.props.onTblCovalChangeHandeler(e);
                }}
              />
            </div>
          )}
          {!isEmpty(formData) && <EncounterElementHeader {...props.headerProps} />}
        </div>
      </fieldset>
    );
  }
}
