import axios from 'axios';

const ROOT = 'api/v1/finance/vendors';

const FinVendorApi = {
  findAll() {
    return axios.get(ROOT);
  },

  findAllEnabled() {
    return axios.get(`${ROOT}/enabled`);
  },

  viewUsage() {
    return axios.get(`${ROOT}/view-usage`);
  },

  create(vendor) {
    return axios.post(ROOT, vendor);
  },

  update(vendorToUpdate) {
    return axios.put(`${ROOT}/${vendorToUpdate.id}`, vendorToUpdate);
  },

  findOne(id) {
    return axios.get(`${ROOT}/${id}`);
  },

  updateState(state, id) {
    return axios.put(`${ROOT}/state/${state}/${id}`);
  }
};

export default FinVendorApi;
