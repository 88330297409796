import React, { useCallback, useMemo, useState } from 'react';
import { every, groupBy, map, partition, reduce, size } from 'lodash/collection';
import { cloneDeep, isEmpty } from 'lodash/lang';
import { mapValues } from 'lodash/object';
import { objectOf, shape, string } from 'prop-types';

import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';
import Icon from '../../../../../../../common/general/Icon';
import { ToggleSelect } from '../../../../../../../common/inputs/ToggleSelect/ToggleSelect';
import { PS_NOT_ASKED, PS_UNAVAILABLE } from '../constants';

import ChangeReason from './ChangeReason';

import './MissingItemsModal.scss';

export default function MissingItemsModal({ modalBox, onSave, data }) {
  const [missingItems, setMissingItems] = useState(data);

  const [generalFields, tableSettingsFields] = useMemo(
    function() {
      return partition(missingItems, ({ label }) => !label);
    },
    [missingItems]
  );

  const tableSettingsGroupedFields = useMemo(
    function() {
      return groupBy(tableSettingsFields, 'name');
    },
    [tableSettingsFields]
  );

  const [withReasonCount, fieldsCount] = useMemo(
    function() {
      const withReasonCount = reduce(
        missingItems,
        function(sum, { reason }) {
          if (!isEmpty(reason)) {
            return sum + 1;
          }
          return sum;
        },
        0
      );

      return [withReasonCount, size(missingItems)];
    },
    [missingItems]
  );

  const formIsValid = useMemo(
    function() {
      if (isEmpty(missingItems)) {
        return false;
      }
      return every(missingItems, function({ reason, value }) {
        const rightPerformedStatusCondition = value === PS_UNAVAILABLE || value === PS_NOT_ASKED,
          reasonsAreFilled = rightPerformedStatusCondition && !isEmpty(reason?.trim()),
          reasonsAreEmpty = !rightPerformedStatusCondition && isEmpty(reason?.trim());
        return reasonsAreFilled || reasonsAreEmpty;
      });
    },
    [missingItems]
  );

  const updatePerformedStatusForAllItems = useCallback(function(type) {
    setMissingItems(function(missingItems) {
      return mapValues(missingItems, function({ value, ...rest }) {
        return {
          value: type,
          ...rest
        };
      });
    });
  }, []);

  const updatePerformedStatus = useCallback(function(type, fieldUid) {
    setMissingItems(function(missingItems) {
      const missingItemsCopy = cloneDeep(missingItems);
      missingItemsCopy[fieldUid].value = type;
      return missingItemsCopy;
    });
  }, []);

  const updateReasonForAllItems = useCallback(function(comment) {
    setMissingItems(function(missingItems) {
      return mapValues(missingItems, function({ reason, ...rest }) {
        return {
          reason: comment,
          ...rest
        };
      });
    });
  }, []);

  const updateReason = useCallback(function(reason, fieldUid) {
    setMissingItems(function(missingItems) {
      const missingItemsCopy = cloneDeep(missingItems);
      missingItemsCopy[fieldUid].reason = reason;
      return missingItemsCopy;
    });
  }, []);

  const onSaveHandler = useCallback(
    function() {
      onSave(missingItems);
    },
    [onSave, missingItems]
  );

  return (
    <>
      <ModalBoxes.Header>{size(data) > 1 ? 'Missing Items' : 'Missing Item'}</ModalBoxes.Header>
      <ModalBoxes.Body>
        <div className="pre-save-items-modal-info">
          <Icon>info</Icon>
          <span className="pre-save-items-modal-warning-message">
            If empty fields will be entered later, click Save without entering a reason.
          </span>
        </div>
        {fieldsCount > 1 && (
          <div className="pre-save-items-modal-group">
            <h5>Apply to all</h5>
            <div className="pre-save-items-modal-group-fields">
              <ChangePerformedStatus updatePerformedStatus={updatePerformedStatusForAllItems} />
              <ChangeReason updateReason={updateReasonForAllItems} />
            </div>
          </div>
        )}
        {!isEmpty(generalFields) && (
          <div className="pre-save-items-modal-section">
            <h4>OVERVIEW</h4>
            {map(generalFields, function(field) {
              const { name, uid } = field;
              return (
                <div className="pre-save-items-modal-group" key={uid}>
                  <h5>{name}</h5>
                  <div className="pre-save-items-modal-group-fields">
                    <ChangePerformedStatus field={field} updatePerformedStatus={updatePerformedStatus} />
                    <ChangeReason field={field} updateReason={updateReason} />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {!isEmpty(tableSettingsGroupedFields) && (
          <div className="pre-save-items-modal-section">
            <h4>RESULTS</h4>
            {map(tableSettingsGroupedFields, function(q, name) {
              return (
                <div className="pre-save-items-modal-group" key={name}>
                  <h5>{name}</h5>
                  {map(q, function(field) {
                    const { label, uid } = field;
                    return (
                      <div className="pre-save-items-modal-group-item" key={uid}>
                        <h6>{label}</h6>
                        <div className="pre-save-items-modal-group-fields">
                          <ChangePerformedStatus field={field} updatePerformedStatus={updatePerformedStatus} />
                          <ChangeReason field={field} updateReason={updateReason} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <div className="pre-save-items-modal-counter">
          With reason: {withReasonCount}/{fieldsCount}
        </div>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            CANCEL
          </Button>
          <Button onClick={onSaveHandler} priority="high" disabled={!formIsValid}>
            SAVE
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
}

MissingItemsModal.className = 'pre-save-items-modal';
MissingItemsModal.size = 'w950';

export function ChangePerformedStatus(props) {
  const { field, updatePerformedStatus } = props;
  const uid = field?.uid;
  const onChangeStatus = useCallback(
    value => {
      updatePerformedStatus(value, uid);
    },
    [uid, updatePerformedStatus]
  );

  return (
    <div className="pre-save-items-modal-change-performed-status">
      <ToggleSelect
        listOfItem={[
          { name: 'Not Asked', value: PS_NOT_ASKED },
          { name: 'Unavailable', value: PS_UNAVAILABLE }
        ]}
        initialValue={field?.value}
        onChange={onChangeStatus}
        reset
      />
    </div>
  );
}

MissingItemsModal.propTypes = {
  missingFieldsMap: objectOf(
    shape({
      uid: string.isRequired,
      label: string,
      name: string.isRequired,
      value: string.isRequired,
      reason: string
    })
  )
};
