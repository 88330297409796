import React, { useState } from 'react';
import cx from 'classnames';

import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Icon from '../../../../../../../../common/general/Icon';
import { useActiveTask, useActiveTaskUpdate } from '../../../../../../../../store/activeTask';

import './TaskNavigationBar.scss';

function TaskNavigationBar({ relatedTasks }) {
  const updateActiveTask = useActiveTaskUpdate();
  const activeTask = useActiveTask();
  const [currentPage, setCurrentPage] = useState(1);

  const elementsPerPage = 8;
  const totalPages = Math.ceil(relatedTasks?.length / elementsPerPage);

  const startIndex = (currentPage - 1) * elementsPerPage;
  const endIndex = startIndex + elementsPerPage;
  const displayedElements = relatedTasks?.slice(startIndex, endIndex);

  return (
    <div className="etc-body-navigation">
      {currentPage !== 1 && (
        <Icon className="etc-body-navigation-button-next" color="grey" onClick={handlePreviousPage}>
          keyboard_arrow_left
        </Icon>
      )}
      {displayedElements?.map(i => (
        <span
          key={i.taskId}
          onClick={() => confirmSwitchEditedTask(i.taskId, i.ssuId)}
          className={cx('etc-body-navigation-item', { active: i.taskId === activeTask.taskId })}
        />
      ))}
      {currentPage !== totalPages && (
        <Icon className="etc-body-navigation-button-next" color="grey" onClick={handleNextPage}>
          keyboard_arrow_right
        </Icon>
      )}
    </div>
  );

  function confirmSwitchEditedTask(taskId, ssuId) {
    if (activeTask.isChanged) {
      showConfirmDialog()
        .then(() => {
          updateActiveTask({ taskId: taskId, ssuId: ssuId });
        })
        .catch(() => {});
    } else {
      updateActiveTask({ taskId: taskId, ssuId: ssuId });
    }
  }

  function showConfirmDialog() {
    return ModalBoxes.confirm({
      content: 'Are you sure you want to discard the changes made to this task?',
      title: 'Discard Task Changes',
      confirmButton: 'Yes',
      cancelButton: 'No'
    });
  }

  function handlePreviousPage() {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  }

  function handleNextPage() {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  }
}

export default TaskNavigationBar;
