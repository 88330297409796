import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash/object';

import { getHomePageDashboardsConfig } from '../../../actions/homePageDashboardsConfigActions';
import { HomePageDashboardsConfigApi } from '../../../api';
import { HOME_PAGE_DASHBOARDS } from '../../../constants/accessToPages';
import { VIEW_AND_EDIT_HOME_PAGE_DASHBOARDS_WIDGET_CONFIG } from '../../../constants/userOperations';
import { userHasAccessTo } from '../../../services/auth';
import { getTittleByRole } from '../../../services/personnelService';

import PreferenceTemplate from './PreferenceTemplate';

export default function HomePageDashboardPreferences({ appInfo, currentUser }) {
  const homePageDashboardsConfig = useSelector(state => state.homePageDashboardsConfig);
  const dispatch = useDispatch();

  const homePageDashboardsConfigEnabled =
    get(appInfo, 'features.homePageDashboardsEnabled', false) &&
    currentUser.accessToPages.includes(HOME_PAGE_DASHBOARDS) &&
    userHasAccessTo(VIEW_AND_EDIT_HOME_PAGE_DASHBOARDS_WIDGET_CONFIG);

  const getDashboardInfo = () => {
    dispatch(getHomePageDashboardsConfig());
  };

  const onChangeLandingPagePreference = active => {
    const data = {
      dashboardId: homePageDashboardsConfig.dashboard.id,
      active
    };
    HomePageDashboardsConfigApi.updateDashboardState(data).then(getDashboardInfo);
  };
  const onChangeWidgetPreference = (item, active) => {
    const data = {
      dashboardId: homePageDashboardsConfig.dashboard.id,
      type: item.type,
      active
    };
    HomePageDashboardsConfigApi.updateDashboardWidgetState(data).then(getDashboardInfo);
  };

  return (
    <>
      {homePageDashboardsConfigEnabled && homePageDashboardsConfig && (
        <section className="px-3 pb-3 pt-2 mt-3">
          <div className="header-section">
            <h5>Home Page</h5>
          </div>
          <div className="mt-3 mb-2 pl-3 py-3 preferences-message">
            Dashboard with selected widgets will be used as a default Home Page.
            <br />
            Configuration is User Role specific and will be applied only for
            <strong className="section-label">{` ${getTittleByRole(currentUser.activeRole)}`}</strong>
          </div>
          <PreferenceTemplate
            className="ml-3"
            item={{}}
            name={'Dashboard'}
            description={'Use Dashboard with selected widgets as a default Home Page'}
            checked={homePageDashboardsConfig?.dashboard?.landingPage}
            onChange={(i, checked) => onChangeLandingPagePreference(checked)}
          />
          <div className="section-label mt-3 mb-2 pl-3">Widgets</div>
          {homePageDashboardsConfig?.widgets &&
            homePageDashboardsConfig.widgets.map(item => (
              <PreferenceTemplate
                className="ml-3"
                item={item}
                name={item.name}
                description={item.description}
                checked={item.active}
                onChange={onChangeWidgetPreference}
                key={item.name}
                disabled={!homePageDashboardsConfig?.dashboard?.landingPage}
              />
            ))}
        </section>
      )}
    </>
  );
}
