export function isSigned(logSnapshotReviewStates, item) {
  return logSnapshotReviewStates[item.itemGroupTemplateId]?.reviewState?.reviewStatus !== 'NOT_SIGNED';
}

export function isNotPerformed(logSnapshotReviewStates, item) {
  return logSnapshotReviewStates[item.itemGroupTemplateId]?.logSnapshotState?.dataStatus?.notPerformed;
}

export function isDisabled(logSnapshotReviewStates, item) {
  return (
    !isNotPerformed(logSnapshotReviewStates, item) &&
    logSnapshotReviewStates[item.itemGroupTemplateId]?.logSnapshotState?.rows?.length === 0
  );
}
