import { any, bool, func, number, oneOfType, string } from 'prop-types';

const inputPropTypes = {
  label: string,
  onChange: func,
  defaultValue: string,
  value: oneOfType([string, number]),
  required: bool,
  validationMessage: string,
  valid: bool,
  placeholder: string,
  disabled: bool,
  suffix: string,
  iconsAfter: any,
  legacyLook: bool,
  validate: bool
};

export default inputPropTypes;
