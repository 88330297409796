import React from 'react';

import { useEncounterSegment } from '../../EncounterSegment';

export default function CardHeaderLeftDefault() {
  const { encounter } = useEncounterSegment();

  const { encounterName, encounterFilterStatus } = encounter;

  return (
    <div title={encounterName} className="est-name">
      <div className="encounter-name">{encounterName}</div>
      <div className="status-chip">{encounterFilterStatus}</div>
    </div>
  );
}
