import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem } from '@szhsin/react-menu';
import { isEmpty } from 'lodash';

import Button from '../../../../../../../../../../../common/general/Button';
import Icon from '../../../../../../../../../../../common/general/Icon';
import {
  CANCELED_SPE_STATUS,
  isSituationalOrUnexpectedEncounter,
  UNEXPECTED
} from '../../../../../../../../../../../constants/encounterConstants';
import { generateUrlByKey, useCurrentRoute } from '../../../../../../../../../../root/router';
import {
  cancelPatientEncounter,
  IS_DEFAULT_ENCOUNTER,
  IS_SCHEDULED_ENCOUNTER,
  reopenSitePatientEncounter
} from '../../../../../../../../shared/services';
import { PatientInfoContext } from '../../../../../../PatientInfoContext';
import { useEncountersSection } from '../../../../../EncountersSectionContext';
import { useEncounterSegment } from '../../EncounterSegment';

export default function CardHeaderRightDefault() {
  const navigate = useNavigate();
  const currentRoute = useCurrentRoute();
  const { addAppointment } = useEncountersSection();
  const ssuPatientId = currentRoute.params?.ssuPatientId;
  const patientId = currentRoute.params?.patientId;
  const {
    encounter,
    updatePatientEncountersWithAppointments,
    isManageEncountersAllowed,
    lastNotCancelledEncounterEvent,
    lastEncounterEvent
  } = useEncounterSegment();
  const { encounterName, encounterFilterStatus, sitePatientEncounter } = encounter;
  const { currentStudy } = useContext(PatientInfoContext);

  const { encounterStatus, id } = sitePatientEncounter || {};

  const encounterEvent = isEmpty(lastNotCancelledEncounterEvent) ? lastEncounterEvent : lastNotCancelledEncounterEvent;

  const protocolEncounterId = encounterEvent.protocolEncounterId;

  const cancelOrReopen = status => {
    if (status === CANCELED_SPE_STATUS) {
      reopenSitePatientEncounter(ssuPatientId, id, updatePatientEncountersWithAppointments);
      return;
    }
    cancelPatientEncounter(
      id,
      ssuPatientId,
      encounterEvent,
      encounterName,
      updatePatientEncountersWithAppointments,
      IS_DEFAULT_ENCOUNTER,
      currentStudy
    );
  };

  return (
    <div className="est-more-options" onClick={e => e.preventDefault()}>
      {isManageEncountersAllowed &&
        (isSituationalOrUnexpectedEncounter(encounter.encounterType) ? !!encounter.sitePatientEncounter : true) && (
          <Menu
            align="end"
            menuButton={
              <Button priority="low" size="h40">
                <Icon>more_vert</Icon>
              </Button>
            }
          >
            {encounter.sitePatientEncounter && (
              <MenuItem
                onClick={function(e) {
                  e.syntheticEvent.preventDefault();
                  cancelOrReopen(encounterStatus);
                }}
              >
                {(encounterStatus === CANCELED_SPE_STATUS && 'Reopen Encounter') || 'Cancel Encounter'}
              </MenuItem>
            )}
            {!encounter.sitePatientEncounter && !isSituationalOrUnexpectedEncounter(encounter.encounterType) && (
              <MenuItem
                onClick={function(e) {
                  e.syntheticEvent.preventDefault();
                  cancelPatientEncounter(
                    id,
                    ssuPatientId,
                    encounterEvent,
                    encounterName,
                    updatePatientEncountersWithAppointments,
                    IS_SCHEDULED_ENCOUNTER
                  );
                }}
              >
                Cancel Encounter
              </MenuItem>
            )}
            {encounter.encounterType !== UNEXPECTED &&
              !encounter.noAppointments &&
              !!protocolEncounterId &&
              (encounterFilterStatus === 'Scheduled' || encounterFilterStatus === 'Open') && (
                <MenuItem onClick={() => addAppointment(protocolEncounterId)}>Add appointment</MenuItem>
              )}
            {encounter.encounterType === UNEXPECTED && encounter.sitePatientEncounter && (
              <MenuItem onClick={editUnexpectedEncounter}>Edit Unexpected Encounter</MenuItem>
            )}
          </Menu>
        )}
    </div>
  );

  function editUnexpectedEncounter() {
    navigate(
      generateUrlByKey(`${currentRoute.key}.Edit Unexpected Encounter`, {
        patientId,
        ssuPatientId,
        patientEncounterId: id
      })
    );
  }
}
