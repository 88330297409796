import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { sumBy } from 'lodash/math';
import moment, { isMoment } from 'moment';

import PatientStipendApi from '../../../../../../../../api/patient/PatientStipendApi';
import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import NotificationManager from '../../../../../../../../common/notifications/NotificationManager';
import { PATIENT_STIPEND } from '../../../../../../../../constants/notificationMessages';
import { STTS_ESOURCE_TYPES } from '../../../../../../../../constants/study/studyTypes';
import { toFormattedBill } from '../../../../../../../../services/financial';
import { DATE_FORMAT } from '../../../../../../ModeReports/modeReportsServices';
import { PatientInfoContext } from '../../../PatientInfoContext';
import PaymentsSectionHeader from '../PaymentsSectionHeader/PaymentsSectionHeader';

import { AddUnexpectedVisitButton } from './AddUnexpectedVisitButton';
import PaidStipendItemModal from './PaidStipendItemModal';
import useStipendGridData from './useStipendGridData';

import './StipendSection.scss';

const initialState = {
  columns: {
    columnVisibilityModel: {}
  }
};

export default function StipendSection() {
  const { ssuPatientId } = useParams();
  const { currentStudy, patientInfo } = useContext(PatientInfoContext);
  const studyTypes = currentStudy.types;
  const studyType = currentStudy.type;
  const [columns, data, setData] = useStipendGridData(studyTypes);
  const [loading, setLoading] = useState(true);

  const loadData = useCallback(() => {
    if (!ssuPatientId) return;
    setLoading(true);

    PatientStipendApi.getBySitePatientId(ssuPatientId)
      .then(response => {
        setData(
          response.data.map((item, index) => ({
            ...item,
            id: item.sitePatientEncounterId || item.protocolEncounterKey || index
          }))
        );
      })
      .catch(() => {
        NotificationManager.error(PATIENT_STIPEND);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [ssuPatientId, setData]);

  function onStipendPay(encounter, encounterDate, selectedItems) {
    const request = {
      handlerName: encounter.handlerName,
      encounterDate: isMoment(encounterDate) && moment(encounterDate).format(DATE_FORMAT),
      sitePatientEncounterId: encounter.sitePatientEncounterId,
      situationalEncounterId: encounter.situationalEncounterId,
      protocolEncounterId: encounter.protocolEncounterId,
      protocolEncounterKey: encounter.protocolEncounterKey,
      selectedItems
    };

    const amount = sumBy(selectedItems, 'amount');

    PatientStipendApi.triggerStipendPayment(ssuPatientId, request).then(
      function() {
        loadData();
        NotificationManager.success(
          `${toFormattedBill(amount)} amount of patient stipend was successfully queued for processing`
        );
      },
      function() {
        NotificationManager.error(
          `${toFormattedBill(
            amount
          )} amount of patient stipend failed to be queued for processing, please try again or contact support`
        );
      }
    );
  }

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div>
      <PaymentsSectionHeader title="Patient Stipends">
        <AddUnexpectedVisitButton onStipendPay={onStipendPay} />
      </PaymentsSectionHeader>
      <DataGridPremium
        rowHeight={38}
        rows={data}
        columns={columns}
        disableRowSelectionOnClick
        loading={loading}
        initialState={initialState}
        localeText={{ noRowsLabel: 'No results to display' }}
        onRowClick={function({ row }) {
          ModalBoxes.open({
            component: (
              <PaidStipendItemModal
                viewOnlyMod={isESourceType(studyTypes)}
                encounter={row}
                itemsData={row?.items}
                onStipendPay={onStipendPay}
                patientInfo={patientInfo}
                studyType={studyType}
              />
            )
          });
        }}
        disableRowGrouping
        disableAggregation
      />
    </div>
  );
}

function isESourceType(types) {
  return types.some(function(type) {
    return STTS_ESOURCE_TYPES.includes(type);
  });
}
