export const statusChangeLocations = {
  LOAD_PATIENTS: 'Load Patients',
  ADD_PATIENT: 'Add Patient',
  PATIENT_INFO: 'Patient Info',
  PRE_SCREEN_WORKLIST: 'Pre-Screen Worklist',
  ITEMGROUP: 'Item Group',
  STATUS_HISTORY: 'Status history',
  BULK_STATUS_CHANGE: 'Bulk Status Update',
  SYSTEM_PATIENT_MIGRATION: 'System - Patient Migration',
  'N/A': 'N/A'
};

export const statusChangeModes = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  VIEW: 'VIEW'
};

export const dateFormatForShow = 'DD/MMM/YYYY';

export const dateFormat = 'YYYY-MM-DD';

export const allowedStatusChangesWithoutEncounter = [
  'IDENTIFIED',
  'PRE-SCREENING',
  'PRE-SCREENED',
  'WITHDRAWN',
  'PRE-SCREEN FAILED',
  'CANCELED'
];
