import React, { useContext, useMemo } from 'react';
import { isEmpty } from 'lodash/lang';

import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';
import { toFormattedBill } from '../../../../../services/financial';
import { PageInfoHeader } from '../../../../PageInfoHeader/PageInfoHeader';
import { Amount } from '../../shared/amount-view/Amount/Amount';
import { InvoiceContext } from '../NewInvoiceContext';

import InvoiceFilter from './InvoiceFilter/InvoiceFilter';

import './InvoiceTopSection.scss';

export const InvoiceTopSection = () => {
  const { downloadCSV, downloadNSCSV, ledgerEvents, isExportNsDisabled, selectedEventIds } = useContext(InvoiceContext);

  const invoiceEligible = useMemo(
    function() {
      return ledgerEvents?.reduce(
        (accumulator, currentValue) =>
          !currentValue.invoiceNumber ? accumulator + currentValue.invoiceAmount : accumulator,
        0
      );
    },
    [ledgerEvents]
  );
  const selectedForInvoice = useMemo(() => {
    return ledgerEvents
      ?.filter(event => selectedEventIds.includes(event.tableId))
      .reduce((accumulator, currentValue) => {
        return !currentValue.invoiceNumber ? accumulator + currentValue.invoiceAmount : accumulator;
      }, 0);
  }, [ledgerEvents, selectedEventIds]);

  return (
    <PageInfoHeader
      className="invoice-page-header"
      pageInfo={
        <React.Fragment>
          <PageInfoHeader.AdditionalInfo title={'Total Amount'}>
            <Amount coinsAmount={invoiceEligible} customCoinsFormat={toFormattedBill} />
          </PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo title={'Selected Amount'}>
            <Amount coinsAmount={selectedForInvoice} customCoinsFormat={toFormattedBill} />
          </PageInfoHeader.AdditionalInfo>
        </React.Fragment>
      }
      right={
        <ButtonGroup>
          <Button
            className="its-export"
            size="h28"
            priority="medium"
            onClick={downloadCSV}
            disabled={isEmpty(ledgerEvents)}
          >
            Export
          </Button>
          <Button
            className="its-export"
            size="h28"
            priority="medium"
            onClick={downloadNSCSV}
            disabled={isExportNsDisabled}
          >
            Export NS
          </Button>
        </ButtonGroup>
      }
    >
      <InvoiceFilter />
    </PageInfoHeader>
  );
};
