import React, { useState } from 'react';

import { NotificationApi } from '../../api';

export const NotificationsContext = React.createContext(null);

export function NotificationsProvider({ children }) {
  const [notificationAmount, setNotificationAmount] = useState(-1);
  const onPreferencesChange = () => {
    NotificationApi.getNumberOfNewNotifications().then(({ data }) => {
      setNotificationAmount(data?.notificationAmount);
    });
  };

  return (
    <NotificationsContext.Provider
      value={{
        amount: notificationAmount,
        onPreferencesChange: onPreferencesChange
      }}
      children={children}
    />
  );
}

export function withNotificationsContext(Component) {
  return function WrapperComponent(props) {
    return (
      <NotificationsContext.Consumer>{state => <Component {...props} {...state} />}</NotificationsContext.Consumer>
    );
  };
}
