import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { reduce } from 'lodash/collection';
import { get } from 'lodash/object';

import Button from '../../../../../common/general/Button';
import useAppInfo from '../../../../../common/hooks/useAppInfo';
import useOnWindowResize from '../../../../../common/hooks/useOnWindowResize';
import { GLOBAL_SEARCH } from '../../../../../constants/accessToPages';
import { MANAGE_TASKS_GLOBAL, VIEW_NOTIFICATIONS } from '../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../services/auth';
import Notifications from '../../../../Notifications/Notifications';
import { useCurrentRoute } from '../../../router';
import { useCurrentUser } from '../../CurrentUserContainer';
import { TaskRoutes } from '../Tasks/Task/taskConstants';
import TasksSupervisor from '../Tasks/TasksSupervisor/TasksSupervisor';

import FindPatient from './FindPatient/FindPatient';
import User from './User/User';
import { MenuItems } from './MenuConfig';
import {
  checkRoleHasAccess,
  generateMenuItems,
  getFilteredMenuItems,
  setDefaultFilterItemsToStorage
} from './MenuOptionsService';

import './MenuOptions.scss';

function getFeatureMap(versionData) {
  const defaultFeatureMap = {
    reporting: true
  };
  if (!versionData) {
    return defaultFeatureMap;
  }
  return versionData.features || defaultFeatureMap;
}

export default function MenuOptions() {
  const appInfo = useAppInfo();
  const displayAppVersion = appInfo?.displayAppVersion;
  const userNotificationsEnabled = appInfo?.features.userNotificationsEnabled;
  const currentRoute = useCurrentRoute();
  const user = useCurrentUser();
  const [maxVisibleItemsCount, setMaxVisibleItemsCount] = useState(MenuItems.length);
  const [menuItems, setMenuItems] = useState([]);
  const [container, setContainer] = useState(React.createRef());
  const hiddenRefContainer = useRef();
  const searchRef = useRef();
  const featureMap = getFeatureMap(appInfo);
  const activeRoute = currentRoute.parent ? currentRoute.parent.path : currentRoute.path;
  useEffect(
    function() {
      if (user) {
        setMenuItems(getFilteredMenuItems(user, featureMap));
      }
    },
    [user, featureMap]
  );

  const calculateMaxVisibleItemsCount = useCallback(
    function() {
      const margin = 4;
      const threshold = 1;
      const addTaskWidth = 35;
      const containerWidth = container?.current?.offsetWidth;
      const searchWidth = userNotificationsEnabled ? addTaskWidth : searchRef?.current?.offsetWidth || 10;
      const renderedElementsWidth = hiddenRefContainer?.current?.offsetWidth;
      const firstLevelMenuItems = [...get(hiddenRefContainer, 'current.children')].filter(
        e => e.className !== 'rc-menu-container'
      );
      let lastVisibleIndex = 0;
      const availableWidth = containerWidth - threshold - searchWidth;
      if (containerWidth - searchWidth < renderedElementsWidth) {
        const listOfEachFirstLevelMenuItemWidth = firstLevelMenuItems.map(e => e.offsetWidth);
        listOfEachFirstLevelMenuItemWidth.forEach((ref, index) => {
          const size = reduce(listOfEachFirstLevelMenuItemWidth.slice(0, index + 1), (sum, n) => sum + n + margin, 0);
          if (size < availableWidth) {
            lastVisibleIndex = index;
          }
        });
      } else {
        lastVisibleIndex = firstLevelMenuItems.length;
      }
      if (maxVisibleItemsCount !== lastVisibleIndex) {
        setMaxVisibleItemsCount(lastVisibleIndex);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [container]
  );

  useEffect(
    function() {
      if (container && hiddenRefContainer) {
        calculateMaxVisibleItemsCount();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [container, hiddenRefContainer]
  );

  useEffect(
    function() {
      setContainer(React.createRef());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useOnWindowResize(() => {
    setContainer(React.createRef());
  });

  const showAddTask = userHasAccessTo(MANAGE_TASKS_GLOBAL);

  const showAddTaskOnPatientSpecificPage = () => {
    return TaskRoutes.includes(currentRoute.key);
  };

  return (
    <div className="eui-nav-bar pl-4">
      <NavLink onClick={setDefaultFilterItemsToStorage} className="mr-4 eui-link-img" to="/">
        <img alt="" className="elligo-logo-img" src={`${process.env.PUBLIC_URL}/assets/images/Elligo_logo.svg`} />
        {displayAppVersion?.includes('TRN') && <span>TRAINING</span>}
        {displayAppVersion?.includes('SND') && <span>SANDBOX</span>}
        {displayAppVersion?.includes('STAGE') && <span>STAGING</span>}
      </NavLink>
      <ul className="nav-items invisible-ref-container" ref={hiddenRefContainer}>
        {generateMenuItems(menuItems, user, activeRoute, 100, false)}
      </ul>
      <div className="nav-items-container" ref={container}>
        <div style={{ position: 'absolute', display: 'flex' }}>
          <ul className="nav-items">{generateMenuItems(menuItems, user, activeRoute, maxVisibleItemsCount, true)}</ul>
          {(!userNotificationsEnabled || !userHasAccessTo(VIEW_NOTIFICATIONS)) &&
            checkRoleHasAccess(GLOBAL_SEARCH, user) && (
              <div ref={searchRef} style={{ display: 'flex' }}>
                <FindPatient />
              </div>
            )}
          {showAddTaskOnPatientSpecificPage() && showAddTask && userNotificationsEnabled && (
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}>
              <Button priority={'medium'} size={'h28'} onClick={() => TasksSupervisor.create()}>
                + Task
              </Button>
            </div>
          )}
        </div>
      </div>
      {userNotificationsEnabled && userHasAccessTo(VIEW_NOTIFICATIONS) && (
        <>
          {checkRoleHasAccess(GLOBAL_SEARCH, user) && <FindPatient />}
          <Notifications />
        </>
      )}
      <User />
    </div>
  );
}
