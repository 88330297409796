import { findIndex } from 'lodash/array';
import { isArray, isEmpty } from 'lodash/lang';

import NotificationManager from '../common/notifications/NotificationManager';

const POSTPONED_NOTIFICATIONS = 'POSTPONED_NOTIFICATIONS';

let timeoutID = null;

export function notifyAfterTabReload(key, type, message, className) {
  const notification = { key, type, message, className };
  let postponedNotifications = JSON.parse(sessionStorage.getItem(POSTPONED_NOTIFICATIONS));
  if (!isArray(postponedNotifications)) {
    postponedNotifications = [];
  }

  const existingIndex = findIndex(postponedNotifications, n => n.key === key);

  if (existingIndex > -1) {
    postponedNotifications.splice(existingIndex, 1, notification);
  } else {
    postponedNotifications.push(notification);
  }

  sessionStorage.setItem(POSTPONED_NOTIFICATIONS, JSON.stringify(postponedNotifications));
}

export function performPostponedNotifications() {
  if (timeoutID) {
    clearTimeout(timeoutID);
  }
  timeoutID = setTimeout(function() {
    const postponedNotifications = JSON.parse(sessionStorage.getItem(POSTPONED_NOTIFICATIONS));
    if (!isArray(postponedNotifications)) {
      return;
    }
    performPostponedNotificationRecursively(postponedNotifications);
  }, 1000);
}

function performPostponedNotificationRecursively(notifications) {
  if (isEmpty(notifications)) return;

  const { type, message, className } = notifications.shift();

  NotificationManager[type](message, className);

  sessionStorage.setItem(POSTPONED_NOTIFICATIONS, JSON.stringify(notifications));

  setTimeout(function() {
    performPostponedNotifications(notifications);
  }, 300);
}
