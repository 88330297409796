import React, { useContext } from 'react';
import { isEmpty } from 'lodash/lang';

import Input from '../../../../common/data-entry/Input';
import MultiSelect from '../../../../common/data-entry/MultiSelect/MultiSelect';
import Select from '../../../../common/data-entry/Select';
import Icon from '../../../../common/general/Icon';
import ApplyAndResetButtons from '../../../ApplyAndResetButtons/ApplyAndResetButtons';

import { encounterPerPersonValues, schedulingAppointmentStatusesValues } from './constants';
import { SchedulingWorklistDatePickerFilter } from './SchedulingWorklistDatePickerFilter';
import { SchedulingWorklistContext } from './SchedulingWorklistFiltersContext';

export const SchedulingWorklistFilters = ({ isFetching }) => {
  const Context = useContext(SchedulingWorklistContext);
  const {
    selectedStudies,
    selectedSites,
    selectedEncounters,
    studies,
    sites,
    appointmentStatus,
    encountersPerPerson,
    searchString,
    encounters,
    onStudiesChange,
    onSitesChange,
    onEncountersChange,
    setEncountersPerPerson,
    setAppointmentStatus,
    setSearchString,
    applyFilters,
    validationErrors
  } = Context;

  const getCustomTemplateFunction = label => elements => {
    if (!isEmpty(elements)) {
      return <Template label={label} count={elements.length} />;
    } else {
      return label;
    }
  };
  return (
    <>
      <div className="general-header-group-container general-header-wrapper ">
        <Select
          customSelectedValueTemplateFunction={() => <Template label="Appointment Status" count={1} />}
          clearSearchOnSelection
          dataSource={schedulingAppointmentStatusesValues}
          onChange={setAppointmentStatus}
          value={appointmentStatus}
          validate={false}
          clearable={false}
        />
        <MultiSelect
          controlId={'id'}
          clearable={false}
          disabled={isFetching}
          validate={true}
          clearSearchOnSelection
          forceDefaultBrowserScrolling={true}
          optionValueKey={'name'}
          placeholder={getCustomTemplateFunction('Study')([])}
          focusToSelectedValue={false}
          onChange={onStudiesChange}
          value={selectedStudies}
          searchable
          dataSource={studies}
          customSelectedValueTemplateFunction={getCustomTemplateFunction('Study')}
          validationMessage={
            isEmpty(selectedStudies) && validationErrors.includes('selectedStudies') ? 'Select Study' : ''
          }
        />
        <MultiSelect
          controlId={'id'}
          clearable={false}
          disabled={isFetching}
          validate={true}
          clearSearchOnSelection
          forceDefaultBrowserScrolling={true}
          optionValueKey={'name'}
          focusToSelectedValue={false}
          onChange={onSitesChange}
          value={selectedSites}
          searchable
          dataSource={sites}
          placeholder={getCustomTemplateFunction('Site')([])}
          customSelectedValueTemplateFunction={getCustomTemplateFunction('Site')}
          validationMessage={isEmpty(selectedSites) && validationErrors.includes('selectedSites') ? 'Select Site' : ''}
        />
        <MultiSelect
          clearSearchOnSelection
          controlId={'id'}
          clearable={false}
          disabled={isFetching || isEmpty(selectedSites)}
          validate={true}
          optionValueKey={'name'}
          focusToSelectedValue={false}
          forceDefaultBrowserScrolling={true}
          onChange={onEncountersChange}
          value={selectedEncounters}
          searchable
          dataSource={encounters}
          placeholder={getCustomTemplateFunction('Encounter')([])}
          customSelectedValueTemplateFunction={getCustomTemplateFunction('Encounter')}
          validationMessage={
            isEmpty(selectedEncounters) && validationErrors.includes('selectedEncounters') ? 'Select Encounter' : ''
          }
        />
        <Select
          customSelectedValueTemplateFunction={() => <Template label="# Appointments Per Patient" count={1} />}
          clearSearchOnSelection
          dataSource={encounterPerPersonValues}
          onChange={setEncountersPerPerson}
          value={appointmentStatus.id !== 'SCHEDULED' ? encountersPerPerson : { id: 'ALL', name: 'All' }}
          clearable={false}
          disabled={appointmentStatus.id === 'SCHEDULED'}
        />
        <div className="eds-filters-unit">
          <Input
            name="Search patient"
            iconsAfter={<Icon>search</Icon>}
            validate={false}
            label="Search patient"
            value={searchString}
            onChange={({ target }) => {
              const { value: searchString } = target;
              setSearchString(searchString);
            }}
          />
        </div>
        <ApplyAndResetButtons onApply={() => applyFilters()} disabled={isFetching} />
      </div>
      <div className="general-header-group-container general-header-wrapper">
        <SchedulingWorklistDatePickerFilter />
      </div>
    </>
  );
};

function Template({ label, count }) {
  return (
    <React.Fragment>
      <div className={'placeholder'}>{label}</div>
      <div className="filter-count">{count}</div>
    </React.Fragment>
  );
}
