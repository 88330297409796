import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactTable from 'react-table';

import { StudySiteApi } from '../../../../../../api';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import Icon from '../../../../../../common/general/Icon';
import { MANAGE_EXPECTED_SSU_SUCCESS_METRICS } from '../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../services/auth';

import {
  COMPLETION_AT_PSV,
  COMPLETION_AT_SIV,
  CONSENT_AT_PSV,
  CONSENT_AT_SIV,
  ENROLLMENT_AT_PSV,
  ENROLLMENT_AT_SIV
} from './constants';
import UpdateMetricsModal from './UpdateMetricsModal';

const columns = [
  {
    Header: 'Study',
    accessor: 'study'
  },
  {
    Header: 'Site',
    accessor: 'site'
  },
  {
    Header: 'PCN',
    accessor: 'pcn'
  },
  {
    Header: 'Consent at PSV',
    accessor: CONSENT_AT_PSV
  },
  {
    Header: 'Enrollment at PSV',
    accessor: ENROLLMENT_AT_PSV
  },
  {
    Header: 'Completion at PSV',
    accessor: COMPLETION_AT_PSV
  },
  {
    Header: 'Consent at SIV',
    accessor: CONSENT_AT_SIV
  },
  {
    Header: 'Enrollment at SIV',
    accessor: ENROLLMENT_AT_SIV
  },
  {
    Header: 'Completion at SIV',
    accessor: COMPLETION_AT_SIV
  }
];

const ExpectedSuccessMetricsTable = ({ siteDetails }) => {
  const [metrics, setMetrics] = useState({});

  const data = useMemo(
    function() {
      return [{ study: siteDetails.study_name, site: siteDetails.name, pcn: siteDetails.pcn, ...metrics }];
    },
    [metrics, siteDetails.name, siteDetails.pcn, siteDetails.study_name]
  );

  const updateValues = useCallback(() => {
    if (siteDetails.unique_identifier) {
      StudySiteApi.getMetricsById(siteDetails.unique_identifier).then(({ data }) => {
        setMetrics(data);
      });
    }
  }, [siteDetails.unique_identifier]);

  useEffect(() => {
    updateValues();
  }, [updateValues]);

  return (
    <div className="row col-12 px-0 pt-1 pb-3 m-0">
      <div className="col-md-12 row m-0 p-0 mb-2 justify-content-between border-bottom-dotted pb-0">
        <h5 className="mt-2 col-md-8 col-sm-auto p-0 c-p ">Expected SSU Success Metrics</h5>
        {userHasAccessTo(MANAGE_EXPECTED_SSU_SUCCESS_METRICS) && (
          <span className="update-metrics-button">
            <Button
              size="h28"
              priority="low"
              onClick={() => {
                openUpdateModal(metrics, siteDetails.unique_identifier, updateValues);
              }}
            >
              <Icon suit="glyphicon">edit</Icon>
              Update
            </Button>
          </span>
        )}
      </div>
      <div className="col-12 border px-0 site-info-table">
        <ReactTable
          data={data}
          columns={columns}
          minRows={1}
          multiSort
          className="table activity-table table-responsive-sm m-0"
          showPagination={false}
          noDataText="No Record Found"
          sortable={false}
        />
      </div>
    </div>
  );
};

function openUpdateModal(metrics, studySiteId, onClose) {
  ModalBoxes.open({
    component: <UpdateMetricsModal initialMetrics={metrics} studySiteId={studySiteId} />,
    onClose
  });
}

export default ExpectedSuccessMetricsTable;
