import { useEffect } from 'react';
import { Formik, useFormikContext } from 'formik';

import PatientReimbursementApi from '../../../../../../../api/patient/PatientReimbursementApi';
import { onRequestError } from '../../../../../../../services/handlers';
import EuiFileUploadProvider, {
  useEuiFileUpload
} from '../../../../../../eui/EuiFileUploadProvider/EuiFileUploadProvider';

import { generateInitialValues, prepareFiles, prepareRequest } from './services';
import { validationSchema } from './validationSchema';

export default function withPaymentsItemsForm(Component) {
  return function WithPaymentsItemsForm(props) {
    const {
      editMode,
      ssuPatientId,
      reimbursementId,
      initialValues,
      reimbursement,
      fileUrlGetter,
      initialFiles,
      modalBox
    } = props;
    return (
      <EuiFileUploadProvider fileUrlGetter={fileUrlGetter} initialFiles={initialFiles}>
        <Formik
          initialValues={generateInitialValues(initialValues, reimbursement)}
          validationSchema={validationSchema}
          onSubmit={function(values) {
            return new Promise(function(resolve) {
              prepareFiles(values.files)
                .then(function(files) {
                  const request = prepareRequest(values);
                  let promise = null;
                  if (editMode) {
                    promise = PatientReimbursementApi.updateReimbursementItem(
                      ssuPatientId,
                      reimbursementId,
                      request.id,
                      {
                        ...request,
                        files
                      }
                    );
                  } else {
                    promise = PatientReimbursementApi.addReimbursementItem(ssuPatientId, reimbursementId, {
                      ...request,
                      files
                    });
                  }
                  promise
                    .then(function({ data }) {
                      modalBox.close(data);
                    })
                    .catch(onRequestError)
                    .finally(function() {
                      resolve();
                    });
                })
                .catch(() => {
                  resolve();
                });
            });
          }}
        >
          {function() {
            return (
              <FilesSync>
                <Component {...props} />
              </FilesSync>
            );
          }}
        </Formik>
      </EuiFileUploadProvider>
    );
  };
}

function FilesSync({ children }) {
  const { setFieldValue } = useFormikContext();
  const { files } = useEuiFileUpload();
  useEffect(
    function() {
      setFieldValue('files', files);
    },
    [files, setFieldValue]
  );
  return children;
}
