import React from 'react';
import moment from 'moment';

import DatePicker from '../../../../../common/data-entry/DatePicker';
import Checkbox from '../../../../../common/data-entry/InputSelectors/Checkbox';

import 'react-datetime/css/react-datetime.css';
import './DateInput.scss';

export default function DateInput(props) {
  return (
    <div className="form-group milestone-date-input">
      <label>{props.fullName}</label>
      <DatePicker
        id={props.name}
        name={props.name}
        legacyLook
        value={moment(props.value, 'YYYY-MM-DD')}
        onChange={e => props.onDateChange(e, props.name)}
      />
      <div className="mr-sm-2 my-2 d-flex flex-nowrap actual-checkbox">
        <Checkbox
          id={`${props.name}_actual`}
          value={props.actual}
          checked={props.actual}
          disabled={!props.value}
          onChange={() => props.onActualChange(props.name)}
          label="Actual"
        />
      </div>
      <button
        className="clear-date-button"
        type="button"
        onClick={() => {
          props.onDelete(props.name);
        }}
      >
        <span className="glyphicon glyphicon-trash" />
      </button>
    </div>
  );
}
