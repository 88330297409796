import React from 'react';
import PropTypes from 'prop-types';

import GroupsSetupEncounterHeaderCell from '../../pages/setup/Protocol/ProtocolSetup/ProtocolGroupsSetup/GroupsSetupTableComponents/GroupsSetupEncounterHeaderCell/GroupsSetupEncounterHeaderCell';
import { IEpochs } from '../types';

import './ItemGroupsEncountersTableHeader.scss';
export default function ItemGroupsEncountersTableHeader({ epochs, HeaderCell, EncounterCell }) {
  if (!epochs) return null;
  return (
    <div className="item-groups-encounters-table-header">
      <div className="item-groups-encounters-table-header-main">
        <HeaderCell />
      </div>
      {epochs.map(function({ protocolEpochKey, epochName, encounters }) {
        return (
          <div
            key={protocolEpochKey}
            className="item-groups-encounters-table-header-epoch"
            style={{ flex: `0 0 ${encounters.length * 90}px` }}
          >
            <div className="item-groups-encounters-table-header-epoch-title">
              <div>{epochName}</div>
            </div>
            <div className="item-groups-encounters-table-header-epoch-encounters">
              {encounters.map(function(encounter) {
                const { encounterKey } = encounter;
                return (
                  <div className="item-groups-encounters-table-header-epoch-encounter" key={encounterKey}>
                    <EncounterCell encounter={encounter} />
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

ItemGroupsEncountersTableHeader.defaultProps = {
  HeaderCell() {
    return (
      <>
        <div />
        <div>Item Groups</div>
      </>
    );
  },

  EncounterCell: GroupsSetupEncounterHeaderCell
};

ItemGroupsEncountersTableHeader.propTypes = {
  epochs: IEpochs,
  HeaderCell: PropTypes.func,
  EncounterCell: PropTypes.func
};
