import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { isEmpty, isEqual, isNull, isUndefined } from 'lodash/lang';
import moment from 'moment';

import { FinVendorApi } from '../../../../../../../api';
import DatePicker from '../../../../../../../common/data-entry/DatePicker';
import Input from '../../../../../../../common/data-entry/Input';
import NumericFormatAmountInput from '../../../../../../../common/data-entry/Input/NumericFormatAmountInput';
import Select from '../../../../../../../common/data-entry/Select';
import { toCoins } from '../../../../../../../services/financial';
import { onRequestError } from '../../../../../../../services/handlers';
import ApplyAndResetButtons from '../../../../../../ApplyAndResetButtons/ApplyAndResetButtons';
import { DIRECT_COST, PASS_THRU } from '../../../../../setup/Budget/BudgetDetails/modal/ExpenseType';
import ExpenseRadioInputs from '../../../../../setup/Budget/BudgetDetails/modal/help-component/ExpenseRadioInputs';
import { AdjustmentContext } from '../../AdjustmentContext';

import './AdjustmentResult.scss';

const numberInputCommonProps = {
  step: 0.01,
  type: 'text',
  prefix: '$',
  min: 0
};

const VENDOR_AMOUNT_TYPE = 'vendorAmountType';
const SITE_AMOUNT_TYPE = 'siteAmountType';
const VENDOR_AMOUNT = 'vendorAmount';
const PATIENT_AMOUNT_TYPE = 'patientAmountType';
const SITE_AMOUNT = 'siteAmount';
const PATIENT_AMOUNT = 'patientAmount';

export function AdjustmentResult() {
  const {
    metadata,
    isExpenseVariable,
    previousAdjustmentResults,
    isPatientStipend,
    setRequestData,
    setDisableSaveButton,
    disableSaveButton,
    newTableEvents,
    setNewTableEvents
  } = useContext(AdjustmentContext);
  const [adjustmentResults, setAdjustmentResults] = useState({});
  const [reason, setReason] = useState(null);
  const [revenueDate, setRevenueDate] = useState(moment());
  const [net, setNet] = useState(null);
  const [gross, setGross] = useState(null);
  const [previousReason, setPreviousReason] = useState(null);
  const [previousRevenueDate, setPreviousRevenueDate] = useState(null);
  const [isReasonOrRevenueChanged, setIsReasonOrRevenueChanged] = useState(false);

  useEffect(
    function() {
      setAdjustmentResults(previousAdjustmentResults);
    },
    [previousAdjustmentResults]
  );

  useEffect(
    function() {
      if (previousReason) {
        return setIsReasonOrRevenueChanged(isEqual(previousReason, reason));
      }
    },
    [previousReason, reason]
  );

  useEffect(
    function() {
      if (previousRevenueDate) {
        return setIsReasonOrRevenueChanged(isEqual(previousRevenueDate, revenueDate));
      }
    },
    [previousRevenueDate, revenueDate]
  );

  function parseAmount(value) {
    return parseFloat(value?.toString().replace(/,/g, ''));
  }

  useEffect(
    function() {
      if (metadata && adjustmentResults) {
        const clientAmount = parseAmount(adjustmentResults.clientAmount) || 0;
        const vendorAmount =
          adjustmentResults.vendorAmountType === PASS_THRU ? parseAmount(adjustmentResults.vendorAmount) : 0;
        const siteAmount =
          adjustmentResults.siteAmountType === PASS_THRU ? parseAmount(adjustmentResults.siteAmount) : 0;
        const patientAmount =
          adjustmentResults.patientAmountType === PASS_THRU ? parseAmount(adjustmentResults.patientAmount) : 0;

        const gross = clientAmount + clientAmount * (metadata.overhead / 100);
        const net = gross - siteAmount - vendorAmount - patientAmount;
        setGross(+gross.toFixed(2));
        setNet(+net.toFixed(2));
      }
    },
    [adjustmentResults, metadata, setNet, setGross]
  );

  const [vendors, setVendors] = useState([]);
  useEffect(
    function() {
      FinVendorApi.findAll().then(
        ({ data: vendors }) => {
          setVendors(vendors);
        },
        err => onRequestError(err, { customMessage: "Can't load vendors list" })
      );
    },
    [setVendors]
  );

  const getCoinsAmount = useCallback(function(vendorAmount) {
    if (vendorAmount || vendorAmount === 0) {
      return toCoins(vendorAmount);
    } else {
      return undefined;
    }
  }, []);

  const vendorData = useCallback(
    function(vendorId, vendorAmount, vendorAmountType) {
      if (vendorId && vendorAmountType && (vendorAmount || vendorAmount === 0)) {
        return {
          vendorData: {
            vendorId: vendorId,
            amount: getCoinsAmount(vendorAmount),
            type: vendorAmountType
          }
        };
      }
    },
    [getCoinsAmount]
  );

  const patientData = useCallback(
    (patientAmount, patientAmountType) => {
      const parsedAmount = parseAmount(patientAmount);

      if (patientAmountType && parsedAmount !== null) {
        return {
          patientData: {
            amount: getCoinsAmount(parsedAmount),
            type: patientAmountType
          }
        };
      }
    },
    [getCoinsAmount]
  );

  const siteData = useCallback(
    (siteAmount, siteAmountType) => {
      const parsedAmount = parseAmount(siteAmount);

      if (siteAmountType && parsedAmount !== 0) {
        return {
          siteData: {
            amount: getCoinsAmount(parsedAmount),
            type: siteAmountType
          }
        };
      }
    },
    [getCoinsAmount]
  );

  const isChangeable = useMemo(fieldWasChanged, [previousAdjustmentResults, adjustmentResults]);
  const isApplicable = useMemo(couldApply, [reason, isChangeable, isReasonOrRevenueChanged]);

  useEffect(
    function() {
      setDisableSaveButton(isChangeable);
    },
    [isChangeable, setDisableSaveButton]
  );

  const vendorAmountValue = adjustmentResults?.vendorAmount;
  const vendorValue = vendorAmountValue || vendorAmountValue === '' ? vendorAmountValue?.toString() : '0';
  const siteValue = parseAmount(adjustmentResults?.siteAmount);
  const patientAmount = parseAmount(adjustmentResults?.patientAmount);

  const isGreaterThenZero = useCallback(value => {
    return parseAmount(value) > 0;
  }, []);

  const isGreaterOrEqualsZero = useCallback(value => {
    const numericValue = parseAmount(value) || '';
    return numericValue >= 0;
  }, []);

  const isClientValid = isGreaterOrEqualsZero(adjustmentResults?.clientAmount);
  const isSiteValid = isGreaterOrEqualsZero(siteValue);
  const isPatientValid = isGreaterOrEqualsZero(patientAmount);

  const isVendorEmpty = useMemo(
    function() {
      return isEmpty(adjustmentResults?.vendorId);
    },
    [adjustmentResults]
  );
  const isVendorAmountInvalid = !isVendorEmpty && !isGreaterThenZero(vendorValue);
  const isSiteGreaterThanZero = isGreaterThenZero(siteValue);
  const isPatientGreaterThanZero = isGreaterThenZero(patientAmount);

  function getValidationMessage(condition) {
    if (condition) {
      return "Can't be negative";
    }
  }

  function getVendorValidationMessage(condition) {
    if (condition) {
      return "Can't be 0 or less";
    }
  }

  const isApplyLocked = !isApplicable || isVendorAmountInvalid || !isClientValid || !isSiteValid || !isPatientValid;

  const resetAdjustments = () => {
    setNewTableEvents([]);
    setAdjustmentResults(previousAdjustmentResults);
    setRevenueDate(moment());
    setReason(null);
  };

  return (
    <div className="adjustment-result">
      <div className="ar-top">
        <span className="ar-header">Adjustment result</span>
        <span className="ar-overhead-withholding">{`Overhead: ${metadata?.overhead}%`}</span>
        <span className="ar-overhead-withholding">{`Withholding: ${metadata?.withholding}%`}</span>
      </div>
      <div className="ar-inputs">
        <NumericFormatAmountInput
          id="clientAmount"
          value={adjustmentResults?.clientAmount?.toString()}
          onChange={onChangeNumberInputs}
          valid={isClientValid}
          onBlur={onBlurIfEmptyField}
          validationMessage={getValidationMessage(!isClientValid)}
          {...numberInputCommonProps}
          className="ar-amount-input"
          label="Client"
        />
        {isPatientStipend && (
          <div className="ar-amount-input">
            <NumericFormatAmountInput
              id="patientAmount"
              value={patientAmount?.toString()}
              onChange={onChangeWithTriggerAmountType}
              valid={isPatientValid}
              validationMessage={getValidationMessage(!isPatientValid)}
              onBlur={onBlurIfEmptyField}
              {...numberInputCommonProps}
              label="Patient"
            />
            <ExpenseRadioInputs
              className="ar-type-amount"
              disabled={!isPatientGreaterThanZero}
              onChangeFormControls={e => onChangeAmountType(e, PATIENT_AMOUNT_TYPE)}
              value={adjustmentResults?.patientAmountType}
              shortForm
              name={PATIENT_AMOUNT_TYPE}
            />
          </div>
        )}
        {!isPatientStipend && (
          <>
            <div className="ar-amount-input">
              <NumericFormatAmountInput
                id="siteAmount"
                value={siteValue?.toString()}
                onChange={onChangeWithTriggerAmountType}
                valid={isSiteValid}
                validationMessage={getValidationMessage(!isSiteValid)}
                onBlur={onBlurIfEmptyField}
                {...numberInputCommonProps}
                label="Site"
              />
              <ExpenseRadioInputs
                className="ar-type-amount"
                disabled={!isSiteGreaterThanZero}
                onChangeFormControls={e => onChangeAmountType(e, SITE_AMOUNT_TYPE)}
                value={adjustmentResults?.siteAmountType}
                shortForm
                name={SITE_AMOUNT_TYPE}
              />
            </div>

            {!isExpenseVariable && (
              <Select
                value={vendors.find(v => v.id === adjustmentResults?.vendorId) || null}
                onChange={onChangeVendor}
                onClear={() => onChangeVendor(null)}
                dataSource={vendors}
                className="ar-vendor-name"
                label="Vendor name"
                searchable
              />
            )}

            {!isExpenseVariable && (
              <div className="ar-amount-input">
                <NumericFormatAmountInput
                  id={VENDOR_AMOUNT}
                  value={vendorValue}
                  onChange={onChangeNumberInputs}
                  onBlur={onBlurIfEmptyField}
                  valid={isGreaterThenZero(vendorValue) || isVendorEmpty}
                  validationMessage={getVendorValidationMessage(isVendorAmountInvalid)}
                  disabled={isVendorEmpty}
                  {...numberInputCommonProps}
                  label="Vendor"
                />
                <ExpenseRadioInputs
                  className="ar-type-amount"
                  disabled={isVendorEmpty}
                  onChangeFormControls={e => onChangeAmountType(e, VENDOR_AMOUNT_TYPE)}
                  value={adjustmentResults?.vendorAmountType}
                  shortForm
                  name={VENDOR_AMOUNT_TYPE}
                />
              </div>
            )}
          </>
        )}
        <NumericFormatAmountInput
          id="grossAmount"
          value={gross?.toString()}
          disabled
          className="ar-amount-input"
          {...numberInputCommonProps}
          label="Gross"
        />
        <NumericFormatAmountInput
          id="netAmount"
          value={net?.toString()}
          disabled
          {...numberInputCommonProps}
          className="ar-amount-input"
          label="Net"
        />

        <DatePicker
          onChange={value => setRevenueDate(value)}
          value={revenueDate}
          label="Revenue date"
          timeFormat={false}
          closeOnSelect
          className="ar-calendar-input"
          dateFormat="DD/MMM/YYYY"
          disabled={!isChangeable && (isEmpty(newTableEvents) || disableSaveButton)}
          required={isChangeable}
          readOnly
          isValidDate={current => {
            const currentMoment = moment(current);
            const today = moment();
            return currentMoment.isSameOrBefore(today) && currentMoment.isAfter(today.subtract(1, 'months'));
          }}
        />
        <div
          className={cx({ 'reason-field-patient-stipend': isPatientStipend }, { 'reason-field': !isPatientStipend })}
        >
          <Input
            id={'reason'}
            value={reason}
            onChange={({ target: { value: reason } }) => setReason(reason)}
            className="ar-reason"
            label="Reason"
            disabled={!isChangeable && (isEmpty(newTableEvents) || disableSaveButton)}
            required={isChangeable}
          />
        </div>
        <ApplyAndResetButtons
          buttonGroupClassName="ar-buttons"
          applyClassName="save-button"
          applyDisabled={isApplyLocked}
          onApply={generateRequestByPreparedData}
          resetClassName="reset-button"
          onReset={resetAdjustments}
        />
      </div>
    </div>
  );

  function fieldWasChanged() {
    if (adjustmentResults) {
      const {
        clientAmount,
        patientAmount,
        siteAmount,
        vendorAmount,
        ...adjustmentResultWithoutAmounts
      } = adjustmentResults;

      const adjustingResultsWithNumberAmount = {
        clientAmount: +clientAmount,
        patientAmount: +patientAmount,
        siteAmount: +siteAmount,
        vendorAmount: vendorAmount && +vendorAmount,
        ...adjustmentResultWithoutAmounts
      };
      return !isEqual(previousAdjustmentResults, adjustingResultsWithNumberAmount);
    }
  }

  function couldApply() {
    return (isChangeable || !isReasonOrRevenueChanged) && !isEmpty(reason);
  }

  function onChangeNumberInputs({ target: { id, value } }) {
    changeAdjustmentResults({ [id]: parseAmount(value) });
  }

  function onChangeAmountType({ target: { value } }, id) {
    if (value) {
      changeAdjustmentResults({ [id]: value });
    }
  }
  function generateRequestByPreparedData() {
    const {
      clientAmount,
      patientAmount,
      patientAmountType,
      siteAmount,
      vendorId,
      vendorAmount,
      vendorAmountType,
      siteAmountType
    } = adjustmentResults;
    setPreviousRevenueDate(revenueDate);
    setPreviousReason(reason);
    setRequestData({
      finEntitiesData: {
        clientAmount: getCoinsAmount(clientAmount),
        ...vendorData(vendorId, vendorAmount, vendorAmountType),
        ...patientData(patientAmount, patientAmountType),
        ...siteData(siteAmount, siteAmountType)
      },
      gross: getCoinsAmount(gross),
      revenueDate: revenueDate,
      reason: reason,
      net: getCoinsAmount(net)
    });
  }

  function onChangeVendor(newVendorValue) {
    if (!isUndefined(newVendorValue)) {
      const id = 'vendorId';
      const oldVendorValue = adjustmentResults.vendorId;
      function triggerOtherFieldRelatedToVendor() {
        if (isNull(newVendorValue)) {
          return {
            [VENDOR_AMOUNT_TYPE]: undefined,
            [VENDOR_AMOUNT]: undefined
          };
        } else if (!oldVendorValue) {
          return {
            [VENDOR_AMOUNT_TYPE]: DIRECT_COST,
            [VENDOR_AMOUNT]: 0
          };
        }
      }
      const fieldRelatedToVendor = triggerOtherFieldRelatedToVendor();
      changeAdjustmentResults({ [id]: newVendorValue?.id, ...fieldRelatedToVendor });
    }
  }

  function onChangeWithTriggerAmountType({ target: { value: stringValue, id } }) {
    const oldValue = +adjustmentResults[id];
    function triggerAmountType() {
      let amountType;
      let typeValue;
      if (id === SITE_AMOUNT) {
        amountType = SITE_AMOUNT_TYPE;
        typeValue = PASS_THRU;
      }
      if (id === PATIENT_AMOUNT) {
        amountType = PATIENT_AMOUNT_TYPE;
        typeValue = DIRECT_COST;
      }
      const value = +stringValue;
      if (value === 0) {
        return {
          [amountType]: undefined
        };
      } else if (!oldValue) {
        return {
          [amountType]: typeValue
        };
      }
    }
    const amountType = triggerAmountType();
    changeAdjustmentResults({ [id]: stringValue, ...amountType });
  }

  function changeAdjustmentResults(changedKeyToValue) {
    setAdjustmentResults(adjustmentResults => ({ ...adjustmentResults, ...changedKeyToValue }));
  }

  function onBlurIfEmptyField({ target: { value, id } }) {
    if (isEmpty(value)) {
      changeAdjustmentResults({ [id]: 0 });
    }
  }
}
