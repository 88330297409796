import React, { useMemo } from 'react';
import cx from 'classnames';
import Tooltip from 'rc-tooltip';

import Icon from '../../../../../../common/general/Icon';
import { ROLE_SYSTEM_ADMINISTRATOR } from '../../../../../../constants/userRoles';
import { userHasRole } from '../../../../../../services/auth';
import { EMDASH } from '../../../SmWorklistNew/constants';

import './ReactTableHoc.scss';

export default function ReactTableHoc({ children }) {
  return useMemo(() => {
    if (children) {
      return React.Children.map(children, child => {
        return React.cloneElement(child, {
          ...child.props,
          className: cx('rt-hoc', child.props.className),
          nextText: <Icon>chevron_right</Icon>,
          previousText: <Icon>chevron_left</Icon>,
          resizable: false
        });
      });
    }
  }, [children]);
}

export const resolveBasicCell = value => {
  return (
    <Tooltip
      destroyTooltipOnHide={true}
      placement="top"
      overlayClassName="eds-rc-tooltip"
      mouseEnterDelay={0}
      mouseLeaveDelay={0}
      trigger={value?.length > 30 ? ['hover'] : []}
      overlay={value}
    >
      <span className="tooltip-container">
        <span className="tooltip-cell">{value}</span>
      </span>
    </Tooltip>
  );
};

export const resolveUnsignedProcedureValue = (original, groupsAndSignatures) => {
  let value = null;

  if (userHasRole(ROLE_SYSTEM_ADMINISTRATOR)) {
    return EMDASH;
  }
  const enc = groupsAndSignatures[original?.patientEncounterId];
  if (enc && enc[original?.groupAssignId]?.unsignedCount) {
    value = enc[original?.groupAssignId]?.unsignedCount;
  } else {
    value = EMDASH;
  }

  return value;
};

export const CustomSortHeader = ({ title }) => {
  return (
    <div className="rt-hoc-sort-header">
      <div className="title">{title}</div>
      <div className="icons">
        <Icon>expand_less</Icon>
        <Icon>expand_more</Icon>
      </div>
    </div>
  );
};
