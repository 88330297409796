import axios from 'axios';

const ROOT = 'api/v1/pre-screen';

const PreScreenApi = {
  filterWorkListPatients(filters) {
    return axios.post(`${ROOT}/filter`, filters);
  },
  getWorkListPatients(studyId) {
    return axios.get(`${ROOT}/patients/?studyId=${studyId}`);
  },
  getWorkListPatientsCounts(studyId, siteId) {
    return axios.get(`${ROOT}/patients/counts/?studyId=${studyId}${siteId ? '&siteId='.concat(siteId) : ''}`);
  },
  getLinkConstructor(sitePatientId, ssuPatientEncounterId, elementIdentifier) {
    return axios.get(
      `${ROOT}/patients/link/constructor/${sitePatientId}/${ssuPatientEncounterId}/${elementIdentifier}`
    );
  },
  exportPatients(studyId, siteId, status, search) {
    return axios.post(`${ROOT}/download/`, null, {
      responseType: 'arraybuffer',
      params: { studyId, siteId, status, search }
    });
  }
};

export default PreScreenApi;
