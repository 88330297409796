import { ADD_STUDY_BUDGET, COPY_STUDY_BUDGET, GET_STUDY_BUDGETS, UPDATE_STUDY_BUDGET } from '../../actions/actionTypes';

export default function studyBudgetReducer(state, action) {
  if (typeof state === 'undefined') {
    state = [];
  }
  switch (action.type) {
    case GET_STUDY_BUDGETS:
      return action.payload;
    case ADD_STUDY_BUDGET:
      return [action.payload, ...state];
    case COPY_STUDY_BUDGET:
      return [action.payload, ...state];
    case UPDATE_STUDY_BUDGET:
      return state.map(studyBudget =>
        studyBudget.id === action.payload.studyBudget.id
          ? { ...studyBudget, ...action.payload.studyBudget }
          : studyBudget
      );
    default:
      return state;
  }
}
