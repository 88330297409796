import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';

import HighlightIcon, { highlightTypes } from './HighlightIcon';

const keyIconNameLabels = Object.freeze({
  [highlightTypes.HIGHLIGHT]: 'Highlight Pending',
  [highlightTypes.CHECK]: 'Highlight Reviewed'
});

const spanIconStyle = {
  display: 'inline-block'
};

const spanLabelStyle = {
  verticalAlign: 'top'
};

const HighlightKey = () => (
  <Fragment>
    {Object.keys(keyIconNameLabels).map((name, idx) => (
      <div key={idx}>
        <span style={spanIconStyle}>
          <HighlightIcon highlightType={name} />
        </span>
        <span style={spanLabelStyle}> {keyIconNameLabels[name]}</span>
      </div>
    ))}
  </Fragment>
);

const HighlightToolTip = ({ children }) => (
  <Tooltip
    destroyTooltipOnHide={true}
    placement="top"
    overlayClassName="eds-rc-tooltip"
    mouseEnterDelay={0}
    mouseLeaveDelay={0}
    overlay={<HighlightKey />}
  >
    {children}
  </Tooltip>
);

HighlightToolTip.propTypes = {
  children: PropTypes.element.isRequired
};

export default HighlightToolTip;
