export const authenticationTypes = [
  { name: 'BASIC', value: 'BASIC' }
  // { name: 'BEARER', value: 'BEARER' }
];
export const httpTypes = [
  {
    name: 'POST',
    value: 'POST'
  },
  {
    name: 'GET',
    value: 'GET'
  },
  {
    name: 'PUT',
    value: 'PUT'
  },
  {
    name: 'DELETE',
    value: 'DELETE'
  }
];
