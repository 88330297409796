import axios from 'axios';

const ROOT = 'api/v1/finance/revenue/ledger';

const RevenueLedgerApi = {
  filter(filterProperty) {
    let { startDate, endDate } = filterProperty;
    return axios.post(ROOT, {
      ...filterProperty,
      startDate,
      endDate
    });
  },
  exportElligoRevenue(filterProperty) {
    return axios.post(`${ROOT}/download`, filterProperty, {
      responseType: 'arraybuffer'
    });
  },
  exportElligoRevenueForNetSuite(filterProperty) {
    return axios.post(`${ROOT}/export-net-suite`, filterProperty, {
      responseType: 'arraybuffer'
    });
  }
};

export default RevenueLedgerApi;
