import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactTable from 'react-table';
import cx from 'classnames';

import useSessionStorage from '../../../../../common/hooks/useSessionStorage';
import { SCHEDULING_WORK_LIST_FILTERS } from '../../../../../constants/sessionStorageConstants';
import { generateUrlByKey } from '../../../../root/router';
import { CustomHeader } from '../common/CustomHeader';

import { getDateRange, getOneMonthDateRangeForMonth } from './PendingScheduleWidgetConstants';
import { PendingScheduleWidgetContext } from './PendingScheduleWidgetFiltersContext';

export default function PendingScheduleWidgetTable() {
  // eslint-disable-next-line no-unused-vars
  const [sessionStorage, setSessionStorage] = useSessionStorage(SCHEDULING_WORK_LIST_FILTERS, {});
  // eslint-disable-next-line no-unused-vars
  const { selectedStudies, selectedSites, selectedStudySites, selectedEncounters, tableData } = useContext(
    PendingScheduleWidgetContext
  );
  const navigate = useNavigate();

  const openNumbers = (isProjected, month) => {
    let dateRangeStart, dateRangeEnd;
    if (month) {
      [dateRangeStart, dateRangeEnd] = getOneMonthDateRangeForMonth(month, isProjected);
    } else {
      [dateRangeStart, dateRangeEnd] = getDateRange(true, isProjected);
    }
    setSessionStorage(sessionStorage => ({
      ...sessionStorage,
      appointmentStatus: isProjected
        ? { id: 'NO_APPOINTMENT', name: 'Projected' }
        : { id: 'SCHEDULED', name: 'Scheduled' },
      dateRangeStart,
      dateRangeEnd,
      selectedStudySites,
      selectedStudies,
      selectedSites,
      selectedEncounters,
      encountersPerPerson: { id: 'ALL', name: 'All' },
      page: 0
    }));
    setTimeout(() => navigate(generateUrlByKey('Home Page.Scheduling Worklist')));
  };

  const columns = [
    {
      Header: <CustomHeader title="Month" />,
      accessor: 'date',
      minWidth: 90
    },
    {
      Header: <CustomHeader title="Unscheduled Next Visit" />,
      accessor: 'projected',
      minWidth: 200,
      Cell: ({ value, original }) => <Template value={value} onClick={() => openNumbers(true, original.date)} />
    },
    {
      Header: <CustomHeader title="Overdue Check-In" />,
      accessor: 'checkIn',
      minWidth: 170,
      Cell: ({ value, original }) => <Template value={value} onClick={() => openNumbers(false, original.date)} />
    }
  ];
  return (
    <div>
      <ReactTable
        resizable={false}
        className="px-3 mb-3 with-total-table"
        data={tableData}
        columns={columns}
        showPageSizeOptions={false}
        defaultPageSize={3}
        showPagination={false}
        noDataText="No Record Found"
      />
    </div>
  );
}

function Template({ value, onClick }) {
  return (
    <div className={cx('', { link: value > 0 })} onClick={() => value > 0 && onClick()}>
      {value}
    </div>
  );
}
