import React, { useContext, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import moment from 'moment';

import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import useAppInfo from '../../../../../../common/hooks/useAppInfo';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../../constants/dateFormat';
import { SIGNED } from '../../../../../../constants/notificationMessages';
import { CLOSED } from '../../../../../../constants/ssuStatuses';
import { ROLE_SYSTEM_ADMINISTRATOR } from '../../../../../../constants/userRoles';
import store from '../../../../../../store';
import { useCurrentRoute } from '../../../../../root/router';
import { isUserAbleToSeeSignEncounterButton } from '../reviewAccessService';
import {
  generateResearchConnectStatus,
  getConfirmedItemGroupsCount,
  getRequireAttentionItemGroupsCount
} from '../ReviewContent/reviewContentService';
import { ReviewContext } from '../ReviewContext';
import ReviewDataResolver from '../ReviewDataResolver';
import { isSmOrPiReview, isSmReview } from '../reviewService';

import ReviewLogHistory from './ReviewLogHistory/ReviewLogHistory';
import SmPiReviewSignModal from './SmPiReviewSignModal/SmPiReviewSignModal';

import './ReviewControl.scss';

export default function ReviewControl() {
  const currentRoute = useCurrentRoute();
  const groupAssignId = currentRoute.params?.groupAssignId;

  const {
    itemGroupSnapshotReviewStates,
    logAndLogCheckSnapshotReviewStates,
    ssuPatientId,
    patientEncounterId,
    reviewType,
    isHistoricalData,
    requiredAttentionItemGroupsConfirmStatuses,
    itemGroups,
    logsAndLogChecks,
    reviewMetaData,
    sendDataToResearchConnect,
    reviewDetails,
    loadData
  } = useContext(ReviewContext);

  const groupAssignName = reviewDetails?.groupAssignName;
  const isFullySigned = reviewDetails?.isFullySigned;

  const studySiteStatus = reviewMetaData?.studySiteStatus;
  const appInfo = useAppInfo();
  const researchConnectButton = appInfo.features?.afterburnerEnabled;

  const [reviewHistory, setReviewHistory] = useState([]);
  const requireAttentionItemGroupsCount = getRequireAttentionItemGroupsCount(
    requiredAttentionItemGroupsConfirmStatuses
  );
  const confirmedItemGroupsCount = getConfirmedItemGroupsCount(requiredAttentionItemGroupsConfirmStatuses);
  const allItemGroups = useMemo(
    function() {
      return [...logsAndLogChecks, ...itemGroups];
    },
    [itemGroups, logsAndLogChecks]
  );
  const isDisableSignButton = useMemo(
    function() {
      /*TODO: need to fix*/
      const lengthOfLogChecksWhichHasSnapshot = Object.values({ ...logAndLogCheckSnapshotReviewStates }).length;
      const lengthOfItemGroupWhichHasSnapshot =
        Object.values({
          ...itemGroupSnapshotReviewStates
        }).length + lengthOfLogChecksWhichHasSnapshot;
      const isAllDataLoaded = lengthOfItemGroupWhichHasSnapshot === allItemGroups.length;
      return isHistoricalData || allItemGroups.length === 0 || !isAllDataLoaded;
    },
    [isHistoricalData, itemGroupSnapshotReviewStates, allItemGroups, logAndLogCheckSnapshotReviewStates]
  );

  useEffect(
    function() {
      ReviewDataResolver.getReviewHistoryByReviewType(reviewType, ssuPatientId, patientEncounterId).then(({ data }) => {
        setReviewHistory(data.items);
      });
    },
    [ssuPatientId, patientEncounterId, reviewType]
  );

  const signHandlerFn = res => {
    const signatureItem = res?.data;
    setReviewHistory([...reviewHistory, signatureItem]);
    loadData();
    NotificationManager.success(SIGNED);
    const afterburnerStatus = generateResearchConnectStatus(res?.data?.afterburnerResponses);
    if (afterburnerStatus) {
      NotificationManager.info(afterburnerStatus);
    }
  };

  const modalProps = {
    itemGroupSnapshotReviewStates,
    logAndLogCheckSnapshotReviewStates,
    ssuPatientId,
    patientEncounterId,
    reviewType,
    groupAssignId
  };

  const openLoginModal = () => {
    const currentDate = moment().format(DD_SLASH_MMM_SLASH_YYYY);
    let signAsAdmin = store.getState().currentUser.activeRole === ROLE_SYSTEM_ADMINISTRATOR;

    ModalBoxes.open({
      component: (
        <SmPiReviewSignModal
          {...modalProps}
          showReason={signAsAdmin}
          signHandlerFn={signHandlerFn}
          currentDate={currentDate}
        />
      ),
      className: 'eds-review-sign-modal-box'
    });
  };

  const statusText = getStatusText();

  return (
    <div
      className={cx('erv-control', {
        'erv-control-signed': !isHistoricalData && isFullySigned,
        'erv-control-previous': isHistoricalData
      })}
    >
      <div className="erv-control-left" title={statusText}>
        {statusText}
      </div>
      <div className="erv-control-right">
        {isSmReview(reviewType) &&
          isUserAbleToSeeSignEncounterButton() &&
          studySiteStatus &&
          studySiteStatus !== CLOSED &&
          researchConnectButton && (
            <Button priority="medium" size="h28" disabled={isDisableSignButton} onClick={sendDataToResearchConnect}>
              Send to Research Connect
            </Button>
          )}
        <div className="eds-review-history">
          <ReviewLogHistory reviewHistory={reviewHistory} />
        </div>
        {isSmOrPiReview(reviewType) &&
          isUserAbleToSeeSignEncounterButton() &&
          studySiteStatus &&
          studySiteStatus !== CLOSED &&
          !isHistoricalData && (
            <Button
              disabled={isDisableSignButton || requireAttentionItemGroupsCount !== confirmedItemGroupsCount}
              onClick={openLoginModal}
              priority="medium"
              size="h28"
            >
              Sign Encounter
            </Button>
          )}
      </div>
    </div>
  );

  function getStatusText() {
    if (!isHistoricalData && isFullySigned) {
      return `${groupAssignName} Encounter sign-off has been completed.`;
    }
    if (isHistoricalData) {
      return `You are viewing a previous state for ${groupAssignName} Review`;
    }
    return `You are in ${groupAssignName} Review Mode`;
  }
}
