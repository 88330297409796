import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Input from '../../../../../common/data-entry/Input';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';

import './not-perform-shortcut-modal.scss';

function NotPerformShortcutModal({ modalBox, onConfirm, notStarted, partial }) {
  const [input, setInput] = useState('');
  const [validationError, setValidationError] = useState(false);

  const handleInputChange = e => {
    let text = e.target.value;
    setInput(text);
    setValidationError(false);
  };

  const closeEncounter = e => {
    if (!input) {
      e.preventDefault();
      setValidationError(true);
      return;
    }
    onConfirm(input);
  };

  return (
    <React.Fragment>
      <ModalBoxes.Header>Close Encounter</ModalBoxes.Header>
      <ModalBoxes.Body>
        <div className="mt-4">
          <h5>Partially Done</h5>
          {partial.length ? (
            partial.map((encounter, index) => {
              return <p key={index}>{encounter}</p>;
            })
          ) : (
            <p>None</p>
          )}
        </div>
        <div className="my-4 pt-2">
          <h5>Not Started</h5>
          {notStarted.length ? (
            notStarted.map((encounter, index) => {
              return <p key={index}>{encounter}</p>;
            })
          ) : (
            <p>None</p>
          )}
        </div>
      </ModalBoxes.Body>
      <div className="close-reason-div">
        <Input
          label="Reason"
          onChange={handleInputChange}
          value={input}
          required={true}
          valid={!validationError}
          disabled={notStarted.length === 0 && partial.length === 0}
          validationMessage={validationError ? 'Reason is required' : null}
        />
      </div>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button onClick={modalBox.close}>Cancel</Button>
          <Button priority="medium" disabled={notStarted.length === 0 && partial.length === 0} onClick={closeEncounter}>
            Close Encounter
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </React.Fragment>
  );
}

export default NotPerformShortcutModal;

NotPerformShortcutModal.defaultProps = {
  partial: [],
  notStarted: []
};

NotPerformShortcutModal.propTypes = {
  modalBox: PropTypes.shape({
    id: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired
  }),
  onConfirm: PropTypes.func.isRequired,
  partial: PropTypes.array,
  notStarted: PropTypes.array
};

NotPerformShortcutModal.className = 'not-performed-shortcut-modal';
NotPerformShortcutModal.size = 'w650';
