import { Outlet } from 'react-router-dom';

import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';

import ModalBoxesContainer from '../../common/feedback/ModalBoxes/ModalBoxesContainer/ModalBoxesContainer';
import useAppInfo from '../../common/hooks/useAppInfo';
import NotificationContainer from '../../common/notifications/NotificationContainer';
import { FileViewerProvider } from '../../contexts/FileViewerContext';
import { FiltersProvider } from '../../contexts/filters/FiltersContext';
import PendoAnalytics from '../../pendo/PendoUtils';
import FileViewer from '../FileViewer/FileViewer';
import { NotificationsProvider } from '../Notifications/NotificationsContext';

import HistoryBlock from './Container/HistoryBlock';
import Layout from './Container/Layout/Layout';
import TasksContainer from './Container/Layout/Tasks/TasksContainer';

export default function Root() {
  const appInfo = useAppInfo();

  return (
    <FileViewerProvider>
      <FiltersProvider>
        <HistoryBlock />
        <div className="page-outer-container" style={generateAppTheme(appInfo)}>
          <NotificationsProvider>
            <Layout>
              <Outlet />
            </Layout>
          </NotificationsProvider>
        </div>
        <ModalBoxesContainer />
        <NotificationContainer />
        <LoadingOverlay />
        <TasksContainer />
        <FileViewer />
        <PendoAnalytics />
        {/*<NewRelicBrowserMonitoring /> todo: disabled because of conflict with jsPDF library */}
      </FiltersProvider>
    </FileViewerProvider>
  );
}

function generateAppTheme(appInfo = {}) {
  const { displayAppVersion } = appInfo;
  const trainingOrSandboxEnvironmentMainColor = '#015592',
    trainingOrSandboxEnvironmentExtraColor1 = '#1269A9',
    trainingOrSandboxEnvironmentExtraColor2 = '#044270';
  const stagingEnvironmentMainColor = '#019155',
    stagingEnvironmentExtraColor1 = '#12A969',
    stagingEnvironmentExtraColor2 = '#047042';
  if (displayAppVersion?.includes('TRN') || displayAppVersion?.includes('SND')) {
    return {
      '--environment-main-color': trainingOrSandboxEnvironmentMainColor,
      '--environment-extra-color-1': trainingOrSandboxEnvironmentExtraColor1,
      '--environment-extra-color-2': trainingOrSandboxEnvironmentExtraColor2
    };
  }
  if (displayAppVersion?.includes('STAGE')) {
    return {
      '--environment-main-color': stagingEnvironmentMainColor,
      '--environment-extra-color-1': stagingEnvironmentExtraColor1,
      '--environment-extra-color-2': stagingEnvironmentExtraColor2
    };
  }
}
