import { useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { List } from '@mui/material';
import { useVirtualizer } from '@tanstack/react-virtual';
import { isFunction } from 'lodash/lang';

import { SITUATIONAL_ENCOUNTERS_EPOCH_ID } from '../../services';

const itemHeight = 50;

export default function DraggableVirtualList({ data, onDragEnd, ItemContent, itemContentProps, itemIdKey }) {
  const parentRef = useRef();

  const virtualList = useVirtualizer({
    count: data.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => itemHeight,
    overscan: 5
  });

  const items = virtualList.getVirtualItems();
  const idProperty = itemIdKey;
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId={`droppable-${idProperty}`}
        mode="virtual"
        renderClone={function(provided, snapshot, rubric) {
          return (
            <ItemContent
              provided={provided}
              snapshot={snapshot}
              index={rubric.source.index}
              item={data[rubric.source.index]}
            />
          );
        }}
      >
        {function(provided) {
          return (
            <div
              className="cpo-droppable-list"
              ref={function(ref) {
                parentRef.current = ref;
                provided.innerRef(ref);
              }}
              {...provided.droppableProps}
            >
              <div className="cpo-droppable-list-container">
                <List
                  style={{
                    height: `${virtualList.getTotalSize() + 40}px`,
                    paddingTop: `${items[0].start + 20}px`,
                    paddingBottom: 20
                  }}
                >
                  {virtualList.getVirtualItems().map(function(virtualRow) {
                    const { index } = virtualRow;
                    const item = data[index];
                    const props = isFunction(itemContentProps) ? itemContentProps(index, item) : {};
                    return (
                      <div style={{ height: itemHeight }} key={item[idProperty]}>
                        <Draggable
                          draggableId={`draggable-id-${item[idProperty]}`}
                          index={index}
                          isDragDisabled={item[idProperty] === SITUATIONAL_ENCOUNTERS_EPOCH_ID}
                        >
                          {function(provided, snapshot) {
                            return (
                              <ItemContent
                                provided={provided}
                                snapshot={snapshot}
                                index={index}
                                item={item}
                                {...props}
                              />
                            );
                          }}
                        </Draggable>
                      </div>
                    );
                  })}
                </List>
              </div>
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
}
