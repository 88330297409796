import React, { useEffect, useMemo, useState } from 'react';
import ReactSuperSelect from 'react-super-select';
import cx from 'classnames';
import { isEmpty, isEqual, isFunction, isNull, isString, isUndefined } from 'lodash/lang';
import { omit } from 'lodash/object';

import { pendoTrack } from '../../../pendo/PendoUtils';

import _Primitive from './Primitive/_Primitive';
import selectPropTypes from './types';

import './Select.scss';
import './Select.legacy.scss';

const Select = React.forwardRef(function(props, ref) {
  const {
    label,
    optionLabelKey,
    defaultValue,
    validationMessage,
    valid,
    clearable,
    className,
    disabled,
    required,
    multiple,
    validate,
    groupBy,
    dataSource,
    name,
    type,
    customSelectedValueTemplateFunction,
    legacyLook,
    title
  } = props;
  const propValue = props.value;
  const [value, setValue] = useState(isUndefined(propValue) ? defaultValue : propValue);
  const [customDataSource, setCustomDataSource] = useState([]);

  useEffect(() => {
    let customDataSourceToSave;
    if (!multiple && !isEmpty(value) && dataSource?.length > 0) {
      const data = Array.isArray(dataSource) ? dataSource.filter(element => !isEqual(value, element)) : [];
      customDataSourceToSave = [value, ...data];
    } else {
      customDataSourceToSave = dataSource;
    }

    if (!isEmpty(value) && (!customDataSourceToSave || customDataSourceToSave?.length === 0)) {
      setValue(null);
    }
    if (customDataSourceToSave?.length > 0) {
      setCustomDataSource(customDataSourceToSave);
    } else {
      setCustomDataSource([]);
    }
  }, [dataSource, multiple, value]);

  const isDefinedNewValue = !isUndefined(propValue);
  const isUndefinedNewValue = props.hasOwnProperty('value') && (isUndefined(propValue) || isNull(propValue));
  const isDefinedCurrentValue = !isUndefined(value) && !isNull(value);
  const isNotEqualCurrentValueAndNewValue = propValue !== value;

  if ((isUndefinedNewValue && isDefinedCurrentValue) || (isDefinedNewValue && isNotEqualCurrentValueAndNewValue)) {
    setValue(propValue);
  }

  const rest = omit(
    props,
    'label',
    'type',
    'onChange',
    'defaultValue',
    'value',
    'validationMessage',
    'valid',
    'className',
    'validate',
    'initialValue',
    'dataSource',
    'data-testid',
    'legacyLook',
    'title'
  );

  const isNotValid = (isString(validationMessage) && validationMessage.length > 0) || !valid;

  const isChips = useMemo(() => type === 'chips', [type]);

  const ifChipsProps = useMemo(
    function() {
      if (!isChips) {
        return {};
      }
      return {
        placeholder: label,
        customSelectedValueTemplateFunction(value) {
          if (customSelectedValueTemplateFunction) {
            return (
              <React.Fragment>
                {label}: {customSelectedValueTemplateFunction(value)}
              </React.Fragment>
            );
          }
          return `${label}: ${value[0][optionLabelKey]}`;
        }
      };
    },
    [isChips, label, optionLabelKey, customSelectedValueTemplateFunction]
  );

  return (
    <div
      className={cx('eds-select', `eds-select-type-${type}`, className, {
        'eds-select-not-empty': !isEmpty(value),
        'eds-select-not-valid': isNotValid,
        'eds-select-clearable': clearable,
        'eds-select-not-clearable': !clearable,
        'eds-select-disabled': disabled,
        'eds-select-required': required,
        'eds-select-multiselect': multiple,
        'eds-select-with-grouping': groupBy,
        'eds-select-legacy': legacyLook
      })}
      data-testid={props['data-testid']}
      title={title}
    >
      <ReactSuperSelect
        inputProps={{ 'aria-label': props?.controlId }}
        initialValue={value}
        onChange={value => {
          pendoTrack('Click on select', { value: value, pageName: window.location.pathname });
          onChange(value);
        }}
        dataSource={customDataSource}
        onCloseDropdown={onCloseDropdown}
        groupBy={groupBy}
        {...rest}
        {...ifChipsProps}
        focusToSelectedValue={true}
      />
      {!isChips && (
        <label className="eds-select-label" name={name}>
          {label}
        </label>
      )}
      {validate && <div className="eds-select-validation-message">{validationMessage}</div>}
    </div>
  );

  function onChange(value) {
    const { onChange } = props;

    setValue(value);
    isFunction(onChange) && onChange(value);
  }

  function onCloseDropdown() {
    if (multiple && !isEmpty(value)) {
      const selectedValuesMap = props.value.map(e => e.id);
      dataSource.forEach(elem => {
        if (elem.id === 'ALL') {
          elem.checked = 0;
        } else {
          if (selectedValuesMap.includes(elem.id)) {
            elem.checked = 1;
          } else {
            elem.checked = 2;
          }
        }
      });
    }
  }
});

Select.defaultProps = {
  deselectOnSelectedOptionClick: false,
  valid: true,
  disabled: false,
  clearable: true,
  required: false,
  validate: true,
  placeholder: '',
  type: 'default',
  optionLabelKey: 'name'
};

Select.propTypes = selectPropTypes;

Select.Primitive = _Primitive;

export default Select;
