import React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash/lang';

import IconButton from '../../../../../../../common/buttons/IconButton/IconButton';
import Checkbox from '../../../../../../../common/data-entry/InputSelectors/Checkbox';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { scBlue100 } from '../../../../../../../constants/systemColors';
import {
  NO_DATA_TO_COLLECT,
  NOT_PERFORMED
} from '../../../../../patient-source/shared/EncounterDescNew/LogCheck/LogCheckNew';
import AddOrEditLogCheckOptionModal from '../AddOrEditLogCheckOptionModal/AddOrEditLogCheckOptionModal';

import './LogCheckSection.scss';

export function LogCheckSectionNew({ initialOptionList, updateLogCheckOptions, sectionTitle, optionType }) {
  const validateNewOption = value => {
    return !initialOptionList.some(option => option.value.trim() === value.trim());
  };

  const editLogCheckOption = option => {
    const addOrEditLogCheckOptionModal = ModalBoxes.open({
      component: (
        <AddOrEditLogCheckOptionModal
          onSave={option => {
            saveExistingLogCheckOption(option);
            addOrEditLogCheckOptionModal.close();
          }}
          logCheckOption={option}
          validateOption={validateNewOption}
        />
      ),
      title: 'Edit log check option'
    });
  };

  const saveExistingLogCheckOption = option => {
    updateLogCheckOptions(initialOptionList.map(el => (el.sequence === option.sequence ? option : el)));
  };

  const onChange = event => {
    initialOptionList.forEach(e => {
      e.checked = event.target.checked;
    });
    updateLogCheckOptions([...initialOptionList]);
  };

  return (
    <div className="col p-3 section-container">
      <div className="section-header">
        <span className="title">
          <b>{sectionTitle}</b>
        </span>
      </div>
      <div className="section-body">
        <div className="list-group mt-2">
          <div className="label-list">
            {initialOptionList
              .filter((option, index) => index === 0)
              .map((option, index) => (
                <div className={cx('log-check-list-group-item')} key={index}>
                  {[NO_DATA_TO_COLLECT, NOT_PERFORMED].includes(optionType) && (
                    <Checkbox
                      className={'ml-0 mr-3'}
                      color={scBlue100}
                      checked={!isEmpty(initialOptionList.filter(e => e.checked))}
                      onChange={onChange}
                    />
                  )}
                  <IconButton
                    className="left-position"
                    suit="glyphicon"
                    color="blue"
                    title="Edit status"
                    onClick={() => editLogCheckOption(option)}
                  >
                    edit
                  </IconButton>
                  <span>{option.value}</span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
