import React from 'react';
import * as PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';

import './EncountersHeaders.scss';

export default function EncountersHeaders(props) {
  const { encounters } = props;
  return (
    <React.Fragment>
      {encounters?.map(encounter => {
        return (
          <th
            height="38px"
            width="90"
            key={encounter.encounterIdentifier}
            id={encounter.encounterIdentifier}
            className="eett-encounter-cell eett-sticky-encounter"
          >
            <Tooltip
              overlay={encounter.encounterName}
              placement="top"
              mouseEnterDelay={0}
              mouseLeaveDelay={0.1}
              destroyTooltipOnHide={false}
              trigger={Object.keys({ hover: 1 })}
              align={{
                offset: [50, -5]
              }}
            >
              <span className="eett-encounter">
                <span className="eett-encounter-board eett-front-encounter-board">{encounter.displayName}</span>
                <span
                  className={`${
                    encounter.nonProtocol ? 'eett-non-protocol-encounter' : 'eett-regular-encounter'
                  } eett-back-encounter-board eett-encounter-board`}
                />
              </span>
            </Tooltip>
          </th>
        );
      })}
    </React.Fragment>
  );
}

EncountersHeaders.propTypes = { encounters: PropTypes.array };
