import React from 'react';

import { usePatientProfile } from '../../PatientProfileContext';

export default function PatientDetailsGuardianInformation() {
  const { patientInfo } = usePatientProfile();
  const { guardian } = patientInfo || {};
  const { firstName, lastName, phone, email } = guardian || {};

  return (
    <div className="patient-profile-information-content-section">
      <div className="patient-profile-information-header">
        <div className="patient-profile-information-header-title">Guardian Information</div>
      </div>

      <div className="patient-profile-information-data-row">
        <div className="patient-profile-information-label">
          <div className="patient-profile-information-label-field">Name:</div>
          <div aria-label="guardian-name-value" className="patient-profile-information-label-field">{`${firstName ||
            '-'} ${lastName || '-'}`}</div>
        </div>
      </div>

      <div className="patient-profile-information-data-row">
        <div className="patient-profile-information-label">
          <div className="patient-profile-information-label-field">Phone:</div>
          <div className="patient-profile-information-label-field">{phone || '-'}</div>
        </div>

        <div className="patient-profile-information-label">
          <div className="patient-profile-information-label-field">Email:</div>
          <div aria-label="guardian-email-value" className="patient-profile-information-label-field">
            {email || '-'}
          </div>
        </div>
      </div>
    </div>
  );
}
