import React, { useState } from 'react';
import { isEmpty, isEqual, isFunction } from 'lodash/lang';

import Button from '../../../../../../../../common/general/Button';
import Icon from '../../../../../../../../common/general/Icon';
import { useCurrentUser } from '../../../../../CurrentUserContainer';
import TaskAssigneesSelect from '../../TaskAssigneesSelect/TaskAssigneesSelect';
import useFilteredAssignees from '../../useFilteredAssignees';

import './TaskDetailsAssignees.scss';

function TaskDetailsAssignees({ taskForm, setAssignees, allAssignees, access, matchedSsuIds }) {
  const { assignees, studySiteId } = taskForm;
  const [editMode, setEditMode] = useState(false);
  const { personnelIdentifier } = useCurrentUser();

  const filteredAssignees = useFilteredAssignees(allAssignees, access, matchedSsuIds);

  return (
    <div className="etc-body-details">
      <div className="etc-body-details-block">
        <div className="etc-body-details-block-label">
          Assignees
          {assignees?.length < 5 && (
            <Button onClick={() => setEditMode(!editMode)} priority="medium" size="h28">
              <Icon>add</Icon> Add
            </Button>
          )}
        </div>
      </div>
      <div className="etc-body-details-assignees">
        {alphabeticalAssigneesSort(assignees)?.map(assignee => (
          <div key={assignee.personnelIdentifier} className="etc-body-details-assignees-list-item">
            <div className="etc-body-details-assignee-info">
              {isEqual(assignee.personnelIdentifier, personnelIdentifier) && <Icon>person</Icon>}
              <p>
                {`${assignee.lastName} ${assignee.firstName}` || `${assignee.name}`}
                <span>
                  {assignee.roles
                    ?.map(role => role.title)
                    .sort()
                    .join(', ')}
                </span>
              </p>
            </div>
            {assignees?.length > 1 && (
              <Icon
                suit={'material-outline'}
                onClick={() => {
                  isFunction(setAssignees) &&
                    setAssignees(assignees.filter(i => i.personnelIdentifier !== assignee.personnelIdentifier));
                }}
              >
                delete
              </Icon>
            )}
          </div>
        ))}
      </div>
      {editMode && (
        <div className="etc-body-details-assignees-select">
          <TaskAssigneesSelect
            disabled={!studySiteId}
            filteredAssignees={filteredAssignees}
            assignees={assignees}
            onChange={function(selectedAssignee) {
              if (isEmpty(selectedAssignee)) return;
              if (assignees.every(item => item.personnelIdentifier !== selectedAssignee?.personnelIdentifier)) {
                setAssignees([...assignees, selectedAssignee]);
              }
              setEditMode(false);
            }}
            access={access}
            matchedSsuIds={matchedSsuIds}
            clearable={false}
            validate={false}
          />
        </div>
      )}
    </div>
  );

  function alphabeticalAssigneesSort(assignees) {
    return assignees?.sort(function(a, b) {
      const textA = a.firstName.toUpperCase();
      const textB = b.firstName.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  }
}

export default TaskDetailsAssignees;
