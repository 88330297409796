import React, { useContext, useEffect, useMemo, useRef } from 'react';
import moment from 'moment';

import { FinInvoiceApi } from '../../../../../api';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { SOMETHING_WENT_WRONG } from '../../../../../constants/notificationMessages';
import { createPDF, getBlobContent } from '../CreateInvoice/CreateInvoiceServices';
import { EncounterInvoiceTemplate } from '../CreateInvoice/InvoiceTamplates/EncounterInvoiceTemplate';
import { CREDIT_MEMO, DEBIT_MEMO } from '../InvoiceTable/TableConstants';
import { InvoiceContext } from '../NewInvoiceContext';

export const InvoiceGenerator = () => {
  const {
    applyFilters,
    loadInvoice,
    setAutomaticallyGeneratedInvoice,
    automaticallyGeneratedInvoice: {
      createdInvoiceDetails: {
        comment,
        displayNumber,
        invoiceNumber,
        terms,
        contactName,
        remit,
        payee,
        client,
        invoiceDate
      },
      createdInvoiceEvents,
      invoiceFileId
    }
  } = useContext(InvoiceContext);

  const normalizedPayee = useMemo(() => JSON.parse(payee), [payee]);

  const invoiceType = createdInvoiceEvents[0].invoiceType;
  const resolvedInvoiceNumberForPDF = useMemo(() => `${displayNumber}-${invoiceType === DEBIT_MEMO ? 'DM' : 'CM'}`, [
    displayNumber,
    invoiceType
  ]);
  const pdfTemplateRef = useRef(null);

  useEffect(() => {
    const generatePDF = async () => {
      try {
        const pdf = await createPDF(pdfTemplateRef.current);
        const suffix = invoiceType === CREDIT_MEMO ? '-CM' : '-DM';
        const data = {
          fileId: invoiceFileId,
          fileName: `${createdInvoiceEvents[0].pcnName}_${createdInvoiceEvents[0].siteName}_${
            createdInvoiceEvents[0].eventNumber
          }.${createdInvoiceEvents[0].adjustmentSequence}${suffix}_${displayNumber}${suffix}_${moment(invoiceDate)
            .format('DD-MMM-YYYY')
            .toLowerCase()}.pdf`,
          content: getBlobContent(pdf)
        };
        await FinInvoiceApi.updateInvoiceFile(data);
        await FinInvoiceApi.saveInvoiceLog(invoiceNumber, null, 'SAVE', invoiceType);
        loadInvoice(invoiceNumber, invoiceFileId, invoiceType);
        setAutomaticallyGeneratedInvoice(null);
        applyFilters();
      } catch {
        setAutomaticallyGeneratedInvoice(null);
        applyFilters();
        NotificationManager.error(SOMETHING_WENT_WRONG);
      }
    };

    generatePDF();
  }, [
    createdInvoiceEvents,
    displayNumber,
    invoiceFileId,
    invoiceNumber,
    invoiceType,
    loadInvoice,
    setAutomaticallyGeneratedInvoice,
    invoiceDate,
    applyFilters
  ]);
  return (
    <div ref={pdfTemplateRef} className="hided-invoice-template">
      <EncounterInvoiceTemplate
        comment={comment}
        invoices={createdInvoiceEvents}
        invoiceNumber={resolvedInvoiceNumberForPDF}
        terms={terms}
        payee={normalizedPayee}
        billTo={client}
        contact={{ name: contactName }}
        remit={remit}
        balanceDue={createdInvoiceEvents[0].invoiceAmount}
        totalPages={1}
        setTotalPages={() => {}}
        invoiceType={invoiceType}
        adjustmentDate={invoiceDate}
      />
    </div>
  );
};
