import React, { useCallback, useContext, useEffect, useState } from 'react';
import { isEmpty, isString } from 'lodash/lang';
import moment from 'moment';
import * as PropTypes from 'prop-types';

import { ProgressNoteApi } from '../../../../../../../api';
import { LoadingHandler } from '../../../../../../../common/elements/Loader/LoadingHandler';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import Icon from '../../../../../../../common/general/Icon';
import { CLOSED } from '../../../../../../../constants/ssuStatuses';
import { MANAGE_PROGRESS_NOTES } from '../../../../../../../constants/userOperations';
import { ROLE_PATIENT_LIAISON, ROLE_SYSTEM_ADMINISTRATOR } from '../../../../../../../constants/userRoles';
import { userHasAccessTo, userHasRole } from '../../../../../../../services/auth';
import { PatientInfoContext } from '../../PatientInfoContext';

import AddOrEditProgressNoteModal from './AddOrEditProgressNoteModal/AddOrEditProgressNoteModal';
import AllProgressNotesModal from './AllProgressNotesModal/AllProgressNotesModal';

import './ProgressNotesWidget.scss';

export default function ProgressNotesWidget({ ssuPatientId }) {
  const { currentStudy, patientInfo } = useContext(PatientInfoContext);
  const [loading, setLoading] = useState(true);
  const [progressNotes, setProgressNotes] = useState(null);

  const isAllowedToManage =
    userHasRole(ROLE_SYSTEM_ADMINISTRATOR) ||
    userHasRole(ROLE_PATIENT_LIAISON) ||
    (currentStudy?.studySiteStatus !== CLOSED && userHasAccessTo(MANAGE_PROGRESS_NOTES));
  const loadProgressNotes = useCallback(
    function() {
      let canceled = false;
      setLoading(true);
      if (!isString(ssuPatientId) || isEmpty(ssuPatientId)) {
        return;
      }
      ProgressNoteApi.getProgressNotes(ssuPatientId).then(({ data }) => {
        if (canceled) {
          return;
        }
        setLoading(false);
        setProgressNotes(data);
      });
      return function() {
        canceled = true;
      };
    },
    [ssuPatientId]
  );
  const notesInfo = {
    patientFirstName: patientInfo?.firstName,
    patientLastName: patientInfo?.lastName,
    patientId: patientInfo?.patientId,
    subjectId: currentStudy?.patientSubjectId,
    dateOfBirth: patientInfo?.dob,
    studyName: currentStudy?.studyName,
    siteName: currentStudy?.siteName
  };

  useEffect(
    function() {
      const cancelLoadProgressNotes = loadProgressNotes();
      return function() {
        cancelLoadProgressNotes();
      };
    },
    [loadProgressNotes]
  );

  return (
    <div className="eds-progress-notes-widget">
      <div className="pnw-header">
        <div>
          <h3 className="mr-2">NOTES</h3>
          {isAllowedToManage && (
            <Button size="h28" priority="medium" onClick={addProgressNote} title="Add Note">
              <Icon>add</Icon>
            </Button>
          )}
        </div>
        <Button size="h28" priority="low" onClick={viewAllProgressNotes}>
          View All
        </Button>
      </div>
      <div className="pnw-list">
        <LoadingHandler complete={!loading}>
          {isEmpty(progressNotes) ? (
            <div className="pnw-list-no-data">There are currently no notes</div>
          ) : (
            progressNotes.map(progressNote => {
              const { noteCreatedDate, lastUpdatedByName, note, progressNoteType } = progressNote;
              return (
                <div
                  className="pnw-list-item"
                  onClick={() => viewProgressNote(progressNote)}
                  key={+moment(noteCreatedDate)}
                >
                  <div className="pnw-list-item-header">
                    {moment(noteCreatedDate).format('DD/MMM/YY')}
                    <span className="ml-3">{progressNoteType}</span>
                    <span className="ml-3">{lastUpdatedByName}</span>
                  </div>
                  <div className="pnw-list-item-content">{note}</div>
                </div>
              );
            })
          )}
        </LoadingHandler>
      </div>
    </div>
  );

  function viewProgressNote(progressNote) {
    ModalBoxes.open({
      component: (
        <AllProgressNotesModal
          ssuPatientId={ssuPatientId}
          reloadProgressNotes={loadProgressNotes}
          progressNote={progressNote}
          isAllowedToManage={isAllowedToManage}
          editProgressNote={editProgressNote}
          notesInfo={notesInfo}
        />
      )
    });
  }

  function viewAllProgressNotes() {
    ModalBoxes.open({
      component: (
        <AllProgressNotesModal
          ssuPatientId={ssuPatientId}
          reloadProgressNotes={loadProgressNotes}
          editProgressNote={editProgressNote}
          addProgressNote={() => addProgressNote(false)}
          isAllowedToManage={isAllowedToManage}
          notesInfo={notesInfo}
        />
      )
    });
  }

  function addProgressNote(shouldClose = true) {
    ModalBoxes.open({
      component: (
        <AddOrEditProgressNoteModal
          ssuPatientId={ssuPatientId}
          onSave={function() {
            ModalBoxes.closeAll();
            !shouldClose && viewAllProgressNotes();
            loadProgressNotes();
          }}
          notesInfo={notesInfo}
        />
      ),
      title: 'Add Note'
    });
  }

  function editProgressNote(progressNote) {
    ModalBoxes.open({
      component: (
        <AddOrEditProgressNoteModal
          ssuPatientId={ssuPatientId}
          progressNote={progressNote}
          onSave={function() {
            ModalBoxes.closeAll();
            viewAllProgressNotes();
          }}
          notesInfo={notesInfo}
        />
      ),
      title: 'Edit Note'
    });
  }
}

ProgressNotesWidget.propTypes = {
  ssuPatientId: PropTypes.string
};
