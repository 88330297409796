import React from 'react';
import { isEqual } from 'lodash/lang';

import './SingleSelectList.scss';

export const SingleSelectList = ({ options, selectedOption, onSelect, height, label }) => {
  return (
    <div
      className={`eds-single-select-list py-3 ${height ? 'eds-single-select-list-fixed-height' : ''}`}
      style={{ height: height }}
    >
      <div className="eds-single-select-list-options">
        {options.map((option, index) => {
          return (
            <div
              className={`eds-single-select-list-option cursor-pointer ${
                isEqual(selectedOption, option) ? 'selected' : 'text-muted'
              }`}
              key={index}
              onClick={() => onSelect(option)}
            >
              <i className="material-icons">done</i>
              {option[label]}
            </div>
          );
        })}
      </div>
    </div>
  );
};
