import React, { useState } from 'react';

import Button from '../../../../common/general/Button';

export function AddAppointmentButtons({ onSave, onClose }) {
  // prevent clicking on Save Button multiple times
  const [isSaveDisabled, setSaveDisabled] = useState(false);

  const onClickSave = event => {
    setSaveDisabled(true);
    // onSave may or may not return a promise depending on
    // form validation. Just to be safe use Promise.resolve
    Promise.resolve(onSave(event)).then(() => {
      setSaveDisabled(false);
    });
  };

  return (
    <React.Fragment>
      <div className="action-item">
        <Button onClick={onClose} priority="medium">
          Cancel
        </Button>
      </div>
      <div className="action-item">
        <Button onClick={onClickSave} disabled={isSaveDisabled}>
          Add
        </Button>
      </div>
    </React.Fragment>
  );
}
