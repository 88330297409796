import React from 'react';
import moment from 'moment';

import './LedgerEventInfo.scss';

export function LedgerEventInfo({ ledgerEventInfo }) {
  return (
    <div className="ledger-event-info-history-modal" role="row">
      <span className="header">Info</span>
      <div className="info-item">
        <span className="info-item-title">Event date</span>
        <span className="info-item-content">{moment().format('DD/MMM/YYYY')}</span>
      </div>
      <div className="info-item">
        <span className="info-item-title">Study</span>
        <span className="info-item-content">{ledgerEventInfo?.studyName}</span>
      </div>
      <div className="info-item">
        <span className="info-item-title">Site</span>
        <span className="info-item-content">{ledgerEventInfo?.siteName}</span>
      </div>
      <div className="info-item">
        <span className="info-item-title">Subject ID</span>
        <span className="info-item-content">{ledgerEventInfo?.subjectId || '—'}</span>
      </div>
      <div className="info-item">
        <span className="info-item-title">Encounter Name</span>
        <span className="info-item-content">{ledgerEventInfo?.encounterName || '—'}</span>
      </div>
      <div className="info-item">
        <span className="info-item-title">Budget Event Type</span>
        <span className="info-item-content">{ledgerEventInfo?.type}</span>
      </div>
      <div className="info-item">
        <span className="info-item-title">Item Group Name</span>
        <span className="info-item-content">{ledgerEventInfo?.itemGroupName || '—'}</span>
      </div>
      <div className="info-item">
        <span className="info-item-title">Event Name</span>
        <span className="info-item-content">{ledgerEventInfo?.finBudgetRowName}</span>
      </div>
    </div>
  );
}
