import React, { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@mui/material';

import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import {
  APPROVE_SITE_PAYMENTS,
  EXPORT_SITE_PAYMENT_NS,
  MANAGE_SITE_PAYMENTS
} from '../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../services/auth';
import { useCurrentUser } from '../../../../../root/Container/CurrentUserContainer';
import { CreditApplicationHistoryModal } from '../../CreditApplicationHistoryModal/CreditApplicationHistoryModal';
import { SitePaymentsContext } from '../../SitePaymentsContext';
import {
  openCreateBillModal,
  openMarkAsPaidModal,
  openRejectModal,
  prepareItemsForReadyForApproval
} from '../SelectedItemsMenu/SelectedItemsMenu';
import { SITE_CREDIT, SITE_CREDIT_WH } from '../SitePaymentsConstants';

export const SitePaymentsTableItemMenu = ({ row }) => {
  const { onResetToOpen, applyFilter, onApprove, exportNS, tableData } = useContext(SitePaymentsContext);

  const currentUser = useCurrentUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  const readyForApprovalEnabled = useMemo(
    () => ['OPEN', 'REJECTED'].includes(row.siteApprovalStatus) && row?.sitePaymentBillNumber,
    [row.siteApprovalStatus, row.sitePaymentBillNumber]
  );

  const resetToOpenEnabled = useMemo(() => ['READY_FOR_APPROVAL', 'REJECTED'].includes(row.siteApprovalStatus), [
    row.siteApprovalStatus
  ]);

  const createSiteBillEnabled = useMemo(() => {
    const withoutParentAndNoSiteBill = row.withoutParent && !row.sitePaymentBillNumber;
    const parentPaymentTypeAndNoParentBill = row.sitePaymentType === 'PARENT' && !row.parentSiteBillNumber;
    const eventHasNoSiteBillNumber = !row.sitePaymentBillNumber;
    const eventsHaveSiteApprovalStatusOpen = ['OPEN'].includes(row.siteApprovalStatus);
    const everyEventHasParentWithBillNumber = row.parentSiteBillNumber;

    return (
      withoutParentAndNoSiteBill ||
      parentPaymentTypeAndNoParentBill ||
      (eventHasNoSiteBillNumber && eventsHaveSiteApprovalStatusOpen && everyEventHasParentWithBillNumber)
    );
  }, [
    row.withoutParent,
    row.sitePaymentType,
    row.parentSiteBillNumber,
    row.sitePaymentBillNumber,
    row.siteApprovalStatus
  ]);

  const approveEnabled = useMemo(() => {
    const eventWasMarkedAsReadyForApprovalNotByCurrentUser = row.readyForApprovalBy !== currentUser.personnelIdentifier;
    const eventHaveSiteApprovalStatusReadyForApproval = ['READY_FOR_APPROVAL'].includes(row.siteApprovalStatus);

    return eventWasMarkedAsReadyForApprovalNotByCurrentUser && eventHaveSiteApprovalStatusReadyForApproval;
  }, [currentUser.personnelIdentifier, row.readyForApprovalBy, row.siteApprovalStatus]);

  const rejectEnabled = useMemo(() => {
    const sitePaymentStatusIsNotPaid = !['PAID', 'APPLIED', 'PARTIALLY_APPLIED'].includes(row.sitePaymentStatus);
    return ['READY_FOR_APPROVAL', 'APPROVED'].includes(row.siteApprovalStatus) && sitePaymentStatusIsNotPaid;
  }, [row.siteApprovalStatus, row.sitePaymentStatus]);

  const markAsPaidEnabled = useMemo(() => {
    const eventHasSiteApprovalStatusApproved = ['APPROVED'].includes(row.siteApprovalStatus);
    const eventHasSitePaymentStatusPending = ['PENDING'].includes(row.sitePaymentStatus);
    return eventHasSiteApprovalStatusApproved && eventHasSitePaymentStatusPending;
  }, [row.siteApprovalStatus, row.sitePaymentStatus]);

  const exportNSEnabled = useMemo(() => {
    const eventHasBillNumber = row.sitePaymentBillNumber;
    const onlyOneEventWithThisBillNumberExists =
      tableData.filter(({ sitePaymentBillNumber }) => sitePaymentBillNumber === row.sitePaymentBillNumber).length === 1;
    return eventHasBillNumber && onlyOneEventWithThisBillNumberExists;
  }, [row.sitePaymentBillNumber, tableData]);

  const applyCreditEnabled = useMemo(() => {
    const isCreditEvent = [SITE_CREDIT, SITE_CREDIT_WH].includes(row.sitePaymentType);
    const hasBillNumber = row.sitePaymentBillNumber;
    const isNotFullyApplied = row.sitePaidAmount !== row.siteAmount;
    return isCreditEvent && hasBillNumber && isNotFullyApplied;
  }, [row.siteAmount, row.sitePaidAmount, row.sitePaymentBillNumber, row.sitePaymentType]);

  const applicationHistoryEnabled = useMemo(() => {
    const isCreditEvent = [SITE_CREDIT, SITE_CREDIT_WH].includes(row.sitePaymentType);
    const sitePaymentStatusIsApplied = ['APPLIED', 'PARTIALLY_APPLIED'].includes(row.sitePaymentStatus);
    return isCreditEvent && sitePaymentStatusIsApplied;
  }, [row.sitePaymentStatus, row.sitePaymentType]);

  return (
    <>
      <MoreVertIcon onClick={openMenu} data-testid={`event-row-menu-${row.eventNumber}.${row.adjustmentSequence}`} />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        slotProps={{
          paper: {
            sx: {
              width: '250px'
            }
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem
          onClick={() => {
            openCreateBillModal([row], applyFilter);
            handleCloseMenu();
          }}
          disabled={!createSiteBillEnabled}
        >
          Create Bill
        </MenuItem>
        {userHasAccessTo(EXPORT_SITE_PAYMENT_NS) && (
          <MenuItem
            onClick={() => {
              exportNS([row]);
              handleCloseMenu();
            }}
            disabled={!exportNSEnabled}
          >
            Export NS
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            navigate('/site-payments/ready-for-approval', {
              state: { checkedEvents: prepareItemsForReadyForApproval([row]) }
            });
            handleCloseMenu();
          }}
          disabled={!readyForApprovalEnabled}
        >
          Ready for Approval
        </MenuItem>
        <MenuItem
          onClick={() => {
            onResetToOpen([row.itemSiteId]);
            handleCloseMenu();
          }}
          disabled={!resetToOpenEnabled}
        >
          Reset to Open
        </MenuItem>
        {userHasAccessTo(APPROVE_SITE_PAYMENTS) && (
          <MenuItem
            onClick={() => {
              onApprove([row.itemSiteId]);
              handleCloseMenu();
            }}
            disabled={!approveEnabled}
          >
            Approve
          </MenuItem>
        )}
        {userHasAccessTo(APPROVE_SITE_PAYMENTS) && (
          <MenuItem
            onClick={() => {
              openRejectModal([row.itemSiteId], applyFilter);
              handleCloseMenu();
            }}
            disabled={!rejectEnabled}
          >
            Reject
          </MenuItem>
        )}
        {userHasAccessTo(APPROVE_SITE_PAYMENTS) && (
          <MenuItem
            onClick={() => {
              openMarkAsPaidModal([row.itemSiteId], applyFilter);
              handleCloseMenu();
            }}
            disabled={!markAsPaidEnabled}
          >
            Mark as Paid
          </MenuItem>
        )}
        {userHasAccessTo(MANAGE_SITE_PAYMENTS) && (
          <MenuItem
            onClick={() => {
              navigate('/site-payments/site-credit-application', {
                state: { row }
              });
              handleCloseMenu();
            }}
            disabled={!applyCreditEnabled}
          >
            Apply Credit
          </MenuItem>
        )}
        {userHasAccessTo(MANAGE_SITE_PAYMENTS) && (
          <MenuItem
            onClick={() => {
              openCreditApplicationHistory(row);
              handleCloseMenu();
            }}
            disabled={!applicationHistoryEnabled}
          >
            Credit Application History
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export const openCreditApplicationHistory = row => {
  ModalBoxes.open({
    component: <CreditApplicationHistoryModal row={row} />,
    className: 'deposit-history-modal',
    size: 'w1250'
  });
};
