import React, { useContext } from 'react';
import { isEmpty } from 'lodash/lang';

import Chip, { ChipLabel } from '../../HomePageDashboards/Widgets/common/Chip';

import { SchedulingWorklistContext } from './SchedulingWorklistFiltersContext';
import { getNames } from './SchedulingWorklistService';

import './SchedulingWorklist.scss';

export default function SchedulingWorklistChips() {
  const Context = useContext(SchedulingWorklistContext);
  const { allStudies, allSites, filter, resetStudies, resetSites, resetEncounters } = Context;

  const getDateChipsValues = idx => {
    if (idx === 0) {
      return filter.dateRangeStart;
    } else {
      return filter.dateRangeEnd;
    }
  };
  return (
    <div className="pr-3 chips-container">
      <ChipLabel label="Appointment Status" value={filter.appointmentStatus.name} />
      {allStudies.length === 1 && <ChipLabel label="Study" value={allStudies[0].name} />}
      {allSites.length === 1 && <ChipLabel label="Site" value={allSites[0].name} />}
      {allStudies.length > 1 && (
        <ChipInstance
          label="Study"
          originalItems={filter.studies}
          values={getNames(filter.selectedStudies)}
          onClose={resetStudies}
        />
      )}
      {allSites.length > 1 && (
        <ChipInstance
          label="Site"
          originalItems={filter.sites}
          values={getNames(filter.selectedSites)}
          onClose={resetSites}
        />
      )}
      <ChipInstance
        label="Encounter"
        originalItems={filter.encounters}
        values={getNames(filter.selectedEncounters)}
        onClose={resetEncounters}
      />

      <ChipLabel label="# Appointments Per Person" value={filter.encountersPerPerson.name} />
      {['Start Date', 'End Date'].map((labelText, idx) => (
        <ChipLabel key={idx} label={labelText} value={getDateChipsValues(idx)} />
      ))}
    </div>
  );
}

function ChipInstance({ label, originalItems, values, onClose }) {
  return (
    <>
      {!isEmpty(values) && originalItems.length >= 1 && (
        <Chip label={label} values={values} onClose={onClose} originalItems={originalItems} />
      )}
    </>
  );
}
