import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { isArray } from 'lodash';
import { uniq } from 'lodash/array';
import { orderBy } from 'lodash/collection';
import { isEqual } from 'lodash/lang';
import { get } from 'lodash/object';
import moment from 'moment';

import { TASKS_TYPES } from '../../../../../root/Container/Layout/Tasks/Task/taskConstants';
import {
  APPOINTMENT_TYPES,
  MILESTONE_TYPES,
  PATIENT_ENCOUNTER_EVENT,
  PATIENT_SITUATIONAL_ENCOUNTER_EVENT,
  PATIENT_UNEXPECTED_ENCOUNTER_EVENT
} from '../../../CalendarEventType';
import { composePatientName, composePatientNameFromTask } from '../../../CalendarUtils';
import EventTypeIndicator from '../EventTypeIndicator';

import './Agenda.scss';

export function Agenda({ onSelectEvent, events }, { selectedEvent, displayedDate }) {
  const [dates, setDates] = useState([]);
  const [eventsFromToday, setEventsFromToday] = useState([]);

  useEffect(() => {
    let filteredEvents = orderByDate(
      events.filter(e => moment(e.start).isSameOrAfter(moment(displayedDate).startOf('d'), 'm'))
    );
    !isEqual(filteredEvents, eventsFromToday) && setEventsFromToday(filteredEvents);
  }, [events, displayedDate, eventsFromToday]);

  useEffect(() => {
    if (eventsFromToday) {
      const allDates = uniq(
        eventsFromToday.map(e =>
          moment(e.start)
            .startOf('d')
            .toString()
        )
      );
      !isEqual(dates, allDates) && setDates(allDates);
    } else {
      setDates([]);
    }
  }, [dates, eventsFromToday]);

  const filterByDate = (e, date) => {
    return moment(e.start)
      .startOf('d')
      .isSame(moment(date).startOf('d'));
  };

  const milestones = orderBy(
    eventsFromToday.filter(e => MILESTONE_TYPES.includes(e.type)),
    ['subject', 'id']
  );
  const tasks = orderBy(
    eventsFromToday.filter(e => TASKS_TYPES.includes(e.type)),
    ['subject', 'calendarId']
  );
  const appointments = eventsFromToday.filter(e => APPOINTMENT_TYPES.includes(e.type));

  return (
    <div className="agenda-container">
      {isArray(dates) &&
        dates.map(date => (
          <div className="agenda-event pt-3 px-3" key={date}>
            <div className="event-header">
              <div className={cx('time', { today: moment(date).isSame(moment(), 'day') })}>
                <span className={'day'}>{moment(date).format('DD')}</span>
                {moment(date).format('MMM, ddd')}
              </div>
            </div>
            <div className="events">
              {milestones
                .filter(e => filterByDate(e, date))
                .map((event, idx) => (
                  <AgendaEvent event={event} key={idx} selected={selectedEvent} onSelectEvent={onSelectEvent} />
                ))}
              {tasks
                .filter(e => filterByDate(e, date))
                .map((event, idx) => (
                  <AgendaEvent event={event} key={idx} selected={selectedEvent} onSelectEvent={onSelectEvent} />
                ))}
              {appointments
                .filter(e => filterByDate(e, date))
                .map((event, idx) => (
                  <AgendaEvent event={event} key={idx} selected={selectedEvent} onSelectEvent={onSelectEvent} />
                ))}
            </div>
          </div>
        ))}
    </div>
  );
}

export function getEventTimeRangeString(event) {
  if ([...MILESTONE_TYPES, ...TASKS_TYPES].includes(event.type)) {
    return 'All Day';
  }
  const start = moment(event.start);
  const end = moment(event.end);
  const isSamePartOfDay = start.clone().format('A') === end.clone().format('A');
  return isSamePartOfDay
    ? `${start.clone().format('hh:mm')} - ${end.clone().format('hh:mm A')}`
    : `${start.clone().format('hh:mm A')} - ${end.clone().format('hh:mm A')}`;
}

function AgendaEvent({ event, selected, onSelectEvent }) {
  return (
    <div
      className={cx('agenda-event-desc', {
        selected: selected && selected.id === event.id && selected.calendarId === event.calendarId
      })}
      onClick={() => onSelectEvent(event)}
    >
      <div className="time-range">
        <span className={cx('text pl-3', { canceled: event.canceled })}>{getEventTimeRangeString(event)}</span>
      </div>
      <div className="details-container">
        <div className="title">
          <EventTypeIndicator event={event} />
          <strong className="text pl-3">
            {get(event, 'subject')}
            {TASKS_TYPES.includes(event.type) && `: ${get(event, 'summary')}`}
          </strong>
        </div>
        <div className="pt-2">
          <div className="text pr-3">{get(event, 'studySite.study.name') || get(event, 'studyName')}</div>
          <div className="text pr-3">{get(event, 'studySite.site.name') || get(event, 'siteName')}</div>
          <div className="text pr-3">
            {[
              PATIENT_ENCOUNTER_EVENT,
              PATIENT_UNEXPECTED_ENCOUNTER_EVENT,
              PATIENT_SITUATIONAL_ENCOUNTER_EVENT
            ].includes(event.type) &&
              composePatientName(event, get(event, 'patient'), get(event, 'patientSubjectId'), true)}
            {TASKS_TYPES.includes(event.type) &&
              composePatientNameFromTask(event, get(event, 'patientFullName'), get(event, 'patientSubjectId'), true)}
          </div>
        </div>
        <div className="comments pt-2">{event.comment}</div>
      </div>
    </div>
  );
}

const orderByDate = collection => {
  return collection.sort((a, b) => {
    if (moment(a.start).isBefore(moment(b.start))) {
      return -1;
    }
    if (moment(a.start).isAfter(moment(b.start))) {
      return 1;
    } else {
      return moment(a.lastUpdateDate).isBefore(moment(b.lastUpdateDate)) ? -1 : 1;
    }
  });
};
