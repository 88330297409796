export function definePatternById(id) {
  if (typeof id === 'string') {
    return {
      getPattern: origin => v => v[id] === origin[id],
      getId: item => item[id]
    };
  }
  if (Array.isArray(id)) {
    const [eventNumber, adjustmentSequence] = id;
    return {
      getPattern: origin => ({ eventNumber: id, adjustmentSequence: sequence }) =>
        id === origin[eventNumber] && sequence === origin[adjustmentSequence],
      getId: item => `${item[eventNumber]}.${item[adjustmentSequence]}`
    };
  }
}
