import axios from 'axios';

const ROOT = 'api/v1/finance/clients';

const FinClientApi = {
  findByEnabled(enabled) {
    return axios.get(`${ROOT}/enabled/${enabled}`);
  },
  viewUsage() {
    return axios.get(`${ROOT}/view-usage`);
  },
  find(id) {
    return axios.get(`${ROOT}/${id}`);
  },
  updateEnabled(enabled, id) {
    return axios.put(`${ROOT}/state/${enabled}/${id}`);
  },
  save(client) {
    return axios.post(ROOT, client);
  },
  update(client) {
    return axios.put(`${ROOT}/${client.id}`, client);
  }
};

export default FinClientApi;
