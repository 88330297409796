import React from 'react';
import { omit } from 'lodash/object';

import { afterburnerApi } from '../../../../../api/patient/AftrburnerApi';
import Input from '../../../../../common/data-entry/Input';
import Button from '../../../../../common/general/Button';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { SOMETHING_WENT_WRONG } from '../../../../../constants/notificationMessages';

const MappingAuthenticationTest = ({
  platformId,
  authenticationType,
  username,
  setUsername,
  password,
  setPassword,
  bearerKey,
  setBearerKey,
  createRequestBody
}) => {
  const onTestAuthentication = () => {
    const dataForTest = omit(createRequestBody(), [
      'platformUrlsConfiguration',
      'platformConfigurationEnabled',
      'platformName'
    ]);
    afterburnerApi
      .testAuthentication(dataForTest)
      .then(({ data }) => {
        if (data) {
          NotificationManager.success('UserName and Password are correct');
        } else {
          NotificationManager.error('Username and Password are incorrect');
        }
      })
      .catch(() => {
        NotificationManager.error(SOMETHING_WENT_WRONG);
      });
  };

  const selectInputsForTest = () => {
    switch (authenticationType) {
      case 'BASIC':
        return (
          <div className="authentication-wrapper">
            <Input
              className="endpoint-input"
              label="Username"
              type="text"
              validate={false}
              value={username}
              onChange={({ target }) => {
                if (platformId) {
                  setPassword('');
                }
                setUsername(target.value);
              }}
              disabled={false}
              required
            />
            <Input
              className="endpoint-input"
              label="Password"
              type="password"
              autocomplete="new-password"
              validate={false}
              value={password}
              onChange={({ target }) => setPassword(target.value)}
              onFocus={() => {
                if (platformId && password === '************') setPassword(null);
              }}
              disabled={false}
              required
              onActive={() => setPassword('')}
            />
          </div>
        );
      case 'BEARER':
        return (
          <div className="authentication-wrapper">
            <Input
              className="endpoint-input"
              label="Key"
              type="text"
              validate={false}
              value={bearerKey}
              onChange={({ target }) => setBearerKey(target.value)}
              onFocus={() => {
                if (platformId && password === '************') setPassword(null);
              }}
              disabled={false}
              required
            />
          </div>
        );
      default:
        return <span className="endpoint-label">Please Select Authentication Type</span>;
    }
  };
  return (
    <>
      {selectInputsForTest()}
      <Button
        className="test-auth-button"
        size="h40"
        priority="medium"
        onClick={onTestAuthentication}
        disabled={!authenticationType}
      >
        Test Authentication
      </Button>
    </>
  );
};

export default MappingAuthenticationTest;
