import React, { useImperativeHandle, useRef } from 'react';
import cx from 'classnames';
import { isFunction } from 'lodash/lang';
import { omit } from 'lodash/object';
import * as PropTypes from 'prop-types';

import RadioGroup from './_RadioGroup';

import '../InputSelectors.scss';
import './Radio.scss';
const Radio = React.forwardRef(function(props, ref) {
  const { checked, title, value, label, disabled, onChange: onChangeCallback, className, color } = props;
  const restProps = omit(props, 'value', 'label', 'disabled', 'onChange', 'className', ' title', 'color');
  const inputRef = useRef();
  useImperativeHandle(ref, () => inputRef.current);
  return (
    <label
      title={title}
      className={cx('eui-input-selectors eui-radio-button', className, {
        'eui-input-selectors-without-label': !label,
        'eui-input-selector-disabled': disabled
      })}
      style={{ '--eui-input-color': color }}
      key={value}
    >
      <input
        ref={inputRef}
        {...restProps}
        checked={checked}
        type="radio"
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      <span>
        <em />
        {label}
      </span>
    </label>
  );

  function onChange(e) {
    e.persist();
    if (isFunction(onChangeCallback)) {
      onChangeCallback(e);
    }
  }
});
Radio.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  title: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string
};
Radio.isRadio = true;
Radio.Group = RadioGroup;
export default Radio;
