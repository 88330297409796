import axios from 'axios';

const ROOT = 'api/v1/ssu-patients';

const PatientStatusJourneyApi = {
  getStatusJourneyInfo(ssuPatientId) {
    return axios.get(`${ROOT}/${ssuPatientId}/status-journey`, { hideLoader: true });
  },

  getCurrentPatientStatusType(ssuPatientId) {
    return axios.get(`${ROOT}/${ssuPatientId}/status-journey/type`, { hideLoader: true });
  }
};

export default PatientStatusJourneyApi;
