import { uniqBy } from 'lodash/array';

import { ParticipantApi } from '../../../api';
import {
  ROLE_OPERATION_MANAGER,
  ROLE_OPERATIONS_ANALYST,
  ROLE_PATIENT_LIAISON,
  ROLE_PATIENT_REPRESENTATIVE,
  ROLE_PRINCIPAL_INVESTIGATOR,
  ROLE_SITE_COORDINATOR,
  ROLE_SITE_FINANCIAL_COORDINATOR,
  ROLE_STUDY_LEAD,
  ROLE_STUDY_MANAGER,
  ROLE_SUB_INVESTIGATOR
} from '../../../constants/userRoles';
import { userHasRole } from '../../../services/auth';

import { NON_PATIENT_EVENT } from './CalendarEventType';
import { isDraft } from './DraftEventProvider';

export const refreshParticipants = (
  personnelIdentifier,
  appointment,
  onChangeParticipants,
  isSituationalEncounter = false
) => {
  const encounterId = appointment.encounter?.id;
  const isAppointmentDraft = isDraft(appointment);
  const sitePatientId = appointment.study?.sitePatientId;

  if (isAppointmentDraft) {
    const promises = [];
    let updatedParticipants = [...appointment.participants];

    if (!isSituationalEncounter && sitePatientId && encounterId) {
      promises.push(ParticipantApi.getPrimaryStudyManager(sitePatientId, encounterId));
    } else {
      promises.push(Promise.resolve());
    }
    if (personnelIdentifier && isUserCanBeAsAttendee()) {
      promises.push(ParticipantApi.getOrganizerByPersonnelId(personnelIdentifier));
    } else {
      promises.push(Promise.resolve());
    }
    Promise.all(promises).then(([res1, res2]) => {
      const sm = res1?.data;
      const organizer = res2?.data;
      if (sm) {
        updatedParticipants.push(sm);
      }
      if (organizer) {
        if (appointment.type === NON_PATIENT_EVENT && userHasRole(ROLE_PATIENT_REPRESENTATIVE)) {
          updatedParticipants = updatedParticipants?.filter(p => p?.userId !== organizer?.userId);
        } else {
          updatedParticipants.push(organizer);
        }
      }
      onChangeParticipants(uniqBy(updatedParticipants, 'userId'));
    });
  }
};

const isUserCanBeAsAttendee = () => {
  return userHasRole([
    ROLE_STUDY_LEAD,
    ROLE_OPERATION_MANAGER,
    ROLE_STUDY_MANAGER,
    ROLE_PRINCIPAL_INVESTIGATOR,
    ROLE_SUB_INVESTIGATOR,
    ROLE_PATIENT_REPRESENTATIVE,
    ROLE_PATIENT_LIAISON,
    ROLE_OPERATIONS_ANALYST,
    ROLE_SITE_COORDINATOR,
    ROLE_SITE_FINANCIAL_COORDINATOR
  ]);
};
