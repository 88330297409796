import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import { FormBuilderApi } from '../../../../../api';
import common from '../../../../../common/common';
import Icon from '../../../../../common/general/Icon';
import { getChildParentsCountMap } from '../../../patient-source/shared/EncounterDescNew/ConditionalLogicHelperService';
import GenerateHTML from '../GenerateHtml/GenerateHtml';

const ItemGroupFormPreview = props => {
  const [inputValuesMap, setInputValuesMap] = useState({});
  const [conditionalLogic, setConditionalLogic] = useState();

  useEffect(() => {
    if (!conditionalLogic) {
      FormBuilderApi.getStandardConditionalLogicForDomain(props.formData.domainIdentifier).then(({ data }) => {
        setConditionalLogic(data);
      });
    }
  }, [conditionalLogic, props.formData.domainIdentifier]);

  const renderForm = () => {
    const formValidationRules = {};

    const allCheckedItems = props.formData.labelList.filter(e => e.isChecked).map(e => e.name);

    if (conditionalLogic) {
      Object.keys({ ...conditionalLogic }).forEach(key => {
        if (allCheckedItems.includes(key)) {
          formValidationRules[key] = conditionalLogic[key];
        }
      });
    }

    Object.keys(formValidationRules).forEach(key => {
      const rule = formValidationRules[key];
      rule.targetIdentifiersMap = rule.fieldsToHide;
      rule.sourceIdentifier = key;
    });

    props.formData.labelList
      .filter(e => e.fieldConfigurationResponse)
      .forEach(e => {
        const parent = e.fieldConfigurationResponse?.conditionField?.question;
        const child = e.question;

        if (formValidationRules[parent] && formValidationRules[parent].targetIdentifiersMap) {
          formValidationRules[parent].targetIdentifiersMap = [
            ...formValidationRules[parent].targetIdentifiersMap,
            child
          ];
          formValidationRules[parent].sourceIdentifier = parent;
        } else {
          formValidationRules[parent] = {
            targetIdentifiersMap: [child],
            sourceIdentifier: parent
          };
        }
      });

    const childParentsCountMap = getChildParentsCountMap(formValidationRules);
    const allParents = Object.keys(formValidationRules);

    return (
      <React.Fragment>
        {props.formData &&
          props.formData.labelList &&
          props.formData.labelList.length > 0 &&
          common
            .sortByOrder(props.formData.labelList, 'ASC')
            .filter(
              item =>
                item.isChecked &&
                item.isChecked === true &&
                validateIfRowIsVisible(item, formValidationRules, inputValuesMap)
            )
            .map((item, idx) => {
              return (
                <div key={idx} className={'item-group-field-row'}>
                  {item.inputType !== 'reminder' && item.inputType !== 'checkbox' && (
                    <label
                      className={cx('d-flex', props.dynamicClassesForFieldName(item))}
                      style={{
                        paddingLeft: childParentsCountMap[item.question]
                          ? childParentsCountMap[item.question] * 24 + 'px'
                          : 0,
                        fontWeight: allParents.includes(item.question) ? 'bold' : 'normal'
                      }}
                    >
                      {item.investigatorField === true && (
                        <Icon className={'investigator-field-icon'}>medical_services</Icon>
                      )}
                      {item.updatedQuestion ? item.updatedQuestion : item.question}
                    </label>
                  )}
                  <GenerateHTML
                    labelText={item.label}
                    data={item}
                    forPreview={true}
                    key={item.key}
                    formId={props.formData.formId}
                    formLabelId={props.formData.formLableId}
                    customClassObject={props.customClassObject}
                    itemDefIndex={idx}
                    onChangeRadioHandler={(value, question, decodedVal) => {
                      item.inputValue = value;
                      setInputValuesMap(prevState => ({
                        ...prevState,
                        [question.question]: decodedVal
                      }));
                      resetConditionalQuestions(item, props.formData.labelList, formValidationRules, decodedVal);
                    }}
                    fromWhichPage="popUp"
                  />
                </div>
              );
            })}
      </React.Fragment>
    );
  };

  const validateIfRowIsVisible = (item, formValidationRules) => {
    if (formValidationRules) {
      for (const [key, value] of Object.entries(formValidationRules)) {
        const answer = inputValuesMap[key];
        if (answer && value.hideWhenAnswer === answer) {
          if (value?.fieldsToHide?.includes(item.question) || value?.fieldsToHide?.includes(item.name)) {
            return false;
          }
        }
        if (!answer) {
          if (value?.fieldsToHide?.includes(item.question) || value?.fieldsToHide?.includes(item.name)) {
            return false;
          }
        }
      }
    }
    if (!isEmpty(item.fieldConfigurationResponse)) {
      const answer = inputValuesMap[item.fieldConfigurationResponse?.conditionField?.question];
      const codeAnswer = item.fieldConfigurationResponse?.conditionField?.codeDefinationList.find(
        e => e.decode === answer
      );
      if (codeAnswer && codeAnswer.name === item.fieldConfigurationResponse.conditionFieldAnswerName) {
        return false;
      }
      if (!codeAnswer) {
        return false;
      }
    }
    return true;
  };

  const resetConditionalQuestions = (parentItem, labelList, formValidationRules, decodeValOfParent) => {
    const codeAnswer = parentItem.fieldConfigurationResponse?.conditionField?.codeDefinationList.find(
      e => e.decode === decodeValOfParent
    );
    formValidationRules[parentItem.question]?.targetIdentifiersMap.forEach(identifier => {
      const childItemToReset = labelList.find(label => label.question === identifier && label.inputType === 'radio');
      if (childItemToReset) {
        if (
          !codeAnswer ||
          (codeAnswer && codeAnswer.name === childItemToReset.fieldConfigurationResponse.conditionFieldAnswerName)
        ) {
          setInputValuesMap(prevState => {
            const updatedState = { ...prevState };
            delete updatedState[identifier];
            return updatedState;
          });
          childItemToReset.inputValue = 0;
          resetConditionalQuestions(childItemToReset, labelList, formValidationRules);
        }
      }
    });
  };

  return renderForm();
};

export default ItemGroupFormPreview;
