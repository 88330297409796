import React from 'react';
import { Box } from '@mui/material';

import { Amount } from '../../../../../finance/shared/amount-view/Amount/Amount';

export const ExpenseVariableAmountSection = ({ sx, amount, maximumEventAmount, totalAmount, variance }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-around"
      sx={{
        '.eds-amount': {
          fontWeight: 600
        },
        ...sx
      }}
    >
      <Box data-testid="amount">
        <span>Amount</span>
        <Amount coinsAmount={amount} showDollarSign={true} />
      </Box>
      <Box data-testid="max-amount">
        <span>Maximum</span>
        <Amount coinsAmount={maximumEventAmount} showDollarSign={true} defaultZeroValue={'N/A'} />
      </Box>
      {maximumEventAmount === 0 ? (
        <Box data-testid="total-amount">
          <span>Total Amount</span>
          <Amount coinsAmount={totalAmount} showDollarSign={true} />
        </Box>
      ) : (
        <Box data-testid="variance">
          <span>Variance</span>
          <Amount coinsAmount={variance} showDollarSign={true} />
        </Box>
      )}
    </Box>
  );
};
