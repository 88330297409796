import React, { useState } from 'react';
import { isEqual } from 'lodash/lang';

import { StudySiteApi } from '../../../../../../api';
import Checkbox from '../../../../../../common/data-entry/InputSelectors/Checkbox';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../common/general/ButtonGroup';
import { ROLE_SYSTEM_ADMINISTRATOR } from '../../../../../../constants/userRoles';
import { userHasRole } from '../../../../../../services/auth';

import { findCorrespondingLockNameForMetric, MAX_INPUT_VALUE, METRICS_NAMES, METRICS_VALUES_LIST } from './constants';

import './UpdateMetricsModal.scss';

const UpdateMetricsModal = ({ modalBox, initialMetrics, studySiteId }) => {
  const [metrics, setMetrics] = useState(initialMetrics);

  const handleLockChange = lockMetricName => {
    const prevLockValue = metrics[lockMetricName];
    setMetrics({ ...metrics, [lockMetricName]: !prevLockValue });
  };

  const handleValueChange = (metric, newValue) => {
    if (newValue === '') {
      setMetrics({ ...metrics, [metric]: newValue });
    } else if (/^\d+$/.test(newValue)) {
      const intValue = parseInt(newValue, 10);
      if (intValue < MAX_INPUT_VALUE / 10) {
        setMetrics({ ...metrics, [metric]: intValue });
      }
    }
  };

  const handleCancel = () => {
    if (!isEqual(metrics, initialMetrics)) {
      ModalBoxes.confirm({
        content: 'You have unsaved changes, are you sure you want to cancel?',
        title: 'Discard Unsaved Changes',
        confirmButton: 'Yes',
        cancelButton: 'No'
      }).then(modalBox.close);
    } else {
      modalBox.close();
    }
  };

  const handleUpdate = () => {
    StudySiteApi.saveOrUpdateMetrics(studySiteId, metrics).then(modalBox.close);
  };

  return (
    <>
      <ModalBoxes.Header onClose={handleCancel}>Update Expected SSU Success Metrics</ModalBoxes.Header>
      <ModalBoxes.Body>
        {METRICS_VALUES_LIST.map(metric => (
          <InputWithCheckBox
            key={metric}
            name={METRICS_NAMES[metric]}
            value={metrics[metric]}
            isLocked={metrics[findCorrespondingLockNameForMetric(metric)]}
            onLockChange={() => handleLockChange(findCorrespondingLockNameForMetric(metric))}
            onValueChange={e => handleValueChange(metric, e.target.value)}
          />
        ))}
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <div className="eds-btn-group">
          <ButtonGroup>
            <Button priority="medium" onClick={handleCancel}>
              Cancel
            </Button>
            <Button priority="high" onClick={handleUpdate}>
              Update
            </Button>
          </ButtonGroup>
        </div>
      </ModalBoxes.Footer>
    </>
  );
};

const InputWithCheckBox = ({ name, value, isLocked, onValueChange, onLockChange }) => {
  return (
    <div className="form-group">
      <label className="metric-name">{name}</label>
      <div className="input-group">
        <input type="text" className="form-control" value={value || ''} onChange={onValueChange} disabled={isLocked} />
        <Checkbox
          className="lock-check-box"
          label="Locked"
          onChange={onLockChange}
          checked={isLocked}
          disabled={isLocked && !userHasRole(ROLE_SYSTEM_ADMINISTRATOR)}
        />
      </div>
    </div>
  );
};

UpdateMetricsModal.className = 'update-success-metrics-modal';
UpdateMetricsModal.size = 'w500';

export default UpdateMetricsModal;
