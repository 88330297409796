import React, { Component } from 'react';
import cx from 'classnames';
import { cloneDeep } from 'lodash';
import { isEqual, isFunction } from 'lodash/lang';
import { get } from 'lodash/object';
import * as PropTypes from 'prop-types';

import IconButton from '../../../../../common/buttons/IconButton';
import Checkbox from '../../../../../common/data-entry/InputSelectors/Checkbox';
import Select from '../../../../../common/data-entry/Select';
import useMobileDevice from '../../../../../common/hooks/useMobileDevice';
import { scGray50 } from '../../../../../constants/systemColors';
import { ColorsRegistry } from '../../ColorHashPalette';

import './UserCalendars.scss';

export class UserCalendars extends Component {
  static propTypes = {
    onCalendarsChange: PropTypes.func,
    initialCalendars: PropTypes.array,
    calendars: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.state = {
      calendars: []
    };
  }

  componentDidMount() {
    this.initialize();
    ColorsRegistry().initEmptyRegistry();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const isCalendarHasChanged = isEqual(
      prevProps.initialCalendars.map(c => c.id).sort(),
      this.props.initialCalendars.map(c => c.id).sort()
    );
    if (!(prevProps.calendars === this.props.calendars && isCalendarHasChanged)) {
      this.initialize();
    }
  }

  initialize() {
    let calendars = [];

    if (this.props.calendars) {
      calendars = this.props.calendars.map(it => {
        it.group = 'calendars';
        const presentInInitials = this.props.initialCalendars && this.props.initialCalendars.find(c => c.id === it.id);
        if (presentInInitials) {
          it.selected = presentInInitials.selected;
          it.checked = presentInInitials.checked;
          it.selectedOrder = presentInInitials.selectedOrder || new Date().getTime();
        } else {
          it.selected = false;
          it.checked = false;
          it.selectedOrder = -1;
        }
        return it;
      });
    }
    this.setState({ calendars }, this.onChangeCallback);
  }

  removeItem = option => {
    if (option) {
      const originalDataSource = cloneDeep(this.state[option.group]);
      const el = originalDataSource.find(e => e.id.toString() === option.id.toString());

      if (el.selected !== false) {
        el.selected = false;
        el.checked = false;
        el.selectedOrder = -1;
        ColorsRegistry().removeColorById(option.id);
        this.setState({ [option.group]: originalDataSource }, this.onChangeCallback);
      }
    }
  };
  checkboxClick = option => {
    if (option) {
      const originalDataSource = cloneDeep(this.state[option.group]);
      const el = originalDataSource.find(e => e.id.toString() === option.id.toString());
      el.checked = !el.checked;
      this.setState({ [option.group]: originalDataSource }, this.onChangeCallback);
    }
  };

  onChangeCallback = () => {
    this.state.calendars &&
      this.state.calendars.length > 0 &&
      isFunction(this.props.onCalendarsChange) &&
      this.props.onCalendarsChange(this.state.calendars.filter(e => e.selected));
  };

  onChange = option => {
    if (option) {
      const originalDataSource = cloneDeep(this.state[option.group]);
      const el = originalDataSource.find(e => e.id.toString() === option.id.toString());
      if (el.selected !== true) {
        el.selected = true;
        el.checked = true;
        el.selectedOrder = new Date().getTime();
      }
      this.setState({ [option.group]: originalDataSource }, this.onChangeCallback);
    }
  };

  render({ state } = this) {
    const data = [...state.calendars].filter(e => !e.selected);
    const selectedItems = state.calendars
      .filter(e => e.selected)
      .sort((a, b) => (a.selectedOrder > b.selectedOrder ? 1 : -1))
      .map(c => ({ ...c, color: ColorsRegistry().getColorById(c.id) }));
    return (
      <div className="eds-select-with-search">
        <Select
          dataSource={data}
          placeholder="Search Users, Sites, & Studies"
          className="mx-3"
          groupBy="type"
          searchable={true}
          clearable={false}
          clearSearchOnSelection={true}
          closeOnSelectedOptionClick={false}
          deselectOnSelectedOptionClick={false}
          clearSelectedValueOnDataSourceChange={true}
          onChange={option => {
            isFunction(this.onChange) && this.onChange(option);
          }}
          customSelectedValueTemplateFunction={() => 'Search'}
          customOptionTemplateFunction={item => <UserInfo className="pt-2 pb-1" item={item} />}
        />
        <div className="selected-groups-container">
          <Group checkboxClick={this.checkboxClick} items={selectedItems} removeItem={this.removeItem} />
        </div>
      </div>
    );
  }
}

export default UserCalendars;

function UserInfo({ item, className }) {
  return (
    <div className={cx('user-info', className)}>
      <div className="label">{item.label}</div>
      <div className="name">{item.name}</div>
    </div>
  );
}

function Group({ title, items, checkboxClick, removeItem, groupDisabled = false }) {
  const isMobile = useMobileDevice();
  return (
    <div className="selected-group" style={{ display: items.length > 0 ? 'block' : 'none' }}>
      <div className="group-label">{title}</div>
      {items.map((item, key) => (
        <Item
          items={items}
          key={key}
          item={item}
          checkboxClick={checkboxClick}
          removeItem={removeItem}
          isGrayOut={groupDisabled}
          isMobile={isMobile}
        />
      ))}
    </div>
  );
}

function Item({ item, items, checkboxClick, removeItem, isGrayOut, isMobile }) {
  return (
    <div className={cx('group-item', { mobile: isMobile })}>
      <div className="check-box-label">
        <Checkbox
          onChange={() => checkboxClick(item, items)}
          color={isGrayOut ? scGray50 : get(item, 'color.main', '')}
          checked={item.checked}
        />
        <UserInfo className="pl-3" item={item} />
        <div className="button-container">
          <IconButton color={'grey'} onClick={() => removeItem(item)}>
            cancel
          </IconButton>
        </div>
      </div>
    </div>
  );
}
