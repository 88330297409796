import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import IconButton from '../IconButton';

import './RoundIconButton.scss';

export default function RoundIconButton(props) {
  const { className, ...restProps } = props;
  return (
    <IconButton className={cx(`eds-round-icon-btn`, className)} {...restProps}>
      {props.children}
    </IconButton>
  );
}

RoundIconButton.propTypes = {
  title: PropTypes.string,
  style: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.any,
  color: PropTypes.oneOf(['blue']),
  suit: PropTypes.oneOf(['glyphicon', 'material'])
};
