import axios from 'axios';

const ROOT = 'api/v1';

const StudyProtocolApi = {
  findAllWithoutProtocol() {
    return axios.get(`${ROOT}/studies/without/protocol`);
  },
  copy(protocolIdentifier, fromStudyIdentifier, toStudyIdentifier) {
    return axios.post(
      `${ROOT}/protocol/${protocolIdentifier}/copy/from/${fromStudyIdentifier}/to/${toStudyIdentifier}`
    );
  }
};

export default StudyProtocolApi;
