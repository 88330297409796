import React, { useContext } from 'react';
import { reject } from 'lodash';
import moment from 'moment';

import { defaultEventIdViewAndSort } from '../../../../../constants/financeEventIdView';
import { Amount } from '../../shared/amount-view/Amount/Amount';
import { getVisibleItems } from '../../shared/tableVisibleOnPageElement';
import { TableWithCheckbox } from '../../shared/TableWithCheckbox/TableWithCheckbox';
import { SiteBillContext } from '../SiteBillContext';

import './SiteBillTable.scss';

const cellWithLongText = {
  Cell: ({ value }) => {
    return (
      <span className="ft-long-text" title={value}>
        {value}
      </span>
    );
  }
};
export function SiteBillTable() {
  const { tableData = [], checkedForBill, setCheckedForBill, withholding } = useContext(SiteBillContext);
  const columns = getColumn(withholding);
  return (
    <div className="eds-site-bill-table-layout">
      <TableWithCheckbox
        setCheckedForCreate={setCheckedForBill}
        id={'id'}
        alreadyCheckedList={checkedForBill}
        number={'billNumber'}
        className="eds-site-bill-table"
        onChange={changeBilled}
        showCheckBox={(origin, number) => !origin[number]}
        data={tableData}
        getFilteredItems={getValidSiteBillItems}
        columns={columns}
      />
    </div>
  );

  function getValidSiteBillItems(tableRef) {
    return (
      tableRef &&
      getVisibleItems(tableRef.current)
        ?.map(({ _original }) => _original)
        ?.filter(item => {
          return !item['billNumber'];
        })
    );
  }

  function changeBilled({ target: { checked, value } }) {
    const eventWhatIsNeeded = ({ id: ledgerEventId }) => ledgerEventId === value;
    setCheckedForBill(checkedForBill => {
      if (checked) {
        return [...checkedForBill, tableData?.find(eventWhatIsNeeded)];
      } else {
        return reject(checkedForBill, ['id', value]);
      }
    });
  }
}

function getColumn(withholding) {
  return [
    {
      Header: 'Event ID',
      id: 'eventId',
      width: 100,
      className: 'ft-center ft-numbers',
      headerClassName: 'ft-center',
      accessor: ({ eventNumber, adjustmentSequence }) => {
        return {
          id: eventNumber,
          sequence: adjustmentSequence
        };
      },
      ...defaultEventIdViewAndSort
    },
    {
      Header: 'Study',
      accessor: 'studyName',
      width: 175,
      ...cellWithLongText
    },
    {
      Header: 'PCN',
      accessor: 'pcn',
      width: 100,
      ...cellWithLongText
    },
    {
      Header: 'Site',
      accessor: 'siteName',
      width: 175,
      ...cellWithLongText
    },
    {
      Header: 'Patient ID',
      accessor: 'patientId',
      ...cellWithLongText
    },
    {
      Header: 'Subject ID',
      accessor: 'subjectId',
      width: 110,
      ...cellWithLongText
    },
    {
      Header: 'Patient Status',
      accessor: 'patientStatus',
      width: 110,
      ...cellWithLongText
    },
    {
      Header: 'Encounter',
      accessor: 'encounterName',
      width: 170,
      ...cellWithLongText
    },
    {
      Header: 'Type',
      accessor: 'type',
      ...cellWithLongText
    },
    {
      Header: 'Event',
      accessor: 'eventName',
      width: 170,
      ...cellWithLongText
    },
    {
      Header: 'Name',
      accessor: 'name',
      width: 175,
      ...cellWithLongText
    },
    {
      Header: 'Investigator',
      accessor: 'principleInvestigatorName',
      width: 180,
      ...cellWithLongText
    },
    {
      Header: 'Event Date',
      id: 'eventDate',
      width: 110,
      accessor: ({ eventDate }) => (eventDate ? moment(eventDate) : moment(0)),
      Cell: ({ value, original: { eventDate } }) => {
        return eventDate ? value.format('DD/MMM/YYYY') : eventDate;
      }
    },
    {
      id: 'revenueDate',
      Header: 'Revenue Date',
      width: 110,
      accessor: ({ revenueDate }) => (revenueDate ? moment(revenueDate) : moment(0)),
      Cell: ({ value, original: { revenueDate } }) => (revenueDate ? value.format('DD/MMM/YYYY') : revenueDate)
    },
    {
      id: 'siteAmount',
      Header: 'Amount',
      accessor: row => {
        return !withholding ? row.withHoldingAmount : row.amount;
      },
      className: 'ft-end-leveling',
      headerClassName: 'ft-end-leveling',
      Cell: ({ value }) => {
        return <Amount coinsAmount={value} className="ft-numbers" />;
      },
      width: 100
    },
    {
      id: 'billNumber',
      Header: 'Bill #',
      className: 'ft-end-leveling',
      headerClassName: 'ft-end-leveling',
      width: 80,
      accessor: ({ billNumber }) => (billNumber ? billNumber : '')
    },
    {
      id: 'billingDate',
      Header: 'Bill Date',
      width: 110,
      accessor: ({ billingDate }) => (billingDate ? moment(billingDate) : moment(0)),
      Cell: ({ value, original: { billingDate } }) => (billingDate ? value.format('DD/MMM/YYYY') : billingDate)
    }
  ];
}
