import { useMemo, useState } from 'react';
import { MenuItem, Select } from '@mui/material';

import ReimbursementWorklistApi from '../../../../../api/patient/ReimbursementWorklistApi';
import { onRequestError } from '../../../../../services/handlers';
import {
  RBS_APPROVED,
  RBS_DENIED,
  RBS_PENDING,
  reimbursementStatuses,
  reimbursementStatusesMap
} from '../../../patient-source/Patients/PatientInfo/EncountersSection/PaymentsSection/reimbursementConstants';

export default function StatusChangeDropdown({ row, onChangeSuccess }) {
  const { id: reimbursementId, totalAmount } = row;
  const [saving, setSaving] = useState(false);

  const allowedStatuses = useMemo(
    function() {
      return reimbursementStatuses
        .filter(function({ id }) {
          return [RBS_APPROVED, RBS_DENIED].includes(id);
        })
        .map(function(value) {
          return prepareStatus({ ...value });
        });
      function prepareStatus(value) {
        if ((value.id === RBS_APPROVED || value.id === RBS_DENIED) && !row.canBeApprovedOrDenied) {
          value.disabled = true;
        }
        return value;
      }
    },
    [row]
  );

  return (
    <Select
      value={RBS_PENDING}
      disableUnderline={true}
      size="small"
      variant="standard"
      disabled={saving}
      fullWidth
      renderValue={value => reimbursementStatusesMap[value]}
      onChange={reimbursementStatusOnChange}
    >
      {allowedStatuses.map(e => (
        <MenuItem key={e.id} value={e.id} disabled={e.disabled}>
          {e.name}
        </MenuItem>
      ))}
    </Select>
  );
  function reimbursementStatusOnChange({ target }) {
    const status = target.value;

    setSaving(true);
    ReimbursementWorklistApi.updateWorklistRow(reimbursementId, {
      status,
      totalAmount
    })
      .then(function({ data }) {
        onChangeSuccess(data);
      })
      .catch(onRequestError)
      .finally(() => setSaving(false));
  }
}
