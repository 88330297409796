import { EventEmitter } from 'events';

export const LoadingOverlayEventEmitter = new EventEmitter();

let numberOfCallsInPending = 0;
let previousValue = false;
let timeoutId;
let lastTime = 0;

export function interceptorHandler(config, n) {
  if (!config?.hideLoader) {
    memoizedHandlerWithDebounce((numberOfCallsInPending += n) > 0);
  }
}

function memoizedHandlerWithDebounce(inPending) {
  if (inPending !== previousValue) {
    previousValue = inPending;

    const now = Date.now();

    if (inPending) {
      if (now - lastTime >= 400) {
        triggerChange(inPending);
        lastTime = now;
      }
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
    } else {
      timeoutId = setTimeout(() => {
        triggerChange(inPending);
        timeoutId = null;
      }, 400);
    }
  }
}

function triggerChange(loading) {
  LoadingOverlayEventEmitter.emit('set-loading', { manual: false, loading });
  if (!loading) {
    numberOfCallsInPending = 0;
  }
}
