import { first } from 'lodash/array';
import { map, orderBy, sortBy } from 'lodash/collection';
import { isArray } from 'lodash/lang';
import { defaults, get, has } from 'lodash/object';
import moment from 'moment';

import { FILE_TYPES } from '../../../../../constants/inputTypes';
import { isReadOnlyField } from '../../../setup/shared/ElementSetupNew/itemGroupSetupService';

import { PS_ANSWERED, PS_NOT_ANSWERED } from './TableItemGroup/constants';

export function prepareFormDefinitionLabelList(labelList) {
  return (
    isArray(labelList) &&
    sortBy(collectLabelListDefinitions(labelList), label => +label.sequence)
      .map(label => {
        if (
          label.hasOwnProperty('codeDefinationList') &&
          get(label, 'codeDefinationList[0].inputType') === 'radio' &&
          (label.inputValue === '0' || label.inputValue === '')
        ) {
          label.inputValue = 0;
        } else if (label.hasOwnProperty('inputType') && isReadOnlyField(label.inputType)) {
          const defaultInputValue = label.inputType;
          label.inputValue = label.inputValue ? label.inputValue : defaultInputValue;
        } else {
          label.inputValue = '';
        }
        return label;
      })
      .filter(el => el.isChecked)
  );
}

export function generateJsonForSaveByFormDefinition(labelList, columnList) {
  return (
    isArray(labelList) &&
    labelList
      .filter(
        label =>
          (label.hasOwnProperty('inputType') && isReadOnlyField(label.inputType)) || label.cdashAliasName === 'AEYN'
      )
      .map(label => {
        const jsInfo = getSaveJSONInfo();
        jsInfo.answerStatus = 1;
        jsInfo.attributeType = label.inputType;
        jsInfo.investigatorField = label.investigatorField;
        jsInfo.attributeValue = label.inputValue;
        jsInfo.elementEncounterIdentifier = label.uniqueIdentifier;
        jsInfo.performedStatus = PS_ANSWERED;
        jsInfo.tableview = isArray(columnList) && columnList.length ? 1 : 0;
        return jsInfo;
      })
  );
}

export function getSaveJSONInfo() {
  return {
    answerStatus: 0,
    attributeValue: '',
    comments: '',
    elementEncounterIdentifier: '',
    meausrementvalue: '',
    performedStatus: 4,
    tableview: 0,
    fileList: []
  };
}

export function buildNewFileData(data, file) {
  return {
    content: data,
    type: file && file.originalFile.type,
    name: file && file.originalFile.name,
    isCertifyCopy: file.isCertifyCopy,
    isCertifyCopyConfirmed: !!(file.isCertifyCopy && file.isCertifyCopyConfirmed)
  };
}

export function prepareFormDefinitionLabelListWithFilledAnswersForQuestions(
  currentLabelList,
  currentFormDataRecord,
  currentFileList
) {
  const patientEncounterFieldResponse = get(currentFormDataRecord, 'pateintEncounterFieldResponse');

  if (!isArray(currentLabelList)) {
    return;
  }
  return currentLabelList.map(label => {
    const filledLabel = {};
    const field = get(patientEncounterFieldResponse, `[${label.uniqueIdentifier}]`),
      elementEncounterIdentifier = get(field, 'elementEncounterIdentifier'),
      attributeValue = get(field, 'attributeValue'),
      isMultiOne = get(field, 'isMultiOne'),
      multiValues = get(field, 'multiValues'),
      comments = get(field, 'comments', ''),
      performedStatus = get(field, 'performedStatus', PS_NOT_ANSWERED),
      inputType = get(label, has(label, 'codeDefinationList[0]') ? 'codeDefinationList[0].inputType' : 'inputType');

    filledLabel.measurementValue = get(field, 'meausrementvalue', '');

    if (FILE_TYPES.includes(inputType)) {
      filledLabel.inputValue = '';
      filledLabel.fileList = currentFileList?.filter(item => elementEncounterIdentifier === item.attributeIdentifier);
    } else if (inputType === 'multiselect' && isMultiOne && multiValues) {
      filledLabel.inputValue = JSON.stringify(multiValues);
    } else if (inputType === 'radio') {
      filledLabel.inputValue = attributeValue || 0;
    } else if (inputType === 'label') {
      filledLabel.inputValue = label.inputValue;
    } else {
      filledLabel.inputValue = field ? attributeValue : '';
    }

    filledLabel.ansComments = comments;
    filledLabel.ansStatus = performedStatus;

    return defaults(filledLabel, label);
  });
}

export function getInputsByFormDefinitionLabelList(labelList) {
  return map(labelList, field => {
    if (FILE_TYPES.includes(field.inputType)) {
      const file = { inputValue: field.inputValue, fileList: [], attributeType: 'file' };
      if (field.fileList) {
        file.fileList.push(...field.fileList);
      }
      return file;
    }
    return { inputValue: field.inputValue, attributeType: 'field' };
  });
}

export function getWhenAndWhoDidItFromLastFormDataRecord(formData) {
  return getWhenAndWhoDidItFromFormDataRecord(getLatestFormDataRecord(formData));
}

export function getWhenAndWhoDidItByFormGroupIdentifier(formData, formGroupIdentifier) {
  return getWhenAndWhoDidItFromFormDataRecord(getFormDataRecordByFormGroupIdentifier(formData, formGroupIdentifier));
}

export function getWhenAndWhoDidItFromFormDataRecord(formDataRecord) {
  const comment = get(formDataRecord, 'pateintEncounterFieldResponse.COVAL');
  return {
    whenDidIt: get(comment, 'whenDiditInCdash'),
    whoDidItUniqueIdentifier: get(comment, 'whoDiditInCdash'),
    additionNotes: get(comment, 'attributeValue')
  };
}

export function getFormDataRecordByFormGroupIdentifier(formData, formGroupIdentifier) {
  return formData.find(record => record.fromGroupIdentifier === formGroupIdentifier);
}

export function getLatestFormDataRecord(formData) {
  if (!isArray(formData)) {
    return;
  }
  return first(
    orderBy(
      formData.filter(item => get(item, 'pateintEncounterFieldResponse.COVAL')),
      [
        item => {
          return moment(get(item, 'pateintEncounterFieldResponse.COVAL.lastUpdateDate'));
        }
      ],
      ['desc']
    )
  );
}

function collectLabelListDefinitions(labelList) {
  return labelList.flatMap(item => {
    const itemDefinitionList = isArray(item.itemDefinitionList) ? item.itemDefinitionList : [];
    const customItemDefinitionList = isArray(item.customItemDefinationList) ? item.customItemDefinationList : [];
    return [...itemDefinitionList, ...customItemDefinitionList];
  });
}
