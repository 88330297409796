import React, { useEffect } from 'react';

import { SitePatientEncounterApi } from '../../../../../api';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { NavigationPanel } from '../../../../../common/fullScreenLayout/FullScreenLayout';
import {
  ROLE_FINANCE_ANALYST,
  ROLE_OPERATION_MANAGER,
  ROLE_OPERATIONS_ANALYST
} from '../../../../../constants/userRoles';
import { userHasRole } from '../../../../../services/auth';
import { useCurrentRoute } from '../../../../root/router';
import { isAbleToEditEncounter } from '../EncounterDescNew/encounterDetailsService';

import EdcVisitDateConfirmModal from './EdcVisitDateConfirmModal/EdcVisitDateConfirmModal';
import { ReviewContent } from './ReviewContent/ReviewContent';
import ReviewControl from './ReviewControl/ReviewControl';
import ReviewInfoHeader from './ReviewInfoHeader/ReviewInfoHeader';
import { ReviewSideBar } from './ReviewSideBar/ReviewSideBar';
import { ReviewType } from './reviewConstants';
import { ReviewProvider } from './ReviewContext';
import { getReviewType } from './reviewService';

import './ReviewPage.scss';

export function ReviewPageDEV() {
  const currentRoute = useCurrentRoute();

  const currentRouteName = currentRoute?.name;
  const currentRouteKey = currentRoute?.key;
  const ssuPatientId = currentRoute?.params?.ssuPatientId;
  const patientEncounterId = currentRoute?.params?.patientEncounterId;

  useEffect(() => {
    const reviewType = getReviewType({ name: currentRouteName, key: currentRouteKey });
    if (
      reviewType === ReviewType.SM &&
      !userHasRole([ROLE_OPERATION_MANAGER, ROLE_FINANCE_ANALYST, ROLE_OPERATIONS_ANALYST])
    ) {
      SitePatientEncounterApi.getSsuPatientEncounterMetaInfo(ssuPatientId, patientEncounterId).then(res => {
        const data = res.data;
        const isAbleToEditEdcVisitDate = isAbleToEditEncounter(data?.nonProtocol, data?.ssuStatus);
        if (isAbleToEditEdcVisitDate) {
          ModalBoxes.open({
            component: (
              <EdcVisitDateConfirmModal
                ssuPatientId={ssuPatientId}
                patientEncounterId={patientEncounterId}
                originEdcVisitDate={data?.edcVisitDate}
              />
            )
          });
        }
      });
    }
  }, [ssuPatientId, patientEncounterId, currentRouteName, currentRouteKey]);

  return (
    <ReviewProvider>
      <ReviewInfoHeader />
      <div className="erv-review-page">
        <div className="eds-review-page-container">
          <div className="eds-review-page-body">
            <NavigationPanel>
              <ReviewSideBar />
            </NavigationPanel>
            <div className="eds-main-content">
              <ReviewControl />
              <ReviewContent />
            </div>
          </div>
        </div>
      </div>
    </ReviewProvider>
  );
}
