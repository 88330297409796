import React from 'react';

export function dynamicClassesForFieldName(item) {
  if (item.inputType === 'textBlockLong') {
    return 'width100 overflow-ellipsis-multiple-line';
  }
  return 'item-group-field-label';
}
export function customOptionTerminologyVersion({ terminologyVersions }, style) {
  return (
    <div style={style}>
      {terminologyVersions?.map(({ type, version }, i) => {
        const name = type.replace('Terminology', '');
        return <span key={i}>{`${name} ${version}`} </span>;
      })}
    </div>
  );
}
