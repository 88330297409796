import { some } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { PROCESS_INTERRUPTED_BY_THE_USER } from '../../../../../../../constants/errorMessages';
import { FILE_TYPES } from '../../../../../../../constants/inputTypes';
import CertifyCopyModal from '../preSaveItemsModals/CertifyCopyModal';
import { collectAllQuestionsField } from '../services/fieldsServices';

export default function processCertifyCopy(encounterTableItemGroup) {
  return new Promise(function(resolve, reject) {
    const allFiles = collectAllQuestionsField(encounterTableItemGroup)
      .filter(field => FILE_TYPES.includes(field.fieldType) && !isEmpty(field?.fileList))
      .flatMap(field => field.fileList);

    if (isEmpty(allFiles) || !some(allFiles, file => file.isCertifyCopy)) {
      return resolve(encounterTableItemGroup);
    }

    const modalInstance = ModalBoxes.open({
      component: (
        <CertifyCopyModal
          filesForCertify={allFiles}
          onSave={function() {
            resolve(encounterTableItemGroup);
            modalInstance.close(true);
          }}
        />
      ),
      onClose(resolved) {
        if (resolved !== true) {
          reject(new Error(PROCESS_INTERRUPTED_BY_THE_USER));
        }
      }
    });
  });
}
