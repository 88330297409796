import axios from 'axios';

const ROOT = 'api/v1/access/studies';

const GroupAssignApi = {
  getProtocolEncounterGroupAssignConfiguration(studyId, protocolVersionId) {
    return axios.get(`${ROOT}/${studyId}/encounter-setup/${protocolVersionId}`, {
      hideLoader: true
    });
  },
  getGroupAssignConfiguration(studyId, protocolVersionId, groupAssignId) {
    return axios.get(`${ROOT}/${studyId}/groups-assign/${groupAssignId}/protocol/${protocolVersionId}`, {
      hideLoader: true
    });
  },
  saveGroupAssignConfiguration(studyId, protocolVersionId, groupAssignId, groupAssignSetup) {
    return axios.put(
      `${ROOT}/${studyId}/groups-assign/${groupAssignId}/protocol/${protocolVersionId}`,
      groupAssignSetup
    );
  },
  getInvestigatorGroupAssigns(studyId) {
    return axios.get(`${ROOT}/${studyId}/groups-assign/investigator-groups`, {
      hideLoader: true
    });
  }
};

export default GroupAssignApi;
