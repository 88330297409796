import moment from 'moment';

import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../constants/dateFormat';

export const formatDateForCSV = value => {
  return value ? moment.utc(value).format(DD_SLASH_MMM_SLASH_YYYY) : '-';
};

export const eventNumberComparator = (v1, v2) => +v1.replace(/-WH$|-CM$|-DM$/, '') - +v2.replace(/-WH$|-CM$|-DM$/, '');

export const prepareNameForCSV = value => {
  return value ? value.replace(/[–—]/g, '-') : '';
};
