import axios from 'axios';

const ROOT = 'api/v1/events';

const EventFinderApi = {
  getEventsForCancelEncounter(ssuPatientId, protocolEncounterId) {
    return axios.get(`${ROOT}/patients/${ssuPatientId}/encounters/${protocolEncounterId}/events`);
  },
  getMilestonesByFilterParams(data) {
    return axios.post(`${ROOT}/filter/milestones`, data, { hideLoader: true });
  },
  getEventsByFilterParams(data, currentTimeZoneId = null) {
    return axios.post(`${ROOT}/filter`, data, { hideLoader: true, params: { currentTimeZoneId } });
  },
  getEventDetails(id) {
    return axios.get(`${ROOT}/${id}`, { hideLoader: false });
  }
};

export default EventFinderApi;
