import React from 'react';

import Button from '../../../../../../common/general/Button';
import Icon from '../../../../../../common/general/Icon';

import './EncounterModificationMenu.scss';

export function EncounterModificationMenu({
  cancelEncounterAvailable,
  cancelEncounter,
  reopenEncounter,
  markItemGroupsNotPerformedAndNotAsked,
  notPerformAllItemsAvailable,
  showCloseEncounterOption,
  isUnexpectedEncounter
}) {
  return (
    <div className="ml-2 dropdown">
      <Button priority="low" size="h40" id="more-options" data-toggle="dropdown">
        <Icon>more_vert</Icon>
      </Button>
      <div
        className="dropdown-menu encounter-modification-dropdown-menu dropdown-menu-right border-0 py-0"
        aria-labelledby="more-options"
        role="menu"
      >
        <button
          className="dropdown-item encounter-modification-dropdown-option py-1"
          onClick={() => {
            if (cancelEncounterAvailable) {
              cancelEncounter();
            } else {
              reopenEncounter();
            }
          }}
        >
          {(cancelEncounterAvailable && (
            <OptionWithHint label="Cancel Encounter" hint="For encounters created in error" />
          )) ||
            'Reopen Encounter'}
        </button>
        {!isUnexpectedEncounter && (
          <button
            className="dropdown-item encounter-modification-dropdown-option py-1"
            disabled={!notPerformAllItemsAvailable || !cancelEncounterAvailable || !showCloseEncounterOption}
            onClick={() => markItemGroupsNotPerformedAndNotAsked()}
          >
            <OptionWithHint label="Close Encounter" hint="Quickly complete all open procedures" />
          </button>
        )}
      </div>
    </div>
  );
}

function OptionWithHint({ label, hint }) {
  return (
    <div>
      <div>{label}</div>
      <div className="hint">{hint}</div>
    </div>
  );
}
