import React from 'react';
import * as PropTypes from 'prop-types';

export default function EpochsHeaders(props) {
  const { epochs } = props;
  return (
    <React.Fragment>
      {epochs?.map(epoch => {
        const countOfEncounters = epoch.encounters.length;
        const maxWidth = countOfEncounters * 90;
        return (
          <th
            style={{ maxWidth: `${maxWidth}px` }}
            className="eett-grey-cell eett-epoch-cell eett-middle-align eett-sticky-row"
            height="38px"
            key={epoch.epochIdentifier}
            id={epoch.epochIdentifier}
            colSpan={countOfEncounters}
            title={epoch.name}
          >
            {epoch.name}
          </th>
        );
      })}
    </React.Fragment>
  );
}

EpochsHeaders.propTypes = { epochs: PropTypes.array };
