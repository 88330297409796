import { createActions } from 'redux-actions';

import { RESET, UPDATE } from './types';

export const options = {
  prefix: 'FILTERS/CRA_REVIEW'
};

export const { update } = createActions(UPDATE, options);
export const { reset } = createActions(RESET, options);
