import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { isEmpty } from 'lodash/lang';

import { DSReportApi, StudySiteApi } from '../../../api';
import NotificationManager from '../../../common/notifications/NotificationManager';
import { AT_LEAST_ONE_VALUE_FOR_FILTER } from '../../../constants/notificationMessages';
import { useCurrentRoute } from '../../root/router';

import { isValidParameter } from './modeReportsServices';
import { generatePayload, getApiParam } from './modeReportsServices';
import { dashboardsMap } from './modeReportsServices';
import { reducer } from './reducer';

const ModeReportsStateContext = React.createContext(null);
const ModeReportsActionsContext = React.createContext(null);

const initialState = {
  initialData: {},
  filters: {},
  selectedValues: {},
  ssus: []
};

export function ModeReportsProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [embedUrl, setEmbedUrl] = useState('');
  const currentRoute = useCurrentRoute();
  const [isFetching, setIsFetching] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(currentRoute.name !== 'Dashboards');
  const reportKey = useMemo(
    function() {
      if (currentRoute.params.reportKey) return currentRoute.params.reportKey;
      if (!currentRoute.params.reportKey && currentRoute.key === 'Home') return 'study';
      if (!currentRoute.params.reportKey && currentRoute.key !== 'Home') return null;
    },
    [currentRoute]
  );

  useEffect(() => {
    StudySiteApi.getAllStudySitesAndMap().then(result => {
      dispatch({ type: 'SET_SSUS', payload: result.data });
    });
  }, []);

  useEffect(() => {
    dispatch({ type: 'CLEAR_DATA' });
    setEmbedUrl('');
    if (!reportKey) {
      setIsCollapsed(false);
      return;
    }
    setIsFetching(true);
    setIsCollapsed(true);
    if (!isEmpty(state?.ssus)) {
      DSReportApi.getModeParameters(getApiParam(reportKey)).then(result => {
        dispatch({ type: 'INIT_DATA', payload: result.data });
        dashboardsMap[reportKey].filters.forEach(filter => {
          if (filter.onInit) {
            dispatch({
              type: filter.onInit,
              payload: { filterKey: filter.filterKey, value: result.data }
            });
          }
        });
        setIsFetching(false);
      });
    }
  }, [reportKey, state?.ssus]);

  const { children } = props;

  return (
    <ModeReportsStateContext.Provider value={{ data: state, isCollapsed, isFetching, embedUrl, reportKey }}>
      <ModeReportsActionsContext.Provider
        value={{
          showHideModeReportsList,
          dispatch,
          applyFilters
        }}
      >
        {children}
      </ModeReportsActionsContext.Provider>
    </ModeReportsStateContext.Provider>
  );

  function showHideModeReportsList() {
    setIsCollapsed(!isCollapsed);
  }

  function applyFilters() {
    if (Object.values(state.selectedValues).some(i => !isValidParameter(i))) {
      return NotificationManager.error(AT_LEAST_ONE_VALUE_FOR_FILTER);
    }
    DSReportApi.postModeReportRun(getApiParam(reportKey), generatePayload(state.selectedValues)).then(result =>
      setEmbedUrl(result?.data?.embedUrl)
    );
  }
}
export function withModeReportsProvider(Component) {
  return function ProtocolGroupsSetupProviderWrapper(props) {
    return (
      <ModeReportsProvider>
        <Component {...props} />
      </ModeReportsProvider>
    );
  };
}

export function useModeReportsState() {
  const context = useContext(ModeReportsStateContext);
  if (context === undefined) {
    throw new Error('useModeReportsState must be used within a ModeReportsStateContext');
  }
  return context;
}

export function useModeReportsActions() {
  const context = useContext(ModeReportsActionsContext);
  if (context === undefined) {
    throw new Error('useModeReportsActions must be used within a ModeReportsActionsContext');
  }
  return context;
}
