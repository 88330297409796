import { investigatorStatusesProvider } from '../../../patient-source/InvestigatorWorklistNew/constants';

export const ENCOUNTER_SIGN_OFF_WIDGET_VALUES = 'ENCOUNTER_SIGN_OFF_WIDGET_VALUES';

export const PI_REVIEW = 'PI_REVIEW';
export const SM_REVIEW = 'SM_REVIEW';
export const OPEN = 'OPEN';

export const ALL_STATUSES = [PI_REVIEW, SM_REVIEW, OPEN];

export const PI_REVIEW_STATUS_OPTION = investigatorStatusesProvider.find(e => e.id === 'REVIEW_REQUIRED');
export const SM_REVIEW_STATUS_OPTION = { id: 'SM_REVIEW', name: 'SM Review' };
export const OPEN_STATUS_OPTION = { id: 'OPEN', name: 'Open' };

export const ALL_STATUS_OPTIONS = [PI_REVIEW_STATUS_OPTION, SM_REVIEW_STATUS_OPTION, OPEN_STATUS_OPTION];
