import React from 'react';
import cx from 'classnames';
import { isEmpty, isUndefined } from 'lodash/lang';
import * as PropTypes from 'prop-types';

import { DollarAppend } from './DollarAppend';

export function NumberInput(props) {
  const { required, name, value, onChange, label, disabled, dollar } = props;
  const id = `number-input-${name}`;
  const onChangeNumberInput = ({ target }) => {
    const { value, name, type } = target;
    const numberValue = isUndefined(value) || isEmpty(value) ? undefined : +value;
    const newTarget = { value: numberValue, name, type };
    onChange({ target: newTarget });
  };
  return (
    <React.Fragment>
      {label && <label htmlFor={id}>{label}</label>}
      <div className="input-group">
        <input
          type="number"
          className={cx('form-control', { reqfeild: required })}
          id={id}
          name={name}
          value={isUndefined(value) ? '' : value}
          onChange={onChangeNumberInput}
          disabled={disabled}
        />
        {dollar && <DollarAppend />}
      </div>
    </React.Fragment>
  );
}

NumberInput.propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  dollar: PropTypes.bool
};

NumberInput.defaultProps = { required: false, label: null, disabled: false, dollar: false };
