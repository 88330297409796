import axios from 'axios';

const ROOT = `api/v1/reimbursements-worklist`;

const ReimbursementWorklistApi = {
  getStudySitesWithReimbursement() {
    return axios.get(`${ROOT}/study-sites`, { hideLoader: true });
  },
  getPersonnelWithReimbursement(ssuIds = []) {
    return axios.post(`${ROOT}/personnel`, ssuIds, {
      hideLoader: true
    });
  },
  getReimbursementsForWorklist(request) {
    return axios.post(
      `${ROOT}`,
      { ...request },
      {
        hideLoader: true
      }
    );
  },
  getAvailableAssignee(ssuIds = []) {
    return axios.post(`${ROOT}/assignees`, ssuIds, {
      hideLoader: true
    });
  },
  updateWorklistRow(reimbursementId, data) {
    return axios.patch(`${ROOT}/rows/${reimbursementId}`, data, {
      hideLoader: true
    });
  }
};

export default ReimbursementWorklistApi;
