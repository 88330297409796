import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Icon from '../../../../../../common/general/Icon';

import FindPatientPopup from './FindPatientPopup/FindPatientPopup';

import './FindPatient.scss';

export default function FindPatient() {
  const location = useLocation();
  const [show, setShow] = useState(false);

  const buttonRef = useRef(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    function handleClickOutside(event) {
      if (buttonRef.current && buttonRef.current.contains(event.target)) {
        return;
      }
      if (wrapperRef.current && wrapperRef.current.contains(event.target)) {
        return;
      }
      setShow(false);
    }
  }, [wrapperRef, buttonRef]);

  useEffect(
    function() {
      setShow(false);
    },
    [location]
  );

  return (
    <div className="eds-find-patient">
      <div className="eds-find-patient-btn" ref={buttonRef} onClick={() => setShow(true)}>
        <Icon>search</Icon> Find Patient
      </div>
      <FindPatientPopup ref={wrapperRef} show={show} />
    </div>
  );
}
