import * as React from 'react';
import { NumericFormat } from 'react-number-format';
import cx from 'classnames';
import { isEqual } from 'lodash';
import { isEmpty, isUndefined } from 'lodash/lang';

import { DollarAppend } from './DollarAppend';

import './AmountInput.scss';

export default function AmountInput(props) {
  const { required, value, name, onChange, label, disabled, dollar, eventType } = props;

  const onChangeNumberInput = ({ target }) => {
    const { value, name, type } = target;
    const numberValue = isUndefined(value) || isEmpty(value) ? undefined : parseFloat(value.replace(/,/g, ''));
    const newTarget = { value: numberValue, name, type };
    onChange({ target: newTarget });
  };

  return (
    <div className="budget-amount-input-block">
      {!isEqual(eventType, 'ENCOUNTER_ITEM_GROUP') && <div className="amount-type-label">{label}</div>}
      <div className="amount-input-wrapper">
        <NumericFormat
          className={cx('budget-amount-input', { 'required-amount-input': required })}
          disabled={disabled}
          value={value}
          name={name}
          data-testid={name}
          thousandSeparator=","
          onChange={onChangeNumberInput}
        />
        {dollar && <DollarAppend />}
      </div>
    </div>
  );
}
