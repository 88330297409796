import React from 'react';
import { isString } from 'lodash/lang';

import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import AddOrEditProgressNoteModal from '../Patients/PatientInfo/PatientInfoSidebar/ProgressNotesWidget/AddOrEditProgressNoteModal/AddOrEditProgressNoteModal';
import AllProgressNotesModal from '../Patients/PatientInfo/PatientInfoSidebar/ProgressNotesWidget/AllProgressNotesModal/AllProgressNotesModal';

export function openAddOrEditModal(progressNote, ssuPatientId, hasAccessToEdit, notesInfo) {
  return function(studySiteStatus) {
    ModalBoxes.open({
      component: (
        <AddOrEditProgressNoteModal
          ssuPatientId={ssuPatientId}
          progressNote={progressNote}
          onSave={function() {
            ModalBoxes.closeAll();
            viewAllProgressNotes(ssuPatientId, studySiteStatus, hasAccessToEdit, notesInfo);
          }}
          notesInfo={notesInfo}
        />
      ),
      title: `${isString(progressNote?.id) ? 'Edit' : 'Add'} Note`
    });
  };
}

export function viewAllProgressNotes(ssuPatientId, studySiteStatus, hasAccessToEdit, notesInfo) {
  ModalBoxes.open({
    component: (
      <AllProgressNotesModal
        isAllowedToManage={hasAccessToEdit}
        ssuPatientId={ssuPatientId}
        editProgressNote={(progressNote, ssuPatientId) =>
          openAddOrEditModal(progressNote, ssuPatientId, hasAccessToEdit, notesInfo)(studySiteStatus)
        }
        addProgressNote={function() {
          openAddOrEditModal(null, ssuPatientId, hasAccessToEdit, notesInfo)(studySiteStatus);
        }}
        notesInfo={notesInfo}
      />
    )
  });
}
