import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { uniqBy } from 'lodash/array';
import { orderBy } from 'lodash/collection';
import { isArray } from 'lodash/lang';
import moment from 'moment';
import Tooltip from 'rc-tooltip';

import { dateFormatHistory } from './AELogsHistory';
import LogsTable from './LogsTable';
import { CustomCell, defaultCellWidth } from './LogsTableService';

export default function AELogRowSignaturesHistory({ history, adverseEventHistory }) {
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [carouselPage, setCarouselPage] = useState(0);

  useEffect(
    function() {
      if (history?.metadata && history?.tableData) {
        setColumns(getColumns(history.metadata));
        setData(history.tableData);
      }
    },
    [history]
  );

  if (columns && data) {
    columns.forEach(column => {
      if (data?.some(ans => ans[column.id]?.comment?.length > 15 || ans[column.id]?.value?.length > 15)) {
        column.className = 'text-left';
      }
    });
  }
  return (
    <>
      {columns.length > 0 && adverseEventHistory.signatures && (
        <>
          {uniqBy(
            orderBy(adverseEventHistory.signatures, 'createdAt', 'desc'),
            o => `${o.groupId} ${o.rowVersion}`
          ).map((signature, idx) => {
            return (
              <div style={{ position: 'relative', marginBottom: '40px' }} key={idx}>
                <LogsTable
                  columns={columns}
                  data={data.filter(e => parseInt(e.rowVersion.value) === signature.rowVersion)}
                  sortable={false}
                  carouselPage={carouselPage}
                  onCarouselPageChange={page => {
                    setCarouselPage(page);
                  }}
                  customTitle={
                    <div>
                      {`State of log at time of signature by ${signature?.creatorUserName} ${signature?.signerRole}, ${signature?.groupName}: `}
                      <span style={{ whiteSpace: 'nowrap' }}>{`${moment(signature?.createdAt).format(
                        dateFormatHistory
                      )}`}</span>
                    </div>
                  }
                  pageSize={100}
                  defaultCellWidth={defaultCellWidth}
                />
              </div>
            );
          })}
        </>
      )}
    </>
  );

  function getColumns(metaData) {
    return metaData.map(columnInfo => {
      return {
        ...columnInfo,
        Header: columnInfo.header,
        minWidth: columnInfo.id === 'Last Updated Date' ? 182 : 100,
        getProps: (state, rowInfo, column) => {
          const isColumnChanged = rowInfo?.original?.[column.id]?.changed;
          return {
            className: cx('history-log-cell', { 'eds-review-history-changed-cell': isColumnChanged })
          };
        },
        Cell: ({ original, column }) => {
          if (original[column.id]) {
            const { value, changed, reasonForDataChange, performedStatus, comment } = original[column.id];
            let slittedValue = value ? value : '';
            if (column.id === 'Last Updated Date') {
              slittedValue = moment(slittedValue).format('DD-MMM-YYYY h:mm:ss a');
            } else if (column.id === 'When') {
              slittedValue = moment(slittedValue).format('DD/MMM/YYYY');
            }
            if (isArray(slittedValue)) {
              slittedValue = slittedValue.join(' ');
            }
            return (
              <React.Fragment>
                {slittedValue === 'Unavailable' && !reasonForDataChange ? (
                  <div>{''}</div>
                ) : (
                  <>
                    {slittedValue && (
                      <CustomCell shortAnswer={slittedValue} longAnswer={slittedValue} className={'text-left'} />
                    )}
                    {!slittedValue && performedStatus && comment && (
                      <Tooltip
                        destroyTooltipOnHide={false}
                        overlayClassName="eds-rc-tooltip logs-table-tooltip over-modal"
                        placement="top"
                        trigger={['hover']}
                        overlay={`${performedStatus}: ${comment}`}
                      >
                        <div>{performedStatus}</div>
                      </Tooltip>
                    )}
                    {!slittedValue && performedStatus && !comment && performedStatus}
                  </>
                )}
              </React.Fragment>
            );
          } else {
            return <div>Field not in template</div>;
          }
        }
      };
    });
  }
}
