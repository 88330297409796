import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty, isFunction } from 'lodash/lang';

import PatientInfoPageApi from '../../../../../../../api/patient/PatientInfoPageApi';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';
import { onRequestError } from '../../../../../../../services/handlers';
import { useCurrentRoute } from '../../../../../../root/router';
import { PatientInfoContext } from '../../PatientInfoContext';
import { useEncountersSection } from '../EncountersSectionContext';

import EncountersTabContent from './EncountersTabContent/EncountersTabContent';
import EncountersTabLoader from './EncountersTabLoader/EncountersTabLoader';

import './EncountersTab.scss';

const PAGE_SIZE = 10;
const DEFAULT_PAGE = 0;

export default function EncountersTab({ activeTab }) {
  const currentRoute = useCurrentRoute();
  const ssuPatientId = currentRoute.params?.ssuPatientId;

  const { setPresentEpochs } = useEncountersSection();

  const [loading, setLoading] = useState(true);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalEncounters, setTotalEncounters] = useState(null);
  const [encounters, setEncounters] = useState([]);

  const Context = useContext(PatientInfoContext);
  const { getNewAndCompletedIcfForms } = Context;

  const loadMoreIsAvailable = useMemo(
    function() {
      return encounters.length < totalEncounters;
    },
    [encounters.length, totalEncounters]
  );

  const loadPatientEncountersWithAppointments = useCallback(
    function(cb, page = DEFAULT_PAGE) {
      let canceled = false;
      const loadingStartTime = +new Date();
      pageableFetcher(ssuPatientId, activeTab, page)
        .then(function({ data }) {
          if (canceled) {
            return;
          }
          const loadingEndTime = +new Date();
          const loadingDeltaTime = loadingEndTime - loadingStartTime;
          setTimeout(function() {
            if (canceled) {
              return;
            }
            cb(data);
          }, Math.max(700 - loadingDeltaTime, 0));
        })
        .catch(err => onRequestError(err, { customMessage: 'Getting Patient Encounters failed' }));
      return function() {
        canceled = true;
      };
    },
    [ssuPatientId, activeTab]
  );

  const loadMore = useCallback(
    function() {
      setLoadMoreLoading(true);
      loadPatientEncountersWithAppointments(function({ page, encountersWithAppointments, totalSize }) {
        setPage(page);
        setEncounters(function(encounters) {
          return [...encounters, ...encountersWithAppointments];
        });
        setTotalEncounters(totalSize);
        setLoadMoreLoading(false);
      }, page + 1);
    },
    [page, loadPatientEncountersWithAppointments]
  );

  const updatePatientEncountersWithAppointments = useCallback(
    function() {
      setLoading(true);
      return loadPatientEncountersWithAppointments(function({
        page,
        encountersWithAppointments,
        totalSize,
        presentEpochs
      }) {
        setPage(page);
        setEncounters(encountersWithAppointments);
        setTotalEncounters(totalSize);
        setPresentEpochs(presentEpochs);
        setLoading(false);
      },
      DEFAULT_PAGE);
    },
    [setPresentEpochs, loadPatientEncountersWithAppointments]
  );

  useEffect(
    function() {
      return updatePatientEncountersWithAppointments();
    },
    [updatePatientEncountersWithAppointments]
  );

  return (
    <div className="encounters-section-content">
      <NoDataWrapper data={encounters} loading={loading}>
        {function() {
          return (
            <>
              <EncountersTabContent
                encounters={encounters}
                updatePatientEncountersWithAppointments={() => {
                  updatePatientEncountersWithAppointments();
                  getNewAndCompletedIcfForms();
                }}
              />
              {loadMoreLoading ? (
                <EncountersTabLoader />
              ) : (
                <ButtonGroup style={{ justifyContent: 'center' }}>
                  <Button disabled={!loadMoreIsAvailable} onClick={loadMore} priority="low">
                    {loadMoreIsAvailable ? 'View More' : 'End of results'}
                  </Button>
                </ButtonGroup>
              )}
            </>
          );
        }}
      </NoDataWrapper>
    </div>
  );
}

function NoDataWrapper({ data, loading, children }) {
  if (loading) {
    return <EncountersTabLoader />;
  }
  if (isEmpty(data)) {
    return <div className="no-data-yet">There is no data yet</div>;
  }
  if (isFunction(children)) {
    return children();
  }
  return children;
}

function pageableFetcher(ssuPatientId, activeTab, page = 0) {
  return PatientInfoPageApi[
    {
      Projected: 'getPatientProjectedEncountersWithAppointments',
      Canceled: 'getPatientCanceledEncountersWithAppointments'
    }[activeTab] || 'getPatientActiveEncountersWithAppointments'
  ](ssuPatientId, page, PAGE_SIZE);
}
