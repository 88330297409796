import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';

import Group from '../_Group';
import { extractRenderingContent } from '../inputSelectorsService';

import Radio from './Radio';

export default function RadioGroup(props) {
  const { children: allChildren, ...restProps } = props;

  const children = useMemo(
    function() {
      return extractRenderingContent(allChildren, child => child?.type.isRadio);
    },
    [allChildren]
  );
  return (
    <Group groupProps={restProps} component={Radio}>
      {children}
    </Group>
  );
}

RadioGroup.propTypes = {
  options: PropTypes.array,
  checkedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  label: PropTypes.string,
  optionValueKey: PropTypes.any,
  optionLabelKey: PropTypes.any,
  optionTitleKey: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  direction: PropTypes.string
};

RadioGroup.defaultProps = {
  optionValueKey: 'value',
  optionLabelKey: 'label',
  optionTitleKey: 'title',
  direction: 'row',
  disabled: false
};
