import {
  addInvalidFieldIfNotValid,
  isBlank,
  isPhoneValid,
  isValidEmailForPayee,
  isZipCodeValid
} from '../../AccountService.js';

export const fieldNames = {
  ORGANIZATION_NAME: 'ORGANIZATION_NAME',
  DEPARTMENT_NAME: 'DEPARTMENT_NAME',
  ADDRESS_1: 'ADDRESS_1',
  COUNTRY: 'COUNTRY',
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
  ZIP_CODE: 'ZIP_CODE',
  CITY: 'CITY',
  STATE: 'STATE',
  BANK_NAME: 'BANK_NAME',
  BANK_ACCOUNT_TYPE: 'BANK_ACCOUNT_TYPE',
  BANK_ROUTING_NUMBER: 'BANK_ROUTING_NUMBER',
  BANK_ACCOUNT_NUMBER: 'BANK_ACCOUNT_NUMBER'
};

export const validatePayee = payee => {
  const invalidPayeeFields = [];

  addInvalidFieldIfNotValid(isBlank(payee.name), fieldNames.ORGANIZATION_NAME, invalidPayeeFields);
  addInvalidFieldIfNotValid(isBlank(payee.departmentName), fieldNames.DEPARTMENT_NAME, invalidPayeeFields);
  addInvalidFieldIfNotValid(isBlank(payee.address1), fieldNames.ADDRESS_1, invalidPayeeFields);
  addInvalidFieldIfNotValid(isBlank(payee.country), fieldNames.COUNTRY, invalidPayeeFields);
  addInvalidFieldIfNotValid(isBlank(payee.city), fieldNames.CITY, invalidPayeeFields);
  addInvalidFieldIfNotValid(isBlank(payee.state), fieldNames.STATE, invalidPayeeFields);
  addInvalidFieldIfNotValid(!isPhoneValid(payee.phone), fieldNames.PHONE, invalidPayeeFields);
  addInvalidFieldIfNotValid(
    !isZipCodeValid(payee.zipCode, payee?.country === 'US'),
    fieldNames.ZIP_CODE,
    invalidPayeeFields
  );
  addInvalidFieldIfNotValid(!isValidEmailForPayee(payee?.email), fieldNames.EMAIL, invalidPayeeFields);
  addInvalidFieldIfNotValid(isBlank(payee.bankRoutingNumber), fieldNames.BANK_ROUTING_NUMBER, invalidPayeeFields);
  addInvalidFieldIfNotValid(isBlank(payee.bankAccountNumber), fieldNames.BANK_ACCOUNT_NUMBER, invalidPayeeFields);
  addInvalidFieldIfNotValid(!payee.bankAccountType, fieldNames.BANK_ACCOUNT_TYPE, invalidPayeeFields);
  addInvalidFieldIfNotValid(isBlank(payee.bankName), fieldNames.BANK_NAME, invalidPayeeFields);

  return invalidPayeeFields;
};
