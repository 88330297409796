import { fetchStudySites } from '../actions/types/studySites';
import { FAILURE, REQUEST, SUCCESS } from '../actions/types/utils';

const initState = {
  isFetching: false,
  errorMsg: '',
  ssus: []
};

const studySites = (state = initState, action) => {
  switch (action.type) {
    case fetchStudySites[REQUEST]:
      return {
        isFetching: true,
        errorMsg: '',
        ssus: []
      };
    case fetchStudySites[SUCCESS]:
      return {
        isFetching: false,
        errorMsg: '',
        ssus: action.payload || []
      };
    case fetchStudySites[FAILURE]:
      return {
        isFetching: false,
        errorMsg: action.msg,
        ssus: []
      };
    default:
      return state;
  }
};

export default studySites;
