import { map } from 'lodash/collection';

const ASP_AISLE = 'AISLE';
const ASP_WINDOW = 'WINDOW';
const ASP_MIDDLE = 'MIDDLE';

const seatingPreferencesMap = {
  [ASP_AISLE]: 'Aisle',
  [ASP_WINDOW]: 'Window',
  [ASP_MIDDLE]: 'Middle'
};

export const seatingPreferences = map(seatingPreferencesMap, function(name, id) {
  return { id, name };
});
