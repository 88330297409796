import { orderBy, reject } from 'lodash/collection';
import moment from 'moment';

import store from '../../../../../../store';
import { dateFormat } from '../PatientStatusChangeConstants';

export function thereAreMoreActiveRecordInsteadSpecified(patientStatusHistory, statusRecord) {
  return patientStatusHistory.some(s => s.active && s.id !== statusRecord.id);
}

export function isCurrentStatusRecord(patientStatusHistory, statusRecord) {
  const sortedHistory = getOrderedStatusHistory(patientStatusHistory);
  const activePatientStatusHistory = sortedHistory.filter(s => s.active !== false);
  return activePatientStatusHistory?.[0]?.id === statusRecord.id;
}

export function moveDownDraftRecord(patientStatusHistory, { date, status, comment, reason, encounter }) {
  const draftStatusChange = getDraftStatusChange(patientStatusHistory);
  return constructDraftStatusChangeRecord(draftStatusChange.position - 10, date, status, encounter, reason, comment);
}

export function moveUpDraftRecord(patientStatusHistory, { date, status, comment, reason, encounter }) {
  const draftStatusChange = getDraftStatusChange(patientStatusHistory);
  return constructDraftStatusChangeRecord(draftStatusChange.position + 10, date, status, encounter, reason, comment);
}

export function isThereMoreStatusChangesAtDraftStatusChangeDate(patientStatusHistory) {
  const draftStatusChange = getDraftStatusChange(patientStatusHistory);
  return patientStatusHistory.filter(e => e.statusDate === draftStatusChange?.statusDate).length > 1;
}

export function addRecordToStatusHistory(originalPatientStatusHistory, draftStatusChangeRecord) {
  const history = [...originalPatientStatusHistory, draftStatusChangeRecord];
  return getOrderedStatusHistory(history);
}

export function findPreviousActiveStatusFromHistory(patientStatusHistory, statusRecord) {
  const sortedHistory = getOrderedStatusHistory(patientStatusHistory);
  const activePatientStatusHistory = sortedHistory.filter(s => s.active !== false);
  const actualStatusIndex = activePatientStatusHistory.indexOf(statusRecord);
  return activePatientStatusHistory?.[actualStatusIndex + 1];
}

export function prepareHistory(statusRecords) {
  statusRecords.forEach(e => {
    e.position = e.position * 10;
  });
  return orderBy(statusRecords, ['statusDate', 'position'], ['desc', 'desc']);
}

export function thereAreNoPreviousStatusChangesSameDateAsDraft(patientStatusHistory) {
  const sortedHistory = getOrderedStatusHistory(patientStatusHistory);
  const draftStatusChange = getDraftStatusChange(sortedHistory);
  const draftStatusChangeIndex = sortedHistory.indexOf(draftStatusChange);
  return sortedHistory?.[draftStatusChangeIndex + 1]?.statusDate !== draftStatusChange?.statusDate;
}

export function thereAreNoNextStatusChangesSameDateAsDraft(patientStatusHistory) {
  const sortedHistory = getOrderedStatusHistory(patientStatusHistory);
  const draftStatusChange = getDraftStatusChange(sortedHistory);
  const draftStatusChangeIndex = sortedHistory.indexOf(draftStatusChange);
  return sortedHistory?.[draftStatusChangeIndex - 1]?.statusDate !== draftStatusChange?.statusDate;
}

export function buildDraftStatusChangeRecord(patientStatusHistory, { date, status, comment, reason, encounter }) {
  const originalPatientStatusHistory = reject(patientStatusHistory, s => s.id === 'newStatus');

  const allStatusesAtSelectedDate = originalPatientStatusHistory.filter(e => e.statusDate === date.format(dateFormat));
  const position = (allStatusesAtSelectedDate?.[0]?.position ?? 0) + 5;

  return constructDraftStatusChangeRecord(position, date, status, encounter, reason, comment);
}

export function updateDraftStatusChangeRecord(patientStatusHistory, { date, status, comment, reason, encounter }) {
  const draftStatusChange = getDraftStatusChange(patientStatusHistory);
  const position = draftStatusChange.position;

  return constructDraftStatusChangeRecord(position, date, status, encounter, reason, comment);
}

export function getNextStatusChangeId(patientStatusHistory) {
  const sortedHistory = getOrderedStatusHistory(patientStatusHistory);
  const draftStatusChange = getDraftStatusChange(sortedHistory);
  const draftStatusChangeIndex = sortedHistory.indexOf(draftStatusChange);
  return sortedHistory?.[draftStatusChangeIndex - 1]?.statusDate === draftStatusChange.statusDate
    ? sortedHistory[draftStatusChangeIndex - 1].id
    : undefined;
}

function getOrderedStatusHistory(patientStatusHistory) {
  return orderBy(patientStatusHistory, ['statusDate', 'position'], ['desc', 'desc']);
}

function getDraftStatusChange(patientStatusHistory) {
  return patientStatusHistory.find(s => !s.id);
}

function constructDraftStatusChangeRecord(position, date, status, encounter, reason, comment) {
  const { lastName, firstName } = store.getState().currentUser;
  return {
    statusDate: date.format(dateFormat),
    status,
    encounter,
    reason,
    comment,
    position,
    creationDate: moment().format(dateFormat),
    statusChangeLocation: 'STATUS_HISTORY',
    changedBy: { lastName, firstName },
    active: true
  };
}
