import { combineReducers } from 'redux';

import craReviewReducer from './craReview';
import patientListReducer from './patientList';
import smWorklistReducer from './smWorklist';

export default combineReducers({
  patientList: patientListReducer,
  craReview: craReviewReducer,
  smWorklist: smWorklistReducer
});
