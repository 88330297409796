import React, { useContext } from 'react';
import cx from 'classnames';
import * as PropTypes from 'prop-types';

import { REMINDER, TEXT_BLOCK_LONG } from '../../../../../../../../../constants/inputTypes';
import InvestigatorFieldIcon from '../../../../../EncounterDescNew/InvestigatorFields/InvestigatorFieldIcon/InvestigatorFieldIcon';
import { ReviewContext } from '../../../../ReviewContext';
import {
  getAnswerHighlightStyleClassByAnswerAndReviewType,
  isAnswerPopulated,
  resolveAnswerNoPopulatedReason
} from '../../../reviewContentService';

import PopulatedValue from './PopulatedValue/PopulatedValue';

import './QuestionAnswer.scss';

export default function QuestionAnswer({ answer }) {
  const { reviewType } = useContext(ReviewContext);
  const isAnswerPopulated = answer.type === 'VALUE' || answer.type === 'EMPTY';
  if (answer.inputType !== TEXT_BLOCK_LONG && answer.inputType !== REMINDER) {
    const isAnswerPopulatedAndEdited = isAnswerPopulated && answer.isEdited && answer.changeReason;
    const answerHighlightStyle = getAnswerHighlightStyleClassByAnswerAndReviewType(answer, reviewType).className;

    return (
      <div className={cx('eds-question-answer', answerHighlightStyle)}>
        <div className="eds-question">
          {answer.question.investigatorField && <InvestigatorFieldIcon />}
          <div className="question">{answer.question.label}</div>
        </div>
        <div className="eds-answer">
          <div
            className={cx('answer', {
              'not-answered': !isAnswerPopulated,
              'change-bullet': isAnswerPopulatedAndEdited
            })}
          >
            {answerValueToShow(answer)}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="eds-question-answer">
      <div className="eds-question">
        <div className="question">{answer.question.label}</div>
      </div>
    </div>
  );
}

QuestionAnswer.propTypes = {
  answer: PropTypes.object.isRequired
};

function answerValueToShow(answer) {
  if (isAnswerPopulated(answer)) {
    return <PopulatedValue {...answer} />;
  } else {
    return resolveAnswerNoPopulatedReason(answer);
  }
}
