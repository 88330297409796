import React, { Component } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { connect } from 'react-redux';
import cx from 'classnames';
import * as dates from 'date-arithmetic';
import { reject } from 'lodash/collection';
import { isEqual, isFunction } from 'lodash/lang';
import { get } from 'lodash/object';
import moment from 'moment-timezone';

import { scBlue50, scBlue100 } from '../../../../constants/systemColors';
import { MANAGE_NON_PATIENT_APPOINTMENTS, MANAGE_PATIENT_APPOINTMENTS } from '../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../services/auth';
import { TASKS_TYPES } from '../../../root/Container/Layout/Tasks/Task/taskConstants';
import { MILESTONE_TYPES } from '../CalendarEventType';
import { getCurrentTimeZone, getTimeForScroll, toTimeZoneWithNormalizingToCurrent } from '../CalendarTimeZoneService';
import { composePatientName, hasUserAccessToProtectedPatientInfo } from '../CalendarUtils';
import { fromCalendarEvent, toCalendarEvent } from '../EventTransformer';

import AllDayEvents from './Components/AllDayEvents';
import { Event, EventCanceled, EventDisabled, EventShort } from './Components/Events/Events';
import { Header } from './Components/Header';
import { Agenda } from './Components/Views/Agenda';
import { prepareTimeRange } from './CalendarBigService';
import { TimeRange } from './TimeRange';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import './CalendarBig.scss';

const calendarSlotDuration = 30; //min
const newEventDefaultDuration = 60; //min
let DragAndDropCalendar = withDragAndDrop(Calendar);
let agendaWrapper;

class CalendarBig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: props.calendars,
      timeRange: prepareTimeRange(props.timeRange),
      selectedEvent: null,
      hasRightsToCreateOrUpdateEvents: false,
      currentTimeForSelectedTimeZone: new Date(),
      lastVisibleDateForViewMode: null
    };
  }

  componentDidMount() {
    lastVisibleDate = moment()
      .set('hour', 8)
      .startOf('hour');
    this.setState({
      events: this.props.calendars,
      timeRange: prepareTimeRange(this.props.timeRange),
      selectedEvent: toCalendarEvent(this.props.selectedEvent),
      hasRightsToCreateOrUpdateEvents:
        userHasAccessTo(MANAGE_PATIENT_APPOINTMENTS) || userHasAccessTo(MANAGE_NON_PATIENT_APPOINTMENTS)
    });
    this.prepareAgendaViewWrapper();
  }

  prepareAgendaViewWrapper = () => {
    agendaWrapper = props => {
      return Agenda(props, this.props);
    };
    agendaWrapper.title = date => {
      return `Agenda: ${date.toLocaleDateString()}`;
    };
    agendaWrapper.navigate = (date, action) => {
      switch (action) {
        case 'PREV':
          return dates.add(date, -1, 'day');
        case 'NEXT':
          return dates.add(date, 1, 'day');
        default:
          return date;
      }
    };
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!isEqual(prevProps.selectedTimeZone, this.props.selectedTimeZone) && this.props.selectedTimeZone) {
      const localTimeZone = getCurrentTimeZone();
      const updatedTime = toTimeZoneWithNormalizingToCurrent(
        new Date(),
        this.props.selectedTimeZone.timeZoneId,
        localTimeZone.timeZoneId
      ).toDate();
      this.setState({
        currentTimeForSelectedTimeZone: updatedTime,
        lastVisibleDateForViewMode: getTimeForScroll(updatedTime)
      });
      DragAndDropCalendar = withDragAndDrop(Calendar);
    }
    if (prevProps.appointmentPanelMode === 'EDIT' && this.props.appointmentPanelMode === 'NONE') {
      this.setState({ lastVisibleDateForViewMode: lastVisibleDate });
    }
    // calendar scroll functionality implementation
    registerOnScrollToTimeListener();

    if (
      prevProps.calendars === this.props.calendars &&
      prevProps.timeRange === this.props.timeRange &&
      prevProps.selectedEvent === this.props.selectedEvent
    ) {
      return;
    }
    // initialization
    let events = this.props.calendars;
    let selectedEvent;
    if (this.props.selectedEvent && this.props.selectedEvent.id) {
      selectedEvent = toCalendarEvent(this.props.selectedEvent);
      events = reject(events, { id: selectedEvent.id });
      events.push(selectedEvent);
    }
    this.setState({
      events,
      timeRange: prepareTimeRange(this.props.timeRange),
      selectedEvent
    });
  }

  componentWillUnmount() {
    removeOnScrollToTimeListener();
  }

  moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
    const { events: oldEvents } = this.state;
    const editedEvent = { ...event, start, end };
    let events = [...oldEvents];
    events = reject(events, { id: event.id });
    events.push(editedEvent);
    this.setState({ events, selectedEvent: editedEvent });
    isFunction(this.props.moveEvent) && this.props.moveEvent(fromCalendarEvent(editedEvent), fromCalendarEvent(event));
  };

  resizeEvent = ({ event, start, end }) => {
    const { events: oldEvents } = this.state;

    let events = [...oldEvents];
    const editedEvent = events.find(e => e.id === event.id);
    editedEvent.start = start;
    editedEvent.end = end;

    this.setState({ events, selectedEvent: editedEvent });

    isFunction(this.props.resizeEvent) &&
      this.props.resizeEvent(fromCalendarEvent(editedEvent), fromCalendarEvent(event));
  };

  isEventFromRegularCalendarCell = draftEvent => {
    return draftEvent.box || draftEvent.bounds;
  };

  getNewEventDuration = draftEvent => {
    const duration = moment(draftEvent.end).diff(moment(draftEvent.start), 'minutes');
    return duration === calendarSlotDuration ? newEventDefaultDuration : duration;
  };

  addOrMoveEvent = event => {
    if (this.props.appointmentPanelMode === 'EDIT') {
      const duration = this.props.selectedEvent.duration;
      const end = moment(event.start)
        .add(duration)
        .toDate();
      this.moveEvent({ event: this.props.selectedEvent, start: event.start, end });
    } else {
      this.newEvent(event);
    }
  };

  newEvent = draftEvent => {
    //prevents from creating events from all-day-events(milestones) section
    if (draftEvent && this.isEventFromRegularCalendarCell(draftEvent)) {
      const duration = this.getNewEventDuration(draftEvent);
      isFunction(this.props.newEvent) && this.props.newEvent(moment(draftEvent.start), moment.duration(duration, 'm'));
    }
  };

  onSelectEvent = event => {
    if (
      get(this.state.selectedEvent, 'id') !== event.id ||
      get(this.state.selectedEvent, 'eventId') !== event.eventId
    ) {
      this.setState({ selectedEvent: event, lastVisibleDateForViewMode: lastVisibleDate });
      isFunction(this.props.onSelectEvent) && this.props.onSelectEvent(fromCalendarEvent(event));
    }
  };

  onNavigate = action => {
    isFunction(this.props.onNavigate) && this.props.onNavigate(action);
  };

  onView = view => {
    isFunction(this.props.onView) && this.props.onView(view);
  };

  onSelecting = timeRange => {
    isFunction(this.props.onSelecting) && this.props.onSelecting(timeRange);
  };

  isNoneMode = () => {
    return this.props.appointmentPanelMode !== 'NONE';
  };

  scrollToSelectedEvent = () => {
    if (this.props.appointmentPanelMode === 'EDIT' || this.props.appointmentPanelMode === 'ADD') {
      const startForSelectedEvent = this.state.selectedEvent?.start
        ? this.state.selectedEvent.start
        : this.state.currentTimeForSelectedTimeZone;
      return getTimeForScroll(startForSelectedEvent);
    }
    return this.state.lastVisibleDateForViewMode;
  };

  getTooltipText = event => {
    return `${event.title}\n${event?.study && event?.study?.name ? event.study.name : 'No Study'}\n${
      event?.patient ? composePatientName(event, get(event, 'patient'), get(event, 'patientSubjectId')) : ''
    }`;
  };

  render({ state, props } = this) {
    let defaultView = this.props.userPreferences.view || Views.WEEK;
    const isWeekViewMode = ['week', 'work_week'].includes(get(props.userPreferences, 'view'));

    if (isWeekViewMode && get(props.userPreferences, 'useWorkWeek')) {
      defaultView = Views.WORK_WEEK;
      DragAndDropCalendar = withDragAndDrop(Calendar);
    }
    return (
      <div
        className="eds-calendar-big eds-calendar"
        style={{
          width: props.width || '100%',
          height: props.height || '100%'
        }}
      >
        {['week', 'work_week', 'day'].includes(get(props.userPreferences, 'view')) && (
          <AllDayEvents
            {...props}
            events={this.state.events.filter(e => [...MILESTONE_TYPES, ...TASKS_TYPES].includes(e.type))}
          />
        )}
        <DragAndDropCalendar
          key={defaultView}
          selectable={
            (event => hasUserAccessToProtectedPatientInfo(event)) && this.state.hasRightsToCreateOrUpdateEvents
          }
          popup
          date={this.props.displayedDate ? moment(this.props.displayedDate).toDate() : undefined}
          localizer={momentLocalizer(moment)}
          events={this.state.events.sort((a, b) => {
            if (a.calendarIndex < b.calendarIndex) {
              return -1;
            }
            if (a.calendarIndex > b.calendarIndex) {
              return 1;
            } else {
              return moment(a.lastUpdateDate).isBefore(moment(b.lastUpdateDate)) ? -1 : 1;
            }
          })}
          onEventDrop={this.moveEvent}
          resizable
          resourceIdAccessor={'fullId'}
          onEventResize={this.resizeEvent}
          onSelectSlot={this.addOrMoveEvent}
          defaultView={defaultView}
          onNavigate={this.onNavigate}
          onView={this.onView}
          onSelecting={this.onSelecting}
          slotPropGetter={e => slotPropGetter(e)}
          eventPropGetter={e => eventPropGetter(e, this.state.selectedEvent)}
          views={{
            week: true,
            work_week: true,
            day: true,
            agenda: agendaWrapper
          }}
          step={calendarSlotDuration} //duration of each slot 30minutes
          timeslots={2} //each hour has 2 slots
          drilldownView=""
          scrollToTime={this.scrollToSelectedEvent()}
          getNow={() => this.state.currentTimeForSelectedTimeZone}
          onSelectEvent={this.onSelectEvent}
          tooltipAccessor={this.getTooltipText}
          defaultDate={new Date()}
          displayedDate={this.props.displayedDate}
          formats={{
            timeGutterFormat: 'h A'
          }}
          draggableAccessor={event =>
            hasUserAccessToProtectedPatientInfo(event) && this.state.hasRightsToCreateOrUpdateEvents
          }
          components={{
            toolbar: () => null,
            eventWrapper: EventWrapper,
            timeSlotWrapper: e => TimeRange(e, this.state.timeRange),
            header: Header,
            event: Event
          }}
        />
      </div>
    );
  }
}

export function getTimeZoneName(siteTimeZone) {
  return `${siteTimeZone?.timeZoneNameToDisplay} - ${getActualTimeByTimeZone(siteTimeZone)}`;
}

function getActualTimeByTimeZone(timeZone) {
  if (timeZone?.timeZoneIdValid) {
    return moment()
      .tz(timeZone?.timeZoneId)
      .format('h:mm A');
  }
  return '';
}

function mapStateToProps(state) {
  return {
    userPreferences: state.userPreferences
  };
}

export default connect(mapStateToProps)(CalendarBig);

let lastVisibleDate = moment();

const eventDurationIsLessThanMinimumSlotDuration = event => {
  return moment(event.end).diff(moment(event.start), 'minutes') <= calendarSlotDuration;
};

export function EventWrapper(e) {
  let res = e.children;
  if (e.event.disabled && e.style) {
    res = <EventDisabled event={e} />;
  }
  if (eventDurationIsLessThanMinimumSlotDuration(e.event)) {
    res = <EventShort event={e} />;
  }
  if (e.event.canceled) {
    res = <EventCanceled event={e} />;
  }
  return res;
}

const slotPropGetter = date => {
  return {
    id: `${moment(date).format('MMM DD, YYYY h:mm A')}`,
    className: cx('period-with-time-range', {
      inactive: moment(date).get('hour') < 8 || moment(date).get('hour') > 16
    })
  };
};

export const eventPropGetter = (event, selectedEvent) => {
  const style = {};

  if ([...MILESTONE_TYPES, ...TASKS_TYPES].includes(event.type)) {
    style.display = 'none';
  }

  const mainColor = get(event, 'color.main', scBlue100);
  const backColor = get(event, 'color.back', scBlue50);
  if (!event.allDay) {
    style.backgroundColor = backColor;
    style.color = mainColor;
  }

  if (selectedEvent && selectedEvent.id === event.id) {
    style.borderColor = backColor;
    style.boxShadow = `0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.2)`;
    style.zIndex = 3;
  }

  event.customStyle = { ...style, mainColor, backColor };
  return { style: style };
};

function prepareLastVisibleDate(event) {
  const minutes = event.target.scrollTop / (event.target.scrollHeight / 24 / 60);
  lastVisibleDate = moment()
    .startOf('day')
    .add(minutes, 'minutes');
}

function removeOnScrollToTimeListener() {
  const scrollableContainer = document.querySelector('.rbc-time-content');
  if (scrollableContainer) {
    scrollableContainer.removeEventListener('scroll', prepareLastVisibleDate);
  }
}

function registerOnScrollToTimeListener() {
  const scrollableContainer = document.querySelector('.rbc-time-content');
  if (scrollableContainer) {
    scrollableContainer.addEventListener('scroll', prepareLastVisibleDate);
  }
}
