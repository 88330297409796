import React, { useContext } from 'react';

import { getPatientAgeFromDob, getPatientPreferredName } from '../../../../../../services/patient';
import { PageInfoHeader } from '../../../../../PageInfoHeader/PageInfoHeader';
import { PatientInfoHeaderContext, PatientInfoHeaderProvider } from '../../../Patients/PatientInfoHeaderContext';

const EncounterPatientInfo = ({ infoLabel, withLabel }) => {
  const { patientInfo } = useContext(PatientInfoHeaderContext);

  return (
    <PageInfoHeader
      objectRecordLabel={infoLabel ? infoLabel : ''}
      withLabel={withLabel}
      pageInfo={
        <PageInfoHeader.CollapsibleList>
          <PageInfoHeader.AdditionalInfo tooltip="Patient Name">
            {patientInfo?.name + getPatientPreferredName(patientInfo)}
          </PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo title="Patient ID">{patientInfo?.patientId}</PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo title="Subject ID">
            {patientInfo?.subjectId || 'No Subject ID'}
          </PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo tooltip="Date of Birth">
            {patientInfo?.dob} ({getPatientAgeFromDob(patientInfo)})
          </PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo tooltip="Study">{patientInfo?.studyName}</PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo tooltip="Site">{patientInfo?.siteName}</PageInfoHeader.AdditionalInfo>
        </PageInfoHeader.CollapsibleList>
      }
    />
  );
};

export default function EncounterPatientInfoComponent(props) {
  return (
    <PatientInfoHeaderProvider>
      <EncounterPatientInfo {...props} />
    </PatientInfoHeaderProvider>
  );
}
