import React from 'react';
import moment from 'moment';

import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../../constants/dateFormat';
import InvoicePageChip from '../../Components/InvoicePageChip/InvoicePageChip';

export const ChipSection = ({ invoiceNumber, investigatorName, studyName, pcnName, siteName, siteNumber }) => {
  return (
    <div className="chip-section">
      <InvoicePageChip label="Date" value={moment().format(DD_SLASH_MMM_SLASH_YYYY)} emptyValue="—" />
      <InvoicePageChip label="Invoice number" value={invoiceNumber} />
      <InvoicePageChip label="Investigator" value={investigatorName} emptyValue="—" />
      <InvoicePageChip label="Study" value={studyName} emptyValue="—" />
      <InvoicePageChip label="PCN" value={pcnName} emptyValue="—" />
      <InvoicePageChip label="Site Name" value={siteName} emptyValue="—" />
      <InvoicePageChip label="Site Number" value={siteNumber} emptyValue="—" />
    </div>
  );
};
