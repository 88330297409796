import cx from 'classnames';
import { get } from 'lodash/object';

import { TASKS_TYPES } from '../../../../root/Container/Layout/Tasks/Task/taskConstants';
import { APPOINTMENT_TYPES, MILESTONE_TYPES } from '../../CalendarEventType';

import './EventTypeIndicator.scss';

export default function EventTypeIndicator({ event, className }) {
  let style = {};
  if (APPOINTMENT_TYPES.includes(event.type)) {
    style = {
      backgroundColor: get(event, 'color.main', ''),
      borderRadius: '20px'
    };
  }
  if (TASKS_TYPES.includes(event.type)) {
    style = {
      backgroundColor: 'transparent',
      borderColor: get(event, 'color.main', ''),
      borderRadius: '20px'
    };
  }
  if (MILESTONE_TYPES.includes(event.type)) {
    style = {
      backgroundColor: get(event, 'color.main', '')
    };
  }

  return <div className={cx('eds-event-type-indicator', className)} style={style} />;
}
