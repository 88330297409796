import { PatientApi } from '../../../../../../../api';

export const FPAT_PS = 'FPAT_PS';
export const FPAT_PP = 'FPAT_PP';

export async function fetchPatients(
  activeTab,
  trimmedSearchString,
  page = 0,
  pageSizeForRecentPatient = 5,
  pageSizeForSearch = 100
) {
  if (trimmedSearchString.length === 0) {
    const doResentPatientsFetch = resentPatientsFetchResolver(activeTab);
    const recentPatientsResponse = await doResentPatientsFetch(page, pageSizeForRecentPatient);
    if (recentPatientsResponse.data.patients.length > 0) {
      return { ...recentPatientsResponse.data, isRecent: true };
    }
  }

  if (trimmedSearchString.length > 0) {
    const doSearchPatientsFetch = searchPatientsFetchResolver(activeTab);
    const searchPatientsResponse = await doSearchPatientsFetch(page, pageSizeForSearch, trimmedSearchString);
    if (searchPatientsResponse.data.patients.length > 0) {
      return { ...searchPatientsResponse.data, isRecent: false };
    }
  }
}

function searchPatientsFetchResolver(activeTab) {
  if (activeTab === FPAT_PS) return PatientApi.getSearchPatients;
  if (activeTab === FPAT_PP) return PatientApi.getSearchPatientProfiles;
}

function resentPatientsFetchResolver(activeTab) {
  if (activeTab === FPAT_PS) return PatientApi.getRecentPatients;
  if (activeTab === FPAT_PP) return PatientApi.getRecentPatientsProfiles;
}
