import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';
import { isEmpty } from 'lodash/lang';

import { saveInvoiceDetails } from '../../../../../actions/finance/budget/updateStudyBudgetAction';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import {
  INVOICE_NOTE_AND_EMAILS_SAVE_FAILED,
  INVOICE_NOTE_AND_EMAILS_SAVED
} from '../../../../../constants/notificationMessages';

import { validateEmails } from './InvoiceNoteAndRecipientModalService';

export const InvoiceNoteAndRecipientModal = ({ modalBox, budgetId, invoiceNote, recipientEmails }) => {
  const dispatch = useDispatch();
  const [note, setNote] = useState(invoiceNote);
  const [emails, setEmails] = useState(Array.isArray(recipientEmails) ? recipientEmails.join(';') : recipientEmails);
  const [error, setError] = useState('');

  const onSave = useCallback(() => {
    dispatch(saveInvoiceDetails(budgetId, { note, emails }))
      .then(() => {
        modalBox.close();
        NotificationManager.success(INVOICE_NOTE_AND_EMAILS_SAVED);
      })
      .catch(() => {
        NotificationManager.error(INVOICE_NOTE_AND_EMAILS_SAVE_FAILED);
      });
  }, [budgetId, note, emails, modalBox, dispatch]);

  const handleEmailChange = useCallback(({ target: { value } }) => {
    const email = value.replace(/\s/g, '');
    setEmails(email);
    if (validateEmails(email) || email.length === 0) {
      setError('');
    } else {
      setError('Please enter semicolon-delimited Emails in correct format');
    }
  }, []);

  const maxNoteLength = 400;
  return (
    <>
      <ModalBoxes.Body>
        <TextField
          label="Invoice Note"
          data-testid="invoice-note"
          value={note}
          onChange={({ target: { value } }) => {
            setNote(value);
          }}
          inputProps={{ maxLength: maxNoteLength }}
          variant="outlined"
          multiline
          helperText={`${note?.length ?? 0}/${maxNoteLength}`}
          sx={{ width: '100%', '.MuiFormHelperText-root': { textAlign: 'end' } }}
        />
        <TextField
          label="Invoice Recipient(s)"
          data-testid="invoice-recipient"
          value={emails}
          onChange={handleEmailChange}
          variant="outlined"
          multiline
          error={!!error}
          helperText={error}
          sx={{ width: '100%', '.MuiFormHelperText-root': { textAlign: 'end' } }}
        />
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="low" onClick={modalBox.close}>
            Cancel
          </Button>
          <Button onClick={onSave} disabled={!isEmpty(error)} data-testid="invoice-note-modal-button">
            Save
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
};
