import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import moment from 'moment/moment';

import FinInvoiceReconcileHistoryApi from '../../../../../../api/finance/FinInvoiceReconcileHistoryApi';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../../constants/dateFormat';
import { SOMETHING_WENT_WRONG } from '../../../../../../constants/notificationMessages';
import { Amount } from '../../../shared/amount-view/Amount/Amount';
import { resolveEventSuffix } from '../../CreateInvoice/CreateInvoiceServices';

import './DepositHistoryModal.scss';

const DepositHistoryModal = ({ row }) => {
  const {
    eventId,
    invoiceId,
    name,
    eventNumber,
    studyName,
    siteName,
    encounterName,
    subjectId,
    budgetEventType,
    eventDate,
    itemGroupName,
    invoiceAmount,
    adjustmentSequence
  } = row;
  const [depositHistory, setDepositHistory] = useState([]);
  useEffect(() => {
    FinInvoiceReconcileHistoryApi.getReconcileHistory(invoiceId, eventId)
      .then(({ data }) => {
        setDepositHistory(data);
      })
      .catch(() => {
        NotificationManager.error(SOMETHING_WENT_WRONG);
      });
  }, [eventId, invoiceId]);

  return (
    <>
      <ModalBoxes.Header>Deposit History</ModalBoxes.Header>
      <ModalBoxes.Body>
        <div className="invoice-info-section">
          <div className="header">Info</div>
          <div className="info-items-wrapper">
            <InfoItem label="Event date" value={moment(eventDate).format('DD/MMM/YYYY')} />
            <InfoItem label="Study" value={studyName} />
            <InfoItem label="Site" value={siteName} />
            <InfoItem label="Subject ID" value={subjectId} />
            <InfoItem label="Encounter" value={encounterName} />
            <InfoItem label="Budget Event Type" value={budgetEventType} />
            <InfoItem label="Event Name" value={name} />
            <InfoItem label="Item Group" value={itemGroupName} />
          </div>
        </div>
        <div className="header">History</div>
        <div className="history-wrapper" data-testid="deposit-history-table">
          <div className="history-table-header-item">Event ID</div>
          <div className="history-table-header-item">Invoice Number</div>
          <div className="history-table-header-item">Deposit Number</div>
          <div className="history-table-header-item">Date Posted</div>
          <div className="history-table-header-item">Amount Paid</div>
          <div className="history-table-header-item">Variance</div>
          <div className="history-table-header-item">Comments</div>
          <div className="history-table-header-item">User Name</div>
          {depositHistory.map(item => (
            <HistoryRow
              item={item}
              invoiceAmount={invoiceAmount}
              key={item.id}
              eventNumber={eventNumber}
              adjustmentSequence={adjustmentSequence}
            />
          ))}
        </div>
      </ModalBoxes.Body>
    </>
  );
};

export const InfoItem = ({ label, value, multiline }) => {
  return (
    <div className={cx('info-item', { 'multi-line-content': multiline })} data-testid="info-item">
      <span className="info-item-label">{label}</span>
      <span className="info-item-value">{value || '—'}</span>
    </div>
  );
};

const HistoryRow = ({ item, invoiceAmount, eventNumber }) => {
  const getNumberToDisplay = (type, number, adjustmentSequence) => {
    if (adjustmentSequence === 0) {
      return resolveEventSuffix(number, type);
    } else {
      return resolveEventSuffix(`${number}.${adjustmentSequence}`, type);
    }
  };
  const varianceAmount = item.adjustmentHistoryRow ? 0 : item.totalAmountPaid - invoiceAmount;

  return (
    <>
      <div>{getNumberToDisplay(item.invoiceType, eventNumber, item.adjustmentSequence)}</div>
      <div>{item.invoiceNumber ? resolveEventSuffix(item.invoiceNumber, item.invoiceType) : '—'}</div>
      <div>{item.adjustmentHistoryRow ? 'N/A' : item.depositNumber}</div>
      <div>{moment.utc(item.postedDate).format(DD_SLASH_MMM_SLASH_YYYY)}</div>
      {item.adjustmentHistoryRow ? <div>N/A</div> : <Amount coinsAmount={item.amountPaid} showDollarSign />}
      <Amount coinsAmount={varianceAmount} showDollarSign />
      <div>{item.comment || '—'}</div>
      <div>{item.createdBy}</div>
    </>
  );
};

export const openDepositHistory = row => {
  ModalBoxes.open({
    component: <DepositHistoryModal row={row} />,
    className: 'deposit-history-modal',
    size: 'w1250'
  });
};
