import React from 'react';

import Button from '../../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../../common/general/ButtonGroup';

import './PatientContentConfirmation.scss';
export default function PatientContentConfirmation({ message, content, onConfirm, onDeny }) {
  return (
    <div className="patient-content-confirmation p-3">
      <span>{message}</span>
      <ButtonGroup>
        <Button priority="medium" onClick={onConfirm}>
          Yes
        </Button>
        <Button onClick={onDeny}>No</Button>
      </ButtonGroup>
    </div>
  );
}
