import { SBS } from '../../../../constants/accessToPages';

import BudgetDetails from './BudgetDetails/BudgetDetails';
import OptionalItemGroupSetup from './OptionalItemGroupsSetup/OptionalItemGroupSetup';
import StudyBudgetSetup from './Budget';

export const budgetRouterConfig = {
  name: 'Study Budget',
  path: '/study-budget',
  component: StudyBudgetSetup,
  access: [SBS],
  inherited: [
    {
      name: 'Budget Details',
      path: '/:id',
      component: BudgetDetails,
      access: [SBS],
      inherited: [
        {
          name: 'Exclude',
          path: '/optional-ig-setup',
          component: OptionalItemGroupSetup,
          access: [SBS]
        }
      ]
    }
  ]
};
