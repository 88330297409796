import { isNull } from 'lodash/lang';

import { CANCELED } from '../components/pages/patient-source/shared/Worklist/constants';

export function isCancelRowStatus(logRowStatus) {
  const piStatusCancel = logRowStatus?.piStatus === CANCELED;
  const smStatusCancel = logRowStatus?.smStatus === CANCELED;
  return piStatusCancel && smStatusCancel;
}

export function resolvePiStatus(status) {
  switch (status) {
    case 'DATA_REQUIRED':
      return 'Data Required';
    case 'REVIEW_REQUIRED':
      return 'Review Required';
    case 'COMPLETE':
      return 'Complete';
    default:
      return '';
  }
}

export function resolveSmStatus(status, isDataRequiredBySm) {
  switch (status) {
    case 'DATA_REQUIRED':
      return isNull(isDataRequiredBySm)
        ? 'Data Required'
        : isDataRequiredBySm
        ? 'Data Required by Study Manager'
        : 'Data Required by Investigator';
    case 'PI_REVIEW':
      return 'Investigator Review';
    case 'SM_REVIEW':
      return 'SM Check';
    case 'COMPLETE':
      return 'Complete';
    case 'AE_NOT_REPORTED':
      return 'AE Not Reported';
    case 'CANCELED':
      return 'Canceled';
    default:
      return '';
  }
}
