import React, { useContext, useMemo } from 'react';
import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import moment from 'moment';

import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../constants/dateFormat';
import { toBill } from '../../../../../services/financial';
import { Amount } from '../../shared/amount-view/Amount/Amount';
import { DefaultFinanceCell } from '../../shared/FinanseTableMUI/DefaultFinanceCell';
import { formatDateForCSV, prepareNameForCSV } from '../../shared/FinanseTableMUI/FinanceTableServices';
import { resolveEventSuffix } from '../CreateInvoice/CreateInvoiceServices';
import { InvoiceContext } from '../NewInvoiceContext';

import PaymentStatus from './PaymentStatus/PaymentStatus';
import { TableItemMenuMUI } from './TableItemMenu/TableItemMenuMUI';
import {
  eventNumberComparator,
  getDaysOpen,
  isCompleteStatus,
  isOpenStatus,
  isPaidStatus,
  isPartialStatus,
  isPastDueStatus
} from './InvoiceTableService';
import { COMPLETE, OPEN, PAID, PARENT, PARTIAL, PAST_DUE } from './TableConstants';

function useInvoiceTableColumns() {
  return useMemo(() => {
    return [
      {
        field: 'eventNumber',
        headerName: 'Event ID',
        flex: 1,
        minWidth: 125,
        sortComparator: eventNumberComparator,
        valueGetter: ({ row }) => {
          if (row.adjustmentSequence === 0) {
            return resolveEventSuffix(row.eventNumber, row.invoiceType);
          }
          return resolveEventSuffix(`${row.eventNumber}.${row.adjustmentSequence}`, row.invoiceType);
        }
      },
      {
        field: 'name',
        headerName: 'Event Name',
        flex: 1,
        minWidth: 150,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'invoiceNumber',
        headerName: 'Invoice',
        flex: 1,
        minWidth: 120,
        sortComparator: eventNumberComparator,
        valueGetter: ({ row: { displayNumber, invoiceType } }) =>
          displayNumber ? resolveEventSuffix(displayNumber, invoiceType) : '',
        renderCell: InvoiceNumberCell
      },
      {
        field: 'invoiceDate',
        headerName: 'Invoice Date',
        flex: 1,
        minWidth: 150,
        type: 'date',
        valueFormatter: ({ value }) => formatDateForCSV(value),
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row: { invoiceDate } }) => (
          <div>{invoiceDate ? moment(invoiceDate).format(DD_SLASH_MMM_SLASH_YYYY) : '—'}</div>
        )
      },
      {
        field: 'revenueDate',
        headerName: 'Revenue Date',
        flex: 1,
        minWidth: 160,
        type: 'date',
        valueFormatter: ({ value }) => formatDateForCSV(value),
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row: { revenueDate } }) => (
          <div>{revenueDate ? moment(revenueDate).format(DD_SLASH_MMM_SLASH_YYYY) : '—'}</div>
        )
      },
      {
        field: 'paymentStatus',
        headerName: 'Payment Status',
        flex: 1,
        minWidth: 170,
        valueGetter: ({ row }) => {
          if (isCompleteStatus(row)) return COMPLETE;
          else if (isOpenStatus(row)) return OPEN;
          else if (isPastDueStatus(row)) return PAST_DUE;
          else if (isPartialStatus(row)) return PARTIAL;
          else if (isPaidStatus(row)) return PAID;
          return '';
        },
        renderCell: StatusCell
      },
      {
        field: 'depositNumber',
        headerName: 'Deposit Number',
        flex: 1,
        minWidth: 180,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'postedDate',
        headerName: 'Date Posted',
        flex: 1,
        minWidth: 150,
        type: 'date',
        valueFormatter: ({ value }) => (value ? moment(value).format(DD_SLASH_MMM_SLASH_YYYY) : '-'),
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row }) => (
          <div>{row.postedDate ? moment.utc(row.postedDate).format(DD_SLASH_MMM_SLASH_YYYY) : '—'}</div>
        )
      },
      {
        field: 'invoiceAmount',
        headerName: 'Invoice Amount',
        flex: 1,
        minWidth: 170,
        type: 'number',
        valueGetter: ({ row }) => toBill(row.invoiceAmount),
        renderCell: ({ row }) => <Amount coinsAmount={row.invoiceAmount} showDollarSign />
      },
      {
        field: 'amountPaid',
        headerName: 'Amount Paid',
        flex: 1,
        minWidth: 160,
        type: 'number',
        valueGetter: ({ row }) => (isCompleteStatus(row) && row.amountPaid === 0 ? 'N/A' : toBill(row.amountPaid)),
        renderCell: ({ row }) =>
          isCompleteStatus(row) && row.amountPaid === 0 ? (
            <div>N/A</div>
          ) : (
            <Amount coinsAmount={row.amountPaid} showDollarSign />
          )
      },
      {
        field: 'variance',
        headerName: 'Variance',
        flex: 1,
        minWidth: 130,
        type: 'number',
        valueGetter: ({ row }) => {
          return isCompleteStatus(row) && row.amountPaid === 0
            ? `${toBill(0)}`
            : `${toBill(row.amountPaid - row.invoiceAmount)}`;
        },
        renderCell: ({ row }) => {
          return (
            <Amount
              className="MuiDataGrid-cellContent"
              showDollarSign
              coinsAmount={isCompleteStatus(row) && row.amountPaid === 0 ? 0 : row.amountPaid - row.invoiceAmount}
            />
          );
        }
      },
      {
        field: 'actions',
        type: 'actions',
        resizable: false,
        width: 50,
        renderCell: ({ row }) => <TableItemMenuMUI row={row} />
      },
      {
        field: 'studyName',
        headerName: 'Study',
        flex: 1,
        minWidth: 110,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'pcnName',
        headerName: 'PCN',
        flex: 1,
        minWidth: 100,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'siteName',
        headerName: 'Site',
        flex: 1,
        minWidth: 100,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'siteNumber',
        headerName: 'Site Number',
        flex: 1,
        minWidth: 150,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => (value != null ? `[${value}]` : '')
      },
      {
        field: 'clientName',
        headerName: 'Client',
        flex: 1,
        minWidth: 115,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'eventDate',
        headerName: 'Event Date',
        flex: 1,
        minWidth: 150,
        type: 'date',
        valueFormatter: ({ value }) => formatDateForCSV(value),
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row }) => {
          const content = moment(row.eventDate).format(DD_SLASH_MMM_SLASH_YYYY);
          return (
            <div className="MuiDataGrid-cellContent" title={content}>
              {content}
            </div>
          );
        }
      },
      {
        field: 'daysOpen',
        headerName: 'Days Open',
        flex: 1,
        minWidth: 140,
        valueGetter: ({ row }) =>
          getDaysOpen({ ...row, invoiceDate: row.invoiceDate ? moment(row.invoiceDate) : moment(0) }),
        renderCell: DefaultFinanceCell
      },
      {
        field: 'budgetEventType',
        headerName: 'Budget Event Type',
        flex: 1,
        minWidth: 190,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'epochName',
        headerName: 'Epoch',
        flex: 1,
        minWidth: 110,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'encounterName',
        headerName: 'Encounter',
        flex: 1,
        minWidth: 135,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'itemGroupName',
        headerName: 'Item Group',
        flex: 1,
        minWidth: 140,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'adjustmentReason',
        headerName: 'Adjustment Memo',
        flex: 1,
        minWidth: 190,
        renderCell: ({ value }) => (
          <Tooltip placement="top" title={<div>{value || '—'}</div>}>
            <span className="table-cell-tooltip">{value || '—'}</span>
          </Tooltip>
        )
      },
      {
        field: 'expenseVariableComment',
        headerName: 'Expense Comments',
        flex: 1,
        minWidth: 150,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'investigator',
        headerName: 'Investigator',
        flex: 1,
        minWidth: 150,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'patientId',
        headerName: 'Patient ID',
        flex: 1,
        minWidth: 135,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'subjectId',
        headerName: 'Subject ID',
        flex: 1,
        minWidth: 145,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => (value != null ? `[${value}]` : '')
      },
      {
        field: 'nonInvoiceable',
        headerName: 'Non-Invoiceable',
        minWidth: 180,
        valueGetter: ({ value }) => (value ? 'Yes' : 'No')
      }
    ];
  }, []);
}

const InvoiceNumberCell = ({
  row: { invoiceNumber, displayNumber, invoiceFileId, invoiceType, oldInvoice, invoiceFileCreated }
}) => {
  const { loadInvoice, getDataForAutomaticallyCreatedInvoice } = useContext(InvoiceContext);
  const textContent = displayNumber ? resolveEventSuffix(displayNumber, invoiceType) : '—';
  return invoiceFileId ? (
    <Button
      type="text"
      onClick={() => {
        if (invoiceFileId) {
          if (!invoiceFileCreated) {
            getDataForAutomaticallyCreatedInvoice(invoiceNumber, invoiceType, invoiceFileId);
          } else {
            const type = oldInvoice ? PARENT : invoiceType;
            loadInvoice(invoiceNumber, invoiceFileId, type);
          }
        }
      }}
      sx={{
        minWidth: 'min-content',
        padding: '0',
        color: '#2465C6',
        fontWeight: '400'
      }}
    >
      {textContent}
    </Button>
  ) : (
    <div>{textContent}</div>
  );
};

const StatusCell = ({ row, value }) => (
  <div className="cell-with-status">
    <div>{value}</div>
    <PaymentStatus status={value} hasInvoiceNumber={!!row?.invoiceNumber} />
  </div>
);

export default useInvoiceTableColumns;
