import React from 'react';
import { useNavigate } from 'react-router-dom';

import LoaderOverlay from '../../../../../../common/elements/Loader/LoaderOverlay';
import Button from '../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../common/general/ButtonGroup';
import withSkeleton from '../../../../../../common/hocs/withSkeleton';
import ItemGroupsEncountersTable from '../../../../../ItemGroupsEncountersTable/ItemGroupsEncountersTable';
import ItemGroupsEncounterTableSkeleton from '../../../../../ItemGroupsEncountersTable/ItemGroupsEncounterTableSkeleton/ItemGroupsEncounterTableSkeleton';
import { PageInfoHeader } from '../../../../../PageInfoHeader/PageInfoHeader';
import { generateUrlByKey, useCurrentRoute } from '../../../../../root/router';

import GroupsSetupHeaderCell from './GroupsSetupTableComponents/GroupsSetupHeaderCell/GroupsSetupHeaderCell';
import GroupsSetupRowTitleCell from './GroupsSetupTableComponents/GroupsSetupRowTitleCell/GroupsSetupRowTitleCell';
import GroupsSetupTableCell from './GroupsSetupTableComponents/GroupsSetupTableCell/GroupsSetupTableCell';
import ProtocolGroupsList from './ProtocolGroupsList/ProtocolGroupsList';
import {
  useProtocolGroupsSetupActions,
  useProtocolGroupsSetupState,
  withProtocolGroupsSetupProvider
} from './ProtocolGroupsSetupContext';
import withProtocolGroupsSetup from './withProtocolGroupsSetup';

import './ProtocolGroupsSetup.scss';

const GroupsSetupTable = withSkeleton(
  ItemGroupsEncounterTableSkeleton,
  withProtocolGroupsSetup(ItemGroupsEncountersTable)
);

function ProtocolGroupsSetup() {
  const navigate = useNavigate();
  const currentRoute = useCurrentRoute();
  const { studyId, protocolIdentity } = currentRoute.params;

  const {
    studyName,
    protocolName,
    currentVersion,
    epochs,
    itemGroups,
    loadingMainData,
    loadingGroupsConfiguration,
    selectedGroupType,
    groups,
    selectedGroupId
  } = useProtocolGroupsSetupState();
  const {
    changeAccessForAllEncountersItemGroupsConfiguration,
    changeAccessForAllItemGroupsConfiguration,
    saveConfiguration,
    cancelConfiguration
  } = useProtocolGroupsSetupActions();

  return (
    <>
      <PageInfoHeader
        objectRecordLabel={studyName}
        pageInfo={
          <PageInfoHeader.CollapsibleList>
            <PageInfoHeader.AdditionalInfo tooltip="Source Data Name">{protocolName}</PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo tooltip="Version">{currentVersion}</PageInfoHeader.AdditionalInfo>
          </PageInfoHeader.CollapsibleList>
        }
        right={
          <ButtonGroup>
            <Button priority="low" onClick={cancelConfiguration}>
              Cancel
            </Button>
            <Button onClick={saveConfiguration}>Save</Button>
          </ButtonGroup>
        }
      />
      <div className="protocol-groups-setup">
        <div className="protocol-groups-hints">
          <div className="protocol-groups-hint protocol-groups-hint-deny">Deny</div>
          <div className="protocol-groups-hint protocol-groups-hint-read">Read Only</div>
          <div className="protocol-groups-hint protocol-groups-hint-edit">Edit</div>
          <div className="protocol-groups-hint protocol-groups-hint-sign">Can Sign</div>
        </div>
        <div className="protocol-groups-content">
          <ProtocolGroupsList
            skeletonLoading={loadingMainData}
            groups={groups}
            selectedGroupId={selectedGroupId}
            selectGroup={selectGroup}
          />
          <GroupsSetupTable
            skeletonLoading={loadingMainData}
            epochs={epochs}
            itemGroups={itemGroups}
            Cell={function({ cellKey }) {
              return <GroupsSetupTableCell cellKey={cellKey} selectedGroupType={selectedGroupType} />;
            }}
            HeaderCell={function(props) {
              return (
                <GroupsSetupHeaderCell
                  {...props}
                  selectedGroupType={selectedGroupType}
                  changeAccessForAllEncountersItemGroupsConfiguration={
                    changeAccessForAllEncountersItemGroupsConfiguration
                  }
                />
              );
            }}
            RowTitleCell={function(props) {
              return (
                <GroupsSetupRowTitleCell
                  {...props}
                  selectedGroupType={selectedGroupType}
                  changeAccessForAllItemGroupsConfiguration={changeAccessForAllItemGroupsConfiguration}
                />
              );
            }}
          />
          {!loadingMainData && loadingGroupsConfiguration && <LoaderOverlay light />}
        </div>
      </div>
    </>
  );

  function selectGroup(groupId) {
    navigate(
      generateUrlByKey(currentRoute.key, {
        studyId,
        protocolIdentity,
        groupId
      }),
      { replace: true }
    );
  }
}

export default withProtocolGroupsSetupProvider(ProtocolGroupsSetup);
