import React from 'react';
import * as PropTypes from 'prop-types';

import Common from '../../../../../../../../common/common';

import CommentItemGroupNewTableContent from './CommentItemGroupNewTableContent/CommentItemGroupNewTableContent';
import OverviewItemGroupNewTableContent from './OverviewItemGroupNewTableContent/OverviewItemGroupNewTableContent';
import ResultsItemGroupNewTableContent from './ResultsItemGroupNewTableContent/ResultsItemGroupNewTableContent';

import './ItemGroupNewTable.scss';

export default function ItemGroupNewTable({ itemGroupSnapshotState }) {
  const {
    general: { rowSnapshotFormData }
  } = itemGroupSnapshotState;

  return (
    <div className="eds-review-item-group-element-table-reflection">
      <div className="eds-general-data-about-filling">
        <span>{rowSnapshotFormData?.form?.whoDidItName}</span>
        <span>{Common.formatDate(rowSnapshotFormData?.form?.whenWasItDone)}</span>
      </div>

      <OverviewItemGroupNewTableContent itemGroupSnapshotState={itemGroupSnapshotState} />
      <ResultsItemGroupNewTableContent itemGroupSnapshotState={itemGroupSnapshotState} />
      <CommentItemGroupNewTableContent itemGroupSnapshotState={itemGroupSnapshotState} />
    </div>
  );
}

ItemGroupNewTable.propTypes = {
  itemGroupSnapshotState: PropTypes.object
};
