import React from 'react';

import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';

import InformedConsentApi from '../../../../../../api/patient/InformedConsentApi';
import Common from '../../../../../../common/common';
import Icon from '../../../../../../common/general/Icon';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import {
  DOWNLOAD_HAS_NOT_YET_CLEARED_VIRUS_SCAN,
  DOWNLOAD_MISSING,
  VIRUS_DETECTED_IN_DOWNLOAD
} from '../../../../../../constants/notificationMessages';
import { scDarkBlue100 } from '../../../../../../constants/systemColors';
import { onFileOpen } from '../../../../study/StudyDetails/SiteDetails/InformedConsentTable';

import './InformedConsentFile.scss';

export default function InformedConsentFile({ informedConsentForm, ssuId, userFile, icfAnswerId }) {
  const name = `ICF ${informedConsentForm.typeName}  ${informedConsentForm.languageName}  ${Common.formatDate(
    informedConsentForm.publishDate
  )}${informedConsentForm?.version ? `_v${informedConsentForm.version}` : ''}`;
  return (
    <div
      className={'single-file-container'}
      onClick={() => {
        !userFile && onFileOpen(informedConsentForm.fileName, informedConsentForm.id, ssuId);
        userFile && onUserFileOpen(informedConsentForm.fileName, icfAnswerId, ssuId);
      }}
    >
      <Icon style={{ color: scDarkBlue100, fontSize: 20 }}>description</Icon>
      <div className={'file-name'}>{name}</div>
    </div>
  );
}

export const onUserFileOpen = (filename, icfAnswerId, ssuId) => {
  LoadingOverlay.showLoader();
  InformedConsentApi.downloadSignedForm(ssuId, icfAnswerId)
    .then(resp => {
      let file = new File([resp.data], filename, { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      LoadingOverlay.hideLoader();
      window.open(fileURL);
    })
    .catch(err => {
      LoadingOverlay.hideLoader();
      if (!err || !err.response) {
        throw err;
      } else if (err.response.status === 404) {
        NotificationManager.error(DOWNLOAD_MISSING);
      } else if (err.response.status === 409) {
        NotificationManager.warning(DOWNLOAD_HAS_NOT_YET_CLEARED_VIRUS_SCAN);
      } else if (err.response.status === 502) {
        NotificationManager.error(VIRUS_DETECTED_IN_DOWNLOAD);
      } else {
        throw err;
      }
    });
};
