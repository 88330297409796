import React, { Component } from 'react';
import { isEqual } from 'lodash/lang';

import { ProjectionSummaryRevenueApi, ProjectionTotalRevenueApi, StudySiteApi } from '../../../../api';
import Select from '../../../../common/data-entry/Select';
import Button from '../../../../common/general/Button';
import ButtonGroup from '../../../../common/general/ButtonGroup';
import { onFileSave } from '../../../../services/handlers';
import { PageInfoHeader } from '../../../PageInfoHeader/PageInfoHeader';
import { SSUFilter } from '../../../SSUFilter/SSUFilter';

import { prepareDataForSummary, prepareDataForTotal } from './ProjectedRevenueService/ProjectedRevenueService';
import ProjectedRevenueByType from './ProjectedRevenueByType';

class ProjectedRevenue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studyIdentifier: '',
      studySiteIdentifier: '',
      newConsentsAll: [],
      followupVisitsAll: [],
      newConsentsFiltered: [],
      followupVisitsFiltered: [],
      filteredSSUs: [],
      selectedSSUs: [],
      areas: [],
      visibleAreas: [],
      allSSUs: [],
      filteredAreasSSUs: [],
      total: defaultTotal,
      showAllSSUs: true,
      filteredAreas: [],
      showNewConsents: true,
      selectedArea: null,
      showFollowUpVisits: true,
      types: [
        { id: 1, name: 'New Consents' },
        { id: 2, name: 'Followup Visits' }
      ]
    };
  }

  componentDidMount() {
    StudySiteApi.getAllStudySitesAndMap().then(res => {
      const therapeuticAreaMap = {};
      let allAreas = [];
      res.data.forEach(x => {
        const area = x.study.therapeuticArea;
        const ssu = x.uniqueIdentifier;
        if (!therapeuticAreaMap.hasOwnProperty(area)) {
          therapeuticAreaMap[area] = [ssu];
        } else {
          therapeuticAreaMap[area].push(ssu);
        }
        if (!allAreas.some(a => a.name === x.study.therapeuticArea)) {
          allAreas.push({ name: x.study.therapeuticArea, ssu: x.uniqueIdentifier });
        }
      });
      allAreas.forEach((el, index) => {
        el.id = index + 1;
      });
      const studyMap = {};
      res.data.forEach(a => {
        studyMap[a.uniqueIdentifier] = {};
        studyMap[a.uniqueIdentifier].study = a.study;
        studyMap[a.uniqueIdentifier].site = a.site;
      });
      this.setState({
        newConsentsAll: res.data.map(v => ({ ...v, type: 'New Consents' })),
        newConsentsFiltered: res.data.map(v => ({ ...v, type: 'New Consents' })),
        followupVisitsAll: res.data.map(v => ({ ...v, type: 'Followup Visits' })),
        followupVisitsFiltered: res.data.map(v => ({ ...v, type: 'Followup Visits' })),
        therapeuticAreaMap: therapeuticAreaMap,
        areas: allAreas,
        visibleAreas: allAreas,
        allSSUs: res.data.map(ssu => ssu.uniqueIdentifier),
        allFullSSUs: studyMap
      });
    });
  }

  filterData = () => {
    const { allSSUs, selectedSSUs, filteredAreasSSUs } = this.state;
    let filteredSSUs = allSSUs;
    if (selectedSSUs.length > 0) {
      filteredSSUs = selectedSSUs;
    }
    if (filteredAreasSSUs.length > 0) {
      filteredSSUs = filteredSSUs.filter(el => filteredAreasSSUs.includes(el));
    }
    if (!isEqual(filteredSSUs, this.state.filteredSSUs)) {
      this.setState(prevState => {
        return {
          total: defaultTotal,
          filteredSSUs: filteredSSUs,
          followupVisitsFiltered: prevState.followupVisitsAll.filter(el => filteredSSUs.includes(el.uniqueIdentifier)),
          newConsentsFiltered: prevState.newConsentsAll.filter(el => filteredSSUs.includes(el.uniqueIdentifier))
        };
      });
    }
  };

  handleSSUFilterChange = (SSUs, study, site) => {
    const selectedSSUs = SSUs.map(ssu => ssu.uniqueIdentifier);
    this.setState({ selectedSSUs: selectedSSUs, showAllSSUs: !study && !site }, () => {
      this.filterData();
    });
  };

  handleAreaChange = area => {
    if (area) {
      const filteredAreasSSUs = this.state.therapeuticAreaMap[area.name];
      this.setState({ filteredAreasSSUs: filteredAreasSSUs }, () => {
        this.filterData();
      });
    } else {
      this.setState({ filteredAreasSSUs: [] }, () => {
        this.filterData();
      });
    }
  };

  handleTypeChange = selected => {
    this.setState({ selectedType: selected });
  };

  calculateTotal = () => {
    let total = defaultTotal;
    ProjectionTotalRevenueApi.getNewConsentsTotalProjection().then(res => {
      if (res && res.data) {
        total = res.data;
      }
      this.setState({
        total: prepareDataForTotal(total)
      });
    });
  };

  calculateSummary = () => {
    let followUpVisitsSummaryBySSUs = [];
    let newConsentsSummaryBySSUs = [];
    const { selectedType } = this.state;
    if (selectedType && selectedType.id === 1) {
      ProjectionSummaryRevenueApi.getNewConsentsProjection(this.state.filteredSSUs).then(response => {
        if (response && response.data) {
          newConsentsSummaryBySSUs = response.data;
        }
        this.setState({
          newConsentsFiltered: prepareDataForSummary(newConsentsSummaryBySSUs, this.state.allFullSSUs)
        });
      });
    }
    if (selectedType && selectedType.id === 2) {
      ProjectionSummaryRevenueApi.getFollowUpVisitsProjection(this.state.filteredSSUs).then(response => {
        if (response && response.data) {
          followUpVisitsSummaryBySSUs = response.data;
        }
        this.setState({
          followupVisitsFiltered: prepareDataForSummary(followUpVisitsSummaryBySSUs, this.state.allFullSSUs)
        });
      });
    }
    if (!selectedType) {
      const getFollowUpVisitsSummaryBySSUs = ProjectionSummaryRevenueApi.getFollowUpVisitsProjection(
        this.state.filteredSSUs
      ).then(res => res);

      const getNewConsentsSummaryBySSUs = ProjectionSummaryRevenueApi.getNewConsentsProjection(
        this.state.filteredSSUs
      ).then(res => res);

      Promise.all([getFollowUpVisitsSummaryBySSUs, getNewConsentsSummaryBySSUs]).then(
        ([followUpVisitsSummaryResponse, newConsentsSummaryResponse]) => {
          if (followUpVisitsSummaryResponse && followUpVisitsSummaryResponse.data) {
            followUpVisitsSummaryBySSUs = followUpVisitsSummaryResponse.data;
          }
          if (newConsentsSummaryResponse && newConsentsSummaryResponse.data) {
            newConsentsSummaryBySSUs = newConsentsSummaryResponse.data;
          }
          this.setState({
            followupVisitsFiltered: prepareDataForSummary(followUpVisitsSummaryBySSUs, this.state.allFullSSUs),
            newConsentsFiltered: prepareDataForSummary(newConsentsSummaryBySSUs, this.state.allFullSSUs)
          });
        }
      );
    }
  };

  downloadSummaryRevenuePrediction = () => {
    this.state.showAllSSUs
      ? ProjectionSummaryRevenueApi.exportSummaryRevenuePredictionTotal().then(onFileSave)
      : ProjectionSummaryRevenueApi.exportSummaryRevenuePrediction(this.state.filteredSSUs).then(onFileSave);
  };

  render({ props, state } = this) {
    const {
      newConsentsFiltered,
      followupVisitsFiltered,
      visibleAreas,
      types,
      selectedType,
      showAllSSUs,
      filteredAreasSSUs,
      total
    } = state;
    const showFollowUpVisits = (selectedType && selectedType.id === 2) || !selectedType;
    const showNewConsents = (selectedType && selectedType.id === 1) || !selectedType;
    const showTotal = showAllSSUs && showFollowUpVisits && showNewConsents && filteredAreasSSUs.length === 0;
    return (
      <div className="footpadb projection-revenue">
        <React.Fragment>
          <PageInfoHeader
            right={
              <ButtonGroup>
                <Button size="h28" type="button" onClick={showTotal ? this.calculateTotal : this.calculateSummary}>
                  Calculate
                </Button>
                <Button
                  priority="medium"
                  size="h28"
                  type="button"
                  onClick={() => this.downloadSummaryRevenuePrediction()}
                >
                  Export
                </Button>
              </ButtonGroup>
            }
          >
            <div className="general-header-group-container general-header-wrapper">
              <SSUFilter handleSSUFilterChange={this.handleSSUFilterChange} />
              <Select
                label="Therapeutic Area"
                clearSearchOnSelection={true}
                optionLabelKey="name"
                optionValueKey="id"
                dataSource={visibleAreas}
                onChange={this.handleAreaChange}
                searchable={true}
                validate={false}
              />
              <Select
                label="Type"
                clearSearchOnSelection={true}
                optionLabelKey="name"
                optionValueKey="id"
                dataSource={types}
                onChange={this.handleTypeChange}
                searchable={true}
                validate={false}
              />
            </div>
          </PageInfoHeader>
          {!showTotal && showNewConsents && (
            <ProjectedRevenueByType
              type="consents"
              data={newConsentsFiltered}
              showPagination={true}
              title="New Consents"
            />
          )}
          {!showTotal && showFollowUpVisits && (
            <ProjectedRevenueByType
              type="visits"
              showPagination={true}
              data={followupVisitsFiltered}
              title="Followup Visits"
            />
          )}
          {showTotal && (
            <div>
              <ProjectedRevenueByType total={true} showPagination={false} data={total} title={'Total'} />
            </div>
          )}
        </React.Fragment>
      </div>
    );
  }
}

export default ProjectedRevenue;

const defaultTotal = [
  { id: 1, type: 'New Consents' },
  { id: 2, type: 'Followup visits' },
  { id: 3, type: 'Total' }
];
