import axios from 'axios';

const ROOT = 'api/v1/ssu-patients';

const UnexpectedEncounterApi = {
  createUnexpectedEncounter(ssuPatientId, data) {
    return axios.post(`${ROOT}/${ssuPatientId}/unexpected-encounters`, data);
  },
  addItemGroupsToUnexpectedEncounter(ssuPatientId, encounterId, data) {
    return axios.put(`${ROOT}/${ssuPatientId}/unexpected-encounters/${encounterId}`, data);
  },
  removeItemGroupFromUnexpectedEncounter(ssuPatientId, sitePatientEncounterId, sitePatientItemGroup) {
    return axios.delete(
      `${ROOT}/${ssuPatientId}/unexpected-encounters/${sitePatientEncounterId}/item-groups/${sitePatientItemGroup}`
    );
  }
};

export default UnexpectedEncounterApi;
