import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import cx from 'classnames';
import { isEmpty } from 'lodash/lang';
import { sum } from 'lodash/math';

import { PatientEncounterSearchApi } from '../../../../../../../api';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import { SOMETHING_WENT_WRONG } from '../../../../../../../constants/notificationMessages';
import { useCurrentRoute } from '../../../../../../root/router';

import { ALL, ENCOUNTERS, LOGS, PROCEDURES } from './SearchConstants';
let cancelTokenForEncounters = null;
let cancelTokenForProcedures = null;
let cancelTokenForLogs = null;
export const useFetchSearchData = (page, searchString, selectedTab) => {
  const [searchData, setSearchData] = useState([]);
  const [counts, setCounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const currentRoute = useCurrentRoute();
  const { ssuPatientId } = currentRoute.params;

  const searchByEncounters = (request, token) => {
    return PatientEncounterSearchApi.searchByEncounters(request, token).then(({ data: encounters }) => {
      encounters.name = 'Encounters';
      return [encounters];
    });
  };

  const searchByProcedures = (request, token) => {
    return PatientEncounterSearchApi.searchByProcedures(request, token).then(({ data: procedures }) => {
      procedures.name = 'Procedures';
      return [procedures];
    });
  };

  const searchByLogs = (request, token) => {
    return PatientEncounterSearchApi.searchByLogs(request, token).then(({ data: logs }) => {
      logs.name = 'Logs';
      return [logs];
    });
  };
  const clearPreviousSearch = () => {
    if (!!cancelTokenForEncounters) {
      cancelTokenForEncounters.cancel();
    }
    if (!!cancelTokenForProcedures) {
      cancelTokenForProcedures.cancel();
    }
    if (!!cancelTokenForLogs) {
      cancelTokenForLogs.cancel();
    }
  };

  const resolveApiForGetData = useCallback(request => {
    clearPreviousSearch();
    switch (request.selectedTab) {
      case ALL:
        cancelTokenForEncounters = axios.CancelToken.source();
        cancelTokenForProcedures = axios.CancelToken.source();
        cancelTokenForLogs = axios.CancelToken.source();
        return Promise.all([
          searchByEncounters(request, cancelTokenForEncounters.token),
          searchByProcedures(request, cancelTokenForProcedures.token),
          searchByLogs(request, cancelTokenForLogs.token)
        ]).then(([encounters, procedures, logs]) => {
          return [...encounters, ...procedures, ...logs];
        });
      case ENCOUNTERS:
        cancelTokenForEncounters = axios.CancelToken.source();
        return Promise.resolve(searchByEncounters(request, cancelTokenForEncounters.token));
      case PROCEDURES:
        cancelTokenForProcedures = axios.CancelToken.source();
        return Promise.resolve(searchByProcedures(request, cancelTokenForProcedures.token));
      case LOGS:
        cancelTokenForLogs = axios.CancelToken.source();
        return Promise.resolve(searchByLogs(request, cancelTokenForLogs.token));
      default:
        Promise.reject();
    }
  }, []);

  useEffect(() => {
    setSearchData([]);
  }, [selectedTab, searchString]);

  useEffect(() => {
    !!searchString?.trim() && setCounts({});
  }, [searchString]);

  useEffect(() => {
    if (!!searchString?.trim()) {
      setLoading(true);
      const delayForSearchRequest = setTimeout(() => {
        const request = { selectedTab, searchString: searchString.trim(), page, ssuPatientId };
        resolveApiForGetData(request)
          .then(res => {
            setHasMore(res[0].last === false);
            setSearchData(prevState => {
              if (isEmpty(prevState) || prevState.length > 1) {
                return res;
              } else {
                return [
                  {
                    ...prevState[0],
                    content: [...prevState[0].content, ...res[0].content]
                  }
                ];
              }
            });
          })
          .catch(err => {
            !axios.isCancel(err) && NotificationManager.error(SOMETHING_WENT_WRONG);
          })
          .finally(() => setLoading(false));
      }, 600);
      return () => clearTimeout(delayForSearchRequest);
    }
  }, [ssuPatientId, searchString, page, selectedTab, resolveApiForGetData]);

  useEffect(() => {
    if (!!searchString?.trim()) {
      const delayForSearchRequest = setTimeout(() => {
        const request = { searchString: searchString?.trim(), ssuPatientId };
        PatientEncounterSearchApi.allCounts(request).then(({ data: counts }) => setCounts(counts));
      }, 400);
      return () => clearTimeout(delayForSearchRequest);
    }
  }, [ssuPatientId, searchString]);

  return { loading, hasMore, searchData, counts };
};

export const TabElement = ({ label, selectedTab, onSelect, counts }) => {
  let count = 0;
  if (counts) {
    if (label === ALL) {
      count = sum(Object.values(counts));
    } else {
      count = counts[label.toLowerCase() + 'Count'] || 0;
    }
  }
  return (
    <div className={cx('tab-element', { selected: selectedTab === label })} onClick={() => onSelect(label)}>
      {label} {<span className={'tab-elements-count'}>{count}</span>}
    </div>
  );
};

export const getRegExpFromSearchStringForId = text =>
  text.replaceAll(/[[+|(){\\?^$.*}]/gi, '\\$&').replace(/\d/gi, '$&-?');
