import { useCallback, useEffect } from 'react';
import { EventEmitter } from 'events';

import { MapsApi } from '../../../../../../../../api';
import { getCountryName, getRegionName } from '../../../../../../../../services/geographic';

const AddressValidatorEventEmitter = new EventEmitter();

export default function AddressValidator({ data, setAddressFields }) {
  const revalidate = useCallback(
    function() {
      const { countryId, regionId, city, address1: address, zipCode: zip } = data;
      if (!countryId || !regionId || !city || !address) {
        return;
      }
      MapsApi.validateAddress({
        country: getCountryName(countryId),
        region: getRegionName(regionId, countryId),
        city,
        address,
        zip: zip || null
      }).then(
        function({ data }) {
          if (data.validIgnoringZip && data.zip) {
            setAddressFields({ valid: true, zipCode: data.zip });
          } else {
            setAddressFields({ valid: false });
          }
        },
        () => {
          setAddressFields({ valid: false });
        }
      );
    },
    [data, setAddressFields]
  );

  useEffect(
    function() {
      AddressValidatorEventEmitter.on('change-state', listener);
      return function() {
        AddressValidatorEventEmitter.removeListener('change-state', listener);
      };
      function listener({ type, key }) {
        if (type === 'REVALIDATE') {
          revalidate(key);
        }
      }
    },
    [revalidate]
  );

  return null;
}

AddressValidator.revalidate = function(key) {
  setTimeout(function() {
    AddressValidatorEventEmitter.emit('change-state', { type: 'REVALIDATE', key });
  }, 100);
};
