import React from 'react';
import { Menu, MenuItem } from '@szhsin/react-menu';
import cx from 'classnames';

import Button from '../../../../../../common/general/Button';
import Icon from '../../../../../../common/general/Icon';

import './StudyManagerSelector.scss';

export default function StudyManagerSelector({ value, items, onChange }) {
  return (
    <div className="study-manager-selector">
      <Menu
        menuButton={
          <Button priority="medium" size="h28" className="menu-button">
            {value ? value.name : 'Select'}
            <Icon>arrow_drop_down</Icon>
          </Button>
        }
      >
        {items.map((e, idx) => (
          <MenuItem key={idx} className={cx({ selected: value === e })} onClick={() => onChange(e)}>
            {e.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
