import React from 'react';
import { Link } from 'react-router-dom';

import { generateUrlByKey, useCurrentRoute } from '../../../../../../root/router';
import { COMPLETED_ICF_TITLE } from '../../ICFSection/constants';

import './InformedConsentWidget.scss';

export const InformedConsentWidget = () => {
  const currentRoute = useCurrentRoute();

  return (
    <div className="eds-icf-widget">
      <div className="icfw-header">Informed Consent</div>
      <div className="icfw-item">
        <Link
          className={'icf-completed-link'}
          to={generateUrlByKey(`${currentRoute?.key}.Completed Informed Consents`, {
            patientId: currentRoute.params.patientId,
            ssuPatientId: currentRoute.params.ssuPatientId
          })}
        >
          {COMPLETED_ICF_TITLE}
        </Link>
      </div>
    </div>
  );
};
