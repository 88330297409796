import React, { useContext, useEffect, useState } from 'react';
import { isString } from 'lodash/lang';
import * as PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';

import { PatientEncounterReviewApi } from '../../../../../../../../../api';
import ModalBoxes from '../../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { FILE_TYPES } from '../../../../../../../../../constants/inputTypes';
import LogsTable from '../../../../../../Patients/Logs/LogsTable';
import { CustomCell, defaultCellWidth } from '../../../../../../Patients/Logs/LogsTableService';
import { ReviewContext } from '../../../../ReviewContext';
import { isSmOrPiReview } from '../../../../reviewService';
import ElementHistoryLogModal from '../../../HistoryLogModal/ElementHistoryLogModal';
import { useReviewContentElement } from '../../../ReviewContentElementContext/ReviewContentElementContext';
import * as reviewContentService from '../../../reviewContentService';
import { resolveAnswerNoPopulatedReason } from '../../../reviewContentService';
import { FileView } from '../../FileView/FileView';
import {
  filterMetaDataComment,
  findQuestionLabel,
  getFieldValue,
  processMultiselectAnswerValues,
  sortBySequence
} from '../ItemGroupNewTableService';

import './ResultsItemGroupNewTableContent.scss';

export default function ResultsItemGroupNewTableContent({ itemGroupSnapshotState }) {
  const { isAllowSign } = useReviewContentElement();

  const [columns, setColumns] = useState([]);
  const [dataToRender, setDataToRender] = useState([]);

  const { isHistoricalData } = useContext(ReviewContext);
  const { general, labels } = itemGroupSnapshotState;
  const { ssuPatientId, reviewType } = useContext(ReviewContext);

  const predefinedTableTitle = 'predefinedTableTitle';
  const historyLabel = 'historyLabel';

  useEffect(() => {
    const preparedDataForRendering = getPreparedDataForRendering();
    setColumns(getColumns(preparedDataForRendering, 'VERTICAL'));
    setDataToRender(getDataToRender(preparedDataForRendering, 'VERTICAL'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels, itemGroupSnapshotState.rows]);

  function getPreparedDataForRendering() {
    return labels?.sort(sortBySequence)?.map(label => {
      return {
        labelName: label.name,
        labelId: label.labelId,
        ...getAnswers(label.labelId)
      };
    });

    function getAnswers(labelId) {
      const itemGroupSnapshotRow = itemGroupSnapshotState.rows.find(row => row?.rowRef?.predefinedValue === labelId);
      const rowSnapshotFormData = itemGroupSnapshotRow?.rowSnapshotFormData;
      const answers = rowSnapshotFormData?.form?.answers;
      return {
        editedAfterSignQuestionIds: itemGroupSnapshotRow?.editedAfterSignQuestionIds,
        editedQuestionIds: itemGroupSnapshotRow?.editedQuestionIds,
        rowReviewStatus: itemGroupSnapshotRow?.rowReviewStatus,
        rowId: rowSnapshotFormData?.rowId,
        answers: answers?.sort(sortBySequence)?.map(answer => {
          return {
            ...answer,
            question: findQuestionLabel(answer?.itemGroupQuestionId, itemGroupSnapshotState?.itemGroupTemplateVersion)
          };
        })
      };
    }
  }

  function getColumns() {
    const rowSnapshotFormData = general?.rowSnapshotFormData;
    const rowSnapshotFormDataForm = rowSnapshotFormData?.form;
    const generalAnswers = rowSnapshotFormDataForm?.answers;

    const columns = itemGroupSnapshotState?.itemGroupTemplateVersion?.itemGroupVersionQuestions
      ?.filter(question => !generalAnswers.find(answer => answer.itemGroupQuestionId === question?.questionId))
      ?.map(question => {
        return {
          Header: question?.label,
          accessor: question?.questionId,
          investigatorField: question?.investigatorField,
          Cell: row => {
            const rowColumnId = row?.column?.id;
            const editedQuestionIds = row?.original?.editedQuestionIds;
            const rowValue = row?.value;
            return (
              <div className="erc-new-table-answer-layout">
                <div className={editedQuestionIds && editedQuestionIds?.includes(rowColumnId) ? 'change-bullet' : ''}>
                  {rowValue && isString(rowValue) && rowValue?.includes('Not Asked') && (
                    <Tooltip
                      destroyTooltipOnHide={false}
                      overlayClassName="eds-rc-tooltip logs-table-tooltip over-modal"
                      placement="top"
                      trigger={['hover']}
                      overlay={rowValue}
                    >
                      <div>Not Asked</div>
                    </Tooltip>
                  )}
                  {rowValue && isString(rowValue) && rowValue?.includes('Unavailable') && (
                    <Tooltip
                      destroyTooltipOnHide={false}
                      overlayClassName="eds-rc-tooltip logs-table-tooltip over-modal"
                      placement="top"
                      trigger={['hover']}
                      overlay={rowValue}
                    >
                      <div>Unavailable</div>
                    </Tooltip>
                  )}
                  {rowValue &&
                    isString(rowValue) &&
                    !rowValue?.includes('Unavailable') &&
                    !rowValue?.includes('Not Asked') && <CustomCell shortAnswer={rowValue} longAnswer={rowValue} />}
                  {rowValue && !isString(rowValue) && <CustomCell shortAnswer={rowValue} longAnswer={rowValue} />}
                  {!rowValue && '--'}
                </div>
              </div>
            );
          }
        };
      });

    return [
      {
        Header: general?.title,
        accessor: predefinedTableTitle,
        className: 'text-left pl-2',
        minWidth: 120
      },
      ...columns,
      {
        Header: 'History',
        minWidth: 75,
        width: 75,
        className: 'edited-link text-left pl-2',
        accessor: historyLabel,
        getProps: (state, row) => ({ onClick: () => openTableHistory(row) }),
        Cell: () => (!isHistoricalData ? 'History' : '')
      }
    ];
  }

  function getDataToRender(preparedData, modeToDisplay) {
    return preparedData?.map(answer => {
      return {
        rowId: answer.rowId,
        rowReviewStatus: answer?.rowReviewStatus,
        predefinedTableTitle: answer.labelName,
        ...convertAnswersToCell(answer),
        historyLabel: 'History'
      };
    });
  }

  function convertAnswersToCell(preparedDataRow) {
    const answers = preparedDataRow?.answers;
    const cell = {
      highlights: [],
      editedAfterSignQuestionIds: [],
      editedQuestionIds: []
    };

    answers?.forEach(answer => {
      const questionId = answer?.question?.questionId;
      if (answer.highlighted) {
        cell.highlights.push(questionId);
      }

      if (preparedDataRow?.editedAfterSignQuestionIds?.includes(questionId)) {
        cell.editedAfterSignQuestionIds.push(questionId);
      }

      if (preparedDataRow?.editedQuestionIds?.includes(questionId)) {
        cell.editedQuestionIds.push(questionId);
      }
      cell[questionId] = getAnswerValue(answer);
    });

    return cell;
  }

  function getAnswerValue(answer) {
    if (FILE_TYPES.includes(answer?.inputType) && answer?.fileValue?.files?.length > 0) {
      return <FileView files={answer.fileValue.files} />;
    }
    return getFieldValue(answer?.inputType, answer?.stringValue) || resolveAnswerNoPopulatedReason(answer);
  }

  function openTableHistory(row) {
    const ssuPatientEncounterId = itemGroupSnapshotState.itemGroupRef.patientEncounterId;
    const itemGroupId = itemGroupSnapshotState.itemGroupRef.patientItemGroupId;
    const rowId = row?.original?.rowId;
    const itemGroupName = `${itemGroupSnapshotState.itemGroupRef.patientItemGroupName} Results`;

    const onLoadData = () =>
      PatientEncounterReviewApi.getItemGroupAnswerHistory(ssuPatientId, ssuPatientEncounterId, itemGroupId, rowId)
        ?.then(filterMetaDataComment)
        ?.then(processMultiselectAnswerValues);

    ModalBoxes.open({
      component: (
        <ElementHistoryLogModal
          itemGroupName={itemGroupName}
          onLoadData={onLoadData}
          filterMetaData={itemMetaData => itemMetaData?.id !== 'COVAL'}
        />
      ),
      title: `${itemGroupName}`
    });
  }

  function getTrProps(state, rowInfo) {
    const rowReviewStatus = rowInfo?.original?.rowReviewStatus;
    return reviewContentService.getFormHighlightStyleBasedOnRowReviewStatusAndReviewType(
      rowReviewStatus,
      reviewType,
      isAllowSign
    );
  }

  function getTdProps(state, rowInfo, tdProp) {
    const questionId = tdProp?.id;
    const origin = rowInfo?.original;

    if (origin?.highlights?.includes(questionId)) {
      return {
        className: 'answer-required-attention-bg'
      };
    }
    if (isSmOrPiReview(reviewType) && origin?.editedAfterSignQuestionIds?.includes(questionId) && isAllowSign) {
      return { className: 'edited-data-after-sign-bg' };
    }

    return {};
  }

  return (
    <div className="erc-new-table-fields-content">
      <div className="erc-item-group-element-table-reflection">
        <div className="erc-new-table-review-title">
          <span>Results</span>
        </div>
        <LogsTable
          columns={columns}
          data={dataToRender}
          getTrProps={getTrProps}
          getTdProps={getTdProps}
          sortable={false}
          showPagination={false}
          pageSize={dataToRender?.length || 1}
          defaultCellWidth={defaultCellWidth}
        />
      </div>
    </div>
  );
}

ResultsItemGroupNewTableContent.propTypes = {
  itemGroupSnapshotState: PropTypes.object
};
