export const optionsToIds = options => options?.map(i => i.id);

export function filterSitesByStudyIdsAndSsus(ssus, study, sites) {
  if (!Array.isArray(study)) {
    const filteredSiteIds = ssus?.filter(ss => ss.studyIdentifier === study?.id)?.map(ss => ss.siteIdentifier);
    return sites?.filter(s => filteredSiteIds.includes(s.id));
  } else if (Array.isArray(study)) {
    const studyIds = optionsToIds(study);
    const filteredSiteIds = ssus?.filter(ss => studyIds.includes(ss.studyIdentifier))?.map(ss => ss.siteIdentifier);
    return sites?.filter(s => filteredSiteIds.includes(s.id));
  }
}

export function filterSitesByStudyIdsAndCanceledSsus(study, sites, ssus) {
  if (!Array.isArray(study)) {
    const filteredSiteIds = ssus
      ?.filter(ss => ss.studyIdentifier === study?.id && ss.siteStatus !== 'Canceled')
      ?.map(ss => ss.siteIdentifier);
    return sites?.filter(s => filteredSiteIds.includes(s.id));
  } else if (Array.isArray(study)) {
    const studyIds = optionsToIds(study);
    const filteredSiteIds = ssus
      ?.filter(ss => studyIds.includes(ss.studyIdentifier) && ss.siteStatus !== 'Canceled')
      ?.map(ss => ss.siteIdentifier);
    return sites?.filter(s => filteredSiteIds.includes(s.id));
  }
}

export function filterPcnsByStudyIdsAndSsus(ssus, study, pcn) {
  if (!Array.isArray(study)) {
    const pcnId = ssus?.find(ss => study?.id === ss.studyIdentifier)?.study?.projectCode;
    return pcn?.find(item => item.id === pcnId) || {};
  } else if (Array.isArray(study)) {
    const studyIds = optionsToIds(study);
    const pcnIds = ssus?.filter(ss => studyIds?.includes(ss.studyIdentifier)).map(ss => ss?.study?.projectCode);
    return pcn?.filter(item => pcnIds.includes(item.id)) || [];
  }
}
export function filterStudiesByPcnIdsAndSsus(ssus, study, pcn, selectedStudy) {
  if (!Array.isArray(pcn)) {
    const studyIds = ssus?.filter(ss => pcn?.id === ss?.study?.projectCode);
    const studyId = ssus?.find(ss => pcn?.id === ss?.study?.projectCode)?.studyIdentifier;
    if (studyIds.some(i => i.studyIdentifier === selectedStudy?.id)) {
      return selectedStudy;
    }
    return study?.find(item => item.id === studyId);
  } else if (Array.isArray(pcn)) {
    const pcnIds = optionsToIds(pcn);
    const studyIds = ssus?.filter(ss => pcnIds?.includes(ss?.study?.projectCode))?.map(ss => ss.studyIdentifier);
    return study?.filter(item => studyIds.includes(item.id)) || [];
  }
}

export function filterStudyTypesBasedOnSelectedStudyIds(studyTypes, selectedStudies, studySites) {
  const selectedStudyIds = optionsToIds(selectedStudies);
  const filteredStudyTypeIds =
    studySites
      ?.filter(ss => selectedStudyIds.includes(ss.studyIdentifier))
      .map(ss => (ss.study.nonTraditional && 'non-traditional') || 'traditional') || [];
  return studyTypes.filter(t => filteredStudyTypeIds.includes(t?.id));
}

export function filterTherapeuticAreasBasedOnSelectedStudyIds(therapeuticAreas, selectedStudies, ssus) {
  const selectedStudyIds = optionsToIds(selectedStudies);
  const filteredTherapeuticAreas =
    ssus?.filter(ss => selectedStudyIds.includes(ss.studyIdentifier)).map(ss => ss.study.therapeuticArea) || [];
  return therapeuticAreas.filter(t => filteredTherapeuticAreas.includes(t?.name));
}

export function filterSitesBasedOnSelectedStudyIds(studies, sites, ssus) {
  const selectedStudyIds = optionsToIds(studies);
  const filteredSiteIds =
    ssus?.filter(ss => selectedStudyIds.includes(ss.studyIdentifier))?.map(ss => ss.siteIdentifier) || [];
  return sites?.filter(s => filteredSiteIds.includes(s?.id)) || [];
}

export function filterPcnsBasedOnSiteIds(pcns, sites, ssus) {
  const siteIds = optionsToIds(sites);
  const filteredPcns = ssus?.filter(ss => siteIds.includes(ss.siteIdentifier)).map(ss => ss.study?.projectCode) || [];
  return pcns?.filter(t => filteredPcns.includes(t?.id));
}

export function filterStudiesBasedOnSiteIds(studies, sites, ssus) {
  const siteIds = optionsToIds(sites);
  const filteredStudyIds = ssus.filter(ss => siteIds.includes(ss.siteIdentifier)).map(ss => ss.studyIdentifier);
  return studies.filter(s => filteredStudyIds.includes(s.id));
}
