import React, { useCallback, useState } from 'react';
import { TextField } from '@mui/material';

import SitePaymentsApi from '../../../../../../../api/finance/SitePaymentsApi';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';

export const RejectModal = ({ modalBox, checkedEvents, applyFilter }) => {
  const [rejectionReason, setRejectionReason] = useState('');

  const onReject = useCallback(() => {
    SitePaymentsApi.rejectSitePayments(checkedEvents, rejectionReason.trim())
      .then(() => {
        modalBox.close();
        applyFilter();
        NotificationManager.success('Event(s) successfully Rejected');
      })
      .catch(() => {
        NotificationManager.error('Event(s) unable to be Rejected, please try again');
      });
  }, [applyFilter, checkedEvents, modalBox, rejectionReason]);

  const maxRejectionReasonLength = 500;
  return (
    <>
      <ModalBoxes.Body>
        <TextField
          label="Rejection Reason"
          data-testid="rejection-reason-input"
          value={rejectionReason}
          onChange={({ target: { value } }) => {
            setRejectionReason(value);
          }}
          inputProps={{ maxLength: maxRejectionReasonLength }}
          variant="standard"
          multiline
          helperText={`${rejectionReason?.length ?? 0}/${maxRejectionReasonLength}`}
          sx={{ width: '100%', '.MuiFormHelperText-root': { textAlign: 'end' } }}
        />
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="low" onClick={modalBox.close}>
            Cancel
          </Button>
          <Button onClick={onReject} data-testid="reject-modal-button">
            Reject
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
};
