import React, { useEffect, useState } from 'react';
import { uniqBy } from 'lodash/array';
import { orderBy } from 'lodash/collection';

import { HomePageDashboardsConfigApi } from '../../../api';

export const HomePageContext = React.createContext(null);

export default function HomePageDashboardsContext({ children }) {
  const [studies, setStudies] = useState([]);
  const [ssus, setSsus] = useState([]);

  useEffect(function() {
    HomePageDashboardsConfigApi.getAllStudySites().then(res => {
      setSsus(res.data);
      const uniqStudies = uniqBy(
        res.data.map(ssu => ({
          id: ssu.study.id,
          name: ssu.study.name
        })),
        e => e.id
      );
      setStudies(orderBy(uniqStudies, e => e.name));
    });
  }, []);

  return <HomePageContext.Provider value={{ ssus, studies }}>{children}</HomePageContext.Provider>;
}

export function WithHomePageContext(Component) {
  return function WrapperComponent(props) {
    return <HomePageContext.Consumer>{() => <Component {...props} />}</HomePageContext.Consumer>;
  };
}
