import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { Button, MobileStepper } from '@mui/material';

import { PayableToCard } from './PayableToCard/PayableToCard';

import './PayableToSection.scss';

export const PayableToSection = ({ openPayableToModal, payees, loadActivePayee }) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepChange = step => {
    setActiveStep(step);
  };

  const maxSteps = payees?.length;

  return (
    <div className="payable-to-section">
      <SwipeableViews index={activeStep} onChangeIndex={handleStepChange}>
        {payees.map((payees, idx) => (
          <div className="cards-container" key={`cards-container-${idx}`} data-testid={`swipe-container-${idx}`}>
            {payees?.map((payee, i) => (
              <PayableToCard
                payee={payee}
                key={`${payee.id} &${i}`}
                openPayableToModal={openPayableToModal}
                loadActivePayee={loadActivePayee}
              />
            ))}
          </div>
        ))}
      </SwipeableViews>
      <MobileStepper
        sx={{
          width: 'fit-content',
          backgroundColor: 'transparent',
          alignSelf: 'end',
          paddingRight: 0,
          '.MuiButton-root': {
            outline: 'none',
            color: '#691E44',
            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.26)'
            }
          },
          '.MuiMobileStepper-dotActive': {
            backgroundColor: '#691E44'
          }
        }}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Next
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            Back
          </Button>
        }
      />
    </div>
  );
};
