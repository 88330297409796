import React, { useState } from 'react';
import cx from 'classnames';
import { isEmpty, isFunction } from 'lodash/lang';

import LoaderOverlay from '../../../elements/Loader/LoaderOverlay';
import ModalBoxes, { ModalBoxContext } from '../ModalBoxes';

import './ModalBoxComponent.scss';

export default function ModalBoxComponent({ id, onCloseCallback, component, className, close, title }) {
  const [loading, setLoading] = useState(false);
  return (
    <ModalBoxContext.Provider value={{ loading, setLoading, id, close: onClose }}>
      <div className={cx('eds-modal-box', className)}>
        <div className="eds-modal-box-overlay" />
        <div className="eds-modal-box-container">
          {!isEmpty(title) && <ModalBoxes.Header>{title}</ModalBoxes.Header>}
          {React.cloneElement(component, {
            modalBox: {
              id,
              close: onClose
            }
          })}
          {loading && <LoaderOverlay light />}
        </div>
      </div>
    </ModalBoxContext.Provider>
  );

  function onClose(data) {
    isFunction(onCloseCallback) && onCloseCallback(data);
    close();
  }
}
