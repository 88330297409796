import { createBrowserRouter } from 'react-router-dom';

import Root from '../Root';

import { appRoutes } from './config';

let router = null;

export default function memoizedRouter() {
  if (router) return router;
  return (router = createBrowserRouter([
    {
      path: '/',
      element: <Root />,
      children: appRoutes
    }
  ]));
}
