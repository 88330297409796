import { some } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import { PROCESS_CONDITION_MISMATCH } from '../../../../../../../constants/errorMessages';
import { FILE_TYPES } from '../../../../../../../constants/inputTypes';
import { CHECKBOX_REVIEW } from '../../../../../../../constants/notificationMessages';
import { collectAllQuestionsField } from '../services/fieldsServices';

export default function processCheckReview(encounterTableItemGroup, setIsCertifiedCopyWasNotConfirmed) {
  return new Promise(function(resolve, reject) {
    const fieldIds = [];
    const allFiles = collectAllQuestionsField(encounterTableItemGroup).filter(
      field => FILE_TYPES.includes(field.fieldType) && !isEmpty(field?.fileList)
    );
    const fileList = allFiles.flatMap(field => field.fileList);

    allFiles.forEach(item => fieldIds.push(item.fieldUid));

    if (some(fileList, file => file.isCertifyCopy && !file.isCertifyCopyConfirmed)) {
      setIsCertifiedCopyWasNotConfirmed(fieldIds);
      NotificationManager.error(CHECKBOX_REVIEW);
      reject(new Error(PROCESS_CONDITION_MISMATCH));
      return;
    }

    resolve(encounterTableItemGroup);
  });
}
