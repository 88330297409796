import { useEffect, useState } from 'react';
import { useBlocker } from 'react-router-dom';
import { EventEmitter } from 'events';
import { remove } from 'lodash/array';
import { isEmpty, isNull } from 'lodash/lang';

const HistoryBlockEventEmitter = new EventEmitter();

export const TASKS_CONTAINER = 'TASKS_CONTAINER';
export const MEDICAL_HISTORY = 'MEDICAL_HISTORY';
export const MEDICATIONS = 'MEDICATIONS';
export const TABLE_ITEM_GROUP = 'TABLE_ITEM_GROUP';
export const PROTOCOL_GROUPS_SETUP = 'PROTOCOL_GROUPS_SETUP';
export const LEGACY_ITEM_GROUP = 'LEGACY_ITEM_GROUP';
export const TRAVEL_PREFERENCES = 'TRAVEL_PREFERENCES';

const blockers = [];

export default function HistoryBlock() {
  const [proceedAllowed, setProceedAllowed] = useState(null);

  const blocker = useBlocker(function({ currentLocation, nextLocation, historyAction }) {
    if (!isEmpty(blockers)) {
      return goThroughAsyncBlockers(blockers, nextLocation, historyAction, currentLocation, function() {
        setProceedAllowed(true);
      });
    }
    return false;
  });

  useEffect(
    function() {
      if (blocker.state === 'blocked' && proceedAllowed) {
        blocker.proceed();
      }
      return function() {
        setProceedAllowed(null);
      };
    },
    [blocker, proceedAllowed]
  );

  useEffect(function() {
    HistoryBlockEventEmitter.on('change-state', listener);
    return function() {
      HistoryBlockEventEmitter.removeListener('change-state', listener);
    };
    function listener({ type, key, cb }) {
      const blockerIndex = blockers.reduce((a, b, i) => (b.key === key ? i : a), null);

      if (type === 'CLEAR' && !isNull(blockerIndex)) {
        remove(blockers, (b, i) => i === blockerIndex);
        return;
      }

      if (type === 'ADD' && isNull(blockerIndex)) {
        blockers.push({ key, cb });
        return;
      }

      if (type === 'ADD' && !isNull(blockerIndex)) {
        blockers.splice(blockerIndex, 1, { key, cb });
      }
    }
  }, []);

  return null;
}

HistoryBlock.block = function(key, cb) {
  HistoryBlockEventEmitter.emit('change-state', { type: 'ADD', key, cb });
  return function() {
    HistoryBlockEventEmitter.emit('change-state', { type: 'CLEAR', key, cb });
  };
};

HistoryBlock.unblock = function(key) {
  HistoryBlockEventEmitter.emit('change-state', { type: 'CLEAR', key });
};

function goThroughAsyncBlockers(blockers, nextLocation, action, currentLocation, onCompleteDiscard) {
  const blockersKeys = Object.keys(blockers);

  return performBlocker(blockers[blockersKeys.pop()]);

  function performBlocker(blocker) {
    const isNotBlocked = blocker.cb(
      function() {
        if (!onBlockerPerformingEnd()) onCompleteDiscard();
      },
      nextLocation,
      action,
      currentLocation
    );
    if (isNotBlocked) {
      return onBlockerPerformingEnd();
    }
    return true;
  }

  function onBlockerPerformingEnd() {
    if (isEmpty(blockersKeys)) {
      return false;
    } else {
      return performBlocker(blockers[blockersKeys.pop()]);
    }
  }
}
