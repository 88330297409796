import React, { useContext } from 'react';
import ReactSuperSelect from 'react-super-select';
import cx from 'classnames';
import { maxBy } from 'lodash';
import { map, orderBy } from 'lodash/collection';
import { isEmpty, isEqual, isFunction } from 'lodash/lang';
import moment from 'moment';

import { getShortDescriptionByRole, getTittleByRole } from '../../../../../../../services/personnelService';
import { ReviewContext } from '../../ReviewContext';

import './ReviewLogHistory.scss';

export default function ReviewLogHistory({ reviewHistory }) {
  const { setSignatureId, signatureId } = useContext(ReviewContext);

  function onChangeSelectedValue(signature) {
    if (signature && isFunction(setSignatureId)) {
      setSignatureId(signature.logId);
    }
  }

  const prepareRenderData = () => {
    const preparedData = map(reviewHistory, ({ reviewDate, signatureId, role, ...rest }) => {
      const reviewDateMoment = moment(reviewDate);
      return {
        ...rest,
        logId: signatureId,
        role: getTittleByRole(role),
        reviewDateForDisplay: reviewDateMoment.format('DD/MMM/YYYY h:mm A'),
        isInitialState: false,
        timestamp: reviewDateMoment,
        roleShortName: getShortDescriptionByRole(role)
      };
    });
    const currentStateMoment = !isEmpty(preparedData)
      ? moment(maxBy(preparedData, 'timestamp').timestamp).add(1, 'seconds')
      : moment();
    return orderBy([getCurrentState(currentStateMoment), ...preparedData], ['timestamp'], ['desc']);
  };

  const getCurrentState = currentStateMoment => {
    return {
      fullName: 'Current state',
      role: '',
      reviewDateForDisplay: null,
      reviewDate: null,
      roleShortName: '',
      timestamp: currentStateMoment,
      logId: null
    };
  };

  const dataForView = prepareRenderData();
  const value = dataForView.find(signature => isEqual(signature.logId, signatureId));

  return (
    <ReactSuperSelect
      customClass={cx(`review-log-history`, { 'review-log-history-highlight': !!signatureId })}
      dataSource={dataForView}
      onChange={onChangeSelectedValue}
      clearable={false}
      customSelectedValueTemplateFunction={r => (
        <div>
          <b>Review Log:</b> {r[0].fullName} {r[0].roleShortName} {r[0].reviewDateForDisplay}
        </div>
      )}
      customOptionTemplateFunction={renderOptionTemplate}
      initialValue={value}
      deselectOnSelectedOptionClick={false}
      customGroupHeadingTemplateFunction={() =>
        `Selecting sign off from the list below will load the state of the system from the history. You can go back to the current state at any time.`
      }
      groupBy="groupKey"
      optionValueKey="timestamp"
    />
  );
}

function renderOptionTemplate(option) {
  return (
    <React.Fragment>
      <div className="review-log-data">
        <div>{option.fullName}</div>
        <div>{option.role}</div>
        <div>{option.reviewDateForDisplay}</div>
      </div>
      {option.reason && <div className="reason">{option.reason}</div>}
    </React.Fragment>
  );
}
