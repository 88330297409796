import React, { useCallback, useContext } from 'react';

import Checkbox from '../../../../../../common/data-entry/InputSelectors/Checkbox/Checkbox';
import ApplyAndResetButtons from '../../../../../ApplyAndResetButtons/ApplyAndResetButtons';
import { StartEndDateSelector } from '../../../NewInvoice/InvoiceTopSection/InvoiceFilter/StartEndDateSelector/StartEndDateSelector';
import StudySiteSelector from '../../../shared/FinanceFilters/StudySiteSelector';
import { SitePaymentsContext } from '../../SitePaymentsContext';
import { OPEN, PAID, PENDING } from '../../SitePaymentsTable/SitePaymentsConstants';

import './SiteBillFilters.scss';

export function SitePaymentsFilters() {
  const {
    study,
    site,
    setSite,
    setStudy,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    applyFilter,
    resetFilters,
    setPcn,
    statuses,
    setStatuses
  } = useContext(SitePaymentsContext);

  const onChangeStartDate = moment => {
    const startDateMoment = moment.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    setStartDate(startDateMoment);
  };

  const onChangeEndDate = moment => {
    const endDateMoment = moment.set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
    setEndDate(endDateMoment);
  };

  const onStudyChange = useCallback(study => setStudy(study), [setStudy]);

  const onSiteChange = useCallback(site => setSite(site), [setSite]);

  const onPcnChange = useCallback(
    (study, sites) => {
      if (study) {
        setStudy(study);
        setPcn(study.projectCode);
        if (sites.length === 1 && !site) {
          const site = sites[0];
          setSite(site);
        }
      } else {
        setStudy(null);
        setPcn(null);
      }
    },
    [setSite, setStudy, site, setPcn]
  );

  const onHandleCheckboxChange = status => {
    setStatuses(prevState =>
      prevState.includes(status) ? prevState.filter(st => st !== status) : [...prevState, status]
    );
  };

  return (
    <div className="eds-site-bill-filter-layout">
      <div className={`more-than-five-fields-wrapper more-than-ten-fields`}>
        <StudySiteSelector
          onSiteChange={onSiteChange}
          onStudyChange={onStudyChange}
          studyId={study?.uniqueIdentifier}
          siteId={site?.uniqueIdentifier}
          pcnFlag={true}
          onPcnChange={onPcnChange}
          enableAutoCompleteSite={true}
        />
        <ApplyAndResetButtons onApply={applyFilter} onReset={resetFilters} />
      </div>
      <div className="ess-site-bill-status-filter-layout">
        <StartEndDateSelector
          endDate={endDate}
          startDate={startDate}
          onChangeEndDate={onChangeEndDate}
          onChangeStartDate={onChangeStartDate}
        />
        <Checkbox.Group label="Site Payment Status:">
          <Checkbox onChange={() => onHandleCheckboxChange(OPEN)} label="Open" checked={statuses.includes(OPEN)} />
          <Checkbox
            onChange={() => onHandleCheckboxChange(PAID)}
            label="Paid/Applied"
            checked={statuses.includes(PAID)}
          />
          <Checkbox
            onChange={() => onHandleCheckboxChange(PENDING)}
            label="Pending"
            checked={statuses.includes(PENDING)}
          />
        </Checkbox.Group>
      </div>
    </div>
  );
}
