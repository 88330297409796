import axios from 'axios';

const ROOT = 'api/v1/ssu-patients';

const PatientEncounterGroupAssignReviewCommentApi = {
  saveCommentOnReview(ssuPatientId, patientEncounterFormId, formCommentRequest, hideLoader = true) {
    return axios.post(
      `${ROOT}/${ssuPatientId}/reviews/item-groups/${patientEncounterFormId}/group-assign/comments`,
      { ...formCommentRequest },
      { hideLoader }
    );
  },

  saveCommentOnReviewForLogItemGroup(
    ssuPatientId,
    itemGroupTemplateId,
    itemGroupTemplateVersionId,
    formCommentRequest,
    hideLoader = true
  ) {
    return axios.post(
      `${ROOT}/${ssuPatientId}/reviews/logs/${itemGroupTemplateId}/${itemGroupTemplateVersionId}/group-assign/comments`,
      { ...formCommentRequest },
      { hideLoader }
    );
  }
};

export default PatientEncounterGroupAssignReviewCommentApi;
