import { keyBy, size } from 'lodash/collection';
import { isEmpty, isEqual } from 'lodash/lang';
import { mapValues, omitBy } from 'lodash/object';

import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { PROCESS_INTERRUPTED_BY_THE_USER } from '../../../../../../../constants/errorMessages';
import ChangedItemsModal from '../preSaveItemsModals/ChangedItemsModal';
import { collectAllQuestionsField, updateQuestionsField } from '../services/fieldsServices';

export default function processChangedItems(encounterTableItemGroup, originEncounterTableItemGroup) {
  return new Promise(function(resolve, reject) {
    const changedFieldsMap = getChangedItems(encounterTableItemGroup, originEncounterTableItemGroup);

    if (size(changedFieldsMap) < 1) {
      return resolve(encounterTableItemGroup);
    }

    const modalInstance = ModalBoxes.open({
      component: (
        <ChangedItemsModal
          data={changedFieldsMap}
          onSave={function(changedFieldsMap) {
            resolve(
              updateQuestionsField(
                encounterTableItemGroup,
                function(field) {
                  const { reason: reasonForDataChange } = changedFieldsMap[field.fieldUid];
                  return {
                    ...field,
                    reasonForDataChange
                  };
                },
                function({ fieldUid }) {
                  return !!changedFieldsMap[fieldUid];
                }
              )
            );
            modalInstance.close(true);
          }}
        />
      ),
      onClose(resolved) {
        if (resolved !== true) {
          reject(new Error(PROCESS_INTERRUPTED_BY_THE_USER));
        }
      }
    });
  });
}

function getChangedItems(encounterTableItemGroup, originEncounterTableItemGroup) {
  return pickWithDifferences(
    aggregatePreviousValue(
      prepareChangedItems(collectAllQuestionsField(encounterTableItemGroup)),
      prepareChangedItems(collectAllQuestionsField(originEncounterTableItemGroup))
    )
  );
}

function pickWithDifferences(data) {
  return omitBy(data, function({ value, name, fileList, filesResponse }) {
    const [currentValue, previousValue] = value;
    const [currentFileList, previousFileList] = fileList;
    const [currentFilesResponse, previousFilesResponse] = filesResponse;
    if (name === 'Comment') {
      return true;
    }
    if (isEmpty(previousValue)) {
      return true;
    }
    return (
      isEqual(currentValue, previousValue) &&
      isEqual(currentFileList, previousFileList) &&
      isEqual(currentFilesResponse, previousFilesResponse)
    );
  });
}

function aggregatePreviousValue(current, previous) {
  return mapValues(current, function(item) {
    const { uid, value, fileList, filesResponse } = item;
    return {
      ...item,
      value: [value, previous[uid].value],
      fileList: [fileList, previous[uid].fileList],
      filesResponse: [filesResponse, previous[uid].filesResponse]
    };
  });
}

function prepareChangedItems(data) {
  return keyBy(
    data.map(function({
      fieldLabel: label,
      fieldUid: uid,
      fieldName: name,
      fieldValue: value,
      reasonForDataChange: reason,
      fieldType,
      fileList,
      filesResponse
    }) {
      return {
        label,
        uid,
        name,
        value: ['multiselect', 'multiselectCT'].includes(fieldType) ? JSON.parse(value) : value,
        reason,
        fileList,
        filesResponse
      };
    }),
    'uid'
  );
}
