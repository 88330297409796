import UserApiApi from '../api/patient/UserApiApi';
import { onRequestDefaultError } from '../services/handlers';

import { SET_USER_PREFERENCES } from './actionTypes';

const prepareView = payload => {
  if (payload.useDay) {
    payload.view = 'day';
  } else {
    payload.view = payload.useWorkWeek === true ? 'work_week' : 'week';
  }
  return payload;
};

export const getUserPreferences = () => dispatch => {
  return UserApiApi.getUserPreferences().then(res => {
    dispatch({
      type: SET_USER_PREFERENCES,
      payload: prepareView(res.data)
    });
  });
};

export const setUserPreferences = preferences => dispatch => {
  if (['day', 'week', 'work_week'].includes(preferences.view)) {
    return UserApiApi.updateUserPreferences(preferences).then(res => {
      dispatch({
        type: SET_USER_PREFERENCES,
        payload: prepareView(res.data)
      });
    }, onRequestDefaultError);
  } else {
    dispatch({
      type: SET_USER_PREFERENCES,
      payload: preferences
    });
  }
};
