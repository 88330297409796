import React from 'react';

import DatePicker from '../../../../../common/data-entry/DatePicker';
import Icon from '../../../../../common/general/Icon';

import './DateTemplate.scss';

const DateTemplate = props => {
  return (
    <div className="date-template">
      <Icon className="date-icon">calendar_today</Icon>
      <DatePicker.Manual {...props} />
    </div>
  );
};

export default DateTemplate;
