import axios from 'axios';

const ROOT = 'api/v1/patient-medical-history';

export const MedicalHistoryAPI = {
  getPatientMedicalHistory: function(patientId) {
    return axios.get(`${ROOT}/patient/${patientId}`);
  },
  addPatientMedicalHistoryRecord: function(patientId, body) {
    return axios.post(`${ROOT}/patient/${patientId}/add`, body);
  },
  removePatientMedicalHistoryRecord: function(patientId, medicationId) {
    return axios.delete(`${ROOT}/patient/${patientId}/remove?id=${medicationId}`);
  },
  editPatientMedicalHistoryRecord: function(patientId, body) {
    return axios.put(`${ROOT}/patient/${patientId}/edit`, body);
  },
  getAllDiagnoses() {
    return axios.get(`${ROOT}/diagnoses`, {
      hideLoader: true
    });
  }
};
