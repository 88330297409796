import React, { useContext } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import { Fab } from '@mui/material';
import { isEmpty } from 'lodash/lang';

import { InvoiceContext } from '../../NewInvoiceContext';
import { ACTION_BUTTON_COLOR } from '../CreateInvoiceConstants';
import { getFileForSandingEmail, openSendmailModal } from '../CreateInvoiceServices';

export const CreateInvoiceActionPanel = ({
  invoiceCreated,
  fileName,
  PDFFile,
  selectedBillTo,
  selectedPayee,
  onSave,
  savingType,
  handlePrint,
  downloadFile,
  emailParams,
  generationInProgress,
  expenseAttachmentIds,
  setInvoiceNumber
}) => {
  const { createInvoiceItems, loadInvoice } = useContext(InvoiceContext);
  return (
    <div className="action-panel">
      <Fab
        data-testid="send-invoice-button"
        onClick={() =>
          invoiceCreated
            ? openSendmailModal({
                ...emailParams,
                initialFiles: getFileForSandingEmail(createInvoiceItems?.fileName ?? `${fileName}.pdf`, PDFFile),
                loadInvoice,
                expenseAttachmentIds,
                createInvoiceItems,
                setInvoiceNumber
              })
            : onSave('SEND')
        }
        disabled={!!savingType || generationInProgress}
      >
        <SendIcon sx={{ color: ACTION_BUTTON_COLOR }} />
      </Fab>
      <Fab
        data-testid="download-invoice-button"
        onClick={() => (invoiceCreated ? downloadFile(PDFFile) : onSave('DOWNLOAD'))}
        disabled={!!savingType || generationInProgress}
      >
        <DownloadIcon sx={{ color: ACTION_BUTTON_COLOR }} />
      </Fab>
      <Fab
        data-testid="print-invoice-button"
        onClick={() => (invoiceCreated ? handlePrint(PDFFile) : onSave('PRINT'))}
        disabled={!!savingType || generationInProgress}
      >
        <PrintIcon sx={{ color: ACTION_BUTTON_COLOR }} />
      </Fab>
      {!invoiceCreated && (
        <Fab
          data-testid="save-invoice-button"
          onClick={() => !invoiceCreated && onSave()}
          disabled={
            invoiceCreated || !!savingType || isEmpty(selectedPayee) || isEmpty(selectedBillTo) || generationInProgress
          }
        >
          <SaveIcon sx={{ color: ACTION_BUTTON_COLOR }} />
        </Fab>
      )}
    </div>
  );
};
