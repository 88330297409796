import { get } from 'lodash/object';

function getEventStyle(event) {
  const style = get(event, 'event.customStyle', {});
  if (event.style) {
    style.top = event.style.top + '%';
    style.left = event.style.xOffset + '%';
    style.width = event.style.width + '%';
    style.height = event.style.height + '%';
  }
  return style || {};
}

export function getCanceledEventStyle(event) {
  const style = getEventStyle(event);
  const backColor = get(event, 'event.customStyle.backColor', '');
  style.background = `repeating-linear-gradient(45deg, rgba(255,255,255,0.5), rgba(255,255,255,0.5) 0.3em, ${backColor} 0, ${backColor} 0.8em) ${backColor}`;
  return style;
}

export function getDisabledEventStyle(event) {
  const style = getEventStyle(event);
  style.border = '1px solid';
  style.borderColor = style.mainColor;
  style.borderLeftWidth = '5px';
  return style;
}
