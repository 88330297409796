import React, { useContext } from 'react';
import { Menu, MenuGroup, MenuItem } from '@szhsin/react-menu';
import cx from 'classnames';
import { isEmpty } from 'lodash/lang';
import moment from 'moment/moment';
import Tooltip from 'rc-tooltip';

import ModalBoxes from '../../../../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../../../../../common/general/Button';
import Icon from '../../../../../../../../../../../common/general/Icon';
import {
  isSituationalOrUnexpectedEncounter,
  PROJECTED
} from '../../../../../../../../../../../constants/encounterConstants';
import { ReactComponent as OutOfWindowIcon } from '../../../../../../../../../../../images/icons/out-of-window.svg';
import { PatientInfoContext } from '../../../../../../PatientInfoContext';
import { useEncountersSection } from '../../../../../EncountersSectionContext';
import CancelAppointmentModal from '../../CancelAppointmentModal/CancelAppointmentModal';
import { useEncounterSegment } from '../../EncounterSegment';

import './CardSectionLastDefault.scss';

const ENCOUNTER_EVENT_START_DATE_FORMAT = 'DD/MMM/YYYY, hh:mm A';
const START_END_DATE_FORMAT = 'DD/MMM/YYYY';

export default function ScheduledAppointments() {
  const { currentStudy } = useContext(PatientInfoContext);
  const { rescheduleAppointment, isAllowedToManageEncounters } = useEncountersSection();
  const { encounter, updatePatientEncountersWithAppointments } = useEncounterSegment();
  const { encounterType, encounterEvents, removedFromProtocol, encounterFilterStatus, outOfWindow } = encounter;
  return (
    encounterEvents &&
    !isEmpty(encounterEvents) && (
      <div className="appointments-rows">
        <div className="appointments-rows-title">
          {encounterType === PROJECTED ? 'Canceled' : 'Scheduled'} Appointments
          {outOfWindow && (
            <Tooltip
              destroyTooltipOnHide={false}
              overlayClassName={cx('eds-rc-tooltip encounter-dates-tooltip')}
              placement="top"
              align={{
                offset: [0, 10]
              }}
              overlay={
                <div className="overlay-container">
                  <span className="content-title">Out-of-Window Encounter</span>
                </div>
              }
            >
              <OutOfWindowIcon className="appointments-out-of-window" />
            </Tooltip>
          )}
        </div>
        {encounterEvents.map(function(encounterEvent) {
          const {
            id,
            start,
            description,
            cancelled,
            cancellationComments,
            windowStartDate,
            windowEndDate
          } = encounterEvent;
          return (
            <div key={id} className={cx('appointments-row', { cancelled })}>
              <Tooltip
                destroyTooltipOnHide={false}
                overlayClassName={cx('eds-rc-tooltip encounter-dates-tooltip')}
                placement="top"
                align={{
                  offset: [0, 10]
                }}
                mouseEnterDelay={0}
                mouseLeaveDelay={0}
                overlay={
                  <div className="overlay-container">
                    <div className="dates-layout">
                      <span className="content-title">Expected Encounter Window:</span>
                      {windowStartDate && windowEndDate && (
                        <div className="date-item">
                          <span>
                            {moment(windowStartDate).format(START_END_DATE_FORMAT)} {' - '}
                            {moment(windowEndDate).format(START_END_DATE_FORMAT)}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                }
              >
                <div className="event-start-date">
                  <div>{getEventStartDate(start)}</div>
                </div>
              </Tooltip>
              <div className="appointment-comments">
                <div>{getCommentMessage(cancelled, cancellationComments, description, removedFromProtocol)}</div>
              </div>
              {encounterType !== PROJECTED && (
                <div className="control-buttons">
                  <Menu
                    align="end"
                    menuButton={
                      <Button disabled={cancelled || !isAllowedToManageEncounters} size="h40" priority="low">
                        <Icon>more_vert</Icon>
                      </Button>
                    }
                  >
                    <MenuGroup>
                      {(encounterFilterStatus === 'Scheduled' || isSituationalOrUnexpectedEncounter(encounterType)) && (
                        <MenuItem onClick={() => rescheduleAppointment(encounterEvent)}>
                          Reschedule Appointment
                        </MenuItem>
                      )}
                      <MenuItem onClick={() => cancelAppointment(encounterEvent)}>Cancel Appointment</MenuItem>
                    </MenuGroup>
                    )
                  </Menu>
                </div>
              )}
            </div>
          );
        })}
      </div>
    )
  );

  function getEventStartDate(date) {
    if (currentStudy?.timeZoneValid) {
      return `${moment(date)
        .tz(currentStudy.timeZone)
        .format(ENCOUNTER_EVENT_START_DATE_FORMAT)} ${getTimeZoneAbbr(currentStudy.timeZone)}`;
    }
    return `${moment(date).format(ENCOUNTER_EVENT_START_DATE_FORMAT)}`;
  }

  function getTimeZoneAbbr(timeZone) {
    let [, ...tz] = new Date().toLocaleTimeString('en', { timeZone, timeZoneName: 'long', hour12: false }).split(' ');
    if (tz.includes('Daylight') || tz.includes('Standard')) {
      return tz[0];
    }
    return moment()
      .tz(timeZone)
      .zoneAbbr();
  }

  function cancelAppointment(event) {
    ModalBoxes.open({
      component: <CancelAppointmentModal event={event} onSuccess={updatePatientEncountersWithAppointments} />
    });
  }
}

function getCommentMessage(cancelled, cancellationComments, description, removedFromProtocol) {
  if (cancelled && !isEmpty(cancellationComments)) {
    return cancellationComments;
  }
  if (removedFromProtocol) {
    return 'Encounter removed from protocol.  Please reschedule with valid encounter';
  }
  return description;
}
