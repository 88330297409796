export const customClassObject = {
  textbox: {
    containerClass: 'p-0',
    elementClass: 'form-control select-width'
  },
  dropdown: {
    containerClass: 'p-0',
    elementClass: 'form-control select-width'
  },
  date: {
    containerClass: 'custonDate',
    elementClass: 'p-0'
  },
  textarea: {
    containerClass: 'col pr-0 pl-0 display-inline',
    elementClass: 'form-control'
  },
  time: {
    containerClass: '',
    elementClass: 'select-width p-0'
  },
  checkbox: {
    containerClass: 'p-0',
    elementClass: ''
  },
  label: {
    containerClass: 'p-0',
    elementClass: 'form-control select-width'
  },
  file: {
    containerClass: 'input-group ml-0 position-relative',
    elementClass: 'select-width custom-file-input'
  },
  reminder: {
    containerClass: 'col-md-12 ml-0 position-relative pl-0',
    elementClass: ''
  }
};
