import React, { useCallback, useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { isEqual } from 'lodash/lang';

import { PatientApi } from '../../../../../../../../api';
import Button from '../../../../../../../../common/general/Button';
import Icon from '../../../../../../../../common/general/Icon';
import NotificationManager from '../../../../../../../../common/notifications/NotificationManager';
import { SOURCE_CHANGED } from '../../../../../../../../constants/notificationMessages';
import { onRequestError } from '../../../../../../../../services/handlers';
import { PatientInfoContext } from '../../../PatientInfoContext';

import './PatientSourceColumn.scss';

export function PatientSourceColumn({ ssuPatientId, patientSource, isAllowedToManage }) {
  const { sourcesList, setPatientSourceIdBySsuPatientId } = useContext(PatientInfoContext);
  const [selectedSource, setSelectedSource] = useState(patientSource);

  const onSelectSource = useCallback(
    function(selectedValue) {
      setSelectedSource(selectedValue);
    },
    [setSelectedSource]
  );

  useEffect(
    function() {
      setSelectedSource(patientSource);
    },
    [patientSource]
  );

  const sortedSourcesList = [...sourcesList].sort(({ id }) => (id === selectedSource?.id ? -1 : 0));

  return (
    <div className="patient-source-column-wrapper">
      <Select
        getOptionLabel={option => option.source}
        getOptionValue={option => option.id}
        options={sortedSourcesList}
        onChange={onSelectSource}
        value={selectedSource}
        menuPortalTarget={document.body}
        classNamePrefix="patient-source-column-wrapper-custom-select"
        placeholder="Choose source"
        noOptionsMessage={() => 'No sources found!'}
        isDisabled={!isAllowedToManage}
      />

      {!isEqual(patientSource, selectedSource) && (
        <div className="patient-source-column-field-buttons">
          <Button size="h28" priority="low" onClick={onSaveSelectedDataHandler}>
            <Icon>save</Icon>
          </Button>
          <Button size="h28" priority="low" onClick={onDiscardSelectedDataHandler}>
            <Icon>close</Icon>
          </Button>
        </div>
      )}
    </div>
  );

  function onSaveSelectedDataHandler() {
    const patientSourceId = selectedSource.id;
    PatientApi.updatePatientSourceId(ssuPatientId, { patientSourceId }).then(result => {
      if (result) {
        setPatientSourceIdBySsuPatientId(ssuPatientId, patientSourceId);
        NotificationManager.success(SOURCE_CHANGED);
      }
    }, onRequestError);
  }

  function onDiscardSelectedDataHandler() {
    setSelectedSource(patientSource);
  }
}
