import React, { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../../../../../../../../../common/general/Button';
import { generateUrlByKey, useCurrentRoute } from '../../../../../../../../../root/router';
import { PatientInfoContext } from '../../../../../PatientInfoContext';
import { useEncountersSection } from '../../../../EncountersSectionContext';
import { useEncounterSegment } from '../EncounterSegment';

export default function HeaderUnexpected() {
  const currentRoute = useCurrentRoute();
  const navigate = useNavigate();
  const { isCheckinDisabled } = useEncountersSection();
  const { encounter, lastEncounterEvent } = useEncounterSegment();
  const patientId = currentRoute.params?.patientId;
  const { sitePatientEncounter, removedFromProtocol } = encounter;
  const { cancelled, id } = lastEncounterEvent;

  const { currentStudy } = useContext(PatientInfoContext);
  const ssuPatientId = currentRoute.params?.ssuPatientId;
  const currentRouteKey = currentRoute?.key;
  const ssuId = currentStudy?.ssuId;
  const studyId = currentStudy?.studyId;

  const createUnexpectedEncounterDetailsPageUrl = useMemo(
    function() {
      return (
        generateUrlByKey(
          `${currentRouteKey}.Create Unexpected Encounter`,
          {
            patientId,
            ssuPatientId
          },
          { ssuId, studyId }
        ) +
        '&event=' +
        id
      );
    },
    [patientId, currentRouteKey, ssuPatientId, ssuId, studyId, id]
  );
  return (
    <>
      {!sitePatientEncounter && !cancelled && !removedFromProtocol && (
        <>
          <Button disabled={isCheckinDisabled} size="h28" onClick={checkIn}>
            Start Encounter
          </Button>
        </>
      )}
    </>
  );

  function checkIn() {
    navigate(createUnexpectedEncounterDetailsPageUrl);
  }
}
