import React from 'react';
import cx from 'classnames';

import Button from '../../../../../../common/general/Button';
import StyledTooltip from '../../../../../../common/widgets/StyledToolTip';
import { MANAGE_SSU_PATIENT_STATUSES } from '../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../services/auth';

import './ProgressItem.scss';

const ProgressItem = ({
  first,
  optional,
  skipped,
  current,
  failed,
  name,
  date,
  notHappened,
  previousSkipped,
  previousFailed,
  previousOptional,
  previousHappened,
  addMissingStatus,
  displayAddButton
}) => {
  const classNameForItem = cx('basic-item', { optional, skipped, current, failed });
  const classNameForLine = cx('basic-line', {
    first,
    optional: optional,
    skipped: skipped,
    current,
    failed: failed || previousFailed,
    not_happened: notHappened,
    previous_skipped: previousSkipped,
    previous_optional: previousOptional,
    previous_happened: previousHappened
  });
  const classNameForText = cx('basic-text', { optional, skipped, current, not_happened: notHappened });
  const classNameForDot = cx('basic-dot', { optional, skipped, current, failed, not_happened: notHappened });

  const missingStatusTooltip =
    displayAddButton && userHasAccessTo(MANAGE_SSU_PATIENT_STATUSES) ? (
      <>
        <div>{`${name} status is missing. Add missing status?`}</div>
        <Button
          size="h28"
          onClick={() => {
            addMissingStatus(name);
          }}
        >
          Add Status
        </Button>
      </>
    ) : (
      <div>{`${name} status is missing.`}</div>
    );

  return (
    <div className="progress-item-wrapper">
      <div className={classNameForItem}>
        <div className={classNameForLine} />
        {skipped && !optional ? (
          <StyledTooltip className="tooltip-missing-status" overlay={missingStatusTooltip} placement="top">
            <div className={classNameForDot}>
              <div className={classNameForText}>
                <span>{name}</span>
                <span>{date}</span>
              </div>
            </div>
          </StyledTooltip>
        ) : (
          <div className={classNameForDot}>
            <div className={classNameForText}>
              <span>{name}</span>
              <span>{date}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProgressItem;
