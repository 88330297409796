import React from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';

function withPatientProvided(CalendarPage) {
  return function() {
    const location = useLocation();
    const { sitePatientId, encounterId, encounterEventType } = parse(location.search);
    return (
      <CalendarPage encounterId={encounterId} sitePatientId={sitePatientId} encounterEventType={encounterEventType} />
    );
  };
}

export default withPatientProvided;
