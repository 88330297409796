import axios from 'axios';

const ROOT = 'api/v1/calendar';

const CalendarPageSupplierApi = {
  cancelReasons() {
    return axios.get(`${ROOT}/cancel-reasons`);
  },
  getStudiesForPatient(patientId) {
    return axios.get(`${ROOT}/patients/${patientId}/studies`, { hideLoader: true });
  },
  getStudiesForPatientRegularEncounter(patientId) {
    return axios.get(`${ROOT}/patients/${patientId}/studies/regular-encounter`, {
      hideLoader: true
    });
  },
  getNotCompletedEncountersForPatient(ssuPatientId, studyId, request) {
    return axios.get(`${ROOT}/patients/${ssuPatientId}/studies/${studyId}/protocol/not-completed`, {
      hideLoader: true,
      cancelToken: request.token
    });
  },
  timeRange(sitePatientId, encounterId) {
    return axios.get(`${ROOT}/ssu-patients/${sitePatientId}/encounters/${encounterId}/time-range`, {
      hideLoader: true
    });
  },
  getPatientEncounterEventData(sitePatientId, encounterId) {
    return axios.get(`${ROOT}/draft-patient-encounter-event-data/${sitePatientId}/${encounterId}`);
  },
  getPatientSituationalEncounterEventData(sitePatientId, encounterId) {
    return axios.get(`${ROOT}/draft-patient-situational-encounter-event-data/${sitePatientId}/${encounterId}`);
  }
};

export default CalendarPageSupplierApi;
