import React, { useEffect, useState } from 'react';
import DateTime from 'react-datetime';
import { isEqual } from 'lodash/lang';
import { omit } from 'lodash/object';
import moment from 'moment';

import DatePicker from '../../../../common/data-entry/DatePicker';
import Input from '../../../../common/data-entry/Input';
import Select from '../../../../common/data-entry/Select';

import './TimeDurationPicker.scss';

require('moment-duration-format');

const durations = Array.from({ length: 16 }, (v, k) => k + 1).map(value => moment.duration(value * 0.5, 'hour'));
const DATE_FORMAT = 'DD/MMM/YYYY';
export default function TimeDurationPicker({ initialTime, onTimeDurationChanged, validationMessage }) {
  const [timeDuration, setTimeDuration] = useState(initialTime);

  useEffect(() => {
    setTimeDuration(initialTime);
  }, [initialTime]);

  const readableDuration = duration => duration.format('h[h] m[m]', { trim: 'all' });

  const changeTimeDuration = newTimeDuration => {
    if (!isEqual(timeDuration, newTimeDuration)) {
      setTimeDuration(newTimeDuration);
      onTimeDurationChanged(newTimeDuration);
    }
  };

  const changeTimeIfValid = newStart => {
    if (moment.isMoment(newStart)) {
      const start = timeDuration.start.clone().set({
        hour: newStart.get('hour'),
        minute: newStart.get('minute'),
        second: newStart.get('second')
      });
      changeTimeDuration({ ...timeDuration, start });
    }
  };

  const changeDateIfValid = newStart => {
    if (moment.isMoment(newStart)) {
      const start = timeDuration.start.clone().set({
        year: newStart.get('year'),
        month: newStart.get('month'),
        date: newStart.get('date')
      });
      changeTimeDuration({ ...timeDuration, start });
    }
  };

  return (
    <div className="time-duration">
      <DatePicker.Manual
        className="date-select"
        dateOnly={true}
        id={'time-selector'}
        label={'Date'}
        value={timeDuration.start.format(DATE_FORMAT)}
        onChange={changeDateIfValid}
        required
      />
      <div>
        <DateTime
          renderInput={props => <Input {...omit(props, 'className')} label={'Time'} required />}
          className={'time-select'}
          id={'time-selector'}
          viewMode={'time'}
          dateFormat={''}
          value={timeDuration.start}
          onChange={changeTimeIfValid}
          onFocus={({ target }) => target.select()}
        />

        <Select
          className={'duration-select ml-2'}
          label={'Duration'}
          clearable={false}
          dataSource={durations}
          deselectOnSelectedOptionClick={false}
          value={timeDuration.duration}
          optionValueKey={'_milliseconds'}
          customOptionTemplateFunction={readableDuration}
          required
          customSelectedValueTemplateFunction={duration => duration[0].format('h[h] m[m]', { trim: 'all' })}
          onChange={duration => changeTimeDuration({ ...timeDuration, duration })}
        />
      </div>
      <span className={'text-danger'}>{validationMessage}</span>
    </div>
  );
}
