import { NON_PATIENT_EVENT_TYPE } from './NonPatientEventType';

export const NON_PATIENT_EVENT_TYPE_DROPDOWN_LABEL_MAP = new Map();

NON_PATIENT_EVENT_TYPE_DROPDOWN_LABEL_MAP.set(NON_PATIENT_EVENT_TYPE.IMV, 'IMV - Interim Monitoring Visit');
NON_PATIENT_EVENT_TYPE_DROPDOWN_LABEL_MAP.set(NON_PATIENT_EVENT_TYPE.RMV, 'RMV - Remote Monitoring Visit');
NON_PATIENT_EVENT_TYPE_DROPDOWN_LABEL_MAP.set(NON_PATIENT_EVENT_TYPE.SMV, 'SMV - Site Monitoring Visit');
NON_PATIENT_EVENT_TYPE_DROPDOWN_LABEL_MAP.set(NON_PATIENT_EVENT_TYPE.PSV, 'PSV - Pre-Study Visit');
NON_PATIENT_EVENT_TYPE_DROPDOWN_LABEL_MAP.set(NON_PATIENT_EVENT_TYPE.SIV, 'SIV - Site Initiation Visit');
NON_PATIENT_EVENT_TYPE_DROPDOWN_LABEL_MAP.set(NON_PATIENT_EVENT_TYPE.IM, 'IM - Investigator Meeting');
NON_PATIENT_EVENT_TYPE_DROPDOWN_LABEL_MAP.set(NON_PATIENT_EVENT_TYPE.CLOSE_OUT_VISIT, 'COV - Close Out Visit');
NON_PATIENT_EVENT_TYPE_DROPDOWN_LABEL_MAP.set(NON_PATIENT_EVENT_TYPE.PHONE, 'Phone');
NON_PATIENT_EVENT_TYPE_DROPDOWN_LABEL_MAP.set(NON_PATIENT_EVENT_TYPE.OUT_OF_OFFICE, 'Out of Office');
NON_PATIENT_EVENT_TYPE_DROPDOWN_LABEL_MAP.set(NON_PATIENT_EVENT_TYPE.OTHER, 'Other');
