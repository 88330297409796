import axios from 'axios';

const ROOT = 'api/v1/protocols';

const ProtocolBranchesApi = {
  getBranches(protocolUid) {
    return axios.get(`${ROOT}/${protocolUid}/primary-branches`);
  }
};

export default ProtocolBranchesApi;
