import React from 'react';
import cx from 'classnames';

import EncounterPrintMultiselect from '../../../../../PrintPatientEncountersGeneratorModal/EncounterPrintMultiselect';
import { useExportCaseReportFormsActions, useExportCaseReportFormsState } from '../ExportCaseReportFormsContext';

export default function ProceduresSelector() {
  const { selectedItemGroupsMap, selectedEncounter, selectedType } = useExportCaseReportFormsState();
  const { onItemGroupsSelect } = useExportCaseReportFormsActions();
  if (!selectedEncounter) {
    return null;
  }
  const { id, itemGroups } = selectedEncounter;
  const selectedItemGroups = selectedItemGroupsMap[id] || [];
  return (
    <div className={cx('item-groups-selector', { 'hide-selector-form': selectedType !== 'ENCOUNTERS' })}>
      <h3>Procedures</h3>
      <EncounterPrintMultiselect
        selectedOptions={selectedItemGroups}
        options={itemGroups}
        onChange={selected => onItemGroupsSelect(id, selected)}
        id="uuid"
        defaultSelectAll
        displaySelectAll={false}
      />
    </div>
  );
}
