import React, { Fragment } from 'react';
import { default as SimpleTimefield } from 'react-simple-timefield';
import { isNull } from 'lodash/lang';
import PropTypes from 'prop-types';

import Input from '../../../../../../../../common/data-entry/Input';
import Icon from '../../../../../../../../common/general/Icon';
import { normalizeTime } from '../../../../../../../../services/normalizers';
import { useTableItemGroupActions, useTableItemGroupState } from '../../TableItemGroupContext/TableItemGroupContext';
import fieldPropTypes from '../fieldPropTypes';

export default function TimeField({ field }) {
  const { fieldUid } = field;
  const { isPreviewOnly } = useTableItemGroupState();
  const { fieldOnChange } = useTableItemGroupActions();

  return (
    <TimeFieldReadonly
      field={field}
      onChange={({ target }) => {
        fieldOnChange(normalizeTime(target.value), fieldUid);
      }}
      disabled={isPreviewOnly}
      onIconClick={() => {
        if (!isPreviewOnly) {
          fieldOnChange(null, fieldUid);
        }
      }}
    />
  );
}

export function TimeFieldReadonly({ field, onIconClick, ...rest }) {
  const { fieldValue } = field;

  return (
    <SimpleTimefield
      className="time-field"
      legacyLook
      value={fieldValue || '--:--'}
      onFocus={({ target }) => target.select()}
      iconsAfter={
        <Fragment>
          {onIconClick && (
            <Icon type="input-button" onClick={onIconClick}>
              cancel
            </Icon>
          )}
          <Icon>access_time</Icon>
        </Fragment>
      }
      input={<Input />}
      {...rest}
    />
  );
}

const propTypes = {
  field: PropTypes.shape({
    ...fieldPropTypes,
    fieldType: PropTypes.oneOf(['time']).isRequired,
    fieldValue: function(props, propName, componentName) {
      const value = props[propName];
      if (!isNull(value) && !/\d\d:\d\d/.test(value)) {
        return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed.`);
      }
    }
  })
};

TimeFieldReadonly.defaultProps = {
  disabled: true
};

TimeFieldReadonly.propTypes = propTypes;
TimeField.propTypes = propTypes;
