import React, { useCallback, useMemo, useState } from 'react';
import { omit } from 'lodash/object';
import moment from 'moment';

import { FinTransactionLedgerEventApi } from '../../../../api';
import FinLedgerEventApi from '../../../../api/finance/FinLedgerEventApi';
import { EXPORT_FAILED } from '../../../../constants/notificationMessages';
import { handleFinLedgerEventIdFieldValidationError, onFileSave, onRequestError } from '../../../../services/handlers';

import { ACTIVE, CLIENT, PENDING } from './ledgerConstants';

export const LedgerContext = React.createContext(null);

export function LedgerProvider({ children }) {
  const initialStatuses = [PENDING, ACTIVE];
  const initialFilter = {
    studyName: null,
    siteName: null,
    siteId: null,
    studyId: null,
    type: null,
    statuses: initialStatuses,
    amountState: CLIENT,
    startDate: moment()
      .subtract(1, 'month')
      .set({ hour: 0, minute: 0, second: 0, millisecond: 1 }),
    endDate: moment().set({ hour: 23, minute: 59, second: 59, millisecond: 0 }),
    overhead: false,
    subjectId: null,
    finLedgerEventId: null
  };
  const [filterProperty, setFilterProperty] = useState(initialFilter);
  const [ledgerEvents, setLedgerEvents] = useState([]);
  const [pendingCount, setPendingCount] = useState(0);

  const setFewFilterProperty = useCallback(
    function(filters = {}) {
      setFilterProperty(prevFilters => ({
        ...prevFilters,
        ...filters
      }));
    },
    [setFilterProperty]
  );

  const applyFilters = useCallback(() => {
    const filtersForRequest = omit(filterProperty, ['amountState', 'overhead']);
    FinLedgerEventApi.getLedgerEventsByFilter(filtersForRequest)
      .then(res => {
        setLedgerEvents(res.data);
      })
      .catch(error => {
        handleFinLedgerEventIdFieldValidationError(error);
      });
    FinTransactionLedgerEventApi.getCountOfPendingLedgerEvents(filtersForRequest)
      .then(({ data }) => {
        setPendingCount(data);
      })
      .catch(error => {
        handleFinLedgerEventIdFieldValidationError(error);
      });
  }, [filterProperty]);

  const downloadCSV = useCallback(() => {
    FinTransactionLedgerEventApi.exportElligoRevenue(filterProperty)
      .then(onFileSave)
      .catch(err => onRequestError(err, { customMessage: EXPORT_FAILED }));
  }, [filterProperty]);

  const normalizedLedgerEvents = useMemo(
    () =>
      ledgerEvents.map(ledgerEvent => {
        const clientAmount = filterProperty.overhead ? ledgerEvent.clientAmountWithOverhead : ledgerEvent.clientAmount;
        const elligoAmount = filterProperty.overhead ? ledgerEvent.elligoAmountWithOverhead : ledgerEvent.elligoAmount;
        const amountForDisplay = filterProperty.amountState === CLIENT ? clientAmount : elligoAmount;
        return { ...ledgerEvent, amountForDisplay };
      }),
    [filterProperty.amountState, filterProperty.overhead, ledgerEvents]
  );

  const value = useMemo(
    () => ({
      downloadCSV,
      applyFilters,
      ledgerEvents: normalizedLedgerEvents,
      filterProperty,
      setFewFilterProperty,
      pendingCount
    }),
    [applyFilters, downloadCSV, filterProperty, normalizedLedgerEvents, pendingCount, setFewFilterProperty]
  );

  return <LedgerContext.Provider value={value}>{children}</LedgerContext.Provider>;
}
