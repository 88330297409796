import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { isEqual } from 'lodash';
import { isBoolean, isFunction, isNumber, isString } from 'lodash/lang';
import { get, omit } from 'lodash/object';

import './_Group.scss';

export default function Group(props) {
  const { groupProps, component: Component, children } = props;
  const {
    options,
    checkedValue,
    checkedValues,
    optionValueKey,
    optionLabelKey,
    optionTitleKey,
    disabled,
    label,
    className,
    onChange: onChangeCallback,
    direction
  } = groupProps;

  const restProps = omit(
    groupProps,
    'options',
    'checkedValue',
    'checkedValues',
    'label',
    'optionValueKey',
    'optionLabelKey',
    'optionTitleKey',
    'disabled',
    'onChange',
    'className',
    'children'
  );

  const [selectedValue, setSelectedValue] = useState(null);
  useEffect(
    function() {
      setSelectedValue(checkedValue);
    },
    [checkedValue]
  );

  const [selectedValues, setSelectedValues] = useState(null);
  useEffect(
    function() {
      setSelectedValues(checkedValues);
    },
    [checkedValues]
  );

  return (
    <div className={cx('eui-form-group', className)}>
      {label && <div className="eui-form-label">{label}</div>}
      <form className={`eui-form-inputs  eui-form-direction-${direction}`}>
        {children ? fillChildrenWithExtraProps() : renderByOption(options)}
      </form>
    </div>
  );
  function renderByOption(options) {
    return options?.map(option => {
      const optionValue = getOptionValue(option);
      return (
        <Component
          checked={
            isEqual(optionValue, selectedValue) ||
            (Array.isArray(selectedValues) && selectedValues?.includes(optionValue))
          }
          label={getOptionLabel(option)}
          disabled={disabled}
          tittle={getOptionTitle(option)}
          key={optionValue}
          value={optionValue}
          onChange={onChangeCallback}
          {...restProps}
        />
      );
    });
  }

  function onChangeResolve(onChange) {
    if (isFunction(onChange)) {
      return onChange;
    } else {
      return onChangeCallback;
    }
  }
  function disabledResolve(childrenDisabled) {
    if (isBoolean(childrenDisabled)) {
      return childrenDisabled;
    } else {
      return disabled;
    }
  }

  function fillChildrenWithExtraProps() {
    return (function childPropsPreparing(children) {
      const { props: childrenProps } = children;
      if (Array.isArray(children)) {
        return children.map(childPropsPreparing);
      } else {
        let checked = childrenProps.checked;
        if (typeof checked !== 'boolean') {
          const childValue = childrenProps.value;
          checked =
            isEqual(childValue, selectedValue) ||
            (Array.isArray(selectedValues) && selectedValues?.includes(childValue));
        }
        const onChange = onChangeResolve(childrenProps.onChange);
        const disabledChildren = disabledResolve(childrenProps.disabled);
        return {
          ...children,
          props: { ...childrenProps, checked, onChange, ...restProps, disabled: disabledChildren }
        };
      }
    })(children);
  }

  function getOptionLabel(option) {
    if (isString(option) || isNumber(option)) {
      return option;
    }

    if (isFunction(optionLabelKey)) {
      return optionLabelKey(option);
    }

    return get(option, optionLabelKey);
  }

  function getOptionValue(option) {
    if (isString(option) || isNumber(option)) {
      return option;
    }

    if (isFunction(optionValueKey)) {
      return optionValueKey(option);
    }

    return get(option, optionValueKey);
  }

  function getOptionTitle(option) {
    if (isString(option) || isNumber(option)) {
      return;
    }

    if (isFunction(optionTitleKey)) {
      return optionTitleKey(option);
    }

    return get(option, optionTitleKey);
  }
}
