import React, { useContext } from 'react';
import { isArray } from 'lodash/lang';
import { get } from 'lodash/object';

import Divider from '../../../../../common/elements/Divider/Divider';
import { ROLE_ELLIGO_MANAGEMENT, ROLE_FINANCE } from '../../../../../constants/userRoles';
import { useCurrentUser } from '../../../../root/Container/CurrentUserContainer';
import { CalendarPageContext } from '../../CalendarPageContext';
import { NON_PATIENT_EVENT_TYPE_DROPDOWN_LABEL_MAP } from '../../NonPatientAppointmentEdit/NonPatientEventTypeDropdownLabelProvider';
import { isSubjectVisibleForType } from '../../NonPatientAppointmentService';
import { AppointmentViewField, ParticipantField } from '../AppointmentViewField';

export function NonPatientAppointmentViewContent({ appointment }) {
  const { nonPatientEventTypeByCodeMap } = useContext(CalendarPageContext);
  const participants = isArray(appointment.participants) ? appointment.participants : [];
  const nonPatientEventTypeCode = appointment?.nonPatientEventTypeCode;
  const isSubjectVisible = isSubjectVisibleForType(nonPatientEventTypeCode);
  const meetingTypeLabel =
    NON_PATIENT_EVENT_TYPE_DROPDOWN_LABEL_MAP.get(nonPatientEventTypeCode) ||
    nonPatientEventTypeByCodeMap.get(nonPatientEventTypeCode)?.name;
  const { activeRole } = useCurrentUser();
  const isUsersRoleAreRestrictedToToSeeComment = activeRole === ROLE_ELLIGO_MANAGEMENT || activeRole === ROLE_FINANCE;
  return (
    <React.Fragment>
      <AppointmentViewField label={'Meeting type'} className="mb-4">
        <div className={'normal'}>{meetingTypeLabel}</div>
      </AppointmentViewField>
      {isSubjectVisible && (
        <AppointmentViewField label={'Event Name'} className="mb-4">
          <div className={'normal'}>{appointment?.subject}</div>
        </AppointmentViewField>
      )}
      <Divider className="mt-0" />
      {appointment?.studySite?.study?.name && (
        <AppointmentViewField label={'Study'} className="mb-4">
          <div className={'normal'}>{appointment.studySite.study.name}</div>
        </AppointmentViewField>
      )}
      {appointment?.studySite?.site?.name && (
        <AppointmentViewField label={'Site'} className="mb-4">
          <div className={'normal'}>{appointment.studySite.site.name}</div>
        </AppointmentViewField>
      )}
      <Divider className="mt-0" />
      <AppointmentViewField label={'Scheduled by'} className="reviewers-container mb-3">
        <ParticipantField participant={appointment?.organizer} />
      </AppointmentViewField>
      {participants?.length > 0 && (
        <AppointmentViewField label={'Attendees'} className="reviewers-container mb-3">
          {participants.map(participant => (
            <ParticipantField
              participant={participant}
              key={get(participant, 'userId', 'patientId')}
              className="mb-2"
            />
          ))}
        </AppointmentViewField>
      )}
      {appointment?.comment && !isUsersRoleAreRestrictedToToSeeComment && (
        <React.Fragment>
          <Divider className="mt-0" />
          <AppointmentViewField label={'Comment'}>
            <div className={'normal'}>{appointment.comment}</div>
          </AppointmentViewField>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
