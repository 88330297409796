import React from 'react';

import Section from '../../../../../../common/data-display/Section/Section';

import './CommentSectionForPublishedProtocolAndBudget.scss';

const CommentSectionForPublishedProtocolAndBudget = ({ comment }) => {
  return (
    <Section className="protocol-setup-comment">
      <span className="comment-title">Comments</span>
      <div className="comment-textarea">
        {comment?.split('\n').map(item => (
          <p>{item}</p>
        ))}
      </div>
    </Section>
  );
};

export default CommentSectionForPublishedProtocolAndBudget;
