import React, { useMemo } from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash/lang';
import * as PropTypes from 'prop-types';

import Common from '../../../../../../../../common/common';
import Checkbox from '../../../../../../../../common/data-entry/InputSelectors/Checkbox';
import { RowReviewStatus } from '../../../reviewConstants';
import { isSmOrPiReview } from '../../../reviewService';

import './LogCheckItemGroupContent.scss';

export default function LogItemGroupContent({ logCheckSnapshotState, reviewType }) {
  const logChecksToRender = useMemo(() => prepareLogChecksToRender(logCheckSnapshotState), [logCheckSnapshotState]);
  let highlightClassStyle = '';
  if (isSmOrPiReview(reviewType) && logCheckSnapshotState?.logCheckReviewStatus !== RowReviewStatus.SIGNED) {
    highlightClassStyle = 'new-not-signed-data-bg';
  }

  return (
    !isEmpty(logChecksToRender) && (
      <div className="eds-review-item-group-element-table-reflection">
        <div className="log-check-title">
          <span>Log Check</span>
        </div>
        <div className="log-check-data-about">
          <span>{logCheckSnapshotState?.logCheckSnapshot.whoDidItName}</span>
          <span>{Common.formatDate(logCheckSnapshotState?.logCheckSnapshot.whenWasItDone)}</span>
        </div>

        <div className="log-check-items">
          {logChecksToRender.map(answer => {
            return (
              <div
                className={cx('log-check-item', {
                  [highlightClassStyle]:
                    answer.edited || logCheckSnapshotState?.logCheckReviewStatus === RowReviewStatus.NEVER_SIGNED
                })}
              >
                {answer.isNotPerformed && (
                  <div className="log-check-question">
                    <div className="log-check-np-item">
                      <span className="log-check-np-header">Not Performed:</span>
                      <span className="log-check-np-reason">{answer.notPerformedReason}</span>
                    </div>
                  </div>
                )}
                {!answer.isNotPerformed && (
                  <>
                    <div className="log-check-question">
                      <div className="edited-answer-layout">
                        <div className={cx({ 'change-bullet': answer.edited })} />
                        <div className="log-check-question-value">{answer.logCheckValue}</div>
                      </div>
                    </div>
                    <div className="log-check-answer">
                      <Checkbox checked={answer.checked} disabled />
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
    )
  );
}

function prepareLogChecksToRender(logCheckSnapshotState) {
  return logCheckSnapshotState?.logCheckSnapshot.logCheckAnswers.map(e => {
    return {
      ...e,
      isNotPerformed: logCheckSnapshotState?.logCheckSnapshot.notPerformed,
      notPerformedReason: logCheckSnapshotState?.logCheckSnapshot.reasonCommentForNotPerformed
    };
  });
}

LogItemGroupContent.propTypes = {
  logCheckSnapshotState: PropTypes.object,
  reviewType: PropTypes.string
};
