import React, { useContext } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import cx from 'classnames';

import Input from '../../../../../../../common/data-entry/Input';
import TextArea from '../../../../../../../common/data-entry/TextArea';
import Button from '../../../../../../../common/general/Button';
import { TableSetupContext } from '../../../../../../../contexts/TableSetupContext';
import { onDragEnd } from '../TableField/TableFieldItem/EditCustomOptionListModal/EditCustomOptionListModal';
import { QUESTION_LAYOUT } from '../TableSetupConstants';

import RightPanel from './EditTableLabel/RightPanel';

import './TableSettings.scss';

const TableSettings = () => {
  const tableSetupContext = useContext(TableSetupContext);

  const classNameForVerticalRadioButton = cx('radio', {
    active: tableSetupContext.questionLayout === QUESTION_LAYOUT.VERTICAL
  });
  const classNameForHorizontalRadioButton = cx('radio', {
    active: tableSetupContext.questionLayout === QUESTION_LAYOUT.HORIZONTAL
  });

  const changeLayoutTo = layout => {
    if (tableSetupContext.questionLayout !== layout) {
      tableSetupContext.setQuestionLayout(layout);
    }
  };

  return (
    <div className="setting-wrapper">
      <div className="setting-header">
        <h5>Table Settings</h5>
      </div>
      <div className="setting-body">
        <div className="questions-layout">
          <span>Question Layout</span>
          <div className={classNameForVerticalRadioButton} onClick={() => changeLayoutTo(QUESTION_LAYOUT.VERTICAL)} />
          Vertical
          <div
            className={classNameForHorizontalRadioButton}
            onClick={() => changeLayoutTo(QUESTION_LAYOUT.HORIZONTAL)}
          />
          Horizontal
        </div>
        <Input
          className="title-input"
          label="Label Header"
          type="text"
          name="elementName"
          validate={false}
          value={tableSetupContext.tableTitle}
          onChange={({ target }) => tableSetupContext.setTableTitle(target.value)}
          disabled={false}
          required
        />
        <span className="label-for-input">Labels</span>
        <div className="add-label-wrapper">
          <TextArea
            className="label-input"
            name="Labels"
            value={tableSetupContext.labelField}
            required
            onChange={({ target }) => {
              tableSetupContext.setLabelField(target.value);
            }}
            onKeyDown={event => {
              if (event.key === 'Enter' && tableSetupContext.labelField.trim()) {
                event.preventDefault();
                tableSetupContext.addNewLabelInList();
              }
            }}
          />
          <Button
            className="add-button"
            onClick={() => tableSetupContext.addNewLabelInList()}
            disabled={!tableSetupContext.labelField.trim()}
          >
            +Add
          </Button>
        </div>
        <span className="add-label-description">Type in label and press Add Button</span>
        <DragDropContext
          onDragEnd={result => onDragEnd(result, tableSetupContext.labelList, tableSetupContext.setLabelList)}
        >
          <Droppable droppableId="list">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef} className="label-list">
                {tableSetupContext.labelList.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={cx('label-wrapper', { dragging: snapshot.isDragging })}
                      >
                        <div
                          className="label-item"
                          onClick={() => {
                            tableSetupContext.editLabel(item);
                          }}
                        >
                          {item.name}
                        </div>
                        <div
                          className="delete-label-item"
                          onClick={() => tableSetupContext.deleteLabelFromList(index)}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {tableSetupContext.editLabelMode && (
          <RightPanel
            header="Edit Label"
            toggleClose={() => tableSetupContext.setEditLabelMode(false)}
            onSave={tableSetupContext.saveUpdatedLabel}
          >
            <TextArea
              className="m-1 mt-3"
              label="Label"
              value={tableSetupContext.labelForEdit.name}
              required
              onChange={({ target: { value } }) => {
                tableSetupContext.setLabelForEdit({ ...tableSetupContext.labelForEdit, name: value });
              }}
            />
          </RightPanel>
        )}
      </div>
    </div>
  );
};

export default TableSettings;
