import axios from 'axios';

const ROOT = 'api/v1/load-patients';

const LoadPatientsApi = {
  importFromFileAndSaveToS3(ssuId, file) {
    return axios.post(`${ROOT}/${ssuId}/import-from-file`, file);
  },
  downloadSampleFile() {
    return axios.get(`${ROOT}/download-sample`, {
      responseType: 'arraybuffer'
    });
  }
};

export default LoadPatientsApi;
