import React from 'react';
import moment from 'moment/moment';

import { Amount } from '../../../../../shared/amount-view/Amount/Amount';
import { stylesContent } from '../../EncounterInvoiceTemplateStyles';

export const ReimbursementItem = ({ item }) => {
  return (
    <div
      style={{ ...stylesContent.invoiceItem, display: 'flex', gap: '3px' }}
      className="row-item"
      data-testid="ledger-event-item"
    >
      {`${item?.type || '—'} (${moment(item.eventDate).format('DD-MMM-YYYY')})`}
      <Amount coinsAmount={item.amount} showDollarSign={true} />
    </div>
  );
};
