import React from 'react';
import cx from 'classnames';
import * as PropTypes from 'prop-types';

import './ButtonGroup.scss';
const ButtonGroup = React.forwardRef(function({ direction, type, children, classNames, ...restProps }, ref) {
  return (
    <div
      ref={ref}
      className={cx('eui-btn-group', `eui-btn-group-direction-${direction}`, `eui-btn-group-type-${type}`, classNames)}
      {...restProps}
    >
      {children}
    </div>
  );
});
ButtonGroup.propTypes = {
  direction: PropTypes.oneOf(['column', 'row']),
  type: PropTypes.oneOf(['default', 'singular'])
};
ButtonGroup.defaultProps = {
  direction: 'row',
  type: 'default'
};

export default ButtonGroup;
