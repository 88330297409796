import { bool, number, string } from 'prop-types';

const fieldPropTypes = {
  // codeDefinition: PropTypes.array,
  // fieldComment: null,
  fieldName: string.isRequired,
  fieldType: string.isRequired, // TODO: replace with custom validation
  // fileList: null,
  // performedStatus: null,
  // fieldUid: '56ed186aa9bb11ec945b23694da6350d',
  fieldUid: string,
  // reasonForDataChange: null,
  requiredAttention: bool,
  sequence: number
};

export default fieldPropTypes;
