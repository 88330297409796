import React, { useContext } from 'react';
import { isEmpty, isEqual } from 'lodash/lang';

import Input from '../../../../../../../common/data-entry/Input';
import Select from '../../../../../../../common/data-entry/Select';
import { TableSetupContext } from '../../../../../../../contexts/TableSetupContext';
import { useCurrentRoute } from '../../../../../../root/router';
import TerminologyDropdown from '../../ItemGroupSetup/TerminologyDropdown';
import { CONTEXT_MODE } from '../TableSetupConstants';

import './ItemGroupDomainConfigurationTableSetup.scss';

const ItemGroupsDomainConfigurationTableSetup = () => {
  const tableSetupContext = useContext(TableSetupContext);
  const currentRoute = useCurrentRoute();

  const changeSelectedDomain = newValue => {
    if (!isEqual(newValue, tableSetupContext.selectedDomain)) {
      tableSetupContext.setSelectedDomain(newValue);
    }
  };

  const changeSelectedType = newValue => {
    if (!isEqual(newValue, tableSetupContext.selectedType)) {
      tableSetupContext.setSelectedType(newValue);
    }
  };

  const disableForFields =
    tableSetupContext.tableContextMode === CONTEXT_MODE.EDIT || currentRoute.name === 'Item Group Table Template Setup';

  return (
    <div className="general-header-wrapper">
      {tableSetupContext.isTemplateMode && currentRoute.name !== 'Item Group Table Template Setup' && (
        <TerminologyDropdown
          terminologyVersionGroupNumber={tableSetupContext.selectedTerminologyVersionGroups}
          updateTerminologyVersion={newTerminologyVersion => {
            if (
              !isEmpty(newTerminologyVersion) &&
              tableSetupContext.selectedTerminologyVersionGroups !== newTerminologyVersion?.number
            ) {
              tableSetupContext.updateTerminologyVersion(
                newTerminologyVersion.number,
                tableSetupContext.selectedTerminologyVersionGroups
              );
            }
          }}
          editMode={disableForFields && currentRoute.name !== 'Table Template Copy'}
        />
      )}
      <Select
        clearSearchOnSelection
        dataSource={tableSetupContext.domainList}
        label="ItemGroup Domain"
        optionLabelKey="domainName"
        optionValueKey="domainIdentifier"
        onChange={changeSelectedDomain}
        searchable
        keepOpenOnSelection={false}
        closeOnSelectedOptionClick
        deselectOnSelectedOptionClick={false}
        disabled={disableForFields}
        validate={false}
        customOptionTemplateFunction={customOptionTemplateFunction}
        clearable={false}
        value={tableSetupContext.selectedDomain}
        required
      />
      <Select
        clearSearchOnSelection
        label="ItemGroup Type"
        dataSource={tableSetupContext.typeList}
        onChange={changeSelectedType}
        searchable
        validate={false}
        clearable={false}
        value={tableSetupContext.selectedType}
        required
        disabled={disableForFields}
      />
      <Input
        label={tableSetupContext.isTemplateMode ? 'Template Name' : 'ItemGroup Name'}
        type="text"
        name="elementName"
        validate={false}
        value={tableSetupContext.itemGroupName}
        onChange={e => tableSetupContext.setItemGroupName(e.target.value)}
        required
        disabled={currentRoute.name === 'Item Group Table Template Setup' && !tableSetupContext.isTemplateMode}
      />
    </div>
  );
};

function customOptionTemplateFunction(item) {
  return <span title={item.domainName}>{item.domainName}</span>;
}

export default ItemGroupsDomainConfigurationTableSetup;
