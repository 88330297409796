import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as PropTypes from 'prop-types';

import { TaskApi } from '../../../../../../../api';
import Select from '../../../../../../../common/data-entry/Select';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';
import { getTittleByRole } from '../../../../../../../services/personnelService';
import { selectRole } from '../../../../../../../services/userRoleService';
import { useCurrentRoute } from '../../../../../router';
import { useCurrentUser } from '../../../../CurrentUserContainer';

import './TaskAccessDeniedModal.scss';

export default function TaskAccessDeniedWithRoleChangeModal(props) {
  const { modalBox, taskId, studySiteId, userHasOnlyCurrentRole } = props;
  const [selectedRole, setSelectedRole] = useState(null);
  const [isSelectFilled, setIsSelectFilled] = useState(true);
  const [mappedRolesForSelect, setMappedRolesForSelect] = useState([]);

  const user = useCurrentUser();
  const navigate = useNavigate();
  const route = useCurrentRoute();

  const hasMoreThanOneRole = mappedRolesForSelect && mappedRolesForSelect.length > 1;
  const hasOnlyOneRole = mappedRolesForSelect && mappedRolesForSelect.length === 1;

  useEffect(() => {
    TaskApi.getUserRolesAllowedForViewTasksPerSsu(taskId, studySiteId, user.personnelIdentifier).then(resp => {
      const roles = resp.data
        .filter(role => role !== user.activeRole)
        .map(role => {
          return {
            userRole: role,
            userRoleName: getTittleByRole(role)
          };
        });
      setMappedRolesForSelect(roles);
    });
  }, [taskId, studySiteId, user]);

  return (
    <>
      <ModalBoxes.Header>Change Role</ModalBoxes.Header>
      <ModalBoxes.Body>
        {userHasOnlyCurrentRole && (
          <p>
            Your role is not authorized to access this information. If you require access, please contact IntElligo
            Support.
          </p>
        )}
        {hasOnlyOneRole && (
          <p>
            Your current role is not authorized to access this information. Would you like to change your current role
            to {getTittleByRole(mappedRolesForSelect[0]?.userRole)}?
          </p>
        )}
        {hasMoreThanOneRole && (
          <div>
            <p>
              Your current role is not authorized to access this information. Would you like to change your current
              role?
            </p>
            <Select
              label="Role"
              onChange={role => {
                setIsSelectFilled(true);
                setSelectedRole(role);
              }}
              dataSource={mappedRolesForSelect.sort((a, b) => a.userRoleName.localeCompare(b.userRoleName))}
              optionLabelKey="userRoleName"
              optionValueKey="userRole"
              value={selectedRole}
              required={true}
              validationMessage={!isSelectFilled ? 'Field is required' : ''}
            />
          </div>
        )}
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <div className="eds-btn-group">
          <ButtonGroup>
            {userHasOnlyCurrentRole ? (
              <Button priority="high" onClick={modalBox.close}>
                Close
              </Button>
            ) : (
              <>
                <Button priority="medium" onClick={modalBox.close}>
                  No
                </Button>
                <Button priority="high" onClick={changeUserRole}>
                  Yes
                </Button>
              </>
            )}
          </ButtonGroup>
        </div>
      </ModalBoxes.Footer>
    </>
  );

  function changeUserRole() {
    if (mappedRolesForSelect && mappedRolesForSelect.length === 1) {
      navigate(route.pathname + `?taskId=${taskId}&taskSsuId=${studySiteId}`);
      selectRole(mappedRolesForSelect[0]?.userRole);
    } else {
      if (!selectedRole) {
        setIsSelectFilled(false);
        return;
      }
      navigate(route.pathname + `?taskId=${taskId}&taskSsuId=${studySiteId}`);
      selectRole(selectedRole.userRole);
    }
    modalBox.close();
  }
}

TaskAccessDeniedWithRoleChangeModal.propTypes = {
  userRoles: PropTypes.array
};

TaskAccessDeniedWithRoleChangeModal.className = 'eds-task-access-with-role-change-denied';
TaskAccessDeniedWithRoleChangeModal.size = 'w650';
