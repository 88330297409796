import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortBy } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

import { RevenueAdjustmentApi } from '../../../../../api';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { LEDGER_EVENTS_CREATED } from '../../../../../constants/notificationMessages';
import { generateUrlByKey, useCurrentRoute } from '../../../../root/router';

import { PATIENT_REIMBURSEMENT, PATIENT_STIPEND } from './AdjustmentConstants';
import getSummaryOfAdjustment from './revenueAdjustmentSummaryService';

export const AdjustmentContext = React.createContext(null);

export function AdjustmentProvider({ children }) {
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const navigate = useNavigate();
  const currentRoute = useCurrentRoute();
  const { eventNumber } = currentRoute.params;
  const [metadata, setMetadata] = useState(null);
  const [tableEvents, setTableEvents] = useState([]);
  const [newTableEvents, setNewTableEvents] = useState([]);
  const [isPatientStipend, setIsPatientStipend] = useState(false);
  const [isExpenseVariable, setExpenseVariable] = useState(false);
  const [requestData, setRequestData] = useState({});
  const previousAdjustmentResults = useMemo(() => getSummaryOfAdjustment([...tableEvents, ...newTableEvents]), [
    tableEvents,
    newTableEvents
  ]);
  useEffect(() => {
    RevenueAdjustmentApi.getLedgerEventInfo(eventNumber).then(({ data }) => {
      setMetadata(data);
      setIsPatientStipend([PATIENT_STIPEND, PATIENT_REIMBURSEMENT].includes(data.type));
      setExpenseVariable(data.type === 'Expense - Variable');
    });
    RevenueAdjustmentApi.getRevenueAdjustmentHistory(eventNumber).then(({ data }) => {
      setTableEvents(data);
    });
  }, [eventNumber]);

  useEffect(
    function() {
      if (!isEmpty(requestData)) {
        RevenueAdjustmentApi.getCalculatedRevenueAdjustments(eventNumber, requestData).then(({ data }) => {
          if (data) {
            setNewTableEvents(() => {
              const newEventsList = [...data];
              const markedLikePreviewEvent = newEventsList.map(event => ({ preview: true, ...event }));
              return sortBy(markedLikePreviewEvent, 'id.adjustmentVersionId');
            });
          }
        });
      }
    },
    [requestData, eventNumber]
  );

  return (
    <AdjustmentContext.Provider
      value={{
        metadata,
        isExpenseVariable,
        previousAdjustmentResults,
        tableEvents,
        newTableEvents,
        isPatientStipend,
        setRequestData,
        save,
        disableSaveButton,
        setDisableSaveButton,
        setNewTableEvents
      }}
    >
      {children}
    </AdjustmentContext.Provider>
  );

  function save() {
    RevenueAdjustmentApi.saveRevenueAdjustmentGroup(eventNumber, requestData).then(({ data }) => {
      NotificationManager.success(LEDGER_EVENTS_CREATED);
      navigate(generateUrlByKey('Revenue'));
    });
  }
}
