import React, { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@mui/material';

import { MANAGE_INVOICE } from '../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../services/auth';
import { InvoiceContext } from '../../NewInvoiceContext';
import { openDepositHistory } from '../DepositHistoryModal/DepositHistoryModal';
import { CREDIT_MEMO, DEBIT_MEMO } from '../TableConstants';

export const TableItemMenuMUI = ({ row }) => {
  const { ledgerEvents, getDataForCreateInvoice } = useContext(InvoiceContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  const userHasAccessMenageInvoice = useMemo(() => userHasAccessTo(MANAGE_INVOICE), []);

  const eventTypeIsWH = useMemo(() => row.tableId.search(/-WH$/) !== -1, [row.tableId]);

  const withholdingEventHasParentWithInvoice = useMemo(() => {
    const parentEvent = ledgerEvents.find(ledgerEvent => ledgerEvent.tableId === row.tableId.replace(/-WH$/, ''));
    return !!parentEvent?.invoiceNumber;
  }, [ledgerEvents, row.tableId]);

  const selectedEventIsCMorDMAndParentHasInvoice = useMemo(() => {
    const selectedCMOrDMInvoice = [CREDIT_MEMO, DEBIT_MEMO].includes(row.invoiceType);
    const selectedEventParentHasInvoice = !!ledgerEvents.find(
      ledgerEvent => ledgerEvent.eventNumber === row.eventNumber && ledgerEvent.adjustmentSequence === 0
    )?.invoiceNumber;
    return selectedCMOrDMInvoice && !selectedEventParentHasInvoice;
  }, [ledgerEvents, row.eventNumber, row.invoiceType]);

  return (
    <>
      <MoreVertIcon onClick={openMenu} data-testid={`event-row-menu-${row.eventNumber}`} />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        slotProps={{
          paper: {
            sx: {
              width: '143px'
            }
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {userHasAccessMenageInvoice && (
          <MenuItem
            onClick={() => {
              getDataForCreateInvoice([row.tableId]);
              handleCloseMenu();
            }}
            disabled={
              (!!row.invoiceNumber && !!row.invoiceDate) ||
              (eventTypeIsWH && !withholdingEventHasParentWithInvoice) ||
              selectedEventIsCMorDMAndParentHasInvoice
            }
          >
            Create Invoice
          </MenuItem>
        )}
        {userHasAccessMenageInvoice && (
          <MenuItem
            onClick={() => {
              navigate('/invoice/reconcile', {
                state: { reconcileIds: [{ eventId: row.eventId, invoiceType: row.invoiceType }] }
              });
            }}
            disabled={!row.invoiceNumber || !row.invoiceDate}
          >
            Reconcile
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            openDepositHistory(row);
            handleCloseMenu();
          }}
          disabled={!row.depositNumber || !row.postedDate}
        >
          Deposit History
        </MenuItem>
      </Menu>
    </>
  );
};
