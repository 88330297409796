import React, { useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty, isString } from 'lodash/lang';
import { get } from 'lodash/object';

import IconButton from '../../common/buttons/IconButton';
import { LoadingHandler } from '../../common/elements/Loader/LoadingHandler';
import { FileViewerContext } from '../../contexts/FileViewerContext';
import { removeQuotesIfExist } from '../../services/string';

import { FvCsvViewer } from './FvCsvViewer/FvCsvViewer';
import { FvErrorHandler } from './FvErrorHandler/FvErrorHandler';
import { FvImageViewer } from './FvImageViewer/FvImageViewer';
import FvInfo from './FvInfo/FvInfo';
import { FvNoViewer } from './FvNoViewer/FvNoViewer';
import { FvPdfViewer } from './FvPdfViewer/FvPdfViewer';

import './FileViewer.scss';
/**
 * @return {null}
 */
function FileViewer() {
  const fileViewer = useContext(FileViewerContext),
    { filePromise, displayed } = fileViewer.state,
    [fileObject, setFileObject] = useState(null),
    [errorObject, setErrorObject] = useState(null),
    [fileInfo, setFileInfo] = useState(null);

  const preparedRequest = useMemo(
    function() {
      if (filePromise) {
        return filePromise;
      }
      return null;
    },
    [filePromise]
  );
  const info = useMemo(
    function() {
      return fileInfo && fileInfo?.certifyCopy && <FvInfo fileInfo={fileInfo} />;
    },
    [fileInfo]
  );
  useEffect(
    function() {
      setFileInfo(null);
      if (!preparedRequest) {
        return;
      }
      let canceled = false;
      preparedRequest
        .then(({ data: blob, headers, id, fileInfo }) => {
          setFileInfo(fileInfo);
          if (canceled) {
            return;
          }
          const filename = removeQuotesIfExist(
            headers['content-disposition'].match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
          );
          if (blob) {
            setFileObject({ name: filename, type: blob.type, url: URL.createObjectURL(blob), id });
          }
        })
        .catch(err => {
          const statusCode = get(err, 'response.status');
          const message = get(err, 'response.message');

          if (![404, 409, 502].includes(statusCode)) {
            if (isString(message) && !isEmpty(message)) {
              setErrorObject({ customMessage: message });
            }
            throw err;
          }
          setErrorObject({
            statusCode
          });
        });
      return function() {
        canceled = true;
      };
    },
    [preparedRequest]
  );

  useEffect(
    function() {
      if (displayed) {
        return;
      }
      if (fileObject && fileObject.url) {
        URL.revokeObjectURL(fileObject.url);
        setFileObject(null);
      }
      setErrorObject(null);
    },
    [displayed, fileObject]
  );

  if (!displayed) {
    return null;
  }

  const Viewer = componentResolver(fileObject && fileObject.type);

  return (
    <div className="eds-file-viewer">
      <div className="eds-file-viewer-header">
        <div className="eds-file-viewer-header-left">
          {fileObject && (
            <a
              className="eds-icon-btn material-icons"
              href={fileObject.url}
              rel="noopener noreferrer"
              target="_blank"
              download={fileObject.name}
              title="Download original file"
            >
              save_alt
            </a>
          )}
        </div>
        <div className="eds-file-viewer-header-center">{fileObject && fileObject.name}</div>
        <div className="eds-file-viewer-header-right">
          <IconButton
            onClick={() =>
              fileViewer.close({
                ...fileObject,
                isFileWithPreview: isFileWithPreview(fileObject?.type)
              })
            }
            title="Close"
          >
            close
          </IconButton>
        </div>
      </div>
      <div className="eds-file-viewer-container">
        <FvErrorHandler error={errorObject}>
          <LoadingHandler complete={!isEmpty(fileObject)}>
            <Viewer file={fileObject} />
          </LoadingHandler>
        </FvErrorHandler>
      </div>
      {info}
    </div>
  );
}

function componentResolver(type) {
  switch (type) {
    case 'image/jpeg':
    case 'image/jpg':
    case 'image/png':
      return FvImageViewer;
    case 'application/pdf':
      return FvPdfViewer;
    case 'text/csv':
      return FvCsvViewer;
    default:
      return FvNoViewer;
  }
}

function isFileWithPreview(type) {
  return ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'text/csv'].includes(type);
}

export default FileViewer;
