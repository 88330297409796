import React, { Fragment, useMemo, useRef } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import cx from 'classnames';

import ItemGroupsEncountersTableHeader from './ItemGroupsEncountersTableHeader/ItemGroupsEncountersTableHeader';
import ItemGroupsEncountersTableSidebar from './ItemGroupsEncountersTableSidebar/ItemGroupsEncountersTableSidebar';
import propTypes from './types';

import './ItemGroupsEncountersTable.scss';

export default function ItemGroupsEncountersTable({
  className,
  epochs,
  itemGroups,
  rowKey = 'itemGroupKey',
  dataMap,
  RowTitleCell,
  HeaderCell,
  EncounterCell,
  Cell
}) {
  const parentRef = useRef();

  const encounters = useMemo(
    function() {
      if (!epochs) return null;
      return epochs.flatMap(({ encounters }) => encounters);
    },
    [epochs]
  );

  const [encountersLength, itemGroupsLength] = useMemo(
    function() {
      if (!encounters || !itemGroups) return [0, 0];
      return [encounters.length, itemGroups.length];
    },
    [encounters, itemGroups]
  );

  const rowVirtualizer = useVirtualizer({
    count: itemGroupsLength,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 45,
    overscan: 5
  });

  const columnVirtualizer = useVirtualizer({
    horizontal: true,
    count: encountersLength,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 90,
    overscan: 5
  });

  return (
    <div ref={parentRef} className={cx('item-groups-encounters-table', className)}>
      <div
        className="item-groups-encounters-table-cells-wrapper"
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: `${columnVirtualizer.getTotalSize()}px`
        }}
      >
        <ItemGroupsEncountersTableSidebar rowKey={rowKey} itemGroups={itemGroups} RowTitleCell={RowTitleCell} />
        <ItemGroupsEncountersTableHeader epochs={epochs} HeaderCell={HeaderCell} EncounterCell={EncounterCell} />
        {rowVirtualizer.getVirtualItems().map(virtualRow => (
          <Fragment key={virtualRow.index}>
            {columnVirtualizer.getVirtualItems().map(function(virtualColumn) {
              const cellKey = dataMap[`${virtualRow.index}_${virtualColumn.index}`];
              return (
                <div
                  key={virtualColumn.index}
                  className="item-groups-encounters-table-cell-wrapper"
                  style={{
                    width: `${virtualColumn.size}px`,
                    height: `${virtualRow.size}px`,
                    transform: `translateX(${virtualColumn.start}px) translateY(${virtualRow.start}px)`
                  }}
                >
                  <Cell cellKey={cellKey} />
                </div>
              );
            })}
          </Fragment>
        ))}
      </div>
    </div>
  );
}

ItemGroupsEncountersTable.propTypes = propTypes;
