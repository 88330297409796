import React, { useContext } from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import Loader from '../../../../../common/elements/Loader/Loader';
import { scElligoRed } from '../../../../../constants/systemColors';
import WidgetDefaultFilters from '../common/WidgetDefaultFilters';
import { getValidationString } from '../common/WidgetDefaultService';
import WidgetFilterChips from '../common/WidgetFilterChips';

import PendingScheduleWidgetFiltersContext, {
  PendingScheduleWidgetContext
} from './PendingScheduleWidgetFiltersContext';
import PendingScheduleWidgetTable from './PendingScheduleWidgetTable';

import '../common/WidgetDefaultStyles.scss';

function PendingScheduleWidgetEl({ widget }) {
  const Context = useContext(PendingScheduleWidgetContext);

  const { filter, validationError, isLoadingFilters, isFetching, tableData } = Context;

  return (
    <div className={cx('p-3 widget-container default-widget', 'size-l')} key={5}>
      <div className="widget">
        <div className="widget-header p-2 px-3">
          <h5 className="title">{widget.name}</h5>
        </div>

        {!isLoadingFilters && <WidgetDefaultFilters isFetching={isLoadingFilters} {...Context} />}
        <div className="px-3 c-p" style={{ minHeight: '24px', color: scElligoRed }}>
          {validationError ? getValidationString(filter) : ''}
        </div>

        {!isEmpty(filter) && <WidgetFilterChips {...Context} />}

        {(isFetching || isLoadingFilters) && <Loader dark={true} />}
        {!isFetching && !isLoadingFilters && !isEmpty(tableData) && <PendingScheduleWidgetTable />}
      </div>
    </div>
  );
}

export default function PendingScheduleWidget(props) {
  return (
    <PendingScheduleWidgetFiltersContext {...props}>
      <PendingScheduleWidgetEl {...props} />
    </PendingScheduleWidgetFiltersContext>
  );
}
