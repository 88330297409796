import axios from 'axios';

const ROOT = `api/v1/ssu-patients`;

const PatientReimbursementApi = {
  getEncounters(ssuPatientId) {
    return axios.get(`${ROOT}/${ssuPatientId}/reimbursements/encounters`, { hideLoader: false });
  },
  addReimbursementItem(ssuPatientId, reimbursementId, data) {
    return axios.post(
      `${ROOT}/${ssuPatientId}/reimbursements/${reimbursementId ? `?reimbursementId=${reimbursementId}` : ''}`,
      data,
      {
        hideLoader: false
      }
    );
  },
  getReimbursement(ssuPatientId, reimbursementId) {
    return axios.get(`${ROOT}/${ssuPatientId}/reimbursements/${reimbursementId}`, { hideLoader: false });
  },
  getReimbursementForSsuPatient(ssuPatientId) {
    return axios.get(`${ROOT}/${ssuPatientId}/reimbursements`, { hideLoader: true });
  },
  getReimbursementFile(ssuPatientId, reimbursementId, itemId, id) {
    return axios.get(`${ROOT}/${ssuPatientId}/reimbursements/${reimbursementId}/items/${itemId}/files/${id}`, {
      hideLoader: true
    });
  },
  updateReimbursementStatus(ssuPatientId, reimbursementId, status, totalAmount) {
    return axios.put(
      `${ROOT}/${ssuPatientId}/reimbursements/${reimbursementId}/statuses`,
      {
        status,
        totalAmount
      },
      {
        hideLoader: true
      }
    );
  },
  updateReimbursementItem(ssuPatientId, reimbursementId, itemId, data) {
    return axios.put(`${ROOT}/${ssuPatientId}/reimbursements/${reimbursementId}/items/${itemId}`, data, {
      hideLoader: true
    });
  },
  deleteReimbursementItem(ssuPatientId, reimbursementId, itemId) {
    return axios.delete(`${ROOT}/${ssuPatientId}/reimbursements/${reimbursementId}/items/${itemId}`, {
      hideLoader: false
    });
  },
  addComment(ssuPatientId, reimbursementId, comment) {
    return axios.post(
      `${ROOT}/${ssuPatientId}/reimbursements/${reimbursementId}/comments`,
      { comment },
      {
        hideLoader: false
      }
    );
  }
};

export default PatientReimbursementApi;
