import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ControlledMenu,
  FocusableItem,
  MenuDivider,
  MenuGroup,
  MenuItem,
  SubMenu,
  useMenuState
} from '@szhsin/react-menu';
import { sortBy } from 'lodash';
import { uniq } from 'lodash/array';
import { cloneDeep, isEmpty } from 'lodash/lang';

import { ItemGroupTemplatesApi } from '../../../../../../../api';
import Input from '../../../../../../../common/data-entry/Input';
import Button from '../../../../../../../common/general/Button';
import Icon from '../../../../../../../common/general/Icon';
import HighlightWithTooltip from '../../../../../../root/Container/Layout/MenuOptions/FindPatient/FindPatientPopup/FindPatientPopupItem/HighlightWithTooltip';
import { generateUrlByKey, useCurrentRoute } from '../../../../../../root/router';

import './AddNewItemGroup.scss';

export default function AddNewItemGroup({ terminologyVersionGroupNumber }) {
  const currentRoute = useCurrentRoute();
  const studyId = currentRoute.params?.studyId;
  const protocolIdentity = currentRoute?.params.protocolIdentity;
  const [templates, setTemplates] = useState([]);
  const [domainToTemplatesMap, setDomainToTemplatesMap] = useState({});
  const { openMenu, closeMenu, toggleMenu, menuItemFocus, ...menuProps } = useMenuState();
  const [searchString, setSearchString] = useState('');
  const ref = useRef();

  useEffect(
    function() {
      if (terminologyVersionGroupNumber) {
        ItemGroupTemplatesApi.getItemGroupTemplatesByTerminologyVersionGroupNumber(
          terminologyVersionGroupNumber
        ).then(({ data }) => setTemplates(data));
      }
    },
    [terminologyVersionGroupNumber]
  );

  useEffect(
    function() {
      if (!isEmpty(templates)) {
        setDomainToTemplatesMap(templatesMapper(templates));
      }
    },
    [templates]
  );

  useEffect(
    function() {
      function isStringIncludeString(name = '', string = '') {
        return isEmpty(string) || name?.toLowerCase().includes(string?.toLowerCase());
      }

      const filteredTemplates = templates.filter(({ domainName, name }) => {
        return isStringIncludeString(domainName, searchString) || isStringIncludeString(name, searchString);
      });
      setDomainToTemplatesMap(templatesMapper(filteredTemplates));
    },
    [searchString, templates]
  );

  return (
    <div className="add-new-item-group">
      <Button ref={ref} onClick={openMenu} size="h28">
        <Icon>add</Icon>
        Item Group
        <Icon>arrow_drop_down</Icon>
      </Button>
      <ControlledMenu
        anchorRef={ref}
        align="end"
        direction="bottom"
        onClose={onClose}
        {...menuProps}
        className="igl-add"
      >
        <MenuItem className="add-new-link">
          <Link
            to={generateUrlByKey(`${currentRoute.key}.Item Group Setup`, {
              studyId,
              protocolIdentity
            })}
          >
            New Item Group
          </Link>
        </MenuItem>
        <SubMenu itemClassName="igt-sub" label={<span>New From Template</span>}>
          <FocusableItem className="igl-search-template">{getInput}</FocusableItem>
          <MenuGroup className="submenu-group">
            {Object.keys(domainToTemplatesMap).map((domainName, i) => {
              const domain = domainToTemplatesMap[domainName];
              return (
                <MenuGroup key={i}>
                  <FocusableItem className="igl-item-domain" styles={{ hover: false }} onClick={onClick(domainName)}>
                    {getDomainHeader(domainName, domain.show)}
                  </FocusableItem>
                  {renderTemplates(domain)}
                  <MenuDivider />
                </MenuGroup>
              );
            })}
          </MenuGroup>
        </SubMenu>
      </ControlledMenu>
    </div>
  );
  function renderTemplates(domain) {
    return (
      domain?.show &&
      domain.templates.map((e, i) => {
        const patchForRedirect =
          e.type === 'New Table'
            ? generateUrlByKey(`${currentRoute.key}.Item Group Table Template Setup`, {
                itemGroupTemplateId: e.itemGroupTemplateId,
                protocolIdentity,
                studyId
              })
            : generateUrlByKey(`${currentRoute.key}.Item Group Template Setup`, {
                itemGroupTemplateId: e.itemGroupTemplateId,
                protocolIdentity,
                studyId
              });
        return (
          <MenuItem className="igl-template" key={i}>
            <Link to={patchForRedirect}>
              <span className="value">
                <HighlightWithTooltip word={e.name} referredLength={26} />
              </span>
            </Link>
          </MenuItem>
        );
      })
    );
  }
  function getDomainHeader(domain, show) {
    return () => (
      <>
        <span>{domain}</span>
        <Icon className="igl-icon">{show ? 'arrow_drop_down' : 'arrow_right'}</Icon>
      </>
    );
  }
  function getInput() {
    return (
      <Input
        onChange={({ target: { value } }) => {
          setSearchString(value);
        }}
        label="Search Template"
        validate={false}
        iconsAfter={<Icon>search</Icon>}
        value={searchString}
      />
    );
  }
  function onClose() {
    setDomainToTemplatesMap(function(templates) {
      const templatesNew = cloneDeep(templates);
      Object.keys(templatesNew).forEach(e => {
        templatesNew[e].show = true;
      });
      setSearchString('');
      return templatesNew;
    });
    closeMenu();
  }
  function onClick(domainName) {
    return () =>
      setDomainToTemplatesMap(function(templates) {
        const newTemplates = cloneDeep(templates);
        newTemplates[domainName].show = !newTemplates[domainName].show;
        return newTemplates;
      });
  }
}
function templatesMapper(templates) {
  const obj = {};
  const domains = sortBy(templates.map(({ domainName }) => domainName));
  uniq(domains).forEach(function(e) {
    obj[e] = {
      templates: sortBy(
        templates.filter(({ domainName }) => domainName === e),
        'name'
      ),
      show: true
    };
  });
  return obj;
}
