import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { cloneDeep } from 'lodash/lang';
import moment from 'moment';

import { pendoTrackDefaultSortingChange } from '../../../../pendo/PendoUtils';

import { MONTH_DISPLAY_FORMAT, MONTH_PROCESSING_FORMAT, VISIBLE_MONTHS_COUNT } from './projectedRevenueConstants';

class ProjectedRevenueByType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studyIdentifier: '',
      studySiteIdentifier: '',
      newConsentsAll: [],
      followupVisitsAll: [],
      newConsentsFiltered: [],
      data: [],
      columnsByType: [],
      filteredSSUs: [],
      areas: [],
      allSSUs: [],
      filteredAreasSSUs: [],
      filteredAreas: [],
      showNewConsents: true,
      showFollowUpVisits: true,
      types: [
        { id: 1, name: 'New Consents' },
        { id: 2, name: 'Followup Visits' }
      ]
    };
  }

  componentDidMount() {
    let columnsByType;
    if (this.props.total) {
      columnsByType = cloneDeep(totalColumns);
    } else {
      columnsByType = cloneDeep(columns);
      this.prepareDetailsLink(columnsByType);
    }
    this.prepareVisibleMonths(columnsByType);
    this.setState({ columnsByType: columnsByType });
  }

  prepareDetailsLink = columnsByType => {
    columnsByType[0].Cell = this.getLinkFor(this.props.type);
  };

  prepareVisibleMonths = columnsByType => {
    let today = moment();
    columnsByType.push({
      Header: today.format(MONTH_DISPLAY_FORMAT),
      accessor: today.format(MONTH_PROCESSING_FORMAT),
      className: 'align-amount-end',
      headerClassName: 'align-amount-end'
    });
    for (let i = 0; i < VISIBLE_MONTHS_COUNT - 1; i++) {
      const monthNext = today.add(1, 'month');
      columnsByType.push({
        Header: monthNext.format(MONTH_DISPLAY_FORMAT),
        accessor: monthNext.format(MONTH_PROCESSING_FORMAT),
        className: 'align-amount-end',
        headerClassName: 'align-amount-end'
      });
    }
  };

  getLinkFor = type => {
    return ({ original }) => {
      const uuid = original.uniqueIdentifier;
      const study = original.study.studyName;
      const site = original.site.siteName;
      const link = `/projected-revenue/details/${type}/${uuid}`;
      return (
        <Link to={link}>
          {study} - {site}
        </Link>
      );
    };
  };

  render({ props, state } = this) {
    const { columnsByType } = state;
    const { data } = props;

    return (
      <React.Fragment>
        <section>
          <div className="container-fluid border mt-2 py-3">
            <div className="row pt-2">
              <div className="col-md-12">
                <h5 className="c-p">{this.props.title || ''}</h5>
              </div>
              <div className="col-md-12">
                <ReactTable
                  columns={columnsByType}
                  data={data}
                  minRows={1}
                  onSortedChange={pendoTrackDefaultSortingChange}
                  multiSort={true}
                  showPagination={props.showPagination && data.length > 0}
                  nextText=">>"
                  previousText="<<"
                  defaultPageSize={10}
                  noDataText="No Record Found"
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default ProjectedRevenueByType;

const columns = [
  {
    id: 'id',
    Header: 'Study Site',
    width: 280,
    accessor: original =>
      `${original.study ? original.study.studyName : ''} ${original.site ? original.site.siteName : ''}`
  },
  {
    Header: 'Therapeutic Area',
    accessor: 'study.therapeuticArea'
  }
];

const totalColumns = [
  {
    id: 'id',
    Header: 'Type',
    width: 280,
    accessor: 'type'
  }
];
