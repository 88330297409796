import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import cx from 'classnames';
import { cloneDeep } from 'lodash';
import { filter } from 'lodash/collection';
import { isEmpty, isFunction } from 'lodash/lang';
import uuid from 'uuid';

import Checkbox from '../../../../../common/data-entry/InputSelectors/Checkbox';
import Toggle from '../../../../../common/data-entry/Toggle';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Icon from '../../../../../common/general/Icon';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { scGray15 } from '../../../../../constants/systemColors';
import { withFileViewerContext } from '../../../../../contexts/FileViewerContext';
import { getFileTitle, openFile, resolveFileIcon } from '../../../../../services/fileService';
import { doesStringContainNullByte } from '../../../../../services/string';

import './MultipleFileTemplate.scss';

const defaultAcceptedUploadTypes = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'image/png',
  'image/jpeg'
];

/** @deprecated **/
function MultipleFileTemplate({
  formGroupIdentifier,
  templateData,
  deleteFile,
  deleteFileForSingleFileUpload,
  onChangeFileHandler,
  isAbleToEditFunction,
  onCloseSelectedFile,
  fileViewer,
  isSomeCertifyCopyWasNotConfirmedAfterSaving,
  multiple,
  askForCertificateCopy = true,
  acceptUploadTypes = null,
  isFilePreviewAvailable = true
}) {
  const [existedFiles, setExistedFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [existedFilesCount, setExistedFilesCount] = useState(0);

  const isAbleToEdit = isFunction(isAbleToEditFunction) && isAbleToEditFunction();

  useEffect(() => {
    const lastViewedFile = fileViewer.state.lastFile;
    if (lastViewedFile?.isFileWithPreview && !isEmpty(selectedFiles)) {
      const viewedNewFile = selectedFiles.find(selectedFile => selectedFile.id === lastViewedFile.id);
      if (viewedNewFile?.isCertifyCopy) {
        viewedNewFile.isCertifyCopyConfirmed = true;
        onChangeFileHandler && onChangeFileHandler(selectedFiles, templateData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileViewer]);

  useEffect(() => {
    if (isSomeCertifyCopyWasNotConfirmedAfterSaving) {
      const filesMarkedAsTriggeredForValidation = cloneDeep(selectedFiles);
      filesMarkedAsTriggeredForValidation.forEach(file => {
        file.validationTriggered = true;
      });
      setSelectedFiles(filesMarkedAsTriggeredForValidation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSomeCertifyCopyWasNotConfirmedAfterSaving]);

  useEffect(() => {
    const { fileList } = templateData;
    if (!isEmpty(fileList)) {
      const filesToDisplay = filter(fileList, file => file.formGroupIdentifier === formGroupIdentifier);
      setExistedFiles(filesToDisplay);
      if (filesToDisplay.length > existedFilesCount) {
        setSelectedFiles([]);
      }
      setExistedFilesCount(filesToDisplay.length);
    } else {
      setExistedFiles([]);
      setExistedFilesCount(0);
    }
  }, [templateData, formGroupIdentifier, existedFilesCount]);

  const updateFileList = (acceptedFiles, newFileWithProperties) => {
    const updatedFiles = multiple
      ? [...selectedFiles, ...newFileWithProperties]
      : isEmpty(newFileWithProperties)
      ? [...selectedFiles]
      : newFileWithProperties;
    onChangeFileHandler && onChangeFileHandler(updatedFiles, templateData);
    setSelectedFiles(updatedFiles);
    const emptyFileNames = acceptedFiles
      .filter(file => file.size === 0)
      .map(file => file.name)
      .join(', ');
    if (!isEmpty(emptyFileNames)) {
      NotificationManager.error(`The file(s) ${emptyFileNames} cannot be uploaded because they are empty.`);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: (acceptUploadTypes || defaultAcceptedUploadTypes).join(),
    multiple,
    disabled: !isAbleToEdit,
    onDrop: acceptedFiles => {
      const nullByteFileNames = acceptedFiles
        .filter(newFile => doesStringContainNullByte(newFile?.name))
        .map(file => file.name)
        .join(', ');

      if (!isEmpty(nullByteFileNames)) {
        NotificationManager.error(
          `The file(s) ${nullByteFileNames} cannot be uploaded because the file name format is unacceptable. Please rename the file(s) and re-upload`
        );
      }

      const notNullByteFileList = acceptedFiles.filter(file => !doesStringContainNullByte(file?.name));

      const newFiles = notNullByteFileList.filter(
        newFile =>
          newFile.size !== 0 &&
          !selectedFiles.some(
            ({ originalFile }) =>
              newFile.name === originalFile.name && newFile.lastModified === originalFile.lastModified
          )
      );
      const newFileWithProperties = newFiles.map(newFile => {
        return {
          originalFile: newFile,
          isCertifyCopy: true,
          id: uuid()
        };
      });
      if (!multiple && (!isEmpty(existedFiles) || !isEmpty(selectedFiles)) && !isEmpty(newFileWithProperties)) {
        ModalBoxes.confirm({
          content: 'Previously loaded file will be deleted',
          confirmButton: 'Yes',
          cancelButton: 'No'
        })
          .then(() => {
            if (isFunction(deleteFileForSingleFileUpload) && !isEmpty(existedFiles)) {
              deleteFileForSingleFileUpload(
                templateData.uniqueIdentifier,
                existedFiles[0].sitePatientIdentifier,
                existedFiles[0].uniqueIdentifier
              );
            }
            setTimeout(function() {
              updateFileList(acceptedFiles, newFileWithProperties);
            });
          })
          .catch(() => {});
      } else {
        updateFileList(acceptedFiles, newFileWithProperties);
      }
    }
  });

  const removeSelectedFile = file => {
    setSelectedFiles(selectedFiles.filter(selectedFile => selectedFile.originalFile !== file));
    onCloseSelectedFile && onCloseSelectedFile(templateData, file.name, isEmpty(selectedFiles));
  };

  const onHandleCertifyToggleChange = (e, selectedFile) => {
    selectedFile.isCertifyCopy = e.target.checked;
    onChangeFileHandler && onChangeFileHandler(selectedFiles, templateData);
  };

  const onCertifiedCopyConfirmCheck = (e, selectedFile) => {
    selectedFile.isCertifyCopyConfirmed = e.target.checked;
    onChangeFileHandler && onChangeFileHandler(selectedFiles, templateData);
  };

  return (
    <div className="multiple-upload-container">
      <div className="drag-zone-outer" {...getRootProps()}>
        <div className="drag-zone-inner">
          <input {...getInputProps()} name={multiple ? 'file' : 'singleFile'} />
          <i className="material-icons rotate-180">arrow_circle_up</i>
          {isDragActive ? (
            <span>Drop the files here</span>
          ) : (
            <span>
              <b>
                <u>{multiple ? 'Choose files' : 'Choose a file'}</u>
              </b>{' '}
              {multiple ? 'or drag them here' : 'or drag it here'}
            </span>
          )}
        </div>
      </div>
      <ul className="list-group mt-2">
        {selectedFiles.length > 0 &&
          selectedFiles.map((selectedFile, index) => (
            <div className="mft-list-group-item-wrap" key={index}>
              <li className="list-group-item mt-2" key={index}>
                <span title={getFileTitle(selectedFile)}>
                  <Icon className="file-status-icon" suit="material-outline" style={{ color: scGray15 }}>
                    insert_drive_file
                  </Icon>
                </span>
                <span className="mft-file-upload-name" title={getFileTitle(selectedFile)}>
                  {selectedFile.originalFile.name}
                </span>
                {isFilePreviewAvailable && (
                  <Icon
                    className="view-icon"
                    onClick={() =>
                      fileViewer.openFileByPromise(
                        new Promise(resolve => {
                          resolve({
                            data: selectedFile.originalFile,
                            headers: {
                              'content-disposition': `attachment; filename=${selectedFile.originalFile.name}`
                            },
                            id: selectedFile.id
                          });
                        })
                      )
                    }
                  >
                    visibility
                  </Icon>
                )}
                <Icon
                  className="close-icon"
                  onClick={e => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    removeSelectedFile(selectedFile.originalFile);
                  }}
                >
                  close
                </Icon>
              </li>

              {askForCertificateCopy && (
                <div className="mft-file-upload-toggle-block">
                  <Toggle
                    className="certify-copy"
                    name="certifyCopy"
                    checked={selectedFile.isCertifyCopy}
                    onChange={e => onHandleCertifyToggleChange(e, selectedFile)}
                  />
                  <span className="mft-certify-copy-label">Certify Copy</span>
                  <Checkbox
                    key={selectedFile?.id}
                    label="Yes, I reviewed this file and confirmed it is an exact copy of the original."
                    checked={selectedFile.isCertifyCopyConfirmed}
                    disabled={!selectedFile.isCertifyCopy}
                    onChange={e => onCertifiedCopyConfirmCheck(e, selectedFile)}
                    className={cx('confirm-certify-copy-checkbox', {
                      'text-color-red':
                        selectedFile.isCertifyCopy &&
                        selectedFile.validationTriggered &&
                        !selectedFile.isCertifyCopyConfirmed
                    })}
                  />
                </div>
              )}
            </div>
          ))}
        {existedFiles.length > 0 &&
          existedFiles.map(existingFile => {
            return (
              <li className="list-group-item mt-2" key={existingFile.uniqueIdentifier}>
                <span title={getFileTitle(existingFile)}>{resolveFileIcon(existingFile)}</span>
                {isFilePreviewAvailable && (
                  <Icon className="view-icon" onClick={() => openFile(fileViewer, existingFile)}>
                    visibility
                  </Icon>
                )}
                <span className="mft-file-upload-name" title={getFileTitle(existingFile)}>
                  {existingFile.fileName}
                </span>
                {isAbleToEdit && (
                  <Icon
                    className="close-icon"
                    onClick={e => {
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                      return deleteFile(
                        templateData.uniqueIdentifier,
                        existingFile.sitePatientIdentifier,
                        existingFile.uniqueIdentifier
                      );
                    }}
                  >
                    close
                  </Icon>
                )}
              </li>
            );
          })}
      </ul>
    </div>
  );
}

MultipleFileTemplate.defaultProps = {
  multiple: true
};
/** @deprecated **/
export default withFileViewerContext(MultipleFileTemplate);
