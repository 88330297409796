import startCase from 'lodash/startCase';

import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';

export function showConfirmDialog(area) {
  return ModalBoxes.confirm({
    content: `Are you sure you want to discard the changes made to ${area}?`,
    title: `Discard ${startCase(area)} Changes`,
    confirmButton: 'Yes',
    cancelButton: 'No'
  });
}

export function convertToFirstDay(dateString) {
  let result;
  if (dateString.includes('UN/UNK/')) {
    result = dateString.replace('UN/UNK/', '01/Jan/');
    return result;
  }
  if (dateString.includes('UN/')) {
    result = dateString.replace('UN/', '01/');
    return result;
  }
  return dateString;
}
