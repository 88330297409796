import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash/lang';

import { getHomePageDashboardsConfig } from '../../../actions/homePageDashboardsConfigActions';
import { VIEW_AND_EDIT_HOME_PAGE_DASHBOARDS_WIDGET_CONFIG } from '../../../constants/userOperations';
import { useCurrentUser } from '../../root/Container/CurrentUserContainer';

import Widgets from './Widgets/Widgets';
import HomePageDashboardsContext, { HomePageContext } from './HomePageContext';

import './HomePageDashboards.scss';

function HomePageDashboardsEl() {
  const Context = useContext(HomePageContext);
  const { ssus, studies } = Context;
  const user = useCurrentUser();
  const dashboardsAllowedForUser = user.operationsMapping[user.activeRole].includes(
    VIEW_AND_EDIT_HOME_PAGE_DASHBOARDS_WIDGET_CONFIG
  );
  const dispatch = useDispatch();
  const homePageDashboardsConfig = useSelector(state => state.homePageDashboardsConfig);

  useEffect(
    function() {
      dashboardsAllowedForUser && dispatch(getHomePageDashboardsConfig());
    },
    [user, dashboardsAllowedForUser, dispatch]
  );

  return (
    <>
      {homePageDashboardsConfig && !isEmpty(ssus) && !isEmpty(studies) && (
        <div className="dashboard">
          <div className="dashboard-header p-3 px-4">
            <h4 className="m-0">Home Page</h4>
          </div>
          <div className="grid-layout-container p-3">
            {!isEmpty(ssus) && !isEmpty(studies) && (
              <Widgets widgets={homePageDashboardsConfig.widgets.filter(e => e.active)} ssus={ssus} studies={studies} />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default function HomePageDashboards() {
  return (
    <HomePageDashboardsContext>
      <HomePageDashboardsEl />
    </HomePageDashboardsContext>
  );
}
