import React, { useMemo, useState } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { reduce, sortBy } from 'lodash/collection';
import { isEqual } from 'lodash/lang';

import { StudyApi } from '../../../../../../api';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import { STUDY_TYPES_UPDATED } from '../../../../../../constants/notificationMessages';
import { STDT_TRAVEL, STTG_CTMS } from '../../../../../../constants/study/studyTypes';
import { onRequestError } from '../../../../../../services/handlers';

import SelectGroup from './SelectGroup/SelectGroup';

const typeGroups = ['E_SOURCE', 'CTMS', 'INTERNAL_PROJECT'];

export default function ManageStudyTypesModal({
  modalBox,
  studyId,
  initialType,
  initialTypeIds,
  typeParents,
  allTypes,
  onStudyTypeAndParentChange
}) {
  const [type, setType] = useState(initialType);
  const [selectedTypeIds, setSelectedTypeIds] = useState(initialTypeIds);

  const typesMap = useMemo(
    function() {
      return reduce(
        typeParents,
        function(accumulator, { typeParent, types }) {
          accumulator[typeParent] = types.map(({ id }) => id);
          return accumulator;
        },
        {}
      );
    },
    [typeParents]
  );

  const isDisabledButton = useMemo(
    function() {
      return isEqual(initialTypeIds, selectedTypeIds) && isEqual(initialType, type);
    },
    [initialTypeIds, selectedTypeIds, type, initialType]
  );

  return (
    <>
      <ModalBoxes.Header>Assign Study Type</ModalBoxes.Header>
      <ModalBoxes.Body>
        <FormControl sx={{ display: 'block', mx: -4, pb: 2 }}>
          <RadioGroup
            row
            value={type}
            onChange={function({ target }) {
              setType(target.value);
            }}
          >
            <FormControlLabel value="E_SOURCE" control={<Radio />} label="eSource" sx={{ m: 0 }} />
            <FormControlLabel value="CTMS" control={<Radio />} label="CTMS" sx={{ m: 0 }} />
          </RadioGroup>
        </FormControl>
        {sortBy(typeParents, function({ typeParent }) {
          return typeGroups.indexOf(typeParent);
        }).map(function(group) {
          const { typeParent } = group;
          if (type === 'CTMS' && typeParent !== 'CTMS') {
            return null;
          }
          return (
            <SelectGroup
              key={typeParent}
              group={group}
              selectedTypeIds={selectedTypeIds}
              setSelectedTypeIds={setSelectedTypeIds}
              studyType={type}
            />
          );
        })}
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority={'medium'} onClick={() => modalBox.close()}>
            Cancel
          </Button>
          <Button onClick={updateTypes} disabled={isDisabledButton}>
            Save
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );

  function updateTypes() {
    const request = { type, studyTypeIds: prepareRequest(type, selectedTypeIds, typesMap, allTypes) };
    if (validateRequest(request, typesMap)) {
      StudyApi.updateStudyTypes(studyId, request)
        .then(function({ data }) {
          onStudyTypeAndParentChange(data.type, data.typeParents);
          modalBox.close(STUDY_TYPES_UPDATED);
        })
        .catch(onRequestError);
    }
  }
}

ManageStudyTypesModal.className = 'study-type-add-modal';
ManageStudyTypesModal.size = 'w500';

function prepareRequest(type, selectedTypeIds, typesMap, allTypes) {
  if (type === STTG_CTMS) {
    return selectedTypeIds.filter(function(selectedId) {
      return typesMap[STTG_CTMS].includes(selectedId);
    });
  } else {
    const travelOption = allTypes?.find(e => e.name === STDT_TRAVEL);
    return selectedTypeIds?.filter(e => e !== travelOption.id);
  }
}

function validateRequest({ type, studyTypeIds }, typesMap) {
  if (!type) {
    NotificationManager.warning('Study Type is required.');
    return false;
  }
  if (type === 'E_SOURCE') {
    if (
      studyTypeIds.every(function(selectedId) {
        return !typesMap['E_SOURCE'].includes(selectedId);
      })
    ) {
      NotificationManager.warning('eSource Identifier is required for eSource Study Type.');
      return false;
    }
  }
  return true;
}
