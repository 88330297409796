import { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash/lang';

import { TaskApi } from '../../../../../../../api';
import { useActiveTask } from '../../../../../../../store/activeTask';
import { useCurrentRoute } from '../../../../../router';

const logRelatedTasksPages = ['Patients.Patient Info.Encounter Details.Logs', 'Patients.Patient Info.Logs'];

export default function useRelatedTasks() {
  const currentRoute = useCurrentRoute();
  const activeTask = useActiveTask();
  const [relatedTasks, setRelatedTasks] = useState(null);

  const getPatientRelatedTasks = useCallback(
    function() {
      if (!currentRoute?.params?.ssuPatientId) return;
      TaskApi.getTasksByPatientIdAndSSU(currentRoute.params.ssuPatientId, activeTask.ssuId).then(({ data }) => {
        if (!isEmpty(data)) {
          setRelatedTasks(data);
        }
      });
    },
    [currentRoute.params.ssuPatientId, activeTask.ssuId]
  );

  const getRelatedTasksForEncounter = useCallback(
    function() {
      TaskApi.getTasksByPatientIdAndEncounter(
        currentRoute.params.ssuPatientId,
        currentRoute.params.patientEncounterId
      ).then(({ data }) => {
        if (!isEmpty(data)) {
          setRelatedTasks(data);
        }
      });
    },
    [currentRoute.params.patientEncounterId, currentRoute.params.ssuPatientId]
  );

  const getAllRelatedTasksForLog = useCallback(
    function() {
      TaskApi.getTasksByPatientIdForLogs(currentRoute.params.ssuPatientId, currentRoute.params.patientItemGroupId).then(
        ({ data }) => {
          if (!isEmpty(data)) {
            setRelatedTasks(data);
          }
        }
      );
    },
    [currentRoute.params.patientItemGroupId, currentRoute.params.ssuPatientId]
  );

  useEffect(() => {
    if (logRelatedTasksPages.some(i => i === currentRoute.key)) {
      getAllRelatedTasksForLog();
    } else if (currentRoute.params.patientEncounterId) {
      getRelatedTasksForEncounter();
    } else {
      getPatientRelatedTasks();
    }
    return function() {
      setRelatedTasks(null);
    };
  }, [
    getAllRelatedTasksForLog,
    getPatientRelatedTasks,
    getRelatedTasksForEncounter,
    currentRoute.params.patientEncounterId,
    currentRoute.params.patientItemGroupId,
    currentRoute.key
  ]);

  return relatedTasks;
}
