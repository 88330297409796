import { has } from 'lodash/object';
import moment from 'moment';

export function getPatientName(patient) {
  return has(patient, 'name') ? patient.name : '---';
}

export function getPatientFullName(patientInfo) {
  let fullName = '';

  const firstName = patientInfo?.firstName || patientInfo?.patientFirstName,
    middleName = patientInfo?.middleName || patientInfo?.patientMiddleName,
    lastName = patientInfo?.lastName || patientInfo?.patientLastName;

  if (firstName) {
    fullName += firstName;
  }
  if (middleName) {
    fullName += ' ' + middleName;
  }
  if (lastName) {
    fullName += ' ' + lastName;
  }
  return fullName;
}

export function getPatientPreferredName(patientInfo) {
  const preferredName = patientInfo?.preferredName || patientInfo?.patientPreferredName;
  if (preferredName) {
    return ' (' + preferredName + ')';
  }
  return '';
}

export function getPatientAgeFromDob(patientInfo) {
  const age = patientInfo && patientInfo.dob ? moment().diff(patientInfo.dob, 'years') : '';
  return age && age > 0 ? `${age} ${age > 1 ? 'years' : 'year'}` : '0 year';
}

export function getPatientAge(patient) {
  if (has(patient, 'age')) {
    const { age } = patient;
    return `${age} ${age === 1 ? 'year' : 'years'}`;
  }
}

export function getPatientDob(patient) {
  return has(patient, 'dob') && patient.dob;
}
