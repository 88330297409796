import React, { useState } from 'react';

import TextArea from '../../../../../common/data-entry/TextArea';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { STATUS_CHANGED } from '../../../../../constants/notificationMessages';

import './LedgerTableStatusChangeModal.scss';

export default function LedgerTableStatusChangeModal({ modalBox, onConfirm, eventId, number, successCallback }) {
  const [reason, setReason] = useState('');

  return (
    <>
      <ModalBoxes.Header>Do you want to save this Ledger status to Paid?</ModalBoxes.Header>
      <ModalBoxes.Body>
        <p>Enter the reason for changing the status of this Ledger to Paid.</p>
        <div className="row">
          <div className="col-md-12">
            <TextArea rows="1" label="Reason" value={reason} onChange={onReasonChange} required />
          </div>
        </div>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            Cancel
          </Button>
          <Button
            onClick={() =>
              onConfirm({ reason: reason }, eventId)
                .then(
                  () => {
                    NotificationManager.success(STATUS_CHANGED);
                    successCallback();
                  },
                  err => {
                    NotificationManager.error(err.response.data.message);
                  }
                )
                .finally(function() {
                  modalBox.close();
                })
            }
            disabled={!reason.trim()}
          >
            Change
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );

  function onReasonChange({ target }) {
    setReason(target.value);
  }
}

LedgerTableStatusChangeModal.className = 'ledger-status-change-modal';
LedgerTableStatusChangeModal.size = 'w650';
