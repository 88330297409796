import { TaskApi } from '../../../../../../../api';
import { prepareAutoPopulatedData } from '../taskDataProxy';

export function loadPopulatedData(patientSpecificReqPayload) {
  return new Promise(function(resolve, reject) {
    if (patientSpecificReqPayload) {
      TaskApi.getAutoPopulationData(patientSpecificReqPayload)
        .then(({ data }) => {
          const responseData = prepareAutoPopulatedData(data);
          resolve(responseData);
        })
        .catch(reject);
    } else {
      resolve(prepareAutoPopulatedData({}));
    }
  });
}
