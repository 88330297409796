import React from 'react';

import ContactAttemptDaysRemainingIcon from './ContactAttemptDaysRemainingIcon';

import './ContactAttemptDaysRemainingCell.scss';

const ContactAttemptDaysRemainingCell = ({ row: { contactAttemptDaysRemaining } }) => (
  <div className="contact-attempt-days-remaining">
    <div>{contactAttemptDaysRemaining !== null ? `${contactAttemptDaysRemaining} Day(s)` : 'N/A'}</div>
    <ContactAttemptDaysRemainingIcon days={contactAttemptDaysRemaining} />
  </div>
);

export default ContactAttemptDaysRemainingCell;
