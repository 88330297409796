import React, { useContext } from 'react';

import Button from '../../../../common/general/Button';
import { PageInfoHeader } from '../../../PageInfoHeader/PageInfoHeader';

import { tableSectionStyle } from './constants';
import SchedulingWorklistChips from './SchedulingWorklistChips';
import { SchedulingWorklistFilters } from './SchedulingWorklistFilters';
import SchedulingWorklistFiltersContext, { SchedulingWorklistContext } from './SchedulingWorklistFiltersContext';
import { SchedulingWorklistTable } from './SchedulingWorklistTable';

const SchedulingWorklist = () => {
  const Context = useContext(SchedulingWorklistContext);
  const { exportFiles } = Context;
  return (
    <div className="scheduling-work-list">
      <PageInfoHeader
        pageInfo={
          <Button className="pswl-export" size="h28" priority="high" onClick={() => exportFiles()}>
            Export
          </Button>
        }
      >
        <SchedulingWorklistFilters />
        <SchedulingWorklistChips />
      </PageInfoHeader>
      <div>
        <section style={tableSectionStyle}>
          <SchedulingWorklistTable />
        </section>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function() {
  return (
    <SchedulingWorklistFiltersContext>
      <SchedulingWorklist />
    </SchedulingWorklistFiltersContext>
  );
}
