import React, { useCallback, useEffect, useState } from 'react';
import { cloneDeep, isEmpty } from 'lodash/lang';

import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import { INVALID_FORM } from '../../../../../../constants/notificationMessages';
import { PS_NOT_ASKED, PS_UNAVAILABLE } from '../TableItemGroup/constants';

import { newDataForSave } from './MissingItemDialogService';
import { UnfilledRowReason } from './UnfilledRowReason';

import './MissingItemDialog.scss';

export function MissingItemDialog(props) {
  let { modalBox, actualFormData, emptyItemGroupList, forSaveForm, onSave } = props;
  const [missingItems, setMissingItems] = useState(emptyItemGroupList);
  const [finaleDataForSave, setFinaleDataForSave] = useState([]);
  const tableview = actualFormData.columnList !== null && actualFormData.columnList.length > 0 ? 1 : 0;

  useEffect(() => {
    if (!isEmpty(finaleDataForSave)) {
      onSave(finaleDataForSave);
      modalBox.close();
    }
  }, [onSave, finaleDataForSave, modalBox]);

  const updateReasonStatusForAllItemsHandler = type => {
    setMissingItems(missingItems => {
      return missingItems?.map(({ performedStatus, ...rest }) => {
        return {
          performedStatus: type,
          ...rest
        };
      });
    });
  };

  const updateItemReasonStatusHandler = (type, index) => {
    setMissingItems(missingItems => {
      const missingItemsCopy = cloneDeep(missingItems);
      missingItemsCopy[index].performedStatus = type;
      return missingItemsCopy;
    });
  };

  const updateReasonStatusForAllItems = useCallback(updateReasonStatusForAllItemsHandler, []);
  const updateItemReasonStatus = useCallback(updateItemReasonStatusHandler, []);

  const updateReasonForAllItemsHandler = comment => {
    setMissingItems(missingItems => {
      return missingItems?.map(({ comments, ...rest }) => {
        return {
          comments: comment,
          ...rest
        };
      });
    });
  };

  const updateItemReasonHandler = (reason, index) => {
    setMissingItems(missingItems => {
      const missingItemsCopy = cloneDeep(missingItems);
      missingItemsCopy[index].comments = reason;
      return missingItemsCopy;
    });
  };

  const updateReasonForAllItems = useCallback(updateReasonForAllItemsHandler, []);
  const updateItemReason = useCallback(updateItemReasonHandler, []);

  return (
    <>
      <ModalBoxes.Body>
        {missingItems?.length > 1 && (
          <div className="eds-missing-item-apply-to-all">
            <UnfilledRowReason
              key={'unfilled-row-apply-to-all'}
              naIdx={'ALL'}
              updateReasonStatus={updateReasonStatusForAllItems}
              updateReasonComment={updateReasonForAllItems}
            />
          </div>
        )}

        {missingItems?.map((naQues, naIdx) => (
          <UnfilledRowReason
            key={`unfilled-row-${naIdx}`}
            naQues={naQues}
            naIdx={naIdx}
            updateReasonStatus={updateItemReasonStatus}
            updateReasonComment={updateItemReason}
          />
        ))}
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <div className="eds-missing-item-info">
          <span className="material-icons">info</span>
          <span className="eds-missing-item-warning-message">
            If empty fields will be entered later, click Save without entering a reason.
          </span>
        </div>
        <div className="eds-btn-group d-flex">
          <Button
            priority="medium"
            style={{ width: '132px' }}
            className="mr-2"
            onClick={() => {
              modalBox.close();
              props.onClose();
            }}
          >
            CANCEL
          </Button>
          <Button
            onClick={() => {
              onConfirm();
            }}
            style={{ width: '132px' }}
            priority="high"
            disabled={!validateForm()}
          >
            SAVE
          </Button>
        </div>
      </ModalBoxes.Footer>
    </>
  );

  function validateForm() {
    return missingItems.every(formVal => {
      const { comments, performedStatus } = formVal;
      const rightPerformedStatusCondition = performedStatus === PS_UNAVAILABLE || performedStatus === PS_NOT_ASKED;
      const reasonsAreFilled = rightPerformedStatusCondition && !isEmpty(comments) && !isEmpty(comments.trim());
      const reasonsAreEmpty = !rightPerformedStatusCondition && isEmpty(comments?.trim());
      return reasonsAreFilled || reasonsAreEmpty;
    });
  }

  function onConfirm() {
    if (validateForm()) {
      setFinaleDataForSave(newDataForSave(forSaveForm, missingItems, tableview));
    } else {
      NotificationManager.error(INVALID_FORM);
    }
  }
}

MissingItemDialog.className = 'eds-missing-item-dialog';
MissingItemDialog.size = 'w950';
