import { any, array, arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types';

const propTypes = {
  previousEpochEncounterList: array,
  onSaveEncounter: func,
  enInfo: shape({
    encounterIdentifier: string,
    encounterName: string,
    encounterDesc: string,
    fromVisit: oneOfType([string, number]),
    toVisit: oneOfType([string, number]),
    fromType: string,
    toType: string,
    midVisit: oneOfType([string, number]),
    midType: string,
    type: any,
    displayName: string,
    enNodeKey: string,
    elements: array,
    enDisplayName: any,
    parentEnNodeKeys: arrayOf(string),
    isDateRange: bool,
    nonProtocol: bool,
    take: oneOfType([string, number]),
    takeType: string,
    give: oneOfType([string, number]),
    giveType: string
  })
};

export default propTypes;
