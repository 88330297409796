import moment from 'moment';

import { PS_ANSWERED } from '../TableItemGroup/constants';

const dateInputFormat = 'YYYY-MM-DD';
const dateOutputFormat = 'DD/MMM/YYYY';

const getIcfMandatoryFields = labelList => {
  return labelList.filter(field => field?.mandatoryField);
};

const getIcfSponsorVersionDateField = labelList => {
  return labelList.find(item => item?.mandatoryField && item.sequence === 1 && item.name === 'ICF IRB Approval Date');
};

const getIcfVersionNumberField = labelList => {
  return labelList.find(item => item?.mandatoryField && item.sequence === 2 && item.name === 'ICF Version Number');
};

const getDateIcfSignedByPatient = labelList => {
  return labelList.find(item => item?.mandatoryField && item.sequence === 3 && item.name === 'Date ICF Signed');
};
const getTimeIcfSignedByPatient = labelList => {
  return labelList.find(item => item?.mandatoryField && item.sequence === 4 && item.name === 'Time ICF Signed');
};
const getCompletedIcfFile = labelList => {
  return labelList.find(item => item?.mandatoryField && item.sequence === 5 && item.name === 'Completed ICF');
};

const getAnswerByField = (answers, field) => {
  return answers.find(answer => answer.elementEncounterIdentifier === field.uniqueIdentifier);
};

export const showIcfSpecificFields = (formData, item, informedConsentForm) => {
  if (formData && formData.domainCode === 'ICF' && formData?.labelList && informedConsentForm?.id) {
    const icfSponsorVersionDateField = getIcfSponsorVersionDateField(formData.labelList);
    const icfVersionNumberField = getIcfVersionNumberField(formData.labelList);
    return item !== icfSponsorVersionDateField && item !== icfVersionNumberField;
  }
  return true;
};

export const setIcfMandatoryFields = (labelList, jsonForSave, informedConsentForm) => {
  const icfSponsorVersionDateField = getIcfSponsorVersionDateField(labelList);
  const icfVersionNumberField = getIcfVersionNumberField(labelList);

  const icfSponsorVersionDateAnswer = getAnswerByField(jsonForSave, icfSponsorVersionDateField);
  const icfVersionNumberAnswer = getAnswerByField(jsonForSave, icfVersionNumberField);

  icfSponsorVersionDateField.inputValue = moment(informedConsentForm.publishDate, dateInputFormat).format(
    dateOutputFormat
  );
  icfVersionNumberField.inputValue = informedConsentForm.version;
  icfSponsorVersionDateAnswer.attributeValue = moment(informedConsentForm.publishDate, dateInputFormat).format(
    dateOutputFormat
  );
  icfSponsorVersionDateAnswer.performedStatus = PS_ANSWERED;
  icfVersionNumberAnswer.attributeValue = informedConsentForm.version;
  icfVersionNumberAnswer.performedStatus = PS_ANSWERED;
};

export const resetIcfMandatoryFields = (labelList, jsonForSave, question) => {
  if (question.question === 'Completed ICF' && question.sequence === 5) {
    const icfSponsorVersionDateField = getIcfSponsorVersionDateField(labelList);
    const icfVersionNumberField = getIcfVersionNumberField(labelList);

    const icfSponsorVersionDateAnswer = getAnswerByField(jsonForSave, icfSponsorVersionDateField);
    const icfVersionNumberAnswer = getAnswerByField(jsonForSave, icfVersionNumberField);

    icfSponsorVersionDateField.inputValue = '';
    icfVersionNumberField.inputValue = '';
    icfSponsorVersionDateAnswer.attributeValue = '';
    icfVersionNumberAnswer.attributeValue = '';
  }
};

export const populateIcfAnswers = (data, labelList, informedConsentForm) => {
  const icfMandatoryFields = getIcfMandatoryFields(labelList);
  const fileList = getCompletedIcfFile(labelList)?.fileList;
  data.icfAnswers = {
    informedConsentFormId: informedConsentForm?.id,
    icfSponsorVersionDate: getIcfSponsorVersionDateField(labelList)?.inputValue,
    icfVersionNumber: getIcfVersionNumberField(labelList)?.inputValue,
    dateIcfSignedByPatient: getDateIcfSignedByPatient(labelList)?.inputValue,
    timeIcfSignedByPatient: getTimeIcfSignedByPatient(labelList)?.inputValue,
    completedIcfFile: fileList?.length > 0 ? fileList[0]?.name || fileList[0]?.fileName : '',
    isCompleted: fileList?.length > 0,
    completedIcfFileAttributeIdentifier: icfMandatoryFields.find(el => el.sequence === 5)?.uniqueIdentifier
  };
};
