import React from 'react';

import RadioButtons from '../../../common/buttons/RadioButtons/RadioButtons';
import Checkbox from '../../../common/data-entry/InputSelectors/Checkbox';
import useAppInfo from '../../../common/hooks/useAppInfo';
import NotificationManager from '../../../common/notifications/NotificationManager';
import { MANAGE_NON_PATIENT_APPOINTMENTS, MANAGE_PATIENT_APPOINTMENTS } from '../../../constants/userOperations';
import { userHasAccessTo } from '../../../services/auth';

import { TimeZoneDetails } from './AppointmentView/TimeZoneDetails';
import { NonPatientAppointmentEdit } from './NonPatientAppointmentEdit/NonPatientAppointmentEdit';
import { PatientAppointmentEdit } from './PatientAppointmentEdit/PatientAppointmentEdit';
import { PatientEncounterAppointmentEdit } from './PatientEncounterAppointmentEdit/PatientEncounterAppointmentEdit';
import { PatientSituationalAppointmentEdit } from './PatientSituationalAppointmentEdit/PatientSituationalAppointmentEdit';
import {
  NON_PATIENT_EVENT,
  PATIENT_ENCOUNTER_EVENT,
  PATIENT_SITUATIONAL_ENCOUNTER_EVENT,
  PATIENT_UNEXPECTED_ENCOUNTER_EVENT
} from './CalendarEventType';
import { ADD_APPOINTMENT, EDIT_APPOINTMENT } from './CalendarPage';

import './AppointmentEdit.scss';

const appointmentEditLabelsMap = {
  [PATIENT_UNEXPECTED_ENCOUNTER_EVENT]: 'Unexpected',
  [PATIENT_ENCOUNTER_EVENT]: 'Patient',
  [NON_PATIENT_EVENT]: 'Non Patient',
  NON_PATIENT_EVENT_WITH_DASH: 'Non-Patient',
  [PATIENT_SITUATIONAL_ENCOUNTER_EVENT]: 'Situational'
};

export default function AppointmentEdit(props) {
  const appInfo = useAppInfo();
  const { mode, initialAppointment, appointmentChanged } = props;
  const type = initialAppointment.type;
  const changeAppointmentType = type => {
    appointmentChanged({ ...initialAppointment, type: type.id, subject: '' });
  };
  const getAppointmentSwitchType = type => {
    return type === PATIENT_UNEXPECTED_ENCOUNTER_EVENT || type === PATIENT_SITUATIONAL_ENCOUNTER_EVENT
      ? PATIENT_ENCOUNTER_EVENT
      : type;
  };

  const getPatientIfHasAnyActiveStudy = () => {
    if (!initialAppointment.patient?.hasActiveStudies) {
      return undefined;
    } else {
      return initialAppointment.patient;
    }
  };

  const onUnexpectedCheckBoxClick = () => {
    const patientEventType =
      initialAppointment.type === PATIENT_UNEXPECTED_ENCOUNTER_EVENT
        ? PATIENT_ENCOUNTER_EVENT
        : PATIENT_UNEXPECTED_ENCOUNTER_EVENT;
    appointmentChanged({
      ...initialAppointment,
      type: patientEventType,
      subject: '',
      patient: getPatientIfHasAnyActiveStudy()
    });
  };

  const situationalEncounterErrorHandler = studyName => {
    if (type === PATIENT_SITUATIONAL_ENCOUNTER_EVENT) {
      setTimeout(function() {
        appointmentChanged(function(initialAppointment) {
          return {
            ...initialAppointment,
            type: PATIENT_ENCOUNTER_EVENT,
            subject: '',
            patient: getPatientIfHasAnyActiveStudy(),
            encounter: null
          };
        });

        NotificationManager.error(`For study ${studyName}, no situational encounters are configured`);
      }, 250);
    }
  };

  const onSituationalCheckBoxClick = () => {
    const patientEventType =
      initialAppointment.type === PATIENT_SITUATIONAL_ENCOUNTER_EVENT
        ? PATIENT_ENCOUNTER_EVENT
        : PATIENT_SITUATIONAL_ENCOUNTER_EVENT;
    appointmentChanged({
      ...initialAppointment,
      type: patientEventType,
      subject: '',
      patient: getPatientIfHasAnyActiveStudy(),
      encounter: null
    });
  };

  const renderRadioButtons = () => {
    const patientAccess = [{ id: PATIENT_ENCOUNTER_EVENT, name: appointmentEditLabelsMap.PATIENT_ENCOUNTER_EVENT }];
    const nonPatientAccess = [{ id: NON_PATIENT_EVENT, name: appointmentEditLabelsMap.NON_PATIENT_EVENT_WITH_DASH }];
    const fullAccess = [...patientAccess, ...nonPatientAccess];
    const noAccess = [];

    if (userHasAccessTo(MANAGE_PATIENT_APPOINTMENTS) && userHasAccessTo(MANAGE_NON_PATIENT_APPOINTMENTS)) {
      return fullAccess;
    } else if (userHasAccessTo(MANAGE_PATIENT_APPOINTMENTS)) {
      return patientAccess;
    } else if (userHasAccessTo(MANAGE_NON_PATIENT_APPOINTMENTS)) {
      return nonPatientAccess;
    } else {
      return noAccess;
    }
  };

  return (
    <div className="appointment-component">
      {mode === EDIT_APPOINTMENT && (
        <div className="appointment-edit-header">{appointmentEditLabelsMap[type]} Appointment</div>
      )}
      {mode === ADD_APPOINTMENT && <div className="appointment-edit-header">Appointment</div>}

      <div className="appointment-edit-content">
        <TimeZoneDetails
          appointment={props.initialAppointment}
          selectedTimeZone={props.selectedTimeZone}
          timeZones={props.timeZones}
          changeSelectedTimeZone={props.changeSelectedTimeZone}
          updateSelectedEventsDueSelectedTimeZone={props.updateSelectedEventsDueSelectedTimeZone}
        />
        {mode === ADD_APPOINTMENT && (
          <div className="mb-3">
            <RadioButtons
              options={renderRadioButtons()}
              initialValue={{ id: getAppointmentSwitchType(type) }}
              onChange={changeAppointmentType}
            />
            {(type === PATIENT_ENCOUNTER_EVENT ||
              type === PATIENT_SITUATIONAL_ENCOUNTER_EVENT ||
              type === PATIENT_UNEXPECTED_ENCOUNTER_EVENT) && (
              <div className="pt-2">
                <Checkbox
                  label="Unexpected"
                  onChange={() => onUnexpectedCheckBoxClick()}
                  checked={type === PATIENT_UNEXPECTED_ENCOUNTER_EVENT}
                />
                {appInfo?.features?.situationalEncountersEnabled && (
                  <Checkbox
                    label="Situational"
                    onChange={() => onSituationalCheckBoxClick()}
                    checked={type === PATIENT_SITUATIONAL_ENCOUNTER_EVENT}
                  />
                )}
              </div>
            )}
          </div>
        )}
        {type === PATIENT_ENCOUNTER_EVENT && <PatientEncounterAppointmentEdit {...props} />}
        {type === PATIENT_UNEXPECTED_ENCOUNTER_EVENT && <PatientAppointmentEdit {...props} />}
        {type === NON_PATIENT_EVENT && <NonPatientAppointmentEdit {...props} />}
        {type === PATIENT_SITUATIONAL_ENCOUNTER_EVENT && (
          <PatientSituationalAppointmentEdit {...props} errorHandleCallback={situationalEncounterErrorHandler} />
        )}
      </div>
    </div>
  );
}
