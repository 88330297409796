import React from 'react';
import ReactTable from 'react-table';

export default function LoadPatientsTable({ issues }) {
  const transformIssues = issuesMap => {
    const issuesArray = [];
    issuesMap.forEach((issueList, row) => {
      issueList.forEach(issue => {
        issuesArray.push({ row, issues: issue });
      });
    });
    return issuesArray;
  };

  const transformedIssues = transformIssues(issues);

  const columns = [
    {
      id: 'row',
      Header: 'Row in File',
      accessor: 'row',
      width: 100,
      Cell: row => <div id={`row-${row.original.row}-number`}>{row.value}</div>
    },
    {
      id: 'issues',
      Header: 'Issues',
      accessor: 'issues',
      sortable: false,
      Cell: row => <div id={`row-${row.original.row}-issue`}>{row.value}</div>
    }
  ];

  return (
    <section id="invalid-rows-table">
      <div className="row border p-3 m-0 my-2">
        <div className="col p-0 pt-2">
          <h5 className=" c-p">Invalid Rows</h5>
        </div>
        <div className="col-12 border px-0">
          <ReactTable
            data={transformedIssues || []}
            columns={columns}
            minRows={1}
            showPagination
            nextText=">>"
            previousText="<<"
            noDataText="No Issues in Data Found"
            pageSizeOptions={[25, 50, 100]}
            defaultPageSize={25}
            defaultSorted={[{ id: 'row' }]}
          />
        </div>
      </div>
    </section>
  );
}
