import { EXTERNAL_INTEGRATION } from '../../../constants/accessToPages';

import PlatformConfiguration from './PlatformConfiguration';

export const platformConfigurationRouterConfig = {
  name: 'Platform Configuration',
  path: '/platform-configuration',
  component: PlatformConfiguration,
  access: [EXTERNAL_INTEGRATION]
};
