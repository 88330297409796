import { craReviewRouterConfig } from './CraReview/routerConfig';
import { investigatorWorklistNewRouterConfig } from './InvestigatorWorklistNew/routerConfig';
import { patientsRouterConfig } from './Patients/routerConfig';
import { schedulingWorkListRouterConfig } from './ScheduleWorklist/routerConfig';
import { smWorklistNewRouterConfig } from './SmWorklistNew/routerConfig';
import { taskWorklistRouterConfig } from './TaskWorklist/routerConfig';

export const patientSourceRouterConfig = [
  patientsRouterConfig,
  craReviewRouterConfig,
  taskWorklistRouterConfig,
  schedulingWorkListRouterConfig,
  investigatorWorklistNewRouterConfig,
  smWorklistNewRouterConfig
];
