import { isEmpty } from 'lodash/lang';

export function isSigned(logAndLogCheckSnapshotReviewState) {
  return logAndLogCheckSnapshotReviewState?.reviewState?.reviewStatus !== 'NOT_SIGNED';
}

export function isNotPerformed(logAndLogCheckSnapshotReviewState) {
  return logAndLogCheckSnapshotReviewState?.logCheckSnapshot?.notPerformed;
}

export function isDisabled(logAndLogCheckSnapshotReviewState) {
  return (
    !isNotPerformed(logAndLogCheckSnapshotReviewState) &&
    isEmpty(logAndLogCheckSnapshotReviewState?.logCheckSnapshot?.logCheckAnswers) &&
    isEmpty(logAndLogCheckSnapshotReviewState?.rows)
  );
}
