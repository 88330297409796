import { useCallback, useEffect, useRef, useState } from 'react';
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode } from '@lexical/rich-text';
import { $getSelectionStyleValueForProperty, $patchStyleText } from '@lexical/selection';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { IconButton } from '@mui/material';
import cx from 'classnames';
import {
  $getSelection,
  $isRangeSelection,
  $setSelection,
  DEPRECATED_$isGridSelection,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND
} from 'lexical';

import { AlignLeft } from './CustomIcons/AlignLeft';
import { BackgroundColor } from './CustomIcons/BackgroundColor';
import { BoldIcon } from './CustomIcons/BoldIcon';
import { Font } from './CustomIcons/Font';
import { FontSize } from './CustomIcons/FontSize';
import { IconWithColorPicker } from './CustomIcons/IconWithColorPicker';
import { IconWithDropdown } from './CustomIcons/IconWithDropdown';
import { Indent } from './CustomIcons/Indent';
import { Italic } from './CustomIcons/Italic';
import { Outdent } from './CustomIcons/Outdent';
import { Strikethrough } from './CustomIcons/Strikethrough';
import { TextColorIcon } from './CustomIcons/TextColorIcon';
import { Underline } from './CustomIcons/Underline';

import './ToolbarPlagin.scss';

const LowPriority = 1;

export const iconButtonStyles = {
  padding: '2px',
  '&:focus': {
    outline: 'none'
  }
};

const dropdownIconStyles = {
  color: '#6E6E6E'
};

const fontSizeOptions = [
  { name: 'Small', value: '10px' },
  { name: 'Normal', value: '13px' },
  { name: 'Large', value: '18px' },
  { name: 'Huge', value: '32px' }
];

const FONT_FAMILY_OPTIONS = [
  ['roboto', 'Roboto'],
  ['sans-serif', 'Sans Serif'],
  ['serif', 'Serif'],
  ['monospace', 'Fixed Width'],
  ['Arial', 'Arial'],
  ['arial narrow', 'Narrow'],
  ['comic sans ms', 'Comic Sans MS'],
  ['garamond', 'Garamond'],
  ['tahoma', 'Tahoma'],
  ['trebuchet ms', 'Trebuchet MS'],
  ['verdana', 'Verdana']
];

export default function ToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [blockType, setBlockType] = useState('paragraph');
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [textDecoration, setTextDecoration] = useState('none');
  const [fontSize, setFontSize] = useState('13px');
  const [fontColor, setFontColor] = useState('#000');
  const [bgColor, setBgColor] = useState('#fff');
  const [fontFamily, setFontFamily] = useState('Roboto');

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element = anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          setBlockType(type);
        }
      }
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setFontSize($getSelectionStyleValueForProperty(selection, 'font-size', '13px'));
      setFontColor($getSelectionStyleValueForProperty(selection, 'color', '#000'));
      setBgColor($getSelectionStyleValueForProperty(selection, 'background-color', '#fff'));
      setTextDecoration($getSelectionStyleValueForProperty(selection, 'text-decoration', 'none'));
      setFontFamily($getSelectionStyleValueForProperty(selection, 'font-family', 'Roboto'));
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        _payload => {
          updateToolbar();
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  const formatBulletList = useCallback(() => {
    if (blockType !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
  }, [blockType, editor]);

  const formatNumberedList = useCallback(() => {
    if (blockType !== 'ol') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
  }, [blockType, editor]);

  const formatTextDecoration = valueForToggle => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
        const currentTextDecoration = $getSelectionStyleValueForProperty(selection, 'text-decoration');
        const resolveTextDecoration = valueForToggle => {
          if (currentTextDecoration.includes(valueForToggle)) {
            return currentTextDecoration.replace(valueForToggle, '').trim() || 'none';
          }
          if (!currentTextDecoration.includes(valueForToggle)) {
            return currentTextDecoration.includes('none')
              ? valueForToggle
              : `${currentTextDecoration} ${valueForToggle}`;
          }
        };
        $patchStyleText(selection, {
          'text-decoration': resolveTextDecoration(valueForToggle)
        });
        $setSelection(null);
      }
    });
  };

  const formatIndent = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
        const currentPadding = $getSelectionStyleValueForProperty(selection, 'padding-left');
        const addIndentLevel = currentPadding => {
          return `${+currentPadding.replace('px', '') + 30}px`;
        };
        $patchStyleText(selection, {
          display: 'block',
          'padding-left': addIndentLevel(currentPadding)
        });
      }
    });
  };

  const formatOutdent = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
        const currentPadding = $getSelectionStyleValueForProperty(selection, 'padding-left');
        if (+currentPadding.replace('px', '') >= 30) {
          const addIndentLevel = currentPadding => {
            return `${+currentPadding.replace('px', '') - 30}px`;
          };
          $patchStyleText(selection, {
            display: 'block',
            'padding-left': addIndentLevel(currentPadding)
          });
        }
      }
    });
  };

  const applyStyleText = useCallback(
    styles => {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
          $patchStyleText(selection, styles);
        }
      });
    },
    [editor]
  );
  const formatFontSize = useCallback(
    size => {
      applyStyleText({ 'font-size': size, 'line-height': size });
    },
    [applyStyleText]
  );

  const formatFontFamily = useCallback(
    family => {
      applyStyleText({ 'font-family': family });
    },
    [applyStyleText]
  );

  const onFontColorSelect = useCallback(
    value => {
      applyStyleText({ color: value });
    },
    [applyStyleText]
  );

  const onBgColorSelect = useCallback(
    value => {
      applyStyleText({ 'background-color': value });
    },
    [applyStyleText]
  );

  return (
    <div className="toolbar" ref={toolbarRef}>
      <IconButton sx={iconButtonStyles} data-testid={'bold-button'}>
        <BoldIcon
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
          }}
          active={isBold}
        />
      </IconButton>
      <IconButton sx={iconButtonStyles} data-testid={'italic-button'}>
        <Italic
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
          }}
          active={isItalic}
        />
      </IconButton>
      <IconButton sx={iconButtonStyles} data-testid={'underline-button'}>
        <Underline
          onClick={() => {
            formatTextDecoration('underline');
          }}
          active={textDecoration.includes('underline')}
        />
      </IconButton>
      <IconButton sx={iconButtonStyles} data-testid={'line-through-button'}>
        <Strikethrough
          onClick={() => {
            formatTextDecoration('line-through');
          }}
          active={textDecoration.includes('line-through')}
        />
      </IconButton>
      <IconWithDropdown Icon={Font} data-testid={'font-button'}>
        {FONT_FAMILY_OPTIONS.map(([option, text]) => (
          <div
            onClick={() => formatFontFamily(option)}
            className={cx('toolbar-dropdown-option', { selected: fontFamily === option })}
            style={{ fontFamily: option }}
          >
            {text}
          </div>
        ))}
      </IconWithDropdown>
      <IconWithDropdown Icon={FontSize} data-testid={'font-size-button'}>
        {fontSizeOptions.map(size => (
          <div
            onClick={() => formatFontSize(size.value)}
            className={cx('toolbar-dropdown-option', { selected: fontSize === size.value })}
            style={{ fontSize: size.value, lineHeight: size.value }}
          >
            {size.name}
          </div>
        ))}
      </IconWithDropdown>
      <IconWithColorPicker
        Icon={TextColorIcon}
        data-testid={'text-color-button'}
        onChange={onFontColorSelect}
        color={fontColor}
      />
      <IconWithColorPicker
        Icon={BackgroundColor}
        data-testid={'background-color-button'}
        onChange={onBgColorSelect}
        color={bgColor}
      />
      <div className="editor-header-divider" />
      <IconWithDropdown Icon={AlignLeft} data-testid={'align-button'}>
        <>
          <IconButton sx={{ ...iconButtonStyles, padding: '5px' }}>
            <FormatAlignLeftIcon
              onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
              }}
              sx={dropdownIconStyles}
            />
          </IconButton>
          <IconButton sx={{ ...iconButtonStyles, padding: '5px' }}>
            <FormatAlignCenterIcon
              onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
              }}
              sx={dropdownIconStyles}
            />
          </IconButton>
          <IconButton sx={{ ...iconButtonStyles, padding: '5px' }}>
            <FormatAlignRightIcon
              onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
              }}
              sx={dropdownIconStyles}
            />
          </IconButton>
          <IconButton sx={{ ...iconButtonStyles, padding: '5px' }}>
            <FormatAlignJustifyIcon
              onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
              }}
              sx={dropdownIconStyles}
            />
          </IconButton>
        </>
      </IconWithDropdown>
      <IconButton sx={iconButtonStyles} data-testid={'list-numbered-button'}>
        <FormatListNumberedIcon
          onClick={formatNumberedList}
          sx={{
            color: blockType === 'ol' ? '#323232' : '#6E6E6E'
          }}
        />
      </IconButton>
      <IconButton sx={iconButtonStyles} data-testid={'list-bulleted-button'}>
        <FormatListBulletedIcon
          onClick={formatBulletList}
          sx={{
            color: blockType === 'ul' ? '#323232' : '#6E6E6E'
          }}
        />
      </IconButton>
      <IconButton sx={iconButtonStyles} data-testid={'indent-button'}>
        <Indent onClick={formatIndent} />
      </IconButton>
      <IconButton sx={iconButtonStyles} data-testid={'outdent-button'}>
        <Outdent onClick={formatOutdent} />
      </IconButton>
    </div>
  );
}
