import React from 'react';
import { isEmpty } from 'lodash/lang';

import MultiSelect from '../../../../../common/data-entry/MultiSelect/MultiSelect';
import ApplyAndResetButtons from '../../../../ApplyAndResetButtons/ApplyAndResetButtons';

export const WorklistFilters = ({ Context }) => {
  const {
    selectedStudies,
    selectedSites,
    studies,
    sites,
    onStudiesChange,
    onSitesChange,
    applyFilters,
    validationErrors,
    isFetching
  } = Context;

  const getCustomTemplateFunction = label => elements => {
    if (!isEmpty(elements)) {
      return (
        <React.Fragment>
          {label}
          <span className="count">{elements.length}</span>
        </React.Fragment>
      );
    } else {
      return <span className={'placeholder'}>{label}</span>;
    }
  };
  return (
    <>
      <div className="general-header-group-container general-header-wrapper ">
        <MultiSelect
          controlId={'id'}
          clearable={false}
          disabled={isFetching || studies.length === 1}
          validate={true}
          clearSearchOnSelection
          forceDefaultBrowserScrolling={true}
          optionValueKey={'name'}
          placeholder={getCustomTemplateFunction('Study')([])}
          focusToSelectedValue={false}
          onChange={onStudiesChange}
          value={selectedStudies}
          searchable
          dataSource={studies}
          customSelectedValueTemplateFunction={getCustomTemplateFunction('Study')}
          validationMessage={
            isEmpty(selectedStudies) && validationErrors.includes('selectedStudies') ? 'Select Study' : ''
          }
        />
        <MultiSelect
          controlId={'id'}
          clearable={false}
          disabled={isFetching || sites.length === 1}
          validate={true}
          clearSearchOnSelection
          forceDefaultBrowserScrolling={true}
          optionValueKey={'name'}
          focusToSelectedValue={false}
          onChange={onSitesChange}
          value={selectedSites}
          searchable
          dataSource={sites}
          placeholder={getCustomTemplateFunction('Site')([])}
          customSelectedValueTemplateFunction={getCustomTemplateFunction('Site')}
          validationMessage={isEmpty(selectedSites) && validationErrors.includes('selectedSites') ? 'Select Site' : ''}
        />
        <ApplyAndResetButtons onApply={() => applyFilters()} disabled={isFetching} />
      </div>
    </>
  );
};
