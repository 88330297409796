import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactTable from 'react-table';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';

import { pendoTrackDefaultSortingChange } from '../../../../pendo/PendoUtils';
import { generateUrlByKey } from '../../../root/router';

export default function PatientList({ patientList, totalPages, pageSize, onFetchData }) {
  const columns = [
    {
      id: 'patientName',
      Header: 'Patient',
      accessor: ({ lastName, firstName }) => `${lastName}, ${firstName}`,
      Cell: ({ value, original }) => {
        return (
          <NavLink
            to={generateUrlByKey('Patients.Patient Profile.Patient Studies', {
              patientId: original.patientId,
              ssuPatientId: original.uniqueIdentifier
            })}
            title="Go to Patient Info"
          >
            {value}
            {original.preferredName && <span className="patient-preferred-name"> ({original.preferredName})</span>}
          </NavLink>
        );
      },
      className: 'activelink'
    },
    {
      Header: 'Subject ID',
      id: 'subjectId',
      accessor: 'subjectId',
      Cell: ({ value }) => value || 'N/A'
    },
    {
      Header: 'Study',
      accessor: 'studyName'
    },
    {
      Header: 'Site',
      accessor: 'siteName'
    },
    {
      Header: 'Status',
      accessor: 'statusCode'
    }
  ];

  return (
    <section>
      <div className="row border p-3 m-0 my-2">
        <div className="col p-0 pt-2">
          <h5 className=" c-p">Patient Details</h5>
        </div>
        <div className="col-12 border px-0">
          <ReactTable
            data={patientList || []}
            columns={columns}
            minRows={1}
            showPagination
            onSortedChange={pendoTrackDefaultSortingChange}
            nextText=">>"
            previousText="<<"
            noDataText="No Record Found"
            pageSizeOptions={[25, 50, 100]}
            defaultPageSize={pageSize}
            defaultSorted={[{ id: 'patientName' }]}
            pages={totalPages || 1}
            manual
            onFetchData={state => isFunction(onFetchData) && onFetchData(state)}
          />
        </div>
      </div>
    </section>
  );
}

PatientList.propTypes = {
  patientList: PropTypes.array,
  onFetchData: PropTypes.func,
  totalPages: PropTypes.number,
  pageSize: PropTypes.oneOf([25, 50, 100]),
  page: PropTypes.number
};
