import React, { useContext } from 'react';

import Button from '../../../../../common/general/Button';
import { PageInfoHeader } from '../../../../PageInfoHeader/PageInfoHeader';
import { WorklistContext } from '../WorklistContext';
import { calculatePatientsWithStatus } from '../worklistService';

import { prescreenFailedStatus, prescreenStatuses } from './WorklistFilters/WorklistFilterConstants';
import { WorklistFilters } from './WorklistFilters/WorklistFilters';

export const WorklistHeader = () => {
  const { downloadCSV, tableData } = useContext(WorklistContext);
  return (
    <PageInfoHeader
      pageInfo={
        <PageInfoHeader.CollapsibleList>
          {prescreenStatuses.map(status => {
            return (
              <PageInfoHeader.AdditionalInfo key={status.id} title={status.name} data-testid={`${status.name}-count`}>
                {calculatePatientsWithStatus(tableData, 'prescreenStatus', status.id)}
              </PageInfoHeader.AdditionalInfo>
            );
          })}
          <PageInfoHeader.AdditionalInfo
            key={prescreenFailedStatus.id}
            title={prescreenFailedStatus.name}
            data-testid={`${prescreenFailedStatus.name}-count`}
          >
            {calculatePatientsWithStatus(tableData, 'status', prescreenFailedStatus.id)}
          </PageInfoHeader.AdditionalInfo>
        </PageInfoHeader.CollapsibleList>
      }
      right={
        <Button size="h28" priority="medium" onClick={downloadCSV}>
          Export
        </Button>
      }
    >
      <WorklistFilters />
    </PageInfoHeader>
  );
};
