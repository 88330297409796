import React from 'react';
import { isEqual } from 'lodash/lang';

import Checkbox from '../../../../../../../../../../common/data-entry/InputSelectors/Checkbox';
import { FILE_TYPES } from '../../../../../../../../../../constants/inputTypes';
import { FileView } from '../../../FileView/FileView';

export default function PopulatedValue({ fileValue, inputType, stringValue }) {
  if (inputType === 'checkbox') {
    return <Checkbox checked={isEqual(stringValue, 'true')} disabled />;
  }
  if (FILE_TYPES.includes(inputType)) {
    if (fileValue.files.length > 0) {
      return <FileView files={fileValue.files} />;
    }
  } else {
    return stringValue;
  }
  return stringValue;
}
