import React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash/lang';

import EncounterPrintMultiselect from '../../../../../PrintPatientEncountersGeneratorModal/EncounterPrintMultiselect';
import { useExportCaseReportFormsActions, useExportCaseReportFormsState } from '../ExportCaseReportFormsContext';

export default function LogsSelector() {
  const { selectedLogs, allLogs, allEncounters, selectedType } = useExportCaseReportFormsState();
  const { onLogsSelect } = useExportCaseReportFormsActions();
  return (
    <div className={cx('logs-selector', { 'hide-selector-form': selectedType !== 'LOGS' })}>
      <h3>Logs</h3>
      <EncounterPrintMultiselect
        selectedOptions={selectedLogs}
        options={allLogs}
        onChange={selected => onLogsSelect(selected)}
        id="uuid"
        defaultSelectAll
        displaySelectAll={false}
      />

      {isEmpty(allLogs) && isEmpty(allEncounters) && (
        <div className="alert-unavailable-items">There are no logs for this patient.</div>
      )}
    </div>
  );
}
