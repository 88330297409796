import React from 'react';
import cx from 'classnames';

import withSkeleton from '../../../../../../../common/hocs/withSkeleton';
import HighlightWithTooltip from '../../../../../../root/Container/Layout/MenuOptions/FindPatient/FindPatientPopup/FindPatientPopupItem/HighlightWithTooltip';

import ProtocolGroupsSetupSkeleton from './ProtocolGroupsListSkeleton/ProtocolGroupsSetupSkeleton';

import './ProtocolGroupsList.scss';

export default withSkeleton(ProtocolGroupsSetupSkeleton, ProtocolGroupsList);

function ProtocolGroupsList({ groups, selectedGroupId, selectGroup }) {
  if (!groups) return null;
  return (
    <div className="protocol-groups-list">
      <div className="protocol-groups-list-title">Groups:</div>

      <ul className="protocol-groups-list-items">
        {groups.map(({ id, name }) => (
          <li
            key={id}
            className={cx({ 'protocol-groups-list-item--active': id === selectedGroupId })}
            onClick={() => selectGroup(id)}
          >
            <HighlightWithTooltip word={`${name}`} referredLength={25} />
          </li>
        ))}
      </ul>
    </div>
  );
}
