import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  createStudyEnrollmentActionCreator,
  updateStudyEnrollmentsActionCreator
} from '../../../../../actions/finance/forecasting/forecastingActions';
import Input from '../../../../../common/data-entry/Input';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';

class StudyEnrolmentPredictionReasonDialog extends Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    return (
      <>
        <ModalBoxes.Body>
          <div className="container">
            <div className="row">
              <div className="col">
                Remaining to enroll:{' '}
                {(this.state.expectedCountSiv || this.state.expectedCountPsv) - this.state.enrolledCount}
              </div>
            </div>
            <div className="row">
              <div className="col">
                Predicted to enroll:{' '}
                {this.state.enrollmentPredictionValues.reduce((accumulate, epv) => accumulate + parseInt(epv.value), 0)}
              </div>
            </div>
            <div className="text-box-field">
              <Input
                label="Reason"
                name="reason"
                value={this.state.reason}
                onChange={this.handleReasonChange}
                validationMessage={this.state.reason === '' ? 'Reason is required' : this.state.validationMessage}
              />
              <div className="text-box-counter">{`${this.state.reason ? this.state.reason.length : ''}/1000`}</div>
            </div>
          </div>
        </ModalBoxes.Body>
        <ModalBoxes.Footer>
          <ButtonGroup>
            <Button priority="medium" onClick={this.props.modalBox.close}>
              Close
            </Button>
            <Button onClick={this.onSave} disabled={this.state.reason === '' || this.state.reason.length > 1000}>
              Save
            </Button>
          </ButtonGroup>
        </ModalBoxes.Footer>
      </>
    );
  }

  onSave = () => {
    if (this.state.id === '') {
      this.props.createEnrollmentState({ ...this.state });
    } else {
      this.props.updateEnrollmentState({ ...this.state });
    }
    this.props.modalBox.close();
  };

  handleReasonChange = event => {
    const { value } = event.target;
    this.setState({ reason: value });
    if (value.length > 1000) {
      this.setState({ validationMessage: 'Reason cannot exceed 1000 characters' });
    } else if (value.length === 0 || value.trim().length === 0) {
      this.setState({ validationMessage: 'Reason is required' });
    } else {
      this.setState({ validationMessage: null });
    }
  };
}

StudyEnrolmentPredictionReasonDialog.className = 'enrollment-reason-dialog';
StudyEnrolmentPredictionReasonDialog.size = 'w800';

function mapDispatchToProps(dispatch) {
  return {
    updateEnrollmentState: studyEnrollment => {
      dispatch(updateStudyEnrollmentsActionCreator(studyEnrollment));
    },
    createEnrollmentState: studyEnrollment => {
      dispatch(createStudyEnrollmentActionCreator(studyEnrollment));
    }
  };
}

export default connect(null, mapDispatchToProps)(StudyEnrolmentPredictionReasonDialog);
