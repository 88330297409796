export const LAYOUT_VERTICAL = 'VERTICAL';
export const LAYOUT_HORIZONTAL = 'HORIZONTAL';

export const PS_ANSWERED = 'ANSWERED'; // 1
export const PS_NOT_ASKED = 'NOT_ASKED'; // 2
export const PS_UNAVAILABLE = 'UNAVAILABLE'; // 3
export const PS_NOT_ANSWERED = 'NOT_ANSWERED'; // 4
export const PS_SYSTEM_DATA = 'SYSTEM_DATA'; // 5

export const performedStatusLabelsMap = {
  [PS_UNAVAILABLE]: 'Unavailable',
  [PS_NOT_ASKED]: 'Not Asked'
};

export const readOnlyFieldTypes = ['reminder', 'textBlockLong', 'label'];
