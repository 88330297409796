import { PL } from '../../../../constants/accessToPages';
import { generatePatientInfoRouterConfig } from '../../../root/router/sharedConfigs';

import PatientDetails from './Patients';

export const patientsRouterConfig = {
  name: 'Patients',
  path: '/patients',
  component: PatientDetails,
  access: [PL],
  inherited: [...generatePatientInfoRouterConfig(PL, PL)]
};
