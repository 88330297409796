import React, { useEffect, useRef, useState } from 'react';
import { ControlledMenu, MenuItem } from '@szhsin/react-menu';
import cx from 'classnames';
import { isFunction } from 'lodash/lang';
import * as moment from 'moment';

import DatePicker from '../../../../../../common/data-entry/DatePicker';
import Button from '../../../../../../common/general/Button';
import useOutsideClickDetector from '../../../../../../common/hooks/useOutsideClickDetector';

import './EdcVisitDatePickerButton.scss';

export default function EdcVisitDatePickerButton({ classNames, onChange, value, isAllowedToManage }) {
  const [showDialog, setShowDialog] = useState(false);
  const [date, setDate] = useState();
  const container = useRef();
  const isDisabled = !(isFunction(isAllowedToManage) ? isAllowedToManage() : isAllowedToManage);
  useOutsideClickDetector(container, () => setShowDialog(false));

  useEffect(
    function() {
      value && moment.isMoment(value) && setDate(value);
    },
    [value]
  );

  const ref = useRef(null);
  return (
    <div className={cx('edc-visit-date-button', classNames, { active: showDialog })} ref={container}>
      <Button
        priority="medium"
        size="h28"
        disabled={isDisabled}
        className="menu-button"
        ref={ref}
        onClick={() => setShowDialog(true)}
      >
        {date ? date.format('DD/MMM/YYYY') : 'Select'}
      </Button>

      <ControlledMenu anchorRef={ref} isOpen={showDialog}>
        <MenuItem>
          <div className="dropdown-container">
            <DatePicker
              input={false}
              value={date}
              timeFormat={false}
              open={true}
              onChange={date => {
                setShowDialog(false);
                onChange(date);
              }}
              isValidDate={current => {
                const currentMoment = moment(current);
                const today = moment();
                return currentMoment.isSameOrBefore(today);
              }}
            />
            <div className={'hint'}>
              This is the official date of the Encounter that corresponds to the visit date defined in the electronic
              data capture (EDC) system.
            </div>
          </div>
        </MenuItem>
      </ControlledMenu>
    </div>
  );
}
