import React, { useMemo } from 'react';

import Checkbox from '../../../../../../../common/data-entry/InputSelectors/Checkbox';
import { scYellow100 } from '../../../../../../../constants/systemColors';
import { useCurrentUser } from '../../../../../../root/Container/CurrentUserContainer';
import { canFieldBeHighlightedForReview, collectAllQuestionsField } from '../services/fieldsServices';
import { useTableItemGroupActions, useTableItemGroupState } from '../TableItemGroupContext/TableItemGroupContext';

export default function HighlightAllForReview() {
  const { encounterTableItemGroup, hiddenFieldsIds, isPreviewOnly } = useTableItemGroupState();
  const { toggleAllRequiredAttention } = useTableItemGroupActions();
  const user = useCurrentUser();

  const [highlightAllForReviewChecked, highlightAllForReviewIndeterminate] = useMemo(
    function() {
      const allFields = collectAllQuestionsField(encounterTableItemGroup).filter(function(field) {
        return canFieldBeHighlightedForReview(field, hiddenFieldsIds, user.activeRole);
      });
      const requiredAttentionFieldsLength = allFields.filter(function({ requiredAttention }) {
        return requiredAttention;
      }).length;

      const allFieldsRequiredAttention = requiredAttentionFieldsLength === allFields.length;

      return [allFieldsRequiredAttention, !allFieldsRequiredAttention && requiredAttentionFieldsLength > 0];
    },
    [encounterTableItemGroup, hiddenFieldsIds, user]
  );

  return (
    <Checkbox
      disabled={isPreviewOnly}
      label="Highlight all for Review"
      color={scYellow100}
      onChange={function({ target }) {
        return toggleAllRequiredAttention(target.checked);
      }}
      indeterminate={highlightAllForReviewIndeterminate}
      checked={highlightAllForReviewChecked}
    />
  );
}
