export const NON_PATIENT_EVENT_TYPE = {
  IMV: 'IMV',
  RMV: 'RMV',
  SMV: 'SMV',
  PSV: 'PSV',
  SIV: 'SIV',
  IM: 'IM',
  CLOSE_OUT_VISIT: 'CLOSE_OUT_VISIT',
  PHONE: 'PHONE',
  OUT_OF_OFFICE: 'OUT_OF_OFFICE',
  OTHER: 'OTHER'
};
