import React, { Component } from 'react';
import ReactTable from 'react-table';
import { orderBy } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

import { pendoTrackDefaultSortingChange } from '../../../../../pendo/PendoUtils';

import EncounterElementHeader from './EncounterElementHeader';

import 'react-table/react-table.css';
import './commentTemplate.scss';

let compInst = null;
class CommentLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enComments: '',
      showCommentBox: false,
      hasEdited: false,
      addFormButton: true
    };
    compInst = this;
  }

  onChangeENComment_Handler = e => {
    this.setState({ enComments: e.target.value });
  };

  resetState = () => {
    this.setState({ hasEdited: false, showCommentBox: false, addFormButton: true });
  };

  cancelForm = () => {
    this.props.closeDetailRow();
    this.props.cancelTableForm(this.resetState);
  };

  openForm = () => {
    this.props.openDetailRow();
    this.props && this.props.addNewComment && this.props.addNewComment();
    this.setState({ hasEdited: false, showCommentBox: true, addFormButton: false });
  };

  onTRClick = (state, rowInfo, column) => {
    return {
      onClick: e => {
        if (rowInfo.original && rowInfo.original.formGroupIdentifier) {
          this.props.addNewComment();
          this.props.editAditionalNotes(rowInfo);
          this.props.openDetailRow();
          this.setState({ hasEdited: true, showCommentBox: true, addFormButton: false });
          return {
            style: {
              cursor: rowInfo.original.formGroupIdentifier ? 'pointer' : 'default'
            }
          };
        }
      }
    };
  };

  static resetFormView() {
    try {
      if (compInst) {
        compInst.setState({ hasEdited: false, showCommentBox: false, addFormButton: true });
      }
    } catch (e) {}
  }

  render() {
    const commentsOrderedFromEarliest = orderBy(this.props?.formData?.listOfComments, 'patientEncounterKey', 'desc');

    const props = this.props;
    return (
      <div className="row m-0 my-2">
        {this.state.addFormButton && this.props?.isAllowedToManage() && (
          <div className="col float-right my-1 add-btn-bg">
            <span className="cursor-pointer" onClick={() => this.openForm()}>
              <span className="float-right c-w cursor-pointer">Add</span>
              <span className="add-btn">
                <i className="material-icons">add</i>
              </span>
            </span>
          </div>
        )}

        {!this.state.showCommentBox && (
          <div className="col-12 px-0">
            <ReactTable
              data={commentsOrderedFromEarliest || []}
              columns={columns}
              minRows={1}
              multiSort
              onSortedChange={pendoTrackDefaultSortingChange}
              showPagination
              nextText=">>"
              previousText="<<"
              noDataText="No Record Found"
              defaultPageSize={10}
              className="commenthover"
              getTrProps={this.onTRClick}
            />
          </div>
        )}
        {this.state.showCommentBox && (this.state.hasEdited || this.state.showCommentBox) && (
          <div className="col-md-12 m-0 p-0">
            <h5 className="subtitle mt-2 col-md-12">
              {' '}
              {this.state.hasEdited ? 'Edit Details' : 'Add Details'} :{' '}
              <span className="cursor-pointer float-right" onClick={() => this.cancelForm()}>
                <span>
                  <i className="glyphicon glyphicon-remove" />
                </span>
              </span>
            </h5>

            <div className="col-12 mt-3 p-0">
              <div className="form-group">
                <label className="">Comment</label>
                <textarea
                  disabled={!this.props?.isAllowedToManage()}
                  name="COVAL"
                  maxLength="3000"
                  className="form-control"
                  value={this.props.additionNotes}
                  onChange={e => {
                    this.props.onTblCovalChangeHandeler(e);
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {!isEmpty(props.formData) && <EncounterElementHeader {...props.headerProps} />}
      </div>
    );
  }
}

export default CommentLogs;

const columns = [
  {
    Header: 'Encounter',
    accessor: 'encounterName',
    Cell: row => {
      return row.row._original.encounterName ? <span> {row.row._original.encounterName}</span> : '--';
    }
  },
  {
    Header: 'Epoch',
    accessor: 'epochName',
    Cell: row => {
      return row.row._original.epochName ? <span> {row.row._original.epochName}</span> : '--';
    }
  },
  {
    Header: 'Procedure',
    accessor: 'itemGroupName',
    Cell: row => {
      return row.row._original.itemGroupName ? <span> {row.row._original.itemGroupName}</span> : '--';
    }
  },
  {
    Header: 'Comments',
    accessor: 'comments',
    Cell: row => {
      return row.row._original.comments ? (
        <div className="wordcss" title={row.row._original.comments}>
          {' '}
          {row.row._original.comments}
        </div>
      ) : (
        '--'
      );
    }
  }
];
