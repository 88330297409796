import React, { useCallback, useMemo } from 'react';
import { MenuItem } from '@mui/material';

import { NonProtocolEncounterApi, PreScreenApi } from '../../../../../../api';
import { CLOSED } from '../../../../../../constants/ssuStatuses';
import {
  ROLE_PATIENT_LIAISON,
  ROLE_PATIENT_REPRESENTATIVE,
  ROLE_PRINCIPAL_INVESTIGATOR,
  ROLE_RECRUITER,
  ROLE_STUDY_MANAGER,
  ROLE_SYSTEM_ADMINISTRATOR
} from '../../../../../../constants/userRoles';
import { userHasRole } from '../../../../../../services/auth';
import { generateLink, isAccessRestricted } from '../../worklistService';

export const ItemGroupMenuItems = ({ row, itemGroupAccessors, handleCloseMenu, updateStatus }) => {
  const isAllowed = useMemo(
    function() {
      if (!userHasRole(ROLE_SYSTEM_ADMINISTRATOR) && row.studySiteStatus === CLOSED) {
        return false;
      }
      return userHasRole([
        ROLE_SYSTEM_ADMINISTRATOR,
        ROLE_STUDY_MANAGER,
        ROLE_RECRUITER,
        ROLE_PATIENT_REPRESENTATIVE,
        ROLE_PATIENT_LIAISON,
        ROLE_PRINCIPAL_INVESTIGATOR
      ]);
    },
    [row.studySiteStatus]
  );

  const startEncounter = useCallback(
    ({ sitePatientId, encounterIdentifier, elementIdentifier }) => {
      const encounterDetails = {
        protocolEncounterId: encounterIdentifier
      };
      NonProtocolEncounterApi.createNonProtocolEncounter(sitePatientId, encounterDetails).then(
        ({ data: ssuPatientEncounterId }) => {
          if (row.status === 'IDENTIFIED') {
            updateStatus(row.sitePatientId, row.status, 'PRE_SCREENING');
          }
          PreScreenApi.getLinkConstructor(sitePatientId, ssuPatientEncounterId, elementIdentifier).then(({ data }) =>
            window.open(generateLink(data), '_blank')
          );
        }
      );
    },
    [row.sitePatientId, row.status, updateStatus]
  );

  const onClick = useCallback(
    accessor => {
      handleCloseMenu();
      if (row[accessor].linkConstructor) {
        const link = generateLink(row[accessor].linkConstructor);
        window.open(link, '_blank');
      } else if (isAllowed) {
        startEncounter(row[accessor]);
      }
    },
    [handleCloseMenu, isAllowed, row, startEncounter]
  );

  return Object.keys(itemGroupAccessors).map((accessor, index) => {
    const disabled =
      isAccessRestricted(row[accessor]) ||
      row[accessor].state === 'UNAVAILABLE' ||
      (!row[accessor].linkConstructor && !['IDENTIFIED', 'PRE_SCREENING'].includes(row.status));
    return (
      <MenuItem key={`${itemGroupAccessors[accessor]}${index}`} disabled={disabled} onClick={() => onClick(accessor)}>
        {itemGroupAccessors[accessor]}
      </MenuItem>
    );
  });
};
