import React from 'react';

import Input from '../../../../../../../common/data-entry/Input';

import './CellTextField.scss';

export function CellTextField({ value, onChange, placeholder }) {
  return (
    <div className="eui-table-input">
      <Input placeholder={placeholder} legacyLook={true} onChange={onChange} value={value} />
    </div>
  );
}
