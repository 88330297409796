import React from 'react';
import { get } from 'lodash/object';
import { func, object, string } from 'prop-types';

import { SSUFilter } from '../../../SSUFilter/SSUFilter';

import SSUFilterEventDialogView from './SSUFilterEventDialogView';

export default function StudySiteSelection({
  changeStudySite,
  studySite,
  validation,
  ssuProvider,
  hideStudy,
  studySiteValidation
}) {
  function toBackendStudySiteMode(studySite, selectedStudy, selectedSite) {
    return {
      id: get(studySite, 'uniqueIdentifier'),
      study: selectedStudy
        ? {
            id: selectedStudy.uniqueIdentifier,
            name: selectedStudy.studyName
          }
        : null,
      site: selectedSite
        ? {
            id: selectedSite.uniqueIdentifier,
            name: selectedSite.siteName
          }
        : null
    };
  }

  const handleStudySiteChange = (studySites, selectedStudy, selectedSite) => {
    const eventStudySite = toBackendStudySiteMode(
      selectedStudy && selectedSite && studySites?.length === 1 ? studySites[0] : null,
      selectedStudy,
      selectedSite
    );
    changeStudySite(eventStudySite);
  };
  return (
    <SSUFilter
      handleSSUFilterChange={handleStudySiteChange}
      ssuIdProvider={() => get(studySite, 'id')}
      studyIdProvider={() => get(studySite, 'study.id')}
      siteIdProvider={() => get(studySite, 'site.id')}
      isStudyRequired={false}
      ssuProvider={ssuProvider}
      resetFilterIfNoValue={true}
      noStudyAutoSelect={hideStudy}
    >
      <SSUFilterEventDialogView
        studyValidationMessage={get(validation, 'validationMessage.study')}
        siteValidationMessage={get(validation, 'validationMessage.site')}
        hideStudy={hideStudy}
        studySiteValidation={studySiteValidation}
      />
    </SSUFilter>
  );
}

StudySiteSelection.propTypes = {
  changeStudySite: func,
  studySite: object,
  validationMessage: string
};
