import React, { useContext, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { findIndex } from 'lodash/array';
import { isEmpty, isObject } from 'lodash/lang';

import IconButton from '../../../../../../../../common/buttons/IconButton';
import Checkbox from '../../../../../../../../common/data-entry/InputSelectors/Checkbox';
import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Icon from '../../../../../../../../common/general/Icon';
import StyledTooltip from '../../../../../../../../common/widgets/StyledToolTip';
import { scBlue100 } from '../../../../../../../../constants/systemColors';
import { TableSetupContext } from '../../../../../../../../contexts/TableSetupContext';
import { InvestigatorFieldsCheckbox } from '../../../InvestigatorFields/InvestigatorFieldsCheckbox/InvestigatorFieldsCheckbox';
import { customClassObject } from '../../../ItemGroupSetup/customClassObject';
import FieldPreview from '../../../ItemGroupSetup/ItemGroupSetupContent/CustomFieldsGenerator/FieldPreview';
import { checkConditionalLogicForThisItem } from '../../TableSetupServices';
import EditCustomFieldLogicModal from '../TableFieldCustomItem/EditCustomFieldLogicModal/EditCustomFieldLogicModal';

import EditCDASHModal from './EditCDASHModal/EditCDASHModal';
import EditFormAnswer from './EditFormAnswer/EditFormAnswer';

import './TableFieldItem.scss';

const TableFieldItem = ({ item, itemIndex, className, general }) => {
  const tableSetupContext = useContext(TableSetupContext);

  const selectedToggleFields = useMemo(() => {
    const fieldList = [...tableSetupContext.generalFields, ...tableSetupContext.fieldList];
    return fieldList.filter(field => field.inputType === 'radio' && field.isChecked && field.name !== item.name);
  }, [item.name, tableSetupContext.fieldList, tableSetupContext.generalFields]);

  const currentFieldsToggleValue = useMemo(() => {
    return item.codeDefinationList.map(element => ({ name: element.name, value: element.name }));
  }, [item.codeDefinationList]);

  const alertForItem = useMemo(() => {
    let alertMessage = null;
    if (!isEmpty(item.fieldConfigurationResponse)) {
      item.fieldConfigurationResponse.forEach(configuration => {
        const toggleIndexInFieldList = findIndex(tableSetupContext.fieldList, [
          'name',
          configuration.conditionFieldName
        ]);
        const toggleIndexInGeneralFields = findIndex(tableSetupContext.generalFields, [
          'name',
          configuration.conditionFieldName
        ]);
        if (
          configuration.conditionFieldName &&
          ((general && toggleIndexInGeneralFields > itemIndex) ||
            toggleIndexInFieldList > itemIndex ||
            (general && toggleIndexInGeneralFields < 0))
        ) {
          alertMessage = `Toggle "${configuration.conditionFieldName}" must be before controlled item`;
        }
      });
    }
    if (isObject(item.hideAllConfiguration) && itemIndex) {
      alertMessage = `This toggle must be first in the list`;
    }
    return alertMessage;
  }, [
    general,
    item.fieldConfigurationResponse,
    item.hideAllConfiguration,
    itemIndex,
    tableSetupContext.fieldList,
    tableSetupContext.generalFields
  ]);

  const itemsWishHideAllConditionalLogic = [
    ...tableSetupContext.generalFields,
    ...tableSetupContext.fieldList
  ].filter(field => isObject(field.hideAllConfiguration));

  useEffect(() => {
    tableSetupContext.updateAlertForItem(itemIndex, !!alertForItem, general);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertForItem]);

  const editCDASHItem = () => {
    ModalBoxes.open({
      component: (
        <EditCDASHModal
          question={item.question}
          updatedQuestion={item.updatedQuestion}
          updateCDASHQuestion={tableSetupContext.updateCDASHQuestion}
          general={general}
          itemIndex={itemIndex}
          headerText="Edit Item"
        />
      )
    });
  };

  const editCustomFieldLogic = () => {
    ModalBoxes.open({
      component: (
        <EditCustomFieldLogicModal
          itemType={item.inputType}
          itemName={item.name}
          general={general}
          addConditionalLogicForItem={tableSetupContext.addConditionalLogicForItem}
          itemIndex={itemIndex}
          selectedToggleFields={selectedToggleFields}
          initialConfiguration={item.fieldConfigurationResponse || []}
          initialHideAllConfiguration={item?.hideAllConfiguration}
          currentFieldsToggleValue={currentFieldsToggleValue}
          itemsWishHideAllConditionalLogic={itemsWishHideAllConditionalLogic}
        />
      ),
      className: 'edit-conditional-logic-modal'
    });
  };

  const deleteToggleWithConfiguration = () => {
    ModalBoxes.confirm({
      content: 'Unchecking this toggle will remove field configurations in this Item Group. Uncheck anyway?',
      confirmButton: 'Yes',
      cancelButton: 'No',
      className: 'edit-custom-field-logic-modal',
      title: 'Toggle Configuration'
    })
      .then(() => {
        tableSetupContext.toggleCheckForField(itemIndex, general, true);
      })
      .catch(e => {});
  };

  return (
    <div className={className}>
      <div className="column big">
        <IconButton
          className="glyphicon glyphicon-edit edit-name"
          suit="glyphicon"
          color="blue"
          title="Edit CDASH Item"
          onClick={editCDASHItem}
        >
          edit
        </IconButton>
        <Checkbox
          color={scBlue100}
          checked={item.isChecked}
          onChange={() => {
            if (
              item?.inputType === 'radio' &&
              checkConditionalLogicForThisItem(
                [...tableSetupContext.fieldList, ...tableSetupContext.generalFields],
                item.name
              ) &&
              item.isChecked
            ) {
              deleteToggleWithConfiguration(true);
            } else {
              tableSetupContext.toggleCheckForField(itemIndex, general);
            }
          }}
        />
        {item.question}
        {alertForItem && (
          <StyledTooltip className="tooltip-table-setup-alert" overlay={<div>{alertForItem}</div>} placement="top">
            <Icon className="conditional-logic-alert">warning</Icon>
          </StyledTooltip>
        )}
      </div>
      <div className="column big">
        <input
          className="form-control select-width"
          value={(item.hasOwnProperty('updatedQuestion') && item.updatedQuestion) || ''}
          type="text"
          disabled
        />
      </div>
      <div className="column big">
        <FieldPreview data={item} customClassObject={customClassObject} itemDefIndex={itemIndex} />
        <EditFormAnswer
          itemIndex={itemIndex}
          inputType={item.inputType}
          isExtensible={item.isExtensible}
          general={general}
        />
        <IconButton
          className={cx('edit-conditional-configuration-icon', { hide_all_configuration: item?.hideAllConfiguration })}
          title="Edit Conditional Logic"
          color={!isEmpty(item.fieldConfigurationResponse) || item?.hideAllConfiguration ? 'red' : 'blue'}
          onClick={editCustomFieldLogic}
        >
          app_registration
        </IconButton>
      </div>
      <div className="column normal">
        <input
          type="text"
          readOnly
          value={item.sequence || ''}
          title="Form Item Sequence"
          className="form-control item-sequence"
        />
      </div>
      <div className="column short">
        <InvestigatorFieldsCheckbox
          item={item}
          onInvestigatorFieldChange={value => {
            tableSetupContext.toggleInvestigatorField(value, itemIndex, general);
          }}
        />
      </div>
      <div className="column short">
        {general ? (
          <div className="delete-option-item" onClick={() => tableSetupContext.deleteFieldItemFromGeneral(item.name)} />
        ) : (
          <Checkbox
            color={scBlue100}
            checked={item.tableGeneralField || false}
            onChange={() => tableSetupContext.toggleGeneralForField(itemIndex)}
          />
        )}
      </div>
    </div>
  );
};

export default TableFieldItem;
