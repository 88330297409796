import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import Tooltip from 'rc-tooltip';

import { useEncounterSegment } from '../../EncounterSegment';

import './CardHeaderMiddleDefault.scss';

const START_END_DATE_FORMAT = 'DD/MMM/YYYY';
export default function CardHeaderMiddleDefault() {
  const { encounter } = useEncounterSegment();
  const { sitePatientEncounter, numberOfRequiredFields } = encounter;

  const {
    numberOfCompletedRequiredFields,
    start,
    completedFromFinancialPoint,
    end,

    edcVisitDate
  } = sitePatientEncounter || {};

  const progressInPercent = Math.round((100 / numberOfRequiredFields) * numberOfCompletedRequiredFields);

  return (
    <>
      <div className="est-comments">{sitePatientEncounter?.statusChangeReason}</div>
      <div className="side-group">
        <div className="est-progress">
          {sitePatientEncounter && (
            <>
              <div className="est-progressbar">
                <i style={{ width: `${progressInPercent}%` }} />
              </div>
              <div>
                {numberOfCompletedRequiredFields}/{numberOfRequiredFields}
              </div>
            </>
          )}
        </div>

        <div className="est-finance">{completedFromFinancialPoint && '$'}</div>
        {(start || end) && (
          <Tooltip
            destroyTooltipOnHide={false}
            overlayClassName={cx('eds-rc-tooltip encounter-dates-tooltip')}
            placement="top"
            align={{
              offset: [-15, 10]
            }}
            mouseEnterDelay={0}
            mouseLeaveDelay={0}
            overlay={
              <div className="overlay-container">
                <span className="content-title">Dates</span>
                <div className="dates-layout">
                  {start && (
                    <div className="date-item">
                      <span className="title">Check-In:</span>
                      <span>{moment(start).format(START_END_DATE_FORMAT)}</span>
                    </div>
                  )}
                  {end && (
                    <div className="date-item">
                      <span className="title">Completed:</span>
                      <span>{moment(end).format(START_END_DATE_FORMAT)}</span>
                    </div>
                  )}
                  {edcVisitDate && (
                    <div className="date-item">
                      <span className="title">Visit:</span>
                      <span>{moment(edcVisitDate).format(START_END_DATE_FORMAT)}</span>
                    </div>
                  )}
                </div>
              </div>
            }
          >
            <div className="est-visit-date">{edcVisitDate && moment(edcVisitDate).format(START_END_DATE_FORMAT)}</div>
          </Tooltip>
        )}
      </div>
    </>
  );
}
