import { cloneDeep, isFunction } from 'lodash/lang';
import moment from 'moment';

export function parsePatientInfo({
  firstName,
  lastName,
  middleName,
  preferredName,
  dob,
  medicalRecordNumber,
  phonesInfo,
  email,
  address,
  ssn,
  cardInfo,
  guardian,
  sex,
  pronouns,
  isDeceased,
  primaryLanguage,
  isInterestedInFutureResearch,
  isSmsOptIn,
  heightFt,
  heightIn,
  weight,
  races,
  ethnicities,
  interestedInFutureResearch,
  smsOptIn,
  source,
  digitalPaymentAllowed
}) {
  return {
    firstName,
    lastName,
    middleName,
    preferredName,
    dob,
    medicalRecordNumber: medicalRecordNumber || '',
    phonesInfo: {
      alternative: phonesInfo.alternative,
      alternativePhoneType: phonesInfo.alternativePhoneType,
      primary: phonesInfo.primary,
      primaryPhoneType: phonesInfo.primaryPhoneType,
      preferredContactMethod: phonesInfo.preferredContactMethod,
      neverCallAgain: phonesInfo.neverCallAgain,
      voicemailNotAllowed: phonesInfo.voicemailNotAllowed
    },
    email,
    address: {
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      city: address.city,
      country: address.country,
      state: address.state,
      zipCode: address.zipCode
    },
    ssn,
    cardId: cardInfo?.id,
    paymentType: cardInfo?.paymentType,
    guardianFirstName: guardian?.firstName,
    guardianLastName: guardian?.lastName,
    guardianEmail: guardian?.email,
    guardianPhone: guardian?.phone,
    sex,
    pronouns,
    isDeceased,
    primaryLanguage,
    isInterestedInFutureResearch,
    isSmsOptIn,
    heightFt,
    heightIn,
    weight,
    races,
    ethnicities,
    interestedInFutureResearch,
    smsOptIn,
    source,
    digitalPaymentAllowed
  };
}

export function validDob(current) {
  return current.isBefore(moment());
}

export function convertDobToDate(info) {
  // Default to original value if original conditional returns false.
  // This can occur if info.dob has already been converted to a formatted string
  // and the user submits and add patient form more than once due to previous errors
  // and corrections within the form. Would prefer this to be a pure function, but this
  // default value should alleviate the problem.
  info.dob = (info.dob && isFunction(info.dob.format) && info.dob.format('DD/MMM/YYYY')) || info.dob;
}

export function preparePatientInfoForAssign(info, currentStudy) {
  const patientInfo = cloneDeep(info);

  const studyIdentifier = currentStudy.studyId;
  const studySiteIdentifier = currentStudy.ssuId;
  const patientSourceId = currentStudy.patientSourceId;
  const statusName = 'Identified';
  const email = patientInfo.email || '';

  const patient = {
    ...patientInfo,
    patientUniqueIdentifier: info.uniqueIdentifier,
    studyIdentifier,
    studySiteIdentifier,
    patientSourceId,
    statusName,
    email,
    statusChangeLocation: 'PATIENT_INFO'
  };

  delete patient.patientId;

  return patient;
}
