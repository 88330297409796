import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import Input from '../../../../../common/data-entry/Input';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup/ButtonGroup';

import './CancelEncounterModal.scss';

const ENCOUNTER_EVENT_START_DATE_FORMAT = 'DD/MMM/YYYY, hh:mm A';
function CancelEncounterModal({ modalBox, onConfirm, encounterName, appointments, currentStudy }) {
  const [input, setInput] = useState('');
  const [validationError, setValidationError] = useState(false);

  const handleInputChange = e => {
    let text = e.target.value;
    setInput(text);
    setValidationError(false);
  };

  const closeEncounter = e => {
    if (!input) {
      e.preventDefault();
      setValidationError(true);
      return;
    }
    onConfirm(input);
  };

  return (
    <>
      <ModalBoxes.Header>Cancel Encounter?</ModalBoxes.Header>
      <ModalBoxes.Body>
        <div className="mt-4">
          <h5>Encounter</h5>
          <p>{encounterName}</p>
        </div>
        <div className="my-4 pt-2">
          <h5>Appointments</h5>
          {appointments.length ? (
            appointments.map((appointment, index) => {
              const start = moment(appointment.start);
              return <p key={index}>{getEventStartDate(start)}</p>;
            })
          ) : (
            <p>None</p>
          )}
        </div>
      </ModalBoxes.Body>
      <div className="cancel-comment-div">
        <Input
          label="Reason"
          onChange={handleInputChange}
          value={input}
          required={true}
          valid={!validationError}
          validationMessage={validationError ? 'Comment is required' : null}
        />
      </div>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            NO
          </Button>
          <Button onClick={closeEncounter}>YES</Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );

  function getEventStartDate(date) {
    if (currentStudy?.timeZoneValid) {
      return `${moment(date)
        .tz(currentStudy.timeZone)
        .format(ENCOUNTER_EVENT_START_DATE_FORMAT)} ${getTimeZoneAbbr(currentStudy.timeZone)}`;
    }
    return `${moment(date).format(ENCOUNTER_EVENT_START_DATE_FORMAT)}`;
  }

  function getTimeZoneAbbr(timeZone) {
    let [, ...tz] = new Date().toLocaleTimeString('en', { timeZone, timeZoneName: 'long', hour12: false }).split(' ');
    if (tz.includes('Daylight') || tz.includes('Standard')) {
      return tz[0];
    }
    return moment()
      .tz(timeZone)
      .zoneAbbr();
  }
}

CancelEncounterModal.className = 'cancel-encounter-modal';
CancelEncounterModal.size = 'w650';

export default CancelEncounterModal;

CancelEncounterModal.defaultProps = {
  appointments: []
};

CancelEncounterModal.propTypes = {
  modalBox: PropTypes.shape({
    id: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired
  }),
  onConfirm: PropTypes.func.isRequired,
  appointments: PropTypes.array
};
