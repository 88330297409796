import React, { useEffect, useState } from 'react';
import { get } from 'lodash/object';

import { UserNotificationPreferencesConfigApi } from '../../../api';
import Toggle from '../../../common/data-entry/Toggle';
import { NOTIFICATION_PREFERENCES } from '../../../constants/accessToPages';
import { withNotificationsContext } from '../../Notifications/NotificationsContext';

import { ALL_IN_APP_NOTIFICATIONS, TASK } from './constants';
import PreferenceTemplate from './PreferenceTemplate';

function NotificationsPreferences({ appInfo, currentUser, onPreferencesChange }) {
  const [preferences, setPreferences] = useState([]);
  const [allInAppNotificationPreference, setAllInAppNotificationPreference] = useState(null);
  const userNotificationsEnabled =
    get(appInfo, 'features.userNotificationsEnabled', false) &&
    currentUser.accessToPages.includes(NOTIFICATION_PREFERENCES);

  useEffect(() => {
    userNotificationsEnabled &&
      UserNotificationPreferencesConfigApi.getAllUserNotificationPreferences(currentUser.personnelIdentifier).then(
        res => {
          const preferences = res.data.userNotificationPreferences;
          const sortedPreferences = preferences.sort(sortByConfigOder);
          setPreferences(sortedPreferences);
          setAllInAppNotificationPreference(
            preferences.find(e => e.eventNotificationName === ALL_IN_APP_NOTIFICATIONS)
          );
        }
      );
  }, [currentUser, userNotificationsEnabled]);

  const onChange = (item, enabled) => {
    UserNotificationPreferencesConfigApi.changeSingleUserNotificationConfigState(currentUser.personnelIdentifier, {
      name: item.eventNotificationName,
      enabled
    }).then(res => {
      let pref = preferences.find(
        p => p.eventNotificationName === res.data.notificationPreferenceTemplate.eventNotificationName
      );
      pref.enabled = res.data.enabled;
      setPreferences([...preferences]);
      onPreferencesChange();
    });
  };

  return (
    <>
      {userNotificationsEnabled && (
        <section className="px-3 pb-3 pt-2 mt-3">
          <div className="header-section">
            <h5>Notifications</h5>
            <Toggle
              name={allInAppNotificationPreference?.name}
              checked={allInAppNotificationPreference?.enabled}
              onChange={e => {
                allInAppNotificationPreference.enabled = e.target.checked;
                onChange(allInAppNotificationPreference, e.target.checked);
              }}
            />
          </div>
          <div className="section-label mt-3 mb-2 pl-3">Tasks</div>
          {preferences &&
            preferences
              .filter(e => get(e, 'section') === TASK)
              .filter(e => get(e, 'eventNotificationName') !== ALL_IN_APP_NOTIFICATIONS)
              .map(item => (
                <PreferenceTemplate
                  className="ml-3"
                  item={item}
                  name={item.name}
                  description={item.configDescription}
                  checked={item.enabled}
                  onChange={onChange}
                  key={item.name}
                  disabled={!allInAppNotificationPreference?.enabled}
                />
              ))}
        </section>
      )}
    </>
  );
}

function sortByConfigOder(a, b) {
  return a.configOrder - b.configOrder;
}

export default withNotificationsContext(NotificationsPreferences);
