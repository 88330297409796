import { CALENDAR } from '../../../constants/accessToPages';

import CalendarPage from './CalendarPage';

export const calendarRouterConfig = {
  name: 'Calendar',
  path: '/calendar',
  component: CalendarPage,
  access: [CALENDAR]
};
