import React, { useCallback, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Pagination } from '@mui/material';

import Loader from '../../../../../../common/elements/Loader/Loader';

import './PDFViewer.scss';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export const PDFViewer = ({ file }) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  }, []);

  return (
    <>
      <Pagination
        showFirstButton
        showLastButton
        size="small"
        sx={{
          margin: '17px 0 15px 0',
          '.MuiButtonBase-root': {
            outline: 'none'
          }
        }}
        count={numPages}
        page={pageNumber}
        onChange={(event, num) => setPageNumber(num)}
      />
      <div className="pdv-viewer">
        {file && (
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<Loader dark={true} />}
            noData={<Loader dark={true} />}
          >
            <Page
              key={`page_${pageNumber}`}
              pageNumber={pageNumber}
              height={766}
              loading={<Loader dark={true} />}
              error={<Loader dark={true} />}
            />
          </Document>
        )}
      </div>
    </>
  );
};
