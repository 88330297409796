import axios from 'axios';

const ROOT = 'api/v1/ssu-patients';

const ItemGroupApi = {
  setItemGroupNotPerformedStatus(request) {
    const { ssuPatientId, ssuPatientEncounterId, itemGroupId, reason, informedConsentFormId } = request;
    const notPerformedReason = {
      reason: reason,
      informedConsentFormId: informedConsentFormId
    };

    return axios.put(
      `${ROOT}/${ssuPatientId}/encounters/${ssuPatientEncounterId}/item-groups/${itemGroupId}/not-performed`,
      notPerformedReason
    );
  },
  setItemGroupPerformedStatus(request) {
    const { ssuPatientId, ssuPatientEncounterId, itemGroupId } = request;

    return axios.put(
      `${ROOT}/${ssuPatientId}/encounters/${ssuPatientEncounterId}/item-groups/${itemGroupId}/performed`
    );
  }
};

export default ItemGroupApi;
