import axios from 'axios';

const ROOT = 'api/v1/outreach-callback';

const OutreachCallbackApi = {
  getCallback(ssuPatientId) {
    return axios.get(`${ROOT}/${ssuPatientId}`, { hideLoader: true });
  },
  saveCallback(ssuPatientId, callback) {
    return axios.put(`${ROOT}/${ssuPatientId}/save`, callback);
  }
};

export default OutreachCallbackApi;
