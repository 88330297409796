import React, { useEffect, useState } from 'react';
import { isEmpty, isString } from 'lodash/lang';

import Input from '../../../../../../../common/data-entry/Input';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';

import './AddOrEditLogCheckOptionModal.scss';

export default function AddOrEditLogCheckOptionModal({
  modalBox,
  onSave: onSaveCallback,
  logCheckOption,
  validateOption
}) {
  const [optionText, setOptionText] = useState('');
  const [textChanged, setTextChanged] = useState(false);
  const isExistedLogCheckOption = isString(logCheckOption?.value);

  useEffect(() => {
    if (!logCheckOption) {
      return;
    }
    const { value } = logCheckOption;
    setOptionText(value);
  }, [logCheckOption]);

  const onTextChange = e => {
    !textChanged && setTextChanged(true);
    setOptionText(e.target.value);
  };

  const isInputValid = () => {
    return isString(optionText) && !isEmpty(optionText.trim()) && validateOption(optionText);
  };

  const onSave = () => {
    if (isExistedLogCheckOption) {
      logCheckOption.value = optionText;
      onSaveCallback(logCheckOption);
    } else {
      onSaveCallback(optionText);
    }
  };

  return (
    <>
      <ModalBoxes.Body>
        <Input
          value={optionText}
          onChange={onTextChange}
          label="SM option"
          validationMessage={!validateOption(optionText) && textChanged ? 'Item with such name exists.' : ''}
        />
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            Cancel
          </Button>
          <Button disabled={!isInputValid()} onClick={onSave}>
            SAVE
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
}

AddOrEditLogCheckOptionModal.className = 'add-or-edit-log-check-option-modal';
AddOrEditLogCheckOptionModal.size = 'w650';
