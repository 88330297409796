import React, { useMemo } from 'react';
import { generatePath, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash/lang';
import { stringify } from 'query-string';

import { preparedRouterConfigMap } from './config';
import { getCurrentRouteByLocation } from './service';

export function useCurrentRoute() {
  const location = useLocation();
  return useMemo(() => getCurrentRouteByLocation(location), [location]);
}

export function withCurrentRoute(Component) {
  function WrapperComponent(props) {
    return <Component {...props} currentRoute={getCurrentRouteByLocation(useLocation())} />;
  }

  return WrapperComponent;
}

export function generateUrlByKey(key, params, searchQuery) {
  const path = preparedRouterConfigMap?.[key]?.path;
  if (!path) {
    throw new Error(`Router path not found by '${key}' key!`);
  }
  const searchQueryString = isEmpty(searchQuery) ? '' : '?' + stringify(searchQuery);
  return generatePath(path, params) + searchQueryString;
}
