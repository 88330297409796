import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash/lang';

import { ProtocolItemGroupTemplatesApi } from '../../../../../../../api';
import Input from '../../../../../../../common/data-entry/Input';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import { ITEM_GROUP_COPIED } from '../../../../../../../constants/notificationMessages';
import { onRequestError } from '../../../../../../../services/handlers';
import { generateUrlByKey } from '../../../../../../root/router';
import { LOGS, TABLE } from '../../../../shared/ElementSetupNew/itemGroupsConstants/itemGroupTypes';
import { RenderForm } from '../../../../shared/ElementSetupNew/ItemGroupSetup/RenderForm';
import TableItemGroupPreview from '../../../../shared/ElementSetupNew/TableSetup/TablePreview/TablePleviewModal/TableItemGroupPreview';
import { TABLE_SETUP } from '../../../../shared/ElementSetupNew/TableSetup/TableSetupConstants';

import './CopyItemGroupModal.scss';

export default function CopyItemGroupModal({ currentRoute, protocolIdentity, modalBox, previewFormData, inputs }) {
  const navigate = useNavigate();
  const [itemGroupName, setItemGroupName] = useState(previewFormData.elementName);

  const onItemGroupNameChange = e => {
    setItemGroupName(e.target.value);
  };

  const navigateToCopiedItemGroupSetup = res => {
    const params = {
      studyId: previewFormData.studyIdentifier,
      protocolIdentity,
      element_identifier: res.data.protocolItemGroupTemplateUniqueIdentifier
    };
    if (previewFormData.elementIcon === TABLE_SETUP) {
      navigate(generateUrlByKey(`${currentRoute.key}.Table Setup.Table Edit`, params));
    } else {
      navigate(generateUrlByKey(`${currentRoute.key}.Item Group Setup.Item Group Edit`, params));
    }
  };

  const copyItemGroup = () => {
    const request = {
      destinationItemGroupName: itemGroupName?.trim(),
      sourceProtocolItemGroupTemplateId: previewFormData.uniqueIdentifier
    };
    if (previewFormData.domainCode === 'ICF') {
      request.informedConsentTemplate = { ...previewFormData.informedConsentTemplate, protocolId: protocolIdentity };
    }
    ProtocolItemGroupTemplatesApi.copyProtocolItemGroupTemplate(previewFormData.studyIdentifier, request)
      .then(res => {
        NotificationManager.success(ITEM_GROUP_COPIED);
        modalBox.close();
        navigateToCopiedItemGroupSetup(res);
      })
      .catch(onRequestError);
  };

  return (
    <>
      <ModalBoxes.Header>Copy Item Group</ModalBoxes.Header>
      <ModalBoxes.Body>
        <div className="d-flex">
          <Input
            required={true}
            label="Name"
            name="itemGroupName"
            value={itemGroupName}
            onChange={onItemGroupNameChange}
          />
        </div>
        {previewFormData.elementIcon === TABLE_SETUP ? (
          <TableItemGroupPreview
            encounterTableItemGroupForPreview={previewFormData.encounterTableItemGroupForPreview}
            conditionalLogicAndRequiredFieldsValidation={previewFormData.conditionalLogicAndRequiredFieldsValidation}
          />
        ) : (
          <div className="preview-layout">
            <RenderForm
              formData={previewFormData}
              displayTableType={previewFormData.elementIcon === TABLE}
              elementIcon={previewFormData.elementIcon}
              inputs={inputs}
              isLogCheckSetupEnabled={previewFormData.elementIcon === LOGS}
            />
          </div>
        )}
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            CANCEL
          </Button>
          <Button onClick={copyItemGroup} disabled={isEmpty(itemGroupName?.trim())}>
            SAVE
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
}

CopyItemGroupModal.className = 'copy-item-group-template-modal';
