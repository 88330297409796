import React, { Component } from 'react';
import ReactTable from 'react-table';
import { isEqual } from 'lodash/lang';

import { StudySiteApi, StudySiteStartupApi } from '../../../../api';
import Select from '../../../../common/data-entry/Select';
import Button from '../../../../common/general/Button';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import { EXPORT_FAILED } from '../../../../constants/notificationMessages';
import { pendoTrackDefaultSortingChange } from '../../../../pendo/PendoUtils';
import { onFileSave } from '../../../../services/handlers';
import { PageInfoHeader } from '../../../PageInfoHeader/PageInfoHeader';
import { SSUFilter } from '../../../SSUFilter/SSUFilter';
const CYCLE_TIME_REPORT_TABLE_DATA = 'CYCLE_TIME_REPORT_TABLE_DATA';
class CycleTimeReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allTherapeuticAreas: [],
      selectedTherapeuticArea: null,
      therapeuticAreaMap: [],
      allSSUs: [],
      filteredAreasSSUs: [],
      visibleAreas: [],
      filteredSSUs: [],
      allCycleTimes: [],
      filteredCycleTimes: [],
      visibleSSUs: [],
      pageSize: sessionStorage.getItem(CYCLE_TIME_REPORT_TABLE_DATA)
        ? JSON.parse(sessionStorage.getItem(CYCLE_TIME_REPORT_TABLE_DATA))?.pageSize
        : 25 //oneOf([25, 50, 100])
    };
    this.columns = [
      {
        Header: 'Study Name',
        accessor: 'studyName'
      },
      {
        Header: 'Site Name',
        accessor: 'siteName'
      },
      {
        Header: 'PSV to Reg Received',
        accessor: 'psvToRegReceive',
        sortMethod: this.filterMethodForNumbers
      },
      {
        Header: 'Reg Received to Reg Submit',
        accessor: 'regReceiveToRegSubmit',
        sortMethod: this.filterMethodForNumbers
      },
      {
        Header: 'Reg Submit to IRB',
        accessor: 'regSubmitToIrb',
        sortMethod: this.filterMethodForNumbers
      },
      {
        Header: 'IRB to SIV',
        accessor: 'irbToSiv',
        sortMethod: this.filterMethodForNumbers
      },
      {
        Header: 'SIV To Green Light',
        accessor: 'sivToGreenLight',
        sortMethod: this.filterMethodForNumbers
      },
      {
        Header: 'Green Light to FPI',
        accessor: 'greenLightToFpi',
        sortMethod: this.filterMethodForNumbers
      },
      {
        Header: 'FPI To First Visit',
        accessor: 'fpiToFirstVisit',
        sortMethod: this.filterMethodForNumbers
      },
      {
        Header: 'First Visit To First Randomized',
        accessor: 'firstVisitToFirstRandomized',
        sortMethod: this.filterMethodForNumbers
      },
      {
        Header: 'Green Light to Final Enrolled',
        accessor: 'greenLightToFinalEnrolled',
        sortMethod: this.filterMethodForNumbers
      }
    ];
  }

  componentDidMount() {
    StudySiteApi.getAllStudySitesAndMap().then(({ data = [] }) => {
      const therapeuticAreaMap = {};
      let allAreas = [];
      const hasOwn = Object.prototype.hasOwnProperty; // es-lint & airbnb recommended approach for using hasOwnProperty
      data.forEach(x => {
        const area = x.study.therapeuticArea ? x.study.therapeuticArea : 'Unassigned';
        const ssu = x.uniqueIdentifier;
        if (!hasOwn.call(therapeuticAreaMap, area)) {
          therapeuticAreaMap[area] = [ssu];
        } else {
          therapeuticAreaMap[area].push(ssu);
        }
        if (!allAreas.some(a => a.name === area)) {
          if (area !== 'Unassigned') {
            allAreas.push({ name: area });
          }
        }
      });
      allAreas
        .sort((x, y) => {
          if (x.name.toLowerCase() < y.name.toLowerCase()) {
            return -1;
          }
          if (x.name.toLowerCase() > y.name.toLowerCase()) {
            return 1;
          }
          return 0;
        })
        .unshift({ name: 'Unassigned' });
      allAreas = allAreas.map((el, index) => {
        const area = { ...el }; // shallow copy & modify instead of pass by reference & modify
        area.id = index + 1;
        return area;
      });
      this.setState({
        therapeuticAreaMap,
        allTherapeuticAreas: allAreas,
        visibleAreas: allAreas,
        allSSUs: data.map(ssu => ssu.uniqueIdentifier)
      });
    });
    StudySiteStartupApi.getCycleTimesForAllAvailableSsus().then(({ data = [] }) => {
      this.setState({
        allCycleTimes: data,
        filteredCycleTimes: data
      });
    });
  }

  handleSSUFilterChange = selectedSSUs => {
    const { allTherapeuticAreas } = this.state;
    const filteredSSUs = selectedSSUs.map(ssu => ssu.uniqueIdentifier);
    const allAreas = selectedSSUs.map(ssu =>
      ssu.study.therapeuticArea === '' ? 'Unassigned' : ssu.study.therapeuticArea
    );
    const filteredAreas = allTherapeuticAreas.filter(area => allAreas.includes(area.name));
    this.setState({ filteredSSUs, visibleAreas: filteredAreas }, () => {
      this.filterData();
    });
  };

  handleAreaChange = area => {
    const { therapeuticAreaMap, selectedTherapeuticArea } = this.state;
    if (!isEqual(area, selectedTherapeuticArea)) {
      if (area) {
        const filteredAreasSSUs = therapeuticAreaMap[area.name];
        this.setState({ selectedTherapeuticArea: area, filteredAreasSSUs }, () => {
          this.filterData();
        });
      } else {
        this.setState({ selectedTherapeuticArea: null, filteredAreasSSUs: [] }, () => {
          this.filterData();
        });
      }
    }
  };

  filterData = () => {
    const { allSSUs, filteredSSUs, filteredAreasSSUs } = this.state;
    let finalSSUs = allSSUs;
    if (filteredSSUs.length > 0) {
      finalSSUs = filteredSSUs;
    }
    if (filteredAreasSSUs.length > 0) {
      finalSSUs = finalSSUs.filter(el => filteredAreasSSUs.includes(el));
    }
    this.setState(prevState => {
      return {
        filteredCycleTimes: prevState.allCycleTimes.filter(el => finalSSUs.includes(el.ssuId)),
        visibleSSUs: finalSSUs
      };
    });
  };

  downloadCSV = () => {
    const { visibleSSUs } = this.state;
    StudySiteStartupApi.exportCycleTimes(visibleSSUs)
      .then(onFileSave)
      .catch(error => {
        NotificationManager.error(EXPORT_FAILED);
        console.error(error);
      });
  };

  filterMethodForNumbers = (a, b) => {
    a = a === '' ? -9999999 : a;
    b = b === '' ? -9999999 : b;
    if (+a < +b) {
      return -1;
    }
    if (+a > +b) {
      return 1;
    }
    return 0;
  };

  render() {
    const { visibleAreas, filteredCycleTimes, selectedTherapeuticArea } = this.state;
    return (
      <div className="footpadb">
        <PageInfoHeader
          right={
            <Button priority="medium" size="h28" type="button" onClick={this.downloadCSV}>
              Export
            </Button>
          }
        >
          <div className="general-header-group-container general-header-wrapper">
            <SSUFilter handleSSUFilterChange={this.handleSSUFilterChange} />
            <Select
              label="Therapeutic Area"
              clearSearchOnSelection
              optionLabelKey="name"
              optionValueKey="id"
              dataSource={visibleAreas}
              onChange={this.handleAreaChange}
              searchable
              validate={false}
              value={selectedTherapeuticArea}
            />
          </div>
        </PageInfoHeader>
        <section>
          <div className="row border p-3 m-0 mt-2">
            <div className="col-12 border px-0">
              <ReactTable
                data={filteredCycleTimes}
                columns={this.columns}
                minRows={1}
                onPageSizeChange={pageSize => {
                  this.setState({ pageSize: pageSize });
                  sessionStorage.setItem(CYCLE_TIME_REPORT_TABLE_DATA, JSON.stringify({ pageSize: pageSize }));
                }}
                multiSort
                onSortedChange={pendoTrackDefaultSortingChange}
                noDataText="No Record Found"
                className="table table-responsive-sm activity-table mb-0"
                showPagination
                nextText=">>"
                previousText="<<"
                pageSizeOptions={[25, 50, 100]}
                defaultPageSize={this.state.pageSize}
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default CycleTimeReport;
