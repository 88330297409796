import axios from 'axios';

const ROOT = 'api/v1/finance/invoice';

const FinInvoiceApi = {
  getInvoiceFile(invoiceFieldId) {
    return axios.get(`${ROOT}/file/${invoiceFieldId}`);
  },
  sendInvoice(data) {
    return axios.post(`${ROOT}/send`, data);
  },
  saveInvoiceLog(invoiceNumber, emailId, type, invoiceType) {
    return axios.post(`${ROOT}/log/save`, {
      invoiceNumber,
      emailId,
      type,
      invoiceType
    });
  },
  getInvoiceHistory(invoiceId) {
    return axios.get(`${ROOT}/history/${invoiceId}`);
  },
  getAttachedFile(type, attachmentId, hideLoader = true) {
    return axios({
      url: `${ROOT}/type/${type}/attachment/${attachmentId}/download`,
      method: 'GET',
      responseType: 'blob',
      hideLoader
    });
  },
  getInvoiceEmail(emailId) {
    return axios.get(`${ROOT}/email/${emailId}`);
  },
  getDataForCreateInvoice(ledgerEventIds) {
    return axios.post(`${ROOT}/events`, ledgerEventIds);
  },
  getAllInvoicesByFilter(filterProperty) {
    return axios.post(`${ROOT}/search/filter`, filterProperty);
  },
  getSelectedLedgerEventValidation(ledgerEvents) {
    return axios.post(`${ROOT}/validation`, ledgerEvents);
  },
  updateInvoiceFile(file) {
    return axios.put(`${ROOT}/file`, file);
  },
  uploadAttachmentForExpense(attachmentId, hideLoader = true) {
    return axios({
      url: `${ROOT}/events/expenses/attachments/${attachmentId}`,
      method: 'GET',
      responseType: 'blob',
      hideLoader
    });
  },
  uploadAttachmentForReimbursement(attachmentId, hideLoader = true) {
    return axios({
      url: `${ROOT}/events/reimbursement/attachments/${attachmentId}`,
      method: 'GET',
      responseType: 'blob',
      hideLoader
    });
  },
  getExpenseAttachmentIdsForInvoice(ledgerEventIds) {
    return axios.get(`${ROOT}/events/expenses/attachments?ledgerEventIds=${ledgerEventIds}`);
  },
  exportNS(filterProperty) {
    let { startDate, endDate } = filterProperty;
    return axios.post(
      `${ROOT}/export/net-suit`,
      { ...filterProperty, startDate, endDate },
      {
        responseType: 'arraybuffer'
      }
    );
  },
  exportInvoices(filterProperty) {
    return axios.post(`${ROOT}/export`, filterProperty, {
      responseType: 'arraybuffer'
    });
  }
};

export default FinInvoiceApi;
