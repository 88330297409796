import { SOMETHING_WENT_WRONG } from 'constants/notificationMessages';

import { PAYMENT_TYPE_PHYSICAL } from 'components/pages/patient-source/Patients/constants';
import handleExceptions from 'components/pages/patient-source/Patients/PatientInfo/PatientInfoMainSection/AddOrEditPatientInfoModal/services/handleExceptions';
import showAddPatientStudyModal from 'components/pages/patient-source/Patients/PatientInfo/PatientInfoMainSection/AddOrEditPatientInfoModal/services/showAddPatientStudyModal';

import { StudyApi } from '../../../../../../../../api';
import NotificationManager from '../../../../../../../../common/notifications/NotificationManager';
import { convertDobToDate } from '../AddOrEditPatientInfoModalService';

import { validateAddPatientInfo } from './validationService/validationService';
import prepareAddPatientInfo from './prepareAddPatientInfo';
import showDuplicateEmailModal from './showDuplicateEmailModal';

export default async function initiateAddPatient({
  info,
  ssu,
  medicalRecordNumber,
  setValidationMessages,
  setInfo,
  setLoading,
  postSave
}) {
  try {
    await validateAddPatientInfo(info, ssu);

    const { cardId, paymentType } = info;

    if (cardId && !paymentType) {
      info.paymentType = PAYMENT_TYPE_PHYSICAL;
    }

    convertDobToDate(info);
    setLoading(true);

    const patientInfoRequest = prepareAddPatientInfo(info, ssu, medicalRecordNumber);

    StudyApi.addPatient(patientInfoRequest)
      .then(({ data }) => {
        if (data.responsecode === 432) {
          patientInfoRequest.patientUniqueIdentifier = data.response.patientIdentifier;
          const studySites = data.response.studySites;
          showAddPatientStudyModal(
            patientInfoRequest,
            ssu.study.studyName,
            ssu.site.site.siteName,
            studySites,
            data.response
          ).then(
            function() {
              postSave();
            },
            () => {}
          );
          setLoading(false);
        } else if (data.responsecode === 409) {
          data.response && showDuplicateEmailModal(data.response);
          setLoading(false);
        } else if (data.responsecode === 430) {
          data.response && NotificationManager.warning(data.response);
          setLoading(false);
        } else {
          data.response && NotificationManager.success(data.response);
          postSave();
          return data;
        }
      })
      .catch(error => {
        const errorMessage = error.response.data.message;
        if (errorMessage.includes('PatientPaymentException')) {
          NotificationManager.warning(
            `Patient created successfully. But${errorMessage.substring(errorMessage.indexOf(':') + 1)}`
          );
          postSave();
        } else if (errorMessage.includes('IllegalStateException')) {
          NotificationManager.error(errorMessage.substring(errorMessage.indexOf(':') + 1));
          setLoading(false);
        } else {
          NotificationManager.error(SOMETHING_WENT_WRONG);
          setLoading(false);
        }
      });
  } catch (e) {
    handleExceptions(e, info, setValidationMessages, setInfo);
  }
}
