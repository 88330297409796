import axios from 'axios';

const ROOT = 'api/v1/finance/revenue/ledger';

const FinLedgerRevenueDateApi = {
  getRevenueDateHistory(eventItemId) {
    return axios.get(`${ROOT}/${eventItemId}/revenue-date/history`);
  },
  changeRevenueDate(eventItemId, data) {
    return axios.post(`${ROOT}/${eventItemId}/revenue-date/change`, data);
  }
};

export default FinLedgerRevenueDateApi;
