import React, { useContext, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Stack,
  Unstable_Grid2 as Grid
} from '@mui/material';

import PatientInfoPageApi from '../../../../../../../../api/patient/PatientInfoPageApi';
import NotificationManager from '../../../../../../../../common/notifications/NotificationManager';
import { PatientInfoContext } from '../../../PatientInfoContext';
import { validateEmail } from '../../../patientInfoService';

export default function NotificationsTab() {
  const { patientInfo, setPatientInfo } = useContext(PatientInfoContext);

  const [currentReimbursementEmailNotificationsState, setCurrentReimbursementEmailNotificationsState] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const reimbursementEmailNotifications = patientInfo?.reimbursementEmailNotifications;

  useEffect(() => {
    setCurrentReimbursementEmailNotificationsState(reimbursementEmailNotifications);
  }, [reimbursementEmailNotifications]);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid xs={6}>
        <FormControl error={showErrorMessage} required component="fieldset" sx={{ m: 1 }} variant="standard">
          <FormControlLabel
            value="top"
            control={
              <Checkbox
                checked={currentReimbursementEmailNotificationsState}
                onChange={({ target }) => setCurrentReimbursementEmailNotificationsState(target.checked)}
              />
            }
            label="Reimbursement Email Notification"
          />
          {showErrorMessage && (
            <FormHelperText>Email can’t be empty when reimbursement notifications are enabled</FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid xs={6}>
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          <IconButton
            aria-label="save"
            disabled={currentReimbursementEmailNotificationsState === reimbursementEmailNotifications}
            color="primary"
            onClick={() => {
              if (
                patientInfo &&
                patientInfo.email &&
                patientInfo.email.length !== 0 &&
                validateEmail(patientInfo.email)
              ) {
                setShowErrorMessage(false);
                onSave();
              } else {
                setShowErrorMessage(true);
              }
            }}
            title="Save"
          >
            <SaveIcon />
          </IconButton>
          <IconButton
            aria-label="discard"
            disabled={currentReimbursementEmailNotificationsState === reimbursementEmailNotifications}
            color="primary"
            onClick={() => {
              setShowErrorMessage(false);
              setCurrentReimbursementEmailNotificationsState(reimbursementEmailNotifications);
            }}
            title="Discard changes"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </Grid>
    </Grid>
  );

  function onSave() {
    PatientInfoPageApi.updatePatientReimbursementEmailNotifications(
      patientInfo.id,
      currentReimbursementEmailNotificationsState
    )
      .then(() => {
        setPatientInfo(state => {
          return { ...state, reimbursementEmailNotifications: currentReimbursementEmailNotificationsState };
        });
      })
      .catch(() => {
        NotificationManager.error('Unable to save Reimbursement notifications');
      });
  }
}
