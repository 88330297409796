import React, { useEffect, useRef } from 'react';
import ReactTable from 'react-table';
import cx from 'classnames';
import { isFunction } from 'lodash/lang';
import * as PropTypes from 'prop-types';

import { pendoTrackDefaultSortingChange } from '../../../../../pendo/PendoUtils';

import { scrollForFinanceTableInAnyPartOfPage } from './financeTableScrollService';

import './FinanceTable.scss';

export function FinanceTable(props) {
  const { className, setRef, setTableRef, scrollInAnyPart, ...restProps } = props;
  const ref = useRef(null);
  const tableRef = useRef(null);
  useEffect(
    function() {
      if (setRef && isFunction(setRef)) {
        setRef(ref);
      }
      if (setTableRef && isFunction(setTableRef)) {
        setTableRef(tableRef);
      }
    },
    [ref, setRef, setTableRef]
  );
  useEffect(
    function() {
      if (scrollInAnyPart) {
        scrollForFinanceTableInAnyPartOfPage(ref?.current?.children[0]?.children[0]?.children[1]);
      }
    },
    [ref, scrollInAnyPart]
  );

  return (
    <div className="eds-finance-table" ref={ref}>
      <ReactTable
        ref={tableRef}
        className={cx(className)}
        {...restProps}
        onSortedChange={pendoTrackDefaultSortingChange}
      />
    </div>
  );
}

FinanceTable.defaultProps = {
  scrollInAnyPart: false
};

FinanceTable.propTypes = {
  className: PropTypes.string,
  scrollInAnyPart: PropTypes.bool,
  setRef: PropTypes.func
};
