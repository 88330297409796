import React from 'react';
import moment from 'moment';

import { dateFormat } from '../../taskConstants';

export default function TaskDetailsUpdates({ taskForm }) {
  const { lastUpdateDate, updatedByName, creationDate, createdByName } = taskForm;
  const isEdited = taskForm && moment(lastUpdateDate).diff(moment(creationDate), 'milliseconds') > 500;
  return (
    <div className="etc-body-details">
      {isEdited && (
        <div className="etc-body-details-block">
          <div className="etc-body-details-block-label">Updated {formatDate(lastUpdateDate)}</div>
          <div className="etc-body-details-block-value">{updatedByName}</div>
        </div>
      )}

      <div className="etc-body-details-block">
        <div className="etc-body-details-block-label">Created on {formatDate(creationDate)}</div>
        <div className="etc-body-details-block-value">{createdByName}</div>
      </div>
    </div>
  );
}

function formatDate(str) {
  return moment(str).format(dateFormat);
}
