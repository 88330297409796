import React from 'react';

import NetRevenue from './NetRevenue/NetRevenue';
import { RevenueTable } from './RevenueTable/RevenueTable';
import { RevenueProvider } from './RevenueContext';

import './Revenue.scss';

function Revenue() {
  return (
    <RevenueProvider>
      <div className="eds-revenue">
        <NetRevenue />
        <RevenueTable />
      </div>
    </RevenueProvider>
  );
}

export default Revenue;
