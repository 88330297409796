import React from 'react';
import { Chip } from '@mui/material';
import moment from 'moment';

import './NotesInfo.scss';

const NotesInfo = ({ notesInfo }) => {
  const prepareValue = (noteValue, referredLength = 40) => {
    if (noteValue.length > referredLength) {
      return noteValue.substring(0, referredLength) + '...';
    }
    return noteValue;
  };

  return (
    <div className="notes-chips">
      {(notesInfo?.patientFirstName || notesInfo?.patientLastName) && (
        <Chip
          size="small"
          label={`Patient Name: ${prepareValue(`${notesInfo.patientFirstName} ${notesInfo.patientLastName}`, 17)}`}
        />
      )}
      <Chip
        size="small"
        label={`DOB: ${
          notesInfo?.dateOfBirth ? prepareValue(getPatientBirthDateAndAge(notesInfo.dateOfBirth)) : 'N/A'
        }`}
      />
      <Chip size="small" label={`Subject ID: ${notesInfo?.subjectId ? prepareValue(notesInfo.subjectId, 7) : 'N/A'}`} />
      <Chip size="small" label={`Patient ID: ${notesInfo?.patientId ? prepareValue(notesInfo.patientId) : 'N/A'}`} />
      <Chip size="small" label={`Study: ${notesInfo?.studyName ? prepareValue(notesInfo.studyName) : 'N/A'}`} />
      <Chip size="small" label={`Site: ${notesInfo?.siteName ? prepareValue(notesInfo.siteName) : 'N/A'}`} />
    </div>
  );
};

export const getPatientBirthDateAndAge = date => {
  return `${moment(date).format('DD/MMM/YYYY')} (${moment().diff(date, 'years')} years)`;
};

export default NotesInfo;
