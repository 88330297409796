import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';

import Group from '../_Group';
import { extractRenderingContent } from '../inputSelectorsService';

import Checkbox from './Checkbox';

export default function CheckboxGroup(props) {
  const { children: allChildren, ...restProps } = props;

  const children = useMemo(
    function() {
      return extractRenderingContent(allChildren, child => child?.type?.isCheckbox);
    },
    [allChildren]
  );
  return (
    <Group groupProps={restProps} component={Checkbox}>
      {children}
    </Group>
  );
}

CheckboxGroup.propTypes = {
  options: PropTypes.array,
  checkedValues: PropTypes.oneOfType([PropTypes.array]),
  label: PropTypes.string,
  direction: PropTypes.string,
  optionValueKey: PropTypes.any,
  optionLabelKey: PropTypes.any,
  optionTitleKey: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string
};

CheckboxGroup.defaultProps = {
  optionValueKey: 'value',
  optionLabelKey: 'label',
  optionTitleKey: 'title',
  disabled: false,
  direction: 'row'
};
