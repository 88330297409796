import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty, isEqual } from 'lodash/lang';

import { updateStudyBudget } from '../../../../../actions/finance/budget/updateStudyBudgetAction';
import { FinBudgetApi, FinBudgetEventApi, FinTriggerApi, FinVendorApi } from '../../../../../api';
import Section from '../../../../../common/data-display/Section/Section';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { EXPORT_FAILED, PROTOCOL_SETUP_IS_INCOMPLETE } from '../../../../../constants/notificationMessages';
import { MANAGE_BUDGETS } from '../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../services/auth';
import { onBudgetFileSave, onRequestError } from '../../../../../services/handlers';
import { PageInfoHeader } from '../../../../PageInfoHeader/PageInfoHeader';
import { withParams } from '../../../../root/router/legacyComponentCompatability';
import CommentSection from '../../Protocol/ProtocolSetup/CommentSection/CommentSection';
import CommentSectionForPublishedProtocolAndBudget from '../../Protocol/ProtocolSetup/CommentSection/CommentSectionForPublishedProtocolAndBudget';

import { BudgetDetailsTableSection } from './BudgetDetailsTableSection/BudgetDetailsTableSection';

import './BudgetDetails.scss';

export class BudgetDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      encounterTriggers: [],
      itemGroupTriggers: [],
      budget: {
        study: {
          name: ''
        },
        finClient: {
          name: ''
        },
        version: '',
        description: '',
        overhead: '',
        withholding: '',
        managementFee: '',
        comment: ''
      },
      isEditingForbidden: null,
      currentStudyBudget: null,
      previousUpdated: {}
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { params } = this.props;
    const { currentStudyBudget } = this.state;
    const { id } = params;

    const isBudgetStatusCorrectOrAbsent =
      !currentStudyBudget || (currentStudyBudget && !isEqual(currentStudyBudget.uniqueIdentifier, id));

    if (!isBudgetStatusCorrectOrAbsent && isEqual(this.state.isEditingForbidden, null)) {
      this.setState({ isEditingForbidden: !currentStudyBudget.editPossibility });
    }
  }

  componentDidMount() {
    const { id } = this.props.params;
    FinBudgetApi.find(id).then(res => {
      const budget = res.data;
      this.setState({ budget: budget, previousUpdated: budget }, () => {
        this.loadBudgetEvents();
        this.preloadModalDetails();
      });
    }, onRequestError);

    FinBudgetApi.isEditable(id).then(({ data }) => {
      this.setState({
        currentStudyBudget: { editPossibility: data, uniqueIdentifier: this.props.params.id }
      });
    });
  }

  loadBudgetEvents = () => {
    FinBudgetEventApi.findByBudget(this.state.budget.id).then(res => {
      this.setState({ events: res.data });
    }, onRequestError);
  };

  preloadModalDetails = () => {
    FinTriggerApi.getEncounterTriggers(this.state.budget.studyId).then(({ data }) => {
      this.setState({ encounterTriggers: data });
    }, onRequestError);

    FinTriggerApi.getItemGroupTriggers(this.state.budget.studyId).then(({ data }) => {
      this.setState({ itemGroupTriggers: data });
      if (!userHasAccessTo(MANAGE_BUDGETS) && isEmpty(data)) {
        NotificationManager.error(PROTOCOL_SETUP_IS_INCOMPLETE);
      }
    }, onRequestError);

    FinVendorApi.findAllEnabled().then(({ data }) => {
      this.setState({ vendors: data });
    }, onRequestError);
  };

  onChangeTextVal = e => {
    const budget = { ...this.state.budget };
    budget[e.target.name] = e.target.value;

    this.setState({ budget });
  };

  onBlur = e => {
    if (this.allRequiredFieldsAreFilledCorrectly()) {
      if (this.state.previousUpdated[e.target.name] !== this.state.budget[e.target.name]) {
        this.setState(prevState => ({
          previousUpdated: { ...prevState.previousUpdated, [e.target.name]: prevState.budget[e.target.name] }
        }));
        this.updateStudyBudget();
      }
    }
  };

  onBlurManagementFee = e => {
    if (this.allRequiredFieldsAreFilledCorrectly()) {
      if (this.state.previousUpdated[e.target.name] !== this.state.budget[e.target.name]) {
        this.updateStudyBudget();
        this.setState(prevState => {
          return {
            ...prevState,
            previousUpdated: { ...prevState.previousUpdated, [e.target.name]: prevState.budget[e.target.name] },
            events: prevState.events.map(budgetEvent => {
              const managementFeePercent = e.target.value;
              const siteAmountWithManagementFee = this.calculateSiteAmountWithManagementFee(
                budgetEvent.clientAmount,
                managementFeePercent
              );
              return {
                ...budgetEvent,
                siteAmount: budgetEvent.managementFee ? siteAmountWithManagementFee : budgetEvent.siteAmount,
                elligoAmount: budgetEvent.managementFee
                  ? budgetEvent.clientAmount -
                    budgetEvent.patientAmount -
                    siteAmountWithManagementFee -
                    budgetEvent.vendorAmount
                  : budgetEvent.elligoAmount,
                managementFee: +managementFeePercent === 0 ? false : budgetEvent.managementFee
              };
            })
          };
        });
      }
    }
  };

  calculateSiteAmountWithManagementFee = (clientAmount, managementFeePercent) => {
    return +managementFeePercent === 0 ? 0 : +(clientAmount - (clientAmount / 100) * managementFeePercent).toFixed(2);
  };

  exportStudyBudget = () => {
    FinBudgetApi.export(this.state.budget.id)
      .then(onBudgetFileSave)
      .catch(err => onRequestError(err, { customMessage: EXPORT_FAILED }));
  };

  updateStudyBudget = () => {
    const comment = this.state.budget?.comment?.trim();
    this.setState(prevState => ({ ...prevState, budget: { ...prevState.budget, comment } }));
    const updateRequest = {
      id: this.state.budget.id,
      description: this.state.budget.description,
      overhead: this.state.budget.overhead,
      withholding: this.state.budget.withholding,
      managementFee: this.state.budget.managementFee,
      comment
    };

    if (this.state.budget.description.length >= 500) {
      NotificationManager.error('Budget name cannot exceed 500 characters');
      return;
    }
    this.props.updateStudyBudget(updateRequest);
  };

  allRequiredFieldsAreFilledCorrectly = () => {
    const { budget } = this.state;
    return (
      isNotEmpty(budget.description) &&
      String(budget.overhead).match(/^(\d{1,2}\.\d{1}|\d{1,2}|100.0|100)$/) &&
      String(budget.withholding).match(/^(\d{1,2}\.\d{1}|\d{1,2}|100.0|100)$/) &&
      String(budget.managementFee).match(/^(\d{1,2}\.\d{1}|\d{1,2}|100.0|100)$/)
    );
  };

  render() {
    const { budget, isEditingForbidden } = this.state;
    const protocolIncomplete = isEmpty(this.state.itemGroupTriggers) || isEmpty(this.state.encounterTriggers);
    return (
      <div className="budget-details-page">
        <PageInfoHeader
          objectRecordLabel={budget.description}
          pageInfo={
            <PageInfoHeader.CollapsibleList>
              <PageInfoHeader.AdditionalInfo tooltip="Study">{budget.study.name}</PageInfoHeader.AdditionalInfo>
              <PageInfoHeader.AdditionalInfo tooltip="Client">{budget.finClient.name}</PageInfoHeader.AdditionalInfo>
              <PageInfoHeader.AdditionalInfo tooltip="Version">{budget.version}</PageInfoHeader.AdditionalInfo>
            </PageInfoHeader.CollapsibleList>
          }
          right={
            <ButtonGroup classNames="bd-buttons">
              <Button type="button" size="h28" to={`optional-ig-setup`} id="gotoOptionalIGSetup">
                Exclude
              </Button>
              {!isEditingForbidden && userHasAccessTo(MANAGE_BUDGETS) && (
                <Button
                  type="button"
                  size="h28"
                  id="updateBudgetBtn"
                  disabled={!this.allRequiredFieldsAreFilledCorrectly()}
                  onClick={this.updateStudyBudget}
                >
                  Update
                </Button>
              )}
              <Button priority="medium" size="h28" type="button" id="exportBudgetBtn" onClick={this.exportStudyBudget}>
                Export
              </Button>
            </ButtonGroup>
          }
        />
        <Section>
          <div className="budget-detail-top">
            <div className="row">
              <div className="col-md-5">
                <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <input
                    id="description"
                    className="form-control reqfeild"
                    type="text"
                    value={budget.description}
                    name="description"
                    disabled={isEditingForbidden || !userHasAccessTo(MANAGE_BUDGETS)}
                    onChange={this.onChangeTextVal}
                    onBlur={this.onBlur}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="overhead">Overhead</label>
                  <div className="input-group">
                    <input
                      id="overhead"
                      type="number"
                      className="form-control reqfeild"
                      value={budget.overhead}
                      name="overhead"
                      disabled={isEditingForbidden || !userHasAccessTo(MANAGE_BUDGETS)}
                      onChange={this.onChangeTextVal}
                      onBlur={this.onBlur}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <small id="overheadHelp" className="form-text text-muted">
                    Range: 0.0 - 100.0
                  </small>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="withholding">Withholding</label>
                  <div className="input-group">
                    <input
                      id="withholding"
                      type="number"
                      className="form-control reqfeild"
                      value={budget.withholding}
                      name="withholding"
                      disabled={isEditingForbidden || !userHasAccessTo(MANAGE_BUDGETS)}
                      onChange={this.onChangeTextVal}
                      onBlur={this.onBlur}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <small id="withholdingHelp" className="form-text text-muted">
                    Range: 0.0 - 100.0
                  </small>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="managementFee">Management Fee</label>
                  <div className="input-group">
                    <input
                      id="managementFee"
                      type="number"
                      className="form-control reqfeild"
                      value={budget.managementFee}
                      name="managementFee"
                      disabled={isEditingForbidden || !userHasAccessTo(MANAGE_BUDGETS)}
                      onChange={this.onChangeTextVal}
                      onBlur={this.onBlurManagementFee}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <small id="managementFeeHelp" className="form-text text-muted">
                    Range: 0.0 - 100.0
                  </small>
                </div>
              </div>
            </div>
          </div>
        </Section>
        {isEditingForbidden ? (
          <CommentSectionForPublishedProtocolAndBudget comment={budget.comment} />
        ) : (
          <CommentSection
            name="comment"
            onChange={this.onChangeTextVal}
            onBlur={this.onBlur}
            value={budget.comment}
            disabled={isEditingForbidden || !userHasAccessTo(MANAGE_BUDGETS)}
          />
        )}
        <BudgetDetailsTableSection
          studyId={this.state.budget.studyId}
          managementFeePercent={this.state.budget.managementFee}
          currentStudyBudget={this.state.currentStudyBudget}
          encounterTriggers={this.state.encounterTriggers}
          itemGroupTriggers={this.state.itemGroupTriggers}
          vendors={this.state.vendors}
          budgetId={this.props.params.id}
          loadBudgetEvents={this.loadBudgetEvents}
          protocolIncomplete={protocolIncomplete}
          isEditingForbidden={this.state.isEditingForbidden}
          events={this.state.events}
        />
      </div>
    );
  }
}

function isNotEmpty(str) {
  return str && str.trim() !== '';
}

function mapDispatchToProps(dispatch) {
  return {
    updateStudyBudget: async budget => {
      dispatch(updateStudyBudget(budget));
    }
  };
}

export default connect(null, mapDispatchToProps)(withParams(BudgetDetails));
