import React from 'react';
import { connect } from 'react-redux';

import { buildEnrollmentsActionCreator } from '../../../../actions/finance/forecasting/forecastingActions';
import { PageInfoHeader } from '../../../PageInfoHeader/PageInfoHeader';
import { SSUFilter } from '../../../SSUFilter/SSUFilter';
import { SSUPCNFilter } from '../../../SSUFilter/SSUPCNFilter';

import StudyEnrollmentList from './StudyEnrollmentList/StudyEnrollmentList';

import './Enrollment.scss';

const Enrollment = props => (
  <div>
    <PageInfoHeader>
      <div className="general-header-group-container general-header-wrapper">
        <SSUFilter handleSSUFilterChange={props.handleSSUFilterChange} enrollmentMode={true}>
          <SSUPCNFilter />
        </SSUFilter>
      </div>
    </PageInfoHeader>
    <div className="row px-3 m-0 mt-2">
      <div className="col p-0 pt-2">
        <div>
          <StudyEnrollmentList />
        </div>
      </div>
    </div>
  </div>
);

const mapDispatchToProps = dispatch => {
  return {
    handleSSUFilterChange: studySites => {
      dispatch(buildEnrollmentsActionCreator(studySites));
    }
  };
};

export default connect(null, mapDispatchToProps)(Enrollment);
