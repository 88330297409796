import { useRef } from 'react';
import { isEqual } from 'lodash/lang';

export default function useDeepCompareMemoize(value) {
  const ref = useRef();
  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}
