import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { groupBy, isEmpty } from 'lodash';
import { isNull } from 'lodash/lang';
import Collapse from 'rc-collapse';
import Tooltip from 'rc-tooltip';

import IconButton from '../../../../../../../common/buttons/IconButton';
import Input from '../../../../../../../common/data-entry/Input';
import Checkbox from '../../../../../../../common/data-entry/InputSelectors/Checkbox';
import Button from '../../../../../../../common/general/Button';
import Icon from '../../../../../../../common/general/Icon';
import useOutsideClickDetector from '../../../../../../../common/hooks/useOutsideClickDetector';

import '../WorklistTableHeaderFilter.scss';

export function WorklistSignedByFilter({
  filterRef,
  Context,
  signaturesProvider,
  statusesProvider,
  setSignedByFilterVisible,
  isSignedByFilterVisible
}) {
  const {
    EncounterWidgetContext: { currentlySelected },
    AdverseEventsWidgetContext: { currentlySelected: currentlySelectedForAdverse },
    selectedStudySites,
    signatures,
    setSignatures,
    page,
    setPage,
    updateWithSignature,
    tableData,
    encounterNames
  } = Context;
  useOutsideClickDetector(filterRef, () => {
    setSignedByFilterVisible(false);
  });

  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [signaturesPerStudy, setSignaturesPerStudy] = useState();
  const [totalSignatures, setTotalSignatures] = useState(() => []);
  const [selectedSignatures, setSelectedSignatures] = useState(signatures || totalSignatures);
  const [placedOnTop, setPlacedOnTop] = useState(false);
  const [isSearchSuccessful, setSearchSuccessful] = useState(true);

  useEffect(() => {
    if (searchFieldValue) {
      const filteredSignatures = getSignaturesForSearch().filter(
        sign =>
          sign.userName.toLowerCase().includes(searchFieldValue.toLowerCase()) ||
          (!isNull(sign.groupAssignName) && sign.groupAssignName.toLowerCase().includes(searchFieldValue.toLowerCase()))
      );
      setSignaturesPerStudy(groupBy(filteredSignatures, signature => signature.studyName));
      setSearchSuccessful(!isEmpty(filteredSignatures));
    } else {
      const signaturesUntilSearch = getSignaturesForSearch();
      setSignaturesPerStudy(groupBy(signaturesUntilSearch, signature => signature.studyName));
      setSearchSuccessful(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFieldValue]);

  useEffect(() => {
    setSelectedSignatures(totalSignatures);
  }, [totalSignatures]);

  useEffect(() => {
    if (currentlySelectedForAdverse) {
      const selectedStatusOptions = statusesProvider
        .filter(invStatus => invStatus.id === currentlySelectedForAdverse)
        .flatMap(({ value }) => value);
      getSignatures(selectedStatusOptions);
    } else {
      resetSignatures();
      updateWithSignature([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlySelectedForAdverse, statusesProvider]);

  useEffect(() => {
    if (currentlySelected) {
      const selectedStatusOptions = statusesProvider
        .filter(invStatus => invStatus.id === currentlySelected)
        .flatMap(({ value }) => value);
      getSignatures(selectedStatusOptions);
    } else {
      resetSignatures();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlySelected, statusesProvider]);

  useEffect(() => {
    if (!isEmpty(encounterNames) && !isEmpty(totalSignatures) && currentlySelected !== '') {
      const filteredSignatures = filterSignatures(totalSignatures, encounterNames);
      sortSignatures(filteredSignatures);
      setSignaturesPerStudy(groupBy(filteredSignatures, signature => signature.studyName));
      setSelectedSignatures(filteredSignatures);
      setSignatures([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encounterNames, totalSignatures]);

  const getSignatures = selectedStatusOptions => {
    signaturesProvider &&
      signaturesProvider(
        selectedStudySites?.map(studySite => studySite.id),
        selectedStatusOptions
      ).then(({ data }) => {
        sortSignatures(data);
        setSignaturesPerStudy(groupBy(data, signature => signature.studyName));
        setTotalSignatures(data);
        setSignatures([]);
        currentlySelectedForAdverse !== '' && updateWithSignature([]);
        setSearchFieldValue('');
      });
  };

  const getSignaturesForSearch = () => {
    return currentlySelected !== '' ? filterSignatures(totalSignatures, encounterNames) : totalSignatures;
  };

  const resetSignatures = () => {
    setSignaturesPerStudy([]);
    setTotalSignatures([]);
    setSignatures([]);
  };

  const onOptionChanged = option => {
    if (checkIfOptionIsSelected(option)) {
      setSelectedSignatures(selectedSignatures.filter(sign => !isSignaturesAreEquals(sign, option)));
    } else {
      setSelectedSignatures([...selectedSignatures, option]);
    }
  };
  const checkIfOptionIsSelected = option => {
    return selectedSignatures.filter(sign => isSignaturesAreEquals(sign, option)).length > 0;
  };

  const countSelectedOptions = options => {
    return options.filter(value => checkIfOptionIsSelected(value)).length;
  };
  const isSelectedAll = () => {
    return selectedSignatures.length === Object.values(signaturesPerStudy).flatMap(s => s).length;
  };
  useEffect(
    function() {
      setPlacedOnTop(tableData.length < 8);
    },
    [tableData.length]
  );

  return (
    <>
      {isSignedByFilterVisible && (
        <div
          className={cx('filter-dropdown', placedOnTop ? 'placed-on-top' : 'placed-on-bottom')}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <Input
            label={'Search'}
            onChange={e => e && setSearchFieldValue(e.target.value)}
            value={searchFieldValue}
            iconsAfter={
              <>
                <span className="count main">{selectedSignatures.length}</span>
                {searchFieldValue && (
                  <IconButton
                    onClick={() => {
                      setSearchFieldValue('');
                    }}
                    className={'clear-button'}
                    style={{ cursor: 'pointer' }}
                  >
                    highlight_off
                  </IconButton>
                )}
                <Icon>search</Icon>
              </>
            }
            className="eui-search-input"
            validate={false}
          />

          {isSearchSuccessful ? (
            <>
              <Checkbox
                label="Select all"
                indeterminate={!isEmpty(selectedSignatures) && !isSelectedAll()}
                className={'select-all-option'}
                onChange={() => {
                  if (!isSelectedAll()) {
                    setSelectedSignatures(
                      Object.values(signaturesPerStudy)
                        .flatMap(s => s)
                        .filter(sign => sign.userName.includes(searchFieldValue))
                    );
                  } else {
                    setSelectedSignatures([]);
                  }
                }}
                checked={isSelectedAll()}
              />
              <div className={'options-container'}>
                {signaturesPerStudy &&
                  Object.entries(signaturesPerStudy).map((value, idx) => (
                    <CustomOptionTemplate
                      option={value}
                      key={idx}
                      onOptionChanged={onOptionChanged}
                      checkIfOptionIsSelected={checkIfOptionIsSelected}
                      countSelectedOptions={countSelectedOptions}
                    />
                  ))}
              </div>
            </>
          ) : (
            <div className={'no-results-message'}>No Results found</div>
          )}
          {isSearchSuccessful && (
            <div className={'filter-footer'}>
              <Button
                priority="medium"
                onClick={() => {
                  setSelectedSignatures([]);
                }}
              >
                Clear All
              </Button>
              <Button
                priority="high"
                onClick={() => {
                  setSignatures(selectedSignatures);
                  page === 0 ? updateWithSignature(selectedSignatures) : setPage(0);
                  setSignedByFilterVisible(false);
                }}
              >
                Apply
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
}

const isSignaturesAreEquals = (firstSign, secondSign) => {
  return (
    firstSign.userName === secondSign.userName &&
    firstSign.studyName === secondSign.studyName &&
    firstSign.groupAssignName === secondSign.groupAssignName
  );
};

const filterSignatures = (data, encounterNames) => {
  return data.filter(s =>
    encounterNames.find(enc => enc.studyName === s.studyName && s.encounterNames.includes(enc.encounterName))
  );
};

const sortSignatures = data => {
  data.sort((a, b) => a.studyName.localeCompare(b.studyName));
};

const CustomOptionTemplate = ({ option, onOptionChanged, checkIfOptionIsSelected, countSelectedOptions }) => {
  function panelHeaderWithCount(title, count) {
    return (
      <React.Fragment>
        <Tooltip
          destroyTooltipOnHide={false}
          overlayClassName="eds-rc-tooltip"
          placement="top"
          trigger={title?.length > 50 ? ['hover'] : []}
          overlay={title}
        >
          <span className={'panel-title truncated'}>{title}</span>
        </Tooltip>
        {count > 0 && <span className="count">{count}</span>}
      </React.Fragment>
    );
  }

  return (
    <Collapse
      expandIcon={({ isActive }) => (
        <span className="rc-collapse-header-expand-icon material-icons">
          {`keyboard_arrow_${isActive ? 'up' : 'down'}`}
        </span>
      )}
      defaultActiveKey={[1]}
      openAnimation={{}}
      className="filter-collapse"
    >
      <Collapse.Panel
        header={panelHeaderWithCount(option[0], countSelectedOptions(option[1]))}
        key={option[0]}
        forceRender={true}
        openAnimation={{}}
      >
        {option[1].map((option, key) => {
          const userGroup = getUserGroup(option.groupAssignName);
          return (
            <div className="selected-group" key={key}>
              <Checkbox
                className="selected-checkbox"
                checked={checkIfOptionIsSelected(option)}
                onChange={() => onOptionChanged(option)}
              />
              <div className="user-info pl-2">
                <UserInfo value={userGroup}>
                  <div className="label truncated">{userGroup}</div>
                </UserInfo>
                <UserInfo value={option.userName}>
                  <div className="name truncated">{option.userName}</div>
                </UserInfo>
              </div>
            </div>
          );
        })}
      </Collapse.Panel>
    </Collapse>
  );
};

const getUserGroup = groupAssignName => {
  return groupAssignName ? groupAssignName : 'Study Manager';
};

const UserInfo = ({ children, value }) => {
  return (
    value && (
      <Tooltip
        destroyTooltipOnHide={false}
        overlayClassName="eds-rc-tooltip"
        placement="top"
        trigger={value?.length > 30 ? ['hover'] : []}
        overlay={value}
      >
        {children}
      </Tooltip>
    )
  );
};
