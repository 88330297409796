import React from 'react';

import Icon from '../../../../../common/general/Icon';

import { getStatusMessage } from './WorklistService';

import './WorklistStatusMessageAndSearchField.scss';

export const WorklistStatusMessageAndSearchField = ({
  Context: { AdverseEventsWidgetContext, EncounterWidgetContext, setSearchFieldValue },
  statusesProvider
}) => {
  const isEncounterWidgetSelected = EncounterWidgetContext.currentlySelected !== '';
  const currentlySelected =
    EncounterWidgetContext.currentlySelected ||
    (AdverseEventsWidgetContext && AdverseEventsWidgetContext.currentlySelected);
  const getMessageText = currentSelectedStatus => {
    if (!currentSelectedStatus) {
      return 'Please select a status above to display results';
    } else {
      return getStatusMessage(currentSelectedStatus, statusesProvider, isEncounterWidgetSelected);
    }
  };
  return (
    <div className={'message-and-search-field-container p-4'}>
      <div className={currentlySelected ? 'general-message' : 'select-status-message'}>
        {getMessageText(currentlySelected)}
      </div>
      <div>
        <div style={{ position: 'relative' }}>
          <Icon className={'investigator-worklist-search-icon'}>search</Icon>
          <input
            className={'investigator-worklist-search-field'}
            placeholder={'Search Patient Name or Subject ID'}
            onChange={e => setSearchFieldValue(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};
