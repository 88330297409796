import React from 'react';

import Checkbox from '../../../../../../../../common/data-entry/InputSelectors/Checkbox';
import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { scBlue100 } from '../../../../../../../../constants/systemColors';
import { isAllItemSelected } from '../../../itemGroupSetupService';

export function OptionalCheckbox({
  inputs,
  isChecked,
  uniqueId,
  item,
  labelIndex,
  index,
  decode,
  updateInputsAndPreviewData,
  disabled
}) {
  return (
    <Checkbox
      color={scBlue100}
      label={decode}
      checked={!!isChecked}
      disabled={disabled}
      className="p-0 overflow-ellipsis"
      id={uniqueId}
      onChange={({ target }) => {
        onOptionalSingleCheck(target?.checked, item, 0, labelIndex, index);
      }}
    />
  );
  function onOptionalSingleCheck(checked, item, pindex, labelIndex, index) {
    if (item.inputType === 'radio' && !checked) {
      const allCustomItems = inputs[0]?.custom.flatMap(e => e.customItemDefinationList) || [];
      const customOptionsWithUsedField = allCustomItems.filter(
        e => e?.fieldConfigurationResponse?.conditionField?.name === item?.name
      );
      const optionalFieldsWishConditionalLogic = inputs[0].optional[0].itemDefinitionList.filter(
        e => e?.fieldConfigurationResponse?.conditionField?.name === item?.name
      );
      if (customOptionsWithUsedField.length > 0 || optionalFieldsWishConditionalLogic.length > 0) {
        ModalBoxes.confirm({
          content: 'Deleting this toggle will remove field configurations in this Item Group. Delete anyway?',
          confirmButton: 'Yes',
          cancelButton: 'No',
          className: 'edit-custom-field-logic-modal',
          title: 'Toggle Configuration'
        }).then(() => {
          if (customOptionsWithUsedField.length > 0) {
            customOptionsWithUsedField.forEach(custom => (custom.fieldConfigurationResponse = null));
          }
          if (optionalFieldsWishConditionalLogic.length > 0) {
            optionalFieldsWishConditionalLogic.forEach(optional => (optional.fieldConfigurationResponse = null));
          }
          optionalSingleCheck(checked, item, pindex, labelIndex, index);
        });
      } else {
        optionalSingleCheck(checked, item, pindex, labelIndex, index);
      }
    } else {
      optionalSingleCheck(checked, item, pindex, labelIndex, index);
    }
  }

  function optionalSingleCheck(checked, item, pindex, labelIndex, index) {
    inputs[pindex].optional[labelIndex].itemDefinitionList[index].isChecked = checked;
    if (!checked) {
      inputs[pindex].optional[labelIndex].itemDefinitionList[index].isTable = false;
    }
    inputs[pindex].optional[labelIndex].itemDefinitionList[index].fixedField = false;
    inputs[pindex].optional[labelIndex].itemDefinitionList[index].investigatorField = false;
    inputs[pindex].allOptionalChecked = isAllItemSelected(inputs[pindex].optional[labelIndex].itemDefinitionList);
    updateInputsAndPreviewData(inputs);
  }
}
