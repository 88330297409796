import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem } from '@szhsin/react-menu';

import Button from '../../../../../../common/general/Button';
import Icon from '../../../../../../common/general/Icon';
import { onRequestError } from '../../../../../../services/handlers';
import { isCancelRowStatus } from '../../../../../../services/logRowStatusService';
import { generateUrlByKey, useCurrentRoute } from '../../../../../root/router';
import { cancelAdverseEventLogRow, reopenAdverseEventLogRow } from '../../services';

function AdverseEventLogRowCloseReopen({ logRowStatus, studyId, itemGroupTemplateId, elementName }) {
  const currentRoute = useCurrentRoute();
  const navigate = useNavigate();

  return (
    <div className="est-more-options" onClick={e => e.preventDefault()}>
      <Menu
        align="end"
        menuButton={
          <Button priority="low" size="h40">
            <Icon>more_vert</Icon>
          </Button>
        }
      >
        {!isCancelRowStatus(logRowStatus) && (
          <MenuItem
            onClick={e => {
              e.syntheticEvent.preventDefault();
              const { rowId, ssuPatientId, itemGroupTemplateVersionId } = currentRoute.params;
              cancelAdverseEventLogRow(
                studyId,
                ssuPatientId,
                itemGroupTemplateId,
                itemGroupTemplateVersionId,
                rowId,
                elementName
              );
            }}
          >
            Cancel Adverse Event
          </MenuItem>
        )}
        {isCancelRowStatus(logRowStatus) && (
          <MenuItem
            onClick={e => {
              e.syntheticEvent.preventDefault();
              const { rowId, ssuPatientId, itemGroupTemplateVersionId } = currentRoute.params;
              reopenAdverseEventLogRow(studyId, ssuPatientId, itemGroupTemplateId, itemGroupTemplateVersionId, rowId)
                .then(() => navigate(generateUrlByKey(currentRoute.parent.key, currentRoute.params)))
                .catch(err => onRequestError(err, { customMessage: 'Something went wrong' }));
            }}
          >
            Reopen Adverse Event
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

export default AdverseEventLogRowCloseReopen;
