import React from 'react';

import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';

import AELogRowSignaturesHistory from './AELogRowSignaturesHistory';

export default function AELogRowSignaturesHistoryModal({ modalBox, history, adverseEventHistory }) {
  return (
    <>
      <ModalBoxes.Body>
        <AELogRowSignaturesHistory history={history} adverseEventHistory={adverseEventHistory} />
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <Button priority="medium" onClick={modalBox.close}>
          Close
        </Button>
      </ModalBoxes.Footer>
    </>
  );
}

AELogRowSignaturesHistoryModal.className = 'ae-log-row-signatures-history-modal';
AELogRowSignaturesHistoryModal.size = 'w1250';
