import axios from 'axios';

const ROOT = 'api/v1/finance/enrollment-predictions';

const EnrollmentPredictionApi = {
  getLastLockedPeriod() {
    return axios.get(`${ROOT}/last-locked-period`);
  },
  getEnrollmentsForActivePeriod(ssuIdentifiers) {
    return axios.post(`${ROOT}/active`, ssuIdentifiers);
  },
  lock(date) {
    return axios.put(`${ROOT}/lock/${date}`);
  },
  getLastLocked(ssuIdentifiers) {
    return axios.post(`${ROOT}/last/locked`, ssuIdentifiers);
  },
  create(enrollment) {
    return axios.post(`${ROOT}?ssuId=${enrollment.studySiteId}`, enrollment);
  },
  update(enrollment) {
    return axios.put(`${ROOT}/${enrollment.id}?ssuId=${enrollment.studySiteId}`, enrollment);
  }
};

export default EnrollmentPredictionApi;
