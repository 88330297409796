import * as React from 'react';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import {
  DataGridPremium,
  getGridStringOperators,
  GridFilterInputValue,
  GridRow,
  useGridApiRef
} from '@mui/x-data-grid-premium';

import { CustomFooter } from '../../NewInvoice/InvoiceTable/CustomFooter/CustomFooter';
import { CustomToolbar } from '../../NewInvoice/InvoiceTable/CustomToolbar/CustomToolbar';

export const FinanceTableMUI = ({
  getTogglableColumns,
  footerElementsCount,
  sx,
  initialState,
  localStorageTableName,
  columns,
  tableVersion,
  columnsFilterSort,
  ...otherProps
}) => {
  const apiRef = useGridApiRef();

  const [savedState, setSavedState] = useState();
  const localStorageStateName = useMemo(() => `${localStorageTableName}_TABLE_CONFIGURATION`, [localStorageTableName]);

  const saveSnapshot = useCallback(() => {
    if (apiRef?.current?.exportState && localStorage) {
      const currentState = apiRef.current.exportState();
      localStorage.setItem(localStorageStateName, JSON.stringify({ ...currentState, filter: {} }));
    }
  }, [apiRef, localStorageStateName]);

  useLayoutEffect(() => {
    const savedStateVersion = localStorage?.getItem(`${localStorageTableName}_TABLE_VERSION`);
    if (savedStateVersion !== tableVersion) {
      localStorage.removeItem(localStorageStateName);
      localStorage.removeItem(`${localStorageTableName}_TABLE_CUSTOM_VIEW`);
      localStorage.setItem(`${localStorageTableName}_TABLE_VERSION`, tableVersion);
    }
  }, [localStorageStateName, localStorageTableName, tableVersion]);

  useLayoutEffect(() => {
    const stateFromLocalStorage = localStorage?.getItem(localStorageStateName);
    setSavedState(stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : {});
    window.addEventListener('beforeunload', saveSnapshot);

    return () => {
      window.removeEventListener('beforeunload', saveSnapshot);
      saveSnapshot();
    };
  }, [localStorageStateName, saveSnapshot]);

  const columnsWithExtendedTextFilters = useMemo(() => {
    const filterOperators = [
      ...getGridStringOperators(),
      {
        label: 'does not contain',
        value: 'doesNotContain',
        getApplyFilterFn: (filterItem, column) => {
          if (!filterItem.field || !filterItem.value || !filterItem.operator) {
            return null;
          }

          const filterRegex = new RegExp(filterItem.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
          return params => {
            const rowValue = column.valueGetter ? column.valueGetter(params) : params.value;
            return !filterRegex.test(rowValue?.toString() || '');
          };
        },
        InputComponent: GridFilterInputValue
      }
    ];
    return columns.map(column => {
      return !column.type || column.type === 'text' ? { ...column, filterOperators } : column;
    });
  }, [columns]);

  if (!savedState) {
    return null;
  }

  return (
    <DataGridPremium
      {...otherProps}
      columns={columnsWithExtendedTextFilters}
      apiRef={apiRef}
      onStateChange={saveSnapshot}
      initialState={{
        ...initialState,
        ...savedState
      }}
      disableRowGrouping
      disableAggregation
      rowHeight={38}
      slots={{
        toolbar: CustomToolbar,
        footer: CustomFooter,
        row: CustomRow
      }}
      slotProps={{
        columnsPanel: {
          getTogglableColumns,
          sort: columnsFilterSort
        },
        filterPanel: {
          columnsSort: columnsFilterSort
        },
        toolbar: { localStorageTableName },
        footer: {
          count: footerElementsCount
        }
      }}
      sx={{
        padding: '20px 40px 0 40px',
        border: 'none',
        '.MuiDataGrid-pinnedColumns, .MuiDataGrid-pinnedColumnHeaders': {
          backgroundColor: '#FAFAFA'
        },
        ...sx
      }}
    />
  );
};

const CustomRow = props => {
  const resolveTestIds = {
    center: 'finance-table-row',
    left: 'finance-table-row-left',
    right: 'finance-table-row-right'
  };
  return <GridRow {...props} data-testid={resolveTestIds[props.position]} />;
};
