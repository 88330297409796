import React, { useEffect, useState } from 'react';
import { cloneDeep, isArray, isEqual } from 'lodash/lang';

import {
  DATA_COLLECTED,
  NO_DATA_TO_COLLECT,
  NOT_PERFORMED
} from '../../../../patient-source/shared/EncounterDescNew/LogCheck/LogCheckNew';
import { resolveDefaultLogCheckOptions } from '../defaultLogCheckOptions';

import { LogCheckSectionNew } from './LogCheckSection/LogCheckSectionNew';

import './LogCheckSetup.scss';

export function LogCheckSetupNew({ initialOptions, updateLogCheckOptions, resetTrigger }) {
  const [renderedOptions, setRenderedOptions] = useState([]);
  const [dataCollectedOptions, setDataCollectedOptions] = useState([]);
  const [emptyRowOptions, setEmptyRowOptions] = useState([]);
  const [notPerformedOptions, setNotPerformedOptions] = useState([]);

  useEffect(() => {
    updateLogCheckOptions(cloneDeep(resolveDefaultLogCheckOptions(resetTrigger)));
  }, [resetTrigger, updateLogCheckOptions]);

  useEffect(() => {
    if (isArray(initialOptions) && !isEqual(renderedOptions, initialOptions)) {
      setDataCollectedOptions(
        initialOptions.filter(el => el.rowType === DATA_COLLECTED).sort((a, b) => a.sequence - b.sequence)
      );
      setEmptyRowOptions(
        initialOptions.filter(el => el.rowType === NO_DATA_TO_COLLECT).sort((a, b) => a.sequence - b.sequence)
      );
      setNotPerformedOptions(
        initialOptions.filter(el => el.rowType === NOT_PERFORMED).sort((a, b) => a.sequence - b.sequence)
      );
      setRenderedOptions(initialOptions);
    }
  }, [initialOptions, renderedOptions]);

  useEffect(() => {
    const selectedOptions = [...dataCollectedOptions, ...emptyRowOptions, ...notPerformedOptions];
    updateLogCheckOptions(selectedOptions);
  }, [dataCollectedOptions, emptyRowOptions, notPerformedOptions, updateLogCheckOptions]);

  return (
    <div className="col-12 mt-3 p-0 encounter-check-container">
      <div className="p-1 encounter-check-header">
        <h5>Log check</h5>
      </div>
      <div className="row p-3 m-0">
        <LogCheckSectionNew
          initialOptionList={dataCollectedOptions}
          updateLogCheckOptions={setDataCollectedOptions}
          sectionTitle="Data Collected"
          optionType={DATA_COLLECTED}
        />
        <LogCheckSectionNew
          initialOptionList={emptyRowOptions}
          updateLogCheckOptions={setEmptyRowOptions}
          sectionTitle="No Data To Collect"
          optionType={NO_DATA_TO_COLLECT}
        />
        <LogCheckSectionNew
          initialOptionList={notPerformedOptions}
          updateLogCheckOptions={setNotPerformedOptions}
          sectionTitle="Not Performed"
          optionType={NOT_PERFORMED}
        />
      </div>
    </div>
  );
}
