import axios from 'axios';

const NewEncounterLogCheckApi = {
  saveLogCheckNotPerformed(sitePatientId, sitePatientEncounterId, itemGroupUuid, ssuId, data) {
    return axios.post(
      `api/v1/study-sites/${ssuId}/patients/${sitePatientId}/encounters/${sitePatientEncounterId}/item-groups/${itemGroupUuid}/log-check/not-performed`,
      data
    );
  },
  saveLogCheckReported(sitePatientId, sitePatientEncounterId, itemGroupUuid, ssuId, data) {
    return axios.post(
      `api/v1/study-sites/${ssuId}/patients/${sitePatientId}/encounters/${sitePatientEncounterId}/item-groups/${itemGroupUuid}/log-check/reported`,
      data
    );
  },
  saveLogCheckEmptyRow(ssuId, sitePatientId, sitePatientEncounterId, itemGroupUuid) {
    return axios.post(
      `api/v1/study-sites/${ssuId}/patients/${sitePatientId}/encounters/${sitePatientEncounterId}/item-groups/${itemGroupUuid}/log-check/no-data-to-collect`
    );
  }
};
export default NewEncounterLogCheckApi;
