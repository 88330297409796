import { find } from 'lodash/collection';

import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { PROCESS_INTERRUPTED_BY_THE_USER } from '../../../../../../../constants/errorMessages';

export default function processValidateWhoDidIt(whoDidItList, { whoDidItId }) {
  const name = find(whoDidItList, r => r.uniqueIdentifier === whoDidItId)?.name;
  if (name && name.includes('(Inactive)')) {
    return ModalBoxes.confirm({
      content:
        'The name appearing in the Who did it? field does not currently have an active role with this study. Are you sure you want to save these updates?',
      confirmButton: 'Save',
      cancelButton: 'Cancel'
    }).catch(function() {
      throw new Error(PROCESS_INTERRUPTED_BY_THE_USER);
    });
  }
  return Promise.resolve();
}
