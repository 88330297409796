import React, { useContext, useEffect, useState } from 'react';
import { last } from 'lodash/array';
import { reject } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

import PatientInfoPageApi from '../../../../../../../api/patient/PatientInfoPageApi';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import { MANAGE_PATIENT_FILES, VIEW_PATIENT_FILES } from '../../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../../services/auth';
import { onRequestError } from '../../../../../../../services/handlers';
import { doesStringContainNullByte } from '../../../../../../../services/string';
import { PatientInfoContext } from '../../PatientInfoContext';

export const availableFormats = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'image/png',
  'image/jpeg'
];

export const FileUploadContext = React.createContext({ files: [] });

export function FileUploadContextProvider({ children }) {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadFilesCandidates, setUploadFilesCandidates] = useState(null);
  const [requestsNumber, setRequestsNumber] = useState(0);

  const { patientInfo, currentStudy } = useContext(PatientInfoContext);

  const canUserViewFiles = userHasAccessTo(VIEW_PATIENT_FILES);
  const uploadFilesAllowed = userHasAccessTo(MANAGE_PATIENT_FILES);

  useEffect(() => {
    if (canUserViewFiles) {
      showLoading(PatientInfoPageApi.getPatientFilesByPatientId(patientInfo.id)).then(({ data: { files } }) => {
        setUploadedFiles(files);
      });
    } else {
      showLoading(PatientInfoPageApi.getPatientFilesCountByPatientId(patientInfo.id)).then(({ data: { count } }) => {
        setUploadedFiles(new Array(count).fill({}));
      });
    }
  }, [patientInfo, canUserViewFiles]);

  function uploadFiles(preparedFilesToUpload) {
    return showLoading(
      PatientInfoPageApi.uploadPatientFiles(patientInfo.id, currentStudy.ssuPatientId, {
        fileList: preparedFilesToUpload
      })
    )
      .then(({ data: { files } }) => {
        setUploadedFiles(previouslyUploadedFiles => [...previouslyUploadedFiles, ...files]);
      })
      .catch(onRequestError);
  }

  function deleteFile(patientFileId) {
    return showLoading(
      PatientInfoPageApi.deletePatientFile(patientInfo.id, currentStudy.ssuPatientId, patientFileId)
    ).then(res => {
      setUploadedFiles(state => reject(state, ['id', patientFileId]));
      return res;
    }, onRequestError);
  }

  function addFilesAsUploadCandidate(fileList) {
    const nullByteFileList = fileList
      .filter(file => doesStringContainNullByte(file.name))
      .map(file => file.name)
      .join(', ');

    if (!isEmpty(nullByteFileList)) {
      NotificationManager.error(
        `The file(s) ${nullByteFileList} cannot be uploaded because the file name format is unacceptable. Please rename the file(s) and re-upload`
      );
    }

    const notNullByteFileList = fileList.filter(file => !doesStringContainNullByte(file.name));
    const notEmptyFiles = notNullByteFileList.filter(file => file.size !== 0);
    const emptyFileNames = notNullByteFileList
      .filter(file => file.size === 0)
      .map(file => file.name)
      .join(', ');
    if (!isEmpty(emptyFileNames)) {
      NotificationManager.error(`The file(s) ${emptyFileNames} cannot be uploaded because they are empty.`);
    }
    if (!isEmpty(notEmptyFiles)) {
      readAllFiles(notEmptyFiles).then(fileList => {
        setUploadFilesCandidates(fileList);
      });
    }
  }

  function clearUploadDraft() {
    setUploadFilesCandidates(null);
  }

  function showLoading(promise) {
    setRequestsNumber(requestsNumber => ++requestsNumber);
    promise.finally(() => {
      setRequestsNumber(requestsNumber => --requestsNumber);
    });
    return promise;
  }

  return (
    <FileUploadContext.Provider
      value={{
        uploadedFiles,
        uploadFiles,
        deleteFile,
        addFilesAsUploadCandidate,
        clearUploadDraft,
        uploadFilesCandidates,
        canUserViewFiles,
        uploadFilesAllowed,
        isLoading: !!requestsNumber
      }}
    >
      {children}
    </FileUploadContext.Provider>
  );
}

function readAllFiles(files) {
  return Promise.all(files.map(file => readFile(file)));

  function readFile(file) {
    return new Promise(function(resolve, reject) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = ({ target }) => {
        resolve({
          content: last(target?.result?.split(',')),
          type: file?.type,
          name: file?.name
        });
      };
      reader.onerror = error => {
        reject(error);
      };
    });
  }
}
