import React from 'react';
import Tooltip from 'rc-tooltip';

export default function InvestigatorForbiddenTooltip({ children, isInvestigatorFieldForbidden = false }) {
  return (
    <Tooltip
      destroyTooltipOnHide={true}
      placement={'top'}
      trigger={isInvestigatorFieldForbidden ? ['hover'] : []}
      overlayClassName="eds-rc-tooltip"
      mouseEnterDelay={0}
      mouseLeaveDelay={0}
      overlay={<span>Investigator credentials are required to make a selection.</span>}
    >
      {children}
    </Tooltip>
  );
}
