import { SvgIcon } from '@mui/material';

export const FontSize = ({ onClick, active }) => {
  return (
    <SvgIcon onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M20.75 19H22.25L19.25 9H17.75L14.75 19H16.25L17 16.5H20L20.75 19ZM17.45 15L18.5 11.5L19.55 15H17.45ZM11.75 19H13.75L8.75 5H6.75L1.75 19H3.75L5.18 15H10.32L11.75 19ZM5.89 13L7.75 7.8L9.61 13H5.89Z"
          fill="black"
          fillOpacity={`${active ? '0.9' : '0.6'}`}
        />
      </svg>
    </SvgIcon>
  );
};
