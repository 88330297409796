import React from 'react';
import { isEmpty } from 'lodash/lang';

import Chip, { ChipLabel } from './Chip';
import { getNames } from './WidgetDefaultService';

import './WidgetDefaultStyles.scss';

export default function WidgetFilterChips({
  allStudies,
  allSites,
  filter,
  resetStudies,
  resetSites,
  resetEpochs,
  resetEncounters
}) {
  return (
    <>
      <div className="px-3 chips-container">
        {allStudies.length === 1 && <ChipLabel label="Study" value={allStudies[0].name} />}
        {allSites.length === 1 && <ChipLabel label="Site" value={allSites[0].name} />}

        {allStudies.length > 1 && (
          <ChipInstance
            label="Study"
            originalItems={filter.studies}
            values={getNames(filter.selectedStudies)}
            onClose={resetStudies}
          />
        )}
        {allSites.length > 1 && (
          <ChipInstance
            label="Site"
            originalItems={filter.sites}
            values={getNames(filter.selectedSites)}
            onClose={resetSites}
          />
        )}
        <ChipInstance
          label="Epoch"
          originalItems={filter.epochs}
          values={getNames(filter.selectedEpochs)}
          onClose={resetEpochs}
        />
        <ChipInstance
          label="Encounter"
          originalItems={filter.encounters}
          values={getNames(filter.selectedEncounters)}
          onClose={resetEncounters}
        />
      </div>
    </>
  );
}

function ChipInstance({ label, originalItems, values, onClose }) {
  return (
    <>
      {!isEmpty(values) && originalItems.length > 0 && (
        <Chip label={label} values={values} onClose={onClose} originalItems={originalItems} />
      )}
    </>
  );
}
