import axios from 'axios';

const ROOT = 'api/v1/ssu-patients';

const PatientStatusApi = {
  getAllStatuses() {
    return axios.get(`${ROOT}/statuses`, { hideLoader: true });
  },

  getAvailableStatuses(sitePatientIdentifier) {
    return axios.get(`${ROOT}/${sitePatientIdentifier}/statuses`, { hideLoader: true });
  },

  getSitePatientStatus(sitePatientIdentifier) {
    return axios.get(`${ROOT}/${sitePatientIdentifier}/status`, { hideLoader: true });
  },

  changeStatus(sitePatientIdentifier, data) {
    return axios.put(`${ROOT}/${sitePatientIdentifier}/status/change`, data);
  },

  addStatus(sitePatientIdentifier, data) {
    return axios.put(`${ROOT}/${sitePatientIdentifier}/status/add`, data);
  },

  getStatusChangeRecords(ssuPatientId) {
    return axios.get(`${ROOT}/${ssuPatientId}/history`, {}, { hideLoader: false });
  },

  deactivatePatientStatus(ssuPatientId, data) {
    return axios.post(`${ROOT}/${ssuPatientId}/status/remove`, data);
  },

  updateStatuses(ssuId, data) {
    return axios.put(`${ROOT}/${ssuId}/statuses`, data);
  }
};

export default PatientStatusApi;
