import { uniqBy } from 'lodash/array';
import { orderBy } from 'lodash/collection';
import { max, min } from 'lodash/math';
import { get } from 'lodash/object';

export function getPreparedData(tableData, signatures, statuses, metadata) {
  if (tableData && signatures && statuses && metadata) {
    const rowVersions = {};

    const firstSignRowVersion = min(signatures?.map(e => parseInt(e.rowVersion)));
    const lastSignRowVersion = max(signatures?.map(e => parseInt(e.rowVersion)));

    orderBy(tableData, 'rowVersion.value', 'asc').forEach((row, id) => {
      const rowVersion = parseInt(row.rowVersion.value);
      const map = {};
      const previousRowVersion = rowVersion - 1 >= 0 ? rowVersion - 1 : 0;
      Object.keys(row)
        .filter(id => !['Who', 'When', 'Last Updated By', 'Last Updated Date'].includes(id))
        .filter(id => 'rowVersion' !== id)
        .filter(id => metadata?.some(e => e.id === id))
        .forEach(id => {
          const currentAnswer = row[id];
          const prevRow = tableData.find(row => parseInt(row?.rowVersion?.value) === previousRowVersion);
          const previousAnswer = get(prevRow, id);
          const question = metadata?.find(e => e.id === id);

          if (
            previousAnswer &&
            (previousAnswer?.value !== currentAnswer?.value || previousAnswer?.comment !== currentAnswer?.comment)
          ) {
            map[id] = {
              currentAnswer: {
                ...currentAnswer,
                value: currentAnswer.value,
                comment: currentAnswer.comment,
                lastUpdatedBy: row['Last Updated By'],
                lastUpdatedDate: row['Last Updated Date']
              },
              previousAnswer: {
                ...previousAnswer,
                value: previousAnswer.value,
                comment: previousAnswer.comment
              },
              question,
              changed: true,
              signed: rowVersion <= lastSignRowVersion
            };
          }
        });
      rowVersions[rowVersion] = { row: map };
    });
    signatures?.forEach(signature => {
      if (rowVersions[signature.rowVersion]?.signature) {
        rowVersions[signature.rowVersion].signature = uniqBy(
          [...rowVersions[signature.rowVersion].signature, signature],
          o => o.groupId
        );
      } else {
        rowVersions[signature.rowVersion].signature = [signature];
      }
    });
    if (statuses) {
      Object.keys(rowVersions).forEach(rowVersion => {
        rowVersions[rowVersion].status = statuses.filter(e => e.rowVersion === parseInt(rowVersion)) || [];
        rowVersions[rowVersion].status = orderBy(rowVersions[rowVersion].status, 'createdAt', 'desc');
      });
    }
    return rowVersions;
  }
  return {};
}
