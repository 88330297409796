import { ITEM_GROUP_TEMPLATES } from '../../../../constants/accessToPages';
import ElementSetup from '../shared/ElementSetupNew/ElementSetupNew';
import { TableTemplates } from '../shared/ElementSetupNew/TableSetup/TableSetupTemplate';

import ItemGroupTemplates from './ItemGroupTemplates';

export const itemGroupTemplatesRouterConfig = {
  name: 'Item Group Templates',
  path: '/protocol-item-group-templates',
  component: ItemGroupTemplates,
  access: [ITEM_GROUP_TEMPLATES],
  inherited: [
    {
      name: 'Template Setup',
      path: '/setup',
      component: ElementSetup,
      access: [ITEM_GROUP_TEMPLATES]
    },
    {
      name: 'Template Edit',
      path: '/edit/:itemGroupTemplateId',
      component: ElementSetup,
      access: [ITEM_GROUP_TEMPLATES]
    },
    {
      name: 'Template Copy',
      path: '/copy/:itemGroupTemplateId',
      component: ElementSetup,
      access: [ITEM_GROUP_TEMPLATES]
    },
    {
      name: 'Table Template Setup',
      path: '/table-setup',
      component: TableTemplates,
      access: [ITEM_GROUP_TEMPLATES]
    },
    {
      name: 'Table Template Edit',
      path: '/table-edit/:itemGroupTemplateId',
      component: TableTemplates,
      access: [ITEM_GROUP_TEMPLATES]
    },
    {
      name: 'Table Template Copy',
      path: '/table-copy/:itemGroupTemplateId',
      component: TableTemplates,
      access: [ITEM_GROUP_TEMPLATES]
    }
  ]
};
