import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import TextArea from '../../../../../../common/data-entry/TextArea/TextArea';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../common/general/ButtonGroup';
import {
  findPreviousActiveStatusFromHistory,
  isCurrentStatusRecord
} from '../PatientStatusChangeEdit/PatientStatusChangeEditService';

import './PatientStatusRemoveModal.scss';

const PatientStatusRemoveModal = ({ modalBox, patientStatusHistory, statusToRemove, removeStatus }) => {
  const { handleSubmit, errors, control } = useForm({
    defaultValues: { comment: '' }
  });

  const onSubmit = data => {
    removeStatus(statusToRemove.id, data.comment);
  };

  return (
    <form id="remove-status-form" onSubmit={handleSubmit(onSubmit)}>
      <ModalBoxes.Header>Remove Status</ModalBoxes.Header>
      <ModalBoxes.Body>
        <div className="change-status-confirmation">
          <span className="confirmation-text">
            <b>
              {isCurrentStatusRecord(patientStatusHistory, statusToRemove)
                ? 'Removing current status will change status to previous status.'
                : 'Are you sure you want to remove status?'}
            </b>
          </span>
          <span className="confirmation-status">
            <strike>{statusToRemove.status.name}</strike>
            {isCurrentStatusRecord(patientStatusHistory, statusToRemove) &&
              ` → ${findPreviousActiveStatusFromHistory(patientStatusHistory, statusToRemove).status.name}`}
          </span>
        </div>
        <Controller
          as={TextArea}
          control={control}
          rules={{ required: true }}
          validationMessage={errors.comment ? 'Comment is required.' : undefined}
          style={{ resize: 'none' }}
          rows={1}
          clearable
          label="Comment"
          name="comment"
        />
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            Cancel
          </Button>
          <Button form="remove-status-form">Save</Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </form>
  );
};

PatientStatusRemoveModal.className = 'patient-status-remove-modal';
PatientStatusRemoveModal.size = 'w650';

export default PatientStatusRemoveModal;
