import React from 'react';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash/object';

import { SitePatientEncounterApi } from '../../../../../../../../../../api';
import Button from '../../../../../../../../../../common/general/Button';
import { onRequestError } from '../../../../../../../../../../services/handlers';
import { generateUrlByKey, useCurrentRoute } from '../../../../../../../../../root/router';
import { PATIENT_SITUATIONAL_ENCOUNTER_EVENT } from '../../../../../../../../Calendar/CalendarEventType';
import { useEncountersSection } from '../../../../EncountersSectionContext';
import { useEncounterSegment } from '../EncounterSegment';

export default function HeaderDefault() {
  const currentRoute = useCurrentRoute();
  const navigate = useNavigate();
  const { isCheckinDisabled } = useEncountersSection();
  const { encounter, lastNotCancelledEncounterEvent } = useEncounterSegment();
  const patientId = currentRoute.params?.patientId;
  const { sitePatientEncounter, removedFromProtocol } = encounter;
  const { cancelled } = lastNotCancelledEncounterEvent;

  return (
    <>
      {!sitePatientEncounter && !cancelled && !removedFromProtocol && (
        <Button disabled={isCheckinDisabled} size="h28" onClick={checkIn}>
          Start Encounter
        </Button>
      )}
    </>
  );

  function checkIn() {
    const checkInMethod =
      lastNotCancelledEncounterEvent.type === PATIENT_SITUATIONAL_ENCOUNTER_EVENT
        ? SitePatientEncounterApi.checkInSituational
        : SitePatientEncounterApi.checkIn;
    checkInMethod(lastNotCancelledEncounterEvent.ssuPatientId, lastNotCancelledEncounterEvent.id).then(
      function(res) {
        const ssuPatientEncounterId = res.data;
        navigate(
          getUrlToEncounterDetailsPage(
            lastNotCancelledEncounterEvent.ssuPatientId,
            patientId,
            ssuPatientEncounterId,
            currentRoute
          )
        );
      },
      err => {
        const statusCode = get(err, 'response.status');
        if (statusCode === 409) {
          const ssuPatientEncounterId = err.response.data.existingResourceId;
          navigate(
            getUrlToEncounterDetailsPage(
              lastNotCancelledEncounterEvent.ssuPatientId,
              patientId,
              ssuPatientEncounterId,
              currentRoute
            )
          );
        } else if (statusCode === 403) {
          onRequestError(err, null);
        }
      }
    );
  }
}

function getUrlToEncounterDetailsPage(ssuPatientId, patientId, ssuPatientEncounterId, currentRoute) {
  return generateUrlByKey(`${currentRoute.key}.Encounter Details`, {
    patientId,
    ssuPatientId,
    patientEncounterId: ssuPatientEncounterId
  });
}
