import React, { useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { isEmpty } from 'lodash/lang';

import { FinClientApi } from '../../../../../../api';
import FinVendorApi from '../../../../../../api/finance/FinVendorApi';
import Input from '../../../../../../common/data-entry/Input';
import Select from '../../../../../../common/data-entry/Select';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import { DATA_SAVED } from '../../../../../../constants/notificationMessages';
import {
  canadianProvinces,
  countries,
  getCountryById,
  getRegionById,
  usStates
} from '../../../../../../services/geographic.js';
import { onRequestError } from '../../../../../../services/handlers';
import { normalizePhoneNumberForCard } from '../../../../../../services/normalizers';
import { onChangeZipCode } from '../../AccountService.js';
import { AccountType } from '../../AccountType.js';

import { accountFieldNames, validateAccount } from './AccountCreateModalValidator';

export const AccountCreateModal = ({ type, modalBox, accountType, accountId, updateFunction }) => {
  const [organizationName, setOrganizationName] = useState('');
  const [departmentName, setDepartmentName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState(countries[0]);
  const [city, setCity] = useState('');
  const [state, setState] = useState(null);
  const [invalidFields, setInvalidFields] = useState([]);

  const stateList = useMemo(() => (country?.id === 'US' ? usStates : canadianProvinces), [country?.id]);

  useEffect(() => {
    if (type === 'Edit' && accountId) {
      if (AccountType[accountType] === 'Client') {
        FinClientApi.find(accountId).then(({ data }) => updateData(data));
      } else {
        FinVendorApi.findOne(accountId).then(({ data }) => updateData(data));
      }
    }
  }, [accountId, accountType, type]);

  const updateData = data => {
    setOrganizationName(data.name);
    setDepartmentName(data.departmentName);
    setPhone(data.phone);
    setEmail(data.email);
    setAddress1(data.address1);
    setAddress2(data.address2);
    setZipCode(data.zipCode);
    setCity(data.city);
    setCountry(getCountryById(data.country));
    setState(getRegionById(data.country === 'US' ? usStates : canadianProvinces, data.state));
  };

  const saveClient = accountDto => {
    FinClientApi.save(accountDto)
      .then(() => {
        modalBox.close();
        updateFunction();
        NotificationManager.success(DATA_SAVED);
      })
      .catch(onRequestError);
  };

  const saveVendor = accountDto => {
    FinVendorApi.create(accountDto)
      .then(() => {
        modalBox.close();
        updateFunction();
        NotificationManager.success(DATA_SAVED);
      })
      .catch(onRequestError);
  };

  const updateClient = accountDto => {
    FinClientApi.update(accountDto)
      .then(() => {
        modalBox.close();
        updateFunction();
        NotificationManager.success(DATA_SAVED);
      })
      .catch(onRequestError);
  };

  const updateVendor = accountDto => {
    FinVendorApi.update(accountDto)
      .then(() => {
        modalBox.close();
        updateFunction();
      })
      .catch(onRequestError);
  };

  const onCreateOrUpdateAccount = () => {
    const accountDto = {
      name: organizationName,
      departmentName,
      phone,
      email,
      address1,
      zipCode,
      country: country?.id ?? null,
      city,
      state: state?.id ?? null
    };

    const invalidFieldNames = validateAccount(accountDto);
    if (!isEmpty(invalidFieldNames)) {
      setInvalidFields(invalidFieldNames);
      return;
    }

    if (type === 'Add') {
      if (AccountType[accountType] === 'Client') {
        saveClient({ ...accountDto, address2 });
      } else {
        saveVendor({ ...accountDto, address2 });
      }
    } else {
      if (AccountType[accountType] === 'Client') {
        updateClient({ ...accountDto, address2, id: accountId });
      } else {
        updateVendor({ ...accountDto, address2, id: accountId });
      }
    }
  };

  const validateField = fieldName => {
    return !isEmpty(invalidFields) ? !invalidFields.includes(fieldName) : true;
  };

  return (
    <>
      <ModalBoxes.Header>
        <div>
          <span className="header-text">
            {type} {AccountType[accountType]} Details
          </span>
        </div>
      </ModalBoxes.Header>
      <ModalBoxes.Body>
        <div className="account-details">
          <Input
            data-testid="organization-name-input"
            className="add-account-input"
            label="Organization Name"
            value={organizationName}
            onChange={({ target: { value } }) => {
              if (!isEqual(organizationName, value)) setOrganizationName(value);
            }}
            required={true}
            valid={validateField(accountFieldNames.ORGANIZATION_NAME)}
            maxLength={AccountType[accountType] === 'Client' ? 50 : 250}
          />
          <Input
            data-testid="department-name-input"
            className="add-account-input"
            label="Department/Contact Name"
            value={departmentName}
            onChange={({ target: { value } }) => {
              if (!isEqual(departmentName, value)) setDepartmentName(value);
            }}
            required={true}
            valid={validateField(accountFieldNames.DEPARTMENT_NAME)}
            maxLength={AccountType[accountType] === 'Client' ? 55 : 250}
          />
          <Input
            data-testid="phone-input"
            className="add-account-input"
            label="Primary Phone"
            value={phone}
            onChange={({ target: { value } }) => {
              if (!isEqual(phone, value)) setPhone(normalizePhoneNumberForCard(value));
            }}
            required={true}
            valid={validateField(accountFieldNames.PRIMARY_PHONE)}
          />
          <Input
            data-testid="email-input"
            className="add-account-input"
            label="Contact Email"
            value={email}
            onChange={({ target: { value } }) => {
              if (!isEqual(email, value)) setEmail(value);
            }}
            required={true}
            valid={validateField(accountFieldNames.CONTACT_EMAIL)}
            maxLength={AccountType[accountType] === 'Client' ? 50 : 250}
          />
          <Input
            data-testid="address1-input"
            className="add-account-input"
            label="Address 1"
            value={address1}
            onChange={({ target: { value } }) => {
              if (!isEqual(address1, value)) setAddress1(value);
            }}
            required={true}
            valid={validateField(accountFieldNames.ADDRESS_1)}
            maxLength={AccountType[accountType] === 'Client' ? 45 : 250}
          />
          <Input
            data-testid="address2-input"
            className="add-account-input"
            label="Address 2"
            value={address2}
            onChange={({ target: { value } }) => {
              if (!isEqual(address2, value)) setAddress2(value);
            }}
            maxLength={AccountType[accountType] === 'Client' ? 45 : 250}
          />
          <Input
            data-testId="postal-code-input"
            className="add-account-input"
            label="ZIP/Postal Code"
            value={zipCode}
            onChange={({ target: { value } }) =>
              onChangeZipCode(value, country, setCountry, zipCode, setZipCode, setState)
            }
            required={true}
            valid={validateField(accountFieldNames.ZIP_CODE)}
          />
          <Select
            data-testid="country-dropdown"
            className="add-account-input"
            label="Country"
            clearSearchOnSelection={false}
            searchable={false}
            clearable={false}
            closeOnSelectedOptionClick={false}
            deselectOnSelectedOptionClick={false}
            value={country}
            onChange={newValue => {
              if (!isEqual(country, newValue)) {
                setCountry(newValue);
                setState(null);
                setZipCode(null);
              }
            }}
            dataSource={countries}
            required={true}
            optionLabelKey="id"
            valid={validateField(accountFieldNames.COUNTRY)}
          />
          <Input
            data-testid="city-input"
            className="add-account-input"
            label="City"
            value={city}
            onChange={({ target: { value } }) => {
              if (!isEqual(city, value)) setCity(value);
            }}
            required={true}
            valid={validateField(accountFieldNames.CITY)}
            maxLength={AccountType[accountType] === 'Client' ? 25 : 250}
          />
          <Select
            data-testid="state-dropdown"
            className="add-account-input"
            label="State/Province"
            clearSearchOnSelection={false}
            searchable
            clearable={false}
            closeOnSelectedOptionClick={false}
            deselectOnSelectedOptionClick={false}
            value={state}
            onChange={newValue => {
              if (!isEqual(state, newValue)) setState(newValue);
            }}
            dataSource={stateList}
            required={true}
            optionLabelKey="id"
            valid={validateField(accountFieldNames.STATE)}
          />
        </div>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            Cancel
          </Button>
          <Button priority="high" onClick={onCreateOrUpdateAccount}>
            Save
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
};
