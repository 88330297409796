import { HELP } from '../../../constants/accessToPages';

import Help from './Help';

export const helpRouterConfig = {
  name: 'Help',
  path: '/help',
  component: Help,
  access: [HELP]
};
