import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { PatientStatusJourneyApi } from '../../../../../../api';

import ProgressItem from './ProgressItem';

import './PatientStatusProgress.scss';

const PatientStatusProgress = ({ ssuPatientId, progressTrigger, addMissingStatus, displayAddButton }) => {
  const [progress, setProgress] = useState();

  useEffect(() => {
    if (ssuPatientId) {
      PatientStatusJourneyApi.getStatusJourneyInfo(ssuPatientId).then(({ data }) => {
        setProgress(data);
      });
    }
  }, [ssuPatientId, progressTrigger]);

  return (
    <>
      <div className="status-progress-header">
        <p>Progress</p>
      </div>
      <div className="status-progress-wrapper">
        {progress?.map((item, index) => {
          const dateText = () => {
            if (item.skipped && item.optional) {
              return 'Missing (Optional)';
            } else if (!item.createdAt && item.skipped) {
              return 'Missing';
            } else if (!item.createdAt && progress[index - 1]?.current && item.optional) {
              return 'Next (Optional)';
            } else if (!item.createdAt && progress[index - 1]?.current) {
              return 'Next';
            } else if (!item.createdAt && item.optional) {
              return 'Optional';
            } else if (!item.createdAt) {
              return null;
            }
            return moment(item.createdAt).format('DD/MMM/YYYY');
          };
          return (
            <ProgressItem
              key={item.name}
              first={index === 0}
              optional={item.optional}
              skipped={item.skipped}
              current={item.current}
              failed={item.dropStatus}
              name={item.name}
              date={dateText()}
              notHappened={!item.createdAt}
              previousSkipped={progress[index - 1]?.skipped}
              previousFailed={progress[index - 1]?.dropStatus}
              previousOptional={progress[index - 1]?.optional}
              previousHappened={progress[index - 1]?.createdAt}
              addMissingStatus={addMissingStatus}
              displayAddButton={displayAddButton}
            />
          );
        })}
      </div>
    </>
  );
};

export default PatientStatusProgress;
