import React from 'react';

import './ItemGroupNotPerformed.scss';

export default function ItemGroupNotPerformed({ notPerformedReason }) {
  return (
    <div className="rie-not-performed">
      <span>Not Performed: </span>
      {notPerformedReason}
    </div>
  );
}
