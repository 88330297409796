import React, { useState } from 'react';
import { isEmpty, isFunction } from 'lodash/lang';
import PropTypes from 'prop-types';

import IconButton from '../../../../../../common/buttons/IconButton';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import EditCustomFieldLogicModal from '../EditCustomFieldLogicModal';

import './CustomFieldConfiguration.scss';
export default function CustomFieldConfiguration({ item, allInputs, onChange }) {
  const [showInfo, setShowInfo] = useState(null);

  const showInfoIfExist = event => {
    if (item.fieldConfigurationResponse) {
      const positionElementToTop = document.documentElement.clientHeight - event.clientY < 150;

      const style = {
        top: positionElementToTop ? 'auto' : '-20px',
        bottom: positionElementToTop ? '-20px' : 'auto',
        zIndex: '100'
      };
      setShowInfo({
        style: style,
        item: item.fieldConfigurationResponse
      });
    }
  };

  const onEditCustomFieldLogic = function() {
    const editCustomFieldLogic = ModalBoxes.open({
      component: (
        <EditCustomFieldLogicModal
          onConfirm={() => {
            editCustomFieldLogic.close();
            isFunction(onChange) && onChange();
          }}
          customField={item}
          allInputs={allInputs}
        />
      )
    });
  };

  return (
    <div className="custom-field-config">
      <IconButton
        className="edit-conditional-configuration-icon"
        title="Edit Conditional Logic"
        color={!isEmpty(item.fieldConfigurationResponse) || item?.hideAllConfiguration ? 'red' : 'blue'}
        onMouseEnter={showInfoIfExist}
        onMouseLeave={() => setShowInfo(null)}
        onClick={onEditCustomFieldLogic}
      >
        app_registration
      </IconButton>

      {showInfo && item.fieldConfigurationResponse && (
        <div className="info" style={showInfo.style}>
          <strong>HIDE WHEN</strong>
          <div className="pt-2">Field</div>
          <strong>
            {item?.fieldConfigurationResponse?.conditionField?.updatedQuestion
              ? item?.fieldConfigurationResponse?.conditionField?.updatedQuestion
              : item?.fieldConfigurationResponse?.conditionField?.name ||
                item?.fieldConfigurationResponse?.conditionFieldName}
          </strong>
          <div className="pt-2">Answer</div>
          <strong>{item?.fieldConfigurationResponse?.conditionFieldAnswerName}</strong>
        </div>
      )}
    </div>
  );
}

CustomFieldConfiguration.propTypes = {
  item: PropTypes.object,
  allInputs: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func
};
