import { Link } from 'react-router-dom';
import { ROLE_CRA, ROLE_EXTERNAL_AUDITOR, ROLE_FINANCE } from 'constants/userRoles';
import { userHasRole } from 'services/auth';

export default function TaskDetailsPatientInfo({ taskForm, patientStudiesPageLink, patientEncounterPageLink }) {
  const {
    patientSubjectId,
    patientFirstName,
    patientLastName,
    study,
    site,
    protocolEncounter,
    protocolItemGroup,
    patientId
  } = taskForm;

  const userPatientInfoProtectedRole = userHasRole([ROLE_CRA, ROLE_EXTERNAL_AUDITOR, ROLE_FINANCE]);

  return (
    <div className="etc-body-details">
      {patientId && (
        <div className="etc-body-details-block">
          <div className="etc-body-details-block-label">Subject ID</div>
          <div className="etc-body-details-block-value">
            {patientStudiesPageLink ? (
              <Link to={patientStudiesPageLink}>{patientSubjectId ? patientSubjectId : '-'}</Link>
            ) : patientSubjectId ? (
              patientSubjectId
            ) : (
              '-'
            )}
          </div>
        </div>
      )}
      {patientId && !userPatientInfoProtectedRole && (
        <div className="etc-body-details-block">
          <div className="etc-body-details-block-label">Patient</div>
          <div className="etc-body-details-block-value">{`${patientFirstName} ${patientLastName}`}</div>
        </div>
      )}
      {!!study?.name && (
        <div className="etc-body-details-block">
          <div className="etc-body-details-block-label">Study</div>
          <div className="etc-body-details-block-value">{study?.name}</div>
        </div>
      )}
      {!!site?.name && (
        <div className="etc-body-details-block">
          <div className="etc-body-details-block-label">Site</div>
          <div className="etc-body-details-block-value">{site?.name}</div>
        </div>
      )}
      {!!protocolEncounter && (
        <div className="etc-body-details-block">
          <div className="etc-body-details-block-label">Encounter</div>
          <div className="etc-body-details-block-value">
            {patientEncounterPageLink ? (
              <Link to={patientEncounterPageLink}>{protocolEncounter?.name}</Link>
            ) : (
              protocolEncounter?.name
            )}
          </div>
        </div>
      )}
      {!!protocolItemGroup && (
        <div className="etc-body-details-block">
          <div className="etc-body-details-block-label">Procedure / Logs</div>
          <div className="etc-body-details-block-value">{protocolItemGroup}</div>
        </div>
      )}
    </div>
  );
}
