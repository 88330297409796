import React, { useMemo } from 'react';
import cx from 'classnames';

import Icon from '../../../../../../../common/general/Icon';
import { LOGS } from '../../../Review/reviewConstants';
import { HighlightForReviewIcon } from '../../HighlightForReviewIcon/HighlightForReviewIcon';
import { getIcon, getIconSuit } from '../../reviewService';

import './ReviewSidebarItem.scss';

const ITM_PENDING = 'ITM_PENDING';
const ITM_SIGNED = 'ITM_SIGNED';
const ITM_NOT_REQUIRED = 'ITM_NOT_REQUIRED';
const ITM_DEFAULT = 'ITM_DEFAULT';
const ITM_DISABLED = 'ITM_DISABLED';
const ITM_DATA_REQUIRED = 'ITM_DATA_REQUIRED';

const itemStatusMap = {
  [ITM_DATA_REQUIRED]: {
    status: 'Data Required',
    classNameSuffix: 'pending'
  },
  [ITM_PENDING]: {
    status: 'Pending Signature',
    classNameSuffix: 'pending'
  },
  [ITM_SIGNED]: {
    status: 'Signed',
    classNameSuffix: 'signed'
  },
  [ITM_NOT_REQUIRED]: {
    status: 'Signature Not Required',
    classNameSuffix: 'not-required'
  },
  [ITM_DEFAULT]: {
    status: '',
    classNameSuffix: 'default'
  },
  [ITM_DISABLED]: {
    status: '',
    classNameSuffix: 'disabled'
  }
};

export default function ReviewSidebarItem({
  isNotRequired,
  hasRecords,
  name,
  type,
  loading,
  isNotPerformed,
  isSigned,
  onClick,
  highlightedAnswersCount,
  isHistoricalData,
  isDataRequired,
  isItemGroupConfirmed,
  className,
  isEncounterExcludedLog
}) {
  const { status, classNameSuffix } = useMemo(
    function() {
      if (loading) {
        return itemStatusMap[ITM_DISABLED];
      }

      if (isEncounterExcludedLog) {
        return itemStatusMap[ITM_DEFAULT];
      }

      if (isSigned) {
        return itemStatusMap[ITM_SIGNED];
      }

      if (isNotRequired && !isHistoricalData) {
        return itemStatusMap[ITM_NOT_REQUIRED];
      }

      if (isDataRequired && !isHistoricalData) {
        return itemStatusMap[ITM_DATA_REQUIRED];
      }

      if (hasRecords && !isHistoricalData) {
        return itemStatusMap[ITM_PENDING];
      }
      return itemStatusMap[ITM_DEFAULT];
    },
    [isHistoricalData, hasRecords, isDataRequired, isSigned, loading, isNotRequired, isEncounterExcludedLog]
  );

  return (
    <div onClick={onClick} className={cx('erv-sidebar-item', className, `erv-sidebar-item-${classNameSuffix}`)}>
      <div className="erv-sidebar-item-icon">
        {loading ? (
          <span className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </span>
        ) : (
          <Icon suit={getIconSuit(type)}>{getIcon(type)}</Icon>
        )}
      </div>
      <div className="erv-sidebar-item-name-and-status">
        <div className="erv-sidebar-item-name" title={name}>
          {name}
        </div>
        <div className="erv-sidebar-item-status">{status}</div>
      </div>
      <div className="erv-sidebar-item-mark">{getIconStatusOfItemGroup()}</div>
    </div>
  );

  function getIconStatusOfItemGroup() {
    const isLogItemGroup = type === LOGS;
    if (loading) {
      return null;
    }

    if (isNotPerformed) {
      return <div className="erv-sidebar-item-mark-not-performed">NP</div>;
    }

    if (isSigned && !isLogItemGroup) {
      return null;
    }
    if (highlightedAnswersCount > 0) {
      return <HighlightForReviewIcon highlighted confirmed={isItemGroupConfirmed} />;
    }
  }
}

ReviewSidebarItem.defaultProps = {
  isNotRequired: true,
  isDataRequired: true,
  isNotPerformed: false,
  isSigned: false
};
