import { useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

import LoaderOverlay from '../../../../../common/elements/Loader/LoaderOverlay';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { getPatientAgeFromDob, getPatientFullName, getPatientPreferredName } from '../../../../../services/patient';
import { PageInfoHeader } from '../../../../PageInfoHeader/PageInfoHeader';
import { generateUrlByKey, useCurrentRoute } from '../../../../root/router';
import { dateFormatForShow } from '../PatientStatusChange/PatientStatusChangeConstants';

import PatientProfileSidebarTab from './PatientProfileSidebarTab/PatientProfileSidebarTab';
import GoToPatientStudiesButton from './GoToPatientStudiesButton';
import { usePatientProfile, withPatientProfile } from './PatientProfileContext';

import './PatientProfile.scss';

function PatientProfile() {
  const currentRoute = useCurrentRoute();
  const navigate = useNavigate();
  const { patientInfo, dictionariesMap, studyHistory, hasAccessToPatient } = usePatientProfile();
  const isPatientUnavailableToContact = patientInfo?.isDeceased || patientInfo?.phonesInfo?.neverCallAgain;
  const isDataLoaded = !isEmpty(dictionariesMap) && !isEmpty(patientInfo) && !isEmpty(studyHistory);

  useEffect(() => {
    let timeout;
    if (!isDataLoaded && hasAccessToPatient === false) {
      NotificationManager.error('You do not have access to the patient, redirecting you to the home page');
      timeout = setTimeout(() => navigate('/'), 2000);
    }

    return () => clearTimeout(timeout);
  }, [isDataLoaded, hasAccessToPatient, navigate]);

  if (!isDataLoaded) {
    return <LoaderOverlay className="eds-loader-manager" />;
  }

  return (
    <>
      <PageInfoHeader
        objectRecordLabel={getPatientFullName(patientInfo) + getPatientPreferredName(patientInfo)}
        pageInfo={
          <PageInfoHeader.CollapsibleList>
            <PageInfoHeader.AdditionalInfo title="Patient ID">{patientInfo?.patientId}</PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo tooltip="Date of Birth">
              {patientInfo?.dob?.format(dateFormatForShow)} ({getPatientAgeFromDob(patientInfo)})
            </PageInfoHeader.AdditionalInfo>
            {patientInfo?.sex && (
              <PageInfoHeader.AdditionalInfo title="Sex">
                {dictionariesMap?.sexCodes[patientInfo?.sex]?.name}
              </PageInfoHeader.AdditionalInfo>
            )}
            {patientInfo?.phonesInfo?.primary && (
              <PageInfoHeader.AdditionalInfo title="Phone Number">
                {patientInfo?.phonesInfo?.primary}
              </PageInfoHeader.AdditionalInfo>
            )}
          </PageInfoHeader.CollapsibleList>
        }
      />
      <GoToPatientStudiesButton />
      <div className="patient-profile">
        <div className="patient-profile-sidebar">
          <PatientProfileSidebarTab isPatientUnavailableToContact={isPatientUnavailableToContact}>
            Patient Details
          </PatientProfileSidebarTab>
          {/*<PatientProfileSidebarTab>Patient Studies</PatientProfileSidebarTab>*/}
          <PatientProfileSidebarTab>Medications</PatientProfileSidebarTab>
          <PatientProfileSidebarTab>Medical History</PatientProfileSidebarTab>
          {/*<PatientProfileSidebarTab>Patient Files</PatientProfileSidebarTab>*/}
        </div>
        <div className="patient-profile-content">
          {currentRoute.name === 'Patient Profile' ? (
            <Navigate to={generateUrlByKey(`${currentRoute.key}.Patient Details`, currentRoute.params)} replace />
          ) : (
            <Outlet />
          )}
        </div>
      </div>
    </>
  );
}

export default withPatientProfile(PatientProfile);
