import React from 'react';
import Tooltip from 'rc-tooltip';

export default function AdditionalInfo({ title, tooltip, children, ...rest }) {
  let visibility = {};
  if (!tooltip) {
    visibility = { visible: false };
  }
  return (
    <Tooltip
      {...visibility}
      destroyTooltipOnHide={false}
      align={{
        offset: [0, -5]
      }}
      placement="bottom"
      overlay={tooltip || title}
      overlayClassName="eds-rc-tooltip"
    >
      <div className="additional-info-item" {...rest}>
        {title && <div className="additional-info-item-title">{title}:&nbsp;</div>}
        <div>{children}</div>
      </div>
    </Tooltip>
  );
}
