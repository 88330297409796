import React, { useEffect, useState } from 'react';
import { isEmpty, isEqual } from 'lodash/lang';
import PropTypes from 'prop-types';

import Select from '../../../../../common/data-entry/Select/Select';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';
import { ToggleSelect } from '../../../../../common/inputs/ToggleSelect/ToggleSelect';
import NotificationManager from '../../../../../common/notifications/NotificationManager';

import { getAllToggles } from './ProtocolItemGroupFieldConfigurationService';

import './EditCustomFieldLogicModal.scss';

const visibilityOptions = [
  { name: 'No', value: true },
  { name: 'Yes', value: false }
];
const optionsForNY = [
  { name: 'Yes', value: 'Yes' },
  { name: 'No', value: 'No' }
];

function EditCustomFieldLogicModal({ customField, modalBox, onConfirm, allInputs }) {
  const [fields, setFields] = useState([]);
  const fieldConfigurationResponse = customField?.fieldConfigurationResponse;

  useEffect(
    function() {
      if (!isEmpty(fields)) {
        const conditionField =
          fieldConfigurationResponse?.conditionField?.name || fieldConfigurationResponse?.conditionFieldName;
        setField(fields.find(({ name }) => name === conditionField));
      }
    },
    [fieldConfigurationResponse?.conditionField?.name, fieldConfigurationResponse?.conditionFieldName, fields]
  );
  const [field, setField] = useState({});
  const [visibility, setVisibility] = useState(!fieldConfigurationResponse);
  const [whenToHide, setWhenToHide] = useState(fieldConfigurationResponse?.conditionFieldAnswerName || null);
  const [whenToHideOptions, setWhenToHideOptions] = useState([]);

  useEffect(
    function() {
      //filter self item if it is toggle
      const allToggles = getAllToggles(allInputs).filter(e => e.sequence !== customField.sequence);

      setFields(allToggles);
    },
    [allInputs, customField.sequence]
  );

  useEffect(
    function() {
      if (!isEmpty(field)) {
        let options = [];
        if (field.ctListName === '{NY}') {
          //if is regular (optional) item
          options = optionsForNY;
        } else {
          //if is custom item
          options = field.codeDefinationList.map(e => {
            return { name: e.decode, value: e.name };
          });
        }

        if (!isEqual(whenToHideOptions, options)) {
          setWhenToHideOptions(options);
        }
      }
    },
    [field, whenToHideOptions]
  );

  useEffect(
    function() {
      if (whenToHideOptions && whenToHideOptions.length) {
        //select first option if nothing was selected previously or selected value doesn't match with any of options' value
        const value =
          whenToHide && whenToHideOptions.map(e => e.value).includes(whenToHide)
            ? whenToHide
            : whenToHideOptions[0].value;
        if (whenToHide !== value) {
          setWhenToHide(value);
        }
      }
    },
    [whenToHideOptions, whenToHide]
  );

  useEffect(
    function() {
      if (visibility === true) {
        setField(null);
      }
    },
    [visibility]
  );

  const getAllConditionalParentSequence = startField => {
    if (!isEmpty(startField)) {
      if (!isEmpty(startField?.fieldConfigurationResponse)) {
        return [
          startField.sequence,
          getAllConditionalParentSequence(startField.fieldConfigurationResponse.conditionField)
        ].flat();
      } else {
        return [startField.sequence].flat();
      }
    } else {
      return [];
    }
  };

  const save = () => {
    const allConditionalParentsForSelectedFields = getAllConditionalParentSequence(field);
    if (allConditionalParentsForSelectedFields.includes(customField.sequence)) {
      return NotificationManager.error('You cannot save conditional logic with a circular dependency');
    }
    if (visibility === true) {
      customField.fieldConfigurationResponse = null;
    } else {
      if (fieldConfigurationResponse) {
        customField.fieldConfigurationResponse.visibility = visibility;
        customField.fieldConfigurationResponse.conditionField = field;
        customField.fieldConfigurationResponse.conditionFieldAnswerName = whenToHide;
        customField.fieldConfigurationResponse.whenToHide = whenToHide;
        customField.fieldConfigurationResponse.conditionFieldSequence = field.sequence;
      } else {
        customField.fieldConfigurationResponse = {
          visibility,
          conditionField: field,
          conditionFieldAnswerName: whenToHide,
          whenToHide: whenToHide,
          conditionFieldSequence: field.sequence
        };
      }
    }

    onConfirm({
      customField
    });
  };

  return (
    <>
      <ModalBoxes.Header>Edit Custom Field Logic</ModalBoxes.Header>
      <ModalBoxes.Body>
        <div>
          <h5>{customField?.updatedQuestion || customField?.name}</h5>
          <h5>Hide field based on a selected field value?</h5>
        </div>
        <div className="mt-4">
          <ToggleSelect
            listOfItem={visibilityOptions}
            initialValue={visibility}
            onChange={e => {
              if (visibility !== e) {
                setVisibility(e);
              }
            }}
          />
          {!visibility && (
            <Select
              value={field}
              className="mt-4"
              label="Select Field"
              dataSource={fields}
              optionLabelKey="name"
              optionValueKey="name"
              disabled={visibility === true}
              onChange={e => {
                if (e !== field) {
                  setField(e);
                }
              }}
              clearable={false}
              customOptionTemplateFunction={customOptionTemplateFunction}
            />
          )}
          {visibility === false && field && (
            <React.Fragment>
              <h5 className="mr-3 mt-2">When to hide:</h5>
              <ToggleSelect
                listOfItem={whenToHideOptions}
                initialValue={whenToHide}
                onChange={e => {
                  if (e !== whenToHide && whenToHideOptions.length && whenToHideOptions.map(e => e.value).includes(e)) {
                    setWhenToHide(e);
                  }
                }}
              />
            </React.Fragment>
          )}
        </div>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            CANCEL
          </Button>
          <Button disabled={visibility === false && !field} onClick={save}>
            SAVE
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
}

function customOptionTemplateFunction({ updatedQuestion, question }) {
  return <React.Fragment>{updatedQuestion ? updatedQuestion : question}</React.Fragment>;
}

EditCustomFieldLogicModal.className = 'edit-custom-field-logic-modal';
EditCustomFieldLogicModal.size = 'w650';

export default EditCustomFieldLogicModal;

EditCustomFieldLogicModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  customField: PropTypes.object,
  allInputs: PropTypes.arrayOf(PropTypes.object)
};
