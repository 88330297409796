import React from 'react';

import './FvNoViewer.scss';

export function FvNoViewer({ file }) {
  return (
    <div className="eds-fv-no-viewer">
      File can not be displayed.{' '}
      <a href={file.url} rel="noopener noreferrer" target="_blank" download={file.name}>
        Download {file.name}
      </a>
    </div>
  );
}
