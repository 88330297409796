import React from 'react';
import { isFunction } from 'lodash/lang';
import moment from 'moment';
import PropTypes from 'prop-types';

import CheckboxTemplate from '../../../../GenerateHtml/CheckboxTemplate';
import DateTemplate from '../../../../GenerateHtml/DateTemplate';
import DropdownTemplate from '../../../../GenerateHtml/DropdownTemplate';
import LabelTemplate from '../../../../GenerateHtml/LabelTemplate';
import MultipleFileTemplate from '../../../../GenerateHtml/MultipleFileTemplate';
import RadioTemplate from '../../../../GenerateHtml/RadioTemplate';
import ReminderTemplate from '../../../../GenerateHtml/ReminderTemplate';
import TextareaTemplate from '../../../../GenerateHtml/TextAreaTemplate';
import TextTemplate from '../../../../GenerateHtml/TextTemplate';
import TimeTemplate from '../../../../GenerateHtml/TimeTemplate';

export default function FieldPreview(props) {
  const { data } = props;
  const listRl = renderElement(data, props);

  return (
    <div className="col-md-3 p-0">
      {listRl.map((v, idx) => {
        return (
          v && (
            <div key={idx} className="pr-1 col-md-12 pl-0">
              {v}
            </div>
          )
        );
      })}
    </div>
  );

  function renderElement(question, props) {
    const eleList = [];
    if (questionHasAnswersToChoose(question) && questionHasCodeDefinitionList(question)) {
      eleList.push(
        getElementByName(
          question.codeDefinationList,
          question.codeDefinationList && question.codeDefinationList[0].inputType,
          props
        )
      );
    } else {
      eleList.push(getElementByName(question, question.inputType, props));
    }
    return eleList;
  }

  function getElementByName(data, type, props) {
    switch (type) {
      case 'textbox':
        return (
          <TextTemplate
            templateData={data}
            onChangeTextHandler={props.onChangeTextHandler}
            formId={props.formId}
            formLabelId={props.formLabelId}
            itemId={props.itemId}
            customClassObject={props.customClassObject && props.customClassObject.textbox}
          />
        );
      case 'dropdown':
        return (
          <DropdownTemplate
            templateData={data}
            fullData={props.data}
            formId={props.formId}
            formLabelId={props.formLabelId}
            itemId={props.itemId}
            onChangeDropDownHandler={props.onChangeDropDownHandler}
            customClassObject={props.customClassObject && props.customClassObject.dropdown}
          />
        );
      case 'date':
        return (
          <DateTemplate
            value={data.inputValue}
            legacyLook
            placeholder={'date'}
            onChange={(e, formattedDate) => {
              let date = null;
              if (e && e._d) {
                date = moment(e._d);
                date.formattedDate = formattedDate;
              }
              isFunction(props.onDateChangeHandler) &&
                props.onDateChangeHandler(date, props.formLabelId, props.itemId, data);
            }}
          />
        );
      case 'radio':
        return (
          <RadioTemplate templateData={data} onChangeRadioHandler={props.onChangeRadioHandler} fullData={props.data} />
        );
      case 'textarea':
        return (
          <TextareaTemplate
            templateData={data}
            onChangeTextHandler={props.onChangeTextHandler}
            formId={props.formId}
            formLabelId={props.formLabelId}
            itemId={props.itemId}
            customClassObject={props.customClassObject && props.customClassObject.textarea}
          />
        );
      case 'time':
        return (
          <TimeTemplate
            templateData={data}
            onchangeTimeHandler={props.onchangeTimeHandler}
            formId={props.formId}
            formLabelId={props.formLabelId}
            itemId={props.itemId}
            customClassObject={props.customClassObject && props.customClassObject.time}
          />
        );
      case 'checkbox':
        return (
          <CheckboxTemplate
            disabled={props.disabled}
            templateData={data}
            onchangeCheckboxHandler={props.onchangeCheckboxHandler}
            fullData={props.data}
            formId={props.formId}
            formLabelId={props.formLabelId}
            itemId={props.itemId}
            customClassObject={props.customClassObject && props.customClassObject.checkbox}
          />
        );
      case 'label':
        return (
          <LabelTemplate
            templateData={data}
            formId={props.formId}
            formLabelId={props.formLabelId}
            itemId={props.itemId}
            onChangeLabelTextHandler={props.onChangeLabelTextHandler}
            customClassObject={props.customClassObject && props.customClassObject.label}
          />
        );
      case 'file':
        return (
          <MultipleFileTemplate
            templateData={data}
            formGroupIdentifier={props.formGroupIdentifier}
            onChangeFileHandler={props.onChangeFileHandler}
            deleteFile={props.deleteFile}
            isAbleToEditFunction={props.isAbleToEdit}
            onCloseSelectedFile={props.onCloseSelectedFile}
          />
        );
      case 'singleFile':
        return (
          <MultipleFileTemplate
            templateData={data}
            formGroupIdentifier={props.formGroupIdentifier}
            onChangeFileHandler={props.onChangeFileHandler}
            deleteFile={props.deleteFile}
            isAbleToEditFunction={props.isAbleToEdit}
            onCloseSelectedFile={props.onCloseSelectedFile}
          />
        );
      case 'reminder':
        return (
          <ReminderTemplate
            templateData={data}
            customClassObject={props.customClassObject && props.customClassObject.reminder}
          />
        );
      case 'multiselect':
        return (
          <DropdownTemplate
            templateData={data}
            fullData={props.data}
            formId={props.formId}
            formLabelId={props.formLabelId}
            itemId={props.itemId}
            onChangeDropDownHandler={props.onChangeDropDownHandler}
            customClassObject={props.customClassObject && props.customClassObject.multiselect}
          />
        );
      default:
        return '';
    }
  }

  function questionHasCodeDefinitionList(question) {
    return question.hasOwnProperty('codeDefinationList') && Object.keys(question.codeDefinationList).length > 0;
  }

  function questionHasAnswersToChoose(question) {
    return ['radio', 'dropdown', 'dropdownCT', 'multiselect', 'multiselectCT'].includes(question.inputType);
  }
}

FieldPreview.propTypes = {
  labelText: PropTypes.string,
  data: PropTypes.object,
  disabled: PropTypes.bool,
  formId: PropTypes.string,
  formLabelId: PropTypes.string,
  customClassObject: PropTypes.object,
  itemDefIndex: PropTypes.number,
  onChangeLabelTextHandler: PropTypes.func
};
