import * as React from 'react';
import { useCallback, useContext, useState } from 'react';

import { FinanceTableMUI } from '../../shared/FinanseTableMUI/FinanceTableMUI';
import { InvoiceContext } from '../NewInvoiceContext';

import { SelectedItemsMenu } from './SelectedItemsMenu/SelectedItemsMenu';
import { ACTIONS_BUTTON, COLUMNS_HIDDEN_BY_DEFAULT, TABLE_CHECKBOX } from './TableConstants';
import useInvoiceTableColumns from './useInvoiceTableColumns';

import './InvoiceTable.scss';

export const InvoiceTable = () => {
  const { ledgerEvents, selectedEventIds, setSelectedEventIds, firstLoading } = useContext(InvoiceContext);
  const columns = useInvoiceTableColumns();
  const stateFromLocalStorage = JSON.parse(localStorage?.getItem('INVOICE_TABLE_CONFIGURATION'));
  const [pinnedColumns, setPinnedColumns] = useState(
    stateFromLocalStorage?.pinnedColumns || {
      left: [TABLE_CHECKBOX],
      right: [ACTIONS_BUTTON]
    }
  );

  const handlePinnedColumnsChange = useCallback(updatedPinnedColumns => {
    setPinnedColumns({
      ...updatedPinnedColumns,
      left: [TABLE_CHECKBOX, ...updatedPinnedColumns.left.filter(column => column !== TABLE_CHECKBOX)],
      right: [...updatedPinnedColumns.right.filter(column => column !== ACTIONS_BUTTON), ACTIONS_BUTTON]
    });
  }, []);

  const getTogglableColumns = useCallback(
    columns =>
      columns.filter(column => ![ACTIONS_BUTTON, TABLE_CHECKBOX].includes(column.field)).map(column => column.field),
    []
  );

  return (
    <>
      <SelectedItemsMenu />
      <FinanceTableMUI
        localStorageTableName="INVOICE"
        getRowId={row => row.tableId}
        data-testid="new-invoice-table"
        className="MUI-grid-invoice-table"
        rows={ledgerEvents}
        columns={columns}
        checkboxSelection
        disableRowSelectionOnClick
        getTogglableColumns={getTogglableColumns}
        footerElementsCount={ledgerEvents.length}
        initialState={{
          columns: {
            columnVisibilityModel: COLUMNS_HIDDEN_BY_DEFAULT
          },
          pinnedColumns
        }}
        pinnedColumns={pinnedColumns}
        onPinnedColumnsChange={handlePinnedColumnsChange}
        onRowSelectionModelChange={newRowSelectionModel => {
          setSelectedEventIds(newRowSelectionModel);
        }}
        rowSelectionModel={selectedEventIds}
        localeText={{ noRowsLabel: firstLoading ? 'Make selections to display results' : 'No results to display' }}
        tableVersion="1.01"
      />
    </>
  );
};
