import React, { useRef, useState } from 'react';
import cx from 'classnames';

import './FulScreenLayout.scss';

export function NavigationPanel({ children }) {
  const [show, setShow] = useState(true);
  const [preview, setPreview] = useState(false);
  const buttonHover = useRef(false);

  const onWrapperLeave = () => {
    if (!show) {
      setTimeout(() => {
        setPreview(buttonHover.current);
      }, 200);
    }
  };

  const onButtonEnter = () => {
    buttonHover.current = true;
  };

  const onButtonLeave = () => {
    buttonHover.current = false;
    setPreview(false);
  };

  const onArrowClick = () => {
    buttonHover.current = false;
    setPreview(false);
    setShow(!show);
  };
  const hiddenClassConditional = show ? 'opened' : 'closed';
  const icon = show ? 'keyboard_arrow_left' : 'keyboard_arrow_right';
  return (
    <div className={`navigation-flex-container ${hiddenClassConditional}`}>
      <div className={'navigation-body'}>
        <div
          className={cx(`navigation-overlap-wrapper`, { preview })}
          onMouseEnter={() => setPreview(true)}
          onMouseLeave={onWrapperLeave}
        >
          <div className="navigation-panel">{children}</div>
        </div>
        <button
          className="round-button"
          onClick={onArrowClick}
          onMouseEnter={onButtonEnter}
          onMouseLeave={onButtonLeave}
        >
          <i className="material-icons round-button-item ">{icon}</i>
        </button>
      </div>
    </div>
  );
}

export function ContentPanel({ children, showPanelSpace }) {
  return <div className={cx('content-panel', { showPanelSpace })}>{children}</div>;
}

export function FullScreenLayout({ children, className }) {
  return <div className={cx('full-screen-layout', className)}>{children}</div>;
}
