import { useContext, useMemo } from 'react';
import { Button, ButtonGroup } from '@mui/material';

import { STDT_STIPEND, STTS_PAYMENT_TYPES } from '../../../../../../../constants/study/studyTypes';
import { PatientInfoContext } from '../../PatientInfoContext';

import ReimbursementSection from './ReimbursementSection/ReimbursementSection';
import StipendSection from './StipendSection/StipendSection';
import useActiveTab from './useActiveTab';

import './PaymentsSection.scss';

export default function PaymentsSection() {
  const { currentStudy } = useContext(PatientInfoContext);

  const studyTypes = currentStudy.types;

  const paymentTypes = useMemo(
    function() {
      return getPaymentTypes(studyTypes);
    },
    [studyTypes]
  );

  const { activeTab, setActiveTab } = useActiveTab(paymentTypes);

  return (
    <div className="patient-payments-section">
      {paymentTypes.length > 1 && (
        <ButtonGroup>
          {paymentTypes.map(function(button) {
            return (
              <Button
                key={button}
                variant={activeTab === button ? 'contained' : 'outlined'}
                onClick={function() {
                  setActiveTab(button);
                }}
              >
                {button}
              </Button>
            );
          })}
        </ButtonGroup>
      )}
      {activeTab === STDT_STIPEND ? <StipendSection /> : <ReimbursementSection />}
    </div>
  );
}

function getPaymentTypes(types) {
  return types
    .filter(function(type) {
      return STTS_PAYMENT_TYPES.includes(type);
    })
    .sort(function(typeA, typeB) {
      return STTS_PAYMENT_TYPES.indexOf(typeA) - STTS_PAYMENT_TYPES.indexOf(typeB);
    });
}
