import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { Box, IconButton, Stack, TextField, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { isEqual } from 'lodash/lang';

import PatientTravelApi from '../../../../../../../api/patient/PatientTravelApi';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';

import { validationSchema } from './validationSchema';

export default function HotelPreferences({ initialData, setTravelPreferences }) {
  const { patientId } = useParams();
  const [saving, setSaving] = useState(false);
  const [state, setState] = useState({
    preferredHotelChain: '',
    specialAccommodation: '',
    ...initialData
  });

  const { preferredHotelChain, specialAccommodation } = state;

  const { saveAllowed, discardAllowed } = useMemo(
    function() {
      let saveAllowed = false;
      let discardAllowed = false;
      if (!saving && !isEqual(initialData, state)) {
        saveAllowed = true;
        discardAllowed = true;
      }
      return {
        saveAllowed,
        discardAllowed
      };
    },
    [initialData, state, saving]
  );

  useEffect(
    function() {
      setTravelPreferences(function(state) {
        return { ...state, hotelPreferencesEdited: saveAllowed };
      });
    },
    [saveAllowed, setTravelPreferences]
  );

  return (
    <Box sx={{ flexGrow: 1, my: 3 }} component="form">
      <Grid container spacing={2} alignItems="center">
        <Grid xs={6}>
          <Typography variant="subtitle2">Hotel Preferences</Typography>
        </Grid>
        <Grid xs={6}>
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <IconButton aria-label="save" disabled={!saveAllowed} color="primary" onClick={onSave} title="Save">
              <SaveIcon />
            </IconButton>
            <IconButton
              aria-label="discard"
              disabled={!discardAllowed}
              color="primary"
              onClick={onDiscard}
              title="Discard changes"
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </Grid>
        <Grid xs={5}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid xs={6}>
                <TextField
                  name="preferredHotelChain"
                  value={preferredHotelChain}
                  onChange={handleChange}
                  fullWidth
                  label="Preferred Hotel Chain"
                  variant="standard"
                  autoComplete="off"
                />
              </Grid>
              <Grid xs={6}></Grid>
              <Grid xs={12}>
                <TextField
                  name="specialAccommodation"
                  value={specialAccommodation}
                  onChange={handleChange}
                  fullWidth
                  label="Special Accommodation"
                  multiline
                  maxRows={4}
                  variant="standard"
                  inputProps={{ maxLength: 200 }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={7}></Grid>
      </Grid>
    </Box>
  );

  function handleChange({ target }) {
    setState(function(state) {
      try {
        const nextState = { ...state, [target.name]: target.value };
        validationSchema.validateSyncAt(target.name, nextState);
        return nextState;
      } catch (e) {
        return state;
      }
    });
  }

  function onSave() {
    setSaving(true);
    PatientTravelApi.saveHotelPreferences(patientId, state)
      .then(
        function() {
          setTravelPreferences(function(prevState) {
            return { ...prevState, hotelPreferences: state };
          });
          NotificationManager.success('Hotel Preferences saved successfully');
        },
        function(error) {
          NotificationManager.error('Hotel Preferences save error');
        }
      )
      .finally(function() {
        setSaving(false);
      });
  }

  function onDiscard() {
    setState(initialData);
  }
}
