// Object.freeze will not allow the dictionary to
// be altered in anyway. Without this any key could
// be reassigned a new value, and other keys could
// be removed or added. Simply using `const userRoles`
// is not enough since that still allows alterations
// to the dictionary. It just won't allow the `userRoles`
// to point to a different object.
/** @deprecated **/
const userRoles = Object.freeze({
  ROLE_STUDY_MANAGER: 'ROLE_STUDY_MANAGER',
  ROLE_PRINCIPAL_INVESTIGATOR: 'ROLE_PRINCIPAL_INVESTIGATOR',
  ROLE_SUB_INVESTIGATOR: 'ROLE_SUB_INVESTIGATOR',
  ROLE_RECRUITER: 'ROLE_RECRUITER',
  ROLE_EXTERNAL_AUDITOR: 'ROLE_EXTERNAL_AUDITOR',
  ROLE_CRA: 'ROLE_CRA',
  ROLE_STUDY_LEAD: 'ROLE_STUDY_LEAD',
  ROLE_STUDY_ADMINISTRATOR: 'ROLE_STUDY_ADMINISTRATOR',
  ROLE_SYSTEM_ADMINISTRATOR: 'ROLE_SYSTEM_ADMINISTRATOR',
  ROLE_FINANCE: 'ROLE_FINANCE',
  ROLE_FINANCE_ANALYST: 'ROLE_FINANCE_ANALYST',
  ROLE_ELLIGO_MANAGEMENT: 'ROLE_ELLIGO_MANAGEMENT',
  ROLE_OPERATION_MANAGER: 'ROLE_OPERATION_MANAGER',
  ROLE_PATIENT_REPRESENTATIVE: 'ROLE_PATIENT_REPRESENTATIVE',
  ROLE_PATIENT_LIAISON: 'ROLE_PATIENT_LIAISON',
  ROLE_OPERATIONS_ANALYST: 'ROLE_OPERATIONS_ANALYST',
  ROLE_CLINICAL_QUALITY_COORDINATOR: 'ROLE_CLINICAL_QUALITY_COORDINATOR',
  ROLE_SITE_COORDINATOR: 'ROLE_SITE_COORDINATOR',
  ROLE_SITE_FINANCIAL_COORDINATOR: 'ROLE_SITE_FINANCIAL_COORDINATOR'
});

export const ROLE_STUDY_MANAGER = 'ROLE_STUDY_MANAGER';
export const ROLE_PRINCIPAL_INVESTIGATOR = 'ROLE_PRINCIPAL_INVESTIGATOR';
export const ROLE_SUB_INVESTIGATOR = 'ROLE_SUB_INVESTIGATOR';
export const ROLE_RECRUITER = 'ROLE_RECRUITER';
export const ROLE_EXTERNAL_AUDITOR = 'ROLE_EXTERNAL_AUDITOR';
export const ROLE_CRA = 'ROLE_CRA';
export const ROLE_STUDY_LEAD = 'ROLE_STUDY_LEAD';
export const ROLE_STUDY_ADMINISTRATOR = 'ROLE_STUDY_ADMINISTRATOR';
export const ROLE_SYSTEM_ADMINISTRATOR = 'ROLE_SYSTEM_ADMINISTRATOR';
export const ROLE_FINANCE = 'ROLE_FINANCE';
export const ROLE_FINANCE_ADMINISTRATOR = 'ROLE_FINANCE_ADMINISTRATOR';
export const ROLE_FINANCE_ANALYST = 'ROLE_FINANCE_ANALYST';
export const ROLE_OPERATIONS_ANALYST = 'ROLE_OPERATIONS_ANALYST';
export const ROLE_ELLIGO_MANAGEMENT = 'ROLE_ELLIGO_MANAGEMENT';
export const ROLE_OPERATION_MANAGER = 'ROLE_OPERATION_MANAGER';
export const ROLE_PATIENT_REPRESENTATIVE = 'ROLE_PATIENT_REPRESENTATIVE';
export const ROLE_PATIENT_LIAISON = 'ROLE_PATIENT_LIAISON';
export const ROLE_CLINICAL_QUALITY_COORDINATOR = 'ROLE_CLINICAL_QUALITY_COORDINATOR';
export const ROLE_SITE_COORDINATOR = 'ROLE_SITE_COORDINATOR';
export const ROLE_SITE_FINANCIAL_COORDINATOR = 'ROLE_SITE_FINANCIAL_COORDINATOR';

export const SSU_ROLES = [
  ROLE_STUDY_MANAGER,
  ROLE_PRINCIPAL_INVESTIGATOR,
  ROLE_SUB_INVESTIGATOR,
  ROLE_RECRUITER,
  ROLE_EXTERNAL_AUDITOR,
  ROLE_CRA,
  ROLE_OPERATION_MANAGER,
  ROLE_PATIENT_REPRESENTATIVE,
  ROLE_SITE_COORDINATOR,
  ROLE_SITE_FINANCIAL_COORDINATOR
];

export const RESEARCH_STACK_GLOBAL_ROLES = [
  ROLE_ELLIGO_MANAGEMENT,
  ROLE_FINANCE,
  ROLE_FINANCE_ANALYST,
  ROLE_OPERATIONS_ANALYST,
  ROLE_PATIENT_LIAISON,
  ROLE_CLINICAL_QUALITY_COORDINATOR
];

export default userRoles;
