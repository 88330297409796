import React, { useEffect, useMemo } from 'react';
import { isEmpty, isEqual } from 'lodash/lang';
import moment from 'moment';

import DatePicker from '../../../../../../../common/data-entry/DatePicker';
import Select from '../../../../../../../common/data-entry/Select';
import Button from '../../../../../../../common/general/Button';
import { YEAR_DASH_MONTH_DASH_DAY } from '../../../../../../../constants/dateFormat';
import { useCurrentUser } from '../../../../../../root/Container/CurrentUserContainer';
import { useTableItemGroupActions, useTableItemGroupState } from '../TableItemGroupContext/TableItemGroupContext';

import './TableItemGroupHeader.scss';

export default function TableItemGroupHeader() {
  const currentUser = useCurrentUser();
  const personnelIdentifier = currentUser?.personnelIdentifier;
  const {
    isAbleToSave,
    whoDidItList,
    encounterTableItemGroup,
    isPreviewOnly,
    validationError
  } = useTableItemGroupState();
  const { onSubmit, whoDidItOnChange, whenWasItDoneOnChange } = useTableItemGroupActions();
  const { whoDidItId, whenWasItDone } = encounterTableItemGroup;

  const whoDidItListWithHistoricalData = useMemo(
    function() {
      return whoDidItList.filter(p => p.uniqueIdentifier === whoDidItId || p.deleteFlag === 0);
    },
    [whoDidItList, whoDidItId]
  );

  const whoDidIt = useMemo(
    function() {
      return whoDidItList.find(({ uniqueIdentifier }) => uniqueIdentifier === whoDidItId);
    },
    [whoDidItList, whoDidItId]
  );

  const whenWasItDoneDate = useMemo(
    function() {
      return moment(whenWasItDone);
    },
    [whenWasItDone]
  );

  useEffect(
    function() {
      if (!isEmpty(whoDidItListWithHistoricalData) && !isEmpty(personnelIdentifier) && isEmpty(whoDidItId)) {
        whoDidItOnChange(
          whoDidItListWithHistoricalData.find(({ uniqueIdentifier }) => uniqueIdentifier === personnelIdentifier)
            ?.uniqueIdentifier,
          true
        );
      }
    },
    [whoDidItListWithHistoricalData, whoDidItId, whoDidItOnChange, personnelIdentifier]
  );

  return (
    <div className="table-item-group-header">
      <div className="table-item-group-header-field">
        <DatePicker
          required
          isValidDate={validDate}
          value={whenWasItDoneDate}
          label="When was it done?"
          disabled={isPreviewOnly}
          onChange={function(date) {
            whenWasItDoneOnChange(date?.format(YEAR_DASH_MONTH_DASH_DAY) || null);
          }}
          validate={true}
        />
      </div>
      <div className="table-item-group-header-field">
        <Select
          label="Who did it?"
          dataSource={whoDidItListWithHistoricalData}
          onChange={value => {
            const newValue = value?.uniqueIdentifier || null;
            if (isEqual(whoDidItId, newValue)) {
              return;
            }
            whoDidItOnChange(newValue);
          }}
          searchable
          keepOpenOnSelection={false}
          closeOnSelectedOptionClick
          deselectOnSelectedOptionClick={false}
          clearable={false}
          disabled={isPreviewOnly}
          value={whoDidIt}
          optionValueKey="uniqueIdentifier"
          required={true}
          validate={true}
          validationMessage={validationError && !whoDidIt ? 'Please make a selection' : ''}
        />
      </div>
      <div className="eds-btn-group mt-3">
        {!isPreviewOnly && (
          <Button size={'h28'} title="Save" onClick={onSubmit} disabled={!isAbleToSave}>
            Submit
          </Button>
        )}
      </div>
    </div>
  );
}

function validDate(current) {
  const today = moment();
  return current.isBefore(today);
}
