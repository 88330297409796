import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { get } from 'lodash/object';

import { CalendarPageSupplierApi } from '../../../../api';

import CalendarSingleSelect from './CalendarSingleSelect';

export function ProtocolSelector({
  onEncounterSelected,
  onEpochSelected,
  sitePatientId,
  studyId,
  initialEpoch,
  initialEncounter,
  epochValidationMessage,
  encounterValidationMessage,
  disabled
}) {
  const [epochs, setEpochs] = useState();
  const [encounters, setEncounters] = useState();

  useEffect(() => {
    if (!sitePatientId || !studyId) {
      setEpochs([]);
    } else if (!disabled) {
      const request = axios.CancelToken.source();
      CalendarPageSupplierApi.getNotCompletedEncountersForPatient(sitePatientId, studyId, request)
        .then(({ data: epochs }) => {
          const foundEpoch = epochs.find(e => e.id === get(initialEpoch, 'id'));
          const encounters = foundEpoch ? foundEpoch.encounters : [];
          setEpochs(epochs);
          setEncounters(encounters);
        })
        .catch(() => {});
      return () => {
        if (request) {
          request.cancel();
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sitePatientId, studyId, disabled]);

  function selectEpoch(epoch) {
    const encounters = epoch ? epoch.encounters : [];
    setEncounters(encounters);
    onEpochSelected(epoch);
  }

  const selectEncounter = encounter => {
    onEncounterSelected(encounter);
  };

  return (
    <React.Fragment>
      <CalendarSingleSelect
        controlId={'epoch-selector'}
        label={'Epoch'}
        dataSource={epochs}
        initialValue={initialEpoch}
        onChange={selectEpoch}
        required
        validationMessage={epochValidationMessage}
        disabled={!!disabled}
        clearable={!disabled}
      />
      <CalendarSingleSelect
        controlId={'encounter-selector'}
        label={'Encounter'}
        dataSource={encounters}
        initialValue={initialEncounter}
        onChange={selectEncounter}
        required
        validationMessage={encounterValidationMessage}
        disabled={!!disabled}
        clearable={!disabled}
      />
    </React.Fragment>
  );
}
