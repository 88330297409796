export const ITEM_GROUP_DOMAIN = {
  EREVSM: 'EREVSM',
  EREVPI: 'EREVPI',
  CO: 'CO'
};

export const PROCEDURE_TYPES = [
  'Patient Status',
  'Procedure',
  'ICF',
  'Reminder',
  'Outreach',
  'Chart Review',
  'Checklist',
  'Patient Stipend'
];
