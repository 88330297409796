import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash/lang';

import Input from '../../../../../../common/data-entry/Input';
import Checkbox from '../../../../../../common/data-entry/InputSelectors/Checkbox';
import MultiSelect from '../../../../../../common/data-entry/MultiSelect/MultiSelect';
import Select from '../../../../../../common/data-entry/Select';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import { FILL_REQUIRED } from '../../../../../../constants/notificationMessages';

import propTypes from './types';

import './EncounterDetailsModal.scss';

let errorMsg;

export default function EncounterDetailsModal({
  enInfo,
  previousEpochEncounterList,
  modalBox,
  onSaveEncounter,
  previewMode
}) {
  const [currentState, setCurrentState] = useState({
    encounterName: '',
    encounterDesc: '',
    fromVisit: '',
    toVisit: '',
    fromType: '',
    toType: '',
    type: '',
    parentEnNodeKeys: [],
    isDateRange: true,
    midVisit: '',
    midType: '',
    give: '',
    giveType: '',
    take: '',
    takeType: '',
    canChange: true,
    noAppointments: false,
    smsOptIn: true
  });

  const {
    nonProtocol,
    midVisit,
    midType,
    give,
    giveType,
    take,
    takeType,
    fromType,
    toType,
    isDateRange,
    fromVisit,
    toVisit,
    encounterName,
    parentEnNodeKeys,
    noAppointments,
    smsOptIn
  } = currentState;

  useEffect(
    function() {
      if (!isEmpty(enInfo)) {
        enInfo.isDateRange = enInfo.isDateRange == null || enInfo.isDateRange;

        if (['fromVisit', 'toVisit', 'fromType'].every(k => k in enInfo)) {
          // Calculate give or take values from the
          // fromVisit and toVisit values
          const fromVisit = Number(enInfo.fromVisit);
          const toVisit = Number(enInfo.toVisit);

          if (!enInfo.midVisit) {
            enInfo.midVisit = (toVisit - fromVisit) / 2 + fromVisit;
          }

          enInfo.take = Math.abs(enInfo.midVisit - fromVisit);
          enInfo.give = Math.abs(toVisit - enInfo.midVisit);

          if (
            enInfo.midType === 'Mixed' ||
            (enInfo.midType === 'Month' && enInfo.takeType === 'Days' && enInfo.giveType === 'Days')
          ) {
            enInfo.midType = 'Month';
            enInfo.giveType = 'Days';
            enInfo.takeType = 'Days';
            enInfo.fromType = 'Month';
            enInfo.toType = 'Month';
          } else {
            enInfo.giveType = enInfo.midType = enInfo.takeType = enInfo.fromType;
          }
        }

        setCurrentState(function(state) {
          if (isEmpty(enInfo.encounterName)) {
            return { ...state, ...enInfo, encounterName: enInfo.displayName };
          }
          return { ...state, ...enInfo };
        });
      }
    },
    [enInfo]
  );

  useEffect(() => {
    return function() {
      if (takeType === 'Days' && midType === 'Month') {
        enInfo.midType = 'Mixed';
      }
    };
  }, [currentState, enInfo, midType, takeType]);

  const fromEpochEncounters = useMemo(
    function() {
      return previousEpochEncounterList.map(function(value) {
        const { info } = value;
        const epochEncounter = {
          ...value,
          disabled: !(info && ((info.fromVisit && info.toVisit) || info.nonProtocol))
        };
        if (epochEncounter.name === 'Start') {
          epochEncounter.disabled = false;
        }
        return epochEncounter;
      });
    },
    [previousEpochEncounterList]
  );

  useEffect(
    function() {
      const start = fromEpochEncounters.filter(epoch => epoch.info.enNodeKey === 'start')[0];
      if (
        start &&
        isEmpty(fromEpochEncounters.filter(epoch => epoch.info.enNodeKey !== 'start')) &&
        currentState.canChange &&
        currentState.encounterName !== ''
      ) {
        setCurrentState(function(state) {
          return { ...state, canChange: false, parentEnNodeKeys: ['start'] };
        });
      }
    },
    [currentState.canChange, currentState.encounterName, parentEnNodeKeys, fromEpochEncounters]
  );

  const selectedFromEpochEncounter = useMemo(
    function() {
      return fromEpochEncounters.filter(function({ info }) {
        return parentEnNodeKeys.includes(info.enNodeKey);
      });
    },
    [parentEnNodeKeys, fromEpochEncounters]
  );

  const isDateRangeValid = useMemo(
    function() {
      if (isDateRange && fromVisit && toVisit && fromType && toType) {
        return +fromVisit <= +toVisit;
      }
      return true;
    },
    [isDateRange, fromVisit, toVisit, fromType, toType]
  );

  const isDateRangeValidFromVisit = useMemo(() => {
    const numericMidVisit = Number(midVisit);
    const numericTake = Number(take);

    const validTimeRangeCombination = takeType === 'Days' && midType === 'Month';

    return (
      (numericMidVisit >= numericTake && midType === takeType) ||
      (validTimeRangeCombination && numericTake < numericMidVisit * 30)
    );
  }, [midVisit, take, midType, takeType]);

  const isDateRangeTypeValidFromVisit = useMemo(() => {
    return (takeType === 'Month' || giveType === 'Month') && midType === 'Days';
  }, [takeType, giveType, midType]);

  const isSameDateType = useMemo(
    function() {
      if (isDateRange) {
        return fromType !== '' && toType !== '' && fromType === toType;
      }
      return midType !== '' && giveType !== '' && takeType === giveType;
    },
    [isDateRange, fromType, toType, midType, giveType, takeType]
  );

  const fromEpochEncountersIsRequired = useMemo(
    function() {
      return !isEmpty(fromEpochEncounters);
    },
    [fromEpochEncounters]
  );

  return (
    <>
      <ModalBoxes.Header>Encounter Details</ModalBoxes.Header>
      <ModalBoxes.Body>
        <div>
          <Input
            required
            label="Encounter Name"
            name="encounterName"
            value={encounterName}
            onChange={textHandleChange}
            disabled={previewMode}
          />
        </div>
        {!nonProtocol && (
          <React.Fragment>
            <div>
              <MultiSelect
                label="From Epoch Encounter(s)"
                required={fromEpochEncountersIsRequired}
                dataSource={fromEpochEncounters || []}
                onChange={handleEpochEncounterChange}
                customOptionTemplateFunction={function({ name, disabled }) {
                  if (disabled) {
                    return `${name} (not configured)`;
                  }
                  return name;
                }}
                clearable={false}
                searchable
                value={selectedFromEpochEncounter}
                disabled={previewMode}
              />
            </div>

            <div className="edm-from-visit">
              <Checkbox label="+/-" checked={!isDateRange} onChange={toggleDateRange} disabled={previewMode} />
              <h5>From Visit</h5>
              <div className="row">
                {(isDateRange && (
                  <React.Fragment>
                    <div className="col-6">
                      <div className="form-row">
                        <div className="col-4">
                          <Input
                            label="Min"
                            required
                            pattern="[0-9]*"
                            name="fromVisit"
                            value={fromVisit}
                            onChange={textHandleChange}
                            disabled={previewMode}
                          />
                        </div>
                        <div className="col-8">
                          <Select.Primitive
                            label="Select"
                            name="fromType"
                            clearable={false}
                            required
                            dataSource={['Days', 'Month']}
                            value={fromType}
                            onChange={function(value) {
                              setCurrentState(function(state) {
                                return { ...state, fromType: value };
                              });
                            }}
                            disabled={previewMode}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-row">
                        <div className="col-4">
                          <Input
                            label="Max"
                            required
                            pattern="[0-9]*"
                            name="toVisit"
                            value={toVisit}
                            onChange={textHandleChange}
                            disabled={previewMode}
                          />
                        </div>
                        <div className="col-8">
                          <Select.Primitive
                            label="Select"
                            name="toType"
                            clearable={false}
                            required
                            dataSource={['Days', 'Month']}
                            value={toType}
                            onChange={function(value) {
                              setCurrentState(function(state) {
                                return { ...state, toType: value };
                              });
                            }}
                            disabled={previewMode}
                          />
                        </div>
                      </div>
                    </div>
                    {!isDateRangeValid && (
                      <div className="col-12 time-range-validation">Min value cannot be greater than the Max value</div>
                    )}
                  </React.Fragment>
                )) || (
                  <React.Fragment>
                    <div className="col-6">
                      <div className="form-row">
                        <div className="col-4">
                          <Input
                            required
                            pattern="[0-9]*"
                            name="midVisit"
                            value={midVisit}
                            onChange={textHandleChange}
                            disabled={previewMode}
                          />
                        </div>
                        <div className="col-8">
                          <Select.Primitive
                            label="Select"
                            clearable={false}
                            required
                            dataSource={['Days', 'Month']}
                            value={midType}
                            onChange={function(value) {
                              setCurrentState(function(state) {
                                return { ...state, midType: value };
                              });
                            }}
                            disabled={previewMode}
                          >
                            <option value="">Select</option>
                            <option>Days</option>
                            <option>Month</option>
                          </Select.Primitive>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-row">
                        <div className="col-4">
                          <Input
                            prefix="+"
                            required
                            pattern="[0-9]*"
                            name="give"
                            value={give}
                            onChange={textHandleChange}
                            disabled={previewMode}
                          />
                        </div>
                        <div className="col-8">
                          <Select.Primitive
                            label="Select"
                            clearable={false}
                            required
                            dataSource={['Days', 'Month']}
                            value={giveType}
                            onChange={function(value) {
                              setCurrentState(function(state) {
                                return { ...state, giveType: value };
                              });
                            }}
                            disabled={previewMode}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-4">
                          <Input
                            prefix="-"
                            required
                            pattern="[0-9]*"
                            name="take"
                            value={take}
                            onChange={textHandleChange}
                            disabled={previewMode}
                          />
                        </div>
                        <div className="col-8">
                          <Select.Primitive
                            label="Select"
                            clearable={false}
                            required
                            dataSource={['Days', 'Month']}
                            onChange={function(value) {
                              setCurrentState(function(state) {
                                return { ...state, takeType: value };
                              });
                            }}
                            value={takeType}
                            disabled={previewMode}
                          />
                        </div>
                      </div>
                      {
                        <div className="col-12 time-range-validation">
                          {getValidationMassage(isDateRangeValidFromVisit, isDateRangeTypeValidFromVisit)}
                        </div>
                      }
                    </div>
                  </React.Fragment>
                )}
              </div>
              <div className="form-row">
                <Checkbox
                  label="Workflow encounter (No appointments)"
                  checked={noAppointments}
                  onChange={toggleNoAppointments}
                  disabled={previewMode}
                />
              </div>
              <div className="form-row">
                <Checkbox
                  label="SMS reminders"
                  checked={smsOptIn}
                  onChange={toggleSmsReminders}
                  disabled={previewMode}
                  data-testid="encounter-sms-opt-in-checkbox"
                />
              </div>
            </div>
          </React.Fragment>
        )}
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        {!previewMode && (
          <ButtonGroup>
            <Button priority="medium" onClick={modalBox.close}>
              Cancel
            </Button>
            <Button
              disabled={
                !(isSameDateType || nonProtocol) ||
                !isDateRangeValid ||
                !isDateRangeValidFromVisit ||
                isDateRangeTypeValidFromVisit
              }
              onClick={onSave}
            >
              Save
            </Button>
          </ButtonGroup>
        )}
      </ModalBoxes.Footer>
    </>
  );

  function validateEncounterForm() {
    const {
      nonProtocol,
      encounterName,
      isDateRange,
      fromVisit,
      fromType,
      toType,
      midVisit,
      midType,
      give,
      take,
      giveType,
      takeType
    } = currentState;

    if (
      encounterName && // if start/end date range format then check those values
      ((isDateRange && fromVisit && toVisit && fromType && toType) || // if give or take format then check those values
        (!isDateRange &&
          (midVisit || midVisit === 0) &&
          midType &&
          (give || give === 0) &&
          giveType &&
          (take || take === 0) &&
          takeType) ||
        nonProtocol)
    ) {
      if (fromEpochEncountersIsRequired) {
        if (!isEmpty(currentState.parentEnNodeKeys) || nonProtocol) {
          return true;
        }

        errorMsg = 'Invalid From Epoch Encounter';
        return false;
      }
      return true;
    }

    errorMsg = 'Please fill required fields';
    return false;
  }

  function onSave() {
    if (validateEncounterForm()) {
      let { fromVisit, fromType, toVisit, toType, isDateRange } = currentState;
      if (!isDateRange) {
        // Calculate fromVisit and toVisit values
        // from the give or take values
        const { midVisit, midType, give, take } = currentState;
        fromVisit = (Number(midVisit) - Number(take)).toString();
        toVisit = (Number(midVisit) + Number(give)).toString();

        if (takeType === 'Days' && midType === 'Month') {
          currentState.midType = 'Mixed';
          fromType = 'Month';
          toType = 'Month';
        }

        fromType = midType;
        toType = fromType;
      }
      const params = Object.assign({}, currentState, {
        fromVisit,
        fromType,
        toVisit,
        toType
      });
      onSaveEncounter && onSaveEncounter(params);
      modalBox.close();
    } else {
      if (errorMsg) {
        NotificationManager.error(errorMsg);
      } else {
        NotificationManager.error(FILL_REQUIRED);
      }
    }
  }

  function getValidationMassage(isDateRangeValidFromVisit, isDateRangeTypeValidFromVisit) {
    if (isDateRangeTypeValidFromVisit) {
      return '“Month” values can not be applied to “+/-” selections when “From visit” value is “Days”';
    } else if (!isDateRangeValidFromVisit) {
      return 'The “-” value in the +/- window should not be greater than the “From visit”';
    }
  }
  function textHandleChange({ target }) {
    if (target.validity.valid || target.value === '') {
      setCurrentState(function(state) {
        return { ...state, [target.name]: target.value };
      });
    }
  }

  function handleEpochEncounterChange(options) {
    setCurrentState(function(state) {
      return {
        ...state,
        parentEnNodeKeys: options.map(function({ info }) {
          return info?.enNodeKey;
        })
      };
    });
  }

  function toggleDateRange({ target }) {
    setCurrentState(function(state) {
      return { ...state, isDateRange: !target?.checked };
    });
  }

  function toggleNoAppointments({ target }) {
    setCurrentState(function(state) {
      return { ...state, noAppointments: target.checked };
    });
  }

  function toggleSmsReminders({ target }) {
    setCurrentState(function(state) {
      return { ...state, smsOptIn: target.checked };
    });
  }
}

EncounterDetailsModal.className = 'encounter-detail-modal';
EncounterDetailsModal.size = 'w650';

EncounterDetailsModal.propTypes = propTypes;
