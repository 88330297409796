import React, { Component } from 'react';
import { isArray, isEmpty } from 'lodash/lang';
import { get } from 'lodash/object';
import * as PropTypes from 'prop-types';

import ButtonWithIcon from '../../../../../../common/buttons/ButtonWithIcon';
import IconButton from '../../../../../../common/buttons/IconButton';
import { scGray70 } from '../../../../../../constants/systemColors';
import { ItemGroupDataForm } from '../item-group/ItemGroupDataForm';
import { ItemGroupDataTable } from '../item-group/ItemGroupDataTable';

import './UserFormTable.scss';
import 'react-table/react-table.css';

let compInst = null;
class UserFormTable extends Component {
  static propTypes = {
    hasTableType: PropTypes.bool,
    isItemGroupWithTableView: PropTypes.bool,
    formGroupIdentifier: PropTypes.string,
    editedRowType: PropTypes.string,
    additionNotes: PropTypes.string,
    filledQuestionsAns: PropTypes.array,
    itemGroupTableData: PropTypes.object,
    patientInfo: PropTypes.object,
    formData: PropTypes.object,
    formValidationRules: PropTypes.any,
    onChangeTextHandler: PropTypes.func,
    onChangeDropDownHandler: PropTypes.func,
    onDateChangeHandler: PropTypes.func,
    onChangeRadioHandler: PropTypes.func,
    onchangeTimeHandler: PropTypes.func,
    onChangeFileHandler: PropTypes.func,
    onCloseSelectedFile: PropTypes.func,
    deleteFile: PropTypes.func,
    deleteFileForSingleFileUpload: PropTypes.func,
    onchangeCheckboxHandler: PropTypes.func,
    onTblCovalChangeHandeler: PropTypes.func,
    onChangeRequireAttention: PropTypes.func,
    onRowClickHandeler: PropTypes.func,
    resetRowFrmId: PropTypes.func,
    cancelTableForm: PropTypes.func,
    isAbleToEdit: PropTypes.func,
    ssuPatientId: PropTypes.string,
    adverseEventLogRowStatus: PropTypes.object,
    groupAssignPermissionType: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      showPreviewFormPopUpData: false,
      hasEdited: false,
      addFormButtonIsVisible: true
    };
    compInst = this;
  }

  getEditFormDetail = row => {
    this.props.openDetailRow();
    this.setState({ hasEdited: true, showPreviewFormPopUpData: false, addFormButtonIsVisible: false }); // hasEdited
    this.props.onRowClickHandeler(row.frmGroupIdentifier);
  };

  openFormPopup = () => {
    this.props.resetRowFrmId();
    this.setState({ showPreviewFormPopUpData: true, hasEdited: false, addFormButtonIsVisible: false });
  };

  cancelForm = () => {
    this.props.closeDetailRow();
    this.props.cancelTableForm();
    this.setState({ hasEdited: false, showPreviewFormPopUpData: false, addFormButtonIsVisible: true });
  };

  static resetFormView() {
    try {
      // reseting
      if (compInst) {
        compInst.setState({ hasEdited: false, showPreviewFormPopUpData: false, addFormButtonIsVisible: true });
      }
    } catch (e) {}
  }

  isItemGroupWithTableView = () => {
    const { itemGroupTableData } = this.props;
    return itemGroupTableData && isArray(itemGroupTableData.columnList) && !isEmpty(itemGroupTableData.columnList);
  };

  dataFormIsVisible = () => {
    if (this.isItemGroupWithTableView()) {
      return this.state.hasEdited || this.state.showPreviewFormPopUpData;
    }
    return true;
  };

  render() {
    const { state, props } = this,
      { addFormButtonIsVisible } = state,
      { itemGroupTableData, formData } = props,
      isItemGroupWithTableView = this.isItemGroupWithTableView(),
      itemGroupType = get(formData, 'elementIcon'),
      labelList = get(formData, 'labelList');
    return (
      <div className="eds-user-form-table">
        {isItemGroupWithTableView && (
          <React.Fragment>
            {!props.hasTableType && addFormButtonIsVisible && (
              <div className="d-flex justify-content-end">
                <ButtonWithIcon onClick={this.openFormPopup}>Add</ButtonWithIcon>
              </div>
            )}
            {addFormButtonIsVisible && (
              <ItemGroupDataTable
                itemGroupType={itemGroupType}
                renderData={itemGroupTableData}
                getEditFormDetail={this.getEditFormDetail}
                labelList={labelList}
              />
            )}

            {(state.hasEdited || state.showPreviewFormPopUpData) && (
              <h5 className="subtitle mt-2 col">
                {state.hasEdited ? (props.editedRowType ? props.editedRowType : 'Edit Details') : 'Add Details'} :
                <IconButton
                  onClick={this.cancelForm}
                  className="float-right"
                  style={{ fontSize: '18px', color: scGray70 }}
                  suit="glyphicon"
                >
                  remove
                </IconButton>
              </h5>
            )}
          </React.Fragment>
        )}
        {this.dataFormIsVisible() && (
          <ItemGroupDataForm
            patientInfo={props.patientInfo}
            isItemGroupWithTableView={isItemGroupWithTableView}
            filledQuestionsAns={props.filledQuestionsAns}
            formData={props.formData}
            formValidationRules={props.formValidationRules}
            onChangeTextHandler={props.onChangeTextHandler}
            onChangeDropDownHandler={props.onChangeDropDownHandler}
            onDateChangeHandler={props.onDateChangeHandler}
            onChangeRadioHandler={props.onChangeRadioHandler}
            onchangeTimeHandler={props.onchangeTimeHandler}
            onChangeFileHandler={props.onChangeFileHandler}
            onCloseSelectedFile={props.onCloseSelectedFile}
            deleteFile={props.deleteFile}
            deleteFileForSingleFileUpload={props.deleteFileForSingleFileUpload}
            onchangeCheckboxHandler={props.onchangeCheckboxHandler}
            formGroupIdentifier={props.formGroupIdentifier}
            additionNotes={props.additionNotes}
            onTblCovalChangeHandeler={props.onTblCovalChangeHandeler}
            onChangeRequireAttention={props.onChangeRequireAttention}
            isAbleToEdit={props.isAbleToEdit}
            validation={props.validation}
            ssuPatientId={props.ssuPatientId}
            headerProps={props.headerProps}
            adverseEventLogRowStatus={props.adverseEventLogRowStatus}
            groupAssignPermissionType={props.groupAssignPermissionType}
          />
        )}
      </div>
    );
  }
}

export default UserFormTable;
