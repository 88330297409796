import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { reduce } from 'lodash/collection';
import { has } from 'lodash/object';

import { useCurrentUser } from '../../root/Container/CurrentUserContainer';
import { generateUrlByKey, useCurrentRoute } from '../../root/router';
import { accessAllowed } from '../../root/router/auth';

import { dashboardsMap, usePageName } from './modeReportsServices';

export function useAllowedDashboards() {
  const { accessToPages } = useCurrentUser();
  return useMemo(() => {
    return reduce(
      dashboardsMap,
      function(result, value, key) {
        if (accessAllowed(value.access, accessToPages)) {
          return { ...result, [key]: value };
        }
        return result;
      },
      {}
    );
  }, [accessToPages]);
}

export default function withAccessToModeReports(Component) {
  return function WrapperComponent(props) {
    const currentRoute = useCurrentRoute();
    const { accessToPages } = useCurrentUser();
    const pageName = usePageName();
    const reportKey = currentRoute?.params?.reportKey;
    if (!has(currentRoute?.params, 'reportKey') && pageName !== 'Home') {
      return <Navigate to={`${generateUrlByKey('Dashboards')}`} replace />;
    }
    if (dashboardsMap[reportKey] && !accessAllowed(dashboardsMap[reportKey]?.access, accessToPages)) {
      return <Navigate to={`${generateUrlByKey('Dashboards')}`} replace />;
    }
    return <Component {...props} />;
  };
}
