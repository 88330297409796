import axios from 'axios';

const ROOT = 'api/v1/projections-revenue/total';

const ProjectionTotalRevenueApi = {
  getNewConsentsTotalProjection() {
    return axios.get(ROOT);
  }
};

export default ProjectionTotalRevenueApi;
