import React, { Fragment } from 'react';
import { isEqual, isNull } from 'lodash/lang';
import PropTypes from 'prop-types';

import DatePicker from '../../../../../../../../common/data-entry/DatePicker';
import Icon from '../../../../../../../../common/general/Icon';
import { useTableItemGroupActions, useTableItemGroupState } from '../../TableItemGroupContext/TableItemGroupContext';
import fieldPropTypes from '../fieldPropTypes';

import './DateField.scss';

export default function DateField({ field }) {
  const { fieldValue, fieldUid } = field;
  const { isPreviewOnly } = useTableItemGroupState();
  const { fieldOnChange } = useTableItemGroupActions();

  return (
    <DateFieldReadonly
      field={field}
      onChange={function(e, formattedDate) {
        if (formattedDate.trim() !== '') {
          const value = formattedDate || null;
          if (isEqual(value, fieldValue) || (!isNull(value) && !e)) {
            return;
          }
          fieldOnChange(value, fieldUid);
        }
      }}
      disabled={isPreviewOnly}
      onIconClick={() => {
        if (!isPreviewOnly) {
          fieldOnChange(null, fieldUid);
        }
      }}
    />
  );
}

export function DateFieldReadonly({ field, onIconClick, ...rest }) {
  const { fieldValue } = field;

  return (
    <div className="date-field">
      {onIconClick && (
        <Icon className="delete-date-icon" onClick={onIconClick}>
          cancel
        </Icon>
      )}
      <DatePicker.Manual
        value={fieldValue || ' '}
        iconsAfter={
          <Fragment>
            <Icon>calendar_today</Icon>
          </Fragment>
        }
        legacyLook
        {...rest}
      />
    </div>
  );
}

const propTypes = {
  field: PropTypes.shape({
    ...fieldPropTypes,
    fieldType: PropTypes.oneOf(['date']).isRequired,
    fieldValue: function(props, propName, componentName) {
      const value = props[propName];
      if (!isNull(value) && !/\d\d\/[A-Z][a-z][a-z]\/\d\d\d\d/.test(value)) {
        return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed.`);
      }
    }
  })
};

DateFieldReadonly.defaultProps = {
  disabled: true
};

DateFieldReadonly.propTypes = propTypes;
DateField.propTypes = propTypes;
