import { FinBudgetApi } from '../../../api';
import NotificationManager from '../../../common/notifications/NotificationManager';
import {
  STUDY_BUDGET_ADDED,
  STUDY_BUDGET_COPY_FAILED,
  STUDY_BUDGET_EXPORT_FAILED
} from '../../../constants/notificationMessages';
import { onBudgetFileSave, onRequestError } from '../../../services/handlers';
import { COPY_STUDY_BUDGET } from '../../actionTypes';

import { toBudgetListFormat } from './transformToBudgetListAction';

export const copyStudyBudgetAction = copy => {
  return {
    type: COPY_STUDY_BUDGET,
    payload: copy
  };
};

export const copyStudyBudget = id => dispatch => {
  return FinBudgetApi.copy(id)
    .then(resp => {
      NotificationManager.success(STUDY_BUDGET_ADDED);
      dispatch(copyStudyBudgetAction(toBudgetListFormat(resp.data)));
    })
    .catch(err => onRequestError(err, { customMessage: STUDY_BUDGET_COPY_FAILED }));
};

export const exportStudyBudget = id => dispatch => {
  return FinBudgetApi.export(id)
    .then(onBudgetFileSave)
    .catch(err => onRequestError(err, { customMessage: STUDY_BUDGET_EXPORT_FAILED }));
};
