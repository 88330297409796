import React, { useEffect, useRef, useState } from 'react';
import { get } from 'lodash/object';

import Input from '../../../../common/data-entry/Input';
import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import TextArea from '../../../../common/inputs/TextArea';
import { getRoleDescriptionByUserRoles } from '../../../../services/personnelService';
import { useCurrentUser } from '../../../root/Container/CurrentUserContainer';
import { AppointmentUserInfo, UserMultiSelect } from '../../../UserMultiSelect/UserMultiSelect';
import { PATIENT_ENCOUNTER_EVENT } from '../CalendarEventType';
import { getAppointmentWithFixedTimeZone } from '../CalendarTimeZoneService';
import { isDraft } from '../DraftEventProvider';
import { fromDialogAppointment, toDialogAppointment } from '../EventTransformer';
import { ParticipantsProviderPatientEncounter } from '../form-components/ParticipantsProviderPatientEncounter';
import { PatientSelector } from '../form-components/PatientSelector';
import { ProtocolSelector } from '../form-components/ProtocolSelector';
import { StudySelector } from '../form-components/StudySelector';
import { refreshParticipants } from '../PatientAppointmentEditService';
import ReasonConfirmationDialog from '../ReasonConfirmationDialog/ReasonConfirmationDialog';
import TimeDurationPicker from '../TimeDurationPickerGeneral/TimeDurationPicker';

import PatientEncounterAppointmentValidator from './PatientEncounterAppointmentValidator';

function makePatientEncounterAppointment({
  id,
  eventId,
  calendarIndex,
  patient,
  sitePatientId,
  subject,
  study,
  siteName,
  epoch,
  encounter,
  timeDuration,
  comment,
  organizer,
  reasonForOutOfWindow,
  participants,
  color
}) {
  return {
    id,
    eventId,
    calendarIndex,
    subject,
    timeDuration,
    comment,
    reasonForOutOfWindow,
    patient,
    sitePatientId,
    study,
    siteName,
    epoch,
    encounter,
    participants,
    organizer,
    color,
    type: PATIENT_ENCOUNTER_EVENT
  };
}

export function PatientEncounterAppointmentEdit({
  initialAppointment = {},
  timeRange,
  appointmentChanged,
  children,
  selectedTimeZone,
  timeZones,
  changeSelectedTimeZone,
  eventId
}) {
  const [appointment, setAppointment] = useState(toDialogAppointment(initialAppointment));
  const [patientAppointmentValidator, setPatientAppointmentValidator] = useState(
    new PatientEncounterAppointmentValidator(timeRange)
  );
  const currentUser = useCurrentUser();
  const personnelIdentifier = currentUser.personnelIdentifier,
    roleDescription = getRoleDescriptionByUserRoles(currentUser.roles);

  const [validation, setValidation] = useState({
    patient: {},
    study: {},
    epoch: {},
    encounter: {},
    timeDuration: {}
  });

  useEffect(() => {
    const dialogAppointment = toDialogAppointment(initialAppointment);
    const appointment = makePatientEncounterAppointment(dialogAppointment);
    setAppointment(appointment);
  }, [initialAppointment]);

  useEffect(() => {
    const patientAppointmentValidator = new PatientEncounterAppointmentValidator(timeRange);
    setPatientAppointmentValidator(patientAppointmentValidator);
    setValidation(validation => patientAppointmentValidator.fieldValidation(appointment, validation, 'timeDuration'));
  }, [appointment, timeRange]);

  const getSiteId = appointment =>
    appointment.patient?.studySites?.find(ssu => ssu.study.id === appointment?.study?.id)?.site?.id;

  const siteId = getSiteId(appointment);

  useEffect(() => {
    const timezoneForSelectedStudy = timeZones?.filter(e => e.id === siteId)[0];
    if (
      siteId &&
      appointment.timeDuration &&
      timezoneForSelectedStudy &&
      selectedTimeZone &&
      selectedTimeZone.timeZoneNameToDisplay !== timezoneForSelectedStudy.timeZoneNameToDisplay
    ) {
      setAppointment(
        getAppointmentWithFixedTimeZone(
          appointment,
          selectedTimeZone.timeZoneId,
          timezoneForSelectedStudy.timeZoneId,
          timeZones[0].timeZoneId
        )
      );
      changeSelectedTimeZone(timezoneForSelectedStudy);
    } else if (siteId && appointment.timeDuration && timezoneForSelectedStudy && !selectedTimeZone) {
      setAppointment(
        getAppointmentWithFixedTimeZone(
          appointment,
          timeZones[0].timeZoneId,
          timezoneForSelectedStudy.timeZoneId,
          timeZones[0].timeZoneId
        )
      );
      changeSelectedTimeZone(timezoneForSelectedStudy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId, timeZones]);

  const fieldChange = fieldName => newValue => {
    setAppointment(appointment => {
      const newAppointment = { ...appointment, [fieldName]: newValue };
      const newValidation = patientAppointmentValidator.fieldValidation(newAppointment, validation, fieldName);
      setValidation(newValidation);
      appointmentChanged(fromDialogAppointment(newAppointment));
      return newAppointment;
    });
  };

  const changePatient = fieldChange('patient');
  const changeStudy = fieldChange('study');
  const changeOrganizer = fieldChange('organizer');
  const changeTimeDuration = fieldChange('timeDuration');
  const changeParticipants = fieldChange('participants');
  const changeEpoch = fieldChange('epoch');
  const changeSitePatientId = fieldChange('sitePatientId');
  const changeEncounter = fieldChange('encounter');
  const changeSubject = fieldChange('subject');
  const changeComment = fieldChange('comment');

  const getSiteName = appointment =>
    appointment.patient?.studySites?.find(ssu => ssu.study.id === appointment?.study?.id)?.site?.name;

  useEffect(() => {
    changeSitePatientId(appointment.study?.sitePatientId);
    // adding changeSitePatientId to dependants leads to blinking of form fields
    // eslint-disable-next-line
  }, [appointment.study?.id]);

  const encounterId = appointment.encounter?.id;
  const isAppointmentDraft = isDraft(appointment);
  const sitePatientId = appointment.study?.sitePatientId;

  useEffect(() => {
    refreshParticipants(personnelIdentifier, appointment, changeParticipants);
    // adding changeParticipants to dependants leads to blinking of form fields
    // eslint-disable-next-line
  }, [sitePatientId, encounterId, isAppointmentDraft, personnelIdentifier]);

  const previousAppointmentStart = useRef(appointment.timeDuration.start);

  useEffect(() => {
    if (
      !previousAppointmentStart.current
        .clone()
        .startOf('day')
        .isSame(appointment.timeDuration.start.clone().startOf('day'))
    ) {
      changeOrganizer({
        userId: personnelIdentifier,
        type: 'USER',
        label: roleDescription
      });
      previousAppointmentStart.current = appointment.timeDuration.start;
    }
    // adding changeOrganizer to dependants leads to blinking of form fields
    // eslint-disable-next-line
  }, [appointment.timeDuration.start, personnelIdentifier, roleDescription]);

  useEffect(() => {
    changeSubject(appointment.encounter?.name);
    // adding changeSubject to dependants leads to blinking of form fields
    // eslint-disable-next-line
  }, [appointment?.encounter?.id]);

  const validateBefore = saveHandler => () => {
    const newValidation = patientAppointmentValidator.formValidation(appointment);
    setValidation(newValidation);

    if (!patientAppointmentValidator.isValid(appointment)) {
      return;
    }

    if (validation.timeDuration.matchTimeRange) {
      save(appointment);
    } else if (newValidation.timeDuration.skippedEncounter) {
      callShowReasonDialog(reasonForSkippedEncounter => save({ ...appointment, reasonForSkippedEncounter }));
    } else {
      callShowReasonDialog(reasonForOutOfWindow => save({ ...appointment, reasonForOutOfWindow }));
    }

    function callShowReasonDialog(onSave) {
      showReasonDialog(newValidation.timeDuration.validationMessage, onSave);
    }

    function save(appointment) {
      return saveHandler(fromDialogAppointment(appointment));
    }
  };

  function showReasonDialog(dialogTitle, onPositiveButton) {
    ModalBoxes.open({
      component: <ReasonConfirmationDialog onSubmit={onPositiveButton} />,
      title: dialogTitle
    });
  }

  const composeButtonBar = () => {
    if (children) {
      return React.Children.map(children, child => {
        return React.cloneElement(child, {
          ...child.props,
          onSave: validateBefore(child.props.onSave),
          onEdit: () => child.props.onEdit(appointment)
        });
      });
    }
  };

  return (
    <React.Fragment>
      <PatientSelector
        onPatientSelected={changePatient}
        initialPatient={appointment.patient}
        patientValidationMessage={validation.patient.validationMessage}
        appointmentType={get(appointment, 'type')}
        appointmentChanged={appointmentChanged}
        disabled={eventId}
      />
      <StudySelector
        onStudySelected={changeStudy}
        initialStudy={appointment.study}
        disabled={eventId}
        patientId={get(appointment, 'patient.patientId')}
        studyValidationMessage={validation.study.validationMessage}
      />
      <Input label={'Site'} value={getSiteName(appointment)} disabled />
      <ProtocolSelector
        encounterValidationMessage={validation.encounter.validationMessage}
        epochValidationMessage={validation.epoch.validationMessage}
        sitePatientId={appointment.study?.sitePatientId}
        studyId={get(appointment, 'study.id')}
        initialEpoch={appointment.epoch}
        initialEncounter={appointment.encounter}
        onEncounterSelected={changeEncounter}
        onEpochSelected={changeEpoch}
        disabled={eventId}
      />
      <TimeDurationPicker
        initialTime={appointment.timeDuration}
        onTimeDurationChanged={changeTimeDuration}
        validationMessage={validation.timeDuration.validationMessage}
      />
      <ParticipantsProviderPatientEncounter initialPatientId={appointment?.sitePatientId}>
        <UserMultiSelect
          onChange={changeParticipants}
          initialUsers={appointment.participants}
          organizerUser={appointment?.organizer}
          className="mb-2"
          optionValueKey="userId"
          label="Add Attendees"
          customOptionTemplateFunction={AppointmentUserInfo}
        />
      </ParticipantsProviderPatientEncounter>
      <TextArea
        id={'comment-input'}
        name={'comment'}
        label={'Comment'}
        value={appointment.comment}
        onChange={changeComment}
      />
      <div className="action-buttons">{composeButtonBar()}</div>
    </React.Fragment>
  );
}
