import {
  EXPORT_CASE_REPORT_FORMS,
  LOGS,
  PATIENT_INFO,
  PATIENT_PROFILE,
  PL,
  REIMBURSEMENT_REQUEST,
  TRAVEL_REQUEST
} from '../../../constants/accessToPages';
import CalendarPage from '../../pages/Calendar/CalendarPage';
import withPatientProvided from '../../pages/Calendar/withPatientProvided';
import ExportCaseReportForms from '../../pages/patient-source/Patients/ExportCaseReportForms/ExportCaseReportForms';
import AdverseEventLogRowHistory from '../../pages/patient-source/Patients/Logs/AdverseEventLogRowHistory';
import LogsPage from '../../pages/patient-source/Patients/Logs/LogsPage';
import CompletedICFPage from '../../pages/patient-source/Patients/PatientInfo/ICFSection/CompletedICFPage';
import PatientInfo from '../../pages/patient-source/Patients/PatientInfo/PatientInfo';
import ReimbursementRequest from '../../pages/patient-source/Patients/PatientInfo/ReimbursementRequest/ReimbursementRequest';
import TravelRequest from '../../pages/patient-source/Patients/PatientInfo/TravelRequest/TravelRequest';
import MedicalHistory from '../../pages/patient-source/Patients/PatientProfile/MedicalHistory/MedicalHistory';
import Medications from '../../pages/patient-source/Patients/PatientProfile/Medications/Medications';
import PatientDetails from '../../pages/patient-source/Patients/PatientProfile/PatientDetails/PatientDetails';
import PatientFiles from '../../pages/patient-source/Patients/PatientProfile/PatientFiles/PatientFiles';
import PatientProfile from '../../pages/patient-source/Patients/PatientProfile/PatientProfile';
import PatientStudies from '../../pages/patient-source/Patients/PatientProfile/PatientStudies';
import PatientStatusChange from '../../pages/patient-source/Patients/PatientStatusChange/PatientStatusChange';
import EncounterDescription from '../../pages/patient-source/shared/EncounterDescNew/encounterDescNew';
import GetDomainDetailsElement from '../../pages/patient-source/shared/EncounterDescNew/getDomainDetailElements';
import TableItemGroup from '../../pages/patient-source/shared/EncounterDescNew/TableItemGroup/TableItemGroup';
import InformedConsent from '../../pages/patient-source/shared/NewEncounterPage/InformedConsent/InformedConsent';
import NewEncounterPage from '../../pages/patient-source/shared/NewEncounterPage/NewEncounterPage';
import { withNewEncounterPageContext } from '../../pages/patient-source/shared/NewEncounterPage/NewEncounterPageContext';
import { ReviewPage } from '../../pages/patient-source/shared/Review/ReviewPage';
import { ReviewPageDEV } from '../../pages/patient-source/shared/ReviewDEV/ReviewPage';

import { forceRemount } from './service';
import { withMultiAccess } from './withMultiAccess';

export function generatePatientInfoRouterConfig(itemGroupAccess, smReviewAccess) {
  return [
    {
      name: 'Patient Profile',
      path: '/:patientId',
      component: PatientProfile,
      access: [PATIENT_PROFILE],
      nested: [
        {
          name: 'Patient Details',
          path: '/tab/details',
          component: PatientDetails
        },
        {
          name: 'Studies',
          path: '/tab/studies',
          component: PatientStudies
        },
        {
          name: 'Medications',
          path: '/tab/medications',
          component: Medications
        },
        {
          name: 'Medical History',
          path: '/tab/history',
          component: MedicalHistory
        },
        {
          name: 'Patient Files',
          path: '/tab/files',
          component: PatientFiles
        }
      ],
      inherited: [
        {
          name: 'Patient Studies',
          path: '/:ssuPatientId',
          component: PatientInfo,
          access: [PATIENT_INFO],
          inherited: [
            {
              name: 'Encounter Details',
              path: '/encounters/:patientEncounterId',
              component: withMultiAccess(withNewEncounterPageContext(NewEncounterPage)),
              inherited: [
                {
                  name: 'Investigator Review',
                  path: '/pi-review/:reviewPatientItemGroupId',
                  component: withMultiAccess(ReviewPage),
                  access: [itemGroupAccess],
                  inherited: [
                    {
                      name: 'Adverse Event Log',
                      path: '/adverse-event-log/:patientItemGroupId',
                      component: withMultiAccess(LogsPage),
                      access: [itemGroupAccess]
                    }
                  ]
                },
                {
                  name: 'SM Review',
                  path: '/sm-review/:reviewPatientItemGroupId',
                  component: withMultiAccess(ReviewPage),
                  access: [smReviewAccess],
                  inherited: [
                    {
                      name: 'Adverse Event Log',
                      path: '/adverse-event-log/:patientItemGroupId',
                      component: withMultiAccess(LogsPage),
                      access: [itemGroupAccess]
                    }
                  ]
                },
                {
                  name: 'Investigator Encounter Review',
                  path: '/inv-encounter-review/:groupAssignId/signature/:signatureId',
                  component: withMultiAccess(ReviewPageDEV),
                  access: [itemGroupAccess],
                  inherited: [
                    {
                      name: 'Adverse Event Log',
                      path: '/adverse-event-log/:patientItemGroupId',
                      component: withMultiAccess(LogsPage),
                      access: [itemGroupAccess]
                    }
                  ]
                },
                {
                  name: 'Study Manager Encounter Review',
                  path: '/sm-encounter-review/:groupAssignId/signature/:signatureId',
                  component: withMultiAccess(ReviewPageDEV),
                  access: [smReviewAccess],
                  inherited: [
                    {
                      name: 'Adverse Event Log',
                      path: '/adverse-event-log/:patientItemGroupId',
                      component: withMultiAccess(LogsPage),
                      access: [itemGroupAccess]
                    }
                  ]
                }
              ],
              nested: [
                {
                  name: 'Procedure',
                  path: '/item-groups/:patientItemGroupId',
                  component: withNewEncounterPageContext(
                    forceRemount(function(props) {
                      return <GetDomainDetailsElement {...props} inheritedComponent />;
                    })
                  ),
                  access: [itemGroupAccess]
                },
                {
                  name: 'Table',
                  path: '/table-groups/:patientItemGroupId',
                  component: forceRemount(function(props) {
                    return <TableItemGroup {...props} />;
                  }),
                  access: [itemGroupAccess]
                },
                {
                  name: 'Informed Consent',
                  path: '/icf/:patientItemGroupId/:typeId/:languageId',
                  component: forceRemount(function(props) {
                    return <InformedConsent {...props} />;
                  }),
                  access: [itemGroupAccess]
                },
                {
                  name: 'Logs',
                  path: '/logs/:patientItemGroupId',
                  component: forceRemount(function(props) {
                    return <LogsPage {...props} inheritedComponent />;
                  }),
                  access: [PL],
                  inherited: [
                    {
                      name: 'Row',
                      path: '/:itemGroupTemplateVersionId/row/:rowId',
                      component: withNewEncounterPageContext(
                        forceRemount(function(props) {
                          return <GetDomainDetailsElement {...props} inheritedComponent />;
                        })
                      ),
                      access: [PL],
                      inherited: [
                        {
                          name: 'History',
                          path: '/history',
                          component: AdverseEventLogRowHistory,
                          access: [PL]
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              name: 'Logs',
              path: '/logs/:patientItemGroupId',
              component: withMultiAccess(LogsPage),
              access: [LOGS],
              inherited: [
                {
                  name: 'Row',
                  path: '/:itemGroupTemplateVersionId/row/:rowId',
                  component: withMultiAccess(GetDomainDetailsElement),
                  access: [LOGS],
                  inherited: [
                    {
                      name: 'History',
                      path: '/history',
                      component: withMultiAccess(AdverseEventLogRowHistory),
                      access: [LOGS]
                    }
                  ]
                }
              ]
            },
            {
              name: 'Completed Informed Consents',
              path: '/completed-informed-consents',
              component: CompletedICFPage,
              access: [PL]
            },
            {
              name: 'Create Unexpected Encounter',
              path: '/create-unexpected-encounter',
              component: EncounterDescription
            },
            {
              name: 'Edit Unexpected Encounter',
              path: '/edit-unexpected-encounter/:patientEncounterId',
              component: EncounterDescription
            },
            {
              name: 'Calendar',
              path: '/calendar',
              component: withPatientProvided(CalendarPage)
            },
            {
              name: 'Patient Status',
              path: '/patient-status',
              component: PatientStatusChange,
              access: [PL]
            },
            {
              name: 'Export Case Report Forms',
              path: '/export-case-report-forms',
              component: ExportCaseReportForms,
              access: [EXPORT_CASE_REPORT_FORMS]
            },
            {
              name: 'Reimbursement request',
              path: '/reimbursements/:reimbursementId',
              component: ReimbursementRequest,
              access: [REIMBURSEMENT_REQUEST]
            },
            {
              name: 'New Travel Request',
              path: '/travel-request',
              component: TravelRequest,
              access: [TRAVEL_REQUEST]
            },
            {
              name: 'Travel Request',
              path: '/travel-request/:travelRequestId',
              component: TravelRequest,
              access: [TRAVEL_REQUEST]
            }
          ]
        }
      ]
    },
    {
      name: 'Adverse Event Log Review',
      path: '/:ssuPatientId/adverse-event-log/:patientItemGroupId',
      component: LogsPage,
      access: [itemGroupAccess],
      inherited: [
        {
          name: 'Row',
          path: '/:itemGroupTemplateVersionId/row/:rowId',
          component: GetDomainDetailsElement,
          access: [itemGroupAccess],
          inherited: [
            {
              name: 'History',
              path: '/history',
              component: AdverseEventLogRowHistory,
              access: [itemGroupAccess]
            }
          ]
        }
      ]
    },
    {
      name: 'Adverse Event Log Row',
      path: '/:ssuPatientId/adverse-event-log/:patientItemGroupId/:itemGroupTemplateVersionId/row/:rowId',
      component: GetDomainDetailsElement,
      access: [itemGroupAccess],
      inherited: [
        {
          name: 'History',
          path: '/history',
          component: AdverseEventLogRowHistory,
          access: [itemGroupAccess]
        }
      ]
    }
  ];
}
