import { CANCELED, COMPLETE, DATA_REQUIRED, OPEN, PI_REVIEW, SM_REVIEW } from '../shared/Worklist/constants';

export const sortingPath = {
  taskAge: ['taskAge'],
  patientName: ['patientFirstName', 'patientLastName'],
  subjectId: ['subjectId'],
  studyName: ['studyName'],
  siteName: ['siteName'],
  encounterName: ['encounterName'],
  forms: ['formsCount'],
  date: ['edcVisitDate'],
  adverseEventName: ['adverseEventName']
};

export const SmStatuses = [OPEN, PI_REVIEW, SM_REVIEW, COMPLETE, CANCELED, DATA_REQUIRED];

export const SmStatusesProvider = [
  { id: CANCELED, name: 'Canceled', value: CANCELED },
  { id: COMPLETE, name: 'Complete', value: COMPLETE },
  { id: OPEN, name: 'Data Required', value: OPEN },
  { id: DATA_REQUIRED, name: 'Data Required', value: DATA_REQUIRED },
  { id: PI_REVIEW, name: 'Investigator Review', value: PI_REVIEW },
  { id: SM_REVIEW, name: 'Study Manager Review', value: SM_REVIEW }
];

export const SM_WORKLIST_SORTED_BY = { id: 'patientName', desc: true };
export const SM_WORKLIST_PAGE = 0;
export const SM_WORKLIST_PAGE_SIZE = 25;

export const defaultSmFilters = {
  page: SM_WORKLIST_PAGE,
  pageSize: SM_WORKLIST_PAGE_SIZE,
  sorted: [SM_WORKLIST_SORTED_BY],
  selectedStatuses: SmStatusesProvider.filter(({ id }) => id !== 'COMPLETE'),
  selectedEncounters: [],
  searchString: ''
};

export const colors = {
  OPEN: '#2D9CDB',
  DATA_REQUIRED: '#2D9CDB',
  SM_REVIEW: '#EB6534',
  PI_REVIEW: '#6523BB',
  COMPLETE: '#27AE60',
  CANCELED: '#6E6E6E'
};

export const EMDASH = '\u2014';
