import React, { useState } from 'react';
import { isEmpty, isEqual } from 'lodash/lang';
import { capitalize } from 'lodash/string';

import InformedConsentApi from '../../../../api/patient/InformedConsentApi';
import Input from '../../../../common/data-entry/Input';
import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../common/general/Button';
import ButtonGroup from '../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import { INFORMED_CONSENT_ITEM_SAVED } from '../../../../constants/notificationMessages';
import { onRequestError } from '../../../../services/handlers';

const InformedConsentItemModal = ({ current, label, modalBox, studyId, id, updateItems }) => {
  const [newValue, setNewValue] = useState(current ?? '');
  const [disableButton, setDisableButton] = useState(false);

  function onClose() {
    modalBox.close();
  }

  function save({ label }) {
    setDisableButton(true);
    switch (label) {
      case 'type':
        saveType();
        break;
      case 'language':
        saveLanguage();
        break;
      default:
        break;
    }
  }

  function saveType() {
    if (isEmpty(id)) {
      InformedConsentApi.addType(studyId, newValue)
        .then(({ data }) => {
          returnSuccess();
        })
        .catch(onRequestError);
    } else {
      InformedConsentApi.editType(studyId, id, newValue)
        .then(({ data }) => {
          returnSuccess();
        })
        .catch(onRequestError);
    }
  }

  function saveLanguage() {
    if (isEmpty(id)) {
      InformedConsentApi.addLanguage(studyId, newValue)
        .then(({ data }) => {
          returnSuccess();
        })
        .catch(onRequestError);
    } else {
      InformedConsentApi.editLanguage(studyId, id, newValue)
        .then(({ data }) => {
          returnSuccess();
        })
        .catch(onRequestError);
    }
  }

  function returnSuccess() {
    NotificationManager.success(INFORMED_CONSENT_ITEM_SAVED);
    updateItems();
    modalBox.close();
  }

  return (
    <>
      <ModalBoxes.Header onClose={onClose}>
        {isEmpty(current) ? 'Add' : 'Edit'} ICF {label}
      </ModalBoxes.Header>
      <ModalBoxes.Body>
        <div>
          <Input
            label={capitalize(label)}
            name={label}
            value={newValue}
            onChange={({ target }) => setNewValue(target.value)}
          />
        </div>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority={'medium'} onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              save({ label });
            }}
            disabled={(isEqual(current, newValue) || isEmpty(newValue)) && !disableButton}
          >
            Save
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
};

InformedConsentItemModal.className = 'informed-consent-item-modal';
InformedConsentItemModal.size = 'w350';

export default InformedConsentItemModal;
