import React from 'react';

import './TermsOfUseModal.scss';

export default function TermsOfUseModal() {
  return (
    <React.Fragment>
      <p>
        To protect the security, privacy, and functionality of IntElligo and the information stored and processed within
        the application, you agree to the following terms when you use IntElligo:
      </p>
      <p>
        You will not do or try to do any of the following activities, and you won’t allow anyone else to do or try to do
        them:
      </p>
      <ul>
        <li>Get access to IntElligo or parts of IntElligo that you are not authorized to access.</li>
        <li>Alter or destroy information in IntElligo that you are not permitted to alter or destroy.</li>
        <li>Interfere with other IntElligo users in any way.</li>
        <li>Change or interfere with the appearance, features, or functionality of IntElligo.</li>
        <li>Use IntElligo for unlawful, fraudulent, or unethical purposes.</li>
        <li>Use IntElligo to create or send spam or other marketing communications.</li>
      </ul>
      <p>
        Elligo has put controls in place to protect IntElligo and the information stored and processed within the
        application. You will not try to disable, bypass, or otherwise get around these controls, which may include
        people, processes, and technologies.
      </p>
      <p>
        You agree that you will not try to modify, adapt, sub-license, translate, sell, reverse engineer, decompile or
        disassemble any part of IntElligo or its features. You won’t mirror or replicate any part of the application
        without prior written permission.
      </p>
      <p>
        You will not run any security or network analysis tools against IntElligo without Elligo’s prior written
        permission. This includes security tools designed to analyze network traffic, identify systems or ports,
        enumerate vulnerabilities, or perform penetration tests on web applications.
      </p>
      <p>
        IntElligo is meant to be accessed using standard commercial web browsers. You won’t try to access IntElligo
        using any other specialized software. You won’t use any manual techniques or automated robots, spiders, scrapers
        or other site search and retrieval applications to retrieve, index, or mine IntElligo’s structure, contents,
        code, or data. You also agree not to make any attempt to integrate or connect IntElligo with any other systems
        without Elligo’s prior written permission.
      </p>
      <p>
        Certain information stored in IntElligo is subject to the Health Insurance Portability and Accountability Act
        (“HIPAA”) and other related privacy and healthcare regulations. You agree to use IntElligo in a way that
        complies with regulatory requirements for security and privacy such as HIPAA, as well as state and local
        requirements, even if you may not be subject to HIPAA. This means you will have appropriate security in place
        within your own organization to ensure that you use IntElligo in a way that complies with these regulations.
      </p>
      <p>
        You access IntElligo at your own risk. You agree that you are responsible for making sure that your use of
        IntElligo complies with any other applicable laws specific to your organization and your users. Elligo does not
        guarantee that using IntElligo under these terms will not violate a law or regulation specific to your
        organization.
      </p>
      <p>
        You agree to immediately notify Elligo if you discover or suspect a security breach related to IntElligo or how
        you use the application. This includes any unauthorized use or disclosure or destruction of information, whether
        or not the breach originated in your own organization. If the breach did originate in your organization you will
        also immediately work to investigate and manage the breach or suspected breach, and you will work directly with
        Elligo as part of those efforts.
      </p>
      <p>
        Elligo will provide you with unique credentials for each individual user to access IntElligo. Elligo continues
        to own these credentials and that access, and we can revoke them at any time and for any reason. You agree to
        protect these credentials and the capability for individual users to use e-signatures by doing the following:
      </p>
      <ul>
        <li>Not allowing unauthorized users to have access to the credentials.</li>
        <li>Not allowing authorized users to share or pool credentials among themselves for any reason.</li>
        <li>Not allowing anyone to access IntElligo with another user’s credentials.</li>
        <li>Not trying to guess, brute force, or “crack” any user’s username or password.</li>
        <li>
          Notifying Elligo in the event that a user no longer requires their credentials, for example if they change
          jobs or leave your organization.
        </li>
      </ul>
      <p>For authorized users in your organization, you agree to do the following:</p>
      <ul>
        <li>Identify and document any user provided access to IntElligo.</li>
        <li>Train all IntElligo users so that they understand their responsibilities under these terms of use.</li>
        <li>
          Revoke access to any users deliberately violating these terms of use by notifying Elligo to remove their
          access to the application.
        </li>
        <li>
          Immediately notify Elligo when a user changes role, leaves your organization, or otherwise no longer needs
          access to IntElligo.
        </li>
      </ul>
      <p>
        Unless you are required by law to do so, you will not allow any third parties to use or access IntElligo without
        prior written permission. You agree to immediately notify Elligo if you receive any order or demand that you
        allow a third party to access IntElligo, or to disclose health information that is stored or processed within
        the application. You agree to work with Elligo to resolve any orders or demands you may receive.
      </p>
      <p>
        You agree not to allow any third party to try to access IntElligo using any specialized software, to make any
        attempt to integrate or connect IntElligo with any other systems, or to obtain user credentials in order to
        access the application without Elligo’s prior written permission. You agree to immediately notify Elligo if you
        become aware of a third party attempting to do any of these things.
      </p>
      <p>
        You agree that your organization is responsible for your own business services and products. Elligo does not
        guarantee that the information in IntElligo is complete, accurate, or of use to your business, nor do we
        guarantee that any user accessing IntElligo is qualified or competent. Elligo has no responsibility or liability
        for any consequences that come from using IntElligo.
      </p>
      <p>
        You agree to work with Elligo as necessary to administer IntElligo, which may include providing assistance and
        our collecting data to use to evaluate the application and help with administration.
      </p>
      <p>
        For information or questions about these terms or to report issues including security breaches, please contact
        Elligo at security@elligodirect.com.
      </p>
    </React.Fragment>
  );
}
