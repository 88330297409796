import React, { useCallback, useContext, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { keyBy } from 'lodash/collection';
import { mapValues } from 'lodash/object';
import moment from 'moment';

import { PatientInfoApi } from '../../../../../api';
import PatientApi from '../../../../../api/patient/PatientApi';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { MANAGE_PATIENT_OVERALL_INFORMATION } from '../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../services/auth';
import { useCurrentRoute } from '../../../../root/router';
import AddOrEditPatientInfoModal from '../PatientInfo/PatientInfoMainSection/AddOrEditPatientInfoModal/AddOrEditPatientInfoModal';

export const PatientProfileContext = React.createContext(null);

export function PatientProfileProvider({ children }) {
  const currentRoute = useCurrentRoute();
  const patientId = currentRoute.params?.patientId;
  const [patientInfo, setPatientInfo] = useState(null);
  const [dictionariesMap, setDictionariesMap] = useState({});
  const [userCanEdit, setUserCanEdit] = useState(null);
  const [studyHistory, setStudyHistory] = useState([]);
  const [hasUnsavedInstructions, setHasUnsavedInstructions] = useState(false);
  const [hasAccessToPatient, setHasAccessToPatient] = useState(null);

  const updatePatientInfo = useCallback(
    function() {
      let canceled = false;
      PatientInfoApi.getPatientInfo(patientId).then(function(res) {
        if (canceled) {
          return;
        }
        res && setPatientInfo(convertDobStringToMoment(res.data));
      });
      return function() {
        canceled = true;
      };
    },
    [patientId]
  );

  const editPatientInfo = useCallback(
    function() {
      const addOrEditPatientInfoModal = ModalBoxes.open({
        component: (
          <AddOrEditPatientInfoModal
            patientInfo={patientInfo}
            onSave={function() {
              updatePatientInfo();
              addOrEditPatientInfoModal.close();
            }}
          />
        ),
        title: 'Edit Patient Info'
      });
    },
    [patientInfo, updatePatientInfo]
  );

  useEffect(() => {
    PatientApi.getDictionaries().then(({ data }) => {
      data && setDictionariesMap(mapValues(data, arr => keyBy(arr, 'id')));
    });
  }, []);

  useEffect(
    function() {
      PatientInfoApi.getPatientInfo(patientId)
        .then(function(res) {
          if (res) {
            setPatientInfo(convertDobStringToMoment(res.data));
            setHasAccessToPatient(true);
          }
        })
        .catch(error => {
          if (error.response?.status === 403) {
            setHasAccessToPatient(false);
          }
        });
    },
    [patientId]
  );

  useEffect(function() {
    setUserCanEdit(userHasAccessTo(MANAGE_PATIENT_OVERALL_INFORMATION));
  }, []);

  useEffect(
    function() {
      if (!isEmpty(patientInfo) && hasAccessToPatient) {
        PatientInfoApi.getPatientRelatedStudies(patientId).then(function(res) {
          res && setStudyHistory(res.data.items);
        });
      }
    },
    [patientId, patientInfo, hasAccessToPatient]
  );

  return (
    <PatientProfileContext.Provider
      value={{
        patientInfo,
        dictionariesMap,
        editPatientInfo,
        studyHistory,
        userCanEdit,
        hasUnsavedInstructions,
        setHasUnsavedInstructions,
        hasAccessToPatient
      }}
    >
      {children}
    </PatientProfileContext.Provider>
  );
}

export function usePatientProfile() {
  const context = useContext(PatientProfileContext);
  if (context === undefined) {
    throw new Error('usePatientProfile must be used within a PatientProfileContext.Provider');
  }
  return context;
}

export function withPatientProfile(Component) {
  return function(props) {
    return (
      <PatientProfileProvider>
        <Component {...props} />
      </PatientProfileProvider>
    );
  };
}

function convertDobStringToMoment(patientInfo) {
  const updatedDob = patientInfo.dob && moment(patientInfo.dob);
  return {
    ...patientInfo,
    dob: updatedDob
  };
}
