import axios from 'axios';

const ROOT = 'api/v1/calendar/study';

const SituationalEncounterApi = {
  getSituationalEncounterByStudyId(studyId) {
    return axios.get(`${ROOT}/${studyId}/situational_encounters/names`, { hideLoader: true });
  }
};

export default SituationalEncounterApi;
