import React, { useState } from 'react';
import { isEmpty } from 'lodash/lang';

import Toggle from '../../../../../common/data-entry/Toggle';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';
import { ROLE_PRINCIPAL_INVESTIGATOR } from '../../../../../constants/userRoles';
import { useCurrentUser } from '../../../../root/Container/CurrentUserContainer';

import '../WidgetConfigModal.scss';

const types = [
  {
    name: 'Investigator Review',
    id: 'PI_REVIEW',
    show: false
  },
  {
    name: 'Study Manager Review',
    id: 'SM_REVIEW',
    show: false
  }
];

const AwaitingSignOffTopTenWidgetConfigModal = ({
  modalBox,
  columns,
  changeSettings,
  columnFlags,
  typeFlags,
  listViewMode,
  showIconsMode
}) => {
  const [showColumnFlags, setShowColumnFlags] = useState(columnFlags);
  const [showTypeFlags, setShowTypeFlags] = useState(typeFlags);
  const [listView, setListView] = useState(listViewMode || false);
  const [showIcons, setShowIcons] = useState(showIconsMode || false);

  const User = useCurrentUser();

  return (
    <>
      <ModalBoxes.Header>Config</ModalBoxes.Header>
      <ModalBoxes.Body>
        {User && User.activeRole !== ROLE_PRINCIPAL_INVESTIGATOR && (
          <>
            <h5>Review Types</h5>
            <div>
              {types.map((type, index) => (
                <div className="table-widget-row" key={index}>
                  <span className="table-widget-name">{type.name}</span>
                  <Toggle
                    name={type.name}
                    checked={typeFlags.includes(type.id)}
                    disabled={false}
                    onChange={e => {
                      setShowTypeFlags(prevState => {
                        let newState;
                        if (e.target.checked) {
                          newState = [...prevState, type.id];
                        } else {
                          newState = prevState.filter(e => e !== type.id);
                        }
                        return newState;
                      });
                    }}
                  />
                </div>
              ))}
            </div>
          </>
        )}

        <h5 className="mt-3">Columns</h5>
        <div>
          {columns &&
            columns.map((column, index) => (
              <div className="table-widget-row" key={index}>
                <span className="table-widget-name">{`${index + 1}. ${column.Header}`}</span>
                <Toggle
                  name={column.Header}
                  checked={column.show}
                  disabled={false}
                  onChange={e => {
                    setShowColumnFlags(prevState => {
                      let newState;
                      if (e.target.checked) {
                        newState = [...prevState, column.accessor];
                      } else {
                        newState = prevState.filter(e => e !== column.accessor);
                      }
                      return newState;
                    });
                  }}
                />
              </div>
            ))}
        </div>
        <h5 className="mt-3">View</h5>
        <div>
          <div className="table-widget-row " key={10}>
            <span className="table-widget-name">Use List View</span>
            <Toggle
              name={'Use List View'}
              checked={listView}
              disabled={false}
              onChange={e => {
                setListView(e.target.checked);
              }}
            />
          </div>
          <div className="table-widget-row " key={11}>
            <span className="table-widget-name">Show Icons</span>
            <Toggle
              name={'Show Icons'}
              checked={showIcons}
              disabled={!listView}
              onChange={e => {
                setShowIcons(e.target.checked);
              }}
            />
          </div>
        </div>
        <div className={'text-danger table-config-error-message'}>
          <>
            {isEmpty(showColumnFlags) && <div>Please select at least one column</div>}
            {isEmpty(showTypeFlags) && <div>Please select at least one type</div>}
          </>
        </div>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <div className="eds-btn-group">
          <ButtonGroup>
            <Button
              priority="medium"
              onClick={() => {
                modalBox.close();
              }}
            >
              Cancel
            </Button>
            <Button
              priority="high"
              disabled={isEmpty(showColumnFlags) || isEmpty(showTypeFlags)}
              onClick={() => {
                changeSettings(showColumnFlags, showTypeFlags, listView, showIcons);
                modalBox.close();
              }}
            >
              Save
            </Button>
          </ButtonGroup>
        </div>
      </ModalBoxes.Footer>
    </>
  );
};

AwaitingSignOffTopTenWidgetConfigModal.className = 'widget-config-modal';
AwaitingSignOffTopTenWidgetConfigModal.size = 'w650';

export default AwaitingSignOffTopTenWidgetConfigModal;
