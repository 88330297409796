import { STUDY_ENROLMENTS } from '../../../../constants/accessToPages';

import StudyEnrollment from './Enrollment';

export const studyEnrollmentRouterConfig = {
  name: 'Study Enrollment',
  path: '/study-enrollment',
  component: StudyEnrollment,
  access: [STUDY_ENROLMENTS]
};
