import React, { useContext } from 'react';

import { BUDGET_ITEM, MISSING_EVENTS, REASON } from '../../addLedgerConstants';
import { AddLedgerContext } from '../../AddLedgerContext';

import { MissedEvents } from './MissedEvents/MissedEvents';
import { MissingLedgerReason } from './MissingLedgerReason/MissingLedgerReason';
import { SelectMissedBudgetItem } from './SelectMissedBudgetItem/SelectMissedBudgetItem';

export function AddLedgerStep() {
  const { currentStep } = useContext(AddLedgerContext);
  const steps = {
    [BUDGET_ITEM]: <SelectMissedBudgetItem />,
    [MISSING_EVENTS]: <MissedEvents />,
    [REASON]: <MissingLedgerReason />
  };

  return steps[currentStep];
}
