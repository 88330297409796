import React, { Component } from 'react';
import cx from 'classnames';
import * as PropTypes from 'prop-types';

import common from '../../../../../common/common';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';

import ProtocolEncounter from './ProtocolEncounter';

import './ProtocolEpoch.scss';

class ProtocolEpoch extends Component {
  static propTypes = {
    epochIdx: PropTypes.number,
    epochId: PropTypes.string,
    epochInfo: PropTypes.object,
    addEpochDetails: PropTypes.func,
    addEncounter: PropTypes.func,
    getEncounterDetails: PropTypes.func,
    deleteEpochEncounter: PropTypes.func,
    destruct: PropTypes.func,
    epochsRelationsBroken: PropTypes.array,
    encountersRelationsBroken: PropTypes.array
  };

  componentDidMount() {
    this.props.addEndpointsForAnEpoch(this.props.epochInfo.nodeKey);
    this.props.makeEpochDraggable(this.props.epochInfo.nodeKey);
  }

  destructEncounter = (epochNodeKey, encounterKey) => {
    this.props.deleteEpochEncounter(epochNodeKey, encounterKey);
  };

  createEncounterList() {
    if (this.props.epochInfo.encounters) {
      return this.props.epochInfo.encounters.map((enc, enix) => {
        return (
          <ProtocolEncounter
            key={enc.enNodeKey}
            enc={enc}
            epochInfo={this.props.epochInfo}
            enix={enix}
            getEncounterDetails={this.props.getEncounterDetails}
            deleteEpochEncounter={this.destructEncounter}
            encountersRelationsBroken={this.props.encountersRelationsBroken}
          />
        );
      });
    }
  }

  deleteEpocData = event => {
    event.stopPropagation();
    try {
      ModalBoxes.confirm({
        content: 'Are you sure you want to delete this record?',
        confirmButton: 'Yes',
        cancelButton: 'No'
      }).then(
        () => {
          this.props.destruct(this.props.epochInfo.nodeKey);
        },
        () => {}
      );
    } catch (e) {
      console.log(e);
    }
  };

  addEpochDetails = event => {
    event.stopPropagation();
    this.props.addEpochDetails(this.props.epochInfo.nodeKey);
  };

  addEncounter = (pEle, nonProtocol) => {
    const childCount = this.props.epochInfo.encounters.length;
    this.props.addEncounter(pEle, nonProtocol, childCount);
  };

  render() {
    const { epochIdx, epochInfo, epochsRelationsBroken, openChangeProtocolEncounterOrderingModal } = this.props;
    const epochId = this.props.epochInfo.nodeKey;
    const left = epochInfo.position && epochInfo.position.left ? `${epochInfo.position.left}px` : '400px';
    const top = epochInfo.position && epochInfo.position.top ? `${epochInfo.position.top}px` : '10px';
    const epochName = epochInfo.name ? epochInfo.name : `Epoch${epochIdx}`;
    const style = { left, top };
    const borderWarning = epochsRelationsBroken.includes(epochId) ? 'incorrect-epoch-warning' : '';
    return (
      <div
        id={epochId}
        style={style}
        className={cx(borderWarning, 'protocol-setup-epoch col-auto border mr-4 p-3  mt-3 protocol-epoch')}
      >
        <div className="col-12 p-0 d-flex justify-content-between border-bottom-p-dotted pb-2 mb-3">
          <div className="d-inline-flex my-auto">
            <h5
              data-toggle="tooltip"
              data-placement="top"
              title={epochName}
              className="ep_title col-auto p-0 c-p m-auto"
              id={`${epochId}_title`}
            >
              {common.getSubString(epochName, 18)}
            </h5>
            <button
              className="glyphicon glyphicon-edit pr-2 c-p protocol-setup-btn"
              data-toggle="modal"
              onClick={this.addEpochDetails}
            />
            <button
              className="glyphicon glyphicon-sort pr-2 c-p protocol-setup-btn"
              data-toggle="modal"
              onClick={() => openChangeProtocolEncounterOrderingModal(epochInfo?.epochIdentifier)}
            />
          </div>
          <div className="d-flex my-auto">
            <button
              className="glyphicon glyphicon-remove c-p protocol-setup-btn m-auto"
              onClick={event => this.deleteEpocData(event)}
            />
          </div>
        </div>
        <div className="py-4 enscroll" id={`${epochId}_encounters`}>
          {this.createEncounterList()}
        </div>
        <div className="addiconnline">
          <button className="button-encounter" onClick={() => this.addEncounter(epochId, false)}>
            <span className="glyphicon glyphicon-plus" />
            Encounter
          </button>
          <button className="button-encounter enc p-0" onClick={() => this.addEncounter(epochId, true)}>
            <span className="glyphicon glyphicon-plus" />
            Non protocol
          </button>
        </div>
      </div>
    );
  }
}

export default ProtocolEpoch;
