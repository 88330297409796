import React from 'react';

import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import ScheduleAppointmentModal from '../../../patient-source/Patients/PatientInfo/EncountersSection/ScheduleAppointmentModal/ScheduleAppointmentModal';
import { OutreachAttemptTrackingModal } from '../../../patient-source/shared/NewEncounterPage/OutreachAttemptTrackingModal/OutreachAttemptTrackingModal';

export const viewAttempts = ssuPatientId => {
  ModalBoxes.open({
    component: <OutreachAttemptTrackingModal ssuPatientId={ssuPatientId} viewOnly={true} />,
    title: 'Contact Attempt Tracking',
    size: 'w1100',
    className: 'outreach-attempt-tracking-modal'
  });
};

export const openScheduleAppointmentModal = row => {
  ModalBoxes.open({
    component: (
      <ScheduleAppointmentModal
        patientId={row.patientUniqueIdentifier}
        ssuPatientId={row.sitePatientId}
        studyId={row.study.id}
        isWorklistPage
        encounterId={row.appointment.encounterId}
      />
    )
  });
};

export const normalizeStateForRestore = (savedState, stableColumns, dynamicColumns) => {
  const normalizedLeftPinnedColumns = savedState.pinnedColumns.left.filter(column => stableColumns.includes(column));
  const normalizedRightPinnedColumns = savedState.pinnedColumns.right.filter(column => stableColumns.includes(column));
  const normalizedOrdering = [
    ...savedState.columns.orderedFields.filter(field =>
      stableColumns.filter(name => !normalizedRightPinnedColumns.includes(name)).includes(field)
    ),
    ...dynamicColumns,
    ...normalizedRightPinnedColumns
  ];
  const normalizedColumnVisibilityModelWithoutDynamicColumns = Object.keys(
    savedState.columns.columnVisibilityModel
  ).reduce((acc, key) => {
    if (stableColumns.includes(key)) {
      acc[key] = savedState.columns.columnVisibilityModel[key];
    }
    return acc;
  }, {});
  return {
    ...savedState,
    columns: {
      ...savedState.columns,
      orderedFields: normalizedOrdering,
      columnVisibilityModel: normalizedColumnVisibilityModelWithoutDynamicColumns
    },
    pinnedColumns: {
      left: normalizedLeftPinnedColumns,
      right: normalizedRightPinnedColumns
    }
  };
};
