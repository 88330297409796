import React, { useCallback, useContext, useMemo, useState } from 'react';

export const FiltersContext = React.createContext(null);

export function FiltersProvider({ children }) {
  const [state, setState] = useState({ setupProtocol: { studyUniqueIdentifier: null } });
  const setFilterByKey = useCallback(function(key, filterObject) {
    setState(prevState => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        ...filterObject
      }
    }));
  }, []);
  return (
    <FiltersContext.Provider
      value={{
        ...state,
        setFilterByKey
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
}

export function useFilterContext(key) {
  const { [key]: filters, setFilterByKey } = useContext(FiltersContext);
  return useMemo(() => [filters, setFilterByKey], [filters, setFilterByKey]);
}

export function withFilterContext(key, Component) {
  return function WrapperComponent(props) {
    return (
      <FiltersContext.Consumer>
        {({ [key]: filters, setFilterByKey }) => (
          <Component {...props} setFilterByKey={setFilterByKey} filters={filters} />
        )}
      </FiltersContext.Consumer>
    );
  };
}
