import { generatePath } from 'react-router-dom';
import { isEmpty } from 'lodash/lang';
import { isFunction } from 'lodash/lang';

export function generateNavigationList(currentRoute) {
  if (isFunction(currentRoute.breadcrumb)) {
    return currentRoute.breadcrumb(currentRoute, generateDefaultNavigationList(currentRoute));
  }
  return generateDefaultNavigationList(currentRoute);
}

function generateDefaultNavigationList(currentMatch) {
  const navigationList = [];
  parseRecursively(currentMatch, true);
  function parseRecursively({ path, parent, name, key, isNested, rootBreadcrumbName }, current = false) {
    const { params } = currentMatch;
    navigationList.unshift({ path: generatePath(path, params), current, name, key, rootBreadcrumbName });
    if (!isEmpty(parent)) {
      parseRecursively(parent, isNested);
    }
  }
  return navigationList;
}
