import React, { useMemo } from 'react';

const TextLengthCounter = props => {
  const numberOfCharacters = useMemo(() => {
    if (props === undefined || props?.characters === null) {
      return 0;
    }
    return props.characters?.length;
  }, [props]);
  return <span style={{ fontSize: '12px', float: 'right' }}>{numberOfCharacters}/3000</span>;
};

export default TextLengthCounter;
