import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import * as PropTypes from 'prop-types';

import './ToggleSelect.scss';

export function ToggleSelect(props) {
  const { listOfItem, initialValue, onChange, className, disabled, reset } = props;
  const [selectedItem, setSelectedItem] = useState(initialValue);

  useEffect(() => {
    setSelectedItem(initialValue);
  }, [initialValue]);

  useEffect(() => {
    onChange(selectedItem);
  }, [selectedItem, onChange]);

  return (
    <div className={cx('eds-toggle-select', className)}>
      {listOfItem.map(item => {
        const selected = selectedItem === item.value;
        return (
          <button
            key={item.value}
            onClick={() => !disabled && onSelect(item)}
            className={cx('eds-toggle-select-button', {
              activated: selected && !disabled,
              disabled: selected && disabled,
              unresponsive: disabled
            })}
          >
            {item.name}
          </button>
        );
      })}
    </div>
  );
  function onSelect({ value }) {
    if (reset && value === selectedItem) {
      setSelectedItem(undefined);
    } else {
      setSelectedItem(value);
    }
  }
}

ToggleSelect.propTypes = {
  listOfItem: PropTypes.arrayOf(PropTypes.object),
  initialValue: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  reset: PropTypes.bool
};

ToggleSelect.defaultProps = { listOfItem: [], disabled: false, reset: false };
