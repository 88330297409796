import React from 'react';
import { useNavigate } from 'react-router-dom';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import moment from 'moment';

import useSessionStorage from '../../../../common/hooks/useSessionStorage';
import { DD_SLASH_MMM_SLASH_YY } from '../../../../constants/dateFormat';
import { SCHEDULING_WORK_LIST_FILTERS } from '../../../../constants/sessionStorageConstants';
import { scBlue100 } from '../../../../constants/systemColors';
import { generateUrlByKey } from '../../../root/router';

export const ScheduleAppointmentColumn = ({ selectedStudies, selectedStudySites, selectedSites, patient }) => {
  // eslint-disable-next-line no-unused-vars
  const [sessionStorage, setSessionStorage] = useSessionStorage(SCHEDULING_WORK_LIST_FILTERS, {});

  const navigate = useNavigate();
  const navigateToScheduleWorklist = () => {
    setSessionStorage(sessionStorage => ({
      ...sessionStorage,
      appointmentStatus: { id: 'NO_APPOINTMENT', name: 'Projected' },
      dateRangeStart: moment.utc().format(DD_SLASH_MMM_SLASH_YY),
      dateRangeEnd: moment
        .utc()
        .add(6, 'M')
        .format(DD_SLASH_MMM_SLASH_YY),
      selectedStudySites: selectedStudySites ? [selectedStudySites] : [],
      selectedStudies: selectedStudies ? [selectedStudies] : [],
      selectedSites: selectedSites ? [selectedSites] : [],
      encountersPerPerson: { id: 5, name: '5' },
      searchString: patient,
      page: 0
    }));
    setTimeout(() => navigate(generateUrlByKey('Worklist.Scheduling Worklist')));
  };
  return <ControlPointIcon onClick={navigateToScheduleWorklist} sx={{ color: scBlue100, cursor: 'pointer' }} />;
};
