import axios from 'axios';

const ROOT = 'api/v1/finance/revenue-adjustments';

const RevenueAdjustmentApi = {
  getLedgerEventInfo(eventNumber) {
    return axios.get(`${ROOT}/${eventNumber}/root-ledger-event`);
  },

  getRevenueAdjustmentHistory(eventNumber) {
    return axios.get(`${ROOT}/${eventNumber}`);
  },

  getCalculatedRevenueAdjustments(eventNumber, data) {
    return axios.post(`${ROOT}/${eventNumber}/preview`, data);
  },

  saveRevenueAdjustmentGroup(eventNumber, data) {
    return axios.post(`${ROOT}/${eventNumber}`, data);
  }
};

export default RevenueAdjustmentApi;
