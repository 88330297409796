import { useCallback, useReducer } from 'react';

import { defaultAddress } from './constans';
import { reducer } from './reducer';

const defaultState = {
  type: 'OTHER',
  ...defaultAddress
};

export default function useAddressFieldsReducer(initialState = {}) {
  const [state, dispatch] = useReducer(reducer, { ...defaultState, ...initialState });

  const setAddressField = useCallback(
    function(path, value) {
      dispatch({ type: 'SET_ADDRESS_FIELD', payload: { path, value } });
    },
    [dispatch]
  );

  const setAddressFields = useCallback(
    function(value) {
      dispatch({ type: 'SET_ADDRESS_FIELDS', payload: { value } });
    },
    [dispatch]
  );

  const setCountryId = useCallback(
    function(value) {
      dispatch({ type: 'SET_COUNTRY_ID', payload: { value } });
    },
    [dispatch]
  );

  const setCity = useCallback(
    function(value) {
      dispatch({ type: 'SET_CITY', payload: { value } });
    },
    [dispatch]
  );

  const setAddressType = useCallback(
    function(payload) {
      dispatch({ type: 'SET_ADDRESS_TYPE', payload });
    },
    [dispatch]
  );

  return {
    state,
    dispatch,
    setAddressField,
    setAddressFields,
    setCountryId,
    setCity,
    setAddressType
  };
}
