import React from 'react';
import cx from 'classnames';

import './CollapseList.scss';

const CollapseList = ({ listOfCollapsedGroups, toggleCollapse }) => {
  return (
    <div className="collapse-list">
      {listOfCollapsedGroups.map(group => {
        const classNameForGroup = cx('collapse-item', {
          'collapse-active': group.collapsed
        });
        const classNameForCount = cx({
          'not-empty-mapping': !group.collapsed && group.count
        });
        return (
          <div key={group.name} className={classNameForGroup} onClick={() => toggleCollapse(group.name)}>
            {group.name}: <span className={classNameForCount}>{group.count}</span>
          </div>
        );
      })}
    </div>
  );
};

export default CollapseList;
