import React, { useContext } from 'react';

import { PageInfoHeader } from '../../../../PageInfoHeader/PageInfoHeader';
import { Amount } from '../../shared/amount-view/Amount/Amount';
import { SitePaymentsContext } from '../SitePaymentsContext';
import { PAID, PENDING } from '../SitePaymentsTable/SitePaymentsConstants';

import { SitePaymentsFilters } from './SitePaymentsFilters/SitePaymentsFilters';

export const SitePaymentsControl = () => {
  const { tableData, checkedEvents } = useContext(SitePaymentsContext);

  const totalAmount = tableData?.reduce(
    (accumulator, currentValue) => (!currentValue.billNumber ? accumulator + currentValue.siteAmount : accumulator),
    0
  );

  const openAmount = tableData
    ?.filter(item => item.sitePaymentStatus !== PAID)
    .reduce((accumulator, currentValue) => accumulator + currentValue.siteAmount - currentValue.sitePaidAmount, 0);

  const selectedAmount = tableData
    ?.filter(item => checkedEvents.includes(item.itemSiteId))
    .reduce((accumulator, currentValue) => accumulator + (currentValue.siteAmount - currentValue.sitePaidAmount), 0);

  const paidAmount = tableData
    ?.filter(item => item.sitePaymentStatus === PAID)
    .reduce((accumulator, currentValue) => accumulator + currentValue.sitePaidAmount, 0);

  const pendingPaymentAmount = tableData
    ?.filter(item => item.sitePaymentStatus === PENDING)
    .reduce((accumulator, currentValue) => accumulator + currentValue.sitePaidAmount, 0);

  const pendingApprovalAmount = tableData
    ?.filter(item => item.siteApprovalStatus === 'READY_FOR_APPROVAL')
    .reduce((accumulator, currentValue) => accumulator + currentValue.sitePaidAmount, 0);

  return (
    <PageInfoHeader
      pageInfo={
        <PageInfoHeader.CollapsibleList>
          <PageInfoHeader.AdditionalInfo title="Total Amount" data-testid="site-payments-total-amount">
            <Amount coinsAmount={totalAmount} showDollarSign />
          </PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo title="Open" data-testid="site-payments-open-amount">
            <Amount coinsAmount={openAmount} showDollarSign />
          </PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo title="Paid" data-testid="site-payments-paid-amount">
            <Amount coinsAmount={paidAmount} showDollarSign />
          </PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo title="Pending Payment" data-testid="site-payments-pending-payment-amount">
            <Amount coinsAmount={pendingPaymentAmount} showDollarSign />
          </PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo title="Pending Approval" data-testid="site-payments-pending-approval-amount">
            <Amount coinsAmount={pendingApprovalAmount} showDollarSign />
          </PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo title="Selected Amount" data-testid="site-payments-selected-amount">
            <Amount coinsAmount={selectedAmount} showDollarSign />
          </PageInfoHeader.AdditionalInfo>
        </PageInfoHeader.CollapsibleList>
      }
    >
      <SitePaymentsFilters />
    </PageInfoHeader>
  );
};
