import React, { useEffect } from 'react';
import { uniqBy } from 'lodash/array';
import { isEmpty, isEqual } from 'lodash/lang';

import { ProtocolApi } from '../../../../../../../../api';
import Select from '../../../../../../../../common/data-entry/Select';
import { toIdWithName } from '../../taskServices';

/*TODO: need to cleanup*/
export default function TaskProtocolItemsSelect({
  studySiteId,
  studyId,
  onChangeEncounter,
  onChangeItemGroup,
  initialEncounter,
  initialItemGroup,
  isPopulated
}) {
  const [encounters, setEncounters] = React.useState([]);
  const [encounter, setEncounter] = React.useState(null);

  const [itemGroups, setItemGroups] = React.useState([]);
  const [itemGroup, setItemGroup] = React.useState(null);

  const updateEncounter = value => {
    value = value || null;
    if (!isEqual(value, encounter)) {
      setEncounter(value);
      onChangeEncounter(value || null);
    }
  };

  const updateItemGroup = value => {
    value = value || null;
    if (!isEqual(value, itemGroup)) {
      setItemGroup(value);
      onChangeItemGroup(value?.name || null);
    }
  };

  useEffect(() => {
    if (studySiteId) {
      ProtocolApi.getProtocolEncountersAndElementsForStudy(studyId, studySiteId).then(({ data }) => {
        setEncounters(uniqBy(data.encounters, e => e.id));
        setItemGroups(uniqBy(toIdWithName(data.itemGroups), e => e.name));
      });
    } else {
      setEncounters([]);
      setItemGroups([]);

      !isPopulated.encounter && updateEncounter(null);
      !isPopulated.itemGroup && updateItemGroup(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyId, studySiteId]);

  useEffect(
    function() {
      if (!isEmpty(encounters) && !isEmpty(itemGroups)) {
        if (initialEncounter && !isPopulated.encounter) {
          const encounter = encounters.find(i => i.id === initialEncounter.id);
          updateEncounter(encounter);
        }
        if (initialItemGroup && !isPopulated.itemGroup) {
          const itemGroup = itemGroups.find(i => i.name === initialItemGroup);
          updateItemGroup(itemGroup);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialEncounter, initialItemGroup, encounters, itemGroups]
  );

  if (isPopulated.encounter && isPopulated.itemGroup) return null;

  return (
    <>
      {!isPopulated.encounter && (
        <div className="etc-body-field-wrapper">
          <Select
            label="Encounter"
            disabled={!studySiteId}
            dataSource={encounters}
            onChange={updateEncounter}
            value={encounter}
            searchable={true}
            clearSearchOnSelection
            validate={false}
          />
        </div>
      )}
      {!isPopulated.itemGroup && (
        <div className="etc-body-field-wrapper">
          <Select
            label="Procedure / Logs"
            disabled={!studySiteId}
            dataSource={itemGroups}
            onChange={updateItemGroup}
            value={itemGroup}
            searchable={true}
            clearSearchOnSelection
            validate={false}
          />
        </div>
      )}
    </>
  );
}
