import React, { useState } from 'react';

import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../../common/general/Button';
import { scYellow10 } from '../../../../../../../../constants/systemColors';
import AELogsHistory from '../../../../../Patients/Logs/AELogsHistory';
import LogsTable from '../../../../../Patients/Logs/LogsTable';
import { defaultCellWidth } from '../../../../../Patients/Logs/LogsTableService';

export default function LogItemGroupAdverseEventHistoryModal({
  modalBox,
  rowId,
  logRenderData = [],
  columns,
  onEditComment,
  getTrProps
}) {
  const [changedItems, setChangedItems] = useState([]);
  return (
    <>
      <ModalBoxes.Body>
        <LogsTable
          columns={columns}
          data={logRenderData}
          getTrProps={getTrProps}
          sortable={false}
          showPagination={false}
          defaultCellWidth={defaultCellWidth}
          pageSize={logRenderData?.length || 1}
          getTdProps={(state, rowInfo, column) => {
            let props = {};
            const columnId = column.id;
            if (
              rowInfo &&
              (rowInfo.row[columnId]?.question === 'Comment' || rowInfo.row[columnId]?.itemGroupQuestionId === 'COVAL')
            ) {
              props = {
                onClick: event => {
                  onEditComment(rowInfo, rowInfo?.row[columnId], event);
                }
              };
            }
            if (changedItems && changedItems.length) {
              if (changedItems.includes(column?.id)) {
                props.style = { backgroundColor: scYellow10 };
              }
            }
            return props;
          }}
        />
        <AELogsHistory rowId={rowId} onHistoryDataLoad={setChangedItems} />
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <Button priority="medium" onClick={modalBox.close}>
          Close
        </Button>
      </ModalBoxes.Footer>
    </>
  );
}

LogItemGroupAdverseEventHistoryModal.className = 'log-item-group-adverse-event-history-modal';
LogItemGroupAdverseEventHistoryModal.size = 'w1250';
