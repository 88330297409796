import React, { useCallback } from 'react';
import cx from 'classnames';
import { isEqual } from 'lodash/lang';

import TextArea from '../../../../../../common/data-entry/TextArea';
import { ToggleSelect } from '../../../../../../common/inputs/ToggleSelect/ToggleSelect';
import { PS_NOT_ASKED, PS_UNAVAILABLE } from '../TableItemGroup/constants';

import './UnfilledRows.scss';

export function UnfilledRowReason(props) {
  const { naQues, naIdx, updateReasonStatus, updateReasonComment } = props;
  const onChangeStatus = useCallback(
    value => {
      updateReasonStatus(value, naIdx);
    },
    [naIdx, updateReasonStatus]
  );
  const onChangeReason = ({ target: { value } }) => {
    updateReasonComment(value, naIdx);
  };

  return (
    <div key={`eds-unfilled-row-${naIdx}`} className="eds-unfilled-row">
      <div className="eds-unfilled-row-meta-info">
        <span
          className={cx({
            'eds-unfilled-row-name': !isEqual('ALL', naIdx),
            'eds-unfilled-row-name-for-all': isEqual('ALL', naIdx)
          })}
        >
          {!isEqual('ALL', naIdx) ? naQues?.name : 'Apply to all'}
        </span>
        <div className="eds-unfilled-row-action">
          <ToggleSelect
            listOfItem={[
              { name: 'Not Asked', value: PS_NOT_ASKED },
              { name: 'Unavailable', value: PS_UNAVAILABLE }
            ]}
            initialValue={naQues?.performedStatus}
            onChange={onChangeStatus}
            reset
          />
        </div>
      </div>
      <div className="eds-unfilled-row-reason">
        <TextArea
          style={{ resize: 'none' }}
          rows={1}
          clearable
          label="Reason"
          name="reason"
          value={naQues?.comments}
          onChange={onChangeReason}
        />
      </div>
    </div>
  );
}
