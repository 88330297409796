import React from 'react';
import { first } from 'lodash/array';
import { isEmpty } from 'lodash/lang';

import Select from '../../../../../../../common/data-entry/Select';

const optionValueKey = 'personnelIdentifier';

export default function TaskAssigneesSelect({
  filteredAssignees,
  onChange,
  required,
  disabled,
  validationMessage,
  validate,
  assignee,
  onClear,
  clearable
}) {
  return (
    <Select
      required={required}
      validate={validate}
      validationMessage={validationMessage}
      label="Assignee"
      dataSource={filteredAssignees}
      value={assignee}
      searchable
      customOptionTemplateFunction={customOptionTemplateFunction}
      customSelectedValueTemplateFunction={customSelectedValueTemplateFunction}
      optionValueKey={optionValueKey}
      onClear={onClear}
      onChange={function(selectedAssignee) {
        const listIncludesAssignee = filteredAssignees?.some(
          ({ personnelIdentifier }) => personnelIdentifier === selectedAssignee?.personnelIdentifier
        );
        const value = listIncludesAssignee ? selectedAssignee || null : null;
        onChange(value);
      }}
      clearable={clearable}
      disabled={disabled}
    />
  );
}

function customOptionTemplateFunction(option) {
  if (isEmpty(option)) return;
  return (
    <div className="etc-task-assignees-user-info">
      <div className="etc-task-assignees-user-info-label">{option?.roles?.map(r => r.title).join(', ')}</div>
      <div className="etc-task-assignees-user-info-value">{`${option?.lastName} ${option?.firstName}`}</div>
    </div>
  );
}

function customSelectedValueTemplateFunction(option) {
  if (isEmpty(first(option))) return;
  return `${option[0]?.lastName} ${option[0]?.firstName}`;
}
