import React, { useCallback, useContext, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';

import FinTransactionLedgerEventApi from '../../../../../../api/finance/FinTransactionLedgerEventApi';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { LedgerContext } from '../../LedgerContext';
import LedgerTablePaymentReprocessingModal from '../LedgerTablePaymentReprocessingModal';
import LedgerTableStatusChangeModal from '../LedgerTableStatusChangeModal';

export const PatientLedgerMenu = ({ row }) => {
  const { applyFilters } = useContext(LedgerContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const changeStatusToPaid = useCallback(
    (eventId, number) => {
      return ModalBoxes.open({
        component: (
          <LedgerTableStatusChangeModal
            onConfirm={FinTransactionLedgerEventApi.changePatientStipendPaymentStatus}
            eventId={eventId}
            number={number}
            successCallback={applyFilters}
          />
        )
      });
    },
    [applyFilters]
  );

  const patientStipendPaymentReprocessingModal = useCallback(eventId => {
    return ModalBoxes.open({
      component: (
        <LedgerTablePaymentReprocessingModal
          onConfirm={FinTransactionLedgerEventApi.makePatientStipendEventVisibleForPayment}
          eventId={eventId}
        />
      )
    });
  }, []);

  return (
    <>
      <IconButton sx={{ marginLeft: '5px' }} onClick={openMenu} data-testid={`event-row-menu-${row.eventNumber}`}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        slotProps={{
          paper: {
            sx: {
              width: '205px'
            }
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem
          onClick={() => {
            changeStatusToPaid(row.id);
            handleCloseMenu();
          }}
        >
          Change Status to "Paid"
        </MenuItem>
        <MenuItem
          onClick={() => {
            patientStipendPaymentReprocessingModal(row.id);
            handleCloseMenu();
          }}
        >
          Process Stipend
        </MenuItem>
      </Menu>
    </>
  );
};
