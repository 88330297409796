import { flatMap } from 'lodash/collection';
import moment from 'moment';

export function getChildParentsCountMap(formValidationRules) {
  const childParentMap = {};
  const childParentsCountMap = {};

  Object.values(formValidationRules).forEach(rule => {
    flatMap(Object.values(rule.targetIdentifiersMap)).forEach(target => {
      if (childParentMap[target]) {
        childParentMap[target] = [...childParentMap[target], rule.sourceIdentifier];
      } else {
        childParentMap[target] = [rule.sourceIdentifier];
      }
    });
  });

  const allConditionalChildren = Object.keys(childParentMap);

  function countParents(original, child) {
    const parents = childParentMap[child];

    parents.forEach(parent => {
      if (allConditionalChildren.includes(parent)) {
        childParentsCountMap[original] += 1;
        countParents(original, parent);
      }
    });
  }

  allConditionalChildren.forEach(child => {
    childParentsCountMap[child] = 1;
    countParents(child, child);
  });

  return childParentsCountMap;
}

//TODO: remove of fix this implementation after all Item Groups are correctly configured
//This method is sort of dirty hack
//New Algorithm should be used only for items that were correctly configured after release 3.5
//so everything will work after release 3.6
//"correctly configured" means that every CUSTOM field has a conditional parent
export function isNewAlgorithmForConditionalQuestions(formData, formValidationRules) {
  let conditionalLogicForAllCustomFieldsIsConfiguredInProtocol = false;
  const allConditionalChildren = Object.values(formValidationRules).flatMap(rule =>
    flatMap(Object.values(rule.targetIdentifiersMap))
  );
  if (formData.itemDefinitionList) {
    const allCustomItems = formData.itemDefinitionList.filter(e => e.isChecked && e.type === 'CUSTOM');
    conditionalLogicForAllCustomFieldsIsConfiguredInProtocol = allCustomItems.every(e =>
      allConditionalChildren.includes(e.uniqueIdentifier)
    );
  }
  const dateIsAfterRelease3dot5 = moment(formData.lastModifiedOn).isAfter(moment('2022/09/06', 'YYYY/MM/DD'));
  const dateIsAfterRelease4 = moment(formData.lastModifiedOn).isAfter(moment('2023/01/04', 'YYYY/MM/DD'));
  return dateIsAfterRelease4
    ? true
    : conditionalLogicForAllCustomFieldsIsConfiguredInProtocol && dateIsAfterRelease3dot5;
}
