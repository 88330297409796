import { SvgIcon } from '@mui/material';

export const AlignLeft = ({ onClick, active }) => {
  return (
    <SvgIcon onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M3 18H9V16H3V18ZM3 11V13H15V11H3ZM3 6V8H21V6H3Z"
          fill="black"
          fillOpacity={`${active ? '0.9' : '0.6'}`}
        />
      </svg>
    </SvgIcon>
  );
};
