import uuid from 'uuid/v4';

export const TABLE_SETUP = 'New Table';
export const CONTEXT_MODE = {
  SETUP: 'SETUP',
  EDIT: 'EDIT',
  UNMOUNTED: 'UNMOUNTED'
};
export const QUESTION_LAYOUT = {
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL'
};

export const CUSTOM_FIELD_TYPES = [
  {
    name: 'CheckList',
    value: 'checkbox'
  },
  {
    name: 'Date',
    value: 'date'
  },
  {
    name: 'DropDown',
    value: 'dropdown'
  },
  {
    name: 'Drop Down Controlled Term',
    value: 'dropdownCT'
  },
  {
    name: 'File Upload',
    value: 'file'
  },
  {
    name: 'Single File Upload',
    value: 'singleFile'
  },
  {
    name: 'Free Text',
    value: 'textbox'
  },
  {
    name: 'Free Text Long',
    value: 'textarea'
  },
  {
    name: 'MultiSelect',
    value: 'multiselect'
  },
  {
    name: 'Multi Select Controlled Term',
    value: 'multiselectCT'
  },
  {
    name: 'Reminder',
    value: 'reminder'
  },

  {
    name: 'Text Block Long',
    value: 'textBlockLong'
  },
  {
    name: 'Text Block',
    value: 'label'
  },
  {
    name: 'Toggle',
    value: 'radio'
  },
  {
    name: 'Time',
    value: 'time'
  }
];

export const CUSTOM_TOGGLE_SETUP_DROPDOWN_VALUE = [
  {
    id: 'CLINSIG_1',
    name: 'CLINSIG'
  },
  {
    id: 'NORMABNM_2',
    name: 'NORMABNM'
  },
  {
    id: 'NY_3',
    name: 'NY'
  }
];

export const MOCKED_COMMENT_FIELD = {
  protocolItemDefinitionId: uuid(),
  performedStatus: 'NOT_ANSWERED',
  fieldComment: null,
  reasonForDataChange: null,
  fieldValue: null,
  requiredAttention: false,
  fieldType: 'textarea',
  fileList: null,
  filesResponse: null,
  fieldName: 'Comment',
  codeDefinition: [],
  protocolTableGeneralFieldId: uuid(),
  fieldUid: uuid()
};
