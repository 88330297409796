import React, { useContext, useState } from 'react';
import { isObject } from 'lodash/lang';
import { get } from 'lodash/object';

import Icon from '../../../../../common/general/Icon';
import { scYellow10 } from '../../../../../constants/systemColors';

import AELogsHistory from './AELogsHistory';
import { LogsContext } from './LogsPageContext';
import LogsTable from './LogsTable';
import { getColumns } from './LogsTableService';

import './AELogHistory.scss';

export default function AELogsHistoryPage() {
  const { itemGroupData, onEditComment, longAnswers, setHistoryForRow, historyForRow } = useContext(LogsContext);

  const [changedItems, setChangedItems] = useState([]);
  const rowId = historyForRow.rowId;

  return (
    <div className={'ae-log-history'}>
      <div className={'link'} onClick={() => setHistoryForRow(null)} style={{ display: 'flex', width: 'fit-content' }}>
        <Icon className={'mr-2'} style={{ fontSize: '25px' }}>
          arrow_back
        </Icon>
        Back To Log
      </div>
      <LogsTable
        data={itemGroupData?.tableData?.filter(e => e.rowId === rowId)}
        columns={[...getColumns(itemGroupData?.columnList, false, 'AE')]}
        getTdProps={(state, rowInfo, column) => {
          let props = {};
          const columnId = column.id;
          if (
            rowInfo &&
            (rowInfo.row[columnId]?.question === 'Comment' || rowInfo.row[columnId]?.itemGroupQuestionId === 'COVAL')
          ) {
            let commentValue = get(rowInfo, `row[${columnId}].answer`, '');
            props.onClick = event => {
              onEditComment(rowInfo, commentValue, event);
            };
          }
          if (
            rowInfo &&
            isObject(rowInfo.row) &&
            rowInfo?.row[columnId]?.answer?.length > 1 &&
            rowInfo?.row[columnId]?.attributeType !== 'file' &&
            longAnswers.includes(rowInfo.row[columnId]?.name)
          ) {
            props = { ...props, className: 'text-left' };
          }
          if (changedItems && changedItems.length) {
            const column = rowInfo.row[columnId];
            if (changedItems.includes(column?.attributeIdentifier)) {
              props.style = { backgroundColor: scYellow10 };
            }
          }

          return props;
        }}
      />
      <AELogsHistory rowId={rowId} onHistoryDataLoad={setChangedItems} />
    </div>
  );
}
