import React from 'react';

import PatientDetailsContactInformation from './PatientDetailsSections/PatientDetailsContactInformation';
import PatientDetailsDemographicsInformation from './PatientDetailsSections/PatientDetailsDemographicsInformation';
import PatientDetailsFinancialInformation from './PatientDetailsSections/PatientDetailsFinancialInformation';
import PatientDetailsGuardianInformation from './PatientDetailsSections/PatientDetailsGuardianInformation';
import PatientDetailsInstructionsInformation from './PatientDetailsSections/PatientDetailsInstructionsInformation';

import './PatientDetails.scss';

export default function PatientDetails() {
  return (
    <>
      <PatientDetailsContactInformation />
      <PatientDetailsDemographicsInformation />
      <PatientDetailsFinancialInformation />
      <PatientDetailsInstructionsInformation />
      <PatientDetailsGuardianInformation />
    </>
  );
}
