import separateAxiosInstance from '../separateAxiosInstance';

const ROOT = 'api/v1/studies/';

const InvestigatorSignatureApi = {
  sign(
    studyId,
    ssuPatientId,
    itemGroupTemplateId,
    itemGroupTemplateVersionId,
    rowId,
    request,
    token,
    hideLoader = true
  ) {
    return separateAxiosInstance.post(
      `${ROOT}${studyId}/patients/${ssuPatientId}/item-groups/${itemGroupTemplateId}/${itemGroupTemplateVersionId}/logs/rows/${rowId}/investigator/sign`,
      request,
      {
        hideLoader,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  },
  signAdverseEventLogRows(studyId, ssuPatientId, itemGroupTemplateId, itemGroupTemplateVersionId, request, token) {
    return separateAxiosInstance.post(
      `${ROOT}${studyId}/patients/${ssuPatientId}/item-groups/${itemGroupTemplateId}/logs/sign`,
      request,
      {
        hideLoader: false,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  }
};

export default InvestigatorSignatureApi;
