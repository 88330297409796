import React from 'react';
import { isEmpty, isEqual } from 'lodash/lang';

import { COMMENT_FORM_QUESTION_ID } from '../../../../../../constants/protocolConstant';
import { isUserAbleToManageSmAndPiReviewComment } from '../reviewAccessService';
import { ADD_COMMENT, COMMENT, COMMENTS, COMMENTS_DOMAIN_CODE, RowReviewStatus } from '../reviewConstants';
import { isCraReview, isSmOrPiReview } from '../reviewService';

export function isCommentAnswer(answer) {
  return answer.itemGroupQuestionId === COMMENT_FORM_QUESTION_ID;
}

export function isAnswerAffectedByConditionalLogic(answer) {
  return answer.type === 'NO_SENSE';
}

export function resolveAnswerNoPopulatedReason(answer) {
  const answerType = answer?.type;
  if (answerType === 'NOT_ASKED') {
    return `Not Asked: ${answer.emptyReason}`;
  }
  if (answerType === 'UNAVAILABLE') {
    return `Unavailable: ${answer.emptyReason}`;
  }
  if (answerType === 'NO_SENSE') {
    return 'Unavailable';
  }
  if (answerType === 'EMPTY') {
    return '';
  }

  return '--';
}

export function isAnswerPopulated(answer) {
  return answer?.type === 'VALUE';
}

export function getItemGroupType({ domainCode, patientItemGroupType }) {
  if (domainCode === COMMENTS_DOMAIN_CODE) {
    return COMMENTS;
  }
  return patientItemGroupType;
}

export function getConfirmedItemGroupsCount(requiredAttentionItemGroupsConfirmStatuses) {
  let confirmedItemGroupsCount = 0;
  for (const itemGroupId in requiredAttentionItemGroupsConfirmStatuses) {
    if (requiredAttentionItemGroupsConfirmStatuses.hasOwnProperty(itemGroupId)) {
      if (requiredAttentionItemGroupsConfirmStatuses[itemGroupId] === true) {
        confirmedItemGroupsCount++;
      }
    }
  }
  return confirmedItemGroupsCount;
}

export function getRequireAttentionItemGroupsCount(requiredAttentionItemGroupsConfirmStatuses) {
  return Object.keys(requiredAttentionItemGroupsConfirmStatuses).length;
}

export function isSignedReviewStatus(reviewStatus) {
  return reviewStatus === 'SIGNED';
}

export function openCommentModalLinkContent(commentAnswer, reviewType, isHistoricalData, studySiteStatus) {
  const isCraReviewType = isCraReview(reviewType);
  if (
    isEmpty(commentAnswer?.stringValue) &&
    !isCraReviewType &&
    !isHistoricalData &&
    isUserAbleToManageSmAndPiReviewComment(studySiteStatus)
  ) {
    return ADD_COMMENT;
  } else {
    if (!isEmpty(commentAnswer?.stringValue)) {
      return COMMENT;
    }
  }
  return null;
}

export function isEmptyOrTheSameAsPrevious(previousValue, currentValue) {
  return isEqual(previousValue, currentValue) || isEmpty(currentValue);
}

export function getAnswerHighlightStyleClassByAnswerAndReviewType(answer, reviewType) {
  if (answer?.highlighted) {
    return { className: 'answer-required-attention-bg' };
  }
  if (isSmOrPiReview(reviewType) && answer?.isEditedAfterSign) {
    return { className: 'edited-data-after-sign-bg' };
  }
  return {};
}

export function getFormHighlightStyleBasedOnRowReviewStatusAndReviewType(rowReviewStatus, reviewType) {
  const isRowNeverSigned = rowReviewStatus === RowReviewStatus.NEVER_SIGNED;
  if (isSmOrPiReview(reviewType) && isRowNeverSigned) {
    return { className: 'new-not-signed-data-bg' };
  }
  return {};
}

export function generateResearchConnectStatus(response) {
  if (!isEmpty(response)) {
    return response.reduce(
      (sum, item) => (
        <>
          {sum} <br /> {item.platformName} : {item.status}
        </>
      ),
      <>EDC send status</>
    );
  }
}
