import React, { useEffect, useState } from 'react';
import { flatten } from 'lodash/array';
import { isFunction } from 'lodash/lang';

import { CalendarInstanceApi } from '../../../../api';

import UserCalendars from './UserCalendars/UserCalendars';

export function CalendarSelect({ initialCalendarsProvider, onCalendarsChanged }) {
  const [calendars, setCalendars] = useState([]);

  useEffect(() => {
    const studyCalendarsPromise = CalendarInstanceApi.getAvailableStudies().then(({ data: studies }) =>
      studies.map(s => ({
        type: 'Study',
        label: 'Study',
        name: s.name,
        id: s.id
      }))
    );
    const siteCalendarsPromise = CalendarInstanceApi.getAvailableSites().then(({ data: sites }) =>
      sites.map(s => ({
        type: 'Site',
        label: 'Site',
        name: s.name,
        id: s.id
      }))
    );
    const participantsCalendarsPromise = CalendarInstanceApi.getUserParticipants().then(({ data: participants }) =>
      participants.map(s => ({
        type: 'User',
        label: s.label,
        name: s.name,
        id: s.userId
      }))
    );
    Promise.all([participantsCalendarsPromise, siteCalendarsPromise, studyCalendarsPromise]).then(calendars => {
      setCalendars(flatten(calendars));
    });
    // store can be skipped from dep. as logged user not expected to change
    // eslint-disable-next-line
  }, []);

  return (
    <UserCalendars
      calendars={calendars}
      initialCalendars={isFunction(initialCalendarsProvider) ? initialCalendarsProvider() : []}
      onCalendarsChange={onCalendarsChanged}
    />
  );
}
