import React, { useCallback, useEffect, useState } from 'react';
import { differenceWith } from 'lodash/array';
import { some } from 'lodash/collection';
import { isEqual } from 'lodash/lang';
import uuid from 'uuid/v4';

import { FormBuilderApi } from '../../../../../../../../../api';
import MultiSelect from '../../../../../../../../../common/data-entry/MultiSelect/MultiSelect';
import Select from '../../../../../../../../../common/data-entry/Select';
import NotificationManager from '../../../../../../../../../common/notifications/NotificationManager';
import { DUPLICATE_CUSTOM_OPTION_VALUES_NOT_ALLOWED } from '../../../../../../../../../constants/notificationMessages';
import DragAndDropFinalOptionList from '../EditCustomOptionListModal/DrafAndDropFinalOptionList/DragAndDropFinalOptionList';
import { deleteOptionFromList, onDragEnd } from '../EditCustomOptionListModal/EditCustomOptionListModal';

import OptionInput from './OptionInput';

import './ControlledTermList.scss';

const ControlledTermList = ({
  protocolId,
  inputType,
  terminologyVersionId,
  isTemplateMode,
  initialControlledTermList,
  initialControlledListName,
  setFinalControlledTermListForSave,
  setSelectedControlledTermListName
}) => {
  const [finalControlledTermList, setFinalControlledTermList] = useState([]);
  const [controlledTermList, setControlledTermList] = useState([]);
  const [selectedControlledTermList, setSelectedControlledTermList] = useState(null);
  const [controlledTermOptionsList, setControlledTemOptionsList] = useState([]);
  const [selectedControlledTermOptionList, setSelectedControlledTermOptionList] = useState([]);

  useEffect(() => {
    if (selectedControlledTermList) {
      const normalizedListValue = selectedControlledTermList.ctMenu.map(item => ({
        id: item.uniqueIdentifier,
        name: item.ctValue
      }));
      const normalizedValueForFinalList = normalizedListValue.map((item, index) => ({
        codedValue: null,
        codeOid: '',
        decode: item.name,
        inputType: ['dropdown', 'dropdownCT'].includes(inputType) ? 'dropdown' : 'multiselect',
        name: item.name,
        sequence: index + 1,
        id: uuid()
      }));

      setControlledTemOptionsList(normalizedListValue);
      setSelectedControlledTermOptionList(normalizedListValue);
      setSelectedControlledTermListName(selectedControlledTermList.name);
      if (selectedControlledTermList.name !== initialControlledListName) {
        setFinalControlledTermList(normalizedValueForFinalList);
      } else {
        const preparedInitialValue = initialControlledTermList.map((element, index) => ({
          ...element,
          sequence: index + 1
        }));
        setFinalControlledTermList(preparedInitialValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedControlledTermList]);

  useEffect(() => {
    if (initialControlledTermList) {
      const preparedInitialValue = initialControlledTermList.map((element, index) => ({
        ...element,
        sequence: index + 1
      }));
      setFinalControlledTermList(preparedInitialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    resolveApiForCTList().then(({ data }) => {
      const allLists = data.map(item => {
        return { id: item.uniqueIdentifier, name: item.ctName, ctMenu: item.ctMenu };
      });
      allLists.push({ name: '{TOXGR}', ctMenu: [] });
      setControlledTermList(allLists);
      if (initialControlledListName && some(allLists, ['name', initialControlledListName])) {
        const termList = allLists.filter(list => list.name === initialControlledListName);
        setSelectedControlledTermList(termList[0]);
        const normalizedListValue = termList[0].ctMenu.map(item => ({
          id: item.ctValue,
          name: item.ctValue
        }));
        setControlledTemOptionsList(normalizedListValue);
        if (initialControlledTermList) {
          const selected = initialControlledTermList.map(item => ({ name: item.name, id: item.name }));
          setSelectedControlledTermOptionList(selected);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFinalControlledTermListForSave(finalControlledTermList);
  }, [finalControlledTermList, setFinalControlledTermListForSave]);

  const resolveApiForCTList = () => {
    if (isTemplateMode && terminologyVersionId) {
      return FormBuilderApi.getCTListsForTerminologyVersionGroup(terminologyVersionId);
    }
    return FormBuilderApi.getCTLists(protocolId);
  };

  const addNewCustomOptionInFinalList = newValue => {
    if (some(finalControlledTermList, ['name', newValue.trim()])) {
      NotificationManager.error(DUPLICATE_CUSTOM_OPTION_VALUES_NOT_ALLOWED);
    } else {
      const newOptionItem = {
        codedValue: null,
        codeOid: '',
        decode: newValue,
        inputType: ['dropdown', 'dropdownCT'].includes(inputType) ? 'dropdown' : 'multiselect',
        name: newValue,
        sequence: finalControlledTermList.length + 1,
        id: uuid()
      };
      setFinalControlledTermList([...finalControlledTermList, newOptionItem]);
    }
  };

  const onChangeMultiselectCallback = useCallback(
    selectedValue => {
      if (!isEqual(selectedValue, selectedControlledTermOptionList)) {
        if (selectedValue.length === 0) {
          setFinalControlledTermList([]);
        } else if (selectedValue.length - selectedControlledTermOptionList.length > 1) {
          const allDropdownValue = selectedValue.map((item, index) => ({
            codedValue: null,
            codeOid: '',
            decode: item.name,
            inputType: 'dropdown',
            name: item.name,
            sequence: index + 1
          }));
          setFinalControlledTermList(allDropdownValue);
        } else if (selectedValue.length > selectedControlledTermOptionList.length) {
          const addedValue = differenceWith(selectedValue, selectedControlledTermOptionList, isEqual);
          setFinalControlledTermList([
            ...finalControlledTermList,
            {
              codedValue: null,
              codeOid: '',
              decode: addedValue[0].name,
              inputType: 'dropdown',
              name: addedValue[0].name,
              sequence: finalControlledTermList.length + 1
            }
          ]);
        } else if (selectedValue.length < selectedControlledTermOptionList.length) {
          const deletedValue = differenceWith(selectedControlledTermOptionList, selectedValue, isEqual);
          const updatedFinalValue = finalControlledTermList.filter(listItem => listItem.name !== deletedValue[0].name);
          setFinalControlledTermList(updatedFinalValue);
        }
        setSelectedControlledTermOptionList(selectedValue);
      }
    },
    [selectedControlledTermOptionList, finalControlledTermList]
  );
  return (
    <>
      <div className="controlled-term-list">
        <div className="new-option-label">Controlled Term List</div>
        <Select
          clearSearchOnSelection
          dataSource={controlledTermList}
          optionLabelKey="name"
          optionValueKey="id"
          onChange={newList => {
            if (!isEqual(selectedControlledTermList, newList)) {
              setSelectedControlledTermList(newList);
            }
          }}
          searchable
          keepOpenOnSelection={false}
          closeOnSelectedOptionClick
          deselectOnSelectedOptionClick={false}
          disabled={false}
          validate={false}
          clearable={false}
          value={selectedControlledTermList}
          required={true}
          className="controlled-term-list-dropdown"
        />
      </div>
      <div className="controlled-term-list">
        <div className="new-option-label">Controlled Term Options List</div>
        <MultiSelect
          dataSource={controlledTermOptionsList}
          onChange={onChangeMultiselectCallback}
          searchable
          value={selectedControlledTermOptionList}
          className="controlled-term-list-dropdown"
          clearable={false}
          validate={false}
        />
      </div>
      <OptionInput addNewCustomOptionInFinalList={addNewCustomOptionInFinalList} label="Custom Option List" />
      <DragAndDropFinalOptionList
        onDragEnd={result => onDragEnd(result, finalControlledTermList, setFinalControlledTermList)}
        finalOptionList={finalControlledTermList}
        deleteOptionFromList={index => {
          deleteOptionFromList(index, finalControlledTermList, setFinalControlledTermList);
          const updatedList = selectedControlledTermOptionList.filter(
            item => item.name !== finalControlledTermList[index].name
          );
          setSelectedControlledTermOptionList(updatedList);
        }}
      />
    </>
  );
};

export default ControlledTermList;
