import { array, object, string } from 'yup';

import { seatingPreferences } from './constants';

export const validationSchema = object({
  preferredTravelTime: array().of(string().oneOf(['EARLY_MORNING', 'LATE_MORNING', 'AFTERNOON', 'EVENING'])),
  preferredAirline: string().max(100),
  frequentFlightNumber: string().max(100),
  seatingPreference: string().oneOf(seatingPreferences.map(({ id }) => id)),
  preferredAirport: string().max(100),
  specialAccommodation: string()
});
