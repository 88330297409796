import React from 'react';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { IconButton } from '@mui/material';
import Tooltip from 'rc-tooltip';

import EncountersHeaders from './EncountersHeaders/EncountersHeaders';
import EpochsHeaders from './EpochsHeaders/EpochsHeaders';
import SituationalEncounterHeaders from './SituationalEncounterHeaders/SituationalEncounterHeaders';
import SituationalEncountersTitle from './SituationalEncountersTitle/SituationalEncountersTitle';

import './EncounterEpochTableTemplate.scss';

export function EncounterEpochTableTemplate({
  epochs,
  encounters,
  situationalEncounters,
  updateSituationalEncounters,
  children,
  isUnexpected,
  openChangeProtocolEncounterOrderingModal
}) {
  return (
    <table className="encounter-epoch-table-template">
      <thead className="eett-sticky-row">
        <tr>
          <th rowSpan="2" width="300" className="eett-header eett-grey-cell eett-right-align eett-sticky-main-header">
            {openChangeProtocolEncounterOrderingModal && (
              <IconButton
                size="small"
                title="Change Protocol Encounter Ordering"
                onClick={openChangeProtocolEncounterOrderingModal}
              >
                <SwapHorizIcon />
              </IconButton>
            )}
            <span>Procedure</span>
          </th>
          <EpochsHeaders epochs={epochs} />
          {isUnexpected && <th className="eett-grey-cell eett-epoch-cell eett-middle-align eett-sticky-row" />}
          <th
            className="eett-grey-cell eett-epoch-cell eett-middle-align eett-sticky-row"
            height="38px"
            key={'unexpectedEncounterHeaderKey'}
            id={'unexpectedEncounterHeaderId'}
            colSpan={1}
            title="Unexpected encounters"
          >
            <Tooltip
              destroyTooltipOnHide={false}
              placement="top"
              overlay={`Unexpected encounters`}
              align={{
                offset: [50, -5]
              }}
              trigger={Object.keys({ hover: 1 })}
            >
              <span className={'eds-unexpected-encounter-column'}>{'Unexpected encounters'}</span>
            </Tooltip>
          </th>
          <SituationalEncountersTitle situationalEncounters={situationalEncounters} />
        </tr>
        <tr>
          <EncountersHeaders encounters={encounters} />
          <th
            height="38px"
            width="90"
            key={'unexpectedEncounterKey'}
            id={'unexpectedEncounterId'}
            className="eett-encounter-cell eett-sticky-encounter"
          >
            <Tooltip
              overlay={'Unexpected Encounter'}
              placement="top"
              mouseEnterDelay={0}
              mouseLeaveDelay={0.1}
              destroyTooltipOnHide={false}
              trigger={Object.keys({ hover: 1 })}
              align={{
                offset: [50, -5]
              }}
            >
              <span className="eett-encounter">
                <span className="eett-encounter-board eett-front-encounter-board">{'UE'}</span>
                <span
                  className={
                    'eett-unexpected-encounter eett-back-unexpected-encounter-board eett-unexpected-encounter-board'
                  }
                />
              </span>
            </Tooltip>
          </th>
          <SituationalEncounterHeaders
            encounters={situationalEncounters}
            updateSituationalEncounters={updateSituationalEncounters}
          />
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
}
