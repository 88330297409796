export const ACTIVE_SPE_STATUS = 'ACTIVE';
export const CANCELED_SPE_STATUS = 'CANCELED';
export const COMPLETE_SPE_STATUS = 'COMPLETE';

// Encounter Types
export const REGULAR = 'REGULAR';
export const UNEXPECTED = 'UNEXPECTED';
export const NON_PROTOCOL = 'NON_PROTOCOL';
export const SITUATIONAL = 'SITUATIONAL';
export const PROJECTED = 'PROJECTED';

export const isSituationalOrUnexpectedEncounter = type => {
  return [SITUATIONAL, UNEXPECTED].includes(type);
};
