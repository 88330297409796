import React, { Fragment, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import DateTime from 'react-datetime';
import cx from 'classnames';
import { isFunction, isString } from 'lodash/lang';
import moment from 'moment';

import useOutsideClickDetector from '../../../hooks/useOutsideClickDetector';
import Input from '../../Input';
import { datePickerManualInputTypes } from '../types';

import 'react-datetime/css/react-datetime.css';
import './DatePickerManual.scss';

export const dateViewOptions = {
  DATE: {
    dateFormat: 'DD-MMM-YYYY',
    viewMode: 'days',
    displayDateFormat: 'DD/MMM/YYYY',
    displayDatePrefix: '',
    placeholder: 'DD/MMM/YYYY'
  },
  MONTH: {
    dateFormat: 'MMM-YYYY',
    viewMode: 'months',
    displayDateFormat: 'MMM/YYYY',
    displayDatePrefix: 'UN/',
    placeholder: 'UN/MMM/YYYY'
  },
  YEAR: {
    dateFormat: 'YYYY',
    viewMode: 'years',
    displayDateFormat: 'YYYY',
    displayDatePrefix: 'UN/UNK/',
    placeholder: 'UN/UNK/YYYY'
  }
};

const DatePickerManual = React.forwardRef(function(props, ref) {
  const {
    onChange,
    className,
    required,
    validationMessage,
    isValidDate,
    value,
    label,
    iconsAfter,
    dateOnly,
    legacyLook,
    disabled
  } = props;
  const [viewMode, setViewMode] = useState(dateViewOptions.DATE);
  const [date, setDate] = useState(null);
  const [inputDate, setInputDate] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const datePickerElement = useRef();

  useImperativeHandle(ref, () => datePickerElement.current);

  useOutsideClickDetector(datePickerElement, () => {
    setShowDialog(false);
  });

  const isValidString = useCallback(
    input => {
      const { day, month, year, dateArray } = getParsedValues(input);
      const isValidString = dateArray.length === 3 && isValidDay(day) && isValidMonth(month, day) && isValidYear(year);

      if (isValidDate && isFunction(isValidDate)) {
        const validated = isValidDate(moment(input, dateViewOptions.DATE.dateFormat));
        return (isValidString && validated) || input === '';
      }

      return isValidString || input === '';
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isValidDate]
  );

  useEffect(
    function() {
      if (showDialog === false && (!isValidString(inputDate) || inputDate === '')) {
        setDate(null);
        setInputDate('');
      }
    },
    [showDialog, inputDate, isValidString]
  );

  useEffect(
    function() {
      isFunction(onChange) && onChange(date, inputDate);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date, inputDate]
  );

  const updateDateChanges = e => {
    const formattedDate = `${viewMode.displayDatePrefix}${e.format(viewMode.displayDateFormat)}`;
    setShowDialog(false);
    setDate(e);
    setInputDate(formattedDate);
    isFunction(onChange) && onChange(e, formattedDate);
  };

  const getButtonClass = type => {
    return cx('button', { active: viewMode.viewMode === type });
  };

  const onInputChange = useCallback(
    val => {
      const value = val.substring(0, 11);
      if (isValidString(value) && value !== '') {
        const { day, month, year } = getParsedValues(value);
        if (day.toUpperCase() === 'UN' && month.toUpperCase() === 'UNK') {
          setDate(moment(`01/Jan/${year}`, dateViewOptions.DATE.dateFormat));
          setViewMode(dateViewOptions.YEAR);
          setInputDate(`UN/UNK/${year}`);
        } else if (day.toUpperCase() === 'UN') {
          const date = moment(`01/${month}/${year}`, dateViewOptions.DATE.dateFormat);
          setDate(date);
          setViewMode(dateViewOptions.MONTH);
          setInputDate(`UN/${date.format('MMM')}/${year}`);
        } else {
          const date = moment(`${day}/${month}/${year}`, dateViewOptions.DATE.dateFormat);
          setDate(date);
          setViewMode(dateViewOptions.DATE);
          setInputDate(`${day}/${date.format('MMM')}/${year}`);
        }
      } else {
        setDate(null);
        setInputDate(value);
      }
    },
    [isValidString]
  );

  useEffect(
    function() {
      value && isString(value) && onInputChange(value);
    },
    [value, onInputChange]
  );

  const getValidationMessage = () => {
    let validationMessageStr = '';
    if (validationMessage && isValidString(inputDate)) {
      validationMessageStr = validationMessage;
    }
    if (!isValidString(inputDate)) {
      validationMessageStr = 'Invalid Date';
    }
    return validationMessageStr;
  };

  return (
    <div
      className={cx(`eui-date-picker-manual`, className, {
        'eui-date-picker-manual-legacy': legacyLook
      })}
      ref={datePickerElement}
    >
      <Input
        onFocus={() => setShowDialog(true)}
        onBlur={() => onChange(date, inputDate)}
        placeholder={showDialog ? viewMode.placeholder : ''}
        label={label}
        value={inputDate}
        required={required}
        iconsAfter={iconsAfter}
        legacyLook={legacyLook}
        onChange={e => onInputChange(e.target.value)}
        validationMessage={getValidationMessage()}
        disabled={disabled}
      />
      {showDialog && (
        <div className={'dropdown-menu-wrapper'}>
          {!isValidString(inputDate) && (
            <div className={'message'}>
              <div>Manual entry rules</div>
              {getManualEntryRuleFor('DATE')}
              {!dateOnly && getManualEntryRuleFor('MONTH')}
              {!dateOnly && getManualEntryRuleFor('YEAR')}
            </div>
          )}
          {isValidString(inputDate) && (
            <Fragment>
              {!dateOnly && (
                <div className={'buttons'}>
                  <button className={getButtonClass('days')} onClick={() => setViewMode(dateViewOptions.DATE)}>
                    Date
                  </button>
                  <button className={getButtonClass('months')} onClick={() => setViewMode(dateViewOptions.MONTH)}>
                    Month
                  </button>
                  <button className={getButtonClass('years')} onClick={() => setViewMode(dateViewOptions.YEAR)}>
                    Year
                  </button>
                </div>
              )}
              <DateTime
                open={true}
                value={date}
                viewMode={viewMode.viewMode}
                dateFormat={viewMode.dateFormat}
                onChange={updateDateChanges}
                isValidDate={isValidDate}
                timeFormat={false}
              />
            </Fragment>
          )}
        </div>
      )}
    </div>
  );

  function isValidDay(day) {
    const correctDayString = day.length === 2 && parseInt(day) >= 0 && parseInt(day) <= 31;
    return dateOnly ? correctDayString : correctDayString || day.toUpperCase() === 'UN';
  }

  function isValidMonth(month, day) {
    const correctMonthString = months.includes(month.toLowerCase());
    return dateOnly
      ? correctMonthString
      : correctMonthString || (month.toUpperCase() === 'UNK' && day.toUpperCase() === 'UN');
  }

  function isValidYear(year) {
    return year.length === 4 && parseInt(year).toString().length === 4;
  }
});

export default DatePickerManual;

const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

function getParsedValues(input) {
  const dateArray = input.split('/');
  const day = dateArray[0];
  const month = dateArray[1];
  const year = dateArray[2];
  return { day, month, year, dateArray };
}

function getManualEntryRuleFor(type) {
  return (
    <div>
      {dateViewOptions[type].displayDatePrefix}
      {moment().format(dateViewOptions[type].displayDateFormat)}
    </div>
  );
}

DatePickerManual.defaultProps = {
  dateOnly: false,
  placeholder: 'Select Date',
  disabled: false
};

DatePickerManual.propTypes = datePickerManualInputTypes;
