import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { reverse } from 'lodash/array';
import { orderBy } from 'lodash/collection';
import { isFunction } from 'lodash/lang';
import moment from 'moment';

import LogTableApi from '../../../../../api/patient/LogTableApi';
import Common from '../../../../../common/common';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Icon from '../../../../../common/general/Icon';
import { scDarkBlue100, scGreen100 } from '../../../../../constants/systemColors';

import AELogRowSignaturesHistoryModal from './AELogRowSignaturesHistoryModal';
import { getPreparedData } from './AELogsHistoryService';

import './AELogHistory.scss';

export default function AELogsHistory({ rowId, onHistoryDataLoad, className }) {
  const [adverseEventHistory, setAdverseEventHistory] = useState({});

  useEffect(
    function() {
      LogTableApi.getAverseEventHistory(rowId).then(({ data }) => {
        setAdverseEventHistory(data);
      });
    },
    [rowId]
  );

  const isLastStatusCanceled = useMemo(() => {
    return orderBy(adverseEventHistory.statuses, 'snapshotVersion', 'desc')[0]?.smStatus === 'CANCELED';
  }, [adverseEventHistory.statuses]);

  const rowVersions = useMemo(() => {
    const rowVersions = getPreparedData(
      adverseEventHistory?.logHistoryData?.tableData,
      adverseEventHistory?.signatures,
      adverseEventHistory?.statuses,
      adverseEventHistory?.logHistoryData?.metadata
    );
    const changedItems = [];
    Object.keys(rowVersions).forEach(rowVersion => {
      const element = rowVersions[rowVersion];
      const row = element.row;
      Object.values(row).forEach(item => {
        if (item.changed && !item.signed && !isLastStatusCanceled) {
          changedItems.push(item.question.id);
        }
      });
    });
    isFunction(onHistoryDataLoad) && onHistoryDataLoad(changedItems);
    return rowVersions;
  }, [adverseEventHistory, isLastStatusCanceled, onHistoryDataLoad]);

  function openLogRowHistory() {
    ModalBoxes.open({
      component: (
        <AELogRowSignaturesHistoryModal
          itemGroupName={'AE'}
          history={adverseEventHistory?.logHistoryData}
          adverseEventHistory={adverseEventHistory}
          rowVersions={rowVersions}
        />
      ),
      title: 'State of log at time of signature'
    });
  }

  const firstRow = adverseEventHistory?.logHistoryData?.tableData?.find(row => row?.rowVersion?.value === '1');
  return (
    <div className={cx('ae-log-history mt-2', className)}>
      {rowVersions &&
        reverse(Object.keys(rowVersions)).map(rowVersion => {
          const element = rowVersions[rowVersion];
          const row = element.row;
          return (
            <div className={'version-container'} key={rowVersion}>
              {element?.status?.map(e => (
                <>
                  {e.smStatus === 'CANCELED' && <CancelElement status={e} />}
                  {e.smStatus === 'COMPLETE' && <Status status={e} onClick={openLogRowHistory} />}
                </>
              ))}

              {element?.signature &&
                element?.signature.map(s => <Signature signature={s} onClick={openLogRowHistory} />)}
              {Object.values(row).map((item, id) => {
                return (
                  item.changed && (
                    <HistoryElement
                      key={`${rowVersion} ${id}`}
                      item={item}
                      isLastStatusCanceled={isLastStatusCanceled}
                    />
                  )
                );
              })}
            </div>
          );
        })}
      {firstRow && <LogCreated row={firstRow} />}
    </div>
  );
}

export function HistoryElement({ item, isLastStatusCanceled }) {
  return (
    <div className={'question-answer-container'}>
      <span className={'question-name'}>{item.question?.header} ?</span>
      <span>
        {`Last Updated By ${item.currentAnswer?.lastUpdatedBy?.value} on 
        ${moment(item.currentAnswer.lastUpdatedDate.value).format(dateFormatHistory)}`}
      </span>
      <div
        className={cx('answers', {
          highlighted: !item.signed && !isLastStatusCanceled
        })}
      >
        <Column item={item.previousAnswer} title={'Previous Data'} />
        <Column item={item.currentAnswer} title={'Current Data'} />
        <div className={'answer-section'}>
          <div className={'section-title'}>Reason For Change</div>
          <span className={'answer-name'}>{item.currentAnswer.reasonForDataChange || '--'}</span>
        </div>
      </div>
    </div>
  );
}

function LogCreated({ row }) {
  return (
    <div className={'version-container'}>
      <div className={'question-answer-container'}>
        <div className={'answers'} style={{ justifyContent: 'start' }}>
          <div className={'section-title created-status-section'}>
            Log Created by {row['Last Updated By'].value} at{' '}
            {Common.formatDate(row['Last Updated Date'].value, dateFormatHistory)}
          </div>
        </div>
      </div>
    </div>
  );
}

function Column({ title, item }) {
  const { value, comment, performedStatus } = item;
  return (
    <div className={'answer-section'}>
      <div className={'section-title'}>{title}</div>
      <span className={'answer-name'}>
        {performedStatus ? `${performedStatus}: ${comment}` : ''}
        {value && value}
        {!value && !performedStatus && '--'}
      </span>
    </div>
  );
}

export function Status({ status, onClick }) {
  return (
    <div className={'status-container'}>
      <Icon style={{ color: scGreen100, fontSize: 24 }}>check_circle</Icon>
      <div
        style={{ color: scDarkBlue100, textDecoration: 'underline', marginLeft: '14px', cursor: 'pointer' }}
        onClick={onClick}
      >
        Marked Complete {moment(status?.createdAt).format(dateFormatHistory)}
      </div>
      <div>
        {status?.createdBy}
        {status?.userRole ? ' - ' + status.userRole : ''}
      </div>
    </div>
  );
}

export function CancelElement({ status }) {
  return (
    <div className={'cancel-reason-container'}>
      <span className={'question-name'}> Canceled By: </span>
      <span>
        {`${status.createdBy} on 
        ${moment(status.createdAt).format(dateFormatHistory)}`}
      </span>
      <div className={'cancel-reason'}>
        <div className={'reason-section'}>
          <div className={'cancel-reason-title'}>Reason For Cancellation</div>
          <span className={'reason-name'}>{status.reason || '--'}</span>
        </div>
      </div>
    </div>
  );
}

export function Signature({ signature, onClick }) {
  return (
    <div className={'signature-container'}>
      <Icon style={{ color: scDarkBlue100, fontSize: 30 }}>rate_review</Icon>
      <div
        style={{ color: scDarkBlue100, textDecoration: 'underline', marginLeft: '10px', cursor: 'pointer' }}
        onClick={onClick}
      >
        Signed Off {moment(signature?.createdAt).format(dateFormatHistory)}
      </div>
      <div>
        {signature?.creatorUserName} - {signature?.signerRole}, {signature?.groupName}
      </div>
    </div>
  );
}

export const dateFormatHistory = 'DD/MMM/YYYY h:mm:ss A';
