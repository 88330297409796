import { tableHeaderItem } from './InvoiceHeader/InvoiceHeader';

export const styles = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    width: '612px',
    height: '791px',
    fontSize: '12px',
    fontFamily: 'Roboto, Roboto-Regular',
    padding: '20px',
    boxSizing: 'border-box'
  },
  hiddenPage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    width: '572px',
    height: '0',
    fontSize: '12px',
    fontFamily: 'Roboto, Roboto-Regular',
    boxSizing: 'border-box',
    overflowY: 'hidden',
    visibility: 'hidden'
  },
  section: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    overflowY: 'auto'
  },
  pageCountWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-end',
    fontSize: '12px',
    lineHeight: '12px',
    letterSpacing: '0.15px',
    color: '#828282',
    bankingInformation: {
      width: '168px',
      display: 'flex',
      position: 'absolute',
      left: 0,
      flexDirection: 'column',
      padding: '16px',
      borderRadius: '4px',
      borderTop: '1px solid #FAFAFA',
      borderLeft: '2px solid #F3F3F3',
      borderRight: '2px solid #F3F3F3',
      borderBottom: '2px solid #B7B7B7',
      fontFamily: 'Roboto, Roboto-Regular',
      fontWeight: '400',
      lineHeight: '12px',
      fontSize: '8px',
      color: '#323232'
    }
  },
  balance: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '20px',
    paddingBottom: '30px',
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid #DBDBDB',
      alignItems: 'flex-end',
      paddingBottom: '4px',
      marginBottom: '10px',
      comment: {
        width: '330px',
        fontSize: '12px',
        lineHeight: '20px',
        letterSpacing: '0.4px',
        color: '#323232'
      },
      subtotal: {
        display: 'flex',
        gap: '20px',
        fontSize: '14px',
        lineHeight: '24px',
        fontFamily: 'Roboto, Roboto-Medium',
        fontWeight: '500'
      }
    },
    calculationWrapper: {
      display: 'flex',
      flexDirection: 'row-reverse',
      calculation: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        subtotal: {
          display: 'flex',
          gap: '20px',
          fontSize: '14px',
          lineHeight: '24px'
        },
        balanceDue: {
          display: 'flex',
          gap: '20px',
          fontSize: '14px',
          lineHeight: '24px',
          fontFamily: 'Roboto, Roboto-Medium',
          fontWeight: '500',
          color: '#691E44',
          marginTop: '10px'
        }
      }
    }
  },
  amountWrapper: {
    letterSpacing: '0.17px',
    minWidth: '110px',
    textAlign: 'end'
  }
};

export const stylesContent = {
  groupHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'baseline',
    paddingTop: '13px',
    groupItem: {
      ...tableHeaderItem,
      textAlign: 'center',
      fontSize: '12px',
      color: '#323232',
      paddingTop: 0,
      paddingBottom: 0,
      lineHeight: '20px',
      height: '20px'
    },
    groupItemName: {
      ...tableHeaderItem,
      width: '412px',
      fontSize: '12px',
      color: '#323232',
      paddingTop: 0,
      paddingBottom: 0,
      lineHeight: '20px'
    },
    tableHeaderItemAmount: {
      ...tableHeaderItem,
      textAlign: 'right',
      fontSize: '12px',
      color: '#323232',
      paddingTop: 0,
      paddingBottom: 0,
      lineHeight: '20px',
      height: '20px'
    }
  },
  invoiceItem: {
    paddingLeft: '10px',
    color: '#323232',
    lineHeight: '20px',
    fontSize: '12px'
  },
  groupSubHeader: {
    paddingLeft: '20px',
    color: 'black',
    lineHeight: '20px',
    fontSize: '12px'
  },
  invoiceSubItem: {
    paddingLeft: '30px',
    color: '#323232',
    lineHeight: '20px',
    fontSize: '12px'
  }
};
