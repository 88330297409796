import { useEffect, useState } from 'react';

import InformedConsentApi from '../../../../../../../api/patient/InformedConsentApi';
import { useCurrentRoute } from '../../../../../../root/router';

export default function useIcfConfig() {
  const [icfConfig, setIcfConfig] = useState({});
  const [error, setError] = useState({});
  const currentRoute = useCurrentRoute();
  useEffect(
    function() {
      let controller = new AbortController();
      InformedConsentApi.getItemsByStudyId(currentRoute.params?.studyId, { signal: controller.signal })
        .then(res => {
          setIcfConfig(res.data);
        })
        .catch(setError);
      return () => controller.abort();
    },
    [currentRoute.params?.studyId]
  );
  return { icfConfig };
}
