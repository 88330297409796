import React from 'react';
import cx from 'classnames';
import { isFunction } from 'lodash/lang';

import Checkbox from '../../../../../common/data-entry/InputSelectors/Checkbox';

import { CANCELED, COMPLETE, ENCOUNTER_WIDGET } from './constants';

import './WorklistWidget.scss';

export const WorklistWidget = ({
  Context: {
    totalEncounter,
    amountPerCategory,
    currentlySelected,
    setCurrentlySelected,
    showComplete,
    setShowComplete,
    showCanceled,
    setShowCanceled
  },
  statusProvider,
  colors,
  widgetType = ENCOUNTER_WIDGET
}) => {
  return (
    <div className={cx('p-4 widget-container', 'worklist-widget')}>
      <div className={'widget'}>
        <div className={'total-encounter'}>
          <div className={'total-encounter-amount'}>{getFormat(totalEncounter)}</div>
          {widgetType === ENCOUNTER_WIDGET ? 'My Encounters' : 'My Adverse Events'}
        </div>
        <div className={'graphic-rows-container'}>
          {Object.entries(amountPerCategory).map(
            ([key, value]) =>
              (showComplete || key !== COMPLETE) &&
              (showCanceled || key !== CANCELED) &&
              value > 0 && (
                <div
                  className="bar"
                  key={key}
                  onClick={() => {
                    if (value !== 0) {
                      setCurrentlySelected(key);
                    }
                  }}
                  style={{
                    background: colors[key],
                    width: (100 / totalEncounter) * value + '%'
                  }}
                />
              )
          )}
        </div>
        <div>
          <div className={'details-container'}>
            {Object.entries(amountPerCategory).map(
              ([key, value]) =>
                (showComplete || key !== COMPLETE) &&
                (showCanceled || key !== CANCELED) &&
                value > 0 && (
                  <div
                    key={key}
                    className={cx({ 'currently-selected': currentlySelected === key, clickable: value !== 0 })}
                    onClick={() => {
                      if (value !== 0) {
                        setCurrentlySelected(key);
                      }
                    }}
                  >
                    <div className={cx('details-title', value !== 0 && 'clickable')}>
                      {statusProvider.find(e => e.id === key)['name']}
                    </div>
                    <div className={'details-more'}>
                      <div
                        className={'circle'}
                        style={{
                          background: colors[key]
                        }}
                      />
                      <div className={'number'}>{getFormat(value)}</div>
                    </div>
                  </div>
                )
            )}
          </div>
          <Checkbox
            label="Show Complete"
            className={'investigator-widget-show-complete'}
            onChange={() => {
              setShowComplete(!showComplete);
            }}
            checked={showComplete}
          />
          {isFunction(setShowCanceled) && (
            <Checkbox
              label="Show Canceled"
              className={'investigator-widget-show-canceled'}
              onChange={() => {
                setShowCanceled(!showCanceled);
              }}
              checked={showCanceled}
            />
          )}
        </div>
      </div>
    </div>
  );
};

function getFormat(number) {
  return number.toLocaleString();
}
