import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import SiteCreditApi from '../../../../../api/finance/SiteCreditApi';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../constants/dateFormat';
import { SOMETHING_WENT_WRONG } from '../../../../../constants/notificationMessages';
import { InfoItem } from '../../NewInvoice/InvoiceTable/DepositHistoryModal/DepositHistoryModal';
import { Amount } from '../../shared/amount-view/Amount/Amount';
import { resolveEventSuffix } from '../SitePaymentServices';

export const CreditApplicationHistoryModal = ({
  row: {
    eventDate,
    studyName,
    siteName,
    subjectId,
    encounterName,
    budgetEventType,
    eventName,
    itemGroupName,
    itemSiteId
  }
}) => {
  const [creditApplicationHistory, setCreditApplicationHistory] = useState([]);
  useEffect(() => {
    SiteCreditApi.getHistory(itemSiteId)
      .then(({ data }) => {
        setCreditApplicationHistory(data);
      })
      .catch(() => {
        NotificationManager.error(SOMETHING_WENT_WRONG);
      });
  }, [itemSiteId]);

  const comment = useMemo(() => {
    const allComments = creditApplicationHistory.map(item => item.comment);
    return [...new Set(allComments)].filter(Boolean).join(', ');
  }, [creditApplicationHistory]);

  return (
    <>
      <ModalBoxes.Header>Site Credit Application History</ModalBoxes.Header>
      <ModalBoxes.Body>
        <div className="invoice-info-section">
          <div className="header">Info</div>
          <div className="info-items-wrapper">
            <InfoItem label="Event date" value={moment(eventDate).format('DD/MMM/YYYY')} />
            <InfoItem label="Study" value={studyName} />
            <InfoItem label="Site" value={siteName} />
            <InfoItem label="Subject ID" value={subjectId} />
            <InfoItem label="Encounter" value={encounterName} />
            <InfoItem label="Budget Event Type" value={budgetEventType} />
            <InfoItem label="Event Name" value={eventName} />
            <InfoItem label="Item Group" value={itemGroupName} />
            <InfoItem multiline label="Comment" value={comment} />
          </div>
        </div>
        <div className="header">History</div>
        <div className="history-wrapper credit-application" data-testid="application-history-table">
          <HeaderCell value="Study" />
          <HeaderCell value="Site" />
          <HeaderCell value="Event ID" />
          <HeaderCell value="Event Name" />
          <HeaderCell value="Credit Application Date" />
          <HeaderCell value="Amount Applied" />
          <HeaderCell value="Site Payment Variance" />
          <HeaderCell value="Applied By" />
          {creditApplicationHistory.map(item => (
            <HistoryRow item={item} key={item.id} />
          ))}
        </div>
      </ModalBoxes.Body>
    </>
  );
};

const HeaderCell = ({ value }) => {
  return (
    <div className="history-table-header-item" data-testid="history-table-header-item">
      {value}
    </div>
  );
};

const HistoryRow = ({ item }) => {
  return (
    <>
      <div>{item.studyName}</div>
      <div>{item.siteName}</div>
      <div>
        {item.adjustmentSequence === 0
          ? resolveEventSuffix(item.eventNumber, item.sitePaymentType)
          : resolveEventSuffix(`${item.eventNumber}.${item.adjustmentSequence}`, item.sitePaymentType)}
      </div>
      <div>{item.eventName}</div>
      <div>{moment(item.creditApplicationDate).format(DD_SLASH_MMM_SLASH_YYYY)}</div>
      <Amount coinsAmount={item.appliedAmount} showDollarSign />
      <Amount coinsAmount={item.sitePaymentVariance} showDollarSign />
      <div>{item.appliedBy}</div>
    </>
  );
};
