import React, { useEffect, useState } from 'react';

import InformedConsentApi from '../../../../../../api/patient/InformedConsentApi';
import useSessionStorage from '../../../../../../common/hooks/useSessionStorage';
import { pendoTrackDefaultSortingChange } from '../../../../../../pendo/PendoUtils';
import { CellFormattedDate } from '../../../../../CellFormattedDate/CellFormattedDate';
import { useCurrentRoute } from '../../../../../root/router';
import EncounterPatientInfo from '../../../shared/EncounterDescNew/EncounterPatientInfo/EncounterPatientInfo';
import { onUserFileOpen } from '../../../shared/NewEncounterPage/InformedConsent/InformedConsentFile';
import LogsTable from '../../Logs/LogsTable';
import { defaultCellWidth } from '../../Logs/LogsTableService';

import { COMPLETED_ICF_VALUES, defaultPage, defaultPageSize, defaultSorted } from './constants';

import './CompletedICFPage.scss';

function CompletedICFPage() {
  const currentRoute = useCurrentRoute();

  const ssuPatientId = currentRoute.params?.ssuPatientId;

  const [completedForms, setCompletedForms] = useState([]);
  const [sessionStorage, setSessionStorage] = useSessionStorage(COMPLETED_ICF_VALUES, {});
  const [page, setPage] = useState(sessionStorage.page || defaultPage);
  const [pageSize, setPageSize] = useState(sessionStorage.pageSize || defaultPageSize);
  const [sorted, setSorted] = useState(sessionStorage.sorted || defaultSorted);

  useEffect(() => {
    ssuPatientId &&
      InformedConsentApi.getCompletedForms(ssuPatientId).then(({ data }) => {
        setCompletedForms(data?.filter(e => e.completedIcfFile));
      });
  }, [ssuPatientId]);

  return (
    <div className="completed-icf-page full-screen-layout">
      <EncounterPatientInfo withLabel={true} infoLabel={' '} />
      <section className="p-3 content-section mb-3">
        <LogsTable
          columns={prepareColumns()}
          data={completedForms}
          sortable={true}
          showPagination={true}
          defaultCellWidth={defaultCellWidth}
          pageSize={pageSize}
          page={page}
          onPageSizeChange={pageSize => {
            setPageSize(pageSize);
            setSessionStorage({ pageSize: pageSize });
          }}
          onPageChange={page => {
            setPage(page);
            setSessionStorage({ page: page });
          }}
          onSortedChange={sorted => {
            pendoTrackDefaultSortingChange(sorted);
            setSorted(sorted);
          }}
          defaultSorted={sorted}
        />
      </section>
    </div>
  );

  function prepareColumns() {
    return [
      {
        Header: 'Date Recorded',
        id: 'dateRecorded',
        accessor: 'createdAt',
        Cell: ({ value }) => <CellFormattedDate date={value} defaultValue={'--'} />,
        minWidth: 125
      },
      {
        Header: 'Encounter',
        accessor: 'encounterName',
        id: 'encounterName',
        className: 'text-left',
        Cell: ({ value }) => <div>{value || 'N/A'}</div>,
        minWidth: 150
      },
      {
        Header: 'Progress',
        accessor: 'progress',
        id: 'progress',
        minWidth: 120
      },
      {
        Header: 'ICF Language',
        accessor: 'languageName',
        id: 'languageName',
        minWidth: 120
      },
      {
        Header: 'ICF Type (Main, Sub-study, etc.)',
        accessor: 'typeName',
        id: 'typeName',
        minWidth: 120
      },
      {
        Header: 'ICF IRB Approval Date',
        accessor: 'publishDate',
        id: 'publishDate',
        Cell: ({ value }) => <CellFormattedDate date={value} defaultValue={'--'} />,
        minWidth: 120
      },
      {
        Header: 'ICF Date Signed By Patient',
        accessor: 'dateSignedByPatient',
        id: 'dateSignedByPatient',
        Cell: ({ value, original }) => (
          <div
            onClick={() => {
              if (original.completedIcfFile) {
                onUserFileOpen(original.completedIcfFile, original.id, original.ssuId);
              }
            }}
            className={'link'}
          >
            <CellFormattedDate date={value} defaultValue={'--'} />
          </div>
        ),
        minWidth: 120
      },
      {
        Header: 'ICF Version Number',
        accessor: 'version',
        id: 'version',
        minWidth: 120
      }
    ];
  }
}

export default CompletedICFPage;
