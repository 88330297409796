export function extractRenderingContent(allChildren, filterByType) {
  if (!allChildren) {
    return null;
  } else {
    if (Array.isArray(allChildren)) {
      return allChildren.filter(filterByType);
    } else {
      if (filterByType(allChildren)) {
        return allChildren;
      }
    }
  }
}
