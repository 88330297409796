import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { omit } from 'lodash/object';
import moment from 'moment';

import { RevenueLedgerApi } from '../../../../api';
import { EXPORT_FAILED } from '../../../../constants/notificationMessages';
import { useFilterContext } from '../../../../contexts/filters/FiltersContext';
import { handleFinLedgerEventIdFieldValidationError, onFileSave, onRequestError } from '../../../../services/handlers';

export const RevenueContext = React.createContext(null);
const REVENUE_FILTER_KEY = 'revenueFilters';

export function RevenueProvider({ children }) {
  const [revenueFilters, setFilterByKey] = useFilterContext(REVENUE_FILTER_KEY);
  const initialFilter = useMemo(
    () => ({
      studyName: null,
      siteName: null,
      siteId: null,
      studyId: null,
      type: null,
      statuses: [],
      startDate: moment()
        .startOf('month')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 1 }),
      endDate: moment().set({ hour: 23, minute: 59, second: 59, millisecond: 0 }),
      notIncludeElligoZeroOrNegativeAmount: false,
      patientId: null,
      subjectId: null,
      finLedgerEventId: null,
      eventIdFromInput: null,
      projectCode: undefined
    }),
    []
  );
  const [filterProperty, setFilterProperty] = useState(initialFilter);
  const [ledgerEvents, setLedgerEvents] = useState([]);

  const setFilter = useCallback(
    function(filterProperty) {
      setFilterByKey(REVENUE_FILTER_KEY, filterProperty);
    },
    [setFilterByKey]
  );

  const resetFilter = useCallback(
    function() {
      setFilterByKey(REVENUE_FILTER_KEY, initialFilter);
    },
    [initialFilter, setFilterByKey]
  );

  useEffect(
    function() {
      if (revenueFilters) {
        setFilterProperty(revenueFilters);
      }
    },
    [revenueFilters]
  );

  const filterRevenueEvents = useCallback(() => {
    const filters = omit(filterProperty, 'eventIdFromInput');
    RevenueLedgerApi.filter(filters)
      .then(({ data }) => {
        setLedgerEvents(data);
      })
      .catch(error => {
        handleFinLedgerEventIdFieldValidationError(error);
      });
  }, [filterProperty]);

  const downloadCSV = useCallback(() => {
    RevenueLedgerApi.exportElligoRevenue(filterProperty)
      .then(onFileSave)
      .catch(err => onRequestError(err, { customMessage: EXPORT_FAILED }));
  }, [filterProperty]);

  const exportRevenueNetSuite = useCallback(() => {
    RevenueLedgerApi.exportElligoRevenueForNetSuite(filterProperty)
      .then(onFileSave)
      .catch(err => onRequestError(err, { customMessage: EXPORT_FAILED }));
  }, [filterProperty]);

  const value = useMemo(
    () => ({
      downloadCSV,
      exportRevenueNetSuite,
      filterRevenueEvents,
      ledgerEvents,
      filterProperty,
      setFilterProperty: setFilter,
      resetFilter
    }),
    [downloadCSV, exportRevenueNetSuite, filterProperty, filterRevenueEvents, ledgerEvents, resetFilter, setFilter]
  );

  return <RevenueContext.Provider value={value}>{children}</RevenueContext.Provider>;
}
