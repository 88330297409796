import { createContext, useContext, useMemo, useState } from 'react';
import { reject } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

export const EuiFileUploadContext = createContext(null);

export default function EuiFileUploadProvider({ fileUrlGetter, initialFiles = [], children }) {
  const [state, setState] = useState({
    files: initialFiles,
    selectedFileId: getSelectedFileId(initialFiles)
  });

  const value = useMemo(
    function() {
      return {
        ...state,
        setFiles,
        addFiles,
        removeFile,
        setSelectedFileId,
        fileUrlGetter
      };

      function setFiles(files) {
        setState(function(prevState) {
          return { ...prevState, files, selectedFileId: getSelectedFileId(files) };
        });
      }

      function addFiles(files) {
        setState(function(prevState) {
          const newFiles = [...prevState.files, ...files];
          return { ...prevState, files: newFiles, selectedFileId: getSelectedFileId(newFiles) };
        });
      }

      function removeFile(id) {
        setState(function(prevState) {
          const newFiles = reject(prevState.files, ['id', id]);
          return { ...prevState, files: newFiles, selectedFileId: getSelectedFileId(newFiles) };
        });
      }

      function setSelectedFileId(selectedFileId) {
        setState(function(prevState) {
          return { ...prevState, selectedFileId };
        });
      }
    },
    [fileUrlGetter, state]
  );

  return <EuiFileUploadContext.Provider value={value}>{children}</EuiFileUploadContext.Provider>;
}

function getSelectedFileId(files) {
  return isEmpty(files) ? null : files[files.length - 1].id;
}

export function useEuiFileUpload() {
  const context = useContext(EuiFileUploadContext);
  if (context === undefined) {
    throw new Error('useEuiFileUpload must be used within a EuiFileUploadContext.Provider');
  }
  return context;
}

export function withEuiFileUpload(Component) {
  return function WithEuiFileUpload(props) {
    return (
      <EuiFileUploadContext.Consumer>
        {function(value) {
          return <Component {...props} euiFileUpload={value} />;
        }}
      </EuiFileUploadContext.Consumer>
    );
  };
}

export function withEuiFileUploadProvider(Component) {
  return function WithEuiFileUploadProvider(props) {
    return (
      <EuiFileUploadProvider>
        <Component {...props} />
      </EuiFileUploadProvider>
    );
  };
}
