import Dropzone from 'react-dropzone';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import cx from 'classnames';

import './EuiDropzone.scss';

export default function EuiDropzone({ multiple, accept, onDrop, error }) {
  return (
    <Dropzone multiple={multiple} accept={accept.join()} onDrop={onDrop}>
      {function({ getRootProps, getInputProps, isDragActive }) {
        return (
          <div
            className={cx('eui-file-upload-drag-zone-outer', {
              'eui-file-upload-drag-zone-outer-error': error
            })}
            {...getRootProps()}
          >
            <div className="eui-file-upload-drag-zone-inner">
              <input {...getInputProps()} name="file" />
              <ArrowCircleUpIcon />
              {isDragActive ? (
                <span>Drop the files here</span>
              ) : (
                <span>
                  <b>
                    <u>{'Choose files'}</u>
                  </b>{' '}
                  {'or drag them here'}
                </span>
              )}
            </div>
            {error && <div>{error}</div>}
          </div>
        );
      }}
    </Dropzone>
  );
}

EuiDropzone.defaultProps = {
  multiple: false,
  accept: [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
    'image/png',
    'image/jpeg'
  ]
};
