import React, { useState } from 'react';
import cx from 'classnames';
import { uniqBy } from 'lodash/array';
import { isEmpty } from 'lodash/lang';

import Checkbox from '../../../../../common/data-entry/InputSelectors/Checkbox';
import { definePatternById } from '../../../../../services/invoiceAndSiteBillSearchService';
import { FinanceTable } from '../FinanceTable/FinanceTable';

import './TableWithCheckbox.scss';

export function TableWithCheckbox({
  tableData,
  columns,
  setCheckedForCreate,
  id,
  alreadyCheckedList,
  number,
  onChange,
  className,
  showCheckBox,
  getFilteredItems,
  ...rest
}) {
  const pattern = definePatternById(id);
  const [tableRef, setTableRef] = useState(null);
  const columnsWithCheckbox = generateColumn(columns, headerCheckbox, number, checkBoxItems, showCheckBox);

  return (
    <FinanceTable
      className={cx(className, 'eds-table-with-checkbox')}
      setTableRef={setTableRef}
      columns={columnsWithCheckbox}
      data={tableData || []}
      minRows={1}
      multiSort
      showPagination
      nextText=">>"
      NoDataComponent={NoData}
      previousText="<<"
      defaultPageSize={25}
      defaultSorted={[
        {
          id: 'revenueDate',
          desc: true
        }
      ]}
      {...rest}
    />
  );

  function checkBoxItems(origin) {
    const checked = !!alreadyCheckedList.find(pattern.getPattern(origin));
    const value = pattern.getId(origin);
    return <Checkbox onChange={event => onChange(event, origin)} label="Select" value={value} checked={checked} />;
  }

  function headerCheckbox() {
    const items = getFilteredItems(tableRef);
    const findStateHelper = e => alreadyCheckedList?.find(pattern.getPattern(e));
    const checked = items?.every(findStateHelper);
    const someChecked = items?.some(findStateHelper);
    return (
      <Checkbox
        disabled={isEmpty(items)}
        onChange={addOrRemoveAllVisibleEvent}
        checked={checked}
        indeterminate={!checked && someChecked}
        label={`Selected (${alreadyCheckedList?.length})`}
      />
    );

    function addOrRemoveAllVisibleEvent({ target: { checked } }) {
      setCheckedForCreate(checkedForCreate => {
        if (!checked) {
          return checkedForCreate.filter(item => !items.find(pattern.getPattern(item)));
        } else {
          return uniqBy([...checkedForCreate, ...items], pattern.getId);
        }
      });
    }
  }
}
function generateColumn(columns, header, number, checkboxItem, showCheckBox) {
  return [
    ...columns,
    {
      id: 'selected',
      sortable: false,
      width: 150,
      Header: header,
      accessor: origin => {
        return showCheckBox(origin, number) && checkboxItem(origin);
      }
    }
  ];
}
function NoData() {
  return <div className="eds-no-data">No Record Found</div>;
}
