import React, { useContext, useMemo, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@mui/material';

import { CLOSED } from '../../../../../../constants/ssuStatuses';
import { MANAGE_PATIENT_APPOINTMENTS } from '../../../../../../constants/userOperations';
import { ROLE_SYSTEM_ADMINISTRATOR } from '../../../../../../constants/userRoles';
import { userHasAccessTo, userHasRole } from '../../../../../../services/auth';
import { WorklistContext } from '../../WorklistContext';
import { openScheduleAppointmentModal } from '../WorklistTableServices';

import { ItemGroupMenuItems } from './ItemGroupMenuItems';

export const TableRowMenu = ({ row }) => {
  const { updateStatus, itemGroupAccessors } = useContext(WorklistContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const scheduleAvailable = useMemo(() => {
    const isUserHaveAccessToScheduleAppointment =
      userHasRole(ROLE_SYSTEM_ADMINISTRATOR) ||
      (row.studySiteStatus !== CLOSED && userHasAccessTo(MANAGE_PATIENT_APPOINTMENTS));
    return (
      isUserHaveAccessToScheduleAppointment &&
      row.prescreenStatus === 'Ready for Scheduling' &&
      row.status === 'PRE_SCREENED'
    );
  }, [row.prescreenStatus, row.status, row.studySiteStatus]);

  return (
    <>
      <MoreVertIcon onClick={openMenu} data-testid="patient-row-menu" />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        slotProps={{
          paper: {
            sx: {
              width: 'auto'
            }
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <ItemGroupMenuItems
          row={row}
          itemGroupAccessors={itemGroupAccessors}
          handleCloseMenu={handleCloseMenu}
          updateStatus={updateStatus}
        />
        <MenuItem
          onClick={() => {
            handleCloseMenu();
            openScheduleAppointmentModal(row);
          }}
          disabled={!scheduleAvailable}
        >
          Schedule
        </MenuItem>
      </Menu>
    </>
  );
};
