import { cloneDeep } from 'lodash/lang';

export default function prepareAddPatientInfo(info, ssu, medicalRecordNumber) {
  const patientInfo = cloneDeep(info);
  const studyIdentifier = ssu.study.uniqueIdentifier;
  const studySiteIdentifier = ssu.site.uniqueIdentifier;
  const patientSourceId = ssu.source.id;
  const statusName = ssu.status.name;

  const { addressLine1, addressLine2, city, state, country } = patientInfo.address;

  const newPatient = {
    ...patientInfo,
    postalCode: patientInfo.address.zipCode,
    neverCallAgain: patientInfo.phonesInfo.neverCallAgain,
    voicemailNotAllowed: patientInfo.phonesInfo.voicemailNotAllowed,
    primaryContact: patientInfo.phonesInfo.primary || '',
    primaryPhoneType: patientInfo.phonesInfo.primaryPhoneType,
    emergencyContact: patientInfo.phonesInfo.alternative,
    alternativePhoneType: patientInfo.phonesInfo.alternativePhoneType,
    preferredContactMethod: patientInfo.phonesInfo.preferredContactMethod,
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    studyIdentifier,
    studySiteIdentifier,
    patientSourceId,
    statusName,
    medicalRecordNumber,
    email: patientInfo.email || '',
    statusChangeLocation: 'ADD_PATIENT'
  };

  delete newPatient.address;
  delete newPatient.phonesInfo;

  return newPatient;
}
