import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { isEqual } from 'lodash';
import { isEmpty } from 'lodash/lang';

import FinPayeeApi from '../../../../../../api/finance/FinPayeeApi';
import Input from '../../../../../../common/data-entry/Input';
import Select from '../../../../../../common/data-entry/Select';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import { DATA_SAVED } from '../../../../../../constants/notificationMessages';
import {
  canadianProvinces,
  countries,
  getCountryById,
  getRegionById,
  usStates
} from '../../../../../../services/geographic.js';
import { onRequestDefaultError } from '../../../../../../services/handlers';
import { normalizePhoneNumberForCard } from '../../../../../../services/normalizers';
import { onChangeZipCode } from '../../AccountService.js';

import { fieldNames, validatePayee } from './PayableToValidator';

import './AddPayableToModal.scss';

const accountTypesList = [
  { name: 'Checking', id: 'Checking' },
  { name: 'Saving', id: 'Saving' }
];
const elligoDirectDomain = '@elligodirect.com';
const US = 'US';

export const AddPayableToModal = ({ type, id, updateCallBack, modalBox }) => {
  const [defaultAccount, setDefaultAccount] = useState(false);
  const [organizationName, setOrganizationName] = useState('');
  const [departmentName, setDepartmentName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState(elligoDirectDomain);

  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState(countries[0]);
  const [city, setCity] = useState('');
  const [state, setState] = useState(null);

  const [bankName, setBankName] = useState('');
  const [bankAccountType, setBankAccountType] = useState(accountTypesList[0]);
  const [bankRoutingNumber, setBankRoutingNumber] = useState('');
  const [bankAccountNumber, setBankAccountNumber] = useState('');
  const [bankSwiftCode, setBankSwiftCode] = useState('');
  const [bankRegistrationNumber, setBankRegistrationNumber] = useState('');
  const [invalidFields, setInvalidFields] = useState([]);
  const emailInput = useRef(null);

  const stateList = useMemo(() => (country?.id === US ? usStates : canadianProvinces), [country?.id]);
  const resolveApiForSave = useCallback(
    (type, data) => {
      return type === 'Add' ? FinPayeeApi.save(data) : FinPayeeApi.update({ ...data, id });
    },
    [id]
  );

  useEffect(() => {
    if (type === 'Edit' && id) {
      FinPayeeApi.findById(id).then(({ data }) => {
        setDefaultAccount(data.default);
        setOrganizationName(data.name);
        setDepartmentName(data.departmentName);
        setPhone(data.phone);
        setEmail(data.email);
        setAddress1(data.address1);
        setAddress2(data.address2);
        setZipCode(data.zipCode);
        setCountry(getCountryById(data.country));
        setCity(data.city);
        setState(getRegionById(data.country === US ? usStates : canadianProvinces, data.state));
        setBankName(data.bankName);
        setBankAccountType(accountTypesList.find(el => el.id === data.bankAccountType));
        setBankRoutingNumber(data.bankRoutingNumber);
        setBankAccountNumber(data.bankAccountNumber);
        setBankSwiftCode(data.bankSwiftCode);
        setBankRegistrationNumber(data.bankRegistrationNumber);
      });
    }
  }, [id, type]);

  const handleEmailChange = e => {
    const newEmail = e.target.value;
    if (newEmail.endsWith(elligoDirectDomain) || !email.endsWith(elligoDirectDomain)) {
      setEmail(newEmail);
    }
  };

  useEffect(() => {
    if (!email) {
      setEmail(elligoDirectDomain);
    }
  }, [email]);

  const handleInputClick = () => {
    if (email === elligoDirectDomain) {
      emailInput.current.setSelectionRange(0, 0);
    }
  };

  const onSave = () => {
    const newPayeeFieldWithValidation = {
      name: organizationName,
      departmentName,
      phone,
      email,
      address1,
      zipCode,
      country: country?.id ?? null,
      city,
      state: state?.id ?? null,
      bankName,
      bankAccountType: bankAccountType?.id ?? null,
      bankRoutingNumber,
      bankAccountNumber
    };

    const invalidFieldNames = validatePayee(newPayeeFieldWithValidation);
    if (!isEmpty(invalidFieldNames)) {
      setInvalidFields(invalidFieldNames);
      return;
    }

    const newPayee = {
      ...newPayeeFieldWithValidation,
      bankSwiftCode,
      bankRegistrationNumber,
      address2,
      default: defaultAccount
    };
    resolveApiForSave(type, newPayee)
      .then(() => {
        modalBox.close();
        updateCallBack();
        NotificationManager.success(DATA_SAVED);
      })
      .catch(onRequestDefaultError);
  };

  const validateField = fieldName => {
    return !isEmpty(invalidFields) ? !invalidFields.includes(fieldName) : true;
  };

  return (
    <>
      <ModalBoxes.Header>
        <div>
          <span className="header-text">{type} Account Details</span>
          <FormControlLabel
            sx={{
              margin: 0
            }}
            control={
              <Checkbox
                checked={defaultAccount}
                onChange={({ target: { checked } }) => setDefaultAccount(checked)}
                label="Default Account"
                sx={{
                  color: '#691E44',
                  '&.Mui-checked': {
                    color: '#691E44'
                  }
                }}
              />
            }
            label="Default Account"
          />
        </div>
      </ModalBoxes.Header>
      <ModalBoxes.Body>
        <div className="account-details">
          <Input
            data-testid="organization-name-input"
            className="add-account-input"
            label="Organization Name"
            value={organizationName}
            onChange={({ target: { value } }) => {
              if (!isEqual(organizationName, value)) setOrganizationName(value);
            }}
            required={true}
            valid={validateField(fieldNames.ORGANIZATION_NAME)}
            maxLength={50}
          />
          <Input
            data-testid="department-name-input"
            className="add-account-input"
            label="Department/Contact Name"
            value={departmentName}
            onChange={({ target: { value } }) => {
              if (!isEqual(departmentName, value)) setDepartmentName(value);
            }}
            required={true}
            valid={validateField(fieldNames.DEPARTMENT_NAME)}
            maxLength={55}
          />
          <Input
            data-testid="phone-input"
            className="add-account-input"
            label="Phone"
            value={phone}
            onChange={({ target: { value } }) => {
              if (!isEqual(phone, value)) setPhone(normalizePhoneNumberForCard(value));
            }}
            required={true}
            valid={validateField(fieldNames.PHONE)}
          />
          <Input
            data-testid="email-input"
            className="add-account-input"
            label="Email"
            value={email}
            onChange={handleEmailChange}
            onClick={handleInputClick}
            required={true}
            valid={validateField(fieldNames.EMAIL)}
            validationMessage={email.endsWith(elligoDirectDomain) ? '' : 'Use an elligodirect.com domain email.'}
            ref={emailInput}
            maxLength={50}
          />
          <Input
            data-testid="address1-input"
            className="add-account-input"
            label="Address 1"
            value={address1}
            onChange={({ target: { value } }) => {
              if (!isEqual(address1, value)) setAddress1(value);
            }}
            required={true}
            valid={validateField(fieldNames.ADDRESS_1)}
            maxLength={45}
          />
          <Input
            data-testid="address2-input"
            className="add-account-input"
            label="Address 2"
            value={address2}
            onChange={({ target: { value } }) => {
              if (!isEqual(address2, value)) setAddress2(value);
            }}
            maxLength={45}
          />
          <Input
            data-testId="postal-code-input"
            className="add-account-input"
            label="ZIP/Postal Code"
            value={zipCode}
            onChange={({ target: { value } }) =>
              onChangeZipCode(value, country, setCountry, zipCode, setZipCode, setState)
            }
            required={true}
            valid={validateField(fieldNames.ZIP_CODE)}
          />
          <Select
            data-testid="country-dropdown"
            className="add-account-input"
            label="Country"
            clearSearchOnSelection={false}
            searchable={false}
            clearable={false}
            closeOnSelectedOptionClick={false}
            deselectOnSelectedOptionClick={false}
            value={country}
            onChange={newValue => {
              if (!isEqual(country, newValue)) {
                setCountry(newValue);
                setState(null);
                setZipCode(null);
              }
            }}
            dataSource={countries}
            required={true}
            optionLabelKey="id"
            valid={validateField(fieldNames.COUNTRY)}
          />
          <Input
            data-testid="city-input"
            className="add-account-input"
            label="City"
            value={city}
            onChange={({ target: { value } }) => {
              if (!isEqual(city, value)) setCity(value);
            }}
            required={true}
            valid={validateField(fieldNames.CITY)}
            maxLength={25}
          />
          <Select
            data-testid="state-dropdown"
            className="add-account-input"
            label="State/Province"
            clearSearchOnSelection={false}
            searchable
            clearable={false}
            closeOnSelectedOptionClick={false}
            deselectOnSelectedOptionClick={false}
            value={state}
            onChange={newValue => {
              if (!isEqual(state, newValue)) setState(newValue);
            }}
            dataSource={stateList}
            required={true}
            optionLabelKey="id"
            valid={validateField(fieldNames.STATE)}
          />
          <div className="banking-information-header">Banking Information</div>
          <Input
            data-testid="bank-name-input"
            className="add-account-input"
            label="Bank Name"
            value={bankName}
            onChange={({ target: { value } }) => {
              if (!isEqual(bankName, value)) setBankName(value);
            }}
            required={true}
            valid={validateField(fieldNames.BANK_NAME)}
            maxLength={50}
          />
          <Select
            data-testid="account-type-dropdown"
            className="add-account-input"
            label="Account Type"
            clearSearchOnSelection={false}
            searchable
            clearable={false}
            closeOnSelectedOptionClick={false}
            deselectOnSelectedOptionClick={false}
            value={bankAccountType}
            onChange={newValue => {
              if (!isEqual(bankAccountType, newValue)) setBankAccountType(newValue);
            }}
            dataSource={accountTypesList}
            required={true}
            optionLabelKey="id"
            valid={validateField(fieldNames.BANK_ACCOUNT_TYPE)}
          />
          <Input
            data-testid="routing-number-input"
            className="add-account-input"
            label="Routing Number"
            value={bankRoutingNumber}
            onChange={({ target: { value } }) => {
              if (!isEqual(bankRoutingNumber, value)) setBankRoutingNumber(value);
            }}
            required={true}
            valid={validateField(fieldNames.BANK_ROUTING_NUMBER)}
            maxLength={20}
          />
          <Input
            data-testid="account-number-input"
            className="add-account-input"
            label="Account Number"
            value={bankAccountNumber}
            onChange={({ target: { value } }) => {
              if (!isEqual(bankAccountNumber, value)) setBankAccountNumber(value);
            }}
            required={true}
            valid={validateField(fieldNames.BANK_ACCOUNT_NUMBER)}
            maxLength={20}
          />
          <Input
            data-testid="swift-code-input"
            className="add-account-input"
            label="SWIFT Code"
            value={bankSwiftCode}
            onChange={({ target: { value } }) => {
              if (!isEqual(bankSwiftCode, value)) setBankSwiftCode(value);
            }}
            validate={false}
            maxLength={20}
          />
          <Input
            data-testid="registration-number-input"
            className="add-account-input"
            label="GST/HST Registration Number"
            value={bankRegistrationNumber}
            onChange={({ target: { value } }) => {
              if (!isEqual(bankRegistrationNumber, value)) setBankRegistrationNumber(value);
            }}
            validate={false}
          />
        </div>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            Cancel
          </Button>
          <Button priority="high" onClick={onSave}>
            Save
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
};
