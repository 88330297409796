import { NON_PATIENT_EVENT_TYPE } from './NonPatientAppointmentEdit/NonPatientEventType';

export function isSubjectVisibleForType(type) {
  const applicableEventTypes = Array.of(
    NON_PATIENT_EVENT_TYPE.PHONE,
    NON_PATIENT_EVENT_TYPE.OUT_OF_OFFICE,
    NON_PATIENT_EVENT_TYPE.OTHER
  );

  return !type || applicableEventTypes.includes(type);
}
