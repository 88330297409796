import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import EncounterApi from '../../../../../../api/patient/EncounterApi';
import { CLOSED, STARTUP } from '../../../../../../constants/ssuStatuses';
import {
  MANAGE_PATIENT_APPOINTMENTS,
  MANAGE_PROTOCOL_ENCOUNTER_DATA,
  MANAGE_UNEXPECTED_ENCOUNTER_DATA
} from '../../../../../../constants/userOperations';
import { ROLE_SYSTEM_ADMINISTRATOR } from '../../../../../../constants/userRoles';
import { userHasAccessTo, userHasRole } from '../../../../../../services/auth';
import { generateUrlByKey, useCurrentRoute } from '../../../../../root/router';
import { PATIENT_ENCOUNTER_EVENT } from '../../../../Calendar/CalendarEventType';
import { CALENDAR_SELECTED_EVENT } from '../../../../Calendar/CalendarPageContext';
import { PatientInfoContext } from '../PatientInfoContext';

export const EncountersSectionContext = createContext(null);

export function EncountersSectionProvider({ children }) {
  const currentRoute = useCurrentRoute();
  const { currentStudy } = useContext(PatientInfoContext);
  const { addAppointment, rescheduleAppointment } = useAppointmentActions();
  const studySiteStatus = currentStudy?.studySiteStatus;
  const studyId = currentStudy?.studyId;
  const ssuPatientId = currentStudy?.ssuPatientId ?? currentRoute.params?.ssuPatientId;
  const [presentEpochs, setPresentEpochs] = useState(false);
  const [noAppointmentsEpochs, setNoAppointmentsEpochs] = useState([]);

  const loadStudyNoAppointmentsEncounters = useCallback(
    function() {
      EncounterApi.getStudyNoAppointmentsEncounters(studyId, ssuPatientId).then(({ data }) =>
        setNoAppointmentsEpochs(data)
      );
    },
    [studyId, ssuPatientId]
  );

  useEffect(() => {
    loadStudyNoAppointmentsEncounters();
  }, [loadStudyNoAppointmentsEncounters]);

  const [isAllowedToManageEncounters, isAllowedToManageUnexpectedEncounters] = useMemo(() => {
    if (!studySiteStatus) {
      return [false, false];
    }

    if (userHasRole(ROLE_SYSTEM_ADMINISTRATOR)) {
      return [true, true];
    }

    if (studySiteStatus === CLOSED) {
      return [false, false];
    }

    return [userHasAccessTo(MANAGE_PATIENT_APPOINTMENTS), userHasAccessTo(MANAGE_UNEXPECTED_ENCOUNTER_DATA)];
  }, [studySiteStatus]);

  const isCheckinDisabled = useMemo(() => {
    if (!studySiteStatus || studySiteStatus === STARTUP) {
      return true;
    }
    return !(
      userHasRole(ROLE_SYSTEM_ADMINISTRATOR) ||
      (studySiteStatus !== CLOSED && userHasAccessTo(MANAGE_PROTOCOL_ENCOUNTER_DATA))
    );
  }, [studySiteStatus]);

  return (
    <EncountersSectionContext.Provider
      value={{
        presentEpochs,
        setPresentEpochs,
        addAppointment,
        rescheduleAppointment,
        isAllowedToManageEncounters,
        isAllowedToManageUnexpectedEncounters,
        isCheckinDisabled,
        noAppointmentsEpochs,
        loadStudyNoAppointmentsEncounters
      }}
    >
      {children}
    </EncountersSectionContext.Provider>
  );
}

function useAppointmentActions() {
  const { currentStudy } = useContext(PatientInfoContext);
  const currentRoute = useCurrentRoute();
  const navigate = useNavigate();
  const patientId = currentRoute.params?.patientId;
  const ssuPatientId = currentRoute.params?.ssuPatientId;
  const sourcePage = currentRoute?.key;

  const siteId = currentStudy?.siteId;
  const siteName = currentStudy?.siteName;

  return {
    addAppointment: useCallback(
      function(protocolEncounterId) {
        sessionStorage.setItem(CALENDAR_SELECTED_EVENT, JSON.stringify({ sourcePage, siteId, siteName }));
        setTimeout(() =>
          navigate(
            generateUrlByKey(
              `${sourcePage}.Calendar`,
              {
                patientId,
                ssuPatientId
              },
              {
                encounterId: protocolEncounterId,
                sitePatientId: ssuPatientId,
                encounterEventType: PATIENT_ENCOUNTER_EVENT
              }
            )
          )
        );
      },
      [navigate, patientId, siteId, siteName, sourcePage, ssuPatientId]
    ),
    rescheduleAppointment: useCallback(
      function(event) {
        sessionStorage.setItem(
          CALENDAR_SELECTED_EVENT,
          JSON.stringify({
            sourcePage,
            siteId,
            siteName,
            eventId: event.id,
            appointmentDate: event.start
          })
        );
        setTimeout(() => navigate(generateUrlByKey(`${sourcePage}.Calendar`, { patientId, ssuPatientId })));
      },
      [navigate, patientId, siteId, siteName, sourcePage, ssuPatientId]
    )
  };
}

export function useEncountersSection() {
  if (EncountersSectionContext === undefined) {
    throw new Error('useEncountersSection must be used within EncountersSectionProvider');
  }
  return useContext(EncountersSectionContext);
}
