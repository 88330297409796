import { CAS, VAS } from '../../../../constants/accessToPages';

import { Accounts } from './Accounts';

export const accountRouterConfig = {
  name: 'Accounts',
  path: '/accounts',
  access: [CAS, VAS],
  rootBreadcrumbName: 'Setup',
  component: Accounts
};
