import React from 'react';
import { isEmpty, isFunction } from 'lodash/lang';
import uuid from 'uuid';

export default class PatientEncounterAppointmentValidator {
  constructor(timeRange) {
    this.timeRange = timeRange;
  }

  fieldValidation() {
    const [appointment, previousValidation, ...changedField] = arguments;

    const fieldValidators = {
      timeDuration: () => this.timeDurationValidation(appointment, this.timeRange),
      patient: () => this.clearRequiredValidation(appointment, 'patient'),
      study: () => this.clearRequiredValidation(appointment, 'study'),
      epoch: () => this.clearRequiredValidation(appointment, 'epoch'),
      encounter: () => this.clearRequiredValidation(appointment, 'encounter')
    };

    const not = predicate => arg => !predicate(arg);

    return changedField
      .map(fieldName => fieldValidators[fieldName])
      .filter(validator => isFunction(validator))
      .map(validator => validator())
      .filter(not(isEmpty))
      .reduce((acc, current) => ({ ...acc, ...current }), previousValidation);
  }

  formValidation(appointment) {
    return {
      patient: { validationMessage: appointment.patient ? undefined : 'Patient is Required' },
      study: { validationMessage: appointment.study ? undefined : 'Study is Required' },
      epoch: { validationMessage: appointment.epoch ? undefined : 'Epoch is Required' },
      encounter: { validationMessage: appointment.encounter ? undefined : 'Encounter is Required' },
      timeDuration: {
        skippedEncounter: this.validateSkippedEncounter(this.timeRange),
        validationMessage: this.validateTimeDuration(appointment.timeDuration, this.timeRange),
        matchTimeRange: this.timeDurationMatchesRanges(appointment.timeDuration, this.timeRange)
      }
    };
  }

  timeDurationValidation(appointment, timeRange) {
    return {
      timeDuration: {
        validationMessage: this.validateTimeDuration(appointment.timeDuration, timeRange),
        matchTimeRange: this.timeDurationMatchesRanges(appointment.timeDuration, timeRange)
      }
    };
  }

  validateSkippedEncounter(timeRange) {
    return timeRange && !isEmpty(timeRange.message);
  }

  validateTimeDuration(timeDuration, timeRange) {
    if (this.validateSkippedEncounter(timeRange)) return timeRange.message;
    return timeDuration && timeDuration.start && timeDuration.duration
      ? this.timeDurationMatchesRanges(timeDuration, timeRange)
        ? undefined
        : this.timeRangeValidationMessage(timeRange)
      : 'Start and duration are required';
  }

  clearRequiredValidation(appointment, fieldName) {
    if (appointment[fieldName]) {
      return { [fieldName]: { validationMessage: undefined } };
    }
  }

  isValid(appointment) {
    return (
      !!appointment.patient &&
      !!appointment.study &&
      !!appointment.epoch &&
      !!appointment.encounter &&
      !!appointment.timeDuration &&
      !!appointment.timeDuration.start &&
      !!appointment.timeDuration.duration
    );
  }

  timeRangeValidationMessage(ranges) {
    return ranges ? (
      <div>
        {ranges.length >= 1
          ? 'Appointment date is outside of all expected windows:'
          : 'Appointment date is outside of expected window:'}
        <ul>
          {ranges.dateRanges.map(range => {
            return (
              <li key={uuid()}>
                {range.startDate.format('ll')} - {range.endDate.format('ll')}
              </li>
            );
          })}
        </ul>
      </div>
    ) : (
      undefined
    );
  }

  timeDurationMatchesRanges(timeDuration, timeRange) {
    if (this.validateSkippedEncounter(timeRange)) return false;
    if (!timeRange || isEmpty(timeRange.dateRanges)) return true;
    const start = timeDuration.start;
    const end = timeDuration.start.clone().add(timeDuration.duration);
    return !isEmpty(
      timeRange.dateRanges.filter(
        range => start.isBetween(range.startDate, range.endDate) && end.isBetween(range.startDate, range.endDate)
      )
    );
  }

  isValidDate(dateToCheck) {
    return !dateToCheck || isNaN(dateToCheck);
  }
}
