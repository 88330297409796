import React, { useContext, useEffect, useState } from 'react';
import { groupBy } from 'lodash';
import { intersection } from 'lodash/array';
import { isEmpty, isFunction } from 'lodash/lang';

import NewEncounterLogCheckApi from '../../../../../../api/patient/NewEncounterLogCheckApi';
import Button from '../../../../../../common/general/Button';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import { LOG_CHECKS_ADDED } from '../../../../../../constants/notificationMessages';
import { useCurrentRoute } from '../../../../../root/router';
import { resolveNotReported, resolveReported } from '../../../../setup/shared/ElementSetupNew/defaultLogCheckOptions';
import { EncounterPageContext } from '../../NewEncounterPage/NewEncounterPageContext';

import './LogCheck.scss';

export const DATA_COLLECTED = 'DATA_COLLECTED';
export const NO_DATA_TO_COLLECT = 'NO_DATA_TO_COLLECT';
export const NOT_PERFORMED = 'NOT_PERFORMED';

export function LogCheckNew({ logCheckOptions, logCheckAnswers, addNewLog, studySiteId, itemGroupDomainCode }) {
  const [groups, setGroups] = useState([]);
  const currentRoute = useCurrentRoute();
  const NewEncounterPageContext = useContext(EncounterPageContext);

  useEffect(() => {
    const grouped = groupBy(
      logCheckOptions?.filter(e => e.checked === true),
      'rowType'
    );
    const groups = Object.keys(grouped);
    setGroups(groups);
  }, [logCheckOptions]);

  const onNewLogCheckSave = () => {
    NewEncounterLogCheckApi.saveLogCheckEmptyRow(
      studySiteId,
      currentRoute.params?.ssuPatientId,
      currentRoute.params?.patientEncounterId,
      currentRoute.params?.patientItemGroupId
    )
      .then(() => {
        NotificationManager.success(LOG_CHECKS_ADDED);
        isFunction(NewEncounterPageContext.onSubmit) && NewEncounterPageContext.onSubmit();
      })
      .catch(err => NotificationManager.error(err.response.data.message));
  };

  const translate = type => {
    if (DATA_COLLECTED === type) {
      return resolveReported(itemGroupDomainCode);
    } else if (NO_DATA_TO_COLLECT) {
      return resolveNotReported(itemGroupDomainCode);
    } else {
      throw new Error('Unknown log check option');
    }
  };

  return (
    <div className="log-check-edit">
      <div className={'log-ig-buttons'}>
        {intersection([DATA_COLLECTED, NO_DATA_TO_COLLECT], groups).map((logCheckType, index) => (
          <Button
            key={index}
            className={'log-check-button'}
            disabled={
              logCheckType === NO_DATA_TO_COLLECT &&
              !isEmpty(logCheckAnswers) &&
              [DATA_COLLECTED, NO_DATA_TO_COLLECT].includes(logCheckAnswers?.rowType)
            }
            onClick={() => (logCheckType === DATA_COLLECTED ? addNewLog() : onNewLogCheckSave())}
          >
            {translate(logCheckType)}
          </Button>
        ))}
      </div>
    </div>
  );
}
