import React, { useContext, useMemo } from 'react';

import { AddLedgerContext } from '../../AddLedgerContext';

import './AddLedgerFooterControll.scss';

export function AddLedgerFooterControl() {
  const { stepsList, currentStep, checkedBudgetItems, missingBudgetItem } = useContext(AddLedgerContext);
  const currentStepIndex = useMemo(() => {
    return stepsList.findIndex(step => currentStep === step);
  }, [stepsList, currentStep]);
  const isCheckedEventLengthShow = useMemo(checkedEventLengthVisibility, [currentStepIndex]);
  return (
    <div className="add-ledger-footer-control">
      <div>
        {isCheckedEventLengthShow && missingBudgetItem && checkedBudgetItems && (
          <b>{`Events selected: ${checkedBudgetItems.length}/${missingBudgetItem.length}`}</b>
        )}
      </div>
    </div>
  );

  function checkedEventLengthVisibility() {
    return currentStepIndex === 1;
  }
}
