import React, { Fragment, useCallback, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import cx from 'classnames';

import PatientInfoPageApi from '../../../../../../../api/patient/PatientInfoPageApi';
import Loader from '../../../../../../../common/elements/Loader/Loader';
import Icon from '../../../../../../../common/general/Icon';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import { FILE_NOT_SUPPORTED, WAS_DELETED_SUCCESSFULLY } from '../../../../../../../constants/notificationMessages';
import { scBlack } from '../../../../../../../constants/systemColors';
import { MANAGE_PATIENT_FILES } from '../../../../../../../constants/userOperations';
import { FileViewerContext } from '../../../../../../../contexts/FileViewerContext';
import { userHasAccessTo } from '../../../../../../../services/auth';

import PatientContentConfirmation from './PatientContentConfirmation/PatientContentConfirmation';
import { availableFormats, FileUploadContext } from './FileUploadContextProvider';
import { stopEventAnd } from './PatientContentTabs';

function FilesList({ uploadedFiles, setDeleteCandidateId }) {
  const { openFileByPromise } = useContext(FileViewerContext);

  return uploadedFiles.map(function({ id, patientId, fileName }) {
    return (
      <div
        className="patient-files-item"
        key={id}
        onClick={stopEventAnd(() => openFileByPromise(PatientInfoPageApi.downloadPatientFile(patientId, id)))}
        title="Saved file"
      >
        <Icon className="patient-file-status-icon" suit="material-outline" style={{ color: scBlack }}>
          insert_drive_file
        </Icon>
        {fileName}
        {userHasAccessTo(MANAGE_PATIENT_FILES) && (
          <Icon className="delete-file-icon" onClick={stopEventAnd(() => setDeleteCandidateId(id))}>
            close
          </Icon>
        )}
      </div>
    );
  });
}

export function FileLoader() {
  const {
    uploadedFiles,
    uploadFiles,
    deleteFile,
    addFilesAsUploadCandidate,
    uploadFilesCandidates,
    clearUploadDraft,
    canUserViewFiles,
    uploadFilesAllowed,
    isLoading
  } = useContext(FileUploadContext);

  const [deleteFileCandidateId, setDeleteFileCandidateId] = useState(null);

  function clearDeleteDraft() {
    setDeleteFileCandidateId(null);
  }

  function deleteFileByFileId(patientFileId) {
    deleteFile(patientFileId).then(res => {
      if (res) {
        NotificationManager.success(`"${res.data.fileName}" ${WAS_DELETED_SUCCESSFULLY}`);
      }
    });
    clearDeleteDraft();
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDropAccepted = useCallback(addFilesAsUploadCandidate, []);

  const onDropRejected = useCallback(rejectedFiles => {
    NotificationManager.warning(`${rejectedFiles?.length} ${FILE_NOT_SUPPORTED}`);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    disabled: !uploadFilesAllowed,
    onDropAccepted,
    onDropRejected,
    accept: availableFormats
  });

  return (
    <React.Fragment>
      {!isLoading && !uploadFilesCandidates && !deleteFileCandidateId && (
        <div className={cx('patient-files-items', { 'drop-disabled': !uploadFilesAllowed })} {...getRootProps()}>
          <input {...getInputProps()} />
          {!canUserViewFiles ? (
            uploadedFiles?.length === 0 ? (
              <CenteredMessage text="No files have been uploaded." />
            ) : (
              <CenteredMessage text="You do not have permission to view uploaded files." />
            )
          ) : uploadedFiles?.length ? (
            <FilesList uploadedFiles={uploadedFiles} setDeleteCandidateId={setDeleteFileCandidateId} />
          ) : (
            uploadFilesAllowed && <CenteredMessage text="You can select one or more files at a time" />
          )}
        </div>
      )}
      {!isLoading && deleteFileCandidateId && (
        <PatientContentConfirmation
          message={<Fragment>Are you sure you want to delete this file?</Fragment>}
          onConfirm={stopEventAnd(() => {
            deleteFileByFileId(deleteFileCandidateId);
          })}
          onDeny={stopEventAnd(clearDeleteDraft)}
        />
      )}
      {!isLoading && uploadFilesCandidates && (
        <PatientContentConfirmation
          message={
            <Fragment>
              Would you like to upload <b>{uploadFilesCandidates.length}</b> file(s)?
            </Fragment>
          }
          onConfirm={stopEventAnd(() => {
            uploadFiles(uploadFilesCandidates).then(clearUploadDraft);
          })}
          onDeny={stopEventAnd(clearUploadDraft)}
        />
      )}
      {isLoading && (
        <div className="patient-files-loader">
          <Loader />
        </div>
      )}
    </React.Fragment>
  );
}

function CenteredMessage({ text }) {
  return (
    <div className={'eds-center-self-in-flex'}>
      <span>{text}</span>
    </div>
  );
}
