import React, { useMemo } from 'react';
import cx from 'classnames';

import Checkbox from '../../../../../../common/data-entry/InputSelectors/Checkbox';
import { useExportCaseReportFormsActions, useExportCaseReportFormsState } from '../ExportCaseReportFormsContext';

export default function TypeSelector() {
  const {
    allEncounters,
    selectedItemGroupsMap,
    allLogs,
    selectedLogs,
    selectedType,
    isProgressNotesChecked
  } = useExportCaseReportFormsState();
  const {
    setSelectedItemGroupsMap,
    setSelectedLogs,
    setSelectedType,
    setProgressNotesChecked
  } = useExportCaseReportFormsActions();
  const itemGroupsCheckedState = useMemo(
    function() {
      function defineStateOfSelectedItems() {
        const selectedEncounters = Object.keys(selectedItemGroupsMap).filter(
          encounter => selectedItemGroupsMap[encounter]
        );
        const selectedItemGroups = Object.values(selectedItemGroupsMap)
          .flat()
          .filter(itemGroup => itemGroup);
        const allItemGroups = allEncounters.flatMap(({ itemGroups }) => itemGroups);
        const isSomeEncountersSelected = selectedEncounters.length;
        const isAllEncountersChecked = isSomeEncountersSelected === allEncounters.length;
        const isAllItemGroupsChecked = selectedItemGroups.length === allItemGroups.length;
        return { isSomeEncountersSelected, isAllEncountersChecked, isAllItemGroupsChecked };
      }

      const { isSomeEncountersSelected, isAllEncountersChecked, isAllItemGroupsChecked } = defineStateOfSelectedItems();
      if (isAllEncountersChecked && isAllItemGroupsChecked) {
        return { allChecked: true, someChecked: false };
      } else {
        if (isSomeEncountersSelected) {
          return { someChecked: true, allChecked: false };
        }
      }
      return { someChecked: false, allChecked: false };
    },
    [selectedItemGroupsMap, allEncounters]
  );

  const logsCheckedState = useMemo(
    function() {
      const allChecked = selectedLogs.length === allLogs.length;
      return { allChecked, someChecked: !allChecked && selectedLogs.length > 0 };
    },
    [selectedLogs, allLogs]
  );

  return (
    <div className="encounters-selector">
      <h3>Type</h3>
      <div className="ecr-options">
        <div
          className={cx('ecr-option', { 'ecr-option-selected': selectedType === 'ENCOUNTERS' })}
          onClick={() => setSelectedType('ENCOUNTERS')}
        >
          <Checkbox
            checked={itemGroupsCheckedState.allChecked}
            indeterminate={itemGroupsCheckedState.someChecked}
            onChange={onClickTypeEncountersHandler}
          />
          <span>Encounters</span>
        </div>
        <div
          className={cx('ecr-option', { 'ecr-option-selected': selectedType === 'LOGS' })}
          onClick={() => setSelectedType('LOGS')}
        >
          <Checkbox
            checked={logsCheckedState.allChecked}
            indeterminate={logsCheckedState.someChecked}
            onChange={onClickTypeLogsHandler}
          />
          <span>Logs</span>
        </div>
        <hr />
        <div className="ecr-option ecr-option-disable-selection">
          <Checkbox checked={isProgressNotesChecked} onClick={onClickTypeProgressNotesHandler} />
          <span>Notes</span>
        </div>
      </div>
    </div>
  );

  function onClickTypeEncountersHandler() {
    if (!itemGroupsCheckedState.someChecked && !itemGroupsCheckedState.allChecked) {
      setSelectedItemGroupsMap(
        allEncounters.reduce(function(accumulator, encounter) {
          const { id, itemGroups } = encounter;
          return { ...accumulator, [id]: itemGroups };
        }, {})
      );
      return;
    }
    setSelectedItemGroupsMap({});
  }

  function onClickTypeLogsHandler() {
    if (!logsCheckedState.someChecked && !logsCheckedState.allChecked) {
      setSelectedLogs(allLogs);
    } else {
      setSelectedLogs([]);
    }
  }

  function onClickTypeProgressNotesHandler() {
    setProgressNotesChecked(!isProgressNotesChecked);
  }
}
