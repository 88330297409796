import React from 'react';
import { Link } from 'react-router-dom';

import Icon from '../../../../../common/general/Icon';
import { generateUrlByKey, useCurrentRoute } from '../../../../root/router';
import EncounterPatientInfo from '../../shared/EncounterDescNew/EncounterPatientInfo/EncounterPatientInfo';

import AELogsHistory from './AELogsHistory';

import './AdverseEventLogRowHistory.scss';

export default function AdverseEventLogRowHistory() {
  const currentRoute = useCurrentRoute();
  return (
    <>
      <EncounterPatientInfo withLabel={false} infoLabel={'Adverse Event History'} />
      <Link
        className={'ae-log-row-history-link ml-4'}
        to={generateUrlByKey(`${currentRoute.parent.key}`, currentRoute.params)}
      >
        <Icon className={'mr-2'}>arrow_back</Icon>
        Back To Adverse Event
      </Link>
      <AELogsHistory rowId={currentRoute?.params?.rowId} className={'p-4'} />
    </>
  );
}
