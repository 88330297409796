import React, { useCallback, useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import { sortBy } from 'lodash/collection';
import moment from 'moment';
import * as PropTypes from 'prop-types';

import { PatientEncounterReviewApi } from '../../../../../../../../api';
import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Icon from '../../../../../../../../common/general/Icon';
import { TEXT_BLOCK_LONG } from '../../../../../../../../constants/inputTypes';
import { COMMENT_FORM_QUESTION_ID } from '../../../../../../../../constants/protocolConstant';
import { scBlue100 } from '../../../../../../../../constants/systemColors';
import {
  ROLE_CRA,
  ROLE_PRINCIPAL_INVESTIGATOR,
  ROLE_STUDY_MANAGER,
  ROLE_SYSTEM_ADMINISTRATOR
} from '../../../../../../../../constants/userRoles';
import { userHasRole } from '../../../../../../../../services/auth';
import { showEncounterColumn } from '../../../../../../setup/shared/ElementSetupNew/defaultLogCheckOptions';
import LogsTable from '../../../../../Patients/Logs/LogsTable';
import { CustomCell, defaultCellWidth } from '../../../../../Patients/Logs/LogsTableService';
import { AE_NOT_REPORTED } from '../../../../Worklist/constants';
import { ReviewContext } from '../../../ReviewContext';
import { isSmOrPiReview } from '../../../reviewService';
import ElementHistoryLogModal from '../../HistoryLogModal/ElementHistoryLogModal';
import { AddCommentModal } from '../../ItemGroupElement/AddCommentModal/AddCommentModal';
import * as itemGroupTableViewService from '../../ItemGroupElement/ItemGroupTableViewService';
import { MIN_WIDTH_OF_REVIEW_TABLE_COLUMN } from '../../ItemGroupElement/ItemGroupTableViewService';
import * as reviewContentService from '../../reviewContentService';
import TableCellAnswer from '../../TableCellAnwer/TableCellAnswer';

import LogItemGroupAdverseEventHistoryModal from './LogItemGroupAdverseEventHistoryModal';

import '../../ItemGroupElement/ItemGroupTable/ItemGroupTable.scss';

const logRowNumberAccessor = 'logRowNumber';
const associatedEncounterAccessor = 'associatedEncounterName';

export default function LogItemGroupContent({ logSnapshotState, item }) {
  const [logRenderData, setLogRenderData] = useState([]);
  const [columns, setColumns] = useState([]);
  const prepareLogDataToRender = useCallback(handlePrepareLogDataToRender, [logSnapshotState]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const prepareColumns = useCallback(handlePrepareColumns, [logSnapshotState, logRenderData]);
  const {
    patientEncounterId,
    ssuPatientId,
    patientItemGroupId: reviewItemGroupId,
    loadData,
    isHistoricalData,
    reviewType,
    reviewMetaData,
    updateLogData
  } = useContext(ReviewContext);
  const itemGroupTemplateId = logSnapshotState.logFormRef.itemGroupTemplateId;
  const domainCode = logSnapshotState.logFormRef.domainCode;

  function openLogRowHistory(logRow) {
    const itemGroupTemplateId = logRow?.rowFormRef?.itemGroupTemplateId;
    const itemGroupTemplateVersionId = logRow?.rowFormRef?.itemGroupTemplateVersionId;
    const rowId = logRow?.rowId;
    const itemGroupName = logSnapshotState.logFormRef.name;
    const onLoadData = () =>
      PatientEncounterReviewApi.getLogItemGroupAnswerHistory(
        ssuPatientId,
        patientEncounterId,
        itemGroupTemplateId,
        itemGroupTemplateVersionId,
        rowId
      );
    ModalBoxes.open({
      component: <ElementHistoryLogModal itemGroupName={itemGroupName} onLoadData={onLoadData} />,
      title: `${itemGroupName}`
    });
  }

  useEffect(
    function() {
      setLogRenderData(prepareLogDataToRender);
    },
    [prepareLogDataToRender]
  );

  useEffect(
    function() {
      setColumns(prepareColumns);
    },
    [prepareColumns]
  );

  return (
    <div className="eds-review-item-group-element-table-reflection">
      <div className="log-title">
        <span>Log Entries</span>
      </div>

      <LogsTable
        columns={columns}
        data={logRenderData.filter(e => {
          return !(item.domainCode === 'AE' && e.piStatus === AE_NOT_REPORTED && e.smStatus === AE_NOT_REPORTED);
        })}
        getTrProps={getTrProps}
        sortable={false}
        showPagination={false}
        defaultCellWidth={defaultCellWidth}
        pageSize={logRenderData?.length || 1}
        getTdProps={(state, rowInfo, column) => {
          let props = {};
          const columnId = column.id;
          if (
            rowInfo &&
            (rowInfo?.row[columnId]?.question === 'Comment' || rowInfo?.row[columnId]?.itemGroupQuestionId === 'COVAL')
          ) {
            props = {
              onClick: event => {
                onEditComment(rowInfo, rowInfo?.row[columnId], event);
              }
            };
          }
          return props;
        }}
      />
    </div>
  );

  function onEditComment({ original }, commentAnswer) {
    ModalBoxes.open({
      component: (
        <AddCommentModal
          context={{
            ssuPatientId,
            reviewItemGroupId,
            patientEncounterId,
            loadData,
            reviewType,
            isHistoricalData,
            studySiteStatus: reviewMetaData.studySiteStatus
          }}
          updateData={updateLogData}
          snapshotData={{ ...original, commentAnswer }}
          isLogItemGroup={true}
          itemGroupTemplateId={itemGroupTemplateId}
          itemGroupTemplateVersionId={original.rowFormRef.itemGroupTemplateVersionId}
        />
      )
    });
  }

  function handlePrepareColumns() {
    const formQuestions = collectAllUniqueLogTemplateVersionQuestionsByKey(logSnapshotState);
    const columns = [
      {
        Header: 'History',
        fixed: true,
        id: 'History',
        show:
          item.domainCode === 'AE' &&
          (userHasRole(ROLE_CRA) ||
            userHasRole(ROLE_SYSTEM_ADMINISTRATOR) ||
            userHasRole(ROLE_STUDY_MANAGER) ||
            userHasRole(ROLE_PRINCIPAL_INVESTIGATOR)),
        minWidth: 70,
        width: 70,
        Cell: row => {
          return (
            <Icon
              onClick={() => {
                ModalBoxes.open({
                  component: (
                    <LogItemGroupAdverseEventHistoryModal
                      rowId={row.original.rowId}
                      logRenderData={logRenderData.filter(e => e.rowId === row.original.rowId)}
                      columns={columns.filter(e => e.id !== 'History')}
                      onEditComment={onEditComment}
                      getTrProps={getTrProps}
                    />
                  ),
                  title: 'Log Row History'
                });
              }}
              style={{ fontSize: '25px', color: scBlue100, cursor: 'pointer' }}
            >
              history
            </Icon>
          );
        }
      }
    ];

    columns.push(prepareLogRowNumberColumn());
    columns.push({
      Header: 'Date Recorded',
      fixed: true,
      id: 'Date Recorded',
      accessor: 'whenWasItDone',
      show:
        item.domainCode === 'AE' &&
        (userHasRole(ROLE_SYSTEM_ADMINISTRATOR) ||
          userHasRole(ROLE_CRA) ||
          userHasRole(ROLE_STUDY_MANAGER) ||
          userHasRole(ROLE_PRINCIPAL_INVESTIGATOR)),
      minWidth: 120,
      width: 120,
      Cell: ({ value }) => moment(value, 'YYYY-MM-DD').format('DD/MMM/YYYY')
    });

    showEncounterColumn(domainCode) && columns.push(prepareLogRowEncounterColumn());

    formQuestions.forEach(formQuestion => {
      columns.push(prepareColumnForQuestion(formQuestion, logRenderData));
    });

    columns.push(prepareCommentColumn());

    if (item.domainCode !== 'AE') {
      columns.push(prepareHistoryColumn());
    }

    return columns;

    function prepareColumnForQuestion(formQuestion, logRenderData) {
      if (formQuestion.type === TEXT_BLOCK_LONG) {
        return false;
      }
      const formQuestionAnswerMapAccessor = formQuestion.questionKey;
      const minWidth = calculateMinWidth(formQuestion, logRenderData);

      const isAE = item.domainCode === 'AE';
      const additionalProps = {};
      if (isAE && formQuestion.label === 'Adverse Event') {
        additionalProps.minWidth = defaultCellWidth;
        additionalProps.width = defaultCellWidth;
      }
      return {
        // Color out edited data cells after sign or required attention cells
        getProps: (state, row) => {
          const answer = row?.original?.answers[formQuestionAnswerMapAccessor];
          if (answer) {
            let className = reviewContentService.getAnswerHighlightStyleClassByAnswerAndReviewType(answer, reviewType)
              ?.className;
            if (
              !['radio', 'date'].includes(formQuestion?.type) &&
              answer.type !== 'NO_SENSE' &&
              answer.type !== 'EMPTY'
            ) {
              className = className ? className + ' text-left' : 'text-left';
            }
            return { className: className };
          }
          return {};
        },
        Header: formQuestion.label,
        fixed: isAE && formQuestion.label === 'Adverse Event',
        id: formQuestion.questionId,
        investigatorField: formQuestion.investigatorField,
        formQuestionAnswerMapAccessor,
        Cell: row => {
          const answer = row?.original?.answers[formQuestionAnswerMapAccessor];
          if (answer && answer.type !== 'NO_SENSE' && answer.type !== 'EMPTY') {
            return <TableCellAnswer answer={answer} isAnswerEdited={answer.isEdited} />;
          } else {
            return '--';
          }
        },
        minWidth,
        ...additionalProps
      };
    }

    function prepareHistoryColumn() {
      return {
        Header: 'History',
        minWidth: 80,
        width: 80,
        className: 'text-left',
        fixed: true,
        Cell: row => {
          if (!isHistoricalData) {
            return (
              <div className="edited-link" onClick={() => openLogRowHistory(row.original)}>
                History
              </div>
            );
          }
          return '';
        }
      };
    }

    function prepareLogRowNumberColumn() {
      return {
        Header: 'N',
        width: 45,
        minWidth: 45,
        className: 'text-left pl-2',
        fixed: true,
        accessor: logRowNumberAccessor
      };
    }

    function prepareLogRowEncounterColumn() {
      return {
        getProps: (state, row) => ({
          className: cx({ 'edited-data-after-sign-bg': isSmOrPiReview(reviewType) && row.original?.encounterChanged })
        }),
        Header: 'Encounter',
        fixed: true,
        minWidth: 150,
        className: 'text-left',
        accessor: associatedEncounterAccessor,
        Cell: row => {
          const encounter = row.original?.associatedEncounterName ?? 'N/A';
          return <CustomCell longAnswer={encounter} shortAnswer={encounter} />;
        }
      };
    }

    function prepareCommentColumn() {
      return {
        getProps: (state, row) => {
          const commentAnswer = row?.original?.answers[COMMENT_FORM_QUESTION_ID];
          const props = {
            ...reviewContentService.getAnswerHighlightStyleClassByAnswerAndReviewType(commentAnswer, reviewType)
          };
          props.className = commentAnswer?.stringValue ? 'text-left' : 'text-center';
          return props;
        },
        Header: 'Comments',
        width: defaultCellWidth,
        id: 'Comments',
        accessor: row => row.answers[COMMENT_FORM_QUESTION_ID],
        Cell: row => {
          const answers = row.original?.answers;
          const commentAnswer = answers?.[COMMENT_FORM_QUESTION_ID];

          if (commentAnswer?.stringValue) {
            return (
              <CustomCell
                className={'edited-link'}
                longAnswer={commentAnswer?.stringValue}
                shortAnswer={commentAnswer?.stringValue}
              />
            );
          } else {
            return <div>--</div>;
          }
        }
      };
    }
  }

  function calculateMinWidth(formQuestion, logDataToRender) {
    return (
      Math.max(
        ...logDataToRender.map(logRowToRender => {
          const answer = logRowToRender.answers[formQuestion.questionKey];
          return itemGroupTableViewService.getWidthOfTableColumnByAnswer(answer);
        })
      ) || MIN_WIDTH_OF_REVIEW_TABLE_COLUMN
    );
  }

  function handlePrepareLogDataToRender() {
    const allLogTemplateVersionQuestions = collectAllLogTemplateVersionQuestions(logSnapshotState);

    return logSnapshotState.rows.map(row => {
      const logRowToRender = {};
      populateLogRowNumber(logRowToRender, row.rowRef.logRowNumber);
      populateRowId(logRowToRender, row);
      populateRowFormRef(logRowToRender, row);
      populateLogRowAnswers(logRowToRender, row, allLogTemplateVersionQuestions);
      populateWhenAndWhoIsDone(logRowToRender, row);
      populateRowReviewStatus(logRowToRender, row);
      populateAssociatedEncounter(logRowToRender, row);
      populateRowStatuses(logRowToRender, row);

      return logRowToRender;
    });

    function populateRowId(tableRowToRender, logRowState) {
      tableRowToRender.rowId = logRowState?.rowSnapshotFormData?.rowId;
      tableRowToRender.rowVersion = logRowState?.rowSnapshotFormData?.rowVersion;
      tableRowToRender.studyId = logRowState?.rowSnapshotKey.rowKey.studyId;
    }

    function populateRowStatuses(tableRowToRender, logRowState) {
      tableRowToRender.piStatus = logRowState?.statuses?.piStatus;
      tableRowToRender.smStatus = logRowState?.statuses?.smStatus;
    }

    function populateRowFormRef(tableRowToRender, logRowState) {
      tableRowToRender.rowFormRef = logRowState?.rowRef?.logFormRef;
    }

    function populateWhenAndWhoIsDone(tableRowToRender, logRowState) {
      const { whenWasItDone, whoDidItName } = logRowState?.rowSnapshotFormData?.form;
      tableRowToRender.whenWasItDone = whenWasItDone;
      tableRowToRender.whoDidItName = whoDidItName;
    }

    function populateAssociatedEncounter(tableRowToRender, logRowState) {
      const associatedEncounter = logRowState?.associatedEncounter;
      tableRowToRender.associatedEncounterId = associatedEncounter?.encounterInstanceId;
      tableRowToRender.associatedEncounterName = associatedEncounter?.encounterName;
      tableRowToRender.encounterChanged = logRowState.encounterChanged;
    }

    function collectAllLogTemplateVersionQuestions(logSnapshotState) {
      const allLogTemplateVersionQuestions = logSnapshotState.itemGroupTemplateVersions.flatMap(
        itemGroupTemplateVersion => itemGroupTemplateVersion.itemGroupVersionQuestions
      );
      //Add comment question config since it is not configured in protocol
      const commentQuestionConfig = {
        name: COMMENT_FORM_QUESTION_ID,
        questionId: COMMENT_FORM_QUESTION_ID,
        label: COMMENT_FORM_QUESTION_ID,
        questionKey: COMMENT_FORM_QUESTION_ID
      };
      allLogTemplateVersionQuestions.push(commentQuestionConfig);

      return allLogTemplateVersionQuestions;
    }

    function populateLogRowNumber(logRowToRender, logRowNumber) {
      logRowToRender[logRowNumberAccessor] = logRowNumber;
    }

    function populateLogRowAnswers(logRowToRender, row, formQuestions) {
      if (row?.rowSnapshotFormData?.form?.answers) {
        const logRowAnswers = row?.rowSnapshotFormData.form.answers;
        const answersByQuestionKey = [];
        logRowAnswers.forEach(answer => {
          const questionKey = formQuestions.find(question => question.questionId === answer.itemGroupQuestionId)
            ?.questionKey;
          answer.isEdited = row?.editedQuestionIds?.includes(answer.itemGroupQuestionId);
          answer.isEditedAfterSign = row?.editedAfterSignQuestionIds?.includes(answer.itemGroupQuestionId);
          answersByQuestionKey[questionKey] = answer;
        });
        logRowToRender.answers = answersByQuestionKey;
      }
    }

    function populateRowReviewStatus(logRowToRender, logRowSnapshotState) {
      logRowToRender.rowReviewStatus = logRowSnapshotState?.rowReviewStatus;
    }
  }

  function collectAllUniqueLogTemplateVersionQuestionsByKey(logSnapshotState) {
    let questionsByKey = [];

    let addQuestionTuQuestionSetIfNotAdded = q => {
      if (questionsByKey.every(alreadySelectedQuestion => alreadySelectedQuestion.questionKey !== q.questionKey)) {
        questionsByKey.push(q);
      }
    };
    logSnapshotState.itemGroupTemplateVersions.forEach(answerSet =>
      answerSet.itemGroupVersionQuestions.forEach(addQuestionTuQuestionSetIfNotAdded)
    );

    return sortBy(questionsByKey, question => question.sequence);
  }

  function getTrProps(state, rowInfo) {
    const rowReviewStatus = rowInfo?.row?._original?.rowReviewStatus;
    return reviewContentService.getFormHighlightStyleBasedOnRowReviewStatusAndReviewType(rowReviewStatus, reviewType);
  }
}

LogItemGroupContent.propTypes = {
  logSnapshotState: PropTypes.object
};
