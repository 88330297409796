import React, { useContext, useMemo } from 'react';
import * as PropTypes from 'prop-types';

import { PatientEncounterReviewApi } from '../../../../../../../api';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import {
  ROLE_CLINICAL_QUALITY_COORDINATOR,
  ROLE_FINANCE_ANALYST,
  ROLE_OPERATION_MANAGER,
  ROLE_OPERATIONS_ANALYST,
  ROLE_STUDY_LEAD
} from '../../../../../../../constants/userRoles';
import { userHasRole } from '../../../../../../../services/auth';
import { useCurrentRoute } from '../../../../../../root/router';
import { ReviewContext } from '../../ReviewContext';
import { isSmOrPiReview } from '../../reviewService';
import ElementHistoryLogCheckModal from '../HistoryLogCheckModal/ElementHistoryLogCheckModal';
import ItemGroupElementHeader from '../ItemGroupElementHeader/ItemGroupElementHeader';
import { isSignedReviewStatus } from '../reviewContentService';

import LogCheckItemGroupContent from './LogCheckItemGroupContent/LogCheckItemGroupContent';
import LogItemGroupContent from './LogItemGroupContent/LogItemGroupContent';

export default function LogItemGroupElement({
  logSnapshotState,
  logCheckSnapshotState,
  isLogPresent,
  isLogCheckPresent,
  logCheckItem,
  reviewStatus,
  item
}) {
  const {
    reviewType,
    isHistoricalData,
    requiredAttentionItemGroupsConfirmStatuses,
    setRequiredAttentionItemGroupsConfirmStatuses
  } = useContext(ReviewContext);
  const itemGroupTemplateId = logSnapshotState?.logFormRef.itemGroupTemplateId;
  const currentRoute = useCurrentRoute();
  const logName = logSnapshotState?.logFormRef?.name;
  const isLogItemGroupRequiredAttention = requiredAttentionItemGroupsConfirmStatuses.hasOwnProperty(
    itemGroupTemplateId
  );
  const showLogCheckHistoryButton =
    logCheckSnapshotState?.encounterLogCheckSnapshotKey?.recordVersion >= 1 && !isHistoricalData;

  const userWithReadOnlyAccess = [
    ROLE_STUDY_LEAD,
    ROLE_OPERATION_MANAGER,
    ROLE_FINANCE_ANALYST,
    ROLE_OPERATIONS_ANALYST,
    ROLE_CLINICAL_QUALITY_COORDINATOR
  ];

  const showItemGroupConfirmButton =
    isSmOrPiReview(reviewType) &&
    isLogItemGroupRequiredAttention &&
    !isHistoricalData &&
    !userHasRole(userWithReadOnlyAccess);
  const isItemGroupConfirmed = requiredAttentionItemGroupsConfirmStatuses[itemGroupTemplateId];
  const disableItemGroupConfirmButton = isSignedReviewStatus(reviewStatus);

  const isCraReview = useMemo(() => {
    return currentRoute.key.includes('CRA Review');
  }, [currentRoute]);

  return (
    <div className="eds-review-item-group-element" id={itemGroupTemplateId}>
      {logSnapshotState && (
        <ItemGroupElementHeader
          itemGroupId={itemGroupTemplateId}
          itemGroupName={logName}
          itemGroupType="Logs"
          showItemGroupConfirmButton={showItemGroupConfirmButton}
          disableItemGroupConfirmButton={disableItemGroupConfirmButton}
          isItemGroupConfirmed={isItemGroupConfirmed}
          onConfirmItemGroup={onConfirmLogItemGroup}
          showHistoryButton={showLogCheckHistoryButton}
          getAnswersHistory={getLogCheckAnswersHistory}
        />
      )}
      {isLogCheckPresent && (
        <LogCheckItemGroupContent logCheckSnapshotState={logCheckSnapshotState} reviewType={reviewType} />
      )}
      {isLogPresent && (item.domainCode !== 'AE' || isCraReview) && (
        <LogItemGroupContent logSnapshotState={logSnapshotState} item={item} />
      )}
    </div>
  );

  function onConfirmLogItemGroup() {
    setRequiredAttentionItemGroupsConfirmStatuses(prevState => ({
      ...prevState,
      [itemGroupTemplateId]: !isItemGroupConfirmed
    }));
  }

  function getLogCheckAnswersHistory() {
    const onLoadData = () =>
      PatientEncounterReviewApi.getLogCheckAnswerHistory(
        logCheckSnapshotState?.encounterLogCheckSnapshotKey?.encounterLogCheckKey?.ssuPatientId,
        logCheckSnapshotState?.encounterLogCheckSnapshotKey?.encounterLogCheckKey?.sitePatientEncounterId,
        logCheckItem?.patientItemGroupId
      );

    ModalBoxes.open({
      component: <ElementHistoryLogCheckModal onLoadData={onLoadData} />,
      title: logCheckItem?.patientItemGroupName
    });
  }
}

LogItemGroupElement.propTypes = {
  logSnapshotState: PropTypes.object,
  logCheckSnapshotState: PropTypes.object,
  isLogPresent: PropTypes.bool,
  isLogCheckPresent: PropTypes.bool,
  logCheckItem: PropTypes.object
};
