import React, { Component } from 'react';

import EpochComponent from './epochComponent';

class previewSetupComponent extends Component {
  handelEndPoint(el) {
    this.props.updateEndPointHandler(el);
  }

  render() {
    return (
      <div className="row col-md-12 border px-0 mx-0 mb-2 mb-4">
        <div
          className="col-lg-12 col-md-12 col-sm-12 jtk-demo-canvas canvas-wide protocol-setup-container jtk-surface jtk-surface-nopan"
          id="canvas"
          style={{ height: '650px', maxHeight: '800px', backgroundColor: 'white', display: 'block' }}
          onScroll={this.props.onCanvasScroll}
        >
          <div className="start_window" id="startWindow">
            Start
          </div>
          <div className="end_window" id="endWindow">
            End
          </div>
          {this.props.epochs &&
            this.props.epochs.map((vx, idx) => {
              return (
                <EpochComponent
                  key={idx}
                  endPointHandler={this.handelEndPoint.bind(this)}
                  epochInfo={vx}
                  epochIdx={idx}
                />
              );
            })}

          <div className="col-md-12 row m-0 justify-content-start my-3" />
        </div>
      </div>
    );
  }
}

export default previewSetupComponent;
