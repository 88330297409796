import React, { useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { ALLOW_EDIT, ALLOW_READ, ALLOW_SIGN, DENY_ALL, permissionStatusMap } from '../../permissionType';

import { isCellAccessible } from './GroupsSetupTableCell';

import './GroupsSetupTableCell.scss';
export default function GroupsSetupTableViewableCell({ groupsConfigurationMap, cellKey }) {
  const [currentCellData] = useMemo(
    function() {
      if (!groupsConfigurationMap) return [];

      const data = groupsConfigurationMap[cellKey];

      if (data) {
        return [data, cellKey];
      }

      const validCellKey = cellKey.replace(/_.*/, '_EMPTY_KEY');

      const newData = groupsConfigurationMap[validCellKey];

      if (newData?.logItemGroupType) {
        return [newData, validCellKey];
      }
      return [];
    },
    [groupsConfigurationMap, cellKey]
  );

  if (!currentCellData) return null;
  const { permissionType: originPermissionType } = currentCellData;

  const cellAccessible = isCellAccessible(
    currentCellData?.linkedToEncounterKeys,
    currentCellData?.selectedInUnexpectedConfig,
    currentCellData?.removedFromEncounterSetup,
    currentCellData?.logItemGroupType,
    cellKey.replace(/.*?_/, '')
  );
  const permissionType = cellAccessible ? originPermissionType : null;

  return permissionType ? (
    <div
      className={cx('groups-setup-table-cell', {
        'groups-setup-table-cell-read': permissionType === ALLOW_READ,
        'groups-setup-table-cell-edit': permissionType === ALLOW_EDIT,
        'groups-setup-table-cell-sign': permissionType === ALLOW_SIGN,
        'groups-setup-table-cell-deny': permissionType === DENY_ALL
      })}
    >
      <div className={cx('groups-setup-table-cell-status groups-setup-table-cell-status-read-only')}>
        {permissionStatusMap[permissionType]}
      </div>
    </div>
  ) : (
    <></>
  );
}

GroupsSetupTableViewableCell.propTypes = {
  groupsConfigurationMap: PropTypes.object,
  cellKey: PropTypes.string
};
