import React, { useContext, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import Button from '../../../../../../../common/general/Button';
import Icon from '../../../../../../../common/general/Icon';
import { VIEW_IG_AND_ENCOUNTER_AS_LINKS_AT_PI_WORK_LIST_PI_REVIEW } from '../../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../../services/auth';
import { generateUrlByKey, useCurrentRoute } from '../../../../../../root/router';
import { LOGS, NEW_TABLE } from '../../reviewConstants';
import { ReviewContext } from '../../ReviewContext';
import { ItemGroupElementHeaderWithIcon } from '../ItemGroupElement/ItemGroupElementHeaderWithIcon/ItemGroupElementHeaderWithIcon';

import './ItemGroupElementHeader.scss';

export default function ItemGroupElementHeader({
  itemGroupId,
  itemGroupType,
  itemGroupName,
  showItemGroupConfirmButton,
  disableItemGroupConfirmButton,
  isItemGroupConfirmed,
  onConfirmItemGroup,
  showHistoryButton,
  getAnswersHistory,
  isNotPerformedItemGroup
}) {
  const currentRoute = useCurrentRoute();
  const procedurePageKey = `${currentRoute.parent.key}.Procedure`;
  const newTablePageKey = `${currentRoute.parent.key}.Table`;
  const logsPageKey = `${currentRoute.parent?.parent?.key || currentRoute.parent.key}.Logs`;

  const itemGroupConfirmIcon = isItemGroupConfirmed ? 'bookmark' : 'bookmark_border';
  const itemGroupConfirmText = isItemGroupConfirmed ? 'Confirmed' : 'Confirm';
  const { patientItemGroupId: reviewPatientItemGroupId, ssuPatientId, patientEncounterId } = useContext(ReviewContext);
  const urlForItemGroupPage = useMemo(
    function() {
      if (!reviewPatientItemGroupId) {
        return null;
      }

      let pageKey = procedurePageKey;
      if (LOGS === itemGroupType) {
        pageKey = logsPageKey;
      } else if (NEW_TABLE === itemGroupType) {
        pageKey = newTablePageKey;
      }

      return generateUrlByKey(pageKey, {
        ssuPatientId,
        patientEncounterId,
        reviewPatientItemGroupId,
        patientItemGroupId: itemGroupId,
        ...currentRoute.params
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      logsPageKey,
      procedurePageKey,
      itemGroupType,
      ssuPatientId,
      currentRoute,
      patientEncounterId,
      reviewPatientItemGroupId,
      itemGroupId
    ]
  );

  const showAsLink = useMemo(
    function() {
      return (
        urlForItemGroupPage &&
        userHasAccessTo(VIEW_IG_AND_ENCOUNTER_AS_LINKS_AT_PI_WORK_LIST_PI_REVIEW) &&
        !isNotPerformedItemGroup
      );
    },
    [urlForItemGroupPage, isNotPerformedItemGroup]
  );

  const itemGroupElementHeaderContent = showAsLink ? (
    <NavLink to={urlForItemGroupPage}>{itemGroupName}</NavLink>
  ) : (
    itemGroupName
  );
  return (
    <div className="eds-top-item-group">
      <ItemGroupElementHeaderWithIcon itemGroupType={itemGroupType} content={itemGroupElementHeaderContent} />
      <div className="d-flex">
        {showItemGroupConfirmButton && (
          <Button
            size="h40"
            priority="medium"
            className="review-item-group-element-confirm-button"
            onClick={() => onConfirmItemGroup()}
            disabled={disableItemGroupConfirmButton}
          >
            <Icon>{itemGroupConfirmIcon}</Icon>
            {itemGroupConfirmText}
          </Button>
        )}
        <div className="history">
          {showHistoryButton && (
            <div className="brand-link" onClick={() => getAnswersHistory()}>
              History
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
