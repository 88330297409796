import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { some, sortBy } from 'lodash/collection';
import { isEmpty, isEqual, isFunction, isNull } from 'lodash/lang';
import uuid from 'uuid/v4';

import { ProtocolApi } from '../../../../../../../api';
import { afterburnerApi } from '../../../../../../../api/patient/AftrburnerApi';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import {
  DATA_SAVED,
  REQUIRED_ATTRIBUTE_MISSING,
  SOME_OF_FIELDS_ARE_EMPTY,
  SOMETHING_WENT_WRONG
} from '../../../../../../../constants/notificationMessages';

import { ENCOUNTER_MAPPING, IG_MAPPING, mappingTypes } from './MappingSetupConstants';
import {
  generateListOfCollapsedGroups,
  openTemplateModal,
  prepareDataForTemplateMode,
  prepareGeneralFields,
  prepareGeneralMapping,
  sortFieldsByName,
  validateAlwaysSentMapping,
  validateGeneralFields,
  validateMapping
} from './MappingSetupServices';

export const MappingSetupContext = React.createContext(null);

export const MappingSetupProvider = ({
  children,
  templateMode,
  templateId,
  updateTable,
  initialParameters,
  studyList,
  modalBox
}) => {
  const listOfTriggers = useMemo(
    () => [
      { id: 'SM Review', name: 'SM Review' },
      { id: 'Investigator Review', name: 'Investigator Review' }
    ],
    []
  );
  const [currentEncounterConfiguration, setCurrentEncounterConfiguration] = useState(null);
  const [fullPlatformResponse, setFullPlatformResponse] = useState(null);

  const [listOfPlatforms, setListOfPlatforms] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState(null);

  const [listOfTriggerItemGroups, setListOfTriggerItemGroups] = useState([]);
  const [selectedTriggerItemGroups, setSelectedTriggerItemGroups] = useState([]);

  const [endpointsList, setEndpointsList] = useState([]);
  const [selectedEndpoint, setSelectedEndpoint] = useState(null);

  const [selectedStudy, setSelectedStudy] = useState(null);

  const [protocolList, setProtocolList] = useState([]);
  const [selectedProtocol, setSelectedProtocol] = useState(null);

  const [selectedMappingType, setSelectedMappingType] = useState(null);

  const [epochList, setEpochList] = useState([]);
  const [selectedEpoch, setSelectedEpoch] = useState(null);

  const [mappingNamesList, setMappingNamesList] = useState([]);
  const [selectedMapping, setSelectedMapping] = useState(null);

  const [initialItemGroupMappings, setInitialItemGroupMappings] = useState([]);

  const [generalFieldMapping, setGeneralFieldMapping] = useState([]);
  const [itemGroupsMapping, setItemGroupsMapping] = useState([]);

  const [sendPartialPayload, setSendPartialPayload] = useState(false);
  const [protocolEncounterConfigurationEnabled, setProtocolEncounterConfigurationEnabled] = useState(true);

  const [listOfItemGroups, setListOfItemGroups] = useState([]);

  const [listOfInvalidGeneralMappings, setListOfInvalidGeneralMappings] = useState([]);
  const [listOfInvalidFieldMappings, setListOfInvalidFieldMappings] = useState([]);

  const [templateName, setTemplateName] = useState('');

  const [listOfCollapsedGroups, setListOfCollapsedGroup] = useState([]);

  const [generalGroupAfterFetch, setGeneralGroupAfterFetch] = useState([]);

  const resolveApiForFetchConfiguration = useCallback(() => {
    if (templateMode) {
      if (templateId) {
        return afterburnerApi
          .getMappingTemplateConfigurationById(templateId)
          .then(response => prepareDataForTemplateMode(response));
      } else {
        return afterburnerApi
          .getMappingTemplateConfiguration(initialParameters.protocol.id)
          .then(response => prepareDataForTemplateMode(response));
      }
    } else {
      const requestParameters = {
        protocolIdentity: selectedProtocol.id,
        platformId: selectedPlatform.value,
        platformUrlId: selectedEndpoint.value
      };
      if (selectedMappingType?.id === ENCOUNTER_MAPPING) {
        return afterburnerApi.allMappingsForEncounter({
          ...requestParameters,
          protocolEncounterKey: selectedMapping.id
        });
      } else {
        return afterburnerApi.allMappingsForItemGroup({
          ...requestParameters,
          itemGroupKey: selectedMapping.id
        });
      }
    }
  }, [
    initialParameters,
    selectedEndpoint?.value,
    selectedMapping?.id,
    selectedMappingType,
    selectedPlatform?.value,
    selectedProtocol?.id,
    templateId,
    templateMode
  ]);

  const resolveApiForSaveConfiguration = useCallback(
    data => {
      if (templateMode) {
        return afterburnerApi.saveTemplate(data);
      }
      return afterburnerApi.saveAfterburnerMapping(data);
    },
    [templateMode]
  );

  useEffect(() => {
    if (!templateMode && !isEmpty(initialParameters)) {
      setSelectedPlatform(initialParameters.platform);
      setSelectedEndpoint(initialParameters.endpoint);
      setSelectedStudy(initialParameters.study);
      setSelectedProtocol(initialParameters.protocol);
      setSelectedMappingType(isEmpty(initialParameters.encounter) ? mappingTypes[1] : mappingTypes[0]);
      setSelectedEpoch(initialParameters.epoch);
      setSelectedMapping(
        isEmpty(initialParameters.encounter) ? initialParameters.itemGroup : initialParameters.encounter
      );
    }
  }, [initialParameters, templateMode]);

  useEffect(() => {
    if (!templateMode && isEmpty(initialParameters)) {
      afterburnerApi
        .getAllPlatformsConfiguration()
        .then(({ data }) => {
          const initialListOfPlatforms = sortBy(
            data.map(platform => ({ name: platform.platformName, value: platform.id })),
            'name'
          );
          setFullPlatformResponse(data);
          setListOfPlatforms(initialListOfPlatforms);
        })
        .catch(() => {
          NotificationManager.error(SOMETHING_WENT_WRONG);
        });
    }
  }, [initialParameters, templateMode]);

  useEffect(() => {
    if (selectedStudy?.id && isEmpty(initialParameters)) {
      ProtocolApi.getProtocols(selectedStudy.id).then(
        ({ data: { response } }) => {
          const dataForMap = response === 'No Protocol found' ? [] : response;
          const preparedProtocolList = dataForMap.map(protocol => ({
            id: protocol.uniqueIdentifier,
            name: `${protocol.name} ${protocol.version}`
          }));
          setProtocolList(preparedProtocolList);
        },
        () => {
          NotificationManager.error(SOMETHING_WENT_WRONG);
        }
      );
    }
  }, [initialParameters, selectedStudy?.id]);

  useEffect(() => {
    if (
      selectedStudy?.id &&
      selectedProtocol?.id &&
      selectedMappingType?.id === ENCOUNTER_MAPPING &&
      isEmpty(initialParameters)
    ) {
      afterburnerApi.getEpochList(selectedStudy.id, selectedProtocol.id).then(({ data }) => {
        setEpochList(sortBy(data, 'name'));
      });
    }
  }, [initialParameters, selectedMappingType?.id, selectedProtocol?.id, selectedStudy?.id]);

  useEffect(() => {
    if (
      selectedStudy?.id &&
      selectedProtocol?.id &&
      selectedMappingType?.id === ENCOUNTER_MAPPING &&
      selectedEpoch?.id &&
      isEmpty(initialParameters)
    ) {
      afterburnerApi
        .getEncounterMappingNames(selectedStudy.id, selectedProtocol.id, selectedEpoch?.id)
        .then(({ data }) => {
          setMappingNamesList(sortBy(data, 'name'));
        });
    }
  }, [initialParameters, selectedEpoch?.id, selectedMappingType?.id, selectedProtocol?.id, selectedStudy?.id]);

  useEffect(() => {
    if (
      selectedStudy?.id &&
      selectedProtocol?.id &&
      selectedMappingType?.id === IG_MAPPING &&
      isEmpty(initialParameters)
    ) {
      afterburnerApi.getItemGroupMappingNames(selectedStudy.id, selectedProtocol.id).then(({ data }) => {
        setMappingNamesList(sortBy(data, 'name'));
      });
    }
  }, [initialParameters, selectedMappingType?.id, selectedProtocol?.id, selectedStudy?.id]);

  useEffect(() => {
    if (
      (selectedPlatform?.value && selectedEndpoint?.value && selectedProtocol?.id && selectedMapping?.id) ||
      templateMode
    ) {
      resolveApiForFetchConfiguration()
        .then(({ data: { generalFieldMappings, itemGroupMappings, encounterConfiguration, name } }) => {
          const initialGeneralMapping = prepareGeneralMapping(generalFieldMappings);
          const initialItemGroupMapping = itemGroupMappings
            .filter(mapping => {
              const fieldsWishMapping = mapping.protocolItemDefinitions.filter(field => field.mappingKeyValue);
              return !isEmpty(fieldsWishMapping);
            })
            .map(mapping =>
              mapping.protocolItemDefinitions
                .filter(field => field.mappingKeyValue)
                .map(element => ({
                  ...element,
                  itemGroupName: mapping.itemGroupName,
                  key: uuid()
                }))
            )
            .flat();
          const notEmptyGeneralMappings = initialGeneralMapping
            .filter(mapping => !isEmpty(mapping.fields))
            .map(element => element.groupType);
          const initialListOfCollapsedGroups = generateListOfCollapsedGroups(
            generalFieldMappings,
            isEmpty(initialItemGroupMapping) ? notEmptyGeneralMappings : [...notEmptyGeneralMappings, 'Item Groups']
          );
          const fullListOfItemGroups = itemGroupMappings.map(mapping => ({
            name: mapping.itemGroupName,
            value: mapping.itemGroupName
          }));
          const initialTriggerItemGroups = encounterConfiguration?.triggerItemGroups.map(itemGroup => ({
            id: itemGroup.protocolDomainMasterId,
            name: itemGroup.title
          }));
          const initialSelectedTriggerItemGroups = encounterConfiguration?.triggerItemGroups
            .filter(item => item.selected)
            .map(itemGroup => ({
              id: selectedStudy?.groupAssign ? itemGroup.title : itemGroup.protocolDomainMasterId,
              name: itemGroup.title,
              groupAssign: selectedStudy?.groupAssign
            }));
          setListOfTriggerItemGroups(sortBy(initialTriggerItemGroups, 'name'));
          setSelectedTriggerItemGroups(initialSelectedTriggerItemGroups);
          setInitialItemGroupMappings(itemGroupMappings);
          setGeneralFieldMapping(initialGeneralMapping);
          setItemGroupsMapping(sortFieldsByName(sortFieldsByName(initialItemGroupMapping, 'name'), 'itemGroupName'));
          setListOfItemGroups(fullListOfItemGroups);
          setListOfCollapsedGroup(initialListOfCollapsedGroups);
          setGeneralGroupAfterFetch(generalFieldMappings);
          if (!isEmpty(encounterConfiguration)) {
            setCurrentEncounterConfiguration(encounterConfiguration);
            setSendPartialPayload(encounterConfiguration.sendPartialPayload);
            setProtocolEncounterConfigurationEnabled(encounterConfiguration.protocolEncounterConfigurationEnabled);
          }
          if (templateMode) {
            setTemplateName(name);
          }
        })
        .catch(() => {
          NotificationManager.error(SOMETHING_WENT_WRONG);
        });
      setListOfInvalidGeneralMappings([]);
      setListOfInvalidFieldMappings([]);
    }
  }, [
    resolveApiForFetchConfiguration,
    selectedEndpoint,
    selectedMapping?.id,
    selectedPlatform,
    selectedProtocol,
    selectedStudy?.groupAssign,
    templateMode
  ]);

  useEffect(() => {
    setListOfCollapsedGroup(prevState =>
      prevState.map(element => {
        const mappingsForCurrentCollapse = generalFieldMapping.filter(mapping => mapping.groupType === element.name);
        const count =
          element.name === 'Item Groups'
            ? itemGroupsMapping.length || 0
            : mappingsForCurrentCollapse[0]?.fields.length || 0;
        return { ...element, count };
      })
    );
  }, [generalFieldMapping, itemGroupsMapping.length, listOfCollapsedGroups.length]);

  const addGeneralMappingRow = useCallback(
    (groupIdForAdd, fieldType) => {
      const updatedGeneralMapping = generalFieldMapping.map((group, index) => {
        if (groupIdForAdd === index) {
          return {
            ...group,
            fields: [
              ...group.fields,
              {
                fieldName: null,
                name: null,
                mappingKeyValue: null,
                protocolItemIdentifier: null,
                key: uuid(),
                createdBy: null,
                mappingCreateDate: null,
                fieldType,
                id: null,
                alwaysSent: false
              }
            ]
          };
        }
        return group;
      });
      setGeneralFieldMapping(updatedGeneralMapping);
    },
    [generalFieldMapping]
  );

  const addItemGroupMappingRow = useCallback(() => {
    setItemGroupsMapping([
      ...itemGroupsMapping,
      {
        itemGroupName: null,
        mappingKeyValue: null,
        mappingType: 'String',
        protocolItemIdentifier: null,
        itemGroupKey: null,
        key: uuid(),
        createdBy: null,
        mappingCreateDate: null,
        id: null
      }
    ]);
  }, [itemGroupsMapping]);

  const deleteGeneralMappingRow = useCallback((groupIndexForDelete, itemIndexForDelete) => {
    setGeneralFieldMapping(prevState => {
      return prevState.map((group, groupIndex) => {
        if (groupIndex === groupIndexForDelete) {
          return { ...group, fields: group.fields.filter((item, index) => index !== itemIndexForDelete) };
        }
        return group;
      });
    });
    setListOfInvalidGeneralMappings([]);
  }, []);

  const deleteFieldMappingRow = useCallback(
    indexForDelete => {
      const updatedMapping = itemGroupsMapping.filter((item, index) => index !== indexForDelete);
      setItemGroupsMapping(updatedMapping);
      setListOfInvalidFieldMappings([]);
    },
    [itemGroupsMapping]
  );

  const changeGeneralMappingName = useCallback(
    (newFieldName, newName, groupIndex, indexForUpdate) => {
      const updatedGeneralMapping = generalFieldMapping.map((mapping, index) => {
        if (index === groupIndex) {
          return {
            ...mapping,
            fields: mapping.fields.map((field, fieldIndex) => ({
              ...field,
              fieldName: fieldIndex === indexForUpdate ? newFieldName : field.fieldName,
              name: fieldIndex === indexForUpdate ? newName : field.name
            }))
          };
        }
        return mapping;
      });
      setGeneralFieldMapping(updatedGeneralMapping);
    },
    [generalFieldMapping]
  );

  const changeItemGroupMappingName = useCallback((newMappingName, indexForUpdate) => {
    setItemGroupsMapping(prevState => {
      return prevState.map((mapping, index) => {
        if (index === indexForUpdate) {
          return { ...mapping, itemGroupName: newMappingName };
        }
        return mapping;
      });
    });
  }, []);

  const changeGeneralFieldMappingKey = useCallback(
    (newMappingKeyValue, groupIndex, indexForUpdate) => {
      const updatedGeneralMapping = generalFieldMapping.map((mapping, index) => {
        if (index === groupIndex) {
          return {
            ...mapping,
            fields: mapping.fields.map((field, fieldIndex) => ({
              ...field,
              mappingKeyValue: fieldIndex === indexForUpdate ? newMappingKeyValue : field.mappingKeyValue
            }))
          };
        }
        return mapping;
      });
      setGeneralFieldMapping(updatedGeneralMapping);
    },
    [generalFieldMapping]
  );

  const changeGeneralFieldAlwaysSent = useCallback(
    (newValue, groupIndex, indexForUpdate) => {
      const updatedGeneralMapping = generalFieldMapping.map((mapping, index) => {
        if (index === groupIndex) {
          return {
            ...mapping,
            fields: mapping.fields.map((field, fieldIndex) => ({
              ...field,
              alwaysSent: fieldIndex === indexForUpdate ? newValue : field.alwaysSent
            }))
          };
        }
        return mapping;
      });
      setGeneralFieldMapping(updatedGeneralMapping);
    },
    [generalFieldMapping]
  );

  const changeItemGroupFieldName = useCallback((newMappingName, indexForUpdate, itemGroupKey) => {
    setItemGroupsMapping(prevState => {
      return prevState.map((mapping, index) => {
        if (index === indexForUpdate) {
          return { ...mapping, protocolItemIdentifier: newMappingName, itemGroupKey: itemGroupKey };
        }
        return mapping;
      });
    });
  }, []);

  const changeReceivingPlatformFieldNameItemGroupMapping = useCallback(
    (newFieldValue, indexForUpdate) => {
      const updatedItemGroupMapping = itemGroupsMapping.map((mapping, index) => {
        if (index === indexForUpdate) {
          return { ...mapping, mappingKeyValue: newFieldValue };
        }
        return mapping;
      });
      setItemGroupsMapping(updatedItemGroupMapping);
    },
    [itemGroupsMapping]
  );

  const changeItemGroupMappingType = useCallback(
    (newMappingType, indexForUpdate) => {
      const updatedItemGroupMapping = itemGroupsMapping.map((mapping, index) => {
        if (index === indexForUpdate) {
          return { ...mapping, mappingType: newMappingType };
        }
        return mapping;
      });
      setItemGroupsMapping(updatedItemGroupMapping);
    },
    [itemGroupsMapping]
  );

  const updateEndpointList = useCallback((platformId, endpointId = null, fullPlatformResponse) => {
    const selectedPlatform = fullPlatformResponse.filter(platform => platform.id === platformId);
    const newEndpointList = selectedPlatform[0].platformUrlsConfiguration.map(endpoint => ({
      name: endpoint.url,
      value: endpoint.id
    }));
    setEndpointsList(newEndpointList);
    if (isNull(endpointId)) {
      setSelectedEndpoint(null);
    } else {
      const newSelectedEndpoint = newEndpointList.filter(endpoint => endpoint.value === endpointId);
      setSelectedEndpoint(newSelectedEndpoint[0]);
    }
  }, []);

  const resetMappingsAndCollapseGroup = useCallback(() => {
    setGeneralFieldMapping([]);
    setItemGroupsMapping([]);
    setListOfCollapsedGroup([]);
  }, []);

  const resetSelectedMappingMappingsAndCollapsedGroup = useCallback(() => {
    setMappingNamesList([]);
    setSelectedMapping(null);
    resetMappingsAndCollapseGroup();
  }, [resetMappingsAndCollapseGroup]);

  const onChangeReceivingPlatform = useCallback(
    newValue => {
      if (!isEqual(selectedPlatform, newValue)) {
        if (!newValue) {
          setSelectedPlatform(null);
          setEndpointsList([]);
          setSelectedEndpoint(null);
          setSelectedStudy(null);
          setProtocolList([]);
          setSelectedProtocol(null);
          setSelectedMappingType(null);
          setEpochList([]);
          setSelectedEpoch(null);
          resetSelectedMappingMappingsAndCollapsedGroup();
        } else {
          setSelectedPlatform(newValue);
          updateEndpointList(newValue.value, null, fullPlatformResponse);
        }
      }
    },
    [fullPlatformResponse, resetSelectedMappingMappingsAndCollapsedGroup, selectedPlatform, updateEndpointList]
  );

  const onChangePlatformEndpoint = useCallback(
    newValue => {
      if (!isEqual(selectedEndpoint, newValue)) {
        if (!newValue) {
          setSelectedEndpoint(null);
          setSelectedStudy(null);
          setProtocolList([]);
          setSelectedProtocol(null);
          setSelectedMappingType(null);
          setEpochList([]);
          setSelectedEpoch(null);
          resetSelectedMappingMappingsAndCollapsedGroup();
        } else {
          setSelectedEndpoint(newValue);
        }
      }
    },
    [resetSelectedMappingMappingsAndCollapsedGroup, selectedEndpoint]
  );

  const onChangeStudyName = useCallback(
    newValue => {
      const resetAllFieldsUnder = () => {
        setProtocolList([]);
        setSelectedProtocol(null);
        setSelectedMappingType(null);
        setEpochList([]);
        setSelectedEpoch(null);
        resetSelectedMappingMappingsAndCollapsedGroup();
      };
      if (!isEqual(newValue, selectedStudy)) {
        if (!newValue) {
          setSelectedStudy(null);
          resetAllFieldsUnder();
        } else {
          setSelectedStudy(newValue);
          resetAllFieldsUnder();
        }
      }
    },
    [resetSelectedMappingMappingsAndCollapsedGroup, selectedStudy]
  );

  const onChangeProtocolVersion = useCallback(
    newValue => {
      if (!isEqual(newValue, selectedProtocol)) {
        const resetAllFieldsUnder = () => {
          setSelectedMappingType(null);
          setEpochList([]);
          setSelectedEpoch(null);
          resetSelectedMappingMappingsAndCollapsedGroup();
        };
        if (!newValue) {
          setSelectedProtocol(null);
          resetAllFieldsUnder();
        } else {
          setSelectedProtocol(newValue);
          resetAllFieldsUnder();
        }
      }
    },
    [resetSelectedMappingMappingsAndCollapsedGroup, selectedProtocol]
  );

  const onChangeMappingType = useCallback(
    newValue => {
      const resetAllFieldsUnder = () => {
        setEpochList([]);
        setSelectedEpoch(null);
        resetSelectedMappingMappingsAndCollapsedGroup();
        setSendPartialPayload(false);
      };
      if (!isEqual(newValue, selectedMappingType)) {
        if (!newValue) {
          setSelectedMappingType(null);
          resetAllFieldsUnder();
        } else {
          setSelectedMappingType(newValue);
          resetAllFieldsUnder();
        }
      }
    },
    [resetSelectedMappingMappingsAndCollapsedGroup, selectedMappingType]
  );

  const onChangeEncounter = useCallback(
    newValue => {
      if (!isEqual(newValue, selectedEpoch)) {
        if (!newValue) {
          setSelectedEpoch(null);
          resetSelectedMappingMappingsAndCollapsedGroup();
        } else {
          setSelectedEpoch(newValue);
          resetSelectedMappingMappingsAndCollapsedGroup();
        }
      }
    },
    [resetSelectedMappingMappingsAndCollapsedGroup, selectedEpoch]
  );

  const onChangeMappingName = useCallback(
    newValue => {
      if (!isEqual(newValue, selectedMapping)) {
        if (!newValue) {
          setSelectedMapping(null);
          resetMappingsAndCollapseGroup();
        } else {
          setSelectedMapping(newValue);
          resetMappingsAndCollapseGroup();
        }
      }
    },
    [resetMappingsAndCollapseGroup, selectedMapping]
  );

  const removeGeneralMappingFromInvalidList = useCallback(
    (groupName, indexForUnmarked, name) => {
      const updatedList = listOfInvalidGeneralMappings.filter(
        item =>
          !(
            item.invalidIndex === indexForUnmarked &&
            name.includes(item.invalidItem) &&
            item.invalidGroup === groupName
          )
      );
      setListOfInvalidGeneralMappings(updatedList);
    },
    [listOfInvalidGeneralMappings]
  );

  const removeItemGroupMappingFromInvalidList = useCallback(
    (indexForUnmarked, name) => {
      const updatedList = listOfInvalidFieldMappings.filter(
        item => !(item.invalidIndex === indexForUnmarked && item.invalidItem === name)
      );
      setListOfInvalidFieldMappings(updatedList);
    },
    [listOfInvalidFieldMappings]
  );

  const addTemplatesToMappings = useCallback(
    (itemGroupTemplates, generalTemplates) => {
      let mergedGeneralFieldMapping = [...generalFieldMapping];
      generalTemplates.forEach(element => {
        mergedGeneralFieldMapping = mergedGeneralFieldMapping.map(group =>
          group.groupType === element.fieldType ? { ...group, fields: [...group.fields, element] } : group
        );
      });
      const mergedItemGroupMapping = [...itemGroupsMapping, ...itemGroupTemplates];

      setItemGroupsMapping(mergedItemGroupMapping);
      setGeneralFieldMapping(mergedGeneralFieldMapping);
      setListOfCollapsedGroup(prevState =>
        prevState.map(element => {
          const mappingsForCurrentCollapse = mergedGeneralFieldMapping.filter(
            mapping => mapping.groupType === element.name
          );
          const collapsed =
            element.name === 'Item Groups'
              ? !isEmpty(mergedItemGroupMapping)
              : !isEmpty(mappingsForCurrentCollapse[0]?.fields);
          return { ...element, collapsed };
        })
      );
    },
    [generalFieldMapping, itemGroupsMapping]
  );

  const toggleCollapse = groupName => {
    setListOfCollapsedGroup(prevState => {
      return prevState.map(item => ({
        ...item,
        collapsed: item.name === groupName ? !item.collapsed : item.collapsed
      }));
    });
  };

  const listOfSelectedItemGroupMappingFields = useMemo(
    () =>
      itemGroupsMapping.map(mapping => ({
        itemGroupName: mapping.itemGroupName,
        protocolItemIdentifier: mapping.protocolItemIdentifier,
        itemGroupKey: mapping.itemGroupKey
      })),
    [itemGroupsMapping]
  );

  const maximalCountOfFieldConfigurations = useMemo(() => {
    return initialItemGroupMappings.flatMap(item => item.protocolItemDefinitions).length;
  }, [initialItemGroupMappings]);

  const mappingForFieldsExist = useMemo(() => {
    return [...itemGroupsMapping];
  }, [itemGroupsMapping]);

  const mappingForGeneralExist = useMemo(
    () =>
      generalFieldMapping
        .filter(el => !isEmpty(el.fields))
        .flatMap(mapping => {
          return [...mapping.fields];
        }),
    [generalFieldMapping]
  );

  const itemGroupCollapsed = useMemo(() => {
    return listOfCollapsedGroups.find(item => item.name === 'Item Groups');
  }, [listOfCollapsedGroups]);

  const dataForPreview = useMemo(() => {
    const mappings = itemGroupsMapping.map(mapping => {
      return {
        answerValue: 'INT_ELLIGO_PLACEHOLDER',
        mappingKeyValue: mapping.mappingKeyValue?.trim(),
        mappingType: mapping.mappingType,
        protocolItemIdentifier: mapping.protocolItemIdentifier,
        itemGroupKey: mapping.itemGroupKey,
        id: mapping.id
      };
    });

    const generalMappings = generalFieldMapping
      .filter(element => element.fields.length)
      .flatMap(mapping => {
        return mapping.fields.map(field => {
          return {
            answerValue: 'INT_ELLIGO_PLACEHOLDER',
            mappingKeyValue: field.mappingKeyValue?.trim()
          };
        });
      });

    return {
      mappings: [...mappings, ...generalMappings],
      validationGeneralMappings: prepareGeneralFields(generalFieldMapping),
      validationItemGroupsMappings: mappings
    };
  }, [generalFieldMapping, itemGroupsMapping]);

  const onSave = () => {
    const preparedItemGroupMapping = itemGroupsMapping.map(mapping => {
      let mappingForSave = {
        mappingKeyValue: mapping.mappingKeyValue?.trim(),
        protocolItemIdentifier: mapping.protocolItemIdentifier,
        mappingType: mapping.mappingType,
        itemGroupKey: mapping.itemGroupKey,
        id: mapping.id
      };
      if (mapping.createdBy && mapping.mappingCreateDate) {
        mappingForSave = {
          ...mappingForSave,
          createdBy: mapping.createdBy,
          mappingCreateDate: mapping.mappingCreateDate
        };
      }
      return mappingForSave;
    });

    const preparedGeneralMapping = prepareGeneralFields(generalFieldMapping);

    const preparedTriggerItemGroups = selectedStudy?.groupAssign
      ? listOfTriggers?.map(item => ({
          title: item.name,
          selected: some(selectedTriggerItemGroups, itemGroup => itemGroup.id === item.id)
        }))
      : listOfTriggerItemGroups?.map(item => ({
          protocolDomainMasterId: item.id,
          title: item.name,
          selected: some(selectedTriggerItemGroups, itemGroup => itemGroup.id === item.id)
        }));

    let dataForSave;

    if (templateMode) {
      dataForSave = {
        templateId,
        name: templateName?.trim(),
        protocolVersionId: initialParameters?.protocol?.id ?? null,
        itemGroupMappings: preparedItemGroupMapping.map(mapping => ({
          protocolItemDefinitionKey: mapping.protocolItemIdentifier,
          mappingKeyValue: mapping.mappingKeyValue,
          mappingType: mapping.mappingType,
          id: mapping.id
        })),
        generalFields: preparedGeneralMapping
      };
    } else {
      dataForSave = {
        generalFieldMappings: preparedGeneralMapping,
        itemGroupMappings: [{ protocolItemDefinitions: preparedItemGroupMapping }],
        encounterConfiguration: {
          id: currentEncounterConfiguration?.id ?? null,
          platformConfiguration: {
            id: selectedPlatform?.value ?? null
          },
          platformUrlConfiguration: {
            id: selectedEndpoint?.value ?? null
          },
          protocolVersionId: selectedProtocol.id,
          protocolEncounterKey: selectedMappingType?.id === ENCOUNTER_MAPPING ? selectedMapping.id : null,
          itemGroupKey: selectedMappingType?.id === IG_MAPPING ? selectedMapping.id : null,
          sendPartialPayload,
          protocolEncounterConfigurationEnabled: protocolEncounterConfigurationEnabled,
          triggerItemGroups: preparedTriggerItemGroups
        }
      };
    }

    if (sendPartialPayload && !validateAlwaysSentMapping(preparedGeneralMapping)) {
      return NotificationManager.error(REQUIRED_ATTRIBUTE_MISSING);
    }

    if (validateMappingInput(preparedGeneralMapping, preparedItemGroupMapping)) {
      if (templateMode) {
        if (!dataForSave?.name) {
          return NotificationManager.error('Template name is empty');
        }
        if (initialParameters.notAvailableNamesForTemplate.includes(dataForSave.name)) {
          return NotificationManager.error('This name is already in use');
        }
      }

      resolveApiForSaveConfiguration(dataForSave)
        .then(() => {
          NotificationManager.success(DATA_SAVED);
          modalBox.close();
          if (isFunction(updateTable)) {
            updateTable();
          }
        })
        .catch(() => {
          NotificationManager.error(SOMETHING_WENT_WRONG);
        });
    }
  };

  const validateMappingInput = useCallback((preparedGeneralMapping, preparedItemGroupMapping) => {
    const isGeneralMappingHawInvalid = validateGeneralFields(preparedGeneralMapping);
    const isItemGroupMappingHawInvalid = validateMapping(preparedItemGroupMapping);
    if (isGeneralMappingHawInvalid.length || isItemGroupMappingHawInvalid.length) {
      setListOfInvalidGeneralMappings(isGeneralMappingHawInvalid);
      setListOfInvalidFieldMappings(isItemGroupMappingHawInvalid);
      NotificationManager.error(SOME_OF_FIELDS_ARE_EMPTY);
      return false;
    } else {
      return true;
    }
  }, []);

  const addTemplateToMapping = useCallback(
    () =>
      openTemplateModal(
        addTemplatesToMappings,
        mappingForFieldsExist,
        mappingForGeneralExist,
        selectedMappingType?.id === ENCOUNTER_MAPPING ? selectedMapping.id : null,
        selectedMappingType?.id === IG_MAPPING ? selectedMapping.id : null,
        selectedProtocol?.id
      ),
    [
      addTemplatesToMappings,
      mappingForFieldsExist,
      mappingForGeneralExist,
      selectedMappingType?.id,
      selectedMapping?.id,
      selectedProtocol?.id
    ]
  );

  return (
    <MappingSetupContext.Provider
      value={{
        selectedPlatform,
        listOfPlatforms,
        onChangeReceivingPlatform,
        selectedEndpoint,
        templateMode,
        templateName,
        setTemplateName,
        listOfCollapsedGroups,
        listOfTriggerItemGroups: selectedStudy?.groupAssign ? listOfTriggers : listOfTriggerItemGroups,
        selectedTriggerItemGroups,
        setSelectedTriggerItemGroups,
        endpointsList,
        toggleCollapse,
        listOfItemGroups,
        itemGroupCollapsed,
        addItemGroupMappingRow,
        itemGroupsMapping,
        maximalCountOfFieldConfigurations,
        modalBox,
        dataForPreview,
        onSave,
        generalFieldMapping,
        generalGroupAfterFetch,
        listOfInvalidGeneralMappings,
        deleteGeneralMappingRow,
        addGeneralMappingRow,
        listOfInvalidFieldMappings,
        deleteFieldMappingRow,
        changeItemGroupMappingName,
        initialItemGroupMappings,
        changeItemGroupFieldName,
        changeReceivingPlatformFieldNameItemGroupMapping,
        changeItemGroupMappingType,
        listOfSelectedItemGroupMappingFields,
        removeItemGroupMappingFromInvalidList,
        setSendPartialPayload,
        sendPartialPayload,
        setProtocolEncounterConfigurationEnabled,
        protocolEncounterConfigurationEnabled,
        addTemplateToMapping,
        changeGeneralMappingName,
        removeGeneralMappingFromInvalidList,
        changeGeneralFieldMappingKey,
        validateMappingInput,
        changeGeneralFieldAlwaysSent,
        studyList,
        selectedStudy,
        protocolList,
        selectedProtocol,
        selectedMappingType,
        epochList,
        selectedEpoch,
        mappingNamesList,
        selectedMapping,
        initialParameters,
        onChangePlatformEndpoint,
        onChangeStudyName,
        onChangeProtocolVersion,
        onChangeMappingType,
        onChangeEncounter,
        onChangeMappingName
      }}
    >
      {children}
    </MappingSetupContext.Provider>
  );
};
