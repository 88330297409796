import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import Icon from '../../../../../../../common/general/Icon';
import { scGray60, scPurple80 } from '../../../../../../../constants/systemColors';
import { getUrlToEncounterDetailsPage } from '../../WorklistService';

import { WorklistEncounterFilter } from './WorklistEncounterFilter';

export const EncounterFilterColumn = (context, encounterProvider, defaultFilters, statusProvider, worklistName) => {
  return {
    Header: (
      <CustomEncounterSortAndFilterHeader
        title={'Encounter'}
        Context={context}
        encounterProvider={encounterProvider}
        defaultFilters={defaultFilters}
        statusProvider={statusProvider}
      />
    ),
    accessor: 'encounterName',
    minWidth: 60,
    Cell: ({ value, original }) => (
      <Link
        onClick={function(e) {
          e.nativeEvent.stopImmediatePropagation();
        }}
        to={getUrlToEncounterDetailsPage(original, worklistName)}
      >
        {value}
      </Link>
    )
  };
};

export const CustomEncounterSortAndFilterHeader = ({
  title,
  Context,
  encounterProvider,
  statusProvider,
  defaultFilters
}) => {
  const {
    EncounterWidgetContext: { currentlySelected },
    encounterNames,
    totalEncounters
  } = useContext(Context);
  const [isFilterVisible, setFilterVisible] = useState(false);
  const ref = React.useRef();
  return (
    <div className="rt-hoc-sort-header" ref={ref}>
      <WorklistEncounterFilter
        filterRef={ref}
        Context={useContext(Context)}
        encounterProvider={encounterProvider}
        statusesProvider={statusProvider}
        defaultFilters={defaultFilters}
        setFilterVisible={setFilterVisible}
        isFilterVisible={isFilterVisible}
      />
      <Icon
        className={'filter-icon'}
        style={{
          color: encounterNames.length > 0 && encounterNames.length < totalEncounters.length ? scPurple80 : scGray60
        }}
        onClick={e => {
          e.stopPropagation();
          currentlySelected && setFilterVisible(!isFilterVisible);
        }}
      >
        filter_alt
      </Icon>
      <div className="title">{title}</div>
      <div className="icons">
        <Icon>expand_less</Icon>
        <Icon>expand_more</Icon>
      </div>
    </div>
  );
};
