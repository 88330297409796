import { GroupAssignApi, StudyApi } from '../api';
import { ROLE_SYSTEM_ADMINISTRATOR } from '../constants/userRoles';

import { userHasRole } from './auth';

export default function resolveStudyGroupAssignAndGroupAssignGroups(currentStudyId) {
  return new Promise((resolve, reject) => {
    StudyApi.isStudyGroupAssign(currentStudyId)
      .then(flag => {
        if (userHasRole(ROLE_SYSTEM_ADMINISTRATOR) && flag.data) {
          GroupAssignApi.getInvestigatorGroupAssigns(currentStudyId)
            .then(groups => {
              resolve({
                isGroupAssign: flag.data,
                groups: groups.data
              });
            })
            .catch(error => reject(error));
        } else {
          resolve({
            isGroupAssign: flag.data,
            groups: []
          });
        }
      })
      .catch(error => reject(error));
  });
}
