import React, { useMemo } from 'react';
import { uniqBy } from 'lodash/array';
import { orderBy } from 'lodash/collection';

import Select from '../../../../../../../../common/data-entry/Select';

export default function TaskStudySiteSelect({
  allStudySites,
  selectedStudyId,
  selectedSiteId,
  selectedStudySiteId,
  taskFormValidationMap,
  validate,
  onChange
}) {
  const [filteredStudies, filteredSites, selectedStudy, selectedSite] = useMemo(
    function() {
      const [filteredStudies, filteredSites] = getFilteredStudiesAndSites(
        allStudySites,
        selectedStudyId,
        selectedSiteId
      );
      return [
        filteredStudies,
        filteredSites,
        filteredStudies.find(study => study.id === selectedStudyId),
        filteredSites.find(site => site.id === selectedSiteId)
      ];
    },
    [allStudySites, selectedStudyId, selectedSiteId]
  );

  return (
    <>
      <div className="etc-body-field-wrapper">
        <Select
          label="Study"
          dataSource={filteredStudies}
          onChange={function(study) {
            if (selectedStudyId === study?.id) return;
            onChange(
              supposeStudySiteId(
                supposeSite({
                  study: study || null,
                  site: selectedSite || null
                })
              )
            );
          }}
          optionLabelKey="name"
          optionValueKey="id"
          value={selectedStudy}
          searchable
          clearSearchOnSelection
          validationMessage={!taskFormValidationMap.studyId ? 'Study is required' : null}
          validate={validate}
        />
      </div>
      <div className="etc-body-field-wrapper">
        <Select
          label="Site"
          dataSource={filteredSites}
          onChange={function(site) {
            if (selectedSiteId === site?.id) return;
            onChange(
              supposeStudySiteId(
                supposeStudy({
                  study: selectedStudy || null,
                  site: site || null
                })
              )
            );
          }}
          optionLabelKey="name"
          optionValueKey="id"
          value={selectedSite}
          searchable
          clearSearchOnSelection
          validationMessage={!taskFormValidationMap.siteId ? 'Site is required' : null}
          validate={validate}
        />
      </div>
    </>
  );

  function supposeSite(state) {
    const { study, site } = state;
    if (site || !study) return state;
    const supposedSite = getSiteByStudyIfItIsTheOnlyOne(allStudySites, study.id);
    return { ...state, site: supposedSite };
  }

  function supposeStudy(state) {
    const { study, site } = state;
    if (study || !site) return state;
    const supposedStudy = getStudyBySiteIfItIsTheOnlyOne(allStudySites, site.id);
    return { ...state, study: supposedStudy };
  }

  function supposeStudySiteId(state) {
    const { study, site } = state;
    const ssuId =
      study?.id && site?.id && !!allStudySites.length
        ? allStudySites.find(studySite => studySite.study.id === study?.id && studySite.site.id === site?.id)?.id
        : null;
    if (selectedStudySiteId === ssuId) return state;
    return { ...state, studySiteId: ssuId };
  }
}

function getSiteByStudyIfItIsTheOnlyOne(studySites, studyId) {
  const [, filteredSites] = getFilteredStudiesAndSites(studySites, studyId, null);
  return filteredSites.length === 1 ? filteredSites[0] : null;
}

function getStudyBySiteIfItIsTheOnlyOne(studySites, siteId) {
  const [filteredStudies] = getFilteredStudiesAndSites(studySites, null, siteId);
  return filteredStudies.length === 1 ? filteredStudies[0] : null;
}

function getFilteredStudiesAndSites(studySites, studyId, siteId) {
  const [studies, sites] = studySites.reduce(
    function(accumulator, { study, site }) {
      const [studies, sites] = accumulator;
      if (studyId ? study.id === studyId : true) {
        sites.push(site);
      }
      if (siteId ? site.id === siteId : true) {
        studies.push(study);
      }
      return accumulator;
    },
    [[], []]
  );

  return [orderBy(uniqBy(studies, 'id'), 'name', 'asc'), orderBy(uniqBy(sites, 'id'), 'name', 'asc')];
}
