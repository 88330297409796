import React from 'react';

import { Amount } from '../../../../../shared/amount-view/Amount/Amount';
import { styles } from '../../EncounterInvoiceTemplateStyles';

export const Balance = ({ item }) => {
  return (
    <div style={styles.balance} className="row-item" data-testid="ledger-event-item">
      <div style={styles.balance.header}>
        <div style={styles.balance.header.comment}>{item.comment}</div>
        <div style={styles.balance.header.subtotal}>
          Subtotal
          <div style={styles.amountWrapper}>
            <Amount coinsAmount={item.balanceDue} showDollarSign={true} />
          </div>
        </div>
      </div>
      <div style={styles.balance.calculationWrapper}>
        <div style={styles.balance.calculationWrapper.calculation}>
          <div style={styles.balance.calculationWrapper.calculation.subtotal}>
            Subtotal
            <div style={styles.amountWrapper}>
              <Amount coinsAmount={item.balanceDue} showDollarSign={true} />
            </div>
          </div>
          <div style={styles.balance.calculationWrapper.calculation.subtotal}>
            Less Payments
            <div style={styles.amountWrapper}>
              <Amount coinsAmount={0} showDollarSign={true} />
            </div>
          </div>
          <div style={styles.balance.calculationWrapper.calculation.balanceDue}>
            Balance Due
            <div style={styles.amountWrapper}>
              <Amount coinsAmount={item.balanceDue} showDollarSign={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
