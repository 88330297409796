import axios from 'axios';
import jstz from 'jstz';

import store from '../store';

import NewRelic from './NewRelic';

const timezone = jstz.determine();

axios.interceptors.request.use(function(config) {
  const { activeRole } = store.getState().currentUser;
  if (activeRole) {
    config.headers['X-Active-Role'] = activeRole;
  }
  config.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  config.headers.common['Client-Timezone'] = timezone.name();
  return config;
});

const addAccessTokenInterceptor = getAccessToken => {
  axios.interceptors.request.use(async config => {
    const token = await getAccessToken();
    if (config?.headers?.common) {
      config.headers.common.Authorization = `Bearer ${token}`;
    }
    NewRelic.start();
    return config;
  });
};

axios.interceptors.response.use(
  async response => {
    NewRelic.end();
    return response;
  },
  function(error) {
    NewRelic.end();
    NewRelic.noticeError(error);
    throw error;
  }
);

export { addAccessTokenInterceptor };
