import React, { useState } from 'react';

import Radio from '../../common/data-entry/InputSelectors/Radio';
import ModalBoxes from '../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../common/general/Button';
import ButtonGroup from '../../common/general/ButtonGroup';
import { getTittleByRole } from '../../services/personnelService';

import './ConfirmRoleSwitchModal.scss';

ConfirmRoleSwitchModal.className = 'role-switcher-confirm';
ConfirmRoleSwitchModal.size = 'w650';

export default function ConfirmRoleSwitchModal({ modalBox, roles, selectRole }) {
  const [selectedRole, setSelectedRole] = useState(null);

  return (
    <>
      <ModalBoxes.Header>Confirm your role selection for this study and site</ModalBoxes.Header>
      <ModalBoxes.Body>
        <Radio.Group
          name="role-switcher-confirm-modal-radio-button"
          checkedValue={selectedRole}
          options={roles.map(role => {
            return {
              id: role,
              name: getTittleByRole(role)
            };
          })}
          onChange={item => setSelectedRole(item?.target.value)}
          optionLabelKey="name"
          optionValueKey="id"
        />
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority={'medium'} onClick={modalBox.close}>
            CANCEL
          </Button>
          <Button
            onClick={function() {
              selectRole(selectedRole);
            }}
            disabled={!selectedRole}
          >
            CONFIRM ROLE
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
}
