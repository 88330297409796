import axios from 'axios';

const ROOT = `api/v1/ssu-patient`;

const UserMediaApi = {
  downloadMedia(fileUniqueIdentifier, ssuPatientId, hideLoader = false) {
    return axios({
      url: `${ROOT}/${ssuPatientId}/media/${fileUniqueIdentifier}/download`,
      method: 'GET',
      responseType: 'blob',
      hideLoader
    });
  },
  deleteMedia(ssuPatientId, fileUniqueIdentifier) {
    return axios.post(`${ROOT}/${ssuPatientId}/media/${fileUniqueIdentifier}/delete`);
  }
};

export default UserMediaApi;
