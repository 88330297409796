import React from 'react';
import { useDispatch, useStore } from 'react-redux';
import { Menu, MenuItem } from '@szhsin/react-menu';
import cx from 'classnames';
import { get } from 'lodash/object';
import moment from 'moment';

import { setUserPreferences } from '../../../../../actions/userPreferencesAction';
import Select from '../../../../../common/data-entry/Select';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';
import Icon from '../../../../../common/general/Icon';

import './Toolbar.scss';

const viewOptions = { day: 'Day', week: 'Week', work_week: 'Work Week', agenda: 'List' };
function getDateLabel(start, end, showDay) {
  const isSameYear = start.isSame(end, 'year');
  const isSameMonth = start.isSame(end, 'month');
  const day = 'DD';
  const month = 'MMM';
  const year = 'YYYY';

  if (!end) {
    return `${start.format(`${day} ${month} ${year}`)}`;
  }
  if (isSameYear && isSameMonth) {
    return showDay
      ? `${start.format(`${month} ${day}`)} - ${end.format(`${day}, ${year}`)}`
      : `${start.format(month)} ${end.format(year)}`;
  }
  if (isSameYear && !isSameMonth) {
    return showDay
      ? `${start.format(`${month} ${day}`)} - ${end.format(`${month} ${day}, ${year}`)}`
      : `${start.format(month)} - ${end.format(`${month} ${year}`)}`;
  }
  return showDay
    ? `${start.format(`${month} ${day}, ${year}`)} - ${end.format(`${month} ${day}, ${year}`)}`
    : `${start.format(`${month} ${year}`)} - ${end.format(`${month} ${year}`)}`;
}

export function Toolbar({ e, getTimeZoneName, siteTimeZones, selectedTimeZone, onChangeTimeZone }) {
  const date = moment(e.date);
  const start = date.clone().startOf('week');
  const end = date.clone().endOf('week');
  let label;
  switch (e.view) {
    case 'day':
      label = getDateLabel(date);
      break;
    case 'week':
      label = getDateLabel(start, end, true);
      break;
    case 'work_week':
      label = getDateLabel(start.add(1, 'd'), end.subtract(1, 'd'), true);
      break;
    case 'agenda':
      label = getDateLabel(date, end, true);
      break;
    default:
      break;
  }

  const dispatch = useDispatch();
  const store = useStore().getState();

  const changeView = view => {
    e.onView(view);
    const preferences = get(store, 'userPreferences') || {};
    if (view === 'week' || view === 'work_week') {
      preferences.useWorkWeek = view === 'work_week';
    }
    preferences.useDay = view === 'day';
    preferences.view = view;
    dispatch(setUserPreferences(preferences));
  };

  return (
    <div className="eds-calendar-custom-toolbar">
      <ButtonGroup type="singular">
        <Button priority="medium" size="h28" onClick={() => e.onNavigate('TODAY')}>
          Today
        </Button>
        <Button priority="medium" size="h28" onClick={() => e.onNavigate('PREV')}>
          <Icon>keyboard_arrow_left</Icon>
        </Button>
        <Button priority="medium" size="h28" onClick={() => e.onNavigate('NEXT')}>
          <Icon>keyboard_arrow_right</Icon>
        </Button>
      </ButtonGroup>

      <span className="label mx-4">{label}</span>
      <Menu
        menuButton={
          <Button priority="medium" size="h28" className="menu-button">
            {viewOptions[e.view]}
            <Icon>arrow_drop_down</Icon>
          </Button>
        }
      >
        <MenuItem className={cx({ active: e?.view === 'day' })} onClick={() => changeView('day')}>
          Day
        </MenuItem>
        <MenuItem className={cx({ active: e?.view === 'week' })} onClick={() => changeView('week')}>
          Week
        </MenuItem>
        <MenuItem className={cx({ active: e?.view === 'work_week' })} onClick={() => changeView('work_week')}>
          Work Week
        </MenuItem>
        <MenuItem className={cx({ active: e?.view === 'agenda' })} onClick={() => changeView('agenda')}>
          List
        </MenuItem>
      </Menu>
      <Select
        className="time-zone-dropdown"
        label="Time zone"
        dataSource={siteTimeZones}
        onChange={timeZone => {
          onChangeTimeZone(timeZone);
        }}
        customOptionTemplateFunction={e => (
          <>
            <div className="label">{getTimeZoneName(e)}</div>
            <div className="name">{e.siteName}</div>
          </>
        )}
        customSelectedValueTemplateFunction={e => <span className="r-ss-selected-label">{getTimeZoneName(e[0])}</span>}
        optionLabelKey={'studyName'}
        optionValueKey={'id'}
        value={selectedTimeZone}
        searchable={true}
        clearSearchOnSelection
        clearable={false}
        customFilterFunction={(e, index, collection, searchString) =>
          `${e.siteName} ${e.timeZoneNameToDisplay}`.toLowerCase().includes(searchString)
        }
      />
    </div>
  );
}
