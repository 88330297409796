import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty } from 'lodash/lang';

import ReimbursementWorklistApi from '../../../../api/patient/ReimbursementWorklistApi';
import { useEuiFiltersState } from '../../../eui/EuiFiltersContext/EuiFiltersContext';
import { FRS_READY } from '../../../eui/EuiFiltersContext/reducer';

import useDataGrid from './useDataGrid';

export const ReimbursementWorklistContext = React.createContext(null);

const ReimbursementWorklistProvider = ({ children }) => {
  const onceLoaded = useRef(false);
  const alreadyLoaded = useRef(false);
  const { schema, statuses, ssu, assigned } = useEuiFiltersState();
  const [columns, gridData, setGridData] = useDataGrid();

  const [loading, setLoading] = useState(false);

  const requestObject = useMemo(
    function() {
      if (schema?.status !== FRS_READY || isEmpty(statuses?.value) || isEmpty(ssu) || isEmpty(assigned?.value)) {
        return;
      }
      const { studySites, selectedStudyMap, selectedSiteMap } = ssu;
      const ssuIds = studySites.reduce(function(accumulator, { id, study, site }) {
        if (selectedStudyMap[study.id] && selectedSiteMap[site.id]) {
          accumulator.push(id);
        }
        return accumulator;
      }, []);
      alreadyLoaded.current = false;
      return {
        statuses: statuses.value.map(status => status.id),
        ssuIds,
        assigneeIds: assigned.value.map(a => a.id)
      };
    },
    [schema, statuses, ssu, assigned]
  );

  const loadTableData = useCallback(
    function(request) {
      setLoading(true);
      ReimbursementWorklistApi.getReimbursementsForWorklist(request)
        .then(({ data }) => {
          setGridData(data);
          alreadyLoaded.current = true;
        })
        .finally(() => setLoading(false));
    },
    [setGridData]
  );

  const applyButton = useCallback(
    function() {
      if (requestObject) {
        loadTableData(requestObject);
      }
    },
    [loadTableData, requestObject]
  );

  useEffect(
    function() {
      if (onceLoaded.current || !requestObject) {
        return;
      }
      loadTableData(requestObject);
      onceLoaded.current = true;
    },
    [loadTableData, requestObject]
  );

  return (
    <ReimbursementWorklistContext.Provider
      value={{
        gridData,
        columns,
        loading,
        loadTableData,
        applyButton,
        loadingAllowed: !alreadyLoaded.current && !loading
      }}
    >
      {children}
    </ReimbursementWorklistContext.Provider>
  );
};

export function withReimbursementWorklistContext(Component) {
  return function WrapperComponent(props) {
    return (
      <ReimbursementWorklistProvider>
        <Component {...props} />
      </ReimbursementWorklistProvider>
    );
  };
}
