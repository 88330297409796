import { isArray } from 'lodash/lang';

import { FILE, FILE_TYPES } from '../../../../../../../constants/inputTypes';
import { getWidthOfText } from '../../../../../../../services/fontWidthService';
import { isAnswerPopulated, resolveAnswerNoPopulatedReason } from '../reviewContentService';

const PADDING_FOR_FILE_BUTTON = 100;
const PADDING_FOR_TEXT = 20;
export const MIN_WIDTH_OF_REVIEW_TABLE_COLUMN = 120;
export const MAX_WIDTH_OF_REVIEW_TABLE_COLUMN = 500;

function calculateCellMinWidthBasedOnStringValueAndPadding(stringValue, padding) {
  if (isArray(stringValue)) {
    const values = stringValue.map(a => calculateCellMinWidthBasedOnStringValueAndPadding(a, padding));
    return Math.max(...values);
  } else {
    const width = getWidthOfText(stringValue);
    return width + padding;
  }
}
function resolveAnswerStringValueForColumnWidthCalculation(answer) {
  if (isAnswerPopulated(answer)) {
    if (FILE_TYPES.includes(answer?.inputType)) {
      return answer?.fileValue?.files.map(file => file.name);
    } else {
      return answer?.stringValue;
    }
  } else {
    return resolveAnswerNoPopulatedReason(answer);
  }
}

function fileWidthDefining(stringValues) {
  const widthOfFileContent = calculateCellMinWidthBasedOnStringValueAndPadding(stringValues, PADDING_FOR_FILE_BUTTON);
  if (widthOfFileContent > MAX_WIDTH_OF_REVIEW_TABLE_COLUMN) {
    return MAX_WIDTH_OF_REVIEW_TABLE_COLUMN;
  }
  return widthOfFileContent;
}

function textWidthDefining(stringValue) {
  const widthOfTextContent = calculateCellMinWidthBasedOnStringValueAndPadding(stringValue, PADDING_FOR_TEXT);
  if (widthOfTextContent < MIN_WIDTH_OF_REVIEW_TABLE_COLUMN) {
    return MIN_WIDTH_OF_REVIEW_TABLE_COLUMN;
  } else {
    if (widthOfTextContent > MAX_WIDTH_OF_REVIEW_TABLE_COLUMN) {
      return MAX_WIDTH_OF_REVIEW_TABLE_COLUMN;
    }
  }
  return widthOfTextContent;
}

export function getWidthOfTableColumnByAnswer(answer) {
  const stringValue = resolveAnswerStringValueForColumnWidthCalculation(answer);
  if (answer?.inputType === FILE) {
    return fileWidthDefining(stringValue);
  } else {
    return textWidthDefining(stringValue);
  }
}
