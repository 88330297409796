import React from 'react';
import { get } from 'lodash/object';

import { hasUserAccessToProtectedPatientInfo } from '../CalendarUtils';

export function CancelingDetails({ appointment }) {
  return (
    <div className="appointment-cancellation-content">
      {hasUserAccessToProtectedPatientInfo(appointment) && (
        <React.Fragment>
          <div className="appointment-view-field">
            <div className="primary">{get(appointment, 'cancellationReason.reason')}</div>
          </div>
          <div className={'normal'}>{get(appointment, 'cancellationComments')}</div>
        </React.Fragment>
      )}
    </div>
  );
}
