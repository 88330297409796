import { isNull } from 'lodash/lang';

const oneDecimalPositiveFloat = /^\d+(\.\d?)?$/;

export function normalizePhoneNumber(value) {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, ''),
    cvLength = currentValue.length;
  if (cvLength < 4) return currentValue;
  if (cvLength < 7) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
  return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
}

export function normalizePhoneNumberForCard(value) {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, ''),
    cvLength = currentValue.length;
  if (cvLength < 4) return currentValue;
  if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
}

export function normalizeCardId(value) {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, ''),
    cvLength = currentValue.length;
  if (cvLength <= 13) return currentValue;
  return currentValue.slice(0, 13);
}

export function normalizeSsn(value) {
  if (!value) return value;
  const currentValue = value.replace(/[^x\d]/g, ''),
    cvLength = currentValue.length;
  if (cvLength < 4) return currentValue;
  if (cvLength < 6) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
  return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 5)}-${currentValue.slice(5, 9)}`;
}

export function normalizeSin(value) {
  if (!value) return value;
  const currentValue = value.replace(/[^x\d]/g, ''),
    cvLength = currentValue.length;
  if (cvLength < 4) return currentValue;
  if (cvLength < 7) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
  return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 6)}-${currentValue.slice(6, 9)}`;
}

export function normalizeZipCode(value, isUS = true) {
  if (!value) return value;

  if (!isUS) {
    const currentValue = value.toUpperCase().replace(/[^\dA-Z]/g, ''),
      cvLength = currentValue.length;
    if (cvLength < 3) return currentValue;
    return `${currentValue.slice(0, 3)} ${currentValue.slice(3, 6)}`.trim();
  }

  const currentValue = value.replace(/[^\d]/g, ''),
    cvLength = currentValue.length;
  if (cvLength < 6) return currentValue;
  return `${currentValue.slice(0, 5)}-${currentValue.slice(5, 9)}`;
}

export function normalizeTime(value) {
  if (!isNull(value) && !/\d\d:\d\d/.test(value)) {
    return value.replaceAll('-', '0');
  }
  return value;
}

export function normalizeOneDecimalPositiveFloat(value) {
  if ((value && oneDecimalPositiveFloat.test(value)) || value === '') {
    return value;
  }
  return null;
}
