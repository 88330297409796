import axios from 'axios';

const ROOT = 'api/v1/study-status-setup';

const StudyStatusSetupApi = {
  getByStudyId(studyId) {
    return axios.get(`${ROOT}/study/${studyId}`);
  },
  saveStudyStatusSetup(studyId, statuses) {
    return axios.post(`${ROOT}/study/${studyId}`, statuses);
  }
};

export default StudyStatusSetupApi;
