import { FormControl, FormLabel } from '@mui/material';

import CheckboxSelect from './CheckboxSelect';
import RadioSelect from './RadioSelect';

const singleSelectTypes = ['E_SOURCE', 'INTERNAL_PROJECT'];

export default function SelectGroup({ group, selectedTypeIds, setSelectedTypeIds, studyType }) {
  const { typeParent, typeParentName } = group;
  return (
    <FormControl fullWidth sx={{ mb: 2 }}>
      <FormLabel id={typeParent + '_LABEL_ID'}>{getLabel(typeParent, typeParentName)}</FormLabel>
      {singleSelectTypes.includes(typeParent) ? (
        <RadioSelect group={group} selectedTypeIds={selectedTypeIds} setSelectedTypeIds={setSelectedTypeIds} />
      ) : (
        <CheckboxSelect
          group={group}
          selectedTypeIds={selectedTypeIds}
          setSelectedTypeIds={setSelectedTypeIds}
          studyType={studyType}
        />
      )}
    </FormControl>
  );
}

function getLabel(typeParent, typeParentName) {
  return typeParentName + (typeParent === 'E_SOURCE' ? ' Identifier (required for eSource Study Type)' : ' Identifier');
}
