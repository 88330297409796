import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid-premium';

import { GridToolbarTableViewState } from './GridToolbarTableViewState';

export const CustomToolbar = ({ localStorageTableName }) => {
  return (
    <GridToolbarContainer data-testid="finanse-table-toolbar">
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        csvOptions={{
          utf8WithBom: true,
          escapeFormulas: false
        }}
        excelOptions={{ disableToolbarButton: true }}
      />
      <GridToolbarTableViewState localStorageTableName={localStorageTableName} />
      <GridToolbarQuickFilter sx={{ margin: '0 0 0 auto' }} />
    </GridToolbarContainer>
  );
};
