import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0, useBouncer } from 'bouncer';
import { isArray, isEmpty, isString } from 'lodash/lang';

import { PrintSsuPatientEncountersApi } from '../../../../../api';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';
import useAppInfo from '../../../../../common/hooks/useAppInfo';
import { DOWNLOAD_FAILED } from '../../../../../constants/notificationMessages';
import { onFileSave, onRequestError } from '../../../../../services/handlers';
import { getPatientAgeFromDob, getPatientPreferredName } from '../../../../../services/patient';
import { PageInfoHeader } from '../../../../PageInfoHeader/PageInfoHeader';
import { useCurrentRoute } from '../../../../root/router';
import { PatientInfoHeaderContext, PatientInfoHeaderProvider } from '../PatientInfoHeaderContext';

import EncountersSelector from './EncountersSelector/EncountersSelector';
import LogsSelector from './LogsSelector/LogsSelector';
import ProceduresSelector from './ProceduresSelector/ProceduresSelector';
import TypeSelector from './TypeSelector/TypeSelector';
import {
  ExportCaseReportFormsProvider,
  useExportCaseReportFormsActions,
  useExportCaseReportFormsState
} from './ExportCaseReportFormsContext';

import './ExportCaseReportForms.scss';

function ExportCaseReportForms() {
  const navigate = useNavigate();
  const ssuPatientId = useCurrentRoute().params?.ssuPatientId;
  const [idTokenKey, setIdTokenKey] = useState(null);
  const { setAllEncounters, setAllLogs } = useExportCaseReportFormsActions();
  const { selectedItemGroupsMap, selectedLogs, isProgressNotesChecked } = useExportCaseReportFormsState();
  const { patientInfo } = useContext(PatientInfoHeaderContext);
  const { isAccessTokenReceived, jwtLocalStorageKey } = useBouncer();
  const { user } = useAuth0();
  const appInfo = useAppInfo();

  const selectedItemGroups = useMemo(
    function() {
      return Object.values(selectedItemGroupsMap)
        .flatMap(items => items)
        .filter(item => item);
    },
    [selectedItemGroupsMap]
  );

  const isAllowedToGenerate = useMemo(
    function() {
      return (
        isString(idTokenKey) &&
        isArray(selectedItemGroups) &&
        (!isEmpty(selectedItemGroups) || !isEmpty(selectedLogs) || isProgressNotesChecked)
      );
    },
    [idTokenKey, selectedItemGroups, selectedLogs, isProgressNotesChecked]
  );

  useEffect(
    function() {
      setIdTokenKey(jwtLocalStorageKey);
    },
    [jwtLocalStorageKey, appInfo?.auth0ClientId, user?.sub]
  );

  useEffect(() => {
    PrintSsuPatientEncountersApi.getSsuPatientEncountersForPrintMetaInfo(ssuPatientId).then(({ data }) => {
      setAllEncounters(data.encounters);
      setAllLogs(buildAllLogs(data));
    });
  }, [setAllEncounters, setAllLogs, ssuPatientId]);

  return (
    <React.Fragment>
      <PageInfoHeader
        objectRecordLabel={patientInfo?.name + getPatientPreferredName(patientInfo)}
        pageInfo={
          <PageInfoHeader.CollapsibleList>
            <PageInfoHeader.AdditionalInfo title="Patient ID">{patientInfo?.patientId}</PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo title="Subject ID">
              {patientInfo?.subjectId || 'No Subject ID'}
            </PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo tooltip="Date of Birth">
              {patientInfo?.dob} ({getPatientAgeFromDob(patientInfo)})
            </PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo tooltip="Study">{patientInfo?.studyName}</PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo tooltip="Site">{patientInfo?.siteName}</PageInfoHeader.AdditionalInfo>
          </PageInfoHeader.CollapsibleList>
        }
        right={
          <ButtonGroup>
            <Button priority="low" onClick={() => navigate(-1)} size="h28">
              Cancel
            </Button>
            <Button
              priority="medium"
              target="_blank"
              size="h28"
              href={`/print-case-report-form.html?s=${ssuPatientId}&k=${idTokenKey}&f=true`}
              onClick={generate}
              disabled={!isAllowedToGenerate}
            >
              Export Case Report Forms
            </Button>
            <Button priority="medium" size="h28" onClick={downloadZip} disabled={!isAllowedToGenerate}>
              Download Files
            </Button>
          </ButtonGroup>
        }
      />
      <div className="export-case-report-forms">
        <div className="export-case-report-forms-selector">
          <TypeSelector />
          <EncountersSelector />
          <ProceduresSelector />
          <LogsSelector />
        </div>
      </div>
    </React.Fragment>
  );

  function generate() {
    saveCaseReportFormsDataToLocalStorage(selectedItemGroups, selectedLogs, isProgressNotesChecked);
  }

  function downloadZip() {
    const requestData = {
      itemGroupsForPrint: selectedItemGroups.map(({ itemGroupInstanceId }) => itemGroupInstanceId),
      logsForPrint: selectedLogs.map(({ uuid }) => uuid),
      exportProgressNotes: isProgressNotesChecked
    };
    PrintSsuPatientEncountersApi.downloadSsuPatientFiles(ssuPatientId, requestData)
      .then(onFileSave)
      .catch(err => onRequestError(err, { customMessage: DOWNLOAD_FAILED }));
  }
}

function buildAllLogs(data) {
  if (isEmpty(data.logs) && !isEmpty(data.encounters)) {
    return [data.commentLog];
  } else if (!isEmpty(data.logs) && isEmpty(data.encounters)) {
    return data.logs;
  } else {
    return [...data.logs, data.commentLog];
  }
}

function saveCaseReportFormsDataToLocalStorage(selectedItemGroups, selectedLogs, isProgressNotesChecked) {
  localStorage.setItem(
    'pepvItemGroups',
    JSON.stringify(selectedItemGroups.map(({ itemGroupInstanceId }) => itemGroupInstanceId))
  );
  localStorage.setItem('pepvLogs', JSON.stringify(selectedLogs.map(({ uuid }) => uuid)));
  localStorage.setItem('pepvIncludeProgressNotes', isProgressNotesChecked);
}

export default function ExportCaseReportFormsComponent(props) {
  return (
    <PatientInfoHeaderProvider>
      <ExportCaseReportFormsProvider>
        <ExportCaseReportForms {...props} />
      </ExportCaseReportFormsProvider>
    </PatientInfoHeaderProvider>
  );
}
