import uuid from 'uuid/v4';

import {
  DATA_COLLECTED,
  NO_DATA_TO_COLLECT,
  NOT_PERFORMED
} from '../../../patient-source/shared/EncounterDescNew/LogCheck/LogCheckNew';

export function resolveDefaultLogCheckOptions(domainCode) {
  const DATA_COLLECTED_OPTION = resolveDataCollected(domainCode);
  const NO_DATA_TO_COLLECT_OPTION = resolveNoDataToCollect(domainCode);
  const NOT_PERFORMED_OPTION = getDefaultNotPerformed();
  return [DATA_COLLECTED_OPTION, NO_DATA_TO_COLLECT_OPTION, NOT_PERFORMED_OPTION];
}

export const showEncounterColumn = domain => {
  const notRequiredEncounterForLogs = ['AE', 'MH', 'CM'];
  return !notRequiredEncounterForLogs.includes(domain);
};

export function resolveDataCollected(domainCode) {
  return {
    value: resolveReported(domainCode),
    rowType: DATA_COLLECTED,
    sequence: 1,
    checked: true,
    id: uuid()
  };
}

export function resolveNoDataToCollect(domainCode) {
  return {
    value: resolveNotReported(domainCode),
    rowType: NO_DATA_TO_COLLECT,
    sequence: 1,
    checked: true,
    id: uuid()
  };
}

export function getDefaultNotPerformed() {
  return {
    value: 'Not Performed',
    rowType: NOT_PERFORMED,
    sequence: 1,
    checked: true,
    id: uuid()
  };
}

export function resolveReported(domainCode) {
  switch (domainCode) {
    case 'AE':
      return 'AE Reported';
    case 'CM':
      return 'CM Reported';
    case 'MH':
      return 'MH Reported';
    case 'DA':
      return 'IP Dispensed';
    default:
      return 'Reported';
  }
}

export function resolveNotReported(domainCode) {
  switch (domainCode) {
    case 'AE':
      return 'No AE To Report';
    case 'CM':
      return 'No CM To Report';
    case 'MH':
      return 'No MH To Report';
    case 'DA':
      return 'No IP Dispensed';
    default:
      return 'No Data To Report';
  }
}
