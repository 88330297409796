import moment from 'moment';

import { DD_SLASH_MMM_SLASH_YY, ISO_DATE_TIME_FORMAT, MMM_SPACE_YYYY } from '../../../../../constants/dateFormat';

export const PENDING_SCHEDULE_WIDGET_VALUES = 'PENDING_SCHEDULE_WIDGET_VALUES';

export const getDateRange = (forWorklist = false, isProjected) => {
  let format;
  if (forWorklist) {
    format = DD_SLASH_MMM_SLASH_YY;
  } else {
    format = ISO_DATE_TIME_FORMAT;
  }
  const dateRangeStart = moment
    .utc()
    .subtract(1, 'M')
    .startOf('month')
    .startOf('day')
    .format(format);
  const yesterday = moment()
    .utc()
    .subtract(1, 'day')
    .endOf('day');
  const monthEnd = moment
    .utc()
    .add(1, 'M')
    .endOf('month')
    .endOf('day');
  let dateRangeEnd;
  if (isProjected) {
    dateRangeEnd = monthEnd;
  } else {
    dateRangeEnd = monthEnd.isAfter(yesterday) ? yesterday : monthEnd;
  }
  return [dateRangeStart, dateRangeEnd.format(format)];
};

export const getDisplayDateRange = () => {
  return [
    moment
      .utc()
      .add(1, 'M')
      .format(MMM_SPACE_YYYY),
    moment.utc().format(MMM_SPACE_YYYY),
    moment
      .utc()
      .subtract(1, 'M')
      .format(MMM_SPACE_YYYY)
  ];
};

export const getOneMonthDateRangeForMonth = (month, isProjected) => {
  const parsedMonth = moment(month, MMM_SPACE_YYYY);
  const dateRangeStart = parsedMonth
    .startOf('month')
    .startOf('day')
    .format(DD_SLASH_MMM_SLASH_YY);
  const yesterday = moment()
    .subtract(1, 'day')
    .endOf('day');
  const monthEnd = parsedMonth.endOf('month').endOf('day');
  let dateRangeEnd;
  if (isProjected) {
    dateRangeEnd = monthEnd;
  } else {
    dateRangeEnd = monthEnd.isAfter(yesterday) ? yesterday : monthEnd;
  }
  return [dateRangeStart, dateRangeEnd.format(DD_SLASH_MMM_SLASH_YY)];
};
