import axios from 'axios';

const ROOT = 'api/v1/studies/';

const LogRowStatusHistoryApi = {
  pushToInvestigator(studyId, ssuPatientId, itemGroupTemplateId, itemGroupTemplateVersionId, rowId) {
    return axios.post(
      `${ROOT}${studyId}/patients/${ssuPatientId}/item-groups/${itemGroupTemplateId}/${itemGroupTemplateVersionId}/logs/rows/${rowId}/push-to-investigator`
    );
  },
  smConfirm(studyId, ssuPatientId, itemGroupTemplateId, itemGroupTemplateVersionId, rowId) {
    return axios.post(
      `${ROOT}${studyId}/patients/${ssuPatientId}/item-groups/${itemGroupTemplateId}/${itemGroupTemplateVersionId}/logs/rows/${rowId}/sm-check`
    );
  },
  getLogRowLastStatus(studyId, ssuPatientId, itemGroupTemplateId, itemGroupTemplateVersionId, rowId) {
    return axios.get(
      `${ROOT}${studyId}/patients/${ssuPatientId}/item-groups/${itemGroupTemplateId}/${itemGroupTemplateVersionId}/logs/rows/${rowId}/status`
    );
  },
  cancelAdverseEventLogRow(studyId, ssuPatientId, itemGroupTemplateId, itemGroupTemplateVersionId, rowId, request) {
    return axios.post(
      `${ROOT}${studyId}/patients/${ssuPatientId}/item-groups/${itemGroupTemplateId}/${itemGroupTemplateVersionId}/logs/rows/${rowId}/cancel`,
      request
    );
  },
  reopenAdverseEventLogRow(studyId, ssuPatientId, itemGroupTemplateId, itemGroupTemplateVersionId, rowId) {
    return axios.post(
      `${ROOT}${studyId}/patients/${ssuPatientId}/item-groups/${itemGroupTemplateId}/${itemGroupTemplateVersionId}/logs/rows/${rowId}/reopen`
    );
  }
};

export default LogRowStatusHistoryApi;
