import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';

import Select from '../../../../../../../common/data-entry/Select';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';

export default function AddNoAppointmentsEncounterModal({ modalBox, epochs, onSave }) {
  const [encounters, setEncounters] = useState([]);
  const [selectedEpoch, setSelectedEpoch] = useState(null);
  const [selectedEncounter, setSelectedEncounter] = useState(null);

  useEffect(() => {
    setSelectedEncounter(null);
    setEncounters(selectedEpoch?.encounters);
  }, [selectedEpoch]);

  return (
    <>
      <ModalBoxes.Header>Add Workflow (No Appointments) Encounter</ModalBoxes.Header>
      <ModalBoxes.Body>
        <div className="row">
          <div className="col-md-6">
            <Select
              dataSource={epochs}
              required
              onChange={onChangeEpoch}
              value={selectedEpoch}
              optionLabelKey="epochName"
              optionValueKey="epochName"
              label="Epoch"
            />
          </div>
          <div className="col-md-6">
            <Select
              dataSource={encounters}
              required
              onChange={onEncounterChange}
              value={selectedEncounter}
              optionLabelKey="encounterName"
              optionValueKey="encounterKey"
              label="Encounter"
            />
          </div>
        </div>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            CANCEL
          </Button>
          <Button disabled={!selectedEncounter} onClick={addWorkflowEncounter}>
            ADD
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );

  function addWorkflowEncounter() {
    onSave(selectedEncounter.encounterKey);
  }

  function onChangeEpoch(value) {
    if (!isEqual(value, selectedEpoch)) setSelectedEpoch(value);
  }

  function onEncounterChange(value) {
    if (!isEqual(value, selectedEncounter)) setSelectedEncounter(value);
  }
}

AddNoAppointmentsEncounterModal.className = 'schedule-appointment-modal';
AddNoAppointmentsEncounterModal.size = 'w650';
