import { includes, reduce } from 'lodash/collection';
import { isArray, isEmpty, isNull } from 'lodash/lang';
import { mapValues } from 'lodash/object';
import uuid from 'uuid/v4';

import { isReadOnlyField } from '../../../../../setup/shared/ElementSetupNew/itemGroupSetupService';
import { isInvestigatorForbiddenField } from '../../InvestigatorFields/services/investigatorFieldService';
import { PS_NOT_ANSWERED, PS_NOT_ASKED, PS_UNAVAILABLE } from '../constants';

export function updateQuestionsField(data, iteratee, predicate = () => true) {
  if (isEmpty(data)) {
    return data;
  }
  const { generalSnapshotAnswers, tableItemGroupRows } = data;
  return {
    ...data,
    generalSnapshotAnswers: generalSnapshotAnswers.map(function(field) {
      return predicate(field) ? iteratee(field) : field;
    }),
    tableItemGroupRows: tableItemGroupRows.map(function(row) {
      return {
        ...row,
        answers: row.answers.map(function(field) {
          return predicate(field) ? iteratee(field) : field;
        })
      };
    })
  };
}

export function updateQuestionsFieldWithUid(data) {
  return updateQuestionsField(data, function(field) {
    const fieldUid = uuid();
    return {
      ...field,
      fieldUid
    };
  });
}

export function collectAllQuestionsField(data) {
  if (isEmpty(data)) {
    return [];
  }
  const { generalSnapshotAnswers, tableItemGroupRows } = data;
  return [
    ...generalSnapshotAnswers.map(field => ({ ...field, fieldLabel: null })),
    ...collectTableQuestionsField(tableItemGroupRows)
  ];
}

export function collectTableQuestionsField(tableItemGroupRows) {
  return tableItemGroupRows.flatMap(function({ answers, label: { name } }) {
    return answers.map(field => ({ ...field, fieldLabel: name }));
  });
}

export function clearHiddenField(field, hiddenFieldsIds) {
  if (hiddenFieldsIds.includes(field.fieldUid)) {
    if (field.fieldType === 'label') {
      return {
        ...field,
        performedStatus: PS_UNAVAILABLE
      };
    }
    return {
      ...field,
      performedStatus: PS_UNAVAILABLE,
      requiredAttention: false,
      fieldComment: null,
      fieldValue: null,
      fileList: null,
      filesResponse: null
    };
  }

  if (
    isNull(field.fieldValue) &&
    isNull(field.fieldComment) &&
    [PS_NOT_ASKED, PS_UNAVAILABLE].includes(field.performedStatus)
  ) {
    return {
      ...field,
      performedStatus: PS_NOT_ANSWERED
    };
  }

  return field;
}

export function answerForDisplay(answer) {
  if (isNull(answer)) {
    return '--';
  }
  if (isArray(answer)) {
    return answer.join(', ');
  }
  return answer;
}

export function generateOriginConditionalLogicAndRequiredFieldsMaps(
  encounterTableItemGroup,
  conditionalLogicAndRequiredFieldsValidation
) {
  const [allRequiredFields, conditionalLogicMap] = splitAndPrepareConditionalLogicAndRequiredFieldsValidation(
    conditionalLogicAndRequiredFieldsValidation
  );

  const allQuestions = collectAllQuestionsField(encounterTableItemGroup);

  return reduce(
    allQuestions,
    function(accumulator, { fieldUid, protocolItemDefinitionId, fieldLabel }) {
      const [originRequiredFieldIds, originConditionalLogicMap] = accumulator;
      const matchedConditionalLogic = conditionalLogicMap[protocolItemDefinitionId];

      if (matchedConditionalLogic) {
        originConditionalLogicMap[fieldUid] = mapValues(matchedConditionalLogic, function(protocolItemDefinitionIds) {
          return allQuestions
            .filter(function(question) {
              if (!protocolItemDefinitionIds.includes(question.protocolItemDefinitionId)) {
                return false;
              }
              if (!fieldLabel) {
                return true;
              }
              return fieldLabel === question.fieldLabel;
            })
            .map(({ fieldUid }) => fieldUid);
        });
      }

      if (includes(allRequiredFields, protocolItemDefinitionId)) {
        originRequiredFieldIds.push(fieldUid);
      }

      return accumulator;
    },
    [[], {}]
  );
}

function splitAndPrepareConditionalLogicAndRequiredFieldsValidation(conditionalLogicAndRequiredFieldsValidation) {
  /*TODO: temp service, should be moved to BE side*/
  return reduce(
    conditionalLogicAndRequiredFieldsValidation,
    function(accumulator, { required, targetIdentifiersMap }, id) {
      const [requiredFields, conditionalLogicMap] = accumulator;
      if (required) {
        requiredFields.push(id);
      }
      if (!isEmpty(targetIdentifiersMap)) {
        conditionalLogicMap[id] = targetIdentifiersMap;
      }
      return accumulator;
    },
    [[], {}]
  );
}

export function revertToggleAnswer(answer) {
  switch (answer) {
    case 'Y':
      return 'N';
    case 'N':
      return 'Y';
    case 'NORM':
      return 'ABNORM';
    case 'ABNORM':
      return 'NORM';
    case 'CS':
      return 'NCS';
    case 'NCS':
      return 'CS';
    default:
      return 'Y';
  }
}

export function canFieldBeHighlightedForReview(field, hiddenFieldsIds, userRole) {
  const { investigatorField, fieldType, fieldName, fieldUid } = field;
  return (
    fieldName !== 'Comment' &&
    !isInvestigatorForbiddenField(investigatorField, userRole) &&
    !isReadOnlyField(fieldType) &&
    !hiddenFieldsIds.includes(fieldUid)
  );
}
