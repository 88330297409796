import React, { useContext, useMemo } from 'react';
import DateTime from 'react-datetime';
import moment from 'moment';

import Button from '../../../../common/general/Button';
import { DD_SLASH_MMM_SLASH_YY } from '../../../../constants/dateFormat';

import { SchedulingWorklistContext } from './SchedulingWorklistFiltersContext';

import './SchedulingWorklistDatePickerFilter.scss';

export const SchedulingWorklistDatePickerFilter = () => {
  const Context = useContext(SchedulingWorklistContext);
  const { dateRangeStart, dateRangeEnd, setDateRangeStart, setDateRangeEnd } = Context;

  const labels = useMemo(() => ['Start Date', 'End Date'], []);

  const getButtonText = idx => {
    if (idx === 0 && labels.length > 1) {
      return dateRangeStart;
    } else {
      return dateRangeEnd;
    }
  };

  const onChangeDate = (date, idx) => {
    if (idx === 0 && labels.length > 1) {
      setDateRangeStart(moment.utc(date.toDateString()).format(DD_SLASH_MMM_SLASH_YY));
    } else {
      setDateRangeEnd(moment.utc(date.toDateString()).format(DD_SLASH_MMM_SLASH_YY));
    }
  };
  return (
    <>
      {labels &&
        labels.map((label, idx) => (
          <div className={'date-picker-container'} key={idx}>
            <div className={'label'}>{label}</div>
            <DateTime
              closeOnSelect={true}
              renderInput={props => (
                <Button className={'date-select-button'} size="h28" priority={'medium'} onClick={props.onClick}>
                  {getButtonText(idx)}
                </Button>
              )}
              className={'time-select'}
              id={'time-selector'}
              viewMode={'days'}
              dateFormat={DD_SLASH_MMM_SLASH_YY}
              value={getButtonText(idx)}
              isValidDate={date => {
                if (labels.length > 1 && idx === 1) {
                  return moment(date).isAfter(moment(dateRangeStart));
                }
                if (labels.length > 1 && idx === 0) {
                  return true;
                } else {
                  return moment(date);
                }
              }}
              onChange={value => {
                onChangeDate(value._d, idx);
              }}
              timeFormat={false}
            />
          </div>
        ))}
    </>
  );
};
