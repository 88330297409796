import { combineReducers } from 'redux';

import activeTaskReducer from '../store/activeTask';
import appInfo from '../store/appInfo/index';
import currentUserReducer from '../store/currentUser';
import filtersReducer from '../store/filters';

import budgetSiteReducer from './budgetSiteReducer/budgetSiteReducer';
import forecastingReducer from './forecasting/forecastingReducer';
import studyBudgetReducer from './studyBudgetReducers/studyBudgetReducer';
import ActivePatient from './activePatientReducer';
import getAllSites from './getAllSites';
import getAllStudies from './getAllStudies';
import getFormReducer from './getDynamicDomainFormReducer';
import homePageDashboardsConfig from './homePageDashboardsConfig';
import patientActivity from './patientActivityReducer';
import PatientListReducer from './patientListReducer';
import origins from './patientOriginReducer';
import piWorkList from './piWorkList';
import studySites from './studySites';
import userPreferencesReducer from './userPreferencesReducer';
import worklistFilteringDataReducer from './worklistFilteringDataReducer';

const rootReducer = combineReducers({
  getAllSites,
  patientList: PatientListReducer,
  activePatient: ActivePatient,
  origins,
  patientActivity,
  getAllStudies,
  currentUser: currentUserReducer,
  getFormReducer,
  forecasting: forecastingReducer,
  budgetList: studyBudgetReducer,
  budgetSitesList: budgetSiteReducer,
  workListFilteringData: worklistFilteringDataReducer,
  filters: filtersReducer,
  userPreferences: userPreferencesReducer,
  homePageDashboardsConfig,
  piWorkList,
  studySites,
  appInfo,
  activeTask: activeTaskReducer
});

export default rootReducer;
