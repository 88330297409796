import { useMemo } from 'react';
import { Chip } from '@mui/material';
import moment from 'moment';

export default function EuiSsuPatientInfoChips({ ssuPatientInfo }) {
  const { patientFullName, dateOfBirth, subjectId, patientId, studyName, siteName } = ssuPatientInfo;
  const formattedDob = useMemo(
    function() {
      return `${moment(dateOfBirth).format('DD/MMM/YYYY')} (${moment().diff(dateOfBirth, 'years')} years)`;
    },
    [dateOfBirth]
  );
  return (
    <div className="notes-chips">
      {patientFullName && <Chip size="small" label={`Patient Name: ${patientFullName}`} sx={getSx(14 + 17)} />}
      <Chip size="small" label={`DOB: ${formattedDob ? formattedDob : 'N/A'}`} />
      <Chip size="small" label={`Subject ID: ${subjectId ? subjectId : 'N/A'}`} sx={getSx(11 + 7)} />
      <Chip size="small" label={`Patient ID: ${patientId ? patientId : 'N/A'}`} sx={getSx(11 + 40)} />
      <Chip size="small" label={`Study: ${studyName ? studyName : 'N/A'}`} sx={getSx(6 + 40)} />
      <Chip size="small" label={`Site: ${siteName ? siteName : 'N/A'}`} sx={getSx(6 + 40)} />
    </div>
  );
}

function getSx(length) {
  return {
    '> span': {
      maxWidth: `${length}ch`
    }
  };
}
