import React, { useEffect, useState } from 'react';
import { ArrowDropUp, DateRange } from '@mui/icons-material';
import {
  AlertTitle,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { ArrowDropDownIcon, DesktopDatePicker } from '@mui/x-date-pickers';
import { isEmpty, isString } from 'lodash';
import moment from 'moment';

import { ProgressNoteApi } from '../../../../../../../../api';
import LoaderOverlay from '../../../../../../../../common/elements/Loader/LoaderOverlay';
import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import NotificationManager from '../../../../../../../../common/notifications/NotificationManager';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../../../../constants/dateFormat';
import { PROGRESS_NOTE_ADDED, PROGRESS_NOTE_UPDATED } from '../../../../../../../../constants/notificationMessages';
import { onRequestError } from '../../../../../../../../services/handlers';

import NotesInfo from './NotesInfo';

import './AddOrEditProgressNoteModal.scss';

export default function AddOrEditProgressNoteModal({
  modalBox,
  onSave: onSaveCallback,
  ssuPatientId,
  progressNote,
  notesInfo
}) {
  const DEFAULT_TYPE = 'Phone';
  const [loading, setLoading] = useState(false);
  const [createdDate, setCreatedDate] = useState(moment());
  const [type, setType] = useState(DEFAULT_TYPE);
  const [note, setNote] = useState(null);
  const [isDatePickerOpened, setIsDatePickerOpened] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const isExistedNote = isString(progressNote?.id);

  useEffect(() => {
    if (!progressNote) {
      return;
    }
    const { noteCreatedDate, note, progressNoteType } = progressNote;
    setType(progressNoteType);
    setCreatedDate(moment(noteCreatedDate));
    setNote(note);
  }, [progressNote]);

  useEffect(() => {
    const hasChanges =
      note?.trim() !== progressNote?.note?.trim() ||
      type !== progressNote?.progressNoteType ||
      !createdDate.isSame(moment(progressNote?.noteCreatedDate).startOf('day'), 'day');

    setIsFormChanged(hasChanges);
  }, [note, type, createdDate, progressNote]);

  return (
    <>
      <ModalBoxes.Body>
        <NotesInfo notesInfo={notesInfo} />
        <div className={'date-type-info'}>
          <DesktopDatePicker
            label="Date of Communication"
            value={createdDate}
            onChange={value => setCreatedDate(value)}
            open={isDatePickerOpened}
            onClose={() => setIsDatePickerOpened(false)}
            format={DD_SLASH_MMM_SLASH_YYYY}
            slots={{
              openPickerIcon: isDatePickerOpened ? ArrowDropUp : ArrowDropDownIcon
            }}
            slotProps={{
              textField: {
                readOnly: true,
                variant: 'standard',
                required: true,
                onClick: () => setIsDatePickerOpened(true),
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <DateRange />
                    </InputAdornment>
                  )
                }
              }
            }}
            maxDate={moment()}
          />
          <FormControl variant="standard">
            <InputLabel id="select-type-label" required>
              Select Type
            </InputLabel>
            <Select
              labelId="select-type-label"
              id="select-type"
              value={type}
              onChange={e => setType(e.target.value)}
              label="Type"
            >
              {['Phone', 'Visit', 'Other'].map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <TextField
          multiline={true}
          maxRows={25}
          fullWidth
          required
          variant={'standard'}
          size={'small'}
          label={'Note'}
          value={note}
          onChange={e => setNote(e.target.value)}
        />
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <Button color={'primary'} size={'small'} onClick={modalBox.close}>
          CANCEL
        </Button>
        <Button size={'small'} color={'primary'} variant={'contained'} disabled={!isValid()} onClick={onSave}>
          {isExistedNote ? 'SAVE UPDATES' : 'ADD'}
        </Button>
      </ModalBoxes.Footer>
      {loading && <LoaderOverlay light />}
    </>
  );

  function isValid() {
    return isString(note) && !isEmpty(note.trim()) && isFormChanged;
  }

  function onSave() {
    const id = progressNote?.id;
    setLoading(true);
    const noteObj = {
      note,
      type,
      sitePatientIdentifier: ssuPatientId,
      noteCreatedDate: createdDate.toISOString()
    };

    if (isExistedNote) {
      ProgressNoteApi.updateProgressNote(ssuPatientId, id, noteObj)
        .then(res => {
          setLoading(false);
          NotificationManager.success(getSuccessNotification(PROGRESS_NOTE_UPDATED));

          onSaveCallback();
        })
        .catch(err => {
          onRequestError(err);
          modalBox.close();
        });
    } else {
      ProgressNoteApi.addProgressNote(ssuPatientId, noteObj)
        .then(res => {
          setLoading(false);
          onSaveCallback(res.data);
          NotificationManager.success(getSuccessNotification(PROGRESS_NOTE_ADDED));
        })
        .catch(err => {
          onRequestError(err);
          modalBox.close();
        });
    }
  }
}

function getSuccessNotification(message) {
  return (
    <div>
      <AlertTitle>Success!</AlertTitle>
      {message}
    </div>
  );
}

AddOrEditProgressNoteModal.className = 'add-or-edit-progress-note-modal';
AddOrEditProgressNoteModal.size = 'w800';
