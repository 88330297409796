import React, { useState } from 'react';
import { isFunction } from 'lodash';
import { isEmpty } from 'lodash/lang';
import moment from 'moment';

import InvestigatorSignatureApi from '../../../../../../api/patient/InvestigatorSignatureApi';
import Input from '../../../../../../common/data-entry/Input';
import Select from '../../../../../../common/data-entry/Select';
import TextArea from '../../../../../../common/data-entry/TextArea';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../../constants/dateFormat';
import { SIGNED, UNABLE_TO_SIGN_IN } from '../../../../../../constants/notificationMessages';
import { ROLE_SYSTEM_ADMINISTRATOR } from '../../../../../../constants/userRoles';
import { userHasRole } from '../../../../../../services/auth';
import { useCurrentUser } from '../../../../../root/Container/CurrentUserContainer';
import { authenticateAuth0User } from '../../ReviewDEV/ReviewControl/SmPiReviewSignModal/authApi';

export default function InvestigatorFieldsSignatureModal({
  modalBox,
  signData,
  groupAssignList,
  callback,
  customSignFunction = null,
  customTitle
}) {
  const currentUser = useCurrentUser();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [reason, setReason] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isValidForm, setIsValidForm] = useState(true);
  const [isGroupSelected, setIsGroupSelected] = useState(true);

  const [selectedGroupAssign, setSelectedGroupAssign] = useState(null);
  const showReasonInput = userHasRole(ROLE_SYSTEM_ADMINISTRATOR);
  const showGroupAssignSelect = userHasRole(ROLE_SYSTEM_ADMINISTRATOR);

  return (
    <>
      <ModalBoxes.Header>
        {customTitle
          ? customTitle
          : 'I reviewed and approve the data collected as of '.concat(moment().format(DD_SLASH_MMM_SLASH_YYYY))}
      </ModalBoxes.Header>
      <ModalBoxes.Body>
        <form id="signForm" onSubmit={handleSubmit}>
          <Input
            label="Username"
            name="username"
            autoFocus
            onChange={o => setEmail(o.target.value)}
            value={email}
            placeholder="Enter email"
          />
          <Input
            label="Password"
            type="password"
            onChange={o => setPassword(o.target.value)}
            value={password}
            placeholder="Password"
          />
          {showGroupAssignSelect && (
            <Select
              label={selectedGroupAssign ? 'Group' : ''}
              placeholder={'Select a Group'}
              dataSource={groupAssignList}
              value={selectedGroupAssign}
              onChange={newValue => {
                setSelectedGroupAssign(newValue);
                setIsGroupSelected(true);
              }}
              searchable={true}
              closeOnSelectedOptionClick={false}
              validate={true}
              required={true}
              validationMessage={!isGroupSelected ? 'Group is required' : ''}
            />
          )}
          {showReasonInput && (
            <TextArea
              id={'reason-input'}
              name={'reason'}
              label={'Reason'}
              value={reason}
              style={{ resize: 'none' }}
              required
              validationMessage={isValidForm ? '' : 'Reason is required'}
              onChange={({ target }) => {
                !!target.value && setIsValidForm(true);
                setReason(target.value);
              }}
            />
          )}
        </form>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <Button form="signForm" loading={loader} disabled={isEmpty(email) || isEmpty(password)}>
          SUBMIT
        </Button>
      </ModalBoxes.Footer>
    </>
  );

  function onFailure(err) {
    NotificationManager.error(UNABLE_TO_SIGN_IN);
    console.error(err);
    setLoader(false);
  }

  function onSign(token) {
    if (!isFunction(customSignFunction)) {
      InvestigatorSignatureApi.sign(
        signData.studyId,
        signData.ssuPatientId,
        signData.itemGroupTemplateId,
        signData.itemGroupTemplateVersionId,
        signData.rowId,
        {
          groupAssignId: showGroupAssignSelect ? selectedGroupAssign.id : null,
          reason: reason,
          loggedUserId: currentUser?.personnelIdentifier,
          loggedUserRole: currentUser?.activeRole
        },
        token
      )
        .then(() => {
          modalBox.close();
          isFunction(callback) && callback();
          NotificationManager.success(SIGNED);
        })
        .catch(onFailure);
    } else {
      customSignFunction(
        {
          groupAssignId: showGroupAssignSelect ? selectedGroupAssign.id : null,
          reason: reason,
          loggedUserId: currentUser?.personnelIdentifier,
          loggedUserRole: currentUser?.activeRole
        },
        token
      );
      modalBox.close();
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (loader) {
      return;
    }
    if (!reason && showReasonInput) {
      setIsValidForm(false);
      return;
    }
    if (!selectedGroupAssign && showGroupAssignSelect) {
      setIsGroupSelected(false);
      return;
    }
    setIsValidForm(true);
    setIsGroupSelected(true);
    setLoader(true);
    const callBack = {
      onSuccess: result => onSign(result.getIdToken().getJwtToken()),
      onAuth0Success: result => onSign(result.access_token),
      onFailure: onFailure
    };
    authenticateAuth0User(email.trim().toLowerCase(), password, callBack);
  }
}

InvestigatorFieldsSignatureModal.className = 'eds-investigator-fields-sign-modal-box';
InvestigatorFieldsSignatureModal.size = 'w650';
