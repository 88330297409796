import React, { useEffect, useState } from 'react';

import { PatientDetailsApi } from '../../../../api';
import { useCurrentRoute } from '../../../root/router';

export const PatientInfoHeaderContext = React.createContext(null);

export function PatientInfoHeaderProvider({ children }) {
  const currentRoute = useCurrentRoute();
  const { ssuPatientId } = currentRoute.params;

  const [patientInfo, setPatientInfo] = useState(null);

  useEffect(
    function() {
      PatientDetailsApi.getPatientDetails(ssuPatientId).then(function({ data: patientDetailsResponse }) {
        setPatientInfo(patientDetailsResponse);
      });
    },
    [ssuPatientId]
  );
  return (
    <PatientInfoHeaderContext.Provider
      value={{
        patientInfo
      }}
    >
      {children}
    </PatientInfoHeaderContext.Provider>
  );
}
