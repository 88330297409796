import React, { useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import { groupBy } from 'lodash/collection';

import { HomePageDashboardsConfigApi } from '../../../../../api';
import Loader from '../../../../../common/elements/Loader/Loader';
import { scElligoRed } from '../../../../../constants/systemColors';
import { getIds } from '../../../patient-source/TaskWorklist/TaskWorklistService';
import WidgetDefaultFilters from '../common/WidgetDefaultFilters';
import { getValidationString } from '../common/WidgetDefaultService';
import WidgetFilterChips from '../common/WidgetFilterChips';

import EncounterSignOffFiltersContext, { EncounterSignOffContext } from './EncounterSignOffFiltersContext';
import EncounterSignOffTable from './EncounterSignOffTable';
import { OPEN, PI_REVIEW, SM_REVIEW } from './EncounterSignOffWidgetConstants';

import '../common/WidgetDefaultStyles.scss';

function EncounterSignOffWidgetEl({ widget }) {
  const [isFetching, setIsFetching] = useState(false);

  const [data, setData] = useState([]);

  const Context = useContext(EncounterSignOffContext);

  const { filter, validationError, isLoadingFilters } = Context;

  async function getData(selectedStudySites, selectedEncounters) {
    const piData = await HomePageDashboardsConfigApi.getEncounterSignOffDataPi(
      getIds(selectedStudySites),
      [PI_REVIEW],
      getIds(selectedEncounters) || []
    );
    const smData = await HomePageDashboardsConfigApi.getEncounterSignOffDataSm(
      getIds(selectedStudySites),
      [OPEN, SM_REVIEW],
      getIds(selectedEncounters) || []
    );
    return [...piData.data, ...smData.data];
  }

  useEffect(
    function() {
      if (
        !isEmpty(Context.filter.selectedStudySites) &&
        !isEmpty(Context.filter.selectedStudies) &&
        !isEmpty(Context.filter.selectedSites) &&
        !isEmpty(Context.filter.selectedEpochs) &&
        !isEmpty(Context.filter.selectedEncounters)
      ) {
        setIsFetching(true);

        getData(Context.filter.selectedStudySites, Context.filter.selectedEncounters).then(function(data) {
          const grouped = groupBy(data, e => e.encounterName);
          const allEncs = Object.keys(grouped);
          const result = [];
          allEncs.forEach(key => {
            grouped[key] = groupBy(grouped[key], e => e.encounterStatus);

            result.push({
              encounter: key,
              SM_REVIEW: grouped[key][SM_REVIEW]?.length || 0,
              PI_REVIEW: grouped[key][PI_REVIEW]?.length || 0,
              OPEN: grouped[key][OPEN]?.length || 0
            });
            result.forEach(e => {
              e.total = e.SM_REVIEW + e.PI_REVIEW + e.OPEN;
            });
          });
          setIsFetching(false);
          setData(result);
        });
      }
    },
    [Context.filter]
  );

  return (
    <div className={cx('p-3 widget-container default-widget', 'size-l')} key={5}>
      <div className="widget">
        <div className="widget-header p-2 px-3">
          <h5 className="title">{widget.name}</h5>
        </div>

        {!isLoadingFilters && <WidgetDefaultFilters isFetching={isFetching} {...Context} />}
        <div className="px-3 c-p" style={{ minHeight: '24px', color: scElligoRed }}>
          {validationError ? getValidationString(filter) : ''}
        </div>

        {!isEmpty(filter) && <WidgetFilterChips {...Context} />}

        {(isFetching || isLoadingFilters) && <Loader dark={true} />}
        {!isFetching && !isLoadingFilters && !isEmpty(data) && <EncounterSignOffTable data={data} />}
      </div>
    </div>
  );
}

export default function EncounterSignOffWidget(props) {
  return (
    <EncounterSignOffFiltersContext {...props}>
      <EncounterSignOffWidgetEl {...props} />
    </EncounterSignOffFiltersContext>
  );
}
