import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { isNull } from 'lodash/lang';

import { EventFinderApi, SitePatientEncounterApi } from '../../../../api';
import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../common/general/Button';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import { DATA_SAVED } from '../../../../constants/notificationMessages';
import { CLOSED } from '../../../../constants/ssuStatuses';
import {
  ROLE_CLINICAL_QUALITY_COORDINATOR,
  ROLE_FINANCE_ANALYST,
  ROLE_OPERATIONS_ANALYST,
  ROLE_SYSTEM_ADMINISTRATOR
} from '../../../../constants/userRoles';
import { userHasRole } from '../../../../services/auth';
import { onRequestError } from '../../../../services/handlers';
import { generateUrlByKey, useCurrentRoute } from '../../../root/router';
import { PATIENT_ENCOUNTER_EVENT } from '../../Calendar/CalendarEventType';
import { CALENDAR_SELECTED_EVENT } from '../../Calendar/CalendarPageContext';
import CancelEncounterModal from '../shared/EncounterDescNew/CancelEncounterModal';
import { viewAllProgressNotes } from '../shared/NoteService';

import { SchedulingWorklistContext } from './SchedulingWorklistFiltersContext';

export const SchedulingWorklistSummaryColumn = ({
  hasAccessToView,
  hasAccessToEdit,
  event,
  sitePatientIdentifier,
  studySiteStatus,
  appointmentStatus
}) => {
  const navigate = useNavigate();
  const currentRoute = useCurrentRoute();
  const Context = useContext(SchedulingWorklistContext);
  const { applyFilters } = Context;
  const actionsAllowedForUser = userHasRole([
    ROLE_CLINICAL_QUALITY_COORDINATOR,
    ROLE_FINANCE_ANALYST,
    ROLE_OPERATIONS_ANALYST
  ]);

  function buildCalendarUrl() {
    return generateUrlByKey(
      `${currentRoute?.key}.Patient Profile.Patient Studies.Calendar`,
      {
        patientId: event.patientUniqueId,
        ssuPatientId: sitePatientIdentifier
      },
      {
        encounterId: event.protocolEncounterKey,
        sitePatientId: sitePatientIdentifier,
        encounterEventType: PATIENT_ENCOUNTER_EVENT
      }
    );
  }

  function addAppointment() {
    sessionStorage.setItem(
      CALENDAR_SELECTED_EVENT,
      JSON.stringify({ sourcePage: currentRoute.key, siteId: event.siteId, siteName: event.siteName })
    );
    setTimeout(() => navigate(buildCalendarUrl()));
  }

  function buildCalendarUrlForEdit() {
    return generateUrlByKey(`${currentRoute?.key}.Patient Profile.Patient Studies.Calendar`, {
      patientId: event.patientUniqueId,
      ssuPatientId: sitePatientIdentifier
    });
  }

  function editAppointment() {
    sessionStorage.setItem(
      CALENDAR_SELECTED_EVENT,
      JSON.stringify({ ...event, siteId: event.siteId, siteName: event.siteName, sourcePage: currentRoute.key })
    );
    setTimeout(() => navigate(buildCalendarUrlForEdit()));
  }

  function doCancelSitePatientEncounter(comment) {
    return SitePatientEncounterApi.checkinAndCancelSitePatientEncounter(sitePatientIdentifier, event.eventId, {
      isSituational: !isNull(event.protocolSituationalEncounterId),
      reason: comment
    })
      .then(res => {
        NotificationManager.success(DATA_SAVED);
        return res;
      })
      .catch(onRequestError);
  }

  function cancelSitePatientEncounter() {
    EventFinderApi.getEventsForCancelEncounter(sitePatientIdentifier, event.protocolEncounterKey).then(function({
      data: appointments
    }) {
      const cancelEncounterModal = ModalBoxes.open({
        component: (
          <CancelEncounterModal
            onConfirm={comment => {
              doCancelSitePatientEncounter(comment).then(() => {
                applyFilters();
                cancelEncounterModal.close();
              });
            }}
            encounterName={event.encounterName}
            appointments={appointments}
          />
        )
      });
    });
  }

  return (
    <React.Fragment>
      {appointmentStatus.id !== 'NO_APPOINTMENT' && (
        <>
          <Button
            size="h28"
            priority="high"
            className="ml-2"
            onClick={() => editAppointment()}
            disabled={actionsAllowedForUser}
          >
            Reschedule
          </Button>
          <Button
            size="h28"
            priority="high"
            className="ml-2"
            onClick={() => cancelSitePatientEncounter()}
            disabled={actionsAllowedForUser}
          >
            Cancel Encounter
          </Button>
        </>
      )}
      {appointmentStatus.id === 'NO_APPOINTMENT' && (
        <Button size="h28" priority="high" className="ml-2" onClick={addAppointment} disabled={actionsAllowedForUser}>
          Schedule
        </Button>
      )}

      {hasAccessToView && (
        <Button
          size="h28"
          priority="high"
          className="ml-2"
          onClick={() =>
            viewAllProgressNotes(
              sitePatientIdentifier,
              studySiteStatus,
              (studySiteStatus === CLOSED && userHasRole(ROLE_SYSTEM_ADMINISTRATOR)) || hasAccessToEdit,
              event
            )
          }
        >
          Notes
        </Button>
      )}
    </React.Fragment>
  );
};
