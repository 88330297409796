import { useEffect, useRef } from 'react';
import { xor } from 'lodash';
import { isEmpty } from 'lodash/lang';

import {
  ROLE_PRINCIPAL_INVESTIGATOR,
  ROLE_STUDY_MANAGER,
  ROLE_SUB_INVESTIGATOR
} from '../../../../../constants/userRoles';
import { userHasRole } from '../../../../../services/auth';
import { generateUrlByKey } from '../../../../root/router';

import { CANCELED, COMPLETE } from './constants';

export function getValidationErrors(filter) {
  const errors = [];
  isEmpty(filter.selectedStudies) && errors.push('selectedStudies');
  isEmpty(filter.selectedSites) && errors.push('selectedSites');
  return errors;
}

export function isSelectedStudySitesChanged(oldFilters, newFilters) {
  return !isEmpty(
    xor(
      oldFilters.selectedStudySites.map(studySite => studySite.id),
      newFilters.selectedStudySites.map(studySite => studySite.id)
    )
  );
}

export function getAllUniqueEncountersFromCurrentTable(uploadedData) {
  let encounters = [];
  uploadedData
    .map(tableRow => ({
      studyName: tableRow.studyName,
      encounterName: tableRow.encounterName
    }))
    .filter(function(item) {
      let i = encounters.findIndex(x => x.studyName === item.studyName && x.encounterName === item.encounterName);
      if (i <= -1) {
        encounters.push(item);
      }
      return null;
    });
  return encounters;
}

export function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
export const getUrlToReviewPage = (original, worklistName) => {
  const {
    piReviewItemGroupId,
    ssuPatientId,
    patientEncounterId,
    patientIdentifier,
    groupAssignStudy,
    hasInvestigatorReview,
    groupAssignId,
    encounterStatus
  } = original;

  if (groupAssignStudy) {
    if (!groupAssignId) {
      return;
    }
    if (
      worklistName === 'Investigator Worklist' &&
      (!userHasRole([ROLE_PRINCIPAL_INVESTIGATOR, ROLE_SUB_INVESTIGATOR]) || encounterStatus === 'SM_REVIEW')
    ) {
      return;
    }
    if (
      worklistName === 'Study Manager Worklist' &&
      (!userHasRole(ROLE_STUDY_MANAGER) || encounterStatus === 'PI_REVIEW')
    ) {
      return;
    }
  }

  if (groupAssignStudy ? !hasInvestigatorReview : !piReviewItemGroupId) {
    return;
  }
  return generateUrlByKey(`${worklistName}.Patient Profile.Patient Studies.Encounter Details.${getReviewPageName()}`, {
    ssuPatientId: ssuPatientId,
    patientId: patientIdentifier,
    patientEncounterId,
    reviewPatientItemGroupId: piReviewItemGroupId,
    groupAssignId,
    signatureId: 'current'
  });
  function getReviewPageName() {
    return groupAssignStudy ? `Investigator Encounter Review` : `Investigator Review`;
  }
};

export const getUrlToEncounterDetailsPage = (original, worklistName) => {
  const { patientIdentifier, ssuPatientId, patientEncounterId } = original;
  if (!!patientEncounterId) {
    return generateUrlByKey(`${worklistName}.Patient Profile.Patient Studies.Encounter Details`, {
      patientId: patientIdentifier,
      ssuPatientId,
      patientEncounterId
    });
  }
};

export const getUrlToPatientInfoPage = (original, worklistName) => {
  const { patientIdentifier, ssuPatientId } = original;
  return generateUrlByKey(`${worklistName}.Patient Profile.Patient Studies`, {
    patientId: patientIdentifier,
    ssuPatientId: ssuPatientId
  });
};

export const getStatusMessage = (currentSelectedStatus, statusesProvider, isEncounterWidgetSelected) => {
  if (currentSelectedStatus === CANCELED) {
    return `Canceled ${isEncounterWidgetSelected ? 'Encounters' : 'Adverse Events'}`;
  } else if (currentSelectedStatus === COMPLETE) {
    return `Complete ${isEncounterWidgetSelected ? 'Encounters' : 'Adverse Events'}`;
  } else {
    return `${statusesProvider.filter(status => status.id === currentSelectedStatus).map(status => status.name)} ${
      isEncounterWidgetSelected ? 'for Encounters' : 'for Adverse Events'
    }`;
  }
};
