import React from 'react';

import Button from '../../../../common/general/Button';

export function EditAppointmentButtons({ onSave, onClose }) {
  return (
    <React.Fragment>
      <div className="action-item">
        <Button onClick={onClose} priority="medium">
          Cancel
        </Button>
      </div>
      <div className="action-item">
        <Button onClick={onSave}>Update</Button>
      </div>
    </React.Fragment>
  );
}
