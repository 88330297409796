import React from 'react';

import Icon from '../../../../../common/general/Icon';

import './Chip.scss';

export default function Chip({ label, values, onClose, originalItems }) {
  const all = originalItems.length === values.length;
  return (
    <div className="eui-chip">
      <div>{label}:</div>
      {all && <div>All</div>}
      {!all && (
        <>
          <div className="values ml-1">{values[0]}</div>
          {values.length > 1 && <div className="ml-1">{`(+ ${values.length - 1})`}</div>}
          <Icon className="close-chip ml-2" onClick={onClose}>
            close
          </Icon>
        </>
      )}
    </div>
  );
}

export function ChipLabel({ label, value }) {
  return (
    <div className="eui-chip-label">
      <div>{label}:</div>
      <div className="ml-1">{value}</div>
    </div>
  );
}
