import React, { useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash/lang';
import * as PropTypes from 'prop-types';

import { PatientEncounterReviewApi } from '../../../../../../../../../api';
import ModalBoxes from '../../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { ReviewContext } from '../../../../ReviewContext';
import ElementHistoryLogModal from '../../../HistoryLogModal/ElementHistoryLogModal';
import { useReviewContentElement } from '../../../ReviewContentElementContext/ReviewContentElementContext';
import * as reviewContentService from '../../../reviewContentService';
import QuestionAnswer from '../../ItemGroupProcedure/QuestionAnswer/QuestionAnswer';
import {
  filterMetaDataComment,
  findQuestionLabel,
  getFieldValue,
  isAnswerEdited,
  isAnswerEditedAfterSign,
  processMultiselectAnswerValues,
  sortBySequence
} from '../ItemGroupNewTableService';

import './OverviewItemGroupNewTableContent.scss';

export default function OverviewItemGroupNewTableContent({ snapshotAnswers, itemGroupSnapshotState }) {
  const { isAllowSign } = useReviewContentElement();

  const [answersToRender, setAnswersToRender] = useState([]);
  const { reviewType, ssuPatientId, requiredAttentionItemGroupsConfirmStatuses, isHistoricalData } = useContext(
    ReviewContext
  );

  const {
    general,
    itemGroupRef,
    itemGroupTemplateVersion,
    rows: [{ rowReviewStatus }]
  } = itemGroupSnapshotState;

  const formHighlightStyle = reviewContentService.getFormHighlightStyleBasedOnRowReviewStatusAndReviewType(
    rowReviewStatus,
    reviewType,
    isAllowSign
  ).className;

  useEffect(() => {
    setAnswersToRender(mapGeneralSnapshotStateToQuestionAnswer(general?.rowSnapshotFormData?.form, itemGroupRef));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [general, itemGroupRef, itemGroupTemplateVersion?.itemGroupVersionQuestions]);

  const patientItemGroupId = itemGroupRef?.patientItemGroupId;

  const isItemGroupConfirmed = requiredAttentionItemGroupsConfirmStatuses[patientItemGroupId];

  return (
    <div className="erc-item-group-element-table-reflection">
      {!isEmpty(answersToRender) && (
        <div className="overview-content">
          <div className="erc-overview-title">
            <div className="erc-new-table-review-title">
              <span>Overview</span>
            </div>

            <div className="erc-overview-history">
              {!isHistoricalData && (
                <div className="overview-history-link" onClick={getOverviewHistory}>
                  <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      id="Vector"
                      d="M11.5515 0.00349014C6.46153 -0.13651 2.29153 3.95349 2.29153 9.00349H0.501534C0.0515344 9.00349 -0.168466 9.54349 0.151534 9.85349L2.94153 12.6535C3.14153 12.8535 3.45153 12.8535 3.65153 12.6535L6.44153 9.85349C6.75153 9.54349 6.53153 9.00349 6.08153 9.00349H4.29153C4.29153 5.10349 7.47153 1.95349 11.3915 2.00349C15.1115 2.05349 18.2415 5.18349 18.2915 8.90349C18.3415 12.8135 15.1915 16.0035 11.2915 16.0035C9.68153 16.0035 8.19153 15.4535 7.01153 14.5235C6.61153 14.2135 6.05153 14.2435 5.69153 14.6035C5.27153 15.0235 5.30153 15.7335 5.77153 16.0935C7.29153 17.2935 9.20153 18.0035 11.2915 18.0035C16.3415 18.0035 20.4315 13.8335 20.2915 8.74349C20.1615 4.05349 16.2415 0.13349 11.5515 0.00349014ZM11.0415 5.00349C10.6315 5.00349 10.2915 5.34349 10.2915 5.75349V9.43349C10.2915 9.78349 10.4815 10.1135 10.7815 10.2935L13.9015 12.1435C14.2615 12.3535 14.7215 12.2335 14.9315 11.8835C15.1415 11.5235 15.0215 11.0635 14.6715 10.8535L11.7915 9.14349V5.74349C11.7915 5.34349 11.4515 5.00349 11.0415 5.00349Z"
                      fill="#2465C6"
                    />
                  </svg>
                  History
                </div>
              )}
            </div>
          </div>

          <div className={cx('erc-new-table-q', formHighlightStyle)}>
            {answersToRender.map((answer, key) => {
              return (
                <QuestionAnswer
                  isItemGroupConfirmed={isItemGroupConfirmed}
                  snapshotAnswers={snapshotAnswers}
                  answer={answer}
                  key={key}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );

  function mapGeneralSnapshotStateToQuestionAnswer(generalRowSnapshotFormDataForm, itemGroup) {
    if (generalRowSnapshotFormDataForm?.answers?.length === 0) {
      return [];
    }

    const commentQuestionField = getCommentQuestionFiled();
    return generalRowSnapshotFormDataForm?.answers
      ?.filter(answer => !reviewContentService.isCommentAnswer(answer))
      .filter(answer => !reviewContentService.isAnswerAffectedByConditionalLogic(answer))
      .filter(answer => answer?.itemGroupQuestionId !== commentQuestionField?.questionId)
      .map(answer => {
        return {
          changeReason: answer.changeReason,
          commentAnswer: answer.commentAnswer,
          emptyReason: answer.emptyReason,
          fileValue: answer.fileValue,
          highlighted: answer.highlighted,
          inputType: answer.inputType,
          sequence: answer.sequence,
          stringValue: getFieldValue(answer.inputType, answer.stringValue),
          type: answer.type,
          question: findQuestionLabel(answer?.itemGroupQuestionId, itemGroupTemplateVersion),
          isEdited: isAnswerEdited(answer, general),
          isEditedAfterSign: isAnswerEditedAfterSign(answer, general)
        };
      })
      ?.sort(sortBySequence);
  }

  function getCommentQuestionFiled() {
    return itemGroupTemplateVersion?.itemGroupVersionQuestions?.find(question => {
      return question.name === 'Comment' && question.label === 'Comment';
    });
  }

  function getOverviewHistory() {
    const ssuPatientEncounterId = itemGroupSnapshotState.itemGroupRef.patientEncounterId;
    const itemGroupId = itemGroupSnapshotState.itemGroupRef.patientItemGroupId;
    const rowId = general?.rowSnapshotFormData?.rowId;
    const itemGroupName = `${itemGroupSnapshotState.itemGroupRef.patientItemGroupName} Overview`;
    const onLoadData = () =>
      PatientEncounterReviewApi.getItemGroupGeneralAnswerHistory(
        ssuPatientId,
        ssuPatientEncounterId,
        itemGroupId,
        rowId
      )
        ?.then(filterMetaDataComment)
        ?.then(processMultiselectAnswerValues);

    ModalBoxes.open({
      component: <ElementHistoryLogModal itemGroupName={itemGroupName} onLoadData={onLoadData} />,
      title: itemGroupName
    });
  }
}

OverviewItemGroupNewTableContent.propTypes = {
  itemGroupSnapshotState: PropTypes.object
};
