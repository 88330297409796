import React, { useMemo } from 'react';
import cx from 'classnames';

import Checkbox from '../../../../../../../common/data-entry/InputSelectors/Checkbox';
import { scYellow100 } from '../../../../../../../constants/systemColors';
import { useCurrentUser } from '../../../../../../root/Container/CurrentUserContainer';
import { isReadOnlyField } from '../../../../../setup/shared/ElementSetupNew/itemGroupSetupService';
import InvestigatorFieldIcon from '../../InvestigatorFields/InvestigatorFieldIcon/InvestigatorFieldIcon';
import InvestigatorForbiddenTooltip from '../../InvestigatorFields/InvestigatorForbiddenTooltip/InvestigatorForbiddenTooltip';
import { isInvestigatorForbiddenField } from '../../InvestigatorFields/services/investigatorFieldService';
import { performedStatusLabelsMap } from '../constants';
import HighlightAllForReview from '../HighlightAllForReview/HighlightAllForReview';
import { useTableItemGroupActions, useTableItemGroupState } from '../TableItemGroupContext/TableItemGroupContext';
import TableItemGroupField from '../TableItemGroupField/TableItemGroupField';

import './TableItemGroupGeneral.scss';

export default function TableItemGroupGeneral() {
  const { encounterTableItemGroup, requiredFieldsIds, hiddenFieldsIds, isPreviewOnly } = useTableItemGroupState();
  const { generalSnapshotAnswers } = encounterTableItemGroup;
  const { requiredAttentionOnChange } = useTableItemGroupActions();
  const user = useCurrentUser();

  const filteredAnswers = useMemo(
    function() {
      return generalSnapshotAnswers.filter(function({ fieldUid, fieldName }) {
        return !hiddenFieldsIds.includes(fieldUid) && fieldName !== 'Comment';
      });
    },
    [generalSnapshotAnswers, hiddenFieldsIds]
  );

  return (
    <div className="table-item-group-general">
      <div className="table-item-group-section-headline">Overview</div>
      <div className="table-item-group-section-sub-headline">
        <HighlightAllForReview />
      </div>
      <div className="table-item-group-general-rows">
        {filteredAnswers.map(function(field) {
          const {
            fieldUid,
            fieldName,
            fieldType,
            requiredAttention,
            fieldComment,
            performedStatus,
            investigatorField
          } = field;
          const isInvestigatorFieldForbidden = isInvestigatorForbiddenField(investigatorField, user.activeRole);
          return (
            <InvestigatorForbiddenTooltip isInvestigatorFieldForbidden={isInvestigatorFieldForbidden}>
              <div
                className={cx(`table-item-group-general-row`, isInvestigatorFieldForbidden ? 'forbidden-field' : '')}
                key={fieldUid}
              >
                {fieldType !== 'reminder' && fieldType !== 'textBlockLong' && (
                  <div
                    className={cx('table-item-group-general-field-name', {
                      'table-item-group-general-field-name-required': requiredFieldsIds.includes(fieldUid)
                    })}
                  >
                    {investigatorField && <InvestigatorFieldIcon />}
                    {fieldName}
                  </div>
                )}
                <div className="table-item-group-general-field">
                  <TableItemGroupField field={field} readonly={isInvestigatorFieldForbidden} />
                </div>
                {!isReadOnlyField(fieldType) && (
                  <div className="table-item-group-general-required-attention">
                    <Checkbox
                      disabled={isPreviewOnly || isInvestigatorFieldForbidden}
                      color={scYellow100}
                      onChange={function({ target }) {
                        requiredAttentionOnChange(target.checked, fieldUid);
                      }}
                      checked={requiredAttention}
                    />
                  </div>
                )}
                <div className="table-item-group-general-field-comment">
                  {fieldComment && `${performedStatusLabelsMap[performedStatus]}: ${fieldComment}`}
                </div>
              </div>
            </InvestigatorForbiddenTooltip>
          );
        })}
      </div>
    </div>
  );
}
