import axios from 'axios';

const ROOT = 'api/v1/patient-bulk-update';

const PatientUpdateApi = {
  getStatusesForBulkUpdate(studyId) {
    return axios.get(`${ROOT}/study/${studyId}/statuses`, { hideLoader: true });
  },
  bulkStatusUpdate(request) {
    return axios.post(`${ROOT}/status`, request, { hideLoader: false });
  },
  bulkAddProgressNote(request) {
    return axios.post(`${ROOT}/progress-note`, request, { hideLoader: false });
  }
};
export default PatientUpdateApi;
