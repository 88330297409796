import React, { useState } from 'react';
import moment from 'moment';
import Tooltip from 'rc-tooltip';

import './FvInfo.scss';

export default function FvInfo({ fileInfo }) {
  const [show, setShow] = useState(true),
    { signedBy, signedDate } = fileInfo;
  const overlay = (
    <>
      <div className="eds-file-viewer-info">
        <div className="fvi-block">
          <b className="fvi-sign">Certified Copy</b>
          <span className="fvi-sign">Signed by: {signedBy}</span>
          <span className="fvi-sign">Date: {moment(signedDate).format('DD/MMM/YYYY')}</span>
        </div>
        <span className="fvi-hint">
          Certified to be a true and exact copy of the original. This certification is valid while maintained within
          IntElligo.
        </span>
      </div>
      <i onClick={() => setShow(false)} className="material-icons fv-tooltip-close">
        cancel
      </i>
    </>
  );
  return (
    <div className="eds-fv-info">
      <Tooltip
        overlayClassName="eds-rc-tooltip fv-tooltip"
        overlay={overlay}
        placement="right"
        mouseEnterDelay={0}
        mouseLeaveDelay={0.1}
        destroyTooltipOnHide={false}
        visible={show}
        trigger={Object.keys({ hover: 1 })}
        align={{
          offset: [0, 70]
        }}
      >
        <i onClick={() => setShow(show => !show)} className="material-icons fvi-icon">
          task
        </i>
      </Tooltip>
    </div>
  );
}
