import React from 'react';

import { stylesContent } from '../../EncounterInvoiceTemplateStyles';

export const ReimbursementEpochEncounter = ({ item }) => {
  return (
    <div
      style={{
        ...stylesContent.invoiceItem,
        fontFamily: 'Roboto, Roboto-Medium',
        fontWeight: '500'
      }}
      className="row-item"
      data-testid="ledger-event-item"
    >
      {item.content}
    </div>
  );
};
