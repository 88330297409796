import React, { Component } from 'react';

import Copyright from '../../../Copyright';
import Version from '../../../Version';

import './Footer.scss';

class Footer extends Component {
  render() {
    return (
      <footer className="text-center py-2 text-muted footfx">
        Version <Version />
        <Copyright />
      </footer>
    );
  }
}
export default Footer;
