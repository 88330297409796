import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { GroupAssignApi } from '../../../../../../api';
import LoaderOverlay from '../../../../../../common/elements/Loader/LoaderOverlay';
import withSkeleton from '../../../../../../common/hocs/withSkeleton';
import GroupsSetupTable1 from '../../../../../ItemGroupsEncountersTable/ItemGroupsEncountersTable';
import ItemGroupsEncounterTableSkeleton from '../../../../../ItemGroupsEncountersTable/ItemGroupsEncounterTableSkeleton/ItemGroupsEncounterTableSkeleton';
import { sortEpochsAndEncountersByProtocolSequence } from '../EncounterSetup/services';

import GroupsSetupTableViewableCell from './GroupsSetupTableComponents/GroupsSetupTableCell/GroupsSetupTableViewableCell';
import ProtocolGroupsList from './ProtocolGroupsList/ProtocolGroupsList';
import { generateMapForEncountersItemGroupsConfiguration } from './services';
import withProtocolGroupsSetup from './withProtocolGroupsSetup';

import './ProtocolGroupsSetupPreview.scss';

const GroupsSetupTable = withSkeleton(ItemGroupsEncounterTableSkeleton, withProtocolGroupsSetup(GroupsSetupTable1));
export default function ProtocolGroupsSetupPreview({ studyId, protocolIdentity }) {
  const [loadingMainData, setLoadingMainData] = useState(true);

  const [loadingGroupsConfiguration, setLoadingGroupsConfiguration] = useState(true);

  const [mainData, setMainData] = useState({});
  const [groupsConfigurationMap, setGroupsConfigurationMap] = useState({});
  const [selectedGroupId, setSelectedGroupId] = useState(null);

  const { groups, epochs, itemGroups } = mainData;

  useEffect(
    function() {
      setLoadingMainData(true);
      const loadingStartTime = +new Date();
      GroupAssignApi.getProtocolEncounterGroupAssignConfiguration(studyId, protocolIdentity)
        .then(function({ data }) {
          if (isEmpty(data.groups)) return;
          setMainData({ ...data, epochs: sortEpochsAndEncountersByProtocolSequence(data.epochs) });
          setSelectedGroupId(data.groups[0].id);
        })
        .finally(function() {
          const loadingEndTime = +new Date();
          const loadingDeltaTime = loadingEndTime - loadingStartTime;
          setTimeout(function() {
            setLoadingMainData(false);
          }, Math.max(700 - loadingDeltaTime, 0));
        });
    },
    [studyId, protocolIdentity]
  );

  useEffect(
    function() {
      if (!selectedGroupId) return;
      setLoadingGroupsConfiguration(true);
      const loadingStartTime = +new Date();
      GroupAssignApi.getGroupAssignConfiguration(studyId, protocolIdentity, selectedGroupId)
        .then(function({ data }) {
          const configurationMap = generateMapForEncountersItemGroupsConfiguration(data);
          setGroupsConfigurationMap(configurationMap);
        })
        .finally(function() {
          const loadingEndTime = +new Date();
          const loadingDeltaTime = loadingEndTime - loadingStartTime;
          setTimeout(function() {
            setLoadingGroupsConfiguration(false);
          }, Math.max(700 - loadingDeltaTime, 0));
        });
    },
    [studyId, protocolIdentity, selectedGroupId]
  );

  return (
    <div className="protocol-groups-setup-preview">
      <div className="protocol-groups-setup-preview-wrapper">
        <div className="protocol-groups-setup-preview-wrapper-header">
          <h5>Groups Setup Preview</h5>
        </div>
        <div className="protocol-groups-setup-preview-content">
          <ProtocolGroupsList
            skeletonLoading={loadingMainData}
            groups={groups}
            selectedGroupId={selectedGroupId}
            selectGroup={setSelectedGroupId}
          />
          <GroupsSetupTable
            skeletonLoading={loadingMainData}
            epochs={epochs}
            itemGroups={itemGroups}
            Cell={function({ cellKey }) {
              return <GroupsSetupTableViewableCell cellKey={cellKey} groupsConfigurationMap={groupsConfigurationMap} />;
            }}
            RowTitleCell={function({ itemGroup }) {
              return (
                <>
                  <div />
                  <div
                    title={itemGroup.elementName}
                    style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  >
                    {itemGroup.elementName}
                  </div>
                </>
              );
            }}
          />
          {!loadingMainData && loadingGroupsConfiguration && <LoaderOverlay light />}
        </div>
      </div>
    </div>
  );
}
