import React from 'react';
import { isEmpty } from 'lodash/lang';
import uuid from 'uuid/v4';

import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import StyledTooltip from '../../../../../../../common/widgets/StyledToolTip';
import { MappingTemplateModal } from '../MappingTemplateModal/MappingTemplateModal';

import PreviewModal from './Preview/PreviewModal';
import { MappingSetupContext, MappingSetupProvider } from './MappingSetupContex';
import MappingSetupModal from './MappingSetupModal';

export const editMapping = (
  templateMode = false,
  templateId = null,
  updateTable = null,
  initialParameters = null,
  studyList = []
) => {
  ModalBoxes.open({
    component: (
      <MappingSetupProvider
        templateMode={templateMode}
        templateId={templateId}
        updateTable={updateTable}
        initialParameters={initialParameters}
        studyList={studyList}
        modalBox
      >
        <MappingSetupModal context={MappingSetupContext} />
      </MappingSetupProvider>
    ),
    className: 'edit-mapping-modal',
    size: 'w1250'
  });
};

export const createNewMapping = (studyList, updateFunction) => {
  editMapping(false, null, updateFunction, null, studyList);
};

export const openPreview = (dataForReview, encounterName) => {
  ModalBoxes.open({
    component: <PreviewModal dataForReview={dataForReview} encounterName={encounterName} />,
    className: 'preview-mapping-modal',
    size: 'w800'
  });
};

export const openTemplateModal = (
  addTemplatesToMappings,
  mappingForFieldsExist,
  mappingForGeneralExist,
  encounterId,
  itemGroupKey,
  protocolId
) => {
  ModalBoxes.open({
    component: (
      <MappingTemplateModal
        addTemplatesToMappings={addTemplatesToMappings}
        mappingForFieldsExist={mappingForFieldsExist}
        mappingForGeneralExist={mappingForGeneralExist}
        encounterId={encounterId}
        itemGroupKey={itemGroupKey}
        protocolId={protocolId}
      />
    ),
    className: 'mapping-template-modal',
    size: 'w1250'
  });
};

export const resolveCellValue = value => <span> {value ? value : '--'}</span>;

export const validateMapping = mapping => {
  let listOfInvalidFields = [];
  mapping.forEach((row, index) => {
    for (let key in row) {
      if (!row[key] && key !== 'id' && key !== 'itemGroupKey') {
        listOfInvalidFields.push({ invalidItem: key, invalidIndex: index });
      }
    }
  });
  return listOfInvalidFields;
};

export const validateGeneralFields = mapping => {
  let listOfInvalidFields = [];
  for (let groupKey in mapping) {
    mapping[groupKey].forEach((row, index) => {
      for (let key in row) {
        if (!row[key] && key !== 'id' && key !== 'itemGroupKey' && key !== 'alwaysSent') {
          listOfInvalidFields.push({ invalidItem: key, invalidIndex: index, invalidGroup: groupKey });
        }
      }
    });
  }
  return listOfInvalidFields;
};

export const validateAlwaysSentMapping = mapping => {
  for (let groupKey in mapping) {
    if (!!mapping[groupKey].find(entry => entry.alwaysSent)) {
      return true;
    }
  }
  return false;
};

export const selectedValueWishTooltip = (selectedValue, selectedValueKey = 'name') => (
  <StyledTooltip
    className="mapping-dropdown-tooltip"
    overlay={<div className="note-value">{selectedValue[0][selectedValueKey]}</div>}
    placement="top"
  >
    <span id="selected-value-name">{selectedValue[0][selectedValueKey]}</span>
  </StyledTooltip>
);

export const validateEndpoint = url => {
  const regex = new RegExp(/(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/i);
  return !!url.match(regex);
};

export const sortFieldsByName = (array, sortBy) => {
  return array.sort((a, b) => {
    const nameA = a[sortBy].toLowerCase(),
      nameB = b[sortBy].toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
};

export const prepareDataForTemplateMode = ({ data: { itemGroupFields, generalFields, name, templateId } }) => {
  const itemGroupMappings = itemGroupFields.map(field => {
    const protocolItemDefinitions = field.fields.map(item => ({
      mappingKeyValue: item.mappingKeyValue,
      mappingType: item.mappingType,
      name: item.name,
      protocolItemIdentifier: item.protocolItemDefinitionKey,
      itemGroupId: item.itemGroupId,
      createdBy: item.createdBy,
      mappingCreateDate: item.mappingCreateDate,
      id: item.id
    }));
    return {
      protocolItemDefinitions,
      itemGroupName: field.name,
      itemGroupId: field.itemGroupId
    };
  });
  return {
    data: {
      generalFieldMappings: generalFields,
      itemGroupMappings,
      name,
      templateId
    }
  };
};

export const generateListOfCollapsedGroups = (generalMapping, notEmptyGroups) => {
  let list = [];
  for (let key in generalMapping) {
    list = [...list, { name: key, collapsed: !!notEmptyGroups.includes(key), count: 0 }];
  }
  return [...list, { name: 'Item Groups', collapsed: !!notEmptyGroups.includes('Item Groups'), count: 0 }];
};

export const prepareGeneralMapping = generalMapping => {
  let mapping = [];
  for (let key in generalMapping) {
    mapping.push({
      groupType: key,
      fields: [
        ...generalMapping[key].filter(mapping => mapping.mappingKeyValue).map(line => ({ ...line, key: uuid() }))
      ]
    });
  }
  return mapping.map(item => ({ ...item, fields: sortFieldsByName(item.fields, 'name') }));
};

export const prepareGeneralFields = generalFieldMapping => {
  const transformedGeneralFields = {};
  generalFieldMapping
    .filter(element => !isEmpty(element.fields))
    .forEach(group => {
      transformedGeneralFields[group.groupType] = group.fields.map(mapping => {
        let mappingForSave = {
          mappingKeyValue: mapping.mappingKeyValue?.trim(),
          fieldName: mapping.fieldName,
          fieldType: mapping.fieldType,
          name: mapping.name,
          id: mapping.id,
          alwaysSent: mapping?.alwaysSent
        };
        if (mapping.createdBy && mapping.mappingCreateDate) {
          mappingForSave = {
            ...mappingForSave,
            createdBy: mapping.createdBy,
            mappingCreateDate: mapping.mappingCreateDate
          };
        }
        return mappingForSave;
      });
    });
  return transformedGeneralFields;
};
