import React from 'react';
import * as PropTypes from 'prop-types';

import Checkbox from '../../../../../common/data-entry/InputSelectors/Checkbox';
import { transformToFirstCapitalLetter } from '../../../../../services/string';

import './StatusesCheckboxes.scss';

export default function StatusesCheckboxes(props) {
  const { chosenStatuses, setFewFilterProperty, statuses, label } = props;
  return (
    <Checkbox.Group label={label} checkedValues={chosenStatuses} onChange={onChangeStatuses}>
      {statuses.map(status => {
        return <Checkbox key={status} label={transformToFirstCapitalLetter(status)} value={status} />;
      })}
    </Checkbox.Group>
  );

  function onChangeStatuses({ target: { value: status, checked } }) {
    if (!checked) {
      setFewFilterProperty({ statuses: chosenStatuses.filter(s => s !== status) });
    } else {
      setFewFilterProperty({ statuses: [status, ...chosenStatuses] });
    }
  }
}

StatusesCheckboxes.propTypes = {
  chosenStatuses: PropTypes.array,
  setFewFilterProperty: PropTypes.func,
  statuses: PropTypes.array
};
