import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import UnsubscribeOutlinedIcon from '@mui/icons-material/UnsubscribeOutlined';
import { Button } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { isEmpty, isString } from 'lodash';

import PatientReimbursementApi from '../../../../../../../../api/patient/PatientReimbursementApi';
import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { STTS_ESOURCE_TYPES } from '../../../../../../../../constants/study/studyTypes';
import {
  MANAGE_PATIENT_REIMBURSEMENT_NOTIFICATIONS,
  MANAGE_PATIENT_REIMBURSEMENTS
} from '../../../../../../../../constants/userOperations';
import { ssuRoleAccessCheck, userHasAccessTo } from '../../../../../../../../services/auth';
import { generateUrlByKey, useCurrentRoute } from '../../../../../../../root/router';
import { PET_NF } from '../../../PatientEnablementSection/constants';
import PatientEnablementSection from '../../../PatientEnablementSection/PatientEnablementSection';
import { PatientInfoContext } from '../../../PatientInfoContext';
import { isCtmsReimbursementAndReimbursementEmailOptIn } from '../../../patientInfoService';
import AddOrEditPaymentsItemsModal from '../../../ReimbursementRequest/AddOrEditPaymentsItemsModal/AddOrEditPaymentsItemsModal';
import PaymentsSectionHeader from '../PaymentsSectionHeader/PaymentsSectionHeader';

import useReimbursementGridData from './useReimbursementGridData';

export default function ReimbursementSection() {
  const navigate = useNavigate();
  const currentRoute = useCurrentRoute();
  const patientId = currentRoute.params?.patientId;
  const ssuPatientId = currentRoute.params?.ssuPatientId;
  const { currentStudy, patientInfo } = useContext(PatientInfoContext);
  const [columns, data, setData] = useReimbursementGridData();
  const [hasActiveEncounters, setHasActiveEncounters] = useState(false);
  const granted = currentStudy.granted;
  const studyTypes = currentStudy.types;

  useEffect(() => {
    if (isESourceType(studyTypes)) {
      PatientReimbursementApi.getEncounters(ssuPatientId).then(res => {
        setHasActiveEncounters(!isEmpty(res.data));
      });
    }
  }, [ssuPatientId, studyTypes]);

  const allowToManage = useMemo(
    function() {
      if (isESourceType(studyTypes) && !hasActiveEncounters) {
        return false;
      }
      return userHasAccessTo(MANAGE_PATIENT_REIMBURSEMENTS) && ssuRoleAccessCheck(granted);
    },
    [granted, hasActiveEncounters, studyTypes]
  );

  const [loading, setLoading] = useState(true);

  const loadData = useCallback(
    function() {
      if (!ssuPatientId) return;
      setLoading(true);
      PatientReimbursementApi.getReimbursementForSsuPatient(ssuPatientId).then(res => {
        setData(res.data);
        setLoading(false);
      });
    },
    [ssuPatientId, setData]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div>
      <PaymentsSectionHeader title="Patient Reimbursements">
        {allowToManage && (
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={function() {
              ModalBoxes.open({
                component: (
                  <AddOrEditPaymentsItemsModal
                    ssuPatientInfo={{
                      patientFullName: `${patientInfo.firstName} ${patientInfo.lastName}`,
                      patientId: patientInfo?.patientId,
                      subjectId: currentStudy?.patientSubjectId,
                      dateOfBirth: patientInfo?.dob,
                      studyName: currentStudy?.studyName,
                      siteName: currentStudy?.siteName
                    }}
                    patientId={patientId}
                    ssuPatientId={ssuPatientId}
                    studyId={currentStudy?.studyId}
                    studyType={currentStudy?.type}
                    ssuId={currentStudy?.ssuId}
                  />
                ),
                onClose(reimbursementId) {
                  if (!isString(reimbursementId)) return;
                  navigate(
                    generateUrlByKey(`${currentRoute.key}.Reimbursement request`, {
                      ...currentRoute.params,
                      reimbursementId
                    })
                  );
                }
              });
            }}
          >
            Reimbursement
          </Button>
        )}
      </PaymentsSectionHeader>
      {userHasAccessTo(MANAGE_PATIENT_REIMBURSEMENT_NOTIFICATIONS) &&
        isCtmsReimbursementAndReimbursementEmailOptIn(currentStudy) && (
          <Button
            data-testid="reimbursement-section-notification-button"
            style={{ textTransform: 'none', color: 'black', marginLeft: '13px' }}
            onClick={() => PatientEnablementSection.open(PET_NF)}
            endIcon={
              patientInfo?.reimbursementEmailNotifications ? (
                <MarkEmailReadOutlinedIcon sx={{ fontSize: '28px !important' }} />
              ) : (
                <UnsubscribeOutlinedIcon sx={{ fontSize: '28px !important' }} />
              )
            }
          >
            Reimbursement Notification {patientInfo?.reimbursementEmailNotifications ? 'Enabled' : 'Disabled'}
          </Button>
        )}
      <DataGridPremium
        loading={loading}
        getRowId={getRowId}
        rowHeight={38}
        rows={data}
        columns={columns}
        disableRowSelectionOnClick
        localeText={{ noRowsLabel: 'No results to display' }}
        onRowClick={function({ id: reimbursementId }) {
          if (!allowToManage) return;

          navigate(
            generateUrlByKey(`${currentRoute.key}.Reimbursement request`, {
              ...currentRoute.params,
              reimbursementId
            })
          );
        }}
        disableRowGrouping
        disableAggregation
      />
    </div>
  );
}

function getRowId(row) {
  return row.reimbursementId;
}

function isESourceType(types) {
  return types.some(function(type) {
    return STTS_ESOURCE_TYPES.includes(type);
  });
}
