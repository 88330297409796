import { CRA_REVIEW, PLA } from '../../../../constants/accessToPages';
import { withMultiAccess } from '../../../root/router/withMultiAccess';
import { ReviewPage } from '../shared/Review/ReviewPage';

import CraReview from './CraReview';

export const craReviewRouterConfig = {
  name: 'CRA Review',
  path: '/patient-source-review',
  component: CraReview,
  access: [PLA],
  inherited: [
    {
      name: 'Encounter Review',
      path: '/:ssuPatientId/encounters/:patientEncounterId/cra-review',
      component: withMultiAccess(ReviewPage),
      access: [CRA_REVIEW]
    }
  ]
};
