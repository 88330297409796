import { useMemo } from 'react';
import { Autocomplete, TextField, Unstable_Grid2 as Grid } from '@mui/material';
import { isEmpty } from 'lodash/lang';

import { countries, getCountryById, getRegion, getRegions } from '../../../../../../../../services/geographic';
import { normalizeZipCode } from '../../../../../../../../services/normalizers';

import Address1Autocomplete from './Address1Autocomplete/Address1Autocomplete';
import CityAutocomplete from './CityAutocomplete/CityAutocomplete';
import AddressValidator from './AddressValidator';

export default function AddressFields({ data, setAddressField, setCountryId, setCity }) {
  const [country, regions] = useMemo(
    function() {
      return [getCountryById(data.countryId) || '', getRegions(data.countryId)];
    },
    [data.countryId]
  );

  const region = useMemo(
    function() {
      return data.countryId ? getRegion(data.regionId, data.countryId) || '' : '';
    },
    [data.countryId, data.regionId]
  );

  return (
    <>
      <Grid xs={4}>
        <Autocomplete
          value={country}
          onChange={function({ target }, country) {
            setCountryId(country?.id || '');
          }}
          onBlur={function(e) {
            AddressValidator.revalidate();
          }}
          fullWidth
          getOptionKey={option => option.id}
          getOptionLabel={option => option.name ?? option}
          disablePortal
          options={countries}
          renderInput={params => (
            <TextField {...params} name={`countryId`} label="Country" variant="standard" required autoComplete="off" />
          )}
        />
      </Grid>
      <Grid xs={4}>
        <Autocomplete
          disabled={isEmpty(regions)}
          value={region}
          onChange={function({ target }, region) {
            setAddressField(`regionId`, region?.id || '');
          }}
          onBlur={function(e) {
            AddressValidator.revalidate();
          }}
          fullWidth
          getOptionKey={option => option.id}
          getOptionLabel={option => option.name ?? option}
          disablePortal
          options={regions}
          renderInput={params => (
            <TextField
              {...params}
              name={`regionId`}
              label={data.countryId === 'CA' ? 'Province' : 'State'}
              variant="standard"
              required
              autoComplete="off"
            />
          )}
        />
      </Grid>
      <Grid xs={4}>
        <CityAutocomplete
          name={`city`}
          countryId={data.countryId}
          regionId={data.regionId}
          city={data.city}
          onChange={function(value) {
            setCity(value);
          }}
          onBlur={function(e) {
            AddressValidator.revalidate();
          }}
        />
      </Grid>
      <Grid xs={6}>
        <Address1Autocomplete
          name={`address1`}
          countryId={data.countryId}
          regionId={data.regionId}
          city={data.city}
          address1={data.address1}
          valid={data.valid}
          onChange={function(value) {
            setAddressField(`address1`, value || '');
          }}
          onBlur={function(e) {
            AddressValidator.revalidate();
          }}
        />
      </Grid>
      <Grid xs={3}>
        <TextField
          name={`address2`}
          value={data.address2 || ''}
          onChange={function({ target }) {
            setAddressField(target.name, target.value || '');
          }}
          fullWidth
          label="Address 2"
          variant="standard"
          autoComplete="off"
        />
      </Grid>
      <Grid xs={3}>
        <TextField
          name={`zipCode`}
          value={data.zipCode || ''}
          onChange={function({ target }) {
            setAddressField(target.name, normalizeZipCode(target.value, data.countryId === 'US'));
          }}
          onBlur={function(e) {
            AddressValidator.revalidate();
          }}
          fullWidth
          label="Zip"
          variant="standard"
          required
          autoComplete="off"
        />
      </Grid>
    </>
  );
}
