import axios from 'axios';

const ROOT = 'api/v1/item-group-templates';

const ItemGroupTemplatesApi = {
  saveItemGroupTemplate(data) {
    return axios.post(ROOT, data);
  },
  updateItemGroupTemplate(id, data) {
    return axios.put(`${ROOT}/${id}`, data);
  },
  deleteItemGroupTemplate(id) {
    return axios.delete(`${ROOT}/${id}`);
  },
  getAllItemGroupTemplates() {
    return axios.get(ROOT);
  },
  getItemGroupTemplatesByTerminologyVersionGroupNumber(terminologyVersionGroupNumber) {
    return axios.get(`${ROOT}/number/${terminologyVersionGroupNumber}`);
  },
  getItemGroupTemplate(id) {
    return axios.get(`${ROOT}/${id}`);
  }
};

export default ItemGroupTemplatesApi;
