import { useCallback, useState } from 'react';
import { isEqual, isFunction } from 'lodash';

const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    const item = localStorage.getItem(key);
    return JSON.parse(item) || initialValue;
  });

  const setValue = useCallback(
    value => {
      setStoredValue(storedValue => {
        if (isEqual(value, storedValue)) {
          return storedValue;
        }
        const valueToStore = isFunction(value) ? value(storedValue) : value;
        localStorage.setItem(key, JSON.stringify(valueToStore));
        return valueToStore;
      });
    },
    [key]
  );
  return [storedValue, setValue];
};

export default useLocalStorage;
