import axios from 'axios';

const ROOT = 'api/v1/studies';

const ProtocolItemGroupTemplatesApi = {
  getElementDomain(data) {
    return axios.get(`${ROOT}/${data.study_identifier}/protocol-item-group-templates/${data.element_identifier}`);
  },
  getActiveElementDomain(data) {
    return axios.get(
      `${ROOT}/${data.study_identifier}/protocol-item-group-templates/${data.element_identifier}/active`
    );
  },
  getByStudyId(studyId) {
    return axios.get(`${ROOT}/${studyId}/protocol-item-group-templates`);
  },
  copyProtocolItemGroupTemplate(studyId, request) {
    return axios.post(`${ROOT}/${studyId}/protocol-item-group-templates/copy`, request);
  }
};

export default ProtocolItemGroupTemplatesApi;
