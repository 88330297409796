import { useMemo } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { reject } from 'lodash/collection';

export default function RadioSelect({ group, selectedTypeIds, setSelectedTypeIds }) {
  const { typeParent, types } = group;
  const value = useMemo(
    function() {
      return (
        types.find(function({ id }) {
          return selectedTypeIds.includes(id);
        })?.id || null
      );
    },
    [selectedTypeIds, types]
  );
  return (
    <RadioGroup
      name={typeParent + '_LABEL_ID'}
      value={value}
      onChange={function({ target }) {
        const id = target.value ? +target.value : null;
        setSelectedTypeIds(function(prevIds) {
          const sameValue = value === id;
          if (!sameValue && value) {
            return [...reject(prevIds, pId => pId === value), id].sort();
          }
          return [...prevIds, id].sort();
        });
      }}
    >
      {types?.map(({ id, name }) => {
        return (
          <FormControlLabel
            key={id}
            value={id}
            control={
              <Radio
                onClick={function(e) {
                  if (+e.target.value === value) {
                    e.preventDefault();
                    setSelectedTypeIds(function(prevIds) {
                      return reject(prevIds, pId => pId === value);
                    });
                  }
                }}
              />
            }
            label={name}
            sx={{ m: 0 }}
          />
        );
      })}
    </RadioGroup>
  );
}
