import React, { useContext, useEffect, useState } from 'react';
import { intersectionWith } from 'lodash/array';
import { groupBy } from 'lodash/collection';
import { isEmpty, isEqual } from 'lodash/lang';
import moment from 'moment';

import SchedulingWorklistApi from '../../../../../api/patient/SchedulingWorklistApi';
import useSessionStorage from '../../../../../common/hooks/useSessionStorage';
import { ISO_DATE_TIME_FORMAT, MMM_SPACE_YYYY } from '../../../../../constants/dateFormat';
import { getNames } from '../../../patient-source/ScheduleWorklist/SchedulingWorklistService';
import { HomePageContext } from '../../HomePageContext';
import { getEncountersFrom, getEpochsFrom, getIds, getSitesFrom, getStudiesFrom } from '../common/WidgetDefaultService';

import { getDateRange, getDisplayDateRange, PENDING_SCHEDULE_WIDGET_VALUES } from './PendingScheduleWidgetConstants';

export const PendingScheduleWidgetContext = React.createContext(null);

export default function PendingScheduleWidgetFiltersContext(props) {
  const [sessionStorage, setSessionStorage] = useSessionStorage(PENDING_SCHEDULE_WIDGET_VALUES, {});

  const [selectedEncounters, setSelectedEncounters] = useState(sessionStorage.selectedEncounters || []);
  const [selectedEpochs, setSelectedEpochs] = useState(sessionStorage.selectedEpochs || []);
  const [selectedStudies, setSelectedStudies] = useState(sessionStorage.selectedStudies || []);
  const [selectedSites, setSelectedSites] = useState(sessionStorage.selectedSites || []);
  const [selectedStudySites, setSelectedStudySites] = useState(sessionStorage.selectedStudySites || []);
  const [encounters, setEncounters] = useState([]);
  const [allEncounters, setAllEncounters] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [epochs, setEpochs] = useState([]);
  const [allEpochs, setAllEpochs] = useState([]);
  const [studies, setStudies] = useState([]);
  const [allStudies, setAllStudies] = useState([]);
  const [sites, setSites] = useState([]);
  const [allSites, setAllSites] = useState([]);
  const [epochEncounterMap, setEpochEncounterMap] = useState([]);
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const Context = useContext(HomePageContext);
  const { ssus } = Context;

  const [filter, setFilter] = useState({
    selectedStudySites,
    selectedStudies,
    selectedSites,
    selectedEpochs,
    selectedEncounters,
    studies,
    sites,
    epochs,
    encounters
  });
  const { children } = props;

  async function getFilters(ssus) {
    const filters = await SchedulingWorklistApi.getFilterEncountersAccessibleForUser({
      ssuIds: ssus.map(e => e.id)
    });
    return [...filters.data];
  }

  const calculateAmountOfProjectedEncounters = (byStartDate, byEndDate, key) => {
    if (key in byEndDate && key in byStartDate) {
      return byStartDate[key].concat(byEndDate[key].filter(item => item.startDate !== key)).length;
    } else if (key in byEndDate) {
      return byEndDate[key].length;
    } else if (key in byStartDate) {
      return byStartDate[key].length;
    } else {
      return 0;
    }
  };
  useEffect(() => {
    if (!isEmpty(filter.selectedStudySites) && !isEmpty(filter.selectedEncounters)) {
      setIsFetching(true);
      const [dateRangeStart, dateRangeEnd] = getDateRange(false, true);
      const displayDateRange = getDisplayDateRange();
      const requests = [
        SchedulingWorklistApi.getProjectedEncounter({
          ssuIds: getIds(filter.selectedStudySites),
          encounterNames: getNames(filter.selectedEncounters),
          dueDate: null,
          startDate: dateRangeStart,
          endDate: dateRangeEnd,
          allEncountersPerPersonIncluded: true,
          encountersPerPerson: 1
        }),
        SchedulingWorklistApi.filterWorkListFutureAppointmentEvents({
          ssuIds: getIds(filter.selectedStudySites),
          encounterNames: getNames(filter.selectedEncounters),
          appointmentDate: null,
          appointmentStatus: 'SCHEDULED',
          startDate: dateRangeStart,
          endDate: moment
            .utc()
            .subtract(1, 'day')
            .endOf('day')
            .format(ISO_DATE_TIME_FORMAT),
          allEncountersPerPersonIncluded: true,
          encountersPerPerson: 1
        })
      ];
      Promise.all(requests)
        .then(([projected, checkIn]) => {
          const projectedModifiedDateData = projected.data.map(enc => {
            enc.startDate = moment.utc(enc.startDate).format(MMM_SPACE_YYYY);
            enc.endDate = moment.utc(enc.endDate).format(MMM_SPACE_YYYY);
            return enc;
          });
          const checkInModifiedDateData = checkIn.data.map(enc => {
            enc.appointmentDate = moment.utc(enc.appointmentDate).format(MMM_SPACE_YYYY);
            return enc;
          });
          const projectedGroupedByStartDate = groupBy(projectedModifiedDateData, e => e.startDate);
          const projectedGroupedByEndDate = groupBy(projectedModifiedDateData, e => e.endDate);
          const checkInGroupedByDate = groupBy(checkInModifiedDateData, e => e.appointmentDate);
          const result = [];
          displayDateRange.forEach(key =>
            result.push({
              date: key,
              projected: calculateAmountOfProjectedEncounters(
                projectedGroupedByStartDate,
                projectedGroupedByEndDate,
                key
              ),
              checkIn: key in checkInGroupedByDate ? checkInGroupedByDate[key].length : 0
            })
          );
          setTableData(result);
          setIsFetching(false);
        })
        .catch(() => setIsFetching(false));
    }
  }, [filter]);

  useEffect(
    function() {
      if (!isEmpty(ssus)) {
        setIsLoadingFilters(true);
        getFilters(ssus).then(function(data) {
          setEpochEncounterMap(data);

          const studies = getStudiesFrom(ssus);
          const sites = getSitesFrom(ssus);
          const epochs = getEpochsFrom(data);
          const encounters = getEncountersFrom(data);

          setAllStudies(studies);
          setAllSites(sites);
          setAllEpochs(epochs);
          setAllEncounters(encounters);

          const updatedSelectedStudies = !isEmpty(selectedStudies)
            ? intersectionWith(studies, selectedStudies, nameEquals)
            : studies;
          const updatedSelectedSites = !isEmpty(selectedSites)
            ? intersectionWith(sites, selectedSites, nameEquals)
            : sites;
          const updatedSelectedEpochs = !isEmpty(selectedEpochs)
            ? intersectionWith(epochs, selectedEpochs, nameEquals)
            : epochs;

          const updatedSelectedEncounters = !isEmpty(selectedEncounters)
            ? intersectionWith(encounters, selectedEncounters, nameEquals)
            : encounters;

          const updatedSelectedStudySites = !isEmpty(selectedStudySites)
            ? intersectionWith(ssus, selectedStudySites, (a, b) => a.id === b.id)
            : ssus;

          setSelectedStudies(updatedSelectedStudies);
          setSelectedSites(updatedSelectedSites);
          setSelectedEpochs(updatedSelectedEpochs);
          setSelectedEncounters(updatedSelectedEncounters);
          setSelectedStudySites(updatedSelectedStudySites);

          const updatedSites = !isEmpty(updatedSelectedStudies)
            ? getSitesFrom(ssus.filter(ssu => getIds(updatedSelectedStudies).includes(ssu.study.id)))
            : sites;
          const updatedEpochs = !isEmpty(updatedSelectedStudySites)
            ? getEpochsFrom(data.filter(el => getIds(updatedSelectedStudySites).includes(el.ssuId)))
            : epochs;
          const updatedEncounters = !isEmpty(updatedSelectedStudySites)
            ? getEncountersFrom(data.filter(el => getIds(updatedSelectedStudySites).includes(el.ssuId)))
            : encounters;

          setStudies(studies);
          setSites(updatedSites);
          setEpochs(updatedEpochs);
          setEncounters(updatedEncounters);

          setIsLoadingFilters(false);
          applyFilters(
            updatedSelectedStudySites,
            updatedSelectedStudies,
            updatedSelectedSites,
            updatedSelectedEpochs,
            updatedSelectedEncounters,
            studies,
            updatedSites,
            updatedEpochs,
            updatedEncounters
          );
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ssus]
  );

  const nameEquals = (a, b) => a.name === b.name;

  const onStudiesChange = newStudies => {
    if (!isEqual(selectedStudies, newStudies)) {
      setSelectedStudies(newStudies);
      const newStudySites = ssus.filter(ssu => getIds(newStudies).includes(ssu.study.id));
      !isEqual(newStudySites, selectedStudySites) && setSelectedStudySites(newStudySites);
      const newSites = getSitesFrom(newStudySites);
      !isEqual(sites, newSites) && setSites(newSites);
      onSitesChange(intersectionWith(newSites, selectedSites, nameEquals));
    }
  };

  const onSitesChange = newSites => {
    if (!isEqual(selectedSites, newSites)) {
      setSelectedSites(newSites);
      const newStudySites = ssus
        .filter(ssu => getIds(newSites).includes(ssu.site.id))
        .filter(ssu => getIds(selectedStudies).includes(ssu.study.id));
      !isEqual(newStudySites, selectedStudySites) && setSelectedStudySites(newStudySites);
      const newEpochs = getEpochsFrom(epochEncounterMap.filter(el => getIds(newStudySites).includes(el.ssuId)));
      const newEncounters = getEncountersFrom(epochEncounterMap.filter(el => getIds(newStudySites).includes(el.ssuId)));
      !isEqual(epochs, newEpochs) && setEpochs(newEpochs);
      !isEqual(encounters, newEncounters) && setEncounters(newEncounters);
      onEpochsChange(intersectionWith(newEpochs, selectedEpochs, nameEquals));
      onEncountersChange(intersectionWith(newEncounters, selectedEncounters, nameEquals));
    }
  };

  const onEpochsChange = newEpochs => {
    if (!isEqual(selectedEpochs, newEpochs)) {
      setSelectedEpochs(newEpochs);
      const newEncounters = getEncountersFrom(
        epochEncounterMap.filter(
          el => getIds(selectedStudySites).includes(el.ssuId) && getIds(newEpochs).includes(el.epochName)
        )
      );
      !isEqual(encounters, newEncounters) && setEncounters(newEncounters);
      onEncountersChange(intersectionWith(newEncounters, selectedEncounters, nameEquals));
    }
  };
  const onEncountersChange = newEncounters => {
    if (!isEqual(selectedEncounters, newEncounters)) {
      setSelectedEncounters(newEncounters);
    }
  };

  const applyFilters = (
    selectedStudySites,
    selectedStudies,
    selectedSites,
    selectedEpochs,
    selectedEncounters,
    studies,
    sites,
    epochs,
    encounters
  ) => {
    const newFilter = {
      selectedStudySites: selectedStudySites,
      selectedStudies: selectedStudies,
      selectedSites: selectedSites,
      selectedEpochs: selectedEpochs,
      selectedEncounters: selectedEncounters
    };
    setFilter({ ...newFilter, studies: studies, sites: sites, epochs: epochs, encounters: encounters });
    setSessionStorage(newFilter);
  };
  let validationError = false;
  if (!isEmpty(allStudies) && !isEmpty(allSites) && !isEmpty(allEpochs) && !isEmpty(allEncounters)) {
    validationError =
      isEmpty(filter.selectedStudies) ||
      isEmpty(filter.selectedSites) ||
      isEmpty(filter.selectedEpochs) ||
      isEmpty(filter.selectedEncounters);
  }

  const resetStudies = () => {
    onStudiesChange(studies);
    applyFilters(
      selectedStudySites,
      studies,
      selectedSites,
      selectedEpochs,
      selectedEncounters,
      studies,
      sites,
      epochs,
      encounters
    );
  };

  const resetSites = () => {
    onSitesChange(sites);
    setTimeout(
      applyFilters(
        selectedStudySites,
        selectedStudies,
        sites,
        selectedEpochs,
        selectedEncounters,
        studies,
        sites,
        epochs,
        encounters
      )
    );
  };

  const resetEpochs = () => {
    onEpochsChange(epochs);
    setTimeout(
      applyFilters(
        selectedStudySites,
        selectedStudies,
        selectedSites,
        epochs,
        selectedEncounters,
        studies,
        sites,
        epochs,
        encounters
      )
    );
  };

  const resetEncounters = () => {
    onEncountersChange(encounters);
    setTimeout(
      applyFilters(
        selectedStudySites,
        selectedStudies,
        selectedSites,
        selectedEpochs,
        encounters,
        studies,
        sites,
        epochs,
        encounters
      )
    );
  };

  return (
    <PendingScheduleWidgetContext.Provider
      value={{
        allStudies,
        allSites,
        allEpochs,
        allEncounters,
        studies,
        sites,
        epochs,
        encounters,
        selectedStudies,
        selectedSites,
        selectedEpochs,
        selectedEncounters,
        selectedStudySites,
        setSelectedStudySites,
        setSelectedStudies,
        tableData,
        isFetching,
        setSelectedSites,
        setSelectedEpochs,
        setSelectedEncounters,
        epochEncounterMap,
        filter,
        applyFilters: () =>
          applyFilters(
            selectedStudySites,
            selectedStudies,
            selectedSites,
            selectedEpochs,
            selectedEncounters,
            studies,
            sites,
            epochs,
            encounters
          ),
        resetStudies,
        resetSites,
        resetEpochs,
        resetEncounters,
        onStudiesChange,
        onSitesChange,
        onEpochsChange,
        onEncountersChange,
        validationError,
        ssus,
        isLoadingFilters
      }}
    >
      {children}
    </PendingScheduleWidgetContext.Provider>
  );
}
