import { MILESTONE_REPORT } from '../../../../constants/accessToPages';

import MilestoneReport from './MilestoneReport';

export const milestoneRouterConfig = {
  name: 'Milestone Report',
  path: '/timeline-milestone-report',
  component: MilestoneReport,
  access: [MILESTONE_REPORT]
};
