import React from 'react';

import { stylesContent } from '../../EncounterInvoiceTemplateStyles';

export const ReimbursementSubject = ({ item }) => {
  return (
    <div style={stylesContent.invoiceItem} className="row-item" data-testid="ledger-event-item">
      {item.content}
    </div>
  );
};
