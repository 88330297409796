import React, { useEffect, useState } from 'react';

import UserApiApi from '../../../api/patient/UserApiApi';
import { ACCESS_GLOBAL_ENVIRONMENT_SETTING } from '../../../constants/userOperations';
import { userHasAccessTo } from '../../../services/auth';

import { getSettingsById } from './globalEnvironmentSettingConstant';
import PreferenceTemplate from './PreferenceTemplate';

export default function GlobalEnvironmentSettingPreferences({ appInfo }) {
  const [globalSettings, setGlobalSettings] = useState([]);

  const getGlobalSettings = () => {
    UserApiApi.getGlobalEnvironmentSettings().then(res => {
      setGlobalSettings(res.data);
    });
  };

  const updateGlobalSettings = (checked, id) => {
    UserApiApi.changeGlobalEnvironmentSettings({ name: id, active: checked }).then(res => {
      setGlobalSettings([...globalSettings]);
    });
  };

  useEffect(() => {
    appInfo.features?.twilioEnabled && userHasAccessTo(ACCESS_GLOBAL_ENVIRONMENT_SETTING) && getGlobalSettings();
  }, [appInfo.features?.twilioEnabled]);

  return (
    <>
      {appInfo.features?.twilioEnabled && userHasAccessTo(ACCESS_GLOBAL_ENVIRONMENT_SETTING) && (
        <section className="px-3 pb-3 pt-2 my-3">
          <div className="header-section">
            <h5>Global environment setting</h5>
          </div>
          <div className="mt-3 mb-2 pl-3 py-3 preferences-message">
            <strong className="section-label">Caution!</strong>
            <br />
            This section includes global environment configuration. Changes could affect all environment users
          </div>
          {globalSettings.map(setting => (
            <PreferenceTemplate
              className="ml-3"
              item={{}}
              name={getSettingsById(setting.name).name}
              description={getSettingsById(setting.name).description}
              checked={setting.active}
              onChange={(i, checked) => {
                updateGlobalSettings(checked, setting.name);
              }}
            />
          ))}
        </section>
      )}
    </>
  );
}
