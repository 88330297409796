import React from 'react';

import Icon from '../../../../../common/general/Icon';
import { scDarkBlue100 } from '../../../../../constants/systemColors';
import { viewAllProgressNotes } from '../NoteService';

export const NotesColumn = ({
  hasAccessToView,
  hasAccessToEdit,
  notesInfo,
  sitePatientIdentifier,
  studySiteStatus
}) => {
  return (
    <React.Fragment>
      {hasAccessToView && (
        <Icon
          style={{ color: scDarkBlue100, fontSize: 30 }}
          onClick={() => viewAllProgressNotes(sitePatientIdentifier, studySiteStatus, hasAccessToEdit, notesInfo)}
        >
          description
        </Icon>
      )}
    </React.Fragment>
  );
};
