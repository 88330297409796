export default function setCountryId(state, { type, payload: { path, value } }) {
  return {
    ...state,
    mileageAddresses: {
      ...state.mileageAddresses,
      [path]: {
        ...state.mileageAddresses[path],
        countryId: value,
        regionId: '',
        city: '',
        address1: '',
        address2: '',
        zipCode: ''
      }
    }
  };
}
