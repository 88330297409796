import React, { Component } from 'react';
import { forIn, slice, sortBy } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import { StudySiteStartupApi } from '../../../../../api';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import DateInput from '../DateInput/DateInput';

import 'react-datetime/css/react-datetime.css';

class UpdateMilestonesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rawMilestones: [],
      milestones: {}
    };
  }

  onSubmitData = () => {
    const { milestones } = this.state;
    const payload = [];
    forIn(milestones, (value, key) => {
      if (value.value || value.value === '') {
        payload.push({
          ssuId: value.ssuId,
          code: value.codeId,
          value: value.value,
          actual: value.actual
        });
      }
    });
    StudySiteStartupApi.saveMilestones(payload).then(res => {
      this.props.refreshPage();
    });
    this.props.modalBox.close();
  };

  componentDidMount() {
    Promise.all([
      StudySiteStartupApi.getAllStartupCodes(),
      StudySiteStartupApi.getAllMilestonesForSsu(this.props.ssuId)
    ]).then(values => {
      const msDefinitions = values[0] && sortBy(values[0].data, 'order');
      const data = values[1] && values[1].data;

      const dictionary = {};

      msDefinitions.forEach(def => {
        dictionary[def.code] = {};
        dictionary[def.code].ssuId = this.props.ssuId;
        dictionary[def.code].codeId = def.id;
        dictionary[def.code].codeShortName = def.code;
        dictionary[def.code].codeFullName = def.value;
        dictionary[def.code].order = def.order;
        dictionary[def.code].value = null;
        dictionary[def.code].actual = false;
      });

      data.forEach(ms => {
        dictionary[ms.codeShortName].value = ms.value;
        dictionary[ms.codeShortName].actual = ms.actual;
      });

      this.setState({
        rawMilestones: data,
        milestones: dictionary
      });
    });
  }

  handleDateChange = (date, milestone) => {
    this.setState({
      milestones: {
        ...this.state.milestones,
        [milestone]: {
          ...this.state.milestones[milestone],
          value: moment(date).format('YYYY-MM-DD')
        }
      }
    });
  };

  handleActualChange = milestone => {
    if (this.state.milestones[milestone].value) {
      this.setState({
        milestones: {
          ...this.state.milestones,
          [milestone]: {
            ...this.state.milestones[milestone],
            actual: !this.state.milestones[milestone].actual
          }
        }
      });
    }
  };

  handleDelete = milestone => {
    this.setState({
      milestones: {
        ...this.state.milestones,
        [milestone]: {
          ...this.state.milestones[milestone],
          value: '',
          actual: false
        }
      }
    });
  };

  render() {
    const inputs = [];
    const greenlights = [];
    forIn(this.state.milestones, ms => {
      const field = (
        <DateInput
          name={ms.codeShortName}
          fullName={ms.codeFullName}
          value={ms.value}
          actual={ms.actual}
          onDateChange={this.handleDateChange}
          onActualChange={this.handleActualChange}
          onDelete={this.handleDelete}
          key={ms.codeId}
        />
      );
      ms.codeFullName.includes('Greenlight') ? greenlights.push(field) : inputs.push(field);
    });
    const half = Math.ceil(inputs.length / 2);
    const left = slice(inputs, 0, half);
    const right = slice(inputs, half, inputs.length);

    // in order to make greenlight dates be in a separate row, handle these manually
    const sponsorGreenlight = greenlights[0];
    const elligoGreenlight = greenlights[1];

    left.push(sponsorGreenlight);
    right.push(elligoGreenlight);

    return (
      <>
        <ModalBoxes.Header>Update Milestones</ModalBoxes.Header>
        <ModalBoxes.Body>
          <div className="row mt-3 mx-0">
            <div className="col-md-6">{left}</div>
            <div className="col-md-6">{right}</div>
          </div>
        </ModalBoxes.Body>
        <ModalBoxes.Footer>
          <div className="row col-12 justify-content-between">
            <div className="col-12 p-0 text-right">
              <Button onClick={this.onSubmitData}>Update</Button>
            </div>
          </div>
        </ModalBoxes.Footer>
      </>
    );
  }
}

UpdateMilestonesModal.className = 'update-milestones-modal';
UpdateMilestonesModal.size = 'w650';

export default UpdateMilestonesModal;

UpdateMilestonesModal.defaultProps = {
  onSubmit: null
};

UpdateMilestonesModal.propTypes = {
  onSubmit: PropTypes.func
};
