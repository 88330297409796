import React, { useCallback, useEffect, useState } from 'react';
import { isEqual } from 'lodash/lang';

import Select from '../../../../../../../../../common/data-entry/Select';
import Button from '../../../../../../../../../common/general/Button';
import { ToggleSelect } from '../../../../../../../../../common/inputs/ToggleSelect/ToggleSelect';

const ConditionalLogicConfiguration = ({ selectedToggleFields, addNewConfiguration }) => {
  const disabledValueForToggle = [
    { name: 'Select toggle', value: false },
    { name: 'Select toggle', value: true }
  ];

  const [selectedToggle, setSelectedToggle] = useState(null);
  const [answersForSelectedToggle, setAnswersForSelectedToggle] = useState(disabledValueForToggle);
  const [selectedAnswerForHiding, setSelectedAnswerForHiding] = useState(null);

  useEffect(() => {
    if (selectedToggle) {
      setSelectedAnswerForHiding(null);
      const newAnswers = selectedToggle.codeDefinationList.map(element => ({
        name: element.name,
        value: element.name
      }));
      setAnswersForSelectedToggle(newAnswers);
    } else {
      setAnswersForSelectedToggle(disabledValueForToggle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedToggle]);

  const onChangeToggle = useCallback(
    answer => {
      if (!isEqual(answer, selectedAnswerForHiding)) {
        setSelectedAnswerForHiding(answer);
      }
    },
    [selectedAnswerForHiding]
  );

  return (
    <>
      <div className="custom-logic-question">Add configuration based on selected value</div>
      <div className="add-new-conditional-logic-wrapper">
        <Select
          placeholder="Select"
          clearSearchOnSelection
          dataSource={selectedToggleFields}
          optionLabelKey="name"
          optionValueKey="question"
          onChange={newToggle => {
            if (!isEqual(selectedToggle, newToggle)) setSelectedToggle(newToggle);
          }}
          deselectOnSelectedOptionClick={false}
          disabled={false}
          validate={false}
          clearable={false}
          value={selectedToggle}
          className="select-control-item"
        />
        <Button
          disabled={!selectedToggle || !selectedAnswerForHiding}
          onClick={() =>
            addNewConfiguration({
              conditionFieldAnswerName: selectedAnswerForHiding,
              conditionFieldName: selectedToggle.name
            })
          }
        >
          Add
        </Button>
      </div>
      {selectedToggle && (
        <>
          <div className="custom-logic-question">When to hide:</div>
          <ToggleSelect
            listOfItem={answersForSelectedToggle}
            disabled={isEqual(answersForSelectedToggle, disabledValueForToggle)}
            onChange={onChangeToggle}
          />
        </>
      )}
    </>
  );
};

export default ConditionalLogicConfiguration;
