import { useMemo } from 'react';
import { isEmpty } from 'lodash/lang';

import {
  NON_PROTOCOL,
  PROJECTED,
  SITUATIONAL,
  UNEXPECTED
} from '../../../../../../../../../../constants/encounterConstants';

import CardHeaderLeftDefault from './card-headers/CardHeaderLeftDefault';
import CardHeaderLeftUnexpectedAppointment from './card-headers/CardHeaderLeftUnexpectedAppointment';
import CardHeaderMiddleDefault from './card-headers/CardHeaderMiddleDefault';
import CardHeaderRightDefault from './card-headers/CardHeaderRightDefault';
import CardHeaderWrapperDefault from './card-headers/CardHeaderWrapperDefault';
import AppointmentWindow from './card-sections/AppointmentWindow';
import ProtocolWindow from './card-sections/ProtocolWindow';
import ScheduledAppointments from './card-sections/ScheduledAppointments';
import HeaderDefault from './HeaderDefault';
import HeaderProjected from './HeaderProjected';
import HeaderProjectedNoAppointments from './HeaderProjectedNoAppointments';
import HeaderReview from './HeaderReview';
import HeaderUnexpected from './HeaderUnexpected';

const defaultTemplate = {
  Header: HeaderDefault,
  CardHeaderWrapper: CardHeaderWrapperDefault,
  CardHeaderLeft: CardHeaderLeftDefault,
  CardHeaderMiddle: CardHeaderMiddleDefault,
  CardHeaderRight: CardHeaderRightDefault,
  CardMiddleSection: NullComponent,
  CardSectionLast: ScheduledAppointments
};

const unexpectedAppointmentTemplate = {
  ...defaultTemplate,
  Header: HeaderUnexpected,
  CardHeaderWrapper: NullComponent,
  CardHeaderLeft: CardHeaderLeftUnexpectedAppointment,
  CardHeaderMiddle: NullComponent,
  CardHeaderRight: NullComponent
};

const situationalEncounter = {
  ...defaultTemplate,
  CardHeaderMiddle: CardHeaderMiddleDefault
};

const projectedTemplate = {
  ...defaultTemplate,
  Header: HeaderProjected,
  CardHeaderWrapper: NullComponent,
  CardHeaderMiddle: NullComponent,
  CardHeaderRight: NullComponent,
  CardMiddleSection: AppointmentWindow
};

const projectedNoAppointmentsTemplate = {
  ...projectedTemplate,
  Header: HeaderProjectedNoAppointments,
  CardMiddleSection: ProtocolWindow,
  CardSectionLast: NullComponent
};

const nonProtocolAndUnexpectedTemplate = {
  ...defaultTemplate,
  Header: NullComponent,
  CardSectionLast: NullComponent
};

export default function useEncounterSegmentTemplate({
  encounterType,
  sitePatientEncounter,
  encounterEvents,
  noAppointments
}) {
  const isProjected = encounterType === PROJECTED;
  const isProjectedNoAppointments = isProjected && noAppointments;
  const isUnexpectedAppointment = encounterType === UNEXPECTED && !sitePatientEncounter;
  const isSituational = encounterType === SITUATIONAL;
  const isNonProtocolAndUnexpected = [NON_PROTOCOL, UNEXPECTED].includes(encounterType) && isEmpty(encounterEvents);

  const isReview = ['PI_REVIEW', 'SM_REVIEW'].includes(sitePatientEncounter?.encounterStatus);

  return useMemo(
    function() {
      if (isProjectedNoAppointments) return projectedNoAppointmentsTemplate;
      if (isProjected) return projectedTemplate;
      if (isSituational) return situationalEncounter;
      if (isUnexpectedAppointment) return unexpectedAppointmentTemplate;
      if (isNonProtocolAndUnexpected) return reviewTemplateModifier(nonProtocolAndUnexpectedTemplate, isReview);
      return reviewTemplateModifier(defaultTemplate, isReview);
    },
    [
      isProjectedNoAppointments,
      isProjected,
      isSituational,
      isUnexpectedAppointment,
      isNonProtocolAndUnexpected,
      isReview
    ]
  );
}

function reviewTemplateModifier(template, isReview) {
  if (!isReview) return template;

  return {
    ...template,
    Header: HeaderReview
  };
}

function NullComponent({ children }) {
  return children || null;
}
