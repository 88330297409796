import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Collapse from 'rc-collapse';

import { PatientTravelApi } from '../../../../../../api';
import { LoadingHandler } from '../../../../../../common/elements/Loader/LoadingHandler';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import HistoryBlock, { TRAVEL_PREFERENCES } from '../../../../../root/Container/HistoryBlock';

import AirlinePreferences from './AirlinePreferences/AirlinePreferences';
import HotelPreferences from './HotelPreferences/HotelPreferences';
import PreferredPickupAddress from './PreferredPickupAddress/PreferredPickupAddress';

import './TravelPreferences.scss';

export default function TravelPreferences() {
  const { patientId } = useParams();
  const [loading, setLoading] = useState(true);
  const [activeKey, setActiveKey] = useState(-1);
  const [travelPreferences, setTravelPreferences] = useState(null);
  const { preferredPickupAddressEdited, airlinePreferencesEdited, hotelPreferencesEdited } = travelPreferences || {};

  useEffect(
    function() {
      setLoading(true);
      PatientTravelApi.getTravelPreferences(patientId).then(
        function({ data }) {
          setTravelPreferences(data);
          setLoading(false);
        },
        function(error) {
          console.log(error);
        }
      );
    },
    [patientId]
  );

  useEffect(
    function() {
      if (!preferredPickupAddressEdited && !airlinePreferencesEdited && !hotelPreferencesEdited) {
        return;
      }
      const unblock = HistoryBlock.block(TRAVEL_PREFERENCES, function(discard) {
        ModalBoxes.confirm({
          content: `Are you sure you want to discard the changes made to Travel Preferences?`,
          title: `Discard Travel Preferences Changes`,
          confirmButton: 'Yes',
          cancelButton: 'No'
        })
          .then(() => {
            discard();
          })
          .catch(() => {});
        return false;
      });
      return function() {
        unblock();
      };
    },
    [airlinePreferencesEdited, hotelPreferencesEdited, preferredPickupAddressEdited]
  );

  return (
    <Collapse
      activeKey={activeKey}
      onChange={key => setActiveKey(key)}
      expandIcon={({ isActive }) => (
        <span className="rc-collapse-header-expand-icon material-icons">
          {`keyboard_arrow_${isActive ? 'up' : 'down'}`}
        </span>
      )}
      className="eds-patient-travel-preferences"
    >
      <Collapse.Panel
        header={
          <React.Fragment>
            <div className="rc-collapse-header-title">TRAVEL PREFERENCES</div>
            <div className="rc-collapse-header-content"></div>
          </React.Fragment>
        }
        key="1"
      >
        <LoadingHandler complete={!loading} dark>
          {() => {
            return (
              <>
                <PreferredPickupAddress
                  initialData={travelPreferences.preferredPickupAddress}
                  setTravelPreferences={setTravelPreferences}
                />
                <AirlinePreferences
                  initialData={travelPreferences.airlinePreferences}
                  setTravelPreferences={setTravelPreferences}
                />
                <HotelPreferences
                  initialData={travelPreferences.hotelPreferences}
                  setTravelPreferences={setTravelPreferences}
                />
              </>
            );
          }}
        </LoadingHandler>
      </Collapse.Panel>
    </Collapse>
  );
}
