import { toast } from 'react-toastify';

import { ERROR_ICON, INFO_ICON, SUCCESS_ICON, WARNING_ICON } from '../../constants/notificationIcons';

const NotificationManager = {
  success: (text, className) => toast.success(text, { icon: SUCCESS_ICON, className }),
  warning: (text, className) => toast.warn(text, { icon: WARNING_ICON, className }),
  error: (text, className) => toast.error(text, { icon: ERROR_ICON, className }),
  info: (text, className) => toast.info(text, { icon: INFO_ICON, className })
};

export default NotificationManager;
