export function getAllOptionalItems(allInputs, onlyCheckedItems) {
  let optionalItems = [];
  if (allInputs && allInputs.length && allInputs[0].optional && allInputs[0].optional.length) {
    optionalItems = allInputs[0].optional[0].itemDefinitionList;
  }
  return onlyCheckedItems ? optionalItems.filter(e => e.isChecked) : optionalItems;
}

export function getAllCustomItems(allInputs) {
  let customItems = [];
  if (allInputs && allInputs.length && allInputs[0].custom && allInputs[0].custom.length) {
    customItems = allInputs[0].custom.map(e => e.customItemDefinationList[0]);
  }
  return customItems;
}

export function getAllToggles(allInputs) {
  return [...getAllOptionalItems(allInputs, true), ...getAllCustomItems(allInputs)].filter(
    e => e.inputType === 'radio'
  );
}

export function addConditionFieldToEachCustomItemWithConfiguration(allInputs) {
  //adding appropriate conditionField model to each custom field with fieldConfigurationResponse
  const allOptionalItems = getAllOptionalItems(allInputs, false);
  const allCustomItems = getAllCustomItems(allInputs);
  const allToggles = [...allOptionalItems, ...allCustomItems].filter(e => e.inputType === 'radio');
  [...allOptionalItems, ...allCustomItems].forEach(item => {
    const fieldConfigurationResponse = item?.fieldConfigurationResponse;
    if (fieldConfigurationResponse && fieldConfigurationResponse?.conditionFieldName) {
      const conditionField = allToggles.find(
        e => e.sequence === item.fieldConfigurationResponse.conditionFieldSequence
      );
      Object.defineProperty(fieldConfigurationResponse, 'conditionField', { value: conditionField });
    }
  });
}
