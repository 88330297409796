import React, { useCallback, useMemo } from 'react';
import { sortBy } from 'lodash/collection';
import { isEmpty, isEqual, isNull } from 'lodash/lang';
import PropTypes from 'prop-types';

import MultiSelect from '../../../../../../../../common/data-entry/MultiSelect/MultiSelect';
import { useTableItemGroupActions, useTableItemGroupState } from '../../TableItemGroupContext/TableItemGroupContext';
import fieldPropTypes from '../fieldPropTypes';

export default function MultiselectField({ field }) {
  const { fieldUid } = field;
  const { isPreviewOnly } = useTableItemGroupState();
  const { fieldOnChange } = useTableItemGroupActions();

  const onChange = useCallback(
    function(options, selectedOptions) {
      if (isEqual(options, selectedOptions)) {
        return;
      }
      fieldOnChange(!isEmpty(options) ? JSON.stringify(options?.map(el => el.id)) || null : null, fieldUid);
    },
    [fieldOnChange, fieldUid]
  );

  return <MultiselectFieldReadonly field={field} onChange={onChange} disabled={isPreviewOnly} />;
}

export function MultiselectFieldReadonly({ field, onChange, ...rest }) {
  const { fieldValue, codeDefinition } = field;

  const options = useMemo(
    function() {
      return sortBy(codeDefinition, 'sequence').map(({ name, decode: id }) => ({ name, id }));
    },
    [codeDefinition]
  );

  const selectedOptions = useMemo(
    function() {
      const selectedList = JSON.parse(fieldValue);
      return options.filter(function({ id }) {
        return selectedList?.includes(id);
      });
    },
    [fieldValue, options]
  );

  return (
    <MultiSelect
      title={selectedOptions?.map(e => e.name)?.join(', ') || ''}
      placeholder="Select"
      clearSearchOnSelection
      dataSource={options}
      searchable
      clearable
      customOptionTemplateFunction={customOptionTemplateFunction}
      value={selectedOptions}
      legacyLook
      className="multi-select-field"
      onChange={options => onChange && onChange(options, selectedOptions)}
      {...rest}
    />
  );
}

function customOptionTemplateFunction({ name }) {
  return <span title={name}>{name}</span>;
}

const propTypes = {
  field: PropTypes.shape({
    ...fieldPropTypes,
    fieldType: PropTypes.oneOf(['multiselect', 'multiselectCT']).isRequired,
    fieldValue: function(props, propName, componentName) {
      const value = props[propName];
      if (!isNull(value) && isEmpty(value)) {
        return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed.`);
      }
    }
  })
};

MultiselectFieldReadonly.defaultProps = {
  disabled: true
};

MultiselectFieldReadonly.propTypes = propTypes;
MultiselectField.propTypes = propTypes;
