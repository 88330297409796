import React from 'react';

import { StudySiteEnrollmentPredictionApi } from '../../../../../api';
import Button from '../../../../../common/general/Button';
import { onFileSave } from '../../../../../services/handlers';

export function ExportEnrollment({ dateToShow, studySites, selectedTherapeuticArea }) {
  const exportEnrollments = () => {
    const ssuAndTherapeuticAreaRequest = {
      studySiteIds: studySites.map(ssu => ssu.uniqueIdentifier),
      therapeuticArea: selectedTherapeuticArea?.areaValue
    };
    StudySiteEnrollmentPredictionApi.export(ssuAndTherapeuticAreaRequest, dateToShow.format('YYYY-MM-DD')).then(
      onFileSave
    );
  };

  return (
    <Button onClick={exportEnrollments} size="h28" priority="medium">
      Export
    </Button>
  );
}

export default ExportEnrollment;
