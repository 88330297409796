import React, { useCallback, useEffect, useState } from 'react';

import { ItemGroupTemplatesApi, TerminologyVersionApi } from '../../../../api';
import Button from '../../../../common/general/Button';
import Icon from '../../../../common/general/Icon';
import { PageInfoHeader } from '../../../PageInfoHeader/PageInfoHeader';
import { generateUrlByKey } from '../../../root/router';

import TemplatesFilters from './TemplatesFilters/TemplatesFilters';
import { FILTERS_MAP } from './constants';
import TemplatesTable from './TemplatesTable';

import './ItemGroupTemplates.scss';

export default function ItemGroupTemplates() {
  const [templates, setTemplates] = useState([]);
  const [versions, setVersions] = useState([]);
  const [filters, setFilters] = useState(FILTERS_MAP);
  const getAllTemplates = useCallback(function() {
    ItemGroupTemplatesApi.getAllItemGroupTemplates().then(function({ data }) {
      setTemplates(data);
    });
  }, []);

  useEffect(
    function() {
      getAllTemplates();
      TerminologyVersionApi.getAllTerminologyVersionGroups().then(function({ data }) {
        setVersions(data);
      });
    },
    [getAllTemplates]
  );

  return (
    <div className="eds-item-group-templates">
      <PageInfoHeader
        right={
          <Button size="h28" to={generateUrlByKey('Item Group Templates.Template Setup')}>
            <Icon>add</Icon> Template
          </Button>
        }
      >
        <TemplatesFilters templates={templates} setFilters={setFilters} filters={filters} />
      </PageInfoHeader>
      <div className="igt-content">
        <TemplatesTable getAllTemplates={getAllTemplates} versions={versions} filters={filters} templates={templates} />
      </div>
    </div>
  );
}
