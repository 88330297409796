import axios from 'axios';

const ROOT = 'api/v1/terminology-versions';

const TerminologyVersionApi = {
  getAllTerminologyVersionGroups() {
    return axios.get(`${ROOT}/groups`);
  }
};

export default TerminologyVersionApi;
