import React from 'react';
import cx from 'classnames';

import Toggle from '../../../common/data-entry/Toggle';

export default function PreferenceTemplate({ item, name, description, checked, onChange, className, disabled }) {
  return (
    <div className={cx('preference mb-2', className)}>
      <div>
        <div className="name">{name}</div>
        <div className="description">{description}</div>
      </div>
      <Toggle name={name} checked={checked} disabled={disabled} onChange={e => onChange(item, e.target.checked)} />
    </div>
  );
}
