import { toCoins } from './financial';

export function getBudgetEventType(budgetEventType, defaultType) {
  switch (budgetEventType) {
    case 'ITEM_GROUP_PATIENT_STIPEND':
      return budgetEventType;
    case 'ITEM_GROUP_GENERAL':
      return budgetEventType;
    default:
      return defaultType;
  }
}

export function createBudgetRowForm(
  name,
  overhead,
  withholding,
  clientAmount,
  siteAmount,
  patientAmount,
  vendorAmount,
  type,
  finVendorId,
  finTriggerId,
  id,
  vendorAmountType,
  siteAmountType,
  patientAmountType,
  nonInvoiceable
) {
  return {
    name,
    overhead,
    withholding,
    nonInvoiceable,
    clientAmount: toCoins(clientAmount),
    siteAmount: toCoins(siteAmount),
    patientAmount: toCoins(patientAmount),
    vendorAmount: toCoins(vendorAmount),
    vendorAmountType,
    siteAmountType,
    patientAmountType,
    finVendorId,
    type,
    finTriggerId,
    id
  };
}
