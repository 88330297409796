import { handleActions } from 'redux-actions';

import { options, UPDATE } from './actions';

export default handleActions(
  {
    [UPDATE]: function(state, { payload }) {
      return payload;
    }
  },
  {},
  options
);
