import React, { useContext, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import * as PropTypes from 'prop-types';

import ApiService from '../../../../../../../../api/patient/PatientInfoPageApi';
import Button from '../../../../../../../../common/general/Button';
import Icon from '../../../../../../../../common/general/Icon';
import NotificationManager from '../../../../../../../../common/notifications/NotificationManager';
import { MEDICAL_RECORD_NUMBER_UPDATED } from '../../../../../../../../constants/notificationMessages';
import { onRequestError } from '../../../../../../../../services/handlers';
import { PatientInfoContext } from '../../../PatientInfoContext';

import './MedicalRecordNumberColumn.scss';

export default function MedicalRecordNumberColumn({
  patientMedicalRecordNumber,
  ssuPatientId,
  granted,
  isAllowedToManage
}) {
  const { setPatientMedicalRecordNumberBySsuPatientId } = useContext(PatientInfoContext);
  const [medicalRecordNumber, setMedicalRecordNumber] = useState(patientMedicalRecordNumber);
  const [edit, setEdit] = useState(false);
  const inputRef = useRef();

  useEffect(
    function() {
      setMedicalRecordNumber(patientMedicalRecordNumber);
    },
    [patientMedicalRecordNumber]
  );

  useEffect(
    function() {
      if (edit) {
        inputRef.current.focus();
      } else {
        setMedicalRecordNumber(patientMedicalRecordNumber);
      }
    },
    [edit, patientMedicalRecordNumber]
  );

  return (
    <div className="ish-table-cell medical-record-number-column">
      {(function() {
        if (!granted) {
          return <span className="mrn-is-hidden">MRN is hidden</span>;
        }
        if (edit || patientMedicalRecordNumber) {
          return (
            <div className={cx('medical-record-number-field', { 'medical-record-number-field-edit': edit })}>
              <input
                ref={inputRef}
                type="text"
                value={fixControlledValue(medicalRecordNumber)}
                onChange={onChange}
                disabled={!edit}
                title={fixControlledValue(medicalRecordNumber)}
              />
              <div className="medical-record-number-field-buttons">
                {edit ? (
                  <React.Fragment>
                    <Button size="h28" priority="low" onClick={saveHandler}>
                      <Icon>save</Icon>
                    </Button>
                    <Button size="h28" priority="low" onClick={cancelHandler}>
                      <Icon>close</Icon>
                    </Button>
                  </React.Fragment>
                ) : (
                  <Button
                    disabled={!isAllowedToManage}
                    size="h28"
                    priority="low"
                    onClick={editHandler}
                    className="medical-record-number-field-button-edit"
                  >
                    <Icon>edit</Icon>
                  </Button>
                )}
              </div>
            </div>
          );
        }

        return (
          <Button disabled={!isAllowedToManage} size="h28" priority="medium" onClick={editHandler}>
            <Icon>add</Icon>
          </Button>
        );
      })()}
    </div>
  );

  function editHandler() {
    setEdit(true);
  }

  function cancelHandler() {
    setEdit(false);
  }

  function saveHandler() {
    ApiService.updateSsuPatientMedicalRecordNumber(ssuPatientId, medicalRecordNumber).then(res => {
      if (res) {
        setPatientMedicalRecordNumberBySsuPatientId(ssuPatientId, medicalRecordNumber);
        setEdit(false);
        NotificationManager.success(MEDICAL_RECORD_NUMBER_UPDATED);
      }
    }, onRequestError);
  }

  function onChange({ target }) {
    setMedicalRecordNumber(target.value);
  }
}
MedicalRecordNumberColumn.propTypes = {
  patientMedicalRecordNumber: PropTypes.string,
  ssuPatientId: PropTypes.string,
  isAllowedToAddOrEdit: PropTypes.bool
};

function fixControlledValue(value) {
  if (typeof value === 'undefined' || value === null) {
    return '';
  }
  return value;
}
