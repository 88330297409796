import { ADD_BUDGET_SITE, GET_BUDGET_SITES } from '../../actions/actionTypes';

export default function budgetSiteReducer(state, action) {
  if (typeof state === 'undefined') {
    state = [];
  }
  switch (action.type) {
    case GET_BUDGET_SITES:
      return action.payload;
    case ADD_BUDGET_SITE:
      return [action.payload, ...state];
    default:
      return state;
  }
}
