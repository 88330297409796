import { map, reduce, sortBy } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

export const SITUATIONAL_ENCOUNTERS_EPOCH_ID = 'SituationalId';

export function sortEpochsAndEncountersByProtocolSequence(epochs) {
  return map(sortBy(epochs, 'protocolSequence'), function(epoch) {
    return { ...epoch, encounters: sortBy(epoch.encounters, 'protocolSequence') };
  });
}

export function sortEpochsAndEncountersByOriginSequence(epochs, originEpochs) {
  if (isEmpty(originEpochs)) {
    return epochs;
  }
  const originEpochMap = reduce(
    originEpochs,
    function(accumulator, epoch, index) {
      accumulator[epoch.epochIdentifier || `new-${index}`] = {
        index,
        originEncounterMap: reduce(
          epoch.encounters,
          function(accumulator, encounter, index) {
            accumulator[encounter.encounterIdentifier || `new-${index}`] = index;
            return accumulator;
          },
          {}
        )
      };
      return accumulator;
    },
    {}
  );

  return sortBy(
    map(epochs, function(epoch, protocolSequence) {
      return {
        ...epoch,
        protocolSequence,
        encounters: sortBy(
          map(epoch.encounters, function(encounter, protocolSequence) {
            return { ...encounter, protocolSequence };
          }),
          function(o) {
            if (!epoch.epochIdentifier || !o.encounterIdentifier) {
              return null;
            }
            return originEpochMap[epoch.epochIdentifier].originEncounterMap[o.encounterIdentifier];
          }
        )
      };
    }),
    o => (o.epochIdentifier ? originEpochMap[o.epochIdentifier].index : null)
  );
}
