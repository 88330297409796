import axios from 'axios';

const ROOT = 'api/v1/investigator-worklist';

const InvestigatorWorkListApi = {
  getInvestigatorWorklistData(data, hideLoader = true, request = null) {
    return axios.post(`${ROOT}`, data, { hideLoader: hideLoader, cancelToken: request.token });
  },
  getEncounterNamesBySsuIds(ssuIds, statuses) {
    return axios.post(
      `${ROOT}/encounter`,
      {
        ssuIds,
        statuses
      },
      { hideLoader: true }
    );
  },
  getEncounterNamesAndStudyNamesBySsuIds(ssuIds, statuses) {
    return axios.post(
      `${ROOT}/encounter-with-study`,
      {
        ssuIds,
        statuses
      },
      { hideLoader: false }
    );
  },
  getCountOfReviewsBySsuIds(ssuIds) {
    return axios.post(`${ROOT}/count`, ssuIds, { hideLoader: false });
  },
  getAdverseEventsForInvestigatorWorkListByRequest(data, hideLoader = true, request = null) {
    return axios.post(`api/v1/adverse-events/events-pi`, data, { hideLoader: hideLoader, cancelToken: request.token });
  },
  getAdverseEventsSignedByPerGroupValuesForTable(rowIdToTemplateId, request) {
    return axios.post(`api/v1/adverse-events/signers-per-groups`, rowIdToTemplateId, {
      hideLoader: true,
      cancelToken: request.token
    });
  },
  countAdverseEventsByInvestigatorStatus(studyIds) {
    return axios.post(`api/v1/adverse-events/count-pi`, studyIds, { hideLoader: false });
  },
  getInvestigatorWorkListAdverseEventSignedByFilterSignersAndSmReviewers(ssuIds, piStatuses) {
    return axios.post(
      `api/v1/adverse-events/filters/signers`,
      {
        ssuIds,
        piStatuses,
        smStatuses: []
      },
      { hideLoader: false }
    );
  },
  getInvestigatorWorkListSignedByFilterSignersAndSmReviewers(ssuIds, statuses) {
    return axios.post(
      `${ROOT}/signers`,
      {
        ssuIds,
        statuses
      },
      { hideLoader: true }
    );
  },
  getWorkListSignedByPerGroupValuesForTable(patientEncounterIds, request) {
    return axios.post(`${ROOT}/table/signers-per-groups`, patientEncounterIds, {
      hideLoader: true,
      cancelToken: request.token
    });
  }
};

export default InvestigatorWorkListApi;
