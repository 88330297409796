import React, { useContext, useMemo } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import cx from 'classnames';
import { some } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

import Checkbox from '../../../../../../../common/data-entry/InputSelectors/Checkbox';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { scBlue100 } from '../../../../../../../constants/systemColors';
import { TableSetupContext } from '../../../../../../../contexts/TableSetupContext';
import TableFieldCustomItem from '../TableField/TableFieldCustomItem/TaleFieldCustomItem';
import { onDragEnd } from '../TableField/TableFieldItem/EditCustomOptionListModal/EditCustomOptionListModal';
import TableFieldItem from '../TableField/TableFieldItem/TableFieldItem';

const GeneralSettings = () => {
  const tableSetupContext = useContext(TableSetupContext);

  const allItemsSelected = useMemo(() => {
    if (tableSetupContext.generalFields.length) {
      return !some(tableSetupContext.generalFields, ['isChecked', false]);
    }
    return false;
  }, [tableSetupContext.generalFields]);

  const fieldsForDisplay = useMemo(() => {
    if (tableSetupContext.selectedOnlyGeneral) {
      return tableSetupContext.generalFields.filter(field => field.isChecked);
    }
    return tableSetupContext.generalFields;
  }, [tableSetupContext.generalFields, tableSetupContext.selectedOnlyGeneral]);

  const uncheckAllItems = () => {
    ModalBoxes.confirm({
      content: 'Unchecking this checkbox will remove field configurations in this Item Group. Uncheck anyway?',
      confirmButton: 'Yes',
      cancelButton: 'No',
      className: 'edit-custom-field-logic-modal',
      title: 'Toggle Configuration'
    })
      .then(() => {
        tableSetupContext.toggleSelectAllItems(allItemsSelected, true);
      })
      .catch(e => {});
  };

  return (
    <div className="setting-wrapper field-wrapper">
      <div className="setting-header">
        <h5>Overview</h5>
        <div className="view-options">
          <Checkbox
            label="Selected Only"
            color={scBlue100}
            checked={tableSetupContext.selectedOnlyGeneral}
            onChange={() => {
              tableSetupContext.setSelectedOnlyGeneral(!tableSetupContext.selectedOnlyGeneral);
              tableSetupContext.reorderSelectedItems(true);
            }}
          />
          <Checkbox
            label="All Overview Items"
            color={scBlue100}
            checked={allItemsSelected}
            onChange={() => {
              if (
                allItemsSelected &&
                (some(tableSetupContext.generalFields, item => !isEmpty(item.fieldConfigurationResponse)) ||
                  some(tableSetupContext.generalFields, item => !isEmpty(item.hideAllConfiguration)))
              ) {
                uncheckAllItems();
              } else {
                tableSetupContext.toggleSelectAllItems(allItemsSelected, true);
              }
            }}
          />
        </div>
      </div>
      <div className="labels-row">
        <span className="column big">CDASH Items</span>
        <span className="column big">Updated CDASH Items</span>
        <span className="column big">Answer Type</span>
        <span className="column normal">Item Sequence</span>
        <span className="column short">Investigator Field</span>
        <span className="column short" />
      </div>
      {tableSetupContext.generalFields?.length ? (
        <DragDropContext
          onDragEnd={result =>
            onDragEnd(
              result,
              tableSetupContext.generalFields,
              tableSetupContext.setGeneralFields,
              tableSetupContext.updateConditionalLogicForItems,
              tableSetupContext.fieldList
            )
          }
        >
          <Droppable droppableId="general-fields">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef} className="label-list">
                {fieldsForDisplay.map((item, index) => (
                  <Draggable key={item.name} draggableId={item.name} index={index}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        {item?.type === 'CUSTOM' ? (
                          <TableFieldCustomItem
                            className={cx('field-item-wrapper custom', { dragging: snapshot.isDragging })}
                            item={item}
                            itemIndex={index}
                            general={true}
                          />
                        ) : (
                          <TableFieldItem
                            className={cx('field-item-wrapper', { dragging: snapshot.isDragging })}
                            item={item}
                            itemIndex={index}
                            general={true}
                          />
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className="m-3"> Check Overview check box in Results section </div>
      )}
    </div>
  );
};

export default GeneralSettings;
