import { TASK_WORK_LIST } from '../../../../constants/accessToPages';

import TaskWorklist from './TaskWorklist';

export const taskWorklistRouterConfig = {
  name: 'Task Worklist',
  path: '/task-work-list',
  component: TaskWorklist,
  access: [TASK_WORK_LIST]
};
