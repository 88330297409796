import React, { useCallback, useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import { sortBy } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';
import * as PropTypes from 'prop-types';

import { PatientEncounterReviewApi } from '../../../../../../../../api';
import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { TEXT_BLOCK_LONG } from '../../../../../../../../constants/inputTypes';
import { COMMENT_FORM_QUESTION_ID } from '../../../../../../../../constants/protocolConstant';
import { generateUrlByKey, useCurrentRoute } from '../../../../../../../root/router';
import { showEncounterColumn } from '../../../../../../setup/shared/ElementSetupNew/defaultLogCheckOptions';
import LogsTable from '../../../../../Patients/Logs/LogsTable';
import { CustomCell, defaultCellWidth } from '../../../../../Patients/Logs/LogsTableService';
import { ReviewContext } from '../../../ReviewContext';
import { isSmOrPiReview } from '../../../reviewService';
import ElementHistoryLogModal from '../../HistoryLogModal/ElementHistoryLogModal';
import { AddCommentModal } from '../../ItemGroupElement/AddCommentModal/AddCommentModal';
import * as itemGroupTableViewService from '../../ItemGroupElement/ItemGroupTableViewService';
import { MIN_WIDTH_OF_REVIEW_TABLE_COLUMN } from '../../ItemGroupElement/ItemGroupTableViewService';
import { useReviewContentElement } from '../../ReviewContentElementContext/ReviewContentElementContext';
import * as reviewContentService from '../../reviewContentService';
import TableCellAnswer from '../../TableCellAnwer/TableCellAnswer';

import '../../ItemGroupElement/ItemGroupTable/ItemGroupTable.scss';

const logRowNumberAccessor = 'logRowNumber';
const associatedEncounterAccessor = 'associatedEncounterName';

export default function LogItemGroupContent({ logAndLogCheckSnapshotReviewState }) {
  const currentRoute = useCurrentRoute();
  const { isAllowSign, commentIsReadonly } = useReviewContentElement();

  const [logRenderData, setLogRenderData] = useState([]);
  const [columns, setColumns] = useState([]);
  const prepareLogDataToRender = useCallback(handlePrepareLogDataToRender, [logAndLogCheckSnapshotReviewState]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const prepareColumns = useCallback(handlePrepareColumns, [logAndLogCheckSnapshotReviewState, logRenderData]);
  const {
    patientEncounterId,
    ssuPatientId,
    loadData,
    isHistoricalData,
    reviewType,
    reviewMetaData,
    updateLogData
  } = useContext(ReviewContext);
  const itemGroupTemplateId = logAndLogCheckSnapshotReviewState.logFormRef.itemGroupTemplateId;
  const domainCode = logAndLogCheckSnapshotReviewState.logFormRef.domainCode;

  function openLogRowHistory(logRow) {
    const itemGroupTemplateId = logRow?.rowFormRef?.itemGroupTemplateId;
    const itemGroupTemplateVersionId = logRow?.rowFormRef?.itemGroupTemplateVersionId;
    const rowId = logRow?.rowId;
    const itemGroupName = logAndLogCheckSnapshotReviewState.logFormRef.name;
    const onLoadData = () =>
      PatientEncounterReviewApi.getLogItemGroupAnswerHistory(
        ssuPatientId,
        patientEncounterId,
        itemGroupTemplateId,
        itemGroupTemplateVersionId,
        rowId
      );
    ModalBoxes.open({
      component: <ElementHistoryLogModal itemGroupName={itemGroupName} onLoadData={onLoadData} />,
      title: `${itemGroupName}`
    });
  }

  useEffect(
    function() {
      setLogRenderData(prepareLogDataToRender);
    },
    [prepareLogDataToRender]
  );

  useEffect(
    function() {
      setColumns(prepareColumns);
    },
    [prepareColumns]
  );
  return (
    <div className="erc-item-group-element-table-reflection">
      <LogsTable
        customTitle={
          domainCode === 'AE' ? 'Selecting a log row will open the data entry page in a new browser tab.' : ''
        }
        columns={columns}
        data={logRenderData}
        getTrProps={getTrProps}
        sortable={false}
        showPagination={false}
        defaultCellWidth={defaultCellWidth}
        pageSize={logRenderData?.length || 1}
        getTdProps={(state, rowInfo, column) => {
          const columnId = column.id;
          if (domainCode === 'AE' && !['history', 'Comments', associatedEncounterAccessor].includes(columnId)) {
            return {
              onClick: () => {
                const { parent, params } = currentRoute;
                const { patientId, ssuPatientId, patientEncounterId } = params;
                const logRow = rowInfo.original;
                const itemGroupTemplateId = logRow?.rowFormRef?.itemGroupTemplateId;
                const itemGroupId = logRow?.rowFormRef?.itemGroupId;
                const itemGroupTemplateVersionId = logRow?.rowFormRef?.itemGroupTemplateVersionId;
                const rowId = logRow?.rowId;
                const logUrl = `${itemGroupId ? parent.key : parent.parent.key}.Logs.Row`;
                window.open(
                  generateUrlByKey(logUrl, {
                    patientId,
                    ssuPatientId,
                    patientEncounterId,
                    patientItemGroupId: itemGroupId || itemGroupTemplateId,
                    rowId: rowId,
                    itemGroupTemplateVersionId: itemGroupTemplateVersionId
                  }),
                  '_blank'
                );
              }
            };
          }

          if (
            rowInfo &&
            (rowInfo.row[columnId]?.question === 'Comment' || rowInfo.row[columnId]?.itemGroupQuestionId === 'COVAL')
          ) {
            return {
              onClick: event => {
                onEditComment(rowInfo, rowInfo?.row[columnId], event);
              }
            };
          }
          return {};
        }}
      />
    </div>
  );

  function onEditComment({ original }, commentAnswer) {
    if (isEmpty(commentAnswer?.stringValue) && commentIsReadonly) {
      return;
    }
    ModalBoxes.open({
      component: (
        <AddCommentModal
          context={{
            ssuPatientId,
            patientEncounterId,
            loadData,
            reviewType,
            isHistoricalData,
            studySiteStatus: reviewMetaData.studySiteStatus
          }}
          updateData={updateLogData}
          snapshotData={{ ...original, commentAnswer }}
          isLogItemGroup={true}
          itemGroupTemplateId={itemGroupTemplateId}
          patientItemGroupId={original?.rowFormRef?.itemGroupId}
          itemGroupTemplateVersionId={original.rowFormRef.itemGroupTemplateVersionId}
          readonly={commentIsReadonly}
        />
      )
    });
  }

  function handlePrepareColumns() {
    const formQuestions = collectAllUniqueLogTemplateVersionQuestionsByKey(logAndLogCheckSnapshotReviewState);
    const columns = [];

    columns.push(prepareLogRowNumberColumn());

    showEncounterColumn(domainCode) && columns.push(prepareLogRowEncounterColumn());

    formQuestions.forEach(formQuestion => {
      columns.push(prepareColumnForQuestion(formQuestion, logRenderData));
    });

    columns.push(prepareCommentColumn());

    columns.push(prepareHistoryColumn(domainCode));

    return columns;

    function prepareColumnForQuestion(formQuestion, logRenderData) {
      if (formQuestion.type === TEXT_BLOCK_LONG) {
        return false;
      }
      const formQuestionAnswerMapAccessor = formQuestion.questionKey;
      const minWidth = calculateMinWidth(formQuestion, logRenderData);
      return {
        // Color out edited data cells after sign or required attention cells
        getProps: (state, row) => {
          const answer = row.original?.answers[formQuestionAnswerMapAccessor];
          if (answer) {
            let className = reviewContentService.getAnswerHighlightStyleClassByAnswerAndReviewType(
              answer,
              reviewType,
              isAllowSign
            )?.className;
            if (
              !['radio', 'date'].includes(formQuestion?.type) &&
              answer.type !== 'NO_SENSE' &&
              answer.type !== 'EMPTY'
            ) {
              className = className ? className + ' text-left' : 'text-left';
            }
            return { className: className };
          }
          return {};
        },
        Header: formQuestion.label,
        id: formQuestion.questionId,
        investigatorField: formQuestion.investigatorField,

        Cell: row => {
          const answer = row.original?.answers[formQuestionAnswerMapAccessor];
          if (answer && answer.type !== 'NO_SENSE' && answer.type !== 'EMPTY') {
            return <TableCellAnswer answer={answer} isAnswerEdited={answer.isEdited} />;
          } else {
            return '--';
          }
        },
        minWidth
      };
    }

    function prepareHistoryColumn(domainCode) {
      return {
        Header: 'History',
        minWidth: 80,
        width: 80,
        className: 'text-left',
        fixed: domainCode !== 'AE',
        id: 'history',
        Cell: row => {
          if (!isHistoricalData) {
            return (
              <div className="edited-link" onClick={() => openLogRowHistory(row.original)}>
                History
              </div>
            );
          }
          return '';
        }
      };
    }

    function prepareLogRowNumberColumn() {
      return {
        Header: 'N',
        width: 45,
        minWidth: 45,
        className: 'text-left pl-2',
        fixed: true,
        accessor: logRowNumberAccessor
      };
    }

    function prepareLogRowEncounterColumn() {
      return {
        getProps: (state, row) => ({
          className: cx({
            'edited-data-after-sign-bg': isSmOrPiReview(reviewType) && row.original?.encounterChanged && isAllowSign
          })
        }),
        Header: 'Encounter',
        fixed: true,
        minWidth: 150,
        className: 'text-left',
        accessor: associatedEncounterAccessor,
        Cell: row => {
          const encounter = row.original?.associatedEncounterName ?? 'N/A';
          return <CustomCell longAnswer={encounter} shortAnswer={encounter} />;
        }
      };
    }

    function prepareCommentColumn() {
      return {
        getProps: (state, row) => {
          const commentAnswer = row.original?.answers[COMMENT_FORM_QUESTION_ID];
          const props = {
            ...reviewContentService.getAnswerHighlightStyleClassByAnswerAndReviewType(
              commentAnswer,
              reviewType,
              isAllowSign
            )
          };
          props.className = commentAnswer?.stringValue ? 'text-left' : 'text-center';
          return props;
        },
        Header: 'Comments',
        width: defaultCellWidth,
        id: 'Comments',
        accessor: row => row.answers[COMMENT_FORM_QUESTION_ID],
        Cell: row => {
          const answers = row.original?.answers;
          const commentAnswer = answers?.[COMMENT_FORM_QUESTION_ID];

          if (commentAnswer?.stringValue) {
            return (
              <CustomCell
                className={'edited-link'}
                longAnswer={commentAnswer?.stringValue}
                shortAnswer={commentAnswer?.stringValue}
              />
            );
          } else {
            return <div>--</div>;
          }
        }
      };
    }
  }

  function calculateMinWidth(formQuestion, logDataToRender) {
    return (
      Math.max(
        ...logDataToRender.map(logRowToRender => {
          const answer = logRowToRender.answers[formQuestion.questionKey];
          return itemGroupTableViewService.getWidthOfTableColumnByAnswer(answer);
        })
      ) || MIN_WIDTH_OF_REVIEW_TABLE_COLUMN
    );
  }

  function handlePrepareLogDataToRender() {
    const allLogTemplateVersionQuestions = collectAllLogTemplateVersionQuestions(logAndLogCheckSnapshotReviewState);

    return logAndLogCheckSnapshotReviewState.rows.map(row => {
      const logRowToRender = {};
      populateLogRowNumber(logRowToRender, row.rowRef.logRowNumber);
      populateRowId(logRowToRender, row);
      populateRowFormRef(logRowToRender, row);
      populateLogRowAnswers(logRowToRender, row, allLogTemplateVersionQuestions);
      populateWhenAndWhoIsDone(logRowToRender, row);
      populateRowReviewStatus(logRowToRender, row);
      populateAssociatedEncounter(logRowToRender, row);

      return logRowToRender;
    });

    function populateRowId(tableRowToRender, logRowState) {
      tableRowToRender.rowId = logRowState?.rowSnapshotFormData?.rowId;
      tableRowToRender.rowVersion = logRowState?.rowSnapshotFormData?.rowVersion;
      tableRowToRender.studyId = logRowState?.rowSnapshotKey.rowKey.studyId;
    }

    function populateRowFormRef(tableRowToRender, logRowState) {
      tableRowToRender.rowFormRef = logRowState?.rowRef?.logFormRef;
    }

    function populateWhenAndWhoIsDone(tableRowToRender, logRowState) {
      const { whenWasItDone, whoDidItName } = logRowState?.rowSnapshotFormData?.form;
      tableRowToRender.whenWasItDone = whenWasItDone;
      tableRowToRender.whoDidItName = whoDidItName;
    }

    function populateAssociatedEncounter(tableRowToRender, logRowState) {
      const associatedEncounter = logRowState?.associatedEncounter;
      tableRowToRender.associatedEncounterId = associatedEncounter?.encounterInstanceId;
      tableRowToRender.associatedEncounterName = associatedEncounter?.encounterName;
      tableRowToRender.encounterChanged = logRowState.encounterChanged;
    }

    function collectAllLogTemplateVersionQuestions(logAndLogCheckSnapshotReviewState) {
      const allLogTemplateVersionQuestions = logAndLogCheckSnapshotReviewState.itemGroupTemplateVersions.flatMap(
        itemGroupTemplateVersion => itemGroupTemplateVersion.itemGroupVersionQuestions
      );
      //Add comment question config since it is not configured in protocol
      const commentQuestionConfig = {
        name: COMMENT_FORM_QUESTION_ID,
        questionId: COMMENT_FORM_QUESTION_ID,
        label: COMMENT_FORM_QUESTION_ID,
        questionKey: COMMENT_FORM_QUESTION_ID
      };
      allLogTemplateVersionQuestions.push(commentQuestionConfig);

      return allLogTemplateVersionQuestions;
    }

    function populateLogRowNumber(logRowToRender, logRowNumber) {
      logRowToRender[logRowNumberAccessor] = logRowNumber;
    }

    function populateLogRowAnswers(logRowToRender, row, formQuestions) {
      if (row?.rowSnapshotFormData?.form?.answers) {
        const logRowAnswers = row?.rowSnapshotFormData.form.answers;
        const answersByQuestionKey = [];
        logRowAnswers.forEach(answer => {
          const questionKey = formQuestions.find(question => question.questionId === answer.itemGroupQuestionId)
            ?.questionKey;
          answer.isEdited = row?.editedQuestionIds?.includes(answer.itemGroupQuestionId);
          answer.isEditedAfterSign = row?.editedAfterSignQuestionIds?.includes(answer.itemGroupQuestionId);
          answersByQuestionKey[questionKey] = answer;
        });
        logRowToRender.answers = answersByQuestionKey;
      }
    }

    function populateRowReviewStatus(logRowToRender, logRowSnapshotState) {
      logRowToRender.rowReviewStatus = logRowSnapshotState?.rowReviewStatus;
    }
  }

  function collectAllUniqueLogTemplateVersionQuestionsByKey(logAndLogCheckSnapshotReviewState) {
    let questionsByKey = [];

    let addQuestionTuQuestionSetIfNotAdded = q => {
      if (questionsByKey.every(alreadySelectedQuestion => alreadySelectedQuestion.questionKey !== q.questionKey)) {
        questionsByKey.push(q);
      }
    };
    logAndLogCheckSnapshotReviewState.itemGroupTemplateVersions.forEach(answerSet =>
      answerSet.itemGroupVersionQuestions.forEach(addQuestionTuQuestionSetIfNotAdded)
    );

    return sortBy(questionsByKey, question => question.sequence);
  }

  function getTrProps(state, rowInfo) {
    const rowReviewStatus = rowInfo?.row?._original?.rowReviewStatus;
    return reviewContentService.getFormHighlightStyleBasedOnRowReviewStatusAndReviewType(
      rowReviewStatus,
      reviewType,
      isAllowSign
    );
  }
}

LogItemGroupContent.propTypes = {
  logAndLogCheckSnapshotReviewState: PropTypes.object
};
