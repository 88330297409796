import { memo } from 'react';
import { TextField, Unstable_Grid2 as Grid } from '@mui/material';
import { isNull } from 'lodash/lang';

import { normalizeOneDecimalPositiveFloat } from '../../../../../../../../../services/normalizers';

export default memo(function DistanceField({ distance, setAddressField, handleBlur, error, helperText }) {
  const value = distance || '';

  return (
    <Grid xs={3}>
      <TextField
        name="mileageAddresses.distance"
        value={value}
        onChange={function({ target }) {
          const prepareValue = normalizeOneDecimalPositiveFloat(target.value);
          if (!isNull(prepareValue)) {
            setAddressField('mileageAddresses.distance', prepareValue);
          }
        }}
        onBlur={handleBlur}
        error={error}
        helperText={helperText}
        fullWidth
        label="Distance"
        variant="standard"
        required
        InputProps={{
          type: 'number'
        }}
      />
    </Grid>
  );
});
