import React, { useEffect, useState } from 'react';
import DateTime from 'react-datetime';
import ReactSuperSelect from 'react-super-select';
import cx from 'classnames';
import { isFunction } from 'lodash/lang';
import moment from 'moment';
import PropTypes from 'prop-types';

import Button from '../../../../common/general/Button';
import Icon from '../../../../common/general/Icon';

import './CalendarDateTimePicker.scss';
import 'react-datetime/css/react-datetime.css';
import 'react-super-select/lib/react-super-select.css';

const inputDateFormat = 'YYYY-MM-DD';
const numberOfYearsToSHow = 28;

export default function CalendarDateTimePicker(props) {
  const {
    highlightPeriod,
    datesWithLabel,
    datesWithUnderscore = [],
    inline,
    viewMode,
    closeOnSelect,
    initialDate
  } = props;
  const [highlights, setHighlights] = useState([]);
  const [viewDate, setViewDate] = useState(moment());

  useEffect(() => {
    const highlights = [];
    if (highlightPeriod && highlightPeriod?.dateRanges?.length > 0) {
      highlightPeriod.dateRanges.forEach(range => {
        const periodStart = moment(range.startDate, inputDateFormat).startOf('day');
        const periodEnd = moment(range.endDate, inputDateFormat).startOf('day');
        const dayIterator = periodStart.clone();
        while (periodEnd.diff(dayIterator, 'days') >= 0) {
          highlights.push(dayIterator.clone().format(inputDateFormat));
          dayIterator.add(1, 'day');
        }
      });
    }

    setHighlights(highlights);
  }, [highlightPeriod]);

  useEffect(() => {
    setViewDate(initialDate);
  }, [initialDate]);

  const handleChange = date => {
    isFunction(props.onChange) && props.onChange(date);
  };

  const changeYear = year => {
    setViewDate(viewDate.clone().year(year.value));
  };

  const decreaseMonth = () => {
    const newDate = viewDate.clone().subtract(1, 'month');
    setViewDate(newDate);
    isFunction(props.onMonthChange) && props.onMonthChange(newDate.clone());
  };

  const increaseMonth = () => {
    const newDate = viewDate.clone().add(1, 'month');
    setViewDate(newDate);
    isFunction(props.onMonthChange) && props.onMonthChange(newDate.clone());
  };

  let timeFormat = '';
  let dateFormat = 'YYYY MMM DD HH:mm a';
  if (viewMode) {
    switch (viewMode) {
      case 'months': {
        dateFormat = 'MMM';
        break;
      }
      case 'years': {
        dateFormat = 'YYYY';
        break;
      }
      case 'days': {
        dateFormat = 'DD/MMM/YYYY';
        break;
      }
      case 'time': {
        dateFormat = '';
        timeFormat = 'h:mm A';
        break;
      }
      default: {
        break;
      }
    }
  }

  return (
    <div className="eds-calendar-date-time-picker">
      {inline && (
        <YearSelect
          changeYear={changeYear}
          date={viewDate}
          decreaseMonth={decreaseMonth}
          increaseMonth={increaseMonth}
        />
      )}
      <DateTime
        value={viewDate}
        input={!inline}
        open={inline}
        onChange={handleChange}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        className={cx('eds-date-time', `view-mode-${viewMode}`, { inline: inline })}
        viewDate={viewDate}
        viewMode={viewMode || 'days'}
        closeOnSelect={closeOnSelect}
        renderDay={(props, currentDate) => {
          return renderDay(props, currentDate, highlights, datesWithLabel, datesWithUnderscore);
        }}
      />
    </div>
  );
}

function renderDay(props, currentDate, highlights, datesWithLabel, datesWithUnderscore) {
  let className = '';
  const date = moment(currentDate).format(inputDateFormat);
  const isHighlighted = highlights.includes(date);
  const isLabel = datesWithLabel.includes(date);
  const isUnderscore = datesWithUnderscore.includes(date);
  props.className = cx(props.className, { highlight: isHighlighted });
  className = cx(className, { dateWithLabel: isLabel, dateWithUnderscore: isUnderscore });
  return (
    <td {...props}>
      <div className={className}>{currentDate.date()}</div>
    </td>
  );
}

function YearSelect({ date, changeYear, decreaseMonth, increaseMonth }) {
  const currentDate = moment(date);
  const initialValue = { id: 999999999, value: currentDate.clone().format('YYYY') };
  const years = [];
  const visibleMonth = currentDate.clone().format('MMM');
  const visibleYear = currentDate.clone().format('YYYY');
  const [showButtons, setShowButtons] = useState(true);
  const middleYear = numberOfYearsToSHow / 2;
  for (let i = 0; i < numberOfYearsToSHow; i++) {
    const year = moment()
      .subtract(i - middleYear, 'years')
      .format('YYYY');
    years.push({ id: i, value: year });
  }
  const customOptionTemplateFunction = (item, visibleYear) => {
    return <div className={cx('year-option', { 'current-year': item.value === visibleYear })}>{item.value}</div>;
  };
  return (
    <div className="year-select">
      <div className="left">
        <ReactSuperSelect
          dataSource={years}
          onChange={changeYear}
          initialValue={initialValue}
          clearable={false}
          clearSelectedValueOnDataSourceChange={true}
          keepOpenOnSelection={false}
          closeOnSelectedOptionClick={false}
          deselectOnSelectedOptionClick={false}
          onCloseDropdown={() => setShowButtons(true)}
          onOpenDropdown={() => setShowButtons(false)}
          customClass="year-select-container"
          customOptionTemplateFunction={item => {
            return customOptionTemplateFunction(item, visibleYear);
          }}
          customSelectedValueTemplateFunction={item => {
            return `${visibleMonth} ${item[0].value}`;
          }}
        />
      </div>
      {showButtons && (
        <div className="">
          <Button priority="low" onClick={decreaseMonth}>
            <Icon>keyboard_arrow_left</Icon>
          </Button>
          <Button priority="low" onClick={increaseMonth}>
            <Icon>keyboard_arrow_right</Icon>
          </Button>
        </div>
      )}
    </div>
  );
}

CalendarDateTimePicker.defaultProps = {
  datesWithLabel: [],
  datesWithUnderscore: [],
  viewMode: 'days'
};

CalendarDateTimePicker.propTypes = {
  highlightPeriod: PropTypes.object,
  datesWithLabel: PropTypes.array,
  datesWithUnderscore: PropTypes.array,
  inline: PropTypes.bool,
  viewMode: PropTypes.oneOf(['years', 'months', 'days', 'time']),
  closeOnSelect: PropTypes.bool,
  initialDate: PropTypes.object
};
