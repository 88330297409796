import { PL, SCHEDULING_WORK_LIST, WORK_LIST } from '../../../../constants/accessToPages';
import { generatePatientInfoRouterConfig } from '../../../root/router/sharedConfigs';
import SchedulingWorklist from '../../patient-source/ScheduleWorklist/SchedulingWorklist';

import WorkList from './Worklist';

export const worklistRouterConfig = {
  name: 'Old Worklist',
  path: '/old-worklist',
  component: WorkList,
  access: [WORK_LIST],
  inherited: [
    ...generatePatientInfoRouterConfig(WORK_LIST, WORK_LIST),
    {
      name: 'Scheduling Worklist',
      path: '/scheduling-work-list',
      component: SchedulingWorklist,
      access: [SCHEDULING_WORK_LIST],
      inherited: [...generatePatientInfoRouterConfig(PL, SCHEDULING_WORK_LIST)]
    }
  ]
};
