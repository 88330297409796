import { filter, keyBy, size } from 'lodash/collection';
import { isNull } from 'lodash/lang';
import { get } from 'lodash/object';

import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { PROCESS_INTERRUPTED_BY_THE_USER } from '../../../../../../../constants/errorMessages';
import { FILE_TYPES } from '../../../../../../../constants/inputTypes';
import store from '../../../../../../../store';
import { isReadOnlyField } from '../../../../../setup/shared/ElementSetupNew/itemGroupSetupService';
import { isInvestigatorForbiddenField } from '../../InvestigatorFields/services/investigatorFieldService';
import { PS_NOT_ANSWERED } from '../constants';
import MissingItemsModal from '../preSaveItemsModals/MissingItemsModal';
import { collectAllQuestionsField, updateQuestionsField } from '../services/fieldsServices';

export default function processMissingItems(encounterTableItemGroup, hiddenFieldsIds, skipProcessMissingItems) {
  if (skipProcessMissingItems) {
    return Promise.resolve(encounterTableItemGroup);
  }
  return new Promise(function(resolve, reject) {
    const missingFieldsMap = getMissingItems(encounterTableItemGroup, hiddenFieldsIds);

    if (size(missingFieldsMap) < 1) {
      return resolve(encounterTableItemGroup);
    }

    const modalInstance = ModalBoxes.open({
      component: (
        <MissingItemsModal
          data={missingFieldsMap}
          onSave={function(missingFieldsMap) {
            resolve(
              updateQuestionsField(
                encounterTableItemGroup,
                function(field) {
                  const { value, reason } = missingFieldsMap[field.fieldUid];
                  return {
                    ...field,
                    performedStatus: value || PS_NOT_ANSWERED,
                    fieldComment: reason
                  };
                },
                function({ fieldUid }) {
                  return !!missingFieldsMap[fieldUid];
                }
              )
            );
            modalInstance.close(true);
          }}
        />
      ),
      onClose(resolved) {
        if (resolved !== true) {
          reject(new Error(PROCESS_INTERRUPTED_BY_THE_USER));
        }
      }
    });
  });
}

function getMissingItems(encounterTableItemGroup, hiddenFieldsIds) {
  return prepareChangedItems(pickMissingItems(collectAllQuestionsField(encounterTableItemGroup), hiddenFieldsIds));
}

function pickMissingItems(data, hiddenFieldsIds) {
  const userRole = get(store.getState(), 'currentUser.activeRole');
  return filter(data, function({
    fieldUid,
    fieldValue,
    fieldType,
    fieldName,
    fileList,
    filesResponse,
    investigatorField
  }) {
    return (
      isNull(fieldValue) &&
      !(FILE_TYPES.includes(fieldType) && (!isNull(fileList) || !isNull(filesResponse))) &&
      !isReadOnlyField(fieldType) &&
      fieldName !== 'Comment' &&
      !hiddenFieldsIds.includes(fieldUid) &&
      !isInvestigatorForbiddenField(investigatorField, userRole)
    );
  });
}

function prepareChangedItems(data) {
  return keyBy(
    data.map(function({
      fieldLabel: label,
      fieldUid: uid,
      fieldName: name,
      performedStatus: value,
      fieldComment: reason
    }) {
      return {
        label,
        uid,
        name,
        value,
        reason
      };
    }),
    'uid'
  );
}
