import axios from 'axios';

const ROOT = 'api/v1/reconciliations/histories';

const FinInvoiceReconcileHistoryApi = {
  getReconcileHistory(invoiceId, ledgerEventId) {
    return axios.get(ROOT, {
      params: {
        invoiceId,
        ledgerEventId
      }
    });
  }
};

export default FinInvoiceReconcileHistoryApi;
