import { some } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

import UserMediaApi from '../../../../../../../api/patient/UserMediaApi';
import { FILE_TYPES } from '../../../../../../../constants/inputTypes';

export default function processDeleteFiles(encounterTableItemGroup, originEncounterTableItemGroup, route) {
  const filesResponse = getFilesResponse(encounterTableItemGroup);
  const originFilesResponse = getFilesResponse(originEncounterTableItemGroup);

  const deletedFiles = originFilesResponse.filter(
    file => !some(filesResponse, ['uniqueIdentifier', file.uniqueIdentifier])
  );

  if (isEmpty(deletedFiles)) return Promise.resolve();

  return Promise.all(
    deletedFiles.map(file => {
      return UserMediaApi.deleteMedia(route.params.ssuPatientId, file.uniqueIdentifier);
    })
  );
}

function getFilesResponse(encounterItemGroup) {
  const filesResponseInGeneralSection = encounterItemGroup.generalSnapshotAnswers
    .filter(field => FILE_TYPES.includes(field.fieldType) && !isEmpty(field?.filesResponse))
    .flatMap(field => field.filesResponse);
  const filesResponseInTableSection = encounterItemGroup.tableItemGroupRows
    .flatMap(row => row.answers)
    .filter(field => FILE_TYPES.includes(field.fieldType) && !isEmpty(field?.filesResponse))
    .flatMap(field => field.filesResponse);
  return [...filesResponseInGeneralSection, ...filesResponseInTableSection];
}
