export const globalSettings = [
  {
    id: 'SMS_NOTIFICATIONS',
    name: 'SMS Notifications',
    description: 'Send SMS notifications and reminders for patient appointments.'
  }
];

export const getSettingsById = id => {
  return globalSettings.find(s => s.id === id);
};
