import React, { useEffect } from 'react';
import { omit } from 'lodash';
import { find } from 'lodash/collection';

import Select from '../../../../common/data-entry/Select';

// setting new datasource value that does not contain current value will clear current selected value
// setting datasource to [] will clear current selected value.
// this behavior prevents incorrect state or dropdown
// this behavior is casual for appointment edit dialogs
// to keep data consistence and selected patient, study, site, encounter relationship.
function CalendarSingleSelect(props) {
  const { initialValue, onChange, optionValueKey, dataSource } = props;
  function selectValue(value) {
    if ((initialValue === undefined && value === undefined) || initialValue === value) {
      return;
    }
    onChange(value);
  }

  useEffect(() => {
    const key = optionValueKey || 'id';
    if (initialValue && dataSource) {
      const valueFromDataSource = find(dataSource, { [key]: initialValue[key] });
      if (valueFromDataSource !== initialValue) {
        selectValue(valueFromDataSource);
      }
    }
    // eslint-disable-next-line
  }, [dataSource]);

  const restProps = omit(props, ['onChange']);

  return <Select value={initialValue} onChange={selectValue} {...restProps} />;
}

export default CalendarSingleSelect;
