import React, { Component } from 'react';
import ReactTable from 'react-table';
import { cloneDeep } from 'lodash/lang';
import moment from 'moment';

import { ProjectionRevenueApi } from '../../../../api';
import Button from '../../../../common/general/Button';
import { pendoTrackDefaultSortingChange } from '../../../../pendo/PendoUtils';
import { onFileSave, onRequestError } from '../../../../services/handlers';
import { PageInfoHeader } from '../../../PageInfoHeader/PageInfoHeader';
import { Amount } from '../shared/amount-view/Amount/Amount';

import { prepareDataForDetails } from './ProjectedRevenueService/ProjectedRevenueService';
import {
  DATE_FORMAT,
  MONTH_DISPLAY_FORMAT,
  MONTH_PROCESSING_FORMAT,
  VISIBLE_MONTHS_COUNT
} from './projectedRevenueConstants';

import './ProjectedRevenue.scss';

class ProjectedRevenueDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: defaultColumns,
      data: []
    };
  }

  componentDidMount({ props, state } = this) {
    const { type, ssu } = props.params;
    ProjectionRevenueApi.getProjectedRevenueDetails(type, ssu).then(res => {
      const result = prepareDataForDetails(res.data);

      const columns = cloneDeep(defaultColumns);
      addScheduledColumnForFollowUp(type, columns);
      for (let i = 0; i < VISIBLE_MONTHS_COUNT && i < result.months.length; i++) {
        const month = result.months[i];
        const monthColumn = {
          Header: moment(month, MONTH_PROCESSING_FORMAT).format(MONTH_DISPLAY_FORMAT),
          accessor: month,
          Cell: formatDisplayMonthValue,
          width: 90,
          className: 'align-amount-end',
          headerClassName: 'align-amount-end'
        };
        columns.push(monthColumn);
      }

      this.setState({ columns, data: result.rows });
    }, onRequestError);
  }

  render({ props, state } = this) {
    const type = props.params.type;
    const ssuId = props.params.ssu;
    return (
      <React.Fragment>
        <PageInfoHeader
          name={type === 'visits' ? 'Followup Visits' : 'New Consents'}
          right={
            <Button
              priority="medium"
              size="h28"
              type="button"
              onClick={() => this.downloadRevenuePrediction(type, ssuId)}
            >
              Export
            </Button>
          }
        />
        <section className="projection-revenue footpadb">
          <div className="container-fluid border mt-2 py-3">
            <div className="row pt-2">
              <div className="col-md-12">
                <ReactTable
                  data={state.data || []}
                  columns={state.columns}
                  minRows={1}
                  multiSort
                  onSortedChange={pendoTrackDefaultSortingChange}
                  showPagination
                  nextText=">>"
                  previousText="<<"
                  defaultPageSize={20}
                  noDataText="No Record Found"
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }

  downloadRevenuePrediction = (type, ssuId) => {
    ProjectionRevenueApi.exportRevenuePrediction(type, ssuId).then(onFileSave);
  };
}

function addScheduledColumnForFollowUp(type, columns) {
  if (type === 'visits') {
    const scheduledColumn = {
      Header: 'Scheduled',
      accessor: 'scheduled',
      width: 100
    };
    columns.push(scheduledColumn);
  }
}

function formatDisplayMonthValue({ value }) {
  return <Amount showDollarSign coinsAmount={value} />;
}

export default ProjectedRevenueDetails;

const defaultColumns = [
  {
    Header: 'Study',
    accessor: 'study'
  },
  {
    Header: 'Site',
    accessor: 'site'
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: 120
  },
  {
    Header: 'Subject ID',
    accessor: 'subjectId'
  },
  {
    Header: 'Encounter',
    accessor: 'encounterName',
    Cell: row => {
      return row.original.nonProtocol ? `${row.original.encounterName} (NPE)` : row.original.encounterName;
    }
  },
  {
    Header: 'Estimated Date',
    accessor: 'originalDate',
    Cell: ({ value }) => value.format(DATE_FORMAT),
    width: 130
  }
];
