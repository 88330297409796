import { uniqBy } from 'lodash/array';
import { orderBy } from 'lodash/collection';

export function getUniqStudiesFromSsus(ssus) {
  return orderBy(
    uniqBy(
      ssus.map(e => e.study),
      e => e.id
    ),
    ['name'],
    'asc'
  );
}

export function getUniqSitesFromSsus(ssus) {
  return orderBy(
    uniqBy(
      ssus.map(e => e.site),
      e => e.id
    ),
    ['name'],
    'asc'
  );
}
