import React from 'react';
import * as PropTypes from 'prop-types';

import { DIRECT_COST, PASS_THRU } from '../../../../setup/Budget/BudgetDetails/modal/ExpenseType';
import { Amount } from '../Amount/Amount';

import './AmountWithType.scss';
const amountTypes = {
  [DIRECT_COST]: 'Direct',
  [PASS_THRU]: 'Pass Thru'
};
export function AmountWithType({ coinsAmount, amountType, defaultValue, showDollarSign }) {
  return (
    <div className={'amount-view'}>
      <Amount
        highlightFractionalValue
        coinsAmount={coinsAmount}
        defaultValue={defaultValue}
        showDollarSign={showDollarSign}
      />
      <span className={'aw-grey-text'}>{amountTypes[amountType]}</span>
    </div>
  );
}
AmountWithType.defaultProps = {
  defaultValue: ''
};

AmountWithType.propTypes = {
  amountType: PropTypes.oneOf([DIRECT_COST, PASS_THRU]),
  coinsAmount: PropTypes.number,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
