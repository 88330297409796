import React from 'react';
import moment from 'moment';

export function TimeRange(e, ranges) {
  const { value, children } = e;
  const thisDate = moment(value);
  if (children && children.props.className === 'rbc-time-slot') {
    return children;
  } else {
    let timeRangesForRender;
    if (ranges && ranges.dateRanges) {
      ranges.dateRanges.forEach(range => {
        if (
          range.startDate &&
          range.endDate &&
          (thisDate.isBetween(range.startDate, range.endDate) || range.startDate.isSame(thisDate, 'minutes'))
        ) {
          timeRangesForRender = <div key={range.startDate} className={'encounter-time-range-sub-slot'} />;
        }
      });
    }
    if (timeRangesForRender) {
      return (
        <div className={'encounter-time-range-slot'}>
          {timeRangesForRender}
          {children}
        </div>
      );
    } else {
      return children;
    }
  }
}
