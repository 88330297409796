import axios from 'axios';

const ROOT = 'api/v1/ssu-patient';

const FileInfoApi = {
  getFileInfo(fileUniqueIdentifier, ssuPatientId, hideLoader = false) {
    return axios.get(`${ROOT}/${ssuPatientId}/file/${fileUniqueIdentifier}`, {
      hideLoader
    });
  }
};

export default FileInfoApi;
