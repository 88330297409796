import axios from 'axios';

import separateAxiosInstance from '../separateAxiosInstance';

const ROOT = 'api/v1/ssu-patients';

const PatientEncounterGroupAssignReviewApi = {
  getSmReview(ssuPatientId, patientEncounterId, groupAssignId, hideLoader = true) {
    return axios.get(
      `${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/group-assign-review/sm/${groupAssignId}`,
      {
        hideLoader
      }
    );
  },

  getPiReview(ssuPatientId, patientEncounterId, groupAssignId, hideLoader = true) {
    return axios.get(
      `${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/group-assign-review/investigator/${groupAssignId}`,
      {
        hideLoader
      }
    );
  },

  getCraReview(ssuPatientId, patientEncounterId, hideLoader = true) {
    return axios.get(`${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/group-assign-review/cra`, {
      hideLoader
    });
  },

  signSmReview(groupAssignId, ssuPatientId, encounterId, token, request, hideLoader = true) {
    return separateAxiosInstance.post(
      `${ROOT}/${ssuPatientId}/encounters/${encounterId}/review/SM/sign/${groupAssignId}`,
      request,
      {
        hideLoader,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  },

  signPiReview(groupAssignId, ssuPatientId, encounterId, token, request, hideLoader = true) {
    return separateAxiosInstance.post(
      `${ROOT}/${ssuPatientId}/encounters/${encounterId}/review/PI/sign/${groupAssignId}`,
      request,
      {
        hideLoader,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  },

  getItemGroupRowSnapshotSmReviewsState(
    groupAssignId,
    ssuPatientId,
    patientEncounterId,
    itemGroupIds,
    hideLoader = true
  ) {
    return axios.post(
      `${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/group-assign-review/${groupAssignId}/SM/item-groups`,
      { itemGroupIds },
      {
        hideLoader
      }
    );
  },

  getItemGroupRowSnapshotPiReviewsState(
    groupAssignId,
    ssuPatientId,
    patientEncounterId,
    itemGroupIds,
    hideLoader = true
  ) {
    return axios.post(
      `${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/group-assign-review/${groupAssignId}/PI/item-groups`,
      { itemGroupIds },
      {
        hideLoader
      }
    );
  },

  getLogAndLogCheckSnapshotSmReviewState(
    groupAssignId,
    ssuPatientId,
    patientEncounterId,
    patientItemGroupId,
    hideLoader = true
  ) {
    return axios.get(
      `${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/group-assign-review/${groupAssignId}/SM/logs/${patientItemGroupId}`,
      {
        hideLoader
      }
    );
  },

  getEncounterExcludedLogSmSnapshotReviewState(ssuPatientId, itemGroupTemplateId, hideLoader = true) {
    return axios.get(`${ROOT}/${ssuPatientId}/group-assign-review/SM/excluded-logs/${itemGroupTemplateId}`, {
      hideLoader
    });
  },

  getLogAndLogCheckSnapshotPiReviewState(
    groupAssignId,
    ssuPatientId,
    patientEncounterId,
    patientItemGroupId,
    hideLoader = true
  ) {
    return axios.get(
      `${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/group-assign-review/${groupAssignId}/PI/logs/${patientItemGroupId}`,
      {
        hideLoader
      }
    );
  },

  getEncounterExcludedLogPiSnapshotReviewState(ssuPatientId, itemGroupTemplateId, hideLoader = true) {
    return axios.get(`${ROOT}/${ssuPatientId}/group-assign-review/PI/excluded-logs/${itemGroupTemplateId}`, {
      hideLoader
    });
  },

  getSignedItemGroupRowSnapshotReviewState(
    ssuPatientId,
    patientEncounterId,
    itemGroupIds,
    signatureId,
    hideLoader = true
  ) {
    return axios.post(
      `${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/group-assign-review/item-group/signatures/${signatureId}`,
      { itemGroupIds },
      {
        hideLoader
      }
    );
  },
  /*TODO: incorrect name*/
  getSignedLogCheckRowSnapshotReviewState(
    ssuPatientId,
    patientEncounterId,
    patientItemGroupId,
    signatureId,
    hideLoader = true
  ) {
    return axios.get(
      `${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/group-assign-review/log/${patientItemGroupId}/signatures/${signatureId}`,
      {
        hideLoader
      }
    );
  }
};

export default PatientEncounterGroupAssignReviewApi;
