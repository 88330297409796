import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { differenceWith, findIndex } from 'lodash/array';
import { some } from 'lodash/collection';
import { isObject } from 'lodash/lang';

import { FormBuilderApi, ItemGroupTemplatesApi, ProtocolItemGroupTemplatesApi } from '../api';
import ModalBoxes from '../common/feedback/ModalBoxes/ModalBoxes';
import NotificationManager from '../common/notifications/NotificationManager';
import ValidateCodeDefinitionChangesModal from '../components/pages/setup/shared/ElementSetupNew/ItemGroupTemplate/ValidateCodeDefinitionChangesModal';
import { prepareCustomItem } from '../components/pages/setup/shared/ElementSetupNew/TableSetup/TableField/CustomItemService';
import {
  CONTEXT_MODE,
  QUESTION_LAYOUT
} from '../components/pages/setup/shared/ElementSetupNew/TableSetup/TableSetupConstants';
import {
  calculateCodeDefinitionChangesFromNewTerminologyVersion,
  fillConditionalLogic,
  fillHideAll,
  generateConditionalLogicForSaving,
  preparedLabels,
  prepareFields,
  removeConfigurationAndHideAll,
  renameConfiguration,
  sortFieldsByIsCheckedAndUpdateSequence,
  updateConditionalLogicForItems,
  updateOptionList
} from '../components/pages/setup/shared/ElementSetupNew/TableSetup/TableSetupServices';
import { generateUrlByKey, useCurrentRoute } from '../components/root/router';
import {
  COMMENT_FIELD_IS_NOT_AVAILABLE,
  DATA_SAVED,
  DUPLICATE_LABEL_NOT_ALLOWED,
  ITEM_GROUP_EXIST,
  TABLE_LABEL_HEADER_ERROR,
  TEMPLATE_CREATED
} from '../constants/notificationMessages';
import { onRequestDefaultError, onRequestError } from '../services/handlers';

export const TableSetupContext = React.createContext(null);

export const TableSetupProvider = ({ children, isTemplateMode }) => {
  const currentRoute = useCurrentRoute();
  const navigate = useNavigate();

  const [tableContextMode, setTableContextMode] = useState(CONTEXT_MODE.UNMOUNTED);
  const [domainList, setDomainList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [selectedTerminologyVersionGroups, setSelectedTerminologyVersionGroups] = useState(null);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [itemGroupName, setItemGroupName] = useState('');
  const [studyName, setStudyName] = useState('');
  const [protocolName, setProtocolName] = useState('');
  const [protocolVersion, setProtocolVersion] = useState('');
  const [questionLayout, setQuestionLayout] = useState(QUESTION_LAYOUT.VERTICAL);
  const [tableTitle, setTableTitle] = useState('');
  const [labelField, setLabelField] = useState('');
  const [labelList, setLabelList] = useState([]);
  const [editLabelMode, setEditLabelMode] = useState(false);
  const [labelForEdit, setLabelForEdit] = useState(null);
  const [fieldList, setFieldList] = useState([]);
  const [selectedOnly, setSelectedOnly] = useState(false);
  const [selectedOnlyGeneral, setSelectedOnlyGeneral] = useState(false);
  const [generalFields, setGeneralFields] = useState([]);
  const [uniqueIdentifier, setUniqueIdentifier] = useState(null);

  useEffect(() => {
    if (
      currentRoute.name === 'Table Edit' ||
      currentRoute.name === 'Table Template Edit' ||
      currentRoute.name === 'Table Template Copy'
    ) {
      setTableContextMode(CONTEXT_MODE.EDIT);
    } else if (
      currentRoute.name === 'Table Setup' ||
      currentRoute.name === 'Table Template Setup' ||
      currentRoute.name === 'Item Group Table Template Setup'
    ) {
      setTableContextMode(CONTEXT_MODE.SETUP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoute.name]);

  useEffect(() => {
    if (tableContextMode === CONTEXT_MODE.SETUP) {
      FormBuilderApi.getAllStandardDomains()
        .then(({ data: { response } }) => {
          setDomainList(response);
          const savedValue = currentRoute.state ? { ...currentRoute.state.itemGroupSetupParameters } : null;
          if (savedValue) {
            setSelectedDomainAfterRedirect(savedValue.domainId, response);
            setItemGroupName(savedValue.elementName);
            setSelectedType({
              name: savedValue.selectedType.name,
              id: savedValue.selectedType.id
            });
            setSelectedTerminologyVersionGroups(savedValue.terminologyVersionGroupNumber);
          }
        })
        .catch(onRequestDefaultError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDomainList, tableContextMode]);

  useEffect(() => {
    if (selectedDomain && selectedDomain.elementIcon && tableContextMode === CONTEXT_MODE.SETUP) {
      const protocolIdentifier = currentRoute.params?.protocolIdentity;
      const preparedTypeList = selectedDomain.elementIcon
        .split(',')
        .map((type, index) => ({ name: type, id: type + index }));
      setTypeList(preparedTypeList);
      if (!some(preparedTypeList, selectedType)) {
        setSelectedType(null);
      }
      if (currentRoute.params && (selectedTerminologyVersionGroups || !isTemplateMode)) {
        resolveApiForGetDomainConfiguration(
          selectedDomain.domainIdentifier,
          protocolIdentifier,
          selectedTerminologyVersionGroups
        )
          .then(setFullItemGroup)
          .catch(onRequestDefaultError);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDomain, currentRoute.params, tableContextMode, selectedTerminologyVersionGroups]);

  useEffect(() => {
    if (
      isTemplateMode &&
      tableContextMode === CONTEXT_MODE.SETUP &&
      currentRoute.name === 'Item Group Table Template Setup'
    ) {
      if (currentRoute.params) {
        getItemGroupFromTemplate()
          .then(setFullItemGroup)
          .catch(onRequestDefaultError);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoute.params, tableContextMode]);

  const setFullItemGroup = data => {
    if (currentRoute.name === 'Item Group Table Template Setup') {
      setSelectedDomain(data.domainForSet);
      setSelectedType(data.selectedTypeForSet);
      setItemGroupName(data.itemGroupNameForSet);
      setQuestionLayout(data.questionLayoutForSet);
      setTableTitle(data.tableTitleForSet);
      setLabelList(data.labelListForSet);
      setFieldList(data.fieldListForSet);
      setGeneralFields(data.generalFieldsForSet);
    } else {
      setFieldList(data.optional[0].itemDefinitionList.map(field => ({ ...field, type: 'OPTIONAL' })));
      setGeneralFields([]);
    }
  };

  const resolveApiForGetDomainConfiguration = (domainid, protocolid, terminologyVersionGroupNumber) => {
    if (isTemplateMode) {
      return FormBuilderApi.getStandardDomainFormForTerminologyVersionGroup(
        domainid,
        terminologyVersionGroupNumber
      ).then(({ data }) => data);
    }
    return FormBuilderApi.getStandardDomainForProtocol({ domainid, protocolid }).then(
      ({ data: { response } }) => response
    );
  };

  useEffect(() => {
    if (tableContextMode === CONTEXT_MODE.EDIT) {
      resolveApiForEditMode()
        .then(data => {
          setSelectedDomain(data.domainForSet);
          setSelectedType(data.selectedTypeForSet);
          setItemGroupName(data.itemGroupNameForSet);
          setQuestionLayout(data.questionLayoutForSet);
          setTableTitle(data.tableTitleForSet);
          setLabelList(data.labelListForSet);
          setFieldList(data.fieldListForSet);
          setGeneralFields(data.generalFieldsForSet);
          setUniqueIdentifier(data.uniqueIdentifier);
          setSelectedTerminologyVersionGroups(data.terminologyVersionGroupNumber);
        })
        .catch(onRequestDefaultError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableContextMode]);

  const updateTerminologyVersion = (newTerminologyVersion, previousValue) => {
    setSelectedTerminologyVersionGroups(newTerminologyVersion);
    if (
      tableContextMode === CONTEXT_MODE.EDIT &&
      currentRoute.name === 'Table Template Copy' &&
      selectedDomain?.domainIdentifier &&
      newTerminologyVersion
    ) {
      const protocolIdentifier = currentRoute.params?.protocolIdentity;
      resolveApiForGetDomainConfiguration(selectedDomain.domainIdentifier, protocolIdentifier, newTerminologyVersion)
        .then(data => {
          const changedCodeDefinitionsMap = calculateCodeDefinitionChangesFromNewTerminologyVersion(
            [...generalFields, ...fieldList],
            data
          );
          if (changedCodeDefinitionsMap.size > 0) {
            const modalBox = ModalBoxes.open({
              component: (
                <ValidateCodeDefinitionChangesModal
                  changedCodeDefinitionsMap={changedCodeDefinitionsMap}
                  onConfirm={() => {
                    modalBox.close();
                    setSelectedTerminologyVersionGroups(newTerminologyVersion);
                    setGeneralFields(updateOptionList(generalFields, data.optional[0].itemDefinitionList));
                    setFieldList(updateOptionList(fieldList, data.optional[0].itemDefinitionList));
                  }}
                  onClose={() => {
                    modalBox.close();
                    setSelectedTerminologyVersionGroups(previousValue);
                  }}
                />
              )
            });
          }
        })
        .catch(onRequestDefaultError);
    }
  };

  const resolveApiForEditMode = () => {
    if (isTemplateMode) {
      return getItemGroupFromTemplate();
    } else {
      return ProtocolItemGroupTemplatesApi.getActiveElementDomain({
        study_identifier: currentRoute.params.studyId,
        element_identifier: currentRoute.params.element_identifier
      }).then(({ data }) => {
        const customItems = data.custom.map(element => ({ ...element.customItemDefinationList[0], type: 'CUSTOM' }));
        let sortedFields = [...data.optional[0].itemDefinitionList, ...customItems]
          .filter(field => !field.tableGeneralField)
          .sort((a, b) => a.sequence - b.sequence)
          .map((element, index) => ({
            ...element,
            sequence: index + 1,
            fieldConfigurationResponse: element.fieldConfigurationList
          }));
        const labelListWithId = preparedLabels(data.tableItemGroupTemplate.labels);
        const generalFields = [...data.optional[0].itemDefinitionList, ...customItems].filter(
          item => item.tableGeneralField
        );
        let sortedGeneral = generalFields
          .map(element => {
            if (some(data.tableItemGroupTemplate.generalFields, ['name', element.name])) {
              const matchedElement = data.tableItemGroupTemplate.generalFields.filter(
                field => field.name === element.name
              );
              return {
                ...element,
                sequence: matchedElement[0].sequence,
                fieldConfigurationResponse: element.fieldConfigurationList
              };
            }
            return { ...element, fieldConfigurationResponse: element.fieldConfigurationList };
          })
          .sort((a, b) => a.sequence - b.sequence);
        const fieldsWithHideAllConfiguration = fillHideAll(sortedGeneral, sortedFields);
        return {
          domainForSet: {
            domainIdentifier: data.domainIdentifier,
            domainName: data.domainName,
            domainCode: data.domainCode
          },
          selectedTypeForSet: { name: data.elementIcon, id: data.elementIcon },
          itemGroupNameForSet: data.elementName,
          questionLayoutForSet: data.tableItemGroupTemplate.layout,
          tableTitleForSet: data.tableItemGroupTemplate.title,
          labelListForSet: labelListWithId,
          fieldListForSet: fieldsWithHideAllConfiguration[1],
          generalFieldsForSet: fieldsWithHideAllConfiguration[0],
          uniqueIdentifier: data.uniqueIdentifier
        };
      });
    }
  };

  const getItemGroupFromTemplate = () => {
    return ItemGroupTemplatesApi.getItemGroupTemplate(currentRoute.params.itemGroupTemplateId).then(({ data }) => {
      const labelListWithId = preparedLabels(data.tableTemplate.labels);
      let preparedFieldList = prepareFields(data.itemDefinitionList);
      let preparedGeneralFields = prepareFields(data.tableTemplate.generalFields);
      const fieldsWithHideAllConfiguration = fillHideAll(preparedGeneralFields, preparedFieldList);
      return {
        domainForSet: {
          domainIdentifier: data.domainIdentifier,
          domainName: data.domainName,
          domainCode: data.domainCode
        },
        selectedTypeForSet: { name: data.elementIcon, id: data.elementIcon },
        itemGroupNameForSet:
          currentRoute.name === 'Table Template Copy' ? data.elementName + ' (copy)' : data.elementName,
        questionLayoutForSet: data.tableTemplate.layout,
        tableTitleForSet: data.tableTemplate.title,
        labelListForSet: labelListWithId,
        fieldListForSet: fieldsWithHideAllConfiguration[1],
        generalFieldsForSet: fieldsWithHideAllConfiguration[0],
        terminologyVersionGroupNumber: data.terminologyVersionGroupNumber
      };
    });
  };

  const setSelectedDomainAfterRedirect = (domainId, domains) => {
    const newDomain = domains.filter(e => e.domainIdentifier === domainId);
    setSelectedDomain(newDomain[0]);
  };

  const setSelectedTypeAfterRedirect = typeName => {
    const newType = typeList.filter(e => e.name === typeName);
    setSelectedType(newType[0]);
  };

  const addNewLabelInList = () => {
    if (labelField && !some(labelList, ['name', labelField.trim()])) {
      const newLabelItem = {
        name: labelField.trim(),
        id: `label_${labelField.trim()}`,
        sequence: labelList.length + 1
      };
      setLabelList([...labelList, newLabelItem]);
      setLabelField('');
    } else {
      NotificationManager.error(DUPLICATE_LABEL_NOT_ALLOWED);
    }
  };

  const deleteLabelFromList = index => {
    const updatedList = [...labelList];
    updatedList.splice(index, 1);
    const updatedListWithUpdatedSequence = updatedList.map((label, index) => {
      let updatedLabel = { ...label };
      updatedLabel.sequence = index + 1;
      return updatedLabel;
    });
    setLabelList(updatedListWithUpdatedSequence);
  };

  const editLabel = label => {
    setEditLabelMode(true);
    setLabelForEdit({ ...label });
  };

  const saveUpdatedLabel = () => {
    if (
      some(labelList, ['name', labelForEdit.name.trim()]) &&
      labelForEdit.name.trim() !== labelList[findIndex(labelList, ['id', labelForEdit.id])].name
    ) {
      return NotificationManager.error(DUPLICATE_LABEL_NOT_ALLOWED);
    }
    const updatedList = labelList.map(label => {
      if (label.id === labelForEdit.id) {
        return { ...label, name: labelForEdit.name.trim() };
      }
      return label;
    });
    setLabelList(updatedList);
    setLabelForEdit(null);
    setEditLabelMode(false);
  };

  const updateCDASHQuestion = (newQuestion, indexForUpdate, general) => {
    const fieldsForUpdate = general ? [...generalFields] : [...fieldList];
    if (
      some(fieldList, ['name', newQuestion]) ||
      some(generalFields, ['name', newQuestion]) ||
      some(fieldList, ['updatedQuestion', newQuestion]) ||
      some(generalFields, ['updatedQuestion', newQuestion])
    ) {
      return NotificationManager.error(DUPLICATE_LABEL_NOT_ALLOWED);
    } else if (newQuestion === 'Comment') {
      return NotificationManager.error(COMMENT_FIELD_IS_NOT_AVAILABLE);
    } else {
      const updatedFields = fieldsForUpdate.map((field, index) => {
        if (index === indexForUpdate) {
          return { ...field, updatedQuestion: newQuestion ? newQuestion : null };
        }
        return field;
      });
      if (general) {
        setGeneralFields(updatedFields);
      } else {
        setFieldList(updatedFields);
      }
    }
  };

  const updateCustomItemQuestion = (newQuestion, indexForUpdate, general) => {
    const listForCheck = general ? generalFields : fieldList;
    if (
      (some(fieldList, ['name', newQuestion]) ||
        some(generalFields, ['name', newQuestion]) ||
        some(fieldList, ['updatedQuestion', newQuestion]) ||
        some(generalFields, ['updatedQuestion', newQuestion])) &&
      newQuestion !== listForCheck[indexForUpdate].name
    ) {
      return NotificationManager.error(DUPLICATE_LABEL_NOT_ALLOWED);
    } else if (newQuestion === 'Comment') {
      return NotificationManager.error(COMMENT_FIELD_IS_NOT_AVAILABLE);
    } else {
      if (general) {
        const oldName = generalFields[indexForUpdate].name;
        const updatedGeneralFields = generalFields.map((field, index) => {
          if (index === indexForUpdate) {
            return {
              ...field,
              name: newQuestion,
              question: newQuestion,
              fieldConfigurationResponse: renameConfiguration(field.fieldConfigurationResponse, oldName, newQuestion)
            };
          }
          return {
            ...field,
            fieldConfigurationResponse: renameConfiguration(field.fieldConfigurationResponse, oldName, newQuestion)
          };
        });
        const fieldListWithUpdatedConfiguration = fieldList.map(field => ({
          ...field,
          fieldConfigurationResponse: renameConfiguration(field.fieldConfigurationResponse, oldName, newQuestion)
        }));
        setGeneralFields(updatedGeneralFields);
        setFieldList(fieldListWithUpdatedConfiguration);
      } else {
        const oldName = fieldList[indexForUpdate].name;
        const updatedFieldList = fieldList.map((field, index) => {
          if (index === indexForUpdate) {
            return {
              ...field,
              name: newQuestion,
              question: newQuestion,
              fieldConfigurationResponse: renameConfiguration(field.fieldConfigurationResponse, oldName, newQuestion)
            };
          }
          return {
            ...field,
            fieldConfigurationResponse: renameConfiguration(field.fieldConfigurationResponse, oldName, newQuestion)
          };
        });
        const generalFieldsListWithUpdatedConfiguration = generalFields.map(field => ({
          ...field,
          fieldConfigurationResponse: renameConfiguration(field.fieldConfigurationResponse, oldName, newQuestion)
        }));
        setFieldList(updatedFieldList);
        setGeneralFields(generalFieldsListWithUpdatedConfiguration);
      }
    }
  };

  const toggleCheckForField = (indexForToggle, general = false, removeConfiguration = false) => {
    let fieldListForRemove = [...fieldList];
    const fieldsForUpdate = general ? [...generalFields] : [...fieldList];
    let updatedFieldList = fieldsForUpdate.map((field, index) => {
      if (index === indexForToggle) {
        return { ...field, isChecked: !fieldsForUpdate[index].isChecked, investigatorField: false };
      }
      return field;
    });
    let updatedFieldListSelectedOnly = sortFieldsByIsCheckedAndUpdateSequence([...updatedFieldList]);
    if (removeConfiguration) {
      updatedFieldListSelectedOnly = removeConfigurationAndHideAll(
        updatedFieldListSelectedOnly,
        updatedFieldListSelectedOnly[indexForToggle].name,
        true
      );
      updatedFieldList = removeConfigurationAndHideAll(updatedFieldList, updatedFieldList[indexForToggle].name, true);
      fieldListForRemove = removeConfigurationAndHideAll(
        fieldListForRemove,
        updatedFieldList[indexForToggle].name,
        true
      );
    }
    if (general) {
      if (selectedOnlyGeneral) {
        const filledSelectedOnly = updateConditionalLogicForItems(updatedFieldListSelectedOnly, fieldListForRemove);
        setGeneralFields(filledSelectedOnly[0]);
        setFieldList(filledSelectedOnly[1]);
      } else {
        const filledUpdatedFieldList = updateConditionalLogicForItems(updatedFieldList, fieldListForRemove);
        setGeneralFields(filledUpdatedFieldList[0]);
        setFieldList(filledUpdatedFieldList[1]);
      }
    } else {
      if (selectedOnly) {
        const filledSelectedOnly = updateConditionalLogicForItems(generalFields, updatedFieldListSelectedOnly);
        setFieldList(filledSelectedOnly[1]);
      } else {
        const filledUpdatedFieldList = updateConditionalLogicForItems(generalFields, updatedFieldList);
        setFieldList(filledUpdatedFieldList[1]);
      }
    }
  };

  const toggleGeneralForField = indexForToggle => {
    const updatedFieldList = fieldList
      .filter((field, index) => index !== indexForToggle)
      .map((element, index) => ({ ...element, sequence: index + 1 }));
    const mockedHideAllConfiguration = [...generalFields, ...fieldList]
      .filter(field => isObject(field.hideAllConfiguration))
      .map(element => ({
        conditionFieldAnswerName: element.hideAllConfiguration.hideAllFieldsBelowOnAnswer,
        conditionFieldName: element.name
      }));
    let updatedGeneralFields = [
      ...generalFields,
      {
        ...fieldList[indexForToggle],
        sequence: generalFields.length + 1,
        isChecked: true,
        tableGeneralField: true,
        fieldConfigurationResponse: differenceWith(
          fieldList[indexForToggle].fieldConfigurationResponse,
          mockedHideAllConfiguration,
          (firstEl, secondEl) =>
            firstEl.conditionFieldAnswerName === secondEl.conditionFieldAnswerName &&
            firstEl.conditionFieldName === secondEl.conditionFieldName
        )
      }
    ];
    if (selectedOnlyGeneral) {
      updatedGeneralFields = sortFieldsByIsCheckedAndUpdateSequence(updatedGeneralFields);
    }
    const filledFields = updateConditionalLogicForItems(updatedGeneralFields, updatedFieldList);
    setFieldList(filledFields[1]);
    setGeneralFields(filledFields[0]);
  };

  const toggleInvestigatorField = (checked, indexForToggle, general = false) => {
    const fieldsForUpdate = general ? [...generalFields] : [...fieldList];
    const field = fieldsForUpdate[indexForToggle];
    field.investigatorField = checked;
    field.isChecked = checked ? checked : field.isChecked;
    general ? setGeneralFields(fieldsForUpdate) : setFieldList(fieldsForUpdate);
  };

  const deleteFieldItemFromGeneral = fieldName => {
    const updatedGeneralFields = generalFields
      .filter(field => field.name !== fieldName)
      .map((item, index) => ({ ...item, sequence: index + 1 }));
    const removedItem = generalFields.filter(field => field.name === fieldName);
    let updatedFieldList = [
      ...fieldList,
      { ...removedItem[0], sequence: fieldList.length + 1, tableGeneralField: false }
    ];
    if (selectedOnly) {
      updatedFieldList = sortFieldsByIsCheckedAndUpdateSequence(updatedFieldList);
    }
    const configurationForDelete = isObject(removedItem[0].hideAllConfiguration)
      ? {
          conditionFieldAnswerName: removedItem[0].hideAllConfiguration.hideAllFieldsBelowOnAnswer,
          conditionFieldName: removedItem[0].name
        }
      : null;
    if (configurationForDelete) {
      setFieldList(fillConditionalLogic(updatedFieldList, [9999], [configurationForDelete]));
      setGeneralFields(fillConditionalLogic(updatedGeneralFields, [9999], [configurationForDelete]));
    } else {
      const filledFields = updateConditionalLogicForItems(updatedGeneralFields, updatedFieldList);
      setFieldList(filledFields[1]);
      setGeneralFields(filledFields[0]);
    }
  };

  const updateCustomOptionsList = (newOptionsList, indexForUpdate, general) => {
    if (general) {
      const updatedGeneralFields = generalFields.map((field, index) => {
        if (index === indexForUpdate) {
          return { ...field, codeDefinationList: newOptionsList };
        }
        return field;
      });
      setGeneralFields(updatedGeneralFields);
    } else {
      const updatedFieldList = fieldList.map((field, index) => {
        if (index === indexForUpdate) {
          return { ...field, codeDefinationList: newOptionsList };
        }
        return field;
      });
      setFieldList(updatedFieldList);
    }
  };

  const updateControlledListAndName = (newCtListName, newOptionsList, indexForUpdate, general) => {
    if (general) {
      const updatedGeneralFields = generalFields.map((field, index) => {
        if (index === indexForUpdate) {
          return { ...field, ctListName: newCtListName, codeDefinationList: newOptionsList };
        }
        return field;
      });
      setGeneralFields(updatedGeneralFields);
    } else {
      const updatedFieldList = fieldList.map((field, index) => {
        if (index === indexForUpdate) {
          return { ...field, ctListName: newCtListName, codeDefinationList: newOptionsList };
        }
        return field;
      });
      setFieldList(updatedFieldList);
    }

    if (some(generalFields, ['name', fieldList[indexForUpdate].name])) {
      const updatedGeneralFields = generalFields.map(field => {
        if (field.name === fieldList[indexForUpdate].name) {
          return { ...field, ctListName: newCtListName, codeDefinationList: newOptionsList };
        }
        return field;
      });
      setGeneralFields(updatedGeneralFields);
    }
  };

  const tableSetupIsValid = () => {
    if (!itemGroupName.trim()) {
      NotificationManager.error('ItemGroup Name is required for saving');
      return false;
    }
    if (!tableTitle.trim()) {
      NotificationManager.error(TABLE_LABEL_HEADER_ERROR);
      return false;
    }
    if (!labelList.length) {
      NotificationManager.error('Labels is required for saving');
      return false;
    }
    if (!some(fieldList, ['isChecked', true])) {
      NotificationManager.error('At least one item must be selected in Results section');
      return false;
    }
    if (some(fieldList, ['hasAlert', true]) || some(generalFields, ['hasAlert', true])) {
      NotificationManager.error('One of the items has a warning');
      return false;
    }
    return true;
  };

  const saveTable = () => {
    if (tableSetupIsValid()) {
      resolveApiForSavingData()
        .then(({ data }) => {
          if (data.responsecode === 400) {
            NotificationManager.error(ITEM_GROUP_EXIST);
          } else {
            if (isTemplateMode && currentRoute.name !== 'Item Group Table Template Setup') {
              NotificationManager.success(TEMPLATE_CREATED);
              navigate(generateUrlByKey('Item Group Templates'));
            } else {
              NotificationManager.success(DATA_SAVED);
              const key =
                tableContextMode === CONTEXT_MODE.EDIT
                  ? currentRoute.parent.name === 'Encounter Setup'
                    ? 'Setup Protocol.Source Data Setup.Encounter Setup'
                    : 'Setup Protocol.Source Data Setup.Item Groups List.Table Setup'
                  : 'Setup Protocol.Source Data Setup.Item Groups List';
              navigate(
                generateUrlByKey(key, {
                  studyId: currentRoute.params.studyId,
                  protocolIdentity: currentRoute.params.protocolIdentity
                })
              );
            }
          }
        })
        .catch(error => onRequestError(error));
    }
  };

  const resolveApiForSavingData = () => {
    const fieldListForSaving = [...generalFields, ...fieldList].map((element, index) => ({
      ...element,
      sequence: index + 1
    }));
    if (isTemplateMode && currentRoute.name !== 'Item Group Table Template Setup') {
      const updatedItemDefinitionList = fieldList.map(field => ({
        ...field,
        fieldConfigurationResponse: null,
        filedConfigurationList: field.fieldConfigurationResponse,
        sequence: generalFields.length + field.sequence
      }));
      const updatedItemDefinitionListGeneral = generalFields.map(field => ({
        ...field,
        fieldConfigurationResponse: null,
        filedConfigurationList: field.fieldConfigurationResponse
      }));
      const dataForSavingTemplate = {
        formDomainMaster: {
          domainCode: selectedDomain.domainCode,
          domainIdentifier: selectedDomain.domainIdentifier,
          domainName: selectedDomain.domainName,
          elementIcon: selectedType.name,
          elementName: itemGroupName.trim(),
          itemDefinitionList: updatedItemDefinitionList,
          studyIdentifier: currentRoute.params.studyId,
          terminologyVersionGroupNumber: selectedTerminologyVersionGroups,
          type: selectedType.name === 'Custom' ? 'CUSTOM' : 'OPTIONAL'
        },
        templateFieldConfigurationRequestList: generateConditionalLogicForSaving(fieldListForSaving),
        itemGroupTable: {
          layout: questionLayout,
          title: tableTitle.trim(),
          labels: labelList,
          generalFields: updatedItemDefinitionListGeneral
        }
      };
      if (tableContextMode === CONTEXT_MODE.EDIT && currentRoute.name !== 'Table Template Copy') {
        dataForSavingTemplate.formDomainMaster.uniqueIdentifier = uniqueIdentifier;
        return ItemGroupTemplatesApi.updateItemGroupTemplate(
          currentRoute.params.itemGroupTemplateId,
          dataForSavingTemplate
        );
      }
      return ItemGroupTemplatesApi.saveItemGroupTemplate(dataForSavingTemplate);
    } else {
      const paramsForSaving = {
        studyId: currentRoute.params.studyId,
        domainId: selectedDomain.domainIdentifier
      };
      const generalFieldsForSaving = generalFields.map(item => ({
        name: item.name,
        sequence: item.sequence,
        updatedName: item?.updatedQuestion
      }));
      const dataForSaving = {
        formDomainMaster: {
          domainCode: selectedDomain.domainCode,
          domainIdentifier: selectedDomain.domainIdentifier,
          domainName: selectedDomain.domainName,
          elementIcon: selectedType.name,
          elementName: itemGroupName.trim(),
          itemDefinitionList: fieldListForSaving,
          studyIdentifier: currentRoute.params.studyId
        },
        protocolItemGroupFieldConfigurationList: generateConditionalLogicForSaving(fieldListForSaving),
        tableItemGroupTemplate: {
          layout: questionLayout,
          title: tableTitle.trim(),
          labels: labelList,
          generalFields: generalFieldsForSaving
        }
      };
      if (tableContextMode === CONTEXT_MODE.EDIT) {
        dataForSaving.formDomainMaster.uniqueIdentifier = uniqueIdentifier;
      }
      return FormBuilderApi.saveFormDomain(paramsForSaving, dataForSaving);
    }
  };

  const addNewCustomFieldItem = customItemParams => {
    const newCustomItem = prepareCustomItem(customItemParams, fieldList.length + 1);
    if (newCustomItem) {
      let newFieldList = [...fieldList, newCustomItem];
      if (selectedOnly) {
        newFieldList = sortFieldsByIsCheckedAndUpdateSequence(newFieldList);
      }
      const filledFields = updateConditionalLogicForItems(generalFields, newFieldList);
      setFieldList(filledFields[1]);
      setGeneralFields(filledFields[0]);
    }
  };

  const deleteCustomItem = (customItemName, removeConfiguration = false) => {
    let updatedFieldList = fieldList
      .filter(item => item.name !== customItemName)
      .map((element, index) => ({ ...element, sequence: index + 1 }));
    let updatedGeneralFieldsList = generalFields
      .filter(item => item.name !== customItemName)
      .map((element, index) => ({ ...element, sequence: index + 1 }));
    if (removeConfiguration) {
      updatedFieldList = removeConfigurationAndHideAll(updatedFieldList, customItemName);
      updatedGeneralFieldsList = removeConfigurationAndHideAll(updatedGeneralFieldsList, customItemName);
    }
    setFieldList(updatedFieldList);
    setGeneralFields(updatedGeneralFieldsList);
  };

  const toggleSelectAllItems = (currentStatus, general = false) => {
    const fieldsForToggle = general ? [...generalFields] : [...fieldList];
    let updatedFieldList = fieldsForToggle.map(element => ({ ...element, isChecked: !currentStatus }));
    let fieldsForSeparateUpdate = [...fieldList];
    if (currentStatus) {
      updatedFieldList = updatedFieldList.map(field => ({
        ...field,
        fieldConfigurationResponse: [],
        hideAllConfiguration: null
      }));

      fieldsForSeparateUpdate = fieldsForSeparateUpdate.map(field => ({
        ...field,
        fieldConfigurationResponse: field.fieldConfigurationResponse
          ? field.fieldConfigurationResponse.filter(
              configuration => !some(generalFields, ['name', configuration.conditionFieldName])
            )
          : null
      }));
    }
    if (general) {
      setGeneralFields(updatedFieldList);
      setFieldList(fieldsForSeparateUpdate);
    } else {
      const fieldsWithUpdatedConditionalLogic = updateConditionalLogicForItems(generalFields, updatedFieldList);
      setFieldList(fieldsWithUpdatedConditionalLogic[1]);
    }
  };

  const updateTextBlockValue = (event, itemIndex, general = false) => {
    const fieldsForUpdate = general ? [...generalFields] : [...fieldList];
    const updatedFields = fieldsForUpdate.map((field, index) => {
      if (index === itemIndex) {
        return { ...field, value: event.target.value, inputValue: event.target.value };
      }
      return field;
    });
    if (general) {
      setGeneralFields(updatedFields);
    } else {
      setFieldList(updatedFields);
    }
  };

  const reorderSelectedItems = (general = false) => {
    const fieldsForUpdate = general ? [...generalFields] : [...fieldList];
    const updatedFields = sortFieldsByIsCheckedAndUpdateSequence(fieldsForUpdate);
    if (general) {
      setGeneralFields(updatedFields);
    } else {
      setFieldList(updatedFields);
    }
  };

  const addConditionalLogicForItem = (itemIndex, general, configuration, hideAllConfiguration) => {
    const fieldsForUpdate = general ? [...generalFields] : [...fieldList];
    const fieldsWithUpdatedLogic = fieldsForUpdate.map((field, index) => {
      if (index === itemIndex) {
        return { ...field, fieldConfigurationResponse: configuration, hideAllConfiguration };
      }
      return field;
    });
    const previousHideAllConfiguration = fieldsForUpdate[itemIndex].hideAllConfiguration;
    const configurationForDelete = isObject(previousHideAllConfiguration)
      ? {
          conditionFieldAnswerName: previousHideAllConfiguration.hideAllFieldsBelowOnAnswer,
          conditionFieldName: fieldsForUpdate[itemIndex].name
        }
      : null;
    if (general) {
      if (isObject(previousHideAllConfiguration) && !hideAllConfiguration) {
        setFieldList(fillConditionalLogic(fieldList, [9999], [configurationForDelete]));
        setGeneralFields(fillConditionalLogic(fieldsWithUpdatedLogic, [9999], [configurationForDelete]));
      } else {
        const filledFields = updateConditionalLogicForItems(fieldsWithUpdatedLogic, fieldList);
        setGeneralFields(filledFields[0]);
        setFieldList(filledFields[1]);
      }
    } else {
      if (isObject(previousHideAllConfiguration) && !hideAllConfiguration) {
        setFieldList(fillConditionalLogic(fieldsWithUpdatedLogic, [9999], [configurationForDelete]));
      } else {
        const filledFields = updateConditionalLogicForItems(generalFields, fieldsWithUpdatedLogic);
        setFieldList(filledFields[1]);
      }
    }
  };

  const updateAlertForItem = (itemIndex, alert, general = false) => {
    const fieldsForUpdate = general ? [...generalFields] : [...fieldList];
    const updatedFields = fieldsForUpdate.map((field, index) => {
      if (itemIndex === index) {
        return { ...field, hasAlert: alert };
      } else {
        return field;
      }
    });
    if (general) {
      setGeneralFields(updatedFields);
    } else {
      setFieldList(updatedFields);
    }
  };

  return (
    <TableSetupContext.Provider
      value={{
        tableContextMode,
        domainList,
        typeList,
        selectedDomain,
        selectedType,
        itemGroupName,
        studyName,
        protocolName,
        protocolVersion,
        questionLayout,
        tableTitle,
        labelField,
        labelList,
        editLabelMode,
        labelForEdit,
        fieldList,
        selectedOnly,
        selectedOnlyGeneral,
        generalFields,
        isTemplateMode,
        selectedTerminologyVersionGroups,
        setDomainList,
        setTypeList,
        setSelectedDomain,
        setSelectedType,
        setItemGroupName,
        setStudyName,
        setProtocolName,
        setProtocolVersion,
        setQuestionLayout,
        setTableTitle,
        setLabelField,
        setLabelList,
        setEditLabelMode,
        setLabelForEdit,
        setFieldList,
        setSelectedOnly,
        setSelectedOnlyGeneral,
        setGeneralFields,
        setSelectedTerminologyVersionGroups,
        setSelectedDomainAfterRedirect,
        setSelectedTypeAfterRedirect,
        addNewLabelInList,
        deleteLabelFromList,
        editLabel,
        saveUpdatedLabel,
        updateCDASHQuestion,
        toggleCheckForField,
        toggleGeneralForField,
        toggleInvestigatorField,
        updateCustomOptionsList,
        updateControlledListAndName,
        deleteFieldItemFromGeneral,
        saveTable,
        addNewCustomFieldItem,
        updateCustomItemQuestion,
        deleteCustomItem,
        toggleSelectAllItems,
        updateTextBlockValue,
        reorderSelectedItems,
        addConditionalLogicForItem,
        updateConditionalLogicForItems,
        updateAlertForItem,
        updateTerminologyVersion
      }}
    >
      {children}
    </TableSetupContext.Provider>
  );
};

export function withTableSetupContext(Component) {
  return function WrapperComponent(props) {
    return (
      <TableSetupProvider>
        <Component {...props} />
      </TableSetupProvider>
    );
  };
}

export function withTableTemplateContext(Component) {
  return function WrapperComponent(props) {
    return (
      <TableSetupProvider isTemplateMode>
        <Component {...props} />
      </TableSetupProvider>
    );
  };
}
