import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactTable from 'react-table';

import { PreviewTableApi, ProtocolApi, ProtocolItemGroupTemplatesApi } from '../../../../../../api';
import Section from '../../../../../../common/data-display/Section/Section';
import Input from '../../../../../../common/data-entry/Input';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import Icon from '../../../../../../common/general/Icon';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import { pendoTrackDefaultSortingChange } from '../../../../../../pendo/PendoUtils';
import { onRequestError } from '../../../../../../services/handlers';
import { CellFormattedDate } from '../../../../../CellFormattedDate/CellFormattedDate';
import { PageInfoHeader } from '../../../../../PageInfoHeader/PageInfoHeader';
import { generateUrlByKey, useCurrentRoute } from '../../../../../root/router';
import { mapItemGroupDomainResponseToPreviewDto } from '../../../shared/elementDomainToPreviewDtoMapperService';
import { CUSTOM, OPTIONAL } from '../../../shared/ElementSetupNew/itemGroupsConstants/itemGroupFields';
import { LOGS, TABLE } from '../../../shared/ElementSetupNew/itemGroupsConstants/itemGroupTypes';
import { getCombinedList } from '../../../shared/ElementSetupNew/ItemGroupSetup/ItemGroupSetupContent/ItemGroupSetupService';
import { RenderForm } from '../../../shared/ElementSetupNew/ItemGroupSetup/RenderForm';
import TablePreviewModal from '../../../shared/ElementSetupNew/TableSetup/TablePreview/TablePleviewModal/TablePreviewModal';
import { TABLE_SETUP } from '../../../shared/ElementSetupNew/TableSetup/TableSetupConstants';
import ItemGroupTemplatePreviewModal from '../../../shared/ItemGroupTemplatePreviewModal/ItemGroupTemplatePreviewModal';

import AddNewItemGroup from './AddNewItemGroup/AddNewItemGroup';
import CopyItemGroupModal from './CopyItemGroupModal/CopyItemGroupModal';

import './ItemGroupList.scss';

export function ItemGroupList() {
  const currentRoute = useCurrentRoute();
  const studyId = currentRoute.params?.studyId;
  const protocolIdentity = currentRoute?.params.protocolIdentity;
  const navigate = useNavigate();

  const [protocolMetaInfo, setProtocolMetaInfo] = useState({});
  const [itemGroups, setItemGroups] = useState([]);
  const [filteredItemGroups, setFilteredItemGroups] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const columns = getColumns();

  useEffect(() => {
    ProtocolItemGroupTemplatesApi.getByStudyId(studyId).then(res => {
      setItemGroups(res?.data);
    });
    ProtocolApi.getPreviewProtocol(studyId, protocolIdentity).then(res => {
      setProtocolMetaInfo(res.data);
    });
  }, [studyId, protocolIdentity, navigate]);

  useEffect(() => {
    const filteredItemGroups = itemGroups.filter(itemGroup => {
      return itemGroup?.name?.toLowerCase().includes(searchValue?.toLowerCase());
    });
    setFilteredItemGroups(filteredItemGroups);
  }, [searchValue, itemGroups]);

  const onRowClick = row => {
    const params = {
      studyId,
      protocolIdentity,
      element_identifier: row?.original?.itemGroupTemplateVersionId
    };
    if (row.row.type === TABLE_SETUP) {
      navigate(generateUrlByKey(`${currentRoute.key}.Table Setup.Table Edit`, params));
    } else {
      navigate(generateUrlByKey(`${currentRoute.key}.Item Group Setup.Item Group Edit`, params));
    }
  };

  return (
    <React.Fragment>
      <PageInfoHeader
        objectRecordLabel={protocolMetaInfo?.studyName}
        pageInfo={
          <PageInfoHeader.CollapsibleList>
            <PageInfoHeader.AdditionalInfo tooltip="Source Data Name">
              {protocolMetaInfo?.name}
            </PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo tooltip="Version">{protocolMetaInfo?.version}</PageInfoHeader.AdditionalInfo>
          </PageInfoHeader.CollapsibleList>
        }
      />
      <Section className="eds-protocol-setup-item-group-list">
        <div className="d-flex justify-content-between">
          <Input
            label="Search"
            name="search"
            className="search-input"
            value={searchValue}
            onChange={e => {
              setSearchValue(e?.target?.value);
            }}
            iconsAfter={<Icon>search</Icon>}
            validate={false}
          />
          <AddNewItemGroup terminologyVersionGroupNumber={protocolMetaInfo.terminologyVersionGroupNumber} />
        </div>
        <ReactTable
          data={filteredItemGroups}
          columns={columns}
          minRows={1}
          multiSort
          onSortedChange={pendoTrackDefaultSortingChange}
          className="eui-table eds-protocol-setup-item-groups-table"
          showPagination
          nextText=">>"
          previousText="<<"
          noDataText="No Record Found"
          pageSizeOptions={[25, 50, 100]}
          defaultPageSize={25}
          getTrProps={(state, row = {}) => ({
            onClick: () => onRowClick(row)
          })}
        />
      </Section>
    </React.Fragment>
  );

  function getColumns() {
    return [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Domain',
        accessor: 'domainName'
      },
      {
        Header: 'Type',
        accessor: 'type'
      },
      {
        Header: 'Items',
        accessor: 'numberOfItems'
      },
      {
        Header: 'Date edited',
        accessor: 'lastModifiedOn',
        Cell: ({ value }) => <CellFormattedDate date={new Date(value)} />
      },
      {
        Header: '',
        sortable: false,
        Cell: row => {
          return (
            <div className="actions-layout">
              <span
                className="material-icons eye-icon"
                onClick={e => {
                  e.stopPropagation();
                  if (row.row.type === TABLE_SETUP) {
                    openNewTablePreview(row.original, studyId);
                  } else {
                    openItemGroupPreviewModal(row.original);
                  }
                }}
              >
                visibility
              </span>
              {protocolMetaInfo && protocolMetaInfo.versionId === 1 && (
                <Button className="delete-button ml-2" size="h28" onClick={e => handleItemGroupDelete(e, row)}>
                  Delete
                </Button>
              )}
              <Button
                className="copy-button ml-2"
                size="h28"
                onClick={e => {
                  e.stopPropagation();
                  openCopyItemGroupModalPreview(
                    {
                      study_identifier: studyId,
                      element_identifier: row?.original?.itemGroupTemplateVersionId
                    },
                    row.row.type === TABLE_SETUP
                  );
                }}
              >
                Copy
              </Button>
            </div>
          );
        }
      }
    ];
  }

  function openItemGroupPreviewModal(row) {
    const requestParams = {
      study_identifier: studyId,
      element_identifier: row?.itemGroupTemplateVersionId
    };
    getItemGroupDomain(requestParams).then(previewFormData => {
      ModalBoxes.open({
        component: (
          <ItemGroupTemplatePreviewModal
            renderFormData={
              <React.Fragment>
                <div className="d-flex">
                  <div className="mr-2">
                    <Input label="Name" name="name" value={row?.name} disabled />
                  </div>
                  <div className="mx-2">
                    <Input label="Domain" name="domain" value={row?.domainName} disabled />
                  </div>
                  <div className="mx-2">
                    <Input label="Type" name="type" value={row?.type} disabled />
                  </div>
                </div>
                <RenderForm
                  formData={previewFormData}
                  displayTableType={previewFormData.elementIcon === TABLE}
                  elementIcon={previewFormData.elementIcon}
                  inputs={previewFormData.inputs}
                  isLogCheckSetupEnabled={previewFormData.elementIcon === LOGS}
                />
              </React.Fragment>
            }
          />
        ),
        title: `Item Group Preview`
      });
    });
  }

  async function openCopyItemGroupModalPreview(requestParams, isNewTable) {
    let previewFormData;
    if (isNewTable) {
      const newTableData = await getAndPrepareDataForNewTablePreview(requestParams);
      previewFormData = {
        encounterTableItemGroupForPreview: newTableData.encounterTableItemGroupForPreview,
        conditionalLogicAndRequiredFieldsValidation: newTableData.conditionalLogicAndRequiredFieldsValidation,
        uniqueIdentifier: requestParams.element_identifier,
        studyIdentifier: requestParams.study_identifier,
        elementName: newTableData.encounterTableItemGroupForPreview.itemGroupName + '(copy)',
        elementIcon: newTableData.encounterTableItemGroupForPreview.type
      };
    } else {
      previewFormData = await getItemGroupDomain(requestParams);
    }

    ModalBoxes.open({
      component: (
        <CopyItemGroupModal
          protocolIdentity={protocolIdentity}
          currentRoute={currentRoute}
          previewFormData={previewFormData}
          inputs={previewFormData.inputs}
        />
      )
    });
  }

  function handleItemGroupDelete(e, row) {
    e.stopPropagation();
    ProtocolApi.deleteItemGroupFromInitialDraftProtocol(
      protocolMetaInfo?.studyIdentifier,
      protocolMetaInfo?.uniqueIdentifier,
      row?.original?.itemGroupTemplateId
    )
      .then(res => {
        setItemGroups(itemGroups.filter(ig => ig.itemGroupTemplateId !== row?.original?.itemGroupTemplateId));
        NotificationManager.success('Protocol item group deleted successfully');
      })
      .catch(() => {
        NotificationManager.error('Something went wrong during the item group deletion');
      });
  }

  function getItemGroupDomain(requestParams) {
    return ProtocolItemGroupTemplatesApi.getElementDomain(requestParams).then(({ data }) => {
      data.elementName = data.elementName + '(copy)';
      const inputs = [];
      inputs.push(data);
      return {
        ...mapToModalPreviewDto(inputs),
        inputs
      };
    }, onRequestError);
  }

  function mapToModalPreviewDto(data) {
    const formData = data[0] && JSON.parse(JSON.stringify(data[0]));
    if (formData) {
      mapItemGroupDomainResponseToPreviewDto(formData);
      const combinedList = getCombinedList(Array.from(data), false).map(label => {
        if (label.hasOwnProperty('customItemDefinationList')) {
          return label.customItemDefinationList[0];
        } else {
          return label;
        }
      });
      if (formData.hasOwnProperty(OPTIONAL)) {
        delete formData.optional;
      }
      if (formData.hasOwnProperty(CUSTOM.toLowerCase())) {
        delete formData.custom;
      }
      if (formData.domainCode === 'AE') {
        formData.columnList = combinedList.filter(label => label.isChecked).map(label => label.name);
      }
      return {
        ...formData,
        labelList: combinedList,
        logCheckOptions: formData.logCheckTemplate?.rows,
        logCheckInstruction: formData.logCheckTemplate?.instruction
      };
    }
  }
}

export async function openNewTablePreview(row, studyId) {
  try {
    const requestParams = {
      study_identifier: studyId,
      element_identifier: row?.itemGroupTemplateVersionId
    };
    const newTableData = await getAndPrepareDataForNewTablePreview(requestParams);

    ModalBoxes.open({
      component: (
        <TablePreviewModal
          encounterTableItemGroupForPreview={newTableData.encounterTableItemGroupForPreview}
          conditionalLogicAndRequiredFieldsValidation={newTableData.conditionalLogicAndRequiredFieldsValidation}
        />
      )
    });
  } catch (error) {
    onRequestError(error);
  }
}

async function getAndPrepareDataForNewTablePreview(requestParams) {
  try {
    const { data } = await PreviewTableApi.getNewTablePreview(requestParams);
    const conditionalLogicAndRequiredFieldsValidation = data.conditionalLogicAndRequiredFieldsValidation;
    return { encounterTableItemGroupForPreview: data, conditionalLogicAndRequiredFieldsValidation };
  } catch (error) {
    onRequestError(error);
  }
}
