import axios from 'axios';

const ROOT = 'api/v1/finance/budget';

const OptionalItemGroupApi = {
  getOptionalBudgetDetailsExclude(budgetId) {
    return axios.get(`${ROOT}/${budgetId}/optional-item-groups/setup`);
  },

  makeItemGroupOptional(budgetId, encounterId, itemGroupKey) {
    return axios.post(`${ROOT}/${budgetId}/optional-item-groups`, undefined, {
      hideLoader: true,
      params: { budgetId, encounterId, itemGroupKey }
    });
  },
  makeItemGroupRequired(budgetId, encounterId, itemGroupKey) {
    return axios.delete(`${ROOT}/${budgetId}/optional-item-groups`, {
      hideLoader: true,
      params: { budgetId, encounterId, itemGroupKey }
    });
  }
};

export default OptionalItemGroupApi;
