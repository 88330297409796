import React from 'react';
import { isEmpty } from 'lodash/lang';

import Chip, { ChipLabel } from '../../../HomePageDashboards/Widgets/common/Chip';
import { getNames } from '../../ScheduleWorklist/SchedulingWorklistService';

export default function WorklistChips({ Context }) {
  const { allStudies, allSites, filter, resetStudies, resetSites } = Context;

  return (
    <div className="pr-3 chips-container">
      {allStudies.length === 1 && <ChipLabel label="Study" value={allStudies[0].name} />}
      {allSites.length === 1 && <ChipLabel label="Site" value={allSites[0].name} />}
      {allStudies.length > 1 && (
        <ChipInstance
          label="Study"
          originalItems={filter.studies}
          values={getNames(filter.selectedStudies)}
          onClose={resetStudies}
        />
      )}
      {allSites.length > 1 && (
        <ChipInstance
          label="Site"
          originalItems={filter.sites}
          values={getNames(filter.selectedSites)}
          onClose={resetSites}
        />
      )}
    </div>
  );
}

function ChipInstance({ label, originalItems, values, onClose }) {
  return (
    <>
      {!isEmpty(values) && originalItems.length >= 1 && (
        <Chip label={label} values={values} onClose={onClose} originalItems={originalItems} />
      )}
    </>
  );
}
