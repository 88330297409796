import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './ButtonWithIcon.scss';

export default function ButtonWithIcon(props) {
  const { className, suit, icon, rightIcon, size, children, ...restProps } = props;
  const suitClassName = {
    'material-icons': suit === 'material',
    [`glyphicon glyphicon-${icon}`]: suit === 'glyphicon'
  };
  return (
    <button
      className={cx(`eds-btn-with-icon eds-btn-with-icon-${size}`, className, {
        'eds-btn-with-icon-right': rightIcon
      })}
      {...restProps}
    >
      <i className={cx(suitClassName)}>{suit !== 'glyphicon' ? icon : ''}</i>
      <span>{children}</span>
    </button>
  );
}

ButtonWithIcon.propTypes = {
  style: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.any,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  suit: PropTypes.oneOf(['glyphicon', 'material']),
  icon: PropTypes.string,
  rightIcon: PropTypes.bool
};

ButtonWithIcon.defaultProps = {
  size: 'medium',
  suit: 'material',
  icon: 'add',
  rightIcon: false
};
