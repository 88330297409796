import React from 'react';
import { NavLink } from 'react-router-dom';
import { isArray } from 'lodash/lang';
import { get } from 'lodash/object';

import Divider from '../../../../../common/elements/Divider/Divider';
import { useCurrentRoute } from '../../../../root/router';
import { composePatientName, hasUserAccessToProtectedPatientInfo } from '../../CalendarUtils';
import { generateLinkToPatientInfoPage } from '../AppointmentView';
import { AppointmentViewField, ParticipantField } from '../AppointmentViewField';

export function PatientEncounterAppointmentViewContent({ appointment }) {
  const currentRoute = useCurrentRoute();
  const participants = isArray(appointment.participants) ? appointment.participants : [];
  const patientNameWithSubjectId = composePatientName(
    appointment,
    get(appointment, 'patient'),
    get(appointment, 'patientSubjectId')
  );
  const hasUserAccessToProtectedPatientData = hasUserAccessToProtectedPatientInfo(appointment);

  return (
    <React.Fragment>
      <AppointmentViewField label={'Patient'} className="mb-4 mt-2">
        {!hasUserAccessToProtectedPatientData && <div className={'primary'}>{patientNameWithSubjectId}</div>}
        {hasUserAccessToProtectedPatientData && (
          <NavLink
            to={generateLinkToPatientInfoPage(
              appointment?.patient?.patientId,
              appointment?.sitePatientId,
              currentRoute?.parent?.parent?.name
            )}
          >
            <div className={'normal'}>
              {patientNameWithSubjectId}
              {appointment.patient?.preferredName && (
                <span className="patient-preferred-name"> ({appointment.patient.preferredName})</span>
              )}
            </div>
          </NavLink>
        )}
      </AppointmentViewField>
      <Divider className="mt-0" />
      <AppointmentViewField label={'Study'} className="mb-4">
        <div className={'normal'}>{get(appointment, 'studySite.study.name')}</div>
      </AppointmentViewField>
      <AppointmentViewField label={'Site'} className="mb-4">
        <div className={'normal'}>{get(appointment, 'studySite.site.name')}</div>
      </AppointmentViewField>
      <AppointmentViewField label={'Epoch'} className="mb-4">
        <div className={'normal'}>{get(appointment, 'epoch.name')}</div>
      </AppointmentViewField>
      <AppointmentViewField label={'Encounter'} className="mb-4">
        <div className={'normal'}>{get(appointment, 'encounter.name')}</div>
      </AppointmentViewField>
      <Divider className="mt-0" />
      <AppointmentViewField label={'Scheduled by'} className="reviewers-container mb-3">
        <ParticipantField participant={get(appointment, 'organizer')} />
      </AppointmentViewField>
      <AppointmentViewField label={'Attendees '} className="reviewers-container mb-3">
        {participants.map(participant => (
          <ParticipantField
            participant={participant}
            appointment={appointment}
            key={get(participant, 'userId', 'patientId')}
            className="mb-2"
          />
        ))}
      </AppointmentViewField>
      {hasUserAccessToProtectedPatientData && appointment?.comment && (
        <React.Fragment>
          <Divider className="mt-0" />
          <AppointmentViewField label={'Comment'}>
            <div className={'normal'}>{appointment.comment}</div>
          </AppointmentViewField>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
