import React from 'react';
import { Navigate } from 'react-router-dom';
import { isEmpty } from 'lodash/lang';

import NotificationManager from '../../../common/notifications/NotificationManager';
import { UNAUTHORIZED_ACCESS } from '../../../constants/notificationMessages';
import { useCurrentUser } from '../Container/CurrentUserContainer';

export function withAuthorizedAccess(access, Component) {
  function WrapperComponent(props) {
    const { accessToPages } = useCurrentUser();
    if (!accessAllowed(access, accessToPages)) {
      setTimeout(function() {
        NotificationManager.error(UNAUTHORIZED_ACCESS);
      }, 50);
      return <Navigate to="/" replace />;
    }
    return <Component {...props} />;
  }

  return WrapperComponent;
}

export function accessAllowed(access, accessToPages) {
  if (isEmpty(accessToPages) || isEmpty(access)) {
    return false;
  }
  return access.every(v => accessToPages.includes(v));
}
