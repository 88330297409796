import React, { useContext, useMemo } from 'react';
import { isEmpty } from 'lodash/lang';

import Button from '../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../common/general/ButtonGroup';
import { MANAGE_LEDGER_EVENTS } from '../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../services/auth';
import { PageInfoHeader } from '../../../../../PageInfoHeader/PageInfoHeader';
import { generateUrlByKey } from '../../../../../root/router';
import { AddLedgerContext } from '../AddLedgerContext';

import { AddLedgerFooterControl } from './AddLedgerFooterControll/AddLedgerFooterControl';
import { AddLedgerStep } from './AddLedgerStep/AddLedgerStep';
import { AddLedgerStepsBar } from './AddLedgerStepsBar/AddLedgerStepsBar';

import './AddLedgerContent.scss';

export default function AddLedgerContent() {
  const {
    finBudgetRowId,
    setCurrentStep,
    reason,
    stepsList,
    currentStep,
    checkedBudgetItems,
    createLedgerEvents
  } = useContext(AddLedgerContext);
  const currentStepIndex = useMemo(() => {
    return stepsList.findIndex(step => currentStep === step);
  }, [stepsList, currentStep]);
  const isPreviousShow = useMemo(() => currentStepIndex > 0, [currentStepIndex]);
  const isSaveShow = useMemo(() => currentStepIndex === 2, [currentStepIndex]) && userHasAccessTo(MANAGE_LEDGER_EVENTS);
  const isNextShow = useMemo(() => currentStepIndex < 2, [currentStepIndex]);
  const isNextButtonDisabled = useMemo(
    () => (currentStepIndex === 0 && !finBudgetRowId) || (currentStepIndex === 1 && isEmpty(checkedBudgetItems)),
    [checkedBudgetItems, currentStepIndex, finBudgetRowId]
  );
  const isSaveButtonDisabled = useMemo(() => currentStepIndex === 2 && isEmpty(reason), [reason, currentStepIndex]);
  return (
    <>
      <PageInfoHeader
        right={
          <ButtonGroup>
            <Button href={generateUrlByKey('Ledger')} target="_self" size="h40" priority="low">
              Cancel
            </Button>
            {isPreviousShow && (
              <Button size="h40" priority="medium" onClick={previousStep}>
                Previous
              </Button>
            )}
            {isSaveShow && (
              <Button size="h40" disabled={isSaveButtonDisabled} onClick={createLedgerEvents}>
                Save
              </Button>
            )}
            {isNextShow && (
              <Button size="h40" onClick={nextStep} disabled={isNextButtonDisabled}>
                Next
              </Button>
            )}
          </ButtonGroup>
        }
      />
      <div className="add-ledger-content">
        <div className="al-content">
          <AddLedgerStepsBar />
          <AddLedgerStep />
        </div>
        <AddLedgerFooterControl />
      </div>
    </>
  );

  function nextStep() {
    setCurrentStep(function() {
      return stepsList.find((next, index) => currentStepIndex + 1 === index);
    });
  }
  function previousStep() {
    setCurrentStep(function() {
      return stepsList.find((next, index) => currentStepIndex - 1 === index);
    });
  }
}
