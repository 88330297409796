import { isEqual } from 'lodash/lang';

import { getDistance } from '../../services';

import setAddressField from './stateProcessors/setAddressField';
import setAddressType from './stateProcessors/setAddressType';
import setCity from './stateProcessors/setCity';
import setCountryId from './stateProcessors/setCountryId';
import setSsuPatientDetails from './stateProcessors/setSsuPatientDetails';
import setValid from './stateProcessors/setValid';

export function reducer(previousState, action) {
  const processedState = processState(previousState, action);
  return compareAndFix(previousState, processedState, action.type);
}

function processState(state, action) {
  const { type, payload } = action;
  if (type === 'SET_SSU_PATIENT_DETAILS') {
    return setSsuPatientDetails(state, action);
  }
  if (type === 'SET_ADDRESS_FIELD') {
    return setAddressField(state, action);
  }
  if (type === 'SET_COUNTRY_ID') {
    return setCountryId(state, action);
  }
  if (type === 'SET_CITY') {
    return setCity(state, action);
  }
  if (type === 'SET_ADDRESS_TYPE') {
    return setAddressType(state, action);
  }
  if (type === 'SET_VALID') {
    return setValid(state, action);
  }
  if (type === 'SET_SYNCING') {
    return { ...state, syncing: payload };
  }
  return state;
}

function compareAndFix(previousState, processedState, type) {
  if (previousState.mileageAddresses !== processedState.mileageAddresses) {
    if (type !== 'SET_VALID') {
      doValidRecalculation(previousState, processedState);
    }
    if (
      previousState.mileageAddresses.roundTrip !== processedState.mileageAddresses.roundTrip ||
      previousState.mileageAddresses.calculatedDistance !== processedState.mileageAddresses.calculatedDistance
    ) {
      doDistanceRecalculation(previousState, processedState);
    }
  }
  return processedState;
}

function doValidRecalculation(previousState, processedState) {
  ['startAddress', 'endAddress'].forEach(function(path) {
    if (compare(previousState.mileageAddresses[path], processedState.mileageAddresses[path])) {
      processedState.mileageAddresses[path].valid = false;
    }
  });
}

function compare(a, b) {
  return !isEqual(
    {
      countryId: a.countryId,
      regionId: a.regionId,
      city: a.city,
      address1: a.address1,
      zipCode: a.zipCode
    },
    {
      countryId: b.countryId,
      regionId: b.regionId,
      city: b.city,
      address1: b.address1,
      zipCode: b.zipCode
    }
  );
}

function doDistanceRecalculation(previousState, processedState) {
  if (compareDistance(previousState.mileageAddresses)) {
    const { roundTrip, calculatedDistance } = processedState.mileageAddresses;
    processedState.mileageAddresses.distance = getDistance(roundTrip, calculatedDistance);
  }
}

function compareDistance({ roundTrip, distance, calculatedDistance }) {
  return (!distance && !calculatedDistance) || getDistance(roundTrip, distance, true) === +calculatedDistance;
}
