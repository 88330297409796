import { useRef, useState } from 'react';
import { IconButton } from '@mui/material';

import useOutsideClickDetector from '../../../../../../../../common/hooks/useOutsideClickDetector';
import { ColorPicker } from '../ColorPicker/ColorPicker';
import { iconButtonStyles } from '../ToolbarPlugin';

import './IconWithColorPicker.scss';

export const IconWithColorPicker = ({ Icon, color, onChange, ...restProps }) => {
  const menuRef = useRef();
  useOutsideClickDetector(menuRef, () => {
    setMenuOpen(false);
  });
  const [menuOpen, setMenuOpen] = useState(false);
  const handleToggleMenu = () => {
    setMenuOpen(prevState => !prevState);
  };
  return (
    <div className="icon-with-color-picker" ref={menuRef}>
      <IconButton sx={iconButtonStyles} onClick={handleToggleMenu} {...restProps}>
        <Icon />
      </IconButton>
      {menuOpen && <ColorPicker onChange={onChange} color={color} />}
    </div>
  );
};
