import React from 'react';
import { isEmpty } from 'lodash';

import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../common/general/ButtonGroup';

import './ValidateCodeDefinitionChangesModal.scss';

const ValidateCodeDefinitionChangesModal = ({ changedCodeDefinitionsMap, onConfirm, onClose }) => {
  return (
    <>
      <ModalBoxes.Header>
        The following fields are impacted with the new Terminology Version. Do you wish to continue?
      </ModalBoxes.Header>
      <ModalBoxes.Body>
        <div className="column-headers">
          <div>Original Version</div>
          <div>New Template</div>
        </div>
        {[...changedCodeDefinitionsMap].map(([key, value]) => (
          <div key={key} className="changed-values-group">
            <div className="changed-values-group-header">{key}</div>
            <div className="changed-values-group-body">
              <div className="column">
                <ul>
                  {isEmpty(value[0]) ? <li>[Empty values]</li> : value[0].map(item => <li key={item}>{item}</li>)}
                </ul>
              </div>
              <div className="column">
                <ul>
                  {isEmpty(value[1]) ? <li>[Empty values]</li> : value[1].map(item => <li key={item}>{item}</li>)}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button size="h40" priority="medium" onClick={onClose}>
            No
          </Button>
          <Button size="h40" priority="high" onClick={onConfirm}>
            Yes
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
};

ValidateCodeDefinitionChangesModal.className = 'validate-code-definition-changes';

export default ValidateCodeDefinitionChangesModal;
