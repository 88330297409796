import React, { useEffect, useState } from 'react';
import { filter } from 'lodash/collection';
import { isArray, isEmpty, isEqual, isFunction, isNull, isUndefined } from 'lodash/lang';
import { omit } from 'lodash/object';
import * as PropTypes from 'prop-types';
import { oneOf } from 'prop-types';

import { pendoTrack } from '../../../pendo/PendoUtils';
import Checkbox from '../InputSelectors/Checkbox';
import Select from '../Select';

import './MultiSelect.scss';

//This component set initial value only if datasource has changed. This was made to avoid redundant renders,
// calls onChange. This also helps to manage state of SELECT ALL option based on previous and current state.
// To reset initial value you need to pass deep copy of datasource. This will trigger resetting of initial value.
const MultiSelect = React.forwardRef(function(props, ref) {
  const { dataSource, defaultValue } = props;
  const propValue = props.value;
  const [value, setValue] = useState(isUndefined(propValue) ? defaultValue : propValue);

  useEffect(() => {
    const { value } = props;

    if (isArray(value) && !isEmpty(value)) {
      setValue(value);

      const isAllItemsSelected = value?.length === dataSource?.length;
      setIsAllItemsSelected(isAllItemsSelected);
      const isSomeItemsSelected = value?.length > 0;
      setIsSomeItemSelected(isSomeItemsSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  const [isSomeItemsSelected, setIsSomeItemSelected] = useState(false);
  const [isAllItemsSelected, setIsAllItemsSelected] = useState(false);

  const isDefinedNewValue = !isUndefined(propValue);
  const isUndefinedNewValue = props.hasOwnProperty('value') && (isUndefined(propValue) || isNull(propValue));
  const isDefinedCurrentValue = !isUndefined(value) && !isNull(value);
  const isNotEqualCurrentValueAndNewValue = !isEqual(propValue, value);
  const dataSourceWithoutDisabled = filter(dataSource, ({ disabled }) => !disabled);

  const SELECT_ALL = {
    id: 'ALL',
    checked: 0,
    name: 'Select All',
    disabled: !dataSourceWithoutDisabled.length
  };

  if ((isUndefinedNewValue && isDefinedCurrentValue) || (isDefinedNewValue && isNotEqualCurrentValueAndNewValue)) {
    setValue(propValue);
  }

  const rest = omit(
    props,
    'defaultValue',
    'onChange',
    'customOptionTemplateFunction',
    'customSelectedValueTemplateFunction',
    'dataSource',
    'value',
    'initialValue'
  );

  const customSelectedValueTemplateFunction = getCustomSelectedValueTemplateFunction();

  return (
    <Select
      value={value}
      onChange={onChange}
      customOptionTemplateFunction={customOptionTemplateFunction}
      customSelectedValueTemplateFunction={customSelectedValueTemplateFunction}
      dataSource={dataSource?.length > 0 ? [SELECT_ALL, ...dataSource] : dataSource}
      multiple={true}
      forceDefaultBrowserScrolling
      groupBy="checked"
      {...rest}
    />
  );

  function onChange(currentValue) {
    const { onChange } = props;
    if (isEqual(currentValue, value)) {
      return;
    }
    if (!currentValue || isEmpty(currentValue)) {
      setValue([]);
      setIsSomeItemSelected(false);
      setIsAllItemsSelected(false);
      pendoTrack('Click on multiselect', { value: currentValue, pageName: window.location.pathname });
      isFunction(onChange) && onChange([]);
    } else {
      const currentValueWithoutSelectAll = currentValue?.filter(el => el?.id !== 'ALL');
      const previousValueWithoutSelectAll = value?.filter(el => el?.id !== 'ALL') || [];
      const isAllOptionsSelected = currentValueWithoutSelectAll?.length === dataSourceWithoutDisabled?.length;

      const isSelectAllOptionClicked = isEqual(currentValueWithoutSelectAll, previousValueWithoutSelectAll);

      if (!isAllOptionsSelected && !isSelectAllOptionClicked) {
        setIsSomeItemSelected(true);
        setIsAllItemsSelected(false);
        setValue(currentValue);
        isFunction(onChange) && onChange(currentValue);
      } else if (!isAllOptionsSelected && isSelectAllOptionClicked) {
        setIsSomeItemSelected(true);
        setIsAllItemsSelected(true);
        setValue(dataSourceWithoutDisabled);
        isFunction(onChange) && onChange(dataSourceWithoutDisabled);
      } else if (isAllOptionsSelected && !isSelectAllOptionClicked) {
        setIsSomeItemSelected(true);
        setIsAllItemsSelected(true);
        setValue(currentValue);
        isFunction(onChange) && onChange(currentValue);
      } else if (isAllOptionsSelected && isSelectAllOptionClicked) {
        setIsSomeItemSelected(false);
        setIsAllItemsSelected(false);
        setValue([]);
        isFunction(onChange) && onChange([]);
      }
    }
    pendoTrack('Click on mulitselect', { value: currentValue, pageName: window.location.pathname });
  }

  function getCustomSelectedValueTemplateFunction() {
    const { customSelectedValueTemplateFunction } = props;
    if (isFunction(customSelectedValueTemplateFunction)) {
      return customSelectedValueTemplateFunction;
    } else {
      return defaultMultipleSelectedValueTemplateFunction;
    }
  }

  function customOptionTemplateFunction(el) {
    const { customOptionTemplateFunction } = props;
    if (el?.id === 'ALL') {
      return (
        <React.Fragment>
          <Checkbox
            indeterminate={isSomeItemsSelected && !isAllItemsSelected}
            checked={isAllItemsSelected}
            className="select-all-option"
            label={el.name}
          />
        </React.Fragment>
      );
    } else {
      if (isFunction(customOptionTemplateFunction)) {
        return (
          <>
            <Checkbox checked={!isEmpty(value) && value?.some(chosenElements => chosenElements?.id === el.id)} />
            {customOptionTemplateFunction(el)}
          </>
        );
      } else {
        return (
          <Checkbox
            checked={!isEmpty(value) && value?.some(chosenElements => chosenElements?.id === el.id)}
            label={el.name}
          />
        );
      }
    }
  }

  function defaultMultipleSelectedValueTemplateFunction(elements) {
    return (
      <React.Fragment>
        {elements
          .map(el => el?.name)
          .filter(el => el?.id !== 'ALL')
          .join(', ')}
        <span className="count">{elements.length}</span>
      </React.Fragment>
    );
  }
});

MultiSelect.defaultProps = {
  valid: true,
  disabled: false,
  clearable: true,
  required: false,
  validate: true,
  deselectOnSelectedOptionClick: true,
  keepOpenOnSelection: true,
  focusToSelectedValue: false,
  closeOnSelectedOptionClick: false,
  legacyLook: false
};

MultiSelect.propTypes = {
  validationMessage: PropTypes.string,
  valid: PropTypes.bool,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  required: PropTypes.bool,
  type: oneOf(['default', 'chips']),
  legacyLook: PropTypes.bool
};

export default MultiSelect;
