import React from 'react';

import { RevenueAdjustmentLayout } from './RevenueAdjustmentLayout/RevenueAdjustmentLayout';
import { AdjustmentProvider } from './AdjustmentContext';

export function RevenueAdjustment() {
  return (
    <AdjustmentProvider>
      <RevenueAdjustmentLayout />
    </AdjustmentProvider>
  );
}
