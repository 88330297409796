import moment from 'moment-timezone';

export function toTimeZone(date, eventTimeZone, newTimeZone, localTimeZone) {
  return moment(date)
    .tz(eventTimeZone, true)
    .tz(newTimeZone)
    .tz(localTimeZone, true);
}

export function toTimeZoneWithoutNormalizingToCurrent(date, currentTimeZoneId, newTimeZoneId) {
  return moment(date)
    .tz(currentTimeZoneId, true)
    .tz(newTimeZoneId);
}

export function toTimeZoneWithNormalizingToCurrent(date, selectedTimeZoneId, localTimeZone) {
  return moment(date)
    .tz(selectedTimeZoneId)
    .tz(localTimeZone, true);
}

export function getAppointmentWithFixedTimeZone(appointment, zoneIdA, zoneIdB, zoneIdC) {
  return {
    ...appointment,
    timeDuration: {
      ...appointment.timeDuration,
      start: toTimeZone(appointment.timeDuration.start, zoneIdA, zoneIdB, zoneIdC)
    }
  };
}

export function getTimeForScroll(initialTime) {
  const currentHourForSelectedTimeZone = moment(initialTime).hour();
  if (currentHourForSelectedTimeZone <= 2) {
    return moment(initialTime)
      .startOf('day')
      .toDate();
  }
  if (currentHourForSelectedTimeZone >= 21) {
    return moment(initialTime).toDate();
  }
  return getTimeForScrollingToMiddleOfPage(initialTime);
}

function getTimeForScrollingToMiddleOfPage(time) {
  return moment(time)
    .subtract(2, 'hours')
    .toDate();
}

export function getTimeZoneName(timeZone, timeZoneName = 'long') {
  let [, ...tz] = new Date().toLocaleTimeString('en', { timeZone, timeZoneName, hour12: false }).split(' ');
  return tz.join(' ').replace(/Standard Time/, '');
}

export function getCurrentTimeZone() {
  const currentTimeZone = moment.tz.guess();
  return {
    id: 'currentTimeZoneId',
    siteName: 'My local time zone',
    timeZoneId: currentTimeZone,
    timeZoneIdValid: true,
    timeZoneDifferent: 0,
    timeZoneNameToDisplay: getTimeZoneName(currentTimeZone),
    currentTime: moment().format('h:mm A')
  };
}
