import React from 'react';
import * as PropTypes from 'prop-types';

import CalendarSingleSelect from '../form-components/CalendarSingleSelect';

export default function NonPatientEventTypeSelection({
  eventTypes,
  typeCode,
  onNonPatientEventTypeCodeChange,
  validationMessage,
  required
}) {
  return (
    <CalendarSingleSelect
      controlId="meeting-type-selector"
      label={'Meeting type'}
      dataSource={eventTypes}
      initialValue={typeCode ? eventTypes.find(eventType => eventType.id === typeCode) : undefined}
      onChange={onNonPatientEventTypeCodeChange}
      validationMessage={validationMessage}
      required={required}
    />
  );
}

NonPatientEventTypeSelection.propTypes = {
  eventTypes: PropTypes.array,
  typeCode: PropTypes.string,
  onNonPatientEventTypeCodeChange: PropTypes.func,
  validationMessage: PropTypes.string,
  required: PropTypes.bool
};
