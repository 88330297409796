import axios from 'axios';

const ROOT = 'api/v1/home-page-dashboards';

const HomePageDashboardsConfigApi = {
  getDashboardWidgetConfig() {
    return axios.get(`${ROOT}/config`, { hideLoader: true });
  },
  updateDashboardState(data) {
    return axios.post(`${ROOT}/config`, data);
  },
  updateDashboardWidgetState(data) {
    return axios.post(`${ROOT}/widgets/config`, data, { hideLoader: true });
  },
  getAllStudySites() {
    return axios.get(`${ROOT}/study-sites`, { hideLoader: true });
  },
  getAwaitingSignOffWidgetData(data) {
    return axios.post(`${ROOT}/widgets/awaiting-sign-off`, data, { hideLoader: true });
  },
  getWorkProgressWidgetData(data) {
    return axios.post(`${ROOT}/widgets/work-progress`, data, { hideLoader: true });
  },
  getEnrolledPatientsJourney(data) {
    return axios.post(`${ROOT}/widgets/enrolled_patients_journey`, data, { hideLoader: true });
  },
  getEncounterSignOffDataSm(ssuIds, statuses, encounterNames) {
    return axios.post(
      `${ROOT}/widgets/encounter-sign-off-data-sm`,
      {
        ssuIds,
        statuses,
        encounterNames
      },
      { hideLoader: true }
    );
  },
  getEncounterSignOffDataPi(ssuIds, statuses, encounterNames) {
    return axios.post(
      `${ROOT}/widgets/encounter-sign-off-data-pi`,
      {
        ssuIds,
        statuses,
        encounterNames
      },
      { hideLoader: true }
    );
  },
  getEncounterSignOffEpochEncountersSm(ssuIds, statuses) {
    return axios.post(
      `${ROOT}/widgets/encounter-sign-off-epochs-encounters-sm`,
      {
        ssuIds,
        statuses
      },
      { hideLoader: true }
    );
  },
  getEncounterSignOffEpochEncountersPi(ssuIds, statuses) {
    return axios.post(
      `${ROOT}/widgets/encounter-sign-off-epochs-encounters-pi`,
      {
        ssuIds,
        statuses
      },
      { hideLoader: true }
    );
  }
};

export default HomePageDashboardsConfigApi;
