export function toBudgetListFormat(data) {
  return {
    clientName: data.finClient?.name,
    description: data.description,
    id: data.id,
    studyId: data.studyId,
    studyName: data.study?.name,
    version: data.version,
    pcn: data.study?.projectCode ?? '',
    invoiceNote: data.invoiceNote ?? '',
    recipientEmails: data.recipientEmails ?? ''
  };
}
