import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Container from '@mui/material/Container';
import { map } from 'lodash/collection';

import { generateUrlByKey } from '../../../root/router';
import { SECTIONS } from '../modeReportsServices';

import './ModeReportsList.scss';

const generateSectionDashboardMap = filteredDashboards =>
  Object.entries(filteredDashboards || {})?.reduce((acc, [key, value]) => {
    if (value.navSection in acc) {
      acc[value.navSection][[key]] = value;
    } else {
      acc[value.navSection] = { [key]: value };
    }
    return acc;
  }, {});

const getFilteredSections = sectionDashboardMap => SECTIONS?.filter(section => section in sectionDashboardMap);

function ModeReportsList({ filteredDashboards, currentDashboardName }) {
  const sectionDashboardMap = useMemo(() => {
    return generateSectionDashboardMap(filteredDashboards);
  }, [filteredDashboards]);

  const filteredSections = useMemo(() => {
    return getFilteredSections(sectionDashboardMap);
  }, [sectionDashboardMap]);

  return (
    <Container>
      {map(filteredSections, sectionName => (
        <Accordion
          slotProps={{ transition: { unmountOnExit: true } }}
          square={true}
          disableGutters={true}
          sx={{
            backgroundColor: 'transparent',
            boxShadow: 'none',
            '&::before': { backgroundColor: 'transparent' }
          }}
        >
          <AccordionSummary
            id={sectionName}
            aria-controls={`${sectionName}-panel-contents`}
            expandIcon={<ExpandMore />}
            sx={{
              width: '15.625rem',
              '&::before': { backgroundColor: 'transparent' },
              '&:hover': {
                backgroundColor: '#e7e7e7',
                cursor: 'pointer'
              }
            }}
          >
            {sectionName}
          </AccordionSummary>
          {map(
            sectionDashboardMap[sectionName],
            (item, key) =>
              item.navSection === sectionName && (
                <AccordionDetails
                  sx={{
                    width: '15.625rem',
                    transition: 'background-color 0.3s',
                    backgroundColor: currentDashboardName === item?.name ? '#cfcfcf' : 'inherit',
                    '&:hover': {
                      backgroundColor: currentDashboardName === item?.name ? '#cfcfcf' : '#e7e7e7',
                      cursor: currentDashboardName === item?.name ? 'initial' : 'pointer'
                    }
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <NavLink
                      style={{
                        width: '12rem',
                        color: 'black',
                        cursor: 'inherit'
                      }}
                      to={generateUrlByKey('Dashboards', { reportKey: key })}
                    >
                      {item.name}
                    </NavLink>
                  </div>
                </AccordionDetails>
              )
          )}
        </Accordion>
      ))}
    </Container>
  );
}
export default ModeReportsList;
