import React from 'react';
// Browser tweaks
import { isEdge, isFirefox, isIE } from 'react-device-detect';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LicenseInfo } from '@mui/x-license-pro';
import { addAccessTokenInterceptor } from 'api/interceptors';
import { withBouncer } from 'bouncer';

import Container from 'components/root/Container/Container';

// Stylesheets
import './images/icons/tick-arrow.png'; // Webpack required
import './images/icons/plus-icon.png'; // Webpack required
// TODO Workaround. Polyfill for ES6 Symbol https://www.npmjs.com/package/es6-symbol
import 'es6-symbol';

import VersionApi from './api/patient/VersionApi';
import { LOGOUT_TIME } from './components/root/Container/Container';
import { LAST_ACTION } from './constants/localStorageConstants';
import { handleLogout } from './services/auth';
import { update } from './store/appInfo/actions';
import muiTheme from './theme/mui-theme';
import store from './store';

import './index.scss';

if (isIE || isEdge || isFirefox) {
  require('./ie.css');
}

LicenseInfo.setLicenseKey(
  'bda3a72db5c663c68a6923f2759694d9Tz05NTY4OCxFPTE3NTQ1Nzc2MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI='
);

const root = createRoot(document.getElementById('root'));

begin();

async function begin() {
  checkLastActionCounter(function() {
    handleLogout();
  });
  const appInfo = await loadAppInfo();
  renderAll(appInfo);
}

async function loadAppInfo() {
  return VersionApi.getVersion().then(({ data }) => {
    console.log('Application version:');
    console.log(data);
    store.dispatch(update(data));
    return data;
  });
}

function renderAll(appInfo) {
  const EntryComponent = getEntryComponent(appInfo);
  root.render(
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={muiTheme}>
          <EntryComponent />
        </ThemeProvider>
      </LocalizationProvider>
    </Provider>
  );
}

function getEntryComponent(appInfo = { features: {} }) {
  const { auth0ClientId, auth0IssuerUri, auth0Audience, auth0Connection, auth0LogoutUrl } = appInfo;

  // We recieve auth0LogoutUrl from the appInfo api request and it looks like:
  //
  // https://intelligo.yadi.yada.amazoncognito.com/logout?redirect_uri=
  //
  // we need to assign the last part of the url query `redirect_uri` with the
  // origin of this app's url
  const fullLogoutUrl = `${auth0LogoutUrl}${window.location.origin}`;

  return withBouncer(
    Container,
    auth0ClientId,
    auth0Audience,
    auth0Connection,
    fullLogoutUrl,
    true,
    postLoginCallback,
    null,
    getAdditionalAuth0Props()
  );

  function getAdditionalAuth0Props() {
    if (auth0IssuerUri && typeof auth0IssuerUri === 'string') {
      const url = new URL(auth0IssuerUri);
      const domain = url?.hostname;
      if (domain) {
        return { domain };
      }
    }
    return null;
  }
}

function postLoginCallback(getAccessToken, bouncerLogout) {
  addAccessTokenInterceptor(getAccessToken);
  checkLastActionCounter(function() {
    handleLogout(bouncerLogout);
    throw new Error('The session expired due to inactivity.');
  });
}

function checkLastActionCounter(cb) {
  const localStorageLastAction = localStorage.getItem(LAST_ACTION);
  if (localStorageLastAction) {
    const now = Date.now();
    const lastAction = JSON.parse(localStorageLastAction);
    const timeLeft = lastAction + LOGOUT_TIME;
    const diff = timeLeft - now;
    if (diff < 0) {
      cb();
    }
  }
}
