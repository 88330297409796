import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import cx from 'classnames';

import IconButton from '../../../../../../../../../../common/buttons/IconButton';
import Input from '../../../../../../../../../../common/data-entry/Input';
import Button from '../../../../../../../../../../common/general/Button';

import './DragAndDropFinalOptionList.scss';

const DragAndDropFinalOptionList = ({
  onDragEnd,
  finalOptionList,
  deleteOptionFromList,
  toggleEditModeForLabel,
  updateItemName
}) => {
  const [newOptionName, setNewOptionName] = useState('');

  useEffect(() => {
    const editedElement = finalOptionList.filter(element => element?.edit);
    if (editedElement.length) {
      setNewOptionName(editedElement[0].name);
    } else {
      setNewOptionName('');
    }
  }, [finalOptionList]);

  return (
    <div className="final-option-list">
      <div className="new-option-label">Final Option List</div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="custom-option-list">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="option-list">
              {finalOptionList.map((item, index) => (
                <Draggable key={item.name} draggableId={item.name} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={cx('option-wrapper', { dragging: snapshot.isDragging })}
                    >
                      <div className="option-preview">
                        <div className="option-item">{item.name}</div>
                        <div className="button-wrapper">
                          {toggleEditModeForLabel && (
                            <IconButton
                              className="glyphicon glyphicon-edit edit-name"
                              suit="glyphicon"
                              color="blue"
                              title="Edit CDASH Item"
                              onClick={() => toggleEditModeForLabel(index)}
                            >
                              edit
                            </IconButton>
                          )}
                          <div
                            className="delete-option-item"
                            onClick={() => {
                              deleteOptionFromList(index);
                            }}
                          />
                        </div>
                      </div>
                      {item.edit && (
                        <div className="edit-wrapper">
                          <Input
                            className="option-edit"
                            type="text"
                            validate={false}
                            value={newOptionName}
                            onChange={({ target: { value } }) => {
                              setNewOptionName(value);
                            }}
                          />
                          <Button
                            onClick={() => {
                              updateItemName(item.name, newOptionName.trim());
                            }}
                            disabled={!newOptionName.trim()}
                          >
                            Update
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default DragAndDropFinalOptionList;
