import React, { useState } from 'react';
import { isFunction } from 'lodash/lang';

import Input from '../../../../../../../common/data-entry/Input';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';

import './NotPerformedLogCheckReasonModal.scss';

export default function NotPerformedLogCheckReasonModal({ modalBox, onSave: onSaveCallback }) {
  const [reasonComment, setReasonComment] = useState('');

  const onSave = () => {
    reasonComment?.trim() && isFunction(onSaveCallback) && onSaveCallback(reasonComment.trim());
  };

  return (
    <>
      <ModalBoxes.Body>
        <Input
          value={reasonComment}
          onChange={e => setReasonComment(e.target.value)}
          label="Comment"
          validate={false}
        />
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            CANCEL
          </Button>
          <Button disabled={!reasonComment?.trim()} onClick={onSave}>
            SAVE
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
}

NotPerformedLogCheckReasonModal.className = 'not-performed-log-check-reason-modal';
NotPerformedLogCheckReasonModal.size = 'w650';
