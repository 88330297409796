import { useEffect, useState } from 'react';
import moment from 'moment/moment';

import { TaskApi } from '../../../../../../../api';
import DatePicker from '../../../../../../../common/data-entry/DatePicker';
import Input from '../../../../../../../common/data-entry/Input';
import Select from '../../../../../../../common/data-entry/Select';
import TextArea from '../../../../../../../common/data-entry/TextArea';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import { onRequestError } from '../../../../../../../services/handlers';
import { useActiveTaskUpdate } from '../../../../../../../store/activeTask';
import { useCurrentRoute } from '../../../../../router';
import TasksSupervisor from '../../TasksSupervisor/TasksSupervisor';
import { OTHER, TaskTopics, TaskTopicsMap } from '../taskConstants';
import TaskBody from '../TaskContainer/TaskBody/TaskBody';
import TaskContainer from '../TaskContainer/TaskContainer';
import TaskFooter from '../TaskContainer/TaskFooter/TaskFooter';
import TaskHeader from '../TaskContainer/TaskHeader/TaskHeader';
import { prepareCreateRequestData } from '../taskDataProxy';
import { defaultFieldsValidation, getTopicNameValidationMessage, validateTask } from '../taskServices';
import useTaskForm from '../useTaskForm';

import TaskAssignees from './TaskAssignees/TaskAssignees';
import TaskCreatePopulatedSection from './TaskCreatePopulatedSection/TaskCreatePopulatedSection';
import TaskProtocolItemsSelect from './TaskProtocolItemsSelect/TaskProtocolItemsSelect';
import TaskStudySiteSelect from './TaskStudySiteSelect/TaskStudySiteSelect';
import { loadPopulatedData } from './taskCreateServices';

import './TaskCreate.scss';

export default function TaskCreate({ allStudySites, allAssignees }) {
  const updateActiveTask = useActiveTaskUpdate();
  const currentRoute = useCurrentRoute();
  const patientSpecificReqPayload = preparePatientSpecificReqPayload(currentRoute.params);

  const {
    taskForm,
    taskFormIsLoading,
    setTaskFormIsLoading,
    taskFormValidationMap,
    setTaskFormValidationMap,
    linkAccess,
    matchedSsuIds,
    updateTaskForm,
    setTopic,
    setTopicTitle,
    setDescription,
    setAssignees,
    setDueDate,
    setEncounter,
    setItemGroup,
    setStudySite
  } = useTaskForm(allStudySites);

  const {
    topic,
    topicTitle,
    description,
    dueDate,
    studySiteId,
    assignees,
    study,
    site,
    protocolEncounter,
    protocolItemGroup
  } = taskForm;

  const isPatientSpecific = verifyIsPatientSpecific(patientSpecificReqPayload);

  const [isPopulated, setIsPopulated] = useState({});
  const [saveAllowed, setSaveAllowed] = useState(true);

  useEffect(
    function() {
      setTaskFormIsLoading(true);
      const loadingStartTime = +new Date();
      loadPopulatedData(patientSpecificReqPayload)
        .then(function(responseData) {
          responseData.taskLinks = [
            {
              link: window.location.pathname,
              linkComment: '',
              externalLink: false
            }
          ];
          setTaskFormValidationMap({ ...defaultFieldsValidation });
          updateTaskForm(responseData);
          setIsPopulated({
            encounter: !!responseData.protocolEncounter,
            itemGroup: !!responseData.protocolItemGroup
          });
        })
        .catch(function(error) {
          onRequestError(error, { customMessage: "Can't populate data for Study site" });
          TasksSupervisor.close();
        })
        .finally(function() {
          const loadingEndTime = +new Date();
          const loadingDeltaTime = loadingEndTime - loadingStartTime;
          setTimeout(function() {
            setTaskFormIsLoading(false);
          }, Math.max(700 - loadingDeltaTime, 0));
        });
    },
    /* TODO: temporary disable to avoid clearing form on route change */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // patientEncounterId,
      // patientItemGroupId,
      // reviewPatientItemGroupId,
      // routeKeyForAutoPopulate,
      setTaskFormIsLoading,
      setTaskFormValidationMap,
      // ssuPatientId,
      updateTaskForm
    ]
  );

  return (
    <TaskContainer className="eds-task-container-create">
      <TaskHeader>Add Task</TaskHeader>
      <TaskBody isLoading={taskFormIsLoading}>
        <div className="etc-body-field-wrapper">
          <Select
            label="Topic"
            dataSource={TaskTopics}
            onChange={function(value) {
              const topic = value?.id || null;
              if (taskForm.topic === topic) return;
              return setTopic(value);
            }}
            optionValueKey="id"
            required
            value={TaskTopicsMap[topic] || null}
            searchable={false}
            clearSearchOnSelection
            validate={true}
            validationMessage={!taskFormValidationMap.topic ? 'Topic is required' : ''}
          />
        </div>
        {topic === OTHER && (
          <div className="etc-body-field-wrapper">
            <Input
              label="Topic Name"
              validationMessage={getTopicNameValidationMessage(topicTitle, taskFormValidationMap.topicTitle)}
              validate={true}
              required
              value={topicTitle}
              onChange={setTopicTitle}
            />
          </div>
        )}
        <div className="etc-body-field-wrapper">
          <TextArea
            id="description-input"
            name="description"
            label="Description"
            value={description}
            onChange={setDescription}
            maxLength="2000"
            showCount
          />
        </div>
        <div className="etc-body-field-wrapper">
          <TaskAssignees
            allAssignees={allAssignees}
            assignees={assignees}
            onChange={setAssignees}
            access={linkAccess}
            matchedSsuIds={matchedSsuIds}
            validate={true}
            validationMessage={!taskFormValidationMap.assigneeDto ? 'Assignee is required' : ''}
          />
        </div>
        <div className="etc-body-field-wrapper">
          <DatePicker.Manual
            dateOnly
            id="due-date-selector"
            label="Task Due Date"
            value={moment(dueDate)}
            onChange={setDueDate}
            required
            validate={true}
            validationMessage={!taskFormValidationMap.dueDate ? 'Due Date is required' : ''}
            isValidDate={current => current.isAfter(moment().subtract(1, 'day'))}
          />
        </div>
        {!isPatientSpecific && (
          <TaskStudySiteSelect
            allStudySites={allStudySites}
            selectedStudyId={study?.id}
            selectedSiteId={site?.id}
            selectedStudySiteId={studySiteId}
            taskFormValidationMap={taskFormValidationMap}
            onChange={setStudySite}
            validate={true}
          />
        )}
        {isPatientSpecific && (
          <TaskProtocolItemsSelect
            studySiteId={studySiteId}
            studyId={study?.id}
            onChangeEncounter={setEncounter}
            onChangeItemGroup={setItemGroup}
            initialEncounter={protocolEncounter}
            initialItemGroup={protocolItemGroup}
            isPopulated={isPopulated}
          />
        )}
        {isPatientSpecific && <TaskCreatePopulatedSection taskForm={taskForm} isPopulated={isPopulated} />}
      </TaskBody>
      <TaskFooter>
        <ButtonGroup>
          <Button priority="medium" onClick={TasksSupervisor.close}>
            Cancel
          </Button>
          <Button disabled={!saveAllowed} onClick={onSaveTask}>
            Save
          </Button>
        </ButtonGroup>
      </TaskFooter>
    </TaskContainer>
  );

  function onSaveTask() {
    const requestData = prepareCreateRequestData(taskForm);
    if (validateTask(requestData, setTaskFormValidationMap)) {
      saveTask(requestData);
    }
  }

  function saveTask(requestData) {
    if (saveAllowed) {
      setSaveAllowed(false);
      TaskApi.create(requestData)
        .then(({ data: createdTask }) => {
          updateActiveTask({ updateTimestamp: +new Date() });
          setSaveAllowed(true);
          NotificationManager.success(`Task ${createdTask?.sequenceNumber} created`);
          TasksSupervisor.open(createdTask.id, createdTask.studySiteUniqueIdentifier);
        })
        .catch(() => {
          setSaveAllowed(true);
        });
    }
  }
}

function verifyIsPatientSpecific(payload) {
  return !!payload?.sitePatientId;
}

function preparePatientSpecificReqPayload(currentRouteParams) {
  const { ssuPatientId, patientEncounterId, patientItemGroupId, reviewPatientItemGroupId } = currentRouteParams;
  if (!ssuPatientId) return null;
  return {
    sitePatientId: ssuPatientId,
    encounterId: patientEncounterId,
    itemGroupId:
      patientEncounterId && reviewPatientItemGroupId
        ? reviewPatientItemGroupId
        : patientEncounterId
        ? patientItemGroupId
        : null,
    itemGroupTemplateId: patientEncounterId ? null : patientItemGroupId
  };
}
