import { useMemo, useState } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { STTS_ESOURCE_TYPES } from '../../../../../../../../constants/study/studyTypes';
import { toFormattedBill } from '../../../../../../../../services/financial';

const encounterStatusMap = {
  OPEN: 'Open',
  SM_REVIEW: 'SM Review',
  PI_REVIEW: 'Investigator Review',
  COMPLETE: 'Complete',
  CANCELED: 'Canceled'
};

export default function useStipendGridData(studyTypes) {
  const [data, setData] = useState([]);

  const columns = useMemo(
    function() {
      const isESource = isESourceType(studyTypes);
      return [
        { field: 'encounterDate', headerName: 'Encounter Date', flex: 1 },
        { field: 'encounterName', headerName: 'Encounter', flex: 1 },
        {
          field: 'encounterStatus',
          headerName: 'Encounter Status',
          flex: 1,
          valueGetter({ value }) {
            return encounterStatusMap[value] || 'Projected' || value;
          }
        },
        {
          field: 'amount',
          headerName: isESource ? 'Paid Amount' : 'Amount',
          flex: 1,
          renderCell({ value, row }) {
            if (value === 0) {
              return null;
            }
            if (isESource) {
              return toFormattedBill(value);
            }
            return (
              <span title={row.stipendStatus === 'PROJECTED' ? 'Potential stipend amount' : 'Paid amount'}>
                {toFormattedBill(value)}
                <HelpOutlineIcon className="stipend-help-icon" fontSize="small" color="disabled" />
              </span>
            );
          }
        },
        {
          field: 'stipendStatusName',
          headerName: 'Status',
          flex: 1,
          valueGetter({ value }) {
            return value === 'Projected' ? '' : value;
          }
        }
      ];
    },
    [studyTypes]
  );

  return [columns, data, setData];
}

function isESourceType(types) {
  return types.some(function(type) {
    return STTS_ESOURCE_TYPES.includes(type);
  });
}
