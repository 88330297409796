import { any, bool, node, oneOf, string } from 'prop-types';

const selectPropTypes = {
  label: node,
  defaultValue: string,
  className: string,
  required: bool,
  multiple: bool,
  validate: bool,
  groupBy: string,
  'data-testid': string,
  validationMessage: string,
  valid: bool,
  disabled: bool,
  clearable: bool,
  dataSource: any,
  type: oneOf(['default', 'chips']),
  legacyLook: bool
};

export default selectPropTypes;
