import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { intersectionWith } from 'lodash/array';
import { isEmpty, isEqual, isUndefined } from 'lodash/lang';

import { SMWorklistApi, StudySiteApi } from '../../../../api';
import useSessionStorageWithRole from '../../../../common/hooks/useSessionStorageWithRole';
import { SM_WORKLIST_VALUES_NEW } from '../../../../constants/sessionStorageConstants';
import { CLOSED, ENROLLING, STARTUP, TREATMENT } from '../../../../constants/ssuStatuses';
import { ACCESS_TO_ADVERSE_EVENT_SM_OPERATIONS } from '../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../services/auth';
import { DATA_REQUIRED } from '../InvestigatorWorklistNew/constants';
import { getIds, getSitesFrom, getStudiesFrom } from '../ScheduleWorklist/SchedulingWorklistService';
import {
  ADVERSE_EVENTS_WIDGET,
  CANCELED,
  COMPLETE,
  ENCOUNTER_WIDGET,
  OPEN,
  PI_REVIEW,
  SM_REVIEW
} from '../shared/Worklist/constants';
import { getValidationErrors, isSelectedStudySitesChanged, usePrevious } from '../shared/Worklist/WorklistService';

import {
  defaultSmFilters,
  SM_WORKLIST_PAGE_SIZE,
  SM_WORKLIST_SORTED_BY,
  SmStatuses,
  SmStatusesProvider,
  sortingPath
} from './constants';

export const SmWorklistContext = React.createContext(null);
let timeoutInstances = [];
let request = null;
export default function SmWorkListFiltersContext(props) {
  const [sessionStorage, setSessionStorage] = useSessionStorageWithRole(SM_WORKLIST_VALUES_NEW, {});
  const [selectedStudies, setSelectedStudies] = useState(sessionStorage.selectedStudies || []);
  const [selectedSites, setSelectedSites] = useState(sessionStorage.selectedSites || []);
  const [selectedStudySites, setSelectedStudySites] = useState(sessionStorage.selectedStudySites || []);
  const [totalAdverseEvents, setTotalAdverseEvents] = useState(0);
  const [totalEncounter, setTotalEncounter] = useState(0);
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [amountPerCategory, setAmountPerCategory] = useState({
    OPEN: 0,
    SM_REVIEW: 0,
    PI_REVIEW: 0,
    COMPLETE: 0,
    CANCELED: 0
  });

  const [amountPerCategoryForAdverse, setAmountPerCategoryForAdverse] = useState({
    DATA_REQUIRED: 0,
    SM_REVIEW: 0,
    PI_REVIEW: 0,
    COMPLETE: 0,
    CANCELED: 0
  });
  const [totalEncounters, setTotalEncounters] = useState([]);
  const [showComplete, setShowComplete] = useState(sessionStorage.showComplete || false);
  const [showCompleteForAdverse, setShowCompleteForAdverse] = useState(sessionStorage.showCompleteForAdverse || false);
  const [showCanceledForAdverse, setShowCanceledForAdverse] = useState(sessionStorage.showCanceledForAdverse || false);
  const [showCanceled, setShowCanceled] = useState(sessionStorage.showCanceled || false);
  const [currentlySelected, setCurrentlySelected] = useState(sessionStorage.currentlySelected || '');
  const [currentlySelectedForAdverse, setCurrentlySelectedForAdverse] = useState(
    sessionStorage.currentlySelectedForAdverse || ''
  );
  const [tableData, setTableData] = useState([]);
  const [patientEncounterIds, setPatientEncounterIds] = useState([]);
  const [rowIds, setRowIds] = useState([]);
  const [signatures, setSignatures] = useState(() => []);
  const [groupsAndSignatures, setGroupsAndSignatures] = useState([]);
  const [encounterNames, setEncounterNames] = useState(sessionStorage.encounterNames || []);
  const [studies, setStudies] = useState([]);
  const [allStudies, setAllStudies] = useState([]);
  const [sites, setSites] = useState([]);
  const [allSites, setAllSites] = useState([]);
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);
  const [isSignedByColumnFetching, setIsSignedByColumnFetching] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(sessionStorage.page || 0);
  const [pageSize, setPageSize] = useState(sessionStorage.pageSize || SM_WORKLIST_PAGE_SIZE);
  const [sorted, setSorted] = useState(sessionStorage.sorted || [SM_WORKLIST_SORTED_BY]);
  const [ssus, setSsus] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [statusType, setStatusType] = useState(sessionStorage.statusType || ENCOUNTER_WIDGET);
  const previousPageValue = usePrevious(page);
  const previousStatus = usePrevious(currentlySelected);
  const previousStatusType = usePrevious(statusType);
  const previousStatusForAdverseEvents = usePrevious(currentlySelectedForAdverse);

  const [filter, setFilter] = useState({
    selectedStudySites,
    selectedStudies,
    selectedSites,
    studies,
    sites
  });
  const { children } = props;

  useEffect(() => {
    StudySiteApi.getAllStudySites().then(res => {
      setSsus(prepareStudySitesForFilter(res.data));
    });
    return () => {
      if (!!request) {
        request.cancel();
      }
    };
  }, []);

  function prepareStudySitesForFilter(data) {
    return data.filter(function({ siteStatus: ssuStatus }) {
      return [STARTUP, ENROLLING, TREATMENT, CLOSED].includes(ssuStatus);
    });
  }

  const selectedStudySitesIds = useMemo(
    function() {
      return selectedStudySites?.map(studySite => studySite.id);
    },
    [selectedStudySites]
  );

  useEffect(
    function() {
      if (!isEmpty(ssus)) {
        setIsLoadingFilters(true);
        const studies = getStudiesFrom(ssus);
        const sites = getSitesFrom(ssus);

        setAllStudies(studies);
        setAllSites(sites);

        const updatedSelectedStudies = !isEmpty(selectedStudies)
          ? intersectionWith(studies, selectedStudies, nameEquals)
          : studies;
        const updatedSelectedSites = !isEmpty(selectedSites)
          ? intersectionWith(sites, selectedSites, nameEquals)
          : sites;
        const updatedSelectedStudySites = !isEmpty(selectedStudySites)
          ? intersectionWith(ssus, selectedStudySites, (a, b) => a.id === b.id)
          : ssus;

        setSelectedStudies(updatedSelectedStudies);
        setSelectedSites(updatedSelectedSites);
        setSelectedStudySites(updatedSelectedStudySites);

        const updatedSites = !isEmpty(updatedSelectedStudies)
          ? getSitesFrom(ssus.filter(ssu => getIds(updatedSelectedStudies).includes(ssu.study.id)))
          : sites;

        setStudies(studies);
        setSites(updatedSites);

        setIsLoadingFilters(false);
        applyFilters(
          updatedSelectedStudySites,
          updatedSelectedStudies,
          updatedSelectedSites,
          studies,
          updatedSites,
          false
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ssus]
  );

  useEffect(() => {
    const statusesList = SmStatuses.filter(
      status =>
        (showComplete || status !== COMPLETE) && (showCanceled || status !== CANCELED) && status !== DATA_REQUIRED
    );
    setTotalEncounter(
      statusesList
        .map(status => amountPerCategory[status])
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
    );
  }, [amountPerCategory, showComplete, showCanceled]);

  useEffect(() => {
    const statusesList = SmStatuses.filter(
      status => (showCompleteForAdverse || status !== COMPLETE) && status !== OPEN
    ).filter(status => showCanceledForAdverse || status !== CANCELED);
    setTotalAdverseEvents(
      statusesList
        .map(status => amountPerCategoryForAdverse[status])
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
    );
  }, [amountPerCategoryForAdverse, showCanceledForAdverse, showCompleteForAdverse]);

  useEffect(() => {
    if (timeoutInstances.length > 0) {
      timeoutInstances.forEach(timeoutInstance => clearTimeout(timeoutInstance));
    }

    const timeoutInstance = setTimeout(() => {
      setPage(defaultSmFilters.page);
      updateData({ withoutLoaded: true, onSearchFieldPage: !isEmpty(searchFieldValue) });
    }, 500);
    timeoutInstances.push(timeoutInstance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFieldValue]);

  useEffect(() => {
    updateData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, sorted, encounterNames]);

  useEffect(() => {
    if (currentlySelected !== '') {
      setSessionStorage({ ...sessionStorage, currentlySelected: currentlySelected });
      if (previousStatus && previousStatus !== currentlySelected && previousPageValue !== 0) {
        setPage(0);
      }
    } else {
      setEncounterNames([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlySelected]);

  useEffect(() => {
    if (currentlySelectedForAdverse !== '') {
      if (!!request) {
        request.cancel();
      }
      setSessionStorage({ ...sessionStorage, currentlySelectedForAdverse: currentlySelectedForAdverse });
      if (
        previousStatusForAdverseEvents &&
        previousStatusForAdverseEvents !== currentlySelectedForAdverse &&
        previousPageValue !== 0
      ) {
        setPage(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlySelectedForAdverse]);

  useEffect(() => {
    if (statusType === ENCOUNTER_WIDGET) {
      setSessionStorage({
        ...sessionStorage,
        statusType: statusType,
        currentlySelectedForAdverse: '',
        currentlySelected: currentlySelected,
        sorted: [SM_WORKLIST_SORTED_BY]
      });
    } else {
      setSessionStorage({
        ...sessionStorage,
        statusType: statusType,
        currentlySelected: '',
        currentlySelectedForAdverse: currentlySelectedForAdverse,
        sorted: [SM_WORKLIST_SORTED_BY]
      });
    }

    if (previousStatusType && previousStatusType !== statusType && previousPageValue !== 0) {
      setPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusType]);

  useEffect(() => {
    setSessionStorage({ ...sessionStorage, encounterNames: encounterNames });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encounterNames]);

  useEffect(() => {
    setSessionStorage({ ...sessionStorage, sorted: sorted });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorted]);

  useEffect(() => {
    setSessionStorage({ ...sessionStorage, pageSize: pageSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize]);

  useEffect(() => {
    setSessionStorage({ ...sessionStorage, page: page });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    setSessionStorage({ ...sessionStorage, showComplete: showComplete });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showComplete]);

  useEffect(() => {
    setSessionStorage({ ...sessionStorage, showCanceled: showCanceled });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCanceled]);

  useEffect(() => {
    setSessionStorage({ ...sessionStorage, showCanceledForAdverse: showCanceledForAdverse });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCanceledForAdverse]);

  useEffect(() => {
    setSessionStorage({ ...sessionStorage, showCompleteForAdverse: showCompleteForAdverse });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCompleteForAdverse]);

  useEffect(() => {
    if (!!request) {
      request.cancel();
    }
    request = axios.CancelToken.source();
    !isEmpty(patientEncounterIds) && setIsSignedByColumnFetching(true);
    !isEmpty(patientEncounterIds) &&
      SMWorklistApi.getWorkListSignedByPerGroupValuesForTable(patientEncounterIds, request)
        .then(({ data }) => {
          setTableData(prevState => {
            return prevState.map(el => {
              if (data[el.patientEncounterId]) {
                const enrichWithSignatures = Object.values(data[el.patientEncounterId]);
                return {
                  ...el,
                  requiredReviews:
                    enrichWithSignatures.map(e => ({ type: e.type, groupName: e.groupName, groupId: e.groupId })) || [],
                  signedUsers: enrichWithSignatures.filter(e => e.signatureDateTime && e.unsignedCount === 0) || []
                };
              } else {
                return {
                  ...el,
                  requiredReviews: [],
                  signedUsers: []
                };
              }
            });
          });
          setGroupsAndSignatures(data);
          setIsSignedByColumnFetching(false);
        })
        .catch(console.log);
  }, [patientEncounterIds]);

  useEffect(() => {
    if (statusType === ADVERSE_EVENTS_WIDGET) {
      if (!!request) {
        request.cancel();
      }
      request = axios.CancelToken.source();
      !isEmpty(tableData) && setIsSignedByColumnFetching(true);
      if (!isEmpty(tableData)) {
        const rowIdToTemplateId = tableData.map(el => {
          return { rowId: el.rowId, itemGroupTemplateId: el.itemGroupTemplateId };
        });
        SMWorklistApi.getAdverseEventsSignedByPerGroupValuesForTable(rowIdToTemplateId, request)
          .then(({ data }) => {
            setTableData(prevState => {
              return prevState.map(el => {
                if (data[el.rowId]) {
                  return {
                    ...el,
                    requiredReviews: data[el.rowId].requiredReviews,
                    signedUsers: data[el.rowId].signedUsers
                  };
                } else {
                  return {
                    ...el,
                    requiredReviews: [],
                    signedUsers: []
                  };
                }
              });
            });
            setGroupsAndSignatures(data);
            setIsSignedByColumnFetching(false);
          })
          .catch(console.log);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowIds]);

  const updateData = ({ withoutLoaded = false, withSignatures = null, onSearchFieldPage = false }) => {
    if (selectedStudySitesIds?.length && statusType) {
      const sortProperties = sorted?.flatMap(({ id, desc }) => {
        if (id === 'date' && statusType === ADVERSE_EVENTS_WIDGET) {
          return [{ property: 'date', direction: desc ? 'DESC' : 'ASC' }];
        } else if (
          (id === 'encounterName' && statusType === ADVERSE_EVENTS_WIDGET) ||
          (id === 'adverseEventName' && statusType === ENCOUNTER_WIDGET)
        ) {
          return sortingPath['patientName'].map(attr => ({ property: attr, direction: desc ? 'DESC' : 'ASC' }));
        } else {
          return sortingPath[id].map(attr => ({ property: attr, direction: desc ? 'DESC' : 'ASC' }));
        }
      });
      ((statusType === ENCOUNTER_WIDGET && !isEmpty(encounterNames)) || statusType === ADVERSE_EVENTS_WIDGET) &&
        setIsFetching(true);
      statusType === ENCOUNTER_WIDGET &&
        !isEmpty(encounterNames) &&
        SMWorklistApi.getDataForSMWorklist(
          {
            ssuIds: getSsuIdsForRequest(),
            searchString: searchFieldValue,
            encounterNames:
              encounterNames.length > 0 ? encounterNames.map(enc => enc.encounterName + '_' + enc.studyName) : [],
            patientEncounterIds: withSignatures
              ? [...new Set(withSignatures.flatMap(signature => signature.patientEncounterIds))]
              : [...new Set(signatures.flatMap(signature => signature.patientEncounterIds))],
            statuses: currentlySelected
              ? SmStatusesProvider.filter(invStatus => invStatus.id === currentlySelected).flatMap(({ value }) => value)
              : defaultSmFilters.selectedStatuses.flatMap(({ value }) => value),
            page: onSearchFieldPage ? defaultSmFilters.page : page ? page : defaultSmFilters.page,
            pageSize: pageSize ? pageSize : defaultSmFilters.pageSize,
            sortProperties
          },
          withoutLoaded
        ).then(({ data }) => {
          setTableData(data.elements);
          setTotalPages(data.totalPages);
          setIsFetching(false);
          setPatientEncounterIds(data.elements.map(el => el.patientEncounterId));
        });
      statusType === ADVERSE_EVENTS_WIDGET &&
        SMWorklistApi.getAdverseEventsForSmWorkListByRequest(
          {
            ssuIds: getSsuIdsForRequest(),
            searchString: searchFieldValue,
            rowIds: withSignatures
              ? [...new Set(withSignatures.flatMap(signature => signature.rowIds))]
              : [...new Set(signatures.flatMap(signature => signature.rowIds))],
            smStatuses: currentlySelectedForAdverse
              ? SmStatusesProvider.filter(invStatus => invStatus.id === currentlySelectedForAdverse).flatMap(
                  ({ value }) => value
                )
              : [],
            piStatuses: [],
            page: onSearchFieldPage ? defaultSmFilters.page : page ? page : defaultSmFilters.page,
            pageSize: pageSize ? pageSize : defaultSmFilters.pageSize,
            sortProperties
          },
          withoutLoaded
        ).then(({ data }) => {
          setTableData(data.elements);
          setTotalPages(data.totalPages);
          setIsFetching(false);
          setRowIds(data.elements.map(el => el.rowId));
        });
    }
  };

  const getSsuIdsForRequest = () => {
    return sessionStorage.selectedStudySites
      ?.filter(ssu =>
        !isEmpty(encounterNames) && statusType === ENCOUNTER_WIDGET
          ? encounterNames.map(enc => enc.studyName).includes(`${ssu.study.name} - ${ssu.study.projectCode}`)
          : true
      )
      .map(studySite => studySite.id);
  };

  const getAmountByStatus = (data, status) => {
    const values = SmStatusesProvider.filter(invStatus => invStatus.id === status);
    return data
      .filter(encStatus => values[0].value.includes(encStatus.status))
      .reduce((partSum, a) => partSum + a.count, 0);
  };
  const nameEquals = (a, b) => a.name === b.name;

  const onStudiesChange = newStudies => {
    if (!isEqual(selectedStudies, newStudies)) {
      setSelectedStudies(newStudies);
      const newStudySites = ssus.filter(ssu => getIds(newStudies).includes(ssu.study.id));
      !isEqual(newStudySites, selectedStudySites) && setSelectedStudySites(newStudySites);
      const newSites = getSitesFrom(newStudySites);
      !isEqual(sites, newSites) && setSites(newSites);
      onSitesChange(newSites);
    }
  };

  const onSitesChange = newSites => {
    if (!isEqual(selectedSites, newSites)) {
      setSelectedSites(newSites);
      const newStudySites = ssus
        .filter(ssu => getIds(newSites).includes(ssu.site.id))
        .filter(ssu => getIds(selectedStudies).includes(ssu.study.id));
      !isEqual(newStudySites, selectedStudySites) && setSelectedStudySites(newStudySites);
    }
  };

  const applyFilters = (selectedStudySites, selectedStudies, selectedSites, studies, sites, resetPage = true) => {
    const newFilter = {
      selectedStudySites: selectedStudySites,
      selectedStudies: selectedStudies,
      selectedSites: selectedSites,
      page: resetPage ? 0 : sessionStorage.page || 0,
      pageSize: sessionStorage.pageSize || SM_WORKLIST_PAGE_SIZE,
      sorted: sessionStorage.sorted || [SM_WORKLIST_SORTED_BY],
      currentlySelected: statusType === ENCOUNTER_WIDGET ? currentlySelected : '',
      showComplete: showComplete,
      showCanceled: showCanceled,
      encounterNames: encounterNames,
      showCompleteForAdverse: showCompleteForAdverse,
      showCanceledForAdverse: showCanceledForAdverse,
      currentlySelectedForAdverse: statusType === ADVERSE_EVENTS_WIDGET ? currentlySelectedForAdverse : '',
      statusType: statusType
    };
    const isStatusClearRequired = isSelectedStudySitesChanged(filter, newFilter);
    const errors = getValidationErrors(newFilter);
    setValidationErrors(errors);
    if (isEmpty(errors)) {
      resetPage && setPage(0);
      setFilter({ ...newFilter, studies: studies, sites: sites });
      setSessionStorage(newFilter);
      const requests = [SMWorklistApi.getCountOfReviewsBySsuIds(selectedStudySites?.map(studySite => studySite.id))];
      userHasAccessTo(ACCESS_TO_ADVERSE_EVENT_SM_OPERATIONS) &&
        requests.push(SMWorklistApi.countAdverseEventsBySMStatus(selectedStudySites?.map(studySite => studySite.id)));
      Promise.all(requests).then(([dataForEncounters, dataForAdverseEvents]) => {
        setTotalEncounter(dataForEncounters.data.map(entry => entry.count).reduce((partSum, a) => partSum + a, 0));
        if (!isUndefined(dataForAdverseEvents)) {
          setTotalAdverseEvents(
            dataForAdverseEvents.data.map(entry => entry.count).reduce((partSum, a) => partSum + a, 0)
          );
          setAmountPerCategoryForAdverse({
            DATA_REQUIRED: getAmountByStatus(dataForAdverseEvents.data, DATA_REQUIRED),
            SM_REVIEW: getAmountByStatus(dataForAdverseEvents.data, SM_REVIEW),
            PI_REVIEW: getAmountByStatus(dataForAdverseEvents.data, PI_REVIEW),
            COMPLETE: getAmountByStatus(dataForAdverseEvents.data, COMPLETE),
            CANCELED: getAmountByStatus(dataForAdverseEvents.data, CANCELED)
          });
        }
        setAmountPerCategory({
          OPEN: getAmountByStatus(dataForEncounters.data, OPEN),
          SM_REVIEW: getAmountByStatus(dataForEncounters.data, SM_REVIEW),
          PI_REVIEW: getAmountByStatus(dataForEncounters.data, PI_REVIEW),
          COMPLETE: getAmountByStatus(dataForEncounters.data, COMPLETE),
          CANCELED: getAmountByStatus(dataForEncounters.data, CANCELED)
        });
        isStatusClearRequired && setCurrentlySelected('');
        isStatusClearRequired && setCurrentlySelectedForAdverse('');
      });
    }
  };

  const resetStudies = () => {
    onStudiesChange(studies);
    applyFilters(selectedStudySites, studies, selectedSites, studies, sites);
  };

  const resetSites = () => {
    onSitesChange(sites);
    setTimeout(applyFilters(selectedStudySites, selectedStudies, sites, studies, sites));
  };

  return (
    <SmWorklistContext.Provider
      value={{
        allStudies,
        allSites,
        studies,
        sites,
        selectedStudies,
        selectedSites,
        selectedStudySites,
        setSelectedStudySites,
        setSelectedStudies,
        totalPages,
        page,
        setSearchFieldValue,
        searchFieldValue,
        pageSize,
        sorted,
        setTotalPages,
        setPage,
        setPageSize,
        statusType,
        patientEncounterIds,
        setStatusType,
        setIsSignedByColumnFetching,
        isSignedByColumnFetching,
        setTableData,
        setSorted,
        EncounterWidgetContext: {
          totalEncounter,
          amountPerCategory,
          currentlySelected,
          setCurrentlySelected: status => {
            setCurrentlySelectedForAdverse('');
            setCurrentlySelected(status);
            setStatusType(ENCOUNTER_WIDGET);
          },
          showComplete,
          setShowComplete,
          showCanceled,
          setShowCanceled,
          setSearchFieldValue
        },
        AdverseEventsWidgetContext: {
          totalEncounter: totalAdverseEvents,
          amountPerCategory: amountPerCategoryForAdverse,
          currentlySelected: currentlySelectedForAdverse,
          setCurrentlySelected: status => {
            setCurrentlySelected('');
            setCurrentlySelectedForAdverse(status);
            setStatusType(ADVERSE_EVENTS_WIDGET);
          },
          showComplete: showCompleteForAdverse,
          setShowComplete: setShowCompleteForAdverse,
          showCanceled: showCanceledForAdverse,
          setShowCanceled: setShowCanceledForAdverse
        },
        totalEncounters,
        setTotalEncounters,
        tableData,
        isFetching,
        setSelectedSites,
        encounterNames,
        signatures,
        setSignatures,
        updateWithSignature: newSignatures => {
          updateData({ withSignatures: newSignatures });
        },
        setEncounterNames,
        sessionStorage,
        filter,
        applyFilters: () => applyFilters(selectedStudySites, selectedStudies, selectedSites, studies, sites),
        resetStudies,
        resetSites,
        onStudiesChange,
        onSitesChange,
        validationErrors,
        ssus,
        isLoadingFilters,
        groupsAndSignatures
      }}
    >
      {children}
    </SmWorklistContext.Provider>
  );
}
