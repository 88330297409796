import React, { useCallback, useMemo, useState } from 'react';
import { isUndefined } from 'lodash/lang';

import Section from '../../../../../../common/data-display/Section/Section';
import { MANAGE_BUDGETS } from '../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../services/auth';
import { eventTypeLabel } from '../budgetConstant';

import { AddBudgetEvent } from './AddBudgetEvent/AddBudgetEvent';
import { BudgetEventsTable } from './BudgetEventsTable/BudgetEventsTable';
import { BudgetEventsTableFilter } from './BudgetEventsTableFilter/BudgetEventsTableFilter';

export const BudgetDetailsTableSection = ({
  studyId,
  managementFeePercent,
  currentStudyBudget,
  encounterTriggers,
  itemGroupTriggers,
  vendors,
  budgetId,
  loadBudgetEvents,
  protocolIncomplete,
  isEditingForbidden,
  events
}) => {
  const [filterState, setFilterState] = useState({
    selectedType: 'all',
    selectedEntity: 'all'
  });

  const changeFilterValue = useCallback(e => {
    if (!isUndefined(e)) {
      setFilterState(prevState => ({ ...prevState, [e.targetName]: e.accessor }));
    }
  }, []);

  const filteredEvents = useMemo(() => {
    const byType = type => it => type === 'all' || eventTypeLabel[it.type] === eventTypeLabel[type];
    const byEntity = entity => it => entity === 'all' || it[entity] !== 0;
    return events.filter(byType(filterState.selectedType)).filter(byEntity(filterState.selectedEntity));
  }, [events, filterState]);

  return (
    <Section className="budget-event-section">
      <div className="budget-event-section-header">
        <h5 className="c-p">Budget Events</h5>
        {!isEditingForbidden && userHasAccessTo(MANAGE_BUDGETS) && (
          <AddBudgetEvent
            studyId={studyId}
            managementFeePercent={managementFeePercent}
            currentStudyBudget={currentStudyBudget}
            encounterTriggers={encounterTriggers}
            itemGroupTriggers={itemGroupTriggers}
            vendors={vendors}
            budgetId={budgetId}
            loadBudgetEvents={loadBudgetEvents}
            protocolIncomplete={protocolIncomplete}
            events={events}
          />
        )}
      </div>
      <BudgetEventsTableFilter changeFilterValue={changeFilterValue} />
      <BudgetEventsTable
        filteredEvents={filteredEvents}
        studyId={studyId}
        budgetId={budgetId}
        isEditingForbidden={isEditingForbidden}
        currentStudyBudget={currentStudyBudget}
        loadBudgetEvents={loadBudgetEvents}
        managementFeePercent={managementFeePercent}
        vendors={vendors}
        events={events}
      />
    </Section>
  );
};
