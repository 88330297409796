import { Box, Tab, Tabs } from '@mui/material';

import { PET_NF, PET_PP } from '../constants';

import NotificationsTab from './NotificationsTab/NotificationsTab';
import PatientPortalTab from './PatientPortalTab/PatientPortalTab';

export default function PatientEnablementTabs({ tabs, activeTab, setActiveTab }) {
  return (
    <Box sx={{ flexGrow: 1, bgcolor: 'background.paper' }}>
      <Tabs
        value={activeTab}
        onChange={function(e, value) {
          setActiveTab(value);
        }}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        {tabs.map(function(tab) {
          return <Tab key={tab} label={tab} value={tab} />;
        })}
      </Tabs>
      <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', flex: 1, padding: '15px 20px' }}>
        {activeTab === PET_PP && <PatientPortalTab />}
        {activeTab === PET_NF && <NotificationsTab />}
      </Box>
    </Box>
  );
}
