import PropTypes from 'prop-types';

// TODO: nonProtocol to isNonProtocol & the same noAppointments
// TODO: Can previewGroupsConfigurationMap been an object?

export const IEncounters = PropTypes.arrayOf(
  PropTypes.shape({
    encounterKey: PropTypes.string.isRequired,
    encounterName: PropTypes.string,
    encounterDisplayName: PropTypes.string,
    noAppointments: PropTypes.bool.isRequired,
    nonProtocol: PropTypes.bool.isRequired
  })
);

export const IEpochs = PropTypes.arrayOf(
  PropTypes.shape({
    protocolEpochKey: PropTypes.string,
    epochName: PropTypes.string,
    encounters: IEncounters.isRequired
  })
);

export const IItemGroups = PropTypes.arrayOf(
  PropTypes.shape({
    domainName: PropTypes.string,
    elementName: PropTypes.string,
    itemGroupKey: PropTypes.string,
    itemGroupType: PropTypes.string
  })
);

const propTypes = {
  epochs: IEpochs.isRequired,
  itemGroups: IItemGroups.isRequired,
  Cell: PropTypes.any.isRequired,
  RowTitleCell: PropTypes.func,
  HeaderCell: PropTypes.func,
  EncounterCell: PropTypes.func,
  dataMap: PropTypes.objectOf(PropTypes.any).isRequired
};

export default propTypes;
