import React, { useEffect, useState } from 'react';
import { isEmpty, isEqual } from 'lodash/lang';

import { StudyStatusSetupApi } from '../../../../api';
import Checkbox from '../../../../common/data-entry/InputSelectors/Checkbox';
import Toggle from '../../../../common/data-entry/Toggle';
import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../common/general/Button';
import ButtonGroup from '../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import { STATUS_CONFIGURATION_SAVED } from '../../../../constants/notificationMessages';
import { onRequestError } from '../../../../services/handlers';

import './StatusesSetupModal.scss';

const StatusesSetupModal = ({ initialStatuses, studyId, updateStatuses, modalBox }) => {
  const [statusesSetup, setStatusesSetup] = useState([]);

  useEffect(() => {
    if (!isEmpty(initialStatuses)) {
      setStatusesSetup(initialStatuses);
    }
  }, [initialStatuses]);

  const saveStatusesSetup = () => {
    StudyStatusSetupApi.saveStudyStatusSetup(studyId, statusesSetup)
      .then(({ data }) => {
        updateStatuses(data);
        NotificationManager.success(STATUS_CONFIGURATION_SAVED);
        modalBox.close();
      })
      .catch(onRequestError);
  };

  return (
    <>
      <ModalBoxes.Header onClose={onClose}>Edit Patient Status Setup</ModalBoxes.Header>
      <ModalBoxes.Body>
        <div className="edit-statuses-category">
          <p>Expected Progression</p>
          {statusesSetup
            .filter(status => !status.dropOffStatus)
            .map((status, index) => (
              <div className="status-item" key={status.statusId}>
                <span>{index + 1}.</span>
                <Toggle checked={status.active} onChange={() => toggleStatus(status)}>
                  {status.statusName}
                </Toggle>
                <Checkbox
                  checked={status.optional}
                  onChange={() => toggleOptional(status)}
                  disabled={!status.active}
                  label="Optional"
                />
              </div>
            ))}
        </div>
        <div className="edit-statuses-category">
          <p>Final Disposition</p>
          {statusesSetup
            .filter(status => status.dropOffStatus)
            .map((status, index) => (
              <div className="status-item" key={status.statusId}>
                <span>{index + 1}.</span>
                <Toggle checked={status.active} onChange={() => toggleStatus(status)}>
                  {status.statusName}
                </Toggle>
              </div>
            ))}
        </div>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority={'medium'} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={saveStatusesSetup} disabled={isEqual(initialStatuses, statusesSetup)}>
            Save
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );

  function onClose() {
    modalBox.close(!isEqual(initialStatuses, statusesSetup));
  }

  function toggleOptional(statusItem) {
    const updatedSetup = statusesSetup.map(item => {
      if (item.statusId === statusItem.statusId) {
        return { ...item, optional: !item.optional };
      }
      return item;
    });
    setStatusesSetup(updatedSetup);
  }

  function toggleStatus(statusItem) {
    const updatedSetup = statusesSetup.map(item => {
      if (item.statusId === statusItem.statusId) {
        if (item.active) {
          return { ...item, active: !item.active, optional: false };
        } else {
          return { ...item, active: !item.active };
        }
      }
      return item;
    });
    setStatusesSetup(updatedSetup);
  }
};

StatusesSetupModal.className = 'edit-statuses-popup';
StatusesSetupModal.size = 'w800';

export default StatusesSetupModal;
