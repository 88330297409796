import React from 'react';

import TableSelect from '../shared/TableSelect/TableSelect';

export default function CellDropdown({ value, isDisabled, options, onChange }) {
  return (
    <TableSelect
      onChange={onChange}
      getOptionValue={({ id }) => id}
      getOptionLabel={({ name }) => name}
      isDisabled={isDisabled}
      cacheOptions
      value={value}
      options={options}
    />
  );
}
