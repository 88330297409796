import NotificationManager from 'common/notifications/NotificationManager';
import { cloneDeep, isEmpty } from 'lodash/lang';
import { onRequestError } from 'services/handlers';

import { PAYMENT_TYPE_PHYSICAL } from 'components/pages/patient-source/Patients/constants';
import handleExceptions from 'components/pages/patient-source/Patients/PatientInfo/PatientInfoMainSection/AddOrEditPatientInfoModal/services/handleExceptions';

import { PatientInfoApi } from '../../../../../../../../api';
import { convertDobToDate } from '../AddOrEditPatientInfoModalService';

import { validateEditPatientInfo } from './validationService/validationService';
import showDuplicateEmailModal from './showDuplicateEmailModal';

export default async function initiateEditPatient({
  info,
  currentStudy,
  patientInfo,
  setValidationMessages,
  setInfo,
  setLoading,
  postSave,
  onDuplicate,
  modalBox,
  originalPID,
  originalPaymentType
}) {
  try {
    await validateEditPatientInfo(info, originalPID);

    const { cardId, paymentType } = info;

    if (cardId && !paymentType) {
      info.paymentType = PAYMENT_TYPE_PHYSICAL;
    }

    convertDobToDate(info);
    setLoading(true);

    info.changedCardId =
      Boolean(!originalPID && info.cardId) || (originalPID && info.cardId && originalPID !== info.cardId);
    if (currentStudy) {
      // if on patient studies page, use new edit check
      PatientInfoApi.updatePatientInfoWithSsu(patientInfo.id, cloneDeep(info), currentStudy.studyId)
        .then(function({
          data: { pidMessage, warningMessage, emailDuplicationMessage, duplicateInfo, existsInStudy, patientInfo }
        }) {
          if (emailDuplicationMessage) {
            showDuplicateEmailModal(emailDuplicationMessage);
            setLoading(false);
          } else if (!isEmpty(duplicateInfo)) {
            modalBox.close();
            onDuplicate(currentStudy, duplicateInfo, existsInStudy, {
              ...cloneDeep(info),
              id: patientInfo.id
            });
          } else {
            postSave(info);
            if (pidMessage !== '') {
              warningMessage ? NotificationManager.error(pidMessage) : NotificationManager.success(pidMessage);
            } else if (warningMessage) {
              NotificationManager.warning(warningMessage);
            }
          }
        })
        .catch(function(err, params) {
          onRequestError(err, params);
          setLoading(false);
        });
    } else {
      // if not, then we have no source, so cannot do an edit check the same way
      PatientInfoApi.updatePatientInfo(patientInfo.id, cloneDeep(info))
        .then(function({ data: { pidMessage, warningMessage, emailDuplicationMessage } }) {
          if (emailDuplicationMessage) {
            showDuplicateEmailModal(emailDuplicationMessage);
            setLoading(false);
          } else {
            postSave(info);
          }
          if (pidMessage !== '') {
            warningMessage ? NotificationManager.error(pidMessage) : NotificationManager.success(pidMessage);
          } else if (warningMessage) {
            NotificationManager.warning(warningMessage);
          }
        })
        .catch(function(err, params) {
          onRequestError(err, params);
          setLoading(false);
        });
    }
  } catch (e) {
    handleExceptions(e, info, setValidationMessages, setInfo);
  }
}
