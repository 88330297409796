import React, { useEffect, useState } from 'react';

import { ParticipantApi } from '../../../../api';

export function ParticipantsProviderNonPatient({ initialStudy, initialSite, children }) {
  const [participants, setParticipants] = useState();

  useEffect(() => {
    if ((initialStudy && initialSite) || initialSite) {
      ParticipantApi.getAllParticipantsPerStudyAndSite(initialStudy, initialSite).then(({ data: participants }) => {
        setParticipants(participants);
      });
    } else {
      setParticipants([]);
    }
  }, [initialStudy, initialSite]);

  return (
    children &&
    React.Children.map(children, child => {
      return React.cloneElement(child, {
        ...child.props,
        datasource: participants
      });
    })
  );
}
