import React, { useContext, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import Icon from '../../../../../../../common/general/Icon';
import { generateUrlByKey, useCurrentRoute } from '../../../../../../root/router';
import { ReviewContext } from '../../ReviewContext';

import './ReviewSidebarHeader.scss';

export function ReviewSidebarHeader({ searchText, setSearchText }) {
  const { reviewType, reviewMetaData, ssuPatientId, patientEncounterId } = useContext(ReviewContext);

  const { params, parent } = useCurrentRoute();
  const { patientId } = params;
  const encounterDetailsKey = parent?.key;
  const encounterName = reviewMetaData?.encounterName || '---';

  const urlForEncounterDetailsPage = useMemo(
    function() {
      if (reviewType === 'CRA' || !encounterDetailsKey) {
        return null;
      }
      return generateUrlByKey(encounterDetailsKey, {
        ssuPatientId,
        patientId,
        patientEncounterId
      });
    },
    [reviewType, encounterDetailsKey, ssuPatientId, patientId, patientEncounterId]
  );

  return (
    <div className="erv-review-sidebar-header">
      <div className="erv-review-sidebar-header-headline">
        <h1>
          {!!urlForEncounterDetailsPage ? (
            <NavLink to={urlForEncounterDetailsPage}>{encounterName}</NavLink>
          ) : (
            encounterName
          )}
        </h1>
      </div>
      <div className="erv-review-sidebar-header-search">
        <input
          type="text"
          value={searchText}
          onChange={function({ target }) {
            setSearchText(target.value);
          }}
        />
        <Icon>search</Icon>
      </div>
    </div>
  );
}
