export function toBudgetSiteFormat(data) {
  return {
    description: data.finBudget?.description,
    effectiveDate: data.effectiveDate,
    endDate: data.endDate,
    siteName: data.finSite?.site?.name,
    status: data.status,
    studyName: data.finBudget?.study?.name,
    version: data.finBudget?.version
  };
}
