import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { afterburnerApi } from '../../../../../../../../api/patient/AftrburnerApi';
import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../../../../common/notifications/NotificationManager';
import { SOMETHING_WENT_WRONG } from '../../../../../../../../constants/notificationMessages';

import './PreviewModal.scss';

const PreviewModal = ({ modalBox, dataForReview, encounterName }) => {
  const [jsonPreview, setJsonPreview] = useState({});

  useEffect(() => {
    afterburnerApi
      .preview(dataForReview)
      .then(({ data }) => {
        setJsonPreview(data);
      })
      .catch(() => {
        NotificationManager.error(SOMETHING_WENT_WRONG);
      });
  }, [dataForReview]);

  const exportPreview = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(jsonPreview))}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = encounterName + '.json';
    link.click();

    NotificationManager.success('Preview exported successfully');
  };

  return (
    <div className="PreviewModal">
      <ModalBoxes.Header>JSON preview</ModalBoxes.Header>
      <ModalBoxes.Body>
        <pre className={'json-preview'}>{JSON.stringify(jsonPreview, null, 2)}</pre>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority={'medium'} onClick={() => modalBox.close()}>
            Close
          </Button>
          <Button disabled={isEmpty(jsonPreview)} onClick={exportPreview}>
            Export
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </div>
  );
};

export default PreviewModal;
