import { TRANSACTION_LEDGER } from '../../../../constants/accessToPages';

import AddLedger from './AddLedger/AddLedger';
import Ledger from './Ledger';

export const ledgerRouterConfig = {
  name: 'Ledger',
  path: '/transaction-ledger',
  rootBreadcrumbName: 'Finance',
  component: Ledger,
  access: [TRANSACTION_LEDGER],
  inherited: [
    {
      name: 'Add Ledger',
      path: '/add-ledger',
      component: AddLedger,
      access: [TRANSACTION_LEDGER]
    }
  ]
};
