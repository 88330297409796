export const ALL = 'All';
export const ENCOUNTERS = 'Encounters';
export const PROCEDURES = 'Procedures';
export const LOGS = 'Logs';

export const ALL_TABS = [ENCOUNTERS, PROCEDURES, LOGS];

export const encounterStatusesMap = {
  COMPLETE: 'Complete',
  OPEN: 'Open',
  PI_REVIEW: 'Investigator Review',
  SM_REVIEW: 'SM Review',
  CANCELED: 'Canceled'
};
