import React, { useContext } from 'react';
import ReactSuperSelect from 'react-super-select';
import cx from 'classnames';
import { maxBy } from 'lodash';
import { map, orderBy } from 'lodash/collection';
import { isEmpty, isEqual } from 'lodash/lang';
import moment from 'moment';

import { getShortDescriptionByRole, getTittleByRole } from '../../../../../../../services/personnelService';
import { useCurrentRoute } from '../../../../../../root/router';
import { ReviewContext } from '../../ReviewContext';

import './ReviewLogHistory.scss';

export default function ReviewLogHistory({ reviewHistory }) {
  const currentRoute = useCurrentRoute();
  const { changePageState, initialGroupAssignId } = useContext(ReviewContext);

  let signatureId = currentRoute?.params?.signatureId;

  signatureId = signatureId !== 'current' ? signatureId : null;

  function onChangeSelectedValue(signature) {
    changePageState({
      signatureId: signature.logId || 'current',
      groupAssignId: signature.groupAssign?.id || initialGroupAssignId
    });
  }

  const prepareRenderData = () => {
    const preparedData = map(reviewHistory, ({ reviewDate, signatureId, role, ...rest }) => {
      const reviewDateMoment = moment(reviewDate);
      return {
        ...rest,
        logId: signatureId,
        role: getTittleByRole(role),
        reviewDateForDisplay: reviewDateMoment.format('DD/MMM/YYYY h:mm A'),
        isInitialState: false,
        timestamp: reviewDateMoment,
        roleShortName: getShortDescriptionByRole(role)
      };
    });
    const currentStateMoment = !isEmpty(preparedData)
      ? moment(maxBy(preparedData, 'timestamp').timestamp).add(1, 'seconds')
      : moment();
    return orderBy([getCurrentState(currentStateMoment), ...preparedData], ['timestamp'], ['desc']);
  };

  const getCurrentState = currentStateMoment => {
    return {
      fullName: 'Current state',
      role: '',
      reviewDateForDisplay: null,
      reviewDate: null,
      roleShortName: '',
      timestamp: currentStateMoment,
      logId: null
    };
  };

  const dataForView = prepareRenderData();
  const value = dataForView.find(signature => isEqual(signature.logId, signatureId));

  return (
    <ReactSuperSelect
      customClass={cx(`ern-review-log-history`)}
      dataSource={dataForView}
      onChange={onChangeSelectedValue}
      clearable={false}
      customSelectedValueTemplateFunction={r => (
        <div>
          {r[0].reviewDateForDisplay} {r[0].fullName}
        </div>
      )}
      customOptionTemplateFunction={renderOptionTemplate}
      initialValue={value}
      deselectOnSelectedOptionClick={false}
      customGroupHeadingTemplateFunction={() => `Selecting an item below will update the state of the review displayed`}
      groupBy="groupKey"
      optionValueKey="timestamp"
    />
  );
}

function renderOptionTemplate(option) {
  return (
    <React.Fragment>
      <div className="review-log-data">
        <div title={option.fullName}>{option.fullName}</div>
        <div title={option.role}>{option.role}</div>
        <div title={option.groupAssign?.name}>{option.groupAssign?.name}</div>
        <div title={option.reviewDateForDisplay}>{option.reviewDateForDisplay}</div>
      </div>
      {option.reason && <div className="reason">{option.reason}</div>}
    </React.Fragment>
  );
}
