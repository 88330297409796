import React from 'react';

import DatePicker from '../../../../../../../common/data-entry/DatePicker';
import Button from '../../../../../../../common/general/Button';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../../../constants/dateFormat';

import './StartEndDateSelector.scss';

export const StartEndDateSelector = ({ startDate, onChangeStartDate, endDate, onChangeEndDate }) => {
  return (
    <div className="finance-start-end-date-selector-wrapper">
      <span>Start Date</span>
      <DatePicker
        onChange={onChangeStartDate}
        value={startDate}
        timeFormat={false}
        closeOnSelect
        dateFormat={DD_SLASH_MMM_SLASH_YYYY}
        readOnly
        renderInput={props => (
          <Button className="date-select-button" size="h28" priority={'medium'} onClick={props.onClick}>
            {startDate.format(DD_SLASH_MMM_SLASH_YYYY)}
          </Button>
        )}
      />
      <span>End Date</span>
      <DatePicker
        onChange={onChangeEndDate}
        value={endDate}
        timeFormat={false}
        closeOnSelect
        dateFormat={DD_SLASH_MMM_SLASH_YYYY}
        readOnly
        renderInput={props => (
          <Button className="date-select-button" size="h28" priority={'medium'} onClick={props.onClick}>
            {endDate.format(DD_SLASH_MMM_SLASH_YYYY)}
          </Button>
        )}
      />
    </div>
  );
};
