import React from 'react';
import PropTypes from 'prop-types';

import MultiSelect from '../../../common/data-entry/MultiSelect/MultiSelect';

export const ALL_ELEMENT = { id: 'all', name: 'All' };

const customSelectedSitesValue = (items, name, dataSource) => {
  const length = items?.filter(i => i?.id !== ALL_ELEMENT.id)?.length;
  const postFix = (name && `${name} selected`) || 'selected';
  if (!dataSource?.length) {
    return `No ${name}`;
  } else if (length >= dataSource?.length) {
    return `All(${dataSource?.length}) ${postFix}`;
  } else if (length > 0 && length < 4) {
    return items.map(i => i.name).join(', ');
  }
  return `${length} ${postFix}`;
};

const ReportMultiSelectFilter = ({ dataSource, value, name, id, onChange }) => {
  return (
    <MultiSelect
      controlId={id}
      label={name}
      searchable
      onChange={newValues => onChange(newValues)}
      customSelectedValueTemplateFunction={items => customSelectedSitesValue(items, name, dataSource)}
      dataSource={dataSource}
      value={value}
      validate={false}
    />
  );
};

ReportMultiSelectFilter.defaultProps = {
  dataSource: [],
  value: [],
  name: '',
  id: '',
  placeholder: '',
  onChange: () => {}
};

ReportMultiSelectFilter.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  name: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func
};

export default ReportMultiSelectFilter;
