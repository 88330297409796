import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash/lang';
import Tooltip from 'rc-tooltip';

import Input from '../../../../../../../common/data-entry/Input';
import Loader from '../../../../../../../common/elements/Loader/Loader';
import Icon from '../../../../../../../common/general/Icon';
import useOutsideClickDetector from '../../../../../../../common/hooks/useOutsideClickDetector';
import HighlightedPartOfWord from '../../../../../../root/Container/Layout/MenuOptions/FindPatient/FindPatientPopup/FindPatientPopupItem/HighlightedPartOfWord';
import { generateUrlByKey, useCurrentRoute } from '../../../../../../root/router';

import { ALL, ALL_TABS, encounterStatusesMap } from './SearchConstants';
import { getRegExpFromSearchStringForId, TabElement, useFetchSearchData } from './SearchHooksAndServices';

import './SearchSection.scss';

const EncounterSearchSection = () => {
  const searchResultsContainer = useRef(null);
  const observer = useRef(null);

  const [searchString, setSearchString] = useState('');
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [selectedTab, setSelectedTab] = useState(ALL);
  const [page, setPage] = useState(0);

  const { loading, hasMore, searchData, counts } = useFetchSearchData(page, searchString, selectedTab);

  useOutsideClickDetector(searchResultsContainer, () => setShowSearchResults(false));

  const currentRoute = useCurrentRoute();

  const lastElementRef = useCallback(
    node => {
      if (loading) return;
      if (selectedTab === ALL) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPage(prev => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, selectedTab, hasMore]
  );

  useEffect(() => {
    if (!searchString?.trim()) {
      setSelectedTab(ALL);
    }
  }, [searchString]);

  const onSearchChange = useCallback(
    ({ target: { value } }) => {
      setShowSearchResults(!!value?.trim());
      setSearchString(value);
      setPage(0);
    },
    [setSearchString]
  );

  const searchDataEmpty = useMemo(() => isEmpty(searchData.flatMap(section => section.content)), [searchData]);

  function getUrl(section, element) {
    switch (section) {
      case 'Encounters':
        if (element?.encounterId) {
          return generateUrlByKey(`${currentRoute.key}.Encounter Details`, {
            ...currentRoute.params,
            patientEncounterId: element?.encounterId || ''
          });
        }
        return;
      case 'Procedures':
        if (element?.encounterId && element?.itemGroupId) {
          return generateUrlByKey(`${currentRoute.key}.Encounter Details.Procedure`, {
            ...currentRoute.params,
            patientEncounterId: element?.encounterId || '',
            patientItemGroupId: element?.itemGroupId || ''
          });
        }
        return;
      case 'Logs':
        return generateUrlByKey(`${currentRoute.key}.Logs`, {
          ...currentRoute.params,
          patientEncounterId: element?.encounterId || '',
          patientItemGroupId: element?.itemGroupTemplateId || ''
        });
      default:
        return '';
    }
  }

  return (
    <div className="encounter-search-section" ref={searchResultsContainer}>
      <Input
        autoComplete="off"
        placeholder="Search"
        className="search-input"
        prefix={<Icon>search</Icon>}
        iconsAfter={
          !!searchString && (
            <Icon
              onClick={() => {
                setSearchString('');
                setShowSearchResults(!showSearchResults);
              }}
              suit="material-outline"
            >
              cancel
            </Icon>
          )
        }
        name="search"
        value={searchString}
        onChange={onSearchChange}
        onClick={() => searchString && setShowSearchResults(prevState => (!prevState ? true : prevState))}
      />
      {showSearchResults && (
        <div className="search-results">
          {!loading && (
            <div className="search-tabs-wrapper px-3">
              <TabElement
                key={'all-tab'}
                label={ALL}
                selectedTab={selectedTab}
                searchString={searchString}
                data={searchData}
                counts={counts}
                onSelect={label => {
                  setPage(0);
                  setSelectedTab(label);
                }}
              />
              <div className="search-tabs">
                {ALL_TABS.map(tab => (
                  <TabElement
                    key={tab}
                    label={tab}
                    selectedTab={selectedTab}
                    searchString={searchString}
                    counts={counts}
                    data={searchData}
                    onSelect={label => {
                      setPage(0);
                      setSelectedTab(label);
                    }}
                  />
                ))}
              </div>
            </div>
          )}
          <div className="search-result-content">
            {!searchDataEmpty
              ? searchData
                  .filter(el => !isEmpty(el.content))
                  .map((section, index) => {
                    const isLastElement = searchData.length === index + 1;
                    return (
                      <div key={section.name} className="search-part">
                        <div className="search-part-header">
                          {section.name}
                          {selectedTab === ALL && (
                            <span
                              onClick={() => {
                                setSelectedTab(section.name);
                              }}
                            >
                              See more
                            </span>
                          )}
                        </div>
                        {section.content.map((element, index) => {
                          return (
                            <Tooltip
                              destroyTooltipOnHide={true}
                              placement="top"
                              overlayClassName="eds-rc-tooltip"
                              mouseEnterDelay={0}
                              mouseLeaveDelay={0}
                              trigger={
                                element?.encounterName?.length + element?.itemGroupName?.length > 3 ? ['hover'] : []
                              }
                              overlay={
                                element?.encounterName ? (
                                  <span>
                                    {element?.encounterName} | {element.itemGroupName}
                                  </span>
                                ) : (
                                  <span>{element.itemGroupName}</span>
                                )
                              }
                            >
                              <Link
                                to={getUrl(section.name, element)}
                                key={element.encounterId + element.itemGroupId + index}
                                className="search-part-element"
                                ref={isLastElement ? lastElementRef : null}
                              >
                                {getName(section.name, element, searchString)}
                              </Link>
                            </Tooltip>
                          );
                        })}
                      </div>
                    );
                  })
              : !loading && <div className="no-results-message">No results available</div>}
            {loading && <Loader dark={true} />}
          </div>
        </div>
      )}
    </div>
  );
};

const getName = (sectionName, element, searchString) => {
  const regExpBody = getRegExpFromSearchStringForId(searchString?.trim());
  switch (sectionName) {
    case 'Encounters':
      return (
        <>
          <div className={'truncated'}>
            <HighlightedPartOfWord
              word={element.encounterName}
              reg={regExpBody !== '' && new RegExp(`(${regExpBody})`, 'gi')}
            />
          </div>
          <span className={'px-2'}>|</span>
          <span>{encounterStatusesMap[element.encounterStatus]}</span>
        </>
      );
    case 'Procedures':
      return (
        <div className={'truncated'}>
          {element.encounterName}
          <span className={'px-2'}>|</span>
          <HighlightedPartOfWord
            word={element.itemGroupName}
            reg={regExpBody !== '' && new RegExp(`(${regExpBody})`, 'gi')}
          />
        </div>
      );
    case 'Logs':
      return (
        <div className={'truncated'}>
          <HighlightedPartOfWord
            word={element.itemGroupName}
            reg={regExpBody !== '' && new RegExp(`(${regExpBody})`, 'gi')}
          />
        </div>
      );
    default:
      return '';
  }
};

export default EncounterSearchSection;
