import { REIMBURSEMENT_WORKLIST } from '../../../../constants/accessToPages';
import ReimbursementRequest from '../../patient-source/Patients/PatientInfo/ReimbursementRequest/ReimbursementRequest';

import ReimbursementWorklist from './ReimbursementWorklist';

export const reimbursementRouterConfig = {
  name: 'Reimbursements',
  path: '/reimbursements',
  rootBreadcrumbName: 'Finance',
  component: ReimbursementWorklist,
  access: [REIMBURSEMENT_WORKLIST],
  inherited: [
    {
      name: 'Reimbursement request',
      path: '/:patientId/:ssuPatientId/:reimbursementId',
      component: ReimbursementRequest,
      access: [REIMBURSEMENT_WORKLIST]
    }
  ]
};
