import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactTable from 'react-table';
import cx from 'classnames';
import { uniqWith } from 'lodash';
import { isEmpty } from 'lodash/lang';
import { sumBy } from 'lodash/math';
import Tooltip from 'rc-tooltip';

import Icon from '../../../../../common/general/Icon';
import useSessionStorageWithRole from '../../../../../common/hooks/useSessionStorageWithRole';
import { PI_WORKLIST_NEW_VALUES, SM_WORKLIST_VALUES_NEW } from '../../../../../constants/sessionStorageConstants';
import {
  ROLE_OPERATION_MANAGER,
  ROLE_OPERATIONS_ANALYST,
  ROLE_SITE_FINANCIAL_COORDINATOR,
  ROLE_STUDY_MANAGER,
  ROLE_SYSTEM_ADMINISTRATOR
} from '../../../../../constants/userRoles';
import { useCurrentUser } from '../../../../root/Container/CurrentUserContainer';
import { generateUrlByKey } from '../../../../root/router';
import { CustomHeader } from '../common/CustomHeader';

import { EncounterSignOffContext } from './EncounterSignOffFiltersContext';
import { OPEN, PI_REVIEW, SM_REVIEW } from './EncounterSignOffWidgetConstants';

export default function EncounterSignOffTable({ data }) {
  // eslint-disable-next-line no-unused-vars
  const [sessionStorageSM, setSessionStorageSM] = useSessionStorageWithRole(SM_WORKLIST_VALUES_NEW, {});
  // eslint-disable-next-line no-unused-vars
  const [sessionStorageInvestigator, setSessionStorageInvestigator] = useSessionStorageWithRole(
    PI_WORKLIST_NEW_VALUES,
    {}
  );
  const { selectedStudies, selectedSites, selectedStudySites, epochEncounterMap } = useContext(EncounterSignOffContext);
  const navigate = useNavigate();
  const user = useCurrentUser();
  const allowedRoles = [
    ROLE_STUDY_MANAGER,
    ROLE_SYSTEM_ADMINISTRATOR,
    ROLE_OPERATIONS_ANALYST,
    ROLE_OPERATION_MANAGER,
    ROLE_SITE_FINANCIAL_COORDINATOR
  ].includes(user.activeRole);

  const openNumbersSM = (selectedEncounters, selectedStatuses) => {
    if (allowedRoles) {
      setSessionStorageSM({
        ...sessionStorageSM,
        selectedStudySites,
        selectedStudies,
        selectedSites,
        currentlySelected: selectedStatuses,
        encounterNames: !isEmpty(selectedEncounters) ? prepareEncounters(selectedEncounters) : []
      });
      setTimeout(() => navigate(generateUrlByKey('Home Page.Study Manager Worklist')));
    }
  };

  function prepareEncounters(encounter) {
    const eeMap = epochEncounterMap.filter(
      eeMap => eeMap.encounterName === encounter && selectedStudySites.map(ssu => ssu.id).includes(eeMap.ssuId)
    );
    return uniqWith(
      eeMap
        .map(epochEncounterElement =>
          selectedStudySites
            .filter(ssu => ssu.id === epochEncounterElement.ssuId && ssu.study.name)
            .map(ssu => ({
              encounterName: epochEncounterElement.encounterName,
              studyName: `${ssu.study.name} - ${ssu.study.projectCode}`
            }))
        )
        .flat(),
      function(arrVal, othVal) {
        return arrVal.studyName === othVal.studyName && arrVal.encounterName === othVal.encounterName;
      }
    );
  }

  const openNumbersInvestigator = selectedEncounters => {
    setSessionStorageInvestigator({
      ...sessionStorageInvestigator,
      selectedStudySites,
      selectedStudies,
      selectedSites,
      currentlySelected: 'REVIEW_REQUIRED',
      encounterNames: !isEmpty(selectedEncounters) ? prepareEncounters(selectedEncounters) : []
    });
    navigate(generateUrlByKey('Home Page.Investigator Worklist'));
  };
  const columns = [
    {
      Header: <CustomHeader title={'Encounter'} />,
      Footer: <div className="total-title">Total</div>,
      accessor: 'encounter',
      minWidth: 150,
      Cell: ({ value }) => (
        <Tooltip
          destroyTooltipOnHide={true}
          placement="top"
          overlayClassName="eds-rc-tooltip"
          mouseEnterDelay={0}
          mouseLeaveDelay={0}
          trigger={value?.length > 15 ? ['hover'] : []}
          overlay={value}
        >
          <span>{value}</span>
        </Tooltip>
      )
    },
    {
      Header: <CustomHeader title={'Open'} />,
      Footer: (
        <div className={cx('total-val', { link: allowedRoles })} onClick={() => openNumbersSM([], OPEN)}>
          {sumBy(data, OPEN)}
        </div>
      ),
      accessor: 'OPEN',
      className: allowedRoles ? 'link' : '',
      minWidth: 100,
      Cell: ({ value, original }) => <div onClick={() => openNumbersSM(original.encounter, OPEN)}>{value}</div>
    },
    {
      Header: <CustomHeader title={'SM Review'} />,
      Footer: (
        <div className={cx('total-val', { link: allowedRoles })} onClick={() => openNumbersSM([], SM_REVIEW)}>
          {sumBy(data, SM_REVIEW)}
        </div>
      ),
      accessor: 'SM_REVIEW',
      className: allowedRoles ? 'link' : '',
      minWidth: 120,
      Cell: ({ value, original }) => <div onClick={() => openNumbersSM(original.encounter, SM_REVIEW)}>{value}</div>
    },
    {
      Header: <CustomHeader title={'PI Review'} />,
      Footer: (
        <div
          className={cx('total-val', { link: true })}
          onClick={() => {
            openNumbersInvestigator([]);
          }}
        >
          {sumBy(data, PI_REVIEW)}
        </div>
      ),
      accessor: 'PI_REVIEW',
      className: 'link',
      minWidth: 100,
      Cell: ({ value, original }) => <div onClick={() => openNumbersInvestigator(original.encounter)}>{value}</div>
    },
    { Header: <CustomHeader title={'Total'} />, minWidth: 100, accessor: 'total', Footer: `${sumBy(data, 'total')}` }
  ];
  return (
    <div>
      <ReactTable
        resizable={false}
        className="px-3 mb-3 with-total-table"
        data={data}
        columns={columns}
        showPageSizeOptions={false}
        defaultPageSize={3}
        nextText={<Icon>chevron_right</Icon>}
        previousText={<Icon>chevron_left</Icon>}
        noDataText="No Record Found"
      />
    </div>
  );
}
