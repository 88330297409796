import { ONLY_PRE_SCREEN_STATUSES_ALLOWED } from '../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../services/auth';

export class AllowedStatusesResolver {
  //must always return valid predicate
  getAllowedStatuses() {
    if (userHasAccessTo(ONLY_PRE_SCREEN_STATUSES_ALLOWED)) {
      return s => s.preScreeningStatus || s.id === 'TRANSFERRING';
    }
    return () => true;
  }
}
