import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTable from 'react-table';
import { uniqBy } from 'lodash';
import { isArray, isEmpty, isEqual, isNull } from 'lodash/lang';
import { sumBy } from 'lodash/math';
import moment from 'moment';

import { getLastLockedPeriodActionCreator } from '../../../../actions/finance/forecasting/forecastingActions';
import { StudySiteApi } from '../../../../api';
import Select from '../../../../common/data-entry/Select';
import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../common/general/Button';
import ButtonGroup from '../../../../common/general/ButtonGroup';
import useSessionStorage from '../../../../common/hooks/useSessionStorage';
import { CANCELED } from '../../../../constants/ssuStatuses';
import { pendoTrackDefaultSortingChange } from '../../../../pendo/PendoUtils';
import { onRequestError } from '../../../../services/handlers';
import { PageInfoHeader } from '../../../PageInfoHeader/PageInfoHeader';
import { SSUFilter } from '../../../SSUFilter/SSUFilter';
import { SSUSelect } from '../../../SSUFilter/SSUSelect';
import { getEnrollmentsForDate } from '../../study/Enrollment/studyEnrollmentService';

import ExportEnrollment from './ExportEnrollment/ExportEnrollment';
import LockButton from './LockButton';
import MonthFilter from './MonthFilter';

import './Enrolments.scss';

const ENROLLMENTS_TABLE_DATA = 'ENROLLMENTS_TABLE_DATA';
export function Enrollments() {
  const [sessionStorage, setSessionStorage] = useSessionStorage(ENROLLMENTS_TABLE_DATA, {});
  const [pageSize, setPageSize] = useState(sessionStorage.pageSize || 25);
  const defaultSortProperties = [
      { id: 'study', desc: false },
      { id: 'site', desc: false }
    ],
    [filteredEnrollments, setFilteredEnrollments] = useState([]),
    [selectedStudySites, setSelectedStudySites] = useState([]),
    [selectedStudy, setSelectedStudy] = useState(null),
    [selectedSite, setSelectedSite] = useState(null),
    [therapeuticAreas, setTherapeuticAreas] = useState([]),
    [selectedTherapeuticArea, setSelectedTherapeuticArea] = useState(null),
    [studySites, setStudySites] = useState(null),
    [dateToShow, setDateToShow] = useState(moment().date(10));
  const columns = getColumnsConfigurationForTable(dateToShow);
  const lastLockedPeriod = useSelector(state => state.forecasting.lastLockedPeriod);
  const dispatch = useDispatch();

  const getLastLockedPeriod = useCallback(() => {
    dispatch(getLastLockedPeriodActionCreator());
  }, [dispatch]);

  const showComment = text => {
    ModalBoxes.confirm({
      content: text,
      title: "Reason prediction doesn't match",
      confirmButton: 'Close',
      cancelButton: false
    }).then(
      () => {},
      () => {}
    );
  };

  useEffect(() => {
    getLastLockedPeriod();
  }, [getLastLockedPeriod]);

  useEffect(function() {
    StudySiteApi.getStudySitesEnrollment().then(({ data: studySites }) => {
      setStudySites(studySites);
      const allTherapeuticAreas = extractTherapeuticAreasFromSsus(studySites);
      const uniqAreas = uniqBy(allTherapeuticAreas, 'areaName');
      setTherapeuticAreas(uniqAreas);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    function() {
      const actualTherapeuticAreas = extractTherapeuticAreasFromSsus(selectedStudySites);
      const uniqAreas = uniqBy(actualTherapeuticAreas, 'areaName');
      setTherapeuticAreas(uniqAreas);
      if (uniqAreas.length === 1 && selectedStudy) {
        setSelectedTherapeuticArea(uniqAreas[0]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedStudySites]
  );

  useEffect(
    function() {
      if (isEmpty(selectedStudySites)) {
        return;
      }
      getEnrollmentsForDate(selectedStudySites, dateToShow, selectedTherapeuticArea)
        .then(filteredEnrollments => {
          setFilteredEnrollments(filteredEnrollments);
        })
        .catch(err => onRequestError(err, { customMessage: "Can't get enrollments" }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedStudySites, dateToShow]
  );

  useEffect(
    function() {
      if (isArray(studySites)) {
        const ssusPerTherapeuticArea = studySites
          .filter(ssu => ssu.siteStatus !== CANCELED)
          .filter(ssu =>
            selectedTherapeuticArea ? ssu.study.therapeuticArea === selectedTherapeuticArea.areaValue : true
          );
        const studiesPerTherapeuticArea = uniqBy(ssusPerTherapeuticArea, 'studyIdentifier').map(ssu => ssu.study);
        if (ssusPerTherapeuticArea.length === 1) {
          const site = ssusPerTherapeuticArea[0].site;
          !isEqual(site, selectedSite) && setSelectedSite(site);
        }
        if (studiesPerTherapeuticArea.length === 1) {
          const study = studiesPerTherapeuticArea[0];
          !isEqual(study, selectedStudy) && setSelectedStudy(study);
        } else {
          isEqual(null, selectedStudy) && setSelectedStudy(null);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTherapeuticArea]
  );

  return (
    <div className="eds-finance-enrollments">
      {studySites && (
        <PageInfoHeader
          right={
            <ButtonGroup>
              <LockButton lastLockedPeriod={lastLockedPeriod} studySites={studySites} />
              <ExportEnrollment
                studySites={selectedStudySites}
                dateToShow={dateToShow}
                selectedTherapeuticArea={selectedTherapeuticArea}
              />
            </ButtonGroup>
          }
        >
          <div className="general-header-group-container general-header-wrapper">
            <SSUFilter
              handleSsuProviderUpdate={true}
              handleSSUFilterChange={handleSSUFilterChange}
              studyIdProvider={() => selectedStudy?.uniqueIdentifier}
              siteIdProvider={() => selectedSite?.uniqueIdentifier}
              ssuProvider={() =>
                studySites.filter(ssu =>
                  selectedTherapeuticArea ? ssu.study.therapeuticArea === selectedTherapeuticArea.areaValue : true
                )
              }
            >
              <SSUSelect />
              <TherapeuticAreaSelect
                dataSource={therapeuticAreas}
                onChange={area => {
                  setSelectedTherapeuticArea(area);
                  !isNull(selectedStudy) && (isNull(area) || area === undefined) && setSelectedStudy(null);
                }}
                value={selectedTherapeuticArea}
              />
            </SSUFilter>
            <MonthFilter lastLockedPeriod={lastLockedPeriod} handleMChange={setDateToShow} dateToShow={dateToShow} />
          </div>
        </PageInfoHeader>
      )}
      <section className="filter-section">
        <div className="row border p-3 m-0 my-2">
          <div className="col p-0 pt-2">
            <h5 className="c-p">Enrollment Prediction</h5>
          </div>
          <div className="w-100" data-testid="enrollments-table">
            <ReactTable
              data-testid="enrollments-table"
              data={filteredEnrollments}
              columns={columns}
              onSortedChange={pendoTrackDefaultSortingChange}
              minRows={1}
              onPageSizeChange={pageSize => {
                setPageSize(pageSize);
                setSessionStorage({ pageSize: pageSize });
              }}
              multiSort
              defaultSorted={defaultSortProperties}
              showPagination
              nextText=">>"
              previousText="<<"
              noDataText="No Record Found"
              pageSizeOptions={[25, 50, 100]}
              defaultPageSize={pageSize}
            />
          </div>
        </div>
      </section>
    </div>
  );

  function handleSSUFilterChange(newStudySites, newSelectedStudy, newSelectedSite) {
    !isEqual(newStudySites, selectedStudySites) && setSelectedStudySites(newStudySites);
    !isEqual(newSelectedSite, selectedSite) && setSelectedSite(newSelectedSite);
    if (!isEqual(newSelectedStudy, selectedStudy)) {
      setSelectedStudy(newSelectedStudy);
      !newSelectedStudy && selectedTherapeuticArea && setSelectedTherapeuticArea(null);
    }
  }

  function extractTherapeuticAreasFromSsus(studySites) {
    if (studySites.length !== 0) {
      let therapeuticAreas = studySites
        ?.filter(ssu => ssu.study.therapeuticArea)
        .map(ssu => ({ areaName: ssu.study.therapeuticArea, areaValue: ssu.study.therapeuticArea }));
      let areas = studySites.map(ssu => ssu.study.therapeuticArea);
      if (areas.includes('')) {
        therapeuticAreas.push({ areaName: 'No Therapeutic Area', areaValue: '' });
      }
      return therapeuticAreas;
    } else {
      return therapeuticAreas;
    }
  }

  function getColumnsConfigurationForTable(startDate) {
    const className = 'center-table-column-content';
    const headerClassName = className;
    return [
      {
        id: 'study',
        Header: 'Study',
        accessor: 'study.studyName'
      },
      {
        id: 'site',
        Header: 'Site',
        accessor: 'site.siteName'
      },
      {
        id: 'therapeuticArea',
        Header: 'Therapeutic Area',
        accessor: 'study.therapeuticArea'
      },
      {
        id: 'expected',
        Header: 'Expected',
        accessor: ({ consentExpectedSiv, consentExpectedPsv }) => consentExpectedSiv || consentExpectedPsv,
        className,
        headerClassName
      },
      {
        id: 'remaining',
        Header: 'Remaining',
        className,
        headerClassName,
        accessor: ({ consentExpectedSiv, consentExpectedPsv, enrolledCount }) =>
          (consentExpectedSiv || consentExpectedPsv) - enrolledCount
      },
      {
        Header: 'Enrolled',
        accessor: 'enrolledCount',
        className,
        headerClassName
      },
      ...generateColumnsConfigurationForMonths(startDate),
      {
        id: 'total',
        Header: 'Total',
        className: 'center-table-column-content no-padding-no-border font-size-inherit',
        headerClassName,
        accessor: ({ enrollmentPredictionValues }) => {
          return sumBy(enrollmentPredictionValues, 'value');
        }
      },
      {
        id: 'comments',
        Header: 'Comments',
        className: 'enrollment-column-with-button',
        headerClassName,
        accessor: enrollment => (enrollment.reason !== '' ? 'View' : '--'),
        Cell: ({ original }) => {
          return original.reason !== '' ? (
            <Button
              onClick={() => {
                showComment(original.reason);
              }}
              type="button"
              size="h28"
              priority="medium"
            >
              View
            </Button>
          ) : (
            '--'
          );
        }
      }
    ];

    function generateColumnsConfigurationForMonths(startDate) {
      return ['firstMonth', 'secondMonth', 'thirdMonth', 'fourthMonth'].map((id, index) => ({
        id,
        Header: moment(startDate)
          .add(index, 'month')
          .format('MMMM'),
        className,
        headerClassName,
        accessor: ({ enrollmentPredictionValues }) => {
          const today = moment(startDate).add(index, 'month'),
            enrollmentsForMonth = enrollmentPredictionValues.find(
              p => p.year === today.year() && p.month === today.month()
            );
          return enrollmentsForMonth ? enrollmentsForMonth.value : null;
        },
        Cell: ({ value }) => (!isNull(value) ? value : '--')
      }));
    }
  }
}

function TherapeuticAreaSelect({ dataSource, value, onChange }) {
  return (
    <Select
      label="Therapeutic Area"
      dataSource={dataSource}
      optionLabelKey="areaName"
      optionValueKey="areaValue"
      onChange={onChange}
      value={value}
      searchable
      data-testid="therapeutic-area-selector"
    />
  );
}

export default Enrollments;
