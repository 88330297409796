import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { isArray } from 'lodash/lang';
import moment from 'moment';
import Tooltip from 'rc-tooltip';

import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import LogsTable from '../../../../Patients/Logs/LogsTable';
import { defaultCellWidth } from '../../../../Patients/Logs/LogsTableService';

import './ElementHistoryLogCheckModal.scss';

export default function ElementHistoryLogCheckModal({ modalBox, onLoadData }) {
  const columns = useMemo(
    () => [
      creteColumn('Log Checks'),
      creteColumn('Comment'),
      creteColumn('Who'),
      creteColumn('When'),
      creteColumn('Last Updated By'),
      creteColumn('Last Updated Date')
    ],
    []
  );

  const [data, setData] = useState([]);
  useEffect(loadData, [onLoadData]);

  return (
    <>
      <ModalBoxes.Body>
        <LogsTable data={data?.reverse()} showPagination={false} sortable={false} columns={columns} />
      </ModalBoxes.Body>

      <ModalBoxes.Footer>
        <Button priority="medium" onClick={modalBox.close}>
          Close
        </Button>
      </ModalBoxes.Footer>
    </>
  );

  function loadData() {
    onLoadData().then(response => {
      let prevLogCheckItem = {};
      setData(
        response?.data?.logCheckSnapshots?.reverse().map(logCheckItem => {
          const data = {
            'Log Checks': {
              value: logCheckItem?.logCheckAnswers.filter(answer => answer.checked).map(answer => answer.logCheckValue),
              changed:
                JSON.stringify(prevLogCheckItem?.logCheckAnswers) !== JSON.stringify(logCheckItem?.logCheckAnswers)
            },
            Comment: {
              value: logCheckItem?.reasonCommentForNotPerformed,
              changed: prevLogCheckItem?.reasonCommentForNotPerformed !== logCheckItem.reasonCommentForNotPerformed
            },
            Who: {
              value: logCheckItem.whoDidItName,
              changed: prevLogCheckItem?.whoDidItName !== logCheckItem.whoDidItName
            },
            When: {
              value: moment(logCheckItem.whenWasItDone).format('DD/MMM/YYYY'),
              changed: prevLogCheckItem?.whenWasItDone !== logCheckItem.whenWasItDone
            },
            'Last Updated Date': {
              value: moment(logCheckItem.lastUpdateDate).format('DD-MMM-YYYY hh:mm:ss a')
            },
            'Last Updated By': {
              value: logCheckItem.lastUpdatedByName
            }
          };
          prevLogCheckItem = logCheckItem;
          return data;
        })
      );
    });
  }

  function creteColumn(headerName) {
    const columnInfo = { id: headerName, header: headerName, minWidth: defaultCellWidth };
    return {
      ...columnInfo,
      Header: columnInfo.header,
      getProps: (state, rowInfo, column) => {
        const isColumnChanged = rowInfo?.original?.[column.id]?.changed;
        return {
          className: cx('history-log-cell', { 'eds-review-history-changed-cell': isColumnChanged })
        };
      },
      Cell: ({ original, column }) => {
        if (original[column.id]) {
          const { value, changed, reasonForDataChange, performedStatus, comment } = original[column.id];

          let slittedValue = value ? value : '';

          if (isArray(slittedValue)) {
            slittedValue = slittedValue.join(' ');
          }
          return (
            <React.Fragment>
              {slittedValue && (
                <Tooltip
                  destroyTooltipOnHide={false}
                  overlayClassName="eds-rc-tooltip over-modal"
                  placement="top"
                  trigger={slittedValue?.length > 30 ? ['hover'] : []}
                  overlay={slittedValue}
                >
                  <div className={'tool'}>{slittedValue}</div>
                </Tooltip>
              )}
              {reasonForDataChange && changed && (
                <Tooltip
                  destroyTooltipOnHide={false}
                  overlayClassName="eds-rc-tooltip over-modal"
                  placement="top"
                  trigger={reasonForDataChange?.length > 30 ? ['hover'] : []}
                  overlay={reasonForDataChange}
                >
                  <div className="font-italic">{reasonForDataChange}</div>
                </Tooltip>
              )}
              {!slittedValue && performedStatus && comment && (
                <Tooltip
                  destroyTooltipOnHide={false}
                  overlayClassName="eds-rc-tooltip over-modal"
                  placement="top"
                  trigger={`${performedStatus}: ${comment}`?.length > 30 ? ['hover'] : []}
                  overlay={`${performedStatus}: ${comment}`}
                >
                  <div className="font-italic">{`${performedStatus}: ${comment}`}</div>
                </Tooltip>
              )}
            </React.Fragment>
          );
        } else {
          return <div>Field not in template</div>;
        }
      }
    };
  }
}

ElementHistoryLogCheckModal.className = 'element-history-log-check-modal';
ElementHistoryLogCheckModal.size = 'w1250';
