import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as PropTypes from 'prop-types';

import DatePicker from '../../../../../../common/data-entry/DatePicker/DatePicker';
import Select from '../../../../../../common/data-entry/Select/Select';
import TextArea from '../../../../../../common/data-entry/TextArea/TextArea';
import Button from '../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../common/general/ButtonGroup';
import { allowedStatusChangesWithoutEncounter, statusChangeModes } from '../PatientStatusChangeConstants';

import {
  buildDraftStatusChangeRecord,
  getNextStatusChangeId,
  isThereMoreStatusChangesAtDraftStatusChangeDate,
  moveDownDraftRecord,
  moveUpDraftRecord,
  thereAreNoNextStatusChangesSameDateAsDraft,
  thereAreNoPreviousStatusChangesSameDateAsDraft,
  updateDraftStatusChangeRecord
} from './PatientStatusChangeEditService';

import './PatientStatusChangeEdit.scss';

export default function PatientStatusChangeEdit({
  viewMode,
  initialEncounter,
  statuses,
  encounters,
  close,
  updateStatus,
  isValidDate,
  patientStatusHistory = [],
  onStatusRecordChanged,
  removeDraftStatuses,
  initialStatus
}) {
  const { handleSubmit, errors, control, watch, setValue, reset } = useForm({
    defaultValues: {
      status: statuses?.find(e => e.name === initialStatus) || null,
      date: null,
      reason: null,
      encounter: null,
      comment: ''
    }
  });
  const [showArrows, setShowArrows] = React.useState(false);

  const status = watch('status');
  const date = watch('date');
  const encounter = watch('encounter');
  const reason = watch('reason');
  const comment = watch('comment');

  const isAddMode = useMemo(() => viewMode === statusChangeModes.ADD, [viewMode]);

  const update = ({ status, reason, date, encounter, comment }) => {
    const nextStatusChangeId = getNextStatusChangeId(patientStatusHistory, date);
    updateStatus(status, reason, date, encounter, comment, nextStatusChangeId);
  };

  useEffect(() => {
    reset({
      status: statuses?.find(e => e.name === initialStatus) || null,
      date: null,
      reason: null,
      encounter: null,
      comment: ''
    });
    removeDraftStatuses();
    // eslint-disable-next-line
  }, [isAddMode, reset, removeDraftStatuses]);

  useEffect(() => {
    if (isAddMode) {
      setShowArrows(isThereMoreStatusChangesAtDraftStatusChangeDate(patientStatusHistory));
    } else {
      setShowArrows(false);
    }
  }, [isAddMode, patientStatusHistory]);

  useEffect(() => {
    if (date) {
      onStatusRecordChanged(updateDraftStatusChangeRecord(patientStatusHistory, control.getValues()));
    }
    // eslint-disable-next-line
  }, [encounter, comment, reason, status]);

  useEffect(
    function() {
      if (date) {
        onStatusRecordChanged(buildDraftStatusChangeRecord(patientStatusHistory, control.getValues()));
      }
    },
    // eslint-disable-next-line
    [date, control, onStatusRecordChanged]
  );

  useEffect(() => {
    const encounter = encounters?.find(e => e.id === initialEncounter?.id);
    setValue('encounter', encounter);
  }, [initialEncounter, encounters, setValue]);

  useEffect(() => {
    const status = statuses?.find(e => e.name === initialStatus);
    if (status) {
      setValue('status', status);
    } else {
      setValue('status', null);
    }
  }, [initialStatus, statuses, setValue]);

  const moveUpStatusChange = () => onStatusRecordChanged(moveUpDraftRecord(patientStatusHistory, control.getValues()));

  const moveDownStatusChange = () =>
    onStatusRecordChanged(moveDownDraftRecord(patientStatusHistory, control.getValues()));

  const isEncounterRequired = status => {
    return !allowedStatusChangesWithoutEncounter.includes(status?.id);
  };

  const isTransferringStatus = status?.id === 'TRANSFERRING';

  return (
    <div className="patient-status-change-edit">
      <h4 className="p-4">{isAddMode ? 'Add Status' : 'Change Current Status'}</h4>
      <div className="px-4 main-area">
        <form id="change-status-from" onSubmit={handleSubmit(update)}>
          <Controller
            as={Select}
            control={control}
            name={'status'}
            rules={{ required: true }}
            required={true}
            validationMessage={errors.status ? 'Status is required.' : undefined}
            label="Status"
            clearSearchOnSelection={true}
            searchable
            clearable={false}
            closeOnSelectedOptionClick={true}
            deselectOnSelectedOptionClick={false}
            dataSource={statuses || []}
          />
          <Controller
            as={DatePicker}
            control={control}
            name={'date'}
            required={true}
            rules={{ required: true }}
            validationMessage={errors.date ? 'Date is required.' : undefined}
            label="Date"
            dateFormat="MMM, DD YYYY"
            timeFormat={false}
            closeOnSelect
            readOnly
            isValidDate={isValidDate}
          />
          {!isTransferringStatus && (
            <Controller
              as={Select}
              control={control}
              name={'encounter'}
              rules={{ required: isEncounterRequired(status) }}
              required={isEncounterRequired(status)}
              validationMessage={errors.encounter ? 'Encounter is required.' : undefined}
              label="Encounter"
              clearSearchOnSelection={true}
              clearable={true}
              closeOnSelectedOptionClick={true}
              deselectOnSelectedOptionClick={false}
              dataSource={encounters}
            />
          )}
          {status?.dropStatus && (
            <Controller
              as={Select}
              control={control}
              name={'reason'}
              rules={{ required: true }}
              required={true}
              validationMessage={errors.reason ? 'Reason is required.' : undefined}
              label="Reason"
              clearSearchOnSelection={true}
              searchable
              clearable={false}
              closeOnSelectedOptionClick={true}
              deselectOnSelectedOptionClick={false}
              dataSource={status?.reasons}
            />
          )}
          {status?.dropStatus && (
            <Controller
              as={TextArea}
              control={control}
              rules={{ required: status?.dropStatus }}
              required={status?.dropStatus}
              validationMessage={errors.comment ? 'Comment is required.' : undefined}
              style={{ resize: 'none' }}
              rows={1}
              clearable
              label="Comment"
              name="comment"
            />
          )}
        </form>
        {showArrows && !isTransferringStatus && (
          <div className="position-icons">
            <div className="mb-3">Position in status history</div>
            <div className="mb-1">
              <Button
                priority="low"
                size={'h28'}
                disabled={thereAreNoNextStatusChangesSameDateAsDraft(patientStatusHistory)}
                onClick={moveUpStatusChange}
              >
                <span className="material-icons">keyboard_arrow_up</span>
                <span>Move Up</span>
              </Button>
            </div>
            <div>
              <Button
                priority="low"
                size={'h28'}
                disabled={thereAreNoPreviousStatusChangesSameDateAsDraft(patientStatusHistory)}
                onClick={moveDownStatusChange}
              >
                <span className="material-icons">keyboard_arrow_down</span>
                <span>Move Down</span>
              </Button>
            </div>
          </div>
        )}
      </div>
      <div className="action-buttons px-4 py-3">
        <ButtonGroup>
          <Button priority="medium" onClick={() => close()}>
            Cancel
          </Button>
          <Button form="change-status-from">Save</Button>
        </ButtonGroup>
      </div>
    </div>
  );
}

PatientStatusChangeEdit.propTypes = {
  initialEncounter: PropTypes.object,
  statusList: PropTypes.arrayOf(PropTypes.object),
  dropStatusReasonList: PropTypes.arrayOf(PropTypes.object),
  encounterList: PropTypes.arrayOf(PropTypes.object),
  updateStatus: PropTypes.func,
  isValidDate: PropTypes.func,
  close: PropTypes.func
};
