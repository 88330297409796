import React, { useContext } from 'react';

import { FinLedgerEventApi } from '../../../../../api';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';
import { VIEW_REVENUE_EVENTS } from '../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../services/auth';
import { toFormattedBill } from '../../../../../services/financial';
import { PageInfoHeader } from '../../../../PageInfoHeader/PageInfoHeader';
import { Amount } from '../../shared/amount-view/Amount/Amount';
import { RevenueContext } from '../RevenueContext';
import RevenueFilter from '../RevenueFilter/RevenueFilter';

import './NetRevenue.scss';

function getRevenue(ledgerEvents) {
  if (ledgerEvents?.length > 0) {
    return ledgerEvents.reduce((accumulate, currentValue) => {
      return accumulate + currentValue.netRevenueAmount;
    }, 0);
  }
  return 0;
}

function NetRevenue() {
  const { ledgerEvents, filterRevenueEvents, downloadCSV, exportRevenueNetSuite, filterProperty } = useContext(
    RevenueContext
  );
  const calculatePendingElligoEvents = () => {
    FinLedgerEventApi.calculatePendingElligoEvents().then(() => {
      filterRevenueEvents(filterProperty);
    });
  };

  const revenue = getRevenue(ledgerEvents);
  return (
    <PageInfoHeader
      pageInfo={
        <PageInfoHeader.AdditionalInfo title="Revenue">
          <Amount highlightIntegerValue coinsAmount={revenue} customCoinsFormat={toFormattedBill} />
        </PageInfoHeader.AdditionalInfo>
      }
      right={
        <ButtonGroup>
          {userHasAccessTo(VIEW_REVENUE_EVENTS) && (
            <Button
              className="revenue-net-revenue-calculate"
              size="h28"
              priority="high"
              onClick={calculatePendingElligoEvents}
            >
              Calculate
            </Button>
          )}
          <Button size="h28" priority="medium" onClick={downloadCSV}>
            Export
          </Button>
          <Button size="h28" priority="medium" onClick={exportRevenueNetSuite}>
            Export NS
          </Button>
        </ButtonGroup>
      }
    >
      <RevenueFilter />
    </PageInfoHeader>
  );
}

export default NetRevenue;
