import React, { useMemo } from 'react';
import { isEmpty } from 'lodash/lang';

import ApplyAndResetButtons from '../../../ApplyAndResetButtons/ApplyAndResetButtons';
import { useModeReportsActions, useModeReportsState } from '../ModeReportsContext';
import { dashboardsMap } from '../modeReportsServices';

import ModeReportsFilterBuilder from './ModeReportFilterBuilder';

import './ModeReportsFilters.scss';

function ModeReportsFilters() {
  const { data, reportKey } = useModeReportsState();
  const { applyFilters } = useModeReportsActions();

  const selectedStudy = useMemo(() => {
    if (Array.isArray(data.selectedValues.studies)) {
      return null;
    } else {
      return data.selectedValues.studies;
    }
  }, [data.selectedValues.studies]);

  const selectedPcn = useMemo(() => {
    if (Array.isArray(data.selectedValues.pcn)) {
      return null;
    } else {
      return data.selectedValues.pcn;
    }
  }, [data.selectedValues.pcn]);

  const selectedLevel = useMemo(() => {
    if (Array.isArray(data.selectedValues.levels)) {
      return data.selectedValues.levels.find(item => item.name === 'Total');
    } else {
      return data.selectedValues.levels;
    }
  }, [data.selectedValues.levels]);

  const selectedAggregation = useMemo(() => {
    if (Array.isArray(data.selectedValues.aggregation)) {
      return data.selectedValues.aggregation.find(item => item.name === 'Monthly');
    } else {
      return data.selectedValues.aggregation;
    }
  }, [data.selectedValues.aggregation]);

  if (isEmpty(data)) return null;

  return (
    <div className="mr-dashboard-filters page-info-header more-than-five-fields-wrapper">
      {dashboardsMap[reportKey].filters.map(filter => (
        <ModeReportsFilterBuilder
          onClearDatePicker={filter.onClear}
          key={reportKey + filter.filterKey}
          filterKey={filter.filterKey}
          filterLabel={filter.filterLabel}
          filterType={filter.filterType}
          clearable={filter.clearable}
          dataSource={data?.filters?.[filter.filterKey]}
          selectedValues={data?.selectedValues?.[filter.filterKey]}
          startDateLabel={filter.startDateLabel}
          endDateLabel={data?.selectedValues?.start_date}
          selectedStudy={selectedStudy}
          selectedPcn={selectedPcn}
          selectedLevel={selectedLevel}
          selectedAggregation={selectedAggregation}
          controlId={filter.filterKey + '-filter'}
          onChangeFuncitonName={filter.onChange}
        />
      ))}
      <ApplyAndResetButtons applyClassName="report-common-style-wrapper apply-btn" onApply={applyFilters} />
    </div>
  );
}
export default ModeReportsFilters;
