import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

import { addStudyBudget } from '../../../../actions/finance/budget/addStudyBudgetAction';
import { getStudyBudgets } from '../../../../actions/finance/budget/getStudyBudgetsAction';
import { FinBudgetApi, FinClientApi, FinStudySiteApi } from '../../../../api';
import Input from '../../../../common/data-entry/Input';
import Select from '../../../../common/data-entry/Select';
import Button from '../../../../common/general/Button';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import { MANAGE_BUDGETS } from '../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../services/auth';
import { onRequestError } from '../../../../services/handlers';
import { PageInfoHeader } from '../../../PageInfoHeader/PageInfoHeader';

import { DRAFT } from './BudgetDetails/budgetConstant';
import BudgetList from './BudgetList/BudgetList';
import { GenericLegacyFilter } from './GenericLegacyFilter';

import './Budget.scss';

const Budget = () => {
  const [state, setState] = useState({
    clients: [],
    studySiteDetailsList: [],
    budgetList: [],
    budgetStatuses: {},
    newStudy: '',
    newPCN: '',
    newClient: '',
    newDescription: '',
    subheaderOptions: {
      study: true,
      client: true,
      dropDownOptions: {
        multiSelect: false,
        keepOpenOnSelection: false
      }
    }
  });

  const dispatch = useDispatch();
  const budgetList = useSelector(state => state.budgetList);

  useEffect(() => {
    dispatch(getStudyBudgets());
    FinClientApi.findByEnabled(true).then(({ data: clients }) => {
      setState(prevState => ({ ...prevState, clients }));
    }, onRequestError);

    FinStudySiteApi.allStudiesWithProtocolAssigned().then(res => {
      setState(prevState => ({ ...prevState, studySiteDetailsList: res.data }));
    });

    FinBudgetApi.statutes().then(res => {
      setState(prevState => ({ ...prevState, budgetStatuses: res.data }));
    }, onRequestError);
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(state.newStudy)) {
      const pcn = state.studySiteDetailsList.filter(item => item?.name === state.newStudy?.name);
      if (pcn.length === 1) {
        setState(prevState => ({ ...prevState, newPCN: pcn[0] }));
      }
    } else {
      setState(prevState => ({ ...prevState, newPCN: null }));
    }
  }, [state.newStudy, state.studySiteDetailsList]);

  useEffect(() => {
    if (!isEmpty(state.newPCN) && isEmpty(state.newStudy)) {
      setState(prevState => ({ ...prevState, newStudy: prevState.newPCN }));
    }
  }, [state.newPCN, state.newStudy]);

  const addNewBudget = useCallback(() => {
    const newBudget = {
      studyId: state.newStudy.id,
      finClientId: state.newClient.id,
      description: state.newDescription
    };

    if (newBudget.description.length >= 501) {
      NotificationManager.error('Budget name cannot exceed 500 characters');
      return;
    }

    if (newBudget.studyId && newBudget.finClientId && newBudget.description) {
      dispatch(addStudyBudget(newBudget));
      setState(prevState => ({ ...prevState, newDescription: '' }));
    }
  }, [dispatch, state.newClient?.id, state.newDescription, state.newStudy?.id]);

  const onStudyChange = useCallback(options => {
    setState(prevState => ({ ...prevState, newStudy: options }));
  }, []);

  const onPcnChange = useCallback(options => {
    setState(prevState => ({
      ...prevState,
      newPCN: isEmpty(options) ? null : options,
      newStudy: isEmpty(options) ? null : prevState.newStudy
    }));
  }, []);

  const clientChange = useCallback(options => {
    setState(prevState => ({ ...prevState, newClient: options }));
  }, []);

  const newDescription = useCallback(e => {
    setState(prevState => ({ ...prevState, newDescription: e.target.value }));
  }, []);

  const buttonDisabled = useMemo(() => !state.newStudy || !state.newClient || !state.newDescription, [
    state.newClient,
    state.newDescription,
    state.newStudy
  ]);

  const budgetListWithStatuses = useMemo(
    () =>
      budgetList.map(el => {
        const status = state.budgetStatuses[el.id] || DRAFT;
        return { ...el, status: status };
      }),
    [budgetList, state.budgetStatuses]
  );

  const pcnList = useMemo(() => {
    const existedPCN = state.studySiteDetailsList.filter(item => item.projectCode);
    return sortBy(
      state.newStudy ? existedPCN.filter(item => item?.name === state.newStudy?.name) : existedPCN,
      'projectCode'
    );
  }, [state.newStudy, state.studySiteDetailsList]);

  return (
    <div className="footpadb study-budget">
      <PageInfoHeader>
        {userHasAccessTo(MANAGE_BUDGETS) && (
          <section className="add-section">
            <h5>Create New Budget</h5>
            <div className="general-header-group-container general-header-wrapper">
              <Select
                label="Study"
                dataSource={state.studySiteDetailsList}
                value={state.newStudy}
                onChange={onStudyChange}
                clearable
                searchable
                data-testid="study-dropdown"
              />
              <Select
                label="PCN"
                dataSource={pcnList}
                value={state.newPCN}
                onChange={onPcnChange}
                optionLabelKey="projectCode"
                clearable
                searchable
                data-testid="pcn-dropdown"
                customSelectedValueTemplateFunction={studies =>
                  studies.map(study => (study.projectCode ? study.projectCode : 'No PCN'))
                }
              />
              <GenericLegacyFilter
                label="Client"
                list={state.clients}
                onChange={clientChange}
                clearable
                data-testid="client-dropdown"
              />
              <Input
                id="description"
                name="description"
                label="Description"
                className="description-width"
                value={state.newDescription}
                onChange={newDescription}
                validate={false}
              />
              <div>
                <Button disabled={buttonDisabled} type="button" onClick={addNewBudget} size="h56">
                  Add
                </Button>
              </div>
            </div>
          </section>
        )}
      </PageInfoHeader>

      <BudgetList budgetList={budgetListWithStatuses} clientList={state.clients} />
    </div>
  );
};

export default Budget;
