import React from 'react';
import { cloneDeep } from 'lodash/lang';
import moment from 'moment';

import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { toFormattedBill } from '../../../../../services/financial';
import { Amount } from '../../shared/amount-view/Amount/Amount';
import { MONTH_PROCESSING_FORMAT } from '../projectedRevenueConstants';

const patientType = {
  FOLLOW_UP: 'Followup Visit',
  NEW_CONSENT: 'New Consent'
};

export function prepareDataForDetails(data) {
  let rows = [];
  const months = [];

  data &&
    data.reportRows &&
    data.reportRows.forEach(reportRow => {
      const { study, site, type, subjectId, encounterName, nonProtocol, scheduled, date } = reportRow;
      const month = moment(date).format(MONTH_PROCESSING_FORMAT);

      const row = {
        study: study,
        site: site,
        type: patientType[type],
        subjectId: subjectId,
        encounterName: encounterName,
        nonProtocol: nonProtocol,
        scheduled: scheduled ? 'Yes' : 'No',
        originalDate: moment(date)
      };
      row[month] = reportRow.amount;

      if (!months.includes(month)) {
        months.push(month);
      }
      rows.push(row);
    });

  rows.sort((e1, e2) => {
    if (e1.originalDate.isAfter(e2.originalDate)) {
      return 1;
    }

    if (e1.originalDate.isBefore(e2.originalDate)) {
      return -1;
    }

    return 0;
  });
  months.sort();
  return { rows, months };
}

export function prepareDataForSummary(res, allSSUs) {
  const finalData = cloneDeep(res);
  finalData.forEach(ssu => {
    ssu.monthRevenues.forEach(month => {
      ssu[month.yearMonth] = <Amount coinsAmount={month.revenue} customCoinsFormat={toFormattedBill} />;
    });
    ssu.study = allSSUs[ssu.ssuId].study;
    ssu.site = allSSUs[ssu.ssuId].site;
    ssu.uniqueIdentifier = ssu.ssuId;
    if (ssu.errorMessage) {
      NotificationManager.warning(`${ssu.errorMessage}`);
    }
  });
  return finalData.map((a, index) => {
    a.id = index;
    return a;
  });
}

export function prepareDataForTotal(res) {
  const finalData = cloneDeep(res);
  finalData.forEach(ssu => {
    Object.keys(ssu.monthRevenues).forEach(key => {
      ssu[key] = <Amount coinsAmount={ssu.monthRevenues[key]} customCoinsFormat={toFormattedBill} />;
    });
    ssu.uniqueIdentifier = ssu.ssuId;
  });
  return finalData.map((a, index) => {
    a.id = index;
    return a;
  });
}
