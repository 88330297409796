import React from 'react';

import Button from '../../../../common/general/Button';
import { MANAGE_NON_PATIENT_APPOINTMENTS, MANAGE_PATIENT_APPOINTMENTS } from '../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../services/auth';

export function ViewAppointmentButtons({ onCancel, onEdit, event }) {
  return (
    <React.Fragment>
      {userHasAccessTo(MANAGE_PATIENT_APPOINTMENTS, MANAGE_NON_PATIENT_APPOINTMENTS) && (
        <React.Fragment>
          <div className="action-item">
            <Button priority="medium" onClick={onCancel}>
              Cancel event
            </Button>
          </div>
          <div className="action-item">
            <Button priority="medium" onClick={onEdit}>
              Edit
            </Button>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
