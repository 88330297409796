import React from 'react';

import Checkbox from '../../../../../../../../common/data-entry/InputSelectors/Checkbox';
import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { scBlue100 } from '../../../../../../../../constants/systemColors';
import { useCurrentRoute } from '../../../../../../../root/router';
import AddCustomField from '../CustomFieldsGenerator/AddCustomField';

export function FieldsHeader({
  inputs,
  updateInputsAndPreviewData,
  updateState,
  domainArray,
  changeSequenceCounter,
  sequenceCounter,
  onSelectedOnly,
  selectedOnly,
  isElligoPatientStatusDomain,
  terminologyVersionGroupNumber
}) {
  const currentRoute = useCurrentRoute();
  const protocolId = currentRoute.params.protocolIdentity;

  return (
    <div className="fields-header p-2">
      <h3 className="u-position-relative c-p mb-0" style={{ lineHeight: '28px' }}>
        Field
        {!isElligoPatientStatusDomain && (
          <div className="float-right">
            <AddCustomField
              terminologyVersionGroupNumber={terminologyVersionGroupNumber}
              domainArray={domainArray}
              inputs={inputs}
              selectedOnly={selectedOnly}
              protocolId={protocolId}
              updateInputsAndPreviewData={updateInputsAndPreviewData}
              changeSequenceCounter={changeSequenceCounter}
              sequenceCounter={sequenceCounter}
            />
          </div>
        )}
        {inputs[0]?.optional.length > 0 && (
          <React.Fragment>
            <div
              style={{ lineHeight: '26px' }}
              className="float-right mr-2"
              onClick={e => {
                e.nativeEvent.stopImmediatePropagation();
                e.stopPropagation();
              }}
            >
              <Checkbox
                color={scBlue100}
                label="All Optional Items"
                checked={!!inputs[0].allOptionalChecked}
                id="all"
                onChange={({ target }) => onOptionalAllCheck(target?.checked, inputs[0])}
              />
            </div>
            <div
              style={{ lineHeight: '26px' }}
              className="float-right mr-2"
              onClick={e => {
                e.nativeEvent.stopImmediatePropagation();
                e.stopPropagation();
              }}
            >
              <Checkbox
                color={scBlue100}
                label="Selected only"
                checked={selectedOnly}
                id="all"
                onChange={({ target }) => onSelectedOnly(target?.checked, inputs[0])}
              />
            </div>
          </React.Fragment>
        )}
      </h3>
    </div>
  );

  function uncheckAllItems(prevSt) {
    prevSt.forEach(formData => {
      formData.optional.forEach(formLabelData => {
        formLabelData.itemDefinitionList.forEach(optionalData => {
          optionalData.isChecked = false;
          optionalData.isTable = false;
        });
      });
    });
  }

  function onOptionalAllCheck(checked, formLabelData) {
    const isChecked = checked;
    if (isChecked) {
      formLabelData.allOptionalChecked = isChecked;
      inputs.forEach(formData => {
        formData.optional.forEach(formLabelData => {
          formLabelData.itemDefinitionList.forEach(optinalData => {
            optinalData.isChecked = true;
          });
        });
      });
    } else {
      const allCustomItems = inputs[0]?.custom.flatMap(e => e.customItemDefinationList) || [];
      const allOptionalItems = inputs[0]?.optional[0]?.itemDefinitionList || [];
      const customOptionsWithUsedField = allCustomItems.filter(e => e?.fieldConfigurationResponse);
      const optionalItemsWithUsedFields = allOptionalItems.filter(e => e?.fieldConfigurationResponse);
      const isOptionalFieldInProtocolItemGroupFieldConfiguration =
        customOptionsWithUsedField.length &&
        customOptionsWithUsedField.some(e => e.fieldConfigurationResponse?.conditionField?.cdashAliasName);
      const isAnyOptionalFieldHasConditionalLogic = optionalItemsWithUsedFields.length;

      if (isOptionalFieldInProtocolItemGroupFieldConfiguration || isAnyOptionalFieldHasConditionalLogic) {
        ModalBoxes.confirm({
          content: 'Deleting this toggle will remove field configurations in this Item Group. Delete anyway?',
          confirmButton: 'Yes',
          cancelButton: 'No',
          className: 'edit-custom-field-logic-modal',
          title: 'Toggle Configuration'
        }).then(() => {
          formLabelData.allOptionalChecked = isChecked;
          const allCustomItemsSequences =
            inputs[0]?.custom.flatMap(e => e.customItemDefinationList).map(e => e.sequence) || [];
          customOptionsWithUsedField.forEach(field => {
            if (!allCustomItemsSequences.includes(field.fieldConfigurationResponse.conditionFieldSequence)) {
              field.fieldConfigurationResponse = null;
            }
          });
          optionalItemsWithUsedFields.forEach(field => (field.fieldConfigurationResponse = null));
          updateState(() => {
            uncheckAllItems(inputs);
            updateInputsAndPreviewData(inputs);
          });
        });
      } else {
        formLabelData.allOptionalChecked = isChecked;
        uncheckAllItems(inputs);
      }
    }
    updateInputsAndPreviewData(inputs);
  }
}
