import React from 'react';

import { scBlue100, scGreen100, scRed100, scYellow100 } from './systemColors';

export const SUCCESS_ICON = (
  <i className="material-icons" style={{ color: scGreen100 }}>
    check_circle_outline
  </i>
);
export const WARNING_ICON = (
  <i className="material-icons-outlined" style={{ color: scYellow100 }}>
    info
  </i>
);
export const INFO_ICON = (
  <i className="material-icons-outlined" style={{ color: scBlue100 }}>
    info
  </i>
);
export const ERROR_ICON = (
  <i className="material-icons" style={{ color: scRed100 }}>
    highlight_off
  </i>
);
