import { createContext, useContext, useMemo } from 'react';

const ReviewContentElementContext = createContext(null);

export function ReviewContentLogElementProvider({ children, data, item }) {
  const { isAllowSign, permissionType, commentIsReadonly } = safeDestructureSimilar(data, item);

  const state = useMemo(
    function() {
      return {
        isAllowSign,
        permissionType,
        commentIsReadonly
      };
    },
    [isAllowSign, permissionType, commentIsReadonly]
  );
  return <ReviewContentElementProvider state={state}>{children}</ReviewContentElementProvider>;
}

export function ReviewContentProcedureElementProvider({ children, data, item }) {
  const { isAllowSign, permissionType, commentIsReadonly } = safeDestructureSimilar(data, item);

  const state = useMemo(
    function() {
      return {
        isAllowSign,
        permissionType,
        commentIsReadonly
      };
    },
    [isAllowSign, permissionType, commentIsReadonly]
  );

  return <ReviewContentElementProvider state={state}>{children}</ReviewContentElementProvider>;
}

export function ReviewContentElementProvider({ children, state }) {
  return <ReviewContentElementContext.Provider value={state}>{children}</ReviewContentElementContext.Provider>;
}

export function useReviewContentElement() {
  const context = useContext(ReviewContentElementContext);
  if (context === undefined) {
    throw new Error('useReviewContentElement must be used within a ReviewContentElementProvider');
  }
  return context;
}

function safeDestructureSimilar(data, item) {
  const isAllowSign = data?.reviewState?.isAllowSign;
  const permissionType = item?.permissionType;
  const commentIsReadonly = 'ALLOW_READ' === permissionType;
  return {
    isAllowSign,
    permissionType,
    commentIsReadonly
  };
}
