import React from 'react';
import { get } from 'lodash/object';

export function TimeZoneDetails({
  appointment,
  selectedTimeZone,
  timeZones,
  changeSelectedTimeZone,
  updateSelectedEventsDueSelectedTimeZone
}) {
  const localTimeZone = timeZones.find(timeZone => timeZone.id === 'currentTimeZoneId');
  const timeZoneName = get(selectedTimeZone, 'timeZoneNameToDisplay');

  return selectedTimeZone &&
    localTimeZone &&
    selectedTimeZone &&
    selectedTimeZone.timeZoneNameToDisplay !== localTimeZone.timeZoneNameToDisplay ? (
    <div className="time-zone-details-content">
      <div className="time-zone-details-view-field">
        <div className="primary">Schedule in {timeZoneName} time</div>
      </div>
      <div className={'normal'}>
        This appointment will be scheduled in {timeZoneName} time per the location of the
        {` ${get(selectedTimeZone, 'siteName')}`} site. Your calendar is now displaying all events in {timeZoneName}
        time.
      </div>

      {localTimeZone && (
        <div
          className="change-time-zone-link "
          onClick={() => {
            updateSelectedEventsDueSelectedTimeZone(selectedTimeZone.timeZoneId, localTimeZone.timeZoneId);
            changeSelectedTimeZone(localTimeZone);
          }}
        >
          Change to {get(localTimeZone, 'timeZoneNameToDisplay')} time.
        </div>
      )}
    </div>
  ) : null;
}
