import {
  PARENT,
  SITE_CREDIT,
  SITE_CREDIT_WH,
  SITE_DEBIT,
  SITE_DEBIT_WH,
  WITHHOLDING
} from './SitePaymentsTable/SitePaymentsConstants';

export const resolveEventSuffix = (eventNumber, eventType) => {
  switch (eventType) {
    case PARENT:
      return eventNumber;
    case WITHHOLDING:
      return eventNumber + '-WH';
    case SITE_CREDIT:
      return eventNumber + '-SC';
    case SITE_DEBIT:
      return eventNumber + '-SD';
    case SITE_CREDIT_WH:
      return eventNumber + '-SC-WH';
    case SITE_DEBIT_WH:
      return eventNumber + '-SD-WH';
    default:
      return eventNumber;
  }
};

export function eventNumberComparator(v1, v2) {
  const result = +v1.replace(/-WH$|-SC$|-SC-WH$|-SD$|-SD-WH$/, '') - +v2.replace(/-WH$|-SC$|-SC-WH$|-SD$|-SD-WH$/, '');
  return result === 0 ? v1 > v2 : result;
}
