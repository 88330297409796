import { isEqual } from 'lodash/lang';

import { PS_ANSWERED, PS_NOT_ANSWERED } from '../TableItemGroup/constants';

function changedFieldWithReasons(itemPresentInEmptyList, item, tableview) {
  const { inputType, comments, additionNotes } = itemPresentInEmptyList;
  let { performedStatus = PS_NOT_ANSWERED } = itemPresentInEmptyList;
  if (!additionNotes && performedStatus === PS_ANSWERED) {
    performedStatus = PS_NOT_ANSWERED;
  }
  const {
    attributeType,
    tableview: previousTableview,
    answerStatus,
    comments: previousComments,
    performedStatus: previousStatus,
    ...restItem
  } = item;
  return {
    attributeType: inputType === 'file' ? 'file' : 'field',
    performedStatus,
    comments,
    ...restItem,
    tableview: tableview,
    answerStatus: 1
  };
}

export function newDataForSave(forSaveForm, missingItems, tableview) {
  return forSaveForm.map(item => {
    const missingField = missingItems.find(({ elementEncounterIdentifier }) =>
      isEqual(elementEncounterIdentifier, item.elementEncounterIdentifier)
    );
    if (!!missingField) {
      return changedFieldWithReasons(missingField, item, tableview);
    } else return item;
  });
}
