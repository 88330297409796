import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import cx from 'classnames';

import Icon from '../../../../../../common/general/Icon';
import { generateUrlByKey, useCurrentRoute } from '../../../../../root/router';
import { usePatientProfile } from '../PatientProfileContext';
import { showConfirmDialog } from '../service';

import './PatientProfileSidebarTab.scss';

const iconsMap = {
  'Patient Details': 'account_circle',
  Studies: 'grid_view',
  Medications: 'local_pharmacy',
  'Medical History': 'local_hospital',
  'Patient Files': 'folder_shared'
};

export default function PatientProfileSidebarTab({ children, isPatientUnavailableToContact }) {
  const { hasUnsavedInstructions, setHasUnsavedInstructions } = usePatientProfile();
  const navigate = useNavigate();
  const currentRoute = useCurrentRoute();

  const handleNavigation = event => {
    if (hasUnsavedInstructions) {
      event.preventDefault();
      showConfirmDialog('patient instruction')
        .then(() => {
          navigate(generateUrlByKey(`${currentRoute?.parent?.key}.${children}`, currentRoute.params));
          setHasUnsavedInstructions(false);
        })
        .catch(() => {
          return;
        });
    }
  };

  return (
    <>
      {currentRoute?.parent?.name === 'Patient Profile' && (
        <NavLink
          onClick={handleNavigation}
          className={cx('patient-profile-sidebar-tab', {
            'patient-profile-sidebar-tab-selected': currentRoute.name === children
          })}
          to={generateUrlByKey(`${currentRoute?.parent?.key}.${children}`, currentRoute.params)}
        >
          <div>
            <Icon className="patient-profile-sidebar-tab-icon" suit="material-outline">
              {iconsMap[children]}
            </Icon>
            {children}
          </div>
          {isPatientUnavailableToContact && (
            <Icon className="patient-profile-sidebar-alert" suit="material-outline">
              crisis_alert
            </Icon>
          )}
        </NavLink>
      )}
    </>
  );
}
