import { parseYupValidationErrors } from 'services/yup';

import { addPatientSsuValidationSchema, addValidationSchema } from './validationSchema';

export const VALIDATION_FAILED = 'VALIDATION_FAILED';

export function validateAddPatientInfo(info, ssu) {
  return new Promise(function(resolve, reject) {
    const ssuResult = validateSsu(ssu);
    const result = validatePatientInfo(info);

    if (!ssuResult.data || !result.data) {
      reject({
        status: 'VALIDATION_FAILED',
        validationMessages: { ...ssuResult.validationMessages, ...result.validationMessages }
      });
      return;
    }

    resolve();
  });
}

export function validateEditPatientInfo(info, originalPID) {
  return new Promise(function(resolve, reject) {
    const result = validatePatientInfo(info, originalPID);

    if (!result.data) {
      reject({
        status: 'VALIDATION_FAILED',
        validationMessages: result.validationMessages
      });
      return;
    }

    resolve();
  });
}

function validateSsu(ssu) {
  try {
    return {
      data: addPatientSsuValidationSchema.validateSync(ssu, { abortEarly: false })
    };
  } catch (error) {
    return {
      validationMessages: parseYupValidationErrors(error.inner)
    };
  }
}

function validatePatientInfo(info, originalPID) {
  const { cardId, paymentType } = info;
  const financialFieldsRequired = cardId || paymentType;
  try {
    return {
      data: addValidationSchema.validateSync(info, {
        abortEarly: false,
        context: {
          originalPID,
          financialFieldsRequired
        }
      })
    };
  } catch (error) {
    return {
      validationMessages: parseYupValidationErrors(error.inner)
    };
  }
}
