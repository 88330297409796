import { SitePatientEncounterApi } from '../../../../../../../../../../api';
import Button from '../../../../../../../../../../common/general/Button';
import NotificationManager from '../../../../../../../../../../common/notifications/NotificationManager';
import { onRequestError } from '../../../../../../../../../../services/handlers';
import { useEncountersSection } from '../../../../EncountersSectionContext';
import { useEncounterSegment } from '../EncounterSegment';

export default function HeaderProjectedNoAppointments() {
  const { loadStudyNoAppointmentsEncounters } = useEncountersSection();
  const { encounter, updatePatientEncountersWithAppointments } = useEncounterSegment();
  const { projectedEncounterInfo } = encounter;
  const { ssuPatientId, protocolEncounterId } = projectedEncounterInfo;

  return (
    <Button size="h28" onClick={startEncounter}>
      Start Encounter
    </Button>
  );

  function startEncounter() {
    SitePatientEncounterApi.createNoAppointmentsEncounter(ssuPatientId, protocolEncounterId).then(() => {
      NotificationManager.success('No Appointments encounter was created');
      updatePatientEncountersWithAppointments();
      loadStudyNoAppointmentsEncounters();
    }, onRequestError);
  }
}
