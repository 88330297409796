import React from 'react';
import cx from 'classnames';
import * as PropTypes from 'prop-types';

import Loader from './Loader';

import './LoaderOverlay.scss';
export default function LoaderOverlay({ light = false, className }) {
  return (
    <div className={cx('eds-loader-overlay', className, { 'eds-loader-overlay-light': light })}>
      <Loader dark={light} />
    </div>
  );
}

LoaderOverlay.propTypes = {
  className: PropTypes.string,
  light: PropTypes.bool
};
