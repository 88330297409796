import { PROJECTED_REVENUE } from '../../../../constants/accessToPages';
import { withParams } from '../../../root/router/legacyComponentCompatability';

import ProjectedRevenue from './ProjectedRevenue';
import ProjectedRevenueDetails from './ProjectedRevenueDetails';

export const projectedRevenueRouterConfig = {
  name: 'Projected Revenue',
  path: '/projected-revenue',
  rootBreadcrumbName: 'Finance',
  component: ProjectedRevenue,
  access: [PROJECTED_REVENUE],
  inherited: [
    {
      name: 'Details',
      path: '/details/:type/:ssu',
      component: withParams(ProjectedRevenueDetails),
      breadcrumb: function({ key, params, parent }) {
        const typeMap = { consents: 'New Consents Details', visits: 'Followup Visits Details' };
        return [
          {
            path: parent.path,
            current: false,
            name: parent.name,
            key: parent.key,
            rootBreadcrumbName: parent.rootBreadcrumbName
          },
          { current: true, name: typeMap[params.type], key }
        ];
      },
      access: [PROJECTED_REVENUE]
    }
  ]
};
