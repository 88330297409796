import React from 'react';
import ReactTable from 'react-table';
import moment from 'moment';

import common from '../../../../../common/common';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { scBlue100 } from '../../../../../constants/systemColors';

const InformedConsentHistoryModal = ({ modalBox, onFileOpen, ssuId, forms }) => {
  const onClose = () => {
    modalBox.close();
  };

  const columns = [
    {
      Header: 'Name of ICF',
      accessor: 'name',
      sortable: true,
      Cell: row => {
        const { filename, formId } = row.original;
        const { name } = row.row;
        return (
          <span style={{ color: scBlue100, cursor: 'pointer' }} onClick={() => onFileOpen(filename, formId, ssuId)}>
            {name}
          </span>
        );
      }
    },
    {
      Header: 'ICF IRB Approval Date',
      accessor: 'publishDate',
      Cell: row => {
        const { publishDate } = row.row;
        return common.formatDate(publishDate, 'DD/MMM/YYYY');
      },
      sortable: true,
      sortMethod: common.dateComparator
    },
    {
      Header: 'Date Uploaded',
      accessor: 'uploadDate',
      Cell: row => {
        const { uploadDate } = row.row;
        const zonedDate = moment.tz(uploadDate, moment.tz.guess());
        return common.formatDate(zonedDate, 'DD/MMM/YYYY hh:mm a');
      },
      sortable: true,
      sortMethod: common.dateComparator
    },
    {
      Header: 'Version',
      accessor: 'version',
      Cell: row => {
        const { version } = row.row;
        return version ? <span className="cursor-pointer"> {version} </span> : '--';
      }
    }
  ];

  return (
    <>
      <ModalBoxes.Header onClose={onClose}>Forms History</ModalBoxes.Header>
      <ModalBoxes.Body>
        <div className="col-12 border px-0 site-info-table">
          <ReactTable
            data={forms}
            columns={columns}
            minRows={1}
            multiSort
            className="table activity-table table-responsive-sm m-0 "
            showPagination
            nextText=">>"
            previousText="<<"
            defaultPageSize={5}
            noDataText="No Informed Consent Items Found"
            sortable
          />
        </div>
      </ModalBoxes.Body>
    </>
  );
};

export default InformedConsentHistoryModal;
