import React, { useCallback } from 'react';

import TextArea from '../../../../../../../common/data-entry/TextArea';

export default function ChangeReason({ updateReason, field }) {
  const uid = field?.uid,
    reason = field?.reason;

  const onChangeReason = useCallback(
    ({ target: { value } }) => {
      updateReason(value, uid);
    },
    [updateReason, uid]
  );

  return (
    <div className="pre-save-items-modal-change-reason">
      <TextArea
        style={{ resize: 'none' }}
        rows={1}
        clearable
        label="Reason"
        name="reason"
        value={reason}
        onChange={onChangeReason}
      />
    </div>
  );
}
