import { assign } from 'lodash/object';

import { defaultAddress } from '../../../services';

export default function setAddressType(state, { type, payload: { path, value } }) {
  return {
    ...state,
    mileageAddresses: {
      ...state.mileageAddresses,
      [path]: {
        ...state.mileageAddresses[path],
        ...prepareValue(state.ssuPatientDetails, path, value)
      }
    }
  };
}

function prepareValue(ssuPatientDetails, path, value) {
  const obj = {
    type: value
  };

  if (value === 'PATIENT_HOME_ADDRESS' && ssuPatientDetails.patientAddress) {
    assign(obj, ssuPatientDetails.patientAddress);
  }
  if (value === 'SITE_ADDRESS' && ssuPatientDetails.siteAddress) {
    assign(obj, ssuPatientDetails.siteAddress);
  }
  if (value === 'OTHER') {
    assign(obj, defaultAddress);
  }

  return obj;
}
