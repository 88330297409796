import React from 'react';
import moment from 'moment';
import * as PropTypes from 'prop-types';

import { DD_SLASH_MMM_SLASH_YYYY } from '../../constants/dateFormat';

export function CellFormattedDate(props) {
  const { date, className, format, defaultValue, defaultValueClassName, toUpper } = props;
  return date ? (
    <span className={className}>
      {toUpper
        ? moment(date)
            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
            .format(format)
            .toUpperCase()
        : moment(date).format(format)}
    </span>
  ) : (
    <span className={defaultValueClassName}>{defaultValue}</span>
  );
}

CellFormattedDate.propTypes = {
  date: PropTypes.instanceOf(Date),
  className: PropTypes.string,
  format: PropTypes.string,
  defaultValue: PropTypes.string,
  defaultValueClassName: PropTypes.string,
  toUpper: PropTypes.bool
};

CellFormattedDate.defaultProps = {
  format: DD_SLASH_MMM_SLASH_YYYY,
  defaultValue: '',
  toUpper: false
};
