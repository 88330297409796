import cx from 'classnames';

import './HighlightForReviewIcon.scss';

export function HighlightForReviewIcon({ highlighted, confirmed }) {
  return (
    <span
      className={cx('erv-highlight-for-review', {
        'erv-highlight-for-review-highlighted': highlighted && !confirmed,
        'erv-highlight-for-review-confirmed': highlighted && confirmed
      })}
    />
  );
}
