import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Card } from '@mui/material';
import { isEmpty } from 'lodash/lang';
import moment from 'moment/moment';
import printJS from 'print-js';

import { FinInvoiceApi } from '../../../../../../api';
import { FileViewerContext } from '../../../../../../contexts/FileViewerContext';
import { onRequestError } from '../../../../../../services/handlers';
import { Amount } from '../../../shared/amount-view/Amount/Amount';
import PaymentStatus from '../../InvoiceTable/PaymentStatus/PaymentStatus';
import { downloadFile, generateEmailPDF, openFile } from '../CreateInvoiceServices';

import { EmailDetailsPDFTemplate } from './EmailDetailsModal/EmailDetailsPDFTemplate/EmailDetailsPDFTemplate';
import { HistoryItemIcon } from './HistoryItemIcon/HistoryItemIcon';
import { HistoryItemMenu } from './HistoryItemMenu/HistoryItemMenu';

import './InvoiceHistory.scss';

export const InvoiceHistory = ({
  invoiceNumber,
  invoiceNumberForDisplay,
  emailModalBoxHeader,
  currentInvoiceType,
  invoiceId,
  billToList
}) => {
  const emailTemplateRef = useRef(null);
  const [emailDetailsAction, setEmailDetailsAction] = useState({});

  const [emailData, setEmailData] = useState({});

  const [invoiceHistory, setInvoiceHistory] = useState([]);
  const fileViewer = useContext(FileViewerContext);

  const resolveActionAfterGenerate = useCallback(
    file => {
      if (emailDetailsAction?.action === 'DOWNLOAD') {
        downloadFile(file, 'EmailDetails.pdf');
      } else {
        printJS({ printable: file.split(',')[1], type: 'pdf', base64: true });
      }
    },
    [emailDetailsAction?.action]
  );

  useEffect(() => {
    if (emailDetailsAction?.emailId) {
      FinInvoiceApi.getInvoiceEmail(emailDetailsAction?.emailId).then(({ data }) => {
        setEmailData(data);
      }, onRequestError);
    }
  }, [emailDetailsAction?.emailId]);

  useEffect(() => {
    if (!emailTemplateRef.current || !emailDetailsAction?.emailId || !emailDetailsAction?.action) {
      return;
    }
    const links = emailTemplateRef.current.querySelectorAll('.file-link');
    const linkAreas = [...links].map(({ offsetHeight, offsetWidth, offsetTop, offsetLeft }) => ({
      height: offsetHeight,
      width: offsetWidth,
      y: offsetTop + 20,
      x: offsetLeft
    }));
    generateEmailPDF(emailTemplateRef, file => resolveActionAfterGenerate(file), emailData?.attachments, linkAreas);
    setEmailDetailsAction({});
    setEmailData({});
  }, [emailData?.attachments, emailDetailsAction?.action, emailDetailsAction?.emailId, resolveActionAfterGenerate]);

  useEffect(() => {
    FinInvoiceApi.getInvoiceHistory(invoiceId).then(({ data }) => {
      setInvoiceHistory(data);
    }, onRequestError);
  }, [invoiceId]);

  return (
    <>
      <div className="invoice-history-header">Invoice No. {invoiceNumberForDisplay}</div>
      {invoiceHistory.map((historyItem, index) => (
        <Card sx={{ padding: '16px', color: '#6E6E6E' }} key={historyItem.type + index}>
          <div className="invoice-card-header">
            <div className="invoice-card-name">
              <HistoryItemIcon type={historyItem.type} /> by {historyItem.createdBy}{' '}
              {moment(historyItem.createdAt).format('DD/MMM/YYYY')}
            </div>
            <div className="invoice-card-status">
              <PaymentStatus hasInvoiceNumber status={historyItem.varianceStatus} />
              <Amount coinsAmount={historyItem.varianceAmount} showDollarSign />
              <HistoryItemMenu
                historyItem={historyItem}
                setEmailDetailsAction={setEmailDetailsAction}
                invoiceNumber={invoiceNumber}
                emailModalBoxHeader={emailModalBoxHeader}
                currentInvoiceType={currentInvoiceType}
                billToList={billToList}
                setInvoiceHistory={setInvoiceHistory}
                invoiceId={invoiceId}
              />
            </div>
          </div>
          {historyItem.attachments.map(file => (
            <div
              className="attachment-wrapper"
              onClick={() => openFile(historyItem.type, file.fileId, fileViewer)}
              key={file.fileId}
            >
              <AttachFileIcon /> <div className="attachment-filename">{file.fileName}</div>
            </div>
          ))}
        </Card>
      ))}
      {!isEmpty(emailData) && (
        <div className="email-template-for-generate-pdf">
          <EmailDetailsPDFTemplate emailData={emailData} filePreview={() => {}} ref={emailTemplateRef} />
        </div>
      )}
    </>
  );
};
