import { NON_PATIENT_EVENT_TYPE } from './NonPatientEventType';

export function getStudySiteValidationRulesByType(type) {
  return STUDY_SITE_VALIDATION_RULES.get(type) || DEFAULT_STUDY_SITE_VALIDATION_RULES;
}
const SSU_REQUIRED = {
  isStudyRequired: true,
  isSiteRequired: true
};

const SITE_REQUIRED = {
  isStudyRequired: false,
  isSiteRequired: true
};
const DEFAULT_STUDY_SITE_VALIDATION_RULES = SSU_REQUIRED;

const STUDY_SITE_VALIDATION_RULES = new Map();

STUDY_SITE_VALIDATION_RULES.set(NON_PATIENT_EVENT_TYPE.IMV, SSU_REQUIRED);

STUDY_SITE_VALIDATION_RULES.set(NON_PATIENT_EVENT_TYPE.RMV, SSU_REQUIRED);

STUDY_SITE_VALIDATION_RULES.set(NON_PATIENT_EVENT_TYPE.SMV, SSU_REQUIRED);

STUDY_SITE_VALIDATION_RULES.set(NON_PATIENT_EVENT_TYPE.PSV, SSU_REQUIRED);

STUDY_SITE_VALIDATION_RULES.set(NON_PATIENT_EVENT_TYPE.SIV, SSU_REQUIRED);

STUDY_SITE_VALIDATION_RULES.set(NON_PATIENT_EVENT_TYPE.IM, SSU_REQUIRED);

STUDY_SITE_VALIDATION_RULES.set(NON_PATIENT_EVENT_TYPE.CLOSE_OUT_VISIT, SSU_REQUIRED);

STUDY_SITE_VALIDATION_RULES.set(NON_PATIENT_EVENT_TYPE.PHONE, SITE_REQUIRED);

STUDY_SITE_VALIDATION_RULES.set(NON_PATIENT_EVENT_TYPE.OUT_OF_OFFICE, SITE_REQUIRED);

STUDY_SITE_VALIDATION_RULES.set(NON_PATIENT_EVENT_TYPE.OTHER, SITE_REQUIRED);
