import axios from 'axios';

const ROOT = 'api/v1/preferences/configs';

const hideLoader = {
  hideLoader: true
};

const UserNotificationPreferencesConfigApi = {
  getAllUserNotificationPreferences(userId) {
    return axios.get(`${ROOT}/users/${userId}`, hideLoader);
  },
  changeSingleUserNotificationConfigState(userId, data) {
    return axios.post(`${ROOT}/users/${userId}/single-option`, data, hideLoader);
  }
};

export default UserNotificationPreferencesConfigApi;
