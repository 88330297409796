import React, { useEffect, useMemo, useState } from 'react';

import { FormBuilderApi } from '../../../../../../api';
import common from '../../../../../../common/common';
import Input from '../../../../../../common/data-entry/Input';
import Select from '../../../../../../common/data-entry/Select';
import { onRequestDefaultError } from '../../../../../../services/handlers';

import TerminologyDropdown from './TerminologyDropdown';

export function ItemGroupDomainConfiguration({
  editMode,
  isItemGroupTemplate,
  isItemGroupTemplateSetup,
  isTemplateCopy,
  elementName,
  iconList,
  initDomainValue,
  initElementIcon,
  onDataTypeChange,
  onChangeText,
  onChangeElementName,
  updateTerminologyVersion,
  terminologyVersionGroupNumber,
  isGroupAssign
}) {
  const [domainList, setDomainList] = useState({});
  useEffect(
    function() {
      FormBuilderApi.getAllStandardDomains().then(({ data: { response } }) => {
        if (isGroupAssign) {
          setDomainList(response.filter(domain => !['EREVSM', 'EREVPI'].includes(domain.domainCode)));
        } else {
          setDomainList(response);
        }
      }, onRequestDefaultError);
    },
    [setDomainList, isGroupAssign]
  );
  const disabledAllTheFields = useMemo(
    function() {
      return isItemGroupTemplate && !terminologyVersionGroupNumber;
    },
    [isItemGroupTemplate, terminologyVersionGroupNumber]
  );

  const domainName = 'domainName';
  const domainIdentifier = 'domainIdentifier';

  return (
    <div className="general-header-group-container general-header-wrapper">
      {isItemGroupTemplate && (
        <TerminologyDropdown
          terminologyVersionGroupNumber={terminologyVersionGroupNumber}
          updateTerminologyVersion={updateTerminologyVersion}
          editMode={(!isTemplateCopy && editMode) || disabledAllTheFields}
        />
      )}
      <Select
        clearSearchOnSelection
        dataSource={domainList}
        label="ItemGroup Domain"
        optionLabelKey={domainName}
        optionValueKey={domainIdentifier}
        onChange={option => onDataTypeChange(option, 'domain')}
        searchable
        keepOpenOnSelection={false}
        closeOnSelectedOptionClick
        deselectOnSelectedOptionClick={false}
        disabled={isItemGroupTemplateSetup || editMode || disabledAllTheFields}
        validate={false}
        customSelectedValueTemplateFunction={item =>
          customSelectedValueTemplateFunction(item, domainName, domainIdentifier)
        }
        customOptionTemplateFunction={customOptionTemplateFunction}
        clearable={false}
        value={initDomainValue}
        required
      />
      <Select
        clearSearchOnSelection
        label="ItemGroup Type"
        dataSource={iconList}
        onChange={option => onChangeText(option, 'icon')}
        searchable
        validate={false}
        disabled={isItemGroupTemplateSetup || editMode || disabledAllTheFields}
        customSelectedValueTemplateFunction={customSelectedValueTemplateFunction}
        clearable={false}
        value={initElementIcon}
        required
      />
      <Input
        label={isItemGroupTemplate ? 'Template Name' : 'ItemGroup Name'}
        type="text"
        name="elementName"
        validate={false}
        value={elementName}
        onChange={onChangeElementName}
        disabled={disabledAllTheFields}
        required
      />
    </div>
  );

  function customSelectedValueTemplateFunction(selectedItems, name = 'name', id = 'id') {
    return (
      <div className="selected-dropdown-items">
        {selectedItems.map(item => {
          return (
            <span key={item[id] ? item[id] : common.getRandomNumber()}>
              {item[name]}
              {selectedItems.length > 1 ? ',' : ''}
            </span>
          );
        })}
      </div>
    );
  }
  function customOptionTemplateFunction(item) {
    return <span title={item.domainName}>{item.domainName}</span>;
  }
}
