import React, { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash/lang';
import { get } from 'lodash/object';

import SituationalEncounterApi from '../../../../api/calendar/SituationalEncounterApi';
import Input from '../../../../common/data-entry/Input';
import Select from '../../../../common/data-entry/Select';
import useAppInfo from '../../../../common/hooks/useAppInfo';
import TextArea from '../../../../common/inputs/TextArea';
import { getRoleDescriptionByUserRoles } from '../../../../services/personnelService';
import { useCurrentUser } from '../../../root/Container/CurrentUserContainer';
import { AppointmentUserInfo, UserMultiSelect } from '../../../UserMultiSelect/UserMultiSelect';
import { PATIENT_SITUATIONAL_ENCOUNTER_EVENT } from '../CalendarEventType';
import { getAppointmentWithFixedTimeZone } from '../CalendarTimeZoneService';
import { isDraft } from '../DraftEventProvider';
import { fromDialogAppointment, toDialogAppointment } from '../EventTransformer';
import { ParticipantsProviderPatientEncounter } from '../form-components/ParticipantsProviderPatientEncounter';
import { PatientSelector } from '../form-components/PatientSelector';
import { StudySelector } from '../form-components/StudySelector';
import { refreshParticipants } from '../PatientAppointmentEditService';
import TimeDurationPicker from '../TimeDurationPickerGeneral/TimeDurationPicker';

import PatientSituationalAppointmentValidator from './PatientSituationalAppointmentValidator';

function makeSituationalEncounter({
  id,
  eventId,
  calendarIndex,
  patient,
  encounter,
  sitePatientId,
  study,
  siteName,
  timeDuration,
  comment,
  participants,
  color,
  organizer
}) {
  return {
    id,
    eventId,
    encounter,
    calendarIndex,
    subject: 'Situational: ' + (encounter ? encounter?.name : ''),
    timeDuration,
    comment,
    patient,
    sitePatientId,
    study,
    siteName,
    participants,
    color,
    organizer,
    type: PATIENT_SITUATIONAL_ENCOUNTER_EVENT
  };
}

export function PatientSituationalAppointmentEdit({
  initialAppointment = {},
  appointmentChanged,
  children,
  timeZones,
  selectedTimeZone,
  changeSelectedTimeZone,
  eventId,
  errorHandleCallback
}) {
  const appInfo = useAppInfo();
  const loadedStudyId = useRef(null);
  const patientSituationalAppointmentValidator = new PatientSituationalAppointmentValidator();
  useEffect(() => {
    const dialogAppointment = toDialogAppointment(initialAppointment);
    const appointment = makeSituationalEncounter(dialogAppointment);
    setAppointment(appointment);
  }, [initialAppointment]);

  const [appointment, setAppointment] = useState(toDialogAppointment(initialAppointment));
  const currentUser = useCurrentUser();
  const personnelIdentifier = currentUser.personnelIdentifier,
    roleDescription = getRoleDescriptionByUserRoles(currentUser.roles);

  const [validation, setValidation] = useState({
    patient: {},
    study: {},
    timeDuration: {},
    encounter: {}
  });
  const [encounters, setEncounters] = useState([]);

  const fieldChange = fieldName => newValue => {
    const oldValue = get(appointment, fieldName);
    if (oldValue === newValue) {
      return;
    }
    setAppointment(appointment => {
      const newAppointment = { ...appointment, [fieldName]: newValue };
      const newValidation = patientSituationalAppointmentValidator.fieldValidation(
        newAppointment,
        validation,
        fieldName
      );
      setValidation(newValidation);
      appointmentChanged(fromDialogAppointment(newAppointment));
      return newAppointment;
    });
  };

  const changePatient = fieldChange('patient');
  const changeEncounter = fieldChange('encounter');
  const changeStudy = fieldChange('study');
  const changeOrganizer = fieldChange('organizer');
  const changeTimeDuration = fieldChange('timeDuration');
  const changeSitePatientId = fieldChange('sitePatientId');
  const changeParticipants = fieldChange('participants');
  const changeComment = fieldChange('comment');

  const getSiteName = appointment =>
    appointment.patient?.studySites?.find(ssu => ssu.study.id === appointment?.study?.id)?.site?.name;

  const getSiteId = appointment =>
    appointment.patient?.studySites?.find(ssu => ssu.study.id === appointment?.study?.id)?.site?.id;

  const siteId = getSiteId(appointment);

  useEffect(() => {
    const timezoneForSelectedStudy = timeZones?.filter(e => e.id === siteId)[0];
    if (
      siteId &&
      appointment.timeDuration &&
      timezoneForSelectedStudy &&
      selectedTimeZone.timeZoneId !== timezoneForSelectedStudy.timeZoneId
    ) {
      const updatedAppointment = getAppointmentWithFixedTimeZone(
        appointment,
        selectedTimeZone.timeZoneId,
        timezoneForSelectedStudy.timeZoneId,
        timeZones[0].timeZoneId
      );
      setAppointment(updatedAppointment);
      appointmentChanged(fromDialogAppointment(updatedAppointment));
      changeSelectedTimeZone(timezoneForSelectedStudy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId]);

  useEffect(() => {
    changeSitePatientId(appointment.study?.sitePatientId);
    // adding changeSitePatientId to dependants leads to blinking of form fields
    // eslint-disable-next-line
  }, [appointment.study?.id]);

  const validateBefore = saveHandler => () => {
    const newValidation = patientSituationalAppointmentValidator.formValidation(appointment, validation);
    setValidation(newValidation);

    if (patientSituationalAppointmentValidator.isValid(appointment)) {
      return saveHandler(fromDialogAppointment(appointment));
    }
  };

  const sitePatientId = appointment?.study?.sitePatientId;
  const isAppointmentDraft = isDraft(appointment);
  const encounterId = appointment.encounter?.id;
  const studyId = appointment.study?.id;
  const studyName = appointment.study?.name;

  useEffect(() => {
    if (!studyId || studyId === loadedStudyId.current) return;
    loadedStudyId.current = studyId;
    SituationalEncounterApi.getSituationalEncounterByStudyId(studyId).then(res => {
      if (isEmpty(res.data)) {
        errorHandleCallback(studyName);
        return;
      }
      setEncounters(res.data);
    });
  }, [errorHandleCallback, studyId, studyName]);

  useEffect(() => {
    refreshParticipants(personnelIdentifier, appointment, changeParticipants, true);
    // adding changeParticipants to dependants leads to blinking of form fields
    // eslint-disable-next-line
  }, [sitePatientId, encounterId, isAppointmentDraft, personnelIdentifier]);

  const previousAppointmentStart = useRef(appointment.timeDuration.start);

  useEffect(() => {
    if (
      !previousAppointmentStart.current
        .clone()
        .startOf('day')
        .isSame(appointment.timeDuration.start.clone().startOf('day'))
    ) {
      changeOrganizer({
        userId: personnelIdentifier,
        type: 'USER',
        label: roleDescription
      });
      previousAppointmentStart.current = appointment.timeDuration.start;
    }
    // adding changeOrganizer to dependants leads to blinking of form fields
    // eslint-disable-next-line
  }, [appointment.timeDuration.start, personnelIdentifier, roleDescription]);

  const composeButtonBar = () => {
    if (children) {
      return React.Children.map(children, child => {
        return React.cloneElement(child, {
          ...child.props,
          onSave: validateBefore(child.props.onSave),
          onEdit: () => child.props.onEdit(appointment)
        });
      });
    }
  };
  if (appInfo?.features?.situationalEncountersEnabled) {
    return (
      <React.Fragment>
        <PatientSelector
          onPatientSelected={changePatient}
          initialPatient={appointment.patient}
          patientValidationMessage={validation.patient.validationMessage}
          appointmentType={get(appointment, 'type')}
        />
        <StudySelector
          onStudySelected={changeStudy}
          initialStudy={appointment.study}
          disabled={eventId}
          patientId={get(appointment, 'patient.patientId')}
          studyValidationMessage={validation.study.validationMessage}
          isUnexpected={true}
        />
        <Input label={'Site'} value={getSiteName(appointment)} disabled />
        <Select
          controlId={'encounter-selector'}
          label={'Encounter'}
          dataSource={encounters}
          onChange={changeEncounter}
          optionLabelKey="name"
          optionValueKey="key"
          value={appointment?.encounter}
          clearable={!!appointment?.study}
          required
          validationMessage={validation.encounter.validationMessage}
          disabled={!appointment?.study}
        />
        <TimeDurationPicker
          initialTime={appointment.timeDuration}
          onTimeDurationChanged={changeTimeDuration}
          validationMessage={validation.timeDuration.validationMessage}
        />
        <ParticipantsProviderPatientEncounter initialPatientId={get(appointment, 'sitePatientId')}>
          <UserMultiSelect
            onChange={changeParticipants}
            initialUsers={appointment.participants}
            organizerUser={get(appointment, 'organizer')}
            className="mb-2"
            optionValueKey="userId"
            label="Add Attendees"
            customOptionTemplateFunction={AppointmentUserInfo}
          />
        </ParticipantsProviderPatientEncounter>
        <TextArea
          id={'comment-input'}
          name={'comment'}
          label={'Comment'}
          value={appointment.comment}
          onChange={changeComment}
        />
        <div className="action-buttons">{composeButtonBar()}</div>
      </React.Fragment>
    );
  } else {
    return <></>;
  }
}
