import React, { useCallback, useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { isEmpty, sortBy } from 'lodash';
import { isEqual } from 'lodash/lang';

import { ProtocolApi, StudyApi } from '../../../api';
import { afterburnerApi, afterburnerApi as AftrburnerApi } from '../../../api/patient/AftrburnerApi';
import Common from '../../../common/common';
import Section from '../../../common/data-display/Section/Section';
import SearchInput from '../../../common/data-entry/Input/SearchInput/SearchInput';
import Select from '../../../common/data-entry/Select';
import ModalBoxes from '../../../common/feedback/ModalBoxes/ModalBoxes';
import useSessionStorage from '../../../common/hooks/useSessionStorage';
import NotificationManager from '../../../common/notifications/NotificationManager';
import { SOMETHING_WENT_WRONG } from '../../../constants/notificationMessages';
import { PageInfoHeader } from '../../PageInfoHeader/PageInfoHeader';

import AdditionalMappingInfoModal from './AdditionalMappingInfoModal';
const AFTERBURNER_LOG_TABLE_DATA = 'AFTERBURNER_LOG_TABLE_DATA';
const AfterburnerLog = () => {
  const [sessionStorage, setSessionStorage] = useSessionStorage(AFTERBURNER_LOG_TABLE_DATA, {});
  const [totalElementsCount, setTotalElementsCount] = useState(0);
  const [studyList, setStudyList] = useState([]);
  const [protocolList, setProtocolList] = useState([]);
  const [encounterList, setEncounterList] = useState([]);
  const [platformList, setPlatformList] = useState([]);
  const [requestLogList, setRequestLogList] = useState([]);

  const [filters, setFilters] = useState({
    study: null,
    protocol: null,
    platform: null,
    encounter: null,
    searchString: null,
    sorting: [{ id: 'platformName', desc: false }],
    page: 0,
    pageSize: sessionStorage.pageSize || 15,
    totalPages: 0
  });

  const loadLogs = useCallback(() => {
    if (filters.study || filters.protocol || filters.encounter || filters.platform || filters.searchString) {
      const filtersForRequest = {
        studyId: filters.study?.id ?? null,
        protocolId: filters.protocol?.id ?? null,
        encounterId: filters.encounter?.id ?? null,
        platformName: filters.platform?.name ?? null,
        searchString: filters.searchString ?? null,
        sortingDesc: filters.sorting[0].desc,
        sortingId: filters.sorting[0].id,
        page: filters.page,
        pageSize: filters.pageSize
      };
      if (filtersForRequest.searchString === null || filtersForRequest.searchString.length >= 3)
        afterburnerApi
          .getRequestLogsByIds(filtersForRequest)
          .then(({ data }) => {
            setRequestLogList(data.content);
            setTotalElementsCount(data.totalElements);
            setFilters(prevState => ({ ...prevState, totalPages: data.totalPages }));
          })
          .catch(() => {
            NotificationManager.error(SOMETHING_WENT_WRONG);
          });
    } else {
      setRequestLogList([]);
      setTotalElementsCount(0);
      setFilters(prevState => ({ ...prevState, totalPages: 0 }));
    }
  }, [
    filters.study,
    filters.protocol,
    filters.encounter,
    filters.platform,
    filters.searchString,
    filters.sorting,
    filters.page,
    filters.pageSize
  ]);

  useEffect(() => {
    StudyApi.populateStudyList()
      .then(({ data: { response } }) => {
        const preparedStudies = response.map(d => ({
          id: d.uniqueIdentifier,
          name: d.studyName
        }));
        setStudyList(sortBy(preparedStudies, ['name']));
      })
      .catch(() => {
        NotificationManager.error(SOMETHING_WENT_WRONG);
      });
  }, []);

  useEffect(() => {
    if (filters.study?.id) {
      ProtocolApi.getProtocols(filters.study.id).then(
        ({ data: { response } }) => {
          const dataForMap = response === 'No Protocol found' ? [] : response;
          const preparedProtocolList = dataForMap
            .filter(protocol => protocol.status !== 'Draft')
            .map(protocol => ({
              id: protocol.uniqueIdentifier,
              name: `${protocol.name} ${protocol.version}`
            }));
          setProtocolList(preparedProtocolList);
        },
        () => {
          NotificationManager.error(SOMETHING_WENT_WRONG);
        }
      );
      setFilters(prevState => ({ ...prevState, protocol: null }));
    }
  }, [filters.study]);

  useEffect(() => {
    if (filters.study?.id && filters.protocol?.id) {
      afterburnerApi
        .getEncounterNamesInLogs(filters.study.id, filters.protocol.id)
        .then(({ data }) => {
          const preparedEncounters = data.map(d => ({
            id: d.id,
            name: d.name
          }));
          setEncounterList(sortBy(preparedEncounters, ['name']));
        })
        .catch(() => {
          NotificationManager.error(SOMETHING_WENT_WRONG);
        });
    }
  }, [filters.study, filters.protocol]);

  useEffect(() => {
    if (
      filters.study ||
      filters.protocol ||
      filters.encounter ||
      filters.searchString?.length > 3 ||
      (!filters.study && !filters.protocol)
    ) {
      const filtersForRequest = {
        studyId: filters.study?.id ?? null,
        protocolId: filters.protocol?.id ?? null,
        encounterId: filters.encounter?.id ?? null,
        searchString: filters.searchString ?? null
      };
      if (filtersForRequest.searchString === null || filtersForRequest.searchString.length >= 3) {
        AftrburnerApi.getPlatformNamesByFilters(filtersForRequest).then(
          ({ data }) => {
            const preparedPlatformList = data.map(platform => ({
              id: platform,
              name: platform
            }));
            setPlatformList(sortBy(preparedPlatformList, ['name']));
          },
          () => {
            NotificationManager.error(SOMETHING_WENT_WRONG);
          }
        );
      }
    }
  }, [filters.encounter, filters.protocol, filters.searchString, filters.study]);

  useEffect(() => {
    loadLogs();
  }, [loadLogs]);

  const columns = [
    {
      Header: 'Study Name',
      accessor: 'studyName',
      minWidth: 200,
      Cell: row => {
        return resolveCellValue(row.original?.studyName);
      }
    },
    {
      Header: 'Protocol Version',
      accessor: 'protocolVersion',
      minWidth: 200,
      Cell: row => {
        return resolveCellValue(row.original?.protocolVersion);
      }
    },
    {
      Header: 'External Platform Name',
      accessor: 'platformName',
      minWidth: 200,
      Cell: row => {
        return resolveCellValue(row.original?.platformName);
      }
    },
    {
      Header: 'Patient',
      accessor: 'patientFullName',
      minWidth: 200,
      Cell: row => {
        return resolveCellValue(row.original?.patientFullName);
      }
    },
    {
      Header: 'Patient ID',
      accessor: 'patientId',
      minWidth: 200,
      Cell: row => {
        return resolveCellValue(row.original?.patientId);
      }
    },
    {
      Header: 'Date Sent',
      accessor: 'dateSent',
      minWidth: 200,
      Cell: row => {
        return resolveCellValue(Common.formatDate(row.original?.dateSent, 'DD/MMM/YYYY hh:mm A'));
      }
    },
    {
      Header: 'Retry Count',
      accessor: 'retryCount',
      minWidth: 200,
      Cell: row => {
        return resolveCellValue(row.original?.retryCount.toString());
      }
    },
    {
      Header: 'Response Status',
      accessor: 'responseStatus',
      minWidth: 200,
      Cell: row => {
        return resolveCellValue(row.original?.responseStatus);
      }
    }
  ];

  const onPageSizeChange = useCallback(
    pageSize => {
      let newFilters = { ...filters, pageSize };
      if (filters.page * pageSize > totalElementsCount) {
        newFilters = { ...newFilters, page: 0 };
      }
      setFilters(newFilters);
      setSessionStorage({ pageSize: pageSize });
    },
    [filters, setSessionStorage, totalElementsCount]
  );

  const onPageChange = useCallback(page => {
    setFilters(prevState => ({ ...prevState, page: page >= 0 ? page : 0 }));
  }, []);

  const onSortedChange = useCallback(sortedBy => {
    setFilters(prevState => ({ ...prevState, sorting: sortedBy }));
  }, []);

  const onTableFilterChange = useCallback(
    (newValue, keyForUpdate) => {
      if (!isEqual(newValue, filters[keyForUpdate])) {
        if (!newValue) {
          setFilters(prevState => ({ ...prevState, [keyForUpdate]: null, page: 0 }));
        } else {
          setFilters(prevState => ({ ...prevState, [keyForUpdate]: newValue, page: 0 }));
        }
      }
    },
    [filters]
  );

  return (
    <>
      <PageInfoHeader>
        <div className="general-header-wrapper">
          <Select
            dataSource={studyList}
            value={filters.study}
            onChange={newValue => {
              if (!isEqual(newValue, filters.study)) {
                if (!newValue) {
                  setProtocolList([]);
                  setFilters(prevState => ({
                    ...prevState,
                    study: null,
                    protocol: null,
                    encounter: null,
                    platform: null,
                    page: 0
                  }));
                } else {
                  setFilters(prevState => ({ ...prevState, study: newValue, protocol: null, platform: null, page: 0 }));
                }
              }
            }}
            label="Study"
            searchable={true}
            closeOnSelectedOptionClick={false}
            deselectOnSelectedOptionClick={false}
            clearable={true}
            validate={false}
          />
          <Select
            dataSource={protocolList}
            value={filters.protocol}
            onChange={newValue => {
              if (!isEqual(newValue, filters.protocol)) {
                if (!newValue) {
                  setEncounterList([]);
                  setFilters(prevState => ({ ...prevState, protocol: null, encounter: null, page: 0 }));
                } else {
                  setFilters(prevState => ({ ...prevState, protocol: newValue, encounter: null, page: 0 }));
                }
              }
            }}
            label="Protocol"
            searchable={true}
            closeOnSelectedOptionClick={false}
            deselectOnSelectedOptionClick={false}
            clearable={true}
            validate={false}
          />
          <Select
            dataSource={encounterList}
            value={filters.encounter}
            onChange={newValue => {
              if (!isEqual(newValue, filters.encounter)) {
                if (!newValue) {
                  setFilters(prevState => ({ ...prevState, encounter: null, platform: null, page: 0 }));
                } else {
                  setFilters(prevState => ({ ...prevState, encounter: newValue, platform: null, page: 0 }));
                }
              }
            }}
            label="Encounter"
            searchable={true}
            closeOnSelectedOptionClick={false}
            deselectOnSelectedOptionClick={false}
            clearable={true}
            validate={false}
          />
          <Select
            dataSource={platformList}
            value={filters.platform}
            onChange={newValue => {
              onTableFilterChange(newValue, 'platform');
            }}
            label="Platform"
            searchable={true}
            closeOnSelectedOptionClick={false}
            deselectOnSelectedOptionClick={false}
            clearable={true}
            validate={false}
          />
          <SearchInput
            value={filters.searchString}
            label="Patient"
            onChange={({ target: { value } }) => {
              if (isEmpty(value)) {
                setFilters(prevState => ({ ...prevState, searchString: null, page: 0 }));
              } else {
                if (!isEqual(value, filters.searchString)) {
                  setFilters(prevState => ({ ...prevState, searchString: value, page: 0 }));
                }
              }
            }}
          />
        </div>
      </PageInfoHeader>

      <Section>
        <ReactTable
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          onSortedChange={onSortedChange}
          data={requestLogList}
          columns={columns}
          minRows={1}
          multiSort={false}
          showPagination
          nextText=">>"
          previousText="<<"
          noDataText="No Record Found"
          pageSizeOptions={[15, 25, 50, 100]}
          pageSize={filters.pageSize}
          page={filters.page}
          pages={filters.totalPages}
          sorted={filters.sorting}
          manual
          getTrProps={(data, row = {}) => ({
            onClick: () => {
              ModalBoxes.open({
                component: <AdditionalMappingInfoModal logId={row.original?.logId} data={row} />,
                title: 'Afterburner Log Details',
                className: 'addition-mapping-info-modal',
                size: 'w1250'
              });
            }
          })}
        />
      </Section>
    </>
  );
};

const resolveCellValue = value => <span> {value ? value : '--'}</span>;

export default AfterburnerLog;
