import cloneDeep from 'lodash/cloneDeep';
import { isEmpty, isString } from 'lodash/lang';

import { FormBuilderApi } from '../../../../../api';
import common from '../../../../../common/common';
import {
  DATA_COLLECTED,
  NO_DATA_TO_COLLECT,
  NOT_PERFORMED
} from '../../../patient-source/shared/EncounterDescNew/LogCheck/LogCheckNew';
import { readOnlyFieldTypes } from '../../../patient-source/shared/EncounterDescNew/TableItemGroup/constants';

import { EPS, EPSTP, EREVPI, EREVSM } from './itemGroupsConstants/domainCodes';
import { LOGS, PROCEDURE, TABLE } from './itemGroupsConstants/itemGroupTypes';
import {
  getDefaultNotPerformed,
  resolveDataCollected,
  resolveDefaultLogCheckOptions,
  resolveNoDataToCollect
} from './defaultLogCheckOptions';

export function getFormattedSubElementName(name) {
  const numbers = name && name.match(/\d+/g);
  if (numbers) {
    return name && `${name.split(numbers && numbers[0])[0]} ${numbers && numbers[0]}`;
  }
  return name;
}
export function checkForDuplicate(data) {
  if (isString(data)) {
    const splitedValue = data.split(',');
    return checkForDuplicate(splitedValue);
  }
  return new Set(data.map(elem => elem.trim())).size !== data.length;
}
export function isAllItemSelected(optinalDataList) {
  return optinalDataList.every(({ isChecked }) => isChecked);
}

export function toFormMainElementParameters(data, isItemGroupTemplateSetup, isTemplateCopy) {
  const { uniqueIdentifier, ...rest } = data;
  const {
    elementName,
    domainName,
    logCheckTemplate,
    elementIcon,
    domainCode,
    domainIdentifier,
    terminologyVersionGroupNumber,
    informedConsentTemplate
  } = data;
  const isIcfDomain = !!(domainCode === EREVPI || domainCode === EREVSM);
  const logCheckInstruction = !isEmpty(logCheckTemplate?.instruction) ? logCheckTemplate?.instruction : '';
  const logCheckOptions = !isEmpty(logCheckTemplate?.rows)
    ? logCheckTemplate?.rows
    : cloneDeep(resolveDefaultLogCheckOptions(domainCode));
  const input = isItemGroupTemplateSetup || isTemplateCopy ? rest : data;

  if (!logCheckOptions.some(e => e.rowType === DATA_COLLECTED)) {
    logCheckOptions.push(resolveDataCollected(domainCode));
  }
  if (!logCheckOptions.some(e => e.rowType === NO_DATA_TO_COLLECT)) {
    logCheckOptions.push({ ...resolveNoDataToCollect(domainCode), checked: false });
  }
  if (!logCheckOptions.some(e => e.rowType === NOT_PERFORMED)) {
    logCheckOptions.push({ ...getDefaultNotPerformed(), checked: false });
  }

  return {
    domainId: domainIdentifier,
    informedConsentTemplate,
    inputs: input ? [input] : [],
    elementName: isTemplateCopy ? elementName + ' (copy)' : elementName,
    domainName: domainName,
    logCheckInstruction: logCheckInstruction,
    logCheckOptions: logCheckOptions,
    logCheckTemplate: {
      instruction: logCheckInstruction,
      rows: logCheckOptions
    },
    dataSaveObj: {},
    repeatFormLabel: 1,
    initElementIcon: {
      id: elementIcon,
      name: elementIcon
    },
    directions: elementIcon === LOGS ? data.directions : null,
    elementIcon: elementIcon,
    initDomainValue: data,
    domain: domainCode,
    domainElement: domainName,
    isLogType: elementIcon === LOGS,
    displayTableType: elementIcon === TABLE,
    hideIsTableOptions: elementIcon === PROCEDURE || domainCode === EPS || domainCode === EPSTP,
    showPreviewMsg: isIcfDomain ? 'No preview for this element' : '',
    showForm: !isIcfDomain,
    terminologyVersionGroupNumber
  };
}

// get coloumn value
export function getPredefineColumnValues(inputs) {
  if (inputs) {
    if (typeof inputs.predefinedColumnValues === 'string') {
      const spt = inputs.predefinedColumnValues.split(',');
      return spt.length ? spt : [];
    }
    if (typeof inputs.predefinedColumnValues === 'object' && inputs.predefinedColumnValues != null) {
      return inputs.predefinedColumnValues;
    }
    return [];
  }

  return [];
}

export function getMaxSequenceCounter(optional, custom) {
  const newSequenceData = [].concat(optional || [], custom || []);
  const getSortedBySequenceFields = common.getSortedBySequenceFields(newSequenceData);
  const maxSequenceNumber = Math.max.apply(
    Math,
    getSortedBySequenceFields.map(function(o) {
      return o && o.sequence;
    })
  );
  return maxSequenceNumber !== -Infinity ? maxSequenceNumber : 0;
}
export function getContollTermListDependsPage() {
  if (this.props.pageName.includes('Template')) {
    return FormBuilderApi.getCTListsForTerminologyVersionGroup(this.props.terminologyVersionGroupNumber);
  } else {
    return FormBuilderApi.getCTLists(this.props.protocolId);
  }
}

export function isReadOnlyField(fieldType) {
  return readOnlyFieldTypes.includes(fieldType);
}
