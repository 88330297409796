import React from 'react';

import modalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { validateEmail } from '../../../patient-source/Patients/PatientInfo/patientInfoService';

import { InvoiceNoteAndRecipientModal } from './InvoiceNoteAndRecipientModal';

export const openInvoiceNoteAndRecipientModal = (budgetId, invoiceNote, recipientEmails) => {
  modalBoxes.open({
    component: (
      <InvoiceNoteAndRecipientModal budgetId={budgetId} invoiceNote={invoiceNote} recipientEmails={recipientEmails} />
    ),
    title: 'Invoice Note / Recipient',
    size: 'w650'
  });
};

export const validateEmails = emails => emails.split(';').every(email => validateEmail(email.trim()));
