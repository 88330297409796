export const outline = {
  outlineWidth: 12,
  outlineStroke: 'transparent'
};

export const optionPaintStyle = {
  stroke: 'gray',
  strokeWidth: 1,
  ...outline
};

export const primaryPaintStyle = {
  stroke: 'black',
  strokeWidth: 2,
  ...outline
};

export const warningPaintStyle = {
  stroke: 'red',
  strokeWidth: 2,
  ...outline
};
