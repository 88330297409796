import React from 'react';
import { isNull } from 'lodash/lang';
import PropTypes from 'prop-types';

import Checkbox from '../../../../../../../../common/data-entry/InputSelectors/Checkbox';
import { scGray70 } from '../../../../../../../../constants/systemColors';
import { useTableItemGroupActions, useTableItemGroupState } from '../../TableItemGroupContext/TableItemGroupContext';
import fieldPropTypes from '../fieldPropTypes';

export default function CheckBoxField({ field }) {
  const { fieldUid } = field;
  const { isPreviewOnly } = useTableItemGroupState();
  const { fieldOnChange } = useTableItemGroupActions();

  return (
    <CheckBoxFieldReadonly
      field={field}
      onChange={({ target }) => fieldOnChange(target.checked || null, fieldUid)}
      disabled={isPreviewOnly}
    />
  );
}

export function CheckBoxFieldReadonly({ field, ...rest }) {
  const { fieldValue, fieldName } = field;

  const value = fieldValue === 'true' ? true : fieldValue === 'false' ? false : fieldValue || false;
  return <Checkbox className="m-0 check-box-field" label={fieldName} color={scGray70} checked={value} {...rest} />;
}

const propTypes = {
  field: PropTypes.shape({
    ...fieldPropTypes,
    fieldType: PropTypes.oneOf(['checkbox']).isRequired,
    fieldValue: function(props, propName, componentName) {
      const value = props[propName];
      if (!isNull(value) && value !== true && value !== 'true') {
        return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed.`);
      }
    }
  })
};

CheckBoxFieldReadonly.defaultProps = {
  disabled: true
};

CheckBoxFieldReadonly.propTypes = propTypes;
CheckBoxField.propTypes = propTypes;
