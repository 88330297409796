import React from 'react';

import Button from '../../../../../../../common/general/Button';
import Icon from '../../../../../../../common/general/Icon';

import './ConfirmHighlightedItems.scss';

export default function ConfirmHighlightedItems({
  onConfirmItemGroup,
  disableItemGroupConfirmButton,
  isItemGroupConfirmed
}) {
  return (
    <Button
      size="h40"
      priority="medium"
      className="erc-item-group-element-confirm-button"
      onClick={() => onConfirmItemGroup()}
      disabled={disableItemGroupConfirmButton}
    >
      <Icon suit="material">{isItemGroupConfirmed ? 'bookmark_added' : 'bookmark'}</Icon>
      {isItemGroupConfirmed ? 'Confirmed' : 'Confirm Highlighted Items'}
    </Button>
  );
}
