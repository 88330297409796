import React from 'react';
import moment from 'moment/moment';

import DateTemplate from '../../../../../setup/shared/GenerateHtml/DateTemplate';

import './TableDate.scss';

function isValid(current) {
  return current.isBefore(moment());
}

export default function TableDate({ value, placeholder, onChange }) {
  return (
    <div className="patient-profile-medications-table-cell-date">
      <DateTemplate value={value} isValidDate={isValid} legacyLook placeholder={placeholder} onChange={onChange} />
    </div>
  );
}
