import axios from 'axios';

const ROOT = 'api/v1/finance/site-credits';

const SiteCreditApi = {
  getEligibleEvents(siteId, studyIds) {
    return axios.post(`${ROOT}/eligible-events`, { siteId, studyIds });
  },
  getHistory(itemSiteId) {
    return axios.get(`${ROOT}/history/${itemSiteId}`);
  },
  applySiteCredit(request) {
    return axios.put(`${ROOT}/apply`, request);
  }
};

export default SiteCreditApi;
