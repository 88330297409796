import { PL, SM_WORKLIST } from '../../../../constants/accessToPages';
import { generatePatientInfoRouterConfig } from '../../../root/router/sharedConfigs';

import SmWorklistNew from './SmWorklistNew';

export const smWorklistNewRouterConfig = {
  name: 'Study Manager Worklist',
  path: '/sm-work-list',
  component: SmWorklistNew,
  access: [SM_WORKLIST],
  inherited: [...generatePatientInfoRouterConfig(PL, SM_WORKLIST)]
};
