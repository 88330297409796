import axios from 'axios';

const ROOT = 'api/v1/finance/budget-sites';

const FinBudgetSiteApi = {
  findAll() {
    return axios.get(ROOT);
  },
  save(budgetSite) {
    return axios.post(ROOT, budgetSite);
  },
  findFinBudgetSiteByFilter(filterProperty) {
    return axios.get(`${ROOT}/filter`, {
      params: { ...filterProperty }
    });
  }
};

export default FinBudgetSiteApi;
