import { isEmpty, toNumber } from 'lodash/lang';
import { isMoment } from 'moment';
import moment from 'moment-timezone';

import { DATE_FORMAT } from '../../../../../ModeReports/modeReportsServices';

export const defaultAddress = {
  countryId: 'US',
  regionId: '',
  city: '',
  address1: '',
  address2: '',
  zipCode: '',
  valid: false
};

const defaultMileageAddresses = {
  travelDate: moment(),
  calculatedDistance: '',
  distance: '',
  roundTrip: true,
  startAddress: {
    type: 'PATIENT_HOME_ADDRESS',
    ...defaultAddress
  },
  endAddress: {
    type: 'SITE_ADDRESS',
    ...defaultAddress
  }
};

const defaultInitialValues = {
  files: [],
  encounter: null,
  encounterDate: moment(),
  type: '',
  amount: '',
  note: '',
  mileageAddresses: defaultMileageAddresses
};

export function generateInitialValues(initialValues, reimbursement) {
  let values = { ...defaultInitialValues };
  if (initialValues) {
    values = { ...values, ...initialValues };
  }
  if (reimbursement?.id) {
    if (reimbursement.encounterName) {
      values.encounter = {
        encounterName: reimbursement.encounterName,
        encounterStatus: reimbursement.encounterStatus,
        encounterDate: reimbursement.encounterDate
      };
    }
  }
  return values;
}

export async function prepareFiles(files) {
  if (isEmpty(files)) {
    return [];
  }
  const frPromises = files.map(reader);
  try {
    return await Promise.all(frPromises);
  } catch (err) {
    console.error(err);
  }
}

export function prepareRequest(values) {
  const { mileageAddresses, encounterDate, ...rest } = values;
  if (values.type !== 'MILEAGE') {
    return {
      ...rest,
      encounterDate: isMoment(encounterDate) && moment(encounterDate).format(DATE_FORMAT)
    };
  }
  return {
    ...rest,
    encounterDate: isMoment(encounterDate) && moment(encounterDate).format(DATE_FORMAT),
    mileage: {
      distance: mileageAddresses?.distance,
      calculatedDistance: mileageAddresses?.calculatedDistance,
      roundTrip: mileageAddresses?.roundTrip,
      travelDate: mileageAddresses?.travelDate,
      start: {
        address: mileageAddresses?.startAddress?.address1,
        valid: mileageAddresses?.startAddress?.valid,
        address2: mileageAddresses?.startAddress?.address2,
        city: mileageAddresses?.startAddress?.city,
        region: mileageAddresses?.startAddress?.regionId,
        zip: mileageAddresses?.startAddress?.zipCode,
        country: mileageAddresses?.startAddress?.countryId,
        isOtherAddress: mileageAddresses?.startAddress?.type === 'OTHER'
      },
      end: {
        address: mileageAddresses?.endAddress?.address1,
        valid: mileageAddresses?.endAddress?.valid,
        address2: mileageAddresses?.endAddress?.address2,
        city: mileageAddresses?.endAddress?.city,
        region: mileageAddresses?.endAddress?.regionId,
        zip: mileageAddresses?.endAddress?.zipCode,
        country: mileageAddresses?.endAddress?.countryId,
        isOtherAddress: mileageAddresses?.endAddress?.type === 'OTHER'
      }
    }
  };
}

export function prepareResponse(values) {
  const { mileage, ...rest } = values;
  if (values.type !== 'MILEAGE') {
    return rest;
  }
  return {
    ...rest,
    mileageAddresses: {
      distance: mileage?.distance,
      calculatedDistance: mileage?.calculatedDistance,
      roundTrip: mileage?.roundTrip,
      travelDate: moment(mileage?.travelDate),
      startAddress: {
        address1: mileage?.start?.address,
        valid: mileage?.start?.valid,
        address2: mileage?.start?.address2,
        city: mileage?.start?.city,
        regionId: mileage?.start?.region,
        zipCode: mileage?.start?.zip,
        countryId: mileage?.start?.country,
        type: mileage?.start?.isOtherAddress ? 'OTHER' : 'PATIENT_HOME_ADDRESS'
      },
      endAddress: {
        address1: mileage?.end?.address,
        valid: mileage?.end?.valid,
        address2: mileage?.end?.address2,
        city: mileage?.end?.city,
        regionId: mileage?.end?.region,
        zipCode: mileage?.end?.zip,
        countryId: mileage?.end?.country,
        type: mileage?.end?.isOtherAddress ? 'OTHER' : 'SITE_ADDRESS'
      }
    }
  };
}

export function getDistance(roundTrip, distance, reversed = false) {
  if (distance <= 0) {
    return distance;
  }
  if (roundTrip) {
    return toNumber(reversed ? distance / 2 : distance * 2);
  }
  return toNumber(distance);
}

export function reader(file) {
  const { fileInstance } = file;
  return new Promise((resolve, reject) => {
    if (!fileInstance) {
      resolve({
        fileId: file.id,
        fileData: null,
        fileUploadName: file.fileName,
        type: file.fileType
      });
      return;
    }
    const fr = new FileReader();
    fr.onload = function() {
      let fileData = fr.result.split(',');
      if (fileData.length > 1) {
        fileData = fileData[1];
      }
      resolve({
        fileData,
        fileUploadName: fileInstance.name,
        type: fileInstance.type
      });
    };
    fr.onerror = err => reject(err);
    fr.readAsDataURL(fileInstance);
  });
}
