import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function useActiveTab(types) {
  let [searchParams, setSearchParams] = useSearchParams();
  const urlPaymentType = searchParams.get('paymentType');
  const activeTab = useMemo(
    function() {
      if (types.includes(urlPaymentType)) {
        return urlPaymentType;
      }
      return types[0];
    },
    [types, urlPaymentType]
  );

  useEffect(
    function() {
      if (activeTab !== urlPaymentType) {
        setSearchParams(
          function(searchParams) {
            searchParams.set('paymentType', activeTab);
            return searchParams;
          },
          { replace: true }
        );
      }
    },
    [activeTab, setSearchParams, urlPaymentType]
  );

  const setActiveTab = useCallback(
    function(tab) {
      setSearchParams(function(searchParams) {
        searchParams.set('paymentType', tab);
        return searchParams;
      });
    },
    [setSearchParams]
  );
  return { activeTab, setActiveTab };
}
