import React from 'react';
import Select, { components } from 'react-select';

import Icon from '../../../../../../../common/general/Icon';

import './TableSelect.scss';

function DropdownIndicator(props) {
  const { selectProps } = props;
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Icon>{selectProps.menuIsOpen ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
      </components.DropdownIndicator>
    )
  );
}

export default function TableSelect(props) {
  return (
    <Select
      menuPlacement="auto"
      components={{ DropdownIndicator }}
      className="eui-table-select"
      classNamePrefix="eui-table-select"
      unstyled
      menuPortalTarget={document.body}
      {...props}
    />
  );
}
