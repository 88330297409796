import React, { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import LoaderOverlay from 'common/elements/Loader/LoaderOverlay';

import { interceptorHandler, LoadingOverlayEventEmitter } from './service';

import './LoadingOverlay.scss';

axios.interceptors.request.use(function(config) {
  interceptorHandler(config, 1);
  return config;
});

axios.interceptors.response.use(
  function(response) {
    interceptorHandler(response.config, -1);
    return response;
  },
  function(error) {
    /* TODO: need to fix axios.CancelToken usage. If we use it we have 'error' object without 'config' property so we can not check 'hideLoader' property */
    interceptorHandler(error?.config, -1);
    return Promise.reject(error);
  }
);

export default function LoadingOverlay() {
  const [loading, setLoading] = useState(false);
  const [manualLoading, setManualLoading] = useState(false);
  useEffect(function() {
    LoadingOverlayEventEmitter.on('set-loading', listener);
    return () => {
      LoadingOverlayEventEmitter.removeListener('set-loading', listener);
    };
    function listener({ manual, loading }) {
      if (manual) {
        setManualLoading(loading);
        return;
      }
      setLoading(loading);
    }
  }, []);

  if (!loading && !manualLoading) {
    return null;
  }

  return <LoaderOverlay className="eds-loader-manager" />;
}

LoadingOverlay.showLoader = function() {
  LoadingOverlayEventEmitter.emit('set-loading', { manual: true, loading: true });
};

LoadingOverlay.hideLoader = function() {
  LoadingOverlayEventEmitter.emit('set-loading', { manual: true, loading: false });
};
