import { any, bool, func, string } from 'prop-types';

export const datePickerInputTypes = {
  value: any,
  validationMessage: string,
  valid: bool,
  placeholder: string,
  label: string,
  disabled: bool,
  className: string,
  onChange: func,
  isValidDate: func,
  iconsAfter: any,
  legacyLook: bool,
  validate: bool
};

export const datePickerManualInputTypes = {
  value: any,
  validationMessage: string,
  valid: bool,
  placeholder: string,
  label: string,
  disabled: bool,
  dateOnly: bool,
  onChange: func,
  isValidDate: func,
  className: string,
  iconsAfter: any,
  legacyLook: bool
};
