import { isArray } from 'lodash/lang';
import { get } from 'lodash/object';

import store from '../../../store';

export function getInitialCalendars() {
  const userId = get(store.getState(), 'currentUser.personnelIdentifier').replace(/\./g, '-');
  const userRole = get(store.getState(), 'currentUser.activeRole');
  const calendarPageSettingsString = localStorage.getItem('calendarPageSettings') || '{}';
  const calendarPageSettings = JSON.parse(calendarPageSettingsString);
  const calendarsToDisplay = get(calendarPageSettings, `${userId}-${userRole}.selectedCalendars`);

  return isArray(calendarsToDisplay)
    ? calendarsToDisplay
    : [{ type: 'User', id: store.getState().currentUser.personnelIdentifier, selected: true, checked: true }];
}

export function setInitialCalendars(calendars) {
  const userId = get(store.getState(), 'currentUser.personnelIdentifier').replace(/\./g, '-');
  const userRole = get(store.getState(), 'currentUser.activeRole');
  const calendarPageSettingsString = localStorage.getItem('calendarPageSettings') || '{}';
  const calendarPageSettings = JSON.parse(calendarPageSettingsString);
  calendarPageSettings[`${userId}-${userRole}`] = { selectedCalendars: calendars };
  localStorage.setItem('calendarPageSettings', JSON.stringify(calendarPageSettings));
}
