import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { isArray, isFunction, isObject, isString } from 'lodash/lang';
import { omit } from 'lodash/object';
import * as PropTypes from 'prop-types';

import { pendoTrack } from '../../../pendo/PendoUtils';

import './Button.scss';

const Button = React.forwardRef(function(props, ref) {
  const { children, className, href, priority, size, loading, to, disabled, onClick } = props;
  const Element = disabled ? 'button' : to ? Link : href ? 'a' : 'button';
  const onlyIcon = children?.type?.isIcon;
  const elementProps = omit(props, ['children', 'className', 'priority', 'size', 'loading', 'onClick']);
  const content = wrapWhenLoading();
  return (
    <Element
      ref={ref}
      className={cx(`eui-btn eui-btn-priority-${priority} eui-btn-size-${size}`, className, {
        'eui-btn-loading': loading,
        'eui-btn-icon-only': onlyIcon
      })}
      {...elementProps}
      onClick={e => {
        const url = window.location.pathname;
        if (children !== 'Sign In') {
          pendoTrack('Click on ' + (isArray(children) ? 'multi text' : isObject(children) ? 'icon' : children), {
            pageName: url,
            elementType: Element
          });
        }
        isFunction(onClick) && onClick(e);
      }}
    >
      {content}
    </Element>
  );

  function getChildrenContent() {
    function defineViewOfElement(element) {
      return isString(element) ? (
        <span key={element} className="eui-btn-text">
          {element}
        </span>
      ) : (
        element
      );
    }

    return isArray(children)
      ? children?.map(element => {
          return defineViewOfElement(element);
        })
      : defineViewOfElement(children);
  }

  function wrapWhenLoading() {
    const content = getChildrenContent();
    if (loading) {
      return (
        <>
          <span className="eui-btn-content-wrapper">{content}</span>
          {loading && (
            <span className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </span>
          )}
        </>
      );
    }
    return content;
  }
});

Button.defaultProps = {
  priority: 'high',
  size: 'h40',
  disabled: false,
  loading: false
};

Button.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string,
  priority: PropTypes.oneOf(['high', 'medium', 'low']),
  size: PropTypes.oneOf(['h56', 'h40', 'h28']),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  disabled: PropTypes.bool,
  loading: PropTypes.bool
};

export default Button;
