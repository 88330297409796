import { isEmpty } from 'lodash/lang';
import { merge } from 'lodash/object';

export default function setSsuPatientDetails(state, { type, payload: ssuPatientDetails }) {
  return autoPopulateIfNeeded({
    ...state,
    ssuPatientDetails
  });
}

function autoPopulateIfNeeded(state) {
  const { ssuPatientDetails, mileageAddresses } = state;
  const { patientAddress, siteAddress } = ssuPatientDetails;

  const value = {};

  if (
    patientAddress &&
    mileageAddresses.startAddress.type === 'PATIENT_HOME_ADDRESS' &&
    addressIsDefault(mileageAddresses.startAddress)
  ) {
    value.startAddress = patientAddress;
  }
  if (
    siteAddress &&
    mileageAddresses.endAddress.type === 'SITE_ADDRESS' &&
    addressIsDefault(mileageAddresses.endAddress)
  ) {
    value.endAddress = siteAddress;
  }
  if (!isEmpty(value)) {
    return merge({}, state, { mileageAddresses: value });
  }
  return state;
}

function addressIsDefault({ countryId, regionId, city, address1, address2, zipCode }) {
  return countryId === 'US' && !regionId && !city && !address1 && !address2 && !zipCode;
}
