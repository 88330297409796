import moment from 'moment';

import EnrollmentPredictionApi from '../../../../api/finance/EnrollmentPredictionApi';
import StudySiteEnrollmentPredictionApi from '../../../../api/finance/StudySiteEnrollmentPredictionApi';
import StudyApi from '../../../../api/patient/StudyApi';

import { getSSUEndDate, getSSUStartDate } from './studyStartupService';

export function generateEnrollmentsValues(SSU, existingEnrollmentPredictionValues = []) {
  const enrollmentsStart = getSSUStartDate(SSU);
  const enrolmentsEnd = getSSUEndDate(SSU);

  if (!enrollmentsStart || !enrolmentsEnd) {
    return [];
  }

  const firstMonthOfEnrollments = moment(enrollmentsStart).startOf('month');
  const lastMonthOfEnrolments = moment(enrolmentsEnd).startOf('month');

  const month = firstMonthOfEnrollments.clone();
  const enrollmentValues = [];
  while (month.isSameOrBefore(lastMonthOfEnrolments)) {
    const existingPredictionForMonth = existingEnrollmentPredictionValues.find(pv => {
      return pv.year === month.year() && pv.month === month.month();
    });

    const predictionValueTemplate = {
      id: '',
      year: month.year(),
      month: month.month(),
      value: 0
    };
    enrollmentValues.push(existingPredictionForMonth || predictionValueTemplate);
    month.add(1, 'month');
  }
  return enrollmentValues;
}

export function generateEnrollment(ssu, month, existingEnrollments) {
  const periodStart = getSSUStartDate(ssu);
  const periodEnd = getSSUEndDate(ssu);
  return {
    id: existingEnrollments ? existingEnrollments.id : '',
    study: ssu.study,
    site: ssu.site,
    expectedCountSiv: ssu.consentExpectedSiv,
    expectedEnrollmentSiv: ssu.expectedEnrollmentSiv,
    completeExpectedSiv: ssu.expectedCompletionSiv,
    expectedCountPsv: ssu.consentExpectedPsv,
    expectedEnrollmentPsv: ssu.expectedEnrollmentPsv,
    completeExpectedPsv: ssu.expectedCompletionPsv,
    periodEnd,
    periodStart,
    studySiteId: ssu.uniqueIdentifier,
    reason: existingEnrollments ? existingEnrollments.reason : '',
    year: month.get('year'),
    month: month.get('month'),
    enrollmentPredictionValues: generateEnrollmentsValues(
      ssu,
      existingEnrollments ? existingEnrollments.enrollmentPredictionValues : undefined
    )
  };
}

export function compareEnrollmentsPredictionsByMonth(epv1, epv2) {
  const e1 = moment().set({ year: epv1.year, month: epv1.month });
  const e2 = moment().set({ year: epv2.year, month: epv2.month });
  return e1.diff(e2, 'month');
}

export function fillWithStudyAndSite(studySites) {
  return enrollment => {
    const { site, study, consentExpectedSiv, consentExpectedPsv } = studySites.find(
      ssu => ssu.uniqueIdentifier === enrollment.studySiteId
    );
    return { ...enrollment, ...{ site, study }, consentExpectedSiv, consentExpectedPsv };
  };
}

export function getEnrollmentsForDate(studySites, date, selectedTherapeuticArea) {
  const enrolledPatientCountPerSsuPromise = StudyApi.getEnrolledPatientsCountBySsus(
    studySites.map(ssu => ssu.uniqueIdentifier)
  ).then(data => data.data);
  const ssuAndTherapeuticAreaRequest = {
    studySiteIds: studySites.map(ssu => ssu.uniqueIdentifier),
    therapeuticArea: selectedTherapeuticArea?.areaValue
  };
  const enrollmentsPromise = StudySiteEnrollmentPredictionApi.findEnrollmentPredictionsBySelectedFilterProperties(
    ssuAndTherapeuticAreaRequest,
    date.format('YYYY-MM-DD')
  ).then(data => data.data);
  return Promise.all([enrolledPatientCountPerSsuPromise, enrollmentsPromise]).then(
    ([enrolledPatientCountPerSsu, enrollments]) => {
      return enrollments
        .map(fillWithStudyAndSite(studySites))
        .filter(e => !e.locked)
        .map(enrollment => {
          const enrolled = enrolledPatientCountPerSsu[enrollment.studySiteId];
          return { ...enrollment, ...{ enrolled } };
        });
    }
  );
}

export function buildStudyEnrollments(studySites) {
  const ssuUniqueIdentidiers = studySites.map(ssu => ssu.uniqueIdentifier);

  const previousEnrollmentsPromise = EnrollmentPredictionApi.getLastLocked(ssuUniqueIdentidiers)
    .then(resp => resp.data)
    .then(lockedEnrollments => {
      return lockedEnrollments.map(fillWithStudyAndSite(studySites));
    });

  const enrollmentsForActivePeriodPromise = EnrollmentPredictionApi.getEnrollmentsForActivePeriod(ssuUniqueIdentidiers)
    .then(resp => resp.data)
    .then(unlockedEnrollments => {
      return unlockedEnrollments.map(fillWithStudyAndSite(studySites));
    });

  const lastLockedPeriodPromise = EnrollmentPredictionApi.getLastLockedPeriod().then(resp => resp.data.lockedMonth);

  const ssuEnrolledPatientCountMapPromise = StudyApi.getEnrolledPatientsCountBySsus(ssuUniqueIdentidiers).then(
    resp => resp.data
  );

  const ssuWithdrawnPatientCountMapPromise = StudyApi.geWithdrawnPatientsCountBySsus(ssuUniqueIdentidiers).then(
    resp => resp.data
  );

  const screenFailedPatientCountMapPromise = StudyApi.getScreenFailedPatientsCountBySsus(ssuUniqueIdentidiers).then(
    resp => resp.data
  );

  return Promise.all([
    previousEnrollmentsPromise,
    enrollmentsForActivePeriodPromise,
    lastLockedPeriodPromise,
    ssuEnrolledPatientCountMapPromise,
    screenFailedPatientCountMapPromise,
    ssuWithdrawnPatientCountMapPromise
  ]).then(
    ([
      previousEnrollments,
      activeEnrollments,
      lastLockedPeriod,
      ssuEnrolledPatientCountMap,
      screenFailedPatientCountMap,
      ssuDroppedPatientCountMap
    ]) => {
      return studySites.map(ssu => {
        const previousEnrollment = previousEnrollments.find(e => ssu.uniqueIdentifier === e.studySiteId);
        const enrollmentForSSU = activeEnrollments.find(e => ssu.uniqueIdentifier === e.studySiteId);
        const activePredictionMonth = lastLockedPeriod ? moment(lastLockedPeriod).add(1, 'month') : moment();
        return {
          ...generateEnrollment(ssu, activePredictionMonth, enrollmentForSSU),
          previousEnrollment,
          enrolledCount: ssuEnrolledPatientCountMap[ssu.uniqueIdentifier],
          withdrawn: ssuDroppedPatientCountMap[ssu.uniqueIdentifier],
          screenFailed: screenFailedPatientCountMap[ssu.uniqueIdentifier]
        };
      });
    }
  );
}

export function updateStudyEnrollment(studyEnrollment) {
  return EnrollmentPredictionApi.update(studyEnrollment)
    .then(resp => resp.data)
    .then(enrollment => {
      enrollment.study = studyEnrollment.study;
      enrollment.site = studyEnrollment.site;
      enrollment.previousEnrollment = studyEnrollment.previousEnrollment;
      return enrollment;
    });
}

export function createStudyEnrollment(studyEnrollment) {
  return EnrollmentPredictionApi.create(studyEnrollment)
    .then(resp => resp.data)
    .then(enrollment => {
      enrollment.study = studyEnrollment.study;
      enrollment.site = studyEnrollment.site;
      enrollment.previousEnrollment = studyEnrollment.previousEnrollment;
      return enrollment;
    });
}
