import React, { useContext, useMemo, useState } from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash/lang';

import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';

import CollapseList from './CollapseList/CollapseList';
import MappingFieldItem from './MappingItems/MappingFieldItem';
import MappingGeneralItem from './MappingItems/MappingGeneralItem';
import MappingSetupHeader from './MappingSetupHeader/MappingSetupHeader';
import { openPreview } from './MappingSetupServices';

import './MappingSetupModal.scss';

const MappingSetupModal = ({ context }) => {
  const {
    selectedMapping,
    templateName,
    selectedPlatform,
    selectedEndpoint,
    templateMode,
    listOfCollapsedGroups,
    toggleCollapse,
    listOfItemGroups,
    itemGroupCollapsed,
    addItemGroupMappingRow,
    itemGroupsMapping,
    maximalCountOfFieldConfigurations,
    modalBox,
    dataForPreview,
    onSave,
    generalFieldMapping,
    generalGroupAfterFetch,
    listOfInvalidGeneralMappings,
    addGeneralMappingRow,
    listOfInvalidFieldMappings,
    validateMappingInput
  } = useContext(context);

  const [headerCollapsed, setHeaderCollapsed] = useState(false);

  const generalMapping = useMemo(() => {
    return generalFieldMapping.flatMap((group, groupIndex) => {
      const listOfGeneralMappingNames = generalGroupAfterFetch[group.groupType]?.map(mapping => ({
        name: mapping.fieldName,
        uiName: mapping.name,
        value: mapping.fieldName
      }));
      const listOfSelectedGeneralMappingFields = group.fields.map(mapping => mapping.fieldName);
      const groupCollapsed = listOfCollapsedGroups.find(item => item.name === group.groupType);
      const groupMapping = group.fields.map((field, index) => {
        const invalidMappings = listOfInvalidGeneralMappings
          .filter(mapping => mapping.invalidIndex === index && mapping.invalidGroup === group.groupType)
          .map(item => item.invalidItem);
        return (
          <MappingGeneralItem
            key={field.key}
            groupIndex={groupIndex}
            fieldName={field.fieldName}
            fieldType={field.fieldType}
            mappingKeyValue={field.mappingKeyValue}
            alwaysSent={field.alwaysSent}
            index={index}
            listOfGeneralMappingNames={listOfGeneralMappingNames}
            listOfSelectedGeneralMappingFields={listOfSelectedGeneralMappingFields}
            invalidMappings={invalidMappings}
            context={context}
          />
        );
      });

      return (
        <React.Fragment key={group.groupType}>
          {!isEmpty(listOfGeneralMappingNames) && groupCollapsed?.collapsed && (
            <>
              <Button
                size="h28"
                onClick={() => addGeneralMappingRow(groupIndex, group.groupType)}
                disabled={!(listOfGeneralMappingNames.length > group.fields.length)}
              >
                {`Add ${group.groupType} Mapping`}
              </Button>
              <div className="mapping-field-item-wrapper">{groupMapping}</div>
            </>
          )}
        </React.Fragment>
      );
    });
  }, [
    generalFieldMapping,
    generalGroupAfterFetch,
    listOfCollapsedGroups,
    listOfInvalidGeneralMappings,
    context,
    addGeneralMappingRow
  ]);

  const fieldMapping = useMemo(() => {
    const mapping = itemGroupsMapping.map((field, index) => {
      const invalidMappings = listOfInvalidFieldMappings
        .filter(mapping => mapping.invalidIndex === index)
        .map(item => item.invalidItem);
      return (
        <MappingFieldItem
          key={field.key}
          itemGroupName={field.itemGroupName}
          mappingType={field.mappingType}
          index={index}
          listOfItemGroups={listOfItemGroups}
          mappingKeyValue={field.mappingKeyValue}
          protocolItemIdentifier={field.protocolItemIdentifier}
          invalidMappings={invalidMappings}
          context={context}
        />
      );
    });
    return (
      !isEmpty(listOfItemGroups) &&
      itemGroupCollapsed?.collapsed && (
        <>
          <Button
            size="h28"
            onClick={addItemGroupMappingRow}
            disabled={itemGroupsMapping.length >= maximalCountOfFieldConfigurations}
          >
            Add Item Group Mapping
          </Button>
          <div className="mapping-field-item-wrapper">{mapping}</div>
        </>
      )
    );
  }, [
    addItemGroupMappingRow,
    context,
    itemGroupCollapsed?.collapsed,
    itemGroupsMapping,
    listOfInvalidFieldMappings,
    listOfItemGroups,
    maximalCountOfFieldConfigurations
  ]);

  return (
    <>
      <ModalBoxes.Header>Afterburner Data Mapping: {selectedMapping?.name || templateName}</ModalBoxes.Header>
      <ModalBoxes.Body>
        <MappingSetupHeader
          context={context}
          headerCollapsed={headerCollapsed}
          setHeaderCollapsed={setHeaderCollapsed}
        />
        {((selectedPlatform?.value && selectedEndpoint?.value) || templateMode) && (
          <>
            <CollapseList listOfCollapsedGroups={listOfCollapsedGroups} toggleCollapse={toggleCollapse} />
            <div className={cx('add-new-mapping-button', { 'setting-closed': headerCollapsed })}>
              {generalMapping}
              {fieldMapping}
            </div>
          </>
        )}
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority={'medium'} onClick={() => modalBox.close()}>
            Cancel
          </Button>
          <Button
            disabled={isEmpty(selectedMapping) && !templateMode}
            onClick={() => {
              if (
                validateMappingInput(
                  dataForPreview.validationGeneralMappings,
                  dataForPreview.validationItemGroupsMappings
                )
              ) {
                openPreview([dataForPreview.mappings], selectedMapping?.name);
              }
            }}
          >
            Preview
          </Button>
          <Button disabled={isEmpty(selectedMapping) && !templateMode} onClick={onSave}>
            Save
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
};

export default MappingSetupModal;
