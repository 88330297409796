import React, { useState } from 'react';

export const FileViewerContext = React.createContext(null);

export function FileViewerProvider({ children }) {
  const [state, setState] = useState({ filePromise: null, displayed: false });
  return (
    <FileViewerContext.Provider
      value={{
        state,
        openFileByPromise(filePromise) {
          setState(prevState => ({ ...prevState, displayed: true, filePromise }));
        },
        close(lastFile) {
          setState(prevState => ({ ...prevState, displayed: false, filePromise: null, lastFile }));
        }
      }}
    >
      {children}
    </FileViewerContext.Provider>
  );
}

export function withFileViewerContext(Component) {
  return function WrapperComponent(props) {
    return (
      <FileViewerContext.Consumer>{state => <Component {...props} fileViewer={state} />}</FileViewerContext.Consumer>
    );
  };
}
