import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { intersectionWith } from 'lodash/array';
import { isEmpty, isEqual, isUndefined } from 'lodash/lang';

import { StudySiteApi } from '../../../../api';
import InvestigatorWorkListApi from '../../../../api/patient/InvestigatorWorkListApi';
import useSessionStorageWithRole from '../../../../common/hooks/useSessionStorageWithRole';
import { PI_WORKLIST_NEW_VALUES } from '../../../../constants/sessionStorageConstants';
import { CLOSED, ENROLLING, STARTUP, TREATMENT } from '../../../../constants/ssuStatuses';
import { ACCESS_TO_ADVERSE_EVENT_INVESTIGATOR_OPERATIONS } from '../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../services/auth';
import { getIds, getSitesFrom, getStudiesFrom } from '../ScheduleWorklist/SchedulingWorklistService';
import { ADVERSE_EVENTS_WIDGET, COMPLETE, ENCOUNTER_WIDGET } from '../shared/Worklist/constants';
import { getValidationErrors, isSelectedStudySitesChanged, usePrevious } from '../shared/Worklist/WorklistService';

import {
  CANCELED,
  DATA_REQUIRED,
  defaultInvestigatorFilters,
  INVESTIGATOR_WORKLIST_PAGE_SIZE,
  INVESTIGATOR_WORKLIST_SORTED_BY,
  investigatorAdverseEventsStatusesProvider,
  investigatorStatusesProvider,
  REVIEW_REQUIRED,
  sortingPath
} from './constants';

export const InvestigatorWorklistContext = React.createContext(null);
let timeoutInstances = [];
let request = null;
let requestForData = null;
export default function InvestigatorWorkListFiltersContext(props) {
  const [sessionStorage, setSessionStorage] = useSessionStorageWithRole(PI_WORKLIST_NEW_VALUES, {});
  const [selectedStudies, setSelectedStudies] = useState(sessionStorage.selectedStudies || []);
  const [selectedSites, setSelectedSites] = useState(sessionStorage.selectedSites || []);
  const [selectedStudySites, setSelectedStudySites] = useState(sessionStorage.selectedStudySites || []);
  const [totalEncounter, setTotalEncounter] = useState(0);
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [amountPerCategory, setAmountPerCategory] = useState({ DATA_REQUIRED: 0, REVIEW_REQUIRED: 0, COMPLETE: 0 });
  const [amountPerCategoryForAdverse, setAmountPerCategoryForAdverse] = useState({
    DATA_REQUIRED: 0,
    REVIEW_REQUIRED: 0,
    COMPLETE: 0,
    CANCELED: 0
  });
  const [showComplete, setShowComplete] = useState(sessionStorage.showComplete || false);
  const [showCompleteForAdverse, setShowCompleteForAdverse] = useState(sessionStorage.showCompleteForAdverse || false);
  const [showCanceledForAdverse, setShowCanceledForAdverse] = useState(sessionStorage.showCanceledForAdverse || false);
  const [currentlySelected, setCurrentlySelected] = useState(sessionStorage.currentlySelected || '');
  const [currentlySelectedForAdverse, setCurrentlySelectedForAdverse] = useState(
    sessionStorage.currentlySelectedForAdverse || ''
  );
  const [tableData, setTableData] = useState([]);
  const [patientEncounterIds, setPatientEncounterIds] = useState([]);
  const [rowIds, setRowIds] = useState([]);
  const [isSignedByColumnFetching, setIsSignedByColumnFetching] = useState(false);
  const [encounterNames, setEncounterNames] = useState(sessionStorage.encounterNames || []);
  const [signatures, setSignatures] = useState(() => []);
  const [groupsAndSignatures, setGroupsAndSignatures] = useState([]);
  const [totalAdverseEvents, setTotalAdverseEvents] = useState(0);
  const [totalEncounters, setTotalEncounters] = useState([]);
  const [studies, setStudies] = useState([]);
  const [allStudies, setAllStudies] = useState([]);
  const [sites, setSites] = useState([]);
  const [allSites, setAllSites] = useState([]);
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(sessionStorage.page || 0);
  const [pageSize, setPageSize] = useState(sessionStorage.pageSize || INVESTIGATOR_WORKLIST_PAGE_SIZE);
  const [sorted, setSorted] = useState(sessionStorage.sorted || [INVESTIGATOR_WORKLIST_SORTED_BY]);
  const [ssus, setSsus] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const previousPageValue = usePrevious(page);
  const [statusType, setStatusType] = useState(sessionStorage.statusType || ENCOUNTER_WIDGET);
  const previousStatusType = usePrevious(statusType);

  const [filter, setFilter] = useState({
    selectedStudySites,
    selectedStudies,
    selectedSites,
    studies,
    sites
  });
  const { children } = props;

  useEffect(() => {
    StudySiteApi.getAllStudySites().then(res => {
      setSsus(prepareStudySitesForFilter(res.data));
    });
    return () => {
      if (!!request) {
        request.cancel();
      }
    };
  }, []);

  function prepareStudySitesForFilter(data) {
    return data.filter(function({ siteStatus: ssuStatus }) {
      return [STARTUP, ENROLLING, TREATMENT, CLOSED].includes(ssuStatus);
    });
  }

  const selectedStudySitesIds = useMemo(
    function() {
      return selectedStudySites?.map(studySite => studySite.id);
    },
    [selectedStudySites]
  );

  useEffect(
    function() {
      if (!isEmpty(ssus)) {
        setIsLoadingFilters(true);
        const studies = getStudiesFrom(ssus);
        const sites = getSitesFrom(ssus);

        setAllStudies(studies);
        setAllSites(sites);

        const updatedSelectedStudies = !isEmpty(selectedStudies)
          ? intersectionWith(studies, selectedStudies, nameEquals)
          : studies;
        const updatedSelectedSites = !isEmpty(selectedSites)
          ? intersectionWith(sites, selectedSites, nameEquals)
          : sites;
        const updatedSelectedStudySites = !isEmpty(selectedStudySites)
          ? intersectionWith(ssus, selectedStudySites, (a, b) => a.id === b.id)
          : ssus;

        setSelectedStudies(updatedSelectedStudies);
        setSelectedSites(updatedSelectedSites);
        setSelectedStudySites(updatedSelectedStudySites);

        const updatedSites = !isEmpty(updatedSelectedStudies)
          ? getSitesFrom(ssus.filter(ssu => getIds(updatedSelectedStudies).includes(ssu.study.id)))
          : sites;

        setStudies(studies);
        setSites(updatedSites);

        setIsLoadingFilters(false);
        applyFilters(
          updatedSelectedStudySites,
          updatedSelectedStudies,
          updatedSelectedSites,
          studies,
          updatedSites,
          false
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ssus]
  );

  useEffect(() => {
    if (!!request) {
      request.cancel();
    }
    request = axios.CancelToken.source();
    !isEmpty(patientEncounterIds) && setIsSignedByColumnFetching(true);
    !isEmpty(patientEncounterIds) &&
      InvestigatorWorkListApi.getWorkListSignedByPerGroupValuesForTable(patientEncounterIds, request)
        .then(({ data }) => {
          setTableData(prevState => {
            return prevState.map(el => {
              if (data[el.patientEncounterId]) {
                const enrichWithSignatures = Object.values(data[el.patientEncounterId]);
                return {
                  ...el,
                  requiredReviews:
                    enrichWithSignatures.map(e => ({ type: e.type, groupName: e.groupName, groupId: e.groupId })) || [],
                  signedUsers: enrichWithSignatures.filter(e => e.signatureDateTime && e.unsignedCount === 0) || []
                };
              } else {
                return {
                  ...el,
                  requiredReviews: [],
                  signedUsers: []
                };
              }
            });
          });
          setGroupsAndSignatures(data);
          setIsSignedByColumnFetching(false);
        })
        .catch(console.log);
  }, [patientEncounterIds]);

  useEffect(() => {
    if (statusType === ADVERSE_EVENTS_WIDGET) {
      if (!!request) {
        request.cancel();
      }
      request = axios.CancelToken.source();
      !isEmpty(tableData) && setIsSignedByColumnFetching(true);
      if (!isEmpty(tableData)) {
        const rowIdToTemplateId = tableData.map(el => {
          return { rowId: el.rowId, itemGroupTemplateId: el.itemGroupTemplateId };
        });
        InvestigatorWorkListApi.getAdverseEventsSignedByPerGroupValuesForTable(rowIdToTemplateId, request)
          .then(({ data }) => {
            setTableData(prevState => {
              return prevState.map(el => {
                if (data[el.rowId]) {
                  return {
                    ...el,
                    requiredReviews: data[el.rowId].requiredReviews,
                    signedUsers: data[el.rowId].signedUsers
                  };
                } else {
                  return {
                    ...el,
                    requiredReviews: [],
                    signedUsers: []
                  };
                }
              });
            });
            setGroupsAndSignatures(data);
            setIsSignedByColumnFetching(false);
          })
          .catch(console.log);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowIds]);

  useEffect(() => {
    if (showComplete) {
      setTotalEncounter(
        amountPerCategory[REVIEW_REQUIRED] + amountPerCategory[DATA_REQUIRED] + amountPerCategory[COMPLETE]
      );
    } else {
      setTotalEncounter(amountPerCategory[REVIEW_REQUIRED] + amountPerCategory[DATA_REQUIRED]);
    }
  }, [amountPerCategory, showComplete]);

  useEffect(() => {
    const statusesList = [REVIEW_REQUIRED, COMPLETE, DATA_REQUIRED, CANCELED]
      .filter(status => showCompleteForAdverse || status !== COMPLETE)
      .filter(status => showCanceledForAdverse || status !== CANCELED);
    setTotalAdverseEvents(
      statusesList
        .map(status => amountPerCategoryForAdverse[status])
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
    );
  }, [amountPerCategoryForAdverse, showCanceledForAdverse, showCompleteForAdverse]);

  useEffect(() => {
    if (timeoutInstances.length > 0) {
      timeoutInstances.forEach(timeoutInstance => clearTimeout(timeoutInstance));
    }

    const timeoutInstance = setTimeout(() => {
      setPage(defaultInvestigatorFilters.page);
      updateData({ withoutLoaded: true, onSearchFieldPage: !isEmpty(searchFieldValue) });
    }, 500);
    timeoutInstances.push(timeoutInstance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFieldValue]);

  useEffect(() => {
    updateData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, sorted, encounterNames]);

  useEffect(() => {
    if (statusType === ENCOUNTER_WIDGET) {
      setSessionStorage({
        ...sessionStorage,
        statusType: statusType,
        currentlySelectedForAdverse: '',
        currentlySelected: currentlySelected
      });
    } else {
      setSessionStorage({
        ...sessionStorage,
        statusType: statusType,
        currentlySelected: '',
        currentlySelectedForAdverse: currentlySelectedForAdverse
      });
    }
    if (previousStatusType && previousStatusType !== statusType && previousPageValue !== 0) {
      setPage(0);
      setSessionStorage({ ...sessionStorage, page: 0 });
    }
  }, [
    currentlySelected,
    currentlySelectedForAdverse,
    previousPageValue,
    previousStatusType,
    sessionStorage,
    setSessionStorage,
    statusType
  ]);

  const updateData = ({
    withoutLoaded = false,
    withStatusType = null,
    withSelectedStatus = null,
    withSignatures = null,
    onSearchFieldPage = false
  }) => {
    if (!!requestForData) {
      requestForData.cancel();
    }
    requestForData = axios.CancelToken.source();
    if (selectedStudySitesIds?.length && statusType) {
      const selectedStatusType = withStatusType ? withStatusType : statusType;
      const sortProperties = sorted?.flatMap(({ id, desc }) => {
        if (id === 'date' && selectedStatusType === ADVERSE_EVENTS_WIDGET) {
          return [{ property: 'date', direction: desc ? 'DESC' : 'ASC' }];
        } else if (
          (id === 'encounterName' && selectedStatusType === ADVERSE_EVENTS_WIDGET) ||
          (id === 'adverseEventName' && selectedStatusType === ENCOUNTER_WIDGET)
        ) {
          return sortingPath['patientName'].map(attr => ({ property: attr, direction: desc ? 'DESC' : 'ASC' }));
        } else {
          return sortingPath[id].map(attr => ({ property: attr, direction: desc ? 'DESC' : 'ASC' }));
        }
      });

      ((statusType === ENCOUNTER_WIDGET && !isEmpty(encounterNames)) || statusType === ADVERSE_EVENTS_WIDGET) &&
        setIsFetching(true);
      selectedStatusType === ENCOUNTER_WIDGET &&
        !isEmpty(encounterNames) &&
        InvestigatorWorkListApi.getInvestigatorWorklistData(
          {
            ssuIds: getSsuIdsForRequest(selectedStatusType),
            searchString: searchFieldValue,
            encounterNames: encounterNames ? encounterNames.map(enc => enc.encounterName) : [],
            patientEncounterIds: withSignatures
              ? [...new Set(withSignatures.flatMap(signature => signature.patientEncounterIds))]
              : [...new Set(signatures.flatMap(signature => signature.patientEncounterIds))],
            statuses: withSelectedStatus
              ? investigatorStatusesProvider
                  .filter(invStatus => invStatus.id === withSelectedStatus)
                  .flatMap(({ value }) => value)
              : currentlySelected
              ? investigatorStatusesProvider
                  .filter(invStatus => invStatus.id === currentlySelected)
                  .flatMap(({ value }) => value)
              : defaultInvestigatorFilters.selectedStatuses.flatMap(({ value }) => value),
            page: onSearchFieldPage ? defaultInvestigatorFilters.page : page ? page : defaultInvestigatorFilters.page,
            pageSize: pageSize ? pageSize : defaultInvestigatorFilters.pageSize,
            sortProperties
          },
          withoutLoaded,
          requestForData
        )
          .then(({ data }) => {
            setTableData(data.elements);
            setTotalPages(data.totalPages);
            setIsFetching(false);
            setPatientEncounterIds(data.elements.map(el => el.patientEncounterId));
          })
          .catch(console.log);

      selectedStatusType === ADVERSE_EVENTS_WIDGET &&
        InvestigatorWorkListApi.getAdverseEventsForInvestigatorWorkListByRequest(
          {
            ssuIds: getSsuIdsForRequest(selectedStatusType),
            searchString: searchFieldValue,
            rowIds: withSignatures
              ? [...new Set(withSignatures.flatMap(signature => signature.rowIds))]
              : [...new Set(signatures.flatMap(signature => signature.rowIds))],
            smStatuses: [],
            piStatuses: withSelectedStatus
              ? investigatorAdverseEventsStatusesProvider
                  .filter(invStatus => invStatus.id === withSelectedStatus)
                  .flatMap(({ value }) => value)
              : currentlySelectedForAdverse
              ? investigatorAdverseEventsStatusesProvider
                  .filter(invStatus => invStatus.id === currentlySelectedForAdverse)
                  .flatMap(({ value }) => value)
              : [],
            page: onSearchFieldPage ? defaultInvestigatorFilters.page : page ? page : defaultInvestigatorFilters.page,
            pageSize: pageSize ? pageSize : defaultInvestigatorFilters.pageSize,
            sortProperties
          },
          withoutLoaded,
          requestForData
        )
          .then(({ data }) => {
            setTableData(data.elements);
            setTotalPages(data.totalPages);
            setIsFetching(false);
            setRowIds(data.elements.map(el => el.rowId));
          })
          .catch(console.log);
    }
  };

  const getAmountByStatus = (data, status, statusType = ENCOUNTER_WIDGET) => {
    const statusesProvider =
      statusType === ENCOUNTER_WIDGET ? investigatorStatusesProvider : investigatorAdverseEventsStatusesProvider;
    const values = statusesProvider.filter(invStatus => invStatus.id === status);
    return data
      .filter(encStatus => values[0]?.value?.includes(encStatus.status))
      .reduce((partSum, a) => partSum + a.count, 0);
  };

  const getSsuIdsForRequest = statusType => {
    return selectedStudySites
      ?.filter(ssu =>
        !isEmpty(encounterNames) && statusType === ENCOUNTER_WIDGET
          ? encounterNames.map(enc => enc.studyName).includes(`${ssu.study.name} - ${ssu.study.projectCode}`)
          : true
      )
      .map(studySite => studySite.id);
  };

  const nameEquals = (a, b) => a.name === b.name;

  const onCurrentlySelectedForAdverseEventsChange = newCurrentlySelectedForAdverse => {
    if (newCurrentlySelectedForAdverse !== '') {
      if (
        currentlySelectedForAdverse &&
        currentlySelectedForAdverse !== newCurrentlySelectedForAdverse &&
        previousPageValue !== 0
      ) {
        setPage(0);
        setSessionStorage({ ...sessionStorage, page: 0 });
      }
    }
  };

  const onCurrentlySelectedForEncountersChange = newCurrentlySelected => {
    if (newCurrentlySelected !== '') {
      if (currentlySelected && currentlySelected !== newCurrentlySelected && previousPageValue !== 0) {
        setPage(0);
        setSessionStorage({ ...sessionStorage, page: 0 });
      }
    }
  };

  const onStudiesChange = newStudies => {
    if (!isEqual(selectedStudies, newStudies)) {
      setSelectedStudies(newStudies);
      const newStudySites = ssus.filter(ssu => getIds(newStudies).includes(ssu.study.id));
      !isEqual(newStudySites, selectedStudySites) && setSelectedStudySites(newStudySites);
      const newSites = getSitesFrom(newStudySites);
      !isEqual(sites, newSites) && setSites(newSites);
      onSitesChange(newSites);
    }
  };

  const onSitesChange = newSites => {
    if (!isEqual(selectedSites, newSites)) {
      setSelectedSites(newSites);
      const newStudySites = ssus
        .filter(ssu => getIds(newSites).includes(ssu.site.id))
        .filter(ssu => getIds(selectedStudies).includes(ssu.study.id));
      !isEqual(newStudySites, selectedStudySites) && setSelectedStudySites(newStudySites);
    }
  };

  const applyFilters = (selectedStudySites, selectedStudies, selectedSites, studies, sites, resetPage = true) => {
    const newFilter = {
      selectedStudySites: selectedStudySites,
      selectedStudies: selectedStudies,
      selectedSites: selectedSites,
      page: resetPage ? 0 : sessionStorage.page || 0,
      pageSize: sessionStorage.pageSize || INVESTIGATOR_WORKLIST_PAGE_SIZE,
      sorted: sessionStorage.sorted || [INVESTIGATOR_WORKLIST_SORTED_BY],
      currentlySelected: currentlySelected,
      showComplete: showComplete,
      encounterNames: encounterNames,
      showCompleteForAdverse: showCompleteForAdverse,
      showCanceledForAdverse: showCanceledForAdverse,
      currentlySelectedForAdverse: statusType === ADVERSE_EVENTS_WIDGET ? currentlySelectedForAdverse : '',
      statusType: statusType
    };
    const isStatusClearRequired = isSelectedStudySitesChanged(filter, newFilter);
    const errors = getValidationErrors(newFilter);
    setValidationErrors(errors);
    if (isEmpty(errors)) {
      resetPage && setPage(0);
      resetPage && setSessionStorage({ ...sessionStorage, page: 0 });
      setFilter({ ...newFilter, studies: studies, sites: sites });
      setSessionStorage(newFilter);

      const requests = [
        InvestigatorWorkListApi.getCountOfReviewsBySsuIds(selectedStudySites?.map(studySite => studySite.id))
      ];
      userHasAccessTo(ACCESS_TO_ADVERSE_EVENT_INVESTIGATOR_OPERATIONS) &&
        requests.push(
          InvestigatorWorkListApi.countAdverseEventsByInvestigatorStatus(
            selectedStudySites?.map(studySite => studySite.id)
          )
        );

      Promise.all(requests).then(([dataForEncounters, dataForAdverseEvents]) => {
        setTotalEncounter(dataForEncounters.data.map(entry => entry.count).reduce((partSum, a) => partSum + a, 0));
        if (!isUndefined(dataForAdverseEvents)) {
          setTotalAdverseEvents(
            dataForAdverseEvents.data.map(entry => entry.count).reduce((partSum, a) => partSum + a, 0)
          );
          setAmountPerCategoryForAdverse({
            DATA_REQUIRED: getAmountByStatus(dataForAdverseEvents.data, DATA_REQUIRED, ADVERSE_EVENTS_WIDGET),
            REVIEW_REQUIRED: getAmountByStatus(dataForAdverseEvents.data, REVIEW_REQUIRED, ADVERSE_EVENTS_WIDGET),
            COMPLETE: getAmountByStatus(dataForAdverseEvents.data, COMPLETE, ADVERSE_EVENTS_WIDGET),
            CANCELED: getAmountByStatus(dataForAdverseEvents.data, CANCELED, ADVERSE_EVENTS_WIDGET)
          });
        }
        setAmountPerCategory({
          DATA_REQUIRED: getAmountByStatus(dataForEncounters.data, DATA_REQUIRED),
          REVIEW_REQUIRED: getAmountByStatus(dataForEncounters.data, REVIEW_REQUIRED),
          COMPLETE: getAmountByStatus(dataForEncounters.data, COMPLETE)
        });
        if (isStatusClearRequired) {
          setCurrentlySelected('');
          onCurrentlySelectedForEncountersChange('');
          setCurrentlySelectedForAdverse('');
          onCurrentlySelectedForAdverseEventsChange('');
        }
      });
    }
  };

  const resetStudies = () => {
    onStudiesChange(studies);
    applyFilters(selectedStudySites, studies, selectedSites, studies, sites);
  };

  const resetSites = () => {
    onSitesChange(sites);
    setTimeout(applyFilters(selectedStudySites, selectedStudies, sites, studies, sites));
  };

  return (
    <InvestigatorWorklistContext.Provider
      value={{
        allStudies,
        allSites,
        studies,
        sites,
        selectedStudies,
        selectedSites,
        selectedStudySites,
        setSelectedStudySites,
        setSelectedStudies,
        totalPages,
        page,
        searchFieldValue,
        statusType,
        patientEncounterIds,
        setStatusType,
        setIsSignedByColumnFetching,
        isSignedByColumnFetching,
        setTableData,
        EncounterWidgetContext: {
          totalEncounter,
          amountPerCategory,
          currentlySelected,
          setCurrentlySelected: status => {
            setCurrentlySelectedForAdverse('');
            setCurrentlySelected(status);
            onCurrentlySelectedForEncountersChange(status);
            setStatusType(ENCOUNTER_WIDGET);
          },
          showComplete,
          setShowComplete: value => {
            setShowComplete(value);
            setSessionStorage({ ...sessionStorage, showComplete: value });
          }
        },
        setSearchFieldValue,
        AdverseEventsWidgetContext: {
          totalEncounter: totalAdverseEvents,
          amountPerCategory: amountPerCategoryForAdverse,
          currentlySelected: currentlySelectedForAdverse,
          setCurrentlySelected: status => {
            setCurrentlySelected('');
            setCurrentlySelectedForAdverse(status);
            onCurrentlySelectedForAdverseEventsChange(status);
            setStatusType(ADVERSE_EVENTS_WIDGET);
            if (!!request) {
              request.cancel();
            }
          },
          showComplete: showCompleteForAdverse,
          setShowComplete: value => {
            setShowCompleteForAdverse(value);
            setSessionStorage({ ...sessionStorage, showCompleteForAdverse: value });
          },
          showCanceled: showCanceledForAdverse,
          setShowCanceled: value => {
            setShowCanceledForAdverse(value);
            setSessionStorage({ ...sessionStorage, showCanceledForAdverse: value });
          }
        },
        pageSize,
        sorted,
        setTotalPages,
        setPage: page => {
          setPage(page);
          setSessionStorage({ ...sessionStorage, page });
        },
        totalEncounters,
        setTotalEncounters,
        setPageSize: pageSize => {
          setPageSize(pageSize);
          setSessionStorage({ ...sessionStorage, pageSize });
        },
        setSorted: sorted => {
          setSorted(sorted);
          setSessionStorage({ ...sessionStorage, sorted });
        },
        tableData,
        isFetching,
        setSelectedSites,
        encounterNames,
        signatures,
        setSignatures,
        updateWithSignature: newSignatures => {
          updateData({ withSignatures: newSignatures });
        },
        setEncounterNames: encounterNames => {
          setEncounterNames(encounterNames);
          setSessionStorage({ ...sessionStorage, encounterNames });
        },
        sessionStorage,
        filter,
        applyFilters: () => applyFilters(selectedStudySites, selectedStudies, selectedSites, studies, sites),
        resetStudies,
        resetSites,
        onStudiesChange,
        onSitesChange,
        validationErrors,
        ssus,
        isLoadingFilters,
        groupsAndSignatures
      }}
    >
      {children}
    </InvestigatorWorklistContext.Provider>
  );
}
