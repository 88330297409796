import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEqual } from 'lodash';
import { last } from 'lodash/array';
import { reduce } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

import TaskApi from '../../../../../api/calendar/TaskApi';
import { afterburnerApi } from '../../../../../api/patient/AftrburnerApi';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { SOMETHING_WENT_WRONG } from '../../../../../constants/notificationMessages';
import openKeepIsHidden from '../../../../root/Container/Layout/Tasks/openKeepIsHidden';
import { generateUrlByKey, useCurrentRoute } from '../../../../root/router';

import { generateResearchConnectStatus } from './ReviewContent/reviewContentService';
import useScrollspy from './ReviewContent/useScrollspy';
import { getRowKeysForSignature } from './ReviewControl/reviewControlService';
import { RowReviewStatus } from './reviewConstants';
import ReviewDataResolver from './ReviewDataResolver';
import { getReviewType } from './reviewService';

export const ReviewContext = React.createContext(null);

export function ReviewProvider({ children }) {
  const navigate = useNavigate();
  const currentRoute = useCurrentRoute();
  const params = currentRoute.params;

  const { ssuPatientId, patientEncounterId, groupAssignId, signatureId: sId } = params;

  const signatureId = sId !== 'current' ? sId : null;

  const reviewType = useMemo(() => getReviewType(currentRoute), [currentRoute]);
  const [itemGroupSnapshotReviewStates, setItemGroupSnapshotReviewStates] = useState({});
  const [logAndLogCheckSnapshotReviewStates, setLogAndLogCheckSnapshotReviewStates] = useState({});
  const [logsAndLogChecks, setLogsAndLogChecks] = useState([]);
  const [itemGroups, setItemGroups] = useState([]);
  const [initialGroupAssignId] = useState(groupAssignId);
  const isHistoricalData = useMemo(() => getIsHistoricalData(signatureId), [signatureId]);
  const [reviewMetaData, setReviewMetaData] = useState(null);
  const [reviewDetails, setReviewDetails] = useState(null);
  const reviewContentRefContainer = useRef(null);
  const [requiredAttentionItemGroupsConfirmStatuses, setRequiredAttentionItemGroupsConfirmStatuses] = useState({});
  const loadData = useCallback(
    function() {
      ReviewDataResolver.getItemGroupsAndLogsByType(reviewType, {
        ssuPatientId,
        patientEncounterId,
        groupAssignId
      }).then(({ data }) => {
        setReviewMetaData(data.reviewMetaData);
        setReviewDetails(data.reviewDetails);
        setItemGroups(
          data.patientEncounterStructure.itemGroups
            .map(({ itemGroupRef }) => itemGroupRef)
            .filter(({ domainCode }) => domainCode !== 'EREVPI' && domainCode !== 'EREVSM') /*TODO: filter on BE*/
        );

        setLogsAndLogChecks(
          sortLogItemGroups(data.protocolLogsAndLogChecks, (a, b) => (a.itemGroupName > b.itemGroupName && 1) || -1)
        );
      });
    },
    [ssuPatientId, patientEncounterId, reviewType, groupAssignId]
  );
  const getRelatedTasksForEncounter = useCallback(
    function() {
      TaskApi.getTasksByPatientIdAndEncounter(ssuPatientId, patientEncounterId).then(({ data }) => {
        if (!isEmpty(data)) {
          openKeepIsHidden(data[0].taskId, data[0].ssuId);
        }
      });
    },
    [patientEncounterId, ssuPatientId]
  );
  const changePageState = useCallback(
    function(newParams) {
      const allParams = { ...currentRoute.params, ...newParams };
      if (isEqual(allParams, currentRoute.params)) {
        return;
      }
      navigate(generateUrlByKey(currentRoute.key, { ...currentRoute.params, ...newParams }), { replace: true });
    },
    [currentRoute.params, currentRoute.key, navigate]
  );

  useEffect(
    function() {
      loadData();
      getRelatedTasksForEncounter();
    },
    [getRelatedTasksForEncounter, loadData]
  ); /*TODO: signatureId need to check to avoid double rerender all data*/

  const getItemGroupData = useCallback(
    function(itemGroupIds, hideLoader = true) {
      function itemGroupsSnapshotGetFromResponse({ data }) {
        setItemGroupSnapshotReviewStates(prevState => ({
          ...prevState,
          ...data.reviewsByItemGroupId
        }));
        const newRequiredAttentionItemGroupsConfirmStatuses = reduce(
          data.reviewsByItemGroupId,
          function(accumulator, itemGroup, key) {
            if (
              itemGroup.reviewState.isAllowSign &&
              itemGroup.itemGroupSnapshotState?.dataStatus?.highlightedAnswersCount > 0
            ) {
              const row = itemGroup.itemGroupSnapshotState?.rows[0];
              const isConfirmed = isRowConfirmed(row);
              accumulator = { ...accumulator, [key]: isConfirmed };
            }
            return accumulator;
          },
          {}
        );
        setRequiredAttentionItemGroupsConfirmStatuses(prevState => ({
          ...prevState,
          ...newRequiredAttentionItemGroupsConfirmStatuses
        }));
      }

      ReviewDataResolver.getItemGroupRowSnapshotReviewStateByTypeAndSignatureId(
        reviewType,
        signatureId,
        {
          ssuPatientId,
          patientEncounterId,
          itemGroupIds,
          groupAssignId
        },
        hideLoader
      ).then(itemGroupsSnapshotGetFromResponse);
    },
    [patientEncounterId, reviewType, signatureId, ssuPatientId, groupAssignId]
  );

  useEffect(
    function() {
      if (isEmpty(itemGroups)) {
        return;
      }

      const itemGroupIdsQueues = collectIncreasinglyGroupedItemGroupsIds(itemGroups);

      itemGroupIdsQueues.forEach(itemGroupIdsQueue => {
        getItemGroupData(itemGroupIdsQueue);
      });
      return function() {
        setItemGroupSnapshotReviewStates({});
      };
    },
    [itemGroups, getItemGroupData]
  );

  const getLogData = useCallback(
    function(patientItemGroupId, itemGroupTemplateId, hideLoader = true) {
      ReviewDataResolver.getLogAndLogCheckSnapshotReviewStateByTypeAndSignatureId(
        reviewType,
        signatureId,
        {
          ssuPatientId,
          patientEncounterId,
          patientItemGroupId: patientItemGroupId,
          itemGroupTemplateId: itemGroupTemplateId,
          groupAssignId
        },
        hideLoader
      ).then(logCheckSnapshotGetFromResponse);

      function logCheckSnapshotGetFromResponse({ data }) {
        setLogAndLogCheckSnapshotReviewStates(prevState => ({
          ...prevState,
          [data.logFormRef.itemGroupTemplateId]: data
        }));

        if (data.reviewState.isAllowSign && data?.dataStatus?.highlightedAnswersCount > 0) {
          const isConfirmed = data?.rows?.every(row => isRowConfirmed(row));
          setRequiredAttentionItemGroupsConfirmStatuses(prevState => ({
            ...prevState,
            [data.logFormRef.itemGroupTemplateId]: isConfirmed
          }));
        }
      }
    },
    [patientEncounterId, reviewType, signatureId, ssuPatientId, groupAssignId]
  );

  useEffect(
    function() {
      if (isEmpty(logsAndLogChecks)) {
        return;
      }
      logsAndLogChecks.forEach(item => {
        //TODO: check permissionType
        getLogData(item.patientItemGroupId, item.itemGroupTemplateId);
      });
      return function() {
        setLogAndLogCheckSnapshotReviewStates({});
      };
    },
    [logsAndLogChecks, getLogData]
  );

  const sendDataToResearchConnect = useCallback(
    function() {
      const dataForSend = {
        ssuPatientId,
        patientEncounterId,
        request: getRowKeysForSignature(itemGroupSnapshotReviewStates, logAndLogCheckSnapshotReviewStates)
      };
      afterburnerApi
        .postToAfterburner(dataForSend)
        .then(({ data }) => {
          const afterburnerStatus = generateResearchConnectStatus(data);
          if (afterburnerStatus) {
            NotificationManager.info(afterburnerStatus);
          }
        })
        .catch(() => {
          NotificationManager.error(SOMETHING_WENT_WRONG);
        });
    },
    [itemGroupSnapshotReviewStates, logAndLogCheckSnapshotReviewStates, patientEncounterId, ssuPatientId]
  );

  const ids = useMemo(
    function() {
      return [
        ...itemGroups.map(({ patientItemGroupId }) => patientItemGroupId),
        ...logsAndLogChecks.map(({ itemGroupTemplateId }) => itemGroupTemplateId)
      ];
    },
    [itemGroups, logsAndLogChecks]
  );

  const activeScrollId = useScrollspy(ids, reviewContentRefContainer.current);

  return (
    <ReviewContext.Provider
      value={{
        itemGroups,
        initialGroupAssignId,
        logsAndLogChecks,
        logAndLogCheckSnapshotReviewStates,
        itemGroupSnapshotReviewStates,
        changePageState,
        ssuPatientId,
        patientEncounterId,
        reviewType,
        isHistoricalData,
        loadData,
        reviewMetaData,
        requiredAttentionItemGroupsConfirmStatuses,
        setRequiredAttentionItemGroupsConfirmStatuses,
        updateItemGroupData: getItemGroupData,
        updateLogData: getLogData,
        reviewContentRefContainer,
        sendDataToResearchConnect,
        reviewDetails,
        activeScrollId
      }}
    >
      {children}
    </ReviewContext.Provider>
  );
}

function isRowConfirmed(row) {
  if (row.rowReviewStatus === RowReviewStatus.NEVER_SIGNED) {
    return false;
  }
  return row.rowReviewStatus === RowReviewStatus.SIGNED || !containsHighlightedEdits(row);
}

function containsHighlightedEdits(row) {
  const answers = row?.rowSnapshotFormData?.form?.answers;
  const editedAfterSignQuestionIds = row?.editedAfterSignQuestionIds;
  const isRowEditedAfterSign = row.rowReviewStatus === RowReviewStatus.EDITED_AFTER_SIGN;
  return (
    isRowEditedAfterSign &&
    answers?.some(answer => answer.highlighted && editedAfterSignQuestionIds.includes(answer.itemGroupQuestionId))
  );
}

function getIsHistoricalData(signatureId) {
  return !!signatureId;
}

function sortLogItemGroups(items, compare) {
  /*TODO: try to replace with lodash sorting*/
  return items && items?.length > 1 ? items.sort(compare) : items;
}

function collectIncreasinglyGroupedItemGroupsIds(itemGroups) {
  return itemGroups.reduce(
    function(accumulator, { patientItemGroupId }, index) {
      const lastGroup = last(accumulator),
        lastGroupLength = lastGroup.length,
        lastIndex = accumulator.length,
        maxLengthInGroup = lastIndex * 2;

      if (lastGroupLength < maxLengthInGroup || itemGroups.length - 1 - index < maxLengthInGroup) {
        lastGroup.push(patientItemGroupId);
      } else {
        accumulator.push([patientItemGroupId]);
      }
      return accumulator;
    },
    [[]]
  );
}
