import React from 'react';

import Select from '../../common/data-entry/Select';

export function SSUFilterRepresentation({
  studies,
  sites,
  handleSiteChange,
  handleStudyChange,
  isRequired,
  initialStudy,
  initialSite,
  isStudyRequired,
  clearable
}) {
  return (
    <>
      <Select
        label="Study"
        clearSearchOnSelection
        dataSource={studies}
        optionLabelKey="studyName"
        optionValueKey="uniqueIdentifier"
        onChange={handleStudyChange}
        searchable
        required={isRequired}
        value={initialStudy}
        clearable={!isStudyRequired || clearable}
        deselectOnSelectedOptionClick={!isStudyRequired}
        validate={false}
        data-testid="study-selector"
      />
      <Select
        label="Site"
        clearSearchOnSelection
        optionLabelKey="siteName"
        optionValueKey="uniqueIdentifier"
        dataSource={sites}
        onChange={handleSiteChange}
        value={initialSite}
        searchable
        required={isRequired}
        validate={false}
        data-testid="site-selector"
      />
    </>
  );
}
