import axios from 'axios';

const ROOT = 'api/v1/patients';

const LogTableEncounterApi = {
  getCheckedInEncountersForPatient(ssuPatientId) {
    return axios.get(`${ROOT}/${ssuPatientId}/checked-in-encounters`);
  }
};

export default LogTableEncounterApi;
