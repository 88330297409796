import axios from 'axios';

const ROOT = 'api/v1/maps';

const MapsApi = {
  autocompleteCity(data) {
    return axios.post(`${ROOT}/autocomplete/city`, data, { hideLoader: true });
  },
  autocompleteAddress(data) {
    return axios.post(`${ROOT}/autocomplete/address`, data, { hideLoader: true });
  },
  validateAddress(data) {
    return axios.post(`${ROOT}/validate-address`, data, { hideLoader: true });
  },
  getDistance(data) {
    return axios.post(`${ROOT}/distance`, data, { hideLoader: true });
  }
};

export default MapsApi;
