import axios from 'axios';

const ROOT = 'api/v1/patient-info';

const PatientInfoPageApi = {
  getPatientRelatedStudies(patientId) {
    return axios.get(`${ROOT}/patient/${patientId}/study-history`, {
      hideLoader: false
    });
  },
  getPatientInfo(patientId) {
    return axios.get(`${ROOT}/patient/${patientId}`, { hideLoader: false });
  },
  getPatientSitesForTransferring(patientId, studyId) {
    return axios.get(`${ROOT}/patient/${patientId}/study/${studyId}/transfer/site`, { hideLoader: true });
  },
  updatePatientInfo(patientId, data) {
    return axios.put(`${ROOT}/patient/${patientId}`, data, { hideLoader: true });
  },
  updatePatientInfoWithSsu(patientId, data, studyId) {
    return axios.put(`${ROOT}/patient/${patientId}?studyId=${studyId}`, data, { hideLoader: true });
  },
  updateSsuPatientMedicalRecordNumber(sitePatientUniqueIdentifier, medicalRecordNumber) {
    return axios.put(
      `${ROOT}/ssu-patient/${sitePatientUniqueIdentifier}/medicalRecordNumber?medicalRecordNumber=${medicalRecordNumber}`,
      {},
      { hideLoader: true }
    );
  },
  getSitePatientLogs(ssuPatientId) {
    return axios.get(`${ROOT}/ssu-patient/${ssuPatientId}/logs`, {
      hideLoader: true
    });
  },
  getPatientFilesCountByPatientId(patientId) {
    return axios.get(`${ROOT}/patients/${patientId}/files/count`, {
      hideLoader: true
    });
  },
  getPatientFilesByPatientId(patientId) {
    return axios.get(`${ROOT}/patients/${patientId}/files`, {
      hideLoader: true
    });
  },
  uploadPatientFiles(patientId, ssuPatientId, data) {
    return axios.post(`${ROOT}/patients/${patientId}/ssu-patients/${ssuPatientId}/file-upload`, data, {
      hideLoader: true
    });
  },
  downloadPatientFile(patientId, patientFileId) {
    return axios.get(`${ROOT}/patients/${patientId}/files/${patientFileId}/download`, {
      hideLoader: true,
      responseType: 'blob'
    });
  },
  deletePatientFile(patientId, ssuPatientId, patientFileId) {
    return axios.delete(`${ROOT}/patients/${patientId}/ssu-patients/${ssuPatientId}/files/${patientFileId}`, {
      hideLoader: true
    });
  },
  getPatientInstructionsByPatientId(patientId) {
    return axios.get(`${ROOT}/patients/${patientId}/instructions`, {
      hideLoader: true
    });
  },
  addPatientInstruction(patientId, instruction) {
    return axios.post(`${ROOT}/patients/${patientId}/instruction`, { instruction }, { hideLoader: true });
  },
  deletePatientInstruction(patientId, patientInstructionId) {
    return axios.delete(`${ROOT}/patients/${patientId}/instruction/${patientInstructionId}`, {
      hideLoader: true
    });
  },
  updatePatientInstruction(patientId, patientInstructionId, instruction) {
    return axios.put(
      `${ROOT}/patients/${patientId}/instruction/${patientInstructionId}`,
      { instruction },
      { hideLoader: true }
    );
  },
  getPatientActiveEncountersWithAppointments(ssuPatientId, page, elementsCount) {
    return axios.get(
      `${ROOT}/ssu-patient/${ssuPatientId}/encounters/active/page/${page}/elements-count/${elementsCount}`,
      {
        hideLoader: true
      }
    );
  },
  getPatientCanceledEncountersWithAppointments(ssuPatientId, page, elementsCount) {
    return axios.get(
      `${ROOT}/ssu-patient/${ssuPatientId}/encounters/canceled/page/${page}/elements-count/${elementsCount}`,
      {
        hideLoader: true
      }
    );
  },
  getPatientProjectedEncountersWithAppointments(ssuPatientId, page, elementsCount) {
    return axios.post(
      `${ROOT}/ssu-patient/${ssuPatientId}/encounters/projected/page/${page}/elements-count/${elementsCount}`,
      null,
      {
        hideLoader: true
      }
    );
  },
  updatePatientReimbursementEmailNotifications(patientId, reimbursementEmailNotifications) {
    return axios.patch(
      `${ROOT}/patients/${patientId}/reimbursement-email-notifications/${reimbursementEmailNotifications}`
    );
  }
};

export default PatientInfoPageApi;
