import React, { useCallback, useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash/lang';
import * as PropTypes from 'prop-types';

import { PatientEncounterReviewApi } from '../../../../../../../../api';
import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { TEXT_BLOCK_LONG } from '../../../../../../../../constants/inputTypes';
import { COMMENT_FORM_QUESTION_ID } from '../../../../../../../../constants/protocolConstant';
import LogsTable from '../../../../../Patients/Logs/LogsTable';
import { CustomCell, defaultCellWidth } from '../../../../../Patients/Logs/LogsTableService';
import { ReviewContext } from '../../../ReviewContext';
import ElementHistoryLogModal from '../../HistoryLogModal/ElementHistoryLogModal';
import { useReviewContentElement } from '../../ReviewContentElementContext/ReviewContentElementContext';
import { openCommentModalLinkContent } from '../../reviewContentService';
import * as reviewContentService from '../../reviewContentService';
import TableCellAnswer from '../../TableCellAnwer/TableCellAnswer';
import { AddCommentModal } from '../AddCommentModal/AddCommentModal';
import * as itemGroupTableViewService from '../ItemGroupTableViewService';
import { MIN_WIDTH_OF_REVIEW_TABLE_COLUMN } from '../ItemGroupTableViewService';

import './ItemGroupTable.scss';

const tableRowNameAccessor = 'tableRowName';
const encounterNameAccessor = 'encounterName';
export default function ItemGroupTable({ itemGroupSnapshotState }) {
  const {
    patientEncounterId,
    ssuPatientId,
    loadData,
    reviewMetaData,
    isHistoricalData,
    updateItemGroupData,
    reviewType
  } = useContext(ReviewContext);
  const { isAllowSign, commentIsReadonly } = useReviewContentElement();

  const [tableRenderData, setTableRenderData] = useState([]);
  const [columns, setColumns] = useState([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const prepareTableDataToRender = useCallback(handlePrepareTableDataToRender, [itemGroupSnapshotState]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const prepareColumns = useCallback(handlePrepareColumns, [itemGroupSnapshotState, tableRenderData]);

  function openTableHistory(row) {
    const ssuPatientEncounterId = itemGroupSnapshotState.itemGroupRef.patientEncounterId;
    const itemGroupId = itemGroupSnapshotState.itemGroupRef.patientItemGroupId;
    const rowId = row.original.rowId;
    const itemGroupName = itemGroupSnapshotState.itemGroupRef.patientItemGroupName;
    const onLoadData = () =>
      PatientEncounterReviewApi.getItemGroupAnswerHistory(ssuPatientId, ssuPatientEncounterId, itemGroupId, rowId);
    ModalBoxes.open({
      component: <ElementHistoryLogModal itemGroupName={itemGroupName} onLoadData={onLoadData} />,
      title: `${itemGroupName}`
    });
  }

  useEffect(
    function() {
      setTableRenderData(prepareTableDataToRender);
    },
    [prepareTableDataToRender]
  );

  useEffect(
    function() {
      setColumns(prepareColumns);
    },
    [prepareColumns]
  );

  return (
    <div className="erc-item-group-element-table-reflection">
      <LogsTable
        columns={columns}
        data={tableRenderData}
        getTrProps={getTrProps}
        sortable={false}
        showPagination={false}
        defaultCellWidth={defaultCellWidth}
        pageSize={tableRenderData?.length || 1}
      />
    </div>
  );

  function openModal({ original }, commentAnswer) {
    if (isEmpty(commentAnswer?.stringValue) && commentIsReadonly) {
      return;
    }
    const patientItemGroupId = itemGroupSnapshotState.itemGroupRef.patientItemGroupId;
    ModalBoxes.open({
      component: (
        <AddCommentModal
          context={{
            patientEncounterId,
            ssuPatientId,
            loadData,
            reviewType,
            isHistoricalData,
            studySiteStatus: reviewMetaData.studySiteStatus
          }}
          updateData={updateItemGroupData}
          snapshotData={{ ...original, commentAnswer }}
          patientItemGroupId={patientItemGroupId}
          readonly={commentIsReadonly}
        />
      )
    });
  }

  function handlePrepareColumns() {
    const itemGroupTemplate = itemGroupSnapshotState?.itemGroupTemplateVersion;
    const columns = [];

    const tablePredefinedColumnName = itemGroupTemplate?.predefinedColumn?.name;
    //Add table row name column
    columns.push({
      Header: tablePredefinedColumnName,
      accessor: tableRowNameAccessor,
      className: 'text-left',
      Cell: ({ value }) => <CustomCell shortAnswer={value} longAnswer={value} />
    });

    // Add encounter column
    columns.push({
      Header: 'Encounter',
      accessor: encounterNameAccessor,
      className: 'text-left pl-2',
      minWidth: 120,
      Cell: ({ value }) => {
        return <span className="encounter-name-value">{value}</span>;
      }
    });

    const formQuestions = itemGroupTemplate?.itemGroupVersionQuestions;

    //Add columns for configured answers
    formQuestions.forEach(formQuestion => {
      if (formQuestion.type === TEXT_BLOCK_LONG) {
        return false;
      }
      const minWidth = calculateMinWidth(formQuestion, tableRenderData);
      columns.push({
        // Color out required attention cells
        getProps: (state, row) => {
          if (row.original.isTableRowPopulated === true) {
            const answer = row.original?.answers[formQuestion.questionId];
            if (answer) {
              return reviewContentService.getAnswerHighlightStyleClassByAnswerAndReviewType(
                answer,
                reviewType,
                isAllowSign
              );
            }
          }
          return {};
        },
        id: formQuestion.questionId,
        Header: formQuestion.label,
        Cell: row => {
          if (row.original.isTableRowPopulated === true) {
            const answer = row.original?.answers[formQuestion.questionId];
            if (answer) {
              return <TableCellAnswer answer={answer} isAnswerEdited={answer.isEdited} />;
            }
          }
          return '--';
        },
        minWidth
      });
    });
    //Add comment column
    columns.push({
      getProps: (state, row) => {
        if (row.original.isTableRowPopulated === true) {
          const commentAnswer = row.original?.answers[COMMENT_FORM_QUESTION_ID];
          return reviewContentService.getAnswerHighlightStyleClassByAnswerAndReviewType(
            commentAnswer,
            reviewType,
            isAllowSign
          );
        }
        return {};
      },
      Header: 'Comments',
      className: 'edited-link text-left pl-2',
      width: 115,
      Cell: row => {
        if (row.original.isTableRowPopulated === true) {
          const answers = row.original?.answers;
          const commentAnswer = answers?.[COMMENT_FORM_QUESTION_ID];
          const textInsideOpenModalButton = openCommentModalLinkContent(
            commentAnswer,
            reviewType,
            isHistoricalData,
            reviewMetaData.studySiteStatus
          );
          return (
            <span
              className={cx('edited-link', { 'change-bullet': commentAnswer?.isEdited })}
              onClick={() => {
                openModal(row, commentAnswer);
              }}
            >
              {textInsideOpenModalButton}
            </span>
          );
        }
        return '';
      }
    });

    //Add history column
    columns.push({
      Header: 'History',
      className: 'edited-link text-left pl-2',
      width: 75,
      getProps: (state, rowInfo) => ({
        onClick: () => openTableHistory(rowInfo)
      }),
      Cell: row => (row.original.isTableRowPopulated === true && !isHistoricalData ? 'History' : '')
    });

    return columns;
  }

  function calculateMinWidth(formQuestion, dataToRender) {
    return (
      Math.max(
        ...dataToRender.map(tableRowDataToRender => {
          if (tableRowDataToRender.isTableRowPopulated === true) {
            const answer = tableRowDataToRender.answers[formQuestion.questionId];
            return itemGroupTableViewService.getWidthOfTableColumnByAnswer(answer);
          } else return MIN_WIDTH_OF_REVIEW_TABLE_COLUMN;
        })
      ) || MIN_WIDTH_OF_REVIEW_TABLE_COLUMN
    );
  }

  function handlePrepareTableDataToRender() {
    const encounterName = reviewMetaData?.encounterName || '';
    return itemGroupSnapshotState.itemGroupTemplateVersion.predefinedColumn.values.map(tableRowName => {
      const tableRowToRender = {};
      populateTableRowName(tableRowToRender, tableRowName);

      populateEncounterName(tableRowToRender, encounterName);

      const tableRowSnapshotState = itemGroupSnapshotState.rows.find(
        row => row.rowRef.predefinedValue === tableRowToRender[tableRowNameAccessor]
      );
      populateIsTableRowPopulatedFlag(tableRowToRender, tableRowSnapshotState);
      populateTableRowAnswers(tableRowToRender, tableRowSnapshotState);
      populateSitePatientAndRowId(tableRowToRender, tableRowSnapshotState);
      populateSitePatientAndRowId(tableRowToRender, tableRowSnapshotState);
      populateWhenAndWhoIsDone(tableRowToRender, tableRowSnapshotState);
      populateTableRowReviewStatus(tableRowToRender, tableRowSnapshotState);

      return tableRowToRender;
    });

    function populateTableRowName(tableRowToRender, tableRowName) {
      tableRowToRender[tableRowNameAccessor] = tableRowName;
    }

    function populateEncounterName(tableRowToRender, encounterName) {
      tableRowToRender.encounterName = encounterName;
    }

    function populateIsTableRowPopulatedFlag(tableRowToRender, tableRowSnapshotState) {
      if (tableRowSnapshotState) {
        tableRowToRender.isTableRowPopulated = true;
      } else {
        tableRowToRender.isTableRowPopulated = false;
      }
    }

    function populateTableRowAnswers(tableRowToRender, tableRowSnapshotState) {
      if (tableRowSnapshotState?.rowSnapshotFormData?.form?.answers) {
        const tableRowAnswers = tableRowSnapshotState?.rowSnapshotFormData.form.answers;
        const answersByQuestionId = [];
        tableRowAnswers.forEach(answer => {
          answersByQuestionId[answer.itemGroupQuestionId] = answer;
          answer.isEdited = tableRowSnapshotState?.editedQuestionIds?.includes(answer.itemGroupQuestionId);
          answer.isEditedAfterSign = tableRowSnapshotState?.editedAfterSignQuestionIds?.includes(
            answer.itemGroupQuestionId
          );
        });
        tableRowToRender.answers = answersByQuestionId;
      }
    }

    function populateSitePatientAndRowId(tableRowToRender, tableRowState) {
      tableRowToRender.ssuPatientId = tableRowState?.rowSnapshotKey.rowKey.ssuPatientId;
      tableRowToRender.rowVersion = tableRowState?.rowSnapshotKey.rowVersion;
      tableRowToRender.rowId = tableRowState?.rowSnapshotFormData.rowId;
      tableRowToRender.studyId = tableRowState?.rowSnapshotKey.rowKey.studyId;
    }

    function populateWhenAndWhoIsDone(tableRowToRender, tableRowState) {
      if (tableRowState) {
        const { whenWasItDone, whoDidItName } = tableRowState?.rowSnapshotFormData?.form;
        tableRowToRender.whenWasItDone = whenWasItDone;
        tableRowToRender.whoDidItName = whoDidItName;
      }
    }

    function populateTableRowReviewStatus(tableRowToRender, tableRowSnapshotState) {
      tableRowToRender.rowReviewStatus = tableRowSnapshotState?.rowReviewStatus;
    }
  }

  function getTrProps(state, rowInfo) {
    const rowReviewStatus = rowInfo?.row?._original?.rowReviewStatus;
    return reviewContentService.getFormHighlightStyleBasedOnRowReviewStatusAndReviewType(
      rowReviewStatus,
      reviewType,
      isAllowSign
    );
  }
}

ItemGroupTable.propTypes = {
  itemGroupSnapshotState: PropTypes.object
};
