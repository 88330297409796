import { loadPatientsRouterConfig } from './LoadPatients/routerConfig';
import { newWorklistRouterConfig } from './NewWorklist/routerConfig';
import { patientBulkUpdateRouterConfig } from './PatientBulkUpdate/routerConfig';
import { worklistRouterConfig } from './Worklist/routerConfig';

export const preScreenRouterConfig = [
  loadPatientsRouterConfig,
  worklistRouterConfig,
  patientBulkUpdateRouterConfig,
  newWorklistRouterConfig
];
