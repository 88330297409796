import React, { useContext } from 'react';
import { UNSAFE_NavigationContext, useLocation, useNavigate, useParams } from 'react-router-dom';

export function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

export function withNavigate(Component) {
  return props => <Component {...props} navigate={useNavigate()} />;
}

export function withLocation(Component) {
  return props => <Component {...props} location={useLocation()} />;
}

export function withNavigator(Component) {
  return function(props) {
    const navigator = useContext(UNSAFE_NavigationContext).navigator;
    return <Component {...props} navigator={navigator} />;
  };
}
