import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import StyledTooltip from '../../../../../../../../common/widgets/StyledToolTip';

import HighlightedPartOfWord from './HighlightedPartOfWord';

const HighlightWithTooltip = ({ word = '', referredLength = 25, reg }) => {
  const classNameForLongValue = cx('value-label', {
    found: reg && word.match(reg)
  });
  if (word.length > referredLength) {
    return (
      <StyledTooltip
        className="tooltip-note-value"
        overlay={<div className="note-value">{word}</div>}
        placement="bottom"
      >
        <span className={classNameForLongValue}>{`${word.substr(0, referredLength)}...`}</span>
      </StyledTooltip>
    );
  }
  return <HighlightedPartOfWord word={word} reg={reg} />;
};

HighlightWithTooltip.propTypes = {
  word: PropTypes.string,
  referredLength: PropTypes.number
};

export default HighlightWithTooltip;
