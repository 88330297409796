import axios from 'axios';

const ROOT = 'api/v1/ssu-patients';

const PatientEncounterReviewCommentApi = {
  saveCommentOnReview(ssuPatientId, reviewItemGroupId, patientEncounterFormId, formCommentRequest, hideLoader = true) {
    return axios.post(
      `${ROOT}/${ssuPatientId}/reviews/${reviewItemGroupId}/item-groups/${patientEncounterFormId}/comments`,
      { ...formCommentRequest },
      { hideLoader }
    );
  },

  saveCommentOnReviewForLogItemGroup(
    ssuPatientId,
    reviewItemGroupId,
    itemGroupTemplateId,
    itemGroupTemplateVersionId,
    formCommentRequest,
    hideLoader = true
  ) {
    return axios.post(
      `${ROOT}/${ssuPatientId}/reviews/${reviewItemGroupId}/logs/${itemGroupTemplateId}/${itemGroupTemplateVersionId}/comments`,
      { ...formCommentRequest },
      { hideLoader }
    );
  }
};

export default PatientEncounterReviewCommentApi;
