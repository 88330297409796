import React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash/lang';

import Checkbox from '../../../../../../common/data-entry/InputSelectors/Checkbox';
import { useExportCaseReportFormsActions, useExportCaseReportFormsState } from '../ExportCaseReportFormsContext';

export default function EncountersSelector() {
  const { allEncounters, selectedItemGroupsMap, selectedEncounter, selectedType } = useExportCaseReportFormsState();
  const { onEncounterTypeCheckboxClick, setSelectedEncounter } = useExportCaseReportFormsActions();
  return (
    <div className={cx('encounters-selector', { 'hide-selector-form': selectedType !== 'ENCOUNTERS' })}>
      <h3>Encounters</h3>
      {!isEmpty(allEncounters) ? (
        <div className="ecr-options">
          {allEncounters.map(function(encounter) {
            const { id, name, itemGroups } = encounter;

            const { allIsChecked, someIsChecked } = getCheckboxState(id, itemGroups);
            return (
              <div
                key={id}
                className={cx('ecr-option', { 'ecr-option-selected': selectedEncounter?.id === id })}
                onClick={() => setSelectedEncounter(encounter)}
              >
                <Checkbox
                  checked={allIsChecked}
                  indeterminate={someIsChecked}
                  onClick={function() {
                    if (!someIsChecked && !allIsChecked) {
                      onEncounterTypeCheckboxClick({ [id]: itemGroups });
                      return;
                    }
                    onEncounterTypeCheckboxClick({ [id]: undefined });
                  }}
                />
                <span>{name}</span>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="alert-unavailable-items">There are no encounters for this patient.</div>
      )}
    </div>
  );

  function getCheckboxState(key, itemGroups) {
    const selectedItemGroups = selectedItemGroupsMap[key];
    if (selectedItemGroups?.length === itemGroups.length) {
      return { allIsChecked: true, someIsChecked: false };
    }
    if (selectedItemGroups) {
      return { allIsChecked: false, someIsChecked: true };
    }
    return { allIsChecked: false, someIsChecked: false };
  }
}
