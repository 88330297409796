import { uniq } from 'lodash/array';
import { isEmpty } from 'lodash/lang';
import moment from 'moment';

import { canadianProvinces, countries, usStates } from '../../../../services/geographic';

const processNumber = patient => {
  const { phone } = patient;
  const digits = phone.replace(/[^\d]/g, '');
  const validDigits = digits.length === 11 && digits.startsWith('1') ? digits.substring(1) : digits;
  if (validDigits.length === 10) {
    return `${validDigits.substr(0, 3)}-${validDigits.substr(3, 3)}-${validDigits.substr(6)}`;
  }
  return { error: 'Phone is incorrect' };
};

const addIssue = (updatedIssues, row, message) => {
  const currentIssues = updatedIssues.get(row) || [];
  updatedIssues.set(row, [...currentIssues, message]);
};

const getCountry = countryName => {
  return (
    countries.find(
      c => c.id.toLowerCase() === countryName.toLowerCase() || c.name.toLowerCase() === countryName.toLowerCase()
    )?.id || null
  );
};

const getState = (stateName, countryId) => {
  const stateList = countryId === 'US' ? usStates : countryId === 'CA' ? canadianProvinces : null;
  return (
    stateList?.find(
      s => s.id.toLowerCase() === stateName.toLowerCase() || s.name.toLowerCase() === stateName.toLowerCase()
    )?.id || null
  );
};

const transformGeographicData = patient => {
  if (patient.country) {
    patient.country = getCountry(patient.country);
  }

  if (patient.state) {
    if (!patient.country) {
      patient.country = getCountry('US') || getCountry('CA');
    }
    patient.state = getState(patient.state, patient.country);
  } else if (!patient.country) {
    patient.country = 'US';
  }

  return patient;
};

const postalCodeRegex = country =>
  country === 'US'
    ? /^[0-9]{5}(?:-[0-9]{4})?$/
    : /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$/;

const normalizeSingularEntry = (data, mapping) => {
  data = data.trim().toLowerCase();
  const key = Object.keys(mapping).find(key => key.toLowerCase() === data || mapping[key].toLowerCase() === data);
  return key ? mapping[key] : null;
};

const normalizePluralEntries = (data, mapping) => {
  const splitData = data.split('|').map(item => item.trim().toLowerCase());
  const result = splitData
    .map(item => {
      const key = Object.keys(mapping).find(key => key.toLowerCase() === item || mapping[key].toLowerCase() === item);
      return mapping[key] || null;
    })
    .filter(item => item !== null);
  return uniq(result);
};

const normalizeEthnicities = ethnicitiesInput => {
  const ethnicityMapping = {
    'Not Hispanic, Latino/a, or Spanish origin': 'NOT_HISPANIC',
    'Mexican, Mexican American, Chicano/a': 'MEXICAN',
    'Puerto Rican': 'PUERTO_RICAN',
    Cuban: 'CUBAN',
    'Another Hispanic, Latino/a or Spanish origin': 'OTHER_HISPANIC'
  };
  return normalizePluralEntries(ethnicitiesInput, ethnicityMapping);
};

const normalizeRaces = raceInput => {
  const raceMapping = {
    White: 'WHITE',
    'Black or African American': 'BLACK',
    'American Indian or Alaska Native': 'AMERICAN_INDIAN',
    'Asian Indian': 'ASIAN_INDIAN',
    Chinese: 'CHINESE',
    Filipino: 'FILIPINO',
    Japanese: 'JAPANESE',
    Korean: 'KOREAN',
    Vietnamese: 'VIETNAMESE',
    'Other Asian': 'OTHER_ASIAN',
    'Native Hawaiian': 'HAWAIIAN',
    'Guamanian or Chamarro': 'GUAMANIAN',
    Samoan: 'SAMOAN',
    'Other Pacific Islander': 'OTHER_PACIFIC'
  };
  return normalizePluralEntries(raceInput, raceMapping);
};

const normalizeSex = input => {
  const sexMapping = {
    F: 'FEMALE',
    M: 'MALE'
  };
  return normalizeSingularEntry(input, sexMapping);
};

const normalizePhoneType = input => {
  const phoneTypeMapping = {
    Mobile: 'M',
    Landline: 'L'
  };
  return normalizeSingularEntry(input, phoneTypeMapping);
};

const normalizePreferredContact = input => {
  const preferredContactMapping = {
    Phone: 'PH',
    SMS: 'SMS',
    Email: 'EM'
  };
  return normalizeSingularEntry(input, preferredContactMapping);
};

const normalizePrimaryLanguage = input => {
  const primaryLanguageMapping = {
    English: 'EN',
    Spanish: 'ES',
    Mandarin: 'MD',
    Cantonese: 'CA',
    Tagalog: 'TL',
    Arabic: 'AR',
    Vietnamese: 'VI',
    Korean: 'KO',
    Other: 'OT'
  };
  return normalizeSingularEntry(input, primaryLanguageMapping);
};

const normalizeOptIn = input => {
  const optInMapping = {
    yes: 'YES',
    y: 'YES',
    no: 'NO',
    n: 'NO'
  };
  return normalizeSingularEntry(input, optInMapping);
};

export const validateAndFormatPatientData = (initialIssues, patientData) => {
  const updatedIssues = new Map(initialIssues);
  const updatedPatients = patientData.map(patient => {
    patient = transformGeographicData(patient);

    const {
      address1,
      address2,
      city,
      state,
      zip,
      country,
      dob,
      email,
      ethnicities,
      firstName,
      height,
      instructions,
      lastName,
      optIn,
      phone,
      phoneType,
      preferredContactMethod,
      primaryLanguage,
      pronouns,
      races,
      row,
      sex,
      weight
    } = patient;

    let updatedPatient = { ...patient };

    if (isEmpty(firstName) || isEmpty(lastName)) {
      addIssue(updatedIssues, row, 'Both first and last name are required');
    } else {
      updatedPatient.firstName = firstName.trim();
      updatedPatient.lastName = lastName.trim();
    }

    if (!dob) {
      addIssue(updatedIssues, row, 'Missing date of birth');
    } else {
      const parsedDate = moment(dob, 'M/D/YYYY', true);
      if (parsedDate.isValid()) {
        const earliestValidDate = moment().subtract(110, 'years');
        const latestValidDate = moment();

        if (parsedDate.isBefore(latestValidDate) && parsedDate.isAfter(earliestValidDate)) {
          updatedPatient.dob = parsedDate.format('YYYY-MM-DD');
        } else {
          addIssue(updatedIssues, row, 'Date of birth is not within the valid range');
        }
      } else {
        addIssue(updatedIssues, row, 'Date of birth is incorrect. Use the format: M/D/YYYY');
      }
    }

    if (isEmpty(phone) && isEmpty(email)) {
      addIssue(updatedIssues, row, 'Either phone or email is required');
    }

    if (phone) {
      const phoneResult = processNumber(patient);
      if (phoneResult.error) {
        addIssue(updatedIssues, row, phoneResult.error);
      } else {
        updatedPatient.phone = phoneResult;
      }
    }

    if (phoneType) {
      updatedPatient.phoneType = normalizePhoneType(phoneType);
    } else if (phoneType && !phone) {
      delete updatedPatient.phoneType;
    }

    if (email) {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (!emailPattern.test(email.trim())) {
        addIssue(updatedIssues, row, 'Email is not valid');
      } else {
        updatedPatient.email = email.trim();
      }
    }

    if (height) {
      const parsedHeight = parseInt(height, 10);
      if (isNaN(parsedHeight) || parsedHeight < 24 || parsedHeight > 107) {
        delete updatedPatient.height;
      } else {
        updatedPatient.height = parsedHeight;
      }
    }

    if (weight) {
      const parsedWeight = parseInt(weight, 10);
      if (isNaN(parsedWeight) || parsedWeight > 999) {
        delete updatedPatient.weight;
      } else {
        updatedPatient.weight = parsedWeight;
      }
    }

    if (pronouns) {
      const lowercasedPronouns = pronouns.trim().toLowerCase();
      if (lowercasedPronouns === 'she/her') {
        updatedPatient.pronouns = 'SHE';
      } else if (lowercasedPronouns === 'he/him') {
        updatedPatient.pronouns = 'HE';
      } else if (lowercasedPronouns === 'they/them') {
        updatedPatient.pronouns = 'THEY';
      } else {
        delete updatedPatient.pronouns;
      }
    }

    if (zip && country && !zip.match(postalCodeRegex(country))) {
      delete updatedPatient.zip;
    }

    updatedPatient.ethnicities = ethnicities ? normalizeEthnicities(ethnicities) : [];
    updatedPatient.races = races ? normalizeRaces(races) : [];
    updatedPatient.sex = sex ? normalizeSex(sex) : null;
    updatedPatient.preferredContactMethod = preferredContactMethod
      ? normalizePreferredContact(preferredContactMethod)
      : null;
    updatedPatient.primaryLanguage = primaryLanguage ? normalizePrimaryLanguage(primaryLanguage) : null;
    updatedPatient.optIn = optIn ? normalizeOptIn(optIn) : null;

    Object.keys(updatedPatient).forEach(key => {
      if (updatedPatient[key] === null || (Array.isArray(updatedPatient[key]) && updatedPatient[key].length === 0)) {
        delete updatedPatient[key];
      }
    });

    return updatedPatient;
  });

  return { issues: updatedIssues, patientData: updatedPatients };
};
