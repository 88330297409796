import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import moment from 'moment';

import Icon from '../../../../../common/general/Icon';

import { getReview, getUrlToEncounterDetailsPage } from './AwaitingSignOffTopTenWidgetTable';

import './AwaitingSignOffTopTenWidgetList.scss';
import '../Widgets.scss';

export default function AwaitingSignOffTopTenWidgetList({ showColumns, data, showIconsMode }) {
  return (
    <div className="widget-list-container">
      {data.map((el, index) => {
        return (
          <div className={cx('widget-list-el')} key={index}>
            <div className="left">
              <div className="header">
                {showColumns.includes('Encounter') && (
                  <div style={{ fontWeight: 'bold' }}>
                    <Link to={getUrlToEncounterDetailsPage(el)}>{el.encounterName}</Link>
                  </div>
                )}
              </div>

              {(showColumns.includes('Patient') || showColumns.includes('Subject ID')) && (
                <div style={{ display: 'flex' }}>
                  {showColumns.includes('Patient') && (
                    <h5>
                      {el.patientFirstName} {el.patientLastName}
                    </h5>
                  )}
                  {showColumns.includes('Subject ID') && (
                    <span className="pl-2">{el.subjectId ? ` (${el.subjectId})` : ''}</span>
                  )}
                </div>
              )}

              <div className="header">
                {(showColumns.includes('Study') || showColumns.includes('Site')) && (
                  <div>
                    {showColumns.includes('Study') && (
                      <div className="icon-text">
                        {showIconsMode && <Icon suit="material-outline">school</Icon>} {el.studyName}
                      </div>
                    )}
                    {showColumns.includes('Site') && (
                      <div className="icon-text">
                        {showIconsMode && <Icon suit="material-outline">apartment</Icon>} {el.siteName}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="right">
              {showColumns.includes('Review') && <div>{getReview(el)}</div>}
              {showColumns.includes('Visit Date') && (
                <div className="icon-text" style={{ margin: 0 }}>
                  {showIconsMode && <Icon suit="material-outline">event</Icon>}
                  {moment(el.edcVisitDate).format('DD MMM')}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
