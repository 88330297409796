import { isEmpty, isFunction } from 'lodash/lang';

export default class PatientSituationalAppointmentValidator {
  fieldValidation() {
    const [appointment, previousValidation, ...changedField] = arguments;

    const fieldValidators = {
      timeDuration: () => this.timeDurationValidation(appointment),
      patient: () => this.clearRequiredValidation(appointment, 'patient'),
      study: () => this.clearRequiredValidation(appointment, 'study'),
      encounter: () => this.clearRequiredValidation(appointment, 'encounter')
    };

    const not = predicate => arg => !predicate(arg);

    return changedField
      .map(fieldName => fieldValidators[fieldName])
      .filter(validator => isFunction(validator))
      .map(validator => validator())
      .filter(not(isEmpty))
      .reduce((acc, current) => ({ ...acc, ...current }), previousValidation);
  }

  formValidation(appointment) {
    return {
      patient: { validationMessage: appointment.patient ? undefined : 'Patient is Required' },
      study: { validationMessage: appointment.study ? undefined : 'Study is Required' },
      timeDuration: {
        validationMessage: this.validateTimeDuration(appointment.timeDuration)
      },
      encounter: { validationMessage: appointment.encounter ? undefined : 'Encounter is Required' }
    };
  }

  timeDurationValidation(appointment) {
    return {
      timeDuration: {
        validationMessage: this.validateTimeDuration(appointment.timeDuration)
      }
    };
  }

  validateTimeDuration(timeDuration) {
    return timeDuration && timeDuration.start && timeDuration.duration ? undefined : 'Start and duration are required';
  }

  clearRequiredValidation(appointment, fieldName) {
    if (appointment[fieldName]) {
      return { [fieldName]: { validationMessage: undefined } };
    }
  }

  isValid(appointment) {
    return (
      appointment.patient &&
      appointment.study &&
      appointment.timeDuration &&
      appointment.timeDuration.start &&
      appointment.timeDuration.duration &&
      appointment.encounter
    );
  }
}
