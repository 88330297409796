import React, { useEffect, useRef, useState } from 'react';
import { get } from 'lodash/object';

import Input from '../../../../common/data-entry/Input';
import TextArea from '../../../../common/inputs/TextArea';
import { getRoleDescriptionByUserRoles } from '../../../../services/personnelService';
import { useCurrentUser } from '../../../root/Container/CurrentUserContainer';
import { AppointmentUserInfo, UserMultiSelect } from '../../../UserMultiSelect/UserMultiSelect';
import { PATIENT_UNEXPECTED_ENCOUNTER_EVENT } from '../CalendarEventType';
import { getAppointmentWithFixedTimeZone } from '../CalendarTimeZoneService';
import { isDraft } from '../DraftEventProvider';
import { fromDialogAppointment, toDialogAppointment } from '../EventTransformer';
import { ParticipantsProviderPatientEncounter } from '../form-components/ParticipantsProviderPatientEncounter';
import { PatientSelector } from '../form-components/PatientSelector';
import { StudySelector } from '../form-components/StudySelector';
import { refreshParticipants } from '../PatientAppointmentEditService';
import TimeDurationPicker from '../TimeDurationPickerGeneral/TimeDurationPicker';

import PatientAppointmentValidator from './PatientAppointmentValidator';

function makeUnexpectedAppointment({
  id,
  eventId,
  calendarIndex,
  patient,
  sitePatientId,
  study,
  siteName,
  timeDuration,
  comment,
  participants,
  color,
  organizer
}) {
  return {
    id,
    eventId,
    calendarIndex,
    subject: 'Unexpected Appointment',
    timeDuration,
    comment,
    patient,
    sitePatientId,
    study,
    siteName,
    participants,
    color,
    organizer,
    type: PATIENT_UNEXPECTED_ENCOUNTER_EVENT
  };
}

export function PatientAppointmentEdit({
  initialAppointment = {},
  appointmentChanged,
  children,
  timeZones,
  selectedTimeZone,
  changeSelectedTimeZone,
  eventId
}) {
  const patientUnexpectedAppointmentValidator = new PatientAppointmentValidator();

  useEffect(() => {
    const dialogAppointment = toDialogAppointment(initialAppointment);
    const appointment = makeUnexpectedAppointment(dialogAppointment);
    setAppointment(appointment);
  }, [initialAppointment]);

  const [appointment, setAppointment] = useState(toDialogAppointment(initialAppointment));
  const currentUser = useCurrentUser();
  const personnelIdentifier = currentUser.personnelIdentifier,
    roleDescription = getRoleDescriptionByUserRoles(currentUser.roles);

  const [validation, setValidation] = useState({
    patient: {},
    study: {},
    timeDuration: {}
  });

  const fieldChange = fieldName => newValue => {
    const oldValue = get(appointment, fieldName);
    if (oldValue === newValue) {
      return;
    }
    setAppointment(appointment => {
      const newAppointment = { ...appointment, [fieldName]: newValue };
      const newValidation = patientUnexpectedAppointmentValidator.fieldValidation(
        newAppointment,
        validation,
        fieldName
      );
      setValidation(newValidation);
      appointmentChanged(fromDialogAppointment(newAppointment));
      return newAppointment;
    });
  };

  const changePatient = fieldChange('patient');
  const changeStudy = fieldChange('study');
  const changeOrganizer = fieldChange('organizer');
  const changeTimeDuration = fieldChange('timeDuration');
  const changeSitePatientId = fieldChange('sitePatientId');
  const changeParticipants = fieldChange('participants');
  const changeComment = fieldChange('comment');

  const getSiteName = appointment =>
    appointment.patient?.studySites?.find(ssu => ssu.study.id === appointment?.study?.id)?.site?.name;

  const getSiteId = appointment =>
    appointment.patient?.studySites?.find(ssu => ssu.study.id === appointment?.study?.id)?.site?.id;

  const siteId = getSiteId(appointment);

  useEffect(() => {
    const timezoneForSelectedStudy = timeZones?.filter(e => e.id === siteId)[0];
    if (
      siteId &&
      appointment.timeDuration &&
      timezoneForSelectedStudy &&
      selectedTimeZone.timeZoneId !== timezoneForSelectedStudy.timeZoneId
    ) {
      const updatedAppointment = getAppointmentWithFixedTimeZone(
        appointment,
        selectedTimeZone.timeZoneId,
        timezoneForSelectedStudy.timeZoneId,
        timeZones[0].timeZoneId
      );
      setAppointment(updatedAppointment);
      appointmentChanged(fromDialogAppointment(updatedAppointment));
      changeSelectedTimeZone(timezoneForSelectedStudy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId]);

  useEffect(() => {
    changeSitePatientId(appointment.study?.sitePatientId);
    // adding changeSitePatientId to dependants leads to blinking of form fields
    // eslint-disable-next-line
  }, [appointment.study?.id]);

  const validateBefore = saveHandler => () => {
    const newValidation = patientUnexpectedAppointmentValidator.formValidation(appointment, validation);
    setValidation(newValidation);

    if (patientUnexpectedAppointmentValidator.isValid(appointment)) {
      return saveHandler(fromDialogAppointment(appointment));
    }
  };

  const sitePatientId = appointment?.study?.sitePatientId;
  const isAppointmentDraft = isDraft(appointment);
  const encounterId = appointment.encounter?.id;

  useEffect(() => {
    refreshParticipants(personnelIdentifier, appointment, changeParticipants);
    // adding changeParticipants to dependants leads to blinking of form fields
    // eslint-disable-next-line
  }, [sitePatientId, encounterId, isAppointmentDraft, personnelIdentifier]);

  const previousAppointmentStart = useRef(appointment.timeDuration.start);

  useEffect(() => {
    if (
      !previousAppointmentStart.current
        .clone()
        .startOf('day')
        .isSame(appointment.timeDuration.start.clone().startOf('day'))
    ) {
      changeOrganizer({
        userId: personnelIdentifier,
        type: 'USER',
        label: roleDescription
      });
      previousAppointmentStart.current = appointment.timeDuration.start;
    }
    // adding changeOrganizer to dependants leads to blinking of form fields
    // eslint-disable-next-line
  }, [appointment.timeDuration.start, personnelIdentifier, roleDescription]);

  const composeButtonBar = () => {
    if (children) {
      return React.Children.map(children, child => {
        return React.cloneElement(child, {
          ...child.props,
          onSave: validateBefore(child.props.onSave),
          onEdit: () => child.props.onEdit(appointment)
        });
      });
    }
  };

  return (
    <React.Fragment>
      <PatientSelector
        onPatientSelected={changePatient}
        initialPatient={appointment.patient}
        patientValidationMessage={validation.patient.validationMessage}
        appointmentType={get(appointment, 'type')}
      />
      <StudySelector
        onStudySelected={changeStudy}
        initialStudy={appointment.study}
        disabled={eventId}
        patientId={get(appointment, 'patient.patientId')}
        studyValidationMessage={validation.study.validationMessage}
        isUnexpected={true}
      />
      <Input label={'Site'} value={getSiteName(appointment)} disabled />
      <TimeDurationPicker
        initialTime={appointment.timeDuration}
        onTimeDurationChanged={changeTimeDuration}
        validationMessage={validation.timeDuration.validationMessage}
      />
      <ParticipantsProviderPatientEncounter initialPatientId={get(appointment, 'sitePatientId')}>
        <UserMultiSelect
          onChange={changeParticipants}
          initialUsers={appointment.participants}
          organizerUser={get(appointment, 'organizer')}
          className="mb-2"
          optionValueKey="userId"
          label="Add Attendees"
          customOptionTemplateFunction={AppointmentUserInfo}
        />
      </ParticipantsProviderPatientEncounter>
      <TextArea
        id={'comment-input'}
        name={'comment'}
        label={'Comment'}
        value={appointment.comment}
        onChange={changeComment}
      />
      <div className="action-buttons">{composeButtonBar()}</div>
    </React.Fragment>
  );
}
