import {
  addInvalidFieldIfNotValid,
  isBlank,
  isPhoneValid,
  isValidEmail,
  isZipCodeValid
} from '../../AccountService.js';

export const accountFieldNames = {
  ORGANIZATION_NAME: 'ORGANIZATION_NAME',
  DEPARTMENT_NAME: 'DEPARTMENT_NAME',
  PRIMARY_PHONE: 'PRIMARY_PHONE',
  CONTACT_EMAIL: 'CONTACT_EMAIL',
  ADDRESS_1: 'ADDRESS_1',
  ZIP_CODE: 'ZIP_CODE',
  COUNTRY: 'COUNTRY',
  CITY: 'CITY',
  STATE: 'STATE'
};

export const validateAccount = account => {
  const invalidAccountFields = [];
  addInvalidFieldIfNotValid(isBlank(account.name), accountFieldNames.ORGANIZATION_NAME, invalidAccountFields);
  addInvalidFieldIfNotValid(isBlank(account.departmentName), accountFieldNames.DEPARTMENT_NAME, invalidAccountFields);
  addInvalidFieldIfNotValid(isBlank(account.address1), accountFieldNames.ADDRESS_1, invalidAccountFields);
  addInvalidFieldIfNotValid(isBlank(account.country), accountFieldNames.COUNTRY, invalidAccountFields);
  addInvalidFieldIfNotValid(isBlank(account.city), accountFieldNames.CITY, invalidAccountFields);
  addInvalidFieldIfNotValid(isBlank(account.state), accountFieldNames.STATE, invalidAccountFields);
  addInvalidFieldIfNotValid(!isPhoneValid(account.phone), accountFieldNames.PRIMARY_PHONE, invalidAccountFields);
  addInvalidFieldIfNotValid(
    !isZipCodeValid(account.zipCode, account?.country === 'US'),
    accountFieldNames.ZIP_CODE,
    invalidAccountFields
  );
  addInvalidFieldIfNotValid(!isValidEmail(account?.email), accountFieldNames.CONTACT_EMAIL, invalidAccountFields);
  return invalidAccountFields;
};
