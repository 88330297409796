import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { isEmpty } from 'lodash';
import { isNull } from 'lodash/lang';
import moment from 'moment';

import StyledTooltip from '../../../../common/widgets/StyledToolTip';
import { CLOSED } from '../../../../constants/ssuStatuses';
import { MANAGE_PROGRESS_NOTES, VIEW_PROGRESS_NOTES } from '../../../../constants/userOperations';
import { ROLE_PATIENT_LIAISON, ROLE_SYSTEM_ADMINISTRATOR } from '../../../../constants/userRoles';
import { pendoTrackDefaultSortingChange } from '../../../../pendo/PendoUtils';
import { userHasAccessTo, userHasRole } from '../../../../services/auth';
import { generateUrlByKey } from '../../../root/router';

import { SchedulingWorklistContext } from './SchedulingWorklistFiltersContext';
import { SchedulingWorklistSummaryColumn } from './SchedulingWorklistSummaryColumn';

import './SchedulingWorklistTable.scss';

const getColumns = (appointmentStatus, smsColumnData) => [
  {
    id: 'patientName',
    Header: 'Patient',
    accessor({ firstName, lastName }) {
      return `${firstName} ${lastName}`;
    },
    Cell: ({ value, original }) => (
      <span className="tooltip-cell">
        <Link
          onClick={function(e) {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
          to={getUrlToPatientInfoPage(original)}
        >
          {value}
        </Link>
      </span>
    ),
    minWidth: 200
  },
  {
    accessor: 'subjectId',
    Header: 'Subject ID',
    Cell: ({ value }) => value || 'N/A',
    minWidth: 180
  },
  {
    Header: 'Study',
    accessor: 'studyName',
    minWidth: 190
  },
  {
    Header: 'Site',
    accessor: 'siteName',
    minWidth: 190
  },
  {
    Header: 'Encounter',
    accessor: 'encounterName',
    minWidth: 180,
    Cell: ({ value }) => value
  },
  {
    Header: 'Phone Number',
    accessor: 'phoneNumber',
    minWidth: 180
  },
  {
    Header: 'Appointment Date',
    id: 'appointmentDate',
    show: appointmentStatus.id !== 'NO_APPOINTMENT',
    accessor({ startDate, endDate, appointmentDate }) {
      if (startDate && endDate) {
        return null;
      } else {
        return appointmentDate;
      }
    },
    width: 160,
    Cell: ({ value }) => (!isNull(value) ? moment.utc(value.toLocaleString()).format('DD/MMM/YYYY') : '')
  },
  {
    accessor: 'startDate',
    show: appointmentStatus.id === 'NO_APPOINTMENT',
    Header: 'Start Protocol Window',
    width: 160,
    Cell: ({ value }) => (!isNull(value) ? moment.utc(value.toLocaleString()).format('DD/MMM/YYYY') : '')
  },
  {
    accessor: 'endDate',
    show: appointmentStatus.id === 'NO_APPOINTMENT',
    Header: 'End Protocol Window',
    width: 160,
    Cell: ({ value }) => (!isNull(value) ? moment.utc(value.toLocaleString()).format('DD/MMM/YYYY') : '')
  },
  {
    Header: 'SMS Reminder Status',
    id: 'reminderStatus',
    accessor: row => {
      return { eventId: row.eventId, smsOptOut: row.smsOptOut };
    },
    show: appointmentStatus.id !== 'NO_APPOINTMENT',
    width: 180,
    sortMethod: (a, b) => {
      const firstReminderStatus = getSmsReminderStatus(a, smsColumnData);
      const secondReminderStatus = getSmsReminderStatus(b, smsColumnData);
      if (firstReminderStatus === '--' && secondReminderStatus === '--') return 0;
      if (firstReminderStatus === '--') return 1;
      if (secondReminderStatus === '--') return -1;
      return firstReminderStatus.localeCompare(secondReminderStatus);
    },
    Cell: ({ value, original }) => getSmsReminderStatusCell(original, smsColumnData)
  },
  {
    Header: 'Actions',
    sortable: false,
    className: 'scheduling-worklist-summary-column',
    width: appointmentStatus.id !== 'NO_APPOINTMENT' ? 350 : 180,
    Cell: row => {
      const hasAccessToManage =
        userHasRole(ROLE_SYSTEM_ADMINISTRATOR) ||
        userHasRole(ROLE_PATIENT_LIAISON) ||
        (row.original.studySiteStatus !== CLOSED && userHasAccessTo(MANAGE_PROGRESS_NOTES));
      return (
        <SchedulingWorklistSummaryColumn
          hasAccessToView={userHasAccessTo(VIEW_PROGRESS_NOTES)}
          hasAccessToEdit={hasAccessToManage}
          sitePatientIdentifier={row.original.sitePatientUniqueId}
          studySiteStatus={row.original.studySiteStatus}
          event={row.original}
          appointmentStatus={appointmentStatus}
        />
      );
    }
  }
];

export const SchedulingWorklistTable = () => {
  const Context = useContext(SchedulingWorklistContext);
  const {
    tableData,
    searchString,
    page,
    pageSize,
    sorted,
    setPage,
    setPageSize,
    setSorted,
    setInitialFilters,
    filter,
    smsColumnData
  } = Context;

  const filterBySearchString = el => {
    if (!isEmpty(searchString)) {
      return `${el.firstName} ${el.lastName} ${el.firstName} ${el.subjectId}`
        .toLowerCase()
        .includes(searchString.toLowerCase());
    }
    return true;
  };

  return (
    <ReactTable
      onPageSizeChange={pageSize => {
        setPageSize(pageSize);
        setInitialFilters({ pageSize });
        const totalPages = Math.ceil(tableData.length / pageSize);
        if (page > totalPages && !isEmpty(tableData)) {
          setPage(totalPages);
          setInitialFilters({ page: totalPages });
        }
      }}
      onPageChange={page => {
        setPage(page);
        setInitialFilters({ page });
      }}
      onSortedChange={sorted => {
        pendoTrackDefaultSortingChange(sorted);
        setSorted(sorted);
        setInitialFilters({ sorted });
      }}
      columns={getColumns(filter.appointmentStatus, smsColumnData)}
      pages={Math.ceil(tableData.length / pageSize)}
      data={tableData.filter(filterBySearchString)}
      defaultPageSize={10}
      showPagination
      showPageSizeOptions
      minRows={1}
      pageSize={pageSize}
      page={!isEmpty(searchString) ? 0 : page}
      defaultSorted={sorted}
      noDataText="No Records Found"
      nextText=">>"
      previousText="<<"
      className="scheduling-worklist"
    />
  );
};
const getUrlToPatientInfoPage = original => {
  const { patientUniqueId, sitePatientUniqueId } = original;
  return generateUrlByKey('Scheduling Worklist.Patient Profile.Patient Studies', {
    patientId: patientUniqueId,
    ssuPatientId: sitePatientUniqueId
  });
};

const getSmsReminderStatusCell = (original, smsColumnData) => {
  const reminderStatus = getSmsReminderStatus(original, smsColumnData);
  const { tooltipMessage, showTooltip } = getTooltipMessage(original, smsColumnData);
  if (reminderStatus === '--') {
    return '--';
  } else if (showTooltip) {
    return (
      <StyledTooltip
        className="tooltip-note-value"
        overlay={<div className="note-value">{tooltipMessage}</div>}
        placement="right"
      >
        <div>{getSmsReminderStatus(original, smsColumnData)}</div>
      </StyledTooltip>
    );
  } else {
    return <div>{getSmsReminderStatus(original, smsColumnData)}</div>;
  }
};

const getSmsReminderStatus = (original, smsColumnData) => {
  const { eventId, smsOptOut } = original;
  if (isEmpty(smsColumnData?.remindersByEventId)) {
    return '--';
  }
  const mostRecentReminder =
    smsColumnData?.remindersByEventId[eventId] &&
    smsColumnData?.remindersByEventId[eventId].reduce((acc, current) => {
      const accTimestamp = moment(acc.sendAt)
        .toDate()
        .getTime();
      const currentTimestamp = moment(current.sendAt)
        .toDate()
        .getTime();
      if (currentTimestamp > accTimestamp) {
        return current;
      }
      return acc;
    }, smsColumnData?.remindersByEventId[eventId][0]);
  let reminderStatus;
  if (
    smsColumnData?.remindersByEventId[eventId] &&
    !isEmpty(smsColumnData?.remindersByEventId[eventId].filter(event => event.status === 'SCHEDULED'))
  ) {
    reminderStatus = 'SCHEDULED';
  } else {
    reminderStatus = mostRecentReminder ? mostRecentReminder.status : null;
  }

  if (smsOptOut) {
    return 'Opted Out of SMS';
  }
  switch (reminderStatus) {
    case 'DELIVERED':
    case 'SENT':
      return 'Sent';
    case 'SCHEDULED':
      return 'Scheduled';
    case 'FAILED':
      return 'Failed';
    case 'CANCELED_BY_SYSTEM':
      return getCancelReason(mostRecentReminder?.cancelReason, mostRecentReminder.status);
    default:
      break;
  }
  return '--';
};

const getTooltipMessage = (original, smsColumnData) => {
  const { eventId, siteTimezone } = original;
  const firstSendMessage = smsColumnData?.firstSentMessageByEventId[eventId];
  let tooltipMessage = '';
  let tooltipMessagesAmount = 0;
  if (firstSendMessage) {
    if (!isNull(firstSendMessage.sendDate)) {
      tooltipMessage +=
        `First SMS sent: ` +
        moment(firstSendMessage.sendDate)
          .tz(siteTimezone)
          .format('DD/MMM/YY, h:mm A z');
      tooltipMessagesAmount++;
    } else {
      const messageToAdd = getCancelReason(firstSendMessage?.cancelReason, firstSendMessage.status);
      tooltipMessage += messageToAdd;
      !isEmpty(messageToAdd) && tooltipMessagesAmount++;
    }
  }
  if (!isEmpty(smsColumnData?.remindersByEventId[eventId])) {
    smsColumnData?.remindersByEventId[eventId].forEach(reminder => {
      if (reminder.status === 'SCHEDULED') {
        tooltipMessage += isEmpty(tooltipMessage) ? '' : '\n';
        tooltipMessage +=
          'Reminder will be sent at: ' +
          moment(reminder.sendAt)
            .tz(siteTimezone)
            .format('DD/MMM/YY, h:mm A z');
        tooltipMessagesAmount++;
      } else if (!isNull(reminder.sendDate)) {
        tooltipMessage += isEmpty(tooltipMessage) ? '' : '\n';
        tooltipMessage +=
          'Reminder sent: ' +
          moment(reminder.sendDate)
            .tz(siteTimezone)
            .format('DD/MMM/YY, h:mm A z');
        tooltipMessagesAmount++;
      } else {
        tooltipMessage += isEmpty(tooltipMessage) ? '' : '\n';
        const reminderMessageToAdd = getCancelReason(reminder?.cancelReason, reminder.status);
        tooltipMessage += reminderMessageToAdd;
        !isEmpty(reminderMessageToAdd) && tooltipMessagesAmount++;
      }
    });
  }
  return { tooltipMessage, showTooltip: tooltipMessagesAmount > 1 };
};

const getCancelReason = (reason, status) => {
  if (status !== 'CANCELED_BY_SYSTEM') {
    return '';
  }
  switch (reason) {
    case 'STUDY_OPT_OUT':
      return 'Study SMS Opted-Out';
    case 'ENCOUNTER_OPT_OUT':
      return 'Encounter SMS Opted-Out';
    case 'PATIENT_OPT_OUT':
      return 'Patient SMS Opted-Out';
    case 'GLOBAL_OPT_OUT':
      return 'Global setting was turned OFF';
    default:
      return 'Unknown issue';
  }
};
