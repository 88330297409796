import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash/lang';
import moment from 'moment';
import * as PropTypes from 'prop-types';

import { PatientEncounterGroupAssignReviewCommentApi } from '../../../../../../../../api';
import Common from '../../../../../../../../common/common';
import TextArea from '../../../../../../../../common/data-entry/TextArea/TextArea';
import Button from '../../../../../../../../common/general/Button';
import NotificationManager from '../../../../../../../../common/notifications/NotificationManager';
import { COMMENT_SAVED } from '../../../../../../../../constants/notificationMessages';
import { handleReviewCommentExceptions } from '../../../../../../../../services/handlers';
import { useCurrentUser } from '../../../../../../../root/Container/CurrentUserContainer';
import { isUserAbleToManageSmAndPiReviewComment, isUserAbleToViewCommentAtReview } from '../../../reviewAccessService';
import { isCraReview } from '../../../reviewService';
import { isEmptyOrTheSameAsPrevious } from '../../reviewContentService';

import './CommentSection.scss';

export default function CommentSection(props) {
  const {
      snapshotData,
      patientItemGroupId,
      context,
      saveButton,
      setCommentData,
      setEditableComment,
      updateData,
      readonly
    } = props,
    { patientEncounterId, ssuPatientId, reviewType, isHistoricalData, studySiteStatus } = context,
    { whoDidItName, commentAnswer, whenWasItDone, rowId, rowVersion, studyId } = snapshotData,
    isCraReviewPage = isCraReview(reviewType),
    initialCommentStringValue = commentAnswer?.stringValue,
    [commentPopulatedValue, setCommentPopulatedValue] = useState(null),
    isAbleToManageSmAndPiReviewComment = isUserAbleToManageSmAndPiReviewComment(studySiteStatus),
    isAbleToViewComment = isUserAbleToViewCommentAtReview(),
    { lastName, firstName } = useCurrentUser(),
    [loader, setLoader] = useState(false),
    isEditableComment = isAbleToManageSmAndPiReviewComment && !isCraReviewPage && !isHistoricalData && !readonly,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dataAssembleCallback = useCallback(commentDataAssemble, [
      commentPopulatedValue,
      ssuPatientId,
      patientEncounterId,
      patientItemGroupId,
      rowId,
      rowVersion,
      reviewType
    ]),
    showCommentAuthor = false;

  useEffect(() => {
    if (initialCommentStringValue !== commentPopulatedValue) {
      setCommentPopulatedValue(initialCommentStringValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCommentStringValue]);

  useEffect(() => {
    setCommentData && setCommentData(dataAssembleCallback);
  }, [dataAssembleCallback, setCommentData]);

  useEffect(() => {
    setEditableComment && setEditableComment(isEditableComment);
  }, [setEditableComment, isEditableComment]);

  if (isEmpty(commentPopulatedValue) && !isAbleToManageSmAndPiReviewComment) {
    return null;
  }
  return (
    <div className="erv-comment-section">
      <div className="edit-comment-area">
        {showCommentAuthor && <div className="comments-author">{getAuthor()}</div>}
        {getComment()}
      </div>
    </div>
  );

  function getAuthor() {
    const isEmptyInitialValue = isEmpty(commentPopulatedValue);
    if (isEditableComment && isEmptyInitialValue) {
      return `${lastName}, ${firstName}`;
    }
    if (!isEmptyInitialValue) {
      return (
        <React.Fragment>
          {whoDidItName} {Common.formatDate(whenWasItDone)}
        </React.Fragment>
      );
    }
    return null;
  }

  function getComment() {
    const isEmptyCommentArea = isEmpty(commentPopulatedValue);
    if (isEditableComment) {
      return (
        <div className="comments-area">
          <div className="textarea-layout">
            <TextArea rows="3" label="Enter Comment" value={commentPopulatedValue} onChange={onChangeComment} />
          </div>
          {saveButton && (
            <div className="button-layout">
              <Button
                disabled={loader || isEmptyOrTheSameAsPrevious(initialCommentStringValue, commentPopulatedValue)}
                size="h28"
                priority="medium"
                onClick={onSubmit}
                loading={loader}
              >
                Save Comment
              </Button>
            </div>
          )}
        </div>
      );
    }
    if (!isEmptyCommentArea && (isCraReviewPage || isAbleToViewComment || isHistoricalData)) {
      return <div className="comment">{commentPopulatedValue}</div>;
    }
    return null;
  }

  function onChangeComment(e) {
    setCommentPopulatedValue(e.target.value);
  }

  function commentDataAssemble() {
    return {
      sitePatientEncounterId: patientEncounterId,
      comment: commentPopulatedValue,
      rowId,
      whenWasItDone: moment(),
      rowVersion,
      studyId,
      reviewType: reviewType
    };
  }
  function onFailure(err) {
    handleReviewCommentExceptions(err);
    setLoader(false);
  }
  function onSubmit() {
    setLoader(true);
    const commentSnapshot = commentDataAssemble();
    PatientEncounterGroupAssignReviewCommentApi.saveCommentOnReview(
      ssuPatientId,
      patientItemGroupId,
      commentSnapshot,
      false
    ).then(() => {
      NotificationManager.success(COMMENT_SAVED);
      updateData([patientItemGroupId], false);
      setLoader(false);
    }, onFailure);
  }
}

CommentSection.defaultProps = { saveButton: true, isLogItemGroup: false, readonly: false };

CommentSection.propTypes = {
  snapshotData: PropTypes.object.isRequired,
  setCommentData: PropTypes.func,
  saveButton: PropTypes.bool,
  patientItemGroupId: PropTypes.string,
  context: PropTypes.object
};
