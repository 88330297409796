import { UserApiApi } from 'api';
import { LAST_ACTION, SESSION_ID } from 'constants/localStorageConstants';
import { SSU_ROLES } from 'constants/userRoles';
import { includes } from 'lodash/collection';
import { isArray, isFunction, isString } from 'lodash/lang';
import store from 'store';
import { clear } from 'store/currentUser/actions';

export function userHasAccessTo(...operationsToCheck) {
  const { currentUser } = store.getState();
  const allowedOperations = currentUser?.operationsMapping && currentUser?.operationsMapping[currentUser?.activeRole];
  if (isArray(operationsToCheck) && isArray(allowedOperations)) {
    return operationsToCheck.some(operation => includes(allowedOperations, operation));
  }
  return false;
}

export function userHasRole(rolesToCheck) {
  const state = store.getState();
  if (isString(rolesToCheck)) {
    rolesToCheck = [rolesToCheck];
  }
  if (isArray(rolesToCheck) && isString(state.currentUser.activeRole)) {
    return rolesToCheck.some(role => state.currentUser.activeRole === role);
  }
  return false;
}

export function userHasAccessAs(rolesToCheck) {
  if (isString(rolesToCheck)) {
    rolesToCheck = [rolesToCheck];
  }
  if (isArray(rolesToCheck)) {
    // system administrator has full access
    rolesToCheck.push('ROLE_SYSTEM_ADMINISTRATOR');
    return userHasRole(rolesToCheck);
  }
  return false;
}
export function ssuRoleAccessCheck(granted) {
  const { currentUser } = store.getState();
  if (SSU_ROLES.includes(currentUser?.activeRole)) {
    return granted;
  }
  return true;
}

export async function handleLogout(bouncerLogout) {
  store.dispatch(clear());
  const sessionId = localStorage.getItem(SESSION_ID);
  if (sessionId) {
    await UserApiApi.writeLogoutTime(sessionId);
  }

  document.cookie = 'sessionExpiry=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  localStorage.removeItem(SESSION_ID);
  localStorage.removeItem(LAST_ACTION);
  sessionStorage.clear();

  if (isFunction(bouncerLogout)) {
    await bouncerLogout();
  }
}
