import React from 'react';
import cx from 'classnames';
import { omit } from 'lodash/object';
import * as PropTypes from 'prop-types';

import './Icon.scss';
const Icon = React.forwardRef(function(props, ref) {
  const { children, className, suit, type } = props;

  const elementProps = omit(props, ['children', 'className', 'suit']);

  const suitClassName = {
    'material-icons-outlined': suit === 'material-outline',
    'material-icons': suit === 'material',
    'material-icons-round': suit === 'material-round',
    [`glyphicon glyphicon-${children}`]: suit === 'glyphicon'
  };

  return (
    <i ref={ref} className={cx(`eui-icon`, `eui-icon-type-${type}`, suitClassName, className)} {...elementProps}>
      {suit !== 'glyphicon' && children}
    </i>
  );
});
Icon.isIcon = true;
Icon.defaultProps = {
  suit: 'material',
  type: 'default'
};

Icon.propTypes = {
  suit: PropTypes.oneOf(['glyphicon', 'material', 'material-outline', 'material-round']),
  type: PropTypes.oneOf(['default', 'input-button'])
};

export default Icon;
