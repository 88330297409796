import React, { Component } from 'react';
import { find } from 'lodash';

import { StudySiteStartupApi } from '../../../../api';
import Button from '../../../../common/general/Button';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import { EXPORT_FAILED } from '../../../../constants/notificationMessages';
import { onFileSave, onRequestError } from '../../../../services/handlers';
import { PageInfoHeader } from '../../../PageInfoHeader/PageInfoHeader';
import { SSUFilter } from '../../../SSUFilter/SSUFilter';
import { SSUPCNFilter } from '../../../SSUFilter/SSUPCNFilter';

import Chevron from './Chevron';

import './MilestoneReport.scss';

const dateHeaderStyle = {
  width: '185px',
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  fontWeight: 'bold'
};

const legendContainer = {
  marginTop: '15px',
  border: '1px solid #bbb',
  width: '135px',
  padding: '5px'
};

const legendActualBoxStyle = {
  display: 'inline-block',
  color: '#2F8F4C',
  backgroundColor: '#2F8F4C',
  height: '10px',
  width: '10px',
  border: '1px solid #2F8F4C',
  marginRight: '5px'
};

const legendPastBoxStyle = {
  display: 'inline-block',
  color: '#7EC894',
  backgroundColor: '#7EC894',
  height: '10px',
  width: '10px',
  border: '1px solid #7EC894',
  marginRight: '5px'
};

const legendProjectedBoxStyle = {
  display: 'inline-block',
  color: '#FFFDA1',
  backgroundColor: '#FFFDA1',
  height: '10px',
  width: '10px',
  border: '1px solid #FFFDA1',
  marginRight: '5px'
};

class MilestoneReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      milestones: [],
      dateDefinitions: []
    };
    this.columns = [];
    this.ssuIds = [];
  }

  componentDidMount() {
    StudySiteStartupApi.getAllCodesAndDates().then(res => {
      if (res && res.data) {
        this.setState({
          dateDefinitions: res.data
        });
      }
    });
  }

  dataFormatter(data) {
    const { state } = this;
    const allDates = state.dateDefinitions;
    const mapped = data.map(ssu => {
      const newSsu = {
        studyName: ssu.studyName,
        siteName: ssu.siteName,
        pcn: ssu.pcn,
        ssuId: ssu.ssuId,
        dates: []
      };
      allDates.forEach(date => {
        newSsu.dates.push({
          shortName: date.shortName,
          longName: date.longName,
          value: null,
          actual: null
        });
      });
      if (ssu.dates.length > 0) {
        ssu.dates.forEach(date => {
          if (find(newSsu.dates, { shortName: date.shortName })) {
            const obj = find(newSsu.dates, { shortName: date.shortName });
            obj.date = date.date;
            obj.actual = date.actual;
          }
        });
      }
      return newSsu;
    });
    this.setState({
      milestones: mapped
    });
  }

  downloadCSV = () => {
    StudySiteStartupApi.exportMilestones(this.ssuIds)
      .then(onFileSave)
      .catch(error => {
        NotificationManager.error(EXPORT_FAILED);
        console.error(error);
      });
  };

  handleSSUFilterChange = ssus => {
    const ssuIds = ssus.map(ssu => ssu.uniqueIdentifier);
    this.ssuIds = ssuIds;
    StudySiteStartupApi.getAllSsusAndMilestonesAndDatesFiltered(ssuIds).then(({ data }) => {
      this.dataFormatter(data);
    }, onRequestError);
  };

  render() {
    const { state } = this;
    return (
      <div className="footpadb">
        <PageInfoHeader
          right={
            <Button priority="medium" type="button" size="h28" onClick={this.downloadCSV}>
              Export
            </Button>
          }
        >
          <div className="general-header-group-container general-header-wrapper">
            <SSUFilter handleSSUFilterChange={this.handleSSUFilterChange}>
              <SSUPCNFilter />
            </SSUFilter>
          </div>
        </PageInfoHeader>
        <section className="border">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-2">
                <div style={legendContainer}>
                  <div>
                    <span style={legendActualBoxStyle} />
                    <span>Actual Date</span>
                  </div>
                  <div>
                    <span style={legendPastBoxStyle} />
                    <span>Past Date</span>
                  </div>
                  <div>
                    <span style={legendProjectedBoxStyle} />
                    <span>Projected Date</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ paddingRight: '15px' }}>
              <div className="col-md-2">
                <div className="chevron-title-row" style={{ height: '49px' }}>
                  <p style={{ fontWeight: 'bold' }}>Study Name - Site Name</p>
                </div>
                {state.milestones.map(ms => {
                  return (
                    <div className="chevron-title-row" key={ms.ssuId}>
                      <p>
                        {ms.studyName} - {ms.siteName}
                      </p>
                    </div>
                  );
                })}
              </div>
              <div className="col-md-1">
                <div className="chevron-title-row" style={{ height: '49px' }}>
                  <p style={{ fontWeight: 'bold' }}>PCN</p>
                </div>
                {state.milestones.map(ms => {
                  return (
                    <div className="chevron-title-row" key={ms.ssuId}>
                      <p>{ms.pcn}</p>
                    </div>
                  );
                })}
              </div>
              <div className="col-md-9" style={{ overflowX: 'auto' }}>
                <div className="chevron-row" style={{ height: '49px' }}>
                  {state.dateDefinitions.map((def, idx) => {
                    return (
                      <p style={dateHeaderStyle} key={idx}>
                        {def.longName}
                      </p>
                    );
                  })}
                </div>
                {state.milestones.map(ms => {
                  return (
                    <div className="chevron-row" key={ms.ssuId}>
                      {ms.dates.map(date => {
                        return (
                          <Chevron
                            milestone={date.shortName}
                            date={date.date}
                            actual={date.actual}
                            key={`${ms.ssuId}-${date.shortName}`}
                          />
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default MilestoneReport;
