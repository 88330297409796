import { useContext } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Chip, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import ModalBoxes from 'common/feedback/ModalBoxes/ModalBoxes';

import { PatientInfoContext } from '../../../PatientInfoContext';

import PatientPortalInvitationModal from './PatientPortalInvitationModal/PatientPortalInvitationModal';

const PPS_ENABLED = 'Enabled';
const PPS_NOT_ENABLED = 'Not Enabled';
export default function PatientPortalTab() {
  const { patientInfo, setPatientInfo } = useContext(PatientInfoContext);
  return (
    <Stack direction="row" spacing={2} alignItems="center" sx={{ py: 2 }}>
      <Typography variant="subtitle2">Portal</Typography>
      <Chip label={patientInfo?.portalInvitationSent ? PPS_ENABLED : PPS_NOT_ENABLED} />
      <Button
        disabled={patientInfo?.portalLoginHappened || !patientInfo?.email}
        variant="contained"
        size="small"
        startIcon={<AddIcon />}
        onClick={function() {
          ModalBoxes.open({
            component: <PatientPortalInvitationModal patientInfo={patientInfo} />,
            onClose(r) {
              if (!r?.id) {
                return;
              }
              setPatientInfo(state => {
                return { ...state, portalInvitationSent: true, portalLoginHappened: r.loginHappened };
              });
            }
          });
        }}
      >
        Invite
      </Button>
      {!patientInfo?.email && (
        <Typography variant="caption" color="error">
          Email can’t be empty
        </Typography>
      )}
    </Stack>
  );
}
