import React, { useContext, useEffect, useState } from 'react';
import { intersectionWith } from 'lodash/array';
import { isEmpty, isEqual } from 'lodash/lang';

import { HomePageDashboardsConfigApi } from '../../../../../api';
import useSessionStorage from '../../../../../common/hooks/useSessionStorage';
import { HomePageContext } from '../../HomePageContext';
import { getEncountersFrom, getEpochsFrom, getIds, getSitesFrom, getStudiesFrom } from '../common/WidgetDefaultService';

import { ENCOUNTER_SIGN_OFF_WIDGET_VALUES, OPEN, PI_REVIEW, SM_REVIEW } from './EncounterSignOffWidgetConstants';

export const EncounterSignOffContext = React.createContext(null);

export default function EncounterSignOffFiltersContext(props) {
  const [sessionStorage, setSessionStorage] = useSessionStorage(ENCOUNTER_SIGN_OFF_WIDGET_VALUES, {});

  const [selectedEncounters, setSelectedEncounters] = useState(sessionStorage.selectedEncounters || []);
  const [selectedEpochs, setSelectedEpochs] = useState(sessionStorage.selectedEpochs || []);
  const [selectedStudies, setSelectedStudies] = useState(sessionStorage.selectedStudies || []);
  const [selectedSites, setSelectedSites] = useState(sessionStorage.selectedSites || []);
  const [selectedStudySites, setSelectedStudySites] = useState(sessionStorage.selectedStudySites || []);
  const [encounters, setEncounters] = useState([]);
  const [allEncounters, setAllEncounters] = useState([]);
  const [epochs, setEpochs] = useState([]);
  const [allEpochs, setAllEpochs] = useState([]);
  const [studies, setStudies] = useState([]);
  const [allStudies, setAllStudies] = useState([]);
  const [sites, setSites] = useState([]);
  const [allSites, setAllSites] = useState([]);
  const [epochEncounterMap, setEpochEncounterMap] = useState([]);
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);

  const Context = useContext(HomePageContext);
  const { ssus } = Context;

  const [filter, setFilter] = useState({
    selectedStudySites,
    selectedStudies,
    selectedSites,
    selectedEpochs,
    selectedEncounters,
    studies,
    sites,
    epochs,
    encounters
  });
  const { children } = props;

  async function getFilters(ssus) {
    const smFilters = await HomePageDashboardsConfigApi.getEncounterSignOffEpochEncountersSm(
      ssus.map(e => e.id),
      [SM_REVIEW, OPEN]
    );
    const piFilters = await HomePageDashboardsConfigApi.getEncounterSignOffEpochEncountersPi(
      ssus.map(e => e.id),
      [PI_REVIEW]
    );
    return [...smFilters.data, ...piFilters.data];
  }

  useEffect(
    function() {
      if (!isEmpty(ssus)) {
        setIsLoadingFilters(true);
        getFilters(ssus).then(function(data) {
          setEpochEncounterMap(data);

          const studies = getStudiesFrom(ssus);
          const sites = getSitesFrom(ssus);
          const epochs = getEpochsFrom(data);
          const encounters = getEncountersFrom(data);

          setAllStudies(studies);
          setAllSites(sites);
          setAllEpochs(epochs);
          setAllEncounters(encounters);

          const updatedSelectedStudies = !isEmpty(selectedStudies)
            ? intersectionWith(studies, selectedStudies, nameEquals)
            : studies;
          const updatedSelectedSites = !isEmpty(selectedSites)
            ? intersectionWith(sites, selectedSites, nameEquals)
            : sites;
          const updatedSelectedEpochs = !isEmpty(selectedEpochs)
            ? intersectionWith(epochs, selectedEpochs, nameEquals)
            : epochs;

          const updatedSelectedEncounters = !isEmpty(selectedEncounters)
            ? intersectionWith(encounters, selectedEncounters, nameEquals)
            : encounters;

          const updatedSelectedStudySites = !isEmpty(selectedStudySites)
            ? intersectionWith(ssus, selectedStudySites, (a, b) => a.id === b.id)
            : ssus;

          setSelectedStudies(updatedSelectedStudies);
          setSelectedSites(updatedSelectedSites);
          setSelectedEpochs(updatedSelectedEpochs);
          setSelectedEncounters(updatedSelectedEncounters);
          setSelectedStudySites(updatedSelectedStudySites);

          const updatedSites = !isEmpty(updatedSelectedStudies)
            ? getSitesFrom(ssus.filter(ssu => getIds(updatedSelectedStudies).includes(ssu.study.id)))
            : sites;
          const updatedEpochs = !isEmpty(updatedSelectedStudySites)
            ? getEpochsFrom(data.filter(el => getIds(updatedSelectedStudySites).includes(el.ssuId)))
            : epochs;
          const updatedEncounters = !isEmpty(updatedSelectedStudySites)
            ? getEncountersFrom(data.filter(el => getIds(updatedSelectedStudySites).includes(el.ssuId)))
            : encounters;

          setStudies(studies);
          setSites(updatedSites);
          setEpochs(updatedEpochs);
          setEncounters(updatedEncounters);

          setIsLoadingFilters(false);
          applyFilters(
            updatedSelectedStudySites,
            updatedSelectedStudies,
            updatedSelectedSites,
            updatedSelectedEpochs,
            updatedSelectedEncounters,
            studies,
            updatedSites,
            updatedEpochs,
            updatedEncounters
          );
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ssus]
  );

  const nameEquals = (a, b) => a.name === b.name;

  const onStudiesChange = newStudies => {
    if (!isEqual(selectedStudies, newStudies)) {
      setSelectedStudies(newStudies);
      const newStudySites = ssus.filter(ssu => getIds(newStudies).includes(ssu.study.id));
      !isEqual(newStudySites, selectedStudySites) && setSelectedStudySites(newStudySites);
      const newSites = getSitesFrom(newStudySites);
      !isEqual(sites, newSites) && setSites(newSites);
      onSitesChange(intersectionWith(newSites, selectedSites, nameEquals));
    }
  };

  const onSitesChange = newSites => {
    if (!isEqual(selectedSites, newSites)) {
      setSelectedSites(newSites);
      const newStudySites = ssus.filter(
        ssu => getIds(newSites).includes(ssu.site.id) && getIds(selectedStudies).includes(ssu.study.id)
      );
      !isEqual(newStudySites, selectedStudySites) && setSelectedStudySites(newStudySites);
      const newEpochs = getEpochsFrom(epochEncounterMap.filter(el => getIds(newStudySites).includes(el.ssuId)));
      const newEncounters = getEncountersFrom(epochEncounterMap.filter(el => getIds(newStudySites).includes(el.ssuId)));
      !isEqual(epochs, newEpochs) && setEpochs(newEpochs);
      !isEqual(encounters, newEncounters) && setEncounters(newEncounters);
      onEpochsChange(intersectionWith(newEpochs, selectedEpochs, nameEquals));
      onEncountersChange(intersectionWith(newEncounters, selectedEncounters, nameEquals));
    }
  };
  const onEpochsChange = newEpochs => {
    if (!isEqual(selectedEpochs, newEpochs)) {
      setSelectedEpochs(newEpochs);
      const newEncounters = getEncountersFrom(
        epochEncounterMap.filter(
          el =>
            getIds(selectedStudySites).includes(el.ssuId) && (getIds(newEpochs).includes(el.epochName) || !el.epochName)
        )
      );
      !isEqual(encounters, newEncounters) && setEncounters(newEncounters);
      onEncountersChange(intersectionWith(newEncounters, selectedEncounters, nameEquals));
    }
  };
  const onEncountersChange = newEncounters => {
    if (!isEqual(selectedEncounters, newEncounters)) {
      setSelectedEncounters(newEncounters);
    }
  };

  const applyFilters = (
    selectedStudySites,
    selectedStudies,
    selectedSites,
    selectedEpochs,
    selectedEncounters,
    studies,
    sites,
    epochs,
    encounters
  ) => {
    const newFilter = {
      selectedStudySites: selectedStudySites,
      selectedStudies: selectedStudies,
      selectedSites: selectedSites,
      selectedEpochs: selectedEpochs,
      selectedEncounters: selectedEncounters
    };
    setFilter({ ...newFilter, studies: studies, sites: sites, epochs: epochs, encounters: encounters });
    setSessionStorage(newFilter);
  };
  let validationError = false;
  if (!isEmpty(allStudies) && !isEmpty(allSites) && !isEmpty(allEpochs) && !isEmpty(allEncounters)) {
    validationError =
      isEmpty(filter.selectedStudies) ||
      isEmpty(filter.selectedSites) ||
      isEmpty(filter.selectedEpochs) ||
      isEmpty(filter.selectedEncounters);
  }

  const resetStudies = () => {
    onStudiesChange(studies);
    applyFilters(
      selectedStudySites,
      studies,
      selectedSites,
      selectedEpochs,
      selectedEncounters,
      studies,
      sites,
      epochs,
      encounters
    );
  };

  const resetSites = () => {
    onSitesChange(sites);
    setTimeout(
      applyFilters(
        selectedStudySites,
        selectedStudies,
        sites,
        selectedEpochs,
        selectedEncounters,
        studies,
        sites,
        epochs,
        encounters
      )
    );
  };

  const resetEpochs = () => {
    onEpochsChange(epochs);
    setTimeout(
      applyFilters(
        selectedStudySites,
        selectedStudies,
        selectedSites,
        epochs,
        selectedEncounters,
        studies,
        sites,
        epochs,
        encounters
      )
    );
  };

  const resetEncounters = () => {
    onEncountersChange(encounters);
    setTimeout(
      applyFilters(
        selectedStudySites,
        selectedStudies,
        selectedSites,
        selectedEpochs,
        encounters,
        studies,
        sites,
        epochs,
        encounters
      )
    );
  };

  return (
    <EncounterSignOffContext.Provider
      value={{
        allStudies,
        allSites,
        allEpochs,
        allEncounters,
        studies,
        sites,
        epochs,
        encounters,
        selectedStudies,
        selectedSites,
        selectedEpochs,
        selectedEncounters,
        selectedStudySites,
        setSelectedStudySites,
        setSelectedStudies,
        setSelectedSites,
        setSelectedEpochs,
        setSelectedEncounters,
        epochEncounterMap,
        filter,
        applyFilters: () =>
          applyFilters(
            selectedStudySites,
            selectedStudies,
            selectedSites,
            selectedEpochs,
            selectedEncounters,
            studies,
            sites,
            epochs,
            encounters
          ),
        resetStudies,
        resetSites,
        resetEpochs,
        resetEncounters,
        onStudiesChange,
        onSitesChange,
        onEpochsChange,
        onEncountersChange,
        validationError,
        ssus,
        isLoadingFilters
      }}
    >
      {children}
    </EncounterSignOffContext.Provider>
  );
}
