import React from 'react';
import { connect } from 'react-redux';

import StudyEnrollmentListItem from '../StudyEnrollmentListItem/StudyEnrollmentListItem';

const StudyEnrollmentList = ({ studyEnrollments }) => (
  <div>
    {studyEnrollments.map((enrollment, index) => (
      <StudyEnrollmentListItem key={index} enrollment={enrollment} />
    ))}
  </div>
);

const mapStateToProps = state => {
  return {
    studyEnrollments: state.forecasting.studyEnrollments
  };
};

export default connect(mapStateToProps, null)(StudyEnrollmentList);
