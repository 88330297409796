import React from 'react';
import { compact, join } from 'lodash/array';

import Button from '../../../../../../../common/general/Button';
import Icon from '../../../../../../../common/general/Icon';
import { getPatientFullName } from '../../../../../../../services/patient';
import { usePatientProfile } from '../../PatientProfileContext';

export default function PatientDetailsContactInformation() {
  const { editPatientInfo, patientInfo, dictionariesMap, userCanEdit } = usePatientProfile();
  const { pronounsCodes, contactCodes, languageCodes } = dictionariesMap || {};
  const {
    preferredName,
    phonesInfo,
    pronouns,
    primaryLanguage,
    email,
    smsOptIn,
    address,
    interestedInFutureResearch,
    isDeceased
  } = patientInfo || {};

  const isUserNotAbleToContact = isDeceased || phonesInfo?.neverCallAgain;

  const addressFields = [
    address?.addressLine1,
    address?.addressLine2,
    address?.city,
    address?.state,
    address?.zipCode,
    address?.country
  ];
  const formattedAddress = join(compact(addressFields), ', ');

  return (
    <div className="patient-profile-information-content-section">
      <div className="patient-profile-information-header">
        <div className="patient-profile-information-header-title">Patient Contact Details</div>
        {userCanEdit && (
          <div className="patient-profile-information-header-edit">
            <Button onClick={editPatientInfo} priority="medium" size="h28">
              <Icon>edit</Icon> Edit
            </Button>
          </div>
        )}
      </div>

      {isUserNotAbleToContact && (
        <div className="patient-profile-information-data-row">
          <div className="patient-profile-information-highlighted-field">DO NOT CONTACT</div>
          {isDeceased && <div className="patient-profile-information-deceased-chip">Deceased</div>}
        </div>
      )}

      <div className="patient-profile-information-data-row">
        <div className="patient-profile-information-label">
          <div aria-label="patient-full-name-label-field" className="patient-profile-information-label-field">
            Name:
          </div>
          <div className="patient-profile-information-label-field">{getPatientFullName(patientInfo)}</div>
        </div>

        <div className="patient-profile-information-label">
          <div className="patient-profile-information-label-field">Preferred Name:</div>
          <div className="patient-profile-information-label-field">{preferredName || '-'}</div>
        </div>
      </div>

      <div className="patient-profile-information-data-row">
        <div className="patient-profile-information-label">
          <div className="patient-profile-information-label-field">Primary Phone:</div>
          <div className="patient-profile-information-label-field">{phonesInfo?.primary || '-'}</div>
        </div>

        <div className="patient-profile-information-label">
          <div className="patient-profile-information-label-field">Pronouns:</div>
          <div className="patient-profile-information-label-field">
            {pronouns ? pronounsCodes[pronouns]?.name : '-'}
          </div>
        </div>

        <div className="patient-profile-information-label">
          <div className="patient-profile-information-label-field">Primary Language:</div>
          <div className="patient-profile-information-label-field">
            {primaryLanguage ? languageCodes[primaryLanguage]?.name : '-'}
          </div>
        </div>
      </div>

      <div className="patient-profile-information-data-row">
        <div className="patient-profile-information-label">
          <div className="patient-profile-information-label-field">Email:</div>
          <div aria-label="patient-email-value" className="patient-profile-information-label-field">
            {email || '-'}
          </div>
        </div>
      </div>

      <div className="patient-profile-information-data-row">
        <div className="patient-profile-information-label">
          <div className="patient-profile-information-label-field">Preferred Contact Method:</div>
          <div className="patient-profile-information-label-field">
            {(contactCodes && contactCodes[phonesInfo?.preferredContactMethod]?.name) || '-'}
          </div>
        </div>

        <div className="patient-profile-information-label">
          <div className="patient-profile-information-label-field">SMS Opt-in:</div>
          <div className="patient-profile-information-label-field">{resolveDropDownOption(smsOptIn)}</div>
        </div>
      </div>

      <div className="patient-profile-information-data-row">
        <div className="patient-profile-information-label">
          <div className="patient-profile-information-label-field">Address:</div>
          <div className="patient-profile-information-label-field">{formattedAddress}</div>
        </div>
      </div>

      <div className="patient-profile-information-data-row">
        <div className="patient-profile-information-label">
          <div className="patient-profile-information-label-field">Interested in Future Research:</div>
          <div className="patient-profile-information-label-field">
            {resolveDropDownOption(interestedInFutureResearch)}
          </div>
        </div>
      </div>
    </div>
  );

  function resolveDropDownOption(option) {
    if (option === 'YES') {
      return 'Y';
    }
    if (option === 'NO') {
      return 'N';
    }
    return 'Not Asked';
  }
}
