import React, { useState } from 'react';
import { isEmpty } from 'lodash/lang';
import moment from 'moment';

import DatePicker from '../../../../../../../common/data-entry/DatePicker';
import Input from '../../../../../../../common/data-entry/Input/Input';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';
import { isValidDateToSelect } from '../revenueDatesService';
export default function EnterRevenueDatesModal({ modalBox, onSave, currentRevenueDate }) {
  const [newRevenueDate, setNewRevenueDate] = useState(null);
  const [changeReason, setChangeReason] = useState(null);

  return (
    <>
      <ModalBoxes.Body>
        <div className="row">
          <div className="col-md-12">
            <DatePicker
              isValidDate={isValidDateToSelect.bind(currentRevenueDate)}
              viewDate={moment(currentRevenueDate)}
              dateFormat="DD/MMM/YYYY"
              timeFormat={false}
              closeOnSelect
              label="New Revenue date"
              readOnly
              value={newRevenueDate}
              onChange={function(date) {
                setNewRevenueDate(date);
              }}
            />
          </div>
          <div className="col-md-12">
            <Input
              label="Change Reason"
              value={changeReason}
              onChange={function(e) {
                setChangeReason(e.target.value);
              }}
            />
          </div>
        </div>
      </ModalBoxes.Body>

      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            Cancel
          </Button>
          <Button
            disabled={isEmpty(changeReason) || isEmpty(newRevenueDate)}
            onClick={function() {
              onSave({
                revenueDate: newRevenueDate.format('YYYY-MM-DD'),
                reason: changeReason
              });
              modalBox.close();
            }}
          >
            Save
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
}

EnterRevenueDatesModal.className = 'enter-revenue-dates-modal';
EnterRevenueDatesModal.size = 'w650';
