import React, { useCallback, useState } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import moment from 'moment/moment';

import SitePaymentsApi from '../../../../../../../api/finance/SitePaymentsApi';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../../../constants/dateFormat';

export const MarkAsPaidModal = ({ modalBox, checkedEvents, applyFilter }) => {
  const [sitePaymentDate, setSitePaymentDate] = useState(moment());
  const invalidDate = !sitePaymentDate?.isValid();

  const onMarkAsPaid = useCallback(() => {
    SitePaymentsApi.markAsPaid(checkedEvents, sitePaymentDate)
      .then(() => {
        modalBox.close();
        applyFilter();
        NotificationManager.success('Event(s) successfully Marked as Paid');
      })
      .catch(() => {
        NotificationManager.error('Event(s) unable to be Marked As Paid, please try again');
      });
  }, [applyFilter, checkedEvents, modalBox, sitePaymentDate]);

  return (
    <>
      <ModalBoxes.Body>
        <DesktopDatePicker
          label="Site Payment Date"
          value={sitePaymentDate}
          onChange={function(date) {
            setSitePaymentDate(date);
          }}
          format={DD_SLASH_MMM_SLASH_YYYY}
          views={['year', 'month', 'day']}
          slotProps={{
            textField: {
              variant: 'standard',
              required: true,
              fullWidth: true,
              error: invalidDate,
              helperText: invalidDate ? 'Date is required' : ''
            }
          }}
        />
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="low" onClick={modalBox.close}>
            Cancel
          </Button>
          <Button onClick={onMarkAsPaid} data-testid="mark-as-paid-modal-button" disabled={invalidDate}>
            Mark as Paid
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
};
