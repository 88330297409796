export function removeRelationInOtherEncounters(epochList, enNodeKeysForDelete, epochWhichWillNotLoseRelations = []) {
  return epochList.map(function(epoch) {
    const { encounters, nodeKey } = epoch;
    if (!epochWhichWillNotLoseRelations.includes(nodeKey)) {
      epoch.encounters = encounters.map(function(encounter) {
        const { parentEnNodeKeys } = encounter;
        encounter.parentEnNodeKeys = parentEnNodeKeys.filter(function(parentEnNodeKey) {
          return !enNodeKeysForDelete.includes(parentEnNodeKey);
        });
        return encounter;
      });
    }
    return epoch;
  });
}

export function getEncounterAbbreviation(name, inDisplayName) {
  if (name) {
    const withoutSpace = name.replace(/\s+/g, ' ').trim();
    const spt = withoutSpace.split(' ');
    if (spt.length && spt.length > 1) {
      return `${spt[0].charAt(0).toUpperCase()}${spt[1].charAt(0).toUpperCase()}`;
    }
    return `${spt[0].charAt(0).toUpperCase()}`;
  }
  return inDisplayName;
}
