import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import Icon from '../../../../../../common/general/Icon';
import { scGray70 } from '../../../../../../constants/systemColors';
import InformedConsentFile from '../../../shared/NewEncounterPage/InformedConsent/InformedConsentFile';

import './FilesSection.scss';

export const FilesSection = ({ forms }) => {
  const listContainerRef = useRef(null);
  const [childWidth, setChildWidth] = useState([]);
  const [maxItemsPerPage, setMaxItemsPerPage] = useState(2);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(1);

  const marginSize = 80;

  useEffect(() => {
    const fileContainers = listContainerRef.current.querySelectorAll('.single-file-container');
    setChildWidth([...fileContainers].map(({ offsetWidth }) => offsetWidth).reduce((a, b) => a + b));
  }, []);

  useLayoutEffect(() => {
    const handleResize = () => {
      const parentContainer = listContainerRef.current.parentElement.parentElement;
      const arrowNav = parentContainer.querySelectorAll('.icf-files-container > i');
      const arrowsWidth = [...arrowNav].reduce((a, b) => a.offsetWidth + b.offsetWidth);
      const newMessageWidth = parentContainer.querySelector('.new-message-container').offsetWidth;

      setMaxItemsPerPage(calculateMaxItemPerPage(parentContainer.offsetWidth, newMessageWidth, arrowsWidth));

      const newPageSize = Math.ceil(forms.length / maxItemsPerPage);
      setPageSize(newPageSize);
      setCurrentPage(Math.min(currentPage, newPageSize - 1));
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const calculateMaxItemPerPage = (parentWidth, newMessageWidth, arrowsWidth) => {
    const maxNumberOfForms = Math.ceil((parentWidth - newMessageWidth - arrowsWidth - marginSize) / childWidth);
    if (maxNumberOfForms > 2) {
      return 2;
    }
    if (maxNumberOfForms < 1) {
      return 1;
    }
    return maxNumberOfForms;
  };

  const getItemFromList = list => {
    return list.slice(currentPage * maxItemsPerPage, (currentPage + 1) * maxItemsPerPage);
  };

  const handlePrevPage = () => {
    setCurrentPage(Math.max(0, currentPage - 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(pageSize - 1, currentPage + 1));
  };

  return (
    <div className={'icf-files-container'}>
      <Icon
        style={{
          color: scGray70,
          fontSize: 30,
          cursor: 'pointer',
          visibility: currentPage === 0 ? 'hidden' : 'visible'
        }}
        onClick={handlePrevPage}
      >
        keyboard_arrow_left
      </Icon>
      <div className={'icf-files-list'} ref={listContainerRef}>
        {getItemFromList(forms).map((form, index) => (
          <InformedConsentFile key={index} informedConsentForm={form} ssuId={form.ssuId} />
        ))}
      </div>
      <Icon
        style={{
          marginLeft: 20,
          color: scGray70,
          fontSize: 30,
          cursor: 'pointer',
          visibility: currentPage === pageSize - 1 ? 'hidden' : 'visible'
        }}
        onClick={handleNextPage}
      >
        keyboard_arrow_right
      </Icon>
    </div>
  );
};
