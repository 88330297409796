import { isEmpty } from 'lodash/lang';
import { get } from 'lodash/object';

import store from '../../../store';

import { PATIENT_ENCOUNTER_EVENT, PATIENT_SITUATIONAL_ENCOUNTER_EVENT } from './CalendarEventType';
import { CALENDAR_SELECTED_EVENT } from './CalendarPageContext';

function getUserId() {
  return get(store.getState(), 'currentUser.personnelIdentifier');
}

function getUserRole() {
  return get(store.getState(), 'currentUser.activeRole');
}

function getValue(key) {
  const userId = getUserId();
  const role = getUserRole();
  const calendarFilterString = localStorage.getItem(key) || '{}';
  const calendarFilterElement = JSON.parse(calendarFilterString);
  return get(calendarFilterElement, `${userId}-${role}`);
}

function setValue(key, value) {
  const userId = getUserId();
  const role = getUserRole();
  const calendarFilterString = localStorage.getItem(key) || '{}';
  const calendarFilterElement = JSON.parse(calendarFilterString);
  calendarFilterElement[`${userId}-${role}`] = value;
  localStorage.setItem(key, JSON.stringify(calendarFilterElement));
}

function getSelectedEventValue() {
  return JSON.parse(sessionStorage.getItem(CALENDAR_SELECTED_EVENT));
}

export function getInitialEventTypes() {
  if (!isEmpty(getSelectedEventValue())) {
    return [PATIENT_ENCOUNTER_EVENT, PATIENT_SITUATIONAL_ENCOUNTER_EVENT];
  }

  return getValue('calendarFilterEventTypes');
}

export function getInitialEventStatus() {
  if (!isEmpty(getSelectedEventValue())) {
    return ['SCHEDULED', 'ACTIVE', 'COMPLETED', 'CANCELLED'];
  }
  return getValue('calendarFilterEventStatus');
}

export function setInitialEventTypes(eventTypes) {
  return setValue('calendarFilterEventTypes', eventTypes);
}

export function setInitialMilestoneTypes(eventTypes) {
  return setValue('calendarFilterMilestoneTypes', eventTypes);
}

export function getInitialMilestoneTypes() {
  if (!isEmpty(getSelectedEventValue())) {
    return [];
  }
  return getValue('calendarFilterMilestoneTypes');
}

export function setInitialEventStatus(eventStatus) {
  return setValue('calendarFilterEventStatus', eventStatus);
}

export function getInitialNonPatientEventTypes() {
  if (!isEmpty(getSelectedEventValue())) {
    return [];
  }
  return getValue('calendarFilterNonPatientEventTypes');
}

export function setInitialNonPatientEventTypes(eventTypes) {
  return setValue('calendarFilterNonPatientEventTypes', eventTypes);
}

export function getInitialTimeZone() {
  return getValue('initialTimeZone');
}

export function setInitialTimeZone(timeZone) {
  return setValue('initialTimeZone', timeZone);
}
