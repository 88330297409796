import React, { useContext } from 'react';

import Button from '../../../../../../../../../../common/general/Button';
import { VIEW_PI_REVIEW, VIEW_SM_REVIEW } from '../../../../../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../../../../../services/auth';
import { generateUrlByKey, useCurrentRoute } from '../../../../../../../../../root/router';
import { PatientInfoContext } from '../../../../../PatientInfoContext';
import { useEncounterSegment } from '../EncounterSegment';

export default function HeaderReview() {
  const currentRoute = useCurrentRoute();
  const { currentStudy } = useContext(PatientInfoContext);
  const { encounter } = useEncounterSegment();
  const patientId = currentRoute.params?.patientId;
  const ssuPatientId = currentRoute.params?.ssuPatientId;
  const { sitePatientEncounter } = encounter;
  const groupAssignStudy = currentStudy?.groupAssignStudy;

  const url = generateBeginReviewUrl();

  return (
    <Button size="h28" disabled={!url} to={url}>
      Begin Review
    </Button>
  );

  function generateBeginReviewUrl() {
    const {
      id: patientEncounterId,
      encounterStatus,
      investigatorReviewPatientItemGroupId,
      smReviewPatientItemGroupId,
      hasSmReview,
      hasInvestigatorReview,
      smGroupAssignId,
      investigatorGroupAssignId
    } = sitePatientEncounter;

    if (!userHasAccessTo(resolveByEncounterStatus(VIEW_SM_REVIEW, VIEW_PI_REVIEW))) {
      return null;
    }

    if (
      resolveByEncounterStatus(
        resolveByGroupAssign(!smGroupAssignId, !smReviewPatientItemGroupId || !hasSmReview),
        resolveByGroupAssign(
          !investigatorGroupAssignId,
          !investigatorReviewPatientItemGroupId || !hasInvestigatorReview
        )
      )
    ) {
      return null;
    }

    return generateUrlByKey(
      `${currentRoute.key}.Encounter Details.${resolveByEncounterStatus(
        resolveByGroupAssign(`Study Manager Encounter Review`, `SM Review`),
        resolveByGroupAssign(`Investigator Encounter Review`, `Investigator Review`)
      )}`,
      {
        patientId,
        ssuPatientId,
        patientEncounterId,
        reviewPatientItemGroupId: resolveByEncounterStatus(
          smReviewPatientItemGroupId,
          investigatorReviewPatientItemGroupId
        ),
        groupAssignId: resolveByEncounterStatus(smGroupAssignId, investigatorGroupAssignId),
        signatureId: 'current'
      }
    );

    function resolveByEncounterStatus(a, b) {
      return {
        SM_REVIEW: a,
        PI_REVIEW: b
      }[encounterStatus];
    }

    function resolveByGroupAssign(a, b) {
      return groupAssignStudy ? a : b;
    }
  }
}
