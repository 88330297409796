import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { uniqBy } from 'lodash/array';
import { sortBy } from 'lodash/collection';
import * as PropTypes from 'prop-types';

import { StudySiteApi } from '../../../../../api';
import { SSUSelect } from '../../../../SSUFilter/SSUSelect';

export default function StudySiteSelector({ onSiteChange, onStudyChange, onPcnChange, studyId, siteId, pcnFlag }) {
  const [studySiteList, setStudySiteList] = useState([]);

  useEffect(function() {
    StudySiteApi.getAllStudySitesAndMap().then(({ data: studySites }) => {
      setStudySiteList(studySites);
    });
  }, []);

  const extractNecessaryListFromStudySite = useCallback(
    function(uniqueIdentifier, filterIdentifier, name) {
      const studySiteFilteredByIdentifier = studySiteList.filter(
        ssu => !uniqueIdentifier || uniqueIdentifier === ssu[filterIdentifier]
      );
      const list = studySiteFilteredByIdentifier.map(ssu => ssu[name]);
      const uniqueList = uniqBy(list, 'uniqueIdentifier');
      return sortBy(uniqueList, `${name}Name`);
    },
    [studySiteList]
  );

  const studies = useMemo(
    function() {
      return extractNecessaryListFromStudySite(siteId, 'siteIdentifier', 'study');
    },
    [siteId, extractNecessaryListFromStudySite]
  );

  const studiesForPcn = useMemo(
    function() {
      return sortBy(
        uniqBy(
          studySiteList
            .filter(({ study }) => (studyId ? study.uniqueIdentifier === studyId : true))
            .filter(({ site }) => (siteId ? site.uniqueIdentifier === siteId : true))
            .map(({ study }) => study)
            .filter(study => study.projectCode),
          'uniqueIdentifier'
        ),
        'studyName'
      );
    },
    [studyId, siteId, studySiteList]
  );

  const sites = useMemo(
    function() {
      return extractNecessaryListFromStudySite(studyId, 'studyIdentifier', 'site');
    },
    [studyId, extractNecessaryListFromStudySite]
  );
  const initialStudy = useMemo(
    function() {
      return studies.find(({ uniqueIdentifier }) => uniqueIdentifier === studyId);
    },
    [studyId, studies]
  );
  const initialSite = useMemo(
    function() {
      return sites.find(({ uniqueIdentifier }) => uniqueIdentifier === siteId);
    },
    [siteId, sites]
  );

  const studyPcnOrNoPCNMessage = studies => studies.map(study => (study.projectCode ? study.projectCode : 'No PCN'));

  return (
    <SSUSelect
      handleSiteChange={onSiteChange}
      handleStudyChange={onStudyChange}
      handlePcnChange={study => onPcnChange(study, sites)}
      studies={studies}
      studiesForPcn={studiesForPcn}
      sites={sites}
      initialStudy={initialStudy}
      initialSite={initialSite}
      pcnFlag={pcnFlag}
      customSelectedValueTemplateFunction={studyPcnOrNoPCNMessage}
    />
  );
}

StudySiteSelector.defaultProps = {
  pcnFlag: false
};

StudySiteSelector.propTypes = {
  pcnFlag: PropTypes.bool,
  onSiteChange: PropTypes.func,
  onStudyChange: PropTypes.func,
  studyId: PropTypes.string,
  siteId: PropTypes.string
};
