import { FinBudgetApi } from '../../../api';
import NotificationManager from '../../../common/notifications/NotificationManager';
import { STUDY_BUDGET_ADD_FAILED, STUDY_BUDGET_ADDED } from '../../../constants/notificationMessages';
import { onRequestError } from '../../../services/handlers';
import { ADD_STUDY_BUDGET } from '../../actionTypes';

import { toBudgetListFormat } from './transformToBudgetListAction';

export const addStudyBudgetAction = copy => {
  return {
    type: ADD_STUDY_BUDGET,
    payload: copy
  };
};

export const addStudyBudget = copy => dispatch => {
  return FinBudgetApi.save(copy)
    .then(resp => {
      NotificationManager.success(STUDY_BUDGET_ADDED);
      dispatch(addStudyBudgetAction(toBudgetListFormat(resp.data)));
    })
    .catch(err => onRequestError(err, { customMessage: STUDY_BUDGET_ADD_FAILED }));
};
