import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { isEmpty } from 'lodash/lang';
import Papa from 'papaparse';

import { pendoTrackDefaultSortingChange } from '../../../pendo/PendoUtils';

import './FvCsvViewer.scss';
export function FvCsvViewer({ file }) {
  const { url } = file,
    [data, setData] = useState([]),
    [columns, setColumns] = useState([]),
    [loading, setLoading] = useState(true);

  useEffect(
    function() {
      if (!url) {
        return;
      }
      Papa.parse(url, {
        download: true,
        dynamicTyping: true,
        complete: file => {
          if (isEmpty(file.data)) {
            return;
          }
          setData(file.data);
          setColumns([
            {
              Header: '',
              id: 'row',
              width: 42,
              Cell: ({ index }) => index + 1
            },
            ...generateColumns(file.data[0])
          ]);
        }
      });
    },
    [url]
  );

  useEffect(() => {
    if (data.length && columns.length) {
      setLoading(false);
    }
  }, [data, columns]);

  return (
    <div className="eds-fv-csv-viewer">
      {!loading && (
        <ReactTable
          data={data}
          onSortedChange={pendoTrackDefaultSortingChange}
          columns={columns}
          defaultPageSize={50}
          className="-striped -highlight"
        />
      )}
    </div>
  );
}

function generateColumns(firstRow) {
  return firstRow.map((c, i) => ({ Header: toColumnName(i), accessor: i.toString() }));
}

function toColumnName(index) {
  let num = index + 1,
    ret = '';
  for (let a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
    ret = String.fromCharCode(Math.round((num % b) / a) + 65) + ret;
  }
  return ret;
}
