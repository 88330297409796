import React, { Component } from 'react';
import Timeline, { DateHeader, SidebarHeader, TimelineHeaders } from 'react-calendar-timeline';
import ReactTable from 'react-table';
import moment from 'moment';

import { StudySiteStartupApi } from '../../../../api';
import TableIconButton from '../../../../common/buttons/TableIconButton';
import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import { scPurple80 } from '../../../../constants/systemColors';
import { MANAGE_STUDY_TIMELINES } from '../../../../constants/userOperations';
import { pendoTrackDefaultSortingChange } from '../../../../pendo/PendoUtils';
import { userHasAccessTo } from '../../../../services/auth';
import { onRequestError } from '../../../../services/handlers';
import { PageInfoHeader } from '../../../PageInfoHeader/PageInfoHeader';
import { SSUFilter } from '../../../SSUFilter/SSUFilter';
import { SSUPCNFilter } from '../../../SSUFilter/SSUPCNFilter';

import UpdateMilestonesModal from './UpdateMilestonesModal/UpdateMilestonesModal';

import 'react-calendar-timeline/lib/Timeline.css';

const legendContainer = {
  marginTop: '15px',
  border: '1px solid #bbb',
  width: '135px',
  padding: '5px'
};

const legendActualBoxStyle = {
  display: 'inline-block',
  color: 'green',
  backgroundColor: 'green',
  height: '10px',
  width: '10px',
  border: '1px solid green',
  marginRight: '5px'
};

const legendProjectedBoxStyle = {
  display: 'inline-block',
  color: 'blue',
  backgroundColor: 'blue',
  height: '10px',
  width: '10px',
  border: '1px solid blue',
  marginRight: '5px'
};

const timelineHeader = {
  position: 'sticky',
  top: 0,
  zIndex: '95'
};
const STUDY_TIMELINE_TABLE_DATA = 'STUDY_TIMELINE_TABLE_DATA';
class StudyTimeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table: [],
      timelineGroups: [],
      timelineItems: [],
      pageSize: sessionStorage.getItem(STUDY_TIMELINE_TABLE_DATA)
        ? JSON.parse(sessionStorage.getItem(STUDY_TIMELINE_TABLE_DATA))?.pageSize
        : 25 //oneOf([25, 50, 100])
    };
    this.columns = [];
  }

  componentDidMount() {
    this.columns = [
      {
        Header: 'Study',
        accessor: 'studyName',
        Cell: row => {
          return row.value;
        }
      },
      {
        Header: 'Site',
        accessor: 'siteName',
        Cell: row => {
          return row.value;
        }
      },
      {
        Header: 'PCN',
        accessor: 'pcn',
        Cell: row => row.value
      },
      {
        Header: 'PSV',
        accessor: 'PSV',
        Cell: row => {
          return row.value ? <span>{moment(row.value).format('DD/MMM/YYYY')}</span> : '--';
        }
      },
      {
        Header: 'SIV',
        accessor: 'SIV',
        Cell: row => {
          return row.value ? <span>{moment(row.value).format('DD/MMM/YYYY')}</span> : '--';
        }
      },
      {
        Header: 'Sponsor Greenlight',
        accessor: 'SGL',
        Cell: row => {
          return row.value ? <span>{moment(row.value).format('DD/MMM/YYYY')}</span> : '--';
        }
      },
      {
        Header: 'Elligo Greenlight',
        accessor: 'GRN',
        Cell: row => {
          return row.value ? <span>{moment(row.value).format('DD/MMM/YYYY')}</span> : '--';
        }
      },
      {
        Header: 'Enrollment End',
        accessor: 'END',
        Cell: row => {
          return row.value ? <span>{moment(row.value).format('DD/MMM/YYYY')}</span> : '--';
        }
      },
      {
        Header: 'Action',
        accessor: 'ssuId',
        Cell: row => {
          return (
            <div>
              <TableIconButton
                suit="glyphicon"
                title="Update Milestones"
                disabled={!userHasAccessTo(MANAGE_STUDY_TIMELINES)}
                onClick={() => this.showUpdateMilestonesModal(row.value, this.refreshPage)}
              >
                edit
              </TableIconButton>
            </div>
          );
        }
      }
    ];
  }

  refreshPage = () => {
    const ssuIds = this.state.table.map(ssu => ssu.ssuId);
    StudySiteStartupApi.getMilestoneForSSu(ssuIds).then(({ data }) => {
      this.tableDataFormatter(data);
      this.timeLineFormatter(data);
    }, onRequestError);
  };

  tableDataFormatter(data) {
    const mapped = data.map(ssu => {
      const newSsu = {
        studyName: ssu.studyName,
        siteName: ssu.siteName,
        pcn: ssu.pcn,
        ssuId: ssu.ssuId
      };
      if (ssu.milestones.length > 0) {
        ssu.milestones.forEach(milestone => {
          newSsu[milestone.milestoneCode] = milestone.milestoneDate;
        });
      }
      return newSsu;
    });
    this.setState({
      table: mapped
    });
  }

  timeLineFormatter(data) {
    const timelineGroups = data.map(ssu => {
      return {
        id: ssu.ssuId,
        title: `${ssu.studyName} - ${ssu.siteName}`
      };
    });

    const timelineItems = [];
    data.forEach(ssu => {
      if (ssu.milestones.length > 0) {
        ssu.milestones.forEach(ms => {
          timelineItems.push({
            id: ms.id,
            group: ssu.ssuId,
            title: ms.milestoneCode,
            tip: `${moment(ms.milestoneDate, 'YYYY-MM-DD').format('DD/MMM/YYYY')} - ${ms.milestoneCodeValue}`,
            start_time: moment(ms.milestoneDate, 'YYYY-MM-DD')
              .startOf('day')
              .valueOf(),
            end_time: moment(ms.milestoneDate, 'YYYY-MM-DD')
              .endOf('day')
              .valueOf(),
            canMove: false,
            canResize: false,
            canChangeGroup: false,
            bgColor: ms.actual ? 'green' : 'blue',
            color: 'white'
          });
        });
      }
    });
    this.setState({
      timelineGroups,
      timelineItems
    });
  }

  itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    const backgroundColor = itemContext.selected ? item.selectedBgColor : item.bgColor;
    const borderColor = item.color;
    return (
      <div
        {...getItemProps({
          style: {
            backgroundColor,
            color: item.color,
            borderColor
          }
        })}
        title={item.tip}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

        <div
          style={{
            height: itemContext.dimensions.height,
            overflow: 'hidden',
            paddingLeft: 3,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {itemContext.title}
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
    );
  };

  handleSSUFilterChange = ssus => {
    const ssuIds = ssus.map(ssu => ssu.uniqueIdentifier);
    StudySiteStartupApi.getMilestoneForSSu(ssuIds).then(({ data }) => {
      this.tableDataFormatter(data);
      this.timeLineFormatter(data);
    }, onRequestError);
  };

  showUpdateMilestonesModal = (ssuId, refreshPage) => {
    ModalBoxes.open({
      component: <UpdateMilestonesModal ssuId={ssuId} refreshPage={refreshPage} />
    });
  };

  render() {
    const groups = this.state.timelineGroups;
    const items = this.state.timelineItems;

    return (
      <div className="footpadb">
        <PageInfoHeader>
          <div className="general-header-group-container general-header-wrapper">
            <SSUFilter handleSSUFilterChange={this.handleSSUFilterChange}>
              <SSUPCNFilter />
            </SSUFilter>
          </div>
        </PageInfoHeader>
        <section>
          <div className="border p-3 m-0 my-2">
            <Timeline
              groups={groups}
              items={items}
              defaultTimeStart={moment().startOf('day')}
              defaultTimeEnd={moment().add(1, 'M')}
              itemRenderer={this.itemRenderer}
              lineHeight={35}
              minZoom={1000 * 60 * 60 * 24 * 7} // min view is 1 week
              maxZoom={1000 * 60 * 60 * 24 * 7 * 26} // max view is 6 months
              style={{
                maxHeight: '500px',
                overflowY: 'auto',
                border: '1px solid #bbb',
                borderTop: 'none',
                borderBottom: 'none'
              }}
              sidebarWidth={250}
            >
              <TimelineHeaders style={timelineHeader} calendarHeaderStyle={{ backgroundColor: scPurple80 }}>
                <SidebarHeader>
                  {({ getRootProps }) => {
                    return (
                      <div
                        {...getRootProps({
                          style: {
                            backgroundColor: scPurple80,
                            color: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '1px solid #691e44'
                          }
                        })}
                      >
                        SSUs
                      </div>
                    );
                  }}
                </SidebarHeader>
                <DateHeader unit="primaryHeader" />
                <DateHeader />
              </TimelineHeaders>
            </Timeline>
            <div style={legendContainer}>
              <div>
                <span style={legendActualBoxStyle} />
                <span>Actual Date</span>
              </div>
              <div>
                <span style={legendProjectedBoxStyle} />
                <span>Projected Date</span>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="col p-0 pt-2">
            <h5 className="c-p">Study Timeline Details</h5>
          </div>
          <ReactTable
            className={'rt-table-wrap'}
            data={this.state.table}
            onPageSizeChange={pageSize => {
              this.setState({ pageSize: pageSize });
              sessionStorage.setItem(STUDY_TIMELINE_TABLE_DATA, JSON.stringify({ pageSize: pageSize }));
            }}
            columns={this.columns}
            minRows={1}
            multiSort
            onSortedChange={pendoTrackDefaultSortingChange}
            showPagination
            nextText=">>"
            previousText="<<"
            noDataText="No Record Found"
            pageSizeOptions={[25, 50, 100]}
            defaultPageSize={this.state.pageSize}
          />
        </section>
      </div>
    );
  }
}

export default StudyTimeline;
