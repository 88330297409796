import React, { Component } from 'react';
import ReactTable from 'react-table';
import { isEmpty, isNull } from 'lodash/lang';
import { get } from 'lodash/object';

import { EncounterApi, StudySiteApi } from '../../../../api';
import Button from '../../../../common/general/Button';
import { withSessionStorage } from '../../../../common/hocs/withSessionStorage';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../constants/dateFormat';
import { CRA_WORKLIST_VALUES } from '../../../../constants/sessionStorageConstants';
import { CLOSED } from '../../../../constants/ssuStatuses';
import { MANAGE_PROGRESS_NOTES, VIEW_PROGRESS_NOTES } from '../../../../constants/userOperations';
import { ROLE_SYSTEM_ADMINISTRATOR } from '../../../../constants/userRoles';
import { pendoTrackDefaultSortingChange } from '../../../../pendo/PendoUtils';
import { userHasAccessTo, userHasRole } from '../../../../services/auth';
import { onRequestError } from '../../../../services/handlers';
import { mapStudySite } from '../../../../services/studySiteMapper';
import ApplyAndResetButtons from '../../../ApplyAndResetButtons/ApplyAndResetButtons';
import { CellFormattedDate } from '../../../CellFormattedDate/CellFormattedDate';
import { PageInfoHeader } from '../../../PageInfoHeader/PageInfoHeader';
import { SSUFilter } from '../../../SSUFilter/SSUFilter';
import SummaryColumnContent from '../shared/SummaryColumnContent';

import {
  CRA_REVIEW_PAGE,
  CRA_REVIEW_PAGE_SIZE,
  CRA_REVIEW_SORTED_BY,
  CRA_REVIEW_SUBJECT_ID
} from './defaultCraReviewTableConfiguration';

import 'react-table/react-table.css';
import './CraReview.scss';

const defaultFilters = {
  studyUniqueIdentifier: null,
  siteUniqueIdentifier: null,
  subjectId: CRA_REVIEW_SUBJECT_ID,
  page: CRA_REVIEW_PAGE,
  pageSize: CRA_REVIEW_PAGE_SIZE,
  sortedBy: [CRA_REVIEW_SORTED_BY]
};
let timeoutInstances = [];

class CraReviewComponent extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      craPatientListData: [],
      columns: [],
      subheaderOptions: {
        site: true,
        study: true,
        status: false,
        dropDownOptions: {
          multiSelect: false,
          keepOpenOnSelection: false
        }
      }
    };
    this.ssu = StudySiteApi.getAllStudySites().then(res => {
      return res.data.map(ssu => mapStudySite(ssu));
    });
  }

  onChangeSearchInput = e => {
    const subjectId = e.target.value;
    this.updateSearchFilter(subjectId);
  };

  getSessionStorageControl = () => this.props[CRA_WORKLIST_VALUES];

  updateFilter(paramName, subjectId) {
    const [filters, setFilters] = this.getSessionStorageControl();
    const { searchStringPage } = filters;
    if (paramName === 'page' && !isNull(searchStringPage)) {
      setFilters(filters => {
        return { ...filters, searchStringPage: subjectId };
      });
    } else {
      setFilters(filters => {
        return { ...filters, [paramName]: subjectId };
      });
    }
    if (paramName !== 'totalPages') {
      this.updateData(null, paramName, subjectId);
    }
  }

  updateSearchFilter(subjectId) {
    const [, setFilters] = this.getSessionStorageControl();
    if (subjectId !== '') {
      setFilters(filters => {
        return { ...filters, subjectId, searchStringPage: 0 };
      });
    } else {
      setFilters(filters => {
        return { ...filters, subjectId, searchStringPage: null };
      });
    }
    if (timeoutInstances.length > 0) {
      timeoutInstances.forEach(timeoutInstance => clearTimeout(timeoutInstance));
    }

    const timeoutInstance = setTimeout(() => this.updateData(null, 'subjectId', subjectId), 500);
    timeoutInstances.push(timeoutInstance);
  }

  handleSSUFilterChange = (ssus, study, site) => {
    const [filters, setFilters] = this.getSessionStorageControl();
    const studyUniqueIdentifier = get(study, 'uniqueIdentifier');
    const siteUniqueIdentifier = get(site, 'uniqueIdentifier');
    const ssuIds = ssus.map(({ uniqueIdentifier }) => uniqueIdentifier);
    if (
      studyUniqueIdentifier !== filters.studyUniqueIdentifier ||
      siteUniqueIdentifier !== filters.siteUniqueIdentifier ||
      isEmpty(this.state.craPatientListData)
    ) {
      setFilters({
        ...filters,
        studyUniqueIdentifier: studyUniqueIdentifier,
        siteUniqueIdentifier: siteUniqueIdentifier,
        ssuIds: ssuIds,
        page: 0,
        searchStringPage: null
      });
      this.updateData(ssuIds, 'page', 0);
    }
  };

  prepareRequestData = (newSsuIds = null, changedParam = null, changedParamValue = null) => {
    const [filters] = this.getSessionStorageControl();
    const { page, pageSize, sortedBy, ssuIds, subjectId, searchStringPage } = filters;

    const requestSsuIds = newSsuIds ? newSsuIds : ssuIds;

    let requestPage;
    if (changedParam === 'searchStringPage' || changedParam === 'page') {
      requestPage = changedParamValue;
    } else {
      requestPage = !isNull(searchStringPage) ? searchStringPage : page;
    }

    const requestPageSize = changedParam === 'pageSize' ? changedParamValue : pageSize;
    const requestSortedBy = changedParam === 'sortedBy' ? changedParamValue : sortedBy;
    const requestSearchString = changedParam === 'subjectId' ? changedParamValue : subjectId;

    return {
      ssuIds: requestSsuIds,
      pageSize: requestPageSize,
      page: requestPage,
      searchString: requestSearchString,
      sortedBy: {
        property: get(requestSortedBy, '[0].id'),
        direction: get(requestSortedBy, '[0].desc') ? 'DESC' : 'ASC'
      }
    };
  };

  updateData = (newSsuIds = null, changedParam = null, changedParamValue = null) => {
    const request = this.prepareRequestData(newSsuIds, changedParam, changedParamValue);

    EncounterApi.getEncountersBySSUList(request).then(({ data }) => {
      this.setState({ craPatientListData: data.content });
      this.updateFilter('totalPages', data.totalPages);
    }, onRequestError);
  };

  render({ state } = this) {
    const [filters, setFilters] = this.getSessionStorageControl();
    const { subjectId, page, pageSize, sortedBy, totalPages, searchStringPage } = filters;
    const noAnyAppliedFilters = () => {
      return !(filters.studyUniqueIdentifier || filters.siteUniqueIdentifier || filters.subjectId);
    };
    let data = state.craPatientListData;
    const columns = [
      {
        Header: 'Subject ID',
        accessor: 'subjectId',
        Cell: row => {
          return row.original.subjectId || 'N/A';
        },
        sortMethod(a, b) {
          const aSubjectId = a?.toUpperCase();
          const bSubjectId = b?.toUpperCase();
          return (a === null) - (b === null) || +(aSubjectId > bSubjectId) || -(aSubjectId < bSubjectId);
        }
      },
      {
        Header: 'Study',
        accessor: 'studyName',
        Cell: row => {
          return this.resolveCellValue(row.original.studyName);
        }
      },
      {
        Header: 'Site',
        accessor: 'siteName',
        Cell: row => {
          return this.resolveCellValue(row.original.siteName);
        }
      },
      {
        Header: 'Epoch',
        accessor: 'epochName',
        Cell: row => {
          return this.resolveCellValue(row.original.epochName);
        }
      },
      {
        Header: 'Encounter',
        accessor: 'encounterName',
        Cell: row => {
          return this.resolveCellValue(row.original.encounterName);
        }
      },
      {
        Header: 'Start Date',
        accessor: 'encounterStartDate',
        Cell: ({ value }) => (
          <CellFormattedDate date={new Date(value)} format={DD_SLASH_MMM_SLASH_YYYY} defaultValue={'--'} />
        )
      },
      {
        Header: 'Summary',
        accessor: 'review',
        sortable: false,
        className: 'summary-cell',
        width: 162,
        Cell: row => {
          if (row.original && row.original.sitePatientIdentifier) {
            const { sitePatientIdentifier, patientEncounterId, groupAssignStudy } = row.original;
            // const reviewPath = groupAssignStudy ? 'cra-review-group-assign' : 'cra-review';
            const reviewPath = 'cra-review';

            const hasAccessToManage =
              userHasRole(ROLE_SYSTEM_ADMINISTRATOR) ||
              (row.original.studySiteStatus !== CLOSED && userHasAccessTo(MANAGE_PROGRESS_NOTES));
            return (
              <SummaryColumnContent
                hasAccessToView={userHasAccessTo(VIEW_PROGRESS_NOTES)}
                hasAccessToEdit={hasAccessToManage}
                link={`/patient-source-review/${sitePatientIdentifier}/encounters/${patientEncounterId}/${reviewPath}`}
                sitePatientIdentifier={row.original.sitePatientIdentifier}
                studySiteStatus={row.original.studySiteStatus}
                notesInfo={row.original}
              />
            );
          }
          return (
            <Button size="h28" priority="high">
              FE
            </Button>
          );
        }
      }
    ];

    return (
      <div className="footpadb">
        <PageInfoHeader>
          <div className="general-header-group-container general-header-wrapper">
            <SSUFilter
              handleSSUFilterChange={this.handleSSUFilterChange}
              studyIdProvider={() => filters.studyUniqueIdentifier}
              siteIdProvider={() => filters.siteUniqueIdentifier}
              ssuProvider={() => this.ssu}
              resetFilterIfNoValue
            />
            <ApplyAndResetButtons
              onReset={() => {
                setFilters({
                  ...filters,
                  studyUniqueIdentifier: null,
                  siteUniqueIdentifier: null,
                  subjectId: CRA_REVIEW_SUBJECT_ID,
                  page: CRA_REVIEW_PAGE,
                  pageSize: CRA_REVIEW_PAGE_SIZE,
                  sortedBy: [CRA_REVIEW_SORTED_BY]
                });
                this.updateSearchFilter('');
              }}
              resetDisabled={noAnyAppliedFilters()}
            />
          </div>
        </PageInfoHeader>
        <section>
          <div className="row border p-3 m-0 my-2">
            <div className="col p-0 pt-2">
              <h5 className=" c-p">Review Encounters</h5>
            </div>
            <div className="col-auto p-0 mb-2 d-flex flex-row">
              <span className="px-2 pt-1">Search</span>
              <div className="input-group border">
                <input
                  className="form-control border-0"
                  placeholder=""
                  value={subjectId}
                  onChange={event => this.onChangeSearchInput(event)}
                />
                <div className="input-group-addon px-2 bg-p  search-icon">
                  <i className="material-icons pt-2 text-white">search</i>
                </div>
              </div>
            </div>
            <div className="col-12 px-0">
              <ReactTable
                onPageSizeChange={pageSize => {
                  this.updateFilter('pageSize', pageSize);
                }}
                onPageChange={page => {
                  this.updateFilter('page', page);
                }}
                onSortedChange={sortedBy => {
                  pendoTrackDefaultSortingChange(sortedBy);
                  this.updateFilter('sortedBy', sortedBy);
                }}
                data={data || []}
                columns={columns}
                minRows={1}
                multiSort
                showPagination
                nextText=">>"
                previousText="<<"
                noDataText="No Record Found"
                className="cra-review-table"
                pageSizeOptions={[25, 50, 100]}
                pageSize={pageSize}
                manual
                pages={totalPages}
                page={!isNull(searchStringPage) ? searchStringPage : page}
                defaultSorted={sortedBy}
              />
            </div>
          </div>
        </section>
      </div>
    );
  }

  resolveCellValue(value) {
    return value ? <span> {value}</span> : '--';
  }
}

const CraReview = withSessionStorage(CraReviewComponent, CRA_WORKLIST_VALUES, defaultFilters);

export default CraReview;
