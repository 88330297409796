import React from 'react';

import Icon from '../../../../../../common/general/Icon';

import './InvoicePageChip.scss';

const InvoicePageChip = ({ label, value, onClose, emptyValue }) => {
  return (
    <div className="invoice-chip" data-testid="invoice-chip">
      <div>{label}:</div>
      <div className="invoice-chip-value">{value ? value : emptyValue ? emptyValue : 'All'}</div>
      {!!value && onClose && (
        <Icon className="close-chip-icon" onClick={() => onClose(label)}>
          close
        </Icon>
      )}
    </div>
  );
};

export default InvoicePageChip;
