import React from 'react';
import { func, string } from 'prop-types';

import Icon from '../../../general/Icon';
import Input from '../Input';

import './SearchInput.scss';
const SearchInput = function({ label, name, onChange, value }) {
  return (
    <Input
      label={label}
      name={name}
      onChange={onChange}
      value={value}
      iconsAfter={<Icon>search</Icon>}
      className="eui-search-input"
      validate={false}
    />
  );
};

SearchInput.defaultProps = {
  label: 'Search',
  name: 'search'
};

SearchInput.propTypes = {
  label: string,
  name: string,
  onChange: func
};

export default SearchInput;
