import InfoIcon from '@mui/icons-material/Info';

import { scElligoRed, scGreen100, scYellow100 } from '../../../../../constants/systemColors';

const ContactAttemptDaysRemainingIcon = ({ days }) => {
  const resolveIcon = () => {
    if (days === null) {
      return '';
    } else if (days >= 2) {
      return <InfoIcon sx={{ color: scGreen100 }} />;
    } else if (days >= 0) {
      return <InfoIcon sx={{ color: scYellow100 }} />;
    } else if (days < 0) {
      return <InfoIcon sx={{ color: scElligoRed }} />;
    }
  };
  return resolveIcon();
};

export default ContactAttemptDaysRemainingIcon;
