import React from 'react';
import cx from 'classnames';
import moment from 'moment';

import './Header.scss';

export function Header(e) {
  const date = moment(e.date);
  const className = date.isSame(moment(), 'day') ? `headerToday` : '';
  return (
    <div className="eds-calendar-custom-header">
      <span className={cx('headerDate', className)}>{date.format('DD')}</span>
      <span className="dateName">{date.format('ddd').toUpperCase()}</span>
    </div>
  );
}
