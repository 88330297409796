import { map } from 'lodash/collection';

export const RBIT_MEAL = 'MEAL';
export const RBIT_GROUND_TRANSPORTATION = 'GROUND_TRANSPORTATION';
export const RBIT_PARKING = 'PARKING';
export const RBIT_PLANE_TICKETS = 'PLANE_TICKETS';
export const RBIT_HOTEL = 'HOTEL';
export const RBIT_OTHER = 'OTHER';
export const RBIT_MILEAGE = 'MILEAGE';

export const reimbursementItemTypesMap = {
  [RBIT_MEAL]: 'Meal',
  [RBIT_GROUND_TRANSPORTATION]: 'Ground transportation',
  [RBIT_PARKING]: 'Parking',
  [RBIT_PLANE_TICKETS]: 'Plane tickets',
  [RBIT_HOTEL]: 'Hotel',
  [RBIT_OTHER]: 'Other',
  [RBIT_MILEAGE]: 'Mileage'
};

export const reimbursementItemTypes = map(reimbursementItemTypesMap, function(name, id) {
  return { id, name };
});

export const RBS_CANCELED = 'CANCELED';
export const RBS_DRAFT = 'DRAFT';
export const RBS_PENDING = 'PENDING';
export const RBS_APPROVED = 'APPROVED';
export const RBS_DENIED = 'DENIED';

export const INVALID_PID = 'Valid PID not found, please add valid PID to patient profile prior to approval.';

export const reimbursementStatusesMap = {
  [RBS_CANCELED]: 'Canceled',
  [RBS_DRAFT]: 'Not Submitted - Draft',
  [RBS_PENDING]: 'Submitted - Pending',
  [RBS_APPROVED]: 'Approved',
  [RBS_DENIED]: 'Denied'
};

export const reimbursementStatuses = map(reimbursementStatusesMap, function(name, id) {
  return { id, name };
});
