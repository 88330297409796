export function getRowKeysForSignature(itemGroupSnapshotReviewStates, logAndLogCheckSnapshotReviewStates, reason) {
  const procedureLikeRows = Object.values(itemGroupSnapshotReviewStates).flatMap(i => i.itemGroupSnapshotState.rows);
  const tableGeneralRows = Object.values(itemGroupSnapshotReviewStates)
    .flatMap(i => i.itemGroupSnapshotState.general)
    .filter(e => e != null);
  const logRows = Object.values(logAndLogCheckSnapshotReviewStates).flatMap(i => i.rows);
  const logCheckSnapshotKeys = Object.values(logAndLogCheckSnapshotReviewStates)
    .flatMap(i => i.encounterLogCheckSnapshotKey)
    .filter(e => e != null);
  const itemGroupRowSnapshotKeys = [...procedureLikeRows, ...logRows].map(row => row.rowSnapshotKey);
  const tableGeneralRowSnapshotKeys = tableGeneralRows.map(row => row.rowSnapshotKey);
  return {
    itemGroupRowSnapshotKeys,
    tableGeneralRowSnapshotKeys,
    logCheckSnapshotKeys,
    reason
  };
}
