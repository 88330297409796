import { handleActions } from 'redux-actions';

import { options } from './actions';
import { UPDATE } from './types';

export default handleActions(
  {
    [UPDATE]: function(state, { payload }) {
      return { ...state, ...payload };
    }
  },
  {
    siteUniqueIdentifier: undefined,
    studyUniqueIdentifier: undefined,
    statuses: undefined
  },
  options
);
