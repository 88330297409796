import React from 'react';

import { Amount } from '../../../../../shared/amount-view/Amount/Amount';
import { stylesContent } from '../../EncounterInvoiceTemplateStyles';

export const Header = ({ item }) => {
  return (
    <div style={stylesContent.groupHeader} className="row-item" data-testid="event-group-amount-calculation">
      <div style={stylesContent.groupHeader.groupItemName}>
        {item.groupName}
        {item.groupHeaderSuffix ?? ''}
      </div>
      {!(item.groupHeaderSuffix && item?.inseparable) && (
        <>
          <div style={stylesContent.groupHeader.groupItem}>{item.groupLength}</div>
          <div style={stylesContent.groupHeader.tableHeaderItemAmount}>
            <Amount
              coinsAmount={item.groupAmount ? item.groupAmount : item.amountForItem * item.groupLength}
              showDollarSign={true}
            />
          </div>
        </>
      )}
    </div>
  );
};
