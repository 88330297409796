import { useEffect } from 'react';
import { isFunction, isObject } from 'lodash/lang';

export default function useOnWindowResize(callback) {
  useEffect(() => {
    if (!isObject(window)) {
      return false;
    }

    function handleResize() {
      isFunction(callback) && callback();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);
}
