import axios from 'axios';

const ROOT = 'api/v1/projections-revenue';

const ProjectionRevenueApi = {
  getProjectedRevenueDetails(type, ssu) {
    const typeString = transformProjectionType(type);
    return axios.get(`${ROOT}/${typeString}/${ssu}`);
  },
  exportRevenuePrediction(type, ssuId) {
    const typeString = transformProjectionType(type);
    return axios.get(`${ROOT}/${typeString}/ssu/${ssuId}/export`, {
      responseType: 'arraybuffer'
    });
  }
};

function transformProjectionType(type) {
  return type === 'consents' ? 'new-consents' : 'followup-visits';
}

export default ProjectionRevenueApi;
