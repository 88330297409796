import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { every, map, reduce } from 'lodash/collection';
import { isEmpty, isEqual } from 'lodash/lang';
import { mapValues } from 'lodash/object';

import { GroupAssignApi } from '../../../../../../api';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import { onRequestDefaultError } from '../../../../../../services/handlers';
import HistoryBlock, { PROTOCOL_GROUPS_SETUP } from '../../../../../root/Container/HistoryBlock';
import { generateUrlByKey, useCurrentRoute } from '../../../../../root/router';
import { sortEpochsAndEncountersByProtocolSequence } from '../EncounterSetup/services';

import { generateMapForEncountersItemGroupsConfiguration } from './services';

const ProtocolGroupsSetupStateContext = React.createContext(null);
const ProtocolGroupsSetupActionsContext = React.createContext(null);

export function ProtocolGroupsSetupProvider({ children }) {
  const currentRoute = useCurrentRoute();
  const { studyId, protocolIdentity, groupId } = currentRoute.params;
  const navigate = useNavigate();

  const [loadingMainData, setLoadingMainData] = useState(true);

  const [loadingGroupsConfiguration, setLoadingGroupsConfiguration] = useState(true);

  const [state, setState] = useState({});
  const [originGroupsConfigurationMap, setOriginGroupsConfigurationMap] = useState({});
  const [groupsConfigurationMap, setGroupsConfigurationMap] = useState({});

  const { studyName, protocolName, currentVersion, epochs, itemGroups, groups } = state;
  const selectedGroup = useMemo(() => groups?.find(({ id }) => id === groupId), [groups, groupId]);
  const selectedGroupName = selectedGroup?.name;
  const selectedGroupId = selectedGroup?.id;

  const selectedGroupType = useMemo(() => {
    if (!groups || !selectedGroup) return null;
    return selectedGroup.type;
  }, [groups, selectedGroup]);

  const saveAllowed = useMemo(
    function() {
      return every(groupsConfigurationMap, 'permissionType');
    },
    [groupsConfigurationMap]
  );

  const hasChanges = useMemo(
    function() {
      return !isEqual(groupsConfigurationMap, originGroupsConfigurationMap);
    },
    [groupsConfigurationMap, originGroupsConfigurationMap]
  );

  const changeAccessForEncountersItemGroupsConfiguration = useCallback(function(key, value) {
    setGroupsConfigurationMap(function(prevState) {
      const currentData = prevState[key];
      return {
        ...prevState,
        [key]: { ...currentData, permissionType: value }
      };
    });
  }, []);

  const changeAccessForAllItemGroupsConfiguration = useCallback(function(itemGroupKey, value) {
    setGroupsConfigurationMap(function(prevState) {
      return mapValues(prevState, function(configuration) {
        if (configuration.itemGroupKey !== itemGroupKey) {
          return { ...configuration };
        }
        return { ...configuration, permissionType: value };
      });
    });
  }, []);

  const changeAccessForAllEncountersItemGroupsConfiguration = useCallback(function(value) {
    setGroupsConfigurationMap(function(prevState) {
      return mapValues(prevState, function(configuration) {
        return { ...configuration, permissionType: value };
      });
    });
  }, []);

  useEffect(() => {
    if (!hasChanges) {
      return;
    }
    return HistoryBlock.block(PROTOCOL_GROUPS_SETUP, function(discard) {
      ModalBoxes.confirm({
        title: 'Not saved changes',
        content: `You have not saved configuration for "${selectedGroupName}" group. Are you sure want to discard changes?`
      })
        .then(() => {
          discard();
        })
        .catch(() => {});

      return false;
    });
  }, [hasChanges, selectedGroupName]);

  useEffect(
    function() {
      setLoadingMainData(true);
      const loadingStartTime = +new Date();
      GroupAssignApi.getProtocolEncounterGroupAssignConfiguration(studyId, protocolIdentity)
        .then(function({ data }) {
          setState({ ...data, epochs: sortEpochsAndEncountersByProtocolSequence(data.epochs) });
        })
        .finally(function() {
          const loadingEndTime = +new Date();
          const loadingDeltaTime = loadingEndTime - loadingStartTime;
          setTimeout(function() {
            setLoadingMainData(false);
          }, Math.max(700 - loadingDeltaTime, 0));
        });
    },
    [studyId, protocolIdentity]
  );

  useEffect(
    function() {
      if (isEmpty(groups)) return;
      const validGroupId = selectedGroup?.id ?? groups[0].id;
      if (groupId !== validGroupId) {
        navigate(
          generateUrlByKey(currentRoute.key, {
            studyId,
            protocolIdentity,
            groupId: validGroupId
          }),
          { replace: true }
        );
      }
    },
    [groups, groupId, navigate, studyId, protocolIdentity, selectedGroup?.id, currentRoute.key]
  );

  useEffect(
    function() {
      if (!selectedGroupId) return;
      setLoadingGroupsConfiguration(true);
      const loadingStartTime = +new Date();
      GroupAssignApi.getGroupAssignConfiguration(studyId, protocolIdentity, selectedGroupId)
        .then(function({ data }) {
          const configurationMap = generateMapForEncountersItemGroupsConfiguration(data);
          setGroupsConfigurationMap(configurationMap);
          setOriginGroupsConfigurationMap(configurationMap);
        })
        .finally(function() {
          const loadingEndTime = +new Date();
          const loadingDeltaTime = loadingEndTime - loadingStartTime;
          setTimeout(function() {
            setLoadingGroupsConfiguration(false);
          }, Math.max(700 - loadingDeltaTime, 0));
        });
    },
    [studyId, protocolIdentity, selectedGroupId]
  );

  return (
    <ProtocolGroupsSetupStateContext.Provider
      value={{
        studyName,
        protocolName,
        currentVersion,
        epochs,
        itemGroups,
        groups,
        groupsConfigurationMap,
        selectedGroupId,
        saveAllowed,
        loadingMainData,
        loadingGroupsConfiguration,
        selectedGroupType
      }}
    >
      <ProtocolGroupsSetupActionsContext.Provider
        value={{
          changeAccessForEncountersItemGroupsConfiguration,
          changeAccessForAllItemGroupsConfiguration,
          changeAccessForAllEncountersItemGroupsConfiguration,
          saveConfiguration,
          cancelConfiguration
        }}
      >
        {children}
      </ProtocolGroupsSetupActionsContext.Provider>
    </ProtocolGroupsSetupStateContext.Provider>
  );

  function cancelConfiguration() {
    navigate(
      generateUrlByKey(currentRoute.parent?.key, {
        studyId,
        protocolIdentity
      }),
      { replace: true }
    );
  }

  function saveConfiguration() {
    if (isEqual(groupsConfigurationMap, originGroupsConfigurationMap)) {
      return NotificationManager.warning('No changes to Save.');
    }

    const missingItemGroupsKeys = reduce(
      groupsConfigurationMap,
      function(accumulator, { permissionType, itemGroupKey }) {
        if (permissionType === null) {
          accumulator.add(itemGroupKey);
        }

        return accumulator;
      },
      new Set()
    );

    if (!isEmpty(missingItemGroupsKeys)) {
      const itemGroupsNames = itemGroups.reduce(function(accumulator, { itemGroupKey, elementName }) {
        if (missingItemGroupsKeys.has(itemGroupKey)) {
          accumulator.push(elementName);
        }

        return accumulator;
      }, []);

      ModalBoxes.confirm({
        title: 'Notification',
        content: (
          <>
            The following item groups are missing permissions: <b>{itemGroupsNames.join(', ')}</b>
          </>
        ),
        cancelButton: false,
        confirmButton: 'Ok'
      }).then(() => {});

      return;
    }

    GroupAssignApi.saveGroupAssignConfiguration(
      'studyId',
      protocolIdentity,
      selectedGroupId,
      map(groupsConfigurationMap)
    )
      .then(function() {
        NotificationManager.success('Group configuration saved successfully.');
        setOriginGroupsConfigurationMap(groupsConfigurationMap);
      })
      .catch(onRequestDefaultError);
  }
}

export function withProtocolGroupsSetupProvider(Component) {
  return function ProtocolGroupsSetupProviderWrapper(props) {
    return (
      <ProtocolGroupsSetupProvider>
        <Component {...props} />
      </ProtocolGroupsSetupProvider>
    );
  };
}

export function useProtocolGroupsSetupState() {
  const context = useContext(ProtocolGroupsSetupStateContext);
  if (context === undefined) {
    throw new Error('useProtocolGroupsSetupState must be used within a ProtocolGroupsSetupProvider');
  }
  return context;
}

export function useProtocolGroupsSetupActions() {
  const context = useContext(ProtocolGroupsSetupActionsContext);
  if (context === undefined) {
    throw new Error('useProtocolGroupsSetupActions must be used within a ProtocolGroupsSetupProvider');
  }
  return context;
}
