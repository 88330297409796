import React, { useCallback, useEffect, useState } from 'react';

import { afterburnerApi } from '../../../api/patient/AftrburnerApi';
import Section from '../../../common/data-display/Section/Section';
import ModalBoxes from '../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../common/general/Button';
import NotificationManager from '../../../common/notifications/NotificationManager';
import { PageInfoHeader } from '../../PageInfoHeader/PageInfoHeader';
import { sortFieldsByName } from '../setup/Protocol/EncounterEpochTableTemplate/EncountersHeaders/MappingSetupModal/MappingSetupServices';

import PlatformConfigurationModal from './PlatformConfigurationModal/PlatformConfigurationModal';

import './PlatformConfiguration.scss';

const PlatformConfiguration = () => {
  const [receivingPlatforms, setReceivingPlatforms] = useState([]);

  const updatePlatformsList = useCallback(() => {
    afterburnerApi
      .getAllPlatformsConfiguration()
      .then(({ data }) => {
        setReceivingPlatforms(sortFieldsByName(data, 'platformName'));
      })
      .catch(e => {
        NotificationManager.error(e);
      });
  }, []);

  const deletePlatform = useCallback(
    id => {
      afterburnerApi
        .deletePlatformConfiguration(id)
        .then(({ data }) => {
          if (data) {
            NotificationManager.success('Platform deleted successfully');
          } else {
            NotificationManager.error(
              <>
                Can't delete Platform Configuration.
                <br /> Platform configuration in use.
              </>
            );
          }
          updatePlatformsList();
        })
        .catch(e => {
          NotificationManager.error(e.response.data.message);
        });
    },
    [updatePlatformsList]
  );

  useEffect(() => {
    updatePlatformsList();
  }, [updatePlatformsList]);

  return (
    <>
      <PageInfoHeader />
      <Section className="endpoint-configuration-page">
        <div className="new-platform-wrapper">
          <Button size="h40" priority="medium" onClick={() => editEndpointConfiguration(null, updatePlatformsList)}>
            Add New Platform
          </Button>
        </div>
        <div className="current-platforms">
          {receivingPlatforms.map(platform => {
            return (
              <div className="platform-wrapper" key={platform.id}>
                <div
                  className="platform-name"
                  onClick={() => {
                    editEndpointConfiguration(platform.id, updatePlatformsList);
                  }}
                >
                  {platform.platformName}
                </div>
                <div
                  className="delete-platform-icon"
                  data-testid="delete-button"
                  onClick={() => deletePlatform(platform.id)}
                />
              </div>
            );
          })}
        </div>
      </Section>
    </>
  );
};

const editEndpointConfiguration = (platformId, updatePlatformsList) => {
  ModalBoxes.open({
    component: <PlatformConfigurationModal platformId={platformId} updatePlatformsList={updatePlatformsList} />,
    className: 'platform-configuration-modal',
    size: 'w1250'
  });
};

export default PlatformConfiguration;
