import React from 'react';
import { useBouncer } from 'bouncer';

import Loader from '../../../common/elements/Loader/Loader';
import backgroundImage from '../../../images/login-background-custom.jpg';
const unknownUserStyle = {
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: 99,
  boxShadow: '0 0 5px 0 rgba(0,0,0,0.3)',
  padding: '16px',
  backgroundColor: '#31465F',
  fontSize: '14px',
  color: '#fff',
  boxSizing: 'border-box'
};

const backgroundStyle = {
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'contain',
  display: 'flex',
  height: '100vh',
  justifyContent: 'center',
  textAlign: 'center',
  verticalAlign: 'center',
  color: 'white'
};

const buttonStyle = {
  backgroundColor: 'white',
  color: '#31465f',
  borderStyle: 'solid',
  borderWitdth: '1px',
  borderRadius: '4px',
  borderColor: 'white',
  marginLeft: '1rem'
};

const loaderStyle = {
  position: 'relative',
  top: '50%'
};

const NoUser = ({ isUnknownUser, needsSync }) => {
  const { isAccessTokenReceived, bouncerLogout } = useBouncer();
  return (
    <div style={backgroundStyle}>
      {(isAccessTokenReceived && isUnknownUser && (
        <div style={unknownUserStyle}>
          <span>
            {(needsSync && (
              <React.Fragment>
                Account generation is still in progress. Your password has been updated. Please try logging in again in
                10 minutes
              </React.Fragment>
            )) || (
              <React.Fragment>
                You do not have access. Please contact{' '}
                <a
                  href="https://elligodirect.atlassian.net/servicedesk/customer/portal/8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Support
                </a>{' '}
                to request access.
              </React.Fragment>
            )}
            <button style={buttonStyle} onClick={() => bouncerLogout()}>
              Click here to return to Login Page
            </button>
          </span>
        </div>
      )) || (
        <div style={loaderStyle}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default NoUser;
