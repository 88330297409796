import React from 'react';
import { isFunction } from 'lodash/lang';
import PropTypes from 'prop-types';

import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../common/general/Button';
import ButtonGroup from '../../../../common/general/ButtonGroup';

export default class ReasonConfirmationDialog extends React.Component {
  static propTypes = {
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    dialogTitle: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      comment: ''
    };
  }

  onSubmit = () => {
    const { comment } = this.state;
    isFunction(this.props.onSubmit) &&
      this.props.onSubmit(comment).then(() => isFunction(this.props.modalBox.close) && this.props.modalBox.close());
  };

  onCommentsChange = evt => {
    this.setState({ comment: evt.target.value });
  };

  isAllRequiredFieldsFilled = () => {
    return this.state.comment.trim();
  };

  render() {
    return (
      <>
        <ModalBoxes.Body>
          <div className="mt-3">
            <textarea
              value={this.state.comment}
              className="form-control reqfeild"
              onChange={this.onCommentsChange}
              placeholder="Enter reason"
            />
          </div>
        </ModalBoxes.Body>
        <ModalBoxes.Footer>
          <ButtonGroup>
            <Button priority="medium" onClick={this.props.modalBox.close}>
              Cancel
            </Button>
            <Button onClick={this.onSubmit} disabled={!this.isAllRequiredFieldsFilled()}>
              Save
            </Button>
          </ButtonGroup>
        </ModalBoxes.Footer>
      </>
    );
  }
}

ReasonConfirmationDialog.className = 'reason-confirm-dialog';
ReasonConfirmationDialog.size = 'w650';
