import { difference } from 'lodash/array';

const palette = [
  { back: '#DCDCDC', main: '#727272' },
  { back: '#FFEBB8', main: '#D87112' },
  { back: '#C8E6C9', main: '#2E7D32' },
  { back: '#D1C4E9', main: '#782AB5' },
  { back: '#FFD2E2', main: '#EC1D68' },
  { back: '#D7CCC8', main: '#8A6153' },
  { back: '#F2F7BA', main: '#899C12' },
  { back: '#B2EBF2', main: '#2978D3' },
  { back: '#D1C4E9', main: '#782AB5' },
  { back: '#FFDCDC', main: '#ED3737' },
  { back: '#CBD8DE', main: '#546E7A' },
  { back: '#FFDEC2', main: '#D87112' },
  { back: '#D7F7BC', main: '#1AAB4C' },
  { back: '#BBDEFB', main: '#2978D3' },
  { back: '#F7CAF8', main: '#AF32BA' },
  { back: '#FFCCBC', main: '#AF3A3A' }
];

let colorsRegistry = {};
let extraColor = 0;

export function ColorsRegistry() {
  const initEmptyRegistry = () => {
    colorsRegistry = {};
    extraColor = 0;
  };

  const getColorById = id => {
    if (colorsRegistry.hasOwnProperty(id)) {
      return colorsRegistry[id];
    } else {
      const usedPalette = Object.values(colorsRegistry);
      const freeColors = difference(palette, usedPalette);
      let freeColor;
      if (freeColors.length === 0) {
        freeColor = palette[extraColor % palette.length];
        extraColor += 1;
      } else {
        extraColor = 0;
        freeColor = freeColors[0];
      }
      usedPalette.push(freeColor);
      colorsRegistry[id] = freeColor;
      return colorsRegistry[id];
    }
  };

  const removeColorById = id => {
    if (colorsRegistry.hasOwnProperty(id)) {
      delete colorsRegistry[id];
    }
  };

  return {
    initEmptyRegistry: initEmptyRegistry,
    getColorById: getColorById,
    removeColorById: removeColorById
  };
}
