import React from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { sortBy } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

import Icon from '../../../../../../common/general/Icon';
import { generateUrlByKey, useCurrentRoute } from '../../../../../root/router';

import './ReviewsSidebarBlock.scss';

const SM = 'SM';
const INVESTIGATOR = 'INVESTIGATOR';

export default function ReviewsSidebarBlock({ reviews, userGroupAssignId }) {
  const { key, params } = useCurrentRoute();

  if (isEmpty(reviews)) return null;

  const baseKey = key.replace(/(.*Encounter Details).*/g, '$1');

  const sortedList = sortBy(reviews, ({ reviewType }) => ({ [SM]: 1, [INVESTIGATOR]: 2 }[reviewType] || 3));

  return (
    <div className="reviews-sidebar-block">
      <h5>Reviews</h5>
      {sortedList.map(function({ reviewType, completed, reviewName, groupAssignId }, index) {
        const { reviewKey } = getReviewData(reviewType, reviewName);
        const reviewUrl = generateUrlByKey(`${baseKey}.${reviewKey}`, {
          ...params,
          groupAssignId,
          signatureId: 'current'
        });
        return (
          <div
            key={index}
            className={cx('encounter-item-group', {
              'reviews-sidebar-block-completed': completed,
              'reviews-sidebar-block-for-current-user': userGroupAssignId === groupAssignId
            })}
          >
            <NavLink to={reviewUrl}>
              <div className="details-container">
                <Icon>{userGroupAssignId === groupAssignId ? 'person' : 'rate_review'}</Icon>
                <div>
                  <h6 className="item-group-name" title={reviewName}>
                    {reviewName}
                  </h6>
                  <span className="reviews-sidebar-block-progress" />
                  <span>{completed ? 1 : 0}/1</span>
                </div>
              </div>
            </NavLink>
          </div>
        );
      })}
    </div>
  );
}

function getReviewData(reviewType, reviewName) {
  return {
    [SM]: { reviewName: reviewName, reviewKey: 'Study Manager Encounter Review' },
    [INVESTIGATOR]: { reviewName: reviewName, reviewKey: 'Investigator Encounter Review' }
  }[reviewType];
}
