import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import {
  DataGridPremium,
  GridRow,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid-premium';
import moment from 'moment/moment';

import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../../constants/dateFormat';
import { scGray2 } from '../../../../../../constants/systemColors';
import { toBill } from '../../../../../../services/financial';
import { TABLE_CHECKBOX } from '../../../NewInvoice/InvoiceTable/TableConstants';
import { Amount } from '../../../shared/amount-view/Amount/Amount';
import { DefaultFinanceCell } from '../../../shared/FinanseTableMUI/DefaultFinanceCell';
import { eventNumberComparator, resolveEventSuffix } from '../../SitePaymentServices';
import { SiteApprovalStatus } from '../SitePaymentsConstants';
import { PaymentStatusIcon, SiteApprovalStatusIcon, StatusCell } from '../StatusCell/StatusCell';

import { SiteCreditApplicationContext } from './SiteCreditApplicationContext';

export const SiteCreditApplicationTable = () => {
  const {
    selectedEventForApply,
    setSelectedEventForApply,
    calculateSelectedAmount,
    creditToApply,
    eligibleEventsForApplyCredit
  } = useContext(SiteCreditApplicationContext);

  const [pinnedColumns, setPinnedColumns] = useState({ left: [TABLE_CHECKBOX] });

  const handlePinnedColumnsChange = useCallback(updatedPinnedColumns => {
    setPinnedColumns({
      ...updatedPinnedColumns,
      left: [TABLE_CHECKBOX, ...updatedPinnedColumns.left.filter(column => column !== TABLE_CHECKBOX)]
    });
  }, []);

  const getTogglableColumns = useCallback(
    columns => columns.filter(column => ![TABLE_CHECKBOX].includes(column.field)).map(column => column.field),
    []
  );

  const columns = useMemo(
    () => [
      {
        field: 'studyName',
        headerName: 'Study',
        flex: 1,
        getApplyQuickFilterFn: undefined,
        minWidth: 110,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'pcn',
        headerName: 'PCN',
        flex: 1,
        getApplyQuickFilterFn: undefined,
        minWidth: 100,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'siteName',
        headerName: 'Site',
        flex: 1,
        getApplyQuickFilterFn: undefined,
        minWidth: 100,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'eventNumber',
        headerName: 'Event ID',
        flex: 1,
        minWidth: 125,
        sortComparator: eventNumberComparator,
        valueGetter: ({ row }) => {
          if (row.adjustmentSequence === 0) {
            return resolveEventSuffix(row.eventNumber, row.sitePaymentType);
          }
          return resolveEventSuffix(`${row.eventNumber}.${row.adjustmentSequence}`, row.sitePaymentType);
        }
      },
      {
        field: 'eventName',
        headerName: 'Event Name',
        flex: 1,
        minWidth: 170,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'invoicePaymentStatus',
        headerName: 'Invoice Payment Status',
        flex: 1,
        getApplyQuickFilterFn: undefined,
        minWidth: 170,
        renderCell: ({ value }) => (
          <StatusCell value={value} statusWidth="80px">
            <PaymentStatusIcon status={value} />
          </StatusCell>
        )
      },
      {
        field: 'siteApprovalStatus',
        headerName: 'Site Approval Status',
        flex: 1,
        getApplyQuickFilterFn: undefined,
        minWidth: 170,
        valueGetter: ({ row }) => SiteApprovalStatus[row.siteApprovalStatus],
        renderCell: ({ value }) => (
          <StatusCell value={value} statusWidth="140px">
            <SiteApprovalStatusIcon status={value} />
          </StatusCell>
        )
      },
      {
        field: 'siteAmount',
        headerName: 'Site Amount',
        flex: 1,
        minWidth: 150,
        type: 'number',
        valueGetter: ({ row }) => toBill(row.siteAmount),
        renderCell: ({ row }) => <Amount coinsAmount={row.siteAmount} showDollarSign />
      },
      {
        field: 'sitePaidAmount',
        headerName: 'Site Paid Amount',
        flex: 1,
        getApplyQuickFilterFn: undefined,
        minWidth: 180,
        type: 'number',
        valueGetter: ({ row }) => toBill(row.sitePaidAmount),
        renderCell: ({ row }) => <Amount coinsAmount={row.sitePaidAmount} showDollarSign />
      },
      {
        field: 'sitePaidVariance',
        headerName: 'Site Paid Variance',
        flex: 1,
        minWidth: 170,
        getApplyQuickFilterFn: undefined,
        type: 'number',
        valueGetter: ({ row }) => {
          return `${toBill(row.sitePaidAmount - row.siteAmount)}`;
        },
        renderCell: ({ row }) => {
          return <Amount coinsAmount={row.sitePaidAmount - row.siteAmount} showDollarSign />;
        }
      },
      {
        field: 'billNumber',
        headerName: 'Bill #',
        flex: 1,
        minWidth: 105,
        valueGetter: ({ value }) => value,
        renderCell: ({ row: { billNumber } }) => <div>{billNumber || '—'}</div>
      },
      {
        field: 'revenueDate',
        headerName: 'Revenue Date',
        flex: 1,
        getApplyQuickFilterFn: undefined,
        minWidth: 160,
        type: 'date',
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row: { revenueDate } }) => (
          <div>{revenueDate ? moment(revenueDate).format(DD_SLASH_MMM_SLASH_YYYY) : '—'}</div>
        )
      },
      {
        field: 'invoiceDate',
        headerName: 'Invoice Date',
        flex: 1,
        getApplyQuickFilterFn: undefined,
        minWidth: 160,
        type: 'date',
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row: { invoiceDate } }) => (
          <div>{invoiceDate ? moment(invoiceDate).format(DD_SLASH_MMM_SLASH_YYYY) : '—'}</div>
        )
      },
      {
        field: 'invoiceNumber',
        headerName: 'Invoice Number',
        flex: 1,
        getApplyQuickFilterFn: undefined,
        minWidth: 175,
        sortComparator: eventNumberComparator,
        valueGetter: ({ row: { invoiceNumber, sitePaymentType } }) =>
          invoiceNumber ? resolveEventSuffix(invoiceNumber, sitePaymentType) : '',
        renderCell: DefaultFinanceCell
      }
    ],
    []
  );

  const handleRowSelectionModelChange = useCallback(
    newRowSelectionModel => {
      const selectedAmount = calculateSelectedAmount(newRowSelectionModel, eligibleEventsForApplyCredit);
      const isAddingRow = selectedEventForApply.length + 1 === newRowSelectionModel.length;
      const isRemovingRow = newRowSelectionModel.length < selectedEventForApply.length;
      const creditBalance = creditToApply - selectedAmount;

      if ((isAddingRow && creditBalance < 0) || creditBalance >= 0 || isRemovingRow) {
        setSelectedEventForApply(newRowSelectionModel);
      }
    },
    [
      calculateSelectedAmount,
      creditToApply,
      eligibleEventsForApplyCredit,
      selectedEventForApply.length,
      setSelectedEventForApply
    ]
  );

  const tableData = useMemo(() => {
    const selectedAmount = calculateSelectedAmount(selectedEventForApply, eligibleEventsForApplyCredit);
    const creditBalance = creditToApply - selectedAmount;
    return eligibleEventsForApplyCredit.map(item => {
      return {
        ...item,
        disabled: creditBalance <= 0 && !selectedEventForApply.includes(item.itemSiteId)
      };
    });
  }, [calculateSelectedAmount, creditToApply, eligibleEventsForApplyCredit, selectedEventForApply]);

  const isRowSelectable = useCallback(({ row }) => {
    return !row.disabled;
  }, []);

  return (
    <Box display="flex" height="600px" flexDirection="column" padding="0 0 0 0">
      <DataGridPremium
        getRowId={row => row.itemSiteId}
        rows={tableData}
        columns={columns}
        rowHeight={38}
        onRowSelectionModelChange={handleRowSelectionModelChange}
        rowSelectionModel={selectedEventForApply}
        isRowSelectable={isRowSelectable}
        initialState={{
          columns: {
            columnVisibilityModel: { revenueDate: false, invoiceDate: false, invoiceNumber: false }
          },
          pinnedColumns
        }}
        pinnedColumns={pinnedColumns}
        onPinnedColumnsChange={handlePinnedColumnsChange}
        slots={{
          toolbar: () => (
            <GridToolbarContainer>
              <GridToolbarColumnsButton />
              <GridToolbarFilterButton />
              <GridToolbarDensitySelector />
              <GridToolbarQuickFilter sx={{ margin: '0 0 0 auto' }} />
            </GridToolbarContainer>
          ),
          row: CustomRow
        }}
        slotProps={{
          columnsPanel: {
            getTogglableColumns
          }
        }}
        sx={{
          padding: '20px 40px 0 40px',
          backgroundColor: scGray2,
          border: 'none',
          '.MuiDataGrid-pinnedColumns, .MuiDataGrid-pinnedColumnHeaders': {
            backgroundColor: scGray2
          }
        }}
        checkboxSelection
        disableRowSelectionOnClick
        disableRowGrouping
        disableAggregation
      />
    </Box>
  );
};

const CustomRow = props => {
  const resolveTestIds = {
    center: 'table-row',
    left: 'table-row-left',
    right: 'table-row-right'
  };
  return <GridRow {...props} data-testid={resolveTestIds[props.position]} />;
};
