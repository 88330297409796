import React, { useContext } from 'react';
import { Autocomplete, TextField } from '@mui/material';

import { screeningStatuses } from './WorklistTable/WorklistTableConstants';
import { WorklistContext } from './WorklistContext';

export default function PatientStatusChange({ row, isAllowedToEdit }) {
  const { updateStatus } = useContext(WorklistContext);
  const findPatientStatusByCode = code => {
    return screeningStatuses.find(e => e.code === code);
  };

  return !isAllowedToEdit ? (
    findPatientStatusByCode(row.status)?.name
  ) : (
    <Autocomplete
      fullWidth
      options={screeningStatuses}
      onChange={(event, newValue) => {
        if (newValue) {
          updateStatus(row.sitePatientId, row.status, newValue?.code);
        }
      }}
      value={findPatientStatusByCode(row.status)}
      getOptionKey={option => option.id}
      getOptionLabel={option => option.name}
      clearIcon={false}
      renderInput={params => (
        <TextField
          {...params}
          size="small"
          variant="standard"
          sx={{
            '.MuiInputBase-root': {
              '::before': { border: 'none !important' }
            },
            '.MuiInputBase-input': {
              fontSize: '0.875rem'
            }
          }}
        />
      )}
    />
  );
}
