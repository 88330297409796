import axios from 'axios';

const ROOT = 'api/v1/projections-revenue/summary';

const ProjectionSummaryRevenueApi = {
  getNewConsentsProjection(ssus) {
    return axios.post(`${ROOT}/new-consents`, { ssuIds: ssus });
  },
  getFollowUpVisitsProjection(ssus) {
    return axios.post(`${ROOT}/followup-visits`, { ssuIds: ssus });
  },
  exportSummaryRevenuePrediction(ssus) {
    return axios.get(`${ROOT}/export?ssuId=${ssus}`, {
      responseType: 'arraybuffer'
    });
  },
  exportSummaryRevenuePredictionTotal() {
    return axios.get(`${ROOT}/exportTotal`, {
      responseType: 'arraybuffer'
    });
  }
};

export default ProjectionSummaryRevenueApi;
