import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { groupBy, isEmpty } from 'lodash';
import Collapse from 'rc-collapse';

import IconButton from '../../../../../../../common/buttons/IconButton';
import Input from '../../../../../../../common/data-entry/Input';
import Checkbox from '../../../../../../../common/data-entry/InputSelectors/Checkbox';
import Button from '../../../../../../../common/general/Button';
import Icon from '../../../../../../../common/general/Icon';
import useOutsideClickDetector from '../../../../../../../common/hooks/useOutsideClickDetector';

import '../WorklistTableHeaderFilter.scss';

export function WorklistEncounterFilter({
  filterRef,
  Context,
  encounterProvider,
  statusesProvider,
  defaultFilters,
  isFilterVisible,
  setFilterVisible
}) {
  const {
    EncounterWidgetContext: { currentlySelected },
    setEncounterNames,
    encounterNames,
    tableData,
    selectedStudySites,
    totalEncounters,
    setTotalEncounters
  } = Context;
  useOutsideClickDetector(filterRef, () => {
    setFilterVisible(false);
  });

  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [encountersPerStudy, setEncountersPerStudy] = useState();
  const [selectedEncounters, setSelectedEncounters] = useState(encounterNames || totalEncounters);
  const [placedOnTop, setPlacedOnTop] = useState(false);
  const [isSearchSuccessful, setSearchSuccessful] = useState(true);

  useEffect(() => {
    if (searchFieldValue) {
      const editedEncounters = totalEncounters.filter(enc =>
        enc.encounterName.toLowerCase().includes(searchFieldValue.toLowerCase())
      );
      setEncountersPerStudy(groupBy(editedEncounters, encounter => encounter.studyName));
      setSearchSuccessful(!isEmpty(editedEncounters));
    } else {
      setEncountersPerStudy(groupBy(totalEncounters, encounter => encounter.studyName));
      setSearchSuccessful(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFieldValue]);

  useEffect(() => {
    if (!isEmpty(encounterNames)) {
      setSelectedEncounters(encounterNames);
    } else {
      setSelectedEncounters(totalEncounters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encounterNames, totalEncounters, isFilterVisible]);

  useEffect(() => {
    if (currentlySelected) {
      const selectedStatusOptions = currentlySelected
        ? statusesProvider.filter(invStatus => invStatus.id === currentlySelected).flatMap(({ value }) => value)
        : defaultFilters.selectedStatuses.flatMap(({ value }) => value);
      encounterProvider(
        selectedStudySites?.map(studySite => studySite.id),
        selectedStatusOptions
      ).then(({ data }) => {
        setEncountersPerStudy(groupBy(data, encounter => encounter.studyName));
        setTotalEncounters(data);
        setEncounterNames(data);
        setSearchFieldValue('');
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlySelected]);

  const onOptionChanged = option => {
    if (checkIfOptionIsSelected(option)) {
      setSelectedEncounters(
        selectedEncounters.filter(
          enc => !(enc.encounterName === option.encounterName && enc.studyName === option.studyName)
        )
      );
    } else {
      setSelectedEncounters([...selectedEncounters, option]);
    }
  };
  const checkIfOptionIsSelected = option => {
    return (
      selectedEncounters.filter(enc => enc.encounterName === option.encounterName && enc.studyName === option.studyName)
        .length > 0
    );
  };

  const countSelectedOptions = options => {
    return options.filter(value => checkIfOptionIsSelected(value)).length;
  };
  const isSelectedAll = () => {
    return selectedEncounters.length === totalEncounters.length;
  };
  useEffect(
    function() {
      setPlacedOnTop(tableData.length < 8);
    },
    [tableData.length]
  );

  return (
    <>
      {isFilterVisible && (
        <div
          className={cx('filter-dropdown', placedOnTop ? 'placed-on-top' : 'placed-on-bottom')}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <Input
            label={'Search'}
            onChange={e => e && setSearchFieldValue(e.target.value)}
            value={searchFieldValue}
            iconsAfter={
              <>
                <span className="count main">{selectedEncounters.length}</span>
                {searchFieldValue && (
                  <IconButton
                    onClick={() => {
                      setSearchFieldValue('');
                    }}
                    className={'clear-button'}
                    style={{ cursor: 'pointer' }}
                  >
                    highlight_off
                  </IconButton>
                )}
                <Icon>search</Icon>
              </>
            }
            className="eui-search-input"
            validate={false}
          />

          {isSearchSuccessful ? (
            <>
              <Checkbox
                label="Select all"
                indeterminate={!isEmpty(selectedEncounters) && !isSelectedAll()}
                className={'select-all-option'}
                onChange={() => {
                  if (!isSelectedAll()) {
                    setSelectedEncounters(totalEncounters.filter(enc => enc.encounterName.includes(searchFieldValue)));
                  } else {
                    setSelectedEncounters([]);
                  }
                }}
                checked={isSelectedAll()}
              />
              <div className={'options-container'}>
                {encountersPerStudy &&
                  Object.entries(encountersPerStudy).map((value, idx) => (
                    <CustomOptionTemplate
                      option={value}
                      key={idx}
                      onOptionChanged={onOptionChanged}
                      checkIfOptionIsSelected={checkIfOptionIsSelected}
                      countSelectedOptions={countSelectedOptions}
                    />
                  ))}
              </div>
            </>
          ) : (
            <div className={'no-results-message'}>No Results found</div>
          )}
          {isSearchSuccessful && (
            <div className={'filter-footer'}>
              <Button
                priority="medium"
                onClick={() => {
                  setSelectedEncounters([]);
                }}
              >
                Clear All
              </Button>
              <Button
                priority="high"
                onClick={() => {
                  setEncounterNames(selectedEncounters);
                  setFilterVisible(false);
                }}
              >
                Apply
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
}

const CustomOptionTemplate = ({ option, onOptionChanged, checkIfOptionIsSelected, countSelectedOptions }) => {
  function panelHeaderWithCount(title, count) {
    return (
      <React.Fragment>
        <span className={'panel-title'}>{title}</span>
        {count > 0 && <span className="count">{count}</span>}
      </React.Fragment>
    );
  }

  return (
    <Collapse
      expandIcon={({ isActive }) => (
        <span className="rc-collapse-header-expand-icon material-icons">
          {`keyboard_arrow_${isActive ? 'up' : 'down'}`}
        </span>
      )}
      defaultActiveKey={[1]}
      openAnimation={{}}
      className="filter-collapse"
    >
      <Collapse.Panel
        header={panelHeaderWithCount(option[0], countSelectedOptions(option[1]))}
        key={option[0]}
        forceRender={true}
        openAnimation={{}}
      >
        {option[1].map(option => {
          return (
            <Checkbox
              key={option.encounterName}
              label={option.encounterName}
              checked={checkIfOptionIsSelected(option)}
              onChange={() => onOptionChanged(option)}
            />
          );
        })}
      </Collapse.Panel>
    </Collapse>
  );
};
