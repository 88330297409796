import React from 'react';
import * as PropTypes from 'prop-types';

import Radio from '../../../../../../../common/data-entry/InputSelectors/Radio';
import { DIRECT_COST, PASS_THRU } from '../ExpenseType';

const PASS_THRU_LABEL = 'Pass Thru';
const DIRECT_LABEL = 'Direct Cost';

export default function ExpenseRadioInputs(props) {
  const { disabled, name, onChangeFormControls, shortForm, value, ...rest } = props;

  return (
    <Radio.Group checkedValue={value} disabled={disabled} onChange={onChangeFormControls} name={name} {...rest}>
      <Radio
        value={PASS_THRU}
        title={shortForm ? PASS_THRU_LABEL : undefined}
        label={shortForm ? 'PT' : PASS_THRU_LABEL}
      />
      <Radio value={DIRECT_COST} title={shortForm ? DIRECT_LABEL : undefined} label={shortForm ? 'DC' : DIRECT_LABEL} />
    </Radio.Group>
  );
}

ExpenseRadioInputs.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChangeFormControls: PropTypes.func,
  shortForm: PropTypes.bool,
  value: PropTypes.string
};

ExpenseRadioInputs.defaultProps = { disabled: false, shortForm: false };
