import './ShowMore.scss';

export default function ShowMore({ children }) {
  return (
    <span className="pih-show-more">
      Show more
      {children && <div className="hidden-items">{children}</div>}
    </span>
  );
}
