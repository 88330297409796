import React from 'react';
import ReactTable from 'react-table';
import { isEmpty } from 'lodash/lang';

import { pendoTrackDefaultSortingChange } from '../../../../pendo/PendoUtils';

import './../LoadPatients/LoadPatientsResult.scss';

const PatientBulkUpdateResult = ({ response }) => {
  const columns = [
    {
      id: 'patientId',
      Header: 'Patient ID',
      accessor: 'patientId',
      width: 120
    },
    {
      id: 'failValue',
      Header: 'Failure Reason(s)',
      accessor: 'failValue',
      width: 400
    }
  ];

  const hasFailedRows = () => {
    return !isEmpty(response.failedValues);
  };

  const calculatePercentage = count => {
    return Math.round((100 / response.total) * count);
  };

  return (
    <section className="load-patients-result">
      <div className="container-fluid border mt-2 py-3">
        <div className="row pt-2">
          <div className="col-md-12">
            <h5 className="c-p">Patient Bulk Update Results</h5>
          </div>
          <div className="col-md-12">
            <div className="lpr-blocks">
              <div className="lpr-block">
                <div className="lpr-chart-wrapper">
                  <div className="lpr-chart">
                    <div className="lpr-chart-block" style={{ height: calculatePercentage(response.total) }}>
                      <span>{response.total}</span>
                    </div>
                  </div>
                </div>
                <div className="lpr-block-title">Total</div>
              </div>
              <div className="lpr-block">
                <div className="lpr-chart-wrapper lpr-chart-failed">
                  <div className="lpr-chart">
                    <div className="lpr-chart-block" style={{ height: calculatePercentage(response.successfulValues) }}>
                      <span>{response.successfulValues}</span>
                    </div>
                  </div>
                </div>
                <div className="lpr-block-title">updated</div>
              </div>
              <div className="lpr-block">
                <div className="lpr-chart-wrapper lpr-chart-failed">
                  <div className="lpr-chart">
                    <div
                      className="lpr-chart-block"
                      style={{ height: calculatePercentage(response.failedValues.length) }}
                    >
                      <span>{response.failedValues.length}</span>
                    </div>
                  </div>
                </div>
                <div className="lpr-block-title">Failed</div>
              </div>
              <div className="lpr-block">
                <div className="lpr-chart-wrapper lpr-chart-failed">
                  <div className="lpr-chart">
                    <div className="lpr-chart-block" style={{ height: calculatePercentage(response.missingValues) }}>
                      <span>{response.missingValues}</span>
                    </div>
                  </div>
                </div>
                <div className="lpr-block-title">Missing</div>
              </div>
            </div>
            <div>
              {hasFailedRows() && (
                <ReactTable
                  data={response.failedValues}
                  columns={columns}
                  onSortedChange={pendoTrackDefaultSortingChange}
                  minRows={1}
                  multiSort
                  showPagination
                  nextText=">>"
                  previousText="<<"
                  defaultPageSize={10}
                  noDataText="No Record Found"
                  defaultSorted={[{ id: 'rowNumber' }]}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PatientBulkUpdateResult;
