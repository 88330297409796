import React from 'react';
import { onRequestError } from 'services/handlers';

import { StudyApi } from '../../../../../../../../api';
import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import NotificationManager from '../../../../../../../../common/notifications/NotificationManager';

export default function showAddPatientStudyModal(patientData, studyName, siteName, studySites, duplicationResponse) {
  const patientName = `${duplicationResponse.patientLastName}, ${duplicationResponse.patientFirstName}`;
  const isDuplicatedFieldNameAndNotDOB =
    duplicationResponse.duplicatedFieldName && duplicationResponse.duplicatedFieldName !== 'date of birth';

  const patientDuplicationInfo = `${patientName} ${
    isDuplicatedFieldNameAndNotDOB
      ? `(${duplicationResponse.duplicatedFieldName} "${duplicationResponse.duplicatedFieldValue}")`
      : ''
  }`;

  return new Promise(function(resolve, reject) {
    ModalBoxes.confirm({
      content: (
        <div>
          <p>{patientDuplicationInfo} is an existing patient for the following study sites:</p>
          <ul>
            {studySites.map(item => (
              <li key={item.studyName + item.siteName}>
                {item.studyName}, {item.siteName}
              </li>
            ))}
          </ul>
          <p>
            Would you like to add the {studyName} study for the {siteName} site to their IntElligo patient record?
          </p>
        </div>
      ),
      title: 'Add Patient Study',
      confirmButton: 'Yes',
      cancelButton: 'No',
      className: 'add-patient-study'
    })
      .then(function() {
        StudyApi.addPatientStudy(patientData)
          .then(() => {
            NotificationManager.success(
              `${patientName} has been added to the ${studyName} study for the ${siteName} site`
            );
            resolve();
          })
          .catch(function(e) {
            onRequestError(e);
            reject(e);
          });
      })
      .catch(function(e) {
        NotificationManager.error(`${patientName} was not added to the ${studyName} study for the ${siteName} site`);
        reject(e);
      });
  });
}
