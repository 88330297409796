import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { isEmpty, isFunction, isString, isUndefined } from 'lodash/lang';
import { omit } from 'lodash/object';
import * as PropTypes from 'prop-types';

import IconButton from '../../buttons/IconButton/IconButton';

import './TextArea.scss';
import './TextArea.legacy.scss';

export default function TextArea(props) {
  const inputRef = useRef(null);

  const attachRef = el => {
    inputRef.current = el;
    if (props.textareaRef) {
      if (typeof props.textareaRef === 'function') {
        props.textareaRef(el);
      } else {
        props.textareaRef.current = el;
      }
    }
  };

  const { label, defaultValue, validationMessage, clearable, valid, className, required, legacyLook } = props;
  const propValue = props.value;

  const [value, setValue] = useState(isUndefined(propValue) ? defaultValue : propValue);

  if (!isUndefined(propValue) && propValue !== value) {
    setValue(propValue);
  }

  const restProps = omit(
    props,
    'label',
    'onChange',
    'defaultValue',
    'value',
    'validationMessage',
    'clearable',
    'valid',
    'className',
    'textareaRef',
    'required',
    'showCount',
    'legacyLook',
    'data-testid'
  );

  const isNotValid = (isString(validationMessage) && validationMessage.length > 0) || !valid;

  useEffect(
    function() {
      if (!inputRef.current) {
        return;
      }
      resizeTextAreaHeightToContent(inputRef.current);
    },
    [value, inputRef]
  );

  return (
    <div
      className={cx('eds-textarea', className, {
        'eds-textarea-not-empty': !isEmpty(value),
        'eds-textarea-not-valid': isNotValid,
        'eds-textarea-clear-all': clearable,
        'eds-textarea-legacy': legacyLook
      })}
      data-testid={props['data-testid']}
    >
      <label>
        <textarea ref={attachRef} value={fixControlledValue(value)} onChange={onChange} {...restProps} />
        <div className={cx('eds-textarea-outer', { 'eds-textarea-underlined': required })} />
        <span>{label}</span>
        {clearable && !isEmpty(value) && (
          <IconButton
            className="clear-all"
            color="light-grey"
            onClick={() => {
              setReactInputValue(inputRef.current, '');
            }}
          >
            cancel
          </IconButton>
        )}
      </label>
      {props.showCount && props.maxLength && (
        <div className="eds-textarea-count">
          {value?.length}/{restProps.maxLength}
        </div>
      )}
      <div className="eds-textarea-validation-message">{validationMessage}</div>
    </div>
  );

  function onChange(e) {
    e.persist();
    const { onChange } = props;
    setValue(e.target.value);
    isFunction(onChange) && onChange(e);
  }
}

function fixControlledValue(value) {
  if (typeof value === 'undefined' || value === null) {
    return '';
  }
  return value;
}

export function resizeTextAreaHeightToContent(element) {
  if (!element) {
    return false;
  }
  // Reset field height
  element.style.height = 'inherit';
  // Get the computed styles for the element
  const computed = window.getComputedStyle(element);

  // Calculate the height
  const height =
    parseInt(computed.getPropertyValue('border-top-width'), 10) +
    element.scrollHeight +
    parseInt(computed.getPropertyValue('border-bottom-width'), 10);
  element.style.height = `${height}px`;
}

function setReactInputValue(input, value) {
  const previousValue = input.value;
  input.value = value;
  const tracker = input._valueTracker;
  if (tracker) {
    tracker.setValue(previousValue);
  }
  input.dispatchEvent(new Event('change', { bubbles: true }));
}

TextArea.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  clearable: PropTypes.bool,
  valid: PropTypes.bool,
  validationMessage: PropTypes.string,
  legacyLook: PropTypes.bool
};
TextArea.defaultProps = { clearable: false, valid: true, legacyLook: false };
