import axios from 'axios';

const ROOT = 'api/v1/route-metadata/encounter';

const ReactRouteOldMetaDataApi = {
  getRouteOldMetadataForEncounterDetailsPage(patientEncounterId) {
    return axios.get(`${ROOT}/${patientEncounterId}`);
  },
  getRouteOldMetadataForItemGroupPage(patientEncounterId, itemGroupId) {
    return axios.get(`${ROOT}/${patientEncounterId}/item-group/${itemGroupId}`);
  }
};

export default ReactRouteOldMetaDataApi;
