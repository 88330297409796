export const ENCOUNTER_WIDGET = 'ENCOUNTER_WIDGET';
export const ADVERSE_EVENTS_WIDGET = 'ADVERSE_EVENTS_WIDGET';

export const OPEN = 'OPEN';
export const PI_REVIEW = 'PI_REVIEW';
export const SM_REVIEW = 'SM_REVIEW';
export const COMPLETE = 'COMPLETE';
export const CANCELED = 'CANCELED';
export const DATA_REQUIRED = 'DATA_REQUIRED';
export const AE_NOT_REPORTED = 'AE_NOT_REPORTED';
