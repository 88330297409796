import { EXTERNAL_INTEGRATION } from '../../../constants/accessToPages';

import MappingTemplates from './MappingTemplates';

export const mappingTemplatesRouterConfig = {
  name: 'Mapping Templates',
  path: '/mapping-templates',
  component: MappingTemplates,
  access: [EXTERNAL_INTEGRATION]
};
