import React from 'react';
import { isNull } from 'lodash';
import moment from 'moment/moment';

import Icon from '../../../../../../../common/general/Icon';

export const SignatureOverlayForAdverseEvent = ({ signedUsers, isGroupStudy, reviews }) => {
  if (isGroupStudy) {
    return reviews?.map((review, key) => {
      const performedSignature = signedUsers.find(signature => isSignaturePerformed(signature, review));
      return performedSignature ? (
        <div className="d-flex align-items-center i my-2" style={{ height: '18px' }} key={key}>
          <Icon style={{ fontSize: '25px', marginRight: '15px', fontWeight: 'bold' }}>done</Icon>
          {(performedSignature.groupAssignName ? performedSignature.groupAssignName : review.name) +
            ', ' +
            getSignatureInfo(performedSignature)}
        </div>
      ) : (
        <div className="my-2" style={{ marginLeft: signedUsers?.length ? '2.5rem' : '0.5rem' }} key={key}>
          {review.name}
        </div>
      );
    });
  } else {
    return signedUsers
      ?.sort((a, b) => moment(b.signatureDateTime) - moment(a.signatureDateTime))
      .map((signature, key) => (
        <div className="m-2" key={key}>
          {getSignatureInfo(signature)}
        </div>
      ));
  }
};

const getSignatureInfo = signature => {
  return signature.userName + ', ' + moment(signature.signatureDateTime).format('DD/MMM/YYYY, h:mm a');
};

const isSignaturePerformed = (signature, review) => {
  return !isNull(signature.rowId) && review.type === 'SM'
    ? signature.reviewType === 'SM_REVIEW_CONFIRMATION'
    : signature.groupAssignName === review.name;
};
