import axios from 'axios';

const ROOT = 'api/v1/patients';

const PatientApi = {
  getPatients(
    ssuIds,
    statuses = [],
    page,
    pageSize,
    sortProperties = [
      { property: 'patient.lastName', direction: 'ASC' },
      { property: 'patient.firstName', direction: 'ASC' }
    ]
  ) {
    return axios.post(`${ROOT}/status`, {
      ssuIds,
      statuses,
      page,
      pageSize,
      sortProperties
    });
  },
  exportPatients(filterProperty) {
    return axios.post(`${ROOT}/download`, filterProperty, {
      responseType: 'arraybuffer'
    });
  },
  getSearchPatients(page, pageSize, searchString) {
    return axios.post(
      `${ROOT}/search`,
      { page, pageSize, searchString },
      {
        hideLoader: true
      }
    );
  },
  getSearchPatientProfiles(page, pageSize, searchString) {
    return axios.post(
      `${ROOT}/search-patients-profiles`,
      { page, pageSize, searchString },
      {
        hideLoader: true
      }
    );
  },
  getRecentPatients(page, pageSize) {
    return axios.post(
      `${ROOT}/recent-patients`,
      { page, pageSize },
      {
        hideLoader: true
      }
    );
  },
  getRecentPatientsProfiles(page, pageSize) {
    return axios.post(
      `${ROOT}/recent-patients-profiles`,
      { page, pageSize },
      {
        hideLoader: true
      }
    );
  },
  updateSubjectId(ssuPatientId, subjectId) {
    return axios.put(`${ROOT}/${ssuPatientId}/subject-id`, { subjectId });
  },
  updatePatientSourceId(ssuPatientId, data) {
    return axios.put(`${ROOT}/${ssuPatientId}/patient-source-id`, data);
  },

  checkSubjectIdInCurrentStudy(ssuPatientId, resolvedSubjectId) {
    return axios.post(`${ROOT}/${ssuPatientId}/check-duplicate-subject-id`, { subjectId: resolvedSubjectId });
  },
  checkAbilityToEditFinanceDetails(patientId) {
    return axios.get(`${ROOT}/${patientId}/finance-details-access`);
  },
  checkAbilityToAddFinanceDetails(studySiteId) {
    return axios.get(`${ROOT}/study-sites/${studySiteId}/finance-details-access`);
  },
  getDictionaries() {
    return axios.get(`${ROOT}/dictionaries`);
  }
};

export default PatientApi;
