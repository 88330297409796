export const schedulingAppointmentStatusesValues = [
  { id: 'NO_APPOINTMENT', name: 'Projected' },
  { id: 'SCHEDULED', name: 'Scheduled' }
];
export const defaultSorted = [{ id: 'patientName', desc: false }];
export const encounterPerPersonValues = [
  { id: 1, name: '1' },
  { id: 5, name: '5' },
  { id: 10, name: '10' },
  { id: 'ALL', name: 'All' }
];
export const tableSectionStyle = {
  background: 'white',
  margin: '.5rem 1rem 0.5rem 1rem',
  padding: '1rem',
  border: '.0625rem solid rgba(222, 226, 230, 1)'
};
export const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_PAGE = 1;
