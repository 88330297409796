import { SITE_BILL } from '../../../../constants/accessToPages';

import SiteBill from './SiteBill';

export const siteBillRouterConfig = {
  name: 'Site Bill',
  path: '/site-bill',
  rootBreadcrumbName: 'Finance',
  component: SiteBill,
  access: [SITE_BILL]
};
