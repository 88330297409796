import React, { useEffect, useState } from 'react';

import { ParticipantApi } from '../../../../api';

export function ParticipantsProviderPatientEncounter({ initialPatientId, children }) {
  const [datasource, setDataSource] = useState();

  useEffect(() => {
    if (initialPatientId) {
      ParticipantApi.getAllSmOrPiOrSubPiParticipantsPerStudyRelatedToPatient(initialPatientId).then(
        ({ data: participants }) => {
          setDataSource(participants);
        }
      );
    } else {
      setDataSource([]);
    }
  }, [initialPatientId]);

  return (
    children &&
    React.Children.map(children, child => {
      return React.cloneElement(child, {
        ...child.props,
        datasource
      });
    })
  );
}
