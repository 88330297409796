import axios from 'axios';

const ROOT = 'api/v1/pendo';

const PendoApi = {
  getPendoConfig() {
    return axios.get(`${ROOT}/config`, { hideLoader: true });
  }
};
export default PendoApi;
