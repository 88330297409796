import { reduce } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';
import { addMethod, Schema } from 'yup';

export function parseYupValidationErrors(inner) {
  return reduce(
    inner,
    function(accumulator, { path, message }) {
      accumulator[path] = message;
      return accumulator;
    },
    {}
  );
}

export function parseYupRequiredFields({ fields }, parentKey = '') {
  return reduce(
    fields,
    function(accumulator, { optional, fields }, key) {
      if (!optional) {
        accumulator[parentKey + key] = !optional;
      }
      if (!isEmpty(fields)) {
        Object.assign(accumulator, parseYupRequiredFields({ fields }, parentKey + key + '.'));
      }
      return accumulator;
    },
    {}
  );
}

addMethod(Schema, 'ifNotEmpty', function(nextSchema) {
  return this.when(function([value], schema) {
    if (value) {
      return schema.concat(nextSchema);
    }
    return schema;
  });
});
