export const ADVERSE_EVENT = 'ADVERSE_EVENT';
export const CONCOMITANT_MEDICATION = 'CONCOMITANT_MEDICATION';
export const CRA_FINDING = 'CRA_FINDING';
export const EDC = 'EDC';
export const FILE_UPLOAD = 'FILE_UPLOAD';
export const GDP = 'GDP';
export const INCLUSION_EXCLUSION = 'INCLUSION_EXCLUSION';
export const INFORMED_CONSENT = 'INFORMED_CONSENT';
export const INVESTIGATOR_OVERSIGHT = 'INVESTIGATOR_OVERSIGHT';
export const IWRS_IRT = 'IWRS_IRT';
export const LABORATORY = 'LABORATORY';
export const MEDICAL_HISTORY = 'MEDICAL_HISTORY';
export const OTHER = 'OTHER';
export const PATIENT_INFO = 'PATIENT_INFO';
export const PATIENT_SAFETY = 'PATIENT_SAFETY';
export const PROTOCOL_COMPLIANCE = 'PROTOCOL_COMPLIANCE';
export const SOURCE_DATA_COLLECTION = 'SOURCE_DATA_COLLECTION';
export const W_9 = 'W_9';

export const dateFormat = 'DD/MMM/YYYY, h:mm A';

export const PROCESS = 'PROCESS';
export const QUALITY = 'QUALITY';
export const TO_DO = 'TO_DO';

export const TASKS_TYPES = [PROCESS, QUALITY, TO_DO];

export const TasksTypes = [
  { id: PROCESS, name: 'Process' },
  { id: QUALITY, name: 'Quality' },
  { id: TO_DO, name: 'To-Do' }
];

export const TasksTypesMap = {
  [PROCESS]: 'Process',
  [QUALITY]: 'Quality',
  [TO_DO]: 'To-Do'
};

export const TasksStatuses = [
  { id: 'OPEN', name: 'Open' },
  { id: 'PENDING', name: 'Pending' },
  { id: 'COMPLETE', name: 'Complete' },
  { id: 'CANCELED', name: 'Canceled' }
];

export const TasksStatusesMap = TasksStatuses.reduce(function(accumulator, value) {
  return { ...accumulator, [value.id]: value };
}, {});

export const TaskTopics = [
  { id: ADVERSE_EVENT, name: 'Adverse Event' },
  { id: CONCOMITANT_MEDICATION, name: 'Concomitant Medication' },
  { id: CRA_FINDING, name: 'CRA Finding' },
  { id: EDC, name: 'EDC' },
  { id: FILE_UPLOAD, name: 'File Upload' },
  { id: GDP, name: 'GDP' },
  { id: INCLUSION_EXCLUSION, name: 'Inclusion/Exclusion' },
  { id: INFORMED_CONSENT, name: 'Informed Consent' },
  { id: INVESTIGATOR_OVERSIGHT, name: 'Investigator Oversight' },
  { id: IWRS_IRT, name: 'IWRS/IRT' },
  { id: LABORATORY, name: 'Laboratory' },
  { id: MEDICAL_HISTORY, name: 'Medical History' },
  { id: OTHER, name: 'Other' },
  { id: PATIENT_INFO, name: 'Patient Info' },
  { id: PATIENT_SAFETY, name: 'Patient Safety' },
  { id: PROTOCOL_COMPLIANCE, name: 'Protocol Compliance' },
  { id: SOURCE_DATA_COLLECTION, name: 'Source Data Collection' },
  { id: W_9, name: 'W-9' }
];

export const TaskTopicsMap = TaskTopics.reduce(function(accumulator, value) {
  return { ...accumulator, [value.id]: value };
}, {});

export const TaskRoutes = Object.freeze([
  'Patients.Patient Profile.Patient Studies',
  'Patients.Patient Profile.Patient Studies.Encounter Details',
  'Patients.Patient Profile.Patient Studies.Encounter Details.SM Review',
  'Patients.Patient Profile.Patient Studies.Encounter Details.Investigator Review',
  'Patients.Patient Profile.Patient Studies.Encounter Details.Study Manager Encounter Review',
  'Patients.Patient Profile.Patient Studies.Encounter Details.Investigator Encounter Review',
  'Patients.Patient Profile.Patient Studies.Encounter Details.Informed Consent',
  'Patients.Patient Profile.Patient Studies.Encounter Details.Procedure',
  'Patients.Patient Profile.Patient Studies.Encounter Details.Logs',
  'Patients.Patient Profile.Patient Studies.Completed Informed Consents',
  'Patients.Patient Profile.Patient Studies.Logs',
  'Patients.Patient Profile.Patient Studies.Logs.Row',
  'Patients.Patient Profile.Patient Studies.Encounter Details.Logs.Row',
  'Patients.Patient Profile.Patient Studies.Encounter Details.Table',
  'Patients.Patient Profile.Patient Studies.Patient Status',
  'Worklist.Patient Profile.Patient Studies',
  'Worklist.Patient Profile.Patient Studies.Encounter Details',
  'Worklist.Patient Profile.Patient Studies.Encounter Details.SM Review',
  'Worklist.Patient Profile.Patient Studies.Encounter Details.Investigator Review',
  'Worklist.Patient Profile.Patient Studies.Encounter Details.Study Manager Encounter Review',
  'Worklist.Patient Profile.Patient Studies.Encounter Details.Investigator Encounter Review',
  'Worklist.Patient Profile.Patient Studies.Encounter Details.Informed Consent',
  'Worklist.Patient Profile.Patient Studies.Encounter Details.Procedure',
  'Worklist.Patient Profile.Patient Studies.Encounter Details.Logs',
  'Worklist.Patient Profile.Patient Studies.Completed Informed Consents',
  'Worklist.Patient Profile.Patient Studies.Logs',
  'Worklist.Patient Profile.Patient Studies.Logs.Row',
  'Worklist.Patient Profile.Patient Studies.Encounter Details.Logs.Row',
  'Worklist.Patient Profile.Patient Studies.Encounter Details.Table',
  'Worklist.Patient Profile.Patient Studies.Patient Status',
  'Investigator Worklist.Patient Profile.Patient Studies',
  'Investigator Worklist.Patient Profile.Patient Studies.Encounter Details',
  'Investigator Worklist.Patient Profile.Patient Studies.Encounter Details.SM Review',
  'Investigator Worklist.Patient Profile.Patient Studies.Encounter Details.Investigator Review',
  'Investigator Worklist.Patient Profile.Patient Studies.Encounter Details.Study Manager Encounter Review',
  'Investigator Worklist.Patient Profile.Patient Studies.Encounter Details.Investigator Encounter Review',
  'Investigator Worklist.Patient Profile.Patient Studies.Encounter Details.Informed Consent',
  'Investigator Worklist.Patient Profile.Patient Studies.Encounter Details.Procedure',
  'Investigator Worklist.Patient Profile.Patient Studies.Encounter Details.Logs',
  'Investigator Worklist.Patient Profile.Patient Studies.Completed Informed Consents',
  'Investigator Worklist.Patient Profile.Patient Studies.Logs',
  'Investigator Worklist.Patient Profile.Patient Studies.Logs.Row',
  'Investigator Worklist.Patient Profile.Patient Studies.Encounter Details.Logs.Row',
  'Investigator Worklist.Patient Profile.Patient Studies.Encounter Details.Table',
  'Investigator Worklist.Patient Profile.Patient Studies.Patient Status',
  'Study Manager Worklist.Patient Profile.Patient Studies',
  'Study Manager Worklist.Patient Profile.Patient Studies.Encounter Details',
  'Study Manager Worklist.Patient Profile.Patient Studies.Encounter Details.SM Review',
  'Study Manager Worklist.Patient Profile.Patient Studies.Encounter Details.Investigator Review',
  'Study Manager Worklist.Patient Profile.Patient Studies.Encounter Details.Study Manager Encounter Review',
  'Study Manager Worklist.Patient Profile.Patient Studies.Encounter Details.Investigator Encounter Review',
  'Study Manager Worklist.Patient Profile.Patient Studies.Encounter Details.Informed Consent',
  'Study Manager Worklist.Patient Profile.Patient Studies.Encounter Details.Procedure',
  'Study Manager Worklist.Patient Profile.Patient Studies.Encounter Details.Logs',
  'Study Manager Worklist.Patient Profile.Patient Studies.Completed Informed Consents',
  'Study Manager Worklist.Patient Profile.Patient Studies.Logs',
  'Study Manager Worklist.Patient Profile.Patient Studies.Logs.Row',
  'Study Manager Worklist.Patient Profile.Patient Studies.Encounter Details.Logs.Row',
  'Study Manager Worklist.Patient Profile.Patient Studies.Encounter Details.Table',
  'Study Manager Worklist.Patient Profile.Patient Studies.Patient Status',
  'CRA Review.Encounter Review'
]);
