import { useMemo } from 'react';

import CheckBoxField, { CheckBoxFieldReadonly } from './CheckBoxField/CheckBoxField';
import DateField, { DateFieldReadonly } from './DateField/DateField';
import FileUploadField, { FileUploadFieldReadonly } from './FileUploadField/FileUploadField';
import FreeTextLongField, { FreeTextLongFieldReadonly } from './FreeTextLongField/FreeTextLongField';
import MultiselectField, { MultiselectFieldReadonly } from './MultiselectField/MultiselectField';
import NoticeField from './NoticeField/NoticeField';
import SelectField, { SelectFieldReadonly } from './SelectField/SelectField';
import TextBlockField from './TextBlockField/TextBlockField';
import TextboxField from './TextboxField/TextboxField';
import TimeField, { TimeFieldReadonly } from './TimeField/TimeField';
import ToggleField, { ToggleFieldReadonly } from './ToggleField/ToggleField';

const fieldTypesMap = {
  dropdown: [SelectField, SelectFieldReadonly],
  radio: [ToggleField, ToggleFieldReadonly],
  date: [DateField, DateFieldReadonly],
  time: [TimeField, TimeFieldReadonly],
  textbox: [TextboxField, FreeTextLongFieldReadonly],
  multiselect: [MultiselectField, MultiselectFieldReadonly],
  reminder: [NoticeField, NoticeField],
  textBlockLong: [NoticeField, NoticeField],
  dropdownCT: [SelectField, SelectFieldReadonly],
  multiselectCT: [MultiselectField, MultiselectFieldReadonly],
  label: [TextBlockField, TextBlockField],
  textarea: [FreeTextLongField, FreeTextLongFieldReadonly],
  checkbox: [CheckBoxField, CheckBoxFieldReadonly],
  file: [FileUploadField, FileUploadFieldReadonly],
  singleFile: [FileUploadField, FileUploadFieldReadonly]
};

export default function TableItemGroupField({ field, readonly }) {
  const ResolvedFieldByType = useMemo(
    function() {
      const resolvedFieldArray = fieldTypesMap[field?.fieldType],
        resolvedFieldComponent = resolvedFieldArray && resolvedFieldArray[readonly ? 1 : 0];
      return (
        resolvedFieldComponent ||
        function() {
          return 'TEMPLATE_IS_UNDEFINED';
        }
      );
    },
    [field?.fieldType, readonly]
  );
  return <ResolvedFieldByType field={field} />;
}
