import React, { useEffect, useState } from 'react';
import capitalize from 'lodash/capitalize';

import InformedConsentApi from '../../../../api/patient/InformedConsentApi';
import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../common/general/Button';
import Icon from '../../../../common/general/Icon';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import { INFORMED_CONSENT_ITEM_SAVE_FAILED } from '../../../../constants/notificationMessages';
import { ADD_EDIT_INFORMED_CONSENT_ITEMS } from '../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../services/auth';

import InformedConsentItemModal from './InformedConsentItemModal';

import './InformedConsentSetup.scss';

const InformedConsentSetupItem = ({ label, current, id, studyId, updateItems }) => {
  return (
    <div className="edit-informed-consent-setup-item">
      <p className="edit-informed-consent-setup-item-text">{current}</p>
      {userHasAccessTo(ADD_EDIT_INFORMED_CONSENT_ITEMS) && (
        <span className="edit-informed-consent-setup-item-button">
          <Button size="h28" priority="low" onClick={() => openEditModal({ label, current, id, studyId, updateItems })}>
            <Icon suit="glyphicon">edit</Icon>
          </Button>
        </span>
      )}
    </div>
  );
};

const openAddModal = ({ label, studyId, updateItems }) => {
  ModalBoxes.open({
    component: <InformedConsentItemModal label={label} studyId={studyId} updateItems={updateItems} />,
    onClose: isChanged => {
      if (isChanged) {
        NotificationManager.error(INFORMED_CONSENT_ITEM_SAVE_FAILED);
      }
    }
  });
};

const openEditModal = ({ label, current, studyId, id, updateItems }) => {
  ModalBoxes.open({
    component: (
      <InformedConsentItemModal label={label} id={id} current={current} studyId={studyId} updateItems={updateItems} />
    ),
    onClose: isChanged => {
      if (isChanged) {
        NotificationManager.error(INFORMED_CONSENT_ITEM_SAVE_FAILED);
      }
    }
  });
};

const Column = ({ label, items, studyId, updateItems }) => {
  return (
    <div className="informed-consent-setup-column">
      <div className="informed-consent-setup-column-header">
        <p className="informed-consent-setup-column-header-text">{capitalize(label)}s</p>
        {userHasAccessTo(ADD_EDIT_INFORMED_CONSENT_ITEMS) && (
          <span className="add-informed-consent-setup-item">
            <Button size="h28" priority="low" onClick={() => openAddModal({ label, studyId, updateItems })}>
              <Icon suit="glyphicon">plus</Icon>
            </Button>
          </span>
        )}
      </div>
      <ul>{items}</ul>
    </div>
  );
};

const InformedConsentSetup = ({ studyId, studyName }) => {
  const [informedConsentItems, setInformedConsentItems] = useState({ types: [], languages: [] });

  const updateItems = () => {
    InformedConsentApi.getItemsByStudyId(studyId).then(res => {
      const {
        data: { types, languages }
      } = res;
      setInformedConsentItems({ types, languages });
    });
  };

  useEffect(() => {
    if (studyId) {
      updateItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyId]);

  const types = informedConsentItems.types.map(item => {
    return (
      <li key={`${item.id}-type`}>
        {InformedConsentSetupItem({
          id: item.id,
          label: 'type',
          current: item.type,
          studyId: studyId,
          updateItems: updateItems
        })}
      </li>
    );
  });

  const languages = informedConsentItems.languages.map(item => {
    return (
      <li key={`${item.id}-language`}>
        {InformedConsentSetupItem({
          id: item.id,
          label: 'language',
          current: item.language,
          studyId: studyId,
          updateItems: updateItems
        })}
      </li>
    );
  });

  return (
    <>
      <section>
        <div className="informed-consent-setup-wrapper p-3 m-0 mt-2 border">
          <div className="informed-consent-setup-header border-bottom-dotted">
            <p>
              Informed Consent: <span>{studyName}</span>{' '}
            </p>
          </div>
          <div className="informed-consent-setup-columns">
            {Column({ label: 'type', items: types, studyId, updateItems })}
            {Column({ label: 'language', items: languages, studyId, updateItems })}
          </div>
        </div>
      </section>
    </>
  );
};

export default InformedConsentSetup;
