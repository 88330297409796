import React, { useContext, useEffect, useState } from 'react';
import Tooltip from 'rc-tooltip';

import Icon from '../../../../../../../common/general/Icon';
import { scGray60, scPurple80 } from '../../../../../../../constants/systemColors';
import { EMDASH } from '../../../../SmWorklistNew/constants';
import { ADVERSE_EVENTS_WIDGET, ENCOUNTER_WIDGET } from '../../constants';

import { SignatureOverlayForAdverseEvent } from './SignatureOverlayForAdverseEvent';
import { SignatureOverlayForEncounter } from './SignatureOverlayForEncounter';
import { WorklistSignedByFilter } from './WorklistSignedByFilter';

import './SignedByFilterColumn.scss';

export const CustomSignedBySortAndFilterHeader = ({ Context, signaturesProvider, statusesProvider }) => {
  const {
    EncounterWidgetContext: { currentlySelected },
    AdverseEventsWidgetContext: { currentlySelected: currentlySelectedForAdverse },
    signatures
  } = useContext(Context);
  const ref = React.useRef();
  const [isSignedByFilterVisible, setSignedByFilterVisible] = useState(false);
  return (
    <div className="rt-hoc-sort-header" ref={ref}>
      <WorklistSignedByFilter
        filterRef={ref}
        Context={useContext(Context)}
        signaturesProvider={signaturesProvider}
        statusesProvider={statusesProvider}
        isSignedByFilterVisible={isSignedByFilterVisible}
        setSignedByFilterVisible={setSignedByFilterVisible}
      />
      <Icon
        className={'filter-icon'}
        style={{
          color: signatures.length > 0 ? scPurple80 : scGray60,
          cursor: 'pointer'
        }}
        onClick={e => {
          e.stopPropagation();
          (currentlySelected || currentlySelectedForAdverse) && setSignedByFilterVisible(!isSignedByFilterVisible);
        }}
      >
        filter_alt
      </Icon>
      <div className="title">{'Signed By'}</div>
    </div>
  );
};

//TODO: this is temporary solution
//should be removed with the feature flag 'signedByFilterEnabled'
export const DefaultSignedByHeader = ({ Context }) => {
  const {
    EncounterWidgetContext: { currentlySelected },
    AdverseEventsWidgetContext: { currentlySelected: currentlySelectedForAdverse },
    updateWithSignature
  } = useContext(Context);

  useEffect(() => {
    if (currentlySelectedForAdverse && !currentlySelected) {
      updateWithSignature([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlySelectedForAdverse, currentlySelected]);

  return 'Signed By';
};

export const SignedByFilterColumn = (
  context,
  signaturesProvider,
  statusesProvider,
  signedByFilterEnabled,
  statusType,
  selectedStatus
) => {
  const { isSignedByColumnFetching } = useContext(context);

  //TODO: this is temporary solution
  //should be removed with the feature flag 'signedByFilterEnabled'
  return {
    Header: signedByFilterEnabled ? (
      <CustomSignedBySortAndFilterHeader
        Context={context}
        signaturesProvider={signaturesProvider}
        statusesProvider={statusesProvider}
      />
    ) : (
      <DefaultSignedByHeader Context={context} />
    ),
    accessor: 'signedUsers',
    className: 'rt-hoc-centered p-3 signed-by-cell',
    sortable: false,
    minWidth: 60,
    Cell: ({ value, original }) => {
      const resolvedReviewers = resolveReviewers(statusType, original.requiredReviews, selectedStatus);
      if (!isSignedByColumnFetching) {
        return (
          <Tooltip
            destroyTooltipOnHide={true}
            placement="top"
            trigger={resolvedReviewers?.length ? ['hover'] : []}
            overlayClassName="eds-rc-tooltip"
            overlay={
              statusType === ENCOUNTER_WIDGET ? (
                <SignatureOverlayForEncounter
                  signedUsers={value}
                  isGroupStudy={original.groupAssignStudy}
                  reviews={resolvedReviewers}
                />
              ) : (
                <SignatureOverlayForAdverseEvent
                  signedUsers={value}
                  isGroupStudy={original.groupAssignStudy}
                  reviews={resolvedReviewers}
                />
              )
            }
          >
            <span>{resolvedReviewers?.length ? `${value?.length}/${resolvedReviewers?.length}` : EMDASH}</span>
          </Tooltip>
        );
      } else {
        return <div className={'signed-by-column-loader-gradient'} />;
      }
    }
  };
};

const resolveReviewers = (statusType, requiredReviews) => {
  if (statusType === ADVERSE_EVENTS_WIDGET && !!requiredReviews) {
    return [...requiredReviews.filter(review => review.type === 'INVESTIGATOR'), { name: 'Study Manager', type: 'SM' }];
  }
  return requiredReviews;
};
