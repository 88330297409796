import axios from 'axios';

const ROOT = 'api/v1/studies';

const EncounterLogCheckApi = {
  getLogCheckAnswers(studyId, sitePatientId, encounterId, itemGroupTemplateId, itemGroupTemplateVersionId) {
    return axios.get(
      `${ROOT}/${studyId}/patients/${sitePatientId}/encounters/${encounterId}/item-group-templates/${itemGroupTemplateId}/versions/${itemGroupTemplateVersionId}/log-check/answers`
    );
  },

  saveLogCheck(studyId, sitePatientId, encounterId, itemGroupTemplateId, itemGroupTemplateVersionId, data) {
    return axios.post(
      `${ROOT}/${studyId}/patients/${sitePatientId}/encounters/${encounterId}/item-group-templates/${itemGroupTemplateId}/versions/${itemGroupTemplateVersionId}/log-check`,
      data
    );
  }
};

export default EncounterLogCheckApi;
