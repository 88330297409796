import axios from 'axios';

const ROOT = 'api/v1/ssu-patients';

const NonProtocolEncounterApi = {
  createNonProtocolEncounter(ssuPatientId, data) {
    return axios.post(`${ROOT}/${ssuPatientId}/non-protocol-encounters`, data);
  }
};

export default NonProtocolEncounterApi;
