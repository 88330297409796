import React, { useImperativeHandle, useRef } from 'react';
import DateTime from 'react-datetime';
import cx from 'classnames';
import { isFunction } from 'lodash/lang';
import { omit } from 'lodash/object';

import Input from '../Input';

import DatePickerManual from './DatePickerManual/DatePickerManual';
import { datePickerInputTypes } from './types';

const DatePicker = React.forwardRef(function(props, ref) {
  const {
    value,
    label,
    className,
    required,
    readOnly,
    validationMessage,
    valid,
    disabled,
    placeholder,
    iconsAfter,
    isValidDate,
    onChange,
    validate,
    legacyLook
  } = props;

  const restProps = omit(
    props,
    'value',
    'label',
    'className',
    'required',
    'readOnly',
    'validationMessage',
    'valid',
    'disabled',
    'placeholder',
    'iconsAfter',
    'isValidDate',
    'onChange',
    'validate',
    'legacyLook'
  );
  const datePickerRef = useRef();
  useImperativeHandle(ref, () => datePickerRef.current);
  return (
    <DateTime
      ref={datePickerRef}
      renderInput={customRenderInput}
      isValidDate={isValidDate}
      value={value}
      timeFormat={false}
      className={cx(`eui-date-picker`, className, {
        'eui-date-picker-legacy': legacyLook
      })}
      inputProps={{
        label,
        required,
        readOnly,
        validationMessage,
        valid,
        disabled,
        placeholder,
        iconsAfter,
        validate,
        legacyLook
      }}
      onChange={isFunction(onChange) && onChange}
      {...restProps}
    />
  );
});

function customRenderInput(inputProps) {
  const restProps = omit(inputProps, 'className');
  return <Input {...restProps} />;
}

DatePicker.defaultProps = {
  valid: true,
  dateFormat: 'DD/MMM/YYYY',
  readOnly: true,
  closeOnSelect: true,
  validate: true
};

DatePicker.propTypes = datePickerInputTypes;

DatePicker.Manual = DatePickerManual;

export default DatePicker;
