import React, { Component } from 'react';
import ReactTable from 'react-table';

import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../constants/dateFormat';
import { pendoTrackDefaultSortingChange } from '../../../../../pendo/PendoUtils';
import { SSUFilter } from '../../../../SSUFilter/SSUFilter';
import { SSUPCNFilter } from '../../../../SSUFilter/SSUPCNFilter';

import 'react-table/react-table.css';
const moment = require('moment');
const PUBLISH_BUDGET_TABLE_DATA = 'PUBLISH_BUDGET_TABLE_DATA';
export class BudgetSiteList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studyFilterCriteria: '',
      siteFilterCriteria: '',
      pageSize: sessionStorage.getItem(PUBLISH_BUDGET_TABLE_DATA)
        ? JSON.parse(sessionStorage.getItem(PUBLISH_BUDGET_TABLE_DATA))?.pageSize
        : 25 //oneOf([25, 50, 100])
    };
  }

  onSSUFilterChange = (ssuList, selectedStudy, selectedSite) => {
    this.setState({
      siteFilterCriteria: selectedSite?.siteName?.toLowerCase() || '',
      studyFilterCriteria: selectedStudy?.studyName?.toLowerCase() || ''
    });
  };

  render() {
    let data = this.props.budgetSitesList;

    if (this.state.studyFilterCriteria !== '' || this.state.siteFilterCriteria !== '') {
      data = data.filter(el => {
        const study = el.studyName.toLowerCase();
        const site = el.siteName.toLowerCase();
        const isStudy = this.state.studyFilterCriteria ? study === this.state.studyFilterCriteria : true;
        const isSite = this.state.siteFilterCriteria ? site === this.state.siteFilterCriteria : true;
        return isStudy && isSite;
      });
    }
    const columns = [
      {
        Header: 'Study',
        accessor: 'studyName',
        Cell: row => {
          return row.original.studyName ? <span> {row.original.studyName}</span> : '--';
        }
      },
      {
        Header: 'PCN',
        accessor: 'pcn',
        width: 110,
        Cell: row => {
          return row.original.pcn ? <span> {row.original.pcn}</span> : '--';
        }
      },
      {
        Header: 'Site',
        accessor: 'siteName',
        Cell: row => {
          return row.original.siteName ? <span> {row.original.siteName}</span> : '--';
        }
      },
      {
        Header: 'Budget Version',
        accessor: 'version',
        width: 125,
        Cell: row => {
          return row.original.version ? <span> {row.original.version}</span> : '--';
        }
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: row => {
          return row.original.description ? <span> {row.original.description}</span> : '--';
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 90,
        Cell: row => {
          const { status } = row.original;

          return status ? status.charAt(0).toUpperCase() + status.slice(1).toLowerCase() : '--';
        }
      },
      {
        id: 'effectiveDate',
        Header: 'Effective Date',
        width: 115,
        accessor: ({ effectiveDate }) => moment(effectiveDate),
        Cell: ({ value }) => {
          return value.format(DD_SLASH_MMM_SLASH_YYYY);
        }
      },
      {
        Header: 'Published Date',
        accessor: 'publishedDate',
        Cell: row => {
          return convertPublishDateToCentralTimeZone(row.original?.publishedDate);
        }
      },
      {
        Header: 'Published By',
        accessor: 'publishedBy',
        Cell: row => {
          return row.original.publishedBy ? <span> {row.original.publishedBy}</span> : '--';
        }
      }
    ];

    function convertPublishDateToCentralTimeZone(publishDate) {
      return (
        <span>
          {moment(publishDate)
            .tz('America/Chicago')
            .format('DD/MMM/YYYY hh:mm A')}
        </span>
      );
    }

    return (
      <div>
        <section className="existing-budget-sites-section">
          <div className="existing-budgets">
            <h5>Budget-Site mappings</h5>
            <div className="general-header-group-container general-header-wrapper">
              <SSUFilter handleSSUFilterChange={this.onSSUFilterChange} ssuProvider={this.props.studyOptions}>
                <SSUPCNFilter />
              </SSUFilter>
            </div>
            <div className="col-12 border px-0">
              <ReactTable
                data={data || []}
                columns={columns}
                minRows={1}
                defaultSorted={[
                  {
                    id: 'studyName'
                  }
                ]}
                onSortedChange={pendoTrackDefaultSortingChange}
                onPageSizeChange={pageSize => {
                  this.setState({ pageSize: pageSize });
                  sessionStorage.setItem(PUBLISH_BUDGET_TABLE_DATA, JSON.stringify({ pageSize: pageSize }));
                }}
                multiSort
                showPagination
                nextText=">>"
                previousText="<<"
                noDataText="No Record Found"
                pageSizeOptions={[25, 50, 100]}
                defaultPageSize={this.state.pageSize}
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default BudgetSiteList;
