import './StudyDetailsSection.scss';

export default function StudyDetailsSection({ children }) {
  return (
    <section className="study-details-section">
      <div className="study-details-section-container">{children}</div>
    </section>
  );
}

StudyDetailsSection.Header = function StudyDetailsSectionHeader({ children }) {
  return <div className="study-details-section-header">{children}</div>;
};

StudyDetailsSection.StudyName = function StudyDetailsSectionStudyName({ children }) {
  return <span className="study-details-section-study-name">{children}</span>;
};

StudyDetailsSection.Body = function StudyDetailsSectionBody({ children }) {
  return <div className="study-details-section-body">{children}</div>;
};
