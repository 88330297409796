import React, { Component } from 'react';
import cx from 'classnames';
import { isString } from 'lodash/lang';
import * as PropTypes from 'prop-types';
import uuid from 'uuid/v4';

import './ToggleSwitch.scss';

export default class ToggleSwitch extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.number,
    primaryLabel: PropTypes.string,
    leftLabel: PropTypes.string,
    rightLabel: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string
  };

  static defaultProps = {
    leftLabel: 'Yes',
    rightLabel: 'No'
  };

  inputGroupUUID = uuid();

  constructor(props) {
    super(props);
    this.state = {
      selectedInput: [0, 1, 2].includes(props.value) ? props.value : 0
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.value !== this.state.selectedInput) {
      this.setState({ selectedInput: this.props.value });
    }
  }

  onChange = ({ target }) => {
    this.setState({ selectedInput: +target.value }, () => {
      this.props.onChange(this.state.selectedInput);
    });
  };

  render() {
    const { selectedInput } = this.state;
    const { primaryLabel, leftLabel, rightLabel, disabled, className } = this.props;
    return (
      <div className={cx('eds-toggle-switch', className, { 'eds-toggle-switch-disabled': disabled })}>
        {isString(primaryLabel) && <div className="eds-toggle-switch-primary-label">{primaryLabel}</div>}
        {isString(leftLabel) && <div className="eds-toggle-switch-left-label">{leftLabel}</div>}
        <div className="eds-toggle-switch-control">
          <input
            name={this.inputGroupUUID}
            type="radio"
            value="1"
            checked={selectedInput === 1}
            onChange={this.onChange}
            disabled={disabled}
          />
          <input
            name={this.inputGroupUUID}
            type="radio"
            value="0"
            checked={selectedInput === 0}
            onChange={this.onChange}
            disabled={disabled}
          />
          <input
            name={this.inputGroupUUID}
            type="radio"
            value="2"
            checked={selectedInput === 2}
            onChange={this.onChange}
            disabled={disabled}
          />
          <span />
        </div>
        {isString(rightLabel) && <div className="eds-toggle-switch-right-label">{rightLabel}</div>}
      </div>
    );
  }
}
