import axios from 'axios';

const ROOT = 'api/v1/invoices/reconciliations';

const FinInvoiceReconcileApi = {
  getInvoiceReconciles(finLedgerEventItemIds) {
    return axios.post(`${ROOT}/search`, finLedgerEventItemIds);
  },
  postUpdatedInvoiceReconciles(data) {
    return axios.post(ROOT, data);
  }
};

export default FinInvoiceReconcileApi;
