import React from 'react';

import TextArea from '../../../../../../common/data-entry/TextArea';

import './DirectionInputSection.scss';

export const DirectionInputSection = ({ direction, changeDirection, title }) => {
  return (
    <div className={'direction-field-container'}>
      <span className={'direction-title'}>{title}</span>
      <TextArea
        className={'direction-field-textarea'}
        onChange={e => {
          changeDirection(e.target.value);
        }}
        value={direction}
        maxLength={500}
      />
    </div>
  );
};
