import { get } from 'lodash/object';
import moment from 'moment-timezone';

export function isDisabledAuthority(participant) {
  return participant.type === 'PATIENT' ? false : !participant.activeOnSsu;
}

export function hasUserAccessToProtectedPatientInfo(appointment) {
  return !get(appointment, 'partialAccess');
}

export function getAllSubjectIds(patient, showAllSubjectIds) {
  return patient && patient.subjectIds && showAllSubjectIds ? `(${patient.subjectIds})` : '';
}

export function composePatientName(appointment, patient, sitePatientSubjectId, showAllSubjectIds) {
  return (
    (hasUserAccessToProtectedPatientInfo(appointment) &&
      `${get(patient, 'firstName')} ${get(patient, 'lastName')} ${getAllSubjectIds(patient, showAllSubjectIds)}`) ||
    sitePatientSubjectId ||
    'N/A'
  );
}
export function getAllSubjectIdsForTask(patientName, patientSubjectId, showAllSubjectIds) {
  return patientName && patientSubjectId && showAllSubjectIds ? `(${patientSubjectId})` : '';
}

export function composePatientNameFromTask(appointment, patientFullName, sitePatientSubjectId, showAllSubjectIds) {
  return (
    (hasUserAccessToProtectedPatientInfo(appointment) &&
      `${patientFullName ? patientFullName : ''} ${getAllSubjectIdsForTask(patientFullName, showAllSubjectIds)}`) ||
    sitePatientSubjectId ||
    'N/A'
  );
}

export function weekOfDate(displayedDate) {
  return {
    start: moment(displayedDate)
      .startOf('week')
      .startOf('day'),
    end: moment(displayedDate)
      .endOf('week')
      .endOf('day')
  };
}

export function daysToEndOfWeek(displayedDate) {
  return {
    start: moment(displayedDate).startOf('day'),
    end: moment(displayedDate)
      .endOf('week')
      .endOf('day')
  };
}

export function buildSiteCalendarBasedOnEvent(event) {
  return {
    checked: true,
    group: 'calendars',
    id: event.siteId,
    label: 'Site',
    name: event.siteName,
    selected: true,
    selectedOrder: 1,
    type: 'Site'
  };
}
