import React, { useCallback, useContext, useEffect, useState } from 'react';

const ExportCaseReportFormsStateContext = React.createContext(null);
const ExportCaseReportFormsActionsContext = React.createContext(null);

export function ExportCaseReportFormsProvider({ children }) {
  const [allEncounters, setAllEncounters] = useState([]);
  const [selectedItemGroupsMap, setSelectedItemGroupsMap] = useState({});
  const [allLogs, setAllLogs] = useState([]);
  const [selectedLogs, setSelectedLogs] = useState([]);
  const [isProgressNotesChecked, setProgressNotesChecked] = useState(true);
  const [selectedType, setSelectedType] = useState('ENCOUNTERS');
  const [selectedEncounter, setSelectedEncounter] = useState(null);

  useEffect(
    function() {
      setSelectedEncounter(allEncounters[0]);
      setSelectedItemGroupsMap(
        allEncounters.reduce(function(accumulator, encounter) {
          const { id, itemGroups } = encounter;
          return { ...accumulator, [id]: itemGroups };
        }, {})
      );
    },
    [allEncounters]
  );

  useEffect(
    function() {
      setSelectedLogs(allLogs);
    },
    [allLogs]
  );

  const onEncounterTypeCheckboxClick = useCallback(function(itemGroups) {
    setSelectedItemGroupsMap(function(state) {
      return { ...state, ...itemGroups };
    });
  }, []);

  const onItemGroupsSelect = useCallback(function(key, newSelectedItemGroups) {
    setSelectedItemGroupsMap(function(state) {
      return { ...state, [key]: newSelectedItemGroups };
    });
  }, []);

  const onLogsSelect = useCallback(function(newSelectedLogs) {
    setSelectedLogs(function(state) {
      return [...newSelectedLogs];
    });
  }, []);

  return (
    <ExportCaseReportFormsStateContext.Provider
      value={{
        allEncounters,
        selectedItemGroupsMap,
        allLogs,
        selectedLogs,
        selectedType,
        selectedEncounter,
        isProgressNotesChecked
      }}
    >
      <ExportCaseReportFormsActionsContext.Provider
        value={{
          setAllEncounters,
          setSelectedItemGroupsMap,
          setAllLogs,
          setSelectedLogs,
          setSelectedType,
          setSelectedEncounter,
          setProgressNotesChecked,
          onEncounterTypeCheckboxClick,
          onItemGroupsSelect,
          onLogsSelect
        }}
      >
        {children}
      </ExportCaseReportFormsActionsContext.Provider>
    </ExportCaseReportFormsStateContext.Provider>
  );
}

export function useExportCaseReportFormsState() {
  const context = useContext(ExportCaseReportFormsStateContext);
  if (context === undefined) {
    throw new Error('useExportCaseReportFormsState must be used within a ExportCaseReportFormsProvider');
  }
  return context;
}

export function useExportCaseReportFormsActions() {
  const context = useContext(ExportCaseReportFormsActionsContext);
  if (context === undefined) {
    throw new Error('useExportCaseReportFormsActions must be used within a ExportCaseReportFormsProvider');
  }
  return context;
}
