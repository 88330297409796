import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { uniqBy } from 'lodash';
import { isEmpty } from 'lodash/lang';

import { FormBuilderApi, TerminologyVersionApi } from '../../../../../api';
import Input from '../../../../../common/data-entry/Input';
import Select from '../../../../../common/data-entry/Select';
import ApplyAndResetButtons from '../../../../ApplyAndResetButtons/ApplyAndResetButtons';
import { customOptionTerminologyVersion } from '../../shared/ElementSetupNew/ItemGroupSetup/itemGroupSetupRenderHelpService';
import { DOMAIN_NAME, FILTERS_KEYS, FILTERS_MAP, NAME, TERMINOLOGY_VERSION, TYPE } from '../constants';

export default function TemplatesFilters({ templates, setFilters, filters }) {
  const [domains, setDomains] = useState();
  const [allVersions, setAllVersions] = useState([]);

  const types = useMemo(() => {
    if (!filters?.domainName) {
      return uniqBy(
        templates.map(item => ({ type: item.type })),
        TYPE
      );
    }
    if (!isEmpty(templates)) {
      return uniqBy(
        templates.filter(item => item.domainName === filters.domainName).map(item => ({ type: item.type })),
        TYPE
      );
    }
    return [];
  }, [filters, templates]);

  const terminologyVersions = useMemo(() => {
    if (!isEmpty(templates)) {
      return allVersions.filter(v => templates.map(t => t.terminologyVersionGroupNumber).includes(v.number));
    }
    return [];
  }, [allVersions, templates]);

  const getValue = useCallback(
    function(key, list) {
      return list?.find(item => item[key] === filters[key]);
    },
    [filters]
  );

  useEffect(() => {
    FormBuilderApi.getAllStandardDomains().then(res => setDomains(res.data.response));
  }, []);

  useEffect(function() {
    TerminologyVersionApi.getAllTerminologyVersionGroups().then(function({ data }) {
      setAllVersions(data);
    });
  }, []);

  const domainValue = useMemo(() => getValue(DOMAIN_NAME, domains), [domains, getValue]);
  const typeValue = useMemo(() => getValue(TYPE, types), [getValue, types]);
  const terminologyVersionValue = useMemo(() => {
    const filteredValue = terminologyVersions.filter(version => version.number === filters[TERMINOLOGY_VERSION]);
    return filteredValue[0];
  }, [filters, terminologyVersions]);
  const textValue = useMemo(() => filters[NAME], [filters]);
  const resetDisabled = useMemo(() => FILTERS_KEYS.every(key => isEmpty(filters[key]) && !filters[key]), [filters]);

  return (
    <div className="general-header-group-container general-header-wrapper igt-selects">
      <Select
        optionLabelKey={DOMAIN_NAME}
        optionValueKey={DOMAIN_NAME}
        dataSource={domains}
        label="Domain"
        onChange={onFilterChange(DOMAIN_NAME)}
        validate={false}
        value={domainValue}
      />
      <Select
        onChange={onFilterChange(TYPE)}
        optionLabelKey={TYPE}
        optionValueKey={TYPE}
        dataSource={types}
        label="Type"
        validate={false}
        value={typeValue}
      />
      <Select
        validate={false}
        customOptionTemplateFunction={item =>
          customOptionTerminologyVersion(item, { display: 'flex', flexDirection: 'column' })
        }
        onChange={onFilterChange(TERMINOLOGY_VERSION)}
        customSelectedValueTemplateFunction={array =>
          customOptionTerminologyVersion(array[0], {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            minWidth: '100px'
          })
        }
        label="Terminology version"
        dataSource={terminologyVersions}
        optionValueKey={'number'}
        value={terminologyVersionValue}
      />
      <Input onChange={onFilterChange(NAME)} label="Name" validate={false} value={textValue} />
      <ApplyAndResetButtons resetDisabled={resetDisabled} onReset={() => setFilters(FILTERS_MAP)} />
    </div>
  );
  function onFilterChange(key) {
    return function(value) {
      setFilters(function(prevState) {
        const filters = { ...prevState };
        if (value) {
          if (key === NAME) {
            filters[key] = value.target.value;
          } else if (key === TERMINOLOGY_VERSION) {
            filters[key] = value.number;
          } else {
            filters[key] = value[key];
          }
        } else {
          filters[key] = value;
        }
        return {
          ...filters
        };
      });
    };
  }
}
