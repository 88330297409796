import React from 'react';

import AddLedgerContent from './AddLedgerContent/AddLedgerContent';
import { AddLedgerProvider } from './AddLedgerContext';

export default function AddLedger() {
  return (
    <AddLedgerProvider>
      <AddLedgerContent />
    </AddLedgerProvider>
  );
}
