import React from 'react';
import ReactTable from 'react-table';
import { isEmpty } from 'lodash/lang';

import { pendoTrackDefaultSortingChange } from '../../../../pendo/PendoUtils';

import './LoadPatientsResult.scss';

const LoadPatientsResult = props => {
  const columns = [
    {
      id: 'rowNumber',
      Header: 'Row number',
      accessor: 'rowNumber',
      width: 120
    },
    {
      Header: 'Failure Reason(s)',
      Cell: ({ original }) =>
        original.failureReasons.map((f, k) => (
          <span className="failure-reason" key={k}>
            {f}
          </span>
        ))
    }
  ];

  const hasFailedRows = () => {
    return !isEmpty(props.response.failedRows);
  };

  const calculatePercentage = count => {
    return Math.round((100 / props.response.processed) * count);
  };

  return (
    <section className="load-patients-result">
      <div className="container-fluid border mt-2 py-3">
        <div className="row pt-2">
          <div className="col-md-12">
            <h5 className="c-p">Import Patients Result</h5>
          </div>
          <div className="col-md-12">
            <div className="lpr-blocks">
              <div className="lpr-block">
                <div className="lpr-chart-wrapper">
                  <div className="lpr-chart">
                    <div className="lpr-chart-block" style={{ height: calculatePercentage(props.response.processed) }}>
                      <span>{props.response.processed}</span>
                    </div>
                  </div>
                </div>
                <div className="lpr-block-title">Total</div>
              </div>
              <div className="lpr-block">
                <div className="lpr-chart-wrapper lpr-chart-failed">
                  <div className="lpr-chart">
                    <div className="lpr-chart-block" style={{ height: calculatePercentage(props.response.created) }}>
                      <span>{props.response.created}</span>
                    </div>
                  </div>
                </div>
                <div className="lpr-block-title">created</div>
              </div>
              <div className="lpr-block">
                <div className="lpr-chart-wrapper lpr-chart-failed">
                  <div className="lpr-chart">
                    <div className="lpr-chart-block" style={{ height: calculatePercentage(props.response.assigned) }}>
                      <span>{props.response.assigned}</span>
                    </div>
                  </div>
                </div>
                <div className="lpr-block-title">assigned</div>
              </div>
              <div className="lpr-block">
                <div className="lpr-chart-wrapper lpr-chart-failed">
                  <div className="lpr-chart">
                    <div className="lpr-chart-block" style={{ height: calculatePercentage(props.response.skipped) }}>
                      <span>{props.response.skipped}</span>
                    </div>
                  </div>
                </div>
                <div className="lpr-block-title">skipped</div>
              </div>
              <div className="lpr-block">
                <div className="lpr-chart-wrapper lpr-chart-failed">
                  <div className="lpr-chart">
                    <div
                      className="lpr-chart-block"
                      style={{ height: calculatePercentage(props.response.failedRows.length) }}
                    >
                      <span>{props.response.failedRows.length}</span>
                    </div>
                  </div>
                </div>
                <div className="lpr-block-title">Failed</div>
              </div>
            </div>
            <div>
              {hasFailedRows() && (
                <ReactTable
                  data={props.response.failedRows}
                  columns={columns}
                  onSortedChange={pendoTrackDefaultSortingChange}
                  minRows={1}
                  multiSort
                  showPagination
                  nextText=">>"
                  previousText="<<"
                  defaultPageSize={10}
                  noDataText="No Record Found"
                  defaultSorted={[{ id: 'rowNumber' }]}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoadPatientsResult;
