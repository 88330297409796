import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { without } from 'lodash/array';
import { includes } from 'lodash/collection';
import { isArray, isEmpty, isEqual, isUndefined } from 'lodash/lang';

import { FinVendorApi, PatientStatusApi } from '../../../../../../../api';
import Checkbox from '../../../../../../../common/data-entry/InputSelectors/Checkbox';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { ControlledMultiselectList } from '../../../../../../../common/inputs/MultiSelectList/ControlledMultiselectList';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import { INVALID_DATA_LOADED } from '../../../../../../../constants/notificationMessages';
import { toCoins } from '../../../../../../../services/financial';
import { onRequestError } from '../../../../../../../services/handlers';
import { BUDGET_EVENT_NAME_LENGTH } from '../../budgetConstant';
import BudgetEventControlButtons from '../BudgetEventControlButtons';
import { DIRECT_COST, PASS_THRU } from '../ExpenseType';
import { StatusChangeRows } from '../help-component/StatusChangeRows';

import '../BudgetEventFrom.scss';

const maxCount = Number.MAX_SAFE_INTEGER;

const AddOrEditEncounterStatusChangeEventModal = ({
  data,
  isEditingForbidden,
  currentStudyBudget,
  budgetEventType,
  onSave,
  modalBox,
  encounterTriggers,
  managementFeePercent
}) => {
  const {
    name,
    overhead,
    withholding,
    managementFee,
    encounterOverride,
    encounterName,
    encounterId,
    eventRows,
    vendorId,
    patientStatusId,
    groupId,
    nonInvoiceable
  } = data || {};

  const initialVendorId = useMemo(() => vendorId || 'NOT_SELECTED', [vendorId]);

  const emptyRow = useMemo(
    () => ({
      id: null,
      countFrom: 1,
      countTo: maxCount,
      clientAmount: 0,
      siteAmount: 0,
      siteAmountType: PASS_THRU,
      vendorAmount: 0,
      vendorAmountType: null
    }),
    []
  );

  const filterUnexpectedAndSituationalEncounters = useCallback(encounterTriggers => {
    if (isArray(encounterTriggers)) {
      //Currently unexpected and situational encounters are not supported
      encounterTriggers = encounterTriggers.filter(
        trigger => trigger.type !== 'UNEXPECTED_ENCOUNTER' && trigger.type !== 'SITUATIONAL_ENCOUNTER'
      );
      return [...encounterTriggers];
    }
    return [];
  }, []);

  const oldEmptySiteAmountTypeFilling = useCallback(eventRows => {
    eventRows.filter(e => e.siteAmountType === null).forEach(e => (e.siteAmountType = PASS_THRU));
    return [...eventRows];
  }, []);

  const initialState = useMemo(() => {
    return {
      name: name || '',
      encounterId: encounterId || null,
      encounterName: encounterName || null,
      overhead: overhead || false,
      withholding: withholding || false,
      managementFee: isUndefined(managementFee) ? +managementFeePercent !== 0 : managementFee,
      encounterOverride: encounterOverride || false,
      patientStatusId: patientStatusId || 'NOT_SELECTED',
      selectedVendorId: initialVendorId,
      encounterTriggers: filterUnexpectedAndSituationalEncounters(encounterTriggers),
      selectedEncounterTriggers: [],
      groupId: groupId || null,
      eventRows: isArray(eventRows) ? oldEmptySiteAmountTypeFilling(eventRows) : [emptyRow],
      showOverlapsErrorMsg: isArray(eventRows) ? new Array(eventRows.length).fill(false) : [false],
      nonInvoiceable: !!nonInvoiceable
    };
  }, [
    name,
    encounterId,
    encounterName,
    overhead,
    withholding,
    managementFee,
    managementFeePercent,
    encounterOverride,
    patientStatusId,
    initialVendorId,
    filterUnexpectedAndSituationalEncounters,
    encounterTriggers,
    groupId,
    eventRows,
    oldEmptySiteAmountTypeFilling,
    emptyRow,
    nonInvoiceable
  ]);

  const [modalState, setModalState] = useState({
    ...initialState,
    statuses: [],
    finVendors: []
  });

  useEffect(() => {
    PatientStatusApi.getAllStatuses().then(({ data: statuses }) => {
      statuses = statuses.filter(status => status.id !== 'TRANSFERRING');
      statuses.unshift({ id: 'NOT_SELECTED', name: 'Select status' });
      setModalState(prevState => ({ ...prevState, statuses }));
    }, onRequestError);

    FinVendorApi.findAllEnabled().then(res => {
      if (res && isArray(res.data)) {
        res.data.unshift({ id: 'NOT_SELECTED', name: 'Select vendor' });
        setModalState(prevState => ({ ...prevState, finVendors: res.data }));
      } else {
        NotificationManager.error(INVALID_DATA_LOADED);
      }
    }, onRequestError);
  }, []);

  useEffect(() => {
    if (modalState.managementFee) {
      setModalState(prevState => {
        const updatedEventRows = prevState.eventRows.map(budgetEvent => ({
          ...budgetEvent,
          siteAmount: +(budgetEvent.clientAmount - (budgetEvent.clientAmount / 100) * managementFeePercent).toFixed(2)
        }));
        return {
          ...prevState,
          eventRows: !isEqual(updatedEventRows, prevState.eventRows) ? updatedEventRows : prevState.eventRows
        };
      });
    }
  }, [modalState.managementFee, managementFeePercent, modalState.eventRows]);

  const checkOverlappedFields = useCallback(idx => {
    setModalState(prevState => {
      const currentRow = prevState.eventRows[idx];
      let isRowValid = true;
      if (currentRow) {
        const isFromEmpty = isUndefined(currentRow.countFrom);
        const isFromPositive = currentRow.countFrom > 0;
        const isFromLessOrEqualsTo = currentRow.countFrom <= currentRow.countTo;
        const rangesDoNotOverlap = prevState.eventRows
          .filter((range, i) => i !== idx)
          .every(range => range.countTo < currentRow.countFrom || range.countFrom > currentRow.countTo);
        isRowValid = !isFromEmpty && isFromPositive && isFromLessOrEqualsTo && rangesDoNotOverlap;
      }
      return {
        ...prevState,
        showOverlapsErrorMsg: prevState.showOverlapsErrorMsg.map((el, index) => (index === idx ? !isRowValid : el))
      };
    });
  }, []);

  const handleAddEventRow = useCallback(() => {
    const newRows = modalState.eventRows.concat([
      modalState.selectedVendorId === 'NOT_SELECTED'
        ? emptyRow
        : {
            ...emptyRow,
            vendorAmountType: DIRECT_COST
          }
    ]);
    setModalState(prevState => ({
      ...prevState,
      eventRows: newRows,
      showOverlapsErrorMsg: prevState.showOverlapsErrorMsg.concat([false])
    }));
    newRows.forEach((elem, index) => checkOverlappedFields(index));
  }, [checkOverlappedFields, emptyRow, modalState.eventRows, modalState.selectedVendorId]);

  const handleRemoveEventRow = useCallback(
    idx => () => {
      setModalState(prevState => ({
        ...prevState,
        eventRows: prevState.eventRows.filter((row, rowIdx) => idx !== rowIdx),
        showOverlapsErrorMsg: prevState.showOverlapsErrorMsg.filter((item, itemIdx) => idx !== itemIdx)
      }));
      modalState.showOverlapsErrorMsg.forEach((elem, index) => checkOverlappedFields(index));
    },
    [checkOverlappedFields, modalState.showOverlapsErrorMsg]
  );

  const onChangeEventRow = useCallback(
    idx => evt => {
      const targetBaseName = evt.target.name.split('_')[0];
      setModalState(prevState => {
        const newEventRows = prevState.eventRows.map((eventRow, index) => {
          if (index === idx) {
            return {
              ...eventRow,
              [targetBaseName]:
                targetBaseName === 'countTo' && isUndefined(evt.target.value) ? maxCount : evt.target.value
            };
          }
          return eventRow;
        });
        return { ...prevState, eventRows: newEventRows };
      });
      if (targetBaseName === 'countFrom' || targetBaseName === 'countTo') {
        modalState.showOverlapsErrorMsg.forEach((elem, index) => checkOverlappedFields(index));
      }
    },
    [checkOverlappedFields, modalState.showOverlapsErrorMsg]
  );

  const revertOrSetUpVendorRadioButtons = useCallback(() => {
    setModalState(prevState => {
      const { eventRows, selectedVendorId } = prevState;
      if (selectedVendorId !== 'NOT_SELECTED' && eventRows.every(e => !e.vendorAmountType)) {
        const updatedEventRows = eventRows.map(e => ({ ...e, vendorAmountType: DIRECT_COST }));
        return { ...prevState, eventRows: updatedEventRows };
      } else if (selectedVendorId === 'NOT_SELECTED' && eventRows.some(e => !!e.vendorAmountType)) {
        const updatedEventRows = eventRows.map(e => ({ ...e, vendorAmountType: null }));
        return { ...prevState, eventRows: updatedEventRows };
      }
      return prevState;
    });
  }, []);

  const onChangeFormControls = useCallback(
    ({ target }) => {
      if (target.type === 'checkbox') {
        if (target.name === 'managementFee') {
          setModalState(prevState => ({
            ...prevState,
            managementFee: !prevState.managementFee,
            eventRows: prevState.managementFee
              ? prevState.eventRows.map(budgetEvent => ({
                  ...budgetEvent,
                  siteAmount: 0
                }))
              : prevState.eventRows
          }));
        } else {
          setModalState(prevState => ({ ...prevState, [target.name]: target.checked }));
        }
      } else {
        setModalState({
          ...modalState,
          [target.name]: target.value
        });
        revertOrSetUpVendorRadioButtons();
      }
    },
    [modalState, revertOrSetUpVendorRadioButtons]
  );

  const onSelectSidebar = useCallback(option => {
    setModalState(prevState => {
      if (includes(prevState.selectedEncounterTriggers, option)) {
        return {
          ...prevState,
          selectedEncounterTriggers: without(prevState.selectedEncounterTriggers, option)
        };
      }
      return {
        ...prevState,
        selectedEncounterTriggers: [...prevState.selectedEncounterTriggers, option]
      };
    });
  }, []);

  const toggleAllSidebar = useCallback(allSelected => {
    setModalState(prevState => ({
      ...prevState,
      selectedEncounterTriggers: allSelected ? [] : prevState.encounterTriggers
    }));
  }, []);

  const isEditPossible = useCallback(() => {
    return !isUndefined(data);
  }, [data]);

  const save = useCallback(() => {
    const {
      name,
      overhead,
      withholding,
      managementFee,
      encounterOverride,
      selectedVendorId,
      statuses,
      patientStatusId,
      nonInvoiceable
    } = modalState;
    const commonReqFields = {
      name,
      overhead,
      withholding,
      managementFee,
      encounterOverride
    };
    if (selectedVendorId !== 'NOT_SELECTED') {
      commonReqFields.finVendorId = selectedVendorId;
    }
    if (patientStatusId !== 'NOT_SELECTED') {
      const patientStatus = statuses.find(s => s.id === patientStatusId);
      commonReqFields.patientStatusId = patientStatus.id;
      commonReqFields.patientStatusName = patientStatus.name;
    }
    const preparedBudgetRows = [];
    if (!isEditPossible()) {
      modalState.selectedEncounterTriggers.forEach(encounter => {
        modalState.eventRows.forEach(eventRow => {
          preparedBudgetRows.push({
            type: budgetEventType,
            ...commonReqFields,
            nonInvoiceable,
            id: eventRow.id,
            encounterId: encounter.encounterId,
            encounterName: encounter.name,
            clientAmount: toCoins(eventRow.clientAmount),
            siteAmount: toCoins(eventRow.siteAmount),
            vendorAmountType: eventRow.vendorAmountType,
            siteAmountType: eventRow.siteAmountType,
            vendorAmount: selectedVendorId !== 'NOT_SELECTED' ? toCoins(eventRow.vendorAmount) : 0,
            countFrom: eventRow.countFrom,
            countTo: eventRow.countTo === maxCount ? null : eventRow.countTo
          });
        });
      });
    } else {
      modalState.eventRows.forEach(eventRow => {
        preparedBudgetRows.push({
          type: budgetEventType,
          ...commonReqFields,
          nonInvoiceable,
          id: eventRow.id,
          encounterId: modalState.encounterId,
          encounterName: modalState.encounterName,
          clientAmount: toCoins(eventRow.clientAmount),
          siteAmount: toCoins(eventRow.siteAmount),
          vendorAmountType: eventRow.vendorAmountType,
          siteAmountType: eventRow.siteAmountType,
          vendorAmount: selectedVendorId !== 'NOT_SELECTED' ? toCoins(eventRow.vendorAmount) : 0,
          countFrom: eventRow.countFrom,
          countTo: eventRow.countTo === maxCount ? null : eventRow.countTo
        });
      });
    }

    return onSave(preparedBudgetRows, modalState.groupId);
  }, [budgetEventType, isEditPossible, modalState, onSave]);

  const onSaveButton = useCallback(() => {
    save();
    modalBox.close();
  }, [modalBox, save]);

  const onSaveAndContinue = useCallback(() => {
    save().then(() =>
      setModalState(prevState => ({
        ...prevState,
        ...initialState
      }))
    );
  }, [initialState, save]);

  const isValidForm = useCallback(() => {
    const { name, selectedVendorId, patientStatusId, showOverlapsErrorMsg } = modalState;
    //check encounters are selected for create modal
    let isEncounterSelected = !isEditPossible() ? modalState.selectedEncounterTriggers.length > 0 : true;
    let isEventNamePopulated = name && name.trim() !== '';
    let isPatientStatusPopulated = patientStatusId !== 'NOT_SELECTED';

    if (isEncounterSelected && isEventNamePopulated && isPatientStatusPopulated)
      for (const { clientAmount, countFrom, vendorAmount, siteAmount } of modalState.eventRows) {
        let isStatusRangeRowValid =
          clientAmount >= 0 &&
          countFrom > 0 &&
          showOverlapsErrorMsg.every(curState => !curState) &&
          (!siteAmount || siteAmount >= 0) &&
          (selectedVendorId !== 'NOT_SELECTED' ? vendorAmount > 0 : true) &&
          name.length <= BUDGET_EVENT_NAME_LENGTH;
        if (!isStatusRangeRowValid) {
          return false;
        }
      }
    else {
      return false;
    }

    return true;
  }, [isEditPossible, modalState]);

  return (
    <>
      <ModalBoxes.Body>
        <div className="d-flex">
          {!isEditPossible() && isArray(modalState.encounterTriggers) && (
            <div className="add-or-edit-budget-event-sidebar">
              <ControlledMultiselectList
                options={modalState.encounterTriggers}
                label="name"
                height="400px"
                selectedOptions={modalState.selectedEncounterTriggers}
                onChangeSidebarOption={onSelectSidebar}
                toggleAllOptions={toggleAllSidebar}
              />
            </div>
          )}
          <div className="modal-content-layout pt-3 ml-2">
            <div className="form-row form-group">
              {isEditPossible() && (
                <div className="col-6">
                  <label htmlFor="encounter-status-change-event-encounter-name">Encounter Name</label>
                  <input
                    id="encounter-status-change-event-encounter-name"
                    type="text"
                    className="form-control"
                    name="name"
                    value={encounterName}
                    onChange={onChangeFormControls}
                    disabled
                  />
                </div>
              )}
            </div>
            <div className="form-row form-group">
              <div className="col-6">
                <label htmlFor="encounter-status-change-event-name">Event Name</label>
                <input
                  id="encounter-status-change-event-name"
                  type="text"
                  className="form-control reqfeild"
                  name="name"
                  value={modalState.name}
                  disabled={isEditingForbidden}
                  onChange={onChangeFormControls}
                />
                {modalState.name.length > 500 && (
                  <p className="event-name-length-notification">Event Name cannot exceed 500 characters</p>
                )}
              </div>
              <div className="col-6">
                <div>
                  <label htmlFor="encounter-status-change-count-from-status-select">Status</label>
                  <select
                    className="form-control reqfeild"
                    id="encounter-status-change-count-from-status-select"
                    name="patientStatusId"
                    value={modalState.patientStatusId}
                    onChange={onChangeFormControls}
                    disabled={isEditingForbidden}
                  >
                    {modalState.statuses.map(s => (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="form-row form-group">
              <Checkbox.Group className="col-12 mb-2" onChange={onChangeFormControls}>
                <Checkbox
                  id="encounter-status-change-overhead"
                  name="overhead"
                  disabled={isEditingForbidden}
                  checked={modalState.overhead}
                  label="Overhead"
                />
                <Checkbox
                  id="encounter-status-change-withholding"
                  name="withholding"
                  disabled={isEditingForbidden}
                  checked={modalState.withholding}
                  label="Withholding"
                />
                <Checkbox
                  id="encounter-status-change-management-fee"
                  name="managementFee"
                  disabled={isEditingForbidden || +managementFeePercent === 0}
                  checked={modalState.managementFee}
                  label="Management Fee"
                />
                <Checkbox
                  id="encounter-status-change-override"
                  name="encounterOverride"
                  disabled={isEditingForbidden}
                  checked={modalState.encounterOverride}
                  label="Encounter override"
                />
              </Checkbox.Group>
              <Checkbox.Group className="col-12 mb-2" onChange={onChangeFormControls}>
                <Checkbox
                  id="encounter-status-change-non-invoiceable"
                  name="nonInvoiceable"
                  disabled={isEditingForbidden}
                  checked={modalState.nonInvoiceable}
                  label="Non-Invoiceable"
                />
              </Checkbox.Group>
              <div className="col-4">
                <div>
                  <label htmlFor="encounter-status-change-count-from-vendor">Vendor</label>
                  <select
                    className="form-control"
                    id="encounter-status-change-count-from-vendor-select"
                    name="selectedVendorId"
                    value={modalState.selectedVendorId}
                    onChange={onChangeFormControls}
                    disabled={isEditingForbidden}
                    data-testid="vendor-selector"
                  >
                    {modalState.finVendors.map(v => (
                      <option key={v.id} value={v.id}>
                        {v.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div>
              <StatusChangeRows
                isVendorSelected={modalState.selectedVendorId !== 'NOT_SELECTED'}
                eventRows={modalState.eventRows}
                maxCount={maxCount}
                isEditingForbidden={isEditingForbidden}
                onChangeEventRow={onChangeEventRow}
                handleAddEventRow={handleAddEventRow}
                handleRemoveEventRow={handleRemoveEventRow}
                showOverlapsErrorMsg={modalState.showOverlapsErrorMsg}
                managementFee={modalState.managementFee}
              />
            </div>
          </div>
        </div>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <BudgetEventControlButtons
          className="text-right py-3 mr-3"
          isValidForm={isValidForm}
          onClose={modalBox.close}
          onSave={onSaveButton}
          currentStudyBudget={currentStudyBudget}
          onSaveAndContinue={isEmpty(data) ? onSaveAndContinue : null}
        />
      </ModalBoxes.Footer>
    </>
  );
};

AddOrEditEncounterStatusChangeEventModal.title = 'Add or Edit Encounter Status Change budget event';
AddOrEditEncounterStatusChangeEventModal.className = 'add-encounter-status-change-modal';
AddOrEditEncounterStatusChangeEventModal.size = 'w950';

export default AddOrEditEncounterStatusChangeEventModal;
