import React from 'react';
import moment from 'moment';

export default function StudyEnrollmentPreviousPredictionValueList({
  enrollmentPredictionValues,
  currentMonth,
  ssuDateEnd
}) {
  const previousEnrollments = enrollmentPredictionValues
    .filter(epv => {
      const epvDate = moment()
        .set({ year: epv.year, month: epv.month })
        .startOf('month');
      return epvDate.isSameOrAfter(currentMonth);
    })
    .map((epm, index) => (
      <div className="col-sm-2 col-md-2 col-lg-1 prediction-cont-1  prediction-input-width" key={index}>
        <label>
          {moment()
            .set({ year: epm.year, month: epm.month })
            .format('MMM/YYYY')}
        </label>
        <div className="enrollment-font-size">{epm.value}</div>
      </div>
    ));

  return (
    <div className="col-12">
      <div className="row">
        <div className="col-2 previous-container">
          <span className="enrollment-font-size"> Previous: </span>
        </div>
        <div className="col-10">
          <div className="row predictions-all-items-1">{previousEnrollments}</div>
        </div>
      </div>
    </div>
  );
}
