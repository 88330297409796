import React, { useContext, useEffect, useMemo, useState } from 'react';
import ReactTable from 'react-table';
import cx from 'classnames';
import { debounce } from 'lodash/function';
import { isEmpty, isEqual } from 'lodash/lang';
import moment from 'moment';

import { TaskApi, UserApiApi } from '../../../../api';
import TableIconButton from '../../../../common/buttons/TableIconButton/TableIconButton';
import MultiSelect from '../../../../common/data-entry/MultiSelect/MultiSelect';
import Button from '../../../../common/general/Button';
import Icon from '../../../../common/general/Icon';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../constants/dateFormat';
import { EXPORT_TASKS } from '../../../../constants/userOperations';
import { pendoTrackDefaultSortingChange } from '../../../../pendo/PendoUtils';
import { userHasAccessTo } from '../../../../services/auth';
import { onFileSave } from '../../../../services/handlers';
import { useActiveTask, useActiveTaskUpdate } from '../../../../store/activeTask';
import ApplyAndResetButtons from '../../../ApplyAndResetButtons/ApplyAndResetButtons';
import { CellFormattedDate } from '../../../CellFormattedDate/CellFormattedDate';
import { PageInfoHeader } from '../../../PageInfoHeader/PageInfoHeader';
import {
  OTHER,
  TasksStatuses,
  TaskTopics,
  TaskTopicsMap
} from '../../../root/Container/Layout/Tasks/Task/taskConstants';
import TasksSupervisor from '../../../root/Container/Layout/Tasks/TasksSupervisor/TasksSupervisor';

import TaskAssigneeMultiSelect from './TaskAssigneeMultiSelect';
import TaskSsuMultiSelect from './TaskSsuMultiSelect';
import TaskStatusChangePortal from './TaskStatusChangePortal';
import { TaskWorklistContext, withTasksWorklistProvider } from './TaskWorklistContext';
import { getIds, prepareAssignees, toRequestFilters } from './TaskWorklistService';

import './TaskWorklist.scss';

const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_FILTERS = {
  page: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  totalPages: 0,
  assigneeIds: null,
  ssuIds: null,
  studies: null,
  sites: null,
  taskStatuses: TasksStatuses,
  taskTopics: TaskTopics,
  sortedBy: [{ id: 'dueDate', desc: false }]
};

const TaskWorklist = () => {
  const updateActiveTask = useActiveTaskUpdate();
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [tasks, setTasks] = useState([]);
  const [ssuIdsForAssignees, setSsuIdsForAssignees] = useState([]);
  const Tasks = useContext(TaskWorklistContext);
  const activeTask = useActiveTask();
  const [filters, setFilters] = useState(Tasks.worklistFilters || DEFAULT_FILTERS);
  const [taskForStatusChange, setTaskForStatusChange] = useState({});
  const [allowRowSelect, setAllowRowSelect] = useState(true);

  const [searchValue, setSearchValue] = useState('');

  const onSearchChange = useMemo(function() {
    return debounce(function(searchString) {
      setFilters(function(state) {
        return { ...state, searchString };
      });
    }, 500);
  }, []);

  useEffect(() => {
    if (!isEmpty(Tasks.ssus) && !isEmpty(Tasks.studies) && !isEmpty(Tasks.sites)) {
      if (Tasks.worklistFilters) {
        let canceled = false;
        TaskApi.getAllTasksByFilter(
          toRequestFilters(Tasks.worklistFilters),
          !isEmpty(Tasks.worklistFilters.searchString) // disable the loading widget if using searchString
        ).then(({ data: tasks }) => {
          if (!canceled) {
            setTasks(tasks.content);
            setTotalPages(tasks.totalPages);
            setTotalElements(tasks.totalElements);
          }
        });
        return function() {
          canceled = true;
        };
      } else {
        resetFilters(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Tasks.worklistFilters, Tasks.ssus, Tasks.studies, Tasks.sites, activeTask.updateTimestamp]);

  useEffect(() => {
    onSearchChange(searchValue);
  }, [onSearchChange, searchValue]);

  useEffect(() => {
    if (Tasks.worklistFilters) {
      !isEqual(Tasks.worklistFilters, filters) && setFilters(Tasks.worklistFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Tasks.worklistFilters]);

  const applyFilter = () => {
    Tasks.setWorklistFilters({ ...filters });
  };

  const onChangeStudySiteFilter = (studies, sites, studySites) => {
    let newFilters = { ...filters, studies, sites };
    if (isEmpty(studies) || isEmpty(sites)) {
      newFilters = { ...newFilters, assigneeIds: null };
    }
    const ssuIds = getIds(studySites);
    if (!isEqual(ssuIds, filters.ssuIds)) {
      newFilters = { ...newFilters, ssuIds };
    }
    setFilters(newFilters);
  };

  const resetFilters = function(withoutPageReset = false) {
    const ssuIds = getIds(Tasks.ssus);
    !isEmpty(ssuIds) &&
      UserApiApi.getPersonnelBySsu(ssuIds).then(res => {
        const newFilters = {
          ...DEFAULT_FILTERS,
          assigneeIds: getIds(prepareAssignees(res.data)),
          ssuIds,
          studies: Tasks.studies,
          sites: Tasks.sites,
          pageSize: withoutPageReset ? Tasks?.worklistFilters?.pageSize : 25
        };
        setFilters(newFilters);
        Tasks.setWorklistFilters(newFilters);
      });
  };

  useEffect(
    function() {
      if (!isEmpty(filters.studies) && !isEmpty(filters.sites)) {
        !isEqual(ssuIdsForAssignees, filters.ssuIds) && setSsuIdsForAssignees(filters.ssuIds);
      } else {
        !isEqual(ssuIdsForAssignees, []) && setSsuIdsForAssignees([]);
      }
    },
    [filters.studies, filters.sites, Tasks.ssus, ssuIdsForAssignees, filters.ssuIds]
  );

  useEffect(() => {
    if (!isEmpty(filters.searchString) && !filters.searchString.includes('%') && !isEmpty(Tasks.ssus)) {
      Tasks.setWorklistFilters(filters);
      applyFilter();
    } else {
      resetFilters(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.searchString]);

  const taskEditingEnabled = e => {
    return (allowRowSelect && e.target.nodeName !== 'BUTTON') || (!allowRowSelect && isEmpty(taskForStatusChange));
  };

  const resetTaskForStatusChange = item => {
    setTaskForStatusChange({});
    delete item.allowStatusEdit;
    delete item.selectedStatus;
    setAllowRowSelect(true);
  };

  const editStatus = item => {
    setTaskForStatusChange(item);
    tasks.forEach(task => (task.id !== item.id ? (task.allowStatusEdit = false) : (task.allowStatusEdit = true)));
    setAllowRowSelect(false);
  };

  const editStatusSave = item => {
    if (item.selectedStatus) {
      updateStatus(item);
    } else {
      resetTaskForStatusChange(item);
    }
  };

  const onChangeStatus = (option, item) => {
    item.selectedStatus = option;
  };

  const updateStatus = item => {
    item.status = item.selectedStatus.code;
    delete item.selectedStatus;
    delete item.allowStatusEdit;
    const task = tasks.find(task => task.id === item.id);
    task.status = item.status;
    task.dueDate = convertedDate(task.dueDate);
    TaskApi.update(task).then(({ data: updatedTask }) => {
      NotificationManager.success(`Task ${updatedTask?.sequenceNumber} updated`);
      updateActiveTask({ status: updatedTask.status });
    });
    resetTaskForStatusChange(item);
  };

  const convertedDate = date => moment(date).format(DD_SLASH_MMM_SLASH_YYYY);

  const topicContents = row => {
    const topic = TaskTopicsMap[row.original.topic];
    if (topic.id === OTHER) {
      return `${topic.name}: ${row.original.topicTitle}`;
    }
    return topic.name;
  };

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      id: 'task.sequence_number',
      width: 100,
      Cell: row => {
        return resolveCellValue(row.original.sequenceNumber);
      }
    },
    {
      Header: 'Subject ID',
      accessor: 'subjectId',
      id: 'subjectId',
      width: 150,
      Cell: row => {
        return resolveCellValue(row.original.subjectId || 'N/A');
      }
    },
    {
      Header: 'Topic',
      accessor: 'topic',
      id: 'task.topic',
      minWidth: 150,
      Cell: row => {
        return resolveCellValue(topicContents(row));
      }
    },
    {
      Header: 'Study',
      accessor: 'study',
      id: 'study.name',
      minWidth: 150,
      Cell: row => {
        return resolveCellValue(row.original.studyName);
      }
    },
    {
      Header: 'Site',
      accessor: 'site',
      id: 'site.name',
      minWidth: 150,
      Cell: row => {
        return resolveCellValue(row.original.siteName);
      }
    },
    {
      Header: 'Assignee',
      accessor: 'assignee',
      id: 'assignee_quantity_type',
      minWidth: 150,
      Cell: row => {
        return resolveCellValue(row.original.assignee);
      }
    },
    {
      Header: 'Due Date',
      accessor: 'task.due_date',
      className: 'summary-cell',
      minWidth: 150,
      Cell: row => (
        <CellFormattedDate
          className="rt-td"
          date={row.original.dueDate}
          format={DD_SLASH_MMM_SLASH_YYYY}
          defaultValue={'--'}
        />
      )
    },
    {
      Header: 'Status',
      accessor: 'status',
      id: 'task.status',
      minWidth: 200,
      className: 'status-edit-cell',
      Cell: row => {
        return (
          <React.Fragment>
            <TaskStatusChangePortal row={row} taskStatuses={taskStatuses} onChangeStatus={onChangeStatus} />
            {!row.original.allowStatusEdit && (
              <TableIconButton
                suit="glyphicon"
                color="blue"
                title="Edit task status"
                onClick={() => editStatus(row.original)}
              >
                edit
              </TableIconButton>
            )}
            {row.original.allowStatusEdit && (
              <div style={{ minWidth: '100px' }}>
                <TableIconButton
                  suit="material"
                  color="blue"
                  title="Save status"
                  onClick={() => editStatusSave(row.original)}
                >
                  save
                </TableIconButton>
                <TableIconButton
                  suit="material"
                  color="blue"
                  title="Cancel edit"
                  onClick={() => resetTaskForStatusChange(row.original)}
                >
                  clear
                </TableIconButton>
              </div>
            )}
          </React.Fragment>
        );
      }
    }
  ];

  return (
    <div className="mb-3">
      <PageInfoHeader
        right={
          userHasAccessTo(EXPORT_TASKS) && (
            <Button
              size="h28"
              onClick={() => {
                TaskApi.exportTasks(toRequestFilters(Tasks.worklistFilters)).then(onFileSave);
              }}
              priority="medium"
            >
              Export
            </Button>
          )
        }
      >
        <div className="general-header-wrapper">
          <MultiSelect
            label="Status"
            clearSearchOnSelection
            dataSource={[...TasksStatuses]}
            onChange={taskStatuses => setFilters({ ...filters, taskStatuses })}
            searchable
            value={filters.taskStatuses}
            validate={false}
          />
          <MultiSelect
            label="Topic"
            clearSearchOnSelection
            dataSource={[...TaskTopics]}
            onChange={taskTopics => setFilters({ ...filters, taskTopics })}
            searchable
            value={filters.taskTopics}
            validate={false}
          />
          <TaskSsuMultiSelect
            ssuIds={filters.ssuIds}
            initialStudies={filters.studies}
            initialSites={filters.sites}
            onChange={onChangeStudySiteFilter}
          />
          <TaskAssigneeMultiSelect
            disabled={isEmpty(filters.studies) || isEmpty(filters.sites)}
            studySiteIds={ssuIdsForAssignees}
            onChangeAssignees={assignees => setFilters({ ...filters, assigneeIds: getIds(assignees) })}
            initialAssignees={filters.assigneeIds}
          />
          <ApplyAndResetButtons
            applyButtonId="task-worklist-filter-apply-btn"
            resetButtonId="task-worklist-filter-reset-btn"
            applyClassName="apply-button"
            onApply={applyFilter}
            resetClassName="pi-worklist-refresh-btn"
            onReset={() => resetFilters(false)}
          />
        </div>
      </PageInfoHeader>
      <div className={'search-field-container p-4'}>
        <div style={{ position: 'relative' }}>
          <Icon className={'task-worklist-search-icon'}>search</Icon>
          <input
            className={'task-worklist-search-field'}
            placeholder={'Search Subject ID or Task ID'}
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
          />
        </div>
      </div>
      <section>
        <div className="border p-3 my-2">
          <ReactTable
            onPageSizeChange={pageSize => {
              let newFilters = { ...filters, pageSize };
              if (filters.page * pageSize > totalElements) {
                newFilters = { ...newFilters, page: 0 };
              }
              setFilters(newFilters);
              Tasks.setWorklistFilters(newFilters);
            }}
            onPageChange={page => {
              const newFilters = { ...filters, page: page >= 0 ? page : 0 };
              setFilters(newFilters);
              Tasks.setWorklistFilters(newFilters);
            }}
            onSortedChange={sortedBy => {
              const newFilters = { ...filters, sortedBy };
              pendoTrackDefaultSortingChange(sortedBy);
              setFilters(newFilters);
              Tasks.setWorklistFilters(newFilters);
            }}
            data={tasks}
            columns={columns}
            minRows={1}
            multiSort={false}
            showPagination
            nextText=">>"
            manual
            pages={totalPages}
            previousText="<<"
            noDataText="No Record Found"
            className="task-worklist-table"
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={filters.pageSize}
            page={filters.page}
            sorted={filters.sortedBy}
            getTrProps={(state, row = {}) => ({
              onClick: e => {
                if (taskEditingEnabled(e)) {
                  TasksSupervisor.open(row.original.id, row.original.studySiteId);
                }
              },
              className: cx({ highlight: row?.original?.id === activeTask.taskId })
            })}
          />
        </div>
      </section>
    </div>
  );
};

const resolveCellValue = value => (value ? <span> {value}</span> : '--');

const taskStatuses = [
  {
    name: 'Open',
    code: 'OPEN'
  },
  {
    name: 'Pending',
    code: 'PENDING'
  },
  {
    name: 'Complete',
    code: 'COMPLETE'
  },
  {
    name: 'Canceled',
    code: 'CANCELED'
  }
];

export default withTasksWorklistProvider(TaskWorklist);
