export const generateFetchTypes = action => {
  const fetchTypes = ['REQUEST', 'SUCCESS', 'FAILURE'];
  return fetchTypes.map(type => {
    return `${action}_${type}`;
  });
};

export const REQUEST = 0;
export const SUCCESS = 1;
export const FAILURE = 2;

export const generateFilterTypes = action => {
  const filterTypes = ['RESET', 'SET'];
  return filterTypes.map(type => `${action}_${type}`);
};

export const RESET = 0;
export const SET = 1;
