import axios from 'axios';

const ROOT = 'api/v1/ssu-startup';

const StudySiteStartupApi = {
  getAllStartupCodes() {
    return axios.get(`${ROOT}/startup-codes`);
  },
  getAllCodesAndDates() {
    return axios.get(`${ROOT}/startup-codes-and-dates`);
  },
  getAllMilestonesForSsu(ssuId) {
    return axios.get(`${ROOT}/milestones?id=${ssuId}`);
  },
  getAllSsusAndMilestonesAndDatesFiltered(ssuIds) {
    return axios.post(`${ROOT}/filtered-milestones-and-dates`, ssuIds);
  },
  getMilestoneForSSu(ssuIds) {
    return axios.post(`${ROOT}/study-site-milestones`, ssuIds);
  },
  saveMilestones(data) {
    return axios.post(`${ROOT}/save-milestones`, data);
  },
  exportMilestones(ssuIds) {
    return axios.post(`${ROOT}/milestones/download`, ssuIds, {
      responseType: 'arraybuffer'
    });
  },
  getCycleTimesForAllAvailableSsus() {
    return axios.get(`${ROOT}/cycle-times/all-ssus`);
  },
  exportCycleTimes(ssuList) {
    return axios.post(`${ROOT}/cycle-times/download`, ssuList, {
      responseType: 'arraybuffer'
    });
  }
};

export default StudySiteStartupApi;
