import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';

import './StyledToolTip.scss';

const StyledTooltip = ({ className, children, ...rest }) => (
  <Tooltip overlayClassName={cx(className, 'eds-styled-tooltip')} overlayStyle={{ opacity: 1 }} {...rest}>
    {children}
  </Tooltip>
);

StyledTooltip.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired
};

export default StyledTooltip;
