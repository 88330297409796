import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash/lang';

import Divider from '../../../../../../common/elements/Divider/Divider';
import { generateUrlByKey, useCurrentRoute } from '../../../../../root/router';
import { PatientInfoContext } from '../PatientInfoContext';

import { COMPLETED_ICF_TITLE } from './constants';
import { FilesSection } from './FilesSection';

import './ICFSection.scss';

export const ICFSection = () => {
  const currentRoute = useCurrentRoute();
  const { forms } = useContext(PatientInfoContext);
  return !isEmpty(forms.newIcf) ? (
    <div className={'icf-container'}>
      <div className={'new-icf-container'}>
        <div className={'new-message-container'}>
          <Divider type={'vertical'} className={'icf-divider'} />
          <h2>NEW</h2>
        </div>
        <FilesSection forms={forms.newIcf} />
      </div>
      <div className={'completed-icf'}>
        {!isEmpty(forms.completedIcf) ? (
          <Link
            className={'completed-icf-link'}
            to={generateUrlByKey(`${currentRoute?.key}.Completed Informed Consents`, {
              patientId: currentRoute.params.patientId,
              ssuPatientId: currentRoute.params.ssuPatientId
            })}
          >
            {COMPLETED_ICF_TITLE}
          </Link>
        ) : (
          <span className={'disabled-icf-link'}>{COMPLETED_ICF_TITLE}</span>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};
