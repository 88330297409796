import { handleActions } from 'redux-actions';

import { options, UPDATE } from './actions';

const defaultState = {};
export default handleActions(
  {
    [UPDATE]: function(state, { payload }) {
      return { ...state, ...payload };
    }
  },
  defaultState,
  options
);
