import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';

import Select from '../../../../../common/data-entry/Select';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';

import './LeadModal.scss';

export default function LeadModal({ modalBox, leadLabel, leadList, currentLead, handleLeadChange, saveLead }) {
  const [selectedValue, setSelectedValue] = useState(currentLead);

  useEffect(() => {
    handleLeadChange(selectedValue);
  }, [handleLeadChange, selectedValue]);

  const onSave = () => {
    saveLead();
    modalBox.close();
  };

  return (
    <>
      <ModalBoxes.Body>
        <Select
          label={leadLabel}
          clearSearchOnSelection
          dataSource={leadList}
          optionLabelKey="name"
          optionValueKey="uniqueIdentifier"
          onChange={setSelectedValue}
          value={selectedValue}
          searchable
          clearable={false}
        />
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            Cancel
          </Button>
          <Button onClick={onSave} disabled={isEqual(selectedValue, currentLead)}>
            Save
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
}

LeadModal.className = 'lead-modal';
LeadModal.size = 'w350';
