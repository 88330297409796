import { VALIDATION_FAILED } from './validationService/validationService';
import informAboutRequiredFields from './informAboutRequiredFields';

export default function handleExceptions(e, info, setValidationMessages, setInfo) {
  if (e.status === VALIDATION_FAILED) {
    setValidationMessages(e.validationMessages);
    informAboutRequiredFields(e.validationMessages, info);
    return;
  }
  throw e;
}
