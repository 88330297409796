import React, { useCallback, useContext, useEffect, useState } from 'react';

import { PatientEncounterReviewApi } from '../../../../../../../../api';
import Common from '../../../../../../../../common/common';
import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import LogsTable from '../../../../../Patients/Logs/LogsTable';
import { CustomCell, defaultCellWidth } from '../../../../../Patients/Logs/LogsTableService';
import { ReviewContext } from '../../../ReviewContext';
import ElementHistoryLogModal from '../../HistoryLogModal/ElementHistoryLogModal';

export default function CommentItemGroup({ itemGroupSnapshotState }) {
  const { reviewMetaData, isHistoricalData, ssuPatientId } = useContext(ReviewContext);
  const encounterName = reviewMetaData?.encounterName;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const prepareColumns = useCallback(prepareColumnsCallback, [encounterName, isHistoricalData, itemGroupSnapshotState]);
  const prepareComments = useCallback(prepareCommentsCallback, [itemGroupSnapshotState]);
  const [columns, setColumns] = useState([]);
  const [comments, setComments] = useState([]);

  useEffect(
    function() {
      setColumns(prepareColumns);
    },
    [prepareColumns]
  );

  useEffect(
    function() {
      setComments(prepareComments);
    },
    [prepareComments]
  );

  return (
    <LogsTable
      columns={columns}
      data={comments}
      sortable={false}
      showPagination={false}
      pageSize={comments?.length || 1}
      defaultCellWidth={defaultCellWidth}
    />
  );

  function prepareCommentsCallback() {
    return itemGroupSnapshotState?.rows.map(({ rowSnapshotFormData }) => {
      const { answers, ...rest } = rowSnapshotFormData.form;
      return {
        ...rest,
        ...answers[0],
        rowId: rowSnapshotFormData.rowId
      };
    });
  }

  function getCommentHistory(row) {
    const ssuPatientEncounterId = itemGroupSnapshotState.itemGroupRef.patientEncounterId;
    const itemGroupId = itemGroupSnapshotState.itemGroupRef.patientItemGroupId;
    const rowId = row.original.rowId;

    const itemGroupName = itemGroupSnapshotState.itemGroupRef.patientItemGroupName;
    const onLoadData = () =>
      PatientEncounterReviewApi.getItemGroupAnswerHistory(ssuPatientId, ssuPatientEncounterId, itemGroupId, rowId);
    ModalBoxes.open({
      component: <ElementHistoryLogModal itemGroupName={itemGroupName} onLoadData={onLoadData} />,
      title: itemGroupName
    });
  }

  function prepareColumnsCallback() {
    return [
      {
        Header: 'Encounter',
        minWidth: 120,
        className: 'text-left',
        Cell: () => <CustomCell shortAnswer={encounterName} longAnswer={encounterName} />
      },
      {
        Header: 'Comment',
        accessor: 'attributeValue',
        className: 'text-left',
        Cell: row => (
          <CustomCell
            shortAnswer={row.original.stringValue}
            longAnswer={row.original.stringValue}
            className={'text-left'}
          />
        )
      },
      {
        Header: 'When Was It Done',
        accessor: 'whenWasItDone',
        Cell: ({ value }) => Common.formatDate(value)
      },
      {
        Header: 'Who Did It',
        accessor: 'whoDidItName',
        className: 'text-left pl-2',
        Cell: ({ value }) => value || '--'
      },
      {
        Header: 'History',
        width: 75,
        className: 'brand-link text-left pl-2',
        getProps: (state, rowInfo) => ({
          onClick: () => getCommentHistory(rowInfo)
        }),
        Cell: () => (!isHistoricalData ? 'History' : '')
      }
    ];
  }
}
