import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash/lang';
import moment from 'moment';

import SitePaymentsApi from '../../../../api/finance/SitePaymentsApi';
import useSessionStorage from '../../../../common/hooks/useSessionStorage';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import { SOMETHING_WENT_WRONG } from '../../../../constants/notificationMessages';
import { onFileSave } from '../../../../services/handlers';

import { OPEN, PAID, PENDING } from './SitePaymentsTable/SitePaymentsConstants';

export const SitePaymentsContext = React.createContext(null);

export function SitePaymentsProvider({ children }) {
  const initialStartDate = useMemo(
    () => moment('2019-01-01').set({ hour: 0, minute: 0, second: 0, millisecond: 1 }),
    []
  );
  const initialEndDate = useMemo(() => moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 }), []);
  const emptyFilters = useMemo(
    () => ({
      site: null,
      study: null,
      pcn: null,
      startDate: initialStartDate,
      endDate: initialEndDate
    }),
    [initialEndDate, initialStartDate]
  );

  const [savedFilters, setSavedFilters] = useSessionStorage('SITE_PAYMENTS_FILTERS', {});
  const initialFilters = isEmpty(savedFilters) ? emptyFilters : savedFilters;

  const [tableData, setTableData] = useState([]);
  const [checkedEvents, setCheckedEvents] = useState([]);
  const [site, setSite] = useState(initialFilters.site);
  const [study, setStudy] = useState(initialFilters.study);
  const [pcn, setPcn] = useState(initialFilters.pcn);
  const [startDate, setStartDate] = useState(moment(initialFilters.startDate));
  const [endDate, setEndDate] = useState(moment(initialFilters.endDate));
  const [statuses, setStatuses] = useState([OPEN, PENDING, PAID]);

  const filtersAssembler = useCallback(() => {
    return {
      studyName: study?.studyName,
      siteName: site?.siteName,
      studyId: study?.uniqueIdentifier,
      siteId: site?.uniqueIdentifier,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      statuses: statuses,
      projectCode: pcn
    };
  }, [
    study?.studyName,
    study?.uniqueIdentifier,
    site?.siteName,
    site?.uniqueIdentifier,
    startDate,
    endDate,
    statuses,
    pcn
  ]);

  const applyFilter = useCallback(() => {
    const filters = filtersAssembler();
    SitePaymentsApi.getSitePayments(filters).then(({ data }) => {
      setTableData(data);
      setSavedFilters({ site, study, pcn, startDate, endDate });
    });
  }, [endDate, filtersAssembler, pcn, setSavedFilters, site, startDate, study]);

  const resetFilters = useCallback(() => {
    setSite(null);
    setStudy(null);
    setPcn(null);
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
    setSavedFilters(emptyFilters);
  }, [initialStartDate, initialEndDate, setSavedFilters, emptyFilters]);

  const onResetToOpen = useCallback(
    itemSiteIds => {
      SitePaymentsApi.resetToOpen(itemSiteIds)
        .then(() => {
          NotificationManager.success('Event(s) successfully reset to Open status');
          applyFilter();
        })
        .catch(() => {
          NotificationManager.error('Event(s) unable to be reset to Open status, please try again');
        });
    },
    [applyFilter]
  );

  const onApprove = useCallback(
    itemSiteIds => {
      SitePaymentsApi.setAsApproved(itemSiteIds)
        .then(() => {
          NotificationManager.success('Event(s) successfully Approved');
          applyFilter();
        })
        .catch(() => {
          NotificationManager.error('Event(s) unable to be Approved, please try again');
        });
    },
    [applyFilter]
  );

  const exportNS = useCallback(
    selectedEvents => {
      const siteItems = selectedEvents.map(item => ({
        sitePaymentBillNumber: item.sitePaymentBillNumber,
        itemSiteId: item.itemSiteId
      }));

      SitePaymentsApi.exportNS(siteItems)
        .then(onFileSave)
        .then(applyFilter)
        .catch(err => {
          if (err?.response?.status === 400) {
            const decodedString = String.fromCharCode.apply(null, new Uint8Array(err.response.data));
            const parsedError = JSON.parse(decodedString);
            NotificationManager.error(parsedError.message);
          } else {
            NotificationManager.error(SOMETHING_WENT_WRONG);
          }
        });
    },
    [applyFilter]
  );

  const updateBillNumberInTable = useCallback((billNumbers, itemSiteId) => {
    setTableData(prevData =>
      prevData.map(item =>
        item.itemSiteId === itemSiteId
          ? {
              ...item,
              sitePaymentBillNumber: billNumbers.sitePaymentBillNumber,
              siteBillNumber: billNumbers.siteBillNumber,
              siteBillDate: billNumbers.siteBillDate
            }
          : item
      )
    );
  }, []);

  useEffect(() => {
    if (!isEmpty(savedFilters)) {
      applyFilter();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <SitePaymentsContext.Provider
      value={{
        setSite,
        setStudy,
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        tableData,
        checkedEvents,
        setCheckedEvents,
        applyFilter,
        resetFilters,
        study,
        site,
        pcn,
        setPcn,
        statuses,
        setStatuses,
        onResetToOpen,
        onApprove,
        exportNS,
        updateBillNumberInTable
      }}
    >
      {children}
    </SitePaymentsContext.Provider>
  );
}

export function withSitePaymentsContext(Component) {
  return function WrapperComponent(props) {
    return (
      <SitePaymentsProvider>
        <Component {...props} />
      </SitePaymentsProvider>
    );
  };
}
