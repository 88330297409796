import React, { useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash/lang';
import { get } from 'lodash/object';
import uuid from 'uuid/v4';

import { FormBuilderApi } from '../../../../../../../api';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Icon from '../../../../../../../common/general/Icon';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import { TableSetupContext } from '../../../../../../../contexts/TableSetupContext';
import { MOCKED_COMMENT_FIELD } from '../TableSetupConstants';

import TablePreviewModal from './TablePleviewModal/TablePreviewModal';

import './TablePreview.scss';

const TablePreview = () => {
  const tableSetupContext = useContext(TableSetupContext);
  const generalFieldsWithId = useMemo(() => {
    return tableSetupContext.generalFields.map(field => {
      return {
        ...field,
        protocolItemDefinitionId: uuid()
      };
    });
  }, [tableSetupContext.generalFields]);

  const fieldListWithId = useMemo(() => {
    return tableSetupContext.fieldList.map(field => ({ ...field, protocolItemDefinitionId: uuid() }));
  }, [tableSetupContext.fieldList]);

  const [standardConditionalLogic, setStandardConditionalLogic] = useState({});

  useEffect(() => {
    FormBuilderApi.getStandardConditionalLogicForDomain(tableSetupContext.selectedDomain.domainIdentifier)
      .then(({ data }) => {
        setStandardConditionalLogic(data);
      })
      .catch(error => {
        NotificationManager.error(error);
      });
  }, [tableSetupContext.selectedDomain.domainIdentifier]);

  return (
    <div className="preview-wrapper">
      <h5 className="preview-header">Item Setup</h5>
      <div className="preview-button-wrapper" onClick={openNewTablePreview}>
        <Icon className="add-btn-preview">add</Icon>
        <span className="">ItemGroup Preview</span>
      </div>
    </div>
  );

  function generateStandardConfiguration() {
    let standardConfiguration = {};
    for (let conditional in standardConditionalLogic) {
      const sourceIdentifier = getIdByName([...generalFieldsWithId, ...fieldListWithId], conditional);
      const targetIdentifiersMapList = standardConditionalLogic[conditional].fieldsToHide.map(fieldName =>
        getIdByName([...generalFieldsWithId, ...fieldListWithId], fieldName)
      );
      const showAnswer = standardConditionalLogic[conditional].hideWhenAnswer === 'Y' ? 'N' : 'Y';
      standardConfiguration = {
        ...standardConfiguration,
        [sourceIdentifier]: {
          required: true,
          sourceIdentifier,
          targetIdentifiersMap: { [showAnswer]: targetIdentifiersMapList }
        }
      };
    }
    return standardConfiguration;
  }

  function generateConditionalLogicFromSetup() {
    const allFields = [...generalFieldsWithId, ...fieldListWithId];
    let conditionalLogicAndRequiredFieldsValidation = generateStandardConfiguration();
    allFields.forEach(field => {
      if (!isEmpty(field.fieldConfigurationResponse)) {
        field.fieldConfigurationResponse.forEach(configuration => {
          const conditionField = allFields.filter(field => field.name === configuration.conditionFieldName);
          const selectedAnswer = selectAnswer(configuration.conditionFieldAnswerName);
          if (
            !isEmpty(conditionalLogicAndRequiredFieldsValidation) &&
            get(conditionalLogicAndRequiredFieldsValidation, `${conditionField[0].protocolItemDefinitionId}`)
          ) {
            for (let conditional in conditionalLogicAndRequiredFieldsValidation) {
              if (conditional === conditionField[0].protocolItemDefinitionId) {
                const updatedSelectedAnswer = get(conditionalLogicAndRequiredFieldsValidation, [
                  `${conditionField[0].protocolItemDefinitionId}`,
                  'targetIdentifiersMap',
                  `${selectedAnswer}`
                ])
                  ? [
                      ...conditionalLogicAndRequiredFieldsValidation[conditionField[0].protocolItemDefinitionId]
                        .targetIdentifiersMap[selectedAnswer],
                      field.protocolItemDefinitionId
                    ]
                  : [field.protocolItemDefinitionId];

                conditionalLogicAndRequiredFieldsValidation = {
                  ...conditionalLogicAndRequiredFieldsValidation,
                  [conditionField[0].protocolItemDefinitionId]: {
                    ...conditionalLogicAndRequiredFieldsValidation[conditionField[0].protocolItemDefinitionId],
                    targetIdentifiersMap: {
                      ...conditionalLogicAndRequiredFieldsValidation[conditionField[0].protocolItemDefinitionId]
                        .targetIdentifiersMap,
                      [selectedAnswer]: updatedSelectedAnswer
                    }
                  }
                };
              }
            }
          } else {
            conditionalLogicAndRequiredFieldsValidation = {
              ...conditionalLogicAndRequiredFieldsValidation,
              [conditionField[0].protocolItemDefinitionId]: {
                required: false,
                sourceIdentifier: conditionField[0].protocolItemDefinitionId,
                targetIdentifiersMap: {
                  [selectedAnswer]: [field.protocolItemDefinitionId]
                }
              }
            };
          }
        });
      }
    });
    return conditionalLogicAndRequiredFieldsValidation;
  }

  function openNewTablePreview() {
    const generalSnapshotAnswers = prepareFieldsToPreview(generalFieldsWithId).map(field => ({
      ...field,
      protocolTableGeneralFieldId: uuid()
    }));

    const tableFieldAnswers = prepareFieldsToPreview(fieldListWithId).map(field => ({
      ...field,
      linkedRowId: null,
      term: null
    }));

    const tableItemGroupRows = tableSetupContext.labelList.map(label => ({
      rowId: null,
      label: {
        labelId: label?.labelId || label.id,
        name: label.name,
        sequence: label.sequence
      },
      answers: tableFieldAnswers.map(answer => ({ ...answer }))
    }));

    const encounterTableItemGroupForPreview = {
      layout: tableSetupContext.questionLayout,
      itemGroupName: tableSetupContext.itemGroupName,
      title: tableSetupContext.tableTitle,
      generalSnapshotAnswers: [
        ...generalSnapshotAnswers,
        { ...MOCKED_COMMENT_FIELD, sequence: tableSetupContext.generalFields.length + 1 }
      ],
      tableItemGroupRows: tableItemGroupRows,
      type: tableSetupContext.selectedType.name,
      domain: tableSetupContext.selectedDomain.domainName
    };
    const conditionalLogicAndRequiredFieldsValidation = generateConditionalLogicFromSetup();

    ModalBoxes.open({
      component: (
        <TablePreviewModal
          encounterTableItemGroupForPreview={encounterTableItemGroupForPreview}
          conditionalLogicAndRequiredFieldsValidation={conditionalLogicAndRequiredFieldsValidation}
        />
      )
    });
  }
};

function getIdByName(allFields, fieldNameForFind) {
  const field = allFields.filter(field => field.name === fieldNameForFind);
  return field[0].protocolItemDefinitionId;
}

function prepareFieldsToPreview(fields) {
  return fields
    .filter(field => field.isChecked)
    .map(element => ({
      protocolItemDefinitionId: element.protocolItemDefinitionId,
      performedStatus: 'NOT_ANSWERED',
      fieldComment: null,
      reasonForDataChange: null,
      fieldValue: element.inputValue || null,
      requiredAttention: false,
      investigatorField: element.investigatorField,
      fieldType: element.inputType,
      fileList: null,
      filesResponse: null,
      fieldName: element.updatedQuestion || element.question,
      sequence: element.sequence,
      codeDefinition: element.codeDefinationList.map(code => ({ ...code, uniqueIdentifier: uuid() })),
      cdashAliasName: element.cdashAliasName
    }));
}

function selectAnswer(answer) {
  switch (answer) {
    case 'Yes':
      return 'N';
    case 'No':
      return 'Y';
    case 'Normal':
      return 'ABNORM';
    case 'Abnormal':
      return 'NORM';
    case 'Clinically Significant':
      return 'NCS';
    case 'Not Clinically Significant':
      return 'CS';
    case 'CS':
      return 'NCS';
    case 'NCS':
      return 'CS';
    default:
      return 'Y';
  }
}

export default TablePreview;
