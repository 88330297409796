import React, { useEffect, useState } from 'react';

import { RevenueAdjustmentApi } from '../../../../../../api';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { PATIENT_REIMBURSEMENT, PATIENT_STIPEND } from '../AdjustmentConstants';

import { AdjustedLedgerEventsHistoryTable } from './AdjustedLedgerEventsHistoryTable/AdjustedLedgerEventsHistoryTable';
import AdjustmentResult from './AdjustmentResult/AdjustmentResult';
import { LedgerEventInfo } from './LedgerEventInfo/LedgerEventInfo';

import './RevenueAdjustmentHistoryModal.scss';

export default function RevenueAdjustmentHistoryModal({ modalBox, eventNumber }) {
  const [ledgerEventInfo, setLedgerEventInfo] = useState(null);
  const [ledgerEvents, setLedgerEvents] = useState([]);

  useEffect(() => {
    RevenueAdjustmentApi.getLedgerEventInfo(eventNumber).then(({ data }) => {
      data = {
        ...data,
        isPatientStipend: [PATIENT_STIPEND, PATIENT_REIMBURSEMENT].includes(data.type)
      };

      setLedgerEventInfo(data);
    });

    RevenueAdjustmentApi.getRevenueAdjustmentHistory(eventNumber).then(({ data }) => {
      setLedgerEvents(data);
    });
  }, [eventNumber]);

  return (
    <>
      <ModalBoxes.Header>Adjustment History</ModalBoxes.Header>
      <ModalBoxes.Body>
        <LedgerEventInfo ledgerEventInfo={ledgerEventInfo} />
        <AdjustedLedgerEventsHistoryTable ledgerEventInfo={ledgerEventInfo} ledgerEvents={ledgerEvents} />
        <AdjustmentResult ledgerEventInfo={ledgerEventInfo} ledgerEvents={ledgerEvents} />
      </ModalBoxes.Body>
    </>
  );
}

RevenueAdjustmentHistoryModal.className = 'revenue-adjustment-history-modal';
RevenueAdjustmentHistoryModal.size = 'w1250';
