import React, { Component } from 'react';
import cx from 'classnames';
import * as PropTypes from 'prop-types';

import common from '../../../../../common/common';

import './ProtocolEncounter.scss';

export default class ProtocolEncounter extends Component {
  static propTypes = {
    getEncounterDetails: PropTypes.func,
    deleteEpochEncounter: PropTypes.func,
    encountersRelationsBroken: PropTypes.array
  };

  deleteEncounter = (event, enKey) => {
    event.stopPropagation();
    // event.stopImmediatePropagation(); Was in old implementation
    this.props.deleteEpochEncounter(this.props.epochInfo.nodeKey, enKey);
  };

  editEncounter = (event, enix, enKey) => {
    event.stopPropagation();
    const epochId = this.props.epochInfo.nodeKey;
    this.props.getEncounterDetails(epochId, `E${enix}`, enKey);
  };

  render() {
    const { enc, epochInfo, enix } = this.props;
    const enKey = enc.enNodeKey ? enc.enNodeKey : `${epochInfo.nodeKey}_${common.getRandomNumber()}`;
    const encDisplayName = enc.displayName ? enc.displayName : `E${enix}`;
    const bgColor = this.defineColor(enc);
    return (
      <div
        className="d-inline position-relative protocol-setup-encounter epoch_1_encounters enmargin"
        id={enKey}
        key={enKey}
      >
        <span
          className={cx(bgColor, 'rounded px-2 py-1 mr-4 protocol-box-overlay')}
          onClick={event => event.stopPropagation()}
        >
          <button
            className="glyphicon glyphicon-remove enc_del_btn"
            onClick={event => this.deleteEncounter(event, enKey)}
          />
        </span>
        <span
          title={enc.encounterName}
          className="bg-light-grey rounded px-2 py-1 mr-4 enboxgrey"
          onClick={event => this.editEncounter(event, enix, enKey)}
        >
          {encDisplayName}
        </span>
        <button
          className="glyphicon glyphicon-edit edit_encounter_icon encedit"
          onClick={event => this.editEncounter(event, enix, enKey)}
        />
      </div>
    );
  }
  defineColor(enc) {
    if (this.props.encountersRelationsBroken.includes(enc.enNodeKey)) {
      return 'incorrect-protocol-encounter';
    } else if (enc.nonProtocol) {
      return 'non-protocol-encounter';
    } else {
      return 'protocol-encounter';
    }
  }
}
