import React, { useCallback, useEffect } from 'react';
import { isEmpty } from 'lodash/lang';

import { SitePatientEncounterApi } from '../../../../../api';
import TaskApi from '../../../../../api/calendar/TaskApi';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { NavigationPanel } from '../../../../../common/fullScreenLayout/FullScreenLayout';
import {
  ROLE_FINANCE_ANALYST,
  ROLE_OPERATION_MANAGER,
  ROLE_OPERATIONS_ANALYST
} from '../../../../../constants/userRoles';
import { userHasRole } from '../../../../../services/auth';
import openKeepIsHidden from '../../../../root/Container/Layout/Tasks/openKeepIsHidden';
import { useCurrentRoute } from '../../../../root/router';
import { isAbleToEditEncounter } from '../EncounterDescNew/encounterDetailsService';

import EdcVisitDateConfirmModal from './EdcVisitDateConfirmModal/EdcVisitDateConfirmModal';
import { ReviewContent } from './ReviewContent/ReviewContent';
import ReviewControl from './ReviewControl/ReviewControl';
import ReviewInfoHeader from './ReviewInfoHeader/ReviewInfoHeader';
import { ReviewSideBar } from './ReviewSideBar/ReviewSideBar';
import { ReviewType } from './reviewConstants';
import { ReviewProvider } from './ReviewContext';
import { getReviewType } from './reviewService';

import './ReviewPage.scss';

export function ReviewPage() {
  const currentRoute = useCurrentRoute();

  const getRelatedTasksForEncounter = useCallback(
    function() {
      const { ssuPatientId, patientEncounterId } = currentRoute.params;
      TaskApi.getTasksByPatientIdAndEncounter(ssuPatientId, patientEncounterId).then(({ data }) => {
        if (!isEmpty(data)) {
          openKeepIsHidden(data[0].taskId, data[0].ssuId);
        }
      });
    },
    [currentRoute.params]
  );

  useEffect(() => {
    getRelatedTasksForEncounter();
  }, [getRelatedTasksForEncounter]);

  useEffect(() => {
    const { ssuPatientId, patientEncounterId } = currentRoute.params;
    const reviewType = getReviewType(currentRoute);
    if (
      reviewType === ReviewType.SM &&
      !userHasRole([ROLE_OPERATION_MANAGER, ROLE_FINANCE_ANALYST, ROLE_OPERATIONS_ANALYST])
    ) {
      SitePatientEncounterApi.getSsuPatientEncounterMetaInfo(ssuPatientId, patientEncounterId).then(res => {
        const data = res.data;
        const isAbleToEditEdcVisitDate = isAbleToEditEncounter(data?.nonProtocol, data?.ssuStatus);
        if (isAbleToEditEdcVisitDate) {
          ModalBoxes.open({
            component: (
              <EdcVisitDateConfirmModal
                ssuPatientId={ssuPatientId}
                patientEncounterId={patientEncounterId}
                originEdcVisitDate={data?.edcVisitDate}
              />
            )
          });
        }
      });
    }
  }, [currentRoute]);

  return (
    <ReviewProvider>
      <ReviewInfoHeader />
      <div className="eds-review-page">
        <div className="eds-review-page-container">
          <div className="eds-review-page-body">
            <NavigationPanel>
              <ReviewSideBar />
            </NavigationPanel>
            <div className="eds-main-content">
              <ReviewControl />
              <ReviewContent />
            </div>
          </div>
        </div>
      </div>
    </ReviewProvider>
  );
}
