import React, { useContext } from 'react';

import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';
import Icon from '../../../../../common/general/Icon';
import { MANAGE_LEDGER_EVENTS } from '../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../services/auth';
import { PageInfoHeader } from '../../../../PageInfoHeader/PageInfoHeader';
import { generateUrlByKey } from '../../../../root/router';
import { LedgerContext } from '../LedgerContext';
import LedgerFilter from '../LedgerFilter/LedgerFilter';

import './LedgerTopSection.scss';

export function LedgerTopSection() {
  const { downloadCSV, pendingCount } = useContext(LedgerContext);

  return (
    <PageInfoHeader
      pageInfo={<PageInfoHeader.AdditionalInfo title="Pending Count">{pendingCount}</PageInfoHeader.AdditionalInfo>}
      right={
        <ButtonGroup>
          {userHasAccessTo(MANAGE_LEDGER_EVENTS) && (
            <Button target="_self" href={generateUrlByKey('Ledger.Add Ledger')} size="h28" priority="high">
              <Icon>add</Icon>
              Add Ledger
            </Button>
          )}
          <Button className="tlts-export" size="h28" priority="medium" onClick={downloadCSV}>
            Export
          </Button>
        </ButtonGroup>
      }
    >
      <LedgerFilter />
    </PageInfoHeader>
  );
}
