import React from 'react';

import Button from '../../common/general/Button';
import ButtonGroup from '../../common/general/ButtonGroup';
import Icon from '../../common/general/Icon';
import useMediaQuery from '../../common/hooks/useMediaQuery';

export default function ApplyAndResetButtons({
  buttonGroupClassName,
  onApply,
  onReset,
  applyDisabled,
  resetDisabled,
  applyClassName,
  resetClassName,
  applyButtonId = 'default-apply-button',
  resetButtonId = 'default-reset-button'
}) {
  const isSmallScreen = useMediaQuery('(max-width:1365px)');

  return (
    <ButtonGroup classNames={buttonGroupClassName}>
      {onApply && (
        <Button id={applyButtonId} disabled={applyDisabled} className={applyClassName} size="h56" onClick={onApply}>
          {isSmallScreen ? <Icon>done</Icon> : 'Apply'}
        </Button>
      )}
      {onReset && (
        <Button
          id={resetButtonId}
          disabled={resetDisabled}
          className={resetClassName}
          size="h56"
          priority="medium"
          onClick={onReset}
        >
          {isSmallScreen ? <Icon>restart_alt</Icon> : 'Reset'}
        </Button>
      )}
    </ButtonGroup>
  );
}
