import React, { useEffect, useState } from 'react';

import { CalendarPageSupplierApi } from '../../../../../../../../../../api';
import Select from '../../../../../../../../../../common/data-entry/Select/Select';
import TextArea from '../../../../../../../../../../common/data-entry/TextArea';
import ModalBoxes from '../../../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../../../../../../common/notifications/NotificationManager';
import {
  APPOINTMENT_CANCELED,
  APPOINTMENT_FAILED_TO_CANCEL
} from '../../../../../../../../../../constants/notificationMessages';
import { eventCRUDService } from '../../../../../../../../Calendar/EventCRUDService';

export default function CancelAppointmentModal({ modalBox, onSuccess, event }) {
  const [comment, setComment] = useState('');
  const [reasons, setReasons] = useState([]);
  const [selectedReasonId, setSelectedReasonId] = useState(null);

  useEffect(function() {
    CalendarPageSupplierApi.cancelReasons().then(({ data: reasons }) => {
      setReasons(reasons);
    });
  }, []);

  const allRequiredFieldsFilled = selectedReasonId && comment.trim();

  return (
    <>
      <ModalBoxes.Header>Cancel the event?</ModalBoxes.Header>
      <ModalBoxes.Body>
        <div className="row">
          <div className="col-md-12">
            <Select
              label="Reason"
              clearSearchOnSelection
              dataSource={reasons}
              onChange={onReasonChange}
              searchable={false}
              keepOpenOnSelection={false}
              closeOnSelectedOptionClick
              optionLabelKey="reason"
              clearable={false}
              required
            />
          </div>
          <div className="col-md-12">
            <TextArea rows="1" label="Comment" value={comment} onChange={onCommentsChange} required />
          </div>
        </div>
      </ModalBoxes.Body>

      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            No
          </Button>
          <Button onClick={onYes} disabled={!allRequiredFieldsFilled}>
            Yes
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );

  function onYes() {
    eventCRUDService
      .cancel(event.id, event.type, selectedReasonId, comment, event.ssuPatientId)
      .then(
        () => {
          onSuccess();
          NotificationManager.success(APPOINTMENT_CANCELED);
        },
        () => {
          NotificationManager.error(APPOINTMENT_FAILED_TO_CANCEL);
        }
      )
      .finally(function() {
        modalBox.close();
      });
  }

  function onCommentsChange({ target }) {
    setComment(target.value);
  }

  function onReasonChange(option) {
    setSelectedReasonId(option?.id);
  }
}

CancelAppointmentModal.className = 'cancel-appointment-modal';
CancelAppointmentModal.size = 'w650';
