import React from 'react';

import AmountInput from './AmountInput';
import ExpenseRadioInputs from './ExpenseRadioInputs';
import { NumberInput } from './NumberInput';

import './RowCalculationData.scss';

export function RowCalculationData({
  eventRow,
  onChangeEventRow,
  idx,
  maxCount,
  isVendorSelected,
  isEditingForbidden,
  managementFee
}) {
  return (
    <React.Fragment>
      <div className="RowCalculationData p-0">
        <div className="numberInput width25 pr-3">
          <NumberInput
            required={true}
            name={'countFrom_' + idx}
            value={eventRow.countFrom}
            onChange={onChangeEventRow(idx)}
            disabled={isEditingForbidden}
            label="From"
          />
        </div>
        <div className="numberInput width25 pr-3">
          <NumberInput
            name={'countTo_' + idx}
            value={eventRow.countTo === maxCount ? undefined : eventRow.countTo}
            onChange={onChangeEventRow(idx)}
            disabled={isEditingForbidden}
            label="To"
          />
        </div>
        <div className="numberInput width50">
          <AmountInput
            name={'clientAmount_' + idx}
            required={true}
            value={eventRow.clientAmount}
            onChange={onChangeEventRow(idx)}
            disabled={isEditingForbidden}
            label="Client"
            dollar={true}
          />
        </div>
      </div>
      <div className="RowCalculationData p-0">
        <div className="numberInput width50 pr-3">
          <AmountInput
            name={'siteAmount_' + idx}
            value={eventRow.siteAmount}
            onChange={onChangeEventRow(idx)}
            disabled={isEditingForbidden || managementFee}
            label="Site"
            dollar={true}
          />
          <ExpenseRadioInputs
            onChangeFormControls={onChangeEventRow(idx)}
            value={eventRow.siteAmountType}
            disabled={isEditingForbidden}
            name="siteAmountType"
          />
        </div>
        <div className="numberInput pr-0 width50" data-testid="row-vendor-amount">
          <AmountInput
            name={'vendorAmount_' + idx}
            value={eventRow.vendorAmount}
            onChange={onChangeEventRow(idx)}
            label="Vendor"
            disabled={!isVendorSelected || isEditingForbidden}
            dollar={true}
          />
          <ExpenseRadioInputs
            disabled={!isVendorSelected || isEditingForbidden}
            onChangeFormControls={onChangeEventRow(idx)}
            value={eventRow.vendorAmountType}
            name="vendorAmountType"
          />
        </div>
      </div>
    </React.Fragment>
  );
}
