const NotificationPreferencesKey = 'NotificationPreferences';

function getStoredFilterForUser() {
  return JSON.parse(localStorage.getItem(NotificationPreferencesKey)) || {};
}

export function updateLocalStorageFilter(user, filterKey, value) {
  const storedFilter = getStoredFilterForUser(user);
  storedFilter[user] = storedFilter[user] || {};
  storedFilter[user][filterKey] = value;
  localStorage.setItem(NotificationPreferencesKey, JSON.stringify(storedFilter));
}

export function getLocalStorageFilter(user, filterKey) {
  const storedFilter = getStoredFilterForUser(user);
  storedFilter[user] = storedFilter[user] || {};
  return storedFilter[user][filterKey];
}
