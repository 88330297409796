import React, { useContext, useEffect, useState } from 'react';
import { intersectionBy, uniqBy } from 'lodash/array';
import { orderBy } from 'lodash/collection';
import { isEmpty, isEqual, isFunction } from 'lodash/lang';

import MultiSelect from '../../../../common/data-entry/MultiSelect/MultiSelect';

import { TaskWorklistContext } from './TaskWorklistContext';

import './TaskSsuMultiSelect.scss';

const TaskSsuMultiSelect = ({ ssuIds, initialStudies, initialSites, onChange }) => {
  const [studySites, setStudySites] = React.useState([]);

  const [studies, setStudies] = useState([]);
  const [selectedStudies, setSelectedStudies] = useState([]);

  const [sites, setSites] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);

  const Tasks = useContext(TaskWorklistContext);

  useEffect(
    function() {
      let selectedStudySites = [];
      if (!isEmpty(studySites) && (!isEmpty(selectedStudies) || !isEmpty(selectedSites))) {
        const selectedStudyIds = [...selectedStudies].map(e => e.id);
        const selectedSitesIds = [...selectedSites].map(e => e.id);

        selectedStudySites = studySites.filter(
          ssu =>
            (isEmpty(selectedStudyIds) || selectedStudyIds.includes(ssu.study.id)) &&
            (isEmpty(selectedSitesIds) || selectedSitesIds.includes(ssu.site.id))
        );
      }
      isFunction(onChange) && onChange(selectedStudies, selectedSites, selectedStudySites);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedStudies, selectedSites]
  );

  const updateSelectedStudies = values => {
    !isEqual(values, selectedStudies) && setSelectedStudies(values);
  };

  const updateSelectedSites = values => {
    !isEqual(values, selectedSites) && setSelectedSites(values);
  };

  useEffect(
    function() {
      // works like reset trigger
      if (isEmpty(ssuIds) && isEmpty(initialStudies) && isEmpty(initialSites)) {
        updateSelectedSites([]);
        updateSelectedStudies([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ssuIds, initialStudies, initialSites]
  );

  useEffect(
    function() {
      if (
        !isEmpty(Tasks.ssus) &&
        !isEmpty(Tasks.studies) &&
        !isEmpty(Tasks.sites) &&
        isEmpty(studySites) &&
        isEmpty(studies) &&
        isEmpty(sites)
      ) {
        setStudySites(Tasks.ssus);
        setStudies(Tasks.studies);
        setSites(Tasks.sites);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Tasks]
  );

  useEffect(
    function() {
      if (!isEmpty(selectedSites)) {
        const selectedSitesIds = selectedSites.map(e => e.id);
        const studies = studySites.filter(e => selectedSitesIds.includes(e.site.id)).map(e => e.study);
        const filteredStudies = getUniqueOrderedStudies(studies);

        if (!isEmpty(selectedStudies)) {
          const intersectionWithSelectedStudies = intersectionBy(studies, selectedStudies, 'id');
          isEmpty(intersectionWithSelectedStudies) && updateSelectedStudies([]);
          setStudies(getUniqueOrderedStudies([...filteredStudies, ...selectedStudies]));
        } else {
          setStudies(filteredStudies);
        }
      } else {
        const studies = studySites.map(e => e.study);
        const allStudies = getUniqueOrderedStudies(studies);
        setStudies(allStudies);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedSites, studySites]
  );

  useEffect(
    function() {
      if (!isEmpty(selectedStudies)) {
        const selectedStudyIds = selectedStudies.map(e => e.id);
        const sites = studySites.filter(e => selectedStudyIds.includes(e.study.id)).map(e => e.site);
        const filteredSites = getUniqueOrderedSites(sites);

        if (!isEmpty(selectedSites)) {
          const intersectionWithSelectedSites = intersectionBy(sites, selectedSites, 'id');
          isEmpty(intersectionWithSelectedSites) && updateSelectedSites([]);
          setSites(getUniqueOrderedSites([...filteredSites, ...selectedSites]));
        } else {
          setSites(filteredSites);
        }
      } else {
        const sites = studySites.map(e => e.site);
        const allSites = getUniqueOrderedSites(sites);
        setSites(allSites);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedStudies, studySites]
  );

  useEffect(
    function() {
      if (!isEmpty(studySites)) {
        initialStudies && updateSelectedStudies(initialStudies);
        initialSites && updateSelectedSites(initialSites);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [studySites, initialStudies, initialSites]
  );

  return (
    <>
      <MultiSelect
        label="Study"
        disabled={false}
        dataSource={studies}
        onChange={updateSelectedStudies}
        value={selectedStudies}
        searchable={true}
        clearSearchOnSelection
        customFilterFunction={(e, index, collection, searchString) =>
          `${e.projectCode} ${e.name}`.toLowerCase().includes(searchString)
        }
        customOptionTemplateFunction={e => (
          <div>
            <div className="label">{e?.projectCode}</div>
            <div>{e?.name}</div>
          </div>
        )}
        className="task-ssu-multi-select-study"
        validate={false}
      />
      <MultiSelect
        label="Site"
        disabled={false}
        dataSource={sites}
        onChange={updateSelectedSites}
        value={selectedSites}
        searchable={true}
        clearSearchOnSelection
        validate={false}
      />
    </>
  );
};

export default TaskSsuMultiSelect;

function getUniqueOrderedSites(sites) {
  return orderBy(
    uniqBy(sites, e => e.id),
    ['name'],
    'asc'
  );
}

function getUniqueOrderedStudies(studies) {
  return orderBy(
    uniqBy(studies, e => e.id),
    ['name'],
    'asc'
  );
}
