import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { reverse } from 'lodash/array';
import { forEach, groupBy } from 'lodash/collection';
import { isArray, isEmpty, isString } from 'lodash/lang';
import { parse } from 'query-string';

import { withAuthorizedAccess } from './auth';
import { preparedRouterConfigMap } from './config';

export function prepareConfig(configRoutes, parent, nested = false) {
  return configRoutes.map(route => {
    const { path, name } = route;

    route.relativePath = path;

    if (!isEmpty(parent)) {
      route.path = parent.path + path;

      if (!nested) {
        route.relativePath = parent.relativePath + path;
      }

      route.key = `${parent.key}.${name}`;
      route.parent = parent;
      route.isNested = nested;
    } else {
      route.key = name;
    }

    if (!isEmpty(route.inherited)) {
      route.inherited = prepareConfig(reverse(route.inherited), route);
    }

    if (!isEmpty(route.nested)) {
      route.nested = prepareConfig(route.nested, route, true);
    }

    return route;
  });
}

export function validateConfig(configRoutes) {
  forEach(groupBy(configRoutes, 'path'), (v, k) => {
    if (v.length > 1) {
      throw new Error(`Route '${k}' has duplications! Use 'inherited' property instead.`);
    }
  });
  forEach(configRoutes, route => {
    const { path, inherited, nested, component, access } = route;

    if (!isString(path)) {
      throw new Error(`Parameter 'path' is not defined or incorrect`);
    }

    if (!component) {
      throw new Error(`Parameter 'component' not defined!`);
    }

    if (
      access &&
      (!isArray(access) ||
        access.some(function(a) {
          return typeof a !== 'symbol';
        }))
    ) {
      throw new Error(`Parameter 'access' is not an array or contains not symbol!`);
    }

    if (inherited) {
      if (!isArray(inherited)) {
        throw new Error(`'inherited' is not an array!`);
      }
      if (isEmpty(inherited)) {
        throw new Error(`'inherited' is empty!`);
      }
      validateConfig(route.inherited);
    }

    if (nested) {
      if (!isArray(nested)) {
        throw new Error(`'nested' is not an array!`);
      }
      if (isEmpty(nested)) {
        throw new Error(`'nested' is empty!`);
      }
      validateConfig(route.nested);
    }
  });
  return configRoutes;
}

export function generateRouterConfigMap(routerConfig) {
  const mapConfig = {};
  byRouterConfig(routerConfig);
  return mapConfig;

  function byRouterConfig(routerConfig) {
    return routerConfig.forEach(route => {
      const { key } = route;
      if (!isEmpty(route.inherited)) {
        byRouterConfig(route.inherited);
      }

      if (!isEmpty(route.nested)) {
        byRouterConfig(route.nested);
      }

      mapConfig[key] = route;
    });
  }
}

export function generateRoutes(configRoutes) {
  return configRoutes.flatMap(route => {
    const { relativePath, nested, inherited, access, component } = route;
    const Component = isEmpty(access) ? component : withAuthorizedAccess(access, component);

    const routePath = relativePath.substring(1);

    const routeComponent = {
      element: <Component />
    };

    if (routePath) {
      routeComponent.path = routePath;
    } else {
      routeComponent.index = true;
    }

    if (!isEmpty(nested)) {
      routeComponent.children = generateRoutes(nested);
    }

    if (!isEmpty(inherited)) {
      return [...generateRoutes(inherited), routeComponent];
    }

    return routeComponent;
  });
}

export function getCurrentRouteByLocation(location) {
  if (isEmpty(location)) {
    return;
  }
  const { pathname, search, hash, state } = location;
  const currentRoute = {
    pathname,
    search,
    hash,
    state,
    searchParams: parse(search),
    matched: false
  };
  for (const routeConfig of Object.values(preparedRouterConfigMap)) {
    const { path } = routeConfig;
    const match = matchPath(path, pathname);
    if (!isEmpty(match)) {
      const { params, isExact } = match;
      Object.assign(currentRoute, {
        matched: true,
        params,
        isExact,
        ...routeConfig
      });
      break;
    }
  }
  return currentRoute;
}

export function forceRemount(Component) {
  return function(props) {
    const location = useLocation();
    return <Component {...props} key={location?.key} />;
  };
}
