import EnrollmentPredictionApi from '../../../api/finance/EnrollmentPredictionApi';
import NotificationManager from '../../../common/notifications/NotificationManager';
import {
  buildStudyEnrollments,
  createStudyEnrollment,
  updateStudyEnrollment
} from '../../../components/pages/study/Enrollment/studyEnrollmentService';
import { PERIOD_LOCKED, UNABLE_TO_LOCK_PERIOD } from '../../../constants/notificationMessages';
import { onRequestError } from '../../../services/handlers';

export const LAST_LOCKED_PERIOD_RECEIVED = 'LAST_LOCKED_PERIOD_RECEIVED';
export const getLastLockedPeriodActionCreator = () => dispatch => {
  return EnrollmentPredictionApi.getLastLockedPeriod()
    .then(period => {
      dispatch({
        type: LAST_LOCKED_PERIOD_RECEIVED,
        payload: period.data.lockedMonth
      });
    })
    .catch(err => onRequestError(err, { customMessage: "Can't get enrollments periods information" }));
};

export const lockEnrollmentsActionCreator = () => (dispatch, getState) => {
  const dateToLock = getState()
    .forecasting.lastLockedPeriod.clone()
    .add(1, 'month');
  return EnrollmentPredictionApi.lock(dateToLock.format('YYYY-MM-DD'))
    .then(() => {
      dispatch({
        type: LAST_LOCKED_PERIOD_RECEIVED,
        payload: dateToLock
      });
      NotificationManager.success(`${dateToLock.format('MMMM YYYY')} ${PERIOD_LOCKED}`);
    })
    .catch(err => onRequestError(err, { customMessage: UNABLE_TO_LOCK_PERIOD }));
};

export const buildEnrollmentsActionCreator = studySites => dispatch => {
  buildStudyEnrollments(studySites).then(enrollments => {
    dispatch(getStudyEnrollments(enrollments));
  });
};

export const GET_STUDY_ENROLLMENTS = 'GET_STUDY_ENROLLMENTS';
export const getStudyEnrollments = studyEnrollments => {
  return {
    type: GET_STUDY_ENROLLMENTS,
    payload: studyEnrollments
  };
};

export const UPDATE_STUDY_ENROLLMENT = 'UPDATE_STUDY_ENROLLMENT';
export const updateStudyEnrollmentsAction = studyEnrollment => {
  return {
    type: UPDATE_STUDY_ENROLLMENT,
    payload: studyEnrollment
  };
};

export const CREATE_STUDY_ENROLLMENT = 'CREATE_STUDY_ENROLLMENT';
export const createStudyEnrollmentsAction = studyEnrollment => {
  return {
    type: CREATE_STUDY_ENROLLMENT,
    payload: studyEnrollment
  };
};

export const updateStudyEnrollmentsActionCreator = studyEnrollment => dispatch => {
  updateStudyEnrollment(studyEnrollment)
    .then(enrollment => {
      dispatch(updateStudyEnrollmentsAction(enrollment));
    })
    .catch(err => onRequestError(err, { customMessage: "Can't update  study enrollment" }));
};

export const createStudyEnrollmentActionCreator = studyEnrollment => dispatch => {
  createStudyEnrollment(studyEnrollment)
    .then(enrollment => {
      dispatch(createStudyEnrollmentsAction(enrollment));
    })
    .catch(err => onRequestError(err, { customMessage: "Can't create  study enrollment" }));
};
