import { SvgIcon } from '@mui/material';

export const Italic = ({ onClick, active }) => {
  return (
    <SvgIcon onClick={onClick}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.7601 9H13.7601L11.5601 19H9.56006L11.7601 9ZM13.4401 5C13.2423 5 13.0489 5.05865 12.8845 5.16853C12.72 5.27841 12.5919 5.43459 12.5162 5.61732C12.4405 5.80004 12.4207 6.00111 12.4593 6.19509C12.4979 6.38907 12.5931 6.56725 12.733 6.70711C12.8728 6.84696 13.051 6.9422 13.245 6.98079C13.4389 7.01937 13.64 6.99957 13.8227 6.92388C14.0055 6.84819 14.1616 6.72002 14.2715 6.55557C14.3814 6.39112 14.4401 6.19778 14.4401 6C14.4401 5.73478 14.3347 5.48043 14.1472 5.29289C13.9596 5.10536 13.7053 5 13.4401 5Z"
          fill="black"
          fillOpacity={`${active ? '0.9' : '0.6'}`}
        />
      </svg>
    </SvgIcon>
  );
};
