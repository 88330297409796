import React, { useEffect, useState } from 'react';

import { Amount } from '../../../../shared/amount-view/Amount/Amount';
import { AmountWithType } from '../../../../shared/amount-view/AmountWithType/AmountWithType';

import getSummaryOfAdjustment from './../../revenueAdjustmentSummaryService';

import './AdjustmentResult.scss';

export default function AdjustmentResult({ ledgerEventInfo, ledgerEvents }) {
  const [adjustmentSummary, setAdjustmentSummary] = useState(null);

  useEffect(() => {
    setAdjustmentSummary(getSummaryOfAdjustment(ledgerEvents));
  }, [ledgerEvents]);

  return (
    <div className="adjusting-history-ledger-summary" role="row">
      <div className="summary-item summary-header">
        <div className="title">
          <span>Adjustment result</span>
        </div>
        <div className="budget-details">
          <span>{`Overhead: ${ledgerEventInfo?.overhead}%`}</span>
          <span>{`Withholding: ${ledgerEventInfo?.withholding}%`}</span>
        </div>
      </div>
      <div className="summary-item">
        <span className="summary-item-title">Client</span>
        <Amount highlightFractionalValue coinsAmount={adjustmentSummary?.clientAmountInCoins} showDollarSign />
      </div>
      {!ledgerEventInfo?.isPatientStipend && (
        <div className="summary-item">
          <span className="summary-item-title">Site</span>
          <AmountWithType
            coinsAmount={adjustmentSummary?.siteAmountInCoins}
            amountType={adjustmentSummary?.siteAmountType}
            showDollarSign
          />
        </div>
      )}
      {ledgerEventInfo?.isPatientStipend && (
        <div className="summary-item">
          <span className="summary-item-title">Patient</span>
          <AmountWithType
            coinsAmount={adjustmentSummary?.patientAmountInCoins}
            amountType={adjustmentSummary?.patientAmountType}
            showDollarSign
          />
        </div>
      )}
      {!ledgerEventInfo?.isPatientStipend && adjustmentSummary?.vendorId && (
        <React.Fragment>
          <div className="summary-item">
            <span className="summary-item-title">Vendor name</span>
            <span>{adjustmentSummary?.vendorName}</span>
          </div>
          <div className="summary-item">
            <span className="summary-item-title">Vendor</span>
            <AmountWithType
              coinsAmount={adjustmentSummary?.vendorAmountInCoins}
              amountType={adjustmentSummary?.vendorAmountType}
              showDollarSign
            />
          </div>
        </React.Fragment>
      )}
      <div className="summary-item">
        <span className="summary-item-title">Gross</span>
        <Amount highlightFractionalValue coinsAmount={adjustmentSummary?.grossInCoins} showDollarSign />
      </div>
      <div className="summary-item">
        <span className="summary-item-title">Net</span>
        <Amount highlightFractionalValue coinsAmount={adjustmentSummary?.netInCoins} showDollarSign />
      </div>
    </div>
  );
}
