import { createActions } from 'redux-actions';

import { CLEAR, UPDATE } from './types';

export const options = {
  prefix: 'CURRENT_USER'
};

export const { update } = createActions(UPDATE, options);
export const { clear } = createActions(CLEAR, options);
