import axios from 'axios';

const ROOT = 'api/v1/studies';

const PreviewTableApi = {
  getNewTablePreview(params) {
    return axios.get(`${ROOT}/${params.study_identifier}/preview/table/${params.element_identifier}`);
  }
};

export default PreviewTableApi;
