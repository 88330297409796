import React from 'react';

import Icon from '../../../../../../../common/general/Icon';

export default function InvestigatorFieldIcon() {
  return (
    <Icon suit="material" style={{ fontSize: '25px', marginRight: '5px' }}>
      medical_services
    </Icon>
  );
}
