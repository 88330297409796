import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { isArray } from 'lodash/lang';

import Checkbox from '../../../../common/data-entry/InputSelectors/Checkbox';

import './EventTypeSelector.scss';

export function EventTypeSelector({
  onEventTypesChange,
  onNonPatientEventTypeChange,
  initialEventTypesProvider,
  initialNonPatientEventTypesProvider,
  optionEventTypes,
  nonPatientEventTypeOptions
}) {
  const [eventTypes, setEventTypes] = useState([]);
  const [nonPatientEventTypes, setNonPatientEventTypes] = useState([]);
  const [eventTypesInitialized, setEventTypesInitialized] = useState(false);
  const [nonPatientEventTypeInitialized, setNonPatientEventTypeInitialized] = useState(false);

  useEffect(() => {
    if (!eventTypesInitialized) {
      const initialEventTypes = initialEventTypesProvider();
      if (isArray(initialEventTypes)) {
        optionEventTypes.forEach(e => {
          e.checked = initialEventTypes.includes(e.code);
        });
      }
      setEventTypes(optionEventTypes);
      setEventTypesInitialized(true);
    }
  }, [eventTypes, eventTypesInitialized, optionEventTypes, initialEventTypesProvider]);

  useEffect(() => {
    if (!nonPatientEventTypeInitialized && nonPatientEventTypeOptions?.length > 0) {
      const initialNonPatientEventTypes = initialNonPatientEventTypesProvider();
      if (isArray(initialNonPatientEventTypes)) {
        nonPatientEventTypeOptions.forEach(e => {
          e.checked = initialNonPatientEventTypes.includes(e.code);
        });
      }
      setNonPatientEventTypes(nonPatientEventTypeOptions);
      setNonPatientEventTypeInitialized(true);
    }
  }, [
    nonPatientEventTypeInitialized,
    nonPatientEventTypes,
    nonPatientEventTypeOptions,
    initialNonPatientEventTypesProvider
  ]);

  useEffect(() => {
    if (eventTypesInitialized) {
      onEventTypesChange(eventTypes);
    }
  }, [eventTypes, eventTypesInitialized, onEventTypesChange]);

  useEffect(() => {
    if (nonPatientEventTypeInitialized) {
      onNonPatientEventTypeChange(nonPatientEventTypes);
    }
  }, [nonPatientEventTypeInitialized, onNonPatientEventTypeChange, nonPatientEventTypes]);

  const onEventTypeClick = option => {
    if (option) {
      const originalDataSource = cloneDeep(eventTypes);
      const el = originalDataSource.find(e => e.code === option.code);
      el.checked = !el.checked;
      setEventTypes(originalDataSource);
    }
  };

  const onNonPatientEventTypeClick = option => {
    if (option) {
      const currentNonPatientEventTypes = cloneDeep(nonPatientEventTypes);
      const nonPatientEventType = currentNonPatientEventTypes.find(e => e.code === option.code);
      nonPatientEventType.checked = !nonPatientEventType.checked;
      setNonPatientEventTypes(currentNonPatientEventTypes);
    }
  };

  const onSelectAll = () => {
    const currentNonPatientEventTypes = cloneDeep(nonPatientEventTypes);
    const originalDataSource = cloneDeep(eventTypes);

    const isSomeNonPatientEventsAreChecked = currentNonPatientEventTypes.some(
      nonPatientEventType => nonPatientEventType.checked
    );

    const isSomeEventsAreChecked = originalDataSource.some(eventTypes => eventTypes.checked);
    if (isSomeNonPatientEventsAreChecked || isSomeEventsAreChecked) {
      currentNonPatientEventTypes.forEach(nonPatientEventType => (nonPatientEventType.checked = false));
      originalDataSource.forEach(eventType => (eventType.checked = false));
    } else {
      currentNonPatientEventTypes.forEach(nonPatientEventType => (nonPatientEventType.checked = true));
      originalDataSource.forEach(eventType => (eventType.checked = true));
    }

    setEventTypes(originalDataSource);
    setNonPatientEventTypes(currentNonPatientEventTypes);
  };

  const combinedEventTypes = [...nonPatientEventTypes, ...eventTypes];
  const allEventsAreChecked = combinedEventTypes.every(e => e.checked);
  const someEventsAreChecked = combinedEventTypes.some(e => e.checked) && !allEventsAreChecked;

  return (
    <div className="calendar-event-type-selector-container d-flex flex-column pl-3">
      {
        <Checkbox
          label="Select all"
          onChange={onSelectAll}
          indeterminate={someEventsAreChecked}
          checked={allEventsAreChecked}
        />
      }
      <Checkbox.Group direction="column">
        {nonPatientEventTypes.map(option => {
          return (
            <Checkbox
              key={option.code}
              label={option.value}
              onChange={() => onNonPatientEventTypeClick(option)}
              checked={option.checked}
            />
          );
        })}
      </Checkbox.Group>
      <Checkbox.Group direction="column">
        {eventTypes.map(option => {
          return (
            <Checkbox
              key={option.code}
              label={option.value}
              onChange={() => onEventTypeClick(option)}
              checked={option.checked}
            />
          );
        })}
      </Checkbox.Group>
    </div>
  );
}
