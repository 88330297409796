import React, { useMemo } from 'react';

import { useTableItemGroupState } from '../TableItemGroupContext/TableItemGroupContext';
import TableItemGroupField from '../TableItemGroupField/TableItemGroupField';

import './TableItemGroupComment.scss';
export default function TableItemGroupComment() {
  const { encounterTableItemGroup } = useTableItemGroupState();
  const { generalSnapshotAnswers } = encounterTableItemGroup;

  const commentQuestion = useMemo(
    function() {
      return generalSnapshotAnswers.find(({ fieldName }) => fieldName === 'Comment');
    },
    [generalSnapshotAnswers]
  );

  return (
    <div className="table-item-group-comment">
      <div>{commentQuestion?.fieldName}</div>
      <div>
        <TableItemGroupField field={commentQuestion} />
      </div>
    </div>
  );
}
