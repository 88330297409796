import axios from 'axios';

const ROOT = 'api/v1/finance/site-payments';

const SitePaymentsApi = {
  getSitePayments(filters) {
    return axios.post(`${ROOT}`, filters);
  },
  readyForApproval(billEvents) {
    return axios.put(`${ROOT}/ready-for-approval`, billEvents);
  },
  resetToOpen(itemSiteIds) {
    return axios.put(`${ROOT}/reset-to-open`, itemSiteIds);
  },
  createSiteBill(request) {
    return axios.post(`${ROOT}/bills`, request);
  },
  getUnbilledSiteEventsSummary(studyId, siteId) {
    return axios.get(`${ROOT}/unbilled-summary?studyId=${studyId}&siteId=${siteId}`);
  },
  setAsApproved(itemSiteIds) {
    return axios.put(`${ROOT}/approve`, itemSiteIds);
  },
  rejectSitePayments(itemSiteIds, rejectionReason) {
    return axios.put(`${ROOT}/reject`, { itemSiteIds, rejectionReason });
  },
  markAsPaid(itemSiteIds, sitePaymentDate) {
    return axios.put(`${ROOT}/paid`, { itemSiteIds, sitePaymentDate });
  },
  exportNS(siteItems) {
    return axios.post(`${ROOT}/export/net-suit`, siteItems, { responseType: 'arraybuffer' });
  },
  getAvailableBillNumbers(itemSiteId) {
    return axios.get(`${ROOT}/${itemSiteId}/bill-numbers`);
  },
  updateBillNumber(itemSiteId, billNumbers) {
    return axios.patch(`${ROOT}/${itemSiteId}`, billNumbers, { hideLoader: true });
  },
  deleteBillNumber(itemSiteId) {
    return axios.delete(`${ROOT}/${itemSiteId}/bill-number`, { hideLoader: true });
  }
};

export default SitePaymentsApi;
