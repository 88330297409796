import React from 'react';

export const DefaultBudgetCell = ({ value }) => {
  const content = value || '';
  return (
    <div className="MuiDataGrid-cellContent" title={content}>
      {content}
    </div>
  );
};
