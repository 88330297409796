import { sortBy } from 'lodash/collection';

export function getRecalculatedOrder(combinedList, checkedOnly) {
  let checked = [];
  let unchecked = [];

  if (checkedOnly) {
    checked = combinedList.filter(e => (e.isOptional && e.isChecked) || e.isCustom || e.mandatoryField);
    unchecked = combinedList.filter(e => e.isOptional && !e.isChecked && !e.mandatoryField);
  } else {
    checked = combinedList;
  }
  const customItemWithConditionalLogicConfigured = combinedList
    .flatMap(e => e.customItemDefinationList)
    .filter(e => !!e?.fieldConfigurationResponse);

  const optionalItemsWithConditionalLogicConfigured = combinedList.filter(e => !!e?.fieldConfigurationResponse);

  const allItemsWithConditionalLogicConfigured = [
    ...customItemWithConditionalLogicConfigured,
    ...optionalItemsWithConditionalLogicConfigured
  ];

  const flattenItemsCustom = combinedList.flatMap(e => e.customItemDefinationList).filter(e => e);
  const flattenItemsOptional = combinedList.filter(e => !e.isCustom);

  allItemsWithConditionalLogicConfigured.forEach(e => {
    if (!e.fieldConfigurationResponse.conditionField) {
      e.fieldConfigurationResponse.conditionField = [...flattenItemsCustom, ...flattenItemsOptional].find(
        b => b.sequence === e.fieldConfigurationResponse.conditionFieldSequence
      );
    }
  });

  let lastSequence = 1;
  checked.forEach(e => {
    e.sequence = lastSequence;
    if (e.isCustom) {
      e.sequence = lastSequence;
      e.customItemDefinationList.forEach(a => {
        updateItemsWithConditionalLogicIfExists(customItemWithConditionalLogicConfigured);
        a.sequence = lastSequence;
        lastSequence += 1;
      });
    } else {
      lastSequence += 1;
    }
  });
  unchecked.forEach(e => {
    e.sequence = lastSequence;
    lastSequence += 1;
  });
  return checked;
}

function updateItemsWithConditionalLogicIfExists(customItemWhichUsesCurrentFieldInConfiguration) {
  if (customItemWhichUsesCurrentFieldInConfiguration.length > 0) {
    customItemWhichUsesCurrentFieldInConfiguration.forEach(el => {
      const config = el?.fieldConfigurationResponse;
      if (config && config?.conditionField) {
        config.conditionFieldSequence = config.conditionField.sequence;
      }
    });
  }
}

function populateIdAndSequence(allInputs) {
  allInputs.forEach(item => {
    if (item.isCustom) {
      item.sequence = item.customItemDefinationList[0].sequence;
      item.id = item.customItemDefinationList[0].sequence - 1;
    }
    if (item.isOptional) {
      item.id = item.sequence - 1;
    }
  });
}

export function getCombinedList(inputs, checkedOnly) {
  const isFirstElementOfInputsNotEmptyWithCustomProperty = inputs?.length > 0 && inputs[0]?.custom;
  const isOptionalFieldsExist = inputs[0]?.optional;

  let allInputs = [];

  if (isOptionalFieldsExist) {
    allInputs = inputs[0].optional.flatMap((e, labelIndex) => {
      return e.itemDefinitionList.map((a, itemIndex) => {
        a.isOptional = true;
        a.labelIndex = labelIndex;
        a.itemIndex = itemIndex;
        if (a.mandatoryField) {
          a.isChecked = true;
        }
        return a;
      });
    });
  }

  if (isFirstElementOfInputsNotEmptyWithCustomProperty) {
    allInputs = allInputs.concat(
      inputs[0].custom.map((e, labelIndex) => {
        e.isCustom = true;
        e.labelIndex = labelIndex;
        return e;
      })
    );
  }

  populateIdAndSequence(allInputs);
  return getRecalculatedOrder(sortBy(allInputs, 'sequence'), checkedOnly);
}
