import { isEqual } from 'lodash/lang';

export function calculateCodeDefinitionChangesFromNewTerminologyVersion(currentForm, newForm) {
  const changedCodeDefinitionsMap = new Map();
  currentForm?.optional[0]?.itemDefinitionList
    .filter(currentItemDefinition => currentItemDefinition.isChecked)
    .forEach(currentItemDefinition => {
      const currentCodeDefinitions = currentItemDefinition?.codeDefinationList.map(
        codeDefinition => codeDefinition.decode
      );
      const newCodeDefinitions = newForm.optional[0].itemDefinitionList
        .find(newItemDefinition => newItemDefinition.cdashAliasName === currentItemDefinition.cdashAliasName)
        ?.codeDefinationList.map(codeDefinition => codeDefinition.decode);
      if (!isEqual(currentCodeDefinitions.sort(), newCodeDefinitions.sort())) {
        changedCodeDefinitionsMap.set(currentItemDefinition.question, [currentCodeDefinitions, newCodeDefinitions]);
      }
    });

  return changedCodeDefinitionsMap;
}
