import React, { useContext } from 'react';
import { isEmpty } from 'lodash/lang';

import { CreateInvoice } from './CreateInvoice/CreateInvoice';
import FulScreenModal from './FulScreenModal/FulScreenModal';
import { InvoiceGenerator } from './InvoiceGenerator/InvoiceGenerator';
import { InvoiceTable } from './InvoiceTable/InvoiceTable';
import { InvoiceTopSection } from './InvoiceTopSection/InvoiceTopSection';
import { InvoiceContext, withNewInvoiceContext } from './NewInvoiceContext';

import './Invoice.scss';

function NewInvoice() {
  const { createInvoiceItems, automaticallyGeneratedInvoice } = useContext(InvoiceContext);
  return (
    <div className="eds-invoice">
      <InvoiceTopSection />
      <InvoiceTable />
      <FulScreenModal showBottomPanel={!isEmpty(createInvoiceItems)}>
        <CreateInvoice />
      </FulScreenModal>
      {automaticallyGeneratedInvoice && <InvoiceGenerator />}
    </div>
  );
}

export default withNewInvoiceContext(NewInvoice);
