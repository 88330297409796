import { USER_PREFERENCES } from '../../../constants/accessToPages';

import Preferences from './Preferences';

export const preferencesRouterConfig = {
  name: 'Preferences',
  path: '/preferences',
  component: Preferences,
  access: [USER_PREFERENCES]
};
