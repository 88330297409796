import React from 'react';
import cx from 'classnames';

import './FulScreenModal.scss';

const FulScreenModal = ({ showBottomPanel, children }) => {
  return <div className={cx('bottom-panel-container', { 'bottom-panel-visible': showBottomPanel })}>{children}</div>;
};

export default FulScreenModal;
