import React, { useImperativeHandle, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import cx from 'classnames';
import { isFunction } from 'lodash';
import { isEmpty, isNull, isString, isUndefined } from 'lodash/lang';
import { omit } from 'lodash/object';

import _File from './_File';
import _Password from './_Password';
import inputPropTypes from './types';

import './Input.scss';
import './Input.legacy.scss';

const NumericFormatAmountInput = React.forwardRef(function(props, ref) {
  const {
    label,
    defaultValue,
    validate,
    validationMessage,
    valid,
    prefix,
    suffix,
    iconsAfter,
    className,
    legacyLook,
    disabled
  } = props;
  const propValue = props.value;
  const [value, setValue] = useState(isUndefined(propValue) ? defaultValue : propValue);

  const inputRef = useRef();
  useImperativeHandle(ref, () => inputRef.current);

  const isDefinedNewValue = !isUndefined(propValue);
  const isUndefinedNewValue = isUndefined(propValue) || isNull(propValue);
  const isDefinedCurrentValue = !isUndefined(value) && !isNull(value);
  const isNotEqualCurrentValueAndNewValue = propValue !== value;

  if (
    props.hasOwnProperty('value') &&
    ((isUndefinedNewValue && isDefinedCurrentValue) || (isDefinedNewValue && isNotEqualCurrentValueAndNewValue))
  ) {
    setValue(propValue);
  }

  const restProps = omit(
    props,
    'className',
    'label',
    'onChange',
    'defaultValue',
    'value',
    'validate',
    'validationMessage',
    'valid',
    'prefix',
    'suffix',
    'iconsAfter',
    'legacyLook'
  );

  const isNotValid = (isString(validationMessage) && validationMessage.length > 0) || !valid;

  return (
    <div
      className={cx('eui-input', className, {
        'eui-input-not-empty': !isEmpty(value) || value === 0,
        'eui-input-not-valid': isNotValid,
        'eui-input-legacy': legacyLook
      })}
    >
      <label>
        <NumericFormat
          ref={inputRef}
          disabled={disabled}
          value={fixControlledValue(value)}
          onChange={onChange}
          thousandSeparator=","
          decimalScale={2}
          {...restProps}
        />
        <span className="eui-input-face" />
        <span className="eui-input-prefix">{prefix}</span>
        <span className="eui-input-suffix">{suffix}</span>
        <span className="eui-input-icons-after">{iconsAfter}</span>
        <span className="eui-input-label">{label}</span>
      </label>
      {validate && <div className="eui-input-validation-message">{validationMessage}</div>}
    </div>
  );

  function onChange(e) {
    e.persist();
    const { onChange } = props;
    setValue(parseFloat(e.target.value.replace(/,/g, '')));
    isFunction(onChange) && onChange(e);
  }
});

function fixControlledValue(value) {
  if (typeof value === 'undefined' || value === null) {
    return '';
  }
  return value;
}

NumericFormatAmountInput.defaultProps = {
  valid: true,
  type: 'text',
  validate: true
};

NumericFormatAmountInput.propTypes = inputPropTypes;
NumericFormatAmountInput.Password = _Password;
NumericFormatAmountInput.File = _File;

export default NumericFormatAmountInput;
