import React, { useEffect, useRef, useState } from 'react';
import ReactSuperSelect from 'react-super-select';
import cx from 'classnames';
import { differenceBy } from 'lodash/array';

import IconButton from '../../common/buttons/IconButton/IconButton';
import useMobileDevice from '../../common/hooks/useMobileDevice';
import { resolvePersonnelRoles } from '../../services/personnelService';
import { isDisabledAuthority } from '../pages/Calendar/CalendarUtils';

import './UserMultiSelect.scss';

export function UserMultiSelect({
  initialUsers,
  onChange,
  organizerUser,
  datasource,
  className,
  customOptionTemplateFunction,
  optionValueKey,
  disabled,
  label
}) {
  const [users, seUsers] = useState();
  const [originalUsers, setOriginalUsers] = useState();

  const previousUsersDatasource = useRef(originalUsers);
  const isMobileDevice = useMobileDevice();

  useEffect(() => {
    setOriginalUsers(datasource);
    seUsers(datasource);
    if (previousUsersDatasource.current !== undefined && previousUsersDatasource.current.length !== 0) {
      onChange([]);
    }
    previousUsersDatasource.current = datasource;
    // adding onParticipantsChange initialParticipants to dependants brakes default participant
    // eslint-disable-next-line
  }, [datasource]);

  const selectUsers = originalUsers => (newSelectedUsers = []) => {
    seUsers([...differenceBy(originalUsers, newSelectedUsers, p => p[optionValueKey])]);
    onChange(newSelectedUsers);
  };

  const removeUser = (originalUsers, selectedUsers) => option => {
    const newSelectedUsers = selectedUsers.filter(e => e !== option);
    seUsers([...differenceBy(originalUsers, newSelectedUsers, p => p[optionValueKey])]);
    onChange(newSelectedUsers);
  };

  const CustomOption = customOptionTemplateFunction;
  return (
    <div className={cx('eds-users-select', className)}>
      <ReactSuperSelect
        controlId={'users-selector'}
        dataSource={users}
        clearable={false}
        searchable
        disabled={disabled}
        clearSearchOnSelection
        id={'users-selector'}
        initialValue={initialUsers}
        optionValueKey={optionValueKey}
        placeholder={label}
        multiple={true}
        deselectOnSelectedOptionClick={false}
        tags={true}
        closeOnSelectedOptionClick={true}
        customSelectedValueTemplateFunction={() => label}
        onChange={selectUsers(originalUsers)}
        customOptionTemplateFunction={item => <CustomOption item={item} />}
      />
      <div className="selected-groups-container">
        <div className="selected-group">
          {initialUsers &&
            initialUsers.map((item, key) => (
              <div className={cx('group-item mt-3', { mobile: isMobileDevice })} key={key}>
                <CustomOption
                  item={item}
                  showOrganizerLabel={organizerUser && organizerUser[optionValueKey] === item[optionValueKey]}
                  showDisabledLabel={isDisabledAuthority(item)}
                />
                <div className="button-container">
                  <IconButton color="grey" onClick={() => removeUser(originalUsers, initialUsers)(item)}>
                    cancel
                  </IconButton>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export function AppointmentUserInfo({ item, className, showOrganizerLabel = false, showDisabledLabel = false }) {
  return (
    <div className={cx('user-info', className)}>
      <div className="label">{item.label}</div>
      <div className="name">
        {`${item.firstName} ${item.lastName}`}
        {showOrganizerLabel && <span className="label lightgrey pl-1">{'Organizer'}</span>}
      </div>
      {showDisabledLabel && <div className="name c-p">DISABLED</div>}
    </div>
  );
}

export function TasksUserInfo({ item, className }) {
  return (
    <div className={cx('user-info', className)}>
      <div className="label">{resolvePersonnelRoles(item).roles}</div>
      <div className="value">{`${item.lastName} ${item.firstName}`}</div>
      {resolvePersonnelRoles(item).disabled && <div className="name c-p">DISABLED</div>}
    </div>
  );
}
