import React, { useContext } from 'react';
import { isEmpty } from 'lodash/lang';

import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';
import { MANAGE_REVENUE_EVENTS } from '../../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../../services/auth';
import { PageInfoHeader } from '../../../../../../PageInfoHeader/PageInfoHeader';
import { generateUrlByKey } from '../../../../../../root/router';
import { AdjustmentContext } from '../../AdjustmentContext';

import './LedgerEventInfo.scss';

export function LedgerEventInfo() {
  const { metadata, newTableEvents, save, disableSaveButton } = useContext(AdjustmentContext);
  const NA = 'N/A';
  return (
    <PageInfoHeader
      objectRecordLabel={metadata?.finBudgetRowName}
      pageInfo={
        <PageInfoHeader.CollapsibleList>
          <PageInfoHeader.AdditionalInfo tooltip="Study">{metadata?.studyName}</PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo tooltip="Site">{metadata?.siteName}</PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo tooltip="Subject ID">
            {metadata?.subjectId || NA}
          </PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo tooltip="Encounter">
            {metadata?.encounterName || NA}
          </PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo tooltip="Type">{metadata?.type || NA}</PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo tooltip="Event">{metadata?.event || NA}</PageInfoHeader.AdditionalInfo>
        </PageInfoHeader.CollapsibleList>
      }
      right={
        <ButtonGroup>
          <Button to={generateUrlByKey('Revenue')} size="h28" priority="medium">
            Cancel
          </Button>
          {userHasAccessTo(MANAGE_REVENUE_EVENTS) && (
            <Button onClick={save} size="h28" disabled={isEmpty(newTableEvents) || disableSaveButton}>
              Save
            </Button>
          )}
        </ButtonGroup>
      }
    />
  );
}
