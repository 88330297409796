import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash/object';

import { getUserPreferences, setUserPreferences } from '../../../actions/userPreferencesAction';
import Toggle from '../../../common/data-entry/Toggle';
import { CALENDAR_INVITATIONS_PREFERENCES } from '../../../constants/accessToPages';

import { APPOINTMENTS_DESCRIPTION } from './constants';

export default function CalendarInvitationsPreferences({ currentUser }) {
  const userPreferences = useSelector(state => state.userPreferences);
  const dispatch = useDispatch();

  useEffect(() => {
    currentUser &&
      currentUser.accessToPages.includes(CALENDAR_INVITATIONS_PREFERENCES) &&
      dispatch(getUserPreferences());
  }, [dispatch, currentUser]);

  const updatePreference = (preference, enabled) => {
    const preferences = userPreferences || {};
    preferences[preference] = enabled;
    dispatch(setUserPreferences(preferences));
  };

  return (
    <>
      {currentUser.accessToPages.includes(CALENDAR_INVITATIONS_PREFERENCES) && (
        <section className="px-3 pb-3 pt-2 mt-3">
          <h5>Calendar Invitations</h5>
          <div className="preference mb-2 ml-3">
            <div>
              <div className="name">Appointments</div>
              <div className="description">{APPOINTMENTS_DESCRIPTION}</div>
            </div>
            <Toggle
              name="Appointments"
              checked={get(userPreferences, 'syncWithEmail', false)}
              onChange={e => updatePreference('syncWithEmail', e.target.checked)}
            />
          </div>
        </section>
      )}
    </>
  );
}
