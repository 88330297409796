import React from 'react';

import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../../common/general/Button';

import './ViewProgressNote.scss';
export default function ViewProgressNoteModal({ modalBox, progressNote }) {
  return (
    <>
      <ModalBoxes.Header>Note</ModalBoxes.Header>
      <ModalBoxes.Body>
        <div>{progressNote.note}</div>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <Button priority="medium" onClick={modalBox.close}>
          CLOSE
        </Button>
      </ModalBoxes.Footer>
    </>
  );
}

ViewProgressNoteModal.className = 'view-progress-note-modal';
ViewProgressNoteModal.size = 'w800';
