import React, { useContext, useEffect, useState } from 'react';
import { isEmpty } from 'lodash/lang';
import * as PropTypes from 'prop-types';

import { PatientEncounterReviewApi } from '../../../../../../../../../api';
import ModalBoxes from '../../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { ReviewContext } from '../../../../ReviewContext';
import ElementHistoryLogModal from '../../../HistoryLogModal/ElementHistoryLogModal';
import * as reviewContentService from '../../../reviewContentService';
import QuestionAnswer from '../../ItemGroupProcedure/QuestionAnswer/QuestionAnswer';
import {
  filterMetaDataComment,
  findQuestionLabel,
  getFieldValue,
  isAnswerEdited,
  isAnswerEditedAfterSign,
  processMultiselectAnswerValues,
  sortBySequence
} from '../ItemGroupNewTableService';

import './OverviewItemGroupNewTableContent.scss';

export default function OverviewItemGroupNewTableContent({ itemGroupSnapshotState }) {
  const [answersToRender, setAnswersToRender] = useState([]);
  const { reviewType, ssuPatientId } = useContext(ReviewContext);

  const {
    general,
    itemGroupRef,
    itemGroupTemplateVersion,
    rows: [{ rowReviewStatus }]
  } = itemGroupSnapshotState;

  const { isHistoricalData } = useContext(ReviewContext);

  const formHighlightStyle = reviewContentService.getFormHighlightStyleBasedOnRowReviewStatusAndReviewType(
    rowReviewStatus,
    reviewType
  ).className;

  useEffect(() => {
    setAnswersToRender(mapGeneralSnapshotStateToQuestionAnswer(general?.rowSnapshotFormData?.form, itemGroupRef));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [general, itemGroupRef, itemGroupTemplateVersion?.itemGroupVersionQuestions]);

  return (
    <div className="eds-review-item-group-element-table-reflection">
      {!isEmpty(answersToRender) && (
        <div className="overview-content">
          <div className="overview-title">
            <div className="new-table-review-title">
              <span>Overview</span>
            </div>

            <div className="overview-history">
              {!isHistoricalData && (
                <div className="overview-history-link" onClick={getOverviewHistory}>
                  History
                </div>
              )}
            </div>
          </div>

          <div className={formHighlightStyle}>
            {answersToRender.map((answer, key) => {
              return <QuestionAnswer answer={answer} key={key} />;
            })}
          </div>
        </div>
      )}
    </div>
  );

  function mapGeneralSnapshotStateToQuestionAnswer(generalRowSnapshotFormDataForm, itemGroup) {
    if (generalRowSnapshotFormDataForm?.answers?.length === 0) {
      return [];
    }

    const commentQuestionField = getCommentQuestionFiled();
    return generalRowSnapshotFormDataForm?.answers
      ?.filter(answer => !reviewContentService.isCommentAnswer(answer))
      .filter(answer => !reviewContentService.isAnswerAffectedByConditionalLogic(answer))
      .filter(answer => answer?.itemGroupQuestionId !== commentQuestionField?.questionId)
      .map(answer => {
        return {
          changeReason: answer.changeReason,
          commentAnswer: answer.commentAnswer,
          emptyReason: answer.emptyReason,
          fileValue: answer.fileValue,
          highlighted: answer.highlighted,
          inputType: answer.inputType,
          sequence: answer.sequence,
          stringValue: getFieldValue(answer.inputType, answer.stringValue),
          type: answer.type,
          question: findQuestionLabel(answer?.itemGroupQuestionId, itemGroupTemplateVersion),
          isEdited: isAnswerEdited(answer, general),
          isEditedAfterSign: isAnswerEditedAfterSign(answer, general)
        };
      })
      ?.sort(sortBySequence);
  }

  function getCommentQuestionFiled() {
    return itemGroupTemplateVersion?.itemGroupVersionQuestions?.find(question => {
      return question.name === 'Comment' && question.label === 'Comment';
    });
  }

  function getOverviewHistory() {
    const ssuPatientEncounterId = itemGroupSnapshotState.itemGroupRef.patientEncounterId;
    const itemGroupId = itemGroupSnapshotState.itemGroupRef.patientItemGroupId;
    const rowId = general?.rowSnapshotFormData?.rowId;
    const itemGroupName = `${itemGroupSnapshotState.itemGroupRef.patientItemGroupName} Overview`;
    const onLoadData = () =>
      PatientEncounterReviewApi.getItemGroupGeneralAnswerHistory(
        ssuPatientId,
        ssuPatientEncounterId,
        itemGroupId,
        rowId
      )
        ?.then(filterMetaDataComment)
        ?.then(processMultiselectAnswerValues);

    ModalBoxes.open({
      component: <ElementHistoryLogModal itemGroupName={itemGroupName} onLoadData={onLoadData} />,
      title: itemGroupName
    });
  }
}

OverviewItemGroupNewTableContent.propTypes = {
  itemGroupSnapshotState: PropTypes.object
};
