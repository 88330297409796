import { get } from 'lodash/object';

import { SCHEDULING_WORK_LIST_FILTERS } from '../../../../constants/sessionStorageConstants';
import store from '../../../../store';

function getUserId() {
  return get(store.getState(), 'currentUser.personnelIdentifier');
}

function getUserRole() {
  return get(store.getState(), 'currentUser.activeRole');
}

function getValue(key) {
  const userId = getUserId();
  const role = getUserRole();
  const filterString = localStorage.getItem(key) || '{}';
  const filterElement = JSON.parse(filterString);
  return get(filterElement, `${userId}-${role}`);
}

function setValue(key, value) {
  const userId = getUserId();
  const role = getUserRole();
  const filterString = localStorage.getItem(key) || '{}';
  const filterElement = JSON.parse(filterString);
  filterElement[`${userId}-${role}`] = value;
  localStorage.setItem(key, JSON.stringify(filterElement));
}

export function getStoredFilters() {
  return getValue(SCHEDULING_WORK_LIST_FILTERS);
}

export function setStoredFilters(val) {
  return setValue(SCHEDULING_WORK_LIST_FILTERS, val);
}
