import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { filter } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

import { StudyApi } from '../../../../../api';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import Icon from '../../../../../common/general/Icon';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { STUDY_TYPES_UPDATED } from '../../../../../constants/notificationMessages';
import { MANAGE_STUDY_COST_PER_MILEAGE, MANAGE_STUDY_TYPES } from '../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../services/auth';
import CostPerMileageUpdateModal from '../CostPerMileageUpdateModal';
import StudyDetailsList from '../StudyDetailsSection/StudyDetailsList/StudyDetailsList';
import StudyDetailsSection from '../StudyDetailsSection/StudyDetailsSection';

import ManageStudyTypesModal from './ManageStudyTypesModal/ManageStudyTypesModal';

export default function StudyTypeSetup({ studyDetails, handleCostPerMileageUpdate, onStudyTypeAndParentChange }) {
  const studyId = studyDetails.unique_identifier;
  const [type, setType] = useState(null);
  const [typeParents, setTypeParents] = useState(null);

  const allTypes = useMemo(
    function() {
      if (isEmpty(typeParents)) return [];
      return typeParents.flatMap(parentType => parentType.types);
    },
    [typeParents]
  );

  const fetchStudyTypes = useCallback(() => {
    if (!studyId) return;
    StudyApi.getStudyTypes(studyId).then(function({ data }) {
      setTypeParents(data.typeParents);
      setType(data.type);
    });
  }, [studyId]);

  useEffect(() => {
    fetchStudyTypes();
  }, [fetchStudyTypes]);

  return (
    <>
      <StudyDetailsSection>
        <StudyDetailsSection.Header>
          Study Type:{' '}
          <StudyDetailsSection.StudyName>
            {studyDetails.study_name} {getTypeName()}
          </StudyDetailsSection.StudyName>
        </StudyDetailsSection.Header>
        <StudyDetailsSection.Body>
          <StudyDetailsList>
            <StudyDetailsList.Header
              rightSide={
                userHasAccessTo(MANAGE_STUDY_TYPES) && (
                  <Button size="h28" priority="low" onClick={openAddModal}>
                    <Icon suit="glyphicon">edit</Icon>
                  </Button>
                )
              }
            >
              Study Identifier
            </StudyDetailsList.Header>
            <StudyDetailsList.Container>
              {filter(allTypes, 'selected').map(function({ id, name }) {
                return <StudyDetailsList.Item key={id}>{name}</StudyDetailsList.Item>;
              })}
            </StudyDetailsList.Container>
          </StudyDetailsList>
        </StudyDetailsSection.Body>
      </StudyDetailsSection>

      {isTypeSelected('Reimbursement') && (
        <StudyDetailsSection>
          <StudyDetailsSection.Header>Patient Reimbursement</StudyDetailsSection.Header>
          <StudyDetailsSection.Body>
            <div className="col-md-2 pl-0 pt-3">
              <div className="form-group">
                <label htmlFor="site">
                  <b>Cost per Mileage</b>
                </label>
                <div className="input-group">
                  <span className="col-9 mr-0 p-0">
                    <input
                      type="text"
                      className="form-control"
                      value={studyDetails.costPerMileage ? '$' + (studyDetails.costPerMileage / 100).toFixed(2) : ''}
                      disabled
                    />
                  </span>
                  <span className="col-3 pt-1 pr-0">
                    {userHasAccessTo(MANAGE_STUDY_COST_PER_MILEAGE) ? (
                      <i className="glyphicon glyphicon-edit pr-2 editicn" onClick={openCostPerMileageModal} />
                    ) : (
                      ''
                    )}
                  </span>
                </div>
              </div>
            </div>
          </StudyDetailsSection.Body>
        </StudyDetailsSection>
      )}
    </>
  );

  function getTypeName() {
    if (type === 'E_SOURCE') {
      return '[eSource]';
    } else if (type === 'CTMS') {
      return '[CTMS]';
    }
  }

  function isTypeSelected(typeName) {
    return allTypes
      .filter(type => type.selected === true)
      .map(type => type.name)
      .includes(typeName);
  }

  function openAddModal() {
    const initialTypeIds = isEmpty(typeParents)
      ? []
      : typeParents
          .flatMap(studyType => studyType.types)
          .filter(type => type.selected)
          .map(type => type.id)
          .sort();
    ModalBoxes.open({
      component: (
        <ManageStudyTypesModal
          studyId={studyId}
          initialType={type}
          initialTypeIds={initialTypeIds}
          typeParents={typeParents}
          allTypes={allTypes}
          onStudyTypeAndParentChange={onStudyTypeAndParentChange}
        />
      ),
      onClose: message => {
        if (message === STUDY_TYPES_UPDATED) {
          NotificationManager.success(STUDY_TYPES_UPDATED);
          fetchStudyTypes();
        }
      }
    });
  }

  function openCostPerMileageModal() {
    ModalBoxes.open({
      component: (
        <CostPerMileageUpdateModal
          currentCostPerMileage={studyDetails.costPerMileage}
          handleCostPerMileageUpdate={handleCostPerMileageUpdate}
        />
      )
    });
  }
}
