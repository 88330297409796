import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { isEmpty, isString } from 'lodash';

import { ProgressNoteApi } from '../../../../../../../../api';
import { LoadingHandler } from '../../../../../../../../common/elements/Loader/LoadingHandler';
import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import NotesInfo from '../AddOrEditProgressNoteModal/NotesInfo';

import NotesTable from './NotesTable/NotesTable';

import './AllProgressNotesModal.scss';

export default function AllProgressNotesModal({
  ssuPatientId,
  progressNote,
  isAllowedToManage,
  editProgressNote,
  addProgressNote,
  notesInfo,
  reloadProgressNotes
}) {
  const [loading, setLoading] = useState(true);
  const [progressNotes, setProgressNotes] = useState(null);

  useEffect(
    function() {
      let canceled = false;
      setLoading(true);
      if (!isString(ssuPatientId) || isEmpty(ssuPatientId)) {
        return;
      }
      ProgressNoteApi.getProgressNotesVersions(ssuPatientId).then(({ data }) => {
        if (canceled) {
          return;
        }
        setLoading(false);
        setProgressNotes(data);
        if (reloadProgressNotes) {
          reloadProgressNotes();
        }
      });
      return function() {
        canceled = true;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ssuPatientId]
  );

  return (
    <React.Fragment>
      <ModalBoxes.Header />
      <div className="notes-info">
        <div className="notes-title">
          Patient Study Notes
          {isAllowedToManage && (
            <Button size="small" variant="text" onClick={addProgressNote}>
              ADD NOTE
            </Button>
          )}
        </div>
        <NotesInfo notesInfo={notesInfo} />
      </div>
      <ModalBoxes.Body>
        <LoadingHandler complete={!loading} dark={true}>
          {isEmpty(progressNotes) ? (
            <div className="apn-list-no-data">There are currently no notes</div>
          ) : (
            <NotesTable
              progressNotes={progressNotes}
              editProgressNote={editProgressNote}
              progressNote={progressNote}
              isAllowedToManage={isAllowedToManage}
              ssuPatientId={ssuPatientId}
            />
          )}
        </LoadingHandler>
      </ModalBoxes.Body>
    </React.Fragment>
  );
}

AllProgressNotesModal.className = 'all-progress-note-modal';
