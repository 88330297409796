import React from 'react';

const HighlightedPartOfWord = ({ word, reg }) => {
  if (reg && word) {
    const parts = word.split(reg);
    return (
      <>
        {parts.map((part, index) =>
          part?.match(reg) ? (
            <span className="found" key={index}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </>
    );
  }
  return <>{word}</>;
};

export default HighlightedPartOfWord;
