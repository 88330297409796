import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { afterburnerApi } from '../../../api/patient/AftrburnerApi';
import ModalBoxes from '../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../common/general/Button';
import NotificationManager from '../../../common/notifications/NotificationManager';
import StyledTooltip from '../../../common/widgets/StyledToolTip';
import { SOMETHING_WENT_WRONG } from '../../../constants/notificationMessages';

import './AfterburnerLog.scss';

function AdditionalMappingInfoModal({ logId, data }) {
  const [logDetails, setLogDetails] = useState({});
  console.log(data);
  useEffect(() => {
    afterburnerApi
      .getLogDetailsByLogId(logId)
      .then(({ data }) => {
        const preparedLogDetails = {
          url: data.url,
          eventTrigger: data.eventTrigger,
          triggeringUser: data.triggeringUser,
          responseCode: data.responseCode,
          responseMessage: data.responseMessage,
          dataSent: data.dataSent
        };
        setLogDetails(preparedLogDetails);
      })
      .catch(() => {
        NotificationManager.error(SOMETHING_WENT_WRONG);
      });
  }, [logId]);

  const exportPreview = () => {
    const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(logDetails.dataSent)}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = data.row.studyName + '_' + data.row.protocolVersion + '_' + data.row.platformName + '.json';
    link.click();

    NotificationManager.success('Full Payload exported successfully');
  };

  return (
    <ModalBoxes.Body>
      <div className="log-info">
        <div className="log-row">
          <div className="log-row-header">Url</div>
          <div className="white-spase" />
          <div className="log-row-value">
            <StyledTooltip
              className="mapping-dropdown-tooltip"
              overlay={<div>{logDetails?.url ? logDetails?.url : '--'}</div>}
              placement="top"
            >
              <div>{logDetails?.url ? logDetails?.url : '--'}</div>
            </StyledTooltip>
          </div>
        </div>
        <div className="log-row">
          <div className="log-row-header">Event Trigger</div>
          <div className="white-spase" />
          <div className="log-row-value">{logDetails?.eventTrigger ? logDetails?.eventTrigger : '--'}</div>
        </div>
        <div className="log-row">
          <div className="log-row-header">Triggering User</div>
          <div className="white-spase" />
          <div className="log-row-value">{logDetails?.triggeringUser ? logDetails?.triggeringUser : '--'}</div>
        </div>
        <div className="log-row">
          <div className="log-row-header">Response Code</div>
          <div className="white-spase" />
          <div className="log-row-value">{logDetails?.responseCode ? logDetails?.responseCode : '--'}</div>
        </div>
        <div className="log-row">
          <div className="log-row-header">Response Message</div>
          <div className="white-spase" />
          <div className="log-row-value">{logDetails?.responseMessage ? logDetails?.responseMessage : '--'}</div>
        </div>
        <div className="log-row">
          <div className="log-row-header">Full Payload</div>
          <div className="white-spase" />
          <div className="log-row-value">
            <Button disabled={isEmpty(logDetails.dataSent)} onClick={exportPreview}>
              Export
            </Button>
          </div>
        </div>
      </div>
    </ModalBoxes.Body>
  );
}

export default AdditionalMappingInfoModal;
