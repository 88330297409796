import React from 'react';
import { get } from 'lodash/object';

import { AppointmentViewField } from '../AppointmentViewField';

export function MilestoneAppointmentViewContent({ appointment }) {
  return (
    <React.Fragment>
      <AppointmentViewField label={'Event Name'} className="mb-4" primary={true}>
        <div className={'normal'}>{get(appointment, 'subject')}</div>
      </AppointmentViewField>
      <AppointmentViewField label={'PCN'} className="mb-4">
        <div className={'normal'}>{get(appointment, 'pcn')}</div>
      </AppointmentViewField>
      <AppointmentViewField label={'Study'} className="mb-4">
        <div className={'normal'}>{get(appointment, 'studySite.study.name')}</div>
      </AppointmentViewField>
      <AppointmentViewField label={'Site'}>
        <div className={'normal'}>{get(appointment, 'studySite.site.name')}</div>
      </AppointmentViewField>
    </React.Fragment>
  );
}
