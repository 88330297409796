import React from 'react';

import { EventFinderApi, SitePatientEncounterApi } from '../../../../api';
import LogRowStatusHistoryApi from '../../../../api/patient/LogRowStatusHistoryApi';
import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import { DATA_SAVED } from '../../../../constants/notificationMessages';
import { onRequestError } from '../../../../services/handlers';

import CancelAdverseEventLogRowModal from './EncounterDescNew/CancelAdverseEventLogRowModal';
import CancelEncounterModal from './EncounterDescNew/CancelEncounterModal';

export const IS_SCHEDULED_ENCOUNTER = 'IS_SCHEDULED_ENCOUNTER';
export const IS_DEFAULT_ENCOUNTER = 'IS_DEFAULT_ENCOUNTER';

function _doCancelSitePatientEncounter(ssuPatientId, ssuPatientEncounterId, comment) {
  const payload = {
    reason: comment
  };
  return SitePatientEncounterApi.cancelSitePatientEncounter(ssuPatientId, ssuPatientEncounterId, payload)
    .then(res => {
      NotificationManager.success(DATA_SAVED);
      return res;
    })
    .catch(onRequestError);
}

function _doCancelScheduledSitePatientEncounter(comment, ssuPatientId, encounterEvent) {
  const payload = {
    reason: comment
  };
  return SitePatientEncounterApi.checkinAndCancelSitePatientEncounter(ssuPatientId, encounterEvent?.id, payload)
    .then(res => {
      NotificationManager.success(DATA_SAVED);
      return res;
    })
    .catch(onRequestError);
}

function _doReopenSitePatientEncounter(ssuPatientId, ssuPatientEncounterId) {
  return SitePatientEncounterApi.reopenSitePatientEncounter(ssuPatientId, ssuPatientEncounterId)
    .then(res => {
      NotificationManager.success(DATA_SAVED);
      return res;
    })
    .catch(onRequestError);
}

/**
 * @deprecated Please use _cancelPatientEncounter_ instead
 */
export function cancelSitePatientEncounter(patientId, ssuPatientId, protocolEncounterId, func, encounterName) {
  EventFinderApi.getEventsForCancelEncounter(ssuPatientId, protocolEncounterId).then(function({ data: appointments }) {
    const cancelEncounterModal = ModalBoxes.open({
      component: (
        <CancelEncounterModal
          onConfirm={comment => {
            _doCancelSitePatientEncounter(ssuPatientId, patientId, comment).then(() => {
              func();
              cancelEncounterModal.close();
            });
          }}
          encounterName={encounterName}
          appointments={appointments}
        />
      )
    });
  });
}

export function cancelAdverseEventLogRow(
  studyId,
  ssuPatientId,
  itemGroupTemplateId,
  itemGroupTemplateVersionId,
  rowId,
  itemGroupName
) {
  ModalBoxes.open({
    component: (
      <CancelAdverseEventLogRowModal
        studyId={studyId}
        ssuPatientId={ssuPatientId}
        itemGroupTemplateId={itemGroupTemplateId}
        itemGroupTemplateVersionId={itemGroupTemplateVersionId}
        rowId={rowId}
        itemGroupName={itemGroupName}
      />
    )
  });
}

export function reopenAdverseEventLogRow(
  studyId,
  ssuPatientId,
  itemGroupTemplateId,
  itemGroupTemplateVersionId,
  rowId
) {
  return new Promise((resolve, reject) => {
    LogRowStatusHistoryApi.reopenAdverseEventLogRow(
      studyId,
      ssuPatientId,
      itemGroupTemplateId,
      itemGroupTemplateVersionId,
      rowId
    )
      .then(() => {
        NotificationManager.success('Row Reopened successfully');
        resolve();
      })
      .catch(err => reject(err));
  });
}

export function cancelPatientEncounter(
  patientId,
  ssuPatientId,
  encounterEvent,
  encounterName,
  func,
  encounterActionType,
  currentStudy
) {
  const { protocolEncounterId } = encounterEvent;

  EventFinderApi.getEventsForCancelEncounter(ssuPatientId, protocolEncounterId).then(function({ data: appointments }) {
    const cancelEncounterModal = ModalBoxes.open({
      component: (
        <CancelEncounterModal
          onConfirm={comment => {
            switch (encounterActionType) {
              case IS_SCHEDULED_ENCOUNTER:
                _doCancelScheduledSitePatientEncounter(comment, ssuPatientId, encounterEvent).then(() => {
                  func();
                  cancelEncounterModal.close();
                });
                break;
              case IS_DEFAULT_ENCOUNTER:
                _doCancelSitePatientEncounter(ssuPatientId, patientId, comment).then(() => {
                  func();
                  cancelEncounterModal.close();
                });
                break;
              // eslint-disable-next-line no-fallthrough
              default:
                throw new Error('EncounterActionType not chosen');
            }
          }}
          encounterName={encounterName}
          appointments={appointments}
          currentStudy={currentStudy}
        />
      )
    });
  });
}

export function reopenSitePatientEncounter(ssuPatientId, patientId, func) {
  ModalBoxes.confirm({
    content: ' Are you sure you want to Reopen the Encounter?',
    confirmButton: 'Yes',
    cancelButton: 'No'
  }).then(
    () =>
      _doReopenSitePatientEncounter(ssuPatientId, patientId).then(() => {
        func();
      }),
    () => {}
  );
}
