import { isEmpty } from 'lodash/lang';

import { getCurrentRouteByLocation } from '../../../../router/service';

import { OTHER } from './taskConstants';

const validationKeys = ['topic', 'topicTitle', 'dueDate', 'assigneeDto', 'studyId', 'siteId'];

export const defaultFieldsValidation = {};

validationKeys.forEach(value => {
  defaultFieldsValidation[value] = true;
});

export function validateTask(task, setTaskFormValidationMap) {
  if (task.topic === OTHER && task.topicTitle?.length > 30) {
    return false;
  }
  const fieldsValidation = doValidationKeys(task);
  setTaskFormValidationMap(fieldsValidation);
  return Object.values(fieldsValidation).indexOf(false) < 0 && isEmpty(fieldsValidation.taskLinks);
}

function doValidationKeys(task) {
  const fieldsValidation = {};
  validationKeys.forEach(valueKey => {
    if (valueKey === 'assigneeDto') {
      fieldsValidation[valueKey] = !isEmpty(task[valueKey].personnelIds);
    } else if (valueKey === 'topicTitle') {
      if (task.topic === OTHER) {
        fieldsValidation[valueKey] = Boolean(task[valueKey]);
      }
    } else {
      fieldsValidation[valueKey] = Boolean(task[valueKey]);
    }
  });
  fieldsValidation.taskLinks = [];
  if (!isEmpty(task.taskLinks)) {
    task.taskLinks
      .filter(e => !e.removed && e.externalLink)
      .forEach(link => {
        const isInvalidLink = !isEmpty(link.link) && !validateLink(link.link);
        if (isInvalidLink || isEmpty(link.link) || isEmpty(link.linkComment)) {
          fieldsValidation.taskLinks = [...fieldsValidation.taskLinks, link.id];
        }
      });
  }
  return fieldsValidation;
}

function validateLink(url) {
  const regex = new RegExp('https?://');
  return regex.test(url);
}

export function getTopicNameValidationMessage(topicTitle, isTopicTitlePresent) {
  if (topicTitle?.length > 30) {
    return 'Topic Name must be maximum 30 characters';
  }
  if (!isTopicTitlePresent) {
    return 'Topic Name is required';
  }
  return '';
}

export function toIdWithName(collection) {
  return collection.map((e, idx) => ({ name: e, id: idx }));
}

export function getRouteByPathname(pathname) {
  return getCurrentRouteByLocation({ pathname });
}
