import React, { useContext, useMemo, useState } from 'react';
import { isEmpty } from 'lodash/lang';

import { getItemGroupType } from '../ReviewContent/reviewContentService';
import { ReviewContext } from '../ReviewContext';
import {
  itemGroupSnapshotReviewStateHasRecordsOrNotPerformed,
  logCheckSnapshotReviewStateHasRecords,
  logSnapshotReviewStateHasRecords
} from '../reviewService';

import { ReviewSidebarHeader } from './ReviewSidebarHeader/ReviewSidebarHeader';
import ReviewSidebarItem from './ReviewSidebarItem/ReviewSidebarItem';
import * as logStatusService from './logStatusService';
import * as procedureLikeService from './procedureLikeStatusService';

import './ReviewSideBar.scss';

export function ReviewSideBar() {
  const {
    itemGroups,
    logsAndLogChecks,
    itemGroupSnapshotReviewStates,
    logAndLogCheckSnapshotReviewStates,
    isHistoricalData,
    requiredAttentionItemGroupsConfirmStatuses,
    activeScrollId
  } = useContext(ReviewContext);

  const [searchText, setSearchText] = useState('');

  const [filteredItemGroups, filteredLogsAndLogChecks] = useMemo(
    function() {
      if (!searchText) {
        return [itemGroups, logsAndLogChecks];
      }

      return [
        itemGroups.filter(function({ patientItemGroupName }) {
          /*TODO: need to fix*/
          return patientItemGroupName.toLowerCase().includes(searchText.toLowerCase());
        }),
        logsAndLogChecks.filter(function({ itemGroupName }) {
          /*TODO: need to fix*/
          return itemGroupName.toLowerCase().includes(searchText.toLowerCase());
        })
      ];
    },
    [searchText, itemGroups, logsAndLogChecks]
  );

  return (
    <div className="erv-review-sidebar">
      <ReviewSidebarHeader searchText={searchText} setSearchText={setSearchText} />
      {!isEmpty(filteredItemGroups) && (
        <div className="erv-review-sidebar-list">
          <h4>Procedures and Assessments</h4>
          <div className="eds-review-sidebar-items">
            {filteredItemGroups.map(item => {
              const itemGroupSnapshotReviewState = itemGroupSnapshotReviewStates[item.patientItemGroupId];
              const highlightedAnswersCount =
                itemGroupSnapshotReviewState?.reviewState?.isAllowSign &&
                itemGroupSnapshotReviewState?.itemGroupSnapshotState?.dataStatus?.highlightedAnswersCount;
              const isItemGroupConfirmed = requiredAttentionItemGroupsConfirmStatuses[item.patientItemGroupId];
              return (
                <ReviewSidebarItem
                  className={activeScrollId === item.patientItemGroupId ? 'erv-sidebar-item-active' : null}
                  isDataRequired={itemGroupSnapshotReviewState?.reviewState?.isDataRequired}
                  isNotRequired={!itemGroupSnapshotReviewState?.reviewState?.isAllowSign}
                  onClick={() => document.getElementById(item.patientItemGroupId).scrollIntoView()}
                  hasRecords={itemGroupSnapshotReviewStateHasRecordsOrNotPerformed(itemGroupSnapshotReviewState)}
                  key={item.patientItemGroupId}
                  name={item.patientItemGroupName}
                  loading={!itemGroupSnapshotReviewState}
                  highlightedAnswersCount={highlightedAnswersCount}
                  type={getItemGroupType(item)}
                  isNotPerformed={procedureLikeService.isNotPerformed(itemGroupSnapshotReviewStates, item)}
                  isSigned={procedureLikeService.isSigned(itemGroupSnapshotReviewStates, item)}
                  isHistoricalData={isHistoricalData}
                  isItemGroupConfirmed={isItemGroupConfirmed}
                />
              );
            })}
          </div>
        </div>
      )}
      {!isEmpty(filteredLogsAndLogChecks) && (
        <div className="erv-review-sidebar-list">
          <h4>Logs</h4>
          <div className="eds-review-sidebar-items">
            {filteredLogsAndLogChecks.map(item => {
              const logAndLogCheckSnapshotReviewState = logAndLogCheckSnapshotReviewStates[item.itemGroupTemplateId];
              const highlightedAnswersCount =
                logAndLogCheckSnapshotReviewState?.reviewState?.isAllowSign &&
                logAndLogCheckSnapshotReviewState?.dataStatus?.highlightedAnswersCount;
              const isItemGroupConfirmed = requiredAttentionItemGroupsConfirmStatuses[item.itemGroupTemplateId];
              return (
                <ReviewSidebarItem
                  className={activeScrollId === item.itemGroupTemplateId ? 'erv-sidebar-item-active' : null}
                  isDataRequired={logAndLogCheckSnapshotReviewState?.reviewState?.isDataRequired}
                  isNotRequired={!logAndLogCheckSnapshotReviewState?.reviewState?.isAllowSign}
                  isEncounterExcludedLog={logAndLogCheckSnapshotReviewState?.reviewState?.isEncounterExcludedLog}
                  onClick={() => document.getElementById(item.itemGroupTemplateId).scrollIntoView()}
                  key={item.itemGroupTemplateId}
                  name={item.itemGroupName}
                  loading={!logAndLogCheckSnapshotReviewState}
                  type="Logs"
                  isNotPerformed={logStatusService.isNotPerformed(logAndLogCheckSnapshotReviewState)}
                  isSigned={logStatusService.isSigned(logAndLogCheckSnapshotReviewState)}
                  hasRecords={
                    logSnapshotReviewStateHasRecords(logAndLogCheckSnapshotReviewState) ||
                    logCheckSnapshotReviewStateHasRecords(logAndLogCheckSnapshotReviewState)
                  }
                  highlightedAnswersCount={highlightedAnswersCount}
                  isHistoricalData={isHistoricalData}
                  isItemGroupConfirmed={isItemGroupConfirmed}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
