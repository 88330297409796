import React from 'react';
import { useDispatch } from 'react-redux';
import { sortBy } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';
import moment from 'moment';

import { lockEnrollmentsActionCreator } from '../../../../actions/finance/forecasting/forecastingActions';
import { EnrollmentPredictionApi } from '../../../../api';
import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../common/general/Button';
import { LOCK_ENROLLMENT_PREDICTIONS_MONTH } from '../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../services/auth';

export default function LockButton({ lastLockedPeriod, studySites }) {
  const dispatch = useDispatch();
  const lockPeriod = () => dispatch(lockEnrollmentsActionCreator());

  const text = `Lock ${getPeriodToLock(lastLockedPeriod).format('MMMM')}`;
  return (
    <React.Fragment>
      {userHasAccessTo(LOCK_ENROLLMENT_PREDICTIONS_MONTH) && (
        <div
          className="lock-button"
          title={currentMonthLocked() ? 'Will be enabled next month' : null}
          data-testid={'lock-button'}
        >
          <Button size="h28" disabled={currentMonthLocked()} onClick={() => confirmIfPredictionNotFinished(lockPeriod)}>
            {text}
          </Button>
        </div>
      )}
    </React.Fragment>
  );

  function confirmIfPredictionNotFinished(positiveAction) {
    if (!currentMonthLocked()) {
      EnrollmentPredictionApi.getEnrollmentsForActivePeriod(studySites.map(ssu => ssu.uniqueIdentifier)).then(
        ({ data: enrollments }) => {
          const ssusWithoutEnrolments = studySites.filter(v => hasNoOrEmptyEnrollments(enrollments, v));
          if (!isEmpty(ssusWithoutEnrolments)) {
            ModalBoxes.confirm({
              content: enrollmentPeriodWarningMessage(ssusWithoutEnrolments),
              confirmButton: 'Yes',
              cancelButton: 'No'
            }).then(positiveAction, () => {});
          } else {
            positiveAction();
          }
        }
      );
    }
  }

  function enrollmentPeriodWarningMessage(ssusWithoutEnrolments) {
    return (
      <div>
        Enrollment predictions have not been entered for the follow SSUs. Are you sure you want to lock&nbsp;
        {getPeriodToLock(lastLockedPeriod).format('MMMM')}'s predictions?
        {sortBy(
          ssusWithoutEnrolments,
          v => `${v.site.siteName}`,
          v => `${v.study.studyName}`
        ).map(v => (
          <div key={v.uniqueIdentifier}>
            {v.site.siteName} | {v.study.studyName}
          </div>
        ))}
      </div>
    );
  }

  function currentMonthLocked() {
    return moment().diff(lastLockedPeriod, 'month') === 0;
  }

  function getPeriodToLock(lastLockedPeriod) {
    return lastLockedPeriod ? lastLockedPeriod.clone().add(1, 'Month') : moment();
  }

  function hasNoOrEmptyEnrollments(enrollments, v) {
    const initialEnrollment = enrollments.find(
      e => e.studySiteId === v.uniqueIdentifier && e.enrollmentPredictionValues.every(ev => ev.value === 0)
    );
    return initialEnrollment !== undefined;
  }
}
