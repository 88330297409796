import axios from 'axios';

const ROOT = 'api/v1/site-patient';

const PatientDetailsApi = {
  getPatientDetails(ssuPatientId) {
    return axios.get(`${ROOT}/${ssuPatientId}/details`);
  }
};

export default PatientDetailsApi;
