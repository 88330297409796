import React from 'react';

import IconButton from '../../../../../../../common/buttons/IconButton';

import './ActionColumnContent.scss';

export default function ActionColumnContent({ saveButtonDisabled, onSaveButtonClick, onDeleteButtonClick }) {
  return (
    <React.Fragment>
      <span className="patient-profile-medications-table-tooltip-cell">
        <IconButton
          className="tooltip-cell-button"
          disabled={saveButtonDisabled}
          color="blue"
          onClick={onSaveButtonClick}
        >
          save
        </IconButton>
        <IconButton className="tooltip-cell-button" color="grey" onClick={onDeleteButtonClick}>
          delete
        </IconButton>
      </span>
    </React.Fragment>
  );
}

ActionColumnContent.defaultProps = {
  saveButtonDisabled: false
};
