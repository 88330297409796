import axios from 'axios';

const ROOT = 'api/v1/reports';

const DSReportApi = {
  getModeParameters(reportName) {
    return axios.get(`${ROOT}/name/${reportName}/parameters`);
  },
  postModeReportRun(reportName, payload = {}) {
    return axios.post(`${ROOT}/name/${reportName}/run`, payload);
  }
};

export default DSReportApi;
