import React, { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import './WithAutoDirection.scss';

export default function WithAutoDirection({ children }) {
  const ref = useRef();
  const [reverse, setReverse] = useState(false);
  const [mousedown, setMousedown] = useState(false);

  const updateRef = useCallback(() => {
    if (ref && ref?.current?.offsetTop) {
      setReverse(current => {
        return window.innerHeight - ref.current.offsetTop < 670;
      });
    }
  }, []);

  useEffect(
    function() {
      window.addEventListener('resize', updateRef);
      window.addEventListener('mousedown', updateRef);
      return () => {
        window.removeEventListener('resize', updateRef);
        window.removeEventListener('mousedown', updateRef);
      };
    },
    [updateRef]
  );

  useEffect(() => {
    if (ref && ref?.current && !ref.current?.onclick && !ref.current?.onblur) {
      ref.current.onclick = updateRef;
      ref.current.onblur = updateRef;
    }
  }, [ref, updateRef]);

  return (
    <div className={cx('with-auto-direction', { reverse: !!reverse })} ref={ref}>
      {children}
    </div>
  );
}
