import React from 'react';

import './FvPdfViewer.scss';
export function FvPdfViewer({ file }) {
  const { url, name } = file;
  return (
    <div className="eds-fv-pdf-viewer">
      <iframe src={url} title={name} />
    </div>
  );
}
