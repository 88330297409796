import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash/lang';
import * as PropTypes from 'prop-types';

import { EncounterApi } from '../../../../../../../api';
import Input from '../../../../../../../common/data-entry/Input';
import Checkbox from '../../../../../../../common/data-entry/InputSelectors/Checkbox';
import Select from '../../../../../../../common/data-entry/Select';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';
import { MANAGE_PATIENT_APPOINTMENTS } from '../../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../../services/auth';
import { generateUrlByKey, useCurrentRoute } from '../../../../../../root/router';
import {
  NON_PATIENT_EVENT,
  PATIENT_ENCOUNTER_EVENT,
  PATIENT_SITUATIONAL_ENCOUNTER_EVENT
} from '../../../../../Calendar/CalendarEventType';
import { CALENDAR_SELECTED_EVENT } from '../../../../../Calendar/CalendarPageContext';

import './ScheduleAppointmentModal.scss';
export default function ScheduleAppointmentModal({
  modalBox,
  studyId,
  ssuPatientId,
  patientId,
  isWorklistPage,
  encounterId,
  sourcePage,
  siteId,
  siteName
}) {
  const navigate = useNavigate();
  const currentRoute = useCurrentRoute();
  const [lastEnDate, setLastEnDate] = useState({});
  const [selectedEpoch, setSelectedEpoch] = useState(null);
  const [epochData, setEpochData] = useState([]);
  const [epochInfo, setEpochInfo] = useState('');
  const [encounterList, setEncounterList] = useState([]);
  const [selectedEncounter, setSelectedEncounter] = useState(null);
  const [situationalEncounterList, setSituationalEncounterList] = useState([]);
  const [selectedSituationalEncounter, setSelectedSituationalEncounter] = useState(null);
  const [isSituationalChecked, setIsSituationalChecked] = useState(false);

  useEffect(
    function() {
      if (studyId) {
        EncounterApi.getStudyRelatedEpochs(studyId, ssuPatientId).then(({ data: { response, custommessages } }) => {
          setLastEnDate(custommessages);
          if (response) {
            const objKeys = Object.keys(response);
            setEpochData(objKeys.map(v => ({ id: v, name: v })));
            setEpochInfo(response);
          }
        });

        EncounterApi.getAllSituationalEncountersForActiveProtocol(studyId, ssuPatientId).then(response => {
          setSituationalEncounterList(
            response.data?.map(e => {
              return {
                identity: e.key,
                name: e.name
              };
            })
          );
        });
      }
    },
    [studyId, ssuPatientId, isSituationalChecked]
  );

  useEffect(
    function() {
      if (encounterId) {
        Object.keys(epochInfo).forEach(e => {
          const epochIncludeEncounter = Object.values(epochInfo[e]).some(enc => enc === encounterId);
          if (epochIncludeEncounter) {
            setSelectedEpoch({ id: e, name: e });
          }
        });
      }
    },
    [encounterId, epochInfo]
  );

  useEffect(
    function() {
      if (encounterId && selectedEpoch) {
        setSelectedEncounter(encounterList.find(({ identity }) => identity === encounterId));
      }
    },
    [encounterId, selectedEpoch, encounterList]
  );

  useEffect(
    function() {
      setEncounterList(getEncounters(selectedEpoch?.name, epochInfo));
      setSelectedEncounter(null);
    },
    [selectedEpoch, epochInfo, studyId]
  );

  const [lastEncounter, lastEncounterDate] = getLastEncounter(lastEnDate);

  return (
    <>
      <ModalBoxes.Header>Schedule Encounter</ModalBoxes.Header>
      <ModalBoxes.Body>
        {situationalEncounterList && !isEmpty(situationalEncounterList) && (
          <div className="situational-encounter-check-box-section">
            <Checkbox
              label="Situational Encounter"
              checked={isSituationalChecked}
              onChange={() => (isSituationalChecked ? setIsSituationalChecked(false) : setIsSituationalChecked(true))}
            />
          </div>
        )}
        {isSituationalChecked ? renderSituationalEncounterModalBody() : renderEpochEncounterModalBody()}
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            CANCEL
          </Button>
          <Button disabled={!selectedEncounter && !selectedSituationalEncounter} onClick={addAppointment}>
            ADD
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );

  function renderSituationalEncounterModalBody() {
    return (
      <>
        <Select
          label="Situational Encounter"
          onChange={val => setSelectedSituationalEncounter(val || null)}
          value={selectedSituationalEncounter}
          dataSource={situationalEncounterList}
          optionValueKey="identity"
          required
        />
      </>
    );
  }

  function renderEpochEncounterModalBody() {
    return (
      <>
        <div className="row">
          <div className="col-md-6">
            <Input label="Last Encounter Name" value={lastEncounter} readOnly />
          </div>
          <div className="col-md-6">
            <Input label="Last Encounter Date" value={lastEncounterDate} readOnly />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <Select
              clearable
              label="Epoch"
              onChange={onChangeEpoch}
              value={selectedEpoch}
              dataSource={epochData}
              required
            />
          </div>
          <div className="col-md-6">
            <Select
              label="Encounter"
              onChange={encounterChangeHandler}
              value={selectedEncounter}
              dataSource={encounterList}
              optionValueKey="identity"
              required
            />
          </div>
        </div>
      </>
    );
  }

  function addAppointment() {
    modalBox.close();
    if (sourcePage && siteId && siteName) {
      sessionStorage.setItem(CALENDAR_SELECTED_EVENT, JSON.stringify({ sourcePage, siteId, siteName }));
    }
    setTimeout(function() {
      const link = buildCalendarUrl();
      if (isWorklistPage) {
        window.open(link, '_blank');
      } else {
        navigate(link);
      }
    });
  }

  function encounterChangeHandler(value) {
    setSelectedEncounter(value || null);
  }

  function onChangeEpoch(value) {
    setSelectedEpoch(value);
  }

  function buildCalendarUrl() {
    const patientInfoKey = isWorklistPage ? 'Worklist.Patient Profile.Patient Studies' : currentRoute?.key;
    return generateUrlByKey(
      `${patientInfoKey}.Calendar`,
      {
        patientId: patientId,
        ssuPatientId: ssuPatientId
      },
      {
        encounterId: isSituationalChecked ? selectedSituationalEncounter?.identity : selectedEncounter?.identity,
        sitePatientId: ssuPatientId,
        encounterEventType: resolveEncounterEventType()
      }
    );
  }

  function resolveEncounterEventType() {
    if (isSituationalChecked) {
      return PATIENT_SITUATIONAL_ENCOUNTER_EVENT;
    } else {
      return userHasAccessTo(MANAGE_PATIENT_APPOINTMENTS) ? PATIENT_ENCOUNTER_EVENT : NON_PATIENT_EVENT;
    }
  }
}

function getEncounters(epochName, epochInfo) {
  if (epochName) {
    const epochInfoElement = epochInfo[epochName];
    const keyList = ['name', 'min', 'max', 'type', 'identity'];
    return Object.keys(epochInfoElement).map(encounter => {
      const valueList = encounter.split('$$#$$');
      valueList.push(epochInfoElement[encounter]);
      return Object.assign(...keyList.map((v, i) => ({ [v]: valueList[i] })));
    });
  } else {
    return [];
  }
}

function getLastEncounter(lastEnDate) {
  const data = [];
  for (const variable in lastEnDate) {
    data[0] = variable;
    data[1] = lastEnDate[variable];
  }
  return data;
}

ScheduleAppointmentModal.defaultProps = {
  isWorklistPage: false
};

ScheduleAppointmentModal.propTypes = {
  ssuPatientId: PropTypes.string,
  studyId: PropTypes.string,
  isWorklistPage: PropTypes.bool,
  encounterId: PropTypes.string
};

ScheduleAppointmentModal.className = 'schedule-appointment-modal';
ScheduleAppointmentModal.size = 'w650';
