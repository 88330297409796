import React, { useContext, useMemo } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import cx from 'classnames';
import { some } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

import Checkbox from '../../../../../../../common/data-entry/InputSelectors/Checkbox';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import Icon from '../../../../../../../common/general/Icon';
import { scBlue100 } from '../../../../../../../constants/systemColors';
import { TableSetupContext } from '../../../../../../../contexts/TableSetupContext';

import AddCustomItemModal from './AddCustomItemModal/AddCustomItemModal';
import TableFieldCustomItem from './TableFieldCustomItem/TaleFieldCustomItem';
import { onDragEnd } from './TableFieldItem/EditCustomOptionListModal/EditCustomOptionListModal';
import TableFieldItem from './TableFieldItem/TableFieldItem';

import './TableField.scss';

const TableField = () => {
  const tableSetupContext = useContext(TableSetupContext);
  const params = useParams();

  const addCustomItem = () => {
    ModalBoxes.open({
      component: (
        <AddCustomItemModal
          addNewCustomFieldItem={tableSetupContext.addNewCustomFieldItem}
          protocolId={params.protocolIdentity}
          terminologyVersionId={tableSetupContext.selectedTerminologyVersionGroups}
          isTemplateMode={tableSetupContext.isTemplateMode}
          fieldList={tableSetupContext.fieldList}
          generalFields={tableSetupContext.generalFields}
        />
      )
    });
  };

  const uncheckAllItems = () => {
    ModalBoxes.confirm({
      content: 'Unchecking this checkbox will remove field configurations in this Item Group. Uncheck anyway?',
      confirmButton: 'Yes',
      cancelButton: 'No',
      className: 'edit-custom-field-logic-modal',
      title: 'Toggle Configuration'
    })
      .then(() => {
        tableSetupContext.toggleSelectAllItems(allItemsSelected);
      })
      .catch(e => {});
  };

  const fieldsForDisplay = useMemo(() => {
    if (tableSetupContext.selectedOnly) {
      return tableSetupContext.fieldList.filter(field => field.isChecked);
    }
    return tableSetupContext.fieldList;
  }, [tableSetupContext.fieldList, tableSetupContext.selectedOnly]);

  const allItemsSelected = useMemo(() => {
    if (tableSetupContext.fieldList.length) {
      return !some(tableSetupContext.fieldList, ['isChecked', false]);
    }
    return false;
  }, [tableSetupContext.fieldList]);

  return (
    <div className="setting-wrapper field-wrapper scrollable">
      <div className="setting-header">
        <h5>Results</h5>
        <div className="view-options">
          <Checkbox
            color={scBlue100}
            label="Selected Only"
            checked={tableSetupContext.selectedOnly}
            onChange={() => {
              tableSetupContext.setSelectedOnly(!tableSetupContext.selectedOnly);
              tableSetupContext.reorderSelectedItems(false);
            }}
          />
          <Checkbox
            color={scBlue100}
            label="All Items"
            checked={allItemsSelected}
            onChange={() => {
              if (
                allItemsSelected &&
                some(tableSetupContext.fieldList, item => !isEmpty(item.fieldConfigurationResponse))
              ) {
                uncheckAllItems();
              } else {
                tableSetupContext.toggleSelectAllItems(allItemsSelected);
              }
            }}
          />
          <Button size="h28" className="add-custom-item" onClick={addCustomItem}>
            <Icon>add</Icon>
            Custom Item
          </Button>
        </div>
      </div>
      <div className="labels-row">
        <span className="column big">CDASH Items</span>
        <span className="column big">Updated CDASH Items</span>
        <span className="column big">Answer Type</span>
        <span className="column normal">Item Sequence</span>
        <span className="column short">Investigator Field</span>
        <span className="column short">Overview</span>
      </div>
      <DragDropContext
        onDragEnd={result =>
          onDragEnd(
            result,
            tableSetupContext.fieldList,
            tableSetupContext.setFieldList,
            tableSetupContext.updateConditionalLogicForItems,
            tableSetupContext.generalFields
          )
        }
      >
        <Droppable droppableId="table-fields">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="label-list">
              {fieldsForDisplay.map((item, index) => (
                <Draggable key={item.name} draggableId={item.name} index={index}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      {item?.type === 'CUSTOM' ? (
                        <TableFieldCustomItem
                          className={cx('field-item-wrapper custom', { dragging: snapshot.isDragging })}
                          item={item}
                          itemIndex={index}
                        />
                      ) : (
                        <TableFieldItem
                          className={cx('field-item-wrapper', { dragging: snapshot.isDragging })}
                          item={item}
                          itemIndex={index}
                        />
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default TableField;
