import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import IconButton from '../IconButton/IconButton';

import './TableIconButton.scss';

export default function TableIconButton(props) {
  const { className, ...restProps } = props;
  return (
    <IconButton className={cx(`eds-table-icon-btn`, className)} {...restProps}>
      {props.children}
    </IconButton>
  );
}

TableIconButton.propTypes = {
  title: PropTypes.string,
  style: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.any,
  color: PropTypes.oneOf(['blue', 'red']),
  suit: PropTypes.oneOf(['glyphicon', 'material'])
};
