import React from 'react';
import { Menu, MenuItem } from '@szhsin/react-menu';

import Button from '../../../../../../../../common/general/Button';
import Icon from '../../../../../../../../common/general/Icon';
import { getPermissionOptionsByGroupType } from '../../permissionType';

export default function GroupsSetupHeaderCell({
  selectedGroupType,
  changeAccessForAllEncountersItemGroupsConfiguration
}) {
  return (
    <>
      <Menu
        viewScroll="close"
        align="end"
        menuButton={
          <Button priority="medium" size="h28">
            Set access
            <Icon>arrow_drop_down</Icon>
          </Button>
        }
      >
        {getPermissionOptionsByGroupType(selectedGroupType).map(function({ key, name }) {
          return (
            <MenuItem key={key} onClick={() => changeAccessForAllEncountersItemGroupsConfiguration(key)}>
              {name}
            </MenuItem>
          );
        })}
      </Menu>
      <div>Item Group</div>
    </>
  );
}
