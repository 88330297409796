import axios from 'axios';

const ROOT = 'api/v1/finance/budget-sites';

const MissingLedgerEventsApi = {
  findMissingLedgerEvents(finBudgetSiteId, finBudgetRowId, findMissingBudgetItemsRequest) {
    return axios.post(
      `${ROOT}/${finBudgetSiteId}/events/${finBudgetRowId}/find-missing-ledger-events`,
      findMissingBudgetItemsRequest
    );
  },

  createMissingLedgerEvents(finBudgetSiteId, finBudgetRowId, createMissingLedgerEventsRequest) {
    return axios.post(
      `${ROOT}/${finBudgetSiteId}/events/${finBudgetRowId}/create-missing-ledger-events`,
      createMissingLedgerEventsRequest
    );
  }
};

export default MissingLedgerEventsApi;
