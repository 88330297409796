import React from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';

export const HistoryItemIcon = ({ type }) => {
  const iconStyles = { marginRight: '10px' };
  switch (type) {
    case 'SEND':
      return (
        <>
          <SendIcon sx={iconStyles} /> Emailed
        </>
      );
    case 'DOWNLOAD':
      return (
        <>
          <DownloadIcon sx={iconStyles} /> Downloaded
        </>
      );
    case 'PRINT':
      return (
        <>
          <PrintIcon sx={iconStyles} /> Printed
        </>
      );
    default:
      return (
        <>
          <SaveIcon sx={iconStyles} /> Saved
        </>
      );
  }
};
