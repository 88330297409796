import { isEqual } from 'lodash';

const UNITED_STATES = { id: 'US', name: 'United States' };
const CANADA = { id: 'CA', name: 'Canada' };
const CANADIAN_POSTAL_CODE_REGEXP = /^[ABCEGHJ-NPRSTVXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/i;

export const addInvalidFieldIfNotValid = (isNotValid, fieldName, collection) => {
  if (isNotValid) {
    collection.push(fieldName);
  }
};

export const isPhoneValid = phone => phone && phone?.length !== 0 && phone?.length === 14;

export const isValidEmail = email =>
  email &&
  email?.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

export const isValidEmailForPayee = email =>
  email.includes('@elligodirect.com') &&
  email?.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

export const isBlank = str => !str || /^\s*$/?.test(str);

export const isZipCodeValid = (zipCode, isUsCountry) => {
  const zipCodeLength = zipCode?.length;
  const postalCodeRegex = isUsCountry ? /(^\d{5}$)|(^\d{5}-\d{4}$)/ : CANADIAN_POSTAL_CODE_REGEXP;

  return zipCode && (isUsCountry ? [5, 10] : [7]).includes(zipCodeLength) && postalCodeRegex.test(zipCode);
};

export function onChangeZipCode(value, country, setCountry, zipCode, setZipCode, setState) {
  if (CANADIAN_POSTAL_CODE_REGEXP.test(value) && country?.id !== CANADA?.id) {
    setCountry(CANADA);
    setState(null);
  } else if (value === '' && country?.id === CANADA?.id) {
    setCountry(UNITED_STATES);
    setState(null);
  } else if (value.match(/^\d/) && country?.id === CANADA?.id) {
    setCountry(UNITED_STATES);
    setState(null);
  }

  if (!isEqual(zipCode, value)) {
    setZipCode(normalizeZipCodeForAccounts(value));
  }
}

export function normalizeZipCodeForAccounts(value) {
  if (!value) return value;

  if (/[a-zA-Z]/g.test(value) && /^[A-Za-z]/.test(value.charAt(0))) {
    const currentValue = value.toUpperCase().replace(/[^\dA-Z]/g, ''),
      cvLength = currentValue.length;
    if (cvLength < 3) return currentValue;
    return `${currentValue.slice(0, 3)} ${currentValue.slice(3, 6)}`.trim();
  }

  const currentValue = value.replace(/[^\d]/g, ''),
    cvLength = currentValue.length;
  if (cvLength < 6) return currentValue;
  return `${currentValue.slice(0, 5)}-${currentValue.slice(5, 9)}`;
}
