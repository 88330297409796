import React from 'react';
import cx from 'classnames';
import Tooltip from 'rc-tooltip';

import './GroupsSetupEncounterHeaderCell.scss';

export default function GroupsSetupEncounterHeaderCell({ encounter }) {
  const { encounterName, nonProtocol, encounterDisplayName } = encounter;
  return (
    <Tooltip
      overlay={encounterName}
      placement="top"
      mouseEnterDelay={0}
      mouseLeaveDelay={0.1}
      align={{
        offset: [0, 3]
      }}
    >
      <div
        className={cx('groups-setup-encounter-header-cell-header-epoch-encounter-board', {
          'groups-setup-encounter-header-cell-header-epoch-encounter-board-non-protocol': nonProtocol
        })}
      >
        {encounterDisplayName}
      </div>
    </Tooltip>
  );
}
