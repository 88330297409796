import React, { useCallback, useContext, useMemo, useState } from 'react';

import Radio from '../../../../../common/data-entry/InputSelectors/Radio';
import ApplyAndResetButtons from '../../../../ApplyAndResetButtons/ApplyAndResetButtons';
import MainFilters from '../../shared/FinanceFilters/MainFilters/MainFilters';
import { RevenueContext } from '../RevenueContext';

import './RevenueFilter.scss';

export default function RevenueFilter() {
  const { filterProperty, filterRevenueEvents, setFilterProperty, resetFilter } = useContext(RevenueContext);
  const [isEventIdFormatInvalid, setIsEventIdFormatInvalid] = useState(false);

  const setFewFilterProperty = useCallback(
    function(filters) {
      setFilterProperty({
        ...filterProperty,
        ...filters
      });
    },
    [setFilterProperty, filterProperty]
  );

  const value = useMemo(
    function() {
      return !!filterProperty?.notIncludeElligoZeroOrNegativeAmount;
    },
    [filterProperty]
  );

  return (
    <div className="revenue-filters">
      <div className="rf-all-inputs-and-button">
        <div>
          <MainFilters
            setIsEventIdFormatInvalid={setIsEventIdFormatInvalid}
            setFewFilterProperty={setFewFilterProperty}
            filterProperty={filterProperty}
            setFilterProperty={setFilterProperty}
            pcnFlag
          >
            <ApplyAndResetButtons
              applyDisabled={isEventIdFormatInvalid}
              onApply={filterRevenueEvents}
              onReset={resetFilter}
              resetClassName="rf-reset-btn"
            />
          </MainFilters>
          <div className="rf-radio">
            <Radio.Group
              label="Pass-thru:"
              checkedValue={value}
              onChange={({ target: { value } }) =>
                setFewFilterProperty({ notIncludeElligoZeroOrNegativeAmount: value === 'true' })
              }
            >
              <Radio label="Show" value={false} />
              <Radio label="Hide" value={true} />
            </Radio.Group>
          </div>
        </div>
      </div>
    </div>
  );
}
