import React, { useState } from 'react';
import { isFunction } from 'lodash/lang';

import TextArea from '../../../../../../../../common/data-entry/TextArea/TextArea';
import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../../common/general/ButtonGroup';

import './PatientInstructionEditModal.scss';

const PatientInstructionEditModal = ({ modalBox, initialInstruction, onSave }) => {
  const [instruction, setInstruction] = useState(initialInstruction || '');

  const onClick = function() {
    isFunction(onSave) && onSave(instruction);
  };

  return (
    <>
      <ModalBoxes.Header>{initialInstruction ? 'Edit' : 'New'} Instruction</ModalBoxes.Header>
      <ModalBoxes.Body>
        <TextArea
          style={{ resize: 'none' }}
          value={instruction}
          rows={1}
          clearable
          onChange={({ target }) => setInstruction(target.value)}
          label="Enter Instruction"
          name="Enter Instruction"
        />
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            Cancel
          </Button>
          <Button disabled={instruction === ''} onClick={onClick}>
            Save
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
};

PatientInstructionEditModal.className = 'patient-instruction-change-modal';
PatientInstructionEditModal.size = 'w650';

export default PatientInstructionEditModal;
