export const INITIAL_PATIENT_STATUSES = [
  { id: 'Pre-screened', name: 'Pre-screened' },
  { id: 'Pre-screening', name: 'Pre-screening' },
  { id: 'Identified', name: 'Identified' }
];

export const PAYMENT_TYPE_PHYSICAL = 'PHYSICAL';
export const PAYMENT_TYPE_DIGITAL = 'DIGITAL';

export const paymentTypeMap = {
  [PAYMENT_TYPE_PHYSICAL]: 'Physical',
  [PAYMENT_TYPE_DIGITAL]: 'Digital'
};
