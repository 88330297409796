import React, { useEffect, useState } from 'react';
import { MANAGE_FINANCE_DETAILS_ACCESS } from 'constants/userOperations';
import { cloneDeep } from 'lodash';
import { zipObject } from 'lodash/array';
import { reduce } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';
import { userHasAccessTo } from 'services/auth';

import PatientApi from '../../../../../../../api/patient/PatientApi';
import ModalBoxes, { useModalBoxes } from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';
import StudySiteStatusSourceSelect from '../StudySiteStatusSourceSelect';

import FinanceDetails from './FinanceDetails/FinanceDetails';
import GuardianDetails from './GuardianDetails/GuardianDetails';
import PatientContactDetails from './PatientContactDetails/PatientContactDetails';
import PatientDemographics from './PatientDemographics/PatientDemographics';
import PatientDetails from './PatientDetails/PatientDetails';
import initiateAddPatient from './services/initiateAddPatient';
import initiateEditPatient from './services/initiateEditPatient';
import { useInfo, useRequiredFields } from './AddOrEditPatientInfoModalHooks';

import './AddOrEditPatientInfoModal.scss';

export default function AddOrEditPatientInfoModal({ currentStudy, modalBox, patientInfo, onSave, onDuplicate }) {
  const { setLoading } = useModalBoxes();
  const {
    info,
    setInfo,
    isEditMode,
    validationMessages,
    setValidationMessages,
    handleCountryChange,
    handleStateChange,
    handleDateOfBirthChange,
    handleInputChange,
    handleSexChange,
    handlePronounsChange,
    handlePrimaryLanguageChange,
    handlePreferredContactMethodChange,
    handleSelectedPhoneTypeChange,
    handleHeightChange,
    handleRacesChange,
    handleEthnicitiesChange,
    resetValidationMessageByName,
    handleChangeSmsOptInDropdown,
    handleChangeInterestedInFutureResearch,
    handlePaymentTypeChange,
    handleChangeDigitalPaymentAllowed
  } = useInfo(patientInfo);

  const { address, cardId, paymentType } = info;

  const [source, setSource] = useState(null);
  const [status, setStatus] = useState(null);
  const [selectedStudy, setSelectedStudy] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [medicalRecordNumber, setMedicalRecordNumber] = useState('');
  const [userCanManageFinanceDetails, setUserCanManageFinanceDetails] = useState(true);

  const [originalPID] = useState(cloneDeep(cardId));
  const [originalPaymentType] = useState(cloneDeep(paymentType));
  const [fieldsDataDictionary, setFieldsDataDictionary] = useState(null);
  const [fieldsDataDictionaryMap, setFieldsDataDictionaryMap] = useState({});

  const [isRequiredField] = useRequiredFields(info, originalPID);

  const { ethnicityCodes, raceCodes } = fieldsDataDictionaryMap;

  useEffect(() => {
    PatientApi.getDictionaries().then(({ data }) => {
      setFieldsDataDictionaryMap(prepareDictionaries(data));
      setFieldsDataDictionary(data);
    });
  }, []);

  useEffect(() => {
    if (userHasAccessTo(MANAGE_FINANCE_DETAILS_ACCESS) && !isEditMode) {
      setUserCanManageFinanceDetails(false);
      if (!isEmpty(selectedSite) && !isEmpty(selectedStudy))
        PatientApi.checkAbilityToAddFinanceDetails(selectedSite.uniqueIdentifier).then(({ data }) => {
          if (data) {
            setUserCanManageFinanceDetails(data);
          }
        });
    }
  }, [isEditMode, selectedSite, selectedStudy]);

  useEffect(() => {
    if (userHasAccessTo(MANAGE_FINANCE_DETAILS_ACCESS) && isEditMode) {
      setUserCanManageFinanceDetails(false);
      PatientApi.checkAbilityToEditFinanceDetails(patientInfo.id).then(({ data }) => {
        if (data) {
          setUserCanManageFinanceDetails(data);
        }
      });
    }
  }, [isEditMode, patientInfo?.id]);

  return (
    <>
      <ModalBoxes.Body>
        {!isEditMode && (
          <>
            <div className="row">
              <div className="col-12 apm-form-label">Select SSU</div>
            </div>
            <div className="col-8 top-selectors">
              <StudySiteStatusSourceSelect
                onSSUFilterChange={onSSUFilterChange}
                getValidationMessage={getValidationMessage}
                setSource={v => {
                  resetValidationMessageByName('source');
                  setSource(v);
                }}
                source={source}
                setStatus={v => {
                  resetValidationMessageByName('status');
                  setStatus(v);
                }}
                status={status}
                className="aoepim-study-site-status-source-select"
              />
            </div>
          </>
        )}
        <PatientDetails
          isEditMode={isEditMode}
          handleDateOfBirthChange={handleDateOfBirthChange}
          handleInputChange={handleInputChange}
          handleSexChange={handleSexChange}
          handlePronounsChange={handlePronounsChange}
          handlePrimaryLanguageChange={handlePrimaryLanguageChange}
          isRequiredField={isRequiredField}
          getValidationMessage={getValidationMessage}
          medicalRecordNumber={medicalRecordNumber}
          setMedicalRecordNumber={setMedicalRecordNumber}
          info={info}
          fieldsDataDictionary={fieldsDataDictionary}
        />
        <PatientContactDetails
          handleCountryChange={handleCountryChange}
          handleStateChange={handleStateChange}
          handleInputChange={handleInputChange}
          handlePreferredContactMethodChange={handlePreferredContactMethodChange}
          handleSelectedPhoneTypeChange={handleSelectedPhoneTypeChange}
          isRequiredField={isRequiredField}
          getValidationMessage={getValidationMessage}
          address={address}
          info={info}
          fieldsDataDictionary={fieldsDataDictionary}
          handleChangeSmsOptInDropdown={handleChangeSmsOptInDropdown}
          handleChangeInterestedInFutureResearch={handleChangeInterestedInFutureResearch}
        />
        <PatientDemographics
          info={info}
          isRequiredField={isRequiredField}
          getValidationMessage={getValidationMessage}
          handleInputChange={handleInputChange}
          handleHeightChange={handleHeightChange}
          handleRacesChange={handleRacesChange}
          handleEthnicitiesChange={handleEthnicitiesChange}
          ethnicityCodes={ethnicityCodes}
          raceCodes={raceCodes}
        />
        <FinanceDetails
          handleInputChange={handleInputChange}
          isRequiredField={isRequiredField}
          getValidationMessage={getValidationMessage}
          info={info}
          userCanManageFinanceDetails={userCanManageFinanceDetails}
          handlePaymentTypeChange={handlePaymentTypeChange}
          originalPaymentType={originalPaymentType}
        />
        <GuardianDetails
          handleInputChange={handleInputChange}
          getValidationMessage={getValidationMessage}
          info={info}
        />
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            CANCEL
          </Button>
          <Button onClick={update}>SAVE</Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );

  function getValidationMessage(field) {
    return validationMessages[field];
  }

  function onSSUFilterChange(ssus, study, site) {
    handleChangeDigitalPaymentAllowed(!!study?.digitalPatientPayment);
    if (site && site.country === 'Canada') {
      address.country = 'CA';
    } else {
      address.country = 'US';
    }
    resetValidationMessageByName('study');
    setSelectedStudy(study);
    if (site) {
      resetValidationMessageByName('site');
      const ssu = ssus.find(e => e.site.uniqueIdentifier === site.uniqueIdentifier);
      setSelectedSite(ssu);
    } else {
      setSelectedSite(null);
    }
  }

  function update() {
    if (isEditMode) {
      initiateEditPatient({
        info,
        currentStudy,
        patientInfo,
        setValidationMessages,
        setInfo,
        setLoading,
        postSave() {
          onSave();
        },
        onDuplicate,
        modalBox,
        originalPID,
        originalPaymentType
      });
      return;
    }
    initiateAddPatient({
      info,
      ssu: { study: selectedStudy, site: selectedSite, source: source, status: status },
      medicalRecordNumber,
      setValidationMessages,
      setInfo,
      setLoading,
      postSave() {
        onSave();
      }
    });
  }
}

AddOrEditPatientInfoModal.className = 'add-or-edit-patient-info-modal';
AddOrEditPatientInfoModal.size = 'w1100';

function prepareDictionaries(data) {
  return reduce(
    data,
    function(accumulator, value, key) {
      accumulator[key] = reduce(
        value,
        function(accumulator, value) {
          const disabledOptions = value?.disabledIds || [];
          accumulator[value.id] = {
            ...value,
            disabledIds: !isEmpty(disabledOptions)
              ? zipObject(disabledOptions, Array(disabledOptions.length).fill(true))
              : null
          };
          return accumulator;
        },
        {}
      );
      return accumulator;
    },
    {}
  );
}
