import React, { Component } from 'react';
import { isArray, isEmpty } from 'lodash/lang';
import { get } from 'lodash/object';
import * as PropTypes from 'prop-types';

import { FILE_TYPES } from '../../../../../../constants/inputTypes';
import { getFileTitle, resolveFileIcon } from '../../../../../../services/fileService';

import './ItemGroupDataTable.scss';

export class ItemGroupDataTable extends Component {
  static propTypes = {
    itemGroupType: PropTypes.string,
    labelList: PropTypes.array,
    renderData: PropTypes.any,
    getEditFormDetail: PropTypes.func
  };

  render() {
    const { getEditFormDetail, renderData, labelList } = this.props;
    const tableData = get(renderData, 'tableData'),
      columnList = get(renderData, 'columnList'),
      fileList = get(renderData, 'fileList'),
      tableRows = isArray(tableData) ? tableData : [],
      headers = isArray(columnList) ? columnList : [];
    return (
      <div className="eds-item-group-data-table">
        <table className="table table-responsive-lg border activity-table mb-2">
          <thead>
            <tr valign="middle">
              {headers.map((col, idx) => (
                <th key={idx}>{col.question}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isEmpty(tableRows) ? (
              <tr>
                <td colSpan={headers.length}>No record found.</td>
              </tr>
            ) : (
              tableRows.map((row, idx) => {
                return (
                  <tr key={idx} onClick={() => getEditFormDetail(row)}>
                    {headers.map(columnHeader => {
                      return (
                        <td key={columnHeader?.name}>{displayCellValue(row, columnHeader, fileList, labelList)}</td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    );
  }
}
function fileListWithIcon(value, fileList) {
  const splitedFilesNames = value.split(',').map(fileName => fileName.trim());
  return splitedFilesNames
    .map(function(fileName) {
      const currentFileInfo = fileList.find(file => file.fileName === fileName);
      if (currentFileInfo) {
        return (
          <>
            <span className="file-status-block" title={getFileTitle(currentFileInfo)}>
              {resolveFileIcon(currentFileInfo)}
              {fileName}
            </span>
          </>
        );
      }
      return undefined;
    })
    .filter(file => file);
}
function displayCellValue(row, columnHeader, fileList, labelList) {
  const value =
    row.data && row.data[columnHeader.name.trim()]
      ? row.data[columnHeader.name.trim()]
      : row[columnHeader.question.trim()];
  const columnHeaderName = columnHeader?.name;
  const currentLabel = labelList.find(({ name: labelName }) => columnHeaderName === labelName);
  if (value && FILE_TYPES.includes(currentLabel?.inputType)) {
    return fileListWithIcon(value, fileList);
  }
  if (value && value !== 'textBlockLong') {
    return value;
  }
  return '--';
}
