import React, { Component } from 'react';
import { isEqual, isFunction, isString } from 'lodash/lang';
import * as PropTypes from 'prop-types';

import { StudyApi } from '../../../../../api';
import RoundIconButton from '../../../../../common/buttons/RoundIconButton/RoundIconButton';
import Icon from '../../../../../common/general/Icon';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import {
  PRIMARY_CONTACT_FILL_NOT_CORRECTLY,
  PRIMARY_CONTACT_UPDATED
} from '../../../../../constants/notificationMessages';
import { onRequestError } from '../../../../../services/handlers';
import { normalizePhoneNumber } from '../../../../../services/normalizers';

import './EditPatientPrimaryContact.scss';

class EditPatientPrimaryContact extends Component {
  static propTypes = {
    newPageHeader: PropTypes.bool,
    contactNumber: PropTypes.string,
    neverCallAgain: PropTypes.bool,
    voicemailNotAllowed: PropTypes.bool,
    patientUniqueIdentifier: PropTypes.string,
    onSuccessfulUpdate: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      contactNumber: '',
      neverCallAgain: false,
      voicemailNotAllowed: false
    };
  }

  componentDidMount() {
    const contactNumber = normalizePhoneNumber(this.props.contactNumber);
    const { neverCallAgain, voicemailNotAllowed } = this.props;
    this.setState({ contactNumber, neverCallAgain: neverCallAgain, voicemailNotAllowed });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!isEqual(prevProps.contactNumber, this.props.contactNumber)) {
      const contactNumber = normalizePhoneNumber(this.props.contactNumber);
      this.setState({ contactNumber });
    }
  }

  onChangeContactNumber = ({ target }) => {
    const contactNumber = normalizePhoneNumber(target.value);
    this.setState({ contactNumber });
  };

  showSaveButton = () => {
    return this.state.contactNumber !== this.props.contactNumber;
  };

  saveContactNumber = () => {
    const { contactNumber } = this.state,
      { patientUniqueIdentifier, onSuccessfulUpdate } = this.props;
    if (!isString(contactNumber) || !validateContactNumber(contactNumber.trim())) {
      NotificationManager.error(PRIMARY_CONTACT_FILL_NOT_CORRECTLY);
      return;
    }

    StudyApi.updateContactNumber({
      contactNumber: contactNumber.trim(),
      patientUniqueIdentifier
    }).then(({ data }) => {
      NotificationManager.success(PRIMARY_CONTACT_UPDATED);
      if (isFunction(onSuccessfulUpdate)) {
        onSuccessfulUpdate(data);
      }
    }, onRequestError);
  };

  render() {
    const { state } = this,
      { contactNumber, neverCallAgain, voicemailNotAllowed } = state;
    return (
      <div className="edit-patient-primary-contact">
        {!this.props.newPageHeader && (
          <>
            |<label className="pt-sub px-2 m-0 ">Primary Contact</label>
          </>
        )}
        <div className="edit-patient-primary-contact-field-group">
          <input
            type="text"
            style={neverCallAgain ? { 'text-decoration': 'line-through' } : {}}
            className="form-control"
            value={contactNumber}
            onChange={this.onChangeContactNumber}
            disabled={neverCallAgain}
          />
          {!neverCallAgain && (
            <RoundIconButton onClick={this.saveContactNumber} disabled={!this.showSaveButton()}>
              save
            </RoundIconButton>
          )}
          {!neverCallAgain && voicemailNotAllowed && (
            <Icon className="voicemail-not-allowed-icon eds-icon-btn" title="Voicemail Not Allowed">
              voicemail
            </Icon>
          )}
          {neverCallAgain && <span className="text-danger pl-2">Do Not Call</span>}
        </div>
      </div>
    );
  }
}

function validateContactNumber(number) {
  return number.length === 0 || number.length === 12;
}

export default EditPatientPrimaryContact;
