import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import { pick } from 'lodash/object';
import moment from 'moment-timezone';

import { EventFinderApi } from '../../../../api';
import FloatingPanel from '../../../../common/fullScreenLayout/FloatingPanel';
import { scBlue30, scBlue100 } from '../../../../constants/systemColors';
import TasksSupervisor from '../../../root/Container/Layout/Tasks/TasksSupervisor/TasksSupervisor';
import AppointmentView from '../AppointmentView/AppointmentView';
import { APPOINTMENT_TYPES } from '../CalendarEventType';
import { updateCalendarDueSelectedTimeZone } from '../CalendarPage';
import { CalendarPageContextProvider } from '../CalendarPageContext';
import { getCurrentTimeZone, toTimeZoneWithNormalizingToCurrent } from '../CalendarTimeZoneService';
import { fromBackendModel } from '../EventTransformer';

import { Event } from './Components/Events/Events';
import { eventPropGetter, EventWrapper } from './CalendarBig';
import { prepareEvents } from './CalendarBigService';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

let lastVisibleDate = moment();

function CalendarOneDayViewComponent({ calendars, displayedDate, selectedTimeZone }) {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [currentTimeForSelectedTimeZone, setCurrentTimeForSelectedTimeZone] = useState(new Date());
  const [events, setEvents] = useState([]);

  useEffect(() => {
    registerOnScrollToTimeListener();
    return function() {
      removeOnScrollToTimeListener();
    };
  }, []);

  useEffect(() => {
    if (selectedTimeZone) {
      const updatedEvents = updateCalendarDueSelectedTimeZone(
        selectedTimeZone,
        prepareEvents([...calendars]).map(withDefaultColor)
      );
      setEvents(updatedEvents);
      if (selectedEvent) {
        const updatedEvent = updatedEvents.find(e => e.id === selectedEvent.id);
        if (updatedEvent) {
          const { start, end, eventTimezone } = updatedEvent;
          setSelectedEvent(selectedEvent => ({ ...selectedEvent, start, end, eventTimezone }));
        } else {
          setSelectedEvent(null);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendars, selectedTimeZone]);

  useEffect(() => {
    if (selectedTimeZone) {
      const localTimeZone = getCurrentTimeZone();
      const updatedTime = toTimeZoneWithNormalizingToCurrent(
        new Date(),
        selectedTimeZone.timeZoneId,
        localTimeZone.timeZoneId
      );
      setCurrentTimeForSelectedTimeZone(updatedTime.toDate());
      lastVisibleDate = updatedTime;
    }
  }, [selectedTimeZone]);

  const withDefaultColor = event => {
    return { ...event, color: { main: scBlue100, back: scBlue30 } };
  };

  return (
    <div className="eds-calendar-big eds-calendar" style={{ width: '100%', height: '100%' }}>
      <FloatingPanel show={selectedEvent} close={() => setSelectedEvent(null)}>
        <AppointmentView initialAppointment={selectedEvent} />
      </FloatingPanel>
      <Calendar
        date={displayedDate ? moment(displayedDate).toDate() : undefined}
        localizer={momentLocalizer(moment)}
        events={events}
        resizable={false}
        draggable={false}
        selectable={false}
        resourceIdAccessor={'fullId'}
        scrollToTime={lastVisibleDate.toDate()}
        onNavigate={() => {}}
        eventPropGetter={e => eventPropGetter(e, selectedEvent)}
        defaultView={Views.DAY}
        step={60}
        timeslots={1}
        drilldownView=""
        getNow={() => currentTimeForSelectedTimeZone}
        onSelectEvent={event => {
          if (APPOINTMENT_TYPES.includes(event.type)) {
            EventFinderApi.getEventDetails(event.eventId).then(({ data }) => {
              event = mergeEvents(event, data);
              TasksSupervisor.setHidden(true);
              setSelectedEvent(event);
            });
          }
        }}
        defaultDate={new Date()}
        components={{
          toolbar: () => <></>,
          eventWrapper: EventWrapper,
          event: Event
        }}
      />
    </div>
  );
}

export default function CalendarOneDayView(props) {
  return (
    <CalendarPageContextProvider hideLoader={true}>
      <CalendarOneDayViewComponent {...props} />
    </CalendarPageContextProvider>
  );
}

const mergeEvents = (event, newEvent) => {
  const { calendarId, calendarIndex } = event;
  event.eventLoaded = true;
  return {
    ...fromBackendModel(newEvent, calendarId, calendarIndex),
    ...pick(event, ['start', 'end', 'id', 'color', 'customStyle', 'title', 'eventLoaded'])
  };
};

function prepareLastVisibleDate(event) {
  const minutes = event.target.scrollTop / (event.target.scrollHeight / 24 / 60);
  lastVisibleDate = moment()
    .startOf('day')
    .add(minutes, 'minutes');
}

function removeOnScrollToTimeListener() {
  const scrollableContainer = document.querySelector('.rbc-time-content');
  if (scrollableContainer) {
    scrollableContainer.removeEventListener('scroll', prepareLastVisibleDate);
  }
}

function registerOnScrollToTimeListener() {
  const scrollableContainer = document.querySelector('.rbc-time-content');
  if (scrollableContainer) {
    scrollableContainer.addEventListener('scroll', prepareLastVisibleDate);
  }
}
