import { isArray } from 'lodash/lang';
import { maxBy } from 'lodash/math';

import { CLOSED } from '../../../../constants/ssuStatuses';
import { PENDING_SCHEDULE_WORK_LIST } from '../../../../constants/userOperations';
import { ROLE_SYSTEM_ADMINISTRATOR } from '../../../../constants/userRoles';
import { userHasAccessTo, userHasRole } from '../../../../services/auth';
import { generateUrlByKey } from '../../../root/router';

function getAccessor(ig) {
  return ig.itemGroupKey.concat(ig.encounterIdentifier);
}

export function prepareRenderData(data) {
  const patientWithMaxCol = maxBy(data, patient => patient.preScreenItemGroups.length);
  const itemGroupAccessors = (patientWithMaxCol && getItemGroupAccessors(patientWithMaxCol)) || {};
  const warning = 'Invalid Patient Status';
  const patients =
    isArray(data) &&
    data.map(patient => {
      patient.preScreenItemGroups.forEach(ig => {
        const accessor = getAccessor(ig);
        patient[accessor] = ig;
        ig.sitePatientId = patient.sitePatientId;
      });
      if (patient.appointment.type === 'IdentifiedStatusAndReadyForScheduling' && patient.readyFor === 'Scheduling') {
        patient.appointment.warning = warning;
      }
      patient.fullName = `${patient.lastName}, ${patient.firstName}`;
      patient.idleTime = patient.idleTime || patient.idleTime === 0 ? patient.idleTime : -100;
      return patient;
    });

  return { patients: patients || [], itemGroupAccessors: itemGroupAccessors };
}

function getNameForItemGroup(ig) {
  if (ig.domainName === 'Elligo - Outreach' || ig.domainName === 'Elligo - Chart Review') {
    return ig.itemGroupName;
  }
  if (ig.domainName !== ig.itemGroupName) {
    return `${ig.domainName} (${ig.itemGroupName})`;
  }
  return ig.domainName;
}

function getItemGroupAccessors({ preScreenItemGroups = null }) {
  const itemGroupAccessors = {};
  preScreenItemGroups.forEach(ig => {
    const name = getNameForItemGroup(ig);
    const accessor = getAccessor(ig);
    itemGroupAccessors[accessor] = name;
  });
  return itemGroupAccessors;
}

export function generateLink(link) {
  if (!link) {
    return '';
  }

  const {
    patientId,
    patientEncounterId,
    patDetailIdentity: ssuPatientId,
    elementFormIdentifier: patientItemGroupId
  } = link;

  return generateUrlByKey('Worklist.Patient Profile.Patient Studies.Encounter Details.Procedure', {
    patientId,
    ssuPatientId,
    patientEncounterId,
    patientItemGroupId
  });
}

export const showScheduleAppointmentPlus = original => {
  return (
    (userHasRole(ROLE_SYSTEM_ADMINISTRATOR) ||
      (original.studySiteStatus !== CLOSED && userHasAccessTo(PENDING_SCHEDULE_WORK_LIST))) &&
    (original.status === 'PRE_SCREENED' || original.status === 'IDENTIFIED') &&
    original?.appointment?.date &&
    original.readyFor === 'Complete'
  );
};
