export function scrollForFinanceTableInAnyPartOfPage(scrollablePart) {
  window.addEventListener('wheel', handleScroll);
  return function() {
    window.removeEventListener('wheel', handleScroll);
  };

  function forbidHandleScroll(event, scrollablePart) {
    const className = scrollablePart?.className;
    const isScrollableTable =
      event.target.className === className || event.target.parentElement.className === className;
    const isDropdown = event.target.className.includes('r-ss-dropdown-option');
    const activeElement = document.activeElement;
    const isInput = activeElement.localName.includes('input') && activeElement.type === 'number';
    return isScrollableTable || isDropdown || isInput;
  }

  function handleScroll(event) {
    const deltaY = event.deltaY;
    if (scrollablePart && !forbidHandleScroll(event, scrollablePart)) {
      const to = scrollablePart.scrollTop + deltaY / 6;
      const scrollHeight = scrollablePart?.scrollHeight;
      if (to > scrollHeight) {
        scrollablePart.scrollTop = scrollHeight;
      } else {
        if (to < 0) {
          scrollablePart.scrollTop = 0;
        } else {
          scrollablePart.scrollTop = to;
        }
      }
    }
  }
}
