import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { differenceBy, intersection, intersectionWith } from 'lodash/array';
import { every, some } from 'lodash/collection';
import { isEmpty, isNull } from 'lodash/lang';
import { omit } from 'lodash/object';
import uuid from 'uuid/v4';

import { afterburnerApi } from '../../../../../../../api/patient/AftrburnerApi';
import Checkbox from '../../../../../../../common/data-entry/InputSelectors/Checkbox';
import MultiSelect from '../../../../../../../common/data-entry/MultiSelect/MultiSelect';
import Select from '../../../../../../../common/data-entry/Select';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import { SOMETHING_WENT_WRONG } from '../../../../../../../constants/notificationMessages';
import { prepareGeneralMapping } from '../MappingSetupModal/MappingSetupServices';

import ItemGroupMappingTemplateItem from './ItemGroupMappingTemplateItem/ItemGroupMappingTemplateItem';
import GeneralMappingTemplateItem from './PatientMappingTemplateItem/GeneralMappingTemplateItem';

import './MappingTemplateModal.scss';

export const MappingTemplateModal = ({
  modalBox,
  addTemplatesToMappings,
  mappingForFieldsExist,
  mappingForGeneralExist,
  encounterId,
  itemGroupKey,
  protocolId
}) => {
  const [generalTemplateMapping, setGeneralTemplateMapping] = useState([]);
  const [itemGroupTemplateMapping, setItemGroupTemplateMapping] = useState([]);

  const [templateNames, setTemplateNames] = useState([]);
  const [selectedTemplateName, setSelectedTemplateName] = useState(null);

  const [generalFieldsForFilter, setGeneralFieldsForFilter] = useState([]);
  const [selectedGeneralFieldsForFilter, setSelectedGeneralFieldsForFilter] = useState(null);

  const [itemGroupsForFilter, setItemGroupsForFilter] = useState([]);
  const [selectedItemGroupsForFilter, setSelectedItemGroupForFilter] = useState(null);

  const resolveApiForGetTemplateData = useCallback(() => {
    return itemGroupKey
      ? afterburnerApi.getItemGroupMappingTemplateData(itemGroupKey, selectedTemplateName?.id)
      : afterburnerApi.getEncounterMappingTemplateData(encounterId, selectedTemplateName?.id);
  }, [encounterId, itemGroupKey, selectedTemplateName?.id]);

  useEffect(() => {
    if (selectedTemplateName?.id) {
      resolveApiForGetTemplateData().then(({ data: { generalFields, itemGroupFields } }) => {
        const normalizedGeneralMapping = prepareGeneralMapping(generalFields);
        const preparedGeneralMappings = normalizedGeneralMapping.map(group => ({
          ...group,
          fields: group.fields.map(field => ({ ...field, selected: false }))
        }));
        const preparedGeneralFieldsForFilter = normalizedGeneralMapping.map(group => ({
          name: group.groupType,
          id: group.groupType
        }));
        const preparedItemGroupMapping = itemGroupFields.flatMap(itemGroup => {
          return itemGroup.fields.map(field => ({
            name: field.name,
            itemGroupName: itemGroup.name,
            mappingKeyValue: field.mappingKeyValue,
            mappingType: field.mappingType,
            protocolItemIdentifier: field.protocolItemDefinitionKey,
            key: uuid(),
            selected: false
          }));
        });

        const preparedItemGroupsForFilter = itemGroupFields.map(itemGroup => ({
          name: itemGroup.name,
          id: itemGroup.name
        }));
        setGeneralTemplateMapping(preparedGeneralMappings);
        setItemGroupTemplateMapping(preparedItemGroupMapping);
        setItemGroupsForFilter(preparedItemGroupsForFilter);
        setGeneralFieldsForFilter(preparedGeneralFieldsForFilter);
        setSelectedGeneralFieldsForFilter([]);
        setSelectedItemGroupForFilter([]);
      });
      setItemGroupsForFilter([]);
    }
  }, [encounterId, resolveApiForGetTemplateData, selectedTemplateName]);

  useEffect(() => {
    if (protocolId) {
      afterburnerApi.getTemplatesByProtocol(protocolId).then(
        ({ data }) => {
          setTemplateNames(data);
        },
        () => {
          NotificationManager.error(SOMETHING_WENT_WRONG);
        }
      );
    }
  }, [protocolId]);

  const toggleSelected = useCallback((idForUpdate, value = null) => {
    const updateFunction = prevState => {
      return prevState.map(mapping => {
        if (idForUpdate === mapping.protocolItemIdentifier) {
          return { ...mapping, selected: !isNull(value) ? value : !mapping.selected };
        }
        return mapping;
      });
    };
    setItemGroupTemplateMapping(updateFunction);
  }, []);

  const toggleSelectedGeneral = useCallback((groupForUpdate, idForUpdate, value = null) => {
    const updateFunction = prevState => {
      return prevState.map(group => {
        if (group.groupType === groupForUpdate) {
          return {
            ...group,
            fields: group.fields.map(mapping => {
              if (idForUpdate === mapping.id) {
                return { ...mapping, selected: !isNull(value) ? value : !mapping.selected };
              }
              return mapping;
            })
          };
        }
        return group;
      });
    };
    setGeneralTemplateMapping(updateFunction);
  }, []);

  const notAvailableItemGroupMappings = useMemo(() => {
    return intersection(
      mappingForFieldsExist,
      itemGroupTemplateMapping.filter(mapping => mapping.selected).map(mapping => mapping.protocolItemIdentifier)
    );
  }, [itemGroupTemplateMapping, mappingForFieldsExist]);

  const notAvailableGeneralMappings = useMemo(() => {
    const generalMappingsForCompare = generalTemplateMapping
      .flatMap(element => element.fields)
      .filter(mapping => mapping.selected)
      .map(field => ({ fieldName: field.fieldName, fieldType: field.fieldType }));
    return intersectionWith(
      mappingForGeneralExist,
      generalMappingsForCompare,
      (a, b) => a.fieldType === b.fieldType && a.fieldName === b.fieldName
    );
  }, [mappingForGeneralExist, generalTemplateMapping]);

  const generalTemplates = useMemo(() => {
    return generalTemplateMapping
      .filter(item =>
        some(selectedGeneralFieldsForFilter, {
          name: item.groupType,
          id: item.groupType
        })
      )
      .map(group => {
        return (
          <React.Fragment key={group.groupType}>
            <span className="group-header">{group.groupType}</span>
            {group.fields.map(field => {
              const mappingExistsHighlight = mappingForGeneralExist.filter(
                mapping => mapping.fieldName === field.fieldName && mapping.fieldType === field.fieldType
              );
              return (
                <GeneralMappingTemplateItem
                  key={field.key}
                  template={field}
                  toggleSelectedGeneral={toggleSelectedGeneral}
                  mappingExistsHighlight={mappingExistsHighlight}
                />
              );
            })}
          </React.Fragment>
        );
      });
  }, [generalTemplateMapping, selectedGeneralFieldsForFilter, mappingForGeneralExist, toggleSelectedGeneral]);

  const itemGroupTemplates = useMemo(() => {
    return selectedItemGroupsForFilter?.map(filter => {
      return (
        <React.Fragment key={filter.name}>
          <span className="group-header">{filter.name}</span>
          {itemGroupTemplateMapping
            .filter(item => filter.name === item.itemGroupName)
            .map(field => {
              const mappingExistsHighlight = mappingForFieldsExist.filter(
                mapping => mapping.protocolItemIdentifier === field.protocolItemIdentifier
              );
              return (
                <ItemGroupMappingTemplateItem
                  key={field.key}
                  template={field}
                  toggleSelected={toggleSelected}
                  mappingExistsHighlight={mappingExistsHighlight}
                />
              );
            })}
        </React.Fragment>
      );
    });
  }, [itemGroupTemplateMapping, mappingForFieldsExist, selectedItemGroupsForFilter, toggleSelected]);

  const allItemsSelected = useMemo(() => {
    const preparedSelectedGroups = selectedGeneralFieldsForFilter?.map(el => el.name);
    const availableItemGroupTemplates = itemGroupTemplateMapping.filter(
      item =>
        selectedItemGroupsForFilter?.map(el => el.name).includes(item.itemGroupName) &&
        !some(mappingForFieldsExist, field => field.protocolItemIdentifier === item.protocolItemIdentifier)
    );
    return (
      every(
        generalTemplateMapping.filter(group => preparedSelectedGroups?.includes(group.groupType)),
        mapping =>
          every(
            mapping.fields.filter(
              item =>
                !some(mappingForGeneralExist, el => el.fieldType === item.fieldType && el.fieldName === item.fieldName)
            ),
            field => field.selected
          )
      ) && every(availableItemGroupTemplates, mapping => mapping.selected)
    );
  }, [
    selectedGeneralFieldsForFilter,
    generalTemplateMapping,
    itemGroupTemplateMapping,
    mappingForGeneralExist,
    selectedItemGroupsForFilter,
    mappingForFieldsExist
  ]);

  const toggleAllItems = useCallback(() => {
    setItemGroupTemplateMapping(prevState =>
      prevState.map(mapping => ({
        ...mapping,
        selected: selectedItemGroupsForFilter?.map(el => el.name).includes(mapping.itemGroupName)
          ? !allItemsSelected
          : mapping.selected
      }))
    );
    setGeneralTemplateMapping(prevState =>
      prevState.map(group => ({
        ...group,
        fields: group.fields.map(field => ({
          ...field,
          selected: selectedGeneralFieldsForFilter?.map(el => el.name).includes(group.groupType)
            ? !allItemsSelected
            : field.selected
        }))
      }))
    );
  }, [allItemsSelected, selectedGeneralFieldsForFilter, selectedItemGroupsForFilter]);

  const onChangeGeneralFieldFilter = useCallback(
    updatedFields => {
      setSelectedGeneralFieldsForFilter(updatedFields);
      const differenceForDelete = differenceBy(selectedGeneralFieldsForFilter, updatedFields).map(item => item.name);
      const differenceForAdd = differenceBy(updatedFields, selectedGeneralFieldsForFilter).map(item => item.name);
      if (!isEmpty(differenceForDelete)) {
        setGeneralTemplateMapping(prevState => {
          return prevState.map(group => {
            if (differenceForDelete.includes(group.groupType)) {
              return { ...group, fields: group.fields.map(field => ({ ...field, selected: false })) };
            }
            return group;
          });
        });
      } else if (!isEmpty(differenceForAdd)) {
        setGeneralTemplateMapping(prevState => {
          return prevState.map(group => {
            if (differenceForAdd.includes(group.groupType)) {
              return { ...group, fields: group.fields.map(field => ({ ...field, selected: true })) };
            }
            return group;
          });
        });
      }
    },
    [selectedGeneralFieldsForFilter]
  );

  const onChangeItemGroupFilter = useCallback(
    updatedItemGroups => {
      setSelectedItemGroupForFilter(updatedItemGroups);
      const differenceForDelete = differenceBy(selectedItemGroupsForFilter, updatedItemGroups);
      const differenceForAdd = differenceBy(updatedItemGroups, selectedItemGroupsForFilter);
      if (!isEmpty(differenceForDelete)) {
        setItemGroupTemplateMapping(prevState => {
          return prevState.map(mapping => ({
            ...mapping,
            selected: differenceForDelete.map(el => el.name).includes(mapping.itemGroupName) ? false : mapping.selected
          }));
        });
      } else if (!isEmpty(differenceForAdd)) {
        setItemGroupTemplateMapping(prevState => {
          return prevState.map(mapping => ({
            ...mapping,
            selected: differenceForAdd.map(el => el.name).includes(mapping.itemGroupName) ? true : mapping.selected
          }));
        });
      }
    },
    [selectedItemGroupsForFilter]
  );

  const onCopyTemplate = useCallback(() => {
    if (!isEmpty(notAvailableItemGroupMappings) || !isEmpty(notAvailableGeneralMappings)) {
      NotificationManager.error('Mapping for one of fields is already exists');
    } else {
      addTemplatesToMappings(
        itemGroupTemplateMapping.filter(mapping => mapping.selected),
        generalTemplateMapping
          .flatMap(element => element.fields)
          .filter(mapping => mapping.selected)
          .map(field => omit(field, ['selected', 'id']))
          .map(el => ({ ...el, alwaysSent: false }))
      );
      modalBox.close();
    }
  }, [
    addTemplatesToMappings,
    itemGroupTemplateMapping,
    modalBox,
    notAvailableItemGroupMappings,
    notAvailableGeneralMappings,
    generalTemplateMapping
  ]);

  return (
    <>
      <ModalBoxes.Header>Mapping Template</ModalBoxes.Header>
      <ModalBoxes.Body>
        <div className="filter-wrapper">
          <div className="setup-item setup-multiselect">
            <span>Template Names</span>
            <Select
              dataSource={templateNames}
              value={selectedTemplateName}
              onChange={newValue => {
                setSelectedTemplateName(newValue);
              }}
              label="Template Name"
              searchable={true}
              closeOnSelectedOptionClick={false}
              deselectOnSelectedOptionClick={false}
              clearable={false}
              validate={false}
              className="mapping-input"
            />
          </div>
          {!isEmpty(selectedTemplateName) && (
            <>
              <div className="setup-item setup-multiselect">
                <span>General Fields</span>
                <MultiSelect
                  dataSource={generalFieldsForFilter}
                  onChange={onChangeGeneralFieldFilter}
                  searchable
                  value={selectedGeneralFieldsForFilter}
                  className="mapping-input"
                  clearable={true}
                  validate={false}
                  label="Field Name"
                />
              </div>
              <div className="setup-item setup-multiselect">
                <span>Item Groups</span>
                <MultiSelect
                  dataSource={itemGroupsForFilter}
                  onChange={onChangeItemGroupFilter}
                  searchable
                  value={selectedItemGroupsForFilter}
                  className="mapping-input"
                  clearable={true}
                  validate={false}
                  label="Item Group Name"
                />
              </div>
            </>
          )}
        </div>
        {(!isEmpty(selectedItemGroupsForFilter) || !isEmpty(selectedGeneralFieldsForFilter)) && (
          <div>
            <Checkbox key="selected" onChange={toggleAllItems} checked={allItemsSelected} label="Copy All Available" />
          </div>
        )}
        {generalTemplates}
        {itemGroupTemplates}
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority={'medium'} onClick={() => modalBox.close()}>
            Cancel
          </Button>
          <Button onClick={onCopyTemplate}>Copy</Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
};
