import { handleActions } from 'redux-actions';

import {
  CRA_REVIEW_PAGE,
  CRA_REVIEW_PAGE_SIZE,
  CRA_REVIEW_SORTED_BY,
  CRA_REVIEW_SUBJECT_ID
} from '../../../components/pages/patient-source/CraReview/defaultCraReviewTableConfiguration';

import { options } from './actions';
import { RESET, UPDATE } from './types';

const defaultState = {
  studyUniqueIdentifier: null,
  siteUniqueIdentifier: null,
  subjectId: CRA_REVIEW_SUBJECT_ID,
  sortedBy: [CRA_REVIEW_SORTED_BY],
  pageSize: CRA_REVIEW_PAGE_SIZE,
  page: CRA_REVIEW_PAGE
};
export default handleActions(
  {
    [UPDATE]: function(state, { payload }) {
      return { ...state, ...payload };
    },
    [RESET]: function() {
      return defaultState;
    }
  },
  defaultState,
  options
);
