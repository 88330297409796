export function getVisibleItems(table) {
  const filtered = table?.getResolvedState()?.sortedData;
  const { page } = table?.state;
  const { pageSize } = table?.state;
  const slicedByPages = chunkArray(filtered, pageSize);
  return slicedByPages[page];
}
function chunkArray(myArray, chunkSize) {
  const results = [];

  for (let i = 0; i < myArray.length; i += chunkSize) {
    results.push(myArray.slice(i, i + chunkSize));
  }

  return results;
}
