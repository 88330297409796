import React from 'react';
import { Cancel } from '@mui/icons-material';
import moment from 'moment/moment';

import DatePicker from '../../../../common/data-entry/DatePicker';
import Select from '../../../../common/data-entry/Select';
import { useModeReportsActions } from '../ModeReportsContext';
import { DATE_FORMAT } from '../modeReportsServices';
import ReportMultiSelectFilter from '../ReportMultiSelectFilter';

function ModeReportsFilterBuilder({
  filterKey,
  filterLabel,
  filterType,
  clearable,
  dataSource,
  selectedValues,
  selectedStudy,
  selectedPcn,
  selectedLevel,
  selectedAggregation,
  controlId,
  onChangeFuncitonName,
  onClearDatePicker
}) {
  const valueFilterMapping = {
    studies: selectedStudy,
    pcn: selectedPcn,
    levels: selectedLevel,
    aggregation: selectedAggregation
  };
  const { dispatch } = useModeReportsActions();
  const onChange = value => dispatch({ type: onChangeFuncitonName, payload: { key: filterKey, value: value } });
  const onClear = value => dispatch({ type: onClearDatePicker, payload: { key: filterKey, value: value } });

  if (filterType === 'select') {
    return (
      <Select
        controlId={controlId}
        label={filterLabel}
        dataSource={dataSource}
        value={valueFilterMapping[filterKey]}
        clearable={clearable}
        searchable={true}
        deselectOnSelectedOptionClick={true}
        clearSelectedValueOnDataSourceChange={false}
        onChange={onChange}
        validate={false}
      />
    );
  } else if (filterType === 'multiselect') {
    return (
      <ReportMultiSelectFilter
        dataSource={dataSource}
        value={selectedValues}
        name={filterLabel}
        id={controlId}
        onChange={onChange}
      />
    );
  } else if (filterType === 'date picker') {
    return (
      <DatePicker
        iconsAfter={<Cancel style={{ cursor: 'pointer', marginTop: '3px' }} onClick={onClear} />}
        value={new moment(selectedValues, DATE_FORMAT)}
        onChange={onChange}
        id={controlId}
        label={filterLabel}
      />
    );
  } else {
    return undefined;
  }
}
export default ModeReportsFilterBuilder;
