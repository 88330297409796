import React from 'react';

import './ItemGroupElementHeaderWithIcon.scss';

export function ItemGroupElementHeaderWithIcon({ content, statusChip }) {
  return (
    <div className="erc-item-group-label">
      <div className="erc-item-group-label-content">{content}</div>
      {statusChip && (
        <div className="erc-item-group-label-status">
          <span>{statusChip}</span>
        </div>
      )}
    </div>
  );
}
