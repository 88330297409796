import { CLOSED } from 'constants/ssuStatuses';
import { ROLE_SYSTEM_ADMINISTRATOR } from 'constants/userRoles';
import { userHasRole } from 'services/auth';
import { date, object, string } from 'yup';

import { PAYMENT_TYPE_DIGITAL, PAYMENT_TYPE_PHYSICAL } from '../../../../../constants';
import { emailRegExp } from '../../../../patientInfoService';

import { addressSchema, phonesInfoSchema, ssnSchema } from './fieldSchemas';

const REQUIRED_FIELD_MESSAGE = 'Required field';

export const addPatientSsuValidationSchema = object({
  study: object({}).required(REQUIRED_FIELD_MESSAGE),
  site: object({})
    .required(REQUIRED_FIELD_MESSAGE)
    .test('as', 'SSU is Closed', function(site) {
      return !(!userHasRole(ROLE_SYSTEM_ADMINISTRATOR) && site.siteStatus === CLOSED);
    }),
  source: object().required(REQUIRED_FIELD_MESSAGE),
  status: object().required(REQUIRED_FIELD_MESSAGE)
});

const cardIdSchema = string()
  .nullable()
  .ifNotEmpty(string().length(13, 'Please fill in the field PID correctly'))
  .when(['$originalPID', 'digitalPaymentAllowed', 'paymentType'], function(
    [originalPID, digitalPaymentAllowed, paymentType],
    schema
  ) {
    if (originalPID) {
      return schema.required('Please fill in the field PID correctly');
    } else if (paymentType === PAYMENT_TYPE_PHYSICAL) {
      return schema.required(`The PID field is required for Physical payment type`);
    }
    return schema;
  });

export const addValidationSchema = object({}).shape(
  {
    firstName: string().required(REQUIRED_FIELD_MESSAGE),
    lastName: string().required(REQUIRED_FIELD_MESSAGE),
    dob: date().required(REQUIRED_FIELD_MESSAGE),
    phonesInfo: phonesInfoSchema,
    address: addressSchema,
    email: string()
      .nullable()
      .ifNotEmpty(string().matches(emailRegExp, 'Please fill in the field Email correctly'))
      .when(['paymentType'], function([paymentType], schema) {
        if (paymentType === PAYMENT_TYPE_DIGITAL) {
          return schema.required('Required with digital payment card type');
        }
        return schema;
      }),
    guardianEmail: string()
      .nullable()
      .ifNotEmpty(string().matches(emailRegExp, 'Please fill in the field Guardian Email correctly')),
    guardianPhone: string()
      .nullable()
      .ifNotEmpty(string().length(12, 'Please fill in the field Guardian Phone correctly')),
    heightFt: string().when(['heightIn'], function([heightIn], schema) {
      if (heightIn) {
        return schema.required('Please fill the Height (ft)');
      }
      return schema;
    }),
    cardId: cardIdSchema,
    ssn: ssnSchema,
    paymentType: string()
      .oneOf([PAYMENT_TYPE_PHYSICAL, PAYMENT_TYPE_DIGITAL])
      .when(['digitalPaymentAllowed', 'cardId'], function([digitalPaymentAllowed, cardId], schema) {
        if (digitalPaymentAllowed && cardId) {
          return schema.required('Required with PID');
        }
        return schema.nullable();
      })
  },
  [['paymentType', 'cardId']]
);
