import React from 'react';
import ModalBoxes from 'common/feedback/ModalBoxes/ModalBoxes';
import { map } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';
import { pick } from 'lodash/object';

const fieldsMap = {
  paymentType: 'Payment Card Type',
  cardId: 'PID',
  ssn: 'SSN',
  'phonesInfo.primary': 'Primary Phone #',
  'address.addressLine1': 'Address 1',
  'address.zipCode': 'Zip',
  'address.city': 'City',
  'address.state': 'State',
  email: 'Email'
};

export default function informAboutRequiredFields(validationMessages, info) {
  if (info.address.country !== 'US') {
    return;
  }
  const fields = pick(validationMessages, Object.keys(fieldsMap));
  if (isEmpty(fields) || !info.digitalPaymentAllowed) {
    return;
  }
  return ModalBoxes.confirm({
    title: 'Assign Payment Card Type',
    content: (
      <>
        <p>
          The following required field(s) are missing for {info.paymentType}
          <br />
          Please fill in field before saving assigment:
        </p>
        <p>
          {map(fields, function(value, key) {
            return (
              <>
                {fieldsMap[key]}
                <br />
              </>
            );
          })}
        </p>
      </>
    ),
    cancelButton: 'Ok',
    confirmButton: false
  }).then(
    () => {},
    () => {}
  );
}
