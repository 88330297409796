import { Children, Fragment, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { reduce } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

import ShowMore from '../ShowMore/ShowMore';

import './CollapsibleList.scss';

export default function CollapsibleList({ children }) {
  const ref = useRef(null),
    [maxIndex, setMaxIndex] = useState(null);

  const elements = useMemo(
    function() {
      return Children.toArray(children?.type !== Fragment ? children : children?.props?.children);
    },
    [children]
  );

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
    function updateSize() {
      const { clientWidth } = ref.current,
        $innerItems = ref.current.querySelectorAll('.shadow-items > *'),
        $showMore = ref.current.querySelectorAll('.shadow-items > .pih-show-more')[0],
        itemsWidth = [...$innerItems].map(({ offsetWidth }) => offsetWidth);

      setMaxIndex(getMax(itemsWidth, clientWidth, $showMore.offsetWidth));
    }
  });

  const [visible, hidden] = useMemo(
    function() {
      return reduce(
        elements,
        function(a, v, i) {
          if (i > maxIndex) {
            a[1].push(v);
          } else {
            a[0].push(v);
          }
          return a;
        },
        [[], []]
      );
    },
    [elements, maxIndex]
  );

  return (
    <div className="collapsible-list" ref={ref}>
      <div className="shadow-items">
        {elements}
        <ShowMore />
      </div>
      <div className="visible-items">
        {visible}
        {!isEmpty(hidden) && <ShowMore>{hidden}</ShowMore>}
      </div>
    </div>
  );
}

function getMax(elements, width, showMoreWidth) {
  const lastIndex = elements.length - 1;
  for (let i = 0, w = 0; i <= lastIndex; ++i) {
    w = w + elements[i];
    if (w > width - showMoreWidth) {
      return i - 1;
    }
  }
  return lastIndex;
}
