import { SS } from '../../../../constants/accessToPages';
import PreviewProtocolSetup from '../../setup/Protocol/SourceDataPreview/SourceDataPreview';

import SiteDetails from './SiteDetails/SiteDetails';
import StudyDetails from './StudyDetails';

export const studyDetailsRouterConfig = {
  /** TODO: need to rewrite this routes group to correct path
   * `/study-details`
   * `/study-details/:id`
   * `/study-details/:id/:siteId`
   * **/
  name: 'Study Details',
  path: '/study-details',
  component: StudyDetails,
  access: [SS],
  inherited: [
    {
      name: 'Site Details',
      path: '/siteDetails/:siteName/:studyName',
      component: SiteDetails,
      access: [SS]
    },
    {
      name: 'Source Data Preview',
      path: '/:studyId/protocol/:protocolIdentity/preview',
      component: PreviewProtocolSetup,
      access: [SS]
    }
  ]
};
