import React, { useCallback, useEffect, useState } from 'react';
import { orderBy } from 'lodash/collection';

import PatientInfoPageApi from '../../../../../../../api/patient/PatientInfoPageApi';
import TextArea from '../../../../../../../common/data-entry/TextArea';
import Button from '../../../../../../../common/general/Button';
import Icon from '../../../../../../../common/general/Icon';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import {
  INSTRUCTION_ADDED,
  INSTRUCTION_UPDATED,
  PATIENT_LOCKED
} from '../../../../../../../constants/notificationMessages';
import { MANAGE_PATIENT_OVERALL_INFORMATION } from '../../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../../services/auth';
import { onRequestError } from '../../../../../../../services/handlers';
import { useCurrentRoute } from '../../../../../../root/router';
import { usePatientProfile } from '../../PatientProfileContext';

export default function PatientDetailsInstructionsInformation() {
  const currentRoute = useCurrentRoute();
  const patientId = currentRoute?.params?.patientId;
  const [currentInstruction, setCurrentInstruction] = useState({});
  const [initialInstruction, setInitialInstruction] = useState({});
  const { setHasUnsavedInstructions } = usePatientProfile();

  function normalizeInstruction(instruction) {
    return instruction || '';
  }

  const fetchInstructions = useCallback(() => {
    if (patientId) {
      PatientInfoPageApi.getPatientInstructionsByPatientId(patientId).then(({ data: instructions }) => {
        const lastInstruction =
          instructions.length > 0
            ? orderBy(instructions, ['instructionCreatedDate'], ['desc'])[0]
            : { instruction: '' };
        const normalizedInstruction = normalizeInstruction(lastInstruction.instruction);

        setCurrentInstruction({ ...lastInstruction, instruction: normalizedInstruction });
        setInitialInstruction({ ...lastInstruction, instruction: normalizedInstruction });
      });
    }
  }, [patientId]);

  const saveInstruction = useCallback(
    function() {
      if (!currentInstruction?.instruction || !patientId) return;
      const { instruction, key } = currentInstruction;

      if (!key?.id) {
        PatientInfoPageApi.addPatientInstruction(patientId, instruction)
          .then(function() {
            fetchInstructions();
            NotificationManager.success(INSTRUCTION_ADDED);
          })
          .catch(() => {
            NotificationManager.error(PATIENT_LOCKED);
          });
        return;
      }

      PatientInfoPageApi.updatePatientInstruction(patientId, key?.id, instruction)
        .then(() => {
          fetchInstructions();
          NotificationManager.success(INSTRUCTION_UPDATED);
        })
        .catch(err => onRequestError(err));
    },
    [patientId, currentInstruction, fetchInstructions]
  );

  useEffect(fetchInstructions, [fetchInstructions]);

  const disabledSaveButtonRule =
    !currentInstruction?.instruction || initialInstruction?.instruction === currentInstruction?.instruction;

  const userCanEdit = userHasAccessTo(MANAGE_PATIENT_OVERALL_INFORMATION);

  useEffect(() => {
    const hasChanges = currentInstruction?.instruction !== initialInstruction?.instruction;
    setHasUnsavedInstructions(hasChanges);
  }, [currentInstruction?.instruction, initialInstruction?.instruction, setHasUnsavedInstructions]);

  return (
    <div className="patient-profile-information-content-section">
      <div className="patient-profile-information-header">
        <div className="patient-profile-information-header-title">Patient Instructions</div>
        {userCanEdit && (
          <div className="patient-profile-information-header-edit">
            <Button disabled={disabledSaveButtonRule} onClick={saveInstruction} priority="medium" size="h28">
              <Icon>save</Icon> Save
            </Button>
          </div>
        )}
      </div>
      <div className="patient-profile-information-data-row">
        {userCanEdit && (
          <TextArea
            id="patient-profile-instruction-text-field"
            style={{ resize: 'none' }}
            value={currentInstruction?.instruction}
            rows={1}
            onChange={({ target }) => setCurrentInstruction(prevState => ({ ...prevState, instruction: target.value }))}
            label="Enter Instruction"
            name="Enter Instruction"
          />
        )}
        {!userCanEdit && (currentInstruction ? currentInstruction.instruction : `No instructions for patient provided`)}
      </div>
    </div>
  );
}
