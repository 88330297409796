import {
  fetchLatestHighlightedEncounters,
  fetchPIFormCount,
  fetchPIFormCountAud,
  fetchPIReviewableEncounters,
  fetchPIReviewableUpdatedEncounters,
  fetchPIReviewedEncounters,
  fetchPIStudies
} from '../actions/types/piWorkList';
import { FAILURE, REQUEST, SUCCESS } from '../actions/types/utils';

const formCount = (state, action) => {
  switch (action.type) {
    case fetchPIFormCount[REQUEST]:
      return {
        ...state,
        isFetchingFormCount: true
      };
    case fetchPIFormCount[SUCCESS]:
      return {
        ...state,
        isFetchingFormCount: false,
        formCountObj: action.payload
      };
    case fetchPIFormCount[FAILURE]:
      return {
        ...state,
        isFetchingFormCount: false
      };
    default:
      return state;
  }
};

const formCountAud = (state, action) => {
  switch (action.type) {
    case fetchPIFormCountAud[REQUEST]:
      return {
        ...state,
        isFetchingFormCountAud: true
      };
    case fetchPIFormCountAud[SUCCESS]:
      return {
        ...state,
        isFetchingFormCountAud: false,
        formCountAudObj: action.payload
      };
    case fetchPIFormCountAud[FAILURE]:
      return {
        ...state,
        isFetchingFormCountAud: false
      };
    default:
      return state;
  }
};

const reviewableEncounters = (state, action) => {
  switch (action.type) {
    case fetchPIReviewableEncounters[REQUEST]:
      return {
        ...state,
        isFetchingReviewableEncounters: true
      };
    case fetchPIReviewableEncounters[SUCCESS]:
      return {
        ...state,
        isFetchingReviewableEncounters: false,
        reviewableEncountersList: action.payload
      };
    case fetchPIReviewableEncounters[FAILURE]:
      return {
        ...state,
        isFetchingReviewableEncounters: false
      };
    default:
      return state;
  }
};

const reviewedEncounters = (state, action) => {
  switch (action.type) {
    case fetchPIReviewedEncounters[REQUEST]:
      return {
        ...state,
        isFetchingReviewedEncounters: true
      };
    case fetchPIReviewedEncounters[SUCCESS]:
      return {
        ...state,
        isFetchingReviewedEncounters: false,
        reviewedEncountersList: action.payload
      };
    case fetchPIReviewedEncounters[FAILURE]:
      return {
        ...state,
        isFetchingReviewedEncounters: false
      };
    default:
      return state;
  }
};

const reviewableUpdatedEncounters = (state, action) => {
  switch (action.type) {
    case fetchPIReviewableUpdatedEncounters[REQUEST]:
      return {
        ...state,
        isFetchingReviewableUpdatedEncounters: true
      };
    case fetchPIReviewableUpdatedEncounters[SUCCESS]:
      return {
        ...state,
        isFetchingReviewableUpdatedEncounters: false,
        reviewableUpdatedEncountersList: action.payload
      };
    case fetchPIReviewableUpdatedEncounters[FAILURE]:
      return {
        ...state,
        isFetchingReviewableUpdatedEncounters: false
      };
    default:
      return state;
  }
};

const highlightedEncounters = (state, action) => {
  switch (action.type) {
    case fetchLatestHighlightedEncounters[REQUEST]:
      return {
        ...state,
        isFetchingLatestHighlightedEncounters: true
      };
    case fetchLatestHighlightedEncounters[SUCCESS]:
      return {
        ...state,
        isFetchingLatestHighlightedEncounters: false,
        latestHighlightedEncountersObj: action.payload.reduce((highlightedMap, highlighted) => {
          Object.assign(highlightedMap, {
            [highlighted.patientEncounterGroupId]: highlighted
          });
          return highlightedMap;
        }, {})
      };
    case fetchLatestHighlightedEncounters[FAILURE]:
      return {
        ...state,
        isFetchingLatestHighlightedEncounters: false
      };
    default:
      return state;
  }
};

const studies = (state, action) => {
  switch (action.type) {
    case fetchPIStudies[REQUEST]:
      return {
        ...state,
        isFetchingStudies: true
      };
    case fetchPIStudies[FAILURE]:
      return {
        ...state,
        isFetchingStudies: false
      };
    case fetchPIStudies[SUCCESS]:
      return {
        isFetchingStudies: false,
        studyList: action.payload.map(({ uniqueIdentifier, studyName }) => ({ id: uniqueIdentifier, name: studyName }))
      };
    default:
      return state;
  }
};
const initState = {
  formCount: {
    formCountObj: {},
    isFetchingFormCount: false
  },
  formCountAud: {
    formCountAudObj: {},
    isFetchingFormCountAud: false
  },
  reviewableEncounters: {
    reviewableEncountersList: [],
    isFetchingReviewableEncounters: false
  },
  reviewedEncounters: {
    reviewedEncountersList: [],
    isFetchingReviewedEncounters: false
  },
  reviewableUpdatedEncounters: {
    reviewableUpdatedEncountersList: [],
    isFetchingReviewableUpdatedEncounters: false
  },
  latestHighlightedEncounters: {
    latestHighlightedEncountersObj: {},
    isFetchingLatestHighlightedEncounters: false
  },
  studies: {
    studyList: [],
    isFetchingStudies: false
  }
};

const piWorkList = (state = initState, action) => {
  return {
    formCount: formCount(state.formCount, action),
    formCountAud: formCountAud(state.formCountAud, action),
    reviewableEncounters: reviewableEncounters(state.reviewableEncounters, action),
    reviewedEncounters: reviewedEncounters(state.reviewedEncounters, action),
    reviewableUpdatedEncounters: reviewableUpdatedEncounters(state.reviewableUpdatedEncounters, action),
    latestHighlightedEncounters: highlightedEncounters(state.latestHighlightedEncounters, action),
    studies: studies(state.studies, action)
  };
};

export default piWorkList;
