import { SvgIcon } from '@mui/material';

export const TextColorIcon = ({ onClick }) => {
  return (
    <SvgIcon onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M15.2 13.494C15.2 13.494 11.6 17.394 11.6 19.794C11.5934 20.2733 11.6813 20.7493 11.8587 21.1946C12.036 21.64 12.2994 22.046 12.6337 22.3896C13.3088 23.0835 14.2319 23.4807 15.2 23.494C16.168 23.5073 17.1017 23.1354 17.7956 22.4603C18.1391 22.126 18.4135 21.7273 18.603 21.287C18.7925 20.8467 18.8934 20.3733 18.9 19.894V19.794C18.9 17.394 15.2 13.494 15.2 13.494ZM13.73 12.137L14.399 11.413L12.1 5H10.1L5.09998 19H7.09998L8.52998 15H11.473C12.1517 13.9909 12.9048 13.0339 13.726 12.137H13.73ZM11.1 7.8L12.96 13H9.24398L11.1 7.8Z"
          fill="black"
          fillOpacity="0.6"
        />
      </svg>
    </SvgIcon>
  );
};
