import { EventApi } from '../../../api';

import {
  NON_PATIENT_EVENT,
  PATIENT_ENCOUNTER_EVENT,
  PATIENT_SITUATIONAL_ENCOUNTER_EVENT,
  PATIENT_UNEXPECTED_ENCOUNTER_EVENT
} from './CalendarEventType';
import { toBackendModel } from './EventTransformer';

export const eventCRUDService = {
  create: event => {
    switch (event.type) {
      case PATIENT_ENCOUNTER_EVENT:
        return EventApi.createPatientEncounterEvent(toBackendModel(event), event.sitePatientId);
      case PATIENT_UNEXPECTED_ENCOUNTER_EVENT:
        return EventApi.createPatientEvent(toBackendModel(event), event.sitePatientId);
      case NON_PATIENT_EVENT:
        return EventApi.createNonPatientEvent(toBackendModel(event));
      case PATIENT_SITUATIONAL_ENCOUNTER_EVENT:
        return EventApi.createPatientSituationalEncounterEvent(toBackendModel(event), event.sitePatientId);
      default:
        console.log(`Event type is not Supported: ${event.type}`);
    }
  },

  update: event => {
    switch (event.type) {
      case PATIENT_ENCOUNTER_EVENT:
        return EventApi.updatePatientEncounterEvent(toBackendModel(event), event.sitePatientId);
      case PATIENT_UNEXPECTED_ENCOUNTER_EVENT:
        return EventApi.updatePatientEvent(toBackendModel(event), event.sitePatientId);
      case NON_PATIENT_EVENT:
        return EventApi.updateNonPatientEvent(toBackendModel(event));
      case PATIENT_SITUATIONAL_ENCOUNTER_EVENT:
        return EventApi.updatePatientSituationalEncounterEvent(toBackendModel(event), event.sitePatientId);
      default:
        console.log(`Event type is not Supported: ${event.type}`);
    }
  },

  cancel: (eventId, type, cancellationReasonId, cancellationComments, ssuPatientId) => {
    switch (type) {
      case PATIENT_ENCOUNTER_EVENT:
        return EventApi.cancelPatientEncounterEvent(eventId, ssuPatientId, {
          cancellationReasonId,
          cancellationComments
        });
      case PATIENT_UNEXPECTED_ENCOUNTER_EVENT:
        return EventApi.cancelPatientEvent(eventId, ssuPatientId, { cancellationReasonId, cancellationComments });
      case NON_PATIENT_EVENT:
        return EventApi.cancelNonPatientEvent(eventId, { cancellationReasonId, cancellationComments });
      case PATIENT_SITUATIONAL_ENCOUNTER_EVENT:
        return EventApi.cancelPatientSituationalEncounterEvent(eventId, ssuPatientId, {
          cancellationReasonId,
          cancellationComments
        });
      default:
        console.log(`Event type is not Supported: ${type}`);
    }
  }
};
