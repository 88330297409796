import React, { useContext } from 'react';
import * as PropTypes from 'prop-types';

import { PatientEncounterReviewApi } from '../../../../../../../api';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import {
  ROLE_CLINICAL_QUALITY_COORDINATOR,
  ROLE_FINANCE_ANALYST,
  ROLE_OPERATION_MANAGER,
  ROLE_OPERATIONS_ANALYST,
  ROLE_STUDY_LEAD
} from '../../../../../../../constants/userRoles';
import { userHasRole } from '../../../../../../../services/auth';
import { COMMENTS_DOMAIN_CODE, NEW_TABLE, TABLE } from '../../reviewConstants';
import { ReviewContext } from '../../ReviewContext';
import { isSmOrPiReview } from '../../reviewService';
import ElementHistoryLogModal from '../HistoryLogModal/ElementHistoryLogModal';
import ItemGroupElementHeader from '../ItemGroupElementHeader/ItemGroupElementHeader';
import { getItemGroupType, isSignedReviewStatus } from '../reviewContentService';

import CommentItemGroup from './CommentItemGroup/CommentItemGroup';
import ItemGroupNewTable from './ItemGroupNewTable/ItemGroupNewTable';
import ItemGroupNotPerformed from './ItemGroupNotPerformed/ItemGroupNotPerformed';
import ItemGroupProcedure from './ItemGroupProcedure/ItemGroupProcedure';
import ItemGroupTable from './ItemGroupTable/ItemGroupTable';

import './ItemGroupElement.scss';

export default function ItemGroupElement({ itemGroupSnapshotState, reviewStatus }) {
  const {
    reviewType,
    isHistoricalData,
    requiredAttentionItemGroupsConfirmStatuses,
    setRequiredAttentionItemGroupsConfirmStatuses
  } = useContext(ReviewContext);

  const patientItemGroupId = itemGroupSnapshotState.itemGroupRef.patientItemGroupId;

  const itemGroupName = itemGroupSnapshotState?.itemGroupRef.patientItemGroupName;
  const itemGroupId = itemGroupSnapshotState?.itemGroupRef.patientItemGroupId;
  const itemGroupType = getItemGroupType(itemGroupSnapshotState?.itemGroupRef);
  const domainCode = itemGroupSnapshotState?.itemGroupRef.domainCode;
  const isNotPerformed = itemGroupSnapshotState?.dataStatus.notPerformed;
  const showHistoryButton =
    !isNotPerformed &&
    itemGroupType !== TABLE &&
    itemGroupType !== NEW_TABLE &&
    domainCode !== COMMENTS_DOMAIN_CODE &&
    !isHistoricalData;

  const isItemGroupRequiredAttention = requiredAttentionItemGroupsConfirmStatuses.hasOwnProperty(patientItemGroupId);

  const userWithReadOnlyAccess = [
    ROLE_STUDY_LEAD,
    ROLE_OPERATION_MANAGER,
    ROLE_FINANCE_ANALYST,
    ROLE_OPERATIONS_ANALYST,
    ROLE_CLINICAL_QUALITY_COORDINATOR
  ];

  const showItemGroupConfirmButton =
    isSmOrPiReview(reviewType) &&
    isItemGroupRequiredAttention &&
    !isHistoricalData &&
    !userHasRole(userWithReadOnlyAccess);
  const isItemGroupConfirmed = requiredAttentionItemGroupsConfirmStatuses[patientItemGroupId];
  const disableItemGroupConfirmButton = isSignedReviewStatus(reviewStatus);

  return (
    <div className="eds-review-item-group-element" id={patientItemGroupId}>
      <ItemGroupElementHeader
        itemGroupId={itemGroupId}
        itemGroupName={itemGroupName}
        itemGroupType={itemGroupType}
        showItemGroupConfirmButton={showItemGroupConfirmButton}
        disableItemGroupConfirmButton={disableItemGroupConfirmButton}
        isItemGroupConfirmed={isItemGroupConfirmed}
        onConfirmItemGroup={onConfirmItemGroup}
        showHistoryButton={showHistoryButton}
        getAnswersHistory={getAnswersHistory}
        isNotPerformedItemGroup={itemGroupSnapshotState?.dataStatus?.notPerformed}
      />
      {templateResolver(itemGroupSnapshotState)}
    </div>
  );

  function onConfirmItemGroup() {
    setRequiredAttentionItemGroupsConfirmStatuses(prevState => ({
      ...prevState,
      [patientItemGroupId]: !isItemGroupConfirmed
    }));
  }

  function getAnswersHistory() {
    const ssuPatientId = itemGroupSnapshotState.rows[0].rowSnapshotKey.rowKey.ssuPatientId;
    const ssuPatientEncounterId = itemGroupSnapshotState.itemGroupRef.patientEncounterId;
    const itemGroupId = patientItemGroupId;
    const rowId = itemGroupSnapshotState.rows[0].rowSnapshotFormData.rowId;
    const itemGroupName = itemGroupSnapshotState.itemGroupRef.patientItemGroupName;
    const onLoadData = () =>
      PatientEncounterReviewApi.getItemGroupAnswerHistory(ssuPatientId, ssuPatientEncounterId, itemGroupId, rowId);
    ModalBoxes.open({
      component: <ElementHistoryLogModal itemGroupName={itemGroupName} onLoadData={onLoadData} />,
      title: itemGroupName
    });
  }
}

ItemGroupElement.propTypes = {
  itemGroupSnapshotState: PropTypes.object
};

function templateResolver(itemGroupSnapshotState) {
  const { patientItemGroupType, domainCode } = itemGroupSnapshotState?.itemGroupRef;
  const { notPerformedReason, notPerformed } = itemGroupSnapshotState.dataStatus;

  if (notPerformed) {
    return <ItemGroupNotPerformed notPerformedReason={notPerformedReason} />;
  }

  if (patientItemGroupType === TABLE) {
    return <ItemGroupTable itemGroupSnapshotState={itemGroupSnapshotState} />;
  }

  if (domainCode === COMMENTS_DOMAIN_CODE) {
    return <CommentItemGroup itemGroupSnapshotState={itemGroupSnapshotState} />;
  }

  if (patientItemGroupType === NEW_TABLE) {
    return <ItemGroupNewTable itemGroupSnapshotState={itemGroupSnapshotState} />;
  }

  return <ItemGroupProcedure itemGroupSnapshotState={itemGroupSnapshotState} />;
}
