import { useCallback, useEffect } from 'react';
import { EventEmitter } from 'events';
import { get } from 'lodash/object';

import { MapsApi } from '../../../../../../../../api';
import { getCountryName, getRegionName } from '../../../../../../../../services/geographic';

import {
  useMileageAddressesActions,
  useMileageAddressesState
} from './MileageAddressesContext/MileageAddressesContext';

const AddressValidatorEventEmitter = new EventEmitter();

export default function AddressValidator() {
  const { mileageAddresses } = useMileageAddressesState(),
    { setValid } = useMileageAddressesActions();

  const revalidate = useCallback(
    function(key) {
      const { countryId, regionId, city, address1: address, zipCode: zip } = get(mileageAddresses, key);
      if (!countryId || !regionId || !city || !address) {
        return;
      }
      MapsApi.validateAddress({
        country: getCountryName(countryId),
        region: getRegionName(regionId, countryId),
        city,
        address,
        zip: zip || null
      }).then(
        function({ data }) {
          if (data.validIgnoringZip && data.zip) {
            setValid(key, { valid: true, zipCode: data.zip });
          } else {
            setValid(key, false);
          }
        },
        () => {
          setValid(key, false);
        }
      );
    },
    [mileageAddresses, setValid]
  );

  useEffect(
    function() {
      AddressValidatorEventEmitter.on('change-state', listener);
      return function() {
        AddressValidatorEventEmitter.removeListener('change-state', listener);
      };
      function listener({ type, key }) {
        if (type === 'REVALIDATE') {
          revalidate(key);
        }
      }
    },
    [revalidate]
  );

  return null;
}

AddressValidator.revalidate = function(key) {
  setTimeout(function() {
    AddressValidatorEventEmitter.emit('change-state', { type: 'REVALIDATE', key });
  }, 100);
};
