import React from 'react';

import { Amount } from '../../../../../shared/amount-view/Amount/Amount';
import { styles } from '../../EncounterInvoiceTemplateStyles';

export const BalanceCreditDebit = ({ item }) => {
  return (
    <div style={styles.balance} className="row-item" data-testid="ledger-event-item">
      <div style={styles.balance.header}>
        <div style={styles.balance.header.comment}>{item.comment}</div>
        <div style={styles.balance.header.subtotal}>
          Total
          <div style={styles.amountWrapper}>
            <Amount coinsAmount={item.balanceDue} showDollarSign={true} />
          </div>
        </div>
      </div>
    </div>
  );
};
