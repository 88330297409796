import React, { useMemo } from 'react';
import { isNull } from 'lodash/lang';
import PropTypes from 'prop-types';

import ToggleSwitch from '../../../../../../../../common/inputs/ToggleSwitch';
import { useTableItemGroupActions, useTableItemGroupState } from '../../TableItemGroupContext/TableItemGroupContext';
import fieldPropTypes from '../fieldPropTypes';

import './ToggleField.scss';

const positiveValues = ['Y', 'CS', 'NORM'];
const negativeValues = ['N', 'NCS', 'ABNORM'];

export default function ToggleField({ field }) {
  const { fieldUid } = field;
  const {
    isPreviewOnly,
    originConditionalLogicAndRequiredFieldsMaps,
    encounterTableItemGroup
  } = useTableItemGroupState();
  const { toggleFieldOnChange } = useTableItemGroupActions();

  return (
    <ToggleFieldReadonly
      field={field}
      onChange={value => {
        toggleFieldOnChange(value, fieldUid, originConditionalLogicAndRequiredFieldsMaps, encounterTableItemGroup);
      }}
      disabled={isPreviewOnly}
    />
  );
}

export function ToggleFieldReadonly({ field, onChange, ...rest }) {
  const { fieldValue, codeDefinition } = field;
  const [yesCode, noCode] = useMemo(
    function() {
      return [
        // TODO: replace with custom sorting
        codeDefinition.find(it => positiveValues.includes(it.decode)) || codeDefinition[0],
        codeDefinition.find(it => negativeValues.includes(it.decode)) || codeDefinition[1]
      ];
    },
    [codeDefinition]
  );

  const { name: yesLabel, decode: yesValue } = yesCode;
  const { name: noLabel, decode: noValue } = noCode;

  const value = useMemo(
    function() {
      if (fieldValue === yesValue) {
        return 1;
      }
      if (fieldValue === noValue) {
        return 2;
      }
      return 0;
    },
    [fieldValue, yesValue, noValue]
  );

  return (
    <div className="toggle-field">
      <ToggleSwitch
        value={value}
        leftLabel={yesLabel}
        rightLabel={noLabel}
        onChange={function(value) {
          return onChange(getFieldValueByValue(value, yesValue, noValue));
        }}
        {...rest}
      />
    </div>
  );
}

function getFieldValueByValue(value, yesValue, noValue) {
  if (value === 1) {
    return yesValue;
  }
  if (value === 2) {
    return noValue;
  }
  return null;
}

const propTypes = {
  field: PropTypes.shape({
    ...fieldPropTypes,
    fieldType: PropTypes.oneOf(['radio']).isRequired,
    fieldValue: function(props, propName, componentName) {
      const allowedValues = props.codeDefinition.map(({ decode }) => decode);
      const value = props[propName];
      if (!isNull(value) && !allowedValues.includes(value)) {
        return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed.`);
      }
    }
  })
};

ToggleFieldReadonly.defaultProps = {
  disabled: true
};

ToggleFieldReadonly.propTypes = propTypes;
ToggleField.propTypes = propTypes;
