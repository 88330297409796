import React, { useCallback, useContext, useState } from 'react';
import { Icon, Menu, MenuItem } from '@mui/material';
import printJS from 'print-js';

import { FinInvoiceApi } from '../../../../../../../api';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import { FileViewerContext } from '../../../../../../../contexts/FileViewerContext';
import { onRequestError } from '../../../../../../../services/handlers';
import { downloadFile, openFile, openMailDetailsModal, resendEmail } from '../../CreateInvoiceServices';

export const HistoryItemMenu = ({
  historyItem,
  setEmailDetailsAction,
  invoiceNumber,
  emailModalBoxHeader,
  currentInvoiceType,
  billToList,
  setInvoiceHistory,
  invoiceId
}) => {
  const fileViewer = useContext(FileViewerContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const downloadFileAndDoAction = useCallback(
    actionAfterDownload => {
      FinInvoiceApi.getAttachedFile(historyItem.type, historyItem.attachments[0].fileId)
        .then(({ data }) => {
          if (actionAfterDownload === 'DOWNLOAD') {
            downloadFile(URL.createObjectURL(data), historyItem.attachments[0].fileName);
          } else if (actionAfterDownload === 'PRINT') {
            printJS({ printable: URL.createObjectURL(data), type: 'pdf' });
          }
        })
        .then(() => {
          FinInvoiceApi.saveInvoiceLog(invoiceNumber, null, actionAfterDownload, currentInvoiceType).then(() => {
            FinInvoiceApi.getInvoiceHistory(invoiceId).then(({ data }) => {
              setInvoiceHistory(data);
            }, onRequestError);
          });
        })
        .catch(() => {
          NotificationManager.error('Download not ready');
        });
    },
    [currentInvoiceType, historyItem.attachments, historyItem.type, invoiceId, invoiceNumber, setInvoiceHistory]
  );

  const filePreview = attachmentId => {
    openFile(historyItem.type, attachmentId, fileViewer);
  };

  const getMailAndResend = () => {
    FinInvoiceApi.getInvoiceEmail(historyItem.emailId).then(({ data }) => {
      const newData = { ...data };

      ['to', 'cc', 'bcc'].forEach(field => {
        if (newData[field]) {
          newData[field] = newData[field].split(', ');
        } else {
          newData[field] = [];
        }
      });

      resendEmail(
        newData,
        invoiceNumber,
        emailModalBoxHeader,
        currentInvoiceType,
        billToList,
        invoiceId,
        setInvoiceHistory
      );
    }, onRequestError);
  };

  return (
    <>
      <Icon sx={{ marginLeft: '8px' }} onClick={openMenu}>
        more_vert
      </Icon>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        slotProps={{
          paper: {
            sx: {
              width: '143px'
            }
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem
          onClick={() => {
            if (historyItem.type !== 'SEND') {
              downloadFileAndDoAction('DOWNLOAD');
            } else {
              setEmailDetailsAction({ emailId: historyItem.emailId, action: 'DOWNLOAD' });
            }
            handleCloseMenu();
          }}
        >
          Download
        </MenuItem>
        {historyItem.type === 'SEND' && (
          <MenuItem
            onClick={() => {
              openMailDetailsModal(
                historyItem.emailId,
                filePreview,
                invoiceNumber,
                emailModalBoxHeader,
                currentInvoiceType,
                billToList,
                invoiceId,
                setInvoiceHistory
              );
              handleCloseMenu();
            }}
          >
            Open
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            if (historyItem.type !== 'SEND') {
              downloadFileAndDoAction('PRINT');
            } else {
              setEmailDetailsAction({ emailId: historyItem.emailId, action: 'PRINT' });
            }
            handleCloseMenu();
          }}
        >
          Print
        </MenuItem>
        {historyItem.type === 'SEND' && (
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              getMailAndResend();
            }}
          >
            Send
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
