export function getWidthOfText(stringValue, fontParams) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (fontParams) {
    context.font = fontParams;
  } else {
    context.font = rootFontParams();
  }
  const metrics = context.measureText(stringValue);
  canvas.remove();
  return metrics.width;
}

function rootFontParams() {
  const fontFamily = getStylePropertyValue('root', 'font-family');
  const fontSize = getStylePropertyValue('root', 'font-size');
  const fontWeight = getStylePropertyValue('root', 'font-weight');
  return `${fontWeight} ${fontSize} ${fontFamily}`;
}

export function getStylePropertyValue(elementId, prop) {
  const elem = document.getElementById(elementId);
  return window.getComputedStyle(elem).getPropertyValue(prop);
}
