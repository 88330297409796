import React, { useContext, useEffect, useState } from 'react';
import { orderBy } from 'lodash/collection';

import PatientInfoPageApi from '../../../../../../../api/patient/PatientInfoPageApi';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import {
  INSTRUCTION_ADDED,
  INSTRUCTION_DELETED,
  INSTRUCTION_UPDATED,
  PATIENT_LOCKED
} from '../../../../../../../constants/notificationMessages';
import { MANAGE_PATIENT_INSTRUCTIONS } from '../../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../../services/auth';
import { onRequestError } from '../../../../../../../services/handlers';
import { PatientInfoContext } from '../../PatientInfoContext';

import PatientInstructionEditModal from './PatientInstructionEditModal/PatientInstructionEditModal';

export const InstructionContext = React.createContext({ instructions: [] });

export function InstructionContextProvider({ children }) {
  const [instructions, setInstructions] = useState([]);
  const [requestsNumber, setRequestsNumber] = useState(0);
  const { patientInfo } = useContext(PatientInfoContext);

  function loadInstructions() {
    if (patientInfo?.id) {
      showLoading(PatientInfoPageApi.getPatientInstructionsByPatientId(patientInfo.id)).then(
        ({ data: instructions }) => {
          setInstructions(orderBy(instructions, ['instructionCreatedDate'], ['desc']));
        }
      );
    }
  }
  useEffect(loadInstructions, [patientInfo?.id]);

  function saveInstruction(patientInstructionId, instruction) {
    return patientInstructionId
      ? showLoading(PatientInfoPageApi.updatePatientInstruction(patientInfo.id, patientInstructionId, instruction))
          .then(() => {
            NotificationManager.success(INSTRUCTION_UPDATED);
          })
          .catch(err => onRequestError(err))
      : showLoading(PatientInfoPageApi.addPatientInstruction(patientInfo.id, instruction))
          .then(() => {
            NotificationManager.success(INSTRUCTION_ADDED);
          })
          .catch(() => {
            NotificationManager.error(PATIENT_LOCKED);
          });
  }

  function deleteInstructionByFileId(patientInstructionId) {
    return showLoading(PatientInfoPageApi.deletePatientInstruction(patientInfo.id, patientInstructionId))
      .then(() => {
        loadInstructions();
        NotificationManager.success(INSTRUCTION_DELETED);
      })
      .catch(err => onRequestError(err));
  }

  const editInstruction = function(instruction, id) {
    const patientInstructionEditModal = ModalBoxes.open({
      component: (
        <PatientInstructionEditModal
          initialInstruction={instruction}
          onSave={instruction => {
            saveInstruction(id, instruction).then(() => {
              loadInstructions();
              patientInstructionEditModal.close();
            });
          }}
        />
      )
    });
  };

  const canUserAddInstructions = userHasAccessTo(MANAGE_PATIENT_INSTRUCTIONS);

  function showLoading(promise) {
    setRequestsNumber(requestsNumber => ++requestsNumber);
    promise.finally(() => {
      setRequestsNumber(requestsNumber => --requestsNumber);
    });
    return promise;
  }
  return (
    <InstructionContext.Provider
      value={{
        instructions,
        editInstruction,
        loadInstructions,
        deleteInstructionByFileId,
        canUserAddInstructions,
        isLoading: !!requestsNumber
      }}
    >
      {children}
    </InstructionContext.Provider>
  );
}
