import { SBS } from '../../../../constants/accessToPages';

import BudgetSiteSetup from './PublishBudget';

export const publishBudgetRouterConfig = {
  name: 'Publish Budget',
  path: '/publish-budget',
  component: BudgetSiteSetup,
  access: [SBS]
};
