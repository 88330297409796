import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import get from 'lodash/get';

import PatientReimbursementApi from '../../../../../../api/patient/PatientReimbursementApi';
import Section from '../../../../../../common/data-display/Section/Section';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import { STDT_REIMBURSEMENT } from '../../../../../../constants/study/studyTypes';
import { toBill } from '../../../../../../services/financial';
import { onRequestError } from '../../../../../../services/handlers';
import { getPatientAgeFromDob, getPatientPreferredName } from '../../../../../../services/patient';
import { PageInfoHeader } from '../../../../../PageInfoHeader/PageInfoHeader';
import { useCurrentRoute } from '../../../../../root/router';
import { PatientInfoHeaderContext, PatientInfoHeaderProvider } from '../../PatientInfoHeaderContext';
import {
  INVALID_PID,
  RBS_APPROVED,
  RBS_CANCELED,
  RBS_DENIED,
  RBS_DRAFT,
  RBS_PENDING
} from '../EncountersSection/PaymentsSection/reimbursementConstants';
import { TAB_PAYMENTS } from '../EncountersSection/useActiveTab';

import AddOrEditPaymentsItemsModal from './AddOrEditPaymentsItemsModal/AddOrEditPaymentsItemsModal';
import { prepareResponse } from './AddOrEditPaymentsItemsModal/services';
import StatusDropdown from './StatusDropdown/StatusDropdown';
import useGridData from './useGridData';

import './ReimbursementRequest.scss';

function ReimbursementRequestComponent() {
  const navigate = useNavigate();
  const { ssuPatientId, reimbursementId, patientId } = useParams();
  const currentRoute = useCurrentRoute();
  const { patientInfo } = useContext(PatientInfoHeaderContext);
  const [loading, setLoading] = useState(true);
  const [reimbursement, setReimbursement] = useState(null);
  const { canBeApprovedOrDenied, status } = reimbursement || {};
  const [invalidCard, setInvalidCard] = useState(false);
  const [comment, setComment] = useState();
  const commentDisabled = reimbursement?.status === RBS_APPROVED;
  const commentSaveDisabled =
    commentDisabled || (!!reimbursement?.comment ? comment === reimbursement?.comment : !!!comment);
  const { submitAllowed, addItemAllowed, editItemAllowed, approveOrDeniedAllowed, isFromWorklist } = useMemo(
    function() {
      const submitAllowed = status === RBS_DRAFT;
      const approveOrDeniedAllowed = status === RBS_PENDING && canBeApprovedOrDenied;
      const isFromWorklist = currentRoute.key === 'Reimbursements.Reimbursement request';
      const addItemAllowed = ![RBS_APPROVED, RBS_CANCELED].includes(status);
      const editItemAllowed = ![RBS_APPROVED, RBS_CANCELED].includes(status);
      return { submitAllowed, addItemAllowed, editItemAllowed, approveOrDeniedAllowed, isFromWorklist };
    },
    [canBeApprovedOrDenied, currentRoute.key, status]
  );

  const [columns, gridData, setGridData] = useGridData(ssuPatientId, reimbursementId, editItemAllowed);

  const totalAmount = gridData.reduce((a, { amount }) => a + amount, 0);

  const navigateBack = useCallback(
    function() {
      navigate(
        isFromWorklist
          ? { pathname: '../../../' }
          : {
              pathname: '../../',
              search: createSearchParams({
                encounterTab: TAB_PAYMENTS,
                paymentType: STDT_REIMBURSEMENT
              }).toString()
            },
        { relative: 'path' }
      );
    },
    [isFromWorklist, navigate]
  );

  const handleNavigateBack = useCallback(
    function() {
      if (!commentSaveDisabled) {
        ModalBoxes.confirm({
          title: 'Discard Reimbursement Request Comment Changes',
          content: 'Are you sure you want to discard the changes made to request comment?',
          cancelButton: 'No',
          confirmButton: 'Yes'
        }).then(() => navigateBack());
      } else {
        navigateBack();
      }
    },
    [commentSaveDisabled, navigateBack]
  );

  const loadData = useCallback(
    function() {
      setLoading(true);
      PatientReimbursementApi.getReimbursement(ssuPatientId, reimbursementId).then(function({ data }) {
        const { items, ...rest } = data;
        setGridData(items);
        setReimbursement(rest);
        setComment(rest.comment);
        setLoading(false);
      });
    },
    [reimbursementId, setGridData, ssuPatientId]
  );

  useEffect(
    function() {
      loadData();
    },
    [loadData]
  );

  const saveHandler = () => {
    PatientReimbursementApi.addComment(ssuPatientId, reimbursementId, comment).then(() => {
      NotificationManager.success('Comment was added');
      setReimbursement({ ...reimbursement, comment });
    });
  };

  return (
    <>
      <PageInfoHeader
        objectRecordLabel={patientInfo?.name + getPatientPreferredName(patientInfo)}
        pageInfo={
          <PageInfoHeader.CollapsibleList>
            <PageInfoHeader.AdditionalInfo title="Patient ID">{patientInfo?.patientId}</PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo title="Subject ID">
              {patientInfo?.subjectId || 'No Subject ID'}
            </PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo tooltip="Date of Birth">
              {patientInfo?.dob} ({getPatientAgeFromDob(patientInfo)})
            </PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo tooltip="Study">{patientInfo?.studyName}</PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo tooltip="Site">{patientInfo?.siteName}</PageInfoHeader.AdditionalInfo>
          </PageInfoHeader.CollapsibleList>
        }
      />
      <div className="eds-reimbursement-request">
        <Section>
          <Box sx={{ flexGrow: 1, pb: 5 }}>
            <Grid container spacing={3} alignItems="center" justifyContent="space-between">
              <Grid item>
                <Grid container spacing={3} alignItems="center" justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h6">{reimbursement?.encounterName}</Typography>
                  </Grid>
                  <Grid item>
                    <StatusDropdown
                      setInvalidCard={setInvalidCard}
                      totalAmount={totalAmount}
                      status={reimbursement?.status}
                      canBeApprovedOrDenied={reimbursement?.canBeApprovedOrDenied}
                      onChangeSuccess={function(status) {
                        setReimbursement(function(data) {
                          return { ...data, status };
                        });
                        if (status !== RBS_DRAFT) {
                          navigateBack();
                        }
                        NotificationManager.success(statusChangeNotification(status));
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <IconButton
                  onClick={function() {
                    handleNavigateBack();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3} alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5" sx={{ color: '#691E44' }}>
                  Reimbursement Items List
                </Typography>
                {invalidCard && (
                  <Typography variant="body1" color="error">
                    Valid PID not found. A valid PID (Proxy ID) must exist on the profile in order to submit or approve
                    reimbursement request. Please edit the patient profile and add a valid PID before the request is
                    submitted or approved.
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Button
                  disabled={!addItemAllowed}
                  variant="outlined"
                  startIcon={<AddIcon />}
                  size="small"
                  onClick={function() {
                    ModalBoxes.open({
                      component: (
                        <AddOrEditPaymentsItemsModal
                          ssuPatientInfo={getSsuPatientInfo(patientInfo)}
                          reimbursement={reimbursement}
                          reimbursementId={reimbursementId}
                          patientId={patientId}
                          ssuPatientId={ssuPatientId}
                          studyId={patientInfo?.studyId}
                          studyType={patientInfo?.studyType}
                          ssuId={patientInfo?.ssuId}
                        />
                      ),
                      onClose() {
                        loadData();
                      }
                    });
                  }}
                >
                  Item
                </Button>
              </Grid>
            </Grid>
          </Box>
          <DataGridPremium
            loading={loading}
            rowHeight={38}
            rows={gridData}
            columns={columns}
            disableRowSelectionOnClick
            localeText={{ noRowsLabel: 'No results to display' }}
            onRowClick={function({ row }) {
              const { files, ...rest } = row;
              ModalBoxes.open({
                component: (
                  <AddOrEditPaymentsItemsModal
                    ssuPatientInfo={getSsuPatientInfo(patientInfo)}
                    editMode
                    viewMode={!editItemAllowed}
                    reimbursement={reimbursement}
                    reimbursementId={reimbursementId}
                    patientId={patientId}
                    ssuPatientId={ssuPatientId}
                    studyId={patientInfo?.studyId}
                    studyType={patientInfo?.studyType}
                    ssuId={patientInfo?.ssuId}
                    initialValues={prepareResponse({ ...rest })}
                    initialFiles={files}
                    fileUrlGetter={function({ id, itemId }) {
                      return PatientReimbursementApi.getReimbursementFile(
                        ssuPatientId,
                        reimbursementId,
                        itemId,
                        id
                      ).then(function({ data }) {
                        return `data:${data.fileType};base64,${data.content}`;
                      });
                    }}
                  />
                ),
                onClose() {
                  loadData();
                }
              });
            }}
            disableRowGrouping
            disableAggregation
          />
          <div className="reimbursement-request-comment-field">
            <Stack spacing={3} direction="row" justifyContent="space-between">
              <label>Comment</label>
              <Button startIcon={<SaveIcon />} size="small" onClick={saveHandler} disabled={commentSaveDisabled}>
                Save
              </Button>
            </Stack>
            <TextField
              name="COVAL"
              disabled={commentDisabled}
              value={comment}
              onChange={e => {
                setComment(e.target.value);
              }}
              inputProps={{ maxLength: 500 }}
              fullWidth
              multiline
              maxRows={3}
              minRows={2}
              variant="outlined"
            />
          </div>
          <Stack spacing={3} direction="row" sx={{ py: 3 }} justifyContent="space-between" alignItems={'end'}>
            <Typography variant="body1">Total: ${toBill(totalAmount)}</Typography>
            {!isFromWorklist && (
              <Button
                variant="contained"
                disabled={!submitAllowed || invalidCard}
                onClick={function() {
                  changeStatus(RBS_PENDING);
                }}
              >
                Submit Request
              </Button>
            )}
            {isFromWorklist && (
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  disabled={!approveOrDeniedAllowed || totalAmount === 0}
                  color="error"
                  onClick={function() {
                    changeStatus(RBS_DENIED);
                  }}
                >
                  Deny
                </Button>
                <Button
                  color="success"
                  variant="contained"
                  disabled={!approveOrDeniedAllowed || invalidCard || totalAmount === 0}
                  onClick={function() {
                    changeStatus(RBS_APPROVED);
                  }}
                >
                  Approve
                </Button>
              </Stack>
            )}
          </Stack>
        </Section>
      </div>
    </>
  );

  function changeStatus(status) {
    PatientReimbursementApi.updateReimbursementStatus(ssuPatientId, reimbursementId, status, totalAmount)
      .then(() => {
        setInvalidCard(false);
        setReimbursement(prevData => ({ ...prevData, status }));
        navigateBack();
        NotificationManager.success(statusChangeNotification(status));
      })
      .catch(error => {
        const { messageLocation = 'response.data.message', customMessage = null } = error.params || {};
        const message = customMessage || get(error, messageLocation, null);
        if (message === INVALID_PID) {
          setInvalidCard(true);
        } else {
          onRequestError(error, error.params);
        }
      });
  }
}

function getSsuPatientInfo(patientInfo) {
  return {
    patientFullName: patientInfo?.name,
    patientId: patientInfo?.patientId,
    subjectId: patientInfo.subjectId,
    dateOfBirth: patientInfo?.dob,
    studyName: patientInfo.studyName,
    siteName: patientInfo.siteName
  };
}

export function statusChangeNotification(status) {
  if (status === RBS_PENDING) {
    return 'Reimbursement Request was submitted successfully';
  }
  if (status === RBS_CANCELED) {
    return 'Reimbursement Request was canceled';
  }
  if (status === RBS_APPROVED) {
    return 'Reimbursement Request was approved successfully';
  }
  if (status === RBS_DENIED) {
    return 'Request status was updated to denied';
  }
  return 'Reimbursement Request was changed successfully';
}

export default function ReimbursementRequest() {
  return (
    <PatientInfoHeaderProvider>
      <ReimbursementRequestComponent />
    </PatientInfoHeaderProvider>
  );
}
