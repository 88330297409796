import React from 'react';
import * as PropTypes from 'prop-types';

import WhenWasItDoneAndWhoDidIt from '../../WhenWasItDoneAndWhoDidIt/WhenWasItDoneAndWhoDidIt';

import CommentItemGroupNewTableContent from './CommentItemGroupNewTableContent/CommentItemGroupNewTableContent';
import OverviewItemGroupNewTableContent from './OverviewItemGroupNewTableContent/OverviewItemGroupNewTableContent';
import ResultsItemGroupNewTableContent from './ResultsItemGroupNewTableContent/ResultsItemGroupNewTableContent';

import './ItemGroupNewTable.scss';

export default function ItemGroupNewTable({ snapshotAnswers, itemGroupSnapshotState }) {
  const {
    general: { rowSnapshotFormData }
  } = itemGroupSnapshotState;

  return (
    <div className="erc-item-group-element-table-reflection">
      <OverviewItemGroupNewTableContent
        snapshotAnswers={snapshotAnswers}
        itemGroupSnapshotState={itemGroupSnapshotState}
      />
      <ResultsItemGroupNewTableContent itemGroupSnapshotState={itemGroupSnapshotState} />
      <CommentItemGroupNewTableContent itemGroupSnapshotState={itemGroupSnapshotState} />
      <WhenWasItDoneAndWhoDidIt
        whenWasItDone={rowSnapshotFormData?.form?.whenWasItDone}
        whoDidItName={rowSnapshotFormData?.form?.whoDidItName}
      />
    </div>
  );
}

ItemGroupNewTable.propTypes = {
  itemGroupSnapshotState: PropTypes.object
};
