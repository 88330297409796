import axios from 'axios';

const ROOT = 'api/v1/studies';

const CloseRegularEncounterApi = {
  closeRegularEncounter(isNonProtocol, studyId, sitePatientId, sitePatientEncounterId, payload) {
    return axios.put(
      isNonProtocol
        ? `${ROOT}/${studyId}/ssu-patients/${sitePatientId}/encounters/${sitePatientEncounterId}/close/non-protocol`
        : `${ROOT}/${studyId}/ssu-patients/${sitePatientId}/encounters/${sitePatientEncounterId}/close`,
      payload
    );
  },
  getEncounterClosableStatuses(studyId, sitePatientId, patientEncounterId) {
    return axios.get(
      `${ROOT}/${studyId}/ssu-patients/${sitePatientId}/encounters/${patientEncounterId}/closable-status`
    );
  }
};

export default CloseRegularEncounterApi;
