import React, { useCallback, useContext, useMemo } from 'react';

import Button from '../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../common/general/ButtonGroup';
import StudySiteSelector from '../../../shared/FinanceFilters/StudySiteSelector';
import StatusesCheckboxes from '../../../shared/StatusesCheckboxes/StatusesCheckboxes';
import { ACTIVE, INVOICED } from '../../invoceConstants';
import { InvoiceContext } from '../../NewInvoiceContext';

import { StartEndDateSelector } from './StartEndDateSelector/StartEndDateSelector';

import './InvoiceFilter.scss';

export default function InvoiceFilter() {
  const { filterProperty, applyFilters, resetFilters, setFewFilterProperty } = useContext(InvoiceContext);

  const onStudyChange = useCallback(
    study => {
      if (study) {
        const { uniqueIdentifier, studyName, projectCode } = study;
        setFewFilterProperty({ studyId: uniqueIdentifier, studyName: studyName, projectCode: projectCode });
      } else {
        setFewFilterProperty({ studyId: null, studyName: null });
      }
    },
    [setFewFilterProperty]
  );

  const onPcnChange = useCallback(
    (study, sites) => {
      if (study) {
        const { uniqueIdentifier, studyName, projectCode } = study;
        setFewFilterProperty({ studyId: uniqueIdentifier, studyName: studyName, projectCode: projectCode });
        if (sites.length === 1 && !filterProperty.siteId) {
          const { uniqueIdentifier, siteName } = sites[0];
          setFewFilterProperty({ siteId: uniqueIdentifier, siteName: siteName });
        }
      } else {
        setFewFilterProperty({ studyId: null, studyName: null, projectCode: null });
      }
    },
    [filterProperty.siteId, setFewFilterProperty]
  );

  const onSiteChange = useCallback(
    site => {
      if (site) {
        const { uniqueIdentifier, siteName } = site;
        setFewFilterProperty({ siteId: uniqueIdentifier, siteName: siteName });
      } else {
        setFewFilterProperty({ siteId: null, siteName: null });
      }
    },
    [setFewFilterProperty]
  );

  const onChangeStartDate = moment => {
    const startDateMoment = moment.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    setFewFilterProperty({ startDate: startDateMoment });
  };

  const onChangeEndDate = moment => {
    const endDateMoment = moment.set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
    setFewFilterProperty({ endDate: endDateMoment });
  };

  const startDate = useMemo(
    function() {
      return filterProperty?.startDate;
    },
    [filterProperty]
  );
  const endDate = useMemo(
    function() {
      return filterProperty?.endDate;
    },
    [filterProperty]
  );

  return (
    <div className="invoice-filters">
      <div className="general-header-wrapper">
        <StudySiteSelector
          onSiteChange={onSiteChange}
          onStudyChange={onStudyChange}
          onPcnChange={onPcnChange}
          studyId={filterProperty.studyId}
          siteId={filterProperty.siteId}
          pcnFlag={true}
          enableAutoCompleteSite={true}
          setFewFilterProperty={setFewFilterProperty}
        />
        <ButtonGroup>
          <Button size="h56" onClick={applyFilters}>
            Apply
          </Button>
          <Button priority="medium" size="h56" onClick={resetFilters}>
            Reset
          </Button>
        </ButtonGroup>
      </div>
      <div className="invoice-radio-inputs">
        <StartEndDateSelector
          endDate={endDate}
          startDate={startDate}
          onChangeEndDate={onChangeEndDate}
          onChangeStartDate={onChangeStartDate}
        />
        <div className="invoice-radio">
          <StatusesCheckboxes
            label="Event status:"
            statuses={[ACTIVE, INVOICED]}
            chosenStatuses={filterProperty.statuses}
            setFewFilterProperty={setFewFilterProperty}
          />
        </div>
      </div>
    </div>
  );
}
