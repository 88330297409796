const mapRoleToTittle = {
  ROLE_STUDY_LEAD: 'Study Lead',
  ROLE_STUDY_MANAGER: 'Study Manager/Coordinator',
  ROLE_STUDY_ADMINISTRATOR: 'Study Administrator',
  ROLE_CRA: 'CRA/Monitor',
  ROLE_FINANCE: 'Finance User',
  ROLE_ELLIGO_MANAGEMENT: 'Elligo Management',
  ROLE_RECRUITER: 'Recruiter',
  ROLE_FINANCE_ADMINISTRATOR: 'Finance Administrator',
  ROLE_PRINCIPAL_INVESTIGATOR: 'Investigator',
  ROLE_SYSTEM_ADMINISTRATOR: 'System Administrator',
  ROLE_EXTERNAL_AUDITOR: 'External Auditor',
  ROLE_SUB_INVESTIGATOR: 'Sub Investigator',
  ROLE_OPERATION_MANAGER: 'Operations Manager',
  ROLE_FINANCE_ANALYST: 'Finance Analyst',
  ROLE_PATIENT_REPRESENTATIVE: 'Patient Partnership Rep',
  ROLE_PATIENT_LIAISON: 'Patient Partnerships Liaison',
  ROLE_OPERATIONS_ANALYST: 'Operations Analyst',
  ROLE_CLINICAL_QUALITY_COORDINATOR: 'Clinical Quality Coordinator',
  ROLE_SITE_COORDINATOR: 'Site Coordinator',
  ROLE_SITE_FINANCIAL_COORDINATOR: 'Site Financial Coordinator'
};

const mapRoleToShortDescription = {
  ROLE_STUDY_LEAD: 'SL',
  ROLE_STUDY_MANAGER: 'SM',
  ROLE_STUDY_ADMINISTRATOR: 'SA',
  ROLE_CRA: 'CRA',
  ROLE_FINANCE: 'FU',
  ROLE_ELLIGO_MANAGEMENT: 'MGMT',
  ROLE_RECRUITER: 'REC',
  ROLE_FINANCE_ADMINISTRATOR: 'FA',
  ROLE_PRINCIPAL_INVESTIGATOR: 'INV',
  ROLE_SYSTEM_ADMINISTRATOR: 'ADMIN',
  ROLE_EXTERNAL_AUDITOR: 'AUD',
  ROLE_SUB_INVESTIGATOR: 'SUB-I',
  ROLE_OPERATION_MANAGER: 'OM',
  ROLE_PATIENT_REPRESENTATIVE: 'PPR',
  ROLE_FINANCE_ANALYST: 'FINANALYST',
  ROLE_PATIENT_LIAISON: 'PPL',
  ROLE_OPERATIONS_ANALYST: 'OPERANALYST',
  ROLE_CLINICAL_QUALITY_COORDINATOR: 'CQC',
  ROLE_SITE_COORDINATOR: 'SITECOORD',
  ROLE_SITE_FINANCIAL_COORDINATOR: 'SITEFINCOORD'
};

export function getTittleByRole(role) {
  return mapRoleToTittle[role];
}
export function getShortDescriptionByRole(role) {
  return mapRoleToShortDescription[role];
}

export function getRoleDescriptionByUserRoles(roles) {
  return roles.map(getTittleByRole).join(', ');
}

export function resolvePersonnelRoles(personnel) {
  const roles = personnel.roles.filter(r => r.enabled === 1).map(r => r.title);
  if (roles.length > 0) {
    return {
      roles: `${roles.join(', ')}`,
      disabled: false
    };
  } else {
    return {
      roles: `${personnel.roles.map(role => role.title).join(', ')}`,
      disabled: true
    };
  }
}
