import { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { useFormikContext } from 'formik';
import { debounce } from 'lodash/function';
import { assign, has } from 'lodash/object';

import { PatientInfoApi, StudyApi } from '../../../../../../../../../api';
import AddressValidator from '../AddressValidator';

import { reducer } from './reducer';

const StateContext = createContext(null);
const DispatchContext = createContext(null);

export function MileageAddressesProvider({ children, patientId, studyId, ssuId, initialMileageAddresses }) {
  const { setFieldValue } = useFormikContext();
  const [state, dispatch] = useReducer(reducer, {
    syncing: false,
    ssuPatientDetails: {
      costPerMileage: null,
      patientAddress: null,
      siteAddress: null
    },
    mileageAddresses: initialMileageAddresses
  });

  const syncWithFormik = useMemo(
    function() {
      return debounce(function(mileageAddresses, costPerMileage) {
        setFieldValue('mileageAddresses', mileageAddresses).finally(function() {
          setFieldValue('amount', calculateAmount(mileageAddresses.distance, costPerMileage)).finally(function() {
            dispatch({ type: 'SET_SYNCING', payload: false });
          });
        });
      }, 500);
    },
    [setFieldValue]
  );

  useEffect(
    function() {
      if (!patientId || !studyId || !ssuId) {
        return;
      }
      getSsuPatientDetails(patientId, studyId, ssuId).then(
        function(payload) {
          dispatch({ type: 'SET_SSU_PATIENT_DETAILS', payload });
          setTimeout(function() {
            AddressValidator.revalidate('startAddress');
            AddressValidator.revalidate('endAddress');
          }, 100);
        },
        () => {}
      );
    },
    [patientId, ssuId, studyId]
  );

  useEffect(
    function() {
      dispatch({ type: 'SET_SYNCING', payload: true });
      syncWithFormik(state.mileageAddresses, state.ssuPatientDetails.costPerMileage);
    },
    [syncWithFormik, state.mileageAddresses, state.ssuPatientDetails.costPerMileage]
  );

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}

export function withMileageAddressesProvider(Component) {
  return function WithMileageAddressesProvider(props) {
    const { patientId, studyId, ssuId, initialMileageAddresses } = props;
    return (
      <MileageAddressesProvider
        patientId={patientId}
        ssuId={ssuId}
        studyId={studyId}
        initialMileageAddresses={initialMileageAddresses}
      >
        <Component {...props} />
      </MileageAddressesProvider>
    );
  };
}

export function useMileageAddressesState() {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error('useMileageAddressesState must be used within a MileageAddressesContext');
  }
  return context;
}

export function useMileageAddressesActions() {
  const dispatch = useContext(DispatchContext);

  if (dispatch === undefined) {
    throw new Error('useMileageAddressesDispatch must be used within a MileageAddressesContext');
  }

  const setAddressField = useCallback(
    function(path, value) {
      dispatch({ type: 'SET_ADDRESS_FIELD', payload: { path, value } });
    },
    [dispatch]
  );

  const setCountryId = useCallback(
    function(path, value) {
      dispatch({ type: 'SET_COUNTRY_ID', payload: { path, value } });
    },
    [dispatch]
  );

  const setCity = useCallback(
    function(path, value) {
      dispatch({ type: 'SET_CITY', payload: { path, value } });
    },
    [dispatch]
  );

  const setAddressType = useCallback(
    function(path, value) {
      dispatch({ type: 'SET_ADDRESS_TYPE', payload: { path, value } });
    },
    [dispatch]
  );

  const setValid = useCallback(
    function(path, value) {
      const obj = {};
      if (has(value, 'valid')) {
        assign(obj, value);
      } else {
        obj.valid = value;
      }
      dispatch({ type: 'SET_VALID', payload: { path, value: obj } });
    },
    [dispatch]
  );

  return useMemo(
    function() {
      return {
        dispatch,
        setAddressField,
        setCountryId,
        setCity,
        setAddressType,
        setValid
      };
    },
    [dispatch, setAddressField, setCountryId, setCity, setAddressType, setValid]
  );
}

function getSsuPatientDetails(patientId, studyId, ssuId) {
  return new Promise(function(resolve, reject) {
    Promise.all([
      PatientInfoApi.getPatientInfo(patientId).then(
        function({ data }) {
          return data?.address;
        },
        () => {}
      ),
      StudyApi.getSiteDetails(ssuId).then(
        function({ data }) {
          return data?.response;
        },
        () => {}
      ),
      StudyApi.populateStudyDetailsByIdentifier(studyId).then(
        function({ data }) {
          return data?.response?.costPerMileage;
        },
        () => {}
      )
    ]).then(function([patientAddress, siteDetails, costPerMileage]) {
      resolve(extractSsuPatientDetails(patientAddress, siteDetails, costPerMileage));
    }, reject);
  });
}

function extractSsuPatientDetails(patientAddress, siteDetails, costPerMileage) {
  const ssuPatientDetails = {
    costPerMileage: null,
    patientAddress: null,
    siteAddress: null
  };

  if (patientAddress) {
    ssuPatientDetails.patientAddress = {
      countryId: patientAddress.country,
      regionId: patientAddress.state,
      city: patientAddress.city || '',
      address1: patientAddress.addressLine1 || '',
      address2: patientAddress.addressLine2 || '',
      zipCode: patientAddress.zipCode
    };
  }

  if (siteDetails) {
    ssuPatientDetails.siteAddress = {
      countryId: 'US',
      regionId: siteDetails.state,
      city: siteDetails.city || '',
      address1: siteDetails.address1 || '',
      address2: siteDetails.address2 || '',
      zipCode: siteDetails.zipCode
    };
  }

  if (costPerMileage) {
    ssuPatientDetails.costPerMileage = costPerMileage;
  }

  return ssuPatientDetails;
}

function calculateAmount(distance, costPerMileage) {
  if (!distance || !costPerMileage) {
    return '';
  }
  return (distance * costPerMileage).toFixed(0);
}
