import React, { useEffect, useState } from 'react';
import { findIndex } from 'lodash/array';
import { some } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

import ModalBoxes from '../../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../../../common/general/ButtonGroup';
import Icon from '../../../../../../../../../common/general/Icon';
import { ToggleSelect } from '../../../../../../../../../common/inputs/ToggleSelect/ToggleSelect';
import NotificationManager from '../../../../../../../../../common/notifications/NotificationManager';
import { TOGGLE_CONFIGURATION_ALREADY_ADDED } from '../../../../../../../../../constants/notificationMessages';

import ConditionalLogicConfiguration from './ConditionalLogicConfiguration';

import './EditCustomFieldLogicModal.scss';

const EditCustomFieldLogicModal = ({
  itemType,
  itemName,
  addConditionalLogicForItem,
  general,
  itemIndex,
  selectedToggleFields,
  modalBox,
  initialConfiguration,
  initialHideAllConfiguration,
  currentFieldsToggleValue,
  itemsWishHideAllConditionalLogic
}) => {
  const [hideAllFieldsBelow, setHideAllFieldsBelow] = useState(false);
  const [hideAllFieldsBelowOnAnswer, setHideAllFieldsBelowOnAnswer] = useState(null);
  const [finalConfiguration, setFinalConfiguration] = useState([]);

  useEffect(() => {
    if (!isEmpty(initialConfiguration)) {
      setFinalConfiguration(initialConfiguration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addNewConfiguration = configuration => {
    if (some(finalConfiguration, ['conditionFieldName', configuration.conditionFieldName])) {
      NotificationManager.error(TOGGLE_CONFIGURATION_ALREADY_ADDED);
    } else {
      setFinalConfiguration([...finalConfiguration, configuration]);
    }
  };

  const deleteConditionalLogic = itemIndex => {
    const updatedFinalConfiguration = finalConfiguration.filter((element, index) => index !== itemIndex);
    setFinalConfiguration(updatedFinalConfiguration);
  };

  return (
    <>
      <ModalBoxes.Header>Edit Custom Field Logic</ModalBoxes.Header>
      <ModalBoxes.Body>
        <div className="custom-logic-question custom-item-name">{itemName}</div>
        {((itemType === 'radio' && itemIndex === 0) || initialHideAllConfiguration?.hideAllFieldsBelow) && (
          <>
            <div className="custom-logic-question">Hide full row based on selected Value?</div>
            <ToggleSelect
              listOfItem={[
                { name: 'No', value: false },
                { name: 'Yes', value: true }
              ]}
              initialValue={initialHideAllConfiguration?.hideAllFieldsBelow || false}
              onChange={e => {
                setHideAllFieldsBelow(e);
              }}
            />
            {hideAllFieldsBelow && (
              <>
                <div className="custom-logic-question">When to hide:</div>
                <ToggleSelect
                  listOfItem={currentFieldsToggleValue}
                  initialValue={initialHideAllConfiguration?.hideAllFieldsBelowOnAnswer}
                  onChange={e => {
                    setHideAllFieldsBelowOnAnswer(e);
                  }}
                />
              </>
            )}
          </>
        )}
        <ConditionalLogicConfiguration
          selectedToggleFields={selectedToggleFields}
          addNewConfiguration={addNewConfiguration}
        />
        {!isEmpty(finalConfiguration) && (
          <div className="conditional-logic-list">
            <div className="configuration-item">
              <div className="configuration-item-value">
                <h5>Hide this item when toggle</h5>
              </div>
              <div className="configuration-item-value">
                <h5>Has the selected value</h5>
              </div>
            </div>
            {finalConfiguration.map((configuration, index) => {
              const isDeleteDisabled =
                findIndex(
                  itemsWishHideAllConditionalLogic,
                  field =>
                    field.name === configuration.conditionFieldName &&
                    field.hideAllConfiguration.hideAllFieldsBelowOnAnswer === configuration.conditionFieldAnswerName
                ) >= 0;
              return (
                <div className="configuration-item" key={configuration.conditionFieldName + index}>
                  <div className="configuration-item-value">{configuration.conditionFieldName}</div>
                  <div className="configuration-item-value">{configuration.conditionFieldAnswerName}</div>
                  <Button
                    disabled={isDeleteDisabled}
                    priority="low"
                    size="h40"
                    onClick={() => deleteConditionalLogic(index)}
                  >
                    <Icon>close</Icon>
                  </Button>
                </div>
              );
            })}
          </div>
        )}
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority={'medium'} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onSave}>Save</Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );

  function onClose() {
    modalBox.close();
  }
  function onSave() {
    const finalHideAllConfiguration =
      hideAllFieldsBelow && hideAllFieldsBelowOnAnswer ? { hideAllFieldsBelow, hideAllFieldsBelowOnAnswer } : null;
    addConditionalLogicForItem(itemIndex, general, finalConfiguration, finalHideAllConfiguration);
    modalBox.close();
  }
};

EditCustomFieldLogicModal.className = 'edit-conditional-logic-modal';
EditCustomFieldLogicModal.size = 'w950';

export default EditCustomFieldLogicModal;
