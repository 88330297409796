import React, { useEffect, useState } from 'react';

import { PatientSourceApi } from '../../../../../../api';
import Select from '../../../../../../common/data-entry/Select';
import { reshapeAndOrderSourceData } from '../patientInfoService';

export function SourceSelect({ getValidationMessage, setSource, source }) {
  const [sources, setSources] = useState([]);
  useEffect(function() {
    PatientSourceApi.getAllByTypeSource().then(({ data }) => {
      setSources(reshapeAndOrderSourceData(data));
    });
  }, []);

  return (
    <Select
      label="Source"
      clearSearchOnSelection={false}
      searchable
      clearable={false}
      closeOnSelectedOptionClick={false}
      deselectOnSelectedOptionClick={false}
      optionLabelKey={'source'}
      onChange={setSource}
      dataSource={sources}
      valid={!getValidationMessage('source')}
      validationMessage={getValidationMessage('source')}
      value={source || null}
      required
      className="source-select"
    />
  );
}
