import React from 'react';
import moment from 'moment';
import * as PropTypes from 'prop-types';

import { SiteBillApi } from '../../../../../api';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';
import { onRequestError } from '../../../../../services/handlers';
import { Amount } from '../../shared/amount-view/Amount/Amount';
import { FinanceTable } from '../../shared/FinanceTable/FinanceTable';

import './CreateSiteBillDialog.scss';

export default function CreateSiteBillDialog(props) {
  const { checkedForBill, modalBox, applyFilter } = props;

  const onSave = () => {
    const billEvents = checkedForBill.map(({ id }) => id);
    SiteBillApi.createSiteBill(billEvents).then(() => {
      applyFilter();
      modalBox.close();
    }, onRequestError);
  };

  return (
    <>
      <ModalBoxes.Header>Following budget events will be included on the Site Bill</ModalBoxes.Header>
      <ModalBoxes.Body>
        <FinanceTable
          data={checkedForBill || []}
          columns={columns}
          minRows={1}
          multiSort
          showPagination
          className="eds-create-site-bill-table"
          nextText=">>"
          previousText="<<"
          noDataText="No Record Found"
          defaultPageSize={15}
          pageSizeOptions={[5, 10, 15]}
        />
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            CANCEL
          </Button>
          <Button onClick={onSave}>SAVE</Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
}

CreateSiteBillDialog.className = 'eds-create-site-bill-dialog';

CreateSiteBillDialog.propTypes = { checkedForBill: PropTypes.array, applyFilter: PropTypes.func };

const cellWithLongText = {
  Cell: ({ value }) => {
    return (
      <span className="ft-long-text" title={value}>
        {value}
      </span>
    );
  }
};

const columns = [
  {
    Header: 'Study',
    accessor: 'studyName',
    ...cellWithLongText
  },
  {
    Header: 'Site',
    accessor: 'siteName',
    ...cellWithLongText
  },
  {
    Header: 'Encounter',
    accessor: 'encounterName',
    ...cellWithLongText
  },
  {
    Header: 'Event',
    accessor: 'eventName',
    ...cellWithLongText
  },
  {
    id: 'revenueDate',
    Header: 'Revenue Date',
    accessor: ({ revenueDate }) => (revenueDate ? moment(revenueDate) : moment(0)),
    Cell: ({ value, original: { revenueDate } }) => (revenueDate ? value.format('DD/MMM/YYYY') : revenueDate)
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ value }) => {
      return <Amount coinsAmount={value} className="ft-numbers" />;
    }
  }
];
