import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { IconButton, Typography } from '@mui/material';
import cx from 'classnames';
import { isEmpty } from 'lodash/lang';

import { useEuiFileUpload } from '../EuiFileUploadProvider';

import EuiFileUploadListIcon from './EuiFileUploadListIcon';

import './EuiFileUploadList.scss';

export default function EuiFileUploadList({ viewOnly = false }) {
  const { files, selectedFileId, removeFile, setSelectedFileId } = useEuiFileUpload();
  if (isEmpty(files)) return null;
  return (
    <div className="eui-file-upload-list">
      {files.map(function(file) {
        return (
          <div
            className={cx('eui-file-upload-list-file', {
              'eui-file-upload-list-file-active': selectedFileId === file.id
            })}
            title={file.fileName}
            key={file.id}
            onClick={function() {
              setSelectedFileId(file.id);
            }}
          >
            <EuiFileUploadListIcon file={file} />
            <div className="eui-file-upload-list-file-container">
              <Typography className="eui-file-upload-list-file-name" variant="overline" display="block">
                {file.fileName}
              </Typography>
            </div>
            {!viewOnly && (
              <IconButton
                aria-label="delete"
                size="small"
                title="Delete"
                onClick={function(e) {
                  e.preventDefault();
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  removeFile(file.id);
                }}
              >
                <RemoveCircleIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        );
      })}
    </div>
  );
}
