export const scPurple100 = '#450C29';
export const scPurple80 = '#691e44';
export const scPurple75 = '#7A415C';
export const scPurple50 = '#AF728F';
export const scPurple30 = '#E2C4D4';
export const scPurple20 = '#f8e2ee';
export const scGreen100 = '#27AE60';
export const scGreen80 = '#6FCF97';
export const scGreen10 = '#EDFFF4';
export const scYellow100 = '#FFC738';
export const scYellow10 = '#FFFBDC';
export const scDarkBlue100 = '#2465C6';
export const scBlue100 = '#2D9CDB';
export const scBlue50 = '#8CC7F2';
export const scBlue30 = '#d2edfc';
export const scBlue10 = '#f2f7ff';
export const scElligoRed = '#EE303C';
export const scRed100 = '#F34848';
export const scRed70 = '#EC6363';
export const scRed30 = '#FF9A9A';
export const scRed10 = '#FFF1EF';
export const scBlack = '#000000';
export const scGray85 = '#323232';
export const scGray70 = '#575757';
export const scGray60 = '#6e6e6e';
export const scGray50 = '#828282';
export const scGray30 = '#b7b7b7';
export const scGray15 = '#dbdbdb';
export const scGray10 = '#e7e7e7';
export const scGray5 = '#f3f3f3';
export const scGray2 = '#FAFAFA';
export const scWhite = '#FFFFFF';
export const scAero80 = '#B3BBCA';
export const scAero40 = '#DFE5EF';
