import React, { useContext, useMemo } from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash/lang';

import Radio from '../../../../../../../../../common/data-entry/InputSelectors/Radio';
import { Amount } from '../../../../../../shared/amount-view/Amount/Amount';
import { FinanceTable } from '../../../../../../shared/FinanceTable/FinanceTable';
import { eventTypeLabel } from '../../../../addLedgerConstants';
import { AddLedgerContext } from '../../../../AddLedgerContext';

import './MissedBudgetTable.scss';

export function MissedBudgetTable() {
  const { filteredFinBudgetRows, setFinBudgetRowId, finBudgetRowId } = useContext(AddLedgerContext);
  const disabledHeader = isEmpty(filteredFinBudgetRows);
  // eslint-disable-next-line  react-hooks/exhaustive-deps
  const columns = useMemo(getColumns, [setFinBudgetRowId, finBudgetRowId]);

  return (
    !!filteredFinBudgetRows && (
      <FinanceTable
        scrollInAnyPart
        data={filteredFinBudgetRows}
        columns={columns}
        minRows={1}
        multiSort
        className={cx('missed-budget-table', {
          'mbt-disabled': disabledHeader
        })}
        noDataText="No Budget Events Found"
        defaultPageSize={25}
        resizable={false}
        getTrProps={getTrProps}
      />
    )
  );

  function getTrProps(state, rowInfo) {
    return {
      onClick: () => {
        const id = rowInfo.original.id;
        setFinBudgetRowId(id);
      }
    };
  }

  function getColumns() {
    return [
      {
        Header: '',
        accessor: 'id',
        maxWidth: 55,
        className: 'mbt-id-cell',
        sortable: false,
        Cell({ value }) {
          return <Radio checked={value === finBudgetRowId} value={value} />;
        }
      },
      {
        Header: 'Event',
        accessor: 'triggerName',
        Cell({ value }) {
          return (
            <p className="mbt-value" title={value}>
              {value}
            </p>
          );
        }
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell({ value }) {
          return <p className="mbt-value">{eventTypeLabel[value]}</p>;
        }
      },
      {
        Header: 'Name',
        Cell({ original }) {
          const value = [
            'ENCOUNTER',
            'UNEXPECTED_ENCOUNTER',

            'ENCOUNTER_ITEM_GROUP',
            'UNEXPECTED_ITEM_GROUP',

            'ITEM_GROUP_GENERAL'
          ].includes(original.type)
            ? original.name + ' (' + original.countFrom + '-' + (original.countTo || '∞') + ')'
            : original.name;
          return (
            <p className="mbt-value" title={value}>
              {value}
            </p>
          );
        }
      },
      {
        Header: 'Client',
        accessor: 'clientAmount',
        headerClassName: 'mbt-end-leveling',
        className: 'mbt-end-leveling',
        maxWidth: 100,
        Cell({ value }) {
          return <Amount highlightFractionalValue coinsAmount={value} showDollarSign />;
        }
      },
      {
        Header: 'Site',
        accessor: 'siteAmount',
        headerClassName: 'mbt-end-leveling',
        className: 'mbt-end-leveling',
        maxWidth: 100,
        Cell({ value }) {
          return <Amount highlightFractionalValue coinsAmount={value} showDollarSign />;
        }
      },
      {
        Header: 'Patient',
        accessor: 'patientAmount',
        headerClassName: 'mbt-end-leveling',
        className: 'mbt-end-leveling',
        maxWidth: 100,
        Cell({ value }) {
          return <Amount highlightFractionalValue coinsAmount={value} showDollarSign />;
        }
      },
      {
        Header: 'Vendor',
        accessor: 'vendorAmount',
        headerClassName: 'mbt-end-leveling',
        className: 'mbt-end-leveling',
        maxWidth: 100,
        Cell({ value }) {
          return <Amount highlightFractionalValue coinsAmount={value} showDollarSign />;
        }
      },
      {
        Header: 'Net',
        accessor: 'elligoAmount',
        headerClassName: 'mbt-end-leveling',
        className: 'mbt-end-leveling',
        maxWidth: 100,
        Cell({ value }) {
          return <Amount highlightFractionalValue coinsAmount={value} showDollarSign />;
        }
      }
    ];
  }
}
