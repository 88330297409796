import axios from 'axios';

const ROOT = 'api/v1/smworklist';

const SMWorklistApi = {
  getDataForSMWorklist(req, withLoader) {
    return axios.post(ROOT, req, { hideLoader: withLoader });
  },
  getWorkListSignedByPerGroupValuesForTable(patientEncounterIds, request) {
    return axios.post(`${ROOT}/table/signers-per-groups`, patientEncounterIds, {
      hideLoader: true,
      cancelToken: request.token
    });
  },
  getEncounterNamesBySsuIds(ssuIds, statuses) {
    return axios.post(
      `${ROOT}/encounter`,
      {
        ssuIds,
        statuses
      },
      { hideLoader: true }
    );
  },
  getCountOfReviewsBySsuIds(ssuIds) {
    return axios.post(`${ROOT}/count`, ssuIds, { hideLoader: false });
  },
  countAdverseEventsBySMStatus(ssuIds) {
    return axios.post(`api/v1/adverse-events/count-sm`, ssuIds, { hideLoader: false });
  },
  getAdverseEventsForSmWorkListByRequest(request, withLoader) {
    return axios.post(`api/v1/adverse-events/events-sm`, request, { hideLoader: withLoader });
  },
  getAdverseEventsSignedByPerGroupValuesForTable(rowIdToTemplateId, request) {
    return axios.post(`api/v1/adverse-events/signers-per-groups`, rowIdToTemplateId, {
      hideLoader: true,
      cancelToken: request.token
    });
  },
  getEncounterNamesAndStudyNamesBySsuIds(ssuIds, statuses) {
    return axios.post(
      `${ROOT}/encounter-with-study`,
      {
        ssuIds,
        statuses
      },
      { hideLoader: false }
    );
  },
  getSMWorkListAdverseEventSignedByFilterSignersAndSmReviewers(ssuIds, smStatuses) {
    return axios.post(
      `api/v1/adverse-events/filters/signers`,
      {
        ssuIds,
        smStatuses,
        piStatuses: []
      },
      { hideLoader: false }
    );
  },
  getSMWorkListSignedByFilterSignersAndSmReviewers(ssuIds, statuses) {
    return axios.post(
      `${ROOT}/signers`,
      {
        ssuIds,
        statuses
      },
      { hideLoader: true }
    );
  }
};

export default SMWorklistApi;
