export const DATA_SAVED = 'Data saved successfully';
export const STATUS_CHANGED = 'Status changed successfully';
export const STUDY_BUDGET_ADDED = 'Study Budget item added successfully';
export const STUDY_BUDGET_UPDATED = 'Study Budget item updated successfully';
export const STUDY_BUDGET_ADD_FAILED = 'Study Budget failed to add';
export const STUDY_BUDGET_COPY_FAILED = 'Study Budget failed to copy';
export const STUDY_BUDGET_EXPORT_FAILED = 'Study Budget failed to export';
export const INVOICE_NOTE_AND_EMAILS_SAVED = 'Invoice Note / Recipient Email(s) successfully saved';
export const INVOICE_NOTE_AND_EMAILS_SAVE_FAILED =
  'Invoice Note / Recipient Email(s) could not be saved, please try again';
export const BUDGET_SITE_MAPPING_ADDED = 'Budget Site mapping added successfully';
export const LEDGER_EVENTS_CREATED = 'Ledger events created successfully';
export const PATIENT_STIPEND_SENT_TO_VENDOR = 'The patient stipend will be sent to the vendor for processing';
export const DOWNLOAD_MISSING = 'Download missing';
export const DOWNLOAD_SUCCESS = 'Download succeeded';
export const DOWNLOAD_FAILED = 'Download failed';
export const DOWNLOAD_HAS_NOT_YET_CLEARED_VIRUS_SCAN = 'Download has not yet cleared virus scan';
export const VIRUS_DETECTED_IN_DOWNLOAD = 'Virus detected in download';
export const REVENUE_DATE_ADDED = 'Revenue Date added successfully';
export const LOG_CHECKS_ADDED = 'Log checks added successfully';
export const APPOINTMENT_CANCELED = 'Appointment canceled successfully';
export const APPOINTMENT_FAILED_TO_CANCEL = 'Failed to cancel appointment';
export const INSTRUCTION_ADDED = 'Instruction added successfully';
export const INSTRUCTION_UPDATED = 'Instruction updated successfully';
export const INSTRUCTION_DELETED = 'Instruction deleted successfully';
export const ADDED = 'Added successfully';
export const SIGNED = 'Signed successfully';
export const FILL_REQUIRED = 'Please fill the required fields';
export const SELECT_REQUIRED = 'Please select the required fields';
export const SELECT_REQUIRED_STIPEND = 'Please select the following fields for stipend item group: ';
export const PATIENT_STIPEND = 'Failed to load patient stipends: ';
export const ITEM_REMOVED = 'Item removed successfully';
export const SOMETHING_WENT_WRONG = 'Something went wrong';
export const MEDICAL_RECORD_NUMBER_UPDATED = 'Medical record number updated successfully';
export const SUBJECT_ID_UPDATED = 'Subject ID updated successfully';
export const PROGRESS_NOTE_ADDED = 'The note was added.';
export const PROGRESS_NOTE_UPDATED = 'The note was updated.';
export const PATIENT_STATUS_CHANGED = 'Patient status changed successfully';
export const PATIENT_STATUS_UPDATED = 'Patient status updated successfully';
export const BULK_PATIENT_STATUS_UPDATED = 'Bulk patient statuses updated successfully';
export const PRIMARY_CONTACT_UPDATED = 'Primary contact updated successfully';
export const VISIT_DATE_CONFIRMED = 'Visit date confirmed successfully';
export const COMMENT_SAVED = 'Comment saved successfully';
export const COMMENT_NOT_SAVED = 'Comment failed to save';
export const EDC_VISIT_DATE_SAVED = 'EDC Visit Date saved successfully';
export const CLIENT_ADDED = 'Client added successfully';
export const CLIENT_UPDATED = 'Client updated successfully';
export const CLIENT_DISABLED = 'Client disabled successfully';
export const VENDOR_CREATED = 'Vendor created successfully';
export const VENDOR_UPDATED = 'Vendor updated successfully';
export const VENDOR_DISABLED = 'Vendor disabled successfully';
export const TEMPLATE_DELETED = 'The template deleted successfully';
export const TEMPLATE_NOT_DELETED = 'The template failed to delete';
export const SOURCE_DATA_SETUP_SAVED = 'Source Data Setup saved successfully';
export const SETUP_SAVED = 'Setup saved successfully';
export const SOURCE_CHANGED = 'Patient Source updated successfully';
export const ITEM_GROUP_COPIED = 'Item group copied successfully';
export const TEMPLATE_CREATED = 'A new template created successfully';
export const NO_PRE_SCREEN_PROTOCOL = 'No any pre-screen protocols for all studies';
export const ENCOUNTER_MISSING_ELEMENT = 'Every encounter should contain at least one element';
export const INACTIVITY_LOGOUT = 'You will be logged out due to inactivity in 2 minutes';
export const REPORTS_TEMPORARILY_UNAVAILABLE = 'Error: Reports might be temporarily unavailable';
export const AT_LEAST_ONE_VALUE_FOR_FILTER = 'You must select at least 1 value for each filter';
export const EXPORT_FAILED = 'Export failed';
export const DUPLICATE_LABEL_NOT_ALLOWED = 'Duplicate label values not allowed';
export const DUPLICATE_CUSTOM_OPTION_VALUES_NOT_ALLOWED = 'Duplicate custom option values not allowed';
export const DUPLICATE_PREDEFINED_COLUMN_VALUES_NOT_ALLOWED = 'Duplicate predefined column values not allowed';
export const WRONG_URL = 'Wrong URL';
export const INVALID_DATA_LOADED = 'Invalid data loaded';
export const INVALID_FORM = 'Invalid Form';
export const END_DATE_NOT_FILLED = 'Please fill the End Date field';
export const END_DATE_GREATER_THAN_START_DATE = 'End Date should be greater than Start Date';
export const SEQUENCE_BLANK_ERROR = 'Sequence can not be blank';
export const UNIQUE_SEQUENCE_ERROR = 'Please enter unique sequence';
export const FINAL_OPTION_LIST_REQUIRED = 'Final Option List is required';
export const FIELD_REQUIRED_FOR_TABLE_TYPE_ERROR = 'Please enter required field for table type';
export const UNAUTHORIZED_ACCESS = 'Unauthorized Access';
export const STATUS_CONFIGURATION_SAVED = 'Status configuration saved successfully';
export const CURRENT_ROLE = 'Current Role:';
export const UNABLE_TO_LOCK_PERIOD = 'Unable to lock period';
export const PERIOD_LOCKED = 'period is locked';
export const PATIENT_LOCKED = 'SitePatient is locked in current SSU';
export const PROTOCOL_COPIED_TO_STUDY = 'Protocol successfully copied to study';
export const WAS_DELETED_SUCCESSFULLY = 'was deleted successfully';
export const STUDY_SITE_FETCH_ERROR = 'Error fetching study sites';
export const PRIMARY_CONTACT_FILL_NOT_CORRECTLY = 'Please fill in the field Primary Contact correctly';
export const CHECKBOX_REVIEW =
  'Please mark the checkbox indicating you reviewed and confirmed the file is an exact copy';
export const STATUS_CONFIG_CHANGES_CANCELED = 'Status configuration changes canceled';
export const CONTACT_NUMBER_LENGTH_CHECK = 'Contact number should not be less than 10 digits';
export const AT_LEAST_ONE_ITEM_FORM_SETUP = 'Please select at least one item to complete the form setup';
export const AT_LEAST_ONE_ITEM_TYPE = 'Please select at least one item for Type ';
export const TOGGLE_CONFIGURATION_ALREADY_ADDED = 'Configuration based on this toggle is already added';
export const ENTERED_SAME_FIELD_NAME = 'You Entered Same Field Name';
export const REASON_FOR_CHANGE_NEEDED = 'Please add a reason for each change';
export const UNABLE_TO_SIGN_IN = 'There was an error in the sign in process';
export const CHECKBOX_EXACT_COPY =
  'Please mark the checkbox indicating you reviewed and confirmed the file is an exact copy';
export const FILE_NOT_SUPPORTED = 'file(s) not supported';
export const EVENTS_DISPLAY = 'All calendar events are now displaying in';
export const TABLE_LABEL_HEADER_ERROR = 'Table Label Header is required for saving';
export const ITEM_GROUP_EXIST = 'There is already an Item Group with this name.';
export const BUDGET_SITE_MAPPING_NOT_ADDED = 'Unable to add budget site mapping';
export const COMMENT_FIELD_IS_NOT_AVAILABLE = 'Comment field is already exist by default';
export const ITEM_ALREADY_EXISTS = 'Item with the same name already exists';
export const USERNAME_AND_PASSWORD_REQUIRED = 'Username and Password are required';
export const KEY_IS_REQUIRED = 'Key is required';
export const AUTHENTICATION_URL_IS_NOT_CORRECT = 'Authentication URL is not correct';
export const PLATFORM_NAME_IS_REQUIRED = 'Platform name is required';
export const ENDPOINT_URL_IS_REQUIRED = 'Endpoint URL is required';
export const ENDPOINT_URL_IS_NOT_CORRECT = 'Endpoint URL is not correct';
export const HTTP_TYPE_IS_REQUIRED = 'HTTP Type is required';
export const ENDPOINT_IN_USE = "You cannot delete this endpoint, it's in use";
export const SOME_OF_FIELDS_ARE_EMPTY = 'Some of fields are empty';
export const REQUIRED_ATTRIBUTE_MISSING = 'At least one required attribute for payload must be selected';
export const GROUP_SIGN_EXCEPTION = 'Unable to sign adverse event logs group';
export const INFORMED_CONSENT_ITEM_SAVED = 'Informed consent item successfully saved';
export const INFORMED_CONSENT_ITEM_SAVE_FAILED = "Informed consent item wasn't saved; something went wrong";
export const INFORMED_CONSENT_FORM_SAVED = 'Informed consent form successfully saved';
export const INFORMED_CONSENT_FORM_SAVE_FAILED = "Informed consent form wasn't saved, something went wrong";
export const STUDY_TYPES_UPDATED = 'Study types successfully updated';
export const STUDY_COST_PER_MILEAGE_UPDATED = 'Study cost per mileage successfully updated';
export const PAYMENTS_RECONCILED = ' payments were successfully reconciled';
export const PROTOCOL_SETUP_IS_INCOMPLETE = 'The Protocol Setup is incomplete.';
export const PATIENT_REIMBURSEMENT_EXISTS = 'Only one Patient Reimbursement budget event can be added to the budget.';
export const INVOICE_MAX_ALLOWABLE_RECORDS_ERROR =
  'Max allowable # of records exceeded, please use parameters to reduce the amount of records retrieved';
