import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { EventApi, StudySiteApi } from '../../../api';
import { mapStudySite } from '../../../services/studySiteMapper';
import { useCurrentRoute } from '../../root/router';

import { NON_PATIENT_EVENT_TYPE_DROPDOWN_LABEL_MAP } from './NonPatientAppointmentEdit/NonPatientEventTypeDropdownLabelProvider';
import { weekOfDate } from './CalendarUtils';
export const CalendarPageContext = React.createContext(null);
export const CALENDAR_SELECTED_EVENT = 'CALENDAR_SELECTED_EVENT';

export function CalendarPageContextProvider({ children, hideLoader }) {
  const currentRoute = useCurrentRoute();
  const sitePatientId = currentRoute.params?.sitePatientId;
  const encounterId = currentRoute.params?.encounterId;
  const [nonPatientEventTypeByCodeMap, setNonPatientEventTypeByCodeMap] = useState(new Map());
  const [nonPatientEventTypeDropdownOptions, setNonPatientEventTypeDropdownOptions] = useState([]);
  const [studySites, setStudySites] = useState([]);

  const sessionStorageSelectedEvent = JSON.parse(sessionStorage.getItem(CALENDAR_SELECTED_EVENT));

  useEffect(function() {
    EventApi.getNonPatientEventTypes(hideLoader).then(res => {
      const nonPatientEventTypes = res.data;
      const nonPatientEventTypeByCodeMap = new Map();
      nonPatientEventTypes.forEach(nonPatientEventType =>
        nonPatientEventTypeByCodeMap.set(nonPatientEventType.id, nonPatientEventType)
      );
      setNonPatientEventTypeByCodeMap(nonPatientEventTypeByCodeMap);

      const dropdownOptions = nonPatientEventTypes.map(nonPatientEventType => {
        return {
          id: nonPatientEventType.id,
          name: NON_PATIENT_EVENT_TYPE_DROPDOWN_LABEL_MAP.get(nonPatientEventType.id) || nonPatientEventType.name,
          code: nonPatientEventType.id
        };
      });
      setNonPatientEventTypeDropdownOptions(dropdownOptions);
    });

    StudySiteApi.getAllStudySites().then(res => {
      const studySites = res.data.map(ssu => mapStudySite(ssu));
      setStudySites(studySites);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CalendarPageContext.Provider
      value={{
        encounterId,
        sitePatientId,
        nonPatientEventTypeByCodeMap,
        nonPatientEventTypeDropdownOptions,
        studySites,
        storedEvent: sessionStorageSelectedEvent
          ? {
              ...sessionStorageSelectedEvent,
              displayedTimeRange: weekOfDate(sessionStorageSelectedEvent.appointmentDate),
              displayedDate: moment(sessionStorageSelectedEvent.appointmentDate)
            }
          : null,
        resetStoredEvent: () => {
          sessionStorage.setItem(CALENDAR_SELECTED_EVENT, JSON.stringify(null));
        }
      }}
    >
      {children}
    </CalendarPageContext.Provider>
  );
}
