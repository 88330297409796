import { SvgIcon } from '@mui/material';

export const Font = ({ onClick, active }) => {
  return (
    <SvgIcon onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M16 19H18L13 5H11L6 19H8L9.43 15H14.57L16 19ZM10.14 13L12 7.8L13.86 13H10.14Z"
          fill="black"
          fillOpacity={`${active ? '0.9' : '0.6'}`}
        />
      </svg>
    </SvgIcon>
  );
};
