import React, { createContext, useContext } from 'react';
import cx from 'classnames';
import { EventEmitter } from 'events';
import { isFunction } from 'lodash/lang';
import uuid from 'uuid/v4';

import Button from '../../general/Button';
import Icon from '../../general/Icon';

import ModalBoxConfirmation from './ModalBoxConfirmation/ModalBoxConfirmation';

export const ModalBoxContext = createContext(null);

class ModalBoxes extends EventEmitter {
  constructor() {
    super();
    this.modalBoxes = [];
  }

  create(modalBox) {
    const id = uuid();
    const defaultModalBox = {
      id,
      close: () => {
        this.close(id);
      }
    };
    const modalBoxObject = Object.assign(defaultModalBox, modalBox);
    this.modalBoxes.push(modalBoxObject);
    this.emitChange();
    return modalBoxObject;
  }

  open({ component, onClose, className, size, title, ...rest }) {
    const _size = size || component.type.size;
    return this.create({
      component,
      onCloseCallback: onClose,
      className: cx({ [`eds-modal-box-size-${_size}`]: _size }, component.type.className, className),
      title,
      ...rest
    });
  }

  confirm({ content, className, title = 'Confirmation', cancelButton, confirmButton, size } = {}) {
    return new Promise((resolve, reject) => {
      const confirmationModalInstance = this.open({
        component: (
          <ModalBoxConfirmation
            confirm={() => {
              resolve();
              confirmationModalInstance.close();
            }}
            cancelButton={cancelButton}
            confirmButton={confirmButton}
          >
            {content}
          </ModalBoxConfirmation>
        ),
        title,
        size,
        onClose() {
          reject();
        },
        className: cx('eds-modal-box-confirm', className)
      });
    });
  }

  close(id) {
    this.modalBoxes = this.modalBoxes.filter(n => id !== n.id);
    this.emitChange();
  }

  closeAll() {
    this.modalBoxes = [];
    this.emitChange();
  }

  emitChange() {
    this.emit('change', [...this.modalBoxes]);
  }

  addChangeListener(callback) {
    this.addListener('change', callback);
  }

  removeChangeListener(callback) {
    this.removeListener('change', callback);
  }
}

ModalBoxes.prototype.Header = function ModalBoxesHeader({ children, onClose }) {
  const { close } = useModalBoxes();

  return (
    <div className="eds-modal-box-header">
      {children}
      <Button
        priority="low"
        size="h40"
        onClick={function() {
          if (isFunction(onClose)) {
            return onClose();
          }
          return close();
        }}
      >
        <Icon>close</Icon>
      </Button>
    </div>
  );
};

ModalBoxes.prototype.Body = function ModalBoxesBody({ children }) {
  return <div className="eds-modal-box-body">{children}</div>;
};

ModalBoxes.prototype.Footer = function ModalBoxesFooter({ children }) {
  return <div className="eds-modal-box-footer">{children}</div>;
};

export default new ModalBoxes();

export function useModalBoxes() {
  return useContext(ModalBoxContext);
}
