export function generateMapForEncountersItemGroupsConfiguration(encountersItemGroupsConfiguration) {
  return encountersItemGroupsConfiguration.reduce(function(accumulator, v) {
    if (v === null) {
      /*TODO: remove*/
      console.log('Null found!');
      return accumulator;
    }
    const { itemGroupKey, encounterKey } = v;
    return { ...accumulator, [`${itemGroupKey}_${encounterKey}`]: v };
  }, {});
}
