import axios from 'axios';

export const authenticateAuth0User = (email, password, callback) => {
  const base64Encoder = btoa;
  const stringFieldsRepresentation = JSON.stringify({ email: email, password: password });
  const encodedFields = base64Encoder(stringFieldsRepresentation);
  axios
    .post('/api/v1/auth/token', {
      encodedFields
    })
    .then(response => {
      callback.onAuth0Success(response.data);
    })
    .catch(error => {
      callback.onFailure(error);
    });
};
