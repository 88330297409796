import React, { useContext, useMemo } from 'react';
import cx from 'classnames';
import { isEmpty, isEqual } from 'lodash/lang';
import moment from 'moment';

import Checkbox from '../../../../../../../../../common/data-entry/InputSelectors/Checkbox/Checkbox';
import { FinanceTable } from '../../../../../../shared/FinanceTable/FinanceTable';
import { AddLedgerContext } from '../../../../AddLedgerContext';

import './MissedEventsTable.scss';

const CHECKBOX = 'checkbox';

export function MissedEventsTable() {
  const { missingBudgetItem, checkedBudgetItems, setCheckedBudgetItems } = useContext(AddLedgerContext);
  // eslint-disable-next-line
  const columns = useMemo(getColumns, [checkedBudgetItems, missingBudgetItem, setCheckedBudgetItems]);
  const defaultSorted = [
    {
      id: 'date',
      desc: true
    }
  ];

  const disabledHeader = isEmpty(missingBudgetItem);
  return (
    !!missingBudgetItem && (
      <FinanceTable
        scrollInAnyPart
        defaultSorted={defaultSorted}
        data={missingBudgetItem}
        columns={columns}
        minRows={1}
        multiSort
        className={cx('missed-events-table', {
          'met-disabled': disabledHeader
        })}
        noDataText="No Missing Events Found"
        defaultPageSize={100}
        resizable={false}
        getTrProps={getTrProps}
      />
    )
  );
  function getTrProps(state, rowInfo) {
    return {
      onClick: () => {
        const original = rowInfo.original;
        if (isEventChecked(original)) {
          setCheckedBudgetItems(checkedBudgetItems => checkedBudgetItems.filter(e => !isEqual(e, original)));
        } else {
          setCheckedBudgetItems(checkedBudgetItems => [...checkedBudgetItems, original]);
        }
      }
    };
  }

  function isEventChecked(event) {
    return !!checkedBudgetItems?.find(e => isEqual(e, event));
  }

  function triggerItemCheckbox({ target: { checked } }) {
    if (checked) {
      setCheckedBudgetItems(missingBudgetItem);
    } else {
      setCheckedBudgetItems([]);
    }
  }

  function getColumns() {
    return [
      {
        id: CHECKBOX,
        accessor: e => {
          return isEventChecked(e);
        },
        Header: () => {
          const checked = missingBudgetItem?.length !== 0 && checkedBudgetItems?.length === missingBudgetItem?.length;
          return (
            <Checkbox
              indeterminate={!!checkedBudgetItems?.length && checkedBudgetItems?.length < missingBudgetItem?.length}
              onChange={triggerItemCheckbox}
              disabled={disabledHeader}
              checked={checked}
            />
          );
        },
        sortable: false,
        maxWidth: 60,
        className: 'met-right-level',
        headerClassName: 'met-right-level',
        Cell({ value }) {
          return <Checkbox checked={value} className="checkbox-status" />;
        }
      },
      {
        Header: 'Subject Id',
        accessor: 'subjectId',
        Cell({ value }) {
          return <p title={value}>{value}</p>;
        }
      },
      {
        Header: 'Event Date',
        accessor: 'date',
        width: 120,
        Cell({ value }) {
          return <p>{moment(value).format('DD/MMM/YYYY')}</p>;
        }
      },
      {
        Header: 'Encounter',
        accessor: 'encounter.encounterName',
        className: 'met-left-level',
        headerClassName: 'met-left-level',
        Cell({ value }) {
          return <p title={value}>{value}</p>;
        }
      },
      {
        Header: 'Type',
        accessor: 'budgetEvent.eventType',
        className: 'met-left-level',
        headerClassName: 'met-left-level',
        Cell({ value }) {
          return <p>{value}</p>;
        }
      },
      {
        Header: 'Event',
        accessor: 'budgetEvent.eventName',
        className: 'met-left-level',
        headerClassName: 'met-left-level',
        Cell({ value }) {
          return <p title={value}>{value}</p>;
        }
      }
    ];
  }
}
