import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { isEmpty } from 'lodash/lang';

import { useCurrentRoute } from '../../../router';

import { generateNavigationList } from './breadcrumbNavigationService';
import { BREADCRUMB_TYPE_HIDDEN } from './constants';

import './BreadcrumbNavigation.scss';
function BreadcrumbNavigation() {
  const currentRoute = useCurrentRoute();
  const [navigationList, setNavigationList] = useState([]);

  const patientId = currentRoute?.params?.patientId,
    ssuPatientId = currentRoute?.params?.ssuPatientId;

  useEffect(
    function() {
      if (!patientId || !ssuPatientId) return;
      let lastSsuPatientIdMap = JSON.parse(sessionStorage.getItem('LAST_SSU_PATIENT_ID_MAP'));
      if (!lastSsuPatientIdMap) {
        lastSsuPatientIdMap = {};
      }
      lastSsuPatientIdMap[patientId] = ssuPatientId;
      sessionStorage.setItem('LAST_SSU_PATIENT_ID_MAP', JSON.stringify(lastSsuPatientIdMap));
    },
    [patientId, ssuPatientId]
  );

  useEffect(
    function() {
      if (currentRoute.breadcrumb === BREADCRUMB_TYPE_HIDDEN) {
        return;
      }
      Promise.resolve(generateNavigationList(currentRoute)).then(function(navigationList) {
        setNavigationList(navigationList);
      });
    },
    [currentRoute]
  );

  if (isEmpty(navigationList)) {
    return null;
  }

  return (
    <div className={cx('eds-breadcrumb-navigation')} data-testid="breadcrumb-navigation">
      {navigationList.map(({ current, path, name, key, rootBreadcrumbName }, index) => {
        return (
          <React.Fragment key={key}>
            {rootBreadcrumbName && (
              <React.Fragment>
                <span>{rootBreadcrumbName}</span>
                <span className="slash">/</span>
              </React.Fragment>
            )}
            {index > 0 && <span className="slash">/</span>}
            {current ? (
              <span>{name}</span>
            ) : (
              <Link
                to={{
                  pathname: path
                }}
              >
                {name}
              </Link>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default BreadcrumbNavigation;
