import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { isFunction } from 'lodash/lang';
import * as moment from 'moment';

import DatePicker from '../../../../../../../common/data-entry/DatePicker';
import Input from '../../../../../../../common/data-entry/Input';
import useOutsideClickDetector from '../../../../../../../common/hooks/useOutsideClickDetector';

import './EdcVisitDatePicker.scss';

export default function EdcVisitDatePicker({ classNames, onChange, value, isAllowedToManage }) {
  const [showDialog, setShowDialog] = useState(false);
  const [date, setDate] = useState();
  const userPreferencesContainer = useRef();
  const isDisabled = !(isFunction(isAllowedToManage) ? isAllowedToManage() : isAllowedToManage);
  useOutsideClickDetector(userPreferencesContainer, () => setShowDialog(false));

  useEffect(
    function() {
      value && setDate(moment(value));
    },
    [value]
  );

  return (
    <div className={cx('edc-visit-date', classNames, { active: showDialog })} ref={userPreferencesContainer}>
      <Input
        label="Visit Date"
        placeholder="EDC Visit Date"
        disabled={isDisabled}
        required
        value={moment.isMoment(date) ? date.format('DD/MMM/YYYY') : ''}
        onFocus={() => setShowDialog(true)}
      />
      {showDialog && (
        <div className="dropdown-container">
          <DatePicker
            input={false}
            value={date}
            timeFormat={false}
            open={true}
            onChange={date => {
              setDate(date);
              onChange(date);
              setShowDialog(false);
            }}
            isValidDate={current => {
              const currentMoment = moment(current);
              const today = moment();
              return currentMoment.isSameOrBefore(today);
            }}
          />
          <div className={'hint'}>
            This is the official date of the Encounter that corresponds to the visit date defined in the electronic data
            capture (EDC) system.
          </div>
        </div>
      )}
    </div>
  );
}
