import { PL, SCHEDULING_WORK_LIST } from '../../../../constants/accessToPages';
import { generatePatientInfoRouterConfig } from '../../../root/router/sharedConfigs';

import SchedulingWorklist from './SchedulingWorklist';

export const schedulingWorkListRouterConfig = {
  name: 'Scheduling Worklist',
  path: '/scheduling-work-list',
  component: SchedulingWorklist,
  access: [SCHEDULING_WORK_LIST],
  inherited: [...generatePatientInfoRouterConfig(PL, SCHEDULING_WORK_LIST)]
};
