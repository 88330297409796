export function isSigned(itemGroupSnapshotReviewStates, item) {
  return itemGroupSnapshotReviewStates[item.patientItemGroupId]?.reviewState?.reviewStatus !== 'NOT_SIGNED';
}

export function isNotPerformed(itemGroupSnapshotReviewStates, item) {
  return itemGroupSnapshotReviewStates[item.patientItemGroupId]?.itemGroupSnapshotState?.dataStatus?.notPerformed;
}

export function isDisabled(itemGroupSnapshotReviewStates, item) {
  return (
    !isNotPerformed(itemGroupSnapshotReviewStates, item) &&
    itemGroupSnapshotReviewStates[item.patientItemGroupId]?.itemGroupSnapshotState?.rows?.length === 0
  );
}
