import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { isEmpty } from 'lodash/lang';

import { eventTypeLabel } from '../../../../setup/Budget/BudgetDetails/budgetConstant';
import { CREDIT_MEMO, DEBIT_MEMO } from '../../InvoiceTable/TableConstants';

import { InvoiceHeader } from './InvoiceHeader/InvoiceHeader';
import { InvoiceItemsList } from './InvoiceItemsList/InviceItemsList';
import { styles } from './EncounterInvoiceTemplateStyles';

export const InvoicePageGenerator = ({
  normalizedGroups,
  pageNumber,
  totalPages,
  setTotalPages,
  study,
  pcn,
  investigator,
  site,
  siteNumber,
  invoiceNumber,
  invoiceDate,
  terms,
  payee,
  remit,
  contact,
  billTo,
  invoiceType
}) => {
  const mainRef = useRef();
  const shadowRef = useRef();

  const [maxIndex, setMaxIndex] = useState(null);

  const getMax = useCallback((elements, height) => {
    const lastIndex = elements.length - 1;
    for (let i = 0, w = 0; i <= lastIndex; ++i) {
      w = w + elements[i];
      if (w > height) {
        return i - 1;
      }
    }
    return lastIndex;
  }, []);

  const cutEventsForPages = useCallback((groups, maxIndex) => {
    if (groups[maxIndex]?.typeHeader) {
      return { currentPage: groups.slice(0, maxIndex), nextPage: groups.slice(maxIndex) };
    } else if (groups[maxIndex]?.typeSubHeader) {
      return { currentPage: groups.slice(0, maxIndex - 1), nextPage: groups.slice(maxIndex - 1) };
    } else {
      return { currentPage: groups.slice(0, maxIndex + 1), nextPage: groups.slice(maxIndex + 1) };
    }
  }, []);

  const getQuantityAndAmountForGroup = useCallback((element, itemsForPage) => {
    if (element?.subGroupName) {
      const subGroups = itemsForPage.filter(
        budgetEvent => budgetEvent.groupName === element.groupName && budgetEvent?.typeSubHeader
      );
      const subElements = itemsForPage.filter(
        budgetEvent =>
          budgetEvent.subGroupName === element.subGroupName &&
          budgetEvent.groupName === element.groupName &&
          !budgetEvent?.typeSubHeader
      );
      const groupCount = subGroups.length;
      const groupAmount = subGroups.reduce((acc, item) => acc + item.subGroupAmount, 0);
      const subGroupAmount = subElements.reduce((acc, item) => acc + item.invoiceAmount, 0);
      return { subGroupAmount, groupCount, groupAmount };
    } else {
      const groupCount = itemsForPage.filter(
        bugetEvent => bugetEvent.groupName === element.groupName && bugetEvent.invoiceAmount === element.invoiceAmount
      ).length;
      return { subGroupCount: null, subGroupAmount: null, groupCount, groupAmount: null };
    }
  }, []);

  const listForCurrentPage = useMemo(() => {
    const { currentPage } = cutEventsForPages(normalizedGroups, maxIndex);
    const lastElement = currentPage[currentPage.length - 1];
    const { subGroupAmount, groupCount } = getQuantityAndAmountForGroup(lastElement, currentPage);
    if (maxIndex) {
      const currentPageWithHeaderAndSubHeader = currentPage.map(budgetEvent => {
        if (budgetEvent?.typeHeader && budgetEvent.groupName === lastElement.groupName && !budgetEvent.inseparable) {
          return {
            ...budgetEvent,
            groupLength: budgetEvent.headerBudgetEventType === eventTypeLabel.PATIENT_REIMBURSEMENT ? 1 : groupCount
          };
        }
        if (
          budgetEvent?.typeSubHeader &&
          budgetEvent.subGroupName === lastElement.subGroupName &&
          budgetEvent.groupName === lastElement.groupName
        ) {
          return {
            ...budgetEvent,
            subGroupAmount: subGroupAmount
          };
        }
        return budgetEvent;
      });
      const { groupAmount } = getQuantityAndAmountForGroup(lastElement, currentPageWithHeaderAndSubHeader);
      return currentPageWithHeaderAndSubHeader.map(budgetEvent => {
        if (budgetEvent?.typeHeader && budgetEvent.groupName === lastElement.groupName && !budgetEvent.inseparable) {
          return {
            ...budgetEvent,
            groupAmount
          };
        }
        return budgetEvent;
      });
    }

    return currentPage;
  }, [cutEventsForPages, getQuantityAndAmountForGroup, maxIndex, normalizedGroups]);

  const listPorNextPage = useMemo(() => {
    const { nextPage } = cutEventsForPages(normalizedGroups, maxIndex);
    const firstElement = nextPage[0];
    if (!isEmpty(nextPage) && !firstElement.typeHeader && !firstElement.typeBalance && maxIndex) {
      const { subGroupAmount, groupCount } = getQuantityAndAmountForGroup(firstElement, nextPage);
      const reimbursementItem = nextPage[0]?.budgetEventType === eventTypeLabel.PATIENT_REIMBURSEMENT;
      const groupLength = reimbursementItem ? 1 : groupCount;
      if (!!firstElement.subGroupName) {
        return firstElement.typeSubHeader
          ? [
              {
                typeHeader: true,
                inseparable: true,
                groupName: firstElement.groupName,
                groupHeaderSuffix: '(cont.)'
              },
              ...nextPage
            ]
          : [
              {
                typeHeader: true,
                inseparable: true,
                groupName: firstElement.groupName,
                groupHeaderSuffix: '(cont.)'
              },
              {
                typeSubHeader: true,
                groupName: firstElement.groupName,
                subGroupName: firstElement.subGroupName,
                subGroupAmount
              },
              ...nextPage
            ];
      } else {
        return [
          {
            typeHeader: true,
            groupName: firstElement.groupName,
            groupHeaderSuffix: '(cont.)',
            groupLength,
            amountForItem: firstElement.invoiceAmount,
            headerBudgetEventType: firstElement.budgetEventType
          },
          ...nextPage
        ];
      }
    }
    return nextPage;
  }, [cutEventsForPages, getQuantityAndAmountForGroup, maxIndex, normalizedGroups]);

  useLayoutEffect(() => {
    if (!mainRef.current || !shadowRef.current) {
      return;
    }
    updateSize();

    function updateSize() {
      const { clientHeight } = mainRef.current;
      const shadowItems = shadowRef.current.querySelectorAll('.row-item');
      const shadowItemsHeight = [...shadowItems].map(({ offsetHeight }) => offsetHeight);

      setMaxIndex(getMax(shadowItemsHeight, clientHeight));
    }
  });

  useEffect(() => {
    if (isEmpty(listPorNextPage)) {
      setTotalPages(pageNumber);
    }
  }, [listPorNextPage, pageNumber, setTotalPages]);

  return (
    <>
      <div style={styles.page} data-testid="invoice-pdf-page">
        <InvoiceHeader
          study={study}
          pcn={pcn}
          investigator={investigator?.split(', ').join(' ')}
          site={site}
          siteNumber={siteNumber}
          invoiceNumber={invoiceNumber}
          invoiceDate={invoiceDate}
          terms={terms}
          payee={payee}
          contact={contact}
          remit={remit}
          pageNumber={pageNumber}
          billTo={billTo}
          lastPageWithoutTable={listForCurrentPage.length === 1}
          invoiceType={invoiceType}
        />
        <div style={styles.section} ref={mainRef}>
          <InvoiceItemsList items={listForCurrentPage} invoiceType={invoiceType} />
        </div>
        <div style={styles.pageCountWrapper}>
          {pageNumber === totalPages && ![CREDIT_MEMO, DEBIT_MEMO].includes(invoiceType) && (
            <div style={styles.pageCountWrapper.bankingInformation}>
              <div>{payee?.name}</div>
              <div>{payee?.bankName}</div>
              <div>{`Routing: ${payee?.bankRoutingNumber}`}</div>
              <br />
              <div>{`Account: ${payee?.bankAccountNumber}`}</div>
              <div>{`SWIFT: ${payee?.bankSwiftCode}`}</div>
            </div>
          )}
          <div style={styles.pageCountWrapper.pageCount}>
            {pageNumber} of {totalPages}
          </div>
        </div>
      </div>

      <div style={styles.hiddenPage} className="invoice-generator-hidden">
        <div style={styles.section} ref={shadowRef}>
          <InvoiceItemsList items={normalizedGroups} invoiceType={invoiceType} />
        </div>
      </div>

      {!isEmpty(listPorNextPage) && (
        <InvoicePageGenerator
          normalizedGroups={listPorNextPage}
          pageNumber={pageNumber + 1}
          totalPages={totalPages}
          setTotalPages={setTotalPages}
          study={study}
          pcn={pcn}
          investigator={investigator}
          site={site}
          siteNumber={siteNumber}
          invoiceNumber={invoiceNumber}
          invoiceDate={invoiceDate}
          terms={terms}
          payee={payee}
          contact={contact}
          remit={remit}
          billTo={billTo}
          invoiceType={invoiceType}
        />
      )}
    </>
  );
};
