import React, { useEffect, useMemo } from 'react';
import { intersectionBy } from 'lodash/array';
import { groupBy, map } from 'lodash/collection';
import { isEmpty, isEqual } from 'lodash/lang';
import * as PropTypes from 'prop-types';

import Checkbox from '../../common/data-entry/InputSelectors/Checkbox';

import './EncounterPrintMultiselect.scss';

export default function EncounterPrintMultiselect({
  selectedOptions,
  options,
  label,
  id,
  onChange,
  defaultSelectAll,
  groupByKey,
  displaySelectAll
}) {
  const displayGroupLabel = !!groupByKey;

  const allIsChecked = useMemo(
    function() {
      return selectedOptions?.length === options?.length;
    },
    [selectedOptions, options]
  );

  const someIsChecked = useMemo(
    function() {
      return selectedOptions?.length > 0 && !allIsChecked;
    },
    [selectedOptions, allIsChecked]
  );

  const groupedOptions = useMemo(
    function() {
      if (!groupByKey) {
        return { 0: options };
      }
      return groupBy(options, groupByKey);
    },
    [options, groupByKey]
  );

  useEffect(
    function() {
      if (defaultSelectAll) {
        onChange(options);
      }
    }, // eslint-disable-next-line
    [defaultSelectAll]
  );

  useEffect(
    function() {
      const filtered = intersectionBy(selectedOptions, options, id);
      if (!isEqual(selectedOptions, filtered)) {
        onChange(filtered);
      }
    },
    [options, id, selectedOptions, onChange]
  );

  return (
    <div className="encounter-print-multiselect">
      {displaySelectAll && (
        <div className="epm-header">
          <div className="epm-option">
            <Checkbox label="Select all" indeterminate={someIsChecked} onChange={onSelectAll} checked={allIsChecked} />
          </div>
        </div>
      )}
      {map(groupedOptions, function(options, groupLabel) {
        return (
          <div className="epm-group" key={groupLabel}>
            {displayGroupLabel && <div className="epm-group-label">{groupLabel}</div>}
            <Checkbox.Group direction="column" className="">
              {options.map(option => {
                return (
                  <Checkbox
                    key={option[id]}
                    label={option[label]}
                    onChange={() => _onChange(option)}
                    checked={optionIsChecked(option)}
                  />
                );
              })}
            </Checkbox.Group>
          </div>
        );
      })}
    </div>
  );

  function onSelectAll() {
    if (isEmpty(selectedOptions)) {
      onChange(options);
    } else {
      onChange([]);
    }
  }

  function _onChange(option) {
    if (optionIsChecked(option)) {
      onChange(selectedOptions.filter(selectedOption => !isEqual(selectedOption[id], option[id])));
      return;
    }
    onChange([...selectedOptions, option]);
  }

  function optionIsChecked(option) {
    return selectedOptions.some(selectedOption => option[id] === selectedOption[id]);
  }
}

EncounterPrintMultiselect.defaultProps = {
  label: 'name',
  id: 'id',
  defaultSelectAll: false,
  displaySelectAll: true
};

EncounterPrintMultiselect.propTypes = {
  selectedOptions: PropTypes.array,
  options: PropTypes.array,
  label: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  defaultSelectAll: PropTypes.bool,
  displaySelectAll: PropTypes.bool,
  groupBy: PropTypes.string
};
