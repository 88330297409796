import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { array, bool, func, object, string } from 'prop-types';

import Button from '../../general/Button';

import './RadioButtons.scss';

export default function RadioButtons({ options, initialValue, onChange, className, highlightActive }) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const select = option => {
    setValue(option);
    onChange(option);
  };

  return (
    <div className={cx('eds-btn-radio-group', className)}>
      {options.map(o => {
        const priorityButtonType = highlightActive && o.id === value.id ? 'high' : 'medium';
        return (
          <Button key={o.id} size="h40" priority={priorityButtonType} onClick={() => select(o)}>
            {o.name}
          </Button>
        );
      })}
    </div>
  );
}
RadioButtons.defaultProps = {
  highlightActive: true
};

RadioButtons.propTypes = {
  options: array,
  initialValue: object,
  onChange: func,
  className: string,
  highlightActive: bool
};
