import axios from 'axios';

import separateAxiosInstance from '../separateAxiosInstance';

const ROOT = 'api/v1/ssu-patients';

const PatientEncounterReviewApi = {
  getSmReview(ssuPatientId, patientEncounterId, smReviewId, hideLoader = true) {
    return axios.get(`${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/review/SM/${smReviewId}`, {
      hideLoader
    });
  },

  getPiReview(ssuPatientId, patientEncounterId, piReviewId, hideLoader = true) {
    return axios.get(`${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/review/PI/${piReviewId}`, {
      hideLoader
    });
  },

  getCraReview(ssuPatientId, patientEncounterId, hideLoader = true) {
    return axios.get(`${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/review/CRA`, { hideLoader });
  },

  getItemGroupRowSnapshotSmReviewState(ssuPatientId, patientEncounterId, itemGroupId, hideLoader = true) {
    return axios.get(`${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/review/SM/item-groups/${itemGroupId}`, {
      hideLoader
    });
  },

  getItemGroupRowSnapshotPiReviewState(ssuPatientId, patientEncounterId, itemGroupId, hideLoader = true) {
    return axios.get(`${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/review/PI/item-groups/${itemGroupId}`, {
      hideLoader
    });
  },

  getItemGroupRowSnapshotCraReviewState(ssuPatientId, patientEncounterId, itemGroupId, hideLoader = true) {
    return axios.get(`${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/review/CRA/item-groups/${itemGroupId}`, {
      hideLoader
    });
  },

  getLogSnapshotSmReviewState(ssuPatientId, patientEncounterId, itemGroupTemplateId, hideLoader = true) {
    return axios.get(`${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/review/SM/logs/${itemGroupTemplateId}`, {
      hideLoader
    });
  },
  getLogSnapshotPiReviewState(ssuPatientId, patientEncounterId, itemGroupTemplateId, hideLoader = true) {
    return axios.get(`${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/review/PI/logs/${itemGroupTemplateId}`, {
      hideLoader
    });
  },

  getLogSnapshotCraReviewState(ssuPatientId, patientEncounterId, itemGroupTemplateId, hideLoader = true) {
    return axios.get(
      `${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/review/CRA/logs/${itemGroupTemplateId}`,
      { hideLoader }
    );
  },

  getLogCheckSnapshotSmReviewState(ssuPatientId, patientEncounterId, itemGroupTemplateId, hideLoader = true) {
    return axios.get(
      `${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/review/SM/log-check/${itemGroupTemplateId}`,
      { hideLoader }
    );
  },

  getLogCheckSnapshotSraReviewState(ssuPatientId, patientEncounterId, itemGroupTemplateId, hideLoader = true) {
    return axios.get(
      `${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/review/CRA/log-check/${itemGroupTemplateId}`,
      { hideLoader }
    );
  },

  getLogCheckSnapshotPIReviewState(ssuPatientId, patientEncounterId, itemGroupTemplateId, hideLoader = true) {
    return axios.get(
      `${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/review/PI/log-check/${itemGroupTemplateId}`,
      { hideLoader }
    );
  },

  getSignedItemGroupRowSnapshotReviewState(
    ssuPatientId,
    patientEncounterId,
    itemGroupId,
    signatureId,
    hideLoader = true
  ) {
    return axios.get(
      `${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/review/item-group/${itemGroupId}/signatures/${signatureId}`,
      { hideLoader }
    );
  },
  getSignedLogCheckSnapshotReviewState(ssuPatientId, patientEncounterId, itemGroupId, signatureId, hideLoader = true) {
    return axios.get(
      `${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/review/log-check/${itemGroupId}/signatures/${signatureId}`,
      { hideLoader }
    );
  },
  getSignedLogSnapshotReviewState(
    ssuPatientId,
    patientEncounterId,
    itemGroupTemplateId,
    signatureId,
    hideLoader = true
  ) {
    return axios.get(
      `${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/review/logs/${itemGroupTemplateId}/signatures/${signatureId}`,
      { hideLoader }
    );
  },

  getSmHistory(ssuPatientId, patientEncounterId, hideLoader = true) {
    return axios.get(`${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/review/SM/history`, {
      hideLoader
    });
  },

  getPiHistory(ssuPatientId, patientEncounterId, hideLoader = true) {
    return axios.get(`${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/review/PI/history`, {
      hideLoader
    });
  },

  getCraHistory(ssuPatientId, patientEncounterId, hideLoader = true) {
    return axios.get(`${ROOT}/${ssuPatientId}/encounters/${patientEncounterId}/review/CRA/history`, {
      hideLoader
    });
  },

  signSmReview(ssuPatientId, encounterId, smReviewId, token, request, hideLoader = true) {
    /*TODO: need to replace with new one*/
    return separateAxiosInstance.post(
      `${ROOT}/${ssuPatientId}/encounters/${encounterId}/review/SM/${smReviewId}/sign`,
      request,
      {
        hideLoader,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  },

  signPiReview(ssuPatientId, encounterId, piReviewId, token, request, hideLoader = true) {
    /*TODO: need to replace with new one*/
    return separateAxiosInstance.post(
      `${ROOT}/${ssuPatientId}/encounters/${encounterId}/review/PI/${piReviewId}/sign`,
      request,
      {
        hideLoader,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  },

  getItemGroupAnswerHistory(ssuPatientId, ssuEncounterId, itemGroupId, rowId, hideLoader = true) {
    return axios.get(
      `${ROOT}/${ssuPatientId}/encounters/${ssuEncounterId}/review/item-groups/${itemGroupId}/rows/${rowId}/answers/history`,
      { hideLoader }
    );
  },

  getItemGroupGeneralAnswerHistory(ssuPatientId, ssuEncounterId, itemGroupId, rowId, hideLoader = true) {
    return axios.get(
      `${ROOT}/${ssuPatientId}/encounters/${ssuEncounterId}/review/item-groups/${itemGroupId}/general-row/${rowId}/answers/history`,
      { hideLoader }
    );
  },

  getLogItemGroupAnswerHistory(
    ssuPatientId,
    ssuEncounterId,
    itemGroupTemplateId,
    itemGroupTemplateVersionId,
    rowId,
    hideLoader = true
  ) {
    return axios.get(
      `${ROOT}/${ssuPatientId}/encounters/${ssuEncounterId}/review/logs/${itemGroupTemplateId}/versions/${itemGroupTemplateVersionId}/rows/${rowId}/history-answers`,
      { hideLoader }
    );
  },

  getLogCheckAnswerHistory(ssuPatientId, ssuEncounterId, itemGroupTemplateId, hideLoader = true) {
    return axios.get(
      `${ROOT}/${ssuPatientId}/encounters/${ssuEncounterId}/review/log-check/${itemGroupTemplateId}/answers/history`,
      { hideLoader }
    );
  }
};

export default PatientEncounterReviewApi;
