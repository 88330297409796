import React from 'react';
import { Box } from '@mui/material';

import { ReactComponent as PaidIcon } from '../../../../../../images/icons/green-alert-icon.svg';
import { ReactComponent as PartialIcon } from '../../../../../../images/icons/orange-alert-icon.svg';
import { ReactComponent as PastDueIcon } from '../../../../../../images/icons/red-alert-icon.svg';
import { COMPLETE, OPEN, PAID, PARTIAL, PAST_DUE } from '../../../NewInvoice/InvoiceTable/TableConstants';
import { SiteApprovalStatus, SitePaymentStatus } from '../SitePaymentsConstants';

export const StatusCell = ({ value, statusWidth, children }) => (
  <Box display="flex" alignItems="center">
    <Box width={statusWidth}>{value || '—'}</Box>
    {children}
  </Box>
);

export const PaymentStatusIcon = ({ status }) => {
  const resolveIcon = () => {
    switch (status) {
      case OPEN:
      case PARTIAL:
        return <PartialIcon />;
      case PAST_DUE:
        return <PastDueIcon />;
      case COMPLETE:
      case PAID:
        return <PaidIcon />;
      default:
        return '';
    }
  };
  return resolveIcon();
};

export const SiteApprovalStatusIcon = ({ status }) => {
  const resolveIcon = () => {
    switch (status) {
      case SiteApprovalStatus.APPROVED:
        return <PaidIcon />;
      case SiteApprovalStatus.REJECTED:
        return <PastDueIcon />;
      case SiteApprovalStatus.READY_FOR_APPROVAL:
        return <PartialIcon />;
      default:
        return '';
    }
  };
  return resolveIcon();
};

export const SitePaymentStatusIcon = ({ status }) => {
  const resolveIcon = () => {
    switch (status) {
      case SitePaymentStatus.PENDING:
      case SitePaymentStatus.PARTIALLY_APPLIED:
        return <PartialIcon />;
      case SitePaymentStatus.PAID:
      case SitePaymentStatus.APPLIED:
        return <PaidIcon />;
      default:
        return '';
    }
  };
  return resolveIcon();
};
