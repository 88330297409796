import React from 'react';

import { usePatientProfile } from '../../PatientProfileContext';

export default function PatientDetailsFinancialInformation() {
  const { patientInfo } = usePatientProfile();
  const { ssn, cardInfo } = patientInfo || {};
  return (
    <div className="patient-profile-information-content-section">
      <div className="patient-profile-information-header">
        <div className="patient-profile-information-header-title">Patient Financial</div>
      </div>

      <div className="patient-profile-information-data-row">
        <div className="patient-profile-information-label">
          <div className="patient-profile-information-label-field">SSN:</div>
          <div className="patient-profile-information-label-field">{ssn?.toUpperCase() || '-'}</div>
        </div>

        <div className="patient-profile-information-label">
          <div className="patient-profile-information-label-field">PID:</div>
          <div className="patient-profile-information-label-field">{cardInfo?.id || '-'}</div>
        </div>
      </div>
    </div>
  );
}
