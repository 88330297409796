import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash/lang';
import { has } from 'lodash/object';
import { handleActions } from 'redux-actions';

import { options, UPDATE, update } from './actions';
const DEFAULT_STATE = {
  updateTimestamp: null,
  taskId: null,
  ssuId: null,
  isChanged: false,
  isClosed: true,
  isViewMode: false,
  isCreateMode: false,
  isHidden: true
};

export default handleActions(
  {
    [UPDATE]: function(state, { payload }) {
      if (isEmpty(payload)) {
        return { ...DEFAULT_STATE, updateTimestamp: state.updateTimestamp, isHidden: state.isHidden };
      }
      return calculateState(state, payload || {});
    }
  },
  DEFAULT_STATE,
  options
);

export function useActiveTask() {
  return useSelector(state => state.activeTask);
}

export function useActiveTaskUpdate() {
  const dispatch = useDispatch();
  return useCallback(
    function(payload) {
      dispatch(update(payload));
    },
    [dispatch]
  );
}

function calculateState(state, payload) {
  const calculatedState = { ...state, ...payload };
  const { taskId, ssuId, isChanged, isHidden, updateTimestamp } = calculatedState;

  if (!has(payload, 'taskId')) {
    return calculatedState;
  }

  if (isEmpty(taskId)) {
    return {
      taskId: null,
      ssuId,
      isChanged,
      isClosed: false,
      isViewMode: false,
      isCreateMode: true,
      isHidden,
      updateTimestamp
    };
  }

  return {
    taskId,
    ssuId,
    isChanged,
    isClosed: false,
    isViewMode: true,
    isCreateMode: false,
    isHidden,
    updateTimestamp
  };
}
