import React from 'react';
import { isEmpty, isNull } from 'lodash/lang';
import PropTypes from 'prop-types';

import TextArea from '../../../../../../../../common/data-entry/TextArea';
import { useTableItemGroupActions, useTableItemGroupState } from '../../TableItemGroupContext/TableItemGroupContext';
import fieldPropTypes from '../fieldPropTypes';

export default function FreeTextLongField({ field }) {
  const { fieldUid } = field;
  const { isPreviewOnly } = useTableItemGroupState();
  const { fieldOnChange } = useTableItemGroupActions();

  return (
    <FreeTextLongFieldReadonly
      field={field}
      onChange={({ target }) => fieldOnChange(target.value || null, fieldUid)}
      disabled={isPreviewOnly}
    />
  );
}

export function FreeTextLongFieldReadonly({ field, ...rest }) {
  const { fieldValue } = field;

  return (
    <TextArea legacyLook value={fieldValue} maxLength="3000" showCount className="free-text-long-field" {...rest} />
  );
}

const propTypes = {
  field: PropTypes.shape({
    ...fieldPropTypes,
    fieldType: PropTypes.oneOf(['textarea']).isRequired,
    fieldValue: function(props, propName, componentName) {
      const value = props[propName];
      if (!isNull(value) && isEmpty(value)) {
        return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed.`);
      }
    }
  })
};

FreeTextLongFieldReadonly.defaultProps = {
  disabled: true
};

FreeTextLongFieldReadonly.propTypes = propTypes;
FreeTextLongField.propTypes = propTypes;
