import React, { Component } from 'react';

import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';

import 'react-table/react-table.css';

class EditCustomFieldModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originCustomFieldName: props.originalCustomFieldName,
      updatedCustomFieldName: props.originalCustomFieldName
    };
  }

  onClose = () => {
    this.props.modalBox.close();
  };

  onChangeCustomFieldName = e => {
    if (e.target.value?.length <= 2000) {
      this.setState({
        updatedCustomFieldName: e.target.value
      });
    }
  };

  updateItem = () => {
    this.props.onSave(this.state.updatedCustomFieldName);
    this.onClose();
  };

  isFieldNameNotUpdated() {
    return (
      !this.state.updatedCustomFieldName.trim() ||
      this.state.originCustomFieldName === this.state.updatedCustomFieldName
    );
  }

  render() {
    return (
      <>
        <ModalBoxes.Header>Edit Custom Field</ModalBoxes.Header>
        <ModalBoxes.Body>
          <div className="col-12 form-row p-0 m-0 mt-3">
            <label htmlFor="updatedCustomFieldName">New Custom Field Name</label>
            <textarea
              rows="1"
              id="updatedCustomFieldName"
              value={this.state.updatedCustomFieldName}
              onChange={event => this.onChangeCustomFieldName(event)}
              className=" form-control"
            />
          </div>
        </ModalBoxes.Body>
        <ModalBoxes.Footer>
          <ButtonGroup>
            <Button priority="medium" onClick={this.onClose}>
              Cancel
            </Button>
            <Button onClick={() => this.updateItem()} disabled={this.isFieldNameNotUpdated()}>
              Update
            </Button>
          </ButtonGroup>
        </ModalBoxes.Footer>
      </>
    );
  }
}

EditCustomFieldModal.className = 'element-setup-modals';
EditCustomFieldModal.size = 'w650';

export default EditCustomFieldModal;
