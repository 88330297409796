import React from 'react';

import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { PATIENT_STIPEND_SENT_TO_VENDOR } from '../../../../../constants/notificationMessages';

import './LedgerTableStatusChangeModal.scss';

export default function LedgerTablePaymentReprocessingModal({ modalBox, onConfirm, eventId }) {
  return (
    <>
      <ModalBoxes.Header>Process Patient Stipend</ModalBoxes.Header>
      <ModalBoxes.Body>
        <p>Are you sure you want to submit this patient stipend to the vendor for processing?</p>
      </ModalBoxes.Body>

      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            No
          </Button>
          <Button
            onClick={() =>
              onConfirm(eventId)
                .then(
                  () => {
                    NotificationManager.success(PATIENT_STIPEND_SENT_TO_VENDOR);
                  },
                  err => {
                    NotificationManager.error(err.response.data.message);
                  }
                )
                .finally(function() {
                  modalBox.close();
                })
            }
          >
            Yes
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
}

LedgerTablePaymentReprocessingModal.className = 'enter-revenue-dates-modal';
LedgerTablePaymentReprocessingModal.size = 'w650';
