import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { isArray } from 'lodash/lang';

import { getHomePageDashboardsConfig } from '../../../../../actions/homePageDashboardsConfigActions';
import HomePageDashboardsConfigApi from '../../../../../api/homePageDashboards/HomePageDashboardsConfigApi';
import Loader from '../../../../../common/elements/Loader/Loader';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import Icon from '../../../../../common/general/Icon';
import { getStatusDisplayName } from '../utilityFunctions';

import WorkProgressWidgetConfigModal from './WorkProgressWidgetConfigModal';

import './WorkProgressWidget.scss';

const DEFAULT_STATUSES = ['PI_REVIEW', 'SM_REVIEW', 'OPEN', 'CANCELED'];

export const WorkProgressWidget = ({ widget, ssus }) => {
  const [encounterToStatusMap, setEncounterToStatusMap] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatch();
  const [showStatuses, setShowStatuses] = useState(
    isArray(widget?.configJson?.statuses) ? widget?.configJson?.statuses : DEFAULT_STATUSES
  );
  const [totalAmount, setTotalAmount] = useState(0);

  const fetchData = useCallback(
    function() {
      setIsFetching(true);
      HomePageDashboardsConfigApi.getWorkProgressWidgetData({
        ssuIds: ssus.map(e => e.id),
        encounterStatuses: DEFAULT_STATUSES
      }).then(resp => {
        setEncounterToStatusMap(resp.data);
        setTotalAmount(Object.values(resp.data).reduce((partialSum, a) => partialSum + a, 0));
        setIsFetching(false);
      });
    },
    [ssus]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const changeSettings = newStatuses => {
    HomePageDashboardsConfigApi.updateDashboardWidgetState({
      dashboardId: widget.dashboardId,
      type: widget.type,
      active: widget.active,
      configChanges: { ...widget?.configJson, statuses: newStatuses }
    }).then(resp => {
      dispatch(getHomePageDashboardsConfig());
      setShowStatuses(resp.data.configJson.statuses);
    });
  };

  const configureTable = () => {
    ModalBoxes.open({
      component: (
        <WorkProgressWidgetConfigModal
          statuses={DEFAULT_STATUSES}
          columnFlags={showStatuses}
          changeSettings={changeSettings}
        />
      )
    });
  };
  const max = Math.max(...Object.values(encounterToStatusMap));
  return (
    <div className={cx('p-3 widget-container work-progress', 'size-m')} key={5}>
      <div className="widget">
        <div className="widget-header p-2 px-3">
          <h5 className="title">{widget.name}</h5>
          <span>
            <Button
              size="h28"
              disabled={isFetching}
              onClick={() => {
                configureTable();
              }}
              className="mx-2"
              priority="medium"
            >
              <Icon suit={'material-outline'}>settings</Icon>
            </Button>
            <Button
              size="h28"
              disabled={isFetching}
              onClick={() => {
                fetchData();
              }}
              priority="medium"
            >
              <Icon>restart_alt</Icon>
            </Button>
          </span>
        </div>
        {isFetching && <Loader dark={true} />}
        {!isFetching && (
          <>
            <h5 className="px-3">Total encounters: {totalAmount}</h5>
            <div className="work-progress-bars">
              {DEFAULT_STATUSES.filter(e => showStatuses.includes(e)).map(
                (type, index) =>
                  encounterToStatusMap[type] > 0 && (
                    <div className="el-container" key={index} style={{ borderColor: colors[type] }}>
                      <div className="mx-2" style={{ width: '100px' }}>
                        {getStatusDisplayName(type)}
                      </div>
                      <strong className="mr-3" style={{ width: '50px' }}>
                        {encounterToStatusMap[type]}
                      </strong>
                      <div className="bar-container">
                        <div>
                          <div
                            className="bar"
                            style={{
                              background: colors[type],
                              width: (100 / max) * encounterToStatusMap[type] + '%'
                            }}
                          />
                        </div>
                        <div className="percent" style={{ color: colors[type], width: '50px' }}>
                          {getPercentage(totalAmount, encounterToStatusMap, type)}%
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

function getPercentage(totalAmount, encounterToStatusMap, type) {
  let res = Math.round((100 / totalAmount) * encounterToStatusMap[type]);
  if (res < 1) {
    res = 1;
  }
  return res;
}

const colors = {
  PI_REVIEW: '#36b37e',
  CANCELED: '#ff5630',
  OPEN: '#0073ff',
  SM_REVIEW: '#ffab00',
  SCHEDULED: '#00b8d9'
};
