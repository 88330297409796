import axios from 'axios';

const ROOT = 'api/v1/ssu-patients';

const ProgressNoteApi = {
  getProgressNotes(ssuPatientId) {
    return axios.get(`${ROOT}/${ssuPatientId}/progress-notes`, { hideLoader: true });
  },
  getProgressNotesVersions(ssuPatientId) {
    return axios.get(`${ROOT}/${ssuPatientId}/progress-notes/versions`, { hideLoader: true });
  },
  addProgressNote(ssuPatientId, data) {
    return axios.post(`${ROOT}/${ssuPatientId}/progress-notes`, data, {
      hideLoader: true
    });
  },
  updateProgressNote(ssuPatientId, progressNoteId, data) {
    return axios.put(`${ROOT}/${ssuPatientId}/progress-notes/${progressNoteId}`, data, {
      hideLoader: true
    });
  }
};

export default ProgressNoteApi;
