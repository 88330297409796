import { useCallback, useMemo, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { reject } from 'lodash/collection';
import moment from 'moment-timezone';

import PatientReimbursementApi from '../../../../../../api/patient/PatientReimbursementApi';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { dateTime12 } from '../../../../../../constants/dateFormat';
import { toBill } from '../../../../../../services/financial';
import { onRequestError } from '../../../../../../services/handlers';
import { reimbursementItemTypesMap } from '../EncountersSection/PaymentsSection/reimbursementConstants';

export default function useGridData(ssuPatientId, reimbursementId, editItemAllowed) {
  const [gridData, setGridData] = useState([]);

  const onDelete = useCallback(
    function(itemId) {
      ModalBoxes.confirm({
        content: 'Are you sure you want to delete this item?'
      }).then(
        () => {
          PatientReimbursementApi.deleteReimbursementItem(ssuPatientId, reimbursementId, itemId).then(function({
            data
          }) {
            setGridData(function(items) {
              return reject(items, ['id', itemId]);
            });
          },
          onRequestError);
        },
        () => {}
      );
    },
    [reimbursementId, ssuPatientId]
  );

  const columns = useMemo(
    () => [
      {
        field: 'updatedAt',
        headerName: 'Upload date',
        flex: 1,
        valueGetter({ value }) {
          return new Date(value);
        },
        renderCell({ value }) {
          return value ? moment(value).format(dateTime12) : '—';
        },
        type: 'date'
      },
      {
        field: 'type',
        headerName: 'Type',
        flex: 1,
        renderCell({ value }) {
          return value ? reimbursementItemTypesMap[value] : '—';
        }
      },
      {
        field: 'amount',
        headerName: 'Amount',
        flex: 1,
        renderCell({ value }) {
          return value ? `$${toBill(value)}` : '—';
        }
      },
      {
        field: 'files',
        headerName: 'Attachments',
        flex: 1,
        valueGetter({ value }) {
          return value?.length || 0;
        }
      },
      {
        field: 'note',
        headerName: 'Notes',
        flex: 1
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        renderCell({ row }) {
          return (
            <IconButton
              disabled={!editItemAllowed}
              aria-label="delete"
              onClick={function(e) {
                e.preventDefault();
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                onDelete(row.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          );
        }
      }
    ],
    [onDelete, editItemAllowed]
  );
  return [columns, gridData, setGridData];
}
