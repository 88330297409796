import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactTable from 'react-table';
import { find, isEmpty, isEqual } from 'lodash';
import { cloneDeep } from 'lodash/lang';
import moment from 'moment/moment';

import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import Icon from '../../../../../../common/general/Icon';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import HistoryBlock, { MEDICAL_HISTORY } from '../../../../../root/Container/HistoryBlock';
import ReactTableHoc from '../../../shared/Worklist/ReactTableHoc/ReactTableHoc';
import CellDropdown from '../Medications/CellDropdown';
import { usePatientProfile } from '../PatientProfileContext';
import { convertToFirstDay, showConfirmDialog } from '../service';
import { ReadOnlyCellTextField } from '../shared/ReadOnlyCellTextField/ReadOnlyCellTextField';
import ActionColumnContent from '../shared/TableActions/ActionColumnContent';
import TableDate from '../shared/TableDate/TableDate';
import { CellTextField } from '../shared/TableTextField/CellTextField';

import { MedicalHistoryAPI } from './MedicalHistoryAPI';

const newMedicalHistoryRecord = {
  diagnosis: null,
  startDate: '',
  endDate: '',
  onGoing: '',
  comments: '',
  id: null,
  newRow: true
};

export default function MedicalHistory() {
  const { patientId } = useParams();
  const [data, setData] = useState([]);
  const [diagnoses, setDiagnoses] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [notSavedRow, setNotSavedRow] = useState({});
  const { userCanEdit } = usePatientProfile();

  const getPatientMedicalHistory = useCallback(
    function() {
      MedicalHistoryAPI.getPatientMedicalHistory(patientId).then(result => {
        setData(result.data);
        setInitialData(result.data);
      });
    },
    [patientId]
  );

  const getDiagnoses = useCallback(function() {
    MedicalHistoryAPI.getAllDiagnoses().then(result => {
      setDiagnoses(result.data);
    });
  }, []);

  useEffect(() => {
    getPatientMedicalHistory();
    getDiagnoses();
  }, [getDiagnoses, getPatientMedicalHistory]);

  useEffect(
    function() {
      const unblock = HistoryBlock.block(MEDICAL_HISTORY, function(discard) {
        if (!isEqual(data, initialData)) {
          showConfirmDialog('medical history')
            .then(() => {
              discard();
            })
            .catch(() => {});
        } else {
          return true;
        }
        return false;
      });
      return function() {
        unblock();
      };
    },
    [data, initialData]
  );

  const columns = [
    {
      Header: 'Diagnosis',
      accessor: 'diagnosis',
      minWidth: 120,
      Cell: ({ original, value }) => {
        return userCanEdit ? (
          <CellDropdown value={value} onChange={e => onSelectChange(original, e, 'diagnosis')} options={diagnoses} />
        ) : (
          <ReadOnlyCellTextField value={value?.name} />
        );
      }
    },
    {
      Header: 'Start Date',
      accessor: 'startDate',
      minWidth: 100,
      Cell: ({ value, original }) => {
        return userCanEdit ? (
          <TableDate
            value={value}
            placeholder={'Start date'}
            onChange={(e, formattedDate) => {
              onSelectChange(original, formattedDate, 'startDate');
            }}
          />
        ) : (
          <ReadOnlyCellTextField value={value} />
        );
      }
    },
    {
      Header: 'End Date',
      accessor: 'endDate',
      minWidth: 100,
      Cell: ({ value, original }) => {
        return userCanEdit ? (
          <TableDate
            value={value}
            placeholder={'End date'}
            onChange={(e, formattedDate) => {
              onSelectChange(original, formattedDate, 'endDate');
            }}
          />
        ) : (
          <ReadOnlyCellTextField value={value} />
        );
      }
    },
    {
      Header: 'Ongoing',
      accessor: 'onGoing',
      minWidth: 80,
      Cell: ({ original, value }) => {
        return userCanEdit ? (
          <CellDropdown
            value={getOngoingValue(value)}
            isDisabled={!isEmpty(original.endDate)}
            onChange={e => {
              const value = e.name === 'Yes';
              onSelectChange(original, value, 'onGoing');
            }}
            options={[
              { id: 1, name: 'Yes' },
              { id: 2, name: 'No' }
            ]}
          />
        ) : (
          <ReadOnlyCellTextField value={getOngoingValue(value)?.name} />
        );
      }
    },
    {
      Header: 'Comments',
      accessor: 'comments',
      minWidth: 80,
      Cell: ({ original, value }) => {
        return userCanEdit ? (
          <CellTextField
            placeholder={'Enter Comment'}
            onChange={e => {
              onSelectChange(original, e.target.value, 'comments');
            }}
            value={value}
          />
        ) : (
          <ReadOnlyCellTextField value={value} />
        );
      }
    }
  ];

  if (userCanEdit) {
    columns.push({
      Header: 'Actions',
      width: 70,
      Cell: ({ original }) => {
        return (
          <ActionColumnContent
            onSaveButtonClick={() => saveRowData(original)}
            saveButtonDisabled={checkIfRowCanBeSaved(original)}
            onDeleteButtonClick={() => deleteMedication(original)}
          />
        );
      }
    });
  }

  return (
    <div className="patient-profile-medications">
      <div className="patient-profile-medications-header">
        <h5>Medical History</h5>
        {userCanEdit && (
          <Button id="patient-profile-add-history-btn" onClick={addNewRow} priority="medium" size="h28">
            <Icon>add</Icon> Add
          </Button>
        )}
      </div>
      <div className="patient-profile-medications-table">
        <ReactTableHoc>
          <ReactTable
            data={data}
            columns={columns}
            minRows={1}
            showPagination={false}
            nextText=">>"
            previousText="<<"
            noDataText="No Record Found"
            pages={1}
            manual
          />
        </ReactTableHoc>
      </div>
    </div>
  );

  function deleteMedication(row) {
    ModalBoxes.confirm({
      content: 'Are you sure you want to delete this medical history entry?',
      confirmButton: 'Yes',
      cancelButton: 'Cancel'
    })
      .then(() => {
        if (!row.newRow) {
          MedicalHistoryAPI.removePatientMedicalHistoryRecord(patientId, row.id).then(() => {
            getPatientMedicalHistory();
          });
        } else {
          setNotSavedRow({});
          setData(prevState => prevState.filter(item => !item.newRow));
        }
      })
      .catch(() => {});
  }

  function addNewRow() {
    if (isEmpty(notSavedRow)) {
      setData(prevState => [...prevState, newMedicalHistoryRecord]);
      setNotSavedRow(newMedicalHistoryRecord);
    } else {
      NotificationManager.error('Please save your previous changes');
    }
  }

  function saveRowData(row) {
    const payload = cloneDeep(row);
    if (!isEmpty(payload.endDate) && payload.onGoing) {
      payload.onGoing = false;
    }
    if (validateMedicationData(payload)) {
      if (payload.newRow) {
        MedicalHistoryAPI.addPatientMedicalHistoryRecord(patientId, payload)
          .then(result => {
            if (result.status === 200) {
              setNotSavedRow({});
              getPatientMedicalHistory();
            }
          })
          .catch(error => NotificationManager.error(error?.response?.data?.message));
      } else {
        MedicalHistoryAPI.editPatientMedicalHistoryRecord(patientId, payload)
          .then(result => {
            if (result.status === 200) {
              setNotSavedRow({});
              getPatientMedicalHistory();
            }
          })
          .catch(error => NotificationManager.error(error?.response?.data?.message));
      }
    }
  }

  function getOngoingValue(value) {
    if (value === true) {
      return { id: 1, name: 'Yes' };
    }
    if (value === false) {
      return { id: 2, name: 'No' };
    } else {
      return null;
    }
  }

  function onSelectChange(row, newValue, key) {
    setData(function(prevState) {
      return prevState.map(obj => (obj.id === row.id ? { ...row, [key]: newValue } : obj));
    });
  }

  function checkIfRowCanBeSaved(row) {
    if (!row.id) return false;
    return isEqual(find(data, { id: row.id }), find(initialData, { id: row.id }));
  }

  function validateMedicationData(row) {
    const { startDate, endDate, onGoing, diagnosis, comments } = row;
    if (endDate && !startDate) {
      NotificationManager.error('If End date is entered, then Start date is required.');
      return false;
    }
    if (startDate && isEmpty(endDate) && !onGoing) {
      NotificationManager.error('If Start date is entered, then End date or Ongoing is required.');
      return false;
    }
    if (isEmpty(diagnosis)) {
      NotificationManager.error('Diagnosis is required.');
      return false;
    }
    if (
      moment(convertToFirstDay(startDate), 'DD/MMM/YYYY').isAfter(moment(convertToFirstDay(endDate), 'DD/MMM/YYYY'))
    ) {
      NotificationManager.error('End Date cannot be before Start Date is required.');
      return false;
    }
    if (diagnosis.name === 'See Comments' && !comments) {
      NotificationManager.error('A comment must be entered when See Comments is selected.');
      return false;
    }

    return true;
  }
}
