import React, { useCallback, useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { sortBy } from 'lodash';
import { isEqual } from 'lodash/lang';

import { ProtocolApi, StudyApi } from '../../../api';
import { afterburnerApi } from '../../../api/patient/AftrburnerApi';
import Common from '../../../common/common';
import Section from '../../../common/data-display/Section/Section';
import Checkbox from '../../../common/data-entry/InputSelectors/Checkbox';
import Select from '../../../common/data-entry/Select';
import Button from '../../../common/general/Button';
import useSessionStorage from '../../../common/hooks/useSessionStorage';
import NotificationManager from '../../../common/notifications/NotificationManager';
import { SOMETHING_WENT_WRONG } from '../../../constants/notificationMessages';
import { PageInfoHeader } from '../../PageInfoHeader/PageInfoHeader';
import {
  createNewMapping,
  editMapping,
  resolveCellValue
} from '../setup/Protocol/EncounterEpochTableTemplate/EncountersHeaders/MappingSetupModal/MappingSetupServices';

import './DataMapping.scss';
const DATA_MAPPING_TABLE_DATA = 'DATA_MAPPING_TABLE_DATA';
const DataMapping = () => {
  const [sessionStorage, setSessionStorage] = useSessionStorage(DATA_MAPPING_TABLE_DATA, {});
  const [totalElementsCount, setTotalElementsCount] = useState(0);
  const [studyList, setStudyList] = useState([]);
  const [protocolList, setProtocolList] = useState([]);
  const [platformList, setPlatformList] = useState([]);
  const [mappingList, setMappingList] = useState([]);

  const [filters, setFilters] = useState({
    study: null,
    protocol: null,
    platform: null,
    sorting: [{ id: 'platformName', desc: false }],
    page: 0,
    pageSize: sessionStorage.pageSize || 15,
    totalPages: 0
  });

  const loadTemplates = useCallback(() => {
    if (filters.study || filters.protocol || filters.platform) {
      const filtersForRequest = {
        studyId: filters.study?.id ?? null,
        protocolId: filters.protocol?.id ?? null,
        platformId: filters.platform?.id ?? null,
        sortingDesc: filters.sorting[0].desc,
        sortingId: filters.sorting[0].id,
        page: filters.page,
        pageSize: filters.pageSize
      };
      afterburnerApi
        .getTemplatesByFilter(filtersForRequest)
        .then(({ data }) => {
          setMappingList(data.content);
          setTotalElementsCount(data.totalElements);
          setFilters(filters => ({ ...filters, totalPages: data?.totalPages ?? null }));
        })
        .catch(() => {
          NotificationManager.error(SOMETHING_WENT_WRONG);
        });
    } else {
      setMappingList([]);
      setTotalElementsCount(0);
    }
  }, [filters.study, filters.protocol, filters.platform, filters.sorting, filters.page, filters.pageSize]);

  useEffect(() => {
    StudyApi.populateStudyList()
      .then(({ data: { response } }) => {
        const preparedStudies = response.map(d => ({
          id: d.uniqueIdentifier,
          name: d.studyName,
          groupAssign: d.groupAssign
        }));
        setStudyList(sortBy(preparedStudies, ['name']));
      })
      .catch(() => {
        NotificationManager.error(SOMETHING_WENT_WRONG);
      });
    afterburnerApi
      .getAllPlatformsConfiguration()
      .then(({ data }) => {
        const preparedPlatforms = data.map(d => ({
          id: d.id,
          name: d.platformName
        }));
        setPlatformList(sortBy(preparedPlatforms, ['name']));
      })
      .catch(() => {
        NotificationManager.error(SOMETHING_WENT_WRONG);
      });
  }, []);

  useEffect(() => {
    if (filters.study?.id) {
      ProtocolApi.getProtocols(filters.study.id).then(
        ({ data: { response } }) => {
          const dataForMap = response === 'No Protocol found' ? [] : response;
          const preparedProtocolList = dataForMap.map(protocol => ({
            id: protocol.uniqueIdentifier,
            name: `${protocol.name} ${protocol.version}`
          }));
          setProtocolList(preparedProtocolList);
        },
        () => {
          NotificationManager.error(SOMETHING_WENT_WRONG);
        }
      );
      setFilters(prevState => ({ ...prevState, protocol: null }));
    }
  }, [filters.study]);

  useEffect(() => {
    loadTemplates();
  }, [loadTemplates]);

  const columns = [
    {
      Header: 'Platform ',
      accessor: 'platformName',
      minWidth: 200,
      Cell: row => {
        return resolveCellValue(row.original.platformName);
      }
    },
    {
      Header: 'Platform Endpoint ',
      accessor: 'platformConfigurationUrl',
      minWidth: 200,
      Cell: row => {
        return resolveCellValue(row.original.platformConfigurationUrl);
      }
    },
    {
      Header: 'Study',
      accessor: 'studyName',
      minWidth: 200,
      Cell: row => {
        return resolveCellValue(row.original.studyName);
      }
    },
    {
      Header: 'Protocol Version',
      accessor: 'protocolVersion',
      minWidth: 100,
      Cell: row => {
        return resolveCellValue(row.original.protocolVersion);
      }
    },
    {
      Header: 'Mapping Name',
      accessor: 'mappingName',
      minWidth: 200,
      Cell: row => {
        return resolveCellValue(row.original.mappingName);
      }
    },
    {
      Header: 'Trigger',
      accessor: 'triggerName',
      minWidth: 200,
      Cell: row => {
        return resolveCellValue(row.original.triggerName);
      }
    },
    {
      Header: 'Last Updated By',
      accessor: 'lastUpdatedBy',
      minWidth: 200,
      Cell: row => {
        return resolveCellValue(row.original.lastUpdatedBy);
      }
    },
    {
      Header: 'Last Updated Date',
      accessor: 'lastUpdatedDate',
      minWidth: 200,
      Cell: row => {
        return resolveCellValue(Common.formatDate(row.original.lastUpdatedDate, 'DD/MMM/YYYY'));
      }
    },
    {
      Header: 'Enable',
      accessor: 'enable',
      maxWidth: 65,
      Cell: row => {
        return <Checkbox checked={row.original.enable} onChange={() => onToggleEnable(row)} />;
      }
    },
    {
      Header: 'Edit',
      width: 65,
      sortable: false,
      Cell: row => {
        return (
          <Button size="h28" onClick={() => onEditClick(row)}>
            Edit
          </Button>
        );
      }
    }
  ];

  const onEditClick = useCallback(
    row => {
      editMapping(
        false,
        null,
        loadTemplates,
        {
          platform: {
            value: row.original.platformConfigurationId,
            name: row.original.platformName
          },
          endpoint: {
            value: row.original.platformConfigurationUrlId,
            name: row.original.platformConfigurationUrl
          },
          study: {
            id: row.original.studyId,
            name: row.original.studyName,
            groupAssign: studyList.find(study => study.id === row.original.studyId).groupAssign
          },
          protocol: {
            id: row.original.protocolVersionId,
            name: row.original.protocolVersion
          },
          epoch: row.original?.encounterId ? { id: row.original?.epochId, name: row.original.epochName } : null,
          encounter: row.original?.encounterId
            ? { id: row.original?.encounterId, name: row.original.mappingName }
            : null,
          itemGroup: row.original?.itemGroupKey
            ? { id: row.original?.itemGroupKey, name: row.original.mappingName }
            : null
        },
        studyList
      );
    },
    [loadTemplates, studyList]
  );

  const onToggleEnable = useCallback(
    row => {
      afterburnerApi
        .changeEnableForMapping(row.original.platformId, !row.original.enable)
        .then(() => {
          loadTemplates();
        })
        .catch(() => {
          NotificationManager.error(SOMETHING_WENT_WRONG);
        });
    },
    [loadTemplates]
  );

  const onPageSizeChange = useCallback(
    pageSize => {
      let newFilters = { ...filters, pageSize };
      if (filters.page * pageSize > totalElementsCount) {
        newFilters = { ...newFilters, page: 0 };
      }
      setFilters(newFilters);
      setSessionStorage({ pageSize: pageSize });
    },
    [filters, setSessionStorage, totalElementsCount]
  );

  const onPageChange = useCallback(page => {
    setFilters(prevState => ({ ...prevState, page: page >= 0 ? page : 0 }));
  }, []);

  const onSortedChange = useCallback(sortedBy => {
    setFilters(prevState => ({ ...prevState, sorting: sortedBy }));
  }, []);

  const onTableFilterChange = useCallback(
    (newValue, keyForUpdate) => {
      if (!isEqual(newValue, filters[keyForUpdate])) {
        if (!newValue) {
          setFilters(prevState => ({ ...prevState, [keyForUpdate]: null, page: 0 }));
        } else {
          setFilters(prevState => ({ ...prevState, [keyForUpdate]: newValue, page: 0 }));
        }
      }
    },
    [filters]
  );

  return (
    <>
      <PageInfoHeader
        right={
          <Button size="h28" onClick={() => createNewMapping(studyList, loadTemplates)}>
            + Mapping
          </Button>
        }
      >
        <div className="general-header-wrapper">
          <Select
            dataSource={studyList}
            value={filters.study}
            onChange={newValue => {
              if (!isEqual(newValue, filters.study)) {
                if (!newValue) {
                  setProtocolList([]);
                  setFilters(prevState => ({ ...prevState, study: null, protocol: null, page: 0 }));
                } else {
                  setFilters(prevState => ({ ...prevState, study: newValue, protocol: null, page: 0 }));
                }
              }
            }}
            label="Study"
            searchable={true}
            closeOnSelectedOptionClick={false}
            deselectOnSelectedOptionClick={false}
            clearable={true}
            validate={false}
            data-testid="study-dropdown"
          />
          <Select
            dataSource={protocolList}
            value={filters.protocol}
            onChange={newValue => {
              onTableFilterChange(newValue, 'protocol');
            }}
            label="Protocol"
            searchable={true}
            closeOnSelectedOptionClick={false}
            deselectOnSelectedOptionClick={false}
            clearable={true}
            validate={false}
          />
          <Select
            dataSource={platformList}
            value={filters.platform}
            onChange={newValue => {
              onTableFilterChange(newValue, 'platform');
            }}
            label="Platform"
            searchable={true}
            closeOnSelectedOptionClick={false}
            deselectOnSelectedOptionClick={false}
            clearable={true}
            validate={false}
          />
        </div>
      </PageInfoHeader>

      <Section className="data-mapping-page">
        <div className="data-mapping-table">
          <ReactTable
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onSortedChange={onSortedChange}
            data={mappingList}
            columns={columns}
            minRows={1}
            multiSort={false}
            showPagination
            nextText=">>"
            previousText="<<"
            noDataText="No Record Found"
            pageSizeOptions={[15, 25, 50, 100]}
            pageSize={filters.pageSize}
            page={filters.page}
            pages={filters.totalPages}
            sorted={filters.sorting}
            manual
          />
        </div>
      </Section>
    </>
  );
};

export default DataMapping;
