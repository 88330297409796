import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import cx from 'classnames';
import { maxBy } from 'lodash/math';
import moment from 'moment';

import { ItemGroupApi } from '../../../../../../api';
import Common from '../../../../../../common/common';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import { onRequestError } from '../../../../../../services/handlers';
import DndPopup from '../../EncounterDescNew/DndPopup/DndPopup';
import GetDomainDetailElements from '../../EncounterDescNew/getDomainDetailElements';
import { withNewEncounterPageContext } from '../NewEncounterPageContext';

import { InformedConsentContext } from './InformedConsentContext';
import InformedConsentFile from './InformedConsentFile';

import './InformedConsent.scss';
function InformedConsent({ metaData, selectedItemGroup, onSubmit }) {
  const [informedConsentForm, setInformedConsentForm] = useState(null);
  const [completedForms, setCompletedForms] = useState([]);
  const [applicable, setApplicable] = useState(false);
  useEffect(
    function() {
      setApplicable(selectedItemGroup.completePercentage !== '0.0' && selectedItemGroup.skipped !== 1);
    },
    [selectedItemGroup]
  );
  const { ssuPatientId, patientEncounterId, patientItemGroupId } = useParams();

  const Context = useContext(InformedConsentContext);

  const { completedIcfForms, allIcfForms } = Context;

  useEffect(
    function() {
      if (completedIcfForms && selectedItemGroup?.informedConsentTemplate && allIcfForms) {
        const typeId = selectedItemGroup.informedConsentTemplate?.typeId;
        const languageId = selectedItemGroup.informedConsentTemplate?.languageId;
        const forms = allIcfForms.filter(e => e.typeId === typeId && e.languageId === languageId);
        const completedIcfForm = completedIcfForms.find(
          f => selectedItemGroup.formUniqueIdentifier === f.sitePatientItemGroupId
        );
        if (forms) {
          if (completedIcfForm) {
            setInformedConsentForm(forms.find(f => f.id === completedIcfForm.informedConsentFormId));
          } else {
            const form = maxBy(forms, a => moment(a.uploadDate).format('x'));
            if (form && !completedIcfForms?.some(e => e?.informedConsentFormId === form?.id)) {
              setInformedConsentForm(form);
            } else {
              setInformedConsentForm(null);
            }
          }
        } else {
          setInformedConsentForm(null);
        }

        setCompletedForms(
          completedIcfForms
            ?.filter(e => e.completedIcfFile)
            ?.filter(
              form =>
                form.languageId === selectedItemGroup.informedConsentTemplate?.languageId &&
                form.typeId === selectedItemGroup.informedConsentTemplate?.typeId
            )
        );
      }
    },
    [completedIcfForms, selectedItemGroup, allIcfForms]
  );

  const isNewForm =
    selectedItemGroup &&
    selectedItemGroup.completePercentage === '0.0' &&
    selectedItemGroup.skipped === 0 &&
    informedConsentForm?.active &&
    !completedForms?.some(e => e.informedConsentFormId === informedConsentForm.id);

  return (
    <div className={'informed-consent px-3 pb-2'}>
      {!applicable && (
        <div>
          {informedConsentForm && (
            <div className={'mb-2'}>
              <strong>{`Select the updated ICF version to review in a new tab. `}</strong>
              <span>If a new signature is required, print the new form for patient signature</span>
            </div>
          )}
          <div className={cx('icf-info-bar p-3', { highlighted: isNewForm, yellow: informedConsentForm })}>
            <div className={'icf-info-bar-content'}>
              {informedConsentForm && (
                <>
                  {isNewForm && <div className={'icf-info-bar-new mr-3'}>NEW</div>}
                  <InformedConsentFile informedConsentForm={informedConsentForm} ssuId={informedConsentForm.ssuId} />
                </>
              )}
              <div className={'icf-info-bar-actions'}>
                <strong>Is this ICF applicable for the patient?</strong>
                <Button size={'h28'} className={'ml-2'} onClick={() => setApplicable(true)}>
                  Applicable
                </Button>
                <Button
                  size={'h28'}
                  className={'ml-2'}
                  disabled={selectedItemGroup?.skipped === 1}
                  onClick={() => {
                    const modalBox = ModalBoxes.open({
                      title: `Reason ${
                        selectedItemGroup.elementName ? selectedItemGroup.elementName : 'Procedure'
                      } was not applicable`,
                      component: (
                        <DndPopup
                          onSave={data => {
                            const request = {
                              ssuPatientId: ssuPatientId,
                              ssuPatientEncounterId: patientEncounterId,
                              itemGroupId: patientItemGroupId,
                              reason: data.comment ? data.comment.trim() : '',
                              informedConsentFormId: informedConsentForm?.id
                            };
                            ItemGroupApi.setItemGroupNotPerformedStatus(request).then(() => {
                              onSubmit();
                              modalBox.close();
                            }, onRequestError);
                          }}
                        />
                      )
                    });
                  }}
                >
                  Not Applicable
                </Button>
              </div>
            </div>
          </div>
          {completedForms && completedForms.length > 0 && (
            <div>
              <h5 className={'mt-3'}>Completed Informed Consents</h5>
              <div className={'icf-completed-bar p-3'}>
                <div className={'auto-size-container'}>
                  <div className={'icf-completed-item'}>
                    <strong>ICF Name</strong>
                    <strong>Date Signed by Patient</strong>
                  </div>
                  {completedForms.map(form => (
                    <div className={'icf-completed-item'}>
                      <InformedConsentFile
                        informedConsentForm={form}
                        icfAnswerId={form.id}
                        ssuId={metaData.ssuId}
                        userFile={true}
                      />
                      <div>{Common.formatDate(form.dateSignedByPatient)}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {applicable !== null && applicable === true && (
        <>
          {informedConsentForm && (
            <div className={cx('icf-info-bar p-3 mb-2', { highlighted: isNewForm, yellow: informedConsentForm })}>
              <div className={'icf-info-bar-content'}>
                <>
                  {isNewForm && <div className={'icf-info-bar-new mr-3'}>NEW</div>}
                  <InformedConsentFile informedConsentForm={informedConsentForm} ssuId={informedConsentForm.ssuId} />
                </>
              </div>
            </div>
          )}
          <GetDomainDetailElements inheritedComponent informedConsentForm={informedConsentForm} onSubmit={onSubmit} />
        </>
      )}
    </div>
  );
}

export default withNewEncounterPageContext(InformedConsent);
