import React from 'react';
import PropTypes from 'prop-types';

import { IItemGroups } from '../types';

import './ItemGroupsEncountersTableSidebar.scss';
export default function ItemGroupsEncountersTableSidebar({ rowKey, itemGroups, RowTitleCell }) {
  if (!itemGroups) return null;
  return (
    <div className="item-groups-encounters-table-sidebar">
      {itemGroups.map(function(itemGroup) {
        return (
          <div className="item-groups-encounters-table-sidebar-item-group" key={itemGroup[rowKey]}>
            <RowTitleCell itemGroup={itemGroup} />
          </div>
        );
      })}
    </div>
  );
}

ItemGroupsEncountersTableSidebar.propTypes = {
  itemGroups: IItemGroups,
  RowTitleCell: PropTypes.func
};
