import { useMemo } from 'react';
import { Autocomplete, TextField, Unstable_Grid2 as Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash/lang';
import { get } from 'lodash/object';

import { countries, getCountryById, getRegion, getRegions } from '../../../../../../../../../services/geographic';
import { normalizeZipCode } from '../../../../../../../../../services/normalizers';
import AddressValidator from '../AddressValidator';
import {
  useMileageAddressesActions,
  useMileageAddressesState
} from '../MileageAddressesContext/MileageAddressesContext';

import Address1Autocomplete from './Address1Autocomplete/Address1Autocomplete';
import CityAutocomplete from './CityAutocomplete/CityAutocomplete';

export default function AddressFields({ path }) {
  const { mileageAddresses, syncing } = useMileageAddressesState(),
    { setAddressField, setCountryId, setCity } = useMileageAddressesActions();
  const { touched, errors, handleBlur } = useFormikContext();
  const data = get(mileageAddresses, path);

  const [country, regions] = useMemo(
    function() {
      return [getCountryById(data.countryId) || '', getRegions(data.countryId)];
    },
    [data.countryId]
  );

  const region = useMemo(
    function() {
      return data.countryId ? getRegion(data.regionId, data.countryId) || '' : '';
    },
    [data.countryId, data.regionId]
  );

  return (
    <>
      <Grid xs={4}>
        <Autocomplete
          value={country}
          onChange={function({ target }, country) {
            setCountryId(path, country?.id || '');
          }}
          onBlur={function(e) {
            AddressValidator.revalidate(path);
            handleBlur(e);
          }}
          fullWidth
          getOptionKey={option => option.id}
          getOptionLabel={option => option.name ?? option}
          disablePortal
          options={countries}
          renderInput={params => (
            <TextField
              {...params}
              name={`mileageAddresses.${path}.countryId`}
              label="Country"
              variant="standard"
              required
              error={getErrorBoolean('countryId')}
              helperText={getHelperText('countryId')}
              autoComplete="off"
            />
          )}
        />
      </Grid>
      <Grid xs={4}>
        <Autocomplete
          disabled={isEmpty(regions)}
          value={region}
          onChange={function({ target }, region) {
            setAddressField(`mileageAddresses.${path}.regionId`, region?.id || '');
          }}
          onBlur={function(e) {
            AddressValidator.revalidate(path);
            handleBlur(e);
          }}
          fullWidth
          getOptionKey={option => option.id}
          getOptionLabel={option => option.name ?? option}
          disablePortal
          options={regions}
          renderInput={params => (
            <TextField
              {...params}
              name={`mileageAddresses.${path}.regionId`}
              label={data.countryId === 'CA' ? 'Province' : 'State'}
              variant="standard"
              required
              error={getErrorBoolean('regionId')}
              helperText={
                getHelperText('regionId')
                  ? (data.countryId === 'CA' ? 'Province' : 'State') + getHelperText('regionId')
                  : null
              }
              autoComplete="off"
            />
          )}
        />
      </Grid>
      <Grid xs={4}>
        <CityAutocomplete
          name={`mileageAddresses.${path}.city`}
          countryId={data.countryId}
          regionId={data.regionId}
          city={data.city}
          onChange={function(value) {
            setCity(path, value);
          }}
          onBlur={function(e) {
            AddressValidator.revalidate(path);
            handleBlur(e);
          }}
          error={getErrorBoolean('city')}
          helperText={getHelperText('city')}
        />
      </Grid>
      <Grid xs={6}>
        <Address1Autocomplete
          name={`mileageAddresses.${path}.address1`}
          countryId={data.countryId}
          regionId={data.regionId}
          city={data.city}
          address1={data.address1}
          valid={data.valid}
          onChange={function(value) {
            setAddressField(`mileageAddresses.${path}.address1`, value || '');
          }}
          onBlur={function(e) {
            AddressValidator.revalidate(path);
            handleBlur(e);
          }}
          error={getErrorBoolean('address1')}
          helperText={getHelperText('address1')}
        />
      </Grid>
      <Grid xs={3}>
        <TextField
          name={`mileageAddresses.${path}.address2`}
          value={data.address2 || ''}
          onChange={function({ target }) {
            setAddressField(target.name, target.value || '');
          }}
          onBlur={handleBlur}
          error={getErrorBoolean('address2')}
          helperText={getHelperText('address2')}
          fullWidth
          label="Address 2"
          variant="standard"
          autoComplete="off"
        />
      </Grid>
      <Grid xs={3}>
        <TextField
          name={`mileageAddresses.${path}.zipCode`}
          value={data.zipCode || ''}
          onChange={function({ target }) {
            setAddressField(target.name, normalizeZipCode(target.value, data.countryId === 'US'));
          }}
          onBlur={function(e) {
            AddressValidator.revalidate(path);
            handleBlur(e);
          }}
          error={getErrorBoolean('zipCode')}
          helperText={getHelperText('zipCode')}
          fullWidth
          label="Zip"
          variant="standard"
          required
          autoComplete="off"
        />
      </Grid>
    </>
  );
  function getErrorBoolean(fieldName) {
    const fieldTouched = get(touched, `mileageAddresses.${path}.${fieldName}`),
      fieldError = get(errors, `mileageAddresses.${path}.${fieldName}`);
    return !syncing && fieldTouched && Boolean(fieldError);
  }
  function getHelperText(fieldName) {
    const fieldTouched = get(touched, `mileageAddresses.${path}.${fieldName}`),
      fieldError = get(errors, `mileageAddresses.${path}.${fieldName}`);
    return !syncing && fieldTouched && fieldError;
  }
}
