import axios from 'axios';

const ROOT = 'api/v1/afterburner';
const ROOT_EDC = 'api/v1/edc/mapping/template';
const ROOT_PROTOCOL = 'api/v1/setup';

export const afterburnerApi = {
  sendToAfterburnerFromItemGroupPage(ssuPatientId, sitePatientItemGroupId) {
    return axios.post(`${ROOT}/ssu-patients/${ssuPatientId}/itemGroups/${sitePatientItemGroupId}`);
  },
  allMappingsForEncounter({ protocolIdentity, protocolEncounterKey, platformId, platformUrlId }) {
    return axios.get(
      `${ROOT}/protocols/${protocolIdentity}/encounters/${protocolEncounterKey}/platforms/${platformId}/urls/${platformUrlId}`
    );
  },
  allMappingsForItemGroup({ protocolIdentity, itemGroupKey, platformId, platformUrlId }) {
    return axios.get(
      `${ROOT}/protocols/${protocolIdentity}/itemGroups/${itemGroupKey}/platforms/${platformId}/urls/${platformUrlId}`
    );
  },
  getMappingTemplateConfiguration(protocolIdentity) {
    return axios.get(`${ROOT_EDC}/protocols/${protocolIdentity}/data`);
  },
  getMappingTemplateConfigurationById(templateId) {
    return axios.get(`${ROOT_EDC}/${templateId}`);
  },
  getEncounterMappingTemplateData(encounterId, templateId) {
    return axios.get(`${ROOT_EDC}/${templateId}/encounters/${encounterId}`);
  },
  getItemGroupMappingTemplateData(itemGroupKey, templateId) {
    return axios.get(`${ROOT_EDC}/${templateId}/itemGroup/${itemGroupKey}`);
  },
  saveTemplate(template) {
    return axios.post(`${ROOT_EDC}/save`, template);
  },
  preview(request) {
    return axios.post(`${ROOT}/preview`, request);
  },
  saveAfterburnerMapping(request) {
    return axios.put(`${ROOT}/save`, request);
  },
  getAllPlatformsConfiguration() {
    return axios.get(`${ROOT}/platform`);
  },
  getPlatformConfiguration(id) {
    return axios.get(`${ROOT}/platform/${id}`);
  },
  postNewPlatformConfiguration(request) {
    return axios.post(`${ROOT}/platform/save`, request);
  },
  updatePlatformConfiguration(request) {
    return axios.put(`${ROOT}/platform/update`, request);
  },
  deletePlatformConfiguration(id) {
    return axios.delete(`${ROOT}/platform/delete/${id}`);
  },
  checkPossibilityDeleteUrl(id) {
    return axios.post(`${ROOT}/platform/delete/url/${id}/check`);
  },
  testAuthentication(request) {
    return axios.post(`${ROOT}/platform/authentication/check`, request);
  },
  getTemplatesByProtocol(protocolId) {
    return axios.get(`${ROOT_EDC}/protocols/${protocolId}`);
  },
  changeEnableForMapping(platformId, enable) {
    return axios.post(`${ROOT}/mappings/enable/${platformId}/${enable}`);
  },
  getTemplatesByFilter(request) {
    return axios.post(`${ROOT}/mappings`, request);
  },
  getEpochList(studyId, protocolId) {
    return axios.get(`${ROOT_PROTOCOL}/studies/${studyId}/protocols/${protocolId}/epoch-names`);
  },
  getEncounterMappingNames(studyId, protocolId, epochId) {
    return axios.get(`${ROOT_PROTOCOL}/studies/${studyId}/protocols/${protocolId}/epochs/${epochId}/encounter-names`);
  },
  getEncounterNamesInLogs(studyId, protocolId) {
    return axios.get(`${ROOT_PROTOCOL}/studies/${studyId}/protocols/${protocolId}/encounter-names`);
  },
  getItemGroupMappingNames(studyId, protocolId) {
    return axios.get(`${ROOT_PROTOCOL}/studies/${studyId}/protocols/${protocolId}/item-group-names`);
  },
  getPlatformNamesByFilters(request) {
    return axios.post(`${ROOT}/platform/platform-names`, request);
  },
  getLogDetailsByLogId(logId) {
    return axios.get(`${ROOT}/logs/${logId}/log-details`);
  },
  getRequestLogsByIds(request) {
    return axios.post(`${ROOT}/logs`, request);
  }
};
