export const defaultEventIdViewAndSort = {
  sortMethod: (a, b) => {
    if (a.id === b.id) {
      return a.sequence > b.sequence ? 1 : -1;
    } else {
      return a.id > b.id ? 1 : -1;
    }
  },
  Cell: ({ value }) => {
    if (value.sequence === 0) {
      return value.id;
    }
    return `${value.id}.${value.sequence}`;
  }
};
