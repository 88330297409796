import TasksSupervisor from './TasksSupervisor/TasksSupervisor';

export default function openKeepIsHidden(taskId, ssuId) {
  let openedTaskMap = JSON.parse(sessionStorage.getItem('OPENED_TASK_MAP'));
  if (!openedTaskMap) {
    openedTaskMap = {};
  }
  const openedTaskKey = !!openedTaskMap[taskId + ssuId];

  TasksSupervisor.open(taskId, ssuId, openedTaskKey);

  setTimeout(function() {
    if (!openedTaskKey) {
      openedTaskMap[taskId + ssuId] = true;
      sessionStorage.setItem('OPENED_TASK_MAP', JSON.stringify(openedTaskMap));
    }
  }, 50);
}
