import React, { useEffect, useState } from 'react';
import { isEmpty, isEqual } from 'lodash/lang';

import { UserApiApi } from '../../../../api';
import MultiSelect from '../../../../common/data-entry/MultiSelect/MultiSelect';

import { prepareAssignees } from './TaskWorklistService';

const TaskAssigneeMultiSelect = ({ disabled, studySiteIds, onChangeAssignees, initialAssignees }) => {
  const [allAssignees, setAllAssignees] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState(null);

  const updateSelectedAssignee = (values, updateSource) => {
    if (!isEqual(values, selectedAssignee)) {
      setSelectedAssignee(values);
      onChangeAssignees(values);
      updateSource && setAllAssignees([...allAssignees]);
    }
  };
  useEffect(
    function() {
      if (!isEmpty(studySiteIds) && !disabled) {
        UserApiApi.getPersonnelBySsu(studySiteIds).then(res => {
          setAllAssignees(prepareAssignees(res.data));
        });
      }
      if (isEmpty(studySiteIds)) {
        setAllAssignees([]);
        updateSelectedAssignee(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [studySiteIds, disabled]
  );

  useEffect(() => {
    if (!isEmpty(allAssignees)) {
      let newList = [];
      if (!isEmpty(initialAssignees)) {
        newList = allAssignees.filter(e => initialAssignees.includes(e.id));
      }
      updateSelectedAssignee(newList, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAssignees, initialAssignees]);

  return (
    <MultiSelect
      label="Assignee"
      disabled={disabled}
      clearSearchOnSelection
      dataSource={!disabled ? allAssignees : []}
      onChange={updateSelectedAssignee}
      searchable
      value={selectedAssignee}
      validate={false}
    />
  );
};

export default TaskAssigneeMultiSelect;
