import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { FileLoader } from './FileUploadComponents';
import { FileUploadContextProvider } from './FileUploadContextProvider';
import { FileUploadTab } from './FileUploadTab';
import { Instructions } from './InstructionComponents';
import { InstructionTab } from './InstructionTab';

import 'react-tabs/style/react-tabs.css';
import './PatientContent.scss';

export default function PatientContentTabs() {
  return (
    <div className={'patient-content'}>
      <FileUploadContextProvider>
        <Tabs>
          <TabList>
            <Tab>
              <InstructionTab />
            </Tab>
            <Tab>
              <FileUploadTab />
            </Tab>
          </TabList>

          <TabPanel>
            <Instructions />
          </TabPanel>
          <TabPanel>
            <FileLoader />
          </TabPanel>
        </Tabs>
      </FileUploadContextProvider>
    </div>
  );
}

export function stopEventAnd(eventHandler) {
  return e => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    eventHandler();
  };
}
