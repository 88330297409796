import axios from 'axios';

const ROOT = 'api/v1/finance/budget';

const OptionalReviewApi = {
  makeItemGroupReviewOptional(budgetId, encounterId, rowKey) {
    return axios.post(`${ROOT}/${budgetId}/optional-review`, undefined, {
      hideLoader: true,
      params: { budgetId, encounterId, rowKey }
    });
  },
  makeItemGroupReviewRequired(budgetId, encounterId, rowKey) {
    return axios.delete(`${ROOT}/${budgetId}/optional-review`, {
      hideLoader: true,
      params: { budgetId, encounterId, rowKey }
    });
  }
};

export default OptionalReviewApi;
