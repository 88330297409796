import React from 'react';

import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';

import './ItemGroupTemplatePreviewModal.scss';
export default function ItemGroupTemplatePreviewModal({ modalBox, header, renderFormData }) {
  return (
    <>
      <ModalBoxes.Body>{renderFormData}</ModalBoxes.Body>
    </>
  );
}

ItemGroupTemplatePreviewModal.className = 'item-group-preview-modal';
