import CloseIcon from '@mui/icons-material/Close';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';
import { DesktopDatePicker, DesktopTimePicker } from '@mui/x-date-pickers';
import PatientTravelApi from 'api/patient/PatientTravelApi';
import NotificationManager from 'common/notifications/NotificationManager';
import { DD_SLASH_MMM_SLASH_YYYY } from 'constants/dateFormat';
import { ROLE_SITE_FINANCIAL_COORDINATOR } from 'constants/userRoles';
import { userHasAccessAs } from 'services/auth';
import { onRequestError } from 'services/handlers';

import {
  TR_BOOKED,
  TR_DENIED,
  travelServiceTypes,
  travelStatuses
} from '../../EncountersSection/TravelSection/travelConstants';

export default function MainSection({
  encounterInfo,
  values,
  errors,
  actions,
  navigateBack,
  isEdit,
  ssuPatientId,
  travelRequestId,
  readOnly = false
}) {
  const { setFieldValue, handleCheckboxGroupChange } = actions;
  const { status, visitDate, visitStartTime, visitEndTime, requestedServices } = values;

  const allowApproveOrDeny = userHasAccessAs(ROLE_SITE_FINANCIAL_COORDINATOR);

  return (
    <>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" flexWrap="nowrap">
        <Grid xs="auto">
          <Typography variant="h6" sx={{ maxWidth: 500 }}>
            {encounterInfo?.originalEncounterName || encounterInfo?.encounterName}
          </Typography>
        </Grid>
        <Grid xs>
          <TextField
            disabled={!isEdit || status === TR_BOOKED}
            name="status"
            value={status}
            onChange={statusOnChange}
            sx={{ width: 200 }}
            select
            label="Status"
            variant="standard"
          >
            {travelStatuses.map(e => (
              <MenuItem
                key={e.id}
                value={e.id}
                disabled={[TR_DENIED, TR_BOOKED].includes(e.id) ? !allowApproveOrDeny : false}
              >
                {e.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid xs="auto">
          <IconButton
            onClick={function() {
              navigateBack();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems="flex-start" sx={{ pt: 2 }}>
        <Grid xs="auto">
          <DesktopDatePicker
            disabled={readOnly}
            disablePast={true}
            name="visitDate"
            value={visitDate}
            timezone="system"
            onChange={function(date) {
              setFieldValue('visitDate', date);
            }}
            label="Visit Date"
            format={DD_SLASH_MMM_SLASH_YYYY}
            views={['year', 'month', 'day']}
            slotProps={{
              textField: {
                variant: 'standard',
                required: true,
                fullWidth: true,
                error: Boolean(errors.visitDate),
                helperText: errors.visitDate
              }
            }}
            required
          />
        </Grid>
        <Grid xs="auto">
          <DesktopTimePicker
            disabled={readOnly}
            name="visitStartTime"
            label="Visit Start Time"
            value={visitStartTime}
            timezone="system"
            onChange={function(value) {
              return setFieldValue('visitStartTime', value);
            }}
            slotProps={{
              textField: {
                variant: 'standard',
                required: true,
                fullWidth: true,
                error: Boolean(errors.visitStartTime),
                helperText: errors.visitStartTime
              }
            }}
            required
          />
        </Grid>
        <Grid xs="auto">
          <DesktopTimePicker
            disabled={readOnly}
            name="visitEndTime"
            label="Visit End Time"
            value={visitEndTime}
            timezone="system"
            onChange={function(value) {
              return setFieldValue('visitEndTime', value);
            }}
            slotProps={{
              textField: {
                variant: 'standard',
                fullWidth: true,
                error: Boolean(errors.visitEndTime),
                helperText: errors.visitEndTime
              }
            }}
          />
        </Grid>
        <Grid xs></Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" sx={{ pt: 3 }}>
        <Grid xs={12}>
          <Typography variant="subtitle2">Requested service</Typography>
        </Grid>
        <Grid xs={12}>
          <FormControl
            disabled={readOnly}
            component="fieldset"
            variant="standard"
            error={Boolean(errors.requestedServices)}
            required
          >
            <FormGroup row>
              {travelServiceTypes.map(function({ id, name }) {
                return (
                  <FormControlLabel
                    key={id}
                    sx={{ margin: 0 }}
                    control={
                      <Checkbox
                        checked={requestedServices.includes(id)}
                        onChange={handleCheckboxGroupChange}
                        value={id}
                        name="requestedServices"
                      />
                    }
                    label={name}
                  />
                );
              })}
            </FormGroup>
            {Boolean(errors.requestedServices) && <FormHelperText>{errors.requestedServices}</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
  function statusOnChange({ target }) {
    const status = target.value;
    PatientTravelApi.updateStatus(ssuPatientId, travelRequestId, status)
      .then(function() {
        setFieldValue('status', status);
        NotificationManager.success('Travel Request status was changed successfully');
      })
      .catch(onRequestError);
  }
}
