import React from 'react';
import moment from 'moment';

import './Chevron.scss';

const colorOptions = {
  green: '#2F8F4C',
  darkerGreen: '#197836',
  darkestGreen: '#045A1E',
  lighterGreen: '#4FAA6A',
  lightestGreen: '#7EC894',
  yellow: '#C2BF3F',
  darkerYellow: '#A3A022',
  darkestYellow: '#7A7706',
  lighterYellow: '#E6E36A',
  lightestYellow: '#FFFDA1'
};

function Chevron(props) {
  const { milestone, date, actual } = props;
  let color;
  let text = 'white';
  const today = moment().format('YYYY-MM-DD');
  const isTodayOrAfter = date ? moment(date, 'YYYY-MM-DD').isSameOrAfter(today, 'day') : false;
  const isPast = date ? moment(date, 'YYYY-MM-DD').isBefore(today, 'day') : false;
  if (!date) {
    color = 'white';
  } else if (actual) {
    color = colorOptions.green;
  } else if (!actual && isTodayOrAfter) {
    color = colorOptions.lightestYellow;
    text = 'black';
  } else if (!actual && isPast) {
    color = colorOptions.lightestGreen;
    text = 'black';
  } else {
    color = colorOptions.lightestYellow;
    text = 'black';
  }

  return (
    <div className="chevron" style={{ '--eds-chevron-bg-color': color, '--eds-chevron-text-color': text }}>
      <div className="chevron-text">
        <p>{milestone}</p>
        <p>{date ? moment(date, 'YYYY-MM-DD').format('DD/MMM/YYYY') : '--'}</p>
      </div>
    </div>
  );
}

export default Chevron;
