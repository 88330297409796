import { ENROLMENTS } from '../../../../constants/accessToPages';

import { Enrollments } from './Enrollments';

export const enrollmentRouterConfig = {
  name: 'Enrollment',
  path: '/enrollments',
  rootBreadcrumbName: 'Finance',
  component: Enrollments,
  access: [ENROLMENTS]
};
