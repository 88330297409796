import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, TextField } from '@mui/material';

import Button from '../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../common/general/ButtonGroup';
import { toFormattedBill } from '../../../../../../services/financial';
import { PageInfoHeader } from '../../../../../PageInfoHeader/PageInfoHeader';
import BreadcrumbNavigation from '../../../../../root/Container/Layout/BreadcrumbNavigation/BreadcrumbNavigation';
import { Amount } from '../../../shared/amount-view/Amount/Amount';

import { SiteCreditApplicationFilters } from './SiteCreditApplicationFilters/SiteCreditApplicationFilters';
import { SiteCreditApplicationContext, withSiteCreditApplicationContext } from './SiteCreditApplicationContext';
import { SiteCreditApplicationTable } from './SiteCreditApplicationTable';

const SiteCreditApplication = () => {
  const navigate = useNavigate();
  const {
    selectedEventForApply,
    creditToApply,
    calculateSelectedAmount,
    eligibleEventsForApplyCredit,
    setComment,
    onApply
  } = useContext(SiteCreditApplicationContext);

  const selectedAmount = calculateSelectedAmount(selectedEventForApply, eligibleEventsForApplyCredit);
  const toApply = creditToApply - selectedAmount;

  return (
    <div className="eds-modal-boxes">
      <div className="eds-modal-box">
        <div className="eds-modal-box-overlay" />
        <Box className="eds-modal-box-container" position="absolute">
          <>
            <BreadcrumbNavigation />
            <PageInfoHeader
              name="Site Payments: "
              pageInfo={
                <React.Fragment>
                  <PageInfoHeader.AdditionalInfo title="Credit to Apply" data-testid="credit-to-apply">
                    <Amount coinsAmount={toApply > 0 ? toApply : 0} customCoinsFormat={toFormattedBill} />
                  </PageInfoHeader.AdditionalInfo>
                  <PageInfoHeader.AdditionalInfo title="Selected Amount" data-testid="selected-amount">
                    <Amount coinsAmount={selectedAmount} customCoinsFormat={toFormattedBill} />
                  </PageInfoHeader.AdditionalInfo>
                </React.Fragment>
              }
            >
              <SiteCreditApplicationFilters />
            </PageInfoHeader>
            <SiteCreditApplicationTable />
            <Box
              display="flex"
              justifyContent="space-between"
              padding="0 37px 25px 37px"
              marginTop="20px"
              alignItems="flex-end"
            >
              <TextField
                label="Comment"
                inputProps={{ maxLength: 500 }}
                size="small"
                variant="outlined"
                onChange={({ target: { value } }) => setComment(value)}
                sx={{
                  width: '50%'
                }}
              />
              <ButtonGroup>
                <Button
                  size="h28"
                  priority="medium"
                  onClick={() => {
                    navigate('/site-payments');
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="h28"
                  disabled={selectedEventForApply.length === 0}
                  onClick={() => {
                    onApply();
                  }}
                >
                  Apply
                </Button>
              </ButtonGroup>
            </Box>
            <IconButton
              sx={{ position: 'absolute', top: '17px', right: '17px', zIndex: 1001 }}
              onClick={() => {
                navigate('/site-payments');
              }}
            >
              <CloseIcon />
            </IconButton>
          </>
        </Box>
      </div>
    </div>
  );
};

export default withSiteCreditApplicationContext(SiteCreditApplication);
