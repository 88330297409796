import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { orderBy } from 'lodash/collection';
import { isArray, isEmpty } from 'lodash/lang';
import { sum } from 'lodash/math';

import { getHomePageDashboardsConfig } from '../../../../../actions/homePageDashboardsConfigActions';
import { HomePageDashboardsConfigApi } from '../../../../../api';
import Select from '../../../../../common/data-entry/Select';
import Loader from '../../../../../common/elements/Loader/Loader';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import Icon from '../../../../../common/general/Icon';

import EnrolledPatientsJourneyWidgetConfigModal from './EnrolledPatientsJourneyWidgetConfigModal';

import './EnrolledPatientsJourneyWidget.scss';

const DEFAULT_STATUSES = ['PI_REVIEW', 'SM_REVIEW', 'OPEN', 'CANCELED', 'COMPLETE'];

export default function EnrolledPatientsJourneyWidget({ widget, studies }) {
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState({});
  const [epoch, setEpoch] = useState(null);
  const [visits, setVisits] = useState({});
  const [selectedStudy, setSelectedStudy] = useState(null);
  const dispatch = useDispatch();
  const [showStatuses, setShowStatuses] = useState(
    isArray(widget?.configJson?.statuses) ? widget?.configJson?.statuses : DEFAULT_STATUSES
  );
  useEffect(
    function() {
      setSelectedStudy(studies[0]);
    },
    [studies]
  );
  useEffect(
    function() {
      selectedStudy && fetchData(selectedStudy.id, showStatuses);
    },
    [showStatuses, selectedStudy]
  );

  const fetchData = (studyId, showStatuses) => {
    setIsFetching(true);
    setData({});
    const request = {
      studyId,
      encounterStatuses: showStatuses || DEFAULT_STATUSES
    };
    HomePageDashboardsConfigApi.getEnrolledPatientsJourney(request).then(resp => {
      const byEpoch = resp.data;
      const epochs = orderBy(Object.keys(byEpoch), epochName => getTotalNumber(byEpoch[epochName]), 'desc');
      setData(byEpoch);
      if (!isEmpty(epochs) && !isEmpty(epochs[0])) {
        setEpoch(epochs[0]);
        if (!isEmpty(byEpoch[epochs[0]])) {
          setVisits(byEpoch[epochs[0]]);
        } else {
          setVisits({});
        }
      } else {
        setEpoch({});
      }

      setIsFetching(false);
    });
  };

  const changeSettings = newStatuses => {
    HomePageDashboardsConfigApi.updateDashboardWidgetState({
      dashboardId: widget.dashboardId,
      type: widget.type,
      active: widget.active,
      configChanges: { ...widget?.configJson, statuses: newStatuses }
    }).then(resp => {
      dispatch(getHomePageDashboardsConfig());
      setShowStatuses(resp.data.configJson.statuses);
    });
  };

  const configureTable = () => {
    ModalBoxes.open({
      component: (
        <EnrolledPatientsJourneyWidgetConfigModal
          statuses={DEFAULT_STATUSES}
          columnFlags={showStatuses}
          changeSettings={changeSettings}
        />
      )
    });
  };
  const epochs = Object.keys(data);
  const visitsMap = Object.keys(visits);

  return (
    <div className={cx('p-3 widget-container study-enrolled-patient-journey', 'size-m')} key={7}>
      <div className="widget">
        <div className="widget-header p-2 px-3">
          <h5 className="title">{widget.name}</h5>
          <span>
            <Button
              size="h28"
              disabled={isFetching}
              onClick={() => {
                configureTable();
              }}
              className="mx-2"
              priority="medium"
            >
              <Icon suit={'material-outline'}>settings</Icon>
            </Button>
            <Button
              size="h28"
              disabled={isFetching}
              onClick={() => {
                fetchData(selectedStudy.id, showStatuses);
              }}
              priority="medium"
            >
              <Icon>restart_alt</Icon>
            </Button>
          </span>
        </div>
        <Select
          type="chips"
          className="mx-2 mb-2"
          dataSource={studies}
          label={'Study'}
          searchable
          clearable={false}
          validate={false}
          value={selectedStudy}
          onChange={study => {
            if (selectedStudy.id !== study.id) {
              setSelectedStudy(study);
              fetchData(study.id);
            }
          }}
        />
        {isFetching && <Loader dark={true} />}
        {!isFetching && !isEmpty(data) && !isEmpty(epoch) && (
          <>
            <div className="epoch-list-widget-container">
              <div className="epoch-list-widget">
                {orderBy(epochs, epochName => getTotalNumber(data[epochName]), 'desc')
                  .filter(epochName => getTotalNumber(data[epochName]) > 0)
                  .map((epochName, key) => (
                    <div
                      key={key}
                      className={cx('epoch-list-widget-element', { highlight: epochName === epoch })}
                      onClick={() => {
                        setEpoch(epochName);
                        setVisits(data[epochName]);
                      }}
                    >
                      {epochName === 'Unexpected' && <strong>Unexpected</strong>}
                      {epochName !== 'Unexpected' && <div>{epochName}</div>}
                      <strong>{getTotalNumber(data[epochName]).toLocaleString('en-US')}</strong>
                    </div>
                  ))}
              </div>
              <div className="encounters-list-widget-container">
                <div className="encounters-list-widget">
                  {orderBy(visitsMap, visit => visits[visit], 'desc').map((visit, key) => (
                    <div className="encounters-list-widget-element" key={key}>
                      <div>{visit}</div>
                      <strong>{visits[visit].toLocaleString('en-US')}</strong>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function getTotalNumber(val) {
  return sum(Object.values(val));
}
