import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash/lang';
import { maxBy } from 'lodash/math';
import moment from 'moment';

import FinLedgerRevenueDateApi from '../../../../../../../api/finance/FinLedgerRevenueDateApi';
import DatePicker from '../../../../../../../common/data-entry/DatePicker/DatePicker';
import Input from '../../../../../../../common/data-entry/Input/Input';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';
import { MANAGE_REVENUE_EVENTS } from '../../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../../services/auth';
import { isValidDateToSelect } from '../revenueDatesService';

import './HistoryOfRevenueDatesModal.scss';

export default function HistoryOfRevenueDatesModal({ modalBox, info, onSave }) {
  const {
    itemId,
    studyName,
    siteName,
    subjectId,
    encounterName,
    elligoEventType,
    triggerName,
    eventName,
    eventDate
  } = info;
  const [revenueDateHistory, setRevenueDateHistory] = useState(null);
  const [newRevenueDate, setNewRevenueDate] = useState(null);
  const [currentRevenueDate, setCurrentRevenueDate] = useState(null);
  const [changeReason, setChangeReason] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);

  useEffect(
    function() {
      const lastRecord = maxBy(revenueDateHistory, 'changedAt');
      setCurrentRevenueDate(lastRecord?.revenueDate);
    },
    [revenueDateHistory]
  );

  useEffect(
    function() {
      FinLedgerRevenueDateApi.getRevenueDateHistory(itemId).then(function({ data }) {
        setRevenueDateHistory(data.history);
      });
    },
    [itemId]
  );

  return (
    <>
      <ModalBoxes.Body>
        <div className="revenue-date-history-info">
          <h4>Info</h4>
          <div>
            Study <span>{studyName}</span>
          </div>
          <div>
            Site <span>{siteName}</span>
          </div>
          <div>
            Subject ID <span>{subjectId}</span>
          </div>
          <div>
            Encounter <span>{encounterName}</span>
          </div>
          <div>
            Type <span>{elligoEventType}</span>
          </div>
          <div>
            Event <span>{triggerName}</span>
          </div>
          <div>
            Name <span>{eventName}</span>
          </div>
          <div>
            Event date <span>{moment(eventDate).format('DD/MMM/YYYY')}</span>
          </div>
        </div>
        <div className="revenue-date-history">
          <h4>History</h4>
          <div className="revenue-date-history-table-wrapper">
            <div className="revenue-date-history-table">
              <div>
                <div>Revenue Date</div>
                <div>Username</div>
                <div>Reason</div>
              </div>
              {revenueDateHistory &&
                revenueDateHistory
                  .sort(function(a, b) {
                    return new Date(b.changedAt) - new Date(a.changedAt);
                  })
                  .map(function({ id, revenueDate, reason, changedBy }) {
                    return (
                      <div key={id} className={cx({ highlighted: selectedRowId === id })} role={'row'}>
                        <div>{moment(revenueDate).format('DD/MMM/YYYY')}</div>
                        <div>{changedBy}</div>
                        <div>{reason}</div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
        <div className="revenue-date-history-form">
          <div>
            <DatePicker
              isValidDate={isValidDateToSelect.bind(currentRevenueDate)}
              viewDate={moment(currentRevenueDate)}
              dateFormat="DD/MMM/YYYY"
              timeFormat={false}
              closeOnSelect
              label="New Revenue date"
              readOnly
              value={newRevenueDate}
              onChange={function(date) {
                setNewRevenueDate(date);
              }}
            />
          </div>
          <div>
            <Input
              label="Change Reason"
              value={changeReason}
              onChange={function(e) {
                setChangeReason(e.target.value);
              }}
            />
          </div>
        </div>
      </ModalBoxes.Body>

      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            Cancel
          </Button>
          {userHasAccessTo(MANAGE_REVENUE_EVENTS) && (
            <Button
              disabled={isEmpty(changeReason) || isEmpty(newRevenueDate)}
              onClick={function() {
                onSave({
                  revenueDate: newRevenueDate.format('YYYY-MM-DD'),
                  reason: changeReason
                }).then(function(data) {
                  setRevenueDateHistory(function(state) {
                    return [...state, data];
                  });
                  setNewRevenueDate(null);
                  setChangeReason(null);
                  setSelectedRowId(data.id);
                });
              }}
            >
              Save
            </Button>
          )}
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
}

HistoryOfRevenueDatesModal.className = 'history-of-revenue-dates-modal';
HistoryOfRevenueDatesModal.size = 'w1250';
