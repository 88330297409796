import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash/lang';

import Toggle from '../../../../../common/data-entry/Toggle';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';
import { getStatusDisplayName } from '../utilityFunctions';

import '../WidgetConfigModal.scss';

const EnrolledPatientsJourneyWidgetConfigModal = ({ modalBox, statuses, columnFlags, changeSettings }) => {
  const [showColumnFlags, setShowColumnFlags] = useState(columnFlags);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  useEffect(() => {
    setSaveButtonDisabled(isEmpty(showColumnFlags));
  }, [showColumnFlags]);

  return (
    <>
      <ModalBoxes.Header>Config</ModalBoxes.Header>
      <ModalBoxes.Body>
        <h5>Statuses</h5>
        <div>
          {statuses &&
            statuses.map((status, index) => (
              <div className="table-widget-row" key={index}>
                <span className="table-widget-name">{`${index + 1}. ${getStatusDisplayName(status)}`}</span>
                <Toggle
                  name={status}
                  checked={columnFlags.includes(status)}
                  disabled={false}
                  onChange={e => {
                    setShowColumnFlags(prevState => {
                      let newState;
                      if (e.target.checked) {
                        newState = [...prevState, status];
                      } else {
                        newState = prevState.filter(e => e !== status);
                      }
                      return newState;
                    });
                  }}
                />
              </div>
            ))}
        </div>

        <span className={'text-danger table-config-error-message'}>
          {saveButtonDisabled ? 'Please select at least one status' : ' '}
        </span>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <div className="eds-btn-group">
          <ButtonGroup>
            <Button
              priority="medium"
              onClick={() => {
                modalBox.close();
              }}
            >
              Cancel
            </Button>
            <Button
              priority="high"
              disabled={saveButtonDisabled}
              onClick={() => {
                changeSettings(showColumnFlags);
                modalBox.close();
              }}
            >
              Save
            </Button>
          </ButtonGroup>
        </div>
      </ModalBoxes.Footer>
    </>
  );
};

EnrolledPatientsJourneyWidgetConfigModal.className = 'widget-config-modal';
EnrolledPatientsJourneyWidgetConfigModal.size = 'w650';

export default EnrolledPatientsJourneyWidgetConfigModal;
