import axios from 'axios';

const ROOT = 'api/v1/finance/payees';

const FinPayeeApi = {
  save(payee) {
    return axios.post(ROOT, payee);
  },
  update(payee) {
    return axios.put(ROOT, payee);
  },
  findById(payeeId) {
    return axios.get(`${ROOT}/${payeeId}`);
  },
  findAllActive() {
    return axios.get(ROOT);
  },
  deletePayee(payeeId) {
    return axios.delete(`${ROOT}/${payeeId}`);
  },
  markAsDefault(payeeId) {
    return axios.put(`${ROOT}/${payeeId}/default`);
  }
};

export default FinPayeeApi;
