import ModalBoxes from 'common/feedback/ModalBoxes/ModalBoxes';

export default function showDuplicateEmailModal(message) {
  ModalBoxes.confirm({
    title: 'Duplicate Patient Found',
    content: message,
    cancelButton: 'Cancel',
    confirmButton: false
  }).then(
    () => {},
    () => {}
  );
}
