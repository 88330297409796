import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

import { performPostponedNotifications } from '../../services/postponedNotifications';

import './NotificationContainer.scss';

export default function NotificationContainer() {
  useEffect(function() {
    performPostponedNotifications();
  }, []);

  return <ToastContainer position="top-right" autoClose={5500} theme="colored" limit={3} closeOnClick />;
}
