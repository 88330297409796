import { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash/lang';

import { PendoApi, StudySiteApi } from '../api';
import useAppInfo from '../common/hooks/useAppInfo';
import { useCurrentUser } from '../components/root/Container/CurrentUserContainer';

export function pendoTrack(message, params) {
  window.pendo && window.pendo.track(message, params);
}

export function pendoTrackDefaultSortingChange(sortedBy) {
  pendoTrack('Sorting order changed', {
    pageName: window.location.pathname,
    sortedBy: sortedBy
  });
}
export default function PendoAnalytics() {
  const User = useCurrentUser();

  const [pendoKey, setPendoKey] = useState(null);
  const appInfo = useAppInfo();

  const pendoEnabled = appInfo?.features?.pendoEnabled;

  const initPendo = useCallback(function() {
    PendoApi.getPendoConfig().then(resp => {
      const { pendoApiKey } = resp.data;
      (function(apiKey) {
        (function(p, e, n, d, o) {
          var v, w, x, y, z;
          o = p[d] = p[d] || {};
          o._q = o._q || [];
          v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
          for (w = 0, x = v.length; w < x; ++w)
            (function(m) {
              o[m] =
                o[m] ||
                function() {
                  o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
                };
            })(v[w]);
          y = e.createElement(n);
          y.async = !0;
          y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
          z = e.getElementsByTagName(n)[0];
          z.parentNode.insertBefore(y, z);
        })(window, document, 'script', 'pendo');
      })(pendoApiKey);
      setPendoKey(pendoApiKey);
    });
  }, []);

  const sendLoginDataToPendo = useCallback(
    async function(User) {
      const isGlobalRole = User.operationScopes[User.activeRole];
      const { personnelIdentifier, email, lastName, firstName, roles, activeRole, department, title } = User;
      if (pendoEnabled) {
        let siteNames = '';
        let studyNames = '';
        let studySiteNames = '';
        if (!isGlobalRole) {
          const studySites = await StudySiteApi.getAllStudySitesAndMap();
          const modified = modifyStudySites(studySites.data);
          siteNames = modified.siteNames;
          studyNames = modified.studyNames;
          studySiteNames = modified.studySiteNames;
        }
        sendDataToPendo(
          personnelIdentifier,
          email,
          lastName,
          firstName,
          roles,
          activeRole,
          appInfo.profileName,
          department,
          title,
          isGlobalRole,
          studySiteNames,
          studyNames,
          siteNames
        );
      }
    },
    [pendoEnabled, appInfo.profileName]
  );

  useEffect(
    function() {
      if (!isEmpty(User) && pendoEnabled && pendoKey) {
        sendLoginDataToPendo(User).then(() => {});
      }
    },
    [User, pendoEnabled, sendLoginDataToPendo, appInfo.profileName, pendoKey]
  );

  useEffect(() => {
    return function() {
      window.pendo = null;
    };
  }, []);

  useEffect(
    function() {
      if (!isEmpty(User) && !pendoKey) {
        initPendo();
      }
    },
    [User, initPendo, pendoKey]
  );

  return null;
}

const modifyStudySites = studySites => {
  const studySiteNames = [];
  const studyNames = [];
  const siteNames = [];
  studySites.size !== 0 &&
    studySites.forEach(ssu => {
      studySiteNames.push(ssu.study.studyName + '-' + ssu.site.siteName);
      !studyNames.includes(ssu.study.studyName) && studyNames.push(ssu.study.studyName);
      !siteNames.includes(ssu.site.siteName) && siteNames.push(ssu.site.siteName);
    });
  return {
    siteNames,
    studyNames,
    studySiteNames
  };
};

const sendDataToPendo = (
  personnelIdentifier,
  email,
  lastName,
  firstName,
  roles,
  activeRole,
  profileName,
  department,
  title,
  isGlobalRole,
  studySiteNames,
  studyNames,
  siteNames
) => {
  window.pendo &&
    window.pendo.initialize({
      visitor: {
        id: personnelIdentifier,
        email: email,
        full_name: lastName + ' ' + firstName,
        roles: roles,
        active_role: activeRole,
        environment: profileName,
        department: department,
        title: title,
        active_role_type: isGlobalRole ? 'Global' : 'Study Site Unit',
        study_site_unit_name: studySiteNames,
        study_name: studyNames,
        site_name: siteNames
      }
    });
};
