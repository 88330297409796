import { HomePageDashboardsConfigApi } from '../api';
import { onRequestDefaultError } from '../services/handlers';

import { SET_HOME_PAGE_DASHBOARDS_CONFIG } from './actionTypes';

export const getHomePageDashboardsConfig = () => dispatch => {
  return HomePageDashboardsConfigApi.getDashboardWidgetConfig().then(res => {
    dispatch({
      type: SET_HOME_PAGE_DASHBOARDS_CONFIG,
      payload: res.data[0]
    });
  }, onRequestDefaultError);
};
