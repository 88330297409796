import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { orderBy } from 'lodash/collection';
import { isArray, isEmpty } from 'lodash/lang';

import { getHomePageDashboardsConfig } from '../../../../../actions/homePageDashboardsConfigActions';
import { HomePageDashboardsConfigApi } from '../../../../../api';
import Loader from '../../../../../common/elements/Loader/Loader';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import Icon from '../../../../../common/general/Icon';
import {
  ROLE_PRINCIPAL_INVESTIGATOR,
  ROLE_STUDY_MANAGER,
  ROLE_SYSTEM_ADMINISTRATOR
} from '../../../../../constants/userRoles';
import { useCurrentUser } from '../../../../root/Container/CurrentUserContainer';

import AwaitingSignOffTopTenWidgetConfigModal from './AwaitingSignOffTopTenWidgetConfigModal';
import AwaitingSignOffTopTenWidgetList from './AwaitingSignOffTopTenWidgetList';
import AwaitingSignOffTopTenWidgetTable from './AwaitingSignOffTopTenWidgetTable';

const DEFAULT_COLUMNS = ['Patient', 'Subject ID', 'Study', 'Site', 'Encounter', 'Visit Date', 'Review'];
const DEFAULT_TYPES = ['SM_REVIEW', 'PI_REVIEW'];
let intervalInstance;
export const AwaitingSignOffTopTenWidget = ({ widget, ssus }) => {
  const [smTableData, setSmTableData] = useState([]);
  const [showColumnFlags, setShowColumnFlags] = useState(
    isArray(widget?.configJson?.columns) ? widget?.configJson?.columns : DEFAULT_COLUMNS
  );
  const [showTypeFlags, setShowTypeFlags] = useState(
    isArray(widget?.configJson?.types) ? widget?.configJson?.types : DEFAULT_TYPES
  );
  const [listViewMode, setListViewMode] = useState(widget?.configJson?.listView || false);
  const [showIconsMode, setShowIconsMode] = useState(widget?.configJson?.showIcons || false);
  const [isFetching, setIsFetching] = useState(false);
  const User = useCurrentUser();
  const columns = DEFAULT_COLUMNS.map(e => ({ accessor: e, Header: e, show: showColumnFlags.includes(e) }));
  const dispatch = useDispatch();
  const updateData = useCallback(
    (User, ssus, showTypeFlags) => {
      if (
        User &&
        [ROLE_STUDY_MANAGER, ROLE_SYSTEM_ADMINISTRATOR, ROLE_PRINCIPAL_INVESTIGATOR].includes(User.activeRole)
      ) {
        let encounterStatuses = showTypeFlags;
        if (User.activeRole === ROLE_PRINCIPAL_INVESTIGATOR) {
          encounterStatuses = ['PI_REVIEW'];
        }

        setIsFetching(true);
        HomePageDashboardsConfigApi.getAwaitingSignOffWidgetData(
          {
            ssuIds: ssus.map(e => e.id),
            encounterStatuses
          },
          true
        ).then(({ data }) => {
          data.content.map(entry => {
            entry.edcVisitDate = new Date(entry.edcVisitDate);
            return entry;
          });
          setSmTableData(orderBy(data.content, e => e.edcVisitDate));
          setIsFetching(false);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [User, ssus]
  );

  function updateDataWithInterval(User, ssus, showTypeFlags) {
    if (!isEmpty(ssus)) {
      if (intervalInstance) {
        clearInterval(intervalInstance);
      }
      updateData(User, ssus, showTypeFlags);
      intervalInstance = setInterval(updateData, widget.refreshTime * 1000);
    }
  }

  useEffect(() => {
    updateDataWithInterval(User, ssus, showTypeFlags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [User, showTypeFlags, ssus, updateData]);

  const changeSettings = (newColumns, newTypes, listView, showIcons) => {
    HomePageDashboardsConfigApi.updateDashboardWidgetState({
      dashboardId: widget.dashboardId,
      type: widget.type,
      active: widget.active,
      configChanges: {
        ...widget?.configJson,
        columns: newColumns,
        types: newTypes,
        listView: listView,
        showIcons: showIcons
      }
    }).then(resp => {
      dispatch(getHomePageDashboardsConfig());
      setShowColumnFlags(resp.data.configJson.columns);
      setListViewMode(resp.data.configJson.listView);
      setShowIconsMode(resp.data.configJson.showIcons);
      const typesChanged =
        showTypeFlags.some(e => !resp.data.configJson.types.includes(e)) ||
        resp.data.configJson.types.some(e => !showTypeFlags.includes(e));
      typesChanged && setShowTypeFlags(resp.data.configJson.types);
      typesChanged && updateData(resp.data.configJson.types);
    });
  };

  const configureTable = () => {
    ModalBoxes.open({
      component: (
        <AwaitingSignOffTopTenWidgetConfigModal
          columns={columns}
          columnFlags={showColumnFlags}
          changeSettings={changeSettings}
          typeFlags={showTypeFlags}
          listViewMode={listViewMode}
          showIconsMode={showIconsMode}
        />
      )
    });
  };

  useEffect(function() {
    updateDataWithInterval();
    return () => {
      clearInterval(intervalInstance);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-3 widget-container size-m">
      <div className="widget">
        <div className="widget-header p-3">
          <div className="title">{widget.name}</div>
          <span>
            <Button
              size="h28"
              onClick={() => {
                configureTable();
              }}
              priority="medium"
              className="mx-2"
            >
              <Icon suit="material-outline">settings</Icon>
            </Button>
            <Button
              size="h28"
              onClick={() => {
                updateDataWithInterval(User, ssus, showTypeFlags);
              }}
              priority="medium"
            >
              <Icon>restart_alt</Icon>
            </Button>
          </span>
        </div>
        {isFetching && <Loader dark={true} />}
        {!isFetching && !listViewMode && (
          <div className={'px-3 pb-3 widget-table-container'}>
            <AwaitingSignOffTopTenWidgetTable
              data={smTableData || []}
              showColumns={showColumnFlags}
              showPagination={false}
              showPageSizeOptions={false}
              originalPage="HomePageDashboards"
            />
          </div>
        )}
        {!isFetching && listViewMode && (
          <div className={'pb-3 widget-table-container'}>
            <AwaitingSignOffTopTenWidgetList
              data={smTableData || []}
              showColumns={showColumnFlags}
              showIconsMode={showIconsMode}
              originalPage="HomePageDashboards"
            />
          </div>
        )}
      </div>
    </div>
  );
};
