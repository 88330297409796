import { FinBudgetApi } from '../../../api';
import { onRequestError } from '../../../services/handlers';
import { GET_STUDY_BUDGETS } from '../../actionTypes';

export const getStudyBudgetsAction = budgetList => {
  return {
    type: GET_STUDY_BUDGETS,
    payload: budgetList
  };
};

export const getStudyBudgets = () => dispatch => {
  return FinBudgetApi.findAll()
    .then(resp => {
      dispatch(getStudyBudgetsAction(resp.data));
    })
    .catch(err => onRequestError(err, { customMessage: "Can't get all Study Budgets info" }));
};
