import React, { forwardRef } from 'react';
import parse, { attributesToProps, domToReact } from 'html-react-parser';
import moment from 'moment/moment';

const fileIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADzSURBVHgB7ZUtDsJAEIWHP4GtRWCwWAQXKFfAcgaO0YRwAWwlulcAgQCBqMVisbwXXtNCYHcDGEgn+TLTnXZeZ8WM2a9bw5OPwBT0QBfkIAVn+4IAi88Vb+SHEkpCRVqO3Ax0wBLswBEcwBj0wdYCrOnIDcDe7v+UMbvpWaA1PfmL/ALElbOu4li5twU+tlqgFqgFzNqB73GKjhTTnwK/c3bAkRApTvU8sds8Wuk8snKcPDVXBxx0HM+ZiiYPeRYvBuJLc3WQyc+s7KRavDjPHDW8G433zY3GDnJ5Fh0qvzbPXvAJmArGVm4z3jmvpbi6P7cr6RUpdRoMoW8AAAAASUVORK5CYII=';

const styles = {
  templateBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '590px',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: '#828282',
    fontFamily: 'Roboto, Roboto-Regular',
    height: 'auto',
    wordBreak: 'break-word',
    subject: {
      marginBottom: '16px'
    },
    attachedFile: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '45px',
      backgroundColor: '#F3F3F3',
      color: '#2465C6',
      cursor: 'pointer',
      fontSize: '14px',
      icon: {
        margin: '0 12px 0 9px'
      }
    },
    mailBody: {
      color: 'black',
      marginTop: '16px',
      width: '560px'
    }
  }
};

const options = {
  replace(domNode) {
    if (domNode.name === 'li') {
      const props = attributesToProps(domNode.attribs);
      return (
        <li {...props}>
          <span style={{ paddingLeft: '20px' }}> {domToReact(domNode.children, options)}</span>
        </li>
      );
    }
    if (domNode.name === 'ol') {
      const props = attributesToProps(domNode.attribs);
      return (
        <ol {...props}>
          <span style={{ paddingLeft: '20px' }}> {domToReact(domNode.children, options)}</span>
        </ol>
      );
    }
  }
};

export const EmailDetailsPDFTemplate = forwardRef(({ emailData, filePreview }, ref) => {
  return (
    <div>
      <div style={Object.assign(styles.templateBody)} ref={ref}>
        <div>
          {moment(emailData?.sentAt).format('dddd, MMMM DD, YYYY')} at {moment(emailData?.sentAt).format('hh:mm A')}
        </div>
        <div>
          From: {emailData?.fromName} {`<${emailData?.from}>`}
        </div>
        <div>To: {emailData?.to}</div>
        {emailData?.cc && <div>Cc: {emailData?.cc}</div>}
        {emailData?.bcc && <div>Bcc: {emailData?.bcc}</div>}
        <div style={Object.assign(styles.templateBody.subject)}>Subject: {emailData?.subject}</div>
        {emailData?.attachments?.map(file => (
          <div
            style={Object.assign(styles.templateBody.attachedFile)}
            key={file.fileId}
            onClick={() => filePreview(file.fileId)}
            className={'file-link'}
          >
            <img alt="file-icon" src={fileIcon} style={Object.assign(styles.templateBody.attachedFile.icon)} />
            <div>{file.fileName}</div>
          </div>
        ))}
        <div style={Object.assign(styles.templateBody.mailBody)}>{parse(emailData?.body, options)}</div>
      </div>
    </div>
  );
});
