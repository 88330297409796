import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { MenuItem, TextField } from '@mui/material';
import get from 'lodash/get';

import PatientReimbursementApi from '../../../../../../../api/patient/PatientReimbursementApi';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { ROLE_SITE_FINANCIAL_COORDINATOR, ROLE_SYSTEM_ADMINISTRATOR } from '../../../../../../../constants/userRoles';
import { userHasRole } from '../../../../../../../services/auth';
import { onRequestError } from '../../../../../../../services/handlers';
import {
  INVALID_PID,
  RBS_APPROVED,
  RBS_CANCELED,
  RBS_DENIED,
  RBS_DRAFT,
  reimbursementStatuses
} from '../../EncountersSection/PaymentsSection/reimbursementConstants';

export default function StatusDropdown({
  setInvalidCard,
  status,
  totalAmount,
  canBeApprovedOrDenied,
  onChangeSuccess
}) {
  const { ssuPatientId, reimbursementId } = useParams();
  const allowedStatuses = useMemo(
    function() {
      return reimbursementStatuses.map(function(value) {
        return prepareStatus({ ...value });
      });
      function prepareStatus(value) {
        if (
          !userHasRole([ROLE_SYSTEM_ADMINISTRATOR, ROLE_SITE_FINANCIAL_COORDINATOR]) &&
          [RBS_APPROVED, RBS_DENIED].includes(value.id)
        ) {
          value.disabled = true;
        }
        if (status === RBS_APPROVED) {
          value.disabled = true;
        }
        if (status === RBS_CANCELED && value.id !== RBS_DRAFT) {
          value.disabled = true;
        }
        if ((value.id === RBS_APPROVED || value.id === RBS_DENIED) && (!canBeApprovedOrDenied || totalAmount === 0)) {
          value.disabled = true;
        }
        return value;
      }
    },
    [status, canBeApprovedOrDenied, totalAmount]
  );

  if (!status) return null;
  return (
    <TextField
      value={status}
      onChange={reimbursementStatusOnChange}
      select
      label="Status"
      variant="standard"
      sx={{ width: 200 }}
      size="small"
    >
      {allowedStatuses.map(e => (
        <MenuItem key={e.id} value={e.id} disabled={e.disabled}>
          {e.name}
        </MenuItem>
      ))}
    </TextField>
  );
  function reimbursementStatusOnChange({ target }) {
    const status = target.value;

    (status === RBS_CANCELED
      ? ModalBoxes.confirm({
          content: 'Are you sure you want to cancel the reimbursement request?'
        })
      : Promise.resolve()
    ).then(
      () => {
        PatientReimbursementApi.updateReimbursementStatus(ssuPatientId, reimbursementId, status, totalAmount)
          .then(function() {
            onChangeSuccess(status);
          })
          .catch(error => {
            const { messageLocation = 'response.data.message', customMessage = null } = error.params || {};
            const message = customMessage || get(error, messageLocation, null);
            if (message === INVALID_PID) {
              setInvalidCard(true);
            } else {
              onRequestError(error, error.params);
            }
          });
      },
      () => {}
    );
  }
}
