import React from 'react';
import cx from 'classnames';
import { isEmpty, isUndefined } from 'lodash/lang';
import moment from 'moment';
import Tooltip from 'rc-tooltip';

import Icon from '../../../../../common/general/Icon';
import {
  ROLE_PRINCIPAL_INVESTIGATOR,
  ROLE_STUDY_MANAGER,
  ROLE_SYSTEM_ADMINISTRATOR
} from '../../../../../constants/userRoles';
import { userHasRole } from '../../../../../services/auth';
import { resolveSmStatus } from '../../../../../services/logRowStatusService';
import InvestigatorFieldIcon from '../../shared/EncounterDescNew/InvestigatorFields/InvestigatorFieldIcon/InvestigatorFieldIcon';
import { PS_NOT_ASKED, PS_UNAVAILABLE } from '../../shared/EncounterDescNew/TableItemGroup/constants';
import { CANCELED } from '../../shared/Worklist/constants';

export const defaultCellWidth = 150;
export function getColumns(columns = [], showEncounter = true, domainCode) {
  const transformToColumnHeader = columns
    .filter(e => !['textBlockLong', 'label'].includes(e.inputType))
    .sort(function(a, b) {
      return a.sequence - b.sequence;
    })
    .map(function(el) {
      const { name, question, fixedField, investigatorField } = el;
      return {
        Header: question,
        minWidth: defaultCellWidth,
        sortMethod: sortingStringOrStringNumber,
        id: name,
        accessor: e => e?.data[name],
        fixed: fixedField,
        investigatorField,
        getProps: (state, row, column) => {
          const props = {};
          if (!isUndefined(row)) {
            if (column.id === 'Comment') {
              const commentAnswer = row?.original?.data?.Comment;
              props.className = commentAnswer?.answer ? 'text-left link-text' : 'text-center';
            }
          }
          return props;
        },
        Cell: row => {
          let { value } = row;
          let answer = resolveAnswer(value);
          if (answer) {
            return (
              <Tooltip
                destroyTooltipOnHide={false}
                overlayClassName="eds-rc-tooltip logs-table-tooltip"
                placement="top"
                trigger={answer?.length > 30 ? ['hover'] : []}
                overlay={answer}
              >
                <div className={cx({ 'cross-line': row.original.aeStatuses[value?.groupIdentifier] === CANCELED })}>
                  {answer}
                </div>
              </Tooltip>
            );
          }
          if (
            value?.performedStatus &&
            (value?.performedStatus === PS_NOT_ASKED ||
              (value?.performedStatus === PS_UNAVAILABLE && !isEmpty(value?.fieldComment)))
          ) {
            answer = `${performedStatusMap[value.performedStatus]}: ${value.fieldComment}`;
            return (
              <Tooltip
                destroyTooltipOnHide={false}
                overlayClassName="eds-rc-tooltip logs-table-tooltip"
                placement="top"
                trigger={['hover']}
                overlay={answer}
              >
                <div>{performedStatusMap[value.performedStatus]}</div>
              </Tooltip>
            );
          }
          return '--';
        }
      };
    });

  return [
    ...defaultColumnsForLogs(showEncounter, domainCode),
    {
      Header: <CustomCellHeader title={'Status'} />,
      fixed: true,
      show:
        (userHasRole(ROLE_SYSTEM_ADMINISTRATOR) ||
          userHasRole(ROLE_STUDY_MANAGER) ||
          userHasRole(ROLE_PRINCIPAL_INVESTIGATOR)) &&
        domainCode === 'AE',
      minWidth: 180,
      id: 'status',
      accessor: original => original?.aeStatuses[original?.rowId],
      Cell: ({ value }) => <div>{resolveSmStatus(value, null) || '--'}</div>
    },
    ...transformToColumnHeader
  ];

  function resolveAnswer(questionAndAnswer) {
    if (questionAndAnswer) {
      if (questionAndAnswer.attributeType === 'file') {
        if (questionAndAnswer.files?.length) {
          return 'Yes';
        }
        return null;
      } else {
        return questionAndAnswer.answer;
      }
    }
  }
}

export const CustomCell = ({ longAnswer, shortAnswer, className }) => {
  return (
    <Tooltip
      destroyTooltipOnHide={false}
      overlayClassName="eds-rc-tooltip logs-table-tooltip over-modal"
      placement="top"
      trigger={longAnswer?.length > 28 ? ['hover'] : []}
      overlay={longAnswer}
    >
      <div className={className}>{shortAnswer}</div>
    </Tooltip>
  );
};

export const CustomCellHeader = ({ title, showSorting = true, investigatorField = false }) => {
  const isOneLine = title.split(' ')?.length === 1;
  const trigger = isOneLine ? title?.length > 18 : title?.length > 28;
  return (
    <Tooltip
      destroyTooltipOnHide={false}
      overlayClassName="eds-rc-tooltip logs-table-tooltip over-modal"
      placement="top"
      trigger={trigger ? ['hover'] : []}
      overlay={title}
    >
      <>
        {investigatorField && <InvestigatorFieldIcon />}
        <div className="custom-sort-header">
          <div className={cx('title', { 'one-line': title.split(' ')?.length === 1 })}>{title}</div>
          {showSorting && (
            <div className="icons">
              <Icon>expand_less</Icon>
              <Icon>expand_more</Icon>
            </div>
          )}
        </div>
      </>
    </Tooltip>
  );
};

const defaultColumnsForLogs = (showEncounter, domainCode) => {
  return [
    {
      Header: '№',
      accessor: 'logRowNumber',
      headerClassName: 'text-center',
      width: 70,
      id: 'logRowNumber',
      minWidth: 70,
      fixed: true
    },
    {
      Header: 'Date Recorded',
      id: 'dateRecorded',
      accessor: 'dateRecorded',
      sortMethod: sortDate,
      minWidth: 125,
      fixed: domainCode !== 'AE'
    },
    {
      Header: 'Encounter',
      accessor: 'associatedEncounter.encounterName',
      id: 'encounterName',
      className: 'text-left',
      Cell: ({ value }) => <div>{value || 'N/A'}</div>,
      minWidth: 150,
      fixed: true,
      show: showEncounter
    },
    {
      Header: 'Progress',
      accessor: 'progress',
      id: 'progress',
      sortMethod: sortProgress,
      minWidth: 120,
      fixed: domainCode !== 'AE'
    }
  ];
};

function sortProgress(a, b) {
  const valA = a.split('/');
  const valB = b.split('/');

  let ansA = valA[1] / valA[0];
  let ansB = valB[1] / valB[0];
  if (ansA === ansB && valA[1] !== valB[1]) {
    return valA[1] < valB[1] ? -1 : 1;
  }
  if (ansA === ansB) {
    return 0;
  }
  return ansA > ansB ? -1 : 1;
}

function sortDate(a, b) {
  if (a === b) {
    return 0;
  }
  return moment(a, 'DD/MMM/YYYY').isAfter(moment(b, 'DD/MMM/YYYY')) ? -1 : 1;
}

function sortingStringOrStringNumber(a, b) {
  if (a.attributeType === 'date' && b.attributeType === 'date') {
    let yearA = a?.answer?.split('/')[2] || 'YYYY';
    let monthA = a?.answer?.split('/')[1] || 'UNK';
    let dayA = a?.answer?.split('/')[0] || 'UN';

    let yearB = b?.answer?.split('/')[2] || 'YYYY';
    let monthB = b?.answer?.split('/')[1] || 'UNK';
    let dayB = b?.answer?.split('/')[0] || 'UN';

    let ansA = `${parseInt(yearA) || yearA}/${monthA !== 'UNK' ? moment(monthA, 'MMM').format('MM') : monthA}/${dayA}`;
    let ansB = `${parseInt(yearB) || yearB}/${monthB !== 'UNK' ? moment(monthB, 'MMM').format('MM') : monthB}/${dayB}`;

    if (ansA === ansB) {
      return 0;
    }
    return ansA > ansB ? -1 : 1;
  }

  let ansA = (a?.answer || '').toLowerCase();
  let ansB = (b?.answer || '').toLowerCase();

  if (!isNaN(parseInt(ansA))) {
    ansA = parseInt(ansA);
  }
  if (!isNaN(parseInt(ansB))) {
    ansB = parseInt(ansB);
  }
  if (ansA === ansB) {
    return 0;
  }
  return ansA > ansB ? -1 : 1;
}

const performedStatusMap = {
  PS_UNAVAILABLE: 'Unavailable',
  PS_NOT_ASKED: 'Not Asked'
};
