import { reduce } from 'lodash/collection';

import canadianProvinces from './ISO-3166/CA.json';
import countries from './ISO-3166/countries.json';
import usStates from './ISO-3166/US.json';

const countryIdMap = reduce(
  countries,
  function(accumulator, value) {
    accumulator[value.id] = value;
    return accumulator;
  },
  {}
);

const canadianProvinceIdMap = reduce(
  canadianProvinces,
  function(accumulator, value) {
    accumulator[value.id] = value;
    return accumulator;
  },
  {}
);

const usStatesIdMap = reduce(
  usStates,
  function(accumulator, value) {
    accumulator[value.id] = value;
    return accumulator;
  },
  {}
);

export function getCountryById(id) {
  return countryIdMap[id];
}

export function getCountryName(id) {
  return getCountryById(id)?.name || null;
}

export function getRegionById(regions, id) {
  return regions.find(r => r.id === id);
}

export function getRegions(countryId) {
  return (
    {
      US: usStates,
      CA: canadianProvinces
    }[countryId] || []
  );
}

export function getRegionsMap(countryId) {
  return (
    {
      US: usStatesIdMap,
      CA: canadianProvinceIdMap
    }[countryId] || {}
  );
}

export function getRegion(id, countryId) {
  return getRegionsMap(countryId)[id];
}

export function getRegionName(id, countryId) {
  return getRegion(id, countryId)?.name || null;
}

export { canadianProvinces, countries, usStates };
