import React from 'react';
import moment from 'moment';

export default function StudyEnrollmentPredictionValueListInputs({
  enrollmentPredictionValues,
  currentMonth,
  ssuDateEnd,
  handleEnrollmentValueChange
}) {
  const inputs = enrollmentPredictionValues
    .filter(epv => {
      const epvDate = moment()
        .set({ year: epv.year, month: epv.month })
        .startOf('month');
      return epvDate.isSameOrAfter(currentMonth);
    })
    .map((epm, index) => (
      <div className="col-sm-2 col-md-2 col-lg-1 prediction-cont-1 prediction-input-width" key={index}>
        <label>
          {moment()
            .set({ year: epm.year, month: epm.month })
            .format('MMM/YYYY')}
        </label>
        <input
          id={index}
          name={`${epm.year} ${epm.month}`}
          className=" form-control"
          type="number"
          onChange={handleEnrollmentValueChange}
          value={epm.value}
          min="0"
        />
      </div>
    ));

  return (
    <div className="col-12  pb-4">
      <div className="row">
        <div className="col-2 previous-container">Predictions:</div>
        <div className="col-10">
          <div className="row predictions-all-items-1">{inputs}</div>
        </div>
      </div>
    </div>
  );
}
