export function calculateTextWidth(text, style = {}) {
  const oversight = 2;
  let div = document.createElement('span');
  div.innerText = text;
  div.style = style;
  document.body.appendChild(div);
  const width = div.offsetWidth;
  div.parentNode.removeChild(div);
  return width + oversight;
}
