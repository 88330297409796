import React, { useContext, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { VIEW_IG_AND_ENCOUNTER_AS_LINKS_AT_PI_WORK_LIST_PI_REVIEW } from '../../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../../services/auth';
import { generateUrlByKey, useCurrentRoute } from '../../../../../../root/router';
import { LOGS, NEW_TABLE } from '../../reviewConstants';
import { ReviewContext } from '../../ReviewContext';
import { ItemGroupElementHeaderWithIcon } from '../ItemGroupElement/ItemGroupElementHeaderWithIcon/ItemGroupElementHeaderWithIcon';

import './NoSourceData.scss';

export default function NoSourceData({ itemGroupName, itemGroupType, itemGroupId }) {
  const currentRoute = useCurrentRoute();
  const procedurePageKey = `${currentRoute.parent.key}.Procedure`;
  const newTablePageKey = `${currentRoute.parent.key}.Table`;
  const logsPageKey = `${currentRoute?.parent?.key}.Logs`;
  const { ssuPatientId, patientEncounterId, reviewType } = useContext(ReviewContext);

  const urlForItemGroupPage = useMemo(
    function() {
      if (reviewType === 'CRA' || !itemGroupType || !itemGroupId) {
        return null;
      }

      let pageKey = procedurePageKey;
      if (LOGS === itemGroupType) {
        pageKey = logsPageKey;
      } else if (NEW_TABLE === itemGroupType) {
        pageKey = newTablePageKey;
      }

      return generateUrlByKey(pageKey, {
        ssuPatientId,
        patientEncounterId,
        patientItemGroupId: itemGroupId,
        ...currentRoute.params
      });
    },
    [
      itemGroupId,
      procedurePageKey,
      itemGroupType,
      ssuPatientId,
      patientEncounterId,
      currentRoute.params,
      logsPageKey,
      newTablePageKey,
      reviewType
    ]
  );
  const showAsLink = useMemo(
    function() {
      return urlForItemGroupPage && userHasAccessTo(VIEW_IG_AND_ENCOUNTER_AS_LINKS_AT_PI_WORK_LIST_PI_REVIEW);
    },
    [urlForItemGroupPage]
  );
  const itemGroupElementHeaderContent = showAsLink ? (
    <NavLink to={urlForItemGroupPage}>{itemGroupName}</NavLink>
  ) : (
    itemGroupName
  );
  return (
    <>
      <ItemGroupElementHeaderWithIcon content={itemGroupElementHeaderContent} />
      <div className="erc-no-source-data-text">No source data to display.</div>
    </>
  );
}
