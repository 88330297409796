import React, { useContext, useEffect, useState } from 'react';
import { isFunction } from 'lodash/lang';
import { isEqual } from 'lodash/lang';

import useAppInfo from '../../../../common/hooks/useAppInfo';
import {
  PATIENT_ENCOUNTER_EVENT,
  PATIENT_SITUATIONAL_ENCOUNTER_EVENT,
  PATIENT_UNEXPECTED_ENCOUNTER_EVENT
} from '../CalendarEventType';
import { CalendarPageContext } from '../CalendarPageContext';
import { getInitialEventTypes, getInitialNonPatientEventTypes } from '../FiltersProvider';

import { EventTypeSelector } from './EventTypeSelector';

export function CalendarAppointmentFilter({ onChange }) {
  const { nonPatientEventTypeDropdownOptions } = useContext(CalendarPageContext);
  const appInfo = useAppInfo();
  const [nonPatientEventTypeOptions, setNonPatientEventTypeOptions] = useState([]);

  useEffect(() => {
    const nonPatientEventTypeOptions = nonPatientEventTypeDropdownOptions.map(option => {
      return {
        id: option.id,
        code: option.id,
        value: option.name,
        checked: true
      };
    });

    setNonPatientEventTypeOptions(nonPatientEventTypeOptions);
  }, [nonPatientEventTypeDropdownOptions]);

  const eventTypeOptions = appInfo?.features?.situationalEncountersEnabled
    ? [
        { id: 22, value: 'Unexpected Encounter', code: PATIENT_UNEXPECTED_ENCOUNTER_EVENT, checked: true },
        { id: 42, value: 'Situational Encounter', code: PATIENT_SITUATIONAL_ENCOUNTER_EVENT, checked: true },
        { id: 33, value: 'Protocol Encounter', code: PATIENT_ENCOUNTER_EVENT, checked: true }
      ]
    : [
        { id: 22, value: 'Unexpected Encounter', code: PATIENT_UNEXPECTED_ENCOUNTER_EVENT, checked: true },
        { id: 33, value: 'Protocol Encounter', code: PATIENT_ENCOUNTER_EVENT, checked: true }
      ];

  const [eventTypes, setEventTypes] = useState(null);
  const [nonPatientEventTypes, setNonPatientEventTypes] = useState(null);

  const onEventTypesChange = e => {
    if (!isEqual(e, eventTypes)) {
      setEventTypes(e);
    }
  };

  const onNonPatientEventTypeChange = changedNonPatientEventTypes => {
    if (!isEqual(changedNonPatientEventTypes, nonPatientEventTypes)) {
      setNonPatientEventTypes(changedNonPatientEventTypes);
    }
  };

  useEffect(() => {
    if (eventTypes && nonPatientEventTypes) {
      isFunction(onChange) && onChange({ types: eventTypes, nonPatientEventTypes: nonPatientEventTypes });
    }
  }, [eventTypes, onChange, nonPatientEventTypes]);

  return (
    <div className="calendar-event-type-filter-container mt-2">
      <EventTypeSelector
        optionEventTypes={eventTypeOptions}
        onEventTypesChange={onEventTypesChange}
        onNonPatientEventTypeChange={onNonPatientEventTypeChange}
        nonPatientEventTypeOptions={nonPatientEventTypeOptions}
        initialEventTypesProvider={getInitialEventTypes}
        initialNonPatientEventTypesProvider={getInitialNonPatientEventTypes}
      />
    </div>
  );
}
