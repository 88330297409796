export const DENY_ALL = 'DENY_ALL';
export const ALLOW_READ = 'ALLOW_READ';
export const ALLOW_EDIT = 'ALLOW_EDIT';
export const ALLOW_SIGN = 'ALLOW_SIGN';

export const permissionOptions = [
  {
    icon: 'cancel',
    status: 'Deny All',
    name: 'Deny all',
    key: DENY_ALL
  },
  {
    icon: 'visibility',
    status: 'Read Only',
    name: 'Allow all read only',
    key: ALLOW_READ
  },
  {
    icon: 'draw',
    status: 'Can Edit',
    name: 'Allow all read and edit',
    key: ALLOW_EDIT
  },
  {
    icon: 'check_circle',
    status: 'Can Sign',
    name: 'Allow all read, edit, sign',
    key: ALLOW_SIGN
  }
];

export const permissionStatusMap = permissionOptions.reduce((a, { key, status }) => ({ ...a, [key]: status }), {});

export function getPermissionOptionsByGroupType(type) {
  return permissionOptions.filter(function({ key }) {
    if (key === ALLOW_SIGN) {
      return ['SM', 'INVESTIGATOR'].includes(type);
    }
    return true;
  });
}
