import React, { useContext, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import Button from 'common/general/Button';
import ButtonGroup from 'common/general/ButtonGroup';
import useAppInfo from 'common/hooks/useAppInfo';
import { STDT_REIMBURSEMENT, STDT_TRAVEL, STTG_CTMS } from 'constants/study/studyTypes';
import {
  EXPORT_CASE_REPORT_FORM,
  MANAGE_PATIENT_OVERALL_INFORMATION,
  MANAGE_PATIENT_REIMBURSEMENT_NOTIFICATIONS,
  VIEW_TRAVEL_PREFERENCES
} from 'constants/userOperations';
import { some } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';
import { userHasAccessTo } from 'services/auth';
import { getPatientAgeFromDob, getPatientFullName, getPatientPreferredName } from 'services/patient';

import { PageInfoHeader } from 'components/PageInfoHeader/PageInfoHeader';
import {
  PET_NF,
  PET_PP,
  tabs
} from 'components/pages/patient-source/Patients/PatientInfo/PatientEnablementSection/constants';
import { generateUrlByKey, useCurrentRoute } from 'components/root/router';

import { dateFormatForShow } from '../PatientStatusChange/PatientStatusChangeConstants';

import EncountersSection from './EncountersSection/EncountersSection';
import { ICFSection } from './ICFSection/ICFSection';
import PatientEnablementSection from './PatientEnablementSection/PatientEnablementSection';
import { InstructionContextProvider } from './PatientInfoMainSection/PatientContent/InstructionContextProvider';
import PatientInfoMainSection from './PatientInfoMainSection/PatientInfoMainSection';
import PatientInfoSidebarMenu from './PatientInfoSidebar/PatientInfoSidebarMenu';
import TravelPreferences from './TravelPreferences/TravelPreferences';
import { PatientInfoContext, PatientInfoProvider } from './PatientInfoContext';

import './PatientInfo.scss';

function PatientInfo() {
  const { patientInfo, currentStudy, studyHistory } = useContext(PatientInfoContext);
  const { params, key, parent } = useCurrentRoute();
  const appInfo = useAppInfo();
  const patientId = params?.patientId;
  const ssuPatientId = params?.ssuPatientId;

  const patientPortalEnabled = appInfo?.features?.patientPortalEnabled;
  const reimbursementEmailOptIn = currentStudy?.reimbursementEmailOptIn;
  const reimbursementSelfSubmissionEnabled = currentStudy?.reimbursementSelfSubmissionEnabled;
  const accessRestricted = currentStudy?.accessRestricted;
  const studyType = currentStudy?.type;
  const studyTypes = currentStudy?.types;

  const userIsAbleToViewPatientMenu = useMemo(
    function() {
      return !accessRestricted && userHasAccessTo(EXPORT_CASE_REPORT_FORM);
    },
    [accessRestricted]
  );

  const userIsAbleToViewTravelPreferences = useMemo(
    function() {
      return some(studyHistory, function({ types }) {
        return types?.includes(STDT_TRAVEL);
      });
    },
    [studyHistory]
  );

  const patientEnablementTabs = useMemo(
    function() {
      if (studyType !== STTG_CTMS || !studyTypes?.some(e => e === STDT_REIMBURSEMENT)) {
        return null;
      }
      return tabs.filter(function(tab) {
        if (tab === PET_PP) {
          if (
            !userHasAccessTo(MANAGE_PATIENT_OVERALL_INFORMATION) ||
            !patientPortalEnabled ||
            !reimbursementSelfSubmissionEnabled
          ) {
            return false;
          }
        }
        if (tab === PET_NF) {
          if (!userHasAccessTo(MANAGE_PATIENT_REIMBURSEMENT_NOTIFICATIONS) || !reimbursementEmailOptIn) {
            return false;
          }
        }
        return true;
      });
    },
    [patientPortalEnabled, reimbursementEmailOptIn, reimbursementSelfSubmissionEnabled, studyType, studyTypes]
  );

  return (
    <>
      <PageInfoHeader
        objectRecordLabel={
          <NavLink to={generateUrlByKey(parent?.key, params)} className="patient-profile-header-link">
            {getPatientFullName(patientInfo) + getPatientPreferredName(patientInfo)}
          </NavLink>
        }
        pageInfo={
          <PageInfoHeader.CollapsibleList>
            <PageInfoHeader.AdditionalInfo title="Patient ID">{patientInfo?.patientId}</PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo title="Subject ID">
              {currentStudy?.patientSubjectId || 'No Subject ID'}
            </PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo tooltip="Date of Birth">
              {patientInfo?.dob?.format(dateFormatForShow)} ({getPatientAgeFromDob(patientInfo)})
            </PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo tooltip="Study">{currentStudy?.studyName}</PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo tooltip="Site">{currentStudy?.siteName}</PageInfoHeader.AdditionalInfo>
          </PageInfoHeader.CollapsibleList>
        }
        right={
          <ButtonGroup>
            {userIsAbleToViewPatientMenu && (
              <Button
                to={generateUrlByKey(`${key}.Export Case Report Forms`, {
                  patientId,
                  ssuPatientId
                })}
                priority="medium"
                size="h28"
              >
                Export
              </Button>
            )}
          </ButtonGroup>
        }
      />
      <div className="eds-patient-info">
        <div className="eds-patient-info-left">
          <PatientInfoSidebarMenu />
        </div>
        <div className="eds-patient-info-right">
          <InstructionContextProvider>
            <PatientInfoMainSection />
          </InstructionContextProvider>
          {!isEmpty(patientEnablementTabs) && <PatientEnablementSection tabs={patientEnablementTabs} />}
          {userHasAccessTo(VIEW_TRAVEL_PREFERENCES) && userIsAbleToViewTravelPreferences && <TravelPreferences />}
          <ICFSection />
          {currentStudy && <EncountersSection />}
        </div>
      </div>
    </>
  );
}

export default function PatientInfoComponent(props) {
  return (
    <PatientInfoProvider>
      <PatientInfo {...props} />
    </PatientInfoProvider>
  );
}
