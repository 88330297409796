export default function setValid(state, { type, payload: { path, value } }) {
  return {
    ...state,
    mileageAddresses: {
      ...state.mileageAddresses,
      [path]: {
        ...state.mileageAddresses[path],
        ...value
      }
    }
  };
}
