import {
  HOME_PAGE_DASHBOARDS,
  PI_WORK_LIST,
  PL,
  SCHEDULING_WORK_LIST,
  SM_WORKLIST
} from '../../../constants/accessToPages';
import { generatePatientInfoRouterConfig } from '../../root/router/sharedConfigs';
import InvestigatorWorklistNew from '../patient-source/InvestigatorWorklistNew/InvestigatorWorklistNew';
import SchedulingWorklist from '../patient-source/ScheduleWorklist/SchedulingWorklist';
import SmWorklist from '../patient-source/SmWorklistNew/SmWorklistNew';

import HomePageDashboards from './HomePageDashboards';

export const dashboardsRouterConfig = {
  name: 'Home Page',
  path: '/home-page',
  component: HomePageDashboards,
  access: [HOME_PAGE_DASHBOARDS],
  inherited: [
    ...generatePatientInfoRouterConfig(HOME_PAGE_DASHBOARDS, HOME_PAGE_DASHBOARDS),
    {
      name: 'Study Manager Worklist',
      path: '/sm-work-list',
      component: SmWorklist,
      access: [SM_WORKLIST],
      inherited: [...generatePatientInfoRouterConfig(PL, SM_WORKLIST)]
    },
    {
      name: 'Investigator Worklist',
      path: '/pi-work-list',
      component: InvestigatorWorklistNew,
      access: [PI_WORK_LIST],
      inherited: [...generatePatientInfoRouterConfig(PL, PI_WORK_LIST)]
    },
    {
      name: 'Scheduling Worklist',
      path: '/scheduling-work-list',
      component: SchedulingWorklist,
      access: [SCHEDULING_WORK_LIST],
      inherited: [...generatePatientInfoRouterConfig(PL, SCHEDULING_WORK_LIST)]
    }
  ]
};
