import React from 'react';

import EncounterSegment from './EncounterSegment/EncounterSegment';

export default function EncountersTabContent({ encounters, updatePatientEncountersWithAppointments }) {
  return encounters?.map(function(encounter, key) {
    return (
      <div className="encounter-container" key={key}>
        <EncounterSegment
          encounter={encounter}
          updatePatientEncountersWithAppointments={updatePatientEncountersWithAppointments}
        />
      </div>
    );
  });
}
