import React from 'react';

import Section from '../../../../../../common/data-display/Section/Section';
import TextArea from '../../../../../../common/data-entry/TextArea';

import './CommentSection.scss';

const CommentSection = ({ comment, changeComment, disabled = false, ...restProps }) => {
  return (
    <Section className="protocol-setup-comment">
      <span>Comment</span>
      <TextArea
        className="protocol-setup-comment-textarea"
        onChange={changeComment}
        value={comment}
        disabled={disabled}
        data-testid="comment-textarea"
        maxLength={5000}
        showCount
        required
        {...restProps}
      />
    </Section>
  );
};

export default CommentSection;
