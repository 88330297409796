import React, { useMemo } from 'react';

export default function withOptionalItemGroupsSetup(Component) {
  return function(props) {
    const { epochs, itemGroups, rowKey = `itemGroupKey` } = props;

    const encounters = useMemo(
      function() {
        if (!epochs) return null;
        return epochs.flatMap(({ encounters }) => encounters);
      },
      [epochs]
    );

    const dataMap = useMemo(
      function() {
        if (!encounters || !itemGroups) return {};
        return itemGroups.reduce(function(accumulator, { itemGroupType, ...rest }, itemGroupIndex) {
          const iKey = rest[rowKey];
          encounters.forEach(function({ encounterKey }, encounterIndex) {
            accumulator[`${itemGroupIndex}_${encounterIndex}`] = `${iKey}_${encounterKey}`;
          });
          return accumulator;
        }, {});
      },
      [rowKey, encounters, itemGroups]
    );

    return <Component {...props} dataMap={dataMap} />;
  };
}
