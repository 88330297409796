export function isSigned(logCheckSnapshotReviewState) {
  return logCheckSnapshotReviewState?.reviewState?.reviewStatus !== 'NOT_SIGNED';
}

export function isNotPerformed(logCheckSnapshotReviewState) {
  return logCheckSnapshotReviewState?.logCheckSnapshotState?.logCheckSnapshot.notPerformed;
}

export function isDisabled(logCheckSnapshotReviewState) {
  return (
    (!isNotPerformed(logCheckSnapshotReviewState) &&
      !logCheckSnapshotReviewState?.logCheckSnapshotState?.logCheckSnapshot.logCheckAnswers.length) ||
    logCheckSnapshotReviewState?.logCheckSnapshotState?.logCheckSnapshot.logCheckAnswers.length === 0
  );
}
