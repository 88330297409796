import axios from 'axios';

const ROOT = 'api/v1/portal-patients';

const PortalPatientApi = {
  inviteToPortal(patientId) {
    return axios.post(`${ROOT}/${patientId}`, {}, { hideLoader: true });
  },
  getPatientWithDuplicatedEmail(patientId, email) {
    return axios.post(`${ROOT}/${patientId}/emails`, email, {
      hideLoader: true,
      headers: { 'Content-Type': 'text/plain' }
    });
  }
};

export default PortalPatientApi;
