import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, Box, Grid, InputAdornment, MenuItem, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useFormikContext } from 'formik';
import moment from 'moment';

import PatientReimbursementApi from '../../../../../../../api/patient/PatientReimbursementApi';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../../../constants/dateFormat';
import { STTG_CTMS } from '../../../../../../../constants/study/studyTypes';
import EuiFileUploadDropzone from '../../../../../../eui/EuiFileUploadProvider/EuiFileUploadDropzone';
import EuiFileUploadList from '../../../../../../eui/EuiFileUploadProvider/EuiFileUploadList/EuiFileUploadList';
import EuiFileUploadPreview from '../../../../../../eui/EuiFileUploadProvider/EuiFileUploadPreview';
import EuiSsuPatientInfoChips from '../../../../../../eui/EuiSsuPatientInfoChips/EuiSsuPatientInfoChips';
import { RBIT_MILEAGE, reimbursementItemTypes } from '../../EncountersSection/PaymentsSection/reimbursementConstants';

import MileageAddresses from './MileageAddresses/MileageAddresses';
import withPaymentsItemsForm from './withPaymentsItemsForm';

import './AddOrEditPaymentsItemsModal.scss';

const accept = ['application/pdf', 'image/png', 'image/jpeg'];

const AddOrEditPaymentsItemsModal = withPaymentsItemsForm(function AddOrEditPaymentsItems({
  modalBox,
  editMode,
  ssuPatientInfo,
  ssuPatientId,
  patientId,
  studyId,
  studyType,
  viewMode = false,
  ssuId,
  reimbursementId = null
}) {
  const {
    values,
    initialValues,
    setFieldValue,
    setFieldTouched,
    submitForm,
    touched,
    errors,
    handleBlur,
    handleChange,
    isSubmitting
  } = useFormikContext();

  const [encounters, setEncounters] = useState([]);

  const { encounterDisabled, encounterDateDisabled, reimbursementTypeDisabled, reimbursementViewOnlyMode } = useMemo(
    function() {
      const encounter = values.encounter;
      const encounterDisabled = !!reimbursementId;
      const reimbursementViewOnlyMode = viewMode;
      const encounterDateDisabled = isEncounterDateDisabled(studyType, encounter?.encounterStatus, viewMode);
      const reimbursementTypeDisabled = editMode;

      return {
        encounterDisabled,
        encounterDateDisabled,
        reimbursementTypeDisabled,
        reimbursementViewOnlyMode
      };
    },
    [values.encounter, reimbursementId, studyType, editMode, viewMode]
  );

  useEffect(() => {
    if (editMode || viewMode) return;
    PatientReimbursementApi.getEncounters(ssuPatientId).then(res => {
      setEncounters(res.data);
    });
  }, [editMode, ssuPatientId, viewMode]);

  useEffect(
    function() {
      const encounter = values.encounter;
      if (encounter && encounter.encounterStatus !== 'CANCELED') {
        setFieldValue('encounterDate', encounter.encounterDate ? moment(encounter.encounterDate) : moment());
        setTimeout(() => setFieldTouched('encounterDate', true, true));
      } else {
        setFieldValue('encounterDate', moment());
      }
    },
    [values.encounter, setFieldValue, setFieldTouched]
  );

  return (
    <>
      <ModalBoxes.Header>Reimbursement item</ModalBoxes.Header>
      <ModalBoxes.Body>
        <Box sx={{ flexGrow: 1 }} component="form">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <EuiSsuPatientInfoChips ssuPatientInfo={ssuPatientInfo} />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                disabled={encounterDisabled || reimbursementViewOnlyMode}
                value={values.encounter}
                onChange={function({ target }, encounter) {
                  setFieldValue('encounter', encounter);
                }}
                onBlur={handleBlur}
                fullWidth
                getOptionKey={option =>
                  option.sitePatientEncounterId ||
                  option.situationalEncounterId ||
                  option.protocolEncounterKey ||
                  option.eventId
                }
                getOptionLabel={option => option.encounterName}
                disablePortal
                options={encounters}
                renderInput={params => (
                  <TextField
                    {...params}
                    name="encounter"
                    label="Encounter"
                    variant="standard"
                    required
                    error={touched.encounter && Boolean(errors.encounter)}
                    helperText={touched.encounter && errors.encounter}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <DesktopDatePicker
                disableFuture={true}
                disabled={encounterDateDisabled}
                name="encounterDate"
                value={values.encounterDate}
                onChange={date => setFieldValue('encounterDate', date)}
                label="Encounter Date"
                format={DD_SLASH_MMM_SLASH_YYYY}
                views={['year', 'month', 'day']}
                slotProps={{
                  textField: {
                    variant: 'standard',
                    required: true,
                    fullWidth: true,
                    error: touched.encounterDate && Boolean(errors.encounterDate),
                    helperText: touched.encounterDate && errors.encounterDate,
                    onBlur: handleBlur
                  }
                }}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled={reimbursementTypeDisabled || reimbursementViewOnlyMode}
                name="type"
                value={values.type}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.type && Boolean(errors.type)}
                helperText={touched.type && errors.type}
                fullWidth
                select
                label="Reimbursement Type"
                variant="standard"
                required
              >
                {reimbursementItemTypes.map(e => (
                  <MenuItem key={e.id} value={e.id}>
                    {e.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              {values.type !== RBIT_MILEAGE && (
                <TextField
                  name="amount"
                  disabled={reimbursementViewOnlyMode}
                  value={values.amount ? values.amount / 100 : ''}
                  onChange={function({ target }) {
                    const prepareValue = target.value ? Math.abs(+target.value)?.toFixed(2) : null;
                    const newAmount = prepareValue ? (prepareValue * 100).toFixed(0) : prepareValue;
                    if (newAmount > 200000) return;
                    setFieldValue(target.name, newAmount);
                  }}
                  onBlur={handleBlur}
                  error={touched.amount && Boolean(errors.amount)}
                  helperText={touched.amount && errors.amount}
                  fullWidth
                  label="Amount"
                  variant="standard"
                  required
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    type: 'number',
                    min: 0,
                    max: 2000,
                    inputProps: { min: 0, max: 10 }
                  }}
                />
              )}
            </Grid>
            {values.type === RBIT_MILEAGE && (
              <Grid item xs={12}>
                <MileageAddresses
                  patientId={patientId}
                  studyId={studyId}
                  ssuId={ssuId}
                  initialMileageAddresses={initialValues.mileageAddresses}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                name="note"
                value={values.note}
                disabled={reimbursementViewOnlyMode}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.note && Boolean(errors.note)}
                fullWidth
                label="Comment"
                multiline
                maxRows={4}
                variant="standard"
                inputProps={{ maxLength: 200 }}
                helperText={`${(touched.note && errors.note) || ''} ${values.note?.length || 0}/200`}
              />
            </Grid>
            <Grid item xs={12}>
              {!reimbursementViewOnlyMode && (
                <EuiFileUploadDropzone multiple accept={accept} error={touched.files ? errors.files : null} />
              )}
            </Grid>
            <Grid item xs={12}>
              <EuiFileUploadList viewOnly={reimbursementViewOnlyMode} />
            </Grid>
          </Grid>
        </Box>
        <EuiFileUploadPreview />
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        {reimbursementViewOnlyMode ? (
          <ButtonGroup>
            <Button priority="medium" onClick={modalBox.close}>
              Close
            </Button>
          </ButtonGroup>
        ) : (
          <ButtonGroup>
            <Button priority="medium" onClick={modalBox.close}>
              Cancel
            </Button>
            <Button loading={isSubmitting} onClick={submitForm}>
              Save
            </Button>
          </ButtonGroup>
        )}
      </ModalBoxes.Footer>
    </>
  );
});

AddOrEditPaymentsItemsModal.className = 'add-payments-items-modal';

export default AddOrEditPaymentsItemsModal;

function isEncounterDateDisabled(studyType, encounterStatus, viewMode) {
  if (viewMode) {
    return true;
  }
  if (studyType !== STTG_CTMS) {
    return encounterStatus && encounterStatus !== 'CANCELED';
  }
  return false;
}
