import React, { useMemo, useState } from 'react';
import { isEmpty } from 'lodash/lang';
import * as PropTypes from 'prop-types';

import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../../common/general/ButtonGroup';
import { CLOSED } from '../../../../../../../../constants/ssuStatuses';
import { ROLE_SYSTEM_ADMINISTRATOR } from '../../../../../../../../constants/userRoles';
import { userHasRole } from '../../../../../../../../services/auth';
import StudySiteStatusSourceSelect from '../../StudySiteStatusSourceSelect';

import './AddNewStudyModal.scss';

export default function AddNewStudyModal(props) {
  const { modalBox, onSave, studiesToReject } = props;
  const [validationMessages, setValidationMessages] = useState({});
  const [source, setSource] = useState(null);
  const [status, setStatus] = useState(null);
  const [selectedStudy, setSelectedStudy] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);

  const someDataIsMissed = useMemo(
    function() {
      return !source || !status || !selectedStudy || !selectedSite;
    },
    [source, status, selectedStudy, selectedSite]
  );

  return (
    <>
      <ModalBoxes.Header>Add New Study</ModalBoxes.Header>
      <ModalBoxes.Body>
        <StudySiteStatusSourceSelect
          onSSUFilterChange={onSSUFilterChange}
          setSource={setSource}
          source={source}
          setStatus={setStatus}
          status={status}
          studiesToReject={studiesToReject}
          getValidationMessage={getValidationMessage}
        />
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button className="ansm-button" priority="medium" onClick={modalBox.close}>
            Cancel
          </Button>
          <Button
            className="ansm-button"
            disabled={someDataIsMissed}
            onClick={() => {
              addPatientToStudy();
            }}
          >
            Add
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );

  function onSSUFilterChange(ssus, study, site) {
    setSelectedStudy(study);
    if (site) {
      const ssu = ssus.find(e => e.site.uniqueIdentifier === site.uniqueIdentifier);
      setSelectedSite(ssu);
    } else {
      setSelectedSite(null);
    }
  }

  function addPatientToStudy() {
    const validationMessages = validateInfo({ studySite: selectedSite });
    setValidationMessages(validationMessages);
    if (someDataIsMissed || !isEmpty(validationMessages)) {
      return;
    }
    onSave({
      studyIdentifier: selectedStudy.uniqueIdentifier,
      digitalPaymentAllowed: selectedStudy?.digitalPatientPayment,
      patientSourceId: source.id,
      patientStatusName: status.name,
      studySiteIdentifier: selectedSite.uniqueIdentifier
    });
  }

  function getValidationMessage(field) {
    return validationMessages[field];
  }
}

function validateInfo(info) {
  const { studySite } = info;
  const validationObject = {};
  if (!userHasRole(ROLE_SYSTEM_ADMINISTRATOR) && studySite?.siteStatus === CLOSED) {
    validationObject.site = 'SSU is Closed';
  }
  return validationObject;
}

AddNewStudyModal.propTypes = {
  modalBox: PropTypes.object,
  onSave: PropTypes.func,
  studiesToReject: PropTypes.array
};

AddNewStudyModal.className = 'add-new-study-modal';
AddNewStudyModal.size = 'w650';
