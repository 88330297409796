export const STDT_TRADITIONAL = 'Traditional';
export const STDT_PATIENT_SELECT = 'Patient Select';
export const STDT_FINANCIAL_MANAGEMENT = 'Financial Management';
export const STDT_TRAVEL = 'Travel';
export const STDT_STIPEND = 'Stipend';
export const STDT_REIMBURSEMENT = 'Reimbursement';
export const STDT_CAREGIVER = 'Caregiver';
export const STDT_REFERRAL = 'Referral';

export const STTG_E_SOURCE = 'E_SOURCE';
export const STTG_CTMS = 'CTMS';
export const STTG_INTERNAL_PROJECT = 'INTERNAL_PROJECT';

export const STDT_TO_STTG_MAP = {
  [STDT_TRADITIONAL]: STTG_E_SOURCE,
  [STDT_PATIENT_SELECT]: STTG_E_SOURCE,
  [STDT_FINANCIAL_MANAGEMENT]: STTG_CTMS,
  [STDT_TRAVEL]: STTG_CTMS,
  [STDT_STIPEND]: STTG_CTMS,
  [STDT_REIMBURSEMENT]: STTG_CTMS,
  [STDT_CAREGIVER]: STTG_INTERNAL_PROJECT,
  [STDT_REFERRAL]: STTG_INTERNAL_PROJECT
};

export const STTG_TO_DISPLAY_NAME_MAP = {
  [STTG_E_SOURCE]: 'eSource',
  [STTG_CTMS]: 'CTMS',
  [STTG_INTERNAL_PROJECT]: 'Internal Project'
};

export const STTS_PAYMENT_TYPES = [STDT_STIPEND, STDT_REIMBURSEMENT];

export const STTS_ESOURCE_TYPES = [STDT_TRADITIONAL, STDT_PATIENT_SELECT];

export function getStudyTypeGroup(studyType) {
  return STDT_TO_STTG_MAP[studyType] || null;
}
