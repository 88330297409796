import React from 'react';
import * as PropTypes from 'prop-types';

import Select from '../../../../../../common/data-entry/Select';

import useIcfConfig from './hooks/useIcfConfig';

import './IcfSetup.scss';

export default function IcfSetup({ onChangeType, onChangeLanguage, informedConsentTemplate }) {
  const { icfConfig } = useIcfConfig();
  return (
    <>
      <IcfSection title={'ICF Type and Language'}>
        <div className={'p-2'}>Select a type and language value from the provided dropdowns</div>
        <IcfField title={'Type of ICF'}>
          <Select
            legacyLook={true}
            optionLabelKey="type"
            value={icfConfig?.types?.find(e => e.id === informedConsentTemplate?.typeId)}
            onChange={onChangeType}
            dataSource={icfConfig?.types}
            disabled={!icfConfig?.types}
          />
        </IcfField>
        <IcfField title={'ICF Language'}>
          <Select
            legacyLook={true}
            optionLabelKey="language"
            onChange={onChangeLanguage}
            value={icfConfig?.languages?.find(e => e.id === informedConsentTemplate?.languageId)}
            dataSource={icfConfig?.languages}
            disabled={!icfConfig?.languages}
          />
        </IcfField>
      </IcfSection>
    </>
  );
}

function IcfField(props) {
  const { title, children } = props;
  return (
    <div className={'icf-field'}>
      <div className={'icf-field-label'}>{title}</div>
      {children}
    </div>
  );
}
IcfField.propTypes = {
  title: PropTypes.string
};

function IcfSection(props) {
  const { title, children } = props;
  return (
    <div className={'icf-section'}>
      <div className={'icf-section-header'}>{title}</div>
      {children}
    </div>
  );
}
IcfSection.propTypes = {
  title: PropTypes.string
};
