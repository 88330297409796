import React, { useEffect, useState } from 'react';
import { Menu, MenuItem } from '@szhsin/react-menu';
import { isEmpty } from 'lodash/lang';
import moment from 'moment-timezone';

import { CalendarInstanceApi, EventFinderApi } from '../../../../../api';
import Loader from '../../../../../common/elements/Loader/Loader';
import Button from '../../../../../common/general/Button';
import Icon from '../../../../../common/general/Icon';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { EVENTS_DISPLAY } from '../../../../../constants/notificationMessages';
import { getTimeZoneName } from '../../../Calendar/CalendarBig/CalendarBig';
import { prepareTimeZones } from '../../../Calendar/CalendarBig/CalendarBigService';
import CalendarOneDayView from '../../../Calendar/CalendarBig/CalendarOneDayView';
import { APPOINTMENT_TYPES } from '../../../Calendar/CalendarEventType';
import { processSiteTimeZones } from '../../../Calendar/CalendarPage';
import { fromBackendCalendars } from '../../../Calendar/EventTransformer';
import { getInitialTimeZone, setInitialTimeZone } from '../../../Calendar/FiltersProvider';
import { NON_PATIENT_EVENT_TYPE } from '../../../Calendar/NonPatientAppointmentEdit/NonPatientEventType';

import './CalendatAppointmentWidget.scss';
import '../Widgets.scss';

const requestDateFormat = 'YYYY-MM-DDTHH:mm:ss.000Z';
export default function CalendarAppointmentWidget({ widget, studies }) {
  const [calendars, setCalendars] = useState([]);
  const [preparedTimeZones, setPreparedTimeZones] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const updateData = studies => {
    const studyCalendars = [...studies].map(study => ({ ...study, type: 'STUDY', label: 'Study' }));
    const request = {
      calendars: studyCalendars,
      startDate: moment()
        .startOf('day')
        .format(requestDateFormat),
      endDate: moment()
        .endOf('day')
        .format(requestDateFormat),
      eventStatuses: ['SCHEDULED', 'ACTIVE', 'COMPLETED', 'CANCELLED'],
      eventTypes: APPOINTMENT_TYPES,
      nonPatientEventTypes: Object.keys(NON_PATIENT_EVENT_TYPE)
    };
    setIsFetching(true);
    EventFinderApi.getEventsByFilterParams(request, moment.tz.guess()).then(resp => {
      resp.data.forEach(calendar => {
        calendar.milestones = [];
      });
      const calendars = fromBackendCalendars(resp.data);
      setCalendars(calendars);
      setIsFetching(false);
    });
  };

  useEffect(() => {
    !isEmpty(studies) && updateData(studies);
  }, [studies]);

  useEffect(() => {
    CalendarInstanceApi.getAvailableSiteTimeZones().then(res => {
      const siteTimeZones = processSiteTimeZones(res.data);
      const savedTimeZone = getInitialTimeZone();
      const timeZone = prepareTimeZones(siteTimeZones).filter(e => e.id === savedTimeZone?.id);
      if (savedTimeZone && !isEmpty(timeZone)) {
        setSelectedTimeZone(timeZone[0]);
      } else {
        setSelectedTimeZone(prepareTimeZones(siteTimeZones)[0]);
      }
      setPreparedTimeZones(prepareTimeZones(siteTimeZones));
    });
  }, []);

  return (
    <div className="p-3 widget-container widget-calendar-appointments size-m" style={{ minHeight: '700px' }}>
      <div className="widget">
        <div className="widget-header p-3">
          <div className="title">{widget.name}</div>
          <span>
            <span className="display-inline">
              <Menu
                align="end"
                menuButton={
                  <Button size="h28" onClick={() => {}} priority="medium" className="mx-2" disabled={isFetching}>
                    <Icon>date_range</Icon>
                    Time Zone
                  </Button>
                }
              >
                {preparedTimeZones.map(timeZone => (
                  <MenuItem
                    key={timeZone.id}
                    onClick={() => {
                      if (timeZone) {
                        setSelectedTimeZone(timeZone);
                        setInitialTimeZone(timeZone);
                        if (selectedTimeZone?.timeZoneNameToDisplay !== timeZone?.timeZoneNameToDisplay) {
                          NotificationManager.success(`${EVENTS_DISPLAY} ${timeZone.timeZoneNameToDisplay}`);
                        }
                      } else {
                        setSelectedTimeZone(preparedTimeZones[0]);
                      }
                    }}
                  >
                    <div>{getTimeZoneName(timeZone)}</div>
                    <strong className={'c-p'}>{timeZone.siteName}</strong>
                  </MenuItem>
                ))}
              </Menu>
            </span>
            <Button size="h28" onClick={() => updateData(studies)} priority="medium" disabled={isFetching}>
              <Icon>restart_alt</Icon>
            </Button>
          </span>
        </div>
        {selectedTimeZone && <span className="pb-2 pr-3 text-align-right">{getTimeZoneName(selectedTimeZone)}</span>}
        {isFetching && <Loader dark={true} />}
        {!isFetching && (
          <CalendarOneDayView calendars={calendars} displayedDate={moment()} selectedTimeZone={selectedTimeZone} />
        )}
      </div>
    </div>
  );
}
