import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './Divider.scss';

export default function Divider({ className, type = 'horizontal' }) {
  return <div className={cx(`eui-divider-${type}`, className)} />;
}

Divider.propTypes = {
  type: PropTypes.oneOf(['horizontal', 'vertical'])
};
