import axios from 'axios';

const ROOT = 'api/v1/site-patients';

const PatientEncounterSearchApi = {
  allCounts(request) {
    return axios.post(`${ROOT}/${request.ssuPatientId}/search/counts`, request, {
      hideLoader: true
    });
  },
  searchByEncounters(request, token) {
    return axios.post(`${ROOT}/${request.ssuPatientId}/search/encounters`, request, {
      hideLoader: true,
      cancelToken: token
    });
  },
  searchByProcedures(request, token) {
    return axios.post(`${ROOT}/${request.ssuPatientId}/search/procedures`, request, {
      hideLoader: true,
      cancelToken: token
    });
  },
  searchByLogs(request, token) {
    return axios.post(`${ROOT}/${request.ssuPatientId}/search/logs`, request, {
      hideLoader: true,
      cancelToken: token
    });
  }
};

export default PatientEncounterSearchApi;
