import React, { Component } from 'react';
import { connect } from 'react-redux';

import { PatientSourceApi } from '../../../../api';
import Select from '../../../../common/data-entry/Select';

class PatientSourceSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sources: [],
      types: []
    };
  }

  sortedSources(sources, by) {
    return sources.sort((a, b) => {
      if (a[by].toLowerCase() < b[by].toLowerCase()) return -1;
      if (a[by].toLowerCase() > b[by].toLowerCase()) return 1;
      return 0;
    });
  }

  filteredOutAPISources(sources) {
    return sources.filter(source => source.type !== 'API');
  }

  componentDidMount() {
    PatientSourceApi.getAllByTypeSource().then(({ data }) => {
      this.setState({
        sources: this.filteredOutAPISources(this.sortedSources(this.sortedSources(data, 'source'), 'type'))
      });
    });
  }

  customOptionTemplateFunction = ({ source }) => {
    return <span title={source}>{source}</span>;
  };

  onChangeSource = item => {
    item && this.props.setPatientSource(item);
  };

  render() {
    return (
      <Select
        customOptionTemplateFunction={this.customOptionTemplateFunction}
        dataSource={this.state.sources}
        onChange={this.onChangeSource}
        optionLabelKey="source"
        label="Source"
        searchable
        searchPlaceholder="Source"
        clearable={false}
        required={this.props.requiredField}
        groupBy="type"
        disabled={this.props.disabled}
        validate={false}
      />
    );
  }
}

export default connect(null, null)(PatientSourceSelect);
