import { TIMELINE } from '../../../../constants/accessToPages';

import StudyTimeline from './StudyTimeline';

export const timelineRouterConfig = {
  name: 'Timeline',
  path: '/study-timeline',
  component: StudyTimeline,
  access: [TIMELINE]
};
