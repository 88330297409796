export const DOMAIN_NAME = 'domainName';
export const TYPE = 'type';
export const TERMINOLOGY_VERSION = 'terminologyVersionGroupNumber';
export const NAME = 'name';
export const FILTERS_MAP = {
  [DOMAIN_NAME]: '',
  [TYPE]: '',
  [TERMINOLOGY_VERSION]: '',
  [NAME]: ''
};
export const FILTERS_KEYS = Object.keys(FILTERS_MAP);
