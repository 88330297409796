import React, { useContext, useMemo } from 'react';
import cx from 'classnames';
import * as PropTypes from 'prop-types';

import StyledTooltip from '../../../../../../../../../common/widgets/StyledToolTip';
import InvestigatorFieldIcon from '../../../../../EncounterDescNew/InvestigatorFields/InvestigatorFieldIcon/InvestigatorFieldIcon';
import { performedStatusLabelsMap } from '../../../../../EncounterDescNew/TableItemGroup/constants';
import TableItemGroupField from '../../../../../EncounterDescNew/TableItemGroup/TableItemGroupField/TableItemGroupField';
import { HighlightForReviewIcon } from '../../../../HighlightForReviewIcon/HighlightForReviewIcon';
import { ReviewContext } from '../../../../ReviewContext';
import { isSmOrPiReview } from '../../../../reviewService';

import './QuestionAnswer.scss';

const ddTypes = ['dropdown', 'dropdownCT'],
  msTypes = ['multiselect', 'multiselectCT'],
  needsATip = [...ddTypes, ...msTypes];

export default function QuestionAnswer({ isItemGroupConfirmed, snapshotAnswers, answer }) {
  const { reviewType } = useContext(ReviewContext);
  const currentAnswer = useMemo(
    function() {
      return snapshotAnswers.find(
        ({ protocolItemDefinitionId }) =>
          protocolItemDefinitionId === (answer?.itemGroupQuestionId || answer?.question?.questionId)
      );
    },
    [snapshotAnswers, answer?.itemGroupQuestionId, answer?.question?.questionId]
  );

  const title = useMemo(
    function() {
      if (!currentAnswer || !needsATip.includes(currentAnswer.fieldType)) {
        return null;
      }
      if (ddTypes.includes(currentAnswer.fieldType)) {
        return currentAnswer.fieldValue;
      }
      if (msTypes.includes(currentAnswer.fieldType)) {
        const parsedValue = JSON.parse(currentAnswer.fieldValue);
        return parsedValue?.join(', ') || null;
      }
      return null;
    },
    [currentAnswer]
  );

  if (!currentAnswer) {
    return null;
  }

  const { fieldType, fieldComment, performedStatus } = currentAnswer;

  return (
    <div
      className={cx('erc-question-answer', {
        'erc-question-answer-edited': isSmOrPiReview(reviewType) && answer?.isEditedAfterSign
      })}
    >
      {currentAnswer.investigatorField && <InvestigatorFieldIcon />}
      <div className="erc-question-answer-label">{answer.question.label}</div>
      {title ? (
        <StyledTooltip
          overlay={<div style={{ maxWidth: '200px' }}>{title}</div>}
          align={{
            offset: [0, -15]
          }}
          placement="bottom"
        >
          <div className="erc-question-answer-field">
            {!['reminder', 'textBlockLong'].includes(fieldType) && (
              <TableItemGroupField field={currentAnswer} readonly />
            )}
          </div>
        </StyledTooltip>
      ) : (
        <div className="erc-question-answer-field">
          {!['reminder', 'textBlockLong'].includes(fieldType) && <TableItemGroupField field={currentAnswer} readonly />}
        </div>
      )}

      <div className="erc-question-answer-highlight">
        <HighlightForReviewIcon highlighted={answer.highlighted} confirmed={isItemGroupConfirmed} />
      </div>
      <div className="erc-question-answer-field-comment">
        {fieldComment && `${performedStatusLabelsMap[performedStatus]}: ${fieldComment}`}
      </div>
    </div>
  );
}

QuestionAnswer.propTypes = {
  answer: PropTypes.object.isRequired
};
