import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactTable from 'react-table';
import { find, isEqual } from 'lodash';
import { cloneDeep, isEmpty } from 'lodash/lang';
import moment from 'moment/moment';

import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import Icon from '../../../../../../common/general/Icon';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import HistoryBlock, { MEDICATIONS } from '../../../../../root/Container/HistoryBlock';
import ReactTableHoc from '../../../shared/Worklist/ReactTableHoc/ReactTableHoc';
import { usePatientProfile } from '../PatientProfileContext';
import { convertToFirstDay, showConfirmDialog } from '../service';
import { ReadOnlyCellTextField } from '../shared/ReadOnlyCellTextField/ReadOnlyCellTextField';
import ActionColumnContent from '../shared/TableActions/ActionColumnContent';
import TableDate from '../shared/TableDate/TableDate';
import { CellTextField } from '../shared/TableTextField/CellTextField';

import CellDropdown from './CellDropdown';
import { PatientMedicationsAPI } from './PatientMedicationsAPI';

import './Medications.scss';

const newMedication = {
  medication: null,
  dosage: '',
  doseUnit: null,
  frequency: null,
  startDate: '',
  endDate: '',
  indication: '',
  onGoing: '',
  id: null,
  newRow: true
};

export default function Medications() {
  const { patientId } = useParams();
  const { userCanEdit } = usePatientProfile();
  const [data, setData] = useState([]);
  const [medications, setMedications] = useState([]);
  const [frequencies, setFrequencies] = useState([]);
  const [doseUnits, setDoseUnits] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [notSavedRow, setNotSavedRow] = useState({});

  const getPatientMedications = useCallback(
    function() {
      PatientMedicationsAPI.getPatientMedications(patientId).then(result => {
        setInitialData(result.data);
        setData(result.data);
      });
    },
    [patientId]
  );

  const getMedications = useCallback(function() {
    PatientMedicationsAPI.getAllMedications().then(res => {
      setMedications(res.data);
    });
  }, []);

  const getFrequencies = useCallback(function() {
    PatientMedicationsAPI.getAllFrequencies().then(res => {
      setFrequencies(res.data);
    });
  }, []);

  const getDoseUnits = useCallback(function() {
    PatientMedicationsAPI.getAllDoseUnits().then(res => {
      setDoseUnits(res.data);
    });
  }, []);

  useEffect(() => {
    getPatientMedications();
    getMedications();
    getFrequencies();
    getDoseUnits();
  }, [getDoseUnits, getFrequencies, getMedications, getPatientMedications]);

  useEffect(
    function() {
      const unblock = HistoryBlock.block(MEDICATIONS, function(discard) {
        if (!isEqual(data, initialData)) {
          showConfirmDialog('medications')
            .then(() => {
              discard();
            })
            .catch(() => {});
        } else {
          return true;
        }
        return false;
      });
      return function() {
        unblock();
      };
    },
    [data, initialData]
  );

  const columns = [
    {
      Header: 'Medication',
      accessor: 'medication',
      minWidth: 120,
      Cell: ({ original, value }) => {
        return userCanEdit ? (
          <CellDropdown value={value} onChange={e => onSelectChange(original, e, 'medication')} options={medications} />
        ) : (
          <ReadOnlyCellTextField value={value?.name} />
        );
      }
    },
    {
      Header: 'Dose',
      accessor: 'dosage',
      minWidth: 80,
      Cell: ({ original, value }) => {
        return userCanEdit ? (
          <CellTextField
            placeholder={'Enter Dosage'}
            onChange={e => onSelectChange(original, e.target.value, 'dosage')}
            value={value}
          />
        ) : (
          <ReadOnlyCellTextField value={value} />
        );
      }
    },
    {
      Header: 'Dose Unit',
      accessor: 'doseUnit',
      minWidth: 80,
      Cell: ({ original, value }) => {
        return userCanEdit ? (
          <CellDropdown value={value} onChange={e => onSelectChange(original, e, 'doseUnit')} options={doseUnits} />
        ) : (
          <ReadOnlyCellTextField value={value?.name} />
        );
      }
    },
    {
      Header: 'Frequency',
      accessor: 'frequency',
      minWidth: 100,
      Cell: ({ original, value }) => {
        return userCanEdit ? (
          <CellDropdown
            value={value}
            onChange={e => {
              onSelectChange(original, e, 'frequency');
            }}
            options={frequencies}
          />
        ) : (
          <ReadOnlyCellTextField value={value?.name} />
        );
      }
    },
    {
      Header: 'Start Date',
      accessor: 'startDate',
      minWidth: 100,
      Cell: ({ value, original }) => {
        return userCanEdit ? (
          <TableDate
            value={value}
            placeholder={'Start date'}
            onChange={(e, formattedDate) => {
              onSelectChange(original, formattedDate, 'startDate');
            }}
          />
        ) : (
          <ReadOnlyCellTextField value={value} />
        );
      }
    },
    {
      Header: 'End Date',
      accessor: 'endDate',
      minWidth: 100,
      Cell: ({ value, original }) => {
        return userCanEdit ? (
          <TableDate
            value={value}
            placeholder={'End date'}
            onChange={(e, formattedDate) => {
              onSelectChange(original, formattedDate, 'endDate');
            }}
          />
        ) : (
          <ReadOnlyCellTextField value={value} />
        );
      }
    },
    {
      Header: 'Ongoing',
      accessor: 'onGoing',
      minWidth: 80,
      Cell: ({ original, value }) => {
        return userCanEdit ? (
          <CellDropdown
            value={getOngoingValue(value)}
            isDisabled={!isEmpty(original.endDate)}
            onChange={e => {
              const value = e.name === 'Yes';
              onSelectChange(original, value, 'onGoing');
            }}
            options={[
              { id: 1, name: 'Yes' },
              { id: 2, name: 'No' }
            ]}
          />
        ) : (
          <ReadOnlyCellTextField value={getOngoingValue(value)?.name} />
        );
      }
    },
    {
      Header: 'Indication',
      accessor: 'indication',
      minWidth: 80,
      Cell: ({ original, value }) => {
        return userCanEdit ? (
          <CellTextField
            placeholder={'Enter Indication'}
            onChange={e => onSelectChange(original, e.target.value, 'indication')}
            value={value}
          />
        ) : (
          <ReadOnlyCellTextField value={value?.name} />
        );
      }
    }
  ];

  if (userCanEdit) {
    columns.push({
      Header: 'Actions',
      width: 70,
      Cell: ({ original }) => {
        return (
          <ActionColumnContent
            onSaveButtonClick={() => saveRowData(original)}
            saveButtonDisabled={checkIfRowCanBeSaved(original)}
            onDeleteButtonClick={() => deleteMedication(original)}
          />
        );
      }
    });
  }

  return (
    <div className="patient-profile-medications">
      <div className="patient-profile-medications-header">
        <h5>Medications</h5>
        {userCanEdit && (
          <Button id="patient-profile-add-med-btn" onClick={addNewRow} priority="medium" size="h28">
            <Icon>add</Icon> Add
          </Button>
        )}
      </div>
      <div className="patient-profile-medications-table">
        <ReactTableHoc>
          <ReactTable
            data={data}
            columns={columns}
            minRows={1}
            showPagination={false}
            nextText=">>"
            previousText="<<"
            noDataText="No Record Found"
            pages={1}
            manual
          />
        </ReactTableHoc>
      </div>
    </div>
  );

  function deleteMedication(row) {
    ModalBoxes.confirm({
      content: 'Are you sure you want to delete this medication record?',
      confirmButton: 'Yes',
      cancelButton: 'Cancel'
    })
      .then(() => {
        if (!row.newRow) {
          PatientMedicationsAPI.removePatientMedication(patientId, row.id).then(() => {
            getPatientMedications();
          });
        } else {
          setNotSavedRow({});
          setData(prevState => prevState.filter(item => !item.newRow));
        }
      })
      .catch(() => {});
  }

  function addNewRow() {
    if (isEmpty(notSavedRow)) {
      setData(prevState => [...prevState, newMedication]);
      setNotSavedRow(newMedication);
    } else {
      NotificationManager.error('Please save your previous changes');
    }
  }

  function saveRowData(row) {
    const payload = cloneDeep(row);
    if (!isEmpty(payload.endDate) && payload.onGoing) {
      payload.onGoing = false;
    }
    if (validateMedicationData(payload)) {
      if (payload.newRow) {
        PatientMedicationsAPI.addPatientMedication(patientId, payload)
          .then(result => {
            if (result.status === 200) {
              setNotSavedRow({});
              getPatientMedications();
            }
          })
          .catch(error => NotificationManager.error(error?.response?.data?.message));
      } else {
        PatientMedicationsAPI.editPatientMedication(patientId, payload)
          .then(result => {
            if (result.status === 200) {
              setNotSavedRow({});
              getPatientMedications();
            }
          })
          .catch(error => NotificationManager.error(error?.response?.data?.message));
      }
    }
  }

  function getOngoingValue(value) {
    if (value === true) {
      return { id: 1, name: 'Yes' };
    }
    if (value === false) {
      return { id: 2, name: 'No' };
    } else {
      return null;
    }
  }

  function onSelectChange(row, newValue, key) {
    setData(function(prevState) {
      return prevState.map(obj => (obj.id === row.id ? { ...row, [key]: newValue } : obj));
    });
  }

  function checkIfRowCanBeSaved(row) {
    if (!row.id) return false;
    return isEqual(find(data, { id: row.id }), find(initialData, { id: row.id }));
  }

  function validateMedicationData(row) {
    const { dosage, doseUnit, frequency, startDate, endDate, onGoing, medication } = row;
    if (dosage || doseUnit || frequency) {
      if (!dosage || !doseUnit || !frequency) {
        NotificationManager.error('Dosage, Dose Unit, and Frequency are required if any one is entered.');
        return false;
      }
    }
    if (endDate && !startDate) {
      NotificationManager.error('If End date is entered, then Start date is required.');
      return false;
    }
    if (startDate && isEmpty(endDate) && !onGoing) {
      NotificationManager.error('If Start date is entered, then End date or Ongoing is required.');
      return false;
    }
    if (
      moment(convertToFirstDay(startDate), 'DD/MMM/YYYY').isAfter(moment(convertToFirstDay(endDate), 'DD/MMM/YYYY'))
    ) {
      NotificationManager.error('End Date cannot be before Start Date is required.');
      return false;
    }
    if (isEmpty(medication)) {
      NotificationManager.error('Medication is required.');
      return false;
    }
    return true;
  }
}
