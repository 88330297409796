import React, { useEffect, useState } from 'react';
import { intersectionWith } from 'lodash/array';
import { orderBy } from 'lodash/collection';
import { isEmpty, isEqual, isNull } from 'lodash/lang';
import moment from 'moment';

import StudySiteApi from '../../../../api/orgunit/StudySiteApi';
import SchedulingWorklistApi from '../../../../api/patient/SchedulingWorklistApi';
import useSessionStorage from '../../../../common/hooks/useSessionStorage';
import { DD_SLASH_MMM_SLASH_YY } from '../../../../constants/dateFormat';
import { SCHEDULING_WORK_LIST_FILTERS } from '../../../../constants/sessionStorageConstants';
import { onFileSave } from '../../../../services/handlers';

import { defaultSorted, encounterPerPersonValues, schedulingAppointmentStatusesValues } from './constants';
import {
  getEncountersFrom,
  getIds,
  getNames,
  getOrderRules,
  getSitesFrom,
  getStudiesFrom,
  getValidationErrors
} from './SchedulingWorklistService';
import { getStoredFilters, setStoredFilters } from './SchedulingWorklistStoredFiltersProvider';

export const SchedulingWorklistContext = React.createContext(null);

export default function SchedulingWorklistFiltersContext(props) {
  //this filter data is stored in sessionStorage & is session sensitive
  //should be used only in case when redirection from another page is needed with specific filter params
  //for example from Pre Screen Worklist when click on specific row for schedule appointment
  // eslint-disable-next-line no-unused-vars
  const [filtersFromRedirect, setFiltersFromRedirect] = useSessionStorage(SCHEDULING_WORK_LIST_FILTERS, {});

  //this filter data is stored in localStorage for everyday use & not session sensitive
  //it is role based & could be different for different role
  const storedFilters = getStoredFilters();

  //this is filter for initial use
  //if user was redirected from another page filtersFromRedirect should be used
  //if user opens page as used - storedFilters should be used
  let initialFiltersValue = {};

  if (!isEmpty(filtersFromRedirect)) {
    initialFiltersValue = filtersFromRedirect;
    setFiltersFromRedirect({});
  } else if (!isEmpty(storedFilters)) {
    initialFiltersValue = storedFilters;
  }

  const today = moment.utc().format(DD_SLASH_MMM_SLASH_YY);
  const dateRangeEndDefaultValue = moment
    .utc()
    .add(1, 'M')
    .format(DD_SLASH_MMM_SLASH_YY);
  const [selectedEncounters, setSelectedEncounters] = useState(initialFiltersValue.selectedEncounters || []);
  const [selectedStudies, setSelectedStudies] = useState(initialFiltersValue.selectedStudies || []);
  const [selectedSites, setSelectedSites] = useState(initialFiltersValue.selectedSites || []);
  const [selectedStudySites, setSelectedStudySites] = useState(initialFiltersValue.selectedStudySites || []);
  const [dateRangeStart, setDateRangeStart] = useState(initialFiltersValue.dateRangeStart || today);
  const [dateRangeEnd, setDateRangeEnd] = useState(initialFiltersValue.dateRangeEnd || dateRangeEndDefaultValue);
  const [dueDate, setDueDate] = useState(initialFiltersValue.dueDate || today);
  const [encounters, setEncounters] = useState([]);
  const [allEncounters, setAllEncounters] = useState([]);
  const [studies, setStudies] = useState([]);
  const [allStudies, setAllStudies] = useState([]);
  const [sites, setSites] = useState([]);
  const [allSites, setAllSites] = useState([]);
  const [epochEncounterMap, setEpochEncounterMap] = useState([]);
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);
  const [ssus, setSsus] = useState([]);
  const [page, setPage] = useState(initialFiltersValue.page || 0);
  const [pageSize, setPageSize] = useState(initialFiltersValue.pageSize || 10);
  const [sorted, setSorted] = useState(initialFiltersValue.sorted || defaultSorted);
  const [tableData, setTableData] = useState([]);
  const [smsColumnData, setSmsColumnData] = useState({ firstSentMessageByEventId: {}, remindersByEventId: {} });
  const [encountersPerPerson, setEncountersPerPerson] = useState(
    initialFiltersValue.encountersPerPerson || encounterPerPersonValues[0]
  );
  const [appointmentStatus, setAppointmentStatus] = useState(
    initialFiltersValue.appointmentStatus || schedulingAppointmentStatusesValues[0]
  );
  const [searchString, setSearchString] = useState(initialFiltersValue.searchString || '');
  const [validationErrors, setValidationErrors] = useState([]);
  const [filter, setFilter] = useState({
    selectedStudySites,
    selectedStudies,
    selectedSites,
    selectedEncounters,
    studies,
    sites,
    encounters,
    appointmentStatus,
    dateRangeStart,
    dateRangeEnd,
    dueDate,
    encountersPerPerson,
    searchString
  });
  const { children } = props;

  useEffect(() => {
    if (
      !isEmpty(filter.selectedStudySites) &&
      !isEmpty(filter.selectedEncounters) &&
      !isEmpty(filter.dateRangeStart) &&
      !isEmpty(filter.dateRangeEnd)
    ) {
      if (filter.appointmentStatus.id && filter.appointmentStatus.id === 'NO_APPOINTMENT') {
        SchedulingWorklistApi.getProjectedEncounter({
          ssuIds: getIds(filter.selectedStudySites),
          encounterNames: getNames(filter.selectedEncounters),
          startDate: startDateFormatter(filter.dateRangeStart),
          endDate: endDateFormatter(filter.dateRangeEnd),
          allEncountersPerPersonIncluded: filter.encountersPerPerson.id === 'ALL',
          encountersPerPerson: filter.encountersPerPerson.id !== 'ALL' ? filter.encountersPerPerson.id : null
        }).then(({ data }) => {
          setTableData(
            orderBy(
              Object.values(data).flatMap(e => e),
              'startDate'
            )
          );
        });
      } else if (filter.appointmentStatus.id && filter.appointmentStatus.id === 'SCHEDULED') {
        SchedulingWorklistApi.filterWorkListFutureAppointmentEvents({
          ssuIds: getIds(filter.selectedStudySites),
          encounterNames: getNames(filter.selectedEncounters),
          startDate: startDateFormatter(filter.dateRangeStart),
          endDate: endDateFormatter(filter.dateRangeEnd),
          appointmentStatus: filter.appointmentStatus.id,
          allEncountersPerPersonIncluded: filter.encountersPerPerson.id === 'ALL',
          encountersPerPerson: filter.encountersPerPerson.id !== 'ALL' ? filter.encountersPerPerson.id : null
        }).then(({ data }) => {
          const orderRules = getOrderRules();
          setTableData(
            orderBy(
              Object.values(data).flatMap(e => e),
              orderRules.iteratees,
              orderRules.orders
            )
          );
        });
      }
    }
  }, [filter]);

  useEffect(() => {
    !isEmpty(tableData) &&
      !isEmpty(tableData.map(e => e.eventId).filter(e => !isNull(e))) &&
      SchedulingWorklistApi.getSmsDataForWorklist(tableData.map(e => e.eventId)).then(({ data }) => {
        setSmsColumnData(data);
      });
  }, [tableData]);

  async function getFilters(ssus) {
    const encountersData = await SchedulingWorklistApi.getFilterEncountersAccessibleForUser({
      ssuIds: ssus.map(e => e.id)
    });
    return [...encountersData.data];
  }

  useEffect(function() {
    StudySiteApi.getAllNonCanceledSsuProjections().then(res => {
      setSsus(res.data);
    });
  }, []);

  useEffect(
    function() {
      if (!isEmpty(ssus)) {
        setIsLoadingFilters(true);
        getFilters(ssus).then(function(data) {
          setEpochEncounterMap(data);

          const studies = getStudiesFrom(ssus);
          const sites = getSitesFrom(ssus);
          const encounters = getEncountersFrom(data);

          setAllStudies(studies);
          setAllSites(sites);
          setAllEncounters(encounters);

          const updatedSelectedStudies = !isEmpty(selectedStudies)
            ? intersectionWith(studies, selectedStudies, nameEquals)
            : studies;
          const updatedSelectedSites = !isEmpty(selectedSites)
            ? intersectionWith(sites, selectedSites, nameEquals)
            : sites;

          const updatedSelectedStudySites = !isEmpty(selectedStudySites)
            ? intersectionWith(ssus, selectedStudySites, (a, b) => a.id === b.id)
            : ssus.filter(
                ssu =>
                  getIds(updatedSelectedStudies).includes(ssu.study.id) &&
                  getIds(updatedSelectedSites).includes(ssu.site.id)
              );

          const updatedSelectedEncounters = !isEmpty(selectedEncounters)
            ? intersectionWith(encounters, selectedEncounters, nameEquals)
            : getEncountersFrom(data.filter(el => getIds(updatedSelectedStudySites).includes(el.ssuId)));

          const updatedSites = !isEmpty(updatedSelectedStudies)
            ? getSitesFrom(ssus.filter(ssu => getIds(updatedSelectedStudies).includes(ssu.study.id)))
            : sites;
          const updatedEncounters = !isEmpty(updatedSelectedStudySites)
            ? getEncountersFrom(data.filter(el => getIds(updatedSelectedStudySites).includes(el.ssuId)))
            : encounters;

          setSelectedStudies(updatedSelectedStudies);
          setSelectedSites(updatedSelectedSites);
          setSelectedEncounters(updatedSelectedEncounters);
          setSelectedStudySites(updatedSelectedStudySites);

          setStudies(studies);
          setSites(updatedSites);
          setEncounters(updatedEncounters);

          setIsLoadingFilters(false);
          applyFilters(
            updatedSelectedStudySites,
            updatedSelectedStudies,
            updatedSelectedSites,
            updatedSelectedEncounters,
            studies,
            updatedSites,
            updatedEncounters,
            false
          );
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ssus]
  );

  const nameEquals = (a, b) => a.name === b.name;

  const onStudiesChange = newStudies => {
    if (!isEqual(selectedStudies, newStudies)) {
      setSelectedStudies(newStudies);
      const newStudySites = ssus.filter(ssu => getIds(newStudies).includes(ssu.study.id));
      !isEqual(newStudySites, selectedStudySites) && setSelectedStudySites(newStudySites);
      const newSites = getSitesFrom(newStudySites);
      !isEqual(sites, newSites) && setSites(newSites);
      setTimeout(onSitesChange(intersectionWith(newSites, selectedSites, nameEquals)));
    }
  };

  const onSitesChange = newSites => {
    if (!isEqual(selectedSites, newSites)) {
      setSelectedSites(newSites);
      const newStudySites = ssus.filter(
        ssu => getIds(newSites).includes(ssu.site.id) && getIds(selectedStudies).includes(ssu.study.id)
      );
      !isEqual(newStudySites, selectedStudySites) && setSelectedStudySites(newStudySites);
      const newEncounters = getEncountersFrom(epochEncounterMap.filter(el => getIds(newStudySites).includes(el.ssuId)));
      !isEqual(encounters, newEncounters) && setEncounters(newEncounters);
      setTimeout(onEncountersChange(intersectionWith(newEncounters, selectedEncounters, nameEquals)));
    }
  };

  const onEncountersChange = newEncounters => {
    if (!isEqual(selectedEncounters, newEncounters)) {
      setSelectedEncounters(newEncounters);
    }
  };

  const applyFilters = (
    selectedStudySites,
    selectedStudies,
    selectedSites,
    selectedEncounters,
    studies,
    sites,
    encounters,
    resetPage = true
  ) => {
    const newFilter = {
      selectedStudySites: selectedStudySites,
      selectedStudies: selectedStudies,
      selectedSites: selectedSites,
      selectedEncounters: selectedEncounters,
      appointmentStatus,
      encountersPerPerson,
      dateRangeStart,
      dateRangeEnd,
      dueDate,
      searchString,
      page: resetPage ? 0 : initialFiltersValue.page || 0,
      pageSize: initialFiltersValue.pageSize || 10,
      sorted: initialFiltersValue.sorted || defaultSorted
    };
    const errors = getValidationErrors(newFilter);
    setValidationErrors(errors);
    if (isEmpty(errors)) {
      resetPage && setPage(0);
      setFilter({
        ...newFilter,
        studies: studies,
        sites: sites,
        encounters: encounters
      });
      setStoredFilters(newFilter);
    }
  };
  let validationError = false;
  if (!isEmpty(allStudies) && !isEmpty(allSites) && !isEmpty(allEncounters)) {
    validationError =
      isEmpty(filter.selectedStudies) || isEmpty(filter.selectedSites) || isEmpty(filter.selectedEncounters);
  }

  const resetStudies = () => {
    onStudiesChange(studies);
    applyFilters(selectedStudySites, studies, selectedSites, selectedEncounters, studies, sites, encounters);
  };
  const endDateFormatter = frontEndDate => {
    return moment(frontEndDate)
      .endOf('day')
      .format('YYYY-MM-DDTHH:mm:ss');
  };
  const startDateFormatter = frontEndDate => {
    return moment(frontEndDate)
      .startOf('day')
      .format('YYYY-MM-DDTHH:mm:ss');
  };
  const resetSites = () => {
    onSitesChange(sites);
    setTimeout(
      applyFilters(selectedStudySites, selectedStudies, sites, selectedEncounters, studies, sites, encounters)
    );
  };

  const resetEncounters = () => {
    onEncountersChange(encounters);
    setTimeout(
      applyFilters(selectedStudySites, selectedStudies, selectedSites, encounters, studies, sites, encounters)
    );
  };

  const exportFiles = () => {
    SchedulingWorklistApi.export({
      ssuIds: getIds(filter.selectedStudySites),
      encounterNames: getNames(filter.selectedEncounters),
      startDate: startDateFormatter(filter.dateRangeStart),
      endDate: endDateFormatter(filter.dateRangeEnd),
      appointmentStatus: filter.appointmentStatus.id,
      allEncountersPerPersonIncluded: filter.encountersPerPerson.id === 'ALL',
      encountersPerPerson: filter.encountersPerPerson.id !== 'ALL' ? filter.encountersPerPerson.id : null
    }).then(onFileSave);
  };

  return (
    <SchedulingWorklistContext.Provider
      value={{
        allStudies,
        allSites,
        allEncounters,
        studies,
        sites,
        dateRangeStart,
        dateRangeEnd,
        dueDate,
        encounters,
        selectedStudies,
        selectedSites,
        selectedEncounters,
        selectedStudySites,
        setSelectedStudySites,
        setSelectedStudies,
        setSelectedSites,
        setSelectedEncounters,
        setFilter,
        tableData,
        setEncountersPerPerson,
        setAppointmentStatus,
        setSearchString,
        setDateRangeStart,
        setDateRangeEnd,
        setDueDate,
        epochEncounterMap,
        appointmentStatus,
        encountersPerPerson,
        searchString,
        filter,
        page,
        pageSize,
        sorted,
        setPage,
        setPageSize,
        setSorted,
        smsColumnData,
        setInitialFilters: filter => {
          setStoredFilters({ ...storedFilters, ...filter });
        },
        applyFilters: () => {
          applyFilters(
            selectedStudySites,
            selectedStudies,
            selectedSites,
            selectedEncounters,
            studies,
            sites,
            encounters
          );
        },
        resetStudies,
        resetSites,
        resetEncounters,
        onStudiesChange,
        onSitesChange,
        onEncountersChange,
        validationError,
        ssus,
        isLoadingFilters,
        validationErrors,
        exportFiles
      }}
    >
      {children}
    </SchedulingWorklistContext.Provider>
  );
}
