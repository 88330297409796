import React, { useEffect, useState } from 'react';
import { isFunction } from 'lodash/lang';

import ToggleSwitch from '../../../../../common/inputs/ToggleSwitch';

import './RadioTemplate.scss';
const positiveValues = ['Y', 'CS', 'NORM'];
const negativeValues = ['N', 'NCS', 'ABNORM'];

function RadioTemplate({ isAbleToEdit, templateData, fullData, onChangeRadioHandler, forPreview }) {
  const yesCode = findPositiveCodeOrFirst(templateData);
  const noCode = findNegativeCodeOrSecond(templateData);

  const yesLabel = yesCode.name;
  const noLabel = noCode.name;

  const yesValue = yesCode.decode;
  const noValue = noCode.decode;

  const [radioValueForPreview, setRadioValueForPreview] = useState(
    getRadioValue(fullData && fullData.inputValue, yesValue, noValue)
  );

  const radioValue = getRadioValue(fullData && fullData.inputValue, yesValue, noValue);
  const disabled = isFunction(isAbleToEdit) && !isAbleToEdit();

  useEffect(() => {
    forPreview && setRadioValueForPreview(fullData.inputValue);
  }, [forPreview, fullData.inputValue]);

  return (
    <ToggleSwitch
      className="radio-template-toggle-switch"
      value={forPreview ? radioValueForPreview : radioValue}
      leftLabel={yesLabel}
      rightLabel={noLabel}
      onChange={value =>
        onChangeRadioHandler && onChangeRadioHandler(value, fullData, getLabelByValue(value, yesValue, noValue))
      }
      disabled={disabled}
    />
  );
}

function findCodeIfPresent(templateData, labels) {
  const code = templateData.find(it => labels.includes(it.decode));
  return code ? code : false;
}

function findPositiveCodeOrFirst(templateData) {
  return findCodeIfPresent(templateData, positiveValues) || templateData[0];
}

function findNegativeCodeOrSecond(templateData) {
  return findCodeIfPresent(templateData, negativeValues) || templateData[1];
}

function getRadioValue(inputValue, yesValue, noValue) {
  if (inputValue === yesValue) {
    return 1;
  }
  if (inputValue === noValue) {
    return 2;
  }
  return 0;
}

function getLabelByValue(value, yesValue, noValue) {
  if (value === 1) {
    return yesValue;
  }

  if (value === 2) {
    return noValue;
  }

  return undefined;
}

export default RadioTemplate;
