import React from 'react';

import { WorklistHeader } from './WorklistHeader/WorklistHeader';
import { WorklistTableMUI } from './WorklistTable/WorklistTableMUI';
import { withWorklistContext } from './WorklistContext';

import './Worklist.scss';

const Worklist = () => {
  return (
    <div className="new-work-list-page">
      <WorklistHeader />
      <WorklistTableMUI />
    </div>
  );
};

export default withWorklistContext(Worklist);
