import React from 'react';
import cx from 'classnames';
import * as PropTypes from 'prop-types';

import './Loader.scss';

export default function Loader({ dark = false }) {
  return (
    <div className={cx('eds-loader', { 'eds-loader-dark': dark })}>
      <div className="eds-loader-icon" />
    </div>
  );
}

Loader.propTypes = {
  dark: PropTypes.bool
};
