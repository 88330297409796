import React, { useState } from 'react';
import * as PropTypes from 'prop-types';

import { PatientEncounterReviewCommentApi } from '../../../../../../../../api';
import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../../../../common/notifications/NotificationManager';
import { COMMENT_SAVED } from '../../../../../../../../constants/notificationMessages';
import { handleReviewCommentExceptions } from '../../../../../../../../services/handlers';
import { isEmptyOrTheSameAsPrevious } from '../../reviewContentService';
import CommentSection from '../CommentSection/CommentSection';

import './AddCommentModal.scss';

export function AddCommentModal(props) {
  const { modalBox, patientItemGroupId, itemGroupTemplateId, itemGroupTemplateVersionId, updateData, ...rest } = props,
    { ssuPatientId, reviewItemGroupId } = rest.context,
    [commentData, setCommentData] = useState({}),
    [loader, setLoader] = useState(false),
    [isEditableComment, setEditableComment] = useState();
  return (
    <>
      <ModalBoxes.Header>Comments</ModalBoxes.Header>
      <ModalBoxes.Body>
        <CommentSection
          {...rest}
          setCommentData={setCommentData}
          setEditableComment={setEditableComment}
          saveButton={false}
        />
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            Close
          </Button>
          {isEditableComment && (
            <Button
              onClick={onButtonClick}
              loading={loader}
              disabled={
                loader || isEmptyOrTheSameAsPrevious(rest.snapshotData.commentAnswer?.stringValue, commentData.comment)
              }
            >
              Save
            </Button>
          )}
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
  function sendComment(commentSnapshot) {
    return PatientEncounterReviewCommentApi.saveCommentOnReview(
      ssuPatientId,
      reviewItemGroupId,
      patientItemGroupId,
      commentSnapshot,
      false
    );
  }

  function sendLogComment(commentSnapshot) {
    return PatientEncounterReviewCommentApi.saveCommentOnReviewForLogItemGroup(
      ssuPatientId,
      reviewItemGroupId,
      itemGroupTemplateId,
      itemGroupTemplateVersionId,
      commentSnapshot,
      false
    );
  }
  function commentSendResolver() {
    if (rest.isLogItemGroup) {
      return sendLogComment(commentData);
    } else {
      return sendComment(commentData);
    }
  }
  function onFailure(err) {
    handleReviewCommentExceptions(err);
    setLoader(false);
  }

  function commentSaveSuccessResolver() {
    NotificationManager.success(COMMENT_SAVED);
    const hideLoader = false;
    if (rest.isLogItemGroup) {
      updateData(itemGroupTemplateId, hideLoader);
    } else {
      updateData(patientItemGroupId, hideLoader);
    }
    modalBox.close();
  }

  function onButtonClick() {
    setLoader(true);
    commentSendResolver().then(commentSaveSuccessResolver, onFailure);
  }
}

AddCommentModal.propTypes = {
  modalBox: PropTypes.object,
  patientItemGroupId: PropTypes.string,
  itemGroupTemplateId: PropTypes.string,
  itemGroupTemplateVersionId: PropTypes.string,
  context: PropTypes.object
};

AddCommentModal.size = 'w1100';
AddCommentModal.className = 'eds-add-comment-modal';
