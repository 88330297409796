import axios from 'axios';

const ROOT = 'api/v1/transfer';

const PatientTransferringApi = {
  transferredPatientToNewSsu(ssuPatientId, siteId, personnelId) {
    return axios.post(`${ROOT}/ssu-patient/${ssuPatientId}/site/${siteId}/study-manager/${personnelId}`, {
      hideLoader: true
    });
  }
};

export default PatientTransferringApi;
