import { FinBudgetSiteApi } from '../../../api';
import { onRequestError } from '../../../services/handlers';
import { GET_BUDGET_SITES } from '../../actionTypes';

export const getBudgetSitesAction = budgetSitesList => {
  return {
    type: GET_BUDGET_SITES,
    payload: budgetSitesList
  };
};

export const getBudgetSites = () => dispatch => {
  return FinBudgetSiteApi.findAll()
    .then(resp => {
      dispatch(getBudgetSitesAction(resp.data));
    })
    .catch(err => onRequestError(err, { customMessage: "Can't get all Budget Site mappings" }));
};
