import { SvgIcon } from '@mui/material';

export const BackgroundColor = ({ onClick }) => {
  return (
    <SvgIcon onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M9.91756 12.2408L17.6655 6.85064L18.8449 8.14655L12.7509 15.354L9.91756 12.2408ZM7.58746 12.652L12.1228 17.6353C12.2167 17.7283 12.3292 17.8003 12.453 17.8466C12.5768 17.8929 12.709 17.9123 12.8409 17.9038C12.9728 17.8952 13.1013 17.8587 13.2181 17.7967C13.3348 17.7348 13.4371 17.6488 13.5181 17.5444L20.9695 8.73139C21.1179 8.56827 21.202 8.35672 21.2059 8.1362C21.2098 7.91569 21.1333 7.70128 20.9908 7.53299L18.4778 4.77183C18.3236 4.6141 18.1174 4.51786 17.8975 4.50105C17.6775 4.48425 17.459 4.54804 17.2827 4.68051L7.80905 11.2713C7.69748 11.3422 7.60223 11.4359 7.52958 11.5463C7.45693 11.6567 7.40853 11.7813 7.38759 11.9118C7.36664 12.0423 7.37363 12.1757 7.40808 12.3033C7.44254 12.4309 7.50367 12.5498 7.58746 12.652ZM2.79395 18.5277L8.4113 19.5L9.89044 18.1538L6.86155 14.8258L2.79395 18.5277Z"
          fill="black"
          fillOpacity="0.6"
        />
      </svg>
    </SvgIcon>
  );
};
