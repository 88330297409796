import React, { useEffect, useMemo, useState } from 'react';
import TimeField from 'react-simple-timefield';
import cx from 'classnames';
import { isFunction } from 'lodash/lang';
import moment from 'moment';

import Icon from '../../../../../common/general/Icon';

import './TimeTemplate.scss';

function TimeInput({ time, value, onChange, isAbleToEdit }) {
  let visibleValue = '';
  if (!time && value === '00:00') {
    visibleValue = '--:--';
  } else {
    visibleValue = value;
  }
  const handleFocus = event => event.target.select();
  const disabled = isFunction(isAbleToEdit) && !isAbleToEdit();

  return (
    <input disabled={disabled} value={visibleValue} onChange={onChange} onFocus={handleFocus} onClick={handleFocus} />
  );
}

export default function TimeTemplate({
  isAbleToEdit,
  customClassObject,
  templateData,
  onchangeTimeHandler,
  formLabelId,
  itemId
}) {
  const [time, setTime] = useState('');
  const disabled = isFunction(isAbleToEdit) && !isAbleToEdit();

  const onChange = value => {
    const momentValue = value ? moment(value, 'hh:mm') : null;
    const time = value && moment.isMoment(momentValue) ? value : '';
    setTime(time);
    return isFunction(onchangeTimeHandler) && onchangeTimeHandler(momentValue, formLabelId, itemId, templateData);
  };

  useEffect(
    function() {
      setTime(templateData.inputValue);
    },
    [setTime, templateData.inputValue]
  );

  const containerClass = useMemo(
    function() {
      return customClassObject && customClassObject.containerClass ? customClassObject.containerClass : '';
    },
    [customClassObject]
  );

  return (
    <div className={cx('time-template', containerClass)}>
      <Icon className="time-icon">access_time</Icon>
      <TimeField
        value={time || ''}
        onChange={(e, val) => onChange(val)}
        input={<TimeInput time={time} isAbleToEdit={isAbleToEdit} />}
      />
      <i
        className="glyphicon glyphicon-trash dateicon3 cursor-pointer"
        title="Clear date"
        onClick={() => !disabled && onChange(null)}
      />
    </div>
  );
}
