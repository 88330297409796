import React, { useCallback, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';

import { CalendarPageSupplierApi } from '../../../../api';
import { CLOSED } from '../../../../constants/ssuStatuses';
import { ROLE_SYSTEM_ADMINISTRATOR } from '../../../../constants/userRoles';
import { userHasRole } from '../../../../services/auth';

import CalendarSingleSelect from './CalendarSingleSelect';

import './StudySelector.scss';

export function StudySelector({
  patientId,
  initialStudy,
  onStudySelected,
  studyValidationMessage,
  isUnexpected,
  disabled
}) {
  const [studies, setStudies] = useState();

  const getStudies = useCallback(
    function(patientId) {
      if (isUnexpected) {
        return CalendarPageSupplierApi.getStudiesForPatient(patientId);
      }
      return CalendarPageSupplierApi.getStudiesForPatientRegularEncounter(patientId);
    },
    [isUnexpected]
  );

  useEffect(() => {
    if (!patientId) {
      return;
    }
    getStudies(patientId).then(({ data: studies }) => {
      setStudies(
        studies.filter(function({ studySiteStatus }) {
          if (userHasRole(ROLE_SYSTEM_ADMINISTRATOR)) {
            return true;
          }
          return studySiteStatus !== CLOSED;
        })
      );
    });
  }, [patientId, getStudies]);

  const customSelectedValueTemplateFunction = study => {
    return <span>{study[0].name}</span>;
  };

  const customOptionTemplateFunction = study => {
    return (
      <span className="eds-study-selector">
        {study.name} {study.patientStatus ? <span className="patient-status">{study.patientStatus}</span> : ''}
      </span>
    );
  };

  const selectStudy = study => {
    onStudySelected(study);
  };

  return (
    <React.Fragment>
      <CalendarSingleSelect
        controlId={'study-selector'}
        label={'Study'}
        dataSource={studies}
        disabled={!!disabled}
        clearable={!disabled}
        initialValue={initialStudy}
        optionValueKey={'sitePatientId'}
        customOptionTemplateFunction={customOptionTemplateFunction}
        customSelectedValueTemplateFunction={customSelectedValueTemplateFunction}
        onChange={selectStudy}
        required
        validationMessage={studyValidationMessage}
      />
    </React.Fragment>
  );
}

StudySelector.defaultProps = {
  isUnexpected: false
};

StudySelector.propTypes = {
  patientId: PropTypes.string,
  initialStudy: PropTypes.object,
  onStudySelected: PropTypes.func,
  studyValidationMessage: PropTypes.string,
  isUnexpected: PropTypes.bool
};
