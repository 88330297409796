import React, { useEffect, useState } from 'react';
import { sortBy } from 'lodash';
import { isEmpty } from 'lodash/lang';

import { TerminologyVersionApi } from '../../../../../../api';
import Select from '../../../../../../common/data-entry/Select';

import { customOptionTerminologyVersion } from './itemGroupSetupRenderHelpService';

export default function TerminologyDropdown({ terminologyVersionGroupNumber, updateTerminologyVersion, editMode }) {
  const [versions, setVersions] = useState([]);

  useEffect(function() {
    TerminologyVersionApi.getAllTerminologyVersionGroups().then(function({ data }) {
      setVersions(sortBy(data, 'number'));
    });
  }, []);

  useEffect(
    function() {
      if (!isEmpty(versions) && !terminologyVersionGroupNumber) {
        updateTerminologyVersion(versions[versions.length - 1]);
      }
    },
    [terminologyVersionGroupNumber, updateTerminologyVersion, versions]
  );

  return (
    <Select
      validate={false}
      customOptionTemplateFunction={item =>
        customOptionTerminologyVersion(item, { display: 'flex', flexDirection: 'column' })
      }
      onChange={updateTerminologyVersion}
      clearable={false}
      customSelectedValueTemplateFunction={array =>
        customOptionTerminologyVersion(array[0], {
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          minWidth: '100px'
        })
      }
      disabled={editMode}
      label="Terminology version"
      dataSource={versions}
      optionValueKey="number"
      value={versions.find(({ number }) => terminologyVersionGroupNumber === number)}
      required
    />
  );
}
