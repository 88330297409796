import React from 'react';
import { bool, func, string } from 'prop-types';

import Input from '../../../../common/data-entry/Input';

export function SubjectInput({ initialSubject, onSubjectChanged, subjectValidationMessage, required }) {
  return (
    <Input
      name="subject-input"
      value={initialSubject}
      onChange={e => onSubjectChanged(e.target.value)}
      label={'Event Name'}
      validationMessage={subjectValidationMessage}
      required={required}
    />
  );
}

SubjectInput.propTypes = {
  initialSubject: string,
  onSubjectChanged: func,
  subjectValidationMessage: string,
  required: bool
};
