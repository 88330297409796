import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { first } from 'lodash/array';
import moment from 'moment';

import { getPatientBirthDateAndAge } from '../../../../../../../pages/patient-source/Patients/PatientInfo/PatientInfoSidebar/ProgressNotesWidget/AddOrEditProgressNoteModal/NotesInfo';
import { generateUrlByKey } from '../../../../../../router';

import HighlightWithTooltip from './HighlightWithTooltip';

import './FindPatientPopupItem.scss';

function normalizeDateForHighlight(date) {
  date = date.replaceAll(/[. -]/gi, '/').replaceAll(/[[+|(){\\?*^$.}]/gi, '');
  let separatedDate = date.split('/');
  if (separatedDate[1] && date.search(/[A-Za-z]/) === -1) {
    let normalizedDate = [...separatedDate];
    normalizedDate[1] = `${moment(normalizedDate[1], 'MM').format('MMM')}`;
    if (normalizedDate[1] === 'Invalid date') {
      normalizedDate = [...separatedDate];
      normalizedDate[0] = `${moment(normalizedDate[0], 'MM').format('MMM')}`;
    }
    return normalizedDate.join('/');
  }
  return date;
}

export default function FindPatientPopupItem({ patient, searchString, isPatientProfile, onLinkClick }) {
  const url = generateUrlByKey(
    isPatientProfile ? 'Patients.Patient Profile' : 'Patients.Patient Profile.Patient Studies',
    {
      patientId: patient?.patientUniqueIdentifier,
      ssuPatientId: first(patient.sitePatientIds) || 'ssuId'
    }
  );

  const normalizeContactNumberForHighlight = searchString
    .replace(/^\+\d+\(/gi, '')
    .replace(/[[+|(){}\\?* "^$.-]/gi, '')
    .replace(/\d/gi, '$&-?');

  const normalizeTextForHighlight = searchString.replaceAll(/[[+|(){\\?^$.*}]/gi, '');

  const normalizedNameForHighlight = normalizeTextForHighlight
    .replace(/(\w|-)+/gi, '($&)|')
    .replace(/ /gi, '')
    .replace(/\|$/gi, '');

  return (
    <div className="eds-find-patient-popup-item">
      <Link className="custom-option" to={url} onClick={onLinkClick}>
        <div className="group">
          <div className="column-item xl">
            Name:
            <span className={cx('value')}>
              <HighlightWithTooltip
                word={`${patient.lastName}, ${patient.firstName} ${
                  patient?.preferredName ? `(${patient.preferredName})` : ''
                }`}
                referredLength={18}
                reg={normalizedNameForHighlight !== '' && new RegExp(`${normalizedNameForHighlight}`, 'i')}
              />
              <span>
                <HighlightWithTooltip
                  word={getPatientBirthDateAndAge(patient.patientBirthDate)}
                  reg={
                    normalizeDateForHighlight(searchString) !== '' &&
                    new RegExp(`(${normalizeDateForHighlight(searchString)})`, 'gi')
                  }
                />
              </span>
            </span>
          </div>
          <div className="column-item md">
            Patient ID:
            <span className="value">
              <HighlightWithTooltip
                word={` ${patient.patientId}`}
                referredLength={12}
                reg={normalizeTextForHighlight !== '' && new RegExp(`(${normalizeTextForHighlight})`, 'gi')}
              />
            </span>
            <span className="value" />
          </div>
          {!isPatientProfile && (
            <div className="column-item md">
              Subject ID:
              <span className="value">
                <HighlightWithTooltip
                  word={patient?.subjectIds.length > 0 ? ` ${patient.subjectIds.join(', ')}` : '-'}
                  referredLength={11}
                  reg={
                    patient.subjectIds.length > 0 &&
                    normalizeTextForHighlight !== '' &&
                    new RegExp(`(${normalizeTextForHighlight})`, 'gi')
                  }
                />
              </span>
            </div>
          )}
          {isPatientProfile && (
            <div className="column-item md">
              Sex:
              <span className="value">{patient?.sex || '-'}</span>
            </div>
          )}
          <div className="column-item status sm">
            <span className="value">{patient.deceased && <div className="status">Deceased</div>}</span>
          </div>
          <br />
          <div className="column-item lg">
            Primary Phone:
            <span className="value">
              <HighlightWithTooltip
                word={patient.contactNumber ? ` ${patient.contactNumber}` : '-'}
                referredLength={30}
                reg={
                  patient.contactNumber &&
                  normalizeContactNumberForHighlight !== '' &&
                  new RegExp(`(${normalizeContactNumberForHighlight})`, 'gi')
                }
              />
            </span>
          </div>
          {!isPatientProfile && (
            <div className="column-item">
              Study
              <span className={cx('value', 'md')}>
                <HighlightWithTooltip
                  word={patient.studyNames.length > 0 ? `${patient.studyNames.join(', ')}` : ''}
                  referredLength={20}
                  reg={
                    patient.studyNames.length > 0 &&
                    normalizeTextForHighlight !== '' &&
                    new RegExp(`(${normalizeTextForHighlight})`, 'gi')
                  }
                />
              </span>
            </div>
          )}
          {isPatientProfile && (
            <div className="column-item md">
              City:
              <span className="value">{patient?.city || '-'}</span>
            </div>
          )}
          {isPatientProfile && (
            <div className="column-item md">
              State:
              <span className="value">{patient?.state || '-'}</span>
            </div>
          )}
          {isPatientProfile && (
            <div className="column-item md">
              ZIP Code:
              <span className="value">{patient?.zipCode || '-'}</span>
            </div>
          )}
        </div>
      </Link>
    </div>
  );
}
