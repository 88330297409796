import React from 'react';

import { AdjustmentResult } from './AdjustmentResult/AdjustmentResult';
import { LedgerEventInfo } from './LedgerEventInfo/LedgerEventInfo';
import { LedgerEventsTable } from './LedgerEventsTable/LedgerEventsTable';

import './RevenueAdjustmentLayout.scss';

export function RevenueAdjustmentLayout() {
  return (
    <>
      <LedgerEventInfo />
      <div className="revenue-adjustment">
        <LedgerEventsTable />
        <AdjustmentResult />
      </div>
    </>
  );
}
