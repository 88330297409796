import { object, string } from 'yup';

import { isValidSsn } from '../../FinanceDetails/financeDetailsService';

export const ssnSchema = string()
  .nullable()
  .when(['$financialFieldsRequired'], function([financialFieldsRequired], schema, { value }) {
    let nextSchema = schema;
    if (financialFieldsRequired) {
      nextSchema = nextSchema.required('Required with PID');
    }
    if (value) {
      nextSchema = nextSchema.when(['address.country'], function([country], schema, { value }) {
        if (country === 'US') {
          return schema.test('valid-ssn', 'Invalid SSN', () => isValidSsn(value));
        }
        return schema.length(11, 'Invalid SIN');
      });
    }
    return nextSchema;
  });

const primaryPhoneSchema = string().ifNotEmpty(
  string().length(12, 'Please fill in the field Phone (primary) correctly')
);
export const phonesInfoSchema = object({
  primary: primaryPhoneSchema,
  alternative: string().ifNotEmpty(string().length(12, 'Please fill in the field Phone (alternate) correctly'))
}).when(['$financialFieldsRequired'], function([financialFieldsRequired], schema) {
  if (financialFieldsRequired) {
    return schema.concat(object({ primary: primaryPhoneSchema.required('Required with PID') }));
  }
  return schema;
});

const zipCodeSchema = string().when(['country'], function([country], schema, { value }) {
  if (!value) {
    return schema;
  }
  if (country === 'US') {
    return schema.matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Please fill in the field Zip Code correctly');
  }
  return schema.matches(
    /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$/,
    'Please fill in the field Postal Code correctly'
  );
});

export const addressSchema = object({
  zipCode: zipCodeSchema,
  country: string().required('Required')
}).when(['$financialFieldsRequired'], function([financialFieldsRequired], schema) {
  if (financialFieldsRequired) {
    return schema.concat(
      object({
        zipCode: zipCodeSchema.required('Required with PID'),
        addressLine1: string().required('Required with PID'),
        city: string().required('Required with PID'),
        state: string().required('Required with PID')
      })
    );
  }
  return schema;
});
