import React, { useContext } from 'react';
import cx from 'classnames';

import Icon from '../../../../../../../common/general/Icon';

import { availableFormats, FileUploadContext } from './FileUploadContextProvider';

export function FileUploadTab() {
  const { uploadedFiles, addFilesAsUploadCandidate, uploadFilesAllowed } = useContext(FileUploadContext);

  return (
    <React.Fragment>
      Files - {uploadedFiles?.length || 0}
      {uploadFilesAllowed && (
        <React.Fragment>
          <label
            htmlFor={'file-input-button'}
            className={cx(`eui-btn eui-btn-priority-medium eui-btn-size-h28 eui-btn-icon-only`)}
          >
            <Icon>add</Icon>
          </label>
          <input
            id={'file-input-button'}
            type="file"
            className="d-none"
            multiple=""
            accept={availableFormats}
            onChange={event => {
              addFilesAsUploadCandidate(fileDialogOutputToList(event.target.files));
            }}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

function fileDialogOutputToList(files) {
  let result = [];
  for (let i = 0; i < files.length; i++) {
    result.push(files[i]);
  }
  return result;
}
