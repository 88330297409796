import React, { useEffect, useState } from 'react';

import Checkbox from '../../../../common/data-entry/InputSelectors/Checkbox';
import { getInitialEventStatus } from '../FiltersProvider';

import './EventStatusSelector.scss';

const eventStatusOptions = [
  { label: 'Scheduled', code: 'SCHEDULED', checked: true },
  { label: 'Active', code: 'ACTIVE', checked: true },
  { label: 'Completed', code: 'COMPLETED', checked: true },
  { label: 'Canceled', code: 'CANCELLED', checked: true }
];

export function CalendarStatusFilter({ onChange }) {
  const [eventStatuses, setEventStatuses] = useState(eventStatusOptions);
  const [eventStatusInitialized, setEventStatusInitialized] = useState(false);

  useEffect(() => {
    if (!eventStatusInitialized) {
      const initialEventStatuses = getInitialEventStatus();
      if (initialEventStatuses) {
        eventStatuses.forEach(e => {
          e.checked = initialEventStatuses.includes(e.code);
        });
      }
      setEventStatuses(eventStatuses);
      onChange(eventStatuses);
      setEventStatusInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventStatuses, eventStatusInitialized, getInitialEventStatus]);

  const change = option => {
    option.checked = !option.checked;
    const updatedStatuses = [...eventStatuses];
    setEventStatuses(updatedStatuses);
    onChange(updatedStatuses);
  };

  const onSelectAll = () => {
    if (eventStatuses.some(e => e.checked)) {
      eventStatuses.forEach(e => {
        e.checked = false;
      });
    } else {
      eventStatuses.forEach(e => {
        e.checked = true;
      });
    }
    setEventStatuses(eventStatuses);
    onChange([...eventStatuses]);
  };

  return (
    <div className="calendar-event-status-selector-container pl-3">
      <Checkbox
        label="Select all"
        key="SELECT_ALL"
        onChange={onSelectAll}
        indeterminate={eventStatuses.some(e => e.checked) && eventStatuses.some(e => !e.checked)}
        checked={!eventStatuses.some(e => !e.checked)}
      />
      <Checkbox.Group direction="column">
        {eventStatuses.map(option => {
          return (
            <Checkbox key={option.code} label={option.label} onChange={() => change(option)} checked={option.checked} />
          );
        })}
      </Checkbox.Group>
    </div>
  );
}
