import React, { useCallback, useMemo } from 'react';
import { map } from 'lodash/collection';
import { isEmpty, isString } from 'lodash/lang';
import PropTypes from 'prop-types';

import Select from '../Select';
import selectPropTypes from '../types';

const _Primitive = React.forwardRef(function({ dataSource, value, onChange, ...restProps }, ref) {
  const convertedDataSource = useMemo(() => map(dataSource, str => ({ id: str, name: str })), [dataSource]);
  const convertedValue = useMemo(() => {
    if (!isString(value) || isEmpty(value)) {
      return null;
    }
    return { id: value, name: value };
  }, [value]);
  const _onChange = useCallback(
    option => {
      onChange(option?.name);
    },
    [onChange]
  );
  return <Select {...restProps} value={convertedValue} onChange={_onChange} dataSource={convertedDataSource} />;
});

_Primitive.propTypes = { ...selectPropTypes, dataSource: PropTypes.arrayOf(PropTypes.string), value: PropTypes.string };

export default _Primitive;
