import React from 'react';
import cx from 'classnames';

import { CellFormattedDate } from '../../../../../../CellFormattedDate/CellFormattedDate';
import { Amount } from '../../../../shared/amount-view/Amount/Amount';
import { AmountWithType } from '../../../../shared/amount-view/AmountWithType/AmountWithType';
import { FinanceTable } from '../../../../shared/FinanceTable/FinanceTable';
import { ALL, PATIENT_STIPEND } from '../../AdjustmentConstants';

import './AdjustedLedgerEventsHistoryTable.scss';

export function AdjustedLedgerEventsHistoryTable({ ledgerEventInfo, ledgerEvents }) {
  const columns = getColumns(ledgerEventInfo?.isPatientStipend);

  return (
    <div className="adjusted-ledger-events-history-table">
      <span className="table-header">History</span>
      <FinanceTable
        sortable={false}
        data={ledgerEvents}
        columns={columns}
        minRows={1}
        multiSort
        className="ledger-events-history-table"
        showPagination={false}
        defaultPageSize={100}
        resizable={false}
      />
    </div>
  );

  function getColumns(isPatientStipend) {
    const columns = [
      {
        Header: <span>Event ID</span>,
        accessor: 'id',
        maxWidth: 100,
        className: 'let-event-id',
        headerClassName: 'let-event-id',
        style: { marginTop: 'auto', marginBottom: 'auto' },
        type: ALL,
        Cell({ value }) {
          const adjustmentVersionId = value.adjustmentVersionId;
          const formattedLedgerEventId = adjustmentVersionId
            ? `${value.eventNumber}.${adjustmentVersionId}`
            : value.eventNumber;
          return <span>{formattedLedgerEventId}</span>;
        }
      },
      {
        Header: 'Client',
        headerClassName: 'end-leveling',
        className: 'end-leveling',
        accessor: 'clientAmount',
        type: ALL,
        maxWidth: 100,
        Cell({ value }) {
          return <Amount highlightFractionalValue coinsAmount={value} defaultValue="—" showDollarSign />;
        }
      },
      {
        Header: 'Patient',
        accessor: 'patientAmount',
        headerClassName: 'end-leveling',
        className: 'end-leveling',
        maxWidth: 150,
        type: PATIENT_STIPEND,
        Cell({ value }) {
          return (
            <AmountWithType coinsAmount={value?.amount} amountType={value?.type} defaultValue="—" showDollarSign />
          );
        }
      },
      {
        Header: 'Site',
        accessor: 'siteAmount',
        headerClassName: 'end-leveling',
        className: 'end-leveling',
        maxWidth: 150,
        Cell({ value }) {
          return (
            <AmountWithType coinsAmount={value?.amount} amountType={value?.type} defaultValue="—" showDollarSign />
          );
        }
      },
      {
        Header: 'Vendor Name',
        accessor: 'vendorAmount',
        headerClassName: 'center-leveling',
        className: 'center-leveling',
        maxWidth: 400,
        Cell({ value }) {
          return (
            <span title={value?.vendorName} className="elipsis-overflow">
              {value?.vendorName || '—'}
            </span>
          );
        }
      },
      {
        Header: 'Vendor',
        accessor: 'vendorAmount',
        headerClassName: 'end-leveling',
        className: 'end-leveling',
        maxWidth: 150,
        Cell({ value }) {
          return (
            <AmountWithType coinsAmount={value?.amount} amountType={value?.type} defaultValue="—" showDollarSign />
          );
        }
      },
      {
        Header: 'Revenue Date',
        headerClassName: 'center-leveling',
        className: 'center-leveling',
        type: ALL,
        maxWidth: 150,
        Cell: row => {
          return (
            <CellFormattedDate
              date={row.original.revenueDate}
              className={cx({ 'revenue-date-edited': row.original.isRevenueDateChanged })}
            />
          );
        }
      },
      {
        Header: 'Gross',
        accessor: 'gross',
        headerClassName: 'end-leveling',
        className: 'end-leveling',
        type: ALL,
        maxWidth: 100,
        Cell({ value }) {
          return <Amount highlightFractionalValue coinsAmount={value} defaultValue="—" showDollarSign />;
        }
      },
      {
        Header: 'Net',
        accessor: 'netRevenueAmount',
        headerClassName: 'end-leveling',
        className: 'end-leveling',
        type: ALL,
        maxWidth: 100,
        Cell({ value }) {
          return <Amount highlightFractionalValue coinsAmount={value} defaultValue="—" showDollarSign />;
        }
      },
      {
        Header: 'Adjustment Date',
        accessor: 'adjustmentDate',
        headerClassName: 'center-leveling',
        className: 'center-leveling',
        type: ALL,
        maxWidth: 150,
        Cell: ({ value }) => <CellFormattedDate date={value} defaultValue="—" />
      },
      {
        Header: 'User Name',
        accessor: 'adjusterName',
        headerClassName: 'center-leveling',
        className: 'center-leveling',
        type: ALL,
        maxWidth: 200,
        Cell({ value }) {
          return (
            <span title={value} className="elipsis-overflow">
              {value || '—'}
            </span>
          );
        }
      },
      {
        Header: 'Reason',
        accessor: 'reason',
        type: ALL,
        className: 'display-flex',
        maxWidth: 500,
        Cell({ value }) {
          return (
            <span title={value} className="elipsis-overflow">
              {value || '—'}
            </span>
          );
        }
      }
    ];

    function getCorrectFilterPredicateDependOnPatientStipend(columnParams) {
      const { type } = columnParams;
      if (isPatientStipend) {
        return type === PATIENT_STIPEND || type === ALL;
      } else {
        return type !== PATIENT_STIPEND;
      }
    }

    return columns.filter(getCorrectFilterPredicateDependOnPatientStipend);
  }
}
