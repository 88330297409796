import moment from 'moment/moment';

import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../constants/dateFormat';

import { SITE_CREDIT, SITE_CREDIT_WH } from './SitePaymentsConstants';

export const formatCreditApplicationDateColumn = row => {
  if (![SITE_CREDIT, SITE_CREDIT_WH].includes(row.sitePaymentType)) {
    return 'N/A';
  }
  return row?.creditApplicationDate ? moment(row.creditApplicationDate).format(DD_SLASH_MMM_SLASH_YYYY) : '';
};

export const formatCreditApplicationEventsColumn = row => {
  if (![SITE_CREDIT, SITE_CREDIT_WH].includes(row.sitePaymentType)) {
    return 'N/A';
  }
  return row.appliedCreditEvents ? row.appliedCreditEvents : '';
};
