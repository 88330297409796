import { EXTERNAL_INTEGRATION } from '../../../constants/accessToPages';

import DataMapping from './DataMapping';

export const dataMappingRouterConfig = {
  name: 'Afterburner Mapping',
  path: '/afterburner-mapping',
  component: DataMapping,
  access: [EXTERNAL_INTEGRATION]
};
