import { cloneDeep } from 'lodash';
import { get } from 'lodash/object';
import moment from 'moment';

import {
  APPOINTMENT_TYPES,
  NON_PATIENT_EVENT,
  PATIENT_ENCOUNTER_EVENT,
  PATIENT_SITUATIONAL_ENCOUNTER_EVENT,
  PATIENT_UNEXPECTED_ENCOUNTER_EVENT
} from './CalendarEventType';
import { DRAFT_EVENT_ID } from './DraftEventProvider';

export function toCalendarEvent(event) {
  if (!event) {
    return;
  }
  if (APPOINTMENT_TYPES.includes(event.type)) {
    const originEnd = moment(event.start)
      .clone()
      .add(event.duration);
    const originStart = moment(event.start);
    const end = moment(event.start)
      .clone()
      .add(event.duration)
      .toDate();
    const start = moment(event.start).toDate();
    const title = event.subject;
    return { ...event, start, end, title, originStart, originEnd };
  }
  return { ...event };
}

export function toCalendarAllDayEvent(event) {
  if (!event) {
    return;
  }
  const start = event.milestoneDate || event.dueDate;
  const allDay = false; //this set to false to skip rendering all day events section of CalendarBig and use custom component
  return { ...event, allDay, start };
}

export function fromCalendarEvent(event) {
  if (!event) {
    return;
  }
  if (APPOINTMENT_TYPES.includes(event.type)) {
    const start = moment(event.start);
    const duration = moment.duration(moment(event.end).diff(moment(event.start)));
    return { ...event, start, duration };
  }
  return { ...event };
}

export function fromDialogAppointment(event) {
  if (!event) {
    return;
  }

  const participants = [...event.participants];
  if (event.patient) {
    participants.push(event.patient);
  }
  const {
    timeDuration: { start, duration },
    ...externalEvent
  } = event;
  return {
    ...externalEvent,
    start,
    duration,
    participants: participants
  };
}

export function toDialogAppointment(event) {
  if (!event) {
    return;
  }

  const { start, duration, ...dialogEvent } = event;
  const timeDuration = { start, duration };
  return { ...dialogEvent, timeDuration, participants: getParticipantsWithoutOrganizerAndPatients(event) };
}

export function toBackendModel(event) {
  switch (event.type) {
    case PATIENT_ENCOUNTER_EVENT:
      return patientEncounterEventToBackendModel(event);
    case NON_PATIENT_EVENT:
      return nonPatientEventToBackendModel(event);
    case PATIENT_UNEXPECTED_ENCOUNTER_EVENT:
      return patientEventToBackendModel(event);
    case PATIENT_SITUATIONAL_ENCOUNTER_EVENT:
      return patientSituationalEncounterEventToBackendModel(event);
    default:
      console.log('Event type not yet supported');
  }
}

function nonPatientEventToBackendModel({
  id,
  start,
  duration,
  subject,
  studySite,
  participants,
  comment,
  type,
  organizer,
  eventId,
  nonPatientEventTypeCode
}) {
  id = id !== DRAFT_EVENT_ID ? eventId : '';
  return {
    id,
    start,
    duration,
    subject,
    studySiteId: studySite?.id,
    siteId: !studySite?.id ? studySite?.site?.id : null,
    participants,
    comment,
    type,
    organizer,
    nonPatientEventTypeCode
  };
}

function patientSituationalEncounterEventToBackendModel({
  id,
  start,
  duration,
  subject,
  patient: { patientId },
  study: { id: studyId },
  encounter: { key: encounterId },
  participants,
  comment,
  type,
  organizer,
  eventId
}) {
  id = id !== DRAFT_EVENT_ID ? eventId : '';
  return {
    id,
    type,
    start,
    duration,
    subject,
    patientId,
    studyId,
    situationalEncounterId: encounterId,
    organizer,
    participants,
    comment
  };
}

function patientEncounterEventToBackendModel({
  id,
  start,
  duration,
  subject,
  patient: { patientId },
  study: { id: studyId },
  encounter: { id: encounterId },
  participants,
  comment,
  type,
  organizer,
  eventId,
  reasonForOutOfWindow,
  reasonForSkippedEncounter
}) {
  id = id !== DRAFT_EVENT_ID ? eventId : '';
  return {
    id,
    type,
    start,
    duration,
    subject,
    patientId,
    studyId,
    encounterId,
    organizer,
    participants,
    comment,
    reasonForOutOfWindow,
    reasonForSkippedEncounter
  };
}

function patientEventToBackendModel({
  id,
  start,
  duration,
  subject,
  patient: { patientId },
  study: { id: studyId },
  participants,
  comment,
  type,
  organizer,
  eventId
}) {
  id = id !== DRAFT_EVENT_ID ? eventId : '';
  return {
    id,
    type,
    start,
    duration,
    subject,
    patientId,
    studyId,
    organizer,
    participants,
    comment
  };
}

export function fromBackendModel(event, calendarId, calendarIndex) {
  event.eventId = cloneDeep(event.id);
  event.id = `${event.eventId}-${calendarId}`;
  event.calendarIndex = calendarIndex;
  event.calendarId = calendarId;
  event.start = moment(event.start);
  event.duration = moment.duration(event.duration, 's');

  event.studySite = event?.studySite || resolvePartialStudySite(event);

  event.study = event.studySite.study;
  event.study.sitePatientId = event.sitePatientId;
  event.siteName = get(event, 'studySite.site.name', '');
  return event;
}

function resolvePartialStudySite(event) {
  return {
    id: null,
    study: {},
    site: event.site
  };
}

export function fromBackendCalendars(calendars) {
  return calendars.map((c, calendarIndex) => ({
    ...c,
    events: c.events.map(e => fromBackendModel(e, c.id, calendarIndex)),
    tasks: c.tasks || [],
    milestones: c.milestones.map(e => fromBackendModel(e, c.id, calendarIndex)) || []
  }));
}

export function toBackendCalendar({ id, type, name, label }) {
  return {
    id,
    type: type.toUpperCase(),
    name,
    label
  };
}

function getParticipantsWithoutOrganizerAndPatients(appointment) {
  const { participants = [] } = appointment;
  return participants.filter(p => (appointment.patient ? p.patientId !== appointment.patient.patientId : true));
}
