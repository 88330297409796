import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { isEmpty } from 'lodash/lang';
import uuid from 'uuid';

import FinInvoiceReconcileApi from '../../../../api/finance/FinInvoiceReconcileApi';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import { PAYMENTS_RECONCILED, SOMETHING_WENT_WRONG } from '../../../../constants/notificationMessages';
import { toCoins } from '../../../../services/financial';
import { CREDIT_MEMO, DEBIT_MEMO } from '../NewInvoice/InvoiceTable/TableConstants';

export const ReconcileContext = React.createContext(null);

const ReconcileProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [events, setEvents] = useState([]);
  const [initialEvents, setInitialEvents] = useState({});
  const [datePosted, setDatePosted] = useState(null);
  const [depositNumber, setDepositNumber] = useState('');

  const [changedEvents, setChangedEvents] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const mapEvents = useCallback(data => {
    const dataEvents = cloneDeep(data);
    return dataEvents.reduce((result, reconcile) => {
      result[reconcile.id] = reconcile;
      return result;
    }, {});
  }, []);

  useEffect(() => {
    if (!isEmpty(events) && !isEmpty(initialEvents)) {
      const updatedChangedEvents = events.filter(
        ledgerEvent =>
          ledgerEvent.reconcileAmountPaid !== initialEvents[ledgerEvent.id].reconcileAmountPaid ||
          ledgerEvent.reconcileComment !== initialEvents[ledgerEvent.id].reconcileComment
      );
      setChangedEvents(updatedChangedEvents);
    }
  }, [events, initialEvents]);

  useEffect(() => {
    if (!isEmpty(location.state?.reconcileIds)) {
      FinInvoiceReconcileApi.getInvoiceReconciles(location.state?.reconcileIds)
        .then(({ data }) => {
          const preparedData = data.map(el => {
            const clientAmountCoins = el?.invoiceAmount;
            let totalAmountPaidCoins = toCoins(el?.reconcileAmountPaid) + el?.reconcileTotalAmountPaid;
            if ([CREDIT_MEMO, DEBIT_MEMO].includes(el.invoiceType) && totalAmountPaidCoins === 0) {
              totalAmountPaidCoins = clientAmountCoins;
            }
            const initialVariance = totalAmountPaidCoins - clientAmountCoins;
            return { ...el, id: uuid(), initialVariance };
          });
          setEvents(preparedData);
          setInitialEvents(mapEvents(preparedData));
        })
        .catch(() => {
          NotificationManager.error(SOMETHING_WENT_WRONG);
        });
    }
  }, [location.state?.reconcileIds, mapEvents]);

  const eventsForTable = useMemo(() => {
    return events.map(ledgerEvent => {
      const clientAmountCoins = ledgerEvent?.invoiceAmount;
      let totalAmountPaidCoins = toCoins(ledgerEvent?.reconcileAmountPaid) + ledgerEvent?.reconcileTotalAmountPaid;
      if ([CREDIT_MEMO, DEBIT_MEMO].includes(ledgerEvent.invoiceType) && totalAmountPaidCoins === 0) {
        totalAmountPaidCoins = clientAmountCoins;
      }
      const variance = totalAmountPaidCoins - clientAmountCoins;

      return { ...ledgerEvent, variance };
    });
  }, [events]);

  const onTableDataChange = useCallback((eventIdForUpdate, newValue, columnForUpdate) => {
    setEvents(prevState =>
      prevState.map(event => ({
        ...event,
        [columnForUpdate]: event.id === eventIdForUpdate ? newValue : event[columnForUpdate]
      }))
    );
  }, []);

  const onReconcile = useCallback(() => {
    const preparedData = changedEvents.map(event => ({
      id: event.reconcileId,
      finLedgerEventId: event.eventId,
      finInvoiceId: event.finInvoiceId,
      invoiceNumber: event.invoiceNumber,
      amountPaid: toCoins(event.reconcileAmountPaid),
      comment: event.reconcileComment,
      postedDate: datePosted.utc(true),
      depositNumber: depositNumber
    }));
    FinInvoiceReconcileApi.postUpdatedInvoiceReconciles(preparedData)
      .then(() => {
        NotificationManager.success(preparedData.length + PAYMENTS_RECONCILED);
        navigate('/invoice');
      })
      .catch(() => {
        NotificationManager.error(SOMETHING_WENT_WRONG);
      });
  }, [changedEvents, datePosted, depositNumber, navigate]);

  const onApplyOrClear = useCallback(
    (amount, comment) => {
      setEvents(prevState =>
        prevState.map(event => {
          return selectedItems.includes(event.id)
            ? { ...event, reconcileAmountPaid: amount, reconcileComment: comment }
            : event;
        })
      );
    },
    [selectedItems]
  );

  const applyFullAmountPaid = useCallback(() => {
    setEvents(prevState =>
      prevState.map(event => {
        const updatedReconcile = +(event.initialVariance / 100).toFixed(2);
        return {
          ...event,
          reconcileAmountPaid: updatedReconcile === 0 ? null : -updatedReconcile
        };
      })
    );
  }, []);

  const value = useMemo(
    () => ({
      events,
      datePosted,
      setDatePosted,
      depositNumber,
      setDepositNumber,
      onTableDataChange,
      onReconcile,
      onApplyOrClear,
      changedEvents,
      selectedItems,
      setSelectedItems,
      applyFullAmountPaid,
      eventsForTable
    }),
    [
      applyFullAmountPaid,
      changedEvents,
      datePosted,
      depositNumber,
      events,
      onApplyOrClear,
      onReconcile,
      onTableDataChange,
      selectedItems,
      eventsForTable
    ]
  );

  return <ReconcileContext.Provider value={value}>{children}</ReconcileContext.Provider>;
};

export function withReconcileContext(Component) {
  return function WrapperComponent(props) {
    return (
      <ReconcileProvider>
        <Component {...props} />
      </ReconcileProvider>
    );
  };
}
