import axios from 'axios';

const ROOT = 'api/v1/calendar/participants';

const ParticipantApi = {
  getPatientParticipants(page, size, search, includeDropped) {
    return axios.get(`${ROOT}/patients`, {
      params: {
        page,
        size,
        search,
        includeDropped
      },
      hideLoader: true
    });
  },
  getPatientParticipantsCount() {
    return axios.get(`${ROOT}/patients/count`, { hideLoader: true });
  },
  processEmptyNames(participants) {
    /*TODO: should be moved to service file*/
    return participants.map(participant => {
      participant.name =
        participant.firstName || participant.lastName ? `${participant.firstName} ${participant.lastName}` : 'No Name';
      return participant;
    });
  },
  getAllSmOrPiOrSubPiParticipantsPerStudyRelatedToPatient(sitePatientId) {
    return axios
      .get(`${ROOT}/users/${sitePatientId}`, { hideLoader: true })
      .then(({ data: participants }) => ({ data: this.processEmptyNames(participants) }));
  },
  getAllParticipantsPerStudyAndSite(studyId, siteId) {
    return axios
      .get(`${ROOT}/users/non-patient`, {
        params: {
          studyId,
          siteId
        },
        hideLoader: true
      })
      .then(({ data: participants }) => ({ data: this.processEmptyNames(participants) }));
  },
  getPrimaryStudyManager(sitePatientId, encounterId) {
    return axios.get(`${ROOT}/primary-study-manager/study/patient/${sitePatientId}/encounter/${encounterId}`);
  },
  getOrganizerByPersonnelId(personnelIdentifier) {
    return axios.get(`${ROOT}/organizer/${personnelIdentifier}`);
  }
};

export default ParticipantApi;
