import { isString } from 'lodash/lang';
import { pick } from 'lodash/object';

import { CloseRegularEncounterApi, SitePatientEncounterApi } from '../../../../../api';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { DATA_SAVED } from '../../../../../constants/notificationMessages';
import { CANCELED, CLOSED } from '../../../../../constants/ssuStatuses';
import { MANAGE_PRE_SCREEN_WORK_LIST, MANAGE_PROTOCOL_ENCOUNTER_DATA } from '../../../../../constants/userOperations';
import userRoles, {
  ROLE_RECRUITER,
  ROLE_STUDY_LEAD,
  ROLE_SYSTEM_ADMINISTRATOR
} from '../../../../../constants/userRoles';
import withdrawnPatientStatuses from '../../../../../constants/withdrawnPatientStatuses';
import { userHasAccessTo, userHasRole } from '../../../../../services/auth';
import { onRequestError } from '../../../../../services/handlers';

export function saveStudyManagerForEncounter(ssuPatientId, ssuPatientEncounterId, studyManagerId) {
  if (isString(ssuPatientId) && isString(ssuPatientEncounterId) && isString(studyManagerId)) {
    return SitePatientEncounterApi.savePatientEncounterStudyManager(ssuPatientId, ssuPatientEncounterId, studyManagerId)
      .then(res => {
        NotificationManager.success(DATA_SAVED);
        return res;
      })
      .catch(onRequestError);
  }
  return Promise.reject();
}

export function prepareEncounterStudyManagers(activeUsers) {
  const smRoleIdentifier = 'DS-AUTH-000001';
  return activeUsers
    .filter(({ authority_identifier }) => authority_identifier === smRoleIdentifier)
    .map(u => pick(u, ['uniqueIdentifier', 'name', 'deleteFlag', 'primaryStudyManagerForSsu']));
}

export function isCloseEncounterMenuAvailable(isNonProtocol, sitePatientStatus) {
  if (
    !userHasRole([
      userRoles.ROLE_SYSTEM_ADMINISTRATOR,
      userRoles.ROLE_STUDY_MANAGER,
      userRoles.ROLE_PRINCIPAL_INVESTIGATOR,
      userRoles.ROLE_SUB_INVESTIGATOR,
      userRoles.ROLE_STUDY_LEAD,
      userRoles.ROLE_PATIENT_LIAISON,
      userRoles.ROLE_RECRUITER,
      userRoles.ROLE_PATIENT_REPRESENTATIVE
    ])
  ) {
    return false;
  }
  if (isNonProtocol) {
    return true;
  } else {
    return withdrawnPatientStatuses.includes(sitePatientStatus);
  }
}

export function isCancelEncounterMenuAvailable(isNonProtocol) {
  if (isNonProtocol && userHasRole([ROLE_STUDY_LEAD, ROLE_RECRUITER])) {
    return true;
  } else if (
    !userHasRole([
      userRoles.ROLE_SYSTEM_ADMINISTRATOR,
      userRoles.ROLE_STUDY_MANAGER,
      userRoles.ROLE_PRINCIPAL_INVESTIGATOR,
      userRoles.ROLE_SUB_INVESTIGATOR,
      userRoles.ROLE_PATIENT_LIAISON
    ])
  ) {
    return false;
  }
  return true;
}

export function isEncounterStartedAndIncomplete(encounterDetailsList) {
  const skippableItemGroups = filterOutNonSkippableEncounters(encounterDetailsList);
  return !!skippableItemGroups.some(ig => ig.completePercentage !== '0.0');
}

function filterOutNonSkippableEncounters(encounterDetailsList) {
  /*TODO: Not work properly, `elementName` may be different*/
  const excludedItemGroups = ['Comments', 'PI Review', 'SM Review'];
  return encounterDetailsList.filter(ig => !excludedItemGroups.includes(ig.elementName));
}

function filterOutNonSkippableItemGroupsByDomainCode(encounterDetailsList) {
  const nonSkippableIGDomainCodes = ['CO', 'EREVPI', 'EREVSM'];
  return encounterDetailsList.filter(ig => !nonSkippableIGDomainCodes.includes(ig.domainCode));
}

export function notPerformedAndNotAskedShortcut(
  isNonProtocol,
  encounterDetailsList,
  studyId,
  sitePatientId,
  sitePatientEncounterId,
  reason = 'Hard-coded reason',
  studyManagerId
) {
  const closeEncounterRequest = {
    reason: reason,
    defaultStudyManagerId: studyManagerId
  };
  return CloseRegularEncounterApi.closeRegularEncounter(
    isNonProtocol,
    studyId,
    sitePatientId,
    sitePatientEncounterId,
    closeEncounterRequest
  )
    .then(res => {
      NotificationManager.success(DATA_SAVED);
      return res;
    })
    .catch(onRequestError);
}

function canItemGroupBeNotPerformed(itemGroup) {
  return !!(itemGroup.completePercentage === '0.0' && !itemGroup.skipped);
}

function isItemGroupStartedButNotComplete(itemGroup) {
  return !!(itemGroup.completePercentage !== '0.0' && itemGroup.completePercentage !== '100.0' && !itemGroup.skipped);
}

export function getNotStartedEncounterNames(encounterDetailsList) {
  const skippableItemGroups = filterOutNonSkippableItemGroupsByDomainCode(encounterDetailsList);
  const names = [];
  skippableItemGroups.forEach(item => {
    if (canItemGroupBeNotPerformed(item)) {
      names.push(item.elementName);
    }
  });
  return names;
}

export function getStartedEncounterNames(encounterDetailsList) {
  const skippableItemGroups = filterOutNonSkippableItemGroupsByDomainCode(encounterDetailsList);
  const names = [];
  skippableItemGroups.forEach(item => {
    if (isItemGroupStartedButNotComplete(item)) {
      names.push(`${item.elementName} ${item.completionRange}`);
    }
  });
  return names;
}

export function isAbleToEditEncounter(isNonProtocol, ssuStatus) {
  return isNonProtocol
    ? userHasRole(ROLE_SYSTEM_ADMINISTRATOR) ||
        (ssuStatus !== CLOSED && ssuStatus !== CANCELED && userHasAccessTo(MANAGE_PRE_SCREEN_WORK_LIST))
    : userHasRole(ROLE_SYSTEM_ADMINISTRATOR) ||
        (ssuStatus !== CLOSED && userHasAccessTo(MANAGE_PROTOCOL_ENCOUNTER_DATA));
}
