import { REVENUE } from '../../../../constants/accessToPages';

import { RevenueAdjustment } from './RevenueAdjustment/RevenueAdjustment';
import Revenue from './Revenue';

export const revenueRouterConfig = {
  name: 'Revenue',
  path: '/revenue',
  rootBreadcrumbName: 'Finance',
  component: Revenue,
  access: [REVENUE],
  inherited: [
    {
      name: 'Adjust Revenue',
      path: '/adjusting/:eventNumber',
      access: [REVENUE],
      component: RevenueAdjustment
    }
  ]
};
