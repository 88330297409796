import React, { useCallback, useState } from 'react';
import { Card, Icon, Typography } from '@mui/material';
import cx from 'classnames';

import FinClientApi from '../../../../../../api/finance/FinClientApi';
import FinVendorApi from '../../../../../../api/finance/FinVendorApi';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import { MANAGE_CLIENT_ACCOUNTS, MANAGE_VENDOR_ACCOUNTS } from '../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../services/auth';
import { onRequestError } from '../../../../../../services/handlers';

import './AccountCard.scss';

export const AccountCard = ({ account, updateAccounts, openAddAccountModal, type }) => {
  const [open, setOpen] = useState(false);

  const deleteCard = useCallback(() => {
    if (account.used) return;
    const endpointCall = type === 'Clients' ? FinClientApi.updateEnabled : FinVendorApi.updateState;
    return endpointCall(false, account.id)
      .then(() => {
        NotificationManager.success(`${account.name} has been deleted.`);
        setOpen(false);
        updateAccounts();
      })
      .catch(onRequestError);
  }, [account.used, account.id, account.name, type, updateAccounts]);

  const userHasAccessToCardMenu =
    type === 'Clients' ? userHasAccessTo(MANAGE_CLIENT_ACCOUNTS) : userHasAccessTo(MANAGE_VENDOR_ACCOUNTS);

  return (
    <Card
      data-testid="account-card"
      className="account-card"
      sx={{
        '&.MuiCard-root': {
          overflow: 'visible'
        }
      }}
    >
      <div className="account-card-header">
        <Typography className="header">{account?.departmentName || 'Department/Contact Name unavailable'}</Typography>
        {userHasAccessToCardMenu && (
          <Icon data-testid="account-card-sub-menu" onClick={() => setOpen(prevState => !prevState)}>
            more_vert
          </Icon>
        )}
      </div>
      <Typography className="information-header">{account?.name}</Typography>
      <Typography className="information">{account?.address1}</Typography>
      <Typography className="information">{account?.address2}</Typography>
      <Typography className="information">{`${account?.city} ${account?.state} ${account?.zipCode}`}</Typography>
      <Typography className="information phone">{account?.phone || 'Phone unavailable'}</Typography>
      <Typography className="information email">{account?.email || 'Email unavailable'}</Typography>
      {open && (
        <div className="card-menu">
          <div
            onClick={() => {
              openAddAccountModal('Edit', account.id, updateAccounts);
              setOpen(false);
            }}
          >
            Edit
          </div>
          <div className={cx({ disabled: account.used })} onClick={deleteCard}>
            Delete
          </div>
        </div>
      )}
    </Card>
  );
};
