import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash/lang';
import { omit } from 'lodash/object';

import { PatientStatusJourneyApi } from '../../../../../../api';
import Select from '../../../../../../common/data-entry/Select';
import { generateUrlByKey, useCurrentRoute } from '../../../../../root/router';
import { PatientInfoContext } from '../PatientInfoContext';

import { InformedConsentWidget } from './InformedConsentWidget/InformedConsentWidget';
import LogsWidget from './LogsWidget/LogsWidget';
import ProgressNotesWidget from './ProgressNotesWidget/ProgressNotesWidget';

import './PatientInfoSidebar.scss';

export default function PatientInfoSidebarMenu() {
  const navigate = useNavigate();
  const currentRoute = useCurrentRoute();
  const ssuPatientId = currentRoute.params?.ssuPatientId;
  const { studyHistory, currentStudy, forms } = useContext(PatientInfoContext);
  const [alertStatus, setAlertStatus] = useState(null);

  const studies = useMemo(
    function() {
      return (
        studyHistory &&
        studyHistory.map(function(study) {
          return { ...study, disabled: !study.granted };
        })
      );
    },
    [studyHistory]
  );
  const initialValue = useMemo(
    function() {
      return studies?.find(study => study.ssuPatientId === ssuPatientId);
    },
    [ssuPatientId, studies]
  );

  useEffect(() => {
    if (ssuPatientId) {
      PatientStatusJourneyApi.getCurrentPatientStatusType(ssuPatientId).then(({ data }) => {
        setAlertStatus(data);
      });
    }
  }, [ssuPatientId]);

  return (
    <div className="eds-patient-info-sidebar">
      <div className="pis-ssu-patient-select">
        {studyHistory && (
          <Select
            value={initialValue}
            label="Select Study"
            dataSource={studies}
            optionLabelKey="studyName"
            optionValueKey="ssuPatientId"
            customOptionTemplateFunction={customOptionTemplateFunction}
            customSelectedValueTemplateFunction={study => study[0].studyName}
            onChange={value => {
              if (value?.ssuPatientId && currentRoute.params.ssuPatientId !== value.ssuPatientId) {
                navigate(
                  generateUrlByKey(currentRoute.key, {
                    ssuPatientId: value.ssuPatientId,
                    ...omit(currentRoute.params, 'ssuPatientId')
                  })
                );
              }
            }}
            clearable={false}
          />
        )}
      </div>
      <div className={'pis-status-indicator'}>
        Status:
        <span>
          <Link to={generateUrlByKey(`${currentRoute.key}.Patient Status`, { ssuPatientId, ...currentRoute.params })}>
            {currentStudy?.patientStatus || '---'}
          </Link>
        </span>
        {alertStatus?.hasSkipped && <div className="basic-dot skipped" />}
        {alertStatus?.hasFailed && <div className="basic-dot failed" />}
      </div>
      {!isEmpty(forms.completedIcf) && <InformedConsentWidget />}
      <LogsWidget ssuPatientId={ssuPatientId} />
      <ProgressNotesWidget ssuPatientId={ssuPatientId} />
    </div>
  );
}

function customOptionTemplateFunction({ studyName, patientStatus }) {
  return (
    <React.Fragment>
      {studyName}
      {patientStatus && <span className="patient-status">{statusNormalizer(patientStatus)}</span>}
    </React.Fragment>
  );
}

function statusNormalizer(status) {
  return capitaliseFirstLetter(status.toLowerCase());
}

function capitaliseFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
