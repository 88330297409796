import React from 'react';
import { AccordionItem, AccordionItemBody, AccordionItemTitle } from 'react-accessible-accordion';

import './PredefinedItemsToSelect.scss';

export function PredefinedItemsToSelect({ inputs, onChangePredefinedFields }) {
  return (
    <AccordionItem expanded>
      <AccordionItemTitle>
        <h3 className="u-position-relative c-p">
          Predefined Table
          <div className="accordion__arrow" role="presentation" />
        </h3>
      </AccordionItemTitle>
      <AccordionItemBody>
        <div className="row">
          <div className="col-md-3">
            <label className="form_required_class_after">Predefined Column Name</label>
          </div>
          <div className="col-md-6 p-0">
            <input
              type="text"
              value={(inputs[0] && inputs[0].predefinedColumnName) || ''}
              onChange={event => onChangePredefinedFields(event, 'name')}
              className="form-control"
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3">
            <label className="form_required_class_after"> Predefined Column Values </label>
          </div>
          <div className="col-md-6 p-0">
            <textarea
              row="2"
              onChange={event => onChangePredefinedFields(event, 'value')}
              value={(inputs[0] && inputs[0].predefinedColumnValues) || ''}
              className="form-control"
            />
          </div>
        </div>
      </AccordionItemBody>
    </AccordionItem>
  );
}
