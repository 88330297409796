import React, { useState } from 'react';

import ModalBoxes from '../../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../../../../../common/notifications/NotificationManager';
import { FINAL_OPTION_LIST_REQUIRED } from '../../../../../../../../../constants/notificationMessages';

import ControlledTermList from './ControlledTermList';

import './EditControlledTermListModal.scss';

const EditControlledTermListModal = ({
  itemIndex,
  inputType,
  general,
  protocolId,
  terminologyVersionId,
  isTemplateMode,
  modalBox,
  initialControlledTermList,
  initialControlledListName,
  updateControlledListAndName
}) => {
  const [finalControlledTermListForSave, setFinalControlledTermListForSave] = useState([]);
  const [selectedControlledTermListName, setSelectedControlledTermListName] = useState(null);

  return (
    <>
      <ModalBoxes.Header>Edit Controlled Term List</ModalBoxes.Header>
      <ModalBoxes.Body>
        <ControlledTermList
          inputType={inputType}
          protocolId={protocolId}
          terminologyVersionId={terminologyVersionId}
          isTemplateMode={isTemplateMode}
          initialControlledTermList={initialControlledTermList}
          initialControlledListName={initialControlledListName}
          setFinalControlledTermListForSave={setFinalControlledTermListForSave}
          setSelectedControlledTermListName={setSelectedControlledTermListName}
        />
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority={'medium'} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onSave}>Save</Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );

  function termListIsValid() {
    let isValid = true;
    if (!finalControlledTermListForSave.length) {
      isValid = false;
      NotificationManager.error(FINAL_OPTION_LIST_REQUIRED);
    }
    if (!selectedControlledTermListName) {
      isValid = false;
      NotificationManager.error('Controlled Term List is required');
    }
    return isValid;
  }

  function onClose() {
    modalBox.close();
  }
  function onSave() {
    if (termListIsValid()) {
      updateControlledListAndName(selectedControlledTermListName, finalControlledTermListForSave, itemIndex, general);
      modalBox.close();
    }
  }
};

EditControlledTermListModal.className = 'edit-controlled-term-list-modal';
EditControlledTermListModal.size = 'w950';

export default EditControlledTermListModal;
