import React, { useContext } from 'react';

import Button from '../../../../../../../common/general/Button';
import Icon from '../../../../../../../common/general/Icon';

import { InstructionContext } from './InstructionContextProvider';

export function InstructionTab() {
  const { instructions, editInstruction, canUserAddInstructions } = useContext(InstructionContext);
  return (
    <React.Fragment>
      Instructions - {instructions?.length || 0}
      {canUserAddInstructions && (
        <React.Fragment>
          <Button size="h28" priority="medium" onClick={() => editInstruction()}>
            <Icon>add</Icon>
          </Button>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
