import React from 'react';
import { isString } from 'lodash/lang';

import Button from '../../../../common/general/Button';
import Icon from '../../../../common/general/Icon';

import { AwaitingSignOffTopTenWidget } from './AwaitingSignOffTopTenWidget/AwaitingSignOffTopTenWidget';
import CalendarAppointmentWidget from './CalendarAppointmentWidget/CalendarAppointmentWidget';
import EncounterSignOffWidget from './EncounterSignOffWidget/EncounterSignOffWidget';
import EnrolledPatientsJourneyWidget from './EnrolledPatientsJourneyWidget/EnrolledPatientsJourneyWidget';
import PendingScheduleWidget from './PendingScheduleWidget/PendingScheduleWidget';
import TopTenTasksWidget from './TopTenTasksWidget/TopTenTasksWidget';
import { WorkProgressWidget } from './WorkProgressWidget/WorkProgressWidget';

import './Widgets.scss';

export default function Widgets({ widgets, studies, ssus }) {
  return widgets.map((widget, index) => {
    if (widget && widget?.configJson && isString(widget.configJson)) {
      widget.configJson = JSON.parse(widget.configJson);
    }
    const Component = widgetsMapping[widget.type];
    return <Component widget={widget} key={index} studies={studies} ssus={ssus} />;
  });
}

function WidgetTemplate({ widget }) {
  return (
    <div className="p-3 widget-container">
      <div className="widget">
        <div className="widget-header p-2 px-3">
          <div className="title">{widget.name}</div>
          <Button size="h28" disabled={true} priority="medium">
            <Icon>restart_alt</Icon>
          </Button>
        </div>
        <div className="mx-3">{widget.description}</div>
      </div>
    </div>
  );
}

const widgetsMapping = {
  STUDY_PARTICIPANT_COUNT: WidgetTemplate,
  STUDY_ENROLLED_PATIENT: EnrolledPatientsJourneyWidget,
  STUDY_AE_TRACKER: WidgetTemplate,
  CALENDAR_TODAY_APPOINTMENTS: CalendarAppointmentWidget,
  TOP_TEN_TASKS: TopTenTasksWidget,
  TOP_TEN_OPEN_ENCOUNTERS: AwaitingSignOffTopTenWidget,
  WORK_PROGRESS: WorkProgressWidget,
  ELLIGO_SCORE: WidgetTemplate,
  EARNED_REVENUE: WidgetTemplate,
  ENCOUNTER_SIGN_OFF: EncounterSignOffWidget,
  PENDING_SCHEDULE: PendingScheduleWidget
};
