import { CLOSED } from '../../../../../constants/ssuStatuses';
import { VIEW_SM_PI_REVIEW_COMMENT } from '../../../../../constants/userOperations';
import { VIEW_SM_PI_REVIEW_SIGN_BUTTON } from '../../../../../constants/userOperations';
import { MANAGE_SM_PI_REVIEW_COMMENT } from '../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../services/auth';

export function isUserAbleToManageSmAndPiReviewComment(studySiteStatus) {
  return studySiteStatus !== CLOSED && userHasAccessTo(MANAGE_SM_PI_REVIEW_COMMENT);
}

export function isUserAbleToViewCommentAtReview() {
  return userHasAccessTo(VIEW_SM_PI_REVIEW_COMMENT);
}

export function isUserAbleToSeeSignEncounterButton() {
  return userHasAccessTo(VIEW_SM_PI_REVIEW_SIGN_BUTTON);
}
