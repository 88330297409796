import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { every, some } from 'lodash/collection';
import { isEmpty, isEqual, isFunction } from 'lodash/lang';
import { omit } from 'lodash/object';
import uuid from 'uuid/v4';

import { afterburnerApi } from '../../../../api/patient/AftrburnerApi';
import Input from '../../../../common/data-entry/Input';
import Checkbox from '../../../../common/data-entry/InputSelectors/Checkbox';
import Select from '../../../../common/data-entry/Select';
import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../common/general/Button';
import ButtonGroup from '../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import {
  AUTHENTICATION_URL_IS_NOT_CORRECT,
  DATA_SAVED,
  ENDPOINT_IN_USE,
  ENDPOINT_URL_IS_NOT_CORRECT,
  ENDPOINT_URL_IS_REQUIRED,
  HTTP_TYPE_IS_REQUIRED,
  KEY_IS_REQUIRED,
  PLATFORM_NAME_IS_REQUIRED,
  USERNAME_AND_PASSWORD_REQUIRED
} from '../../../../constants/notificationMessages';
import { onRequestError } from '../../../../services/handlers';
import { validateEndpoint } from '../../setup/Protocol/EncounterEpochTableTemplate/EncountersHeaders/MappingSetupModal/MappingSetupServices';

import MappingAuthenticationTest from './MappingAuthentificationTest/MappingAuthentticationTest';
import { authenticationTypes, httpTypes } from './PlatformConfigurationConstances';

import './PlatformConfigurationModal.scss';

const PlatformConfigurationModal = ({ modalBox, platformId, updatePlatformsList }) => {
  const [currentPlatformName, setCurrentPlatformName] = useState('');
  const [platformConfigurationEnabled, setPlatformConfigurationEnabled] = useState(true);
  const [selectedAuthenticationType, setSelectedAuthenticationType] = useState(authenticationTypes[0]);
  const [authenticationUrl, setAuthenticationUrl] = useState('');

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [bearerKey, setBearerKey] = useState('');
  const [initialAuthenticationsCredentials, setInitialAuthenticationsCredentials] = useState(null);

  const [urlList, setUrlList] = useState([]);

  useEffect(() => {
    if (platformId) {
      afterburnerApi
        .getPlatformConfiguration(platformId)
        .then(
          ({
            data: {
              platformName,
              authenticationType,
              authenticationUrl,
              username,
              platformUrlsConfiguration,
              platformConfigurationEnabled
            }
          }) => {
            setCurrentPlatformName(platformName);
            setPlatformConfigurationEnabled(platformConfigurationEnabled);
            setSelectedAuthenticationType({ name: authenticationType, value: authenticationType });
            setAuthenticationUrl(authenticationUrl);
            setUsername(username);
            setPassword(authenticationType === 'BASIC' ? '************' : null);
            setBearerKey(authenticationType === 'BEARER' ? '***********************' : null);
            setUrlList(platformUrlsConfiguration.map(url => ({ ...url, key: uuid() })));
            setInitialAuthenticationsCredentials({
              username,
              password: null,
              bearerKey: null
            });
          }
        )
        .catch(e => {
          NotificationManager.error(e.response.data.message);
        });
    }
  }, [platformId]);

  const addNewLineToUrlList = useCallback(() => {
    setUrlList([
      ...urlList,
      {
        url: null,
        httpType: httpTypes[0].name,
        requestHeader: null,
        key: uuid(),
        id: null,
        platformUrlConfigurationEnabled: true
      }
    ]);
  }, [urlList]);

  const deleteItemFromUrlList = useCallback(
    (indexForDelete, id) => {
      const updatedList = urlList.filter((url, index) => index !== indexForDelete);

      ModalBoxes.confirm({
        content: 'Are you sure you want to delete this endpoint',
        confirmButton: 'Yes',
        cancelButton: 'No'
      })
        .then(() => {
          if (id) {
            afterburnerApi.checkPossibilityDeleteUrl(id).then(({ data }) => {
              if (data) {
                setUrlList(updatedList);
              } else {
                NotificationManager.error(ENDPOINT_IN_USE);
              }
            });
          } else {
            setUrlList(updatedList);
          }
        })
        .catch(() => {});
    },
    [urlList]
  );

  const changeUrl = useCallback(
    (indexForUpdate, newValue) => {
      const updatedList = urlList.map((item, index) => {
        if (index === indexForUpdate) {
          return { ...item, url: newValue };
        }
        return item;
      });
      setUrlList(updatedList);
    },
    [urlList]
  );

  const changeRequestHeader = useCallback(
    (indexForUpdate, newValue) => {
      const updatedList = urlList.map((item, index) => {
        if (index === indexForUpdate) {
          return { ...item, requestHeader: newValue };
        }
        return item;
      });
      setUrlList(updatedList);
    },
    [urlList]
  );

  const changeHttpType = useCallback(
    (indexForUpdate, newValue) => {
      const updatedList = urlList.map((item, index) => {
        if (index === indexForUpdate) {
          return { ...item, httpType: newValue };
        }
        return item;
      });
      setUrlList(updatedList);
    },
    [urlList]
  );

  const changeUrlEnabled = useCallback(
    (indexForUpdate, newValue) => {
      const updatedList = urlList.map((item, index) => {
        if (index === indexForUpdate) {
          return { ...item, platformUrlConfigurationEnabled: newValue };
        }
        return item;
      });
      setUrlList(updatedList);
    },
    [urlList]
  );

  const resolveUrlForSave = useCallback(
    request => {
      if (!platformId) {
        return afterburnerApi.postNewPlatformConfiguration(request);
      } else {
        return afterburnerApi.updatePlatformConfiguration(request);
      }
    },
    [platformId]
  );

  const createRequestBody = useCallback(() => {
    let request = {
      platformName: currentPlatformName.trim(),
      authenticationType: selectedAuthenticationType.name,
      authenticationUrl,
      platformUrlsConfiguration: urlList.map(url => omit(url, 'key')),
      platformConfigurationEnabled,
      id: platformId
    };
    if (selectedAuthenticationType.name === 'BASIC') {
      request = {
        ...request,
        username: username?.trim(),
        password: platformId && password === '************' ? null : password?.trim(),
        bearerKey: null
      };
    } else {
      request = {
        ...request,
        username: null,
        password: null,
        bearerKey: platformId && bearerKey === '***********************' ? null : bearerKey?.trim()
      };
    }

    return request;
  }, [
    authenticationUrl,
    bearerKey,
    currentPlatformName,
    password,
    platformConfigurationEnabled,
    platformId,
    selectedAuthenticationType.name,
    urlList,
    username
  ]);

  const savePlatformConfiguration = useCallback(() => {
    let request = createRequestBody();

    if (!platformValidation(request, selectedAuthenticationType.name, initialAuthenticationsCredentials, platformId))
      return;

    resolveUrlForSave(request)
      .then(() => {
        NotificationManager.success(DATA_SAVED);
        if (isFunction(updatePlatformsList)) {
          updatePlatformsList();
        }
        modalBox.close();
      })
      .catch(error => onRequestError(error, { messageLocation: 'response.data' }));
  }, [
    createRequestBody,
    initialAuthenticationsCredentials,
    modalBox,
    platformId,
    resolveUrlForSave,
    selectedAuthenticationType.name,
    updatePlatformsList
  ]);

  const urlDuplicates = useMemo(
    function() {
      return urlList.reduce((accumulator, { platformUrlConfigurationEnabled, url, httpType, key }, index, array) => {
        if (url && array.filter(item => item.url === url && item.httpType === httpType).length > 1) {
          accumulator.push(key);
        }
        return accumulator;
      }, []);
    },
    [urlList]
  );

  return (
    <>
      <ModalBoxes.Header>Platform Configuration: {currentPlatformName} </ModalBoxes.Header>
      <ModalBoxes.Body>
        <div className="name-configuration">
          <span className="endpoint-label">Receiving Platforms</span>
          <Input
            className="endpoint-input"
            label="Platform name"
            type="text"
            validate={false}
            value={currentPlatformName}
            onChange={({ target }) => setCurrentPlatformName(target.value)}
            disabled={false}
            required
          />
          <Checkbox
            className="platform-enabled"
            label="Platform enabled"
            key="Platform enabled"
            onChange={() => setPlatformConfigurationEnabled(!platformConfigurationEnabled)}
            checked={platformConfigurationEnabled}
          />
        </div>
        <div className="platform-configuration">
          <div className="url-configuration">
            <Select
              value={selectedAuthenticationType}
              onChange={newValue => {
                if (!isEqual(selectedAuthenticationType, newValue)) {
                  if (!newValue) {
                    setSelectedAuthenticationType(null);
                  } else {
                    setSelectedAuthenticationType(newValue);
                  }
                }
              }}
              dataSource={authenticationTypes}
              optionLabelKey="name"
              optionValueKey="value"
              label="Authentication Type"
              className="endpoint-select"
              clearable={false}
              required
            />
            <Input
              className="endpoint-input"
              label="Authentication URL"
              type="text"
              validate={false}
              value={authenticationUrl}
              onChange={({ target }) => setAuthenticationUrl(target.value)}
              disabled={false}
              required
            />
          </div>
          <div className="authentication-configuration">
            <MappingAuthenticationTest
              platformId={platformId}
              authenticationType={selectedAuthenticationType?.name}
              username={username}
              setUsername={setUsername}
              bearerKey={bearerKey}
              setBearerKey={setBearerKey}
              password={password}
              setPassword={setPassword}
              createRequestBody={createRequestBody}
            />
          </div>
        </div>
        <div className="endpoint-configuration">
          <Button size="h40" priority="medium" onClick={addNewLineToUrlList} className="add-url-button">
            Add Platform Endpoint
          </Button>
          {urlList.map((urlItem, index) => {
            const selectedType = httpTypes.filter(type => type.name === urlItem.httpType);
            return (
              <div className="url-item-wrapper" key={urlItem.key}>
                <Input
                  className="endpoint-input"
                  label="Url"
                  type="text"
                  value={urlItem.url}
                  onChange={({ target }) => {
                    changeUrl(index, target.value);
                  }}
                  validationMessage={urlDuplicates.includes(urlItem.key) ? 'Duplicated url' : null}
                  disabled={false}
                  required
                />
                <Select
                  value={selectedType[0]}
                  onChange={newValue => changeHttpType(index, newValue?.name)}
                  dataSource={httpTypes}
                  optionLabelKey="name"
                  optionValueKey="value"
                  label="Http Type"
                  className="endpoint-select"
                  clearable={false}
                  required
                  validationMessage={urlDuplicates.includes(urlItem.key) ? ' ' : null}
                />
                <Input
                  className="endpoint-input"
                  label="Request Header"
                  type="text"
                  validate={false}
                  value={urlItem.requestHeader}
                  onChange={({ target }) => {
                    changeRequestHeader(index, target.value);
                  }}
                  disabled={false}
                />
                <Checkbox
                  className="url-enabled"
                  label="Url enabled"
                  onChange={() => changeUrlEnabled(index, !urlItem.platformUrlConfigurationEnabled)}
                  checked={urlItem.platformUrlConfigurationEnabled}
                />
                <div className="delete-url-icon" onClick={() => deleteItemFromUrlList(index, urlItem?.id)} />
              </div>
            );
          })}
        </div>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority={'medium'} onClick={() => modalBox.close()}>
            Cancel
          </Button>
          <Button disabled={!isEmpty(urlDuplicates)} onClick={() => savePlatformConfiguration()}>
            Save
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
};

const platformValidation = (request, authenticationType, initialAuthenticationsCredentials, platformId) => {
  if (
    !platformId ||
    !isEqual(initialAuthenticationsCredentials, {
      username: request.username,
      password: request.password,
      bearerKey: request.bearerKey
    })
  ) {
    if (authenticationType === 'BASIC') {
      if (!request.username || !request.password) {
        NotificationManager.error(USERNAME_AND_PASSWORD_REQUIRED);
        return false;
      }
    } else {
      if (!request.bearerKey) {
        NotificationManager.error(KEY_IS_REQUIRED);
        return false;
      }
    }
  }
  if (!validateEndpoint(request.authenticationUrl)) {
    NotificationManager.error(AUTHENTICATION_URL_IS_NOT_CORRECT);
    return false;
  }
  if (!request.platformName) {
    NotificationManager.error(PLATFORM_NAME_IS_REQUIRED);
    return false;
  }
  if (some(request.platformUrlsConfiguration, item => !item.url)) {
    NotificationManager.error(ENDPOINT_URL_IS_REQUIRED);
    return false;
  }
  if (some(request.platformUrlsConfiguration, item => !validateEndpoint(item.url))) {
    NotificationManager.error(ENDPOINT_URL_IS_NOT_CORRECT);
    return false;
  }
  if (some(request.platformUrlsConfiguration, item => !item.httpType)) {
    NotificationManager.error(HTTP_TYPE_IS_REQUIRED);
    return false;
  }
  if (
    some(
      request.platformUrlsConfiguration,
      item =>
        item.requestHeader &&
        !every(item.requestHeader.split(';'), (element, index, array) =>
          element === '' && index === array.length - 1 ? true : /^\s?[\w-]+\s?:\s?[\w-]+\s?$/g.test(element)
        )
    )
  ) {
    NotificationManager.error('Request Header is not correct');
    return false;
  }
  return true;
};

export default PlatformConfigurationModal;
