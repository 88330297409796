import { orderBy } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';
import { get, pick } from 'lodash/object';
import { toLower } from 'lodash/string';

export function getIds(collection) {
  return !isEmpty(collection) ? collection.map(e => e.id) : null;
}

export function toRequestFilters(filters) {
  const { taskStatuses, taskTopics, sortedBy, ssuIds, searchString } = filters;
  return {
    ...pick(filters, ['page', 'assigneeIds']),
    pageSize: filters?.pageSize || 25,
    taskStatuses: getIds(taskStatuses),
    taskTopics: getIds(taskTopics),
    ssuIds,
    searchString: toLower(searchString),
    sortedBy: { property: get(sortedBy, '[0].id'), direction: get(sortedBy, '[0].desc') ? 'DESC' : 'ASC' }
  };
}

export function prepareAssignees(list) {
  return [
    ...orderBy(
      list.map(item => ({
        name: `${item.lastName} ${item.firstName}`,
        id: item.personnelIdentifier,
        roles: item.roles
      })),
      ['name'],
      'asc'
    ),
    { id: 'Unassigned', name: 'Unassigned' }
  ];
}
