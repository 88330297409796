import { INVOICE, RECONCILE } from '../../../../constants/accessToPages';
import Reconcile from '../Reconcile/Reconcile';

import { EmailAttachment } from './EmailAttachment/EmailAttachment';
import NewInvoice from './NewInvoice';

export const newInvoiceRouterConfig = {
  name: 'Invoice',
  path: '/invoice',
  rootBreadcrumbName: 'Finance',
  component: NewInvoice,
  access: [INVOICE],
  inherited: [
    {
      name: 'Reconcile Payments',
      path: '/reconcile',
      component: Reconcile,
      access: [RECONCILE]
    },
    {
      name: 'Email Attachment',
      path: '/email-attachment/:attachmentId',
      component: EmailAttachment,
      access: [INVOICE]
    }
  ]
};
