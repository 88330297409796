import React, { useContext } from 'react';

import Input from '../../../../../../../../common/data-entry/Input';
import { AddLedgerContext } from '../../../AddLedgerContext';
import { StepHeader } from '../StepHeader/StepHeader';

import './MissingLedgerReason.scss';
export function MissingLedgerReason() {
  const { setReason, reason } = useContext(AddLedgerContext);

  return (
    <div className="missing-ledger-reason">
      <StepHeader>Enter Ledger Adjusting Reason</StepHeader>
      <Input className="mlr-reason" label="Reason" onChange={({ target }) => setReason(target.value)} value={reason} />
    </div>
  );
}
