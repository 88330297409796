import moment from 'moment';
import { combineReducers } from 'redux';

import {
  CREATE_STUDY_ENROLLMENT,
  GET_STUDY_ENROLLMENTS,
  LAST_LOCKED_PERIOD_RECEIVED,
  UPDATE_STUDY_ENROLLMENT
} from '../../actions/finance/forecasting/forecastingActions';

function studyEnrollmentsReducer(state = [], action) {
  // NOSONAR
  switch (action.type) {
    case GET_STUDY_ENROLLMENTS:
      return [...action.payload];
    case UPDATE_STUDY_ENROLLMENT:
      return state.map(studyEnrollment =>
        studyEnrollment.year === action.payload.year &&
        studyEnrollment.month === action.payload.month &&
        studyEnrollment.studySiteId === action.payload.studySiteId
          ? { ...studyEnrollment, ...action.payload }
          : studyEnrollment
      );
    case CREATE_STUDY_ENROLLMENT:
      return state.map(studyEnrollment =>
        studyEnrollment.year === action.payload.year &&
        studyEnrollment.month === action.payload.month &&
        studyEnrollment.studySiteId === action.payload.studySiteId
          ? { ...studyEnrollment, ...action.payload }
          : studyEnrollment
      );
    default:
      return state;
  }
}

function lastLockedPeriodReducer(state = null, action) {
  // NOSONAR
  switch (
    action.type // NOSONAR
  ) {
    case LAST_LOCKED_PERIOD_RECEIVED:
      const lastLockedPeriod = action.payload ? moment(action.payload) : moment().subtract(1, 'month');
      return lastLockedPeriod.startOf('month');
    default:
      return state;
  }
}

const forecastingReducer = combineReducers({
  lastLockedPeriod: lastLockedPeriodReducer,
  studyEnrollments: studyEnrollmentsReducer
});

export default forecastingReducer;
