import React, { useContext, useEffect, useState } from 'react';
import { isEmpty, isEqual } from 'lodash/lang';

import Input from '../../../../../../../../common/data-entry/Input';
import Checkbox from '../../../../../../../../common/data-entry/InputSelectors/Checkbox';
import Select from '../../../../../../../../common/data-entry/Select';
import StyledTooltip from '../../../../../../../../common/widgets/StyledToolTip';
import { selectedValueWishTooltip } from '../MappingSetupServices';

const MappingGeneralItem = ({
  groupIndex,
  fieldName,
  fieldType,
  mappingKeyValue,
  alwaysSent,
  index,
  listOfGeneralMappingNames,
  listOfSelectedGeneralMappingFields,
  invalidMappings,
  context
}) => {
  const {
    templateMode,
    changeGeneralMappingName,
    removeGeneralMappingFromInvalidList,
    changeGeneralFieldMappingKey,
    deleteGeneralMappingRow,
    changeGeneralFieldAlwaysSent,
    selectedMappingType
  } = useContext(context);
  const [selectedMapping, setSelectedMapping] = useState(null);

  useEffect(() => {
    const initialSelectedValue = listOfGeneralMappingNames.filter(mapping => mapping.name === fieldName);
    setSelectedMapping(initialSelectedValue[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mapping-field-item">
      <Select
        value={selectedMapping}
        onChange={newValue => {
          if (!isEqual(selectedMapping, newValue)) {
            setSelectedMapping(newValue);
            changeGeneralMappingName(newValue?.name, newValue?.uiName, groupIndex, index);
            removeGeneralMappingFromInvalidList(fieldType, index, ['fieldName', 'name'], true);
          }
        }}
        dataSource={listOfGeneralMappingNames.filter(
          mapping => !listOfSelectedGeneralMappingFields.includes(mapping.name)
        )}
        optionLabelKey="uiName"
        optionValueKey="value"
        label={fieldType}
        searchable
        valid={
          !isEmpty(invalidMappings) ? !invalidMappings.includes('fieldName') && !invalidMappings.includes('name') : true
        }
        required={true}
        customSelectedValueTemplateFunction={selectedValue => selectedValueWishTooltip(selectedValue, 'uiName')}
      />
      <StyledTooltip
        className="mapping-key-tooltip"
        overlay={<div className="note-value">{mappingKeyValue}</div>}
        placement="top"
        showArrow={false}
      >
        <Input
          className="mapping-input"
          label="Mapping Key Value"
          type="text"
          validate={false}
          value={mappingKeyValue}
          onChange={({ target: { value } }) => {
            changeGeneralFieldMappingKey(value, groupIndex, index);
            removeGeneralMappingFromInvalidList(fieldType, index, 'mappingKeyValue', true);
          }}
          valid={!isEmpty(invalidMappings) ? !invalidMappings.includes('mappingKeyValue') : true}
          required={true}
        />
      </StyledTooltip>
      {!templateMode && selectedMappingType.name !== 'IG/Log' && (
        <Checkbox
          className="always-sent-checkbox"
          label="Always Send"
          key="Always Send"
          onChange={() => changeGeneralFieldAlwaysSent(!alwaysSent, groupIndex, index)}
          checked={alwaysSent}
        />
      )}
      <div className="delete-option-item" onClick={() => deleteGeneralMappingRow(groupIndex, index)} />
    </div>
  );
};

export default MappingGeneralItem;
