import React, { useContext, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { VIEW_IG_AND_ENCOUNTER_AS_LINKS_AT_PI_WORK_LIST_PI_REVIEW } from '../../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../../services/auth';
import { generateUrlByKey, useCurrentRoute } from '../../../../../../root/router';
import { LOGS, NEW_TABLE } from '../../reviewConstants';
import { ReviewContext } from '../../ReviewContext';
import ConfirmHighlightedItems from '../ConfirmHighlightedItems/ConfirmHighlightedItems';
import { ItemGroupElementHeaderWithIcon } from '../ItemGroupElement/ItemGroupElementHeaderWithIcon/ItemGroupElementHeaderWithIcon';

import './ItemGroupElementHeader.scss';

export default function ItemGroupElementHeader({
  itemGroupId,
  itemGroupType,
  itemGroupName,
  showItemGroupConfirmButton,
  disableItemGroupConfirmButton,
  isItemGroupConfirmed,
  onConfirmItemGroup,
  showHistoryButton,
  getAnswersHistory,
  isNotPerformedItemGroup,
  statusChip,
  itemGroupTemplateId
}) {
  const currentRoute = useCurrentRoute();
  const procedurePageKey = `${currentRoute.parent.key}.Procedure`;
  const newTablePageKey = `${currentRoute.parent.key}.Table`;
  const encounterLogsPageKey = `${currentRoute.parent.key}.Logs`;
  const logPageKey = `${currentRoute.parent.parent.key}.Logs`;

  const { ssuPatientId, patientEncounterId, reviewType } = useContext(ReviewContext);
  const urlForItemGroupPage = useMemo(
    function() {
      if (reviewType === 'CRA') {
        return null;
      }

      let pageKey = procedurePageKey;
      if (LOGS === itemGroupType) {
        pageKey = itemGroupId ? encounterLogsPageKey : logPageKey;
      } else if (NEW_TABLE === itemGroupType) {
        pageKey = newTablePageKey;
      }
      return generateUrlByKey(pageKey, {
        ssuPatientId,
        patientEncounterId,
        patientItemGroupId: itemGroupId || itemGroupTemplateId,
        ...currentRoute.params
      });
    },
    [
      itemGroupId,
      procedurePageKey,
      itemGroupType,
      ssuPatientId,
      patientEncounterId,
      currentRoute.params,
      encounterLogsPageKey,
      newTablePageKey,
      reviewType,
      itemGroupTemplateId,
      logPageKey
    ]
  );

  const showAsLink = useMemo(
    function() {
      return (
        urlForItemGroupPage &&
        userHasAccessTo(VIEW_IG_AND_ENCOUNTER_AS_LINKS_AT_PI_WORK_LIST_PI_REVIEW) &&
        !isNotPerformedItemGroup
      );
    },
    [urlForItemGroupPage, isNotPerformedItemGroup]
  );

  const itemGroupElementHeaderContent = showAsLink ? (
    <NavLink to={urlForItemGroupPage}>{itemGroupName}</NavLink>
  ) : (
    itemGroupName
  );
  return (
    <div className="erc-top-item-group">
      <div className="d-flex">
        <ItemGroupElementHeaderWithIcon content={itemGroupElementHeaderContent} statusChip={statusChip} />
        {showItemGroupConfirmButton && (
          <ConfirmHighlightedItems
            onConfirmItemGroup={onConfirmItemGroup}
            disableItemGroupConfirmButton={disableItemGroupConfirmButton}
            isItemGroupConfirmed={isItemGroupConfirmed}
          />
        )}
      </div>
      <div className="d-flex">
        <div className="erc-top-item-group-history">
          {showHistoryButton && (
            <div className="brand-link" onClick={() => getAnswersHistory()}>
              <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  id="Vector"
                  d="M11.5515 0.00349014C6.46153 -0.13651 2.29153 3.95349 2.29153 9.00349H0.501534C0.0515344 9.00349 -0.168466 9.54349 0.151534 9.85349L2.94153 12.6535C3.14153 12.8535 3.45153 12.8535 3.65153 12.6535L6.44153 9.85349C6.75153 9.54349 6.53153 9.00349 6.08153 9.00349H4.29153C4.29153 5.10349 7.47153 1.95349 11.3915 2.00349C15.1115 2.05349 18.2415 5.18349 18.2915 8.90349C18.3415 12.8135 15.1915 16.0035 11.2915 16.0035C9.68153 16.0035 8.19153 15.4535 7.01153 14.5235C6.61153 14.2135 6.05153 14.2435 5.69153 14.6035C5.27153 15.0235 5.30153 15.7335 5.77153 16.0935C7.29153 17.2935 9.20153 18.0035 11.2915 18.0035C16.3415 18.0035 20.4315 13.8335 20.2915 8.74349C20.1615 4.05349 16.2415 0.13349 11.5515 0.00349014ZM11.0415 5.00349C10.6315 5.00349 10.2915 5.34349 10.2915 5.75349V9.43349C10.2915 9.78349 10.4815 10.1135 10.7815 10.2935L13.9015 12.1435C14.2615 12.3535 14.7215 12.2335 14.9315 11.8835C15.1415 11.5235 15.0215 11.0635 14.6715 10.8535L11.7915 9.14349V5.74349C11.7915 5.34349 11.4515 5.00349 11.0415 5.00349Z"
                  fill="#2465C6"
                />
              </svg>
              History
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
