import axios from 'axios';

const ROOT = 'api/v1/prepaid';

const PrepaidApi = {
  getCardBalanceByProxy(proxyNum, patientId, hideLoader = false) {
    return axios.get(`${ROOT}/balance/${patientId}/${proxyNum}`, {
      hideLoader,
      responseType: 'text',
      transformResponse: [
        function(data) {
          return data;
        }
      ]
    });
  }
};

export default PrepaidApi;
