import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import { isEmpty, isNull } from 'lodash/lang';
import { maxBy } from 'lodash/math';
import { get } from 'lodash/object';
import * as moment from 'moment';
import Tooltip from 'rc-tooltip';

import LogRowStatusHistoryApi from '../../../../../api/patient/LogRowStatusHistoryApi';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import modalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Icon from '../../../../../common/general/Icon';
import useSessionStorage from '../../../../../common/hooks/useSessionStorage';
import { UNEXPECTED } from '../../../../../constants/encounterConstants';
import { TD_MANAGE_ANY_ENCOUNTER_DATA } from '../../../../../constants/userOperations';
import userRoles, {
  ROLE_PRINCIPAL_INVESTIGATOR,
  ROLE_STUDY_MANAGER,
  ROLE_SYSTEM_ADMINISTRATOR
} from '../../../../../constants/userRoles';
import { userHasAccessAs, userHasAccessTo, userHasRole } from '../../../../../services/auth';
import { isAdverseEventLog, isNotNewAdverseEventLog } from '../../../../../services/itemGroupQuestionService';
import { resolveSmStatus } from '../../../../../services/logRowStatusService';
import { generateUrlByKey, useCurrentRoute } from '../../../../root/router';
import { ALLOW_READ, DENY_ALL } from '../../../setup/Protocol/ProtocolSetup/ProtocolGroupsSetup/permissionType';
import { prepareWhoDidItListForView } from '../EncounterDescNew/EditorAndDateSelect';
import { EncounterModificationMenu } from '../EncounterDescNew/EncounterDetailsHeader/EncounterModificationMenu';
import {
  getNotStartedEncounterNames,
  getStartedEncounterNames,
  isAbleToEditEncounter,
  isCloseEncounterMenuAvailable,
  isEncounterStartedAndIncomplete,
  notPerformedAndNotAskedShortcut,
  saveStudyManagerForEncounter
} from '../EncounterDescNew/encounterDetailsService';
import AdverseEventLogRowCloseReopen from '../EncounterDescNew/item-group/AdverseEventLogRowCloseReopen';
import ItemGroup from '../EncounterDescNew/ItemGroup';
import NotPerformShortcutModal from '../EncounterDescNew/NotPerformShortcutModal';
import { cancelSitePatientEncounter, reopenSitePatientEncounter } from '../services';

import EdcVisitDatePickerButton from './EdcVisitDatePickerButton/EdcVisitDatePickerButton';
import InformedConsentContextProvider, { InformedConsentContext } from './InformedConsent/InformedConsentContext';
import { OutreachAttemptTrackingModal } from './OutreachAttemptTrackingModal/OutreachAttemptTrackingModal';
import ReviewsSidebarBlock from './ReviewsSidebarBlock/ReviewsSidebarBlock';
import StudyManagerSelector from './StudyManagerSelector/StudyManagerSelector';
import NewEncounterPageContext, { EncounterPageContext } from './NewEncounterPageContext';
import NewEncounterPageInfo from './NewEncounterPageInfo';
import {
  getListOfStudyManagers,
  getRegularEncounterDetails,
  getRouteKey,
  loadEncounterDetailsBoxes,
  saveEdcVisitDate
} from './NewEncounterPageService';

import './NewEncounterPage.scss';

function NewEncounterPage() {
  const route = useCurrentRoute();
  const navigate = useNavigate();
  const { patientId, patientEncounterId, ssuPatientId, patientItemGroupId } = route.params;
  const [searchString, setSearchString] = useState(null);
  const [encounterStudyManagers, setEncounterStudyManagers] = useState([]);
  const [selectedStudyManager, setSelectedStudyManager] = useState(null);
  const [edcVisitDate, setEdcVisitDate] = useState(null);
  const currentRoute = useCurrentRoute();
  const [logRowStatus, setLogRowStatus] = useState(null);

  const [storedItemGroup, setStoredItemGroup] = useSessionStorage('NEW_ENCOUNTER_PAGE_IG', {});

  const EncounterContext = useContext(EncounterPageContext);
  const IcfContext = useContext(InformedConsentContext);

  const {
    currentEncounterIsClosable,
    metaData,
    selectedItemGroup,
    setSelectedItemGroup,
    encounterDetails,
    state,
    setState,
    loadUpdatedData
  } = EncounterContext;

  const { allIcfForms, completedIcfForms } = IcfContext;

  useEffect(() => {
    const { rowId, ssuPatientId, itemGroupTemplateVersionId } = currentRoute.params;
    if (
      (userHasRole(ROLE_STUDY_MANAGER) ||
        userHasRole(ROLE_PRINCIPAL_INVESTIGATOR) ||
        userHasRole(ROLE_SYSTEM_ADMINISTRATOR)) &&
      rowId &&
      rowId !== 'newRow' &&
      selectedItemGroup &&
      isAdverseEventLog(selectedItemGroup.domainCode, selectedItemGroup.elementType)
    ) {
      LogRowStatusHistoryApi.getLogRowLastStatus(
        metaData?.studyId,
        ssuPatientId,
        selectedItemGroup?.itemGroupTemplateId,
        itemGroupTemplateVersionId,
        rowId
      ).then(({ data }) => {
        setLogRowStatus(data);
      });
    }
  }, [currentRoute, selectedItemGroup, selectedItemGroup?.itemGroupTemplateId, metaData?.studyId]);

  function selectNextItemGroup(encounterDetailsList) {
    //TODO: improve this logic after clarifying all business cases
    if (
      encounterDetailsList &&
      selectedItemGroup &&
      selectedItemGroup.domainCode !== 'CO' &&
      selectedItemGroup.elementType !== 'Logs' &&
      selectedItemGroup.domainCode !== 'ICF' &&
      selectedItemGroup.elementType !== 'Table' &&
      selectedItemGroup.elementType !== 'ICF'
    ) {
      let nextNotSkipped = encounterDetailsList.filter(
        e =>
          e.skipped === 0 &&
          e.elementType !== 'Review' &&
          e.permissionType !== DENY_ALL &&
          e.completePercentage !== '100.0'
      );
      let nextNotSkippedProcedures = nextNotSkipped.filter(e => e.elementType !== 'Logs');
      let nextNotSkippedLogs = nextNotSkipped.filter(e => e.elementType === 'Logs');

      let next;

      const isProcedure = selectedItemGroup.elementType !== 'Logs';
      const isLogs = selectedItemGroup.elementType === 'Logs';

      if (isProcedure && !isEmpty(nextNotSkippedProcedures)) {
        const nextBySequence = nextNotSkippedProcedures.filter(
          e => e.itemGroupSequence > selectedItemGroup.itemGroupSequence
        );
        if (!isEmpty(nextBySequence)) {
          next = nextBySequence[0];
        } else {
          next = nextNotSkippedProcedures[0];
        }
        if (isEmpty(next) && !isEmpty(nextNotSkippedLogs)) {
          next = nextNotSkippedLogs[0];
        }
      }

      if (isLogs && !isEmpty(nextNotSkippedLogs)) {
        const nextBySequence = nextNotSkippedProcedures.filter(
          e => e.itemGroupSequence > selectedItemGroup.itemGroupSequence
        );
        if (!isEmpty(nextBySequence)) {
          next = nextBySequence[0];
        } else {
          next = nextNotSkippedLogs[0];
        }
        if (isEmpty(next) && !isEmpty(nextNotSkippedProcedures)) {
          next = nextNotSkippedProcedures[0];
        }
      }

      if (!isEmpty(next)) {
        setSelectedItemGroup(next);
        navigate(generateUrlForItem(currentRoute.key, next));
      } else {
        navigate(currentRoute.pathname);
      }
    } else if (selectedItemGroup.elementType === 'Logs') {
      navigate(generateUrlForItem(currentRoute.key, selectedItemGroup), { replace: true });
    }
  }

  useEffect(
    function() {
      loadUpdatedData().then(
        encounterDetailsList => EncounterContext.onUpdate.selectNext && selectNextItemGroup(encounterDetailsList)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [EncounterContext.onUpdate]
  );

  let procedures = [];
  let logs = [];
  let reviews = [];
  let informedConsent = [];
  let groupAssignReviews = [];

  useEffect(function() {
    async function load() {
      const encounter = await getRegularEncounterDetails(patientEncounterId, ssuPatientId);
      const encounterDetailsBoxes = await loadEncounterDetailsBoxes(patientEncounterId, ssuPatientId);
      const encounterStudyManagers = await getListOfStudyManagers(ssuPatientId);
      setEncounterStudyManagers(encounterStudyManagers);

      setState({ encounter, ...encounterDetailsBoxes });

      const sm = encounterStudyManagers.find(e => e.uniqueIdentifier === encounter.studyManagerUniqueIdentifier);

      setEdcVisitDate(encounter.edcVisitDate);
      sm && setSelectedStudyManager(sm);

      let itemGroupForSelect;
      if (
        !patientItemGroupId &&
        !isEmpty(storedItemGroup) &&
        storedItemGroup.patientEncounterId === patientEncounterId
      ) {
        itemGroupForSelect = encounterDetailsBoxes.encounterDetailsList.find(
          e => e.formUniqueIdentifier === storedItemGroup.patientItemGroupId && e.permissionType !== DENY_ALL
        );
      }
      if (!patientItemGroupId && !itemGroupForSelect) {
        const notSkippedIcf = encounterDetailsBoxes.encounterDetailsList.filter(
          e =>
            e.skipped === 0 &&
            e.elementType !== 'Review' &&
            e.elementType !== 'Logs' &&
            e.elementType === 'ICF' &&
            e.permissionType !== DENY_ALL
        );

        const notSkippedProcedure = encounterDetailsBoxes.encounterDetailsList.filter(
          e =>
            e.skipped === 0 && e.elementType !== 'Review' && e.elementType !== 'Logs' && e.permissionType !== DENY_ALL
        );

        const notSkippedLogs = encounterDetailsBoxes.encounterDetailsList.filter(
          e =>
            e.skipped === 0 && e.elementType !== 'Review' && e.elementType === 'Logs' && e.permissionType !== DENY_ALL
        );

        if (!isEmpty(notSkippedIcf)) {
          itemGroupForSelect = notSkippedIcf[0];
        } else if (!isEmpty(notSkippedProcedure)) {
          itemGroupForSelect = notSkippedProcedure[0];
        } else if (!isEmpty(notSkippedLogs)) {
          itemGroupForSelect = notSkippedLogs[0];
        }
      }
      if (!isEmpty(itemGroupForSelect)) {
        navigate(generateUrlForItem(currentRoute.key, itemGroupForSelect), { replace: true });
      }
    }
    load().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    function() {
      Promise.all([
        getRegularEncounterDetails(patientEncounterId, ssuPatientId),
        loadEncounterDetailsBoxes(patientEncounterId, ssuPatientId)
      ]).then(([encounter, encounterDetailsBoxes]) => {
        setState({ encounter, ...encounterDetailsBoxes });
      });
    },
    [patientEncounterId, ssuPatientId, patientItemGroupId, setState]
  );

  useEffect(
    function() {
      if (patientItemGroupId && state?.encounterDetailsList) {
        let selected = state.encounterDetailsList.find(
          e => e.formUniqueIdentifier === patientItemGroupId && e.permissionType !== DENY_ALL
        );
        if (isEmpty(selected)) {
          selected = state.encounterDetailsList.find(e => e.domainCode === 'CO');
          navigate(generateUrlForItem(currentRoute.key, selected));
        } else {
          setSelectedItemGroup(selected);
          setStoredItemGroup({
            patientEncounterId,
            patientItemGroupId: selected.formUniqueIdentifier
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state?.encounterDetailsList, patientItemGroupId, setStoredItemGroup, patientEncounterId]
  );

  useEffect(
    function() {
      if (currentRoute.name === 'Encounter Details') {
        let selected;
        if (selectedItemGroup) {
          selected = selectedItemGroup;
        } else if (state?.encounterDetailsList) {
          const notSkipped = state.encounterDetailsList.filter(
            e => e.skipped === 0 && e.elementType !== 'Review' && e.permissionType !== DENY_ALL
          );
          if (!isEmpty(notSkipped)) {
            selected = notSkipped[0];
          }
        }

        if (selected) {
          navigate(generateUrlForItem(currentRoute.key, selected));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentRoute]
  );

  if (state?.encounterDetailsList) {
    let filtered = state.encounterDetailsList;
    let filteredReviews = encounterDetails?.reviews;
    if (!isEmpty(searchString)) {
      filtered = state.encounterDetailsList.filter(e =>
        e.elementName.toLowerCase().includes(searchString.toLowerCase())
      );
      filteredReviews = filteredReviews?.filter(review =>
        review.reviewName.toLowerCase().includes(searchString.toLowerCase())
      );
    }
    procedures = filtered.filter(
      e => !['Logs', 'Review', 'ICF'].includes(e.elementType) || (e.elementType === 'Logs' && e.domainCode === 'CO')
    );
    logs = filtered.filter(e => e.elementType === 'Logs' && e.domainCode !== 'CO');
    reviews = filtered.filter(e => e.elementType === 'Review');
    informedConsent = filtered.filter(e => e.elementType === 'ICF');
    groupAssignReviews = filteredReviews;
  }

  function selectItemGroup(itemGroup) {
    setSelectedItemGroup(null);
    setTimeout(() => {
      setSelectedItemGroup(itemGroup);
    });
  }

  function generateUrlForItem(key, item) {
    return generateUrlByKey(getRouteKey(key, item), {
      patientId,
      ssuPatientId,
      patientEncounterId,
      reviewPatientItemGroupId: item.formUniqueIdentifier,
      patientItemGroupId: item.formUniqueIdentifier,
      typeId: item?.informedConsentTemplate?.typeId,
      languageId: item?.informedConsentTemplate?.languageId
    });
  }

  function Item(item) {
    let isNewIcfForm = false;
    let activeInformedConsentForEncounter = null;
    if (item && item.domainCode === 'ICF') {
      const typeId = item?.informedConsentTemplate?.typeId;
      const languageId = item?.informedConsentTemplate?.languageId;
      const forms = allIcfForms.filter(e => e.typeId === typeId && e.languageId === languageId);

      if (forms) {
        const form = maxBy(forms, a => moment(a.uploadDate).format('x'));
        if (form) {
          activeInformedConsentForEncounter = form;
        }

        const activeInformedConsentForm = forms?.find(form => form?.active);
        const activeFormHasAnswer = completedIcfForms?.some(
          e => e?.informedConsentFormId === activeInformedConsentForm?.id
        );
        isNewIcfForm =
          item.completePercentage === '0.0' && item.skipped === 0 && activeInformedConsentForm && !activeFormHasAnswer;
      }
    }

    return (
      <ItemGroup
        unexpectedEncounter={false}
        selectedItemGroups={state?.encounterDetailsList}
        key={item.uniqueIdentifier}
        encounter={item}
        patientId={metaData?.patientEncounterGroupId}
        loadEncounterDetailData={performed =>
          loadUpdatedData().then(encounterDetailsList => {
            if (selectedItemGroup === item) {
              if (!performed) {
                selectNextItemGroup(encounterDetailsList);
              } else {
                navigate(generateUrlForItem(currentRoute.key, item));
              }
            } else {
              if (performed) {
                navigate(generateUrlForItem(currentRoute.key, item));
              }
            }
          })
        }
        unExpectedEncounterData={{
          encounterDetailsList: state.encounterDetailsList,
          isUnexpected: metaData?.encounterType === UNEXPECTED
        }}
        disabled={state.encounter.encounterStatus === 'Canceled'}
        studyId={metaData?.studyId}
        isSelected={selectedItemGroup === item}
        isNewIcfForm={isNewIcfForm}
        informedConsentForm={activeInformedConsentForEncounter}
        onIcfUpdate={EncounterContext.onUpdate}
      />
    );
  }

  const isAbleToManageEncounterData = () => {
    return isAbleToEditEncounter(state.encounter?.nonProtocol, state.encounter?.ssuStatus);
  };

  const markItemGroupsNotPerformedAndNotAsked = () => {
    const notPerformedModal = ModalBoxes.open({
      component: (
        <NotPerformShortcutModal
          onConfirm={reason => {
            notPerformedAndNotAskedShortcut(
              state?.encounter?.nonProtocol,
              state.encounterDetailsList,
              metaData.studyId,
              ssuPatientId,
              patientEncounterId,
              reason,
              state.encounter.studyManagerUniqueIdentifier
            ).then(() => {
              notPerformedModal.close();
              loadUpdatedData().then();
            });
          }}
          notStarted={getNotStartedEncounterNames(state.encounterDetailsList).sort()}
          partial={getStartedEncounterNames(state.encounterDetailsList).sort()}
        />
      )
    });
  };

  function isEncounterSaved() {
    return state?.encounter;
  }

  const cancelEncounter = () => {
    cancelSitePatientEncounter(
      patientEncounterId,
      ssuPatientId,
      state.encounter.encounterIdentifier,
      () => {
        loadUpdatedData().then(() => {
          navigate(generateUrlForItem(currentRoute.key, selectedItemGroup), { replace: true });
        });
      },
      state.encounter.encounterName
    );
  };

  const reopenEncounter = () => {
    reopenSitePatientEncounter(ssuPatientId, patientEncounterId, loadUpdatedData);
  };
  const onPatientPrimaryContactUpdate = updatedContactNumber => {
    const { currentPatient } = state;
    setState({ ...state, currentPatient: { ...currentPatient, contactNumber: updatedContactNumber } });
  };

  const onAttemptTracking = () => {
    modalBoxes.open({
      component: (
        <OutreachAttemptTrackingModal
          ssuPatientId={ssuPatientId}
          viewOnly={!userHasAccessTo(TD_MANAGE_ANY_ENCOUNTER_DATA) || selectedItemGroup?.permissionType === ALLOW_READ}
        />
      ),
      title: 'Contact Attempt Tracking',
      size: 'w1100',
      className: 'outreach-attempt-tracking-modal'
    });
  };

  function resolveStatus() {
    if (
      userHasRole(ROLE_STUDY_MANAGER) ||
      userHasRole(ROLE_SYSTEM_ADMINISTRATOR) ||
      userHasRole(ROLE_PRINCIPAL_INVESTIGATOR)
    ) {
      const currenStatus = logRowStatus?.smStatus;
      return (
        currenStatus && (
          <p className="log-row-status-chip">{resolveSmStatus(currenStatus, isNull(logRowStatus?.piStatus))}</p>
        )
      );
    }
  }

  return (
    <div className={'new-encounter-page'}>
      {state.currentPatient && (
        <NewEncounterPageInfo
          currentPatient={state.currentPatient}
          showPrimaryContact={
            ['EDO', 'ECR'].includes(get(selectedItemGroup, 'domainCode')) &&
            (userHasAccessAs(userRoles.ROLE_STUDY_MANAGER) || userHasAccessAs(userRoles.ROLE_PATIENT_REPRESENTATIVE))
          }
          onPatientPrimaryContactUpdate={onPatientPrimaryContactUpdate}
          showAttemptTracking={get(selectedItemGroup, 'domainCode') === 'EDO'}
          onAttemptTracking={onAttemptTracking}
        />
      )}
      {state.encounter && (
        <div className={'content'}>
          <div className={'left'}>
            <div className={'left-header'}>
              <div className={'px-3 mt-4'}>
                <h4>
                  <span className="mr-2">{state.encounter.encounterName}</span>
                  <Tooltip
                    destroyTooltipOnHide={false}
                    trigger={state.encounter.encounterStatus === 'Canceled' ? ['hover'] : []}
                    placement="right"
                    overlayClassName="eds-rc-tooltip"
                    mouseEnterDelay={0}
                    mouseLeaveDelay={0}
                    overlay={<span>{state.encounter.statusChangeReason}</span>}
                  >
                    <div
                      className={cx('status-label', state.encounter.encounterStatus === 'Canceled' ? 'canceled' : '')}
                    >
                      {state.encounter.encounterStatus}
                    </div>
                  </Tooltip>
                </h4>
                {isAbleToEditEncounter(state?.encounter?.nonProtocol, state.currentPatient?.studySiteStatus) &&
                  isEncounterSaved() && (
                    <EncounterModificationMenu
                      cancelEncounterAvailable={state.encounter.encounterStatus !== 'Canceled'}
                      cancelEncounter={cancelEncounter}
                      reopenEncounter={reopenEncounter}
                      isUnexpectedEncounter={metaData?.encounterType === UNEXPECTED}
                      notPerformAllItemsAvailable={isEncounterStartedAndIncomplete(state?.encounterDetailsList)}
                      showCloseEncounterOption={
                        currentEncounterIsClosable &&
                        isCloseEncounterMenuAvailable(
                          state?.encounter?.nonProtocol,
                          state.currentPatient.sitePatientStatus
                        )
                      }
                      markItemGroupsNotPerformedAndNotAsked={markItemGroupsNotPerformedAndNotAsked}
                    />
                  )}
              </div>
              <div className={'m-3 search'}>
                <input onChange={e => setSearchString(e.target.value)} />
                <Icon>search</Icon>
              </div>
            </div>
            <div className={'left-content'}>
              <div>
                {!isEmpty(informedConsent) && (
                  <>
                    <h5 className={'pl-3 mt-4'}>Informed Consents</h5>
                    {informedConsent.map(e => Item(e, selectItemGroup, selectedItemGroup))}
                  </>
                )}
                {!isEmpty(procedures) && (
                  <>
                    <h5 className={'pl-3 mt-4'}>Procedures and Assessments</h5>
                    {procedures.map(e => Item(e, selectItemGroup, selectedItemGroup))}
                  </>
                )}
                {!isEmpty(logs) && (
                  <>
                    <h5 className={'pl-3 mt-4'}>Logs</h5>
                    {logs.map(e => Item(e, selectItemGroup, selectedItemGroup))}
                  </>
                )}
                {!isEmpty(reviews) && (
                  <>
                    <h5 className={'pl-3 mt-4'}>Reviews</h5>
                    {reviews.map(e => Item(e, selectItemGroup, selectedItemGroup))}
                  </>
                )}
                <ReviewsSidebarBlock
                  reviews={groupAssignReviews}
                  userGroupAssignId={encounterDetails.userGroupAssignId}
                />
              </div>
              <div className={'left-footer'}>
                <div className={'my-3'}>
                  <span>Study Manager</span>
                  <StudyManagerSelector
                    value={selectedStudyManager}
                    items={
                      selectedStudyManager
                        ? prepareWhoDidItListForView(encounterStudyManagers, selectedStudyManager.uniqueIdentifier)
                        : encounterStudyManagers.filter(manager => manager.deleteFlag === 0)
                    }
                    onChange={e =>
                      saveStudyManagerForEncounter(ssuPatientId, patientEncounterId, e.uniqueIdentifier).then(() => {
                        setSelectedStudyManager(e);
                      })
                    }
                  />
                </div>
                <div>
                  <span>Date of Encounter </span>
                  <EdcVisitDatePickerButton
                    isAllowedToManage={isAbleToManageEncounterData}
                    onChange={date => {
                      saveEdcVisitDate(ssuPatientId, patientEncounterId, date).then(res => {
                        setEdcVisitDate(res);
                      });
                    }}
                    value={moment(edcVisitDate, 'YYYY-MM-DD')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('right pl-2', {
              disabled: state.encounter.encounterStatus === 'Canceled',
              'read-only': selectedItemGroup?.permissionType === ALLOW_READ
            })}
          >
            {selectedItemGroup && (
              <div className="log-item-group-name-status">
                <div>
                  <h4 className={'mt-4 ml-3 item-group-name'}>
                    {selectedItemGroup.elementName}
                    {selectedItemGroup.skipped
                      ? ` - Not ${selectedItemGroup.elementType === 'ICF' ? 'Applicable' : 'Performed'}`
                      : ''}
                  </h4>
                  {isNotNewAdverseEventLog(
                    currentRoute.params.rowId,
                    selectedItemGroup.domainCode,
                    selectedItemGroup.elementType
                  ) && resolveStatus()}
                  {isNotNewAdverseEventLog(
                    currentRoute.params.rowId,
                    selectedItemGroup.domainCode,
                    selectedItemGroup.elementType
                  ) && (
                    <AdverseEventLogRowCloseReopen
                      logRowStatus={logRowStatus}
                      studyId={metaData?.studyId}
                      itemGroupTemplateId={selectedItemGroup.itemGroupTemplateId}
                      elementName={selectedItemGroup.elementName}
                    />
                  )}
                </div>
              </div>
            )}
            {state.encounter.encounterStatus === 'Canceled' && <div className={'disabled-cover'} />}
            {selectedItemGroup && selectedItemGroup.permissionType !== DENY_ALL && <Outlet />}
          </div>
        </div>
      )}
    </div>
  );
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(props) {
  return (
    <NewEncounterPageContext>
      <InformedConsentContextProvider>
        <NewEncounterPage {...props} />
      </InformedConsentContextProvider>
    </NewEncounterPageContext>
  );
}
