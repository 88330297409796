import React, { useContext, useEffect } from 'react';

import { FileViewerContext } from '../../../../../contexts/FileViewerContext';
import { PageInfoHeader } from '../../../../PageInfoHeader/PageInfoHeader';
import { useCurrentRoute } from '../../../../root/router';
import { openFile } from '../CreateInvoice/CreateInvoiceServices';

export const EmailAttachment = () => {
  const currentRoute = useCurrentRoute();
  const fileViewer = useContext(FileViewerContext);

  useEffect(() => {
    openFile('SEND', currentRoute.params.attachmentId, fileViewer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <PageInfoHeader />
    </div>
  );
};
