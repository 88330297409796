import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash/lang';

import {
  CloseRegularEncounterApi,
  ReactRouteOldMetaDataApi,
  SitePatientEncounterApi,
  TaskApi
} from '../../../../../api';
import openKeepIsHidden from '../../../../root/Container/Layout/Tasks/openKeepIsHidden';
import { useCurrentRoute } from '../../../../root/router';

import { getRegularEncounterDetails, loadEncounterDetailsBoxes } from './NewEncounterPageService';

export const EncounterPageContext = React.createContext(null);

export default function NewEncounterPageContext({ children }) {
  const { patientEncounterId, ssuPatientId } = useParams();
  const { params } = useCurrentRoute();
  const [oldMetadata, setOldMetadata] = useState({});
  const [onUpdate, setOnUpdate] = useState({ selectNext: false });
  const [currentEncounterIsClosable, setCurrentEncounterIsClosable] = useState(false);
  const [selectedItemGroup, setSelectedItemGroup] = useState(null);
  const [encounterDetails, setEncounterDetails] = useState({});
  const [state, setState] = useState({});

  const studyId = oldMetadata.metaData?.studyId;
  const ssuId = oldMetadata.metaData?.ssuId;

  const getAllRelatedTasksForEncounter = useCallback(
    function() {
      TaskApi.getTasksByPatientIdAndEncounter(ssuPatientId, params.patientEncounterId).then(({ data }) => {
        if (!isEmpty(data)) {
          openKeepIsHidden(data[0].taskId, data[0].ssuId);
        }
      });
    },
    [params.patientEncounterId, ssuPatientId]
  );

  const getAllRelatedTasksForLog = useCallback(
    function() {
      TaskApi.getTasksByPatientIdForLogs(ssuPatientId, params.patientItemGroupId).then(({ data }) => {
        if (!isEmpty(data)) {
          openKeepIsHidden(data[0].taskId, data[0].ssuId);
        }
      });
    },
    [params.patientItemGroupId, ssuPatientId]
  );

  useEffect(() => {
    if (selectedItemGroup?.elementType !== 'Logs') {
      getAllRelatedTasksForEncounter();
    } else {
      getAllRelatedTasksForLog();
    }
  }, [getAllRelatedTasksForEncounter, getAllRelatedTasksForLog, selectedItemGroup]);

  useEffect(
    function() {
      if (!patientEncounterId) return;
      ReactRouteOldMetaDataApi.getRouteOldMetadataForEncounterDetailsPage(patientEncounterId).then(
        ({ data: metaData }) => {
          setOldMetadata(state => ({ ...state, metaData }));
        }
      );
    },
    [patientEncounterId]
  );

  useEffect(
    function() {
      SitePatientEncounterApi.getSitePatientEncounterDetails(ssuPatientId, patientEncounterId).then(function({ data }) {
        setEncounterDetails(data);
      });
    },
    [ssuPatientId, patientEncounterId, onUpdate, oldMetadata.metaData, selectedItemGroup]
  );

  useEffect(
    function() {
      if (!studyId || !ssuPatientId || !patientEncounterId) return;
      CloseRegularEncounterApi.getEncounterClosableStatuses(studyId, ssuPatientId, patientEncounterId).then(function({
        data
      }) {
        const currentEncounterIsClosable = data.encounters.find(
          ({ sitePatientEncounterId }) => sitePatientEncounterId === patientEncounterId
        )?.closable;
        setCurrentEncounterIsClosable(currentEncounterIsClosable ?? true);
      });
    },
    [patientEncounterId, studyId, ssuPatientId]
  );

  async function loadUpdatedData() {
    const encounterDetailsBoxes = await loadEncounterDetailsBoxes(patientEncounterId, ssuPatientId);
    const encounter = await getRegularEncounterDetails(patientEncounterId, ssuPatientId);
    setState(state => ({ ...state, ...encounterDetailsBoxes, encounter }));
    return Promise.resolve(encounterDetailsBoxes.encounterDetailsList);
  }

  return (
    <EncounterPageContext.Provider
      value={{
        currentEncounterIsClosable,
        onUpdate,
        onSubmit: (selectNext = true) => {
          setOnUpdate({ selectNext: selectNext || false });
        },
        setSelectedItemGroup,
        selectedItemGroup,
        encounterDetails,
        ssuId,
        ...oldMetadata,
        state,
        setState,
        loadUpdatedData
      }}
      children={children}
    />
  );
}

export function withNewEncounterPageContext(Component) {
  return function WrapperComponent(props) {
    return (
      <EncounterPageContext.Consumer>{state => <Component {...props} {...state} />}</EncounterPageContext.Consumer>
    );
  };
}
