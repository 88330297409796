import axios from 'axios';

import ParticipantApi from './ParticipantApi';

const ROOT = 'api/v1/calendar/instances';

const CalendarInstanceApi = {
  getUserParticipants() {
    return axios
      .get(`${ROOT}/users`, { hideLoader: true })
      .then(({ data: participants }) => ({ data: ParticipantApi.processEmptyNames(participants) }));
  },
  getAvailableStudies() {
    return axios.get(`${ROOT}/studies`, { hideLoader: true });
  },

  getAvailableSites() {
    return axios.get(`${ROOT}/sites`, { hideLoader: true });
  },

  getAvailableSiteTimeZones() {
    return axios.get(`${ROOT}/time-zones`, { hideLoader: true });
  }
};

export default CalendarInstanceApi;
