import { isArray, isEmpty, isString } from 'lodash/lang';
import { has } from 'lodash/object';

import logger from '../../../../../../services/logger';

export function prepareItemGroupTableData(responseData) {
  if (!isString(responseData)) {
    return null;
  }
  try {
    const parsedResponse = JSON.parse(responseData);
    const fileList = parsedResponse.itemDefination.flatMap(({ fileList }) => fileList);
    if (
      !has(parsedResponse, 'columnList') ||
      !isArray(parsedResponse.columnList) ||
      isEmpty(parsedResponse.columnList)
    ) {
      return null;
    }

    const { columnList, tableData } = parsedResponse;

    return {
      columnList,
      tableData,
      fileList
    };
  } catch (e) {
    logger.error(e);
  }
}

export function isQuestionConditional(questionId, validationRules) {
  try {
    const sourceValidationQuestionIds = validationRules && Object.keys(validationRules);
    if (validationRules && sourceValidationQuestionIds.length > 0) {
      return sourceValidationQuestionIds.indexOf(questionId) > -1;
    }
    return false;
  } catch (e) {
    console.log(e);
    return false;
  }
}

/**
 * Mark question with red star "*"
 */
export function isQuestionConditionalAndRequired(questionId, validationRules) {
  try {
    const question = Object.entries(validationRules).filter(e => e.includes(questionId))[0];
    const questionFieldProperties = question && question[1];
    return isQuestionConditional(questionId, validationRules) && questionFieldProperties?.required;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export function getClassesForItemGroupDataFormInputsByFlag(isItemGroupWithTableView) {
  //DO WE REALLY NEED THIS?
  if (isItemGroupWithTableView) {
    return {
      textbox: {
        containerClass: 'p-0',
        elementClass: 'form-control select-width'
      },
      dropdown: {
        containerClass: 'p-0',
        elementClass: 'form-control select-width'
      },
      date: {
        containerClass: 'custonDate',
        elementClass: 'p-0'
      },
      textarea: {
        containerClass: 'col pr-0 pl-0 display-inline',
        elementClass: 'form-control'
      },
      Time: {
        containerClass: '',
        elementClass: 'select-width p-0'
      },
      checkbox: {
        containerClass: 'p-0',
        elementClass: ''
      },
      label: {
        containerClass: 'p-0',
        elementClass: ''
      },
      file: {
        containerClass: 'input-group ml-0 position-relative',
        elementClass: 'select-width custom-file-input'
      },
      reminder: {
        containerClass: 'col-md-12 ml-0 position-relative pl-0',
        elementClass: ''
      }
    };
  } else {
    return {
      textbox: {
        containerClass: '',
        elementClass: 'form-control select-width'
      },
      dropdown: {
        containerClass: '',
        elementClass: 'form-control select-width'
      },
      date: {
        containerClass: '',
        elementClass: ''
      },
      textarea: {
        containerClass: '',
        elementClass: 'form-control'
      },
      time: {
        containerClass: '',
        elementClass: 'col-xs-12 p-0'
      },
      checkbox: {
        containerClass: '',
        elementClass: ''
      },
      label: {
        containerClass: '',
        elementClass: ''
      },
      file: {
        containerClass: 'file btn btn-default',
        elementClass: 'select-width custom-file-input'
      },
      reminder: {
        containerClass: 'col-md-12 ml-0 position-relative pl-0',
        elementClass: ''
      }
    };
  }
}
