import React from 'react';

import common from '../../../../../../../common/common';
import Checkbox from '../../../../../../../common/data-entry/InputSelectors/Checkbox';
import { scBlue100 } from '../../../../../../../constants/systemColors';
import { isReadOnlyField } from '../../itemGroupSetupService';

export const InvestigatorFieldsCheckbox = ({ item, onInvestigatorFieldChange }) => {
  const investigatorFieldCheckUuid = `table${common.getRandomNumber()}`;
  const { investigatorField, inputType } = item;
  return (
    !isReadOnlyField(inputType) && (
      <Checkbox
        color={scBlue100}
        id={investigatorFieldCheckUuid}
        checked={investigatorField}
        onChange={({ target }) => onInvestigatorFieldChange(target?.checked)}
      />
    )
  );
};
