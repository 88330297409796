import React from 'react';

import { ReactComponent as PaidIcon } from '../../../../../../images/icons/green-alert-icon.svg';
import { ReactComponent as PartialIcon } from '../../../../../../images/icons/orange-alert-icon.svg';
import { ReactComponent as PastDueIcon } from '../../../../../../images/icons/red-alert-icon.svg';
import { COMPLETE, OPEN, PAID, PARTIAL, PAST_DUE } from '../TableConstants';

const PaymentStatus = ({ status, hasInvoiceNumber }) => {
  const resolveIcon = () => {
    switch (status) {
      case OPEN:
        return hasInvoiceNumber ? <PartialIcon /> : '';
      case PAST_DUE:
        return <PastDueIcon />;
      case COMPLETE:
      case PAID:
        return <PaidIcon />;
      case PARTIAL:
        return <PartialIcon />;
      default:
        return '';
    }
  };
  return resolveIcon();
};

export default PaymentStatus;
