import Common from '../../../../../../../common/common';
import DatePicker from '../../../../../../../common/data-entry/DatePicker';
import Select from '../../../../../../../common/data-entry/Select';

import './WhenWasItDoneAndWhoDidIt.scss';
export default function WhenWasItDoneAndWhoDidIt({ whenWasItDone, whoDidItName }) {
  return (
    <div className="erc-when-was-it-done-and-who-did-it">
      <DatePicker
        label={'When was it done?'}
        required={false}
        value={Common.formatDate(whenWasItDone)}
        validate={false}
        disabled={true}
        onChange={() => {}}
      />
      <Select
        label={'Who did it?'}
        dataSource={[{ id: 1, name: whoDidItName }]}
        searchable
        keepOpenOnSelection={false}
        closeOnSelectedOptionClick
        deselectOnSelectedOptionClick={false}
        clearable={false}
        disabled={true}
        validate={false}
        value={{ id: 1, name: whoDidItName }}
        required={false}
      />
    </div>
  );
}
