import React, { useContext } from 'react';

import Divider from '../../../../common/elements/Divider/Divider';
import useAppInfo from '../../../../common/hooks/useAppInfo';
import { ACCESS_TO_ADVERSE_EVENT_SM_OPERATIONS } from '../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../services/auth';
import { PageInfoHeader } from '../../../PageInfoHeader/PageInfoHeader';
import { ADVERSE_EVENTS_WIDGET, ENCOUNTER_WIDGET } from '../shared/Worklist/constants';
import WorklistChips from '../shared/Worklist/WorklistChips';
import { WorklistFilters } from '../shared/Worklist/WorklistFilters';
import { WorklistStatusMessageAndSearchField } from '../shared/Worklist/WorklistStatusMessageAndSearchField';
import { WorklistWidget } from '../shared/Worklist/WorklistWidget';

import { colors, SmStatusesProvider } from './constants';
import SmWorkListFiltersContext, { SmWorklistContext } from './SmWorklistFilterContext';
import { SmWorklistTableNew } from './SmWorklistTable';

import '../shared/Worklist/WorklistStyles.scss';

const SmWorklistNew = () => {
  const appInfo = useAppInfo();
  const Context = useContext(SmWorklistContext);
  return (
    <div className={'worklist-new'}>
      <div className="worklist-header">
        <PageInfoHeader>
          <WorklistFilters Context={Context} />
          <WorklistChips Context={Context} />
        </PageInfoHeader>
        <div className={'worklist-widget-container'}>
          <WorklistWidget
            Context={Context.EncounterWidgetContext}
            colors={colors}
            statusProvider={SmStatusesProvider}
            widgetType={ENCOUNTER_WIDGET}
          />
          {userHasAccessTo(ACCESS_TO_ADVERSE_EVENT_SM_OPERATIONS) && (
            <WorklistWidget
              Context={Context.AdverseEventsWidgetContext}
              colors={colors}
              statusProvider={SmStatusesProvider}
              widgetType={ADVERSE_EVENTS_WIDGET}
            />
          )}
        </div>
      </div>
      <Divider className={'worklist-divider'} />
      <WorklistStatusMessageAndSearchField Context={Context} statusesProvider={SmStatusesProvider} />
      <SmWorklistTableNew />
    </div>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function() {
  return (
    <SmWorkListFiltersContext>
      <SmWorklistNew />
    </SmWorkListFiltersContext>
  );
}
