import React from 'react';
import * as PropTypes from 'prop-types';

import DatePicker from '../../../../../common/data-entry/DatePicker';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../constants/dateFormat';

export function StartEndDatePicker({ onChangeStartDate, startDate, calendarLabels, onChangeEndDate, endDate }) {
  return (
    <>
      <DatePicker
        onChange={event => onChangeStartDate(event)}
        value={startDate}
        label={calendarLabels.startDate}
        timeFormat={false}
        closeOnSelect
        dateFormat={DD_SLASH_MMM_SLASH_YYYY}
        readOnly
      />

      <DatePicker
        onChange={event => onChangeEndDate(event)}
        value={endDate}
        label={calendarLabels.endDate}
        timeFormat={false}
        closeOnSelect
        dateFormat={DD_SLASH_MMM_SLASH_YYYY}
        readOnly
      />
    </>
  );
}

StartEndDatePicker.defaultProps = {
  calendarLabels: {
    startDate: 'Revenue Start',
    endDate: 'Revenue End'
  }
};
StartEndDatePicker.propTypes = {
  onChangeStartDate: PropTypes.func,
  onChangeEndDate: PropTypes.func,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  calendarLabels: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string
  })
};
