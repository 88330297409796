import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash/lang';

import Toggle from '../../../../../common/data-entry/Toggle';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';

import '../WidgetConfigModal.scss';

const TopTenTasksWidgetTableConfigModal = ({
  modalBox,
  columns,
  columnFlags,
  changeSettings,
  listViewMode,
  showIconsMode
}) => {
  const [showColumnFlags, setShowColumnFlags] = useState(columnFlags);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [listView, setListView] = useState(listViewMode || false);
  const [showIcons, setShowIcons] = useState(showIconsMode || false);

  useEffect(() => {
    setSaveButtonDisabled(isEmpty(showColumnFlags));
  }, [showColumnFlags]);

  return (
    <>
      <ModalBoxes.Header>Columns Config</ModalBoxes.Header>
      <ModalBoxes.Body>
        <h5>Columns</h5>
        <div>
          {columns &&
            columns.map((column, index) => (
              <div className="table-widget-row" key={index}>
                <span className="table-widget-name">{`${index + 1}. ${column.Header}`}</span>
                <Toggle
                  name={column.Header}
                  checked={column.show}
                  disabled={(column.Header === 'Due Date' && listView) || false}
                  onChange={e => {
                    setShowColumnFlags(prevState => {
                      let newState;
                      if (e.target.checked) {
                        newState = [...prevState, column.accessor];
                      } else {
                        newState = prevState.filter(e => e !== column.accessor);
                      }
                      return newState;
                    });
                  }}
                />
              </div>
            ))}
        </div>
        <h5 className="mt-3">View</h5>
        <div>
          <div className="table-widget-row " key={10}>
            <span className="table-widget-name">Use List View</span>
            <Toggle
              name={'Use List View'}
              checked={listView}
              disabled={false}
              onChange={e => {
                setListView(e.target.checked);
              }}
            />
          </div>
          <div className="table-widget-row" key={11}>
            <span className="table-widget-name">Show Icons</span>
            <Toggle
              name={'Show Icons'}
              checked={showIcons}
              disabled={!listView}
              onChange={e => {
                setShowIcons(e.target.checked);
              }}
            />
          </div>
        </div>

        <span className={'text-danger table-config-error-message'}>
          {saveButtonDisabled ? 'Please select at least one column' : ' '}
        </span>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <div className="eds-btn-group">
          <ButtonGroup>
            <Button
              priority="medium"
              onClick={() => {
                modalBox.close();
              }}
            >
              Cancel
            </Button>
            <Button
              priority="high"
              disabled={saveButtonDisabled}
              onClick={() => {
                changeSettings(showColumnFlags, listView, showIcons);
                modalBox.close();
              }}
            >
              Save
            </Button>
          </ButtonGroup>
        </div>
      </ModalBoxes.Footer>
    </>
  );
};

TopTenTasksWidgetTableConfigModal.className = 'widget-config-modal';
TopTenTasksWidgetTableConfigModal.size = 'w650';

export default TopTenTasksWidgetTableConfigModal;
