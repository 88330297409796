import { sum } from 'lodash';
import { isEmpty } from 'lodash/lang';
import moment from 'moment';

import { toBill, toCoins } from '../../../../../services/financial';

function getAmount(amount) {
  if (amount) {
    return +toBill(amount);
  } else {
    return 0;
  }
}

function getLastVersionByField(allEvents, fieldName) {
  const filter = allEvents?.filter(event => event[fieldName]);
  const allVersionIds = filter?.map(({ id }) => id?.adjustmentVersionId);
  const maxVersion = allVersionIds ? Math.max(...allVersionIds) : null;
  const event = allEvents?.find(({ id }) => maxVersion === +id?.adjustmentVersionId);
  return event && event[fieldName];
}

function vendorValues(lastVersionByVendor, allEvents) {
  const vendorAmountType = lastVersionByVendor?.type;
  const vendorId = lastVersionByVendor?.vendorId;
  const vendorAmount = !isEmpty(vendorId)
    ? sum(allEvents?.map(({ vendorAmount }) => getAmount(vendorAmount?.amount)))
    : undefined;
  if (vendorAmount === 0) {
    return {
      vendorId: undefined,
      vendorAmount: undefined,
      vendorAmountType: undefined
    };
  } else {
    return {
      vendorId: vendorId,
      vendorAmount: vendorAmount,
      vendorAmountInCoins: toCoins(vendorAmount),
      vendorAmountType: vendorAmountType,
      vendorName: lastVersionByVendor?.vendorName
    };
  }
}

function patientValues(patientAmount, patientAmountType) {
  return {
    patientAmount: patientAmount,
    patientAmountInCoins: toCoins(patientAmount),
    patientAmountType: patientAmount ? patientAmountType : undefined
  };
}

function siteValues(siteAmount, siteAmountType) {
  return {
    siteAmount: siteAmount,
    siteAmountInCoins: toCoins(siteAmount),
    siteAmountType: siteAmount ? siteAmountType : undefined
  };
}
export default function getSummaryOfAdjustment(events) {
  const clientAmount = sum(events?.map(({ clientAmount }) => getAmount(clientAmount)));
  const siteAmount = sum(events?.map(({ siteAmount }) => getAmount(siteAmount?.amount)));
  const gross = sum(events?.map(({ gross }) => getAmount(gross)));
  const net = sum(events?.map(({ netRevenueAmount }) => getAmount(netRevenueAmount)));
  const patientAmountType = getLastVersionByField(events, 'patientAmount')?.type;
  const patientAmount = sum(events?.map(({ patientAmount }) => getAmount(patientAmount?.amount)));
  const siteAmountType = getLastVersionByField(events, 'siteAmount')?.type;
  const lastVersionByVendor = getLastVersionByField(events, 'vendorAmount');
  const vendor = vendorValues(lastVersionByVendor, events);
  const patient = patientValues(patientAmount, patientAmountType);
  const site = siteValues(siteAmount, siteAmountType);
  return {
    ...vendor,
    ...patient,
    ...site,
    gross,
    grossInCoins: toCoins(gross),
    net,
    netInCoins: toCoins(net),
    clientAmount: clientAmount,
    clientAmountInCoins: toCoins(clientAmount),
    revenueDate: moment()
  };
}
