export function getRowKeysForSignature(
  itemGroupSnapshotReviewStates,
  logSnapshotReviewStates,
  logCheckSnapshotReviewStates,
  reason
) {
  const procedureLikeRows = Object.values(itemGroupSnapshotReviewStates).flatMap(i => i.itemGroupSnapshotState.rows);
  const tableGeneralRows = Object.values(itemGroupSnapshotReviewStates)
    .flatMap(i => i.itemGroupSnapshotState.general)
    .filter(e => e != null);
  const logRows = Object.values(logSnapshotReviewStates).flatMap(i => i.logSnapshotState.rows);
  const logCheckSnapshotKeys = Object.values(logCheckSnapshotReviewStates)
    .flatMap(i => i.logCheckSnapshotState?.encounterLogCheckSnapshotKey)
    .filter(e => e != null);
  const itemGroupRowSnapshotKeys = [...procedureLikeRows, ...logRows].map(row => row.rowSnapshotKey);
  const tableGeneralRowSnapshotKeys = tableGeneralRows.map(row => row.rowSnapshotKey);
  return {
    itemGroupRowSnapshotKeys,
    tableGeneralRowSnapshotKeys,
    logCheckSnapshotKeys,
    reason
  };
}
