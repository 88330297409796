import React from 'react';
import { isEqual } from 'lodash/lang';
import moment from 'moment';

import DatePicker from '../../../../common/data-entry/DatePicker';

export default function MonthFilter({ dateToShow, handleMChange }) {
  const today = moment();
  const valid = function(current) {
    const isTodayLastMonthInYear = today.month() === 11;
    const isCurrentMonthFirstInYear = current.month() === 0;
    return (
      current.year() < today.year() ||
      (current.year() === today.year() && current.month() <= today.month() + 1) ||
      (current.year() === today.year() + 1 && isTodayLastMonthInYear && isCurrentMonthFirstInYear)
    );
  };
  return (
    <div className="month-select" data-testid="month-selector">
      <DatePicker
        value={dateToShow}
        label="Month"
        timeFormat={false}
        closeOnSelect
        className="calendar-input"
        onChange={monthOnChange}
        viewMode="months"
        dateFormat={'MMMM YYYY'}
        readOnly={true}
        isValidDate={valid}
        data-testid="month-selector"
      />
    </div>
  );
  function monthOnChange(date) {
    if (!(isEqual(date.month(), dateToShow.month()) && isEqual(date.year(), dateToShow.year()))) {
      handleMChange(date);
    }
  }
}
