import React from 'react';

import { getFormattedDateForNotPerformedItemGroup } from '../../../reviewService';

import './ItemGroupNotPerformed.scss';

export default function ItemGroupNotPerformed({ notPerformedReason, completionDate, lastUpdatedByName }) {
  return (
    <div className="erc-not-performed">
      <p>
        <span>Selected As Not Performed: </span>
        {`${lastUpdatedByName} - ${getFormattedDateForNotPerformedItemGroup(completionDate)}`}
      </p>
      <p>
        <span>Reason Not Performed: </span>
        {notPerformedReason}
      </p>
    </div>
  );
}
