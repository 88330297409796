import React, { useContext } from 'react';
import cx from 'classnames';
import { cloneDeep, isEmpty } from 'lodash/lang';

import Input from '../../../../../../../../common/data-entry/Input';
import Checkbox from '../../../../../../../../common/data-entry/InputSelectors/Checkbox';
import MultiSelect from '../../../../../../../../common/data-entry/MultiSelect/MultiSelect';
import Select from '../../../../../../../../common/data-entry/Select';
import Button from '../../../../../../../../common/general/Button';
import { mappingTypes } from '../MappingSetupConstants';

import CollapseButton from './CollapseButton/CollapseButton';

const MappingSetupHeader = ({ context, headerCollapsed, setHeaderCollapsed }) => {
  const {
    templateMode,
    templateName,
    setTemplateName,
    selectedPlatform,
    onChangeReceivingPlatform,
    listOfPlatforms,
    selectedEndpoint,
    endpointsList,
    listOfTriggerItemGroups,
    setSelectedTriggerItemGroups,
    selectedTriggerItemGroups,
    setSendPartialPayload,
    sendPartialPayload,
    setProtocolEncounterConfigurationEnabled,
    protocolEncounterConfigurationEnabled,
    addTemplateToMapping,
    studyList,
    selectedStudy,
    protocolList,
    selectedProtocol,
    selectedMappingType,
    epochList,
    selectedEpoch,
    mappingNamesList,
    selectedMapping,
    initialParameters,
    onChangePlatformEndpoint,
    onChangeStudyName,
    onChangeProtocolVersion,
    onChangeMappingType,
    onChangeEncounter,
    onChangeMappingName
  } = useContext(context);

  const setupClassName = cx('setup-parameters', {
    hidden: headerCollapsed
  });
  const collapseWrapperClassName = cx('collapse-button-wrapper', {
    hidden: headerCollapsed
  });

  const allowEdit = !isEmpty(initialParameters);

  return (
    <div className="mapping-setup-header">
      <div className={setupClassName}>
        {templateMode ? (
          <Input
            className="template-name"
            label="Template Name"
            type="text"
            validate={false}
            value={templateName}
            onChange={({ target: { value } }) => setTemplateName(value)}
            required={true}
          />
        ) : (
          <>
            <div className="setup-item">
              <span>Receiving Platform</span>
              <Select
                value={selectedPlatform}
                onChange={onChangeReceivingPlatform}
                dataSource={listOfPlatforms}
                optionLabelKey="name"
                optionValueKey="value"
                label="Platform name"
                className="mapping-input"
                disabled={allowEdit}
              />
            </div>
            <div className="setup-item">
              <span>Platform Endpoint</span>
              <Select
                value={selectedEndpoint}
                onChange={onChangePlatformEndpoint}
                dataSource={endpointsList}
                optionLabelKey="name"
                optionValueKey="value"
                label="Endpoint"
                className="mapping-input"
                disabled={allowEdit}
              />
            </div>
            {selectedPlatform?.value && selectedEndpoint?.value && (
              <>
                <div className="setup-item">
                  <span>Study Name</span>
                  <Select
                    value={selectedStudy}
                    onChange={onChangeStudyName}
                    dataSource={studyList}
                    label="Study Name"
                    className="mapping-input"
                    disabled={allowEdit}
                    searchable={true}
                  />
                </div>
                <div className="setup-item">
                  <span>Protocol Version</span>
                  <Select
                    value={selectedProtocol}
                    onChange={onChangeProtocolVersion}
                    dataSource={protocolList}
                    label="Protocol Version"
                    className="mapping-input"
                    disabled={allowEdit || isEmpty(selectedStudy)}
                  />
                </div>

                <div className="setup-item">
                  <span>Mapping Type</span>
                  <Select
                    value={selectedMappingType}
                    onChange={onChangeMappingType}
                    dataSource={mappingTypes}
                    label="Mapping Type"
                    className="mapping-input"
                    disabled={allowEdit || isEmpty(selectedProtocol)}
                  />
                </div>
                {selectedMappingType?.id === 'Encounter' && (
                  <div className="setup-item">
                    <span>Epoch</span>
                    <Select
                      value={selectedEpoch}
                      onChange={onChangeEncounter}
                      dataSource={epochList}
                      label="Epoch Name"
                      className="mapping-input"
                      disabled={allowEdit}
                    />
                  </div>
                )}
                <div className="setup-item">
                  <span>Mapping Name</span>
                  <Select
                    value={selectedMapping}
                    onChange={onChangeMappingName}
                    dataSource={mappingNamesList}
                    label="Mapping Name"
                    className="mapping-input"
                    disabled={
                      allowEdit ||
                      (isEmpty(selectedEpoch) && selectedMappingType?.id === 'Encounter') ||
                      isEmpty(selectedMappingType)
                    }
                  />
                </div>
                {selectedMappingType?.id === 'Encounter' && (
                  <div className="setup-item setup-multiselect">
                    <span>Trigger Review</span>
                    <MultiSelect
                      dataSource={cloneDeep(listOfTriggerItemGroups)}
                      onChange={selectedValue => setSelectedTriggerItemGroups(selectedValue)}
                      searchable
                      value={selectedTriggerItemGroups}
                      className="mapping-input"
                      clearable={true}
                      validate={false}
                      disabled={isEmpty(selectedMapping)}
                    />
                  </div>
                )}
                <div className="setup-item checkbox-wrapper">
                  <div>
                    {selectedMappingType?.id === 'Encounter' && (
                      <Checkbox
                        label="Send partial payload"
                        title="Send partial payload of changed data on retry"
                        key="Send partial payload"
                        onChange={() => setSendPartialPayload(!sendPartialPayload)}
                        checked={sendPartialPayload}
                      />
                    )}
                    <Checkbox
                      label="Enable mappings"
                      key="Enable mappings"
                      onChange={() => setProtocolEncounterConfigurationEnabled(!protocolEncounterConfigurationEnabled)}
                      checked={protocolEncounterConfigurationEnabled}
                    />
                  </div>
                  <Button size="h28" onClick={addTemplateToMapping} disabled={isEmpty(selectedMapping)}>
                    + Template
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <div className={collapseWrapperClassName}>
        {headerCollapsed && <span className="section-header">Platform settings</span>}
        <CollapseButton onClick={() => setHeaderCollapsed(!headerCollapsed)} collapsed={headerCollapsed} />
      </div>
    </div>
  );
};

export default MappingSetupHeader;
