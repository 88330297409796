import { EventEmitter } from 'events';

const CREATE = Symbol('CREATE');
const OPEN = Symbol('OPEN');
const CLOSE = Symbol('CLOSE');
const SET_HIDDEN = Symbol('SET_HIDDEN');

const TasksSupervisorEventEmitter = new EventEmitter();

let _onCreateHandler = () => {};
let _onOpenHandler = () => {};
let _onCloseHandler = () => {};
let _onSetHiddenHandler = () => {};
let _onChangeHandler = () => {};

const TasksSupervisor = {
  create() {
    changeState({ action: CREATE });
  },
  open(taskId, ssuId, keepIsHidden) {
    changeState({ action: OPEN, taskId, ssuId, keepIsHidden });
  },
  close() {
    changeState({ action: CLOSE });
  },
  setHidden(isHidden) {
    changeState({ action: SET_HIDDEN, isHidden });
  },
  onCreate(cb) {
    /** In the current implementation, it was designed for single-place usage. **/
    _onCreateHandler = cb;
    return this;
  },
  onOpen(cb) {
    /** In the current implementation, it was designed for single-place usage. **/
    _onOpenHandler = cb;
    return this;
  },
  onClose(cb) {
    /** In the current implementation, it was designed for single-place usage. **/
    _onCloseHandler = cb;
    return this;
  },
  onSetHidden(cb) {
    /** In the current implementation, it was designed for single-place usage. **/
    _onSetHiddenHandler = cb;
    return this;
  },
  subscribe(cb = _onChangeHandler) {
    /** In the current implementation, it was designed for single-place usage. **/
    TasksSupervisorEventEmitter.on('change-state', listener);
    return () => {
      TasksSupervisorEventEmitter.removeListener('change-state', listener);
    };
    function listener(payload) {
      actionResolver(payload);
      cb(payload);
    }
  }
};

export default TasksSupervisor;

function actionResolver(payload) {
  if (payload.action === CREATE) {
    _onCreateHandler(payload);
    return;
  }
  if (payload.action === OPEN) {
    _onOpenHandler(payload);
    return;
  }
  if (payload.action === CLOSE) {
    _onCloseHandler(payload);
    return;
  }
  if (payload.action === SET_HIDDEN) {
    _onSetHiddenHandler(payload);
    return;
  }
  throw new Error(`Was called with wrong action: '${payload.action}'.`);
}

function changeState(payload) {
  TasksSupervisorEventEmitter.emit('change-state', payload);
}
