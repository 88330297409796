import './StudyDetailsList.scss';

export default function StudyDetailsList({ children }) {
  return <div className="study-details-list">{children}</div>;
}

StudyDetailsList.Header = function StudyDetailsListHeader({ children, rightSide }) {
  return (
    <div className="study-details-list-header">
      <div className="study-details-list-title">{children}</div>
      <div className="study-details-list-right-side">{rightSide}</div>
    </div>
  );
};

StudyDetailsList.Container = function StudyDetailsListContainer({ children }) {
  return <ul className="study-details-list-container">{children}</ul>;
};

StudyDetailsList.Item = function StudyDetailsListItem({ children, rightSide }) {
  return (
    <li>
      <div className="study-details-list-item">
        <div className="study-details-list-title">{children}</div>
        <div className="study-details-list-right-side">{rightSide}</div>
      </div>
    </li>
  );
};
