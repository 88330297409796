import { handleActions } from 'redux-actions';

import { options } from './actions';
import { CLEAR, UPDATE } from './types';

export default handleActions(
  {
    [UPDATE]: function(state, { payload }) {
      return { ...state, ...payload };
    },
    [CLEAR]: function() {
      return {};
    }
  },
  {},
  options
);
