import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DataGridPremium, GridToolbar } from '@mui/x-data-grid-premium';
import PatientTravelWorklistApi from 'api/patient/PatientTravelWorklistApi';
import { isEmpty } from 'lodash/lang';

import { useEuiFiltersState, withEuiFiltersProvider } from 'components/eui/EuiFiltersContext/EuiFiltersContext';
import { FRS_READY } from 'components/eui/EuiFiltersContext/reducer';

import FiltersSection from './FiltersSection/FiltersSection';
import useDataGrid from './useDataGrid';

import './TravelRequestsWorklist.scss';

function TravelRequestsWorklist() {
  const onceLoaded = useRef(false);
  const alreadyLoaded = useRef(false);
  const [loading, setLoading] = useState(false);
  const { schema, statuses, ssu, assigned } = useEuiFiltersState();
  const [columns, gridData, setGridData] = useDataGrid();

  const requestObject = useMemo(
    function() {
      if (schema?.status !== FRS_READY || isEmpty(statuses?.value) || isEmpty(ssu) || isEmpty(assigned?.value)) {
        return;
      }
      const { studySites, selectedStudyMap, selectedSiteMap } = ssu;
      const ssuIds = studySites.reduce(function(accumulator, { id, study, site }) {
        if (selectedStudyMap[study.id] && selectedSiteMap[site.id]) {
          accumulator.push(id);
        }
        return accumulator;
      }, []);
      alreadyLoaded.current = false;
      return {
        statuses: statuses.value.map(status => status.id),
        ssuIds,
        assigneeIds: assigned.value.map(a => a.id)
      };
    },
    [schema, statuses, ssu, assigned]
  );

  const loadTableData = useCallback(
    function(request) {
      setLoading(true);
      PatientTravelWorklistApi.getWorklistTravels(request)
        .then(({ data }) => {
          setGridData(data);
          alreadyLoaded.current = true;
        })
        .finally(() => setLoading(false));
    },
    [setGridData]
  );

  const onApply = useCallback(
    function() {
      if (requestObject) {
        loadTableData(requestObject);
      }
    },
    [loadTableData, requestObject]
  );

  useEffect(
    function() {
      if (onceLoaded.current || !requestObject) {
        return;
      }
      loadTableData(requestObject);
      onceLoaded.current = true;
    },
    [loadTableData, requestObject]
  );

  const applyDisabled = alreadyLoaded.current || loading;

  return (
    <div className="eui-travel-requests-worklist">
      <FiltersSection gridData={gridData} onApply={onApply} applyDisabled={applyDisabled} />
      <DataGridPremium
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            printOptions: { disableToolbarButton: true },
            excelOptions: { disableToolbarButton: true },
            quickFilterProps: {
              debounceMs: 500
            }
          }
        }}
        loading={loading}
        rowHeight={50}
        rows={gridData}
        columns={columns}
        disableRowSelectionOnClick
        localeText={{ noRowsLabel: 'No results to display' }}
        disableRowGrouping
        disableAggregation
      />
    </div>
  );
}

export default withEuiFiltersProvider(TravelRequestsWorklist, { cachePrefix: 'TRWF' });
