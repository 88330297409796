import { get } from 'lodash/object';
import moment from 'moment';

import { CalendarPageSupplierApi } from '../../../api';
import { getRoleDescriptionByUserRoles } from '../../../services/personnelService';

import { NON_PATIENT_EVENT, PATIENT_ENCOUNTER_EVENT, PATIENT_SITUATIONAL_ENCOUNTER_EVENT } from './CalendarEventType';

export const DRAFT_EVENT_ID = 'draft_event_id';

export function draftEventProvider(timeRanges, calendars) {
  // timeRange, calendars can be used to calculate most appropriate time for event base on encounter time range and existing events

  return () => ({
    start: moment()
      .add(1, 'h')
      .startOf('h'),
    duration: moment.duration(60, 'm')
  });
}

export function isDraft(event) {
  return event && event.id === DRAFT_EVENT_ID;
}

export function buildDraftPatientEvent(currentUser, sitePatientId, encounterId, getDraftEventTime, encounterEventType) {
  const emptyDraft = {
    type: encounterEventType,
    id: DRAFT_EVENT_ID,
    ...getDraftEventTime(),
    organizer: {
      userId: currentUser.personnelIdentifier,
      type: 'USER',
      label: getRoleDescriptionByUserRoles(currentUser.roles)
    },
    sitePatientId: sitePatientId
  };

  if (encounterId && sitePatientId) {
    if ([PATIENT_ENCOUNTER_EVENT, NON_PATIENT_EVENT].includes(encounterEventType)) {
      return CalendarPageSupplierApi.getPatientEncounterEventData(sitePatientId, encounterId).then(
        ({ data: encounterEventData }) => {
          return {
            ...emptyDraft,
            patient: encounterEventData.patient,
            encounter: encounterEventData.encounter,
            epoch: encounterEventData.epoch,
            study: { ...get(encounterEventData, 'studySite.study'), sitePatientId: sitePatientId },
            siteName: get(encounterEventData, 'studySite.site.name')
          };
        }
      );
    }
    if (encounterEventType === PATIENT_SITUATIONAL_ENCOUNTER_EVENT) {
      return CalendarPageSupplierApi.getPatientSituationalEncounterEventData(sitePatientId, encounterId).then(
        ({ data: encounterEventData }) => {
          return {
            ...emptyDraft,
            patient: encounterEventData.patient,
            encounter: {
              key: encounterEventData.encounter.id,
              name: encounterEventData.encounter.name
            },
            study: { ...get(encounterEventData, 'studySite.study'), sitePatientId: sitePatientId },
            siteName: get(encounterEventData, 'studySite.site.name')
          };
        }
      );
    }
  } else {
    return Promise.resolve(emptyDraft);
  }
}
