import React, { useLayoutEffect, useState } from 'react';
import cx from 'classnames';

import Icon from '../../../../../../../common/general/Icon';

import './ScrollTopButton.scss';

export default function ScrollTopButton({ container }) {
  const [showScrollTop, setShowScrollTop] = useState(false);
  useLayoutEffect(
    function() {
      if (!container) return;

      container.addEventListener('scroll', listener);

      return function() {
        container.removeEventListener('scroll', listener);
      };

      function listener() {
        setShowScrollTop(container?.scrollTop > 300);
      }
    },
    [container]
  );

  return (
    <div
      className={cx('erc-scroll-top', {
        'erc-scroll-top-hidden': !showScrollTop
      })}
      onClick={function() {
        if (container) {
          container.scrollTop = 0;
        }
      }}
    >
      <Icon>arrow_upward</Icon>
      To Top
    </div>
  );
}
