import { EXTERNAL_INTEGRATION } from '../../../constants/accessToPages';

import AfterburnerLog from './AfterburnerLog';

export const afterburnerRequestLogRouterConfig = {
  name: 'Afterburner Log',
  path: '/afterburner-log',
  component: AfterburnerLog,
  access: [EXTERNAL_INTEGRATION]
};
