import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { sortBy } from 'lodash';
import { isEmpty } from 'lodash/lang';
import moment from 'moment/moment';

import { ProtocolApi, StudyApi } from '../../../api';
import { afterburnerApi } from '../../../api/patient/AftrburnerApi';
import Section from '../../../common/data-display/Section/Section';
import Select from '../../../common/data-entry/Select';
import Button from '../../../common/general/Button';
import NotificationManager from '../../../common/notifications/NotificationManager';
import { SOMETHING_WENT_WRONG } from '../../../constants/notificationMessages';
import { PageInfoHeader } from '../../PageInfoHeader/PageInfoHeader';
import { editMapping } from '../setup/Protocol/EncounterEpochTableTemplate/EncountersHeaders/MappingSetupModal/MappingSetupServices';

import './MappingsTemplates.scss';

const MappingTemplates = () => {
  const [studyList, setStudyList] = useState([]);
  const [selectedStudy, setSelectedStudy] = useState(null);

  const [protocolList, setProtocolList] = useState([]);
  const [selectedProtocol, setSelectedProtocol] = useState(null);

  const [templateList, setTemplateList] = useState([]);

  const getTemplatesForTable = useCallback(() => {
    afterburnerApi.getTemplatesByProtocol(selectedProtocol.id).then(
      ({ data }) => {
        setTemplateList(data);
      },
      () => {
        NotificationManager.error(SOMETHING_WENT_WRONG);
      }
    );
  }, [selectedProtocol?.id]);

  useEffect(() => {
    StudyApi.populateStudyList()
      .then(({ data: { response } }) => {
        const preparedStudies = response.map(d => ({
          id: d.uniqueIdentifier,
          name: d.studyName
        }));
        setStudyList(sortBy(preparedStudies, ['name']));
      })
      .catch(() => {
        NotificationManager.error(SOMETHING_WENT_WRONG);
      });
  }, []);

  useEffect(() => {
    if (selectedStudy?.id) {
      ProtocolApi.getProtocols(selectedStudy.id).then(
        ({ data: { response } }) => {
          const preparedProtocolList = response.map(protocol => ({
            id: protocol.uniqueIdentifier,
            name: `${protocol.name} ${protocol.version}`
          }));
          setProtocolList(preparedProtocolList);
          setTemplateList([]);
        },
        () => {
          NotificationManager.error(SOMETHING_WENT_WRONG);
        }
      );
      setSelectedProtocol(null);
    }
  }, [selectedStudy]);

  useEffect(() => {
    if (selectedProtocol?.id) {
      getTemplatesForTable();
    }
  }, [getTemplatesForTable, selectedProtocol]);

  const notAvailableNamesForTemplate = useMemo(() => {
    return templateList.map(template => template.name);
  }, [templateList]);

  const templateTableRows = useMemo(() => {
    return templateList.map(template => {
      return (
        <div
          className="table-rows"
          data-testid="table-row"
          key={template.id}
          onClick={() =>
            editMapping(
              true,
              template.id,
              getTemplatesForTable,
              { notAvailableNamesForTemplate: notAvailableNamesForTemplate.filter(name => name !== template.name) },
              null
            )
          }
        >
          <div className="table-item">{template.name}</div>
          <div className="table-item">{moment(template.lastUpdateDate).format('DD/MMM/YYYY')}</div>
          <div className="table-item">{template.lastUpdateByName}</div>
        </div>
      );
    });
  }, [getTemplatesForTable, notAvailableNamesForTemplate, templateList]);

  return (
    <>
      <PageInfoHeader
        right={
          <Button
            onClick={() =>
              editMapping(
                true,
                null,
                getTemplatesForTable,
                { protocol: selectedProtocol, notAvailableNamesForTemplate },
                null
              )
            }
            disabled={!selectedProtocol?.id}
            size="h28"
          >
            + Template
          </Button>
        }
      >
        <div className="general-header-wrapper">
          <Select
            dataSource={studyList}
            value={selectedStudy}
            onChange={newValue => {
              setSelectedStudy(newValue);
            }}
            label="Study"
            searchable={true}
            closeOnSelectedOptionClick={false}
            deselectOnSelectedOptionClick={false}
            clearable={false}
            validate={false}
            data-testid={'studyDropdown'}
          />
          <Select
            dataSource={protocolList}
            value={selectedProtocol}
            onChange={newValue => {
              setSelectedProtocol(newValue);
            }}
            label="Protocol"
            searchable={true}
            closeOnSelectedOptionClick={false}
            deselectOnSelectedOptionClick={false}
            clearable={false}
            validate={false}
            data-testid={'protocolDropdown'}
          />
        </div>
      </PageInfoHeader>

      <Section className="mapping-template-page">
        <div className="template-table">
          <div className="table-header">
            <div className="table-item">Name</div>
            <div className="table-item">Date edited</div>
            <div className="table-item">Last Edited By</div>
          </div>
          {!isEmpty(templateList) ? (
            templateTableRows
          ) : (
            <div className="table-placeholder"> There are no templates for this protocol </div>
          )}
        </div>
      </Section>
    </>
  );
};

export default MappingTemplates;
