import React from 'react';
import { isEmpty } from 'lodash/lang';
import * as PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';

export default function SituationalEncountersTitle(props) {
  const { situationalEncounters } = props;
  const countOfSituationalEncounters = situationalEncounters.length;
  const maxWidth = countOfSituationalEncounters * 90;
  return (
    <React.Fragment>
      {!isEmpty(situationalEncounters) && (
        <th
          style={{ maxWidth: `${maxWidth}px` }}
          className="eett-grey-cell eett-epoch-cell eett-middle-align eett-sticky-row"
          height="38px"
          key={'situationalEncounterHeaderKey'}
          id={'situationalEncounterHeaderId'}
          colSpan={situationalEncounters?.length}
          title="Situational encounters"
        >
          <Tooltip
            destroyTooltipOnHide={false}
            placement="top"
            overlay={`Situational encounters`}
            align={{
              offset: [50, -5]
            }}
            trigger={Object.keys({ hover: 1 })}
          >
            <span>Situational encounters</span>
          </Tooltip>
        </th>
      )}
    </React.Fragment>
  );
}

SituationalEncountersTitle.propTypes = { encounters: PropTypes.array };
