import axios from 'axios';

const ROOT = 'api/v1/finance/studies';

const FinTriggerApi = {
  getEncounterTriggers(studyId) {
    //regular and unexpected
    return axios.get(`${ROOT}/${studyId}/triggers/encounters`);
  },

  getItemGroupTriggers(studyId) {
    return axios.get(`${ROOT}/${studyId}/triggers/item-groups`);
  },

  getUnexpectedItemGroupsTriggers(studyId) {
    return axios.get(`${ROOT}/${studyId}/triggers/unexpected-item-groups`);
  },

  getEncounterItemGroupTriggers(studyId) {
    return axios.get(`${ROOT}/${studyId}/triggers/encounter-item-groups`);
  },

  getSituationalItemGroupTriggers(studyId) {
    return axios.get(`${ROOT}/${studyId}/triggers/situational-item-groups`);
  }
};

export default FinTriggerApi;
