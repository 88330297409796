import React from 'react';
import * as moment from 'moment';

import DatePicker from '../../../../../../../../common/data-entry/DatePicker';
import Input from '../../../../../../../../common/data-entry/Input';
import Select from '../../../../../../../../common/data-entry/Select';
import Toggle from '../../../../../../../../common/data-entry/Toggle';
import { validDob } from '../AddOrEditPatientInfoModalService';

export default function PatientDetails({
  isEditMode,
  handleDateOfBirthChange,
  handleInputChange,
  handleSexChange,
  handlePronounsChange,
  handlePrimaryLanguageChange,
  isRequiredField,
  getValidationMessage,
  medicalRecordNumber,
  setMedicalRecordNumber,
  info,
  fieldsDataDictionary
}) {
  const { firstName, lastName, middleName, preferredName, dob, isDeceased, sex, pronouns, primaryLanguage } = info;

  return (
    <div className="row">
      <div className="col-12 apm-form-label">Patient Details</div>
      <div className="col-4">
        <Input
          label="First Name"
          name="firstName"
          value={firstName}
          onChange={handleInputChange}
          required={isRequiredField('firstName')}
          validationMessage={getValidationMessage('firstName')}
        />
      </div>
      <div className="col-4">
        <Input
          label="Last Name"
          name="lastName"
          value={lastName}
          onChange={handleInputChange}
          required={isRequiredField('lastName')}
          validationMessage={getValidationMessage('lastName')}
        />
      </div>
      <div className="col-4">
        <Input label="Middle Name" name="middleName" value={middleName} onChange={handleInputChange} />
      </div>
      <div className="col-4">
        <Input label="Preferred Name" name="preferredName" value={preferredName} onChange={handleInputChange} />
      </div>
      <div className="col-4">
        <Select
          label="Sex"
          name="sex"
          value={fieldsDataDictionary?.sexCodes?.find(({ id }) => id === sex) || null}
          onChange={handleSexChange}
          dataSource={fieldsDataDictionary?.sexCodes}
          clearSearchOnSelection
          optionValueKey="name"
        />
      </div>
      <div className="col-4">
        <Select
          label="Pronouns"
          name="pronouns"
          value={fieldsDataDictionary?.pronounsCodes?.find(({ id }) => id === pronouns) || null}
          onChange={handlePronounsChange}
          deselectOnSelectedOptionClick={false}
          dataSource={fieldsDataDictionary?.pronounsCodes}
          optionValueKey="name"
        />
      </div>

      <div className="col-4">
        <DatePicker.Manual
          label="Date of Birth"
          value={moment.isMoment(dob) && dob.format('DD/MMM/YYYY')}
          required={isRequiredField('dob')}
          isValidDate={validDob}
          dateOnly={true}
          onChange={d => handleDateOfBirthChange(d, 'dob')}
          validationMessage={getValidationMessage('dob')}
        />
      </div>
      {!isEditMode && (
        <div className="col-4">
          <Input
            label="Medical Record Number"
            name="medicalRecordNumber"
            value={medicalRecordNumber}
            onChange={e => setMedicalRecordNumber(e.target.value)}
          />
        </div>
      )}
      <div className="col-4">
        <Select
          label="Primary Language"
          name="primaryLanguage"
          value={fieldsDataDictionary?.languageCodes?.find(({ id }) => id === primaryLanguage)}
          onChange={handlePrimaryLanguageChange}
          deselectOnSelectedOptionClick={false}
          dataSource={fieldsDataDictionary?.languageCodes}
          optionValueKey="name"
        />
      </div>

      <div className="col-12" />

      <div className="col-4 mb-4">
        <Toggle name="isDeceased" checked={isDeceased} onChange={handleInputChange}>
          Deceased
        </Toggle>
      </div>
    </div>
  );
}
