import React, { useContext } from 'react';

import { FileViewerContext } from '../../../../../../../../contexts/FileViewerContext';
import { getFileTitle, openFile, resolveFileIcon } from '../../../../../../../../services/fileService';
import { useCurrentRoute } from '../../../../../../../root/router';

import './FileView.scss';

export function FileView({ files }) {
  const fileViewer = useContext(FileViewerContext);
  const {
    params: { ssuPatientId }
  } = useCurrentRoute();
  return files.map(file => {
    return (
      <button
        key={file.id}
        className="review-file-view"
        title={getFileTitle(file)}
        onClick={() => openFile(fileViewer, { uniqueIdentifier: file.id, sitePatientIdentifier: ssuPatientId })}
      >
        <div className="d-flex align-items-center">
          {resolveFileIcon(file)}
          {file.name}
        </div>
      </button>
    );
  });
}
