import { FinBudgetSiteApi } from '../../../api';
import NotificationManager from '../../../common/notifications/NotificationManager';
import { BUDGET_SITE_MAPPING_ADDED, BUDGET_SITE_MAPPING_NOT_ADDED } from '../../../constants/notificationMessages';
import { ADD_BUDGET_SITE } from '../../actionTypes';

import { getBudgetSites } from './getBudgetSitesAction';
import { toBudgetSiteFormat } from './transformBudgetSiteAction';

export const addBudgetSiteAction = budgetSite => {
  return {
    type: ADD_BUDGET_SITE,
    payload: budgetSite
  };
};

export const addBudgetSite = budgetSite => dispatch => {
  return FinBudgetSiteApi.save(budgetSite)
    .then(resp => {
      dispatch(addBudgetSiteAction(toBudgetSiteFormat(resp.data)));
      NotificationManager.success(BUDGET_SITE_MAPPING_ADDED);

      dispatch(getBudgetSites());
    })
    .catch(err => {
      if (err?.response?.status === 409 && err.response?.data?.message) {
        NotificationManager.error(err.response.data.message);
      } else {
        NotificationManager.error(BUDGET_SITE_MAPPING_NOT_ADDED);
      }
    });
};
