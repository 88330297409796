import { SvgIcon } from '@mui/material';

export const Indent = ({ onClick, active }) => {
  return (
    <SvgIcon onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M3 9V15L6 12L3 9ZM3 19H21V17H3V19ZM3 7H21V5H3V7ZM9 11H21V9H9V11ZM9 15H21V13H9V15Z"
          fill="black"
          fillOpacity={`${active ? '0.9' : '0.6'}`}
        />
      </svg>
    </SvgIcon>
  );
};
