import React from 'react';
import { isEmpty } from 'lodash/lang';

import Section from '../../../../../../../../common/data-display/Section/Section';
import TableItemGroupComment from '../../../../../../patient-source/shared/EncounterDescNew/TableItemGroup/TableItemGroupComment/TableItemGroupComment';
import {
  TableItemGroupProvider,
  useTableItemGroupState
} from '../../../../../../patient-source/shared/EncounterDescNew/TableItemGroup/TableItemGroupContext/TableItemGroupContext';
import TableItemGroupGeneral from '../../../../../../patient-source/shared/EncounterDescNew/TableItemGroup/TableItemGroupGeneral/TableItemGroupGeneral';
import TableItemGroupResults from '../../../../../../patient-source/shared/EncounterDescNew/TableItemGroup/TableItemGroupResults/TableItemGroupResults';

function TableItemGroupPreviewComponent() {
  const { encounterTableItemGroup } = useTableItemGroupState();

  if (isEmpty(encounterTableItemGroup)) {
    return null;
  }

  return (
    <div className="table-item-group">
      <Section>
        <div className="table-item-group-scroll">
          <TableItemGroupGeneral />
          <TableItemGroupResults />
          <TableItemGroupComment />
        </div>
      </Section>
    </div>
  );
}

export default function TableItemGroupPreview({
  encounterTableItemGroupForPreview,
  conditionalLogicAndRequiredFieldsValidation,
  ...props
}) {
  return (
    <TableItemGroupProvider
      isPreviewMod
      encounterTableItemGroupForPreview={encounterTableItemGroupForPreview}
      conditionalLogicAndRequiredFieldsValidation={conditionalLogicAndRequiredFieldsValidation}
    >
      <TableItemGroupPreviewComponent {...props} />
    </TableItemGroupProvider>
  );
}
