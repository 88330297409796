import React, { useEffect, useRef, useState } from 'react';
import { LoadingHandler } from 'common/elements/Loader/LoadingHandler';
import { EventEmitter } from 'events';
import { first } from 'lodash/array';
import { isEmpty, isFunction } from 'lodash/lang';
import Collapse from 'rc-collapse';

import PatientEnablementTabs from './PatientEnablementTabs/PatientEnablementTabs';

import './PatientEnablementSection.scss';

const PatientEnablementSectionEventEmitter = new EventEmitter();
export default function PatientEnablementSection({ tabs }) {
  const collapseHeaderRef = useRef();
  const [activeTab, setActiveTab] = useState(null);
  const [activePatientEnablementCollapseKey, setActivePatientEnablementCollapseKey] = useState(-1);

  useEffect(
    function() {
      PatientEnablementSectionEventEmitter.on('change-state', listener);
      return () => {
        PatientEnablementSectionEventEmitter.removeListener('change-state', listener);
      };
      function listener(tabKey) {
        setActivePatientEnablementCollapseKey(1);
        if (tabs.includes(tabKey)) {
          setActiveTab(tabKey);
        }
        if (isFunction(collapseHeaderRef?.current?.scrollIntoView)) {
          collapseHeaderRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }
      }
    },
    [tabs]
  );

  useEffect(
    function() {
      if (activeTab && tabs.includes(activeTab)) {
        return;
      }
      const firstTab = first(tabs);
      if (isEmpty(firstTab)) {
        return;
      }
      setActiveTab(firstTab);
    },
    [activeTab, tabs]
  );

  return (
    <Collapse
      activeKey={activePatientEnablementCollapseKey}
      onChange={key => setActivePatientEnablementCollapseKey(key)}
      expandIcon={({ isActive }) => (
        <span className="rc-collapse-header-expand-icon material-icons">
          {`keyboard_arrow_${isActive ? 'up' : 'down'}`}
        </span>
      )}
      className="eds-patient-enablement-section"
    >
      <Collapse.Panel
        header={
          <div className="rc-collapse-header-title" ref={collapseHeaderRef}>
            PATIENT ENABLEMENT
          </div>
        }
        key="1"
      >
        <LoadingHandler complete dark>
          {() => {
            return <PatientEnablementTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />;
          }}
        </LoadingHandler>
      </Collapse.Panel>
    </Collapse>
  );
}

PatientEnablementSection.open = function(tabKey) {
  PatientEnablementSectionEventEmitter.emit('change-state', tabKey);
};
