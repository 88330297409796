import { useCallback, useContext, useMemo, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { isEmpty } from 'lodash/lang';
import moment from 'moment-timezone';

import SitePaymentsApi from '../../../../../../api/finance/SitePaymentsApi';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../../constants/dateFormat';
import { MANAGE_SITE_PAYMENTS } from '../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../services/auth';
import { onRequestError } from '../../../../../../services/handlers';
import { SitePaymentsContext } from '../../SitePaymentsContext';
import { PARENT, SITE_CREDIT, SITE_DEBIT } from '../SitePaymentsConstants';

export const BillNumberCell = ({
  sitePaymentBillNumber,
  itemSiteId,
  sitePaymentType,
  siteBillExportDate,
  siteApprovalStatus,
  eventNumber
}) => {
  const { updateBillNumberInTable, tableData } = useContext(SitePaymentsContext);
  const [loading, setLoading] = useState(false);
  const [billNumberList, setBillNumberList] = useState([]);
  const [saving, setSaving] = useState(false);

  const onOpen = useCallback(() => {
    setLoading(true);
    SitePaymentsApi.getAvailableBillNumbers(itemSiteId).then(({ data }) => {
      setBillNumberList(data);
      setLoading(false);
    });
  }, [itemSiteId]);

  const onBillNumberChange = useCallback(
    (event, updatedValue) => {
      setSaving(true);

      const newBillNumbers = isEmpty(updatedValue)
        ? { sitePaymentBillNumber: null, siteBillNumber: null, sourceItemSiteId: null, siteBillDate: null }
        : {
            ...updatedValue,
            sourceItemSiteId: updatedValue.itemSiteId,
            siteBillDate: moment().format(DD_SLASH_MMM_SLASH_YYYY)
          };

      const resolveChangeEndpoint = (updatedValue, itemSiteId) => {
        if (isEmpty(updatedValue)) {
          return SitePaymentsApi.deleteBillNumber(itemSiteId);
        }
        return SitePaymentsApi.updateBillNumber(itemSiteId, newBillNumbers);
      };

      resolveChangeEndpoint(updatedValue, itemSiteId)
        .then(() => {
          updateBillNumberInTable(newBillNumbers, itemSiteId);
        })
        .catch(onRequestError)
        .finally(() => setSaving(false));
    },
    [itemSiteId, updateBillNumberInTable]
  );

  const disableEdit = useMemo(() => {
    if (!!siteBillExportDate || siteApprovalStatus !== 'OPEN') {
      return true;
    }
    if (sitePaymentType === PARENT) {
      const childrenForSelectedParent = tableData?.filter(
        paymentEvent => paymentEvent.eventNumber === eventNumber && paymentEvent.sitePaymentType !== PARENT
      );
      return childrenForSelectedParent.some(child => !!child.sitePaymentBillNumber);
    } else if ([SITE_CREDIT, SITE_DEBIT].includes(sitePaymentType)) {
      const childrenForSelectedSiteCredit = tableData?.filter(
        paymentEvent =>
          paymentEvent.eventNumber === eventNumber && paymentEvent.sitePaymentType === `${sitePaymentType}_WH`
      );
      return childrenForSelectedSiteCredit.some(child => !!child.sitePaymentBillNumber);
    }
    return false;
  }, [eventNumber, siteApprovalStatus, siteBillExportDate, sitePaymentType, tableData]);

  return disableEdit || !userHasAccessTo(MANAGE_SITE_PAYMENTS) ? (
    sitePaymentBillNumber
  ) : (
    <Autocomplete
      data-testid={`bill-number-dropdown-${sitePaymentBillNumber}`}
      onOpen={onOpen}
      loading={loading}
      value={{ id: sitePaymentBillNumber, sitePaymentBillNumber: sitePaymentBillNumber }}
      onChange={onBillNumberChange}
      fullWidth
      autoHighlight
      options={billNumberList?.filter(item => item.sitePaymentBillNumber !== sitePaymentBillNumber)}
      disabled={saving}
      getOptionKey={option => option.siteBillNumber}
      getOptionLabel={option => option.sitePaymentBillNumber}
      renderInput={params => (
        <TextField
          {...params}
          size="small"
          variant="standard"
          sx={{
            '.MuiInputBase-root': {
              '::before': { border: 'none !important' }
            },
            '.MuiInputBase-input': {
              fontSize: '0.875rem'
            }
          }}
        />
      )}
    />
  );
};
