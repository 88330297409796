import React, { useMemo } from 'react';

import useSessionStorage from '../hooks/useSessionStorage';

export function withSessionStorage(Component, type, defaultValue) {
  return () => {
    const value = useSessionStorage(type, defaultValue);
    const updatedValue = useMemo(() => ({ [type]: value }), [value]);
    return <Component {...updatedValue} />;
  };
}
