import cx from 'classnames';

import './DirectionSection.scss';

export const DirectionSection = ({ directions, isLogsPage = false, isCraOrAuditor = false, isPreview = false }) => {
  if (directions) {
    return (
      <div className={cx('direction-container', isLogsPage ? 'on-logs-page' : '', isPreview ? 'preview-modal' : '')}>
        {!isLogsPage && !isCraOrAuditor && directions.encounterDirection && (
          <div className={'single-direction-container encounter'}>
            <h5 className={'direction-title'}>Encounter Directions</h5>
            <div className={'direction-message'}>{directions.encounterDirection}</div>
          </div>
        )}
        {directions.logDirection && (
          <div className={'single-direction-container log'}>
            <h5 className={'direction-title'}>Log Directions</h5>
            <div className={'direction-message'}>{directions.logDirection}</div>
          </div>
        )}
      </div>
    );
  } else {
    return <></>;
  }
};
