import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash/lang';

import { PatientTransferringApi } from '../../../../../../../../api';
import PatientInfoPageApi from '../../../../../../../../api/patient/PatientInfoPageApi';
import Select from '../../../../../../../../common/data-entry/Select';
import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../../../../common/notifications/NotificationManager';
import { onRequestError } from '../../../../../../../../services/handlers';
import { useCurrentRoute } from '../../../../../../../root/router';

const TransferPatientModal = props => {
  const currentRoute = useCurrentRoute();
  const { modalBox, study } = props;
  const { studyId, ssuPatientId } = study;
  const [sites, setSites] = useState();
  const [studyManagers, setStudyManagers] = useState();
  const [selectedStudyManager, setSelectedStudyManager] = useState();
  const [selectedSite, setSelectedSite] = useState();
  const patientId = currentRoute.params?.patientId;

  useEffect(() => {
    PatientInfoPageApi.getPatientSitesForTransferring(patientId, studyId).then(({ data }) => setSites(data));
  }, [patientId, studyId]);

  useEffect(() => {
    if (selectedSite) {
      setStudyManagers(selectedSite.studyManagers);
    } else {
      setStudyManagers(null);
      setSelectedStudyManager(null);
    }
  }, [selectedSite]);

  return (
    <>
      <ModalBoxes.Header>Transfer patient to another site</ModalBoxes.Header>
      <ModalBoxes.Body>
        <Select
          dataSource={sites}
          required
          onChange={onSiteChange}
          label="Site"
          optionLabelKey="siteName"
          optionValueKey="uniqueIdentifier"
        />
        <Select
          dataSource={studyManagers}
          required
          onChange={onSMChange}
          label="Study Manager"
          optionLabelKey="fullName"
          optionValueKey="uniqueIdentifier"
          disabled={!selectedSite}
        />
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            Cancel
          </Button>
          <Button onClick={transferPatient} disabled={!selectedSite || !selectedStudyManager}>
            Transfer
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );

  function onSiteChange(newValue) {
    if (isEqual(selectedSite, newValue)) return;
    setSelectedSite(newValue);
  }

  function onSMChange(newValue) {
    if (isEqual(selectedStudyManager, newValue)) return;
    setSelectedStudyManager(newValue);
  }

  function transferPatient() {
    PatientTransferringApi.transferredPatientToNewSsu(
      ssuPatientId,
      selectedSite.uniqueIdentifier,
      selectedStudyManager.uniqueIdentifier
    )
      .then(() => {
        modalBox.close(true);
        NotificationManager.success('Patient is transferred to another StudySite');
      })
      .catch(err => onRequestError(err));
  }
};

TransferPatientModal.className = 'add-new-study-modal';
TransferPatientModal.size = 'w500';

export default TransferPatientModal;
