export const TABLE = 'Table';
export const NEW_TABLE = 'New Table';
export const COMMENTS = 'Comments';
export const COMMENTS_DOMAIN_CODE = 'CO';
export const LOGS = 'Logs';
export const PROCEDURE = 'Procedure';
export const REVIEW = 'Review';
export const ReviewType = {
  SM: 'SM',
  PI: 'PI',
  CRA: 'CRA'
};
export const ADD_COMMENT = 'Add Comment';
export const COMMENT = 'Comment';

export const RowReviewStatus = {
  SIGNED: 'SIGNED',
  EDITED_AFTER_SIGN: 'EDITED_AFTER_SIGN',
  NEVER_SIGNED: 'NEVER_SIGNED'
};

export const ItemGroupReviewStatus = {
  SIGNED: 'SIGNED',
  NOT_SIGNED: 'NOT_SIGNED'
};

export const NOT_PERFORMED_DATE_FORMAT = 'DD/MMM/YYYY hh:mm:ss A';
