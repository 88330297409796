import React, { useContext, useEffect, useMemo, useState } from 'react';

import InformedConsentApi from '../../../../../../api/patient/InformedConsentApi';
import { useCurrentRoute } from '../../../../../root/router';
import { EncounterPageContext } from '../NewEncounterPageContext';

export const InformedConsentContext = React.createContext(null);

export default function InformedConsentContextProvider(props) {
  const { children } = props;
  const route = useCurrentRoute();
  const { ssuPatientId } = route.params;
  const [allIcfForms, setAllIcfForms] = useState([]);
  const [newIcfForms, setNewIcfForms] = useState([]);
  const [completedIcfForms, setCompletedIcfForms] = useState([]);

  const { ssuId, encounterDetails, state } = useContext(EncounterPageContext);

  const isIcfItemGroupInEncounter = useMemo(() => {
    return state?.encounterDetailsList?.some(e => e.domainCode === 'ICF');
  }, [state?.encounterDetailsList]);

  const checkInDateTime = useMemo(() => {
    return encounterDetails?.metaInfo?.checkInDateTime;
  }, [encounterDetails?.metaInfo?.checkInDateTime]);

  useEffect(
    function() {
      if (ssuId && checkInDateTime && isIcfItemGroupInEncounter && route.params.patientItemGroupId) {
        InformedConsentApi.getNewAndCompletedForms(ssuId, ssuPatientId).then(({ data }) => {
          const completedIds = data.completedIcf
            ?.filter(e => e.informedConsentFormId)
            .map(e => e.informedConsentFormId);

          setCompletedIcfForms(data.completedIcf);
          setNewIcfForms(data.newIcf?.filter(e => !completedIds.includes(e.id)));
          InformedConsentApi.getFormsBySsuId(ssuId).then(({ data }) => {
            setAllIcfForms(data.forms);
          });
        });
      }
    },
    [ssuPatientId, ssuId, route.params.patientItemGroupId, route.name, checkInDateTime, isIcfItemGroupInEncounter]
  );

  return (
    <InformedConsentContext.Provider
      value={{
        allIcfForms,
        newIcfForms,
        completedIcfForms
      }}
      children={children}
    />
  );
}
