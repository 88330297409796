import React from 'react';
import { last } from 'lodash/array';
import { isEqual } from 'lodash/lang';

import Button from '../../../../../../../../common/general/Button';
import Icon from '../../../../../../../../common/general/Icon';
import TaskAssigneesSelect from '../../TaskAssigneesSelect/TaskAssigneesSelect';
import useFilteredAssignees from '../../useFilteredAssignees';

import './TaskAssignees.scss';

export default function TaskAssignees({
  allAssignees,
  assignees,
  onChange,
  access,
  matchedSsuIds,
  validationMessage,
  validate
}) {
  const filteredAssignees = useFilteredAssignees(allAssignees, access, matchedSsuIds);

  const lastAssignee = last(assignees);

  return (
    <div className="etc-task-assignees">
      {assignees.map(function(assignee, currentIndex) {
        return (
          <TaskAssigneesSelect
            filteredAssignees={filteredAssignees}
            assignees={assignees}
            onClear={function() {
              if (assignees.length < 2 && currentIndex === 0) {
                return;
              }
              const safeAssignees = [...assignees];
              safeAssignees.splice(currentIndex, 1);
              onChange(safeAssignees);
            }}
            onChange={function(selectedAssignee) {
              if (isEqual(assignees[currentIndex], selectedAssignee)) return;

              onChange([
                ...assignees.map(function(assignee, index) {
                  if (index === currentIndex) {
                    return selectedAssignee;
                  }
                  return assignee;
                })
              ]);
            }}
            access={access}
            matchedSsuIds={matchedSsuIds}
            required={currentIndex === 0}
            validationMessage={currentIndex === 0 ? validationMessage : ''}
            validate={validate}
            assignee={assignee}
            key={+new Date() + '_' + currentIndex}
          />
        );
      })}
      {lastAssignee && assignees.length < 5 && (
        <div className="etc-task-assignees-add-btn-wrapper">
          <Button onClick={() => onChange([...assignees, null])} priority="low" size="h28">
            <Icon>add</Icon> Add Assignee
          </Button>
        </div>
      )}
    </div>
  );
}
