import React from 'react';

import useAppInfo from '../../../common/hooks/useAppInfo';
import { useCurrentUser } from '../../root/Container/CurrentUserContainer';

import CalendarInvitationsPreferences from './CalendarInvitationsPreferences';
import GlobalEnvironmentSettingPreferences from './GlobalEnvironmentSettingPreferences';
import HomePageDashboardPreferences from './HomePageDashboardPreferences';
import NotificationsPreferences from './NotificationsPreferences';

import './Preferences.scss';

export function Preferences() {
  const currentUser = useCurrentUser();
  const appInfo = useAppInfo();
  return (
    <div className="eui-preferences">
      <CalendarInvitationsPreferences currentUser={currentUser} />
      <NotificationsPreferences appInfo={appInfo} currentUser={currentUser} />
      <HomePageDashboardPreferences appInfo={appInfo} currentUser={currentUser} />
      <GlobalEnvironmentSettingPreferences appInfo={appInfo} />
    </div>
  );
}

export default Preferences;
