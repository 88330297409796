import React from 'react';
import { isEmpty, isString } from 'lodash/lang';

import './FvErrorHandler.scss';
export function FvErrorHandler({ error, children }) {
  if (!error) {
    return children;
  }
  return <div className="eds-fv-error">{getErrorMessage(error)}</div>;
}

function getErrorMessage({ statusCode, customMessage }) {
  if (isString(customMessage) && !isEmpty(customMessage)) {
    return customMessage;
  }
  switch (statusCode) {
    case 404:
      return 'Download Missing';
    case 409:
      return 'Download has not yet cleared virus scan. Please try again later';
    case 502:
      return 'Virus detected in download';
    default:
      return 'Something went wrong. Please try again later';
  }
}
