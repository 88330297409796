import React from 'react';
import cx from 'classnames';

import './OptionalItemGroupsSetupTableCell.scss';

export default function OptionalItemGroupsSetupTableCell({ cellKey, configurationMap, toggleExclude, editDeny }) {
  if (!configurationMap[cellKey]) return null;

  const { loading, exclude } = configurationMap[cellKey];

  return (
    <div
      className={cx('optional-item-groups-setup-table-cell', {
        'optional-item-groups-setup-table-cell-exclude': !loading && exclude,
        'optional-item-groups-setup-table-cell-loading': loading,
        'optional-item-groups-setup-table-cell-not-editable': editDeny
      })}
      onClick={handleClick}
    >
      {loading && (
        <div className="spinner-border text-success" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </div>
  );

  function handleClick() {
    if (loading || editDeny) return;
    toggleExclude(cellKey);
  }
}
