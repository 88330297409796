import React from 'react';
import * as PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';

import Icon from '../../common/general/Icon';

import './AccessDeniedTooltip.scss';

function AccessDeniedTooltip({ children, placement, showContentOnly, align, text }) {
  return showContentOnly ? (
    children
  ) : (
    <Tooltip
      destroyTooltipOnHide={true}
      overlayClassName="eds-rc-tooltip eds-access-denied-tooltip"
      placement={placement}
      align={align}
      mouseEnterDelay={0}
      mouseLeaveDelay={0}
      overlay={
        <div className="overlay-container">
          <Icon>block</Icon>
          <span className="mx-2">{text}</span>
        </div>
      }
    >
      {children && React.cloneElement(children, { style: { cursor: 'not-allowed' } })}
    </Tooltip>
  );
}

export default AccessDeniedTooltip;

AccessDeniedTooltip.defaultProps = {
  showContentOnly: false,
  placement: 'bottom',
  text: 'Access is Denied',
  align: { offset: [0, 0] }
};

AccessDeniedTooltip.propTypes = {
  showContentOnly: PropTypes.bool,
  placement: PropTypes.oneOf(['right', 'left', 'top', 'bottom']),
  align: PropTypes.object,
  text: PropTypes.string
};
