import { isEmpty } from 'lodash';
import { uniqBy } from 'lodash/array';
import { orderBy } from 'lodash/collection';

export function getStudiesFrom(ssus) {
  return orderBy(
    uniqBy(
      ssus.map(ssu => ssu.study),
      e => e.id
    ),
    e => e.name
  );
}

export function getSitesFrom(ssus) {
  return orderBy(
    uniqBy(
      ssus.map(ssu => ssu.site),
      e => e.id
    ),
    e => e.name
  );
}

export function getEpochsFrom(data) {
  return orderBy(
    uniqBy(data.map(e => e.epochName))
      .filter(e => e)
      .map((name, id) => ({ id: name, name })),
    e => e.name
  );
}
export function getEncountersFrom(data) {
  return orderBy(
    uniqBy(data.map(e => e.encounterName))
      .filter(e => e)
      .map((name, id) => ({ id: name, name })),
    e => e.name
  );
}

export function getIds(list) {
  return list.map(e => e.id);
}

export function getNames(list) {
  return list.map(e => e.name);
}

export const getLabelFromService = (type, selectedStudies, selectedSites, selectedEpochs, selectedEncounters) => {
  switch (type) {
    case 'Study':
      return (
        <>
          <div>Study </div>({selectedStudies.length})
        </>
      );
    case 'Site':
      return (
        <>
          <div>Site </div>({selectedSites.length})
        </>
      );
    case 'Epoch':
      return (
        <>
          <div>Epoch </div>({selectedEpochs.length})
        </>
      );
    case 'Encounter':
      return (
        <>
          <div>Encounter </div>({selectedEncounters.length})
        </>
      );
    default:
      return '';
  }
};

export function getValidationString(filter) {
  const errors = [];

  isEmpty(filter.selectedStudies) && errors.push('Study');
  isEmpty(filter.selectedSites) && errors.push('Site');
  isEmpty(filter.selectedEpochs) && errors.push('Epoch');
  isEmpty(filter.selectedEncounters) && errors.push('Encounter');

  return `Please select at least one ${errors.join('/')}`;
}
