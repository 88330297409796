import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { first } from 'lodash/array';
import moment from 'moment';

import DatePicker from '../../../../../../../../common/data-entry/DatePicker';
import TextArea from '../../../../../../../../common/data-entry/TextArea';
import Button from '../../../../../../../../common/general/Button';
import Icon from '../../../../../../../../common/general/Icon';
import { OTHER } from '../../taskConstants';
import { getTopicNameValidationMessage } from '../../taskServices';

function TaskDetailsDescription({ taskForm, setDueDate, setDescription, setTopicTitle, taskFormValidationMap }) {
  const { sequenceNumber, dueDate, description, taskLinks, topicTitle, topic } = taskForm;
  const [editModeDescription, setEditModeDescription] = useState(false);
  const [editModeTopicName, setEditModeTopicName] = useState(false);

  const link = first(taskLinks)?.link || '/';

  return (
    <div className="etc-body-details">
      <div className="etc-body-details-block">
        <div className="etc-body-details-block-label">Task ID</div>
        <div className="etc-body-details-block-value">
          <NavLink className="task-link" to={link}>
            <Icon>link</Icon>
            {sequenceNumber}
          </NavLink>
        </div>
      </div>
      {topic === OTHER && (
        <div className="etc-body-details-block">
          <div className="etc-body-details-block-label">
            Topic Name
            <Button onClick={() => setEditModeTopicName(!editModeTopicName)} priority="medium" size="h28">
              <Icon>edit</Icon> Edit
            </Button>
          </div>
          <div className="etc-body-details-block-value">
            {editModeTopicName && (
              <TextArea
                id="topic-input"
                name="topic"
                value={topicTitle}
                onChange={setTopicTitle}
                showCount
                validationMessage={getTopicNameValidationMessage(topicTitle, taskFormValidationMap.topicTitle)}
              />
            )}
            {!editModeTopicName && <div>{topicTitle}</div>}
          </div>
        </div>
      )}
      <div className="etc-body-details-block">
        <div className="etc-body-details-block-label">Due Date</div>
        <div className="etc-body-details-block-value">
          <DatePicker
            value={dueDate}
            legacyLook
            onChange={setDueDate}
            isValidDate={current => {
              return current.isAfter(moment(dueDate, 'DD/MMM/YYYY').subtract(1, 'day'));
            }}
          />
        </div>
      </div>
      <div className="etc-body-details-block">
        <div className="etc-body-details-block-label">
          Description
          <Button onClick={() => setEditModeDescription(!editModeDescription)} priority="medium" size="h28">
            <Icon>edit</Icon> Edit
          </Button>
        </div>
        <div className="etc-body-details-block-value">
          {editModeDescription && (
            <TextArea
              id="description-input"
              name="description"
              value={description}
              onChange={setDescription}
              maxLength="2000"
              showCount
            />
          )}
          {!editModeDescription && <div>{description}</div>}
        </div>
      </div>
    </div>
  );
}
export default TaskDetailsDescription;
