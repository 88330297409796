import axios from 'axios';

const ROOT = 'api/v1/finance/study-sites';

const StudySiteEnrollmentPredictionApi = {
  findEnrollmentPredictionsBySelectedFilterProperties(enrollmentPredictionFilterProperties, date) {
    return axios.post(`${ROOT}/enrollment-predictions/${date}`, enrollmentPredictionFilterProperties);
  },
  export(ssuAndTherapeuticAreaRequest, date) {
    return axios.post(`${ROOT}/enrollment-predictions/${date}/export`, ssuAndTherapeuticAreaRequest, {
      responseType: 'arraybuffer'
    });
  }
};

export default StudySiteEnrollmentPredictionApi;
