import './EncountersTabLoader.scss';
export default function EncountersTabLoader() {
  return [1, 2].map(e => (
    <div key={e} className="encounters-tab-loader">
      <div className="encounters-tab-loader-1">
        <div className="encounters-tab-loader-1-1 encounters-tab-loader-gradient" />
        <div className="encounters-tab-loader-1-2 encounters-tab-loader-gradient" />
      </div>
      <div className="encounters-tab-loader-2">
        <div className="encounters-tab-loader-2-1">
          <div className="encounters-tab-loader-2-1-1 encounters-tab-loader-gradient" />
          <div className="encounters-tab-loader-2-1-2 encounters-tab-loader-gradient" />
        </div>
        <div className="encounters-tab-loader-2-2 encounters-tab-loader-gradient" />
        <div className="encounters-tab-loader-2-3 encounters-tab-loader-gradient" />
        <div className="encounters-tab-loader-2-4 encounters-tab-loader-gradient" />
      </div>
    </div>
  ));
}
