import React from 'react';

import Button from '../../../../../../../../common/general/Button';
import Icon from '../../../../../../../../common/general/Icon';
import { getPermissionOptionsByGroupType } from '../../permissionType';

import './GroupsSetupRowTitleCell.scss';

export default function GroupsSetupRowTitleCell({
  itemGroup,
  changeAccessForAllItemGroupsConfiguration,
  selectedGroupType
}) {
  const { elementName, itemGroupKey } = itemGroup;
  return (
    <>
      <div className="groups-setup-row-title-cell-sidebar-item-group-menu">
        <Button size="h28" priority="low">
          <Icon>more_vert</Icon>
        </Button>
        <div className="groups-setup-row-title-cell-sidebar-item-group-menu-items">
          {getPermissionOptionsByGroupType(selectedGroupType).map(function({ key, status, icon }) {
            return (
              <div
                key={key}
                onClick={() => changeAccessForAllItemGroupsConfiguration(itemGroupKey, key)}
                className="groups-setup-row-title-cell-sidebar-item-group-menu-item"
              >
                <Icon>{icon}</Icon>
                {status}
              </div>
            );
          })}
        </div>
      </div>
      <div title={elementName} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {elementName}
      </div>
    </>
  );
}
