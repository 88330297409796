import React, { Component } from 'react';

import { StudyProtocolApi } from '../../../../../api';
import Select from '../../../../../common/data-entry/Select';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';

class CopyProtocol extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStudy: null,
      studiesWithoutProtocol: []
    };
  }

  componentDidMount() {
    StudyProtocolApi.findAllWithoutProtocol().then(({ data: studiesWithoutProtocol }) => {
      this.setState({ studiesWithoutProtocol });
    });
  }

  handleStudyChange = copyToStudy => {
    this.setState({ selectedStudy: copyToStudy });
    this.props.handleStudyChange(copyToStudy);
  };

  render() {
    const { selectedStudy, studiesWithoutProtocol } = this.state;
    const { copyProtocol, fromStudy, protocolId } = this.props;

    return (
      <>
        <ModalBoxes.Header>Creating copy of protocol</ModalBoxes.Header>
        <ModalBoxes.Body>
          <Select
            label="Destination study"
            clearSearchOnSelection
            dataSource={studiesWithoutProtocol}
            optionLabelKey="studyName"
            optionValueKey="uniqueIdentifier"
            onChange={this.handleStudyChange}
            searchable
            clearable={false}
            required
          />
        </ModalBoxes.Body>
        <ModalBoxes.Footer>
          <div className="eds-btn-group">
            <ButtonGroup>
              <Button priority="medium" onClick={this.props.modalBox.close}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  copyProtocol(fromStudy, protocolId);
                  this.props.modalBox.close();
                }}
                disabled={!selectedStudy}
              >
                Save
              </Button>
            </ButtonGroup>
          </div>
        </ModalBoxes.Footer>
      </>
    );
  }
}

CopyProtocol.className = 'copy-protocol-modal';
CopyProtocol.size = 'w350';

export default CopyProtocol;
