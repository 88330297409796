import { useLayoutEffect, useState } from 'react';
import { isEmpty } from 'lodash/lang';

export default function useScrollspy(ids, element) {
  const [activeId, setActiveId] = useState(null);

  useLayoutEffect(() => {
    if (isEmpty(ids) || isEmpty(element)) return;

    listener();

    window.addEventListener('resize', listener);
    element.addEventListener('scroll', listener);

    return function() {
      window.removeEventListener('resize', listener);
      element.removeEventListener('scroll', listener);
    };

    function listener() {
      const containerRect = element.getBoundingClientRect();
      const scroll = element.scrollTop;
      const position = ids
        .map(id => {
          const element = document.getElementById(id);

          if (!element) return { id, top: -1, bottom: -1 };

          const rect = element.getBoundingClientRect();
          const top = clamp(rect.top - containerRect.top - 7 + scroll);
          const bottom = clamp(rect.bottom - containerRect.top + 7 + scroll);

          return { id, top, bottom };
        })
        .find(({ top, bottom }) => isBetween(scroll + 50, top, bottom));

      setActiveId(position?.id || null);
    }
  }, [ids, element]);

  return activeId;
}

function clamp(value) {
  return Math.max(0, value);
}

function isBetween(value, floor, ceil) {
  return value >= floor && value <= ceil;
}
