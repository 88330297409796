import { isArray } from 'lodash';
import { isEmpty } from 'lodash/lang';
import moment from 'moment-timezone';

import { TasksTypesMap } from '../../../root/Container/Layout/Tasks/Task/taskConstants';
import { getCurrentTimeZone, getTimeZoneName } from '../CalendarTimeZoneService';
import { ColorsRegistry } from '../ColorHashPalette';
import { toCalendarAllDayEvent, toCalendarEvent } from '../EventTransformer';

const calendarEventTypes = ['USER', 'STUDY', 'SITE'];

export function prepareTimeRange(timeRange) {
  if (timeRange) {
    timeRange.dateRanges.forEach(range => {
      range.startDate = moment(range.startDate).startOf('day');
      range.endDate = moment(range.endDate).endOf('day');
    });
    return timeRange;
  }
}

export function prepareEvents(calendars) {
  const events = [];
  if (isArray(calendars)) {
    calendars.forEach(calendar => {
      if (calendar.show === false) {
        return;
      }
      calendarEventTypes.includes(calendar.type) &&
        calendar.events.forEach(event => {
          event.calendarId = calendar.id;
          events.push(toCalendarEvent(event));
        });
      calendar.milestones.forEach((milestone, index) => {
        milestone.calendarId = calendar.id;
        events.push(toCalendarAllDayEvent(milestone));
      });
      if (!isEmpty(calendar.tasks)) {
        calendar.tasks.forEach((event, index) => {
          event.calendarId = calendar.id;
          event.subject = `${TasksTypesMap[event.type]} ${event.sequenceNumber}`;
          event.id = `${event.taskId}_${calendar.id}`;
          events.push(toCalendarAllDayEvent(event, index));
        });
      }
    });
  }
  events.forEach(event => {
    event.color = ColorsRegistry().getColorById(event.calendarId);
  });
  return events;
}

export function prepareTimeZones(timeZones) {
  const updatedTimeZones = timeZones
    ?.filter(site => site.timeZoneIdValid)
    ?.map(el => {
      return {
        ...el,
        currentTime: moment()
          .tz(el.timeZoneId)
          .format('h:mm A'),
        timeZoneNameToDisplay: getTimeZoneName(el.timeZoneId)
      };
    });
  return [getCurrentTimeZone(), ...updatedTimeZones];
}
