import React from 'react';

import Icon from '../../../../../../../../common/general/Icon';
import { getIcon } from '../../../reviewService';

import './ItemGroupElementHeaderWithIcon.scss';

export function ItemGroupElementHeaderWithIcon({ itemGroupType, content, accessDenied }) {
  return (
    <div className="erl-item-group-label">
      <Icon suit="material-outline">{getIcon(itemGroupType)}</Icon>
      <div>{content} </div>
      {accessDenied && <p>(Access denied)</p>}
    </div>
  );
}
