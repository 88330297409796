import React, { Component } from 'react';

import BreadcrumbNavigation from './BreadcrumbNavigation/BreadcrumbNavigation';
import Footer from './Footer/Footer';
import MenuOptions from './MenuOptions/MenuOptions';

import './Layout.scss';

class Layout extends Component {
  render() {
    return (
      <div className="page-inner-container">
        <div className="page-content">
          <MenuOptions />
          <BreadcrumbNavigation />
          {this.props.children}
          <Footer />
        </div>
      </div>
    );
  }
}

export default Layout;
