import React, { useContext } from 'react';
import cx from 'classnames';
import { isEqual } from 'lodash/lang';

import Icon from '../../../../../../../common/general/Icon';
import { AddLedgerContext } from '../../AddLedgerContext';

import './AddLedgerStepsBar.scss';
export function AddLedgerStepsBar() {
  const { stepsList, currentStep } = useContext(AddLedgerContext);

  function beforePointer(index, currentStep) {
    if (index >= currentStep) {
      return <span className="adsb-before-pointer">{index + 1}</span>;
    } else {
      return <Icon className="adsb-before-pointer adsb-icon-done">done</Icon>;
    }
  }

  return (
    <div className="add-ledger-steps-bar">
      <p>Add Missing Ledger</p>
      <ul>
        {stepsList.map((step, index, arr) => {
          const currentIndex = arr.findIndex(step => step === currentStep);
          return (
            <li key={step} className={cx({ 'active-step': isEqual(index, currentIndex) })}>
              {beforePointer(index, currentIndex)}
              <span className="adsb-step">{step}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
