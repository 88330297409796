import './ItemGroupsEncounterTableSkeleton.scss';
export default function ItemGroupsEncounterTableSkeleton() {
  return (
    <div className="item-groups-encounter-table-skeleton">
      <div className="item-groups-encounter-table-skeleton-legend">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-epoch-name">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-encounter-1">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-encounter-2">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-encounter-3">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-epoch-name-middle">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-encounter-5">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-encounter-6">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-epoch-name-large">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-encounter-7">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-encounter-8">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-encounter-9">
        <div />
      </div>

      <div className="item-groups-encounter-table-skeleton-epoch-name-1">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-encounter-1-1">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-encounter-1-2">
        <div />
      </div>

      <div className="item-groups-encounter-table-skeleton-epoch-name-2">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-encounter-2-1">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-encounter-2-2">
        <div />
      </div>

      <div className="item-groups-encounter-table-skeleton-epoch-name-3">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-encounter-3-1">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-encounter-3-2">
        <div />
      </div>

      <div className="item-groups-encounter-table-skeleton-item-group-3">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-item-group-4">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-item-group-5">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-item-group-6">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-item-group-7">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-item-group-8">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-item-group-9">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-item-group-10">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-item-group-11">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-item-group-12">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-item-group-13">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-item-group-14">
        <div />
      </div>
      <div className="item-groups-encounter-table-skeleton-item-group-15">
        <div />
      </div>

      {new Array(182).fill('').map((v, i) => (
        <div className="item-groups-encounter-table-skeleton-cell" key={i}>
          <div />
        </div>
      ))}
    </div>
  );
}
