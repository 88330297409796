import React, { useContext, useMemo } from 'react';
import cx from 'classnames';
import { last } from 'lodash/array';
import { reject } from 'lodash/collection';

import {
  CANCELED_SPE_STATUS,
  COMPLETE_SPE_STATUS,
  NON_PROTOCOL,
  PROJECTED,
  UNEXPECTED
} from '../../../../../../../../../constants/encounterConstants';
import { CANCELED, CLOSED } from '../../../../../../../../../constants/ssuStatuses';
import {
  MANAGE_PRE_SCREEN_WORK_LIST,
  MANAGE_PROTOCOL_ENCOUNTER_DATA
} from '../../../../../../../../../constants/userOperations';
import { ROLE_SYSTEM_ADMINISTRATOR } from '../../../../../../../../../constants/userRoles';
import { userHasAccessTo, userHasRole } from '../../../../../../../../../services/auth';
import { PatientInfoContext } from '../../../../PatientInfoContext';
import { useEncountersSection } from '../../../EncountersSectionContext';

import useEncounterSegmentTemplate from './templates/useEncounterSegmentTemplate';

import './EncounterSegment.scss';

const EncounterSegmentContext = React.createContext(null);

function EncounterSegment({ encounter, updatePatientEncountersWithAppointments }) {
  const { sitePatientEncounter, encounterType, epochName, encounterEvents, noAppointments } = encounter;
  const { numberOfCompletedRequiredFields, encounterStatus } = sitePatientEncounter || {};
  const { currentStudy } = useContext(PatientInfoContext);
  const studySiteStatus = currentStudy?.studySiteStatus;
  const isNonProtocol = encounterType === NON_PROTOCOL;
  const { presentEpochs } = useEncountersSection();

  const Template = useEncounterSegmentTemplate(encounter);

  const isManageEncountersAllowed = useMemo(() => {
    if (userHasRole(ROLE_SYSTEM_ADMINISTRATOR)) {
      return true;
    }
    if (sitePatientEncounter) {
      if (isNonProtocol) {
        return ![CLOSED, CANCELED].includes(studySiteStatus) && userHasAccessTo(MANAGE_PRE_SCREEN_WORK_LIST);
      }
      return studySiteStatus && studySiteStatus !== CLOSED && userHasAccessTo(MANAGE_PROTOCOL_ENCOUNTER_DATA);
    }

    return false;
  }, [sitePatientEncounter, isNonProtocol, studySiteStatus]);

  const lastNotCancelledEncounterEvent = useMemo(
    function() {
      return { ...last(reject(encounterEvents, 'cancelled')) };
    },
    [encounterEvents]
  );

  const lastEncounterEvent = useMemo(
    function() {
      return { ...last(encounterEvents) };
    },
    [encounterEvents]
  );

  const cancelled =
    encounter.sitePatientEncounter || encounter.projectedEncounterInfo
      ? encounterStatus === CANCELED_SPE_STATUS
      : encounter.encounterEvents?.every(event => event.cancelled);

  return (
    <EncounterSegmentContext.Provider
      value={{
        encounter,
        updatePatientEncountersWithAppointments,
        isManageEncountersAllowed,
        lastNotCancelledEncounterEvent,
        lastEncounterEvent
      }}
    >
      <div
        className={cx(
          'encounter-segment',
          `encounter-segment-${getEncounterTypeClassNameSuffix(encounterType, sitePatientEncounter, noAppointments)}`,
          {
            'encounter-segment-canceled': cancelled
          }
        )}
        style={getEncounterStatusStyles(numberOfCompletedRequiredFields, encounterStatus, encounterType)}
      >
        <div className="encounter-segment-epoch-information">
          <div className="encounter-segment-epoch-title">{presentEpochs && `Epoch: ${epochName}`}</div>
          <Template.Header />
        </div>
        <div className="encounter-segment-card-container">
          <Template.CardHeaderWrapper>
            <div className="encounter-segment-card-header">
              <Template.CardHeaderLeft />
              <Template.CardHeaderMiddle />
              <Template.CardHeaderRight />
            </div>
          </Template.CardHeaderWrapper>
          <Template.CardMiddleSection />
          <Template.CardSectionLast />
        </div>
      </div>
    </EncounterSegmentContext.Provider>
  );
}

export default React.memo(EncounterSegment);

export function useEncounterSegment() {
  const context = useContext(EncounterSegmentContext);
  if (context === undefined) {
    throw new Error('useEncounterSegment must be used within a EncounterSegmentContext.Provider');
  }
  return context;
}

function getEncounterTypeClassNameSuffix(encounterType, sitePatientEncounter, noAppointments) {
  if (encounterType === UNEXPECTED && !sitePatientEncounter) {
    return 'unexpected-appointment';
  }
  if (encounterType !== PROJECTED && noAppointments) {
    return 'no-appointments';
  }
  return encounterType.toLowerCase().replaceAll('_', '-');
}

function getEncounterStatusStyles(numberOfCompletedRequiredFields, encounterStatus, encounterType) {
  if (encounterType === PROJECTED) {
    return { '--encounter-status': '#691E44' };
  }
  if (encounterStatus === CANCELED_SPE_STATUS) {
    return { '--encounter-status': 'gray' };
  }
  if (!numberOfCompletedRequiredFields && numberOfCompletedRequiredFields !== 0) {
    return { '--encounter-status': '#691E44' };
  }
  if (encounterStatus !== COMPLETE_SPE_STATUS) {
    return { '--encounter-status': '#FFC738' };
  }
  return { '--encounter-status': '#27AE60' };
}
