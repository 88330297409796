import { isArray } from 'lodash/lang';
import { get } from 'lodash/object';
import moment from 'moment';

import { SitePatientEncounterApi, StudyApi, UserApiApi } from '../../../../../api';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { EDC_VISIT_DATE_SAVED, PATIENT_LOCKED } from '../../../../../constants/notificationMessages';
import { onRequestDefaultError } from '../../../../../services/handlers';
import { prepareEncounterStudyManagers } from '../EncounterDescNew/encounterDetailsService';
import { getIcon } from '../Review/reviewService';

export function getRegularEncounterDetails(patientEncounterId, ssuPatientId) {
  return SitePatientEncounterApi.getSsuPatientEncounterMetaInfo(ssuPatientId, patientEncounterId).then(res => res.data);
}

export function loadEncounterDetailsBoxes(ssuPatientEncounterId, ssuPatientId) {
  return StudyApi.getPatientEncounters(ssuPatientId, ssuPatientEncounterId).then(res => {
    return {
      currentPatient: get(res, 'data.userInfoResponse'),
      encounterDetailsList: get(res, 'data.response')
    };
  });
}

export function getListOfStudyManagers(ssuPatientId) {
  return UserApiApi.getListOfStudyManagers(ssuPatientId).then(({ data: { response: activeUsers } }) => {
    if (isArray(activeUsers)) {
      return prepareEncounterStudyManagers(activeUsers);
    }
  }, onRequestDefaultError);
}

export function saveEdcVisitDate(ssuPatientId, patientEncounterId, selectedEdcVisitDate) {
  return SitePatientEncounterApi.updateEdcVisitDate(ssuPatientId, patientEncounterId, {
    edcVisitDate: moment(selectedEdcVisitDate).format('YYYY-MM-DD')
  })
    .then(({ data }) => {
      NotificationManager.success(EDC_VISIT_DATE_SAVED);
      return Promise.resolve(data);
    })
    .catch(() => {
      NotificationManager.error(PATIENT_LOCKED);
    });
}

export function getRouteKey(key, itemGroup) {
  const { domainCode, elementType } = itemGroup;

  key = key.replace(
    new RegExp('.Investigator Review|.SM Review|.Logs|.Logs.Row|.Row|.Procedure|.Table|.Informed Consent', 'g'),
    ''
  );

  if (domainCode === 'EREVPI') {
    return `${key}.Investigator Review`;
  }
  if (domainCode === 'EREVSM') {
    return `${key}.SM Review`;
  }
  if (elementType === 'Logs' && domainCode !== 'CO') {
    return `${key}.Logs`;
  }
  if (elementType === 'LogsRow') {
    return `${key}.Logs.Row`;
  }
  if (elementType === 'New Table') {
    return `${key}.Table`;
  }
  if (domainCode === 'CO') {
    return `${key}.Procedure`;
  }
  if (domainCode === 'ICF') {
    return `${key}.Informed Consent`;
  }
  return `${key}.Procedure`;
}

export function getItemGroupIcon(item) {
  if (item.domainCode === 'CO') {
    return 'forum';
  }

  return getIcon(item.elementType);
}
