export const MAX_INPUT_VALUE = 2147483647;

export const CONSENT_AT_PSV = 'consentAtPsv';
export const ENROLLMENT_AT_PSV = 'enrollmentAtPsv';
export const COMPLETION_AT_PSV = 'completionAtPsv';
export const CONSENT_AT_SIV = 'consentAtSiv';
export const ENROLLMENT_AT_SIV = 'enrollmentAtSiv';
export const COMPLETION_AT_SIV = 'completionAtSiv';

export const METRICS_NAMES = {
  [CONSENT_AT_PSV]: 'Consent at PSV',
  [ENROLLMENT_AT_PSV]: 'Enrollment at PSV',
  [COMPLETION_AT_PSV]: 'Completion at PSV',
  [CONSENT_AT_SIV]: 'Consent at SIV',
  [ENROLLMENT_AT_SIV]: 'Enrollment at SIV',
  [COMPLETION_AT_SIV]: 'Completion at SIV'
};

export const METRICS_VALUES_LIST = [
  CONSENT_AT_PSV,
  ENROLLMENT_AT_PSV,
  COMPLETION_AT_PSV,
  CONSENT_AT_SIV,
  ENROLLMENT_AT_SIV,
  COMPLETION_AT_SIV
];

export const findCorrespondingLockNameForMetric = metric => {
  return metric.endsWith('Locked') ? metric : `${metric}Locked`;
};
