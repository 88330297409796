import axios from 'axios';

const ROOT = 'api/v1/finance/ledger-events';

const FinLedgerEventApi = {
  calculatePendingElligoEvents() {
    return axios.post(`${ROOT}/process-pending-elligo-events`);
  },
  createInvoice(data) {
    return axios.post(`${ROOT}/client/invoice`, data);
  },
  getCreatedInvoice(invoiceNumber, invoiceType) {
    return axios.get(`${ROOT}/client/invoice/${invoiceNumber}/type/${invoiceType}`);
  },
  generateInvoiceNumber() {
    return axios.post(`${ROOT}/client/invoice-number`);
  },
  inactivateExpenseEvent(eventId) {
    return axios.post(`${ROOT}/${eventId}/inactivate`);
  },
  getLedgerEventsByFilter(filterProperty) {
    return axios.post(`${ROOT}/search`, filterProperty);
  }
};

export default FinLedgerEventApi;
