import axios from 'axios';

const ROOT = 'api/v1/visit';

const EncounterApi = {
  getDynamicEncounterForm(parameters) {
    return axios.get(
      `${ROOT}/${parameters.ssuPatientId}/element/${parameters.uniqueIdentifier}/form/${parameters.elementIdentifier}`
    );
  },
  getEncountersBySSUList(request) {
    return axios.post(`${ROOT}/encounters/by-ssu`, request);
  },
  getUnexpectedEncounters(studyId, patSiteIdentity, ssuPatientEncounterId) {
    return axios.get(`${ROOT}/studies/${studyId}/patients/${patSiteIdentity}/encounters/unexpected`, {
      params: {
        ssuPatientEncounterId
      }
    });
  },
  getStudyRelatedEpochs(studyId, patSiteIdentity) {
    return axios.get(`${ROOT}/studies/${studyId}/patients/${patSiteIdentity}/encounters`);
  },
  getEncounterBySsuPatientId(ssuPatientId) {
    return axios.get(`${ROOT}/encounters/ssu-patient/${ssuPatientId}`);
  },
  getStudyNoAppointmentsEncounters(studyId, patSiteIdentity) {
    return axios.get(`${ROOT}/studies/${studyId}/patients/${patSiteIdentity}/encounters/no-appointments`);
  },
  getAllSituationalEncountersForActiveProtocol(studyId, ssuPatientId) {
    return axios.get(`${ROOT}/studies/${studyId}/ssu-patients/${ssuPatientId}/situational-encounters`);
  }
};

export default EncounterApi;
