import React from 'react';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import { NonProtocolEncounterApi, PreScreenApi } from '../../../../api';

import { generateLink } from './worklistService';
export function StartEncounterButton({
  sitePatientId,
  disabled,
  encounterIdentifier,
  elementIdentifier,
  children,
  state
}) {
  const navigate = useNavigate();
  const encounterDetails = {
    protocolEncounterId: encounterIdentifier
  };

  function startEncounter() {
    NonProtocolEncounterApi.createNonProtocolEncounter(sitePatientId, encounterDetails).then(
      ({ data: ssuPatientEncounterId }) => {
        PreScreenApi.getLinkConstructor(sitePatientId, ssuPatientEncounterId, elementIdentifier).then(({ data }) =>
          navigate(generateLink(data))
        );
      }
    );
  }
  if (!disabled) {
    return (
      <span onClick={startEncounter} className={cx('pointer', { 'add-btn': state === 'TO_DO' })}>
        {children}
      </span>
    );
  } else {
    return <span className={cx('pointer', { 'add-btn': state === 'TO_DO' })}>{children}</span>;
  }
}

export default StartEncounterButton;
