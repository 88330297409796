import React, { useEffect, useRef, useState } from 'react';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Badge, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import moment from 'moment';

import ModalBoxes from '../../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import {
  DD_SLASH_MMM_SLASH_YYYY,
  DD_SLASH_MMM_SLASH_YYYY_HH_COLON_MM
} from '../../../../../../../../../constants/dateFormat';
import { CellFormattedDate } from '../../../../../../../../CellFormattedDate/CellFormattedDate';
import ViewProgressNoteModal from '../../ViewProgressNoteModal/ViewProgressNoteModal';

import './NotesTable.scss';

export default function NotesTable({ progressNotes, editProgressNote, progressNote, isAllowedToManage, ssuPatientId }) {
  const tableContainerRef = useRef(null);
  const [expandedRows, setExpandedRows] = useState({});

  const selectedProgressNoteId = progressNote?.id;

  useEffect(
    function() {
      if (isEmpty(selectedProgressNoteId) || isEmpty(tableContainerRef.current)) {
        return;
      }
      tableContainerRef.current.querySelector(`[data-id="${selectedProgressNoteId}"]`).scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    },
    [selectedProgressNoteId, tableContainerRef]
  );
  return (
    <TableContainer className="notes-table" ref={tableContainerRef}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className="apn-drawer" />
            <TableCell className="apn-date">Date</TableCell>
            <TableCell className="apn-type">Type</TableCell>
            <TableCell className="apn-note">Note</TableCell>
            <TableCell className="apn-edit-date">Last Updated By</TableCell>
            <TableCell className="apn-version" />
            <TableCell className="apn-editor">Last Edited</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {progressNotes.map(progressNote => {
            const {
              id,
              noteCreatedDate,
              lastUpdatedByName,
              note,
              lastUpdateDate,
              progressNoteType,
              previousNoteVersions
            } = progressNote;
            const isRowExpanded = !!expandedRows[id];
            return (
              <React.Fragment key={+moment(noteCreatedDate)}>
                <TableRow
                  selected={selectedProgressNoteId === id}
                  className={cx('current-version', {
                    'expanded-row': isRowExpanded
                  })}
                  onClick={e => onRowClick(e, progressNote)}
                  data-id={id}
                >
                  <TableCell className="apn-drawer">
                    {!isEmpty(previousNoteVersions) && (
                      <IconButton onClick={e => onDrawerClick(e, id)}>
                        {isRowExpanded ? (
                          <ExpandLessIcon size={'small'} fontSize={'small'} />
                        ) : (
                          <ExpandMoreIcon size={'small'} fontSize={'small'} />
                        )}
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell className="apn-date">
                    <CellFormattedDate date={noteCreatedDate} format={DD_SLASH_MMM_SLASH_YYYY} />
                  </TableCell>
                  <TableCell className="apn-type">{progressNoteType}</TableCell>
                  <TableCell className="apn-note" title={note}>
                    <div>{note}</div>
                  </TableCell>
                  <TableCell className="apn-editor">{lastUpdatedByName}</TableCell>
                  <TableCell className="apn-version">
                    <Badge color="default" badgeContent={previousNoteVersions.length + 1}>
                      <EditNoteIcon fontSize="medium" />
                    </Badge>
                  </TableCell>
                  <TableCell className="apn-edit-date">
                    <CellFormattedDate date={lastUpdateDate} format={DD_SLASH_MMM_SLASH_YYYY_HH_COLON_MM} />
                  </TableCell>
                </TableRow>
                {isRowExpanded && renderPreviousVersions(previousNoteVersions)}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
  function onDrawerClick(e, id) {
    e.stopPropagation();
    setExpandedRows(function(state) {
      return { ...state, [id]: !state[id] };
    });
  }

  function onRowClick(e, progressNote) {
    if (isAllowedToManage) {
      return editProgressNote(progressNote, ssuPatientId);
    }
    ModalBoxes.open({
      component: <ViewProgressNoteModal progressNote={progressNote} />
    });
  }
}

function renderPreviousVersions(previousNoteVersions) {
  return previousNoteVersions.map((progressNote, index) => {
    const { noteCreatedDate, lastUpdatedByName, note, lastUpdateDate, progressNoteType } = progressNote;
    const rowClass = cx(index === previousNoteVersions.length - 1 ? 'first-version' : 'previous-version');
    return (
      <TableRow key={index} className={rowClass}>
        <TableCell className="apn-drawer" />
        <TableCell className="apn-date">
          <CellFormattedDate date={noteCreatedDate} format={DD_SLASH_MMM_SLASH_YYYY} />
        </TableCell>
        <TableCell className="apn-type">{progressNoteType}</TableCell>
        <TableCell className="apn-note" title={note}>
          <div>{note}</div>
        </TableCell>
        <TableCell className="apn-editor">{lastUpdatedByName}</TableCell>
        <TableCell className="apn-version">
          <Badge color="default" badgeContent={previousNoteVersions.length - index}>
            <EditNoteIcon fontSize="medium" size="medium" color="default" />
          </Badge>
        </TableCell>
        <TableCell className="apn-edit-date">
          <CellFormattedDate date={lastUpdateDate} format={DD_SLASH_MMM_SLASH_YYYY_HH_COLON_MM} />
        </TableCell>
      </TableRow>
    );
  });
}
