import React, { useEffect, useMemo, useState } from 'react';
import { useAuth0, useBouncer } from 'bouncer';
import { uniqBy } from 'lodash/array';
import { filter, includes, sortBy } from 'lodash/collection';
import { isArray, isEmpty, isString } from 'lodash/lang';

import { StudyApi } from '../../api';
import Select from '../../common/data-entry/Select';
import ModalBoxes from '../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../common/general/Button';
import ButtonGroup from '../../common/general/ButtonGroup';
import useAppInfo from '../../common/hooks/useAppInfo';
import { ITEM_GROUP_DOMAIN } from '../../constants/itemGroupDomainConstants';
import { onRequestError } from '../../services/handlers';

import EncounterPrintMultiselect from './EncounterPrintMultiselect';

import './PrintEncountersGeneratorModal.scss';

const allowedProtocolStatusesToDisplay = ['Active', 'Draft', 'Retired'];

export default function PrintPatientEncountersGeneratorModal({ modalBox, studyIdentifier }) {
  const [idTokenKey, setIdTokenKey] = useState(null);

  const [protocols, setProtocols] = useState([]);
  const [selectedProtocol, setSelectedProtocol] = useState(null);

  const [allEncounters, setAllEncounters] = useState([]);
  const [selectedEncounters, setSelectedEncounters] = useState([]);

  const [displayedItemGroups, setDisplayedItemGroups] = useState([]);
  const [selectedItemGroups, setSelectedItemGroups] = useState([]);

  const selectedProtocolVersion = selectedProtocol?.version;

  const { jwtLocalStorageKey } = useBouncer();
  const { user } = useAuth0();
  const appInfo = useAppInfo();

  const isAllowedToGenerate = useMemo(
    function() {
      return isString(idTokenKey) && isArray(selectedItemGroups) && !isEmpty(selectedItemGroups);
    },
    [idTokenKey, selectedItemGroups]
  );

  const linkToPrintPage = useMemo(
    function() {
      if (!selectedProtocolVersion) {
        return '#';
      }
      return `/print-version-of-encounters.html?p=${selectedProtocolVersion}&i=${studyIdentifier}&k=${idTokenKey}`;
    },
    [idTokenKey, studyIdentifier, selectedProtocolVersion]
  );

  useEffect(
    function() {
      setIdTokenKey(jwtLocalStorageKey);
    },
    [jwtLocalStorageKey, appInfo?.auth0ClientId, user?.sub]
  );

  useEffect(() => {
    StudyApi.getProtocolsByStudyIdentifier(studyIdentifier).then(res => {
      setProtocols(
        filter(res.data, protocol => includes(allowedProtocolStatusesToDisplay, protocol.status)).map(mapProtocol)
      );
    }, onRequestError);
  }, [studyIdentifier]);

  useEffect(
    function() {
      if (!isEmpty(protocols) && !selectedProtocol) {
        const activeProtocol = protocols.find(protocol => protocol.status === 'Active');
        onProtocolChange(activeProtocol || protocols[0]);
      }
    }, // eslint-disable-next-line
    [protocols]
  );

  useEffect(
    function() {
      if (isEmpty(selectedProtocol?.epochs)) {
        return;
      }
      const encounters = selectedProtocol.epochs.flatMap(function(epoch) {
        return epoch.encounters.map(function(encounter) {
          return { ...encounter, epochName: epoch.name, epochId: epoch.id };
        });
      });
      setAllEncounters(encounters);
      setSelectedEncounters(encounters);
    },
    [selectedProtocol]
  );

  useEffect(
    function() {
      setDisplayedItemGroups(
        sortBy(getUniqueItemGroupsFromEncounters(selectedEncounters), elem => elem.name.toUpperCase())
      );
      setSelectedItemGroups(
        sortBy(getUniqueItemGroupsFromEncounters(selectedEncounters), elem => elem.name.toUpperCase())
      );
    },
    [selectedEncounters]
  );
  return (
    <>
      <ModalBoxes.Header>Generate print version of Encounters</ModalBoxes.Header>
      <ModalBoxes.Body>
        <div className="encounters-selector">
          <Select
            className="protocol-select"
            dataSource={protocols}
            label="Protocol Version"
            required
            value={selectedProtocol}
            onChange={onProtocolChange}
            clearable={false}
          />
          <h3>Encounters</h3>
          {!isEmpty(allEncounters) && (
            <EncounterPrintMultiselect
              selectedOptions={selectedEncounters}
              options={allEncounters}
              onChange={onEncountersSelect}
              groupByKey="epochName"
              defaultSelectAll
            />
          )}
        </div>
        <div className="item-groups-selector">
          <h3>Procedures</h3>
          {displayedItemGroups && displayedItemGroups.length > 0 ? (
            <EncounterPrintMultiselect
              selectedOptions={selectedItemGroups}
              options={displayedItemGroups}
              onChange={onItemGroupsSelect}
              id="uuid"
              defaultSelectAll
            />
          ) : (
            <div className="allert-unselected-encounters">
              <div className="alert alert-light" role="alert">
                Select at least one encounter
              </div>
            </div>
          )}
        </div>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            Close
          </Button>
          <Button target="_blank" href={linkToPrintPage} onClick={generate} disabled={!isAllowedToGenerate}>
            Generate
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );

  function onEncountersSelect(newSelectedEncounters) {
    setSelectedEncounters(newSelectedEncounters);
  }

  function onItemGroupsSelect(newSelectedItemGroups) {
    setSelectedItemGroups(newSelectedItemGroups);
  }

  function onProtocolChange(protocol) {
    if (protocol?.id !== selectedProtocol?.id) {
      StudyApi.getProtocolsByStudyIdentifier(studyIdentifier).then(res => {
        setProtocols(
          filter(res.data, prt => includes(allowedProtocolStatusesToDisplay, prt.status))
            .map(mapProtocol)
            .filter(prt => prt.id !== protocol.id)
        );
        protocol = protocols.find(prt => prt.id === protocol.id);
      }, onRequestError);
      setSelectedProtocol(filterAndAddEncounterNameToItemGroups(protocol));
    }
  }

  function generate() {
    saveItemGroupsToLocalStorage(selectedEncounters, selectedItemGroups);
  }
}

function getUniqueItemGroupsFromEncounters(encounters) {
  const allItemGroups = encounters.flatMap(({ itemGroups }) => itemGroups);
  return uniqBy(
    allItemGroups.map(({ uuid, name }) => ({ uuid, name })),
    'uuid'
  );
}

function saveItemGroupsToLocalStorage(selectedEncounters, selectedItemGroups) {
  const itemGroupUUIDs = selectedItemGroups.map(({ uuid }) => uuid);
  const itemGroupsFromAllSelectedEncounters = selectedEncounters.flatMap(({ itemGroups }) =>
    itemGroups.filter(({ uuid }) => itemGroupUUIDs.includes(uuid))
  );

  localStorage.setItem(
    'encountersByIGId',
    JSON.stringify(getEncountersByItemGroup(itemGroupsFromAllSelectedEncounters))
  );
  localStorage.setItem('epvItemGroups', JSON.stringify(itemGroupUUIDs));
}

function mapProtocol(protocol) {
  return {
    id: protocol.protocolId,
    name: `${protocol.name} v${protocol.versionId}.0 (${protocol.status})`,
    epochs: protocol.epochs,
    version: protocol.versionId,
    status: protocol.status
  };
}

function filterAndAddEncounterNameToItemGroups(protocol) {
  /* TODO: do on protocols set and do immutable*/
  protocol.epochs = protocol.epochs.map(epoch => {
    epoch.encounters = epoch.encounters.map(encounter => {
      encounter.itemGroups = encounter.itemGroups
        .map(itemGroup => {
          return {
            encounterName: encounter.name,
            encounterInstanceId: encounter.encounterInstanceId,
            epochName: epoch.name,
            ...itemGroup
          };
        })
        .filter(itemGroup => !includes([ITEM_GROUP_DOMAIN.EREVSM, ITEM_GROUP_DOMAIN.EREVPI], itemGroup.domainCode));
      return encounter;
    });
    return epoch;
  });
  return protocol;
}

function getEncountersByItemGroup(allItemGroups) {
  const map = {};
  allItemGroups.forEach(curr => {
    const key = curr.uuid;
    const value = curr.encounterName;
    if (key in map) {
      map[key].push(value);
    } else {
      map[key] = [value];
    }
  });
  return map;
}

PrintPatientEncountersGeneratorModal.className = 'print-version-generator-of-encounters';
PrintPatientEncountersGeneratorModal.size = 'w950';
