import React, { useCallback, useEffect, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import cx from 'classnames';

import FinClientApi from '../../../../../api/finance/FinClientApi';
import FinVendorApi from '../../../../../api/finance/FinVendorApi';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import { MANAGE_CLIENT_ACCOUNTS, MANAGE_VENDOR_ACCOUNTS } from '../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../services/auth';
import { onRequestError } from '../../../../../services/handlers';
import { AccountType } from '../AccountType.js';

import { AccountCreateModal } from './AccountCreateModal/AccountCreateModal';
import { AccountsList } from './AccountsList/AccountsList';
import { lettersForFilter } from './AccountsList/AccountsListConstants';

import './AccountSection.scss';

export const AccountSection = () => {
  const [value, setValue] = useState(0);
  const [selectedLetter, setSelectedLetter] = useState(null);
  const [accountsList, setAccountsList] = useState([]);

  const resolveApiForGetAccounts = useCallback(() => {
    if (AccountType[value] === 'Client') {
      return FinClientApi.viewUsage().then(({ data }) =>
        data.map(el => ({ ...el.client, used: el.usedInFinBudget || el.usedInFinLedgerEventItem }))
      );
    } else {
      return FinVendorApi.viewUsage().then(({ data }) =>
        data.map(el => ({ ...el.vendor, used: el.usedInActiveFinBudgetRow || el.usedInFinLedgerEventItem }))
      );
    }
  }, [value]);

  const updateAccounts = useCallback(() => {
    resolveApiForGetAccounts().then(accounts => setAccountsList(accounts), onRequestError);
  }, [resolveApiForGetAccounts]);

  useEffect(() => {
    updateAccounts();
  }, [updateAccounts]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openAccountModal = (type, id = null, updateFunction) => {
    ModalBoxes.open({
      component: <AccountCreateModal type={type} accountType={value} accountId={id} updateFunction={updateFunction} />,
      size: 'w800',
      className: 'add-payable-to-modal-box'
    });
  };

  const tabsStyles = {
    color: '#691E44',
    '&.Mui-selected': {
      color: '#691E44',
      outline: 'none'
    }
  };

  const userHasAccessToCardMenu =
    AccountType[value] === 'Clients'
      ? userHasAccessTo(MANAGE_CLIENT_ACCOUNTS)
      : userHasAccessTo(MANAGE_VENDOR_ACCOUNTS);

  return (
    <div className="client-section">
      <div className="tabs-section">
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            sx: {
              backgroundColor: '#691E44'
            }
          }}
        >
          <Tab label="Clients" sx={tabsStyles} />
          <Tab label="Vendors" sx={tabsStyles} />
        </Tabs>

        {userHasAccessToCardMenu && (
          <Button
            className="its-export"
            size="h28"
            onClick={() => openAccountModal('Add', null, updateAccounts)}
            disabled={false}
          >
            {`Add ${AccountType[value]}`}
          </Button>
        )}
      </div>
      <div className="filter-section">
        {lettersForFilter.map(letter => (
          <FilerItem
            key={`filter-item-key-${letter}`}
            letter={letter}
            selectedLetter={selectedLetter}
            setSelectedLetter={setSelectedLetter}
          />
        ))}
      </div>
      <CustomTabPanel value={value} index={0}>
        <AccountsList
          type="Clients"
          selectedLetter={selectedLetter}
          openAddAccountModal={openAccountModal}
          accountsList={accountsList}
          updateAccounts={updateAccounts}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <AccountsList
          type="Vendors"
          selectedLetter={selectedLetter}
          openAddAccountModal={openAccountModal}
          accountsList={accountsList}
          updateAccounts={updateAccounts}
        />
      </CustomTabPanel>
    </div>
  );
};

const FilerItem = ({ letter, selectedLetter, setSelectedLetter }) => {
  return (
    <div
      data-testid={`filter-by-letter-${letter}`}
      className={cx('filter-item', { selected: letter === selectedLetter })}
      onClick={() => (letter === selectedLetter ? setSelectedLetter(null) : setSelectedLetter(letter))}
    >
      {letter}
    </div>
  );
};

const CustomTabPanel = ({ children, value, index, ...other }) => {
  return (
    <div className="tab-content" role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <>{children}</>}
    </div>
  );
};
