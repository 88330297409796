import axios from 'axios';

const ROOT = 'api/v1/patients';

const LogTableApi = {
  getLogTable(parameters) {
    return axios.get(`${ROOT}/${parameters.ssuPatient}/logs/${parameters.itemGroupTemplateId}`);
  },
  getPermissionTypeForLog(ssuPatientId, itemGroupTemplateId) {
    return axios.get(`${ROOT}/${ssuPatientId}/log/${itemGroupTemplateId}/permission`);
  },
  getLogTableWithLogCheck(parameters) {
    return axios.get(`${ROOT}/${parameters.ssuPatient}/item-group/${parameters.itemGroupId}/logs`);
  },
  getLogRow(parameters) {
    return axios.get(
      `${ROOT}/${parameters.ssuPatient}/logs/${parameters.itemGroupTemplateVersionId}/rowId/${parameters.rowId}`
    );
  },
  getActualLogFormFromPatientInfo(parameters) {
    return axios.get(`${ROOT}/${parameters.ssuPatient}/logForm/${parameters.itemGroupTemplateVersionId}`);
  },
  getAverseEventHistory(rowId) {
    return axios.get(`api/v1/adverse-events/history/${rowId}`);
  }
};

export default LogTableApi;
