import React from 'react';

import { SiteBillControl } from './SiteBillControl/SiteBillControl';
import { SiteBillTable } from './SiteBillTable/SiteBillTable';
import { SiteBillProvider } from './SiteBillContext';

import './SiteBill.scss';

function SiteBillPage() {
  return (
    <React.Fragment>
      <SiteBillControl />
      <div className="eds-site-bill">
        <SiteBillTable />
      </div>
    </React.Fragment>
  );
}

function SiteBill() {
  return (
    <SiteBillProvider>
      <SiteBillPage />
    </SiteBillProvider>
  );
}

export default SiteBill;
