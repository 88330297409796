import { Typography } from '@mui/material';

import './PaymentsSectionHeader.scss';
export default function PaymentsSectionHeader({ title, children }) {
  return (
    <div className="pps-header">
      <Typography variant="h6">{title}</Typography>
      {children}
    </div>
  );
}
