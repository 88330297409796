import React from 'react';
import cx from 'classnames';

import { composePatientName, hasUserAccessToProtectedPatientInfo, isDisabledAuthority } from '../CalendarUtils';

export function AppointmentViewField({ label, children, className }) {
  return (
    <div className={cx('appointment-view-field', className)}>
      <div className="label lightgrey">{label}</div>
      {children}
    </div>
  );
}

export function ParticipantField({ participant, appointment, className }) {
  return (
    participant && (
      <div className={cx(className, 'participant-field')}>
        <div className="label grey-text">{participant.label}</div>
        {getPatientName(appointment, participant)}
        {isDisabledAuthority(participant) && <div className="name c-p">DISABLED</div>}
      </div>
    )
  );
}

function getPatientName(appointment, participant) {
  if (participant.type === 'PATIENT') {
    if (participant?.preferredName && hasUserAccessToProtectedPatientInfo(appointment)) {
      return (
        <div className="normal">
          {`${composePatientName(appointment, participant, appointment.patientSubjectId)}`}
          {appointment?.patientSubjectId && `(${appointment.patientSubjectId})`}
          {participant?.preferredName && (
            <span className="patient-preferred-name"> ({participant.preferredName}) </span>
          )}
        </div>
      );
    } else {
      return (
        <div className="normal">
          {`${composePatientName(appointment, participant, appointment.patientSubjectId)}`}
          {appointment?.patientSubjectId && `(${appointment.patientSubjectId})`}
        </div>
      );
    }
  } else {
    return (
      <div className="normal">
        {`${participant.firstName} ${participant.lastName}`}
        {participant?.preferredName && <span className="patient-preferred-name"> ({participant.preferredName})</span>}
      </div>
    );
  }
}
