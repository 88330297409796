import axios from 'axios';

const ROOT = 'api/v1/visit/patient';

const EncounterTableApi = {
  getEncounterTableItemGroup(ssuPatientId, sitePatientEncounterId, sitePatientItemGroupId) {
    return axios.get(
      `${ROOT}/${ssuPatientId}/encounter/${sitePatientEncounterId}/table-item-group/${sitePatientItemGroupId}`
    );
  },
  saveEncounterTableItemGroup(ssuPatientId, sitePatientEncounterId, sitePatientItemGroupId, data) {
    return axios.post(
      `${ROOT}/${ssuPatientId}/encounter/${sitePatientEncounterId}/table-item-group/${sitePatientItemGroupId}`,
      data
    );
  },
  updateEncounterTableItemGroup(ssuPatientId, sitePatientEncounterId, sitePatientItemGroupId, data) {
    return axios.put(
      `${ROOT}/${ssuPatientId}/encounter/${sitePatientEncounterId}/table-item-group/${sitePatientItemGroupId}`,
      data
    );
  }
};

export default EncounterTableApi;
