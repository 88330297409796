import React, { useRef } from 'react';
import { isEmpty, isNull } from 'lodash/lang';
import PropTypes from 'prop-types';

import Input from '../../../../../../../../common/data-entry/Input';
import { useTableItemGroupActions, useTableItemGroupState } from '../../TableItemGroupContext/TableItemGroupContext';
import fieldPropTypes from '../fieldPropTypes';

import './TextboxField.scss';

export default function TextboxField({ field }) {
  const { fieldUid } = field;
  const { isPreviewOnly } = useTableItemGroupState();
  const { fieldOnChange } = useTableItemGroupActions();

  return (
    <TextboxFieldReadonly
      field={field}
      onChange={({ target }) => fieldOnChange(target.value || null, fieldUid)}
      disabled={isPreviewOnly}
    />
  );
}

export function TextboxFieldReadonly({ field, ...rest }) {
  const { fieldValue } = field;
  const inputRef = useRef(); /*TODO: looks like redundant*/

  return (
    <div className="text-box-field">
      <Input legacyLook ref={inputRef} value={fieldValue} maxLength="3000" {...rest} />
      <div className="text-box-counter">{`${fieldValue ? fieldValue.length : ''}/3000`}</div>
    </div>
  );
}

const propTypes = {
  field: PropTypes.shape({
    ...fieldPropTypes,
    fieldType: PropTypes.oneOf(['textbox']).isRequired,
    fieldValue: function(props, propName, componentName) {
      const value = props[propName];
      if (!isNull(value) && isEmpty(value)) {
        return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed.`);
      }
    }
  })
};

TextboxFieldReadonly.defaultProps = {
  disabled: true
};

TextboxFieldReadonly.propTypes = propTypes;
TextboxField.propTypes = propTypes;
