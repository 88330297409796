import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { isEmpty } from 'lodash/lang';

import Button from '../../../../../common/general/Button';
import { toBill } from '../../../../../services/financial';
import { ACTIONS_BUTTON } from '../../NewInvoice/InvoiceTable/TableConstants';
import { Amount } from '../../shared/amount-view/Amount/Amount';
import { eventNumberComparator, resolveEventSuffix } from '../../SitePayments/SitePaymentServices';
import { ReadyForApprovalContext } from '../ReadyForApprovalContext';

export const ApprovalTable = () => {
  const { events, comment, setComment, checkedEvents, setCheckedEvents, onMultipleCommentApply } = useContext(
    ReadyForApprovalContext
  );
  const columns = useMemo(
    () => [
      {
        field: 'eventNumber',
        headerName: 'Event ID',
        flex: 1,
        minWidth: 125,
        sortComparator: eventNumberComparator,
        valueGetter: ({ row }) => {
          if (row.adjustmentSequence === 0) {
            return resolveEventSuffix(row.eventNumber, row.sitePaymentType);
          }
          return resolveEventSuffix(`${row.eventNumber}.${row.adjustmentSequence}`, row.sitePaymentType);
        }
      },
      {
        field: 'siteAmount',
        headerName: 'Site Amount',
        flex: 1,
        minWidth: 150,
        type: 'number',
        valueGetter: ({ row }) => toBill(row.siteAmount),
        renderCell: ({ row }) => <Amount coinsAmount={row.siteAmount} showDollarSign />
      },
      {
        field: 'sitePaidAmount',
        headerName: 'Site Paid Amount',
        flex: 1,
        minWidth: 180,
        type: 'number',
        valueGetter: ({ row }) => toBill(row.sitePaidAmount),
        renderCell: ({ row }) => <Amount coinsAmount={row.sitePaidAmount} showDollarSign />
      },
      {
        field: 'approvedAmount',
        headerName: 'Approved Amount',
        flex: 1,
        minWidth: 180,
        renderCell: ({ row }) => <RowInput row={row} field="approvedAmount" disabled />
      },
      {
        field: 'variance',
        headerName: 'Variance',
        flex: 1,
        minWidth: 170,
        type: 'number',
        valueGetter: ({ value }) => toBill(value),
        renderCell: ({ row: { variance } }) => <Amount coinsAmount={variance} showDollarSign />
      },
      {
        field: 'sitePaymentComment',
        headerName: 'Comments',
        flex: 3,
        minWidth: 180,
        renderCell: ({ row }) => <RowInput row={row} field="sitePaymentComment" inputProps={{ maxLength: 500 }} />
      }
    ],
    []
  );
  const [pinnedColumns, setPinnedColumns] = useState({
    right: [ACTIONS_BUTTON]
  });

  const handlePinnedColumnsChange = useCallback(updatedPinnedColumns => {
    setPinnedColumns({
      ...updatedPinnedColumns,
      right: [...updatedPinnedColumns.right.filter(column => column !== ACTIONS_BUTTON), ACTIONS_BUTTON]
    });
  }, []);

  return (
    <Box display="flex" height="600px" flexDirection="column" padding="0 25px 0 25px">
      <Box display="flex" marginBottom="20px" gap="20px">
        <TextField
          label="Apply Comments to Selected"
          value={comment}
          onChange={({ target: { value } }) => {
            setComment(value);
          }}
          inputProps={{ maxLength: 500 }}
          variant="standard"
          sx={{
            width: '100%'
          }}
        />
        <Button
          onClick={() => onMultipleCommentApply(comment)}
          data-testid="reconcile-apply-button"
          disabled={isEmpty(checkedEvents) || isEmpty(comment.trim())}
        >
          Apply
        </Button>
      </Box>
      <DataGridPremium
        sx={{
          border: 'none'
        }}
        data-testid="ready-for-approval-table"
        getRowId={row => row?.itemSiteId}
        rows={events}
        columns={columns}
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={newRowSelectionModel => {
          setCheckedEvents(newRowSelectionModel);
        }}
        rowHeight={38}
        localeText={{ noRowsLabel: 'No Record Found' }}
        pinnedColumns={pinnedColumns}
        onPinnedColumnsChange={handlePinnedColumnsChange}
        disableRowGrouping
        disableAggregation
      />
    </Box>
  );
};

const RowInput = ({ row, field, ...restProps }) => {
  const { onTableDataChange } = useContext(ReadyForApprovalContext);
  const [inputValue, seInputValue] = useState(row?.[field] ?? '');
  useEffect(() => {
    seInputValue(row?.[field] ?? '');
  }, [field, row]);
  return (
    <TextField
      value={inputValue}
      onChange={({ target: { value } }) => seInputValue(value)}
      onKeyDown={event => event.stopPropagation()}
      onBlur={() => onTableDataChange(row.itemSiteId, inputValue, field)}
      sx={{ height: '25px', width: '100%', '.MuiInputBase-root': { height: '25px' } }}
      {...restProps}
    />
  );
};
