import { useEffect, useMemo, useRef, useState } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { debounce } from 'lodash/function';
import { isEmpty } from 'lodash/lang';

import { MapsApi } from '../../../../../../../../../api';
import { getCountryName, getRegionName } from '../../../../../../../../../services/geographic';
import { onRequestError } from '../../../../../../../../../services/handlers';

export default function CityAutocomplete({ name, countryId, regionId, city, onChange, onBlur }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const prevRef = useRef(open);

  const value = useMemo(
    function() {
      if (!city) {
        return { city: '', description: '' };
      }
      if (!isEmpty(options)) {
        const f = options.find(function(option) {
          return city === option.city && countryId === option.country && regionId === option.region;
        });
        if (!isEmpty(f)) return f;
      }
      return { city, description: '' };
    },
    [city, countryId, options, regionId]
  );

  const getPredictions = useMemo(function() {
    return debounce(function(req, resolve, reject, finish) {
      MapsApi.autocompleteCity(req)
        .then(resolve, reject)
        .finally(finish);
    }, 1000);
  }, []);

  useEffect(
    function() {
      if (!open) {
        return;
      }
      if (city?.length < 2) {
        setLoading(false);
        setOptions([]);
        return;
      }
      const request = prepareRequest(city, countryId, regionId);
      const uid = Object.values(request).join('.');
      if (uid === prevRef.current) {
        return;
      }
      prevRef.current = uid;
      let canceled = false;
      setLoading(true);
      getPredictions(
        request,
        function({ data }) {
          if (!canceled) {
            setOptions(data.predictions);
          }
        },
        onRequestError,
        function() {
          if (!canceled) {
            setLoading(false);
          }
        }
      );
      return function() {
        canceled = true;
      };
    },
    [getPredictions, city, countryId, regionId, open]
  );

  return (
    <Autocomplete
      freeSolo
      loading={loading}
      filterOptions={x => x}
      noOptionsText={city?.length < 2 ? 'Type at least 2 characters' : 'No options'}
      value={value}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={function({ target }, value, reason) {
        if (reason === 'selectOption') {
          onChange({
            city: value.city,
            countryId: value.country,
            regionId: value.region
          });
        }
      }}
      onBlur={onBlur}
      fullWidth
      getOptionKey={option => option.description}
      getOptionLabel={option => option.city}
      renderOption={function(props, option) {
        return (
          <Box component="li" {...props}>
            {option.description}
          </Box>
        );
      }}
      disablePortal
      options={loading ? [] : options}
      onInputChange={(event, newInputValue) => {
        if (city !== newInputValue) {
          onChange(newInputValue);
        }
      }}
      renderInput={params => (
        <TextField {...params} name={name} label="City" variant="standard" required autoComplete="off" />
      )}
    />
  );
}

function prepareRequest(city, countryId, regionId) {
  const obj = { city };
  if (countryId) {
    obj.country = getCountryName(countryId) || null;
  }
  if (obj.country) {
    obj.region = getRegionName(regionId, countryId) || null;
  }
  return obj;
}
