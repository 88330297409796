import { uniqBy } from 'lodash/array';
import { orderBy } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

export function getStudiesFrom(ssus) {
  return orderBy(
    uniqBy(
      ssus.map(ssu => ssu.study),
      e => e.id
    ),
    e => e.name
  );
}

export function getSitesFrom(ssus) {
  return orderBy(
    uniqBy(
      ssus.map(ssu => ssu.site),
      e => e.id
    ),
    e => e.name
  );
}

export function getEncountersFrom(data) {
  return orderBy(
    uniqBy(data.map(e => e.encounterName))
      .filter(e => e)
      .map((name, id) => ({ id: name, name })),
    e => e.name
  );
}

export function getIds(list) {
  return list.map(e => e.id);
}

export function getNames(list) {
  return list.map(e => e.name);
}

export function getOrderRules() {
  return {
    iteratees: 'appointmentDate',
    orders: 'asc'
  };
}

export function getValidationErrors(filter) {
  const errors = [];
  if (isEmpty(filter.selectedStudies)) {
    errors.push('selectedStudies');
  }
  if (isEmpty(filter.selectedSites)) {
    errors.push('selectedSites');
  }
  if (isEmpty(filter.selectedEncounters)) {
    errors.push('selectedEncounters');
  }
  return errors;
}
