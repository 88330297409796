import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { orderBy } from 'lodash/collection';
import moment from 'moment';
import Tooltip from 'rc-tooltip';

import { NotificationApi } from '../../api';
import IconButton from '../../common/buttons/IconButton';
import Toggle from '../../common/data-entry/Toggle';
import useOutsideClickDetector from '../../common/hooks/useOutsideClickDetector';
import { useCurrentUser } from '../root/Container/CurrentUserContainer';
import { OTHER, TaskTopicsMap } from '../root/Container/Layout/Tasks/Task/taskConstants';
import TasksSupervisor from '../root/Container/Layout/Tasks/TasksSupervisor/TasksSupervisor';
import { generateUrlByKey } from '../root/router';

import { withNotificationsContext } from './NotificationsContext';
import { getLocalStorageFilter, updateLocalStorageFilter } from './NotificationsService';
import NotificationsWebSocketConnector from './NotificationsWebSocketConnector';

import './Notifications.scss';

const NO_NOTIFICATIONS_MESSAGE = 'You have no notifications from the last 30 days.';
const NO_UNREAD_NOTIFICATIONS_MESSAGE = 'You have read all of your notifications from the last 30 days.';

export function Notifications({ amount }) {
  const [notificationAmount, setNotificationAmount] = useState(0);
  const [notificationAmountForUpdate, setNotificationAmountForUpdate] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [showDetails, setShowDetails] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showUnreadOnly, setShowUnreadOnly] = useState(true);
  const [isLast, setIsLast] = useState(true);
  const notificationsDetailsContainer = useRef(null);
  const User = useCurrentUser();

  useEffect(() => {
    setNotificationAmount(amount);
  }, [amount]);

  useEffect(() => {
    const showUnreadOnlyStoredValue = getLocalStorageFilter(User?.personnelIdentifier, 'showUnreadOnly');
    if (showUnreadOnlyStoredValue !== undefined) {
      setShowUnreadOnly(showUnreadOnlyStoredValue);
    }
  }, [User]);

  useEffect(
    function() {
      NotificationApi.getPersonnelNotifications(0, showUnreadOnly ? 'UNREAD' : 'ALL').then(({ data }) => {
        setNotifications(orderBy(prepareEvents(data.content), 'notificationCreationDate', 'desc'));
        setIsLast(data.last);
        setPageNumber(data.number);
      });
    },
    [showUnreadOnly]
  );

  const changeShowDetails = showDetails => {
    if (showDetails) {
      NotificationApi.markAllNewStatusAsViewed().then(() => {
        setNotificationAmount(0);
        setNotificationAmountForUpdate(0);
        NotificationApi.getPersonnelNotifications(0, showUnreadOnly ? 'UNREAD' : 'ALL').then(({ data }) => {
          setNotifications(orderBy(prepareEvents(data.content), 'notificationCreationDate', 'desc'));
          setIsLast(data.last);
          setPageNumber(data.number);
          setShowDetails(showDetails);
        });
      });
    } else {
      setShowDetails(showDetails);
    }
  };

  const updateNotificationData = useCallback(
    (withCounter, page, showUnreadOnly) => {
      withCounter &&
        NotificationApi.getNumberOfNewNotifications().then(({ data }) => {
          setNotificationAmount(data?.notificationAmount);
        });

      NotificationApi.getPersonnelNotifications(page, showUnreadOnly ? 'UNREAD' : 'ALL').then(({ data }) => {
        setNotifications(notifications =>
          orderBy([...notifications, ...prepareEvents(data.content)], 'notificationCreationDate', 'desc')
        );
        setIsLast(data.last);
        setPageNumber(data.number);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const changeNotificationStatus = (event, readStatus) => {
    NotificationApi.changeNotificationStatus([event.notificationId], readStatus).then(() => {
      setNotifications(notifications =>
        notifications.map(notification => {
          if (notification.notificationId === event.notificationId) notification.readStatus = !notification.readStatus;
          return notification;
        })
      );
      NotificationApi.getNumberOfNewNotifications().then(({ data }) => {
        setNotificationAmount(data?.notificationAmount);
      });
    });
  };

  const markAllUnreadNotificationsAsRead = () => {
    const events = notifications.filter(event => event.readStatus === false);
    const lastVisibleEventId = events[0].notificationId;
    NotificationApi.markAllBeforeDateAsRead(lastVisibleEventId).then(({ data }) => {
      setNotifications(notifications =>
        notifications
          .map(notification => {
            if (data.includes(notification.notificationId)) {
              notification.readStatus = true;
            }
            return notification;
          })
          .filter(notification => (showUnreadOnly ? !notification.readStatus : notification.readStatus))
      );
      NotificationApi.getNumberOfNewNotifications().then(({ data }) => {
        setNotificationAmount(data?.notificationAmount);
      });
    });
  };

  useEffect(
    function() {
      updateNotificationData(true, 0, showUnreadOnly);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateNotificationData]
  );

  useEffect(
    function() {
      !showDetails && notificationAmountForUpdate && setNotificationAmount(notificationAmountForUpdate);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showDetails]
  );

  useOutsideClickDetector(notificationsDetailsContainer, () => {
    setShowDetails(false);
    setNotifications([]);
  });

  const redirectionDependingOnPressedKey = (clickEvent, event) => {
    const isMacOs = window.navigator.userAgent.includes('Mac'),
      { ctrlKey, shiftKey, metaKey } = clickEvent;
    const { taskId, taskLink, studySiteId } = event;
    // always prevent the click event, else, it bubbles up twice
    clickEvent.preventDefault();
    if (ctrlKey || (isMacOs && metaKey)) {
      window.open(taskLink, '_blank');
    } else if (shiftKey) {
      window.open(taskLink, '_new');
    } else {
      setShowDetails(false);
      TasksSupervisor.open(taskId, studySiteId);
      changeNotificationStatus(event, true);
    }
  };

  return (
    <div className={'eui-notifications'} ref={notificationsDetailsContainer}>
      <NotificationsWebSocketConnector
        onMessage={() => {
          NotificationApi.getNumberOfNewNotifications().then(({ data }) => {
            setNotificationAmountForUpdate(data?.notificationAmount);
            !showDetails && setNotificationAmount(data?.notificationAmount);
          });
        }}
      />
      <div className="notifications-bell-container" onClick={() => changeShowDetails(!showDetails)}>
        {notificationAmount > 0 && (
          <span className={'notifications-counter-circle'}>{notificationAmount > 9 ? '9+' : notificationAmount}</span>
        )}
        <IconButton>notifications</IconButton>
      </div>
      {showDetails && (
        <div className="notifications-dialog">
          <div className="notifications-container pb-2">
            <div className="notifications-container-header mt-2 mx-2 mb-0">
              <div>Notifications</div>
              <div>
                <Toggle
                  name="Appointments"
                  checked={showUnreadOnly}
                  className="mr-2"
                  onChange={e => {
                    setShowUnreadOnly(e.target.checked);
                    updateLocalStorageFilter(User?.personnelIdentifier, 'showUnreadOnly', e.target.checked);
                  }}
                >
                  Only Show unread
                </Toggle>
                |
                <Link to={generateUrlByKey('Preferences')} className="link m-2">
                  Settings
                </Link>
              </div>
            </div>
            <div className="notifications-mark-read">
              {notifications.filter(e => !e.readStatus).length > 0 && (
                <span
                  className="link"
                  onClick={() => {
                    markAllUnreadNotificationsAsRead();
                  }}
                >
                  Mark all as read
                </span>
              )}
            </div>
            <div className="notifications-scrollable-container px-2 pt-2">
              {notifications
                .filter(e => {
                  if (showUnreadOnly) {
                    return !e.readStatus === showUnreadOnly;
                  } else {
                    return true;
                  }
                })
                .map((e, idx) => {
                  return (
                    <div key={idx} className="notification-element p-2 mb-2">
                      <div className="notification-header">
                        <div className="notification-date">{getDateString(e.notificationCreationDate)}</div>
                        <Tooltip
                          destroyTooltipOnHide={false}
                          placement="bottom"
                          overlayClassName="eds-rc-tooltip notification-tooltip"
                          mouseEnterDelay={0}
                          mouseLeaveDelay={0}
                          overlay={<span>{e.readStatus ? 'Mark as unread' : 'Mark as read'}</span>}
                        >
                          <div
                            className={cx('notification-read-status', { read: e.readStatus })}
                            onClick={() => {
                              changeNotificationStatus(e, !e.readStatus);
                            }}
                          />
                        </Tooltip>
                      </div>
                      <div className="copy-text">{e.notificationDescription}</div>
                      <div onClick={event => redirectionDependingOnPressedKey(event, e)} className="link-wrapper">
                        <a className="link" href={e.taskLink}>
                          {e.text}
                        </a>
                      </div>
                    </div>
                  );
                })}
              {notifications.length === 0 && !showUnreadOnly && (
                <div className="notifications-default-message">{NO_NOTIFICATIONS_MESSAGE}</div>
              )}
              {notifications.filter(e => !e.readStatus).length === 0 && showUnreadOnly && (
                <div className="notifications-default-message">{NO_UNREAD_NOTIFICATIONS_MESSAGE}</div>
              )}
            </div>
            {!isLast && notifications.length > 0 && (
              <div
                className="notifications-container-footer link p-2"
                onClick={() => {
                  const nextPage = pageNumber + 1;
                  setPageNumber(nextPage);
                  updateNotificationData(false, nextPage, showUnreadOnly);
                }}
              >
                Show More
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

function getDateString(date) {
  const momentDate = moment(date);
  let prefix;
  if (momentDate.isSame(moment(), 'day')) {
    prefix = 'Today';
  } else if (momentDate.isSame(moment().subtract(1, 'day'), 'day')) {
    prefix = 'Yesterday';
  } else {
    prefix = momentDate.format('DD MMM');
  }
  return `${prefix} at ${momentDate.format('h:mm A')}`;
}

function formatTopic(e) {
  const { topic, topicTitle } = e;
  const { id, name } = TaskTopicsMap[topic];
  return id === OTHER ? `${name}: ${topicTitle}` : `${name}`;
}

function prepareEvents(events) {
  return events.map(e => {
    e.text = `Task ${e.taskSequenceNumber} - Topic: ${formatTopic(e)}`;
    return e;
  });
}

export default withNotificationsContext(Notifications);
