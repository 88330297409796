import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { MissingBudgetItemsApi } from '../../../../../api';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { LEDGER_EVENTS_CREATED } from '../../../../../constants/notificationMessages';
import { generateUrlByKey } from '../../../../root/router';

import { BUDGET_ITEM, MISSING_EVENTS, REASON } from './addLedgerConstants';

export const AddLedgerContext = React.createContext(null);

export function AddLedgerProvider({ children }) {
  const navigate = useNavigate();
  const stepsList = [BUDGET_ITEM, MISSING_EVENTS, REASON];
  const [currentStep, setCurrentStep] = useState(stepsList.find((step, index) => index === 0));
  const [finBudgetRowId, setFinBudgetRowId] = useState(null);
  const [reason, setReason] = useState(null);
  const [finBudgetDetails, setFinBudgetDetails] = useState(null);
  const [filteredFinBudgetRows, setFilteredFinBudgetRows] = useState(null);
  const [studySite, setStudySite] = useState({});
  const [type, setType] = useState(null);
  const [name, setName] = useState(null);
  const [studySiteList, setStudySiteList] = useState();
  const [range, setRange] = useState({ startDate: null, endDate: moment() });
  const [missingBudgetItem, setMissingBudgetItem] = useState(null);
  const [checkedBudgetItems, setCheckedBudgetItems] = useState([]);
  useEffect(
    function() {
      setCheckedBudgetItems(missingBudgetItem);
    },
    [missingBudgetItem]
  );

  useEffect(
    function() {
      setMissingBudgetItem(null);
      setReason(null);
    },
    [finBudgetRowId]
  );

  useEffect(
    function() {
      setFinBudgetRowId(null);
    },
    [finBudgetDetails]
  );
  const createLedgerEvents = useCallback(
    function() {
      const finCompletedWorkIds = checkedBudgetItems.map(({ completedWorkId }) => completedWorkId);
      const missingLedgerEventsCreateRequest = { finCompletedWorkIds, creationReason: reason };
      MissingBudgetItemsApi.createMissingLedgerEvents(
        finBudgetDetails.budgetSite?.id,
        finBudgetRowId,
        missingLedgerEventsCreateRequest
      ).then(() => {
        NotificationManager.success(LEDGER_EVENTS_CREATED);
        navigate(generateUrlByKey('Ledger'));
      });
    },
    [finBudgetDetails, finBudgetRowId, reason, navigate, checkedBudgetItems]
  );

  return (
    <AddLedgerContext.Provider
      value={{
        stepsList,
        currentStep,
        setCurrentStep,
        finBudgetRowId,
        setFinBudgetRowId,
        filteredFinBudgetRows,
        setFilteredFinBudgetRows,
        studySite,
        setStudySite,
        type,
        setType,
        name,
        setName,
        studySiteList,
        setStudySiteList,
        range,
        setRange,
        missingBudgetItem,
        setMissingBudgetItem,
        checkedBudgetItems,
        setCheckedBudgetItems,
        finBudgetDetails,
        setFinBudgetDetails,
        reason,
        setReason,
        createLedgerEvents
      }}
    >
      {children}
    </AddLedgerContext.Provider>
  );
}
