import { LOAD_PATIENTS } from '../../../../constants/accessToPages';

import LoadPatients from './LoadPatients';

export const loadPatientsRouterConfig = {
  name: 'Load Patients',
  path: '/load-patients',
  component: LoadPatients,
  access: [LOAD_PATIENTS]
};
