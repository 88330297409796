import React, { useMemo } from 'react';

import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import { CLOSED } from '../../../../../../constants/ssuStatuses';
import { MANAGE_SSU_PATIENT_STATUSES } from '../../../../../../constants/userOperations';
import { ROLE_SYSTEM_ADMINISTRATOR } from '../../../../../../constants/userRoles';
import { userHasAccessTo, userHasRole } from '../../../../../../services/auth';

import { BulkPatientStatusChangeModal } from './BulkPatientStatusChangeModal/BulkPatientStatusChangeModal';

import './SelectedItemsMenu.scss';

export const SelectedItemsMenu = ({ tableData, selectedSitePatientsIds, updatePage }) => {
  const selectedRows = useMemo(() => tableData.filter(row => selectedSitePatientsIds.includes(row.sitePatientId)), [
    selectedSitePatientsIds,
    tableData
  ]);

  const studySiteIdentifiers = useMemo(() => selectedRows.map(row => row.studySiteIdentifier), [selectedRows]);

  const allSelectedPatientsHaveSameStudySiteIdentifier = useMemo(() => {
    return new Set(studySiteIdentifiers).size === 1;
  }, [studySiteIdentifiers]);

  const selectedRowsHaveNoClosedPatients = useMemo(() => {
    return selectedRows.every(row => row.studySiteStatus !== CLOSED);
  }, [selectedRows]);

  const isAllowedToEdit = useMemo(() => {
    return (
      userHasRole(ROLE_SYSTEM_ADMINISTRATOR) ||
      (selectedRowsHaveNoClosedPatients && userHasAccessTo(MANAGE_SSU_PATIENT_STATUSES))
    );
  }, [selectedRowsHaveNoClosedPatients]);

  return selectedSitePatientsIds.length ? (
    <div className="worklist-selected-patients-menu">
      <div>{`${selectedSitePatientsIds.length} Patients selected`}</div>
      <div>
        <Button
          priority="low"
          size="h28"
          onClick={() => openBulkPatientStatusChangeModal(selectedSitePatientsIds, updatePage, studySiteIdentifiers[0])}
          disabled={!allSelectedPatientsHaveSameStudySiteIdentifier || !isAllowedToEdit}
          data-testid="update-status-button"
        >
          Update Status
        </Button>
      </div>
    </div>
  ) : (
    <div className="menu-place-holder" />
  );
};
const openBulkPatientStatusChangeModal = (selectedSitePatientsIds, updatePage, ssuId) => {
  ModalBoxes.open({
    component: (
      <BulkPatientStatusChangeModal
        selectedSitePatientsIds={selectedSitePatientsIds}
        updatePage={updatePage}
        ssuId={ssuId}
      />
    ),
    title: 'Change Patient Status',
    size: 'w500'
  });
};
