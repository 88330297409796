import { MILESTONE_REPORT } from '../../../../constants/accessToPages';

import CycleTimeReport from './CycleTimeReport';

export const cycleTimeReportRouterConfig = {
  name: 'Cycle Time Report',
  path: '/cycle-time-report',
  component: CycleTimeReport,
  access: [MILESTONE_REPORT]
};
