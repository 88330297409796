import React from 'react';

import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import ScheduleAppointmentModal from '../../patient-source/Patients/PatientInfo/EncountersSection/ScheduleAppointmentModal/ScheduleAppointmentModal';

export default function ScheduleAppointment({ sitePatientId, studyId, encounterId, patientIdentifier }) {
  function onShowScheduleEncounter() {
    ModalBoxes.open({
      component: (
        <ScheduleAppointmentModal
          patientId={patientIdentifier}
          ssuPatientId={sitePatientId}
          studyId={studyId}
          isWorklistPage
          encounterId={encounterId}
        />
      )
    });
  }

  return (
    <div className="worklist-icon">
      <span onClick={onShowScheduleEncounter} className="add-btn">
        <i className="material-icons">add</i>
      </span>
    </div>
  );
}
