import React, { Component } from 'react';
import { sortBy } from 'lodash/collection';

import Select from '../../common/data-entry/Select';

export class SSUPCNFilter extends Component {
  state = {};

  changeStudy = study => {
    this.setState({ study });
    this.props.handleStudyChange(study);
  };

  filterStudyToHavePcn = studies =>
    sortBy(
      studies.filter(study => study.projectCode),
      'projectCode'
    );

  studyPcnOrNoPCNMessage = studies => studies.map(study => (study.projectCode ? study.projectCode : 'No PCN'));

  render() {
    let { studies, sites, handleSiteChange, initialStudy, initialSite, isRequired } = this.props;
    return (
      <>
        <Select
          label="Study"
          clearSearchOnSelection
          dataSource={studies}
          optionLabelKey="studyName"
          optionValueKey="uniqueIdentifier"
          onChange={this.changeStudy}
          searchable
          required={isRequired}
          value={initialStudy}
          validate={false}
          data-testid="study-dropdown"
        />
        <Select
          label="PCN"
          clearSearchOnSelection
          dataSource={this.filterStudyToHavePcn(studies)}
          optionLabelKey="projectCode"
          optionValueKey="uniqueIdentifier"
          onChange={this.changeStudy}
          searchable
          required={isRequired}
          value={initialStudy}
          customSelectedValueTemplateFunction={this.studyPcnOrNoPCNMessage}
          validate={false}
          data-testid="npc-dropdown"
        />
        <Select
          label="Site"
          clearSearchOnSelection
          optionLabelKey="siteName"
          optionValueKey="uniqueIdentifier"
          dataSource={sites}
          onChange={handleSiteChange}
          searchable
          required={isRequired}
          value={initialSite}
          validate={false}
          data-testid="site-dropdown"
        />
      </>
    );
  }
}
