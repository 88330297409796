import axios from 'axios';

const ROOT = 'api/v1/patient-medication';

export const PatientMedicationsAPI = {
  getPatientMedications: function(patientId) {
    return axios.get(`${ROOT}/patient/${patientId}`);
  },
  addPatientMedication: function(patientId, body) {
    return axios.post(`${ROOT}/patient/${patientId}/add`, body);
  },
  removePatientMedication: function(patientId, medicationId) {
    return axios.delete(`${ROOT}/patient/${patientId}/remove?id=${medicationId}`);
  },
  editPatientMedication: function(patientId, body) {
    return axios.put(`${ROOT}/patient/${patientId}/edit`, body);
  },
  getAllMedications() {
    return axios.get(`${ROOT}/medications`, {
      hideLoader: true
    });
  },
  getAllFrequencies() {
    return axios.get(`${ROOT}/frequencies`, {
      hideLoader: true
    });
  },
  getAllDoseUnits() {
    return axios.get(`${ROOT}/dose-units`, {
      hideLoader: true
    });
  }
};
