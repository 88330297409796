import React, { Fragment, useContext, useState } from 'react';

import Loader from '../../../../../../../common/elements/Loader/Loader';
import Button from '../../../../../../../common/general/Button';
import Icon from '../../../../../../../common/general/Icon';

import PatientContentConfirmation from './PatientContentConfirmation/PatientContentConfirmation';
import { InstructionContext } from './InstructionContextProvider';
import { stopEventAnd } from './PatientContentTabs';

export function Instructions() {
  const [deleteInstructionCandidateId, setDeleteInstructionCandidateId] = useState(null);
  const { instructions, editInstruction, deleteInstructionByFileId, canUserAddInstructions, isLoading } = useContext(
    InstructionContext
  );

  return (
    <React.Fragment>
      {!isLoading && !deleteInstructionCandidateId && (
        <div className="patient-instructions-items">
          {instructions.map(({ key: { id }, instruction }) => {
            return (
              <div key={id} className="patient-instructions-item">
                <span>{instruction}</span>
                {canUserAddInstructions && (
                  <React.Fragment>
                    <Button
                      priority="low"
                      size="h40"
                      id="more-options"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <Icon>more_vert</Icon>
                    </Button>
                    <div
                      className="dropdown-menu dropdown-menu-right border-0 py-0"
                      aria-labelledby="more-options"
                      role="menu"
                    >
                      <button
                        className="dropdown-item py-1"
                        onClick={stopEventAnd(() => editInstruction(instruction, id))}
                      >
                        Edit
                      </button>
                      <button
                        className="dropdown-item py-1"
                        onClick={stopEventAnd(() => {
                          setDeleteInstructionCandidateId(id);
                        })}
                      >
                        Delete
                      </button>
                    </div>
                  </React.Fragment>
                )}
              </div>
            );
          })}
        </div>
      )}
      {!isLoading && deleteInstructionCandidateId && (
        <PatientContentConfirmation
          message={<Fragment>Are you sure you want to delete this instruction?</Fragment>}
          onConfirm={stopEventAnd(() => {
            deleteInstructionByFileId(deleteInstructionCandidateId).then(stopDeleting);
          })}
          onDeny={stopEventAnd(stopDeleting)}
        />
      )}

      {isLoading && (
        <div className="patient-files-loader">
          <Loader />
        </div>
      )}
    </React.Fragment>
  );

  function stopDeleting() {
    setDeleteInstructionCandidateId(null);
  }
}
