import { useMemo } from 'react';
import { isEmpty } from 'lodash/lang';

import {
  getStudyTypeGroup,
  STDT_REIMBURSEMENT,
  STDT_STIPEND,
  STDT_TRAVEL,
  STTG_CTMS,
  STTG_E_SOURCE,
  STTG_INTERNAL_PROJECT
} from '../../../../../../constants/study/studyTypes';

export default function useShowHide(PTEnabled, studyTypes, studyType) {
  return useMemo(
    function() {
      let showEncounterTabs = true;
      let showPaymentsSection = false;
      let showTravelSection = PTEnabled && studyTypes.includes(STDT_TRAVEL) && studyType === STTG_CTMS;

      if (isEmpty(studyTypes)) {
        showEncounterTabs = false;
        showPaymentsSection = false;
      } else {
        if (
          studyTypes.every(function(type) {
            return ![STTG_E_SOURCE, STTG_INTERNAL_PROJECT].includes(getStudyTypeGroup(type));
          })
        ) {
          showEncounterTabs = false;
        }
        if (
          [STDT_STIPEND, STDT_REIMBURSEMENT].some(function(type) {
            return studyTypes.includes(type);
          })
        ) {
          showPaymentsSection = true;
        }
      }
      return {
        showEncounterTabs,
        showPaymentsSection,
        showTravelSection
      };
    },
    [PTEnabled, studyType, studyTypes]
  );
}
