import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash/lang';

import ModalBoxComponent from '../ModalBoxComponent/ModalBoxComponent';
import ModalBoxes from '../ModalBoxes';

import './ModalBoxesContainer.scss';

export default function ModalBoxesContainer() {
  const [modalBoxes, setModalBoxes] = useState([]);

  useEffect(
    function() {
      if (!isEmpty(modalBoxes)) {
        document.body.classList.add('eds-modal-boxes-opened');
        return;
      }
      document.body.classList.remove('eds-modal-boxes-opened');
    },
    [modalBoxes]
  );

  useEffect(function() {
    ModalBoxes.addChangeListener(onChange);
    return function() {
      ModalBoxes.removeChangeListener(onChange);
    };
  }, []);

  return (
    <div className="eds-modal-boxes">
      {modalBoxes.map(function(props) {
        return <ModalBoxComponent key={props.id} {...props} />;
      })}
    </div>
  );

  function onChange(list) {
    setModalBoxes(list);
  }
}
