import React, { useCallback, useEffect, useState } from 'react';
import { unionBy } from 'lodash/array';
import { isArray } from 'lodash/lang';

import { ParticipantApi } from '../../../../api';
import { PATIENT_SITUATIONAL_ENCOUNTER_EVENT, PATIENT_UNEXPECTED_ENCOUNTER_EVENT } from '../CalendarEventType';

import CalendarSingleSelect from './CalendarSingleSelect';

let timeoutInstances = [];
export function PatientSelector({
  onPatientSelected,
  initialPatient,
  patientValidationMessage,
  appointmentType,
  disabled
}) {
  const [patients, setPatients] = useState();
  const [patientsCount, setPatientsCount] = useState(0);
  const [page, setPage] = useState(0);
  const [searchString, setSearchString] = useState('');
  const pageSize = 20;
  const pageSizeForSearch = 100;
  const isPatientEvent = useCallback(
    () =>
      appointmentType === PATIENT_UNEXPECTED_ENCOUNTER_EVENT || appointmentType === PATIENT_SITUATIONAL_ENCOUNTER_EVENT,
    [appointmentType]
  );

  const search = str => {
    setPage(0);
    setSearchString(str);

    if (timeoutInstances.length > 0) {
      timeoutInstances.forEach(timeoutInstance => clearTimeout(timeoutInstance));
    }

    const timeoutInstance = setTimeout(
      () =>
        ParticipantApi.getPatientParticipants(
          0,
          str.length > 0 ? pageSizeForSearch : pageSize,
          str,
          isPatientEvent()
        ).then(({ data: newPatients }) => {
          setPatients(newPatients);
          clearTimeout(timeoutInstance);
        }),
      500
    );
    timeoutInstances.push(timeoutInstance);
  };

  const pageDataFetch = patientsFromDropdown => {
    if (patients) {
      setPage(page + 1);
      return ParticipantApi.getPatientParticipants(page, pageSize, searchString, isPatientEvent()).then(
        ({ data: newPatients }) => {
          const filtered = newPatients.filter(e => !patients.some(x => x.patientId === e.patientId));
          const augmentedPatients = patients.concat(filtered);
          setPatients(augmentedPatients);
          return augmentedPatients;
        }
      );
    }
  };

  useEffect(() => {
    setPatients(patients => {
      if (initialPatient && isArray(patients) && !patients?.some(p => p.patientId === initialPatient.patientId)) {
        return [initialPatient, ...patients];
      }
      if (initialPatient && !isArray(patients)) {
        return [initialPatient];
      }
      return patients;
    });
  }, [initialPatient]);

  useEffect(() => {
    if (!disabled) {
      ParticipantApi.getPatientParticipantsCount().then(({ data: count }) => {
        setPatientsCount(count);
        if (count > 0) {
          ParticipantApi.getPatientParticipants(0, pageSize, '', isPatientEvent()).then(({ data: newPatientsPage }) => {
            setPage(1);
            setPatients(patients => {
              const onlyActivePatients = patients?.filter(p => p.hasActiveStudies) ?? [];
              return unionBy(newPatientsPage, onlyActivePatients, 'patientId');
            });
          });
        }
      });
    }
  }, [isPatientEvent, disabled]);

  const selectPatient = patient => {
    onPatientSelected(patient);
  };

  const customTemplateFunction = patient => {
    return patient ? (
      <span className="r-ss-selected-label">
        {patient.firstName} {patient.lastName}
        {patient?.preferredName && <span className="patient-preferred-name"> ({patient.preferredName})</span>}
        {patient.subjectIds && patient.subjectIds.length > 0 ? ` (${patient.subjectIds})` : ''}
      </span>
    ) : (
      undefined
    );
  };

  const byFirstLastNameOrSubject = (p, index, collection, searchString) =>
    `${p.lastName} ${p.firstName} ${p.lastName} ${p.preferredName} ${p.subjectIds}`
      .toLowerCase()
      .includes(searchString);

  return (
    <React.Fragment>
      <CalendarSingleSelect
        hasMorePages={collection => collection?.length < patientsCount}
        forceDefaultBrowserScrolling={true}
        onSearchInputChange={e => search(e)}
        pageDataFetch={pageDataFetch}
        searchable
        controlId={'patient-selector'}
        label={'Patient'}
        dataSource={patients}
        initialValue={initialPatient}
        optionValueKey={'patientId'}
        disabled={!!disabled}
        clearable={!disabled}
        required
        customOptionTemplateFunction={customTemplateFunction}
        customSelectedValueTemplateFunction={customTemplateFunction()}
        customFilterFunction={byFirstLastNameOrSubject}
        onChange={selectPatient}
        validationMessage={patientValidationMessage}
      />
    </React.Fragment>
  );
}
