import React, { useContext } from 'react';
import { isEmpty } from 'lodash/lang';

import { EncounterPageContext } from '../../NewEncounterPage/NewEncounterPageContext';
import WithAutoDirection from '../../NewEncounterPage/WithAutoDirection/WithAutoDirection';

import TableItemGroupComment from './TableItemGroupComment/TableItemGroupComment';
import { TableItemGroupProvider, useTableItemGroupState } from './TableItemGroupContext/TableItemGroupContext';
import TableItemGroupGeneral from './TableItemGroupGeneral/TableItemGroupGeneral';
import TableItemGroupHeader from './TableItemGroupHeader/TableItemGroupHeader';
import TableItemGroupResults from './TableItemGroupResults/TableItemGroupResults';

import './TableItemGroup.scss';

function TableItemGroup() {
  const { patientDetails, encounterTableItemGroup } = useTableItemGroupState();
  if (isEmpty(patientDetails) || isEmpty(encounterTableItemGroup)) {
    return null;
  }

  return (
    <div className="table-item-group">
      <div className="table-item-group-scroll">
        <TableItemGroupGeneral />
        <TableItemGroupResults />
        <TableItemGroupComment />
      </div>
      <WithAutoDirection>
        <TableItemGroupHeader />
      </WithAutoDirection>
    </div>
  );
}

export default function TableItemGroupComponent(props) {
  const encounterPageContext = useContext(EncounterPageContext);
  return (
    <TableItemGroupProvider permissionType={encounterPageContext?.selectedItemGroup?.permissionType}>
      <TableItemGroup {...props} />
    </TableItemGroupProvider>
  );
}
