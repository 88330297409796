import React, { useCallback, useEffect, useState } from 'react';

import FinPayeeApi from '../../../../api/finance/FinPayeeApi';
import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../common/general/Button';
import { MANAGE_PAYEE_ACCOUNTS } from '../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../services/auth';
import { PageInfoHeader } from '../../../PageInfoHeader/PageInfoHeader';

import { AccountSection } from './AccountSection/AccountSection';
import { AddPayableToModal } from './PayableToSection/AddPayableToModal/AddPayableToModal';
import { PayableToSection } from './PayableToSection/PayableToSection';

import './Accounts.scss';

export const Accounts = () => {
  const [payees, setPayees] = useState([]);

  const loadActivePayee = useCallback(() => {
    FinPayeeApi.findAllActive().then(({ data: payees }) => {
      const preparedPayees = [];
      for (let i = 0; i < payees.length; i += 5) {
        preparedPayees.push(payees.slice(i, i + 5));
      }
      setPayees(preparedPayees);
    });
  }, []);

  useEffect(() => {
    loadActivePayee();
  }, [loadActivePayee]);

  const openPayableToModal = (type, id = null, updateCallBack) => {
    ModalBoxes.open({
      component: <AddPayableToModal type={type} id={id} updateCallBack={updateCallBack} />,
      size: 'w800',
      className: 'add-payable-to-modal-box'
    });
  };

  return (
    <div className="accounts-page">
      <PageInfoHeader
        right={
          userHasAccessTo(MANAGE_PAYEE_ACCOUNTS) && (
            <Button
              className="its-export"
              size="h28"
              onClick={() => openPayableToModal('Add', null, loadActivePayee)}
              disabled={false}
            >
              Add Account
            </Button>
          )
        }
      >
        <PayableToSection openPayableToModal={openPayableToModal} payees={payees} loadActivePayee={loadActivePayee} />
      </PageInfoHeader>
      <AccountSection />
    </div>
  );
};
