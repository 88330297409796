import React from 'react';
import * as moment from 'moment/moment';

import { getPatientAgeFromDob } from '../../../../../../../services/patient';
import { calculateBMI } from '../../../PatientInfo/PatientInfoMainSection/AddOrEditPatientInfoModal/PatientDemographics/PatientDemographics';
import { usePatientProfile } from '../../PatientProfileContext';

export default function PatientDetailsDemographicsInformation() {
  const { patientInfo, dictionariesMap } = usePatientProfile();
  const { dob, sex, heightFt, heightIn, weight, races, ethnicities } = patientInfo || {};
  const bmiValue = calculateBMI(weight, heightFt, heightIn);

  return (
    <div className="patient-profile-information-content-section">
      <div className="patient-profile-information-header">
        <div className="patient-profile-information-header-title">Patient Demographics</div>
      </div>

      <div className="patient-profile-information-data-row">
        <div className="patient-profile-information-label">
          <div className="patient-profile-information-label-field">Date of Birth:</div>
          <div className="patient-profile-information-label-field">{`${moment.isMoment(dob) &&
            dob.format('DD/MMM/YYYY')} (${getPatientAgeFromDob(patientInfo)})`}</div>
        </div>

        <div className="patient-profile-information-label">
          <div className="patient-profile-information-label-field">Sex:</div>
          <div className="patient-profile-information-label-field">
            {sex ? dictionariesMap?.sexCodes[sex]?.name : '-'}
          </div>
        </div>

        <div className="patient-profile-information-label">
          <div className="patient-profile-information-label-field">Height:</div>
          <div className="patient-profile-information-label-field">{heightFt ? `${heightFt}'${heightIn}"` : '-'}</div>
        </div>

        <div className="patient-profile-information-label">
          <div className="patient-profile-information-label-field">Weight:</div>
          <div className="patient-profile-information-label-field">{weight ? `${weight} lbs` : '-'}</div>
        </div>

        <div className="patient-profile-information-label">
          <div className="patient-profile-information-label-field">BMI:</div>
          <div className="patient-profile-information-label-field">{bmiValue || '-'}</div>
        </div>
      </div>

      <div className="patient-profile-information-data-row">
        <div className="patient-profile-information-label">
          <div className="patient-profile-information-label-field">Race:</div>
          <div className="patient-profile-information-label-field">{normalizeRaceValues()}</div>
        </div>

        <div className="patient-profile-information-label">
          <div className="patient-profile-information-label-field">Ethnicity:</div>
          <div className="patient-profile-information-label-field">{normalizeEthnicityValues()}</div>
        </div>
      </div>
    </div>
  );

  function normalizeRaceValues() {
    return races?.map(race => dictionariesMap.raceCodes[race].name).join(', ') || '-';
  }

  function normalizeEthnicityValues() {
    return ethnicities?.map(ethnicity => dictionariesMap.ethnicityCodes[ethnicity].name).join(', ') || '-';
  }
}
