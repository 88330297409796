import React, { useState } from 'react';
import moment from 'moment';

import TextArea from '../../../../../../../../common/data-entry/TextArea';
import Button from '../../../../../../../../common/general/Button';
import Icon from '../../../../../../../../common/general/Icon';
import { dateFormat } from '../../taskConstants';

export default function TaskDetailsComments({ taskForm, setTaskCommentField }) {
  const { taskComments, taskCommentField } = taskForm;
  const [editMode, setEditMode] = useState(false);

  return (
    <div className="etc-body-details">
      <div className="etc-body-details-block">
        <div className="etc-body-details-block-label">
          Comments
          <Button onClick={() => setEditMode(!editMode)} priority="medium" size="h28">
            <Icon>add</Icon> Add
          </Button>
        </div>
        <div className="etc-body-details-block-value">
          {editMode && (
            <TextArea
              id="add-comment-input"
              name="description"
              value={taskCommentField}
              onChange={({ target }) => {
                setTaskCommentField(target.value);
              }}
              maxLength="300"
              showCount
            />
          )}
          {taskComments?.map(comment => (
            <div className="etc-body-details-comment" key={comment.creationDate}>
              <Icon suit={'material-outline'}>forum</Icon>
              <p>
                {`${formatDate(comment.creationDate)} by ${comment?.createdByFirstName} ${comment?.createdByLastName}`}:
                <span>{`${comment?.commentText}`}</span>
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function formatDate(str) {
  return moment(str).format(dateFormat);
}
