import React from 'react';

import './ProtocolGroupsSetupSkeleton.scss';

function ProtocolGroupsSetupSkeleton() {
  return (
    <div className="protocol-groups-list-skeleton">
      <div className="protocol-groups-list-skeleton-title" />
      <ul className="protocol-groups-list-skeleton-items">
        <li style={{ width: '170px' }} />
        <li style={{ width: '120px' }} />
        <li style={{ width: '90px' }} />
        <li style={{ width: '120px' }} />
      </ul>
    </div>
  );
}

export default ProtocolGroupsSetupSkeleton;
