import React, { useState } from 'react';

import { DirectionInputSection } from './DirectionInputSection';

export const DirectionSetup = ({ onChangeDirections, initialDirections }) => {
  const [encounterDirection, setEncounterDirection] = useState(initialDirections?.encounterDirection || '');
  const [logDirection, setLogDirection] = useState(initialDirections?.logDirection || '');
  return (
    <div className="col-12 mt-3 p-0 encounter-check-container">
      <div className="p-1 encounter-check-header">
        <h5>Directions</h5>
      </div>
      <div className="row p-3 m-0">
        <DirectionInputSection
          direction={encounterDirection}
          changeDirection={value => {
            setEncounterDirection(value);
            onChangeDirections({ encounterDirection: value, logDirection: logDirection });
          }}
          title={'Encounter Direction'}
        />
        <DirectionInputSection
          direction={logDirection}
          changeDirection={value => {
            setLogDirection(value);
            onChangeDirections({ logDirection: value, encounterDirection: encounterDirection });
          }}
          title={'Log Direction'}
        />
      </div>
    </div>
  );
};
