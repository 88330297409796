import React, { useEffect, useRef, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import { isEmpty } from 'lodash/lang';
import printJS from 'print-js';

import { FinInvoiceApi } from '../../../../../../../api';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { onRequestError } from '../../../../../../../services/handlers';
import { downloadFile, generateEmailPDF, resendEmail } from '../../CreateInvoiceServices';

import { EmailDetailsPDFTemplate } from './EmailDetailsPDFTemplate/EmailDetailsPDFTemplate';

import './EmailDetailsModal.scss';

export const EmailDetailsModal = ({
  modalBox,
  emailId,
  filePreview,
  invoiceNumber,
  emailModalBoxHeader,
  currentInvoiceType,
  billToList,
  invoiceId,
  setInvoiceHistory
}) => {
  const [emailData, setEmailData] = useState(null);
  const [linkAreas, setLinkAreas] = useState([]);
  const emailTemplateRef = useRef(null);

  useEffect(() => {
    FinInvoiceApi.getInvoiceEmail(emailId).then(({ data }) => {
      setEmailData(data);
    }, onRequestError);
  }, [emailId]);

  useEffect(() => {
    if (!emailTemplateRef.current || !isEmpty(linkAreas)) {
      return;
    }
    updateArea();
    function updateArea() {
      const links = emailTemplateRef.current.querySelectorAll('.file-link');
      if ([...links].length) {
        const areas = [...links].map(({ offsetHeight, offsetWidth, offsetTop, offsetLeft }) => ({
          height: offsetHeight,
          width: offsetWidth,
          y: offsetTop - 70,
          x: offsetLeft
        }));
        setLinkAreas(areas);
      }
    }
  });

  return (
    <>
      <ModalBoxes.Header>{emailData?.subject}</ModalBoxes.Header>
      <ModalBoxes.Body>
        {!!emailData && (
          <EmailDetailsPDFTemplate emailData={emailData} filePreview={filePreview} ref={emailTemplateRef} />
        )}
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <div className="action-panel">
          <DownloadIcon
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              generateEmailPDF(
                emailTemplateRef,
                file => downloadFile(file, 'EmailDetails.pdf'),
                emailData?.attachments,
                linkAreas
              )
            }
          />
          <PrintIcon
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              generateEmailPDF(
                emailTemplateRef,
                file => printJS({ printable: file.split(',')[1], type: 'pdf', base64: true }),
                emailData?.attachments,
                linkAreas
              )
            }
          />
          <SendIcon
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              modalBox.close();
              resendEmail(
                emailData,
                invoiceNumber,
                emailModalBoxHeader,
                currentInvoiceType,
                billToList,
                invoiceId,
                setInvoiceHistory
              );
            }}
          />
        </div>
        <Button
          sx={{
            backgroundColor: '#691E44',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#7d3559'
            }
          }}
          variant="contained"
          onClick={() => {
            modalBox.close();
          }}
        >
          Close
        </Button>
      </ModalBoxes.Footer>
    </>
  );
};
