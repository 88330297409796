import axios from 'axios';

const ROOT = 'api/v1/finance';

const FinStudySiteApi = {
  allStudiesWithProtocolAssigned() {
    return axios.get(`${ROOT}/studies/protocol-assigned`);
  }
};

export default FinStudySiteApi;
