import axios from 'axios';

const ROOT = 'api/v1/ssu-patients';

const StatusChangeSupplierApi = {
  getCheckedInEncounters(ssuPatientId) {
    return axios.get(`${ROOT}/${ssuPatientId}/encounters`, { hideLoader: true });
  }
};

export default StatusChangeSupplierApi;
