import React from 'react';

import { DirectionSection } from '../../../../patient-source/Patients/Logs/DirectionSection';
import TableView from '../../DomainTable/DomainTable';
import ItemGroupFormPreview from '../../ItemGroupFormPreview/ItemGroupFormPreview';
import IcfSetup from '../ICF/IcfSetup';
import { getPredefineColumnValues } from '../itemGroupSetupService';
import { LogCheckPreviewNew } from '../LogCheckSetup/LogCheckPreviewNew';

import { customClassObject } from './customClassObject';
import { dynamicClassesForFieldName } from './itemGroupSetupRenderHelpService';

export function RenderForm({ formData, displayTableType, elementIcon, inputs, isLogCheckSetupEnabled }) {
  if (elementIcon === 'Procedure' && formData.hasOwnProperty('columnList') && formData?.columnList?.length > 0) {
    formData.columnList = [];
  }
  formData.labelList.forEach(item => {
    if (item.inputType === 'radio') {
      item.inputValue = 0;
    }
  });

  const predefinedTableData = {
    predefinedColumnName: inputs[0] ? inputs[0].predefinedColumnName : '',
    predefinedColumnValues: getPredefineColumnValues(inputs[0])
  };
  if (Object.keys(formData).length === 0) {
    return <div className="alert alert-info p-1 text-center">Please Add Items</div>;
  }
  return (
    <React.Fragment>
      {isLogCheckSetupEnabled && (
        <LogCheckPreviewNew logCheckOptions={formData.logCheckOptions} domain={formData.domainCode} />
      )}

      {formData.domainCode === 'ICF' && elementIcon === 'ICF' && (
        <IcfSetup
          informedConsentTemplate={formData.informedConsentTemplate}
          onChangeLanguage={e => {
            if (formData.informedConsentTemplate) {
              formData.informedConsentTemplate.languageId = e?.id || null;
            } else {
              formData.informedConsentTemplate = { languageId: e?.id || null };
            }
          }}
          onChangeType={e => {
            if (formData.informedConsentTemplate) {
              formData.informedConsentTemplate.typeId = e?.id || null;
            } else {
              formData.informedConsentTemplate = { typeId: e?.id || null };
            }
          }}
        />
      )}
      {isLogCheckSetupEnabled && <DirectionSection directions={formData.directions} isPreview={true} />}
      {formData.columnList && formData.columnList.length > 0 ? (
        <TableView
          displayElementIconTableType={displayTableType}
          predefinedTableData={predefinedTableData}
          tableData={[formData]}
          elementIcon={elementIcon}
        />
      ) : (
        <div>
          <div className="form-group pt-2 row col-xs-12 col-md-12 position-relative ml-0 mr-0 p-0">
            <ItemGroupFormPreview
              formData={formData}
              dynamicClassesForFieldName={dynamicClassesForFieldName}
              customClassObject={customClassObject}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
