import React, { useEffect, useState } from 'react';
import { some } from 'lodash/collection';
import uuid from 'uuid/v4';

import ModalBoxes from '../../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../../../../../common/notifications/NotificationManager';
import {
  DUPLICATE_CUSTOM_OPTION_VALUES_NOT_ALLOWED,
  FINAL_OPTION_LIST_REQUIRED,
  ITEM_ALREADY_EXISTS
} from '../../../../../../../../../constants/notificationMessages';
import OptionInput from '../EditControlledTermListModal/OptionInput';

import DragAndDropFinalOptionList from './DrafAndDropFinalOptionList/DragAndDropFinalOptionList';

import './EditCustomOptionListModal.scss';

const EditCustomOptionListModal = ({
  itemIndex,
  inputType,
  general,
  updateCustomOptionsList,
  modalBox,
  initialOptionsList
}) => {
  const [finalOptionsList, setFinalOptionsList] = useState([]);

  useEffect(() => {
    if (initialOptionsList) {
      setFinalOptionsList(initialOptionsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addNewOptionInFinalList = newValue => {
    if (some(finalOptionsList, ['name', newValue])) {
      NotificationManager.error(DUPLICATE_CUSTOM_OPTION_VALUES_NOT_ALLOWED);
    } else {
      const newOptionItem = {
        codedValue: null,
        codeOid: '',
        decode: newValue,
        inputType: ['dropdown', 'dropdownCT'].includes(inputType) ? 'dropdown' : 'multiselect',
        name: newValue,
        sequence: finalOptionsList.length + 1,
        id: uuid()
      };
      setFinalOptionsList([...finalOptionsList, newOptionItem]);
    }
  };

  return (
    <>
      <ModalBoxes.Header>Edit Custom Option List</ModalBoxes.Header>
      <ModalBoxes.Body>
        <OptionInput addNewCustomOptionInFinalList={addNewOptionInFinalList} label="Custom Option List" />
        <DragAndDropFinalOptionList
          onDragEnd={result => onDragEnd(result, finalOptionsList, setFinalOptionsList)}
          finalOptionList={finalOptionsList}
          deleteOptionFromList={index => deleteOptionFromList(index, finalOptionsList, setFinalOptionsList)}
          toggleEditModeForLabel={itemIndex => toggleEditModeForLabel(itemIndex, finalOptionsList, setFinalOptionsList)}
          updateItemName={(oldName, newName) => updateItemName(oldName, newName, finalOptionsList, setFinalOptionsList)}
        />
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority={'medium'} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onSave}>Save</Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );

  function onClose() {
    modalBox.close();
  }
  function onSave() {
    if (!finalOptionsList.length) {
      NotificationManager.error(FINAL_OPTION_LIST_REQUIRED);
    } else {
      const finalOptionListForSave = [...finalOptionsList];
      finalOptionListForSave.forEach(element => delete element.edit);
      updateCustomOptionsList(finalOptionListForSave, itemIndex, general);
      modalBox.close();
    }
  }
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result.map((option, index) => ({ ...option, sequence: index + 1 }));
};

export const onDragEnd = (result, initialList, updateList, modification = null, fieldsFromAnotherSection = null) => {
  if (!result.destination) {
    return;
  }
  const items = reorder(initialList, result.source.index, result.destination.index);
  if (modification) {
    if (items[0]?.tableGeneralField) {
      const filledList = modification(items, fieldsFromAnotherSection);
      updateList(filledList[0]);
    } else {
      const filledList = modification(fieldsFromAnotherSection, items);
      updateList(filledList[1]);
    }
  } else {
    updateList(items);
  }
};

export const deleteOptionFromList = (indexForDelete, initialOptionList, updateOptionList) => {
  const updatedList = [...initialOptionList];
  updatedList.splice(indexForDelete, 1);
  const updatedListWithUpdatedSequence = updatedList.map((label, index) => {
    let updatedLabel = { ...label };
    updatedLabel.sequence = index + 1;
    return updatedLabel;
  });
  updateOptionList(updatedListWithUpdatedSequence);
};

export const toggleEditModeForLabel = (itemIndex, finalOptionsList, setFinalOptionsList) => {
  const finalOptionListWithEditMode = finalOptionsList.map((element, index) => {
    if (itemIndex === index) {
      return { ...element, edit: !element.edit };
    }
    return { ...element, edit: false };
  });
  setFinalOptionsList(finalOptionListWithEditMode);
};

export const updateItemName = (oldName, newName, finalOptionsList, setFinalOptionsList) => {
  if (some(finalOptionsList, ['name', newName.trim()]) && oldName !== newName) {
    NotificationManager.error(ITEM_ALREADY_EXISTS);
  } else {
    const updatedItems = finalOptionsList.map(element => {
      if (element.name === oldName) {
        return { ...element, name: newName, decode: newName, edit: false };
      }
      return { ...element, edit: false };
    });
    setFinalOptionsList(updatedItems);
  }
};

EditCustomOptionListModal.className = 'edit-custom-option-list-modal';
EditCustomOptionListModal.size = 'w950';

export default EditCustomOptionListModal;
