class NewRelic {
  constructor() {
    this.interaction = null;
    this.active = false;
    this.requestCount = 0;
  }

  start() {
    this.active = true;
    const newrelic = window.newrelic;
    if (newrelic && !this.requestCount) {
      this.interaction = newrelic.interaction();
      this.interaction.save();
    }
    this.requestCount++;
  }

  end() {
    if (this.requestCount && this.active) {
      this.requestCount--;
    }
    if (!this.requestCount && this.active) {
      this.active = false;
      if (this.interaction) {
        this.interaction.end();
        this.interaction = null;
      }
    }
  }

  noticeError(err) {
    const newrelic = window.newrelic;
    if (newrelic) {
      newrelic.noticeError(err);
    }
  }
}

export default new NewRelic();
