import axios from 'axios';

const ROOT = 'api/v1/ssu-patients';

const PrintSsuPatientEncountersApi = {
  getSsuPatientEncountersForPrintMetaInfo(ssuPatientId) {
    return axios.get(`${ROOT}/${ssuPatientId}/encounters/print-meta-info`);
  },

  downloadSsuPatientFiles(ssuPatientId, data) {
    return axios.post(`${ROOT}/${ssuPatientId}/encounters/download-files`, data, {
      responseType: 'arraybuffer'
    });
  },

  getPatientExportCaseReportForm(ssuPatientId, data) {
    return axios.post(`${ROOT}/${ssuPatientId}/encounters/generate-template`, data);
  }
};

export default PrintSsuPatientEncountersApi;
