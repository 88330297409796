import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';
import { filter } from 'lodash/collection';
import { isEqual } from 'lodash/lang';

import './validationSchema';

import PatientTravelApi from '../../../../../../../api/patient/PatientTravelApi';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';

import { seatingPreferences } from './constants';
import { validationSchema } from './validationSchema';
export default function AirlinePreferences({ initialData, setTravelPreferences }) {
  const { patientId } = useParams();

  const [saving, setSaving] = useState(false);
  const [state, setState] = useState({
    preferredTravelTime: [],
    preferredAirline: '',
    frequentFlightNumber: '',
    seatingPreference: '',
    preferredAirport: '',
    specialAccommodation: '',
    ...initialData
  });

  const {
    preferredTravelTime,
    preferredAirline,
    frequentFlightNumber,
    seatingPreference,
    preferredAirport,
    specialAccommodation
  } = state;

  const { saveAllowed, discardAllowed } = useMemo(
    function() {
      let saveAllowed = false;
      let discardAllowed = false;
      if (!saving && !isEqual(initialData, state)) {
        saveAllowed = true;
        discardAllowed = true;
      }
      return {
        saveAllowed,
        discardAllowed
      };
    },
    [initialData, state, saving]
  );

  useEffect(
    function() {
      setTravelPreferences(function(state) {
        return { ...state, airlinePreferencesEdited: saveAllowed };
      });
    },
    [saveAllowed, setTravelPreferences]
  );

  return (
    <Box sx={{ flexGrow: 1, mt: 3 }} component="form">
      <Grid container spacing={2} alignItems="center">
        <Grid xs={6}>
          <Typography variant="subtitle2">Airline Preferences</Typography>
        </Grid>
        <Grid xs={6}>
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <IconButton aria-label="save" disabled={!saveAllowed} color="primary" onClick={onSave} title="Save">
              <SaveIcon />
            </IconButton>
            <IconButton
              aria-label="discard"
              disabled={!discardAllowed}
              color="primary"
              onClick={onDiscard}
              title="Discard changes"
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </Grid>
        <Grid xs={4}>
          <FormControl sx={{ mx: 2 }} component="fieldset" variant="standard">
            <FormLabel component="legend">Preferred Travel Time</FormLabel>
            <FormGroup sx={{ mx: 2 }}>
              <Grid container spacing={0} alignItems="center">
                <Grid xs={6}>
                  <FormControlLabel
                    sx={{ margin: 0 }}
                    control={
                      <Checkbox
                        checked={preferredTravelTime.includes('EARLY_MORNING')}
                        onChange={preferredTravelTimeOnChange}
                        name="EARLY_MORNING"
                      />
                    }
                    label="Early morning"
                  />
                </Grid>
                <Grid xs={6}>
                  <FormControlLabel
                    sx={{ margin: 0 }}
                    control={
                      <Checkbox
                        checked={preferredTravelTime.includes('LATE_MORNING')}
                        onChange={preferredTravelTimeOnChange}
                        name="LATE_MORNING"
                      />
                    }
                    label="Late morning"
                  />
                </Grid>
                <Grid xs={6}>
                  <FormControlLabel
                    sx={{ margin: 0 }}
                    control={
                      <Checkbox
                        checked={preferredTravelTime.includes('AFTERNOON')}
                        onChange={preferredTravelTimeOnChange}
                        name="AFTERNOON"
                      />
                    }
                    label="Afternoon"
                  />
                </Grid>
                <Grid xs={6}>
                  <FormControlLabel
                    sx={{ margin: 0 }}
                    control={
                      <Checkbox
                        checked={preferredTravelTime.includes('EVENING')}
                        onChange={preferredTravelTimeOnChange}
                        name="EVENING"
                      />
                    }
                    label="Evening"
                  />
                </Grid>
              </Grid>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid xs={8}></Grid>
        <Grid xs={5}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid xs={6}>
                <TextField
                  name="preferredAirline"
                  value={preferredAirline}
                  onChange={handleChange}
                  fullWidth
                  label="Preferred airline"
                  variant="standard"
                  autoComplete="off"
                />
              </Grid>
              <Grid xs={6}>
                <TextField
                  name="frequentFlightNumber"
                  value={frequentFlightNumber}
                  onChange={handleChange}
                  fullWidth
                  label="Frequent Flight #"
                  variant="standard"
                  autoComplete="off"
                />
              </Grid>
              <Grid xs={6}>
                <TextField
                  name="seatingPreference"
                  value={seatingPreference}
                  onChange={handleChange}
                  fullWidth
                  select
                  label="Seating preferences"
                  variant="standard"
                  required
                >
                  {seatingPreferences.map(e => (
                    <MenuItem key={e.id} value={e.id}>
                      {e.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid xs={6}>
                <TextField
                  name="preferredAirport"
                  value={preferredAirport}
                  onChange={handleChange}
                  fullWidth
                  label="Preferred Airport"
                  variant="standard"
                  autoComplete="off"
                />
              </Grid>
              <Grid xs={12}>
                <TextField
                  name="specialAccommodation"
                  value={specialAccommodation}
                  onChange={handleChange}
                  fullWidth
                  label="Special Accommodation"
                  multiline
                  maxRows={4}
                  variant="standard"
                  inputProps={{ maxLength: 200 }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={7}></Grid>
      </Grid>
    </Box>
  );

  function preferredTravelTimeOnChange({ target }) {
    setState(function(state) {
      const preferredTravelTime = target.checked
        ? [...state.preferredTravelTime, target.name]
        : filter(state.preferredTravelTime, t => t !== target.name);

      try {
        const nextState = { ...state, preferredTravelTime };
        validationSchema.validateSyncAt('preferredTravelTime', nextState);
        return nextState;
      } catch (e) {
        return state;
      }
    });
  }

  function handleChange({ target }) {
    setState(function(state) {
      try {
        const nextState = { ...state, [target.name]: target.value };
        validationSchema.validateSyncAt(target.name, nextState);
        return nextState;
      } catch (e) {
        return state;
      }
    });
  }

  function onSave() {
    setSaving(true);
    PatientTravelApi.saveAirlinePreferences(patientId, state)
      .then(
        function() {
          setTravelPreferences(function(prevState) {
            return { ...prevState, airlinePreferences: state };
          });
          NotificationManager.success('Airline Preferences saved successfully');
        },
        function(error) {
          NotificationManager.error('Airline Preferences save error');
        }
      )
      .finally(function() {
        setSaving(false);
      });
  }

  function onDiscard() {
    setState(initialData);
  }
}
