import React, { useContext, useMemo } from 'react';
import { last } from 'lodash/array';

import Loader from '../../../../../../common/elements/Loader/Loader';
import { ReviewContext } from '../ReviewContext';
import {
  itemGroupSnapshotReviewStateHasRecordsOrNotPerformed,
  logCheckSnapshotReviewStateHasRecords,
  logSnapshotReviewStateHasRecords
} from '../reviewService';

import ItemGroupElement from './ItemGroupElement/ItemGroupElement';
import LogItemGroupElement from './LogItemGroupElement/LogItemGroupElement';
import NoSourceData from './NoSourceData/NoSourceData';
import ReviewContentElement from './ReviewContentElement/ReviewContentElement';
import {
  ReviewContentLogElementProvider,
  ReviewContentProcedureElementProvider
} from './ReviewContentElementContext/ReviewContentElementContext';
import ScrollTopButton from './ScrollTopButton/ScrollTopButton';
import { getItemGroupType } from './reviewContentService';

import './ReviewContent.scss';

export function ReviewContent() {
  const {
    itemGroupSnapshotReviewStates,
    logAndLogCheckSnapshotReviewStates,
    itemGroups,
    logsAndLogChecks,
    reviewContentRefContainer,
    activeScrollId
  } = useContext(ReviewContext);

  const isAllDataLoaded = useMemo(
    /*TODO: need to fix*/
    function() {
      const allItemGroup = [...logsAndLogChecks, ...itemGroups];

      const lengthOfLogChecksWhichHasSnapshot = Object.values({ ...logAndLogCheckSnapshotReviewStates }).length;
      const lengthOfItemGroupWhichHasSnapshot =
        Object.values({
          ...itemGroupSnapshotReviewStates
        }).length + lengthOfLogChecksWhichHasSnapshot;
      return itemGroups && logsAndLogChecks && lengthOfItemGroupWhichHasSnapshot === allItemGroup.length;
    },
    [itemGroupSnapshotReviewStates, itemGroups, logsAndLogChecks, logAndLogCheckSnapshotReviewStates]
  );

  const lastElementId = getLastElementId();
  const rcHeight = reviewContentRefContainer?.current?.offsetHeight;

  return (
    <div className="erv-review-content" ref={reviewContentRefContainer}>
      {itemGroups &&
        itemGroups.map(item => {
          const patientItemGroupId = item.patientItemGroupId;
          const itemGroupSnapshotReviewState = itemGroupSnapshotReviewStates[patientItemGroupId];
          const isItemGroupPresent = !!itemGroupSnapshotReviewStateHasRecordsOrNotPerformed(
            itemGroupSnapshotReviewState
          );

          return (
            <ReviewContentProcedureElementProvider
              key={patientItemGroupId}
              data={itemGroupSnapshotReviewState}
              item={item}
            >
              <ReviewContentElement
                className={activeScrollId === patientItemGroupId ? 'erc-element-active' : null}
                rcHeight={rcHeight}
                lastElementId={lastElementId}
                scrollId={patientItemGroupId}
              >
                {isItemGroupPresent ? (
                  <ItemGroupElement
                    key={patientItemGroupId}
                    itemGroupSnapshotState={itemGroupSnapshotReviewState?.itemGroupSnapshotState}
                    snapshotAnswers={itemGroupSnapshotReviewState?.snapshotAnswers}
                    reviewStatus={itemGroupSnapshotReviewState?.reviewState.reviewStatus}
                  />
                ) : (
                  <NoSourceData
                    itemGroupName={
                      itemGroupSnapshotReviewState?.itemGroupSnapshotState?.itemGroupRef?.patientItemGroupName
                    }
                    itemGroupType={
                      itemGroupSnapshotReviewState?.itemGroupSnapshotState?.itemGroupRef &&
                      getItemGroupType(itemGroupSnapshotReviewState?.itemGroupSnapshotState?.itemGroupRef)
                    }
                    itemGroupId={itemGroupSnapshotReviewState?.itemGroupSnapshotState?.itemGroupRef?.patientItemGroupId}
                  />
                )}
              </ReviewContentElement>
            </ReviewContentProcedureElementProvider>
          );
        })}
      {logsAndLogChecks &&
        logsAndLogChecks.map(item => {
          const itemGroupTemplateId = item.itemGroupTemplateId;
          const logAndLogCheckSnapshotReviewState = logAndLogCheckSnapshotReviewStates[itemGroupTemplateId];
          const isLogPresent = !!logSnapshotReviewStateHasRecords(logAndLogCheckSnapshotReviewState);
          const isLogCheckPresent = logCheckSnapshotReviewStateHasRecords(logAndLogCheckSnapshotReviewState);
          const logCheckItem = getLogCheckItemByItemGroupTemplateId(itemGroupTemplateId);

          return (
            <ReviewContentLogElementProvider
              key={itemGroupTemplateId}
              data={logAndLogCheckSnapshotReviewState}
              item={item}
            >
              <ReviewContentElement
                className={activeScrollId === itemGroupTemplateId ? 'erc-element-active' : null}
                rcHeight={rcHeight}
                lastElementId={lastElementId}
                scrollId={itemGroupTemplateId}
              >
                {isLogPresent || isLogCheckPresent ? (
                  <LogItemGroupElement
                    key={itemGroupTemplateId}
                    isLogPresent={isLogPresent}
                    isLogCheckPresent={isLogCheckPresent}
                    logAndLogCheckSnapshotReviewState={logAndLogCheckSnapshotReviewState}
                    logCheckItem={logCheckItem}
                  />
                ) : (
                  <NoSourceData
                    itemGroupName={logAndLogCheckSnapshotReviewState?.logFormRef?.name}
                    itemGroupType="Logs"
                    itemGroupId={logAndLogCheckSnapshotReviewState?.logFormRef?.itemGroupId}
                  />
                )}
              </ReviewContentElement>
            </ReviewContentLogElementProvider>
          );
        })}
      {!isAllDataLoaded && (
        <div className="erc-element">
          <div className="review-element-data-loader">
            <Loader dark={true} />
          </div>
        </div>
      )}
      <ScrollTopButton container={reviewContentRefContainer?.current} />
    </div>
  );

  function getLogCheckItemByItemGroupTemplateId(itemGroupTemplateId) {
    if (logsAndLogChecks) {
      return logsAndLogChecks.find(item => item.itemGroupTemplateId === itemGroupTemplateId);
    }
    return {};
  }

  function getLastElementId() {
    return last(logsAndLogChecks)?.itemGroupTemplateId || last(itemGroups)?.patientItemGroupId;
  }
}
