import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import isFunction from 'lodash/isFunction';
import { isUndefined } from 'lodash/lang';
import PropTypes from 'prop-types';

import './Toggle.scss';

const Toggle = React.forwardRef(function(props, ref) {
  const { className, onChange, children, checked: propChecked, defaultChecked, disabled, ...restProps } = props;
  const initialChecked = useMemo(() => !!(isUndefined(propChecked) ? defaultChecked : propChecked), [
    propChecked,
    defaultChecked
  ]);
  const [checked, setChecked] = useState(initialChecked);

  useEffect(
    function() {
      setChecked(checked => {
        if (!isUndefined(propChecked) && propChecked !== checked) {
          return propChecked;
        }
        return checked;
      });
    },
    [propChecked]
  );

  const inputRef = useRef();
  useImperativeHandle(ref, () => inputRef.current);
  return (
    <label className={cx('eui-toggle', { 'eui-toggle-disabled': disabled }, className)}>
      <input
        disabled={disabled}
        ref={inputRef}
        type="checkbox"
        checked={checked}
        aria-checked={checked}
        onChange={handleChange}
        {...restProps}
      />
      {children && <span className="eui-toggle-label">{children}</span>}
      <span className="eui-toggle-control" />
    </label>
  );

  function handleChange(event) {
    event.persist();
    setChecked(event.target.checked);
    isFunction(onChange) && onChange(event);
  }
});

Toggle.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool
};
Toggle.defaultProps = {
  disabled: false
};

export default Toggle;
