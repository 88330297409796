import { useMemo } from 'react';
import { uniq } from 'lodash/array';
import { filter, flatMap, includes } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';
import { omit } from 'lodash/object';

import useDeepCompareMemoize from '../../../../../../../common/hooks/useDeepCompareMemoize';
import { collectAllQuestionsField } from '../services/fieldsServices';

export default function useRequiredAndHiddenFieldsIds(
  encounterTableItemGroup,
  originConditionalLogicAndRequiredFieldsMaps
) {
  return useDeepCompareMemoize(
    useMemo(
      function() {
        const allQuestions = collectAllQuestionsField(encounterTableItemGroup);
        const [originRequiredFieldIds, originConditionalLogicMap] = originConditionalLogicAndRequiredFieldsMaps;
        const hiddenFieldsIds = calculateHiddenFieldsIds(allQuestions, originConditionalLogicMap);
        const requiredFieldsIds = filter(originRequiredFieldIds, fieldUid => !includes(hiddenFieldsIds, fieldUid));
        return [requiredFieldsIds, hiddenFieldsIds];
      },
      [encounterTableItemGroup, originConditionalLogicAndRequiredFieldsMaps]
    )
  );
}

function calculateHiddenFieldsIds(allQuestions, originConditionalLogicMap) {
  return uniq(
    flatMap(allQuestions, function({ fieldUid, fieldValue }) {
      const matchedConditionalLogic = !isEmpty(fieldValue) && omit(originConditionalLogicMap[fieldUid], fieldValue);
      return flatMap(matchedConditionalLogic);
    })
  );
}
