import Button from '../../../../../../../../../../common/general/Button';
import { useEncountersSection } from '../../../../EncountersSectionContext';
import { useEncounterSegment } from '../EncounterSegment';

export default function HeaderProjected() {
  const { encounter, lastEncounterEvent } = useEncounterSegment();
  const { addAppointment } = useEncountersSection();
  const { projectedEncounterInfo } = encounter;

  return (
    <>
      <Button
        size="h28"
        onClick={() =>
          addAppointment(projectedEncounterInfo?.protocolEncounterId || lastEncounterEvent?.protocolEncounterId)
        }
      >
        Schedule Appointment
      </Button>
    </>
  );
}
