import React, { useCallback, useMemo, useState } from 'react';
import { every, groupBy, map, partition, reduce, size } from 'lodash/collection';
import { cloneDeep, isEmpty } from 'lodash/lang';
import { mapValues } from 'lodash/object';
import { array, objectOf, shape, string } from 'prop-types';

import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';
import { answerForDisplay } from '../services/fieldsServices';

import ChangeReason from './ChangeReason';

import './ChangedItemsModal.scss';

export default function ChangedItemsModal({ modalBox, onSave, data }) {
  const [changedItems, setChangedItems] = useState(data);

  const [generalFields, tableSettingsFields] = useMemo(
    function() {
      return partition(changedItems, ({ label }) => !label);
    },
    [changedItems]
  );

  const tableSettingsGroupedFields = useMemo(
    function() {
      return groupBy(tableSettingsFields, 'name');
    },
    [tableSettingsFields]
  );

  const [withReasonCount, fieldsCount] = useMemo(
    function() {
      const withReasonCount = reduce(
        changedItems,
        function(sum, { reason }) {
          if (!isEmpty(reason)) {
            return sum + 1;
          }
          return sum;
        },
        0
      );

      return [withReasonCount, size(changedItems)];
    },
    [changedItems]
  );

  const formIsValid = useMemo(
    function() {
      if (isEmpty(changedItems)) {
        return false;
      }
      return every(changedItems, function({ reason }) {
        return !isEmpty(reason);
      });
    },
    [changedItems]
  );

  const updateReasonForAllItems = useCallback(function(comment) {
    setChangedItems(function(missingItems) {
      return mapValues(missingItems, function({ reason, ...rest }) {
        return {
          reason: comment,
          ...rest
        };
      });
    });
  }, []);

  const updateReason = useCallback(function(reason, fieldUid) {
    setChangedItems(function(missingItems) {
      const missingItemsCopy = cloneDeep(missingItems);
      missingItemsCopy[fieldUid].reason = reason;
      return missingItemsCopy;
    });
  }, []);

  const onSaveHandler = useCallback(
    function() {
      onSave(changedItems);
    },
    [onSave, changedItems]
  );

  return (
    <>
      <ModalBoxes.Header>{size(data) > 1 ? 'Items' : 'Item'} Changed</ModalBoxes.Header>
      <ModalBoxes.Body>
        {fieldsCount > 1 && (
          <div className="pre-save-items-modal-group pre-save-items-modal-apply-to-all">
            <h5>Apply to all</h5>
            <ChangeReason updateReason={updateReasonForAllItems} />
          </div>
        )}
        {!isEmpty(generalFields) && (
          <div className="pre-save-items-modal-section">
            <h4>OVERVIEW</h4>
            {map(generalFields, function(field) {
              const { name, uid, value } = field;
              return (
                <div className="pre-save-items-modal-group" key={uid}>
                  <h5>{name}</h5>
                  <AnswerDifference answers={value} />
                  <ChangeReason field={field} updateReason={updateReason} />
                </div>
              );
            })}
          </div>
        )}
        {!isEmpty(tableSettingsGroupedFields) && (
          <div className="pre-save-items-modal-section">
            <h4>RESULTS</h4>
            {map(tableSettingsGroupedFields, function(fields, name) {
              return (
                <div className="pre-save-items-modal-group" key={name}>
                  <h5>{name}</h5>
                  {map(fields, function(field) {
                    const { label, uid, value } = field;
                    return (
                      <div className="pre-save-items-modal-group-item" key={uid}>
                        <h6>{label}</h6>
                        <AnswerDifference answers={value} />
                        <ChangeReason field={field} updateReason={updateReason} />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <div className="pre-save-items-modal-counter">
          With reason: {withReasonCount}/{fieldsCount}
        </div>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            CANCEL
          </Button>
          <Button onClick={onSaveHandler} priority="high" disabled={!formIsValid}>
            SAVE
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
}

function AnswerDifference({ answers }) {
  const [currentAnswer, previousAnswer] = useMemo(
    function() {
      const [currentAnswer, previousAnswer] = answers;
      return [answerForDisplay(currentAnswer), answerForDisplay(previousAnswer)];
    },
    [answers]
  );
  return (
    <div className="answer-difference">
      <div>
        <b>Previous:</b> {previousAnswer}
      </div>
      <div>
        <b>New:</b> {currentAnswer}
      </div>
    </div>
  );
}

ChangedItemsModal.propTypes = {
  data: objectOf(
    shape({
      label: string,
      uid: string.isRequired,
      name: string.isRequired,
      value: array.isRequired,
      reason: string
    })
  ).isRequired
};

ChangedItemsModal.className = 'pre-save-items-modal';
ChangedItemsModal.size = 'w950';
