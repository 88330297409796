import { PatientEncounterReviewApi } from '../../../../../api';
import PatientEncounterGroupAssignReviewApi from '../../../../../api/patient/PatientEncounterGroupAssignReviewApi';

import { isCraReview, isPiReview, isSmReview } from './reviewService';

const ReviewDataResolver = {
  getItemGroupsAndLogsByType(reviewType, { ssuPatientId, patientEncounterId, groupAssignId }) {
    if (isSmReview(reviewType)) {
      return PatientEncounterGroupAssignReviewApi.getSmReview(ssuPatientId, patientEncounterId, groupAssignId);
    }
    if (isPiReview(reviewType)) {
      return PatientEncounterGroupAssignReviewApi.getPiReview(ssuPatientId, patientEncounterId, groupAssignId);
    }
    if (isCraReview(reviewType)) {
      return PatientEncounterGroupAssignReviewApi.getCraReview(ssuPatientId, patientEncounterId);
    }
    return Promise.reject(new Error('Incorrect review type'));
  },
  getItemGroupRowSnapshotReviewStateByTypeAndSignatureId(
    reviewType,
    signatureId,
    { ssuPatientId, patientEncounterId, itemGroupIds, groupAssignId },
    hideLoader = true
  ) {
    if (signatureId) {
      return PatientEncounterGroupAssignReviewApi.getSignedItemGroupRowSnapshotReviewState(
        ssuPatientId,
        patientEncounterId,
        itemGroupIds,
        signatureId,
        hideLoader
      );
    }

    if (isSmReview(reviewType)) {
      return PatientEncounterGroupAssignReviewApi.getItemGroupRowSnapshotSmReviewsState(
        groupAssignId,
        ssuPatientId,
        patientEncounterId,
        itemGroupIds,
        hideLoader
      );
    }

    if (isPiReview(reviewType)) {
      return PatientEncounterGroupAssignReviewApi.getItemGroupRowSnapshotPiReviewsState(
        groupAssignId,
        ssuPatientId,
        patientEncounterId,
        itemGroupIds,
        hideLoader
      );
    }

    // if (isCraReview(reviewType)) {
    //   return PatientEncounterReviewApi.getItemGroupRowSnapshotCraReviewState(
    //     ssuPatientId,
    //     patientEncounterId,
    //     itemGroupId,
    //     hideLoader
    //   );
    // }

    return Promise.reject(new Error('Incorrect review type'));
  },

  getLogAndLogCheckSnapshotReviewStateByTypeAndSignatureId(
    reviewType,
    signatureId,
    { ssuPatientId, patientEncounterId, patientItemGroupId, itemGroupTemplateId, groupAssignId },
    hideLoader = true
  ) {
    if (!patientItemGroupId) {
      if (isSmReview(reviewType)) {
        return PatientEncounterGroupAssignReviewApi.getEncounterExcludedLogSmSnapshotReviewState(
          ssuPatientId,
          itemGroupTemplateId,
          hideLoader
        );
      }

      if (isPiReview(reviewType)) {
        return PatientEncounterGroupAssignReviewApi.getEncounterExcludedLogPiSnapshotReviewState(
          ssuPatientId,
          itemGroupTemplateId,
          hideLoader
        );
      }

      return Promise.reject(new Error('Incorrect review type'));
    }

    /*TODO: need to fix*/
    if (signatureId) {
      return PatientEncounterGroupAssignReviewApi.getSignedLogCheckRowSnapshotReviewState(
        ssuPatientId,
        patientEncounterId,
        patientItemGroupId,
        signatureId,
        hideLoader
      );
    }

    if (isSmReview(reviewType)) {
      return PatientEncounterGroupAssignReviewApi.getLogAndLogCheckSnapshotSmReviewState(
        groupAssignId,
        ssuPatientId,
        patientEncounterId,
        patientItemGroupId,
        hideLoader
      );
    }

    if (isPiReview(reviewType)) {
      return PatientEncounterGroupAssignReviewApi.getLogAndLogCheckSnapshotPiReviewState(
        groupAssignId,
        ssuPatientId,
        patientEncounterId,
        patientItemGroupId,
        hideLoader
      );
    }

    // if (isCraReview(reviewType)) {
    //   return PatientEncounterReviewApi.getLogSnapshotCraReviewState(
    //     ssuPatientId,
    //     patientEncounterId,
    //     itemGroupTemplateId,
    //     hideLoader
    //   );
    // }
    return Promise.reject(new Error('Incorrect review type'));
  },

  getReviewHistoryByReviewType(reviewType, ssuPatientId, patientEncounterId) {
    if (isSmReview(reviewType)) {
      return PatientEncounterReviewApi.getSmHistory(ssuPatientId, patientEncounterId);
    }
    if (isPiReview(reviewType)) {
      return PatientEncounterReviewApi.getPiHistory(ssuPatientId, patientEncounterId);
    }
    if (isCraReview(reviewType)) {
      return PatientEncounterReviewApi.getCraHistory(ssuPatientId, patientEncounterId);
    }
    return Promise.reject(new Error('Incorrect review type'));
  },

  signReview({ groupAssignId, ssuPatientId, patientEncounterId, request, token }, reviewType) {
    if (isSmReview(reviewType)) {
      return PatientEncounterGroupAssignReviewApi.signSmReview(
        groupAssignId,
        ssuPatientId,
        patientEncounterId,
        token,
        request
      );
    }
    if (isPiReview(reviewType)) {
      return PatientEncounterGroupAssignReviewApi.signPiReview(
        groupAssignId,
        ssuPatientId,
        patientEncounterId,
        token,
        request
      );
    }
    return Promise.reject(new Error('Unsupported review type'));
  }
};

export default ReviewDataResolver;
