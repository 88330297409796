import moment from 'moment';

import { resolveTerm } from '../CreateInvoice/CreateInvoiceServices';

import { CREDIT_MEMO, DEBIT_MEMO } from './TableConstants';

export function isCompleteStatus({ invoiceType }) {
  return invoiceType === CREDIT_MEMO || invoiceType === DEBIT_MEMO;
}

export function isOpenStatus({ amountPaid, invoiceDate, invoiceNumber, terms }) {
  if (amountPaid !== 0 && invoiceNumber) return false;
  const term = moment().subtract(resolveTerm(terms), 'days');
  const formattedInvoiceDate = invoiceDate ? moment(invoiceDate) : moment(0);
  return !invoiceNumber || (formattedInvoiceDate.isAfter(term) && formattedInvoiceDate.isSameOrBefore(moment()));
}

export function isPastDueStatus({ invoiceAmount, amountPaid, invoiceDate, terms }) {
  if (amountPaid >= invoiceAmount) return false;
  const formattedInvoiceDate = invoiceDate ? moment(invoiceDate) : moment(0);
  const term = moment().subtract(resolveTerm(terms), 'days');
  return formattedInvoiceDate.isBefore(term);
}

export function isPartialStatus({ amountPaid, invoiceAmount, invoiceDate, terms }) {
  if (amountPaid === 0) return false;
  const term = moment().subtract(resolveTerm(terms), 'days');
  const formattedInvoiceDate = invoiceDate ? moment(invoiceDate) : moment(0);
  return (
    amountPaid < invoiceAmount && formattedInvoiceDate.isAfter(term) && formattedInvoiceDate.isSameOrBefore(moment())
  );
}

export function isPaidStatus({ invoiceAmount, amountPaid }) {
  return amountPaid >= invoiceAmount;
}

export function getDaysOpen({ invoiceAmount, invoiceNumber, amountPaid, invoiceDate }) {
  const thirtyDaysAgo = moment().subtract(30, 'days');

  if (!invoiceNumber) return null;
  else if (amountPaid < invoiceAmount && moment(invoiceDate).isBefore(thirtyDaysAgo)) return '> 30 days';
  else if (amountPaid < invoiceAmount && moment(invoiceDate).isAfter(thirtyDaysAgo)) return '< 30 days';
  else return null;
}

export function eventNumberComparator(v1, v2) {
  return +v1.replace(/-WH$|-CM$|-DM$/, '') - +v2.replace(/-WH$|-CM$|-DM$/, '');
}
