import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { get } from 'lodash/object';

import TextArea from '../../../../common/inputs/TextArea';
import { getRoleDescriptionByUserRoles } from '../../../../services/personnelService';
import { useCurrentUser } from '../../../root/Container/CurrentUserContainer';
import { AppointmentUserInfo, UserMultiSelect } from '../../../UserMultiSelect/UserMultiSelect';
import { NON_PATIENT_EVENT } from '../CalendarEventType';
import { CalendarPageContext } from '../CalendarPageContext';
import { getAppointmentWithFixedTimeZone } from '../CalendarTimeZoneService';
import { fromDialogAppointment, toDialogAppointment } from '../EventTransformer';
import { ParticipantsProviderNonPatient } from '../form-components/ParticipantsProviderNonPatient';
import StudySiteSelection from '../form-components/StudySiteSelection';
import { SubjectInput } from '../form-components/SubjectInput';
import { isSubjectVisibleForType } from '../NonPatientAppointmentService';
import { refreshParticipants } from '../PatientAppointmentEditService';
import TimeDurationPicker from '../TimeDurationPickerGeneral/TimeDurationPicker';

import { getStudySiteValidationRulesByType } from './NonPatientAppointmentValidatioRulesProvider';
import NonPatientAppointmentValidator from './NonPatientAppointmentValidator';
import { NON_PATIENT_EVENT_TYPE } from './NonPatientEventType';
import NonPatientEventTypeSelection from './NonPatientEventTypeSelection';

function makeNonPatientAppointment({
  id,
  eventId,
  calendarIndex,
  subject,
  timeDuration,
  comment,
  studySite,
  participants,
  organizer,
  nonPatientEventTypeCode
}) {
  return {
    id,
    eventId,
    calendarIndex,
    subject,
    timeDuration,
    comment,
    studySite,
    participants,
    organizer,
    type: NON_PATIENT_EVENT,
    nonPatientEventTypeCode
  };
}

export function NonPatientAppointmentEdit({
  initialAppointment = {},
  appointmentChanged,
  children,
  timeZones,
  selectedTimeZone,
  changeSelectedTimeZone
}) {
  const currentUser = useCurrentUser();
  const { nonPatientEventTypeByCodeMap, nonPatientEventTypeDropdownOptions, studySites } = useContext(
    CalendarPageContext
  );
  const nonPatientAppointmentValidator = new NonPatientAppointmentValidator();

  useEffect(() => {
    const dialogAppointment = toDialogAppointment(initialAppointment);

    const appointment = makeNonPatientAppointment(dialogAppointment);
    setAppointment(appointment);
  }, [initialAppointment]);

  const [appointment, setAppointment] = useState(toDialogAppointment(initialAppointment));

  const personnelIdentifier = currentUser.personnelIdentifier,
    roleDescription = getRoleDescriptionByUserRoles(currentUser.roles);

  const [validation, setValidation] = useState({
    nonPatientEventTypeCode: {},
    studySite: {},
    subject: {},
    timeDuration: {}
  });

  const [isEverSaved, setIsEverSaved] = useState(false);

  const isSubjectVisible = useMemo(
    function() {
      return isSubjectVisibleForType(appointment.nonPatientEventTypeCode);
    },
    [appointment.nonPatientEventTypeCode]
  );

  const isStudyVisible = useMemo(
    function() {
      return appointment.nonPatientEventTypeCode !== NON_PATIENT_EVENT_TYPE.OUT_OF_OFFICE;
    },
    [appointment.nonPatientEventTypeCode]
  );

  const fieldChange = fieldName => newValue => {
    setAppointment(appointment => {
      const newAppointment = { ...appointment, [fieldName]: newValue };
      if (isEverSaved) {
        const newValidation = nonPatientAppointmentValidator.validate(newAppointment);
        setValidation(newValidation);
      }
      appointmentChanged(fromDialogAppointment(newAppointment));
      return newAppointment;
    });
  };

  const changeSubject = fieldChange('subject');
  const changeStudySite = fieldChange('studySite');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeOrganizer = useCallback(fieldChange('organizer'), []);
  const changeTimeDuration = fieldChange('timeDuration');
  const changeParticipants = fieldChange('participants');
  const changeComment = fieldChange('comment');
  const changeType = fieldChange('nonPatientEventTypeCode');

  const previousAppointmentStart = useRef(appointment.timeDuration.start);

  useEffect(() => {
    if (
      !previousAppointmentStart.current
        .clone()
        .startOf('day')
        .isSame(appointment.timeDuration.start.clone().startOf('day'))
    ) {
      changeOrganizer({
        userId: personnelIdentifier,
        type: 'USER',
        label: roleDescription
      });
      previousAppointmentStart.current = appointment.timeDuration.start;
    }
  }, [appointment.timeDuration.start, changeOrganizer, personnelIdentifier, roleDescription]);

  const siteId = appointment?.studySite?.site?.id;

  useEffect(() => {
    const timezoneForSelectedStudy = timeZones?.filter(e => e.id === siteId)[0];
    if (
      siteId &&
      appointment.timeDuration &&
      timezoneForSelectedStudy &&
      selectedTimeZone.timeZoneId !== timezoneForSelectedStudy.timeZoneId
    ) {
      const updatedAppointment = getAppointmentWithFixedTimeZone(
        appointment,
        selectedTimeZone.timeZoneId,
        timezoneForSelectedStudy.timeZoneId,
        timeZones[0].timeZoneId
      );
      setAppointment(updatedAppointment);
      appointmentChanged(fromDialogAppointment(updatedAppointment));
      changeSelectedTimeZone(timezoneForSelectedStudy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId]);

  useEffect(() => {
    refreshParticipants(personnelIdentifier, appointment, changeParticipants);
    // eslint-disable-next-line
  }, []);

  const validateBefore = onValidationSuccess => () => {
    setIsEverSaved(true);
    const newValidation = nonPatientAppointmentValidator.validate(appointment);
    setValidation({ ...newValidation });
    if (nonPatientAppointmentValidator.isValid(appointment)) {
      onValidationSuccess(fromDialogAppointment(appointment));
    }
  };

  const composeButtonBar = () => {
    if (children) {
      return React.Children.map(children, child => {
        return React.cloneElement(child, {
          ...child.props,
          onSave: validateBefore(child.props.onSave),
          onEdit: () => child.props.onEdit(appointment)
        });
      });
    }
  };

  const onNonPatientEventTypeCodeChange = value => {
    const nonPatientEventTypeCode = value?.id;

    changeType(value?.id);

    if (!isSubjectVisibleForType(nonPatientEventTypeCode)) {
      const subject = nonPatientEventTypeByCodeMap.get(nonPatientEventTypeCode)?.name;
      changeSubject(subject);
    } else {
      changeSubject('');
    }

    clearSsuSelection();

    function clearSsuSelection() {
      changeStudySite({});
    }
  };

  return (
    <React.Fragment>
      <NonPatientEventTypeSelection
        eventTypes={nonPatientEventTypeDropdownOptions}
        typeCode={appointment?.nonPatientEventTypeCode}
        onNonPatientEventTypeCodeChange={onNonPatientEventTypeCodeChange}
        validationMessage={validation?.nonPatientEventTypeCode?.validationMessage}
        required
      />
      {isSubjectVisible && (
        <SubjectInput
          initialSubject={appointment.subject}
          onSubjectChanged={changeSubject}
          subjectValidationMessage={validation.subject.validationMessage}
          required
        />
      )}
      <StudySiteSelection
        changeStudySite={changeStudySite}
        studySite={appointment.studySite}
        validation={validation.studySite}
        ssuProvider={() => studySites}
        hideStudy={!isStudyVisible}
        studySiteValidation={getStudySiteValidationRulesByType(appointment?.nonPatientEventTypeCode)}
      />
      <TimeDurationPicker
        initialTime={appointment.timeDuration}
        onTimeDurationChanged={changeTimeDuration}
        validationMessage={validation.timeDuration.validationMessage}
      />
      <ParticipantsProviderNonPatient
        initialStudy={get(appointment, 'studySite.study.id')}
        initialSite={get(appointment, 'studySite.site.id')}
      >
        <UserMultiSelect
          onChange={changeParticipants}
          initialUsers={appointment.participants}
          organizerUser={get(appointment, 'organizer')}
          className="mb-2"
          optionValueKey="userId"
          label="Add Attendees"
          customOptionTemplateFunction={AppointmentUserInfo}
        />
      </ParticipantsProviderNonPatient>
      <TextArea
        id={'comment-input'}
        name={'comment'}
        label={'Comment'}
        value={appointment.comment}
        onChange={changeComment}
      />
      <div className="action-buttons">{composeButtonBar()}</div>
    </React.Fragment>
  );
}
