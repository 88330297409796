import React from 'react';
import PropTypes from 'prop-types';

export const highlightTypes = Object.freeze({
  HEADER: 'HEADER',
  HIGHLIGHT: 'HIGHLIGHT',
  CHECK: 'CHECK',
  NONE: 'NONE'
});

const MATERIAL_ICONS = 'material-icons';

const EMPTY_BOOKMARK = 'bookmark_border';
const HIGHLIGHT_ICON = 'bookmark';
const CHECK_ICON = 'bookmark_added';

const HighlightIcon = ({ highlightType }) => {
  let className = '';
  let iconName = '';

  switch (highlightType) {
    default:
      className = MATERIAL_ICONS;
      iconName = EMPTY_BOOKMARK;
      break;
    case highlightTypes.HIGHLIGHT:
      className = MATERIAL_ICONS;
      iconName = HIGHLIGHT_ICON;
      break;
    case highlightTypes.CHECK:
      className = MATERIAL_ICONS;
      iconName = CHECK_ICON;
      break;
  }

  return (
    <i className={className} style={{ color: '#7D3559', fontSize: 26 }}>
      {iconName}
    </i>
  );
};

HighlightIcon.propTypes = {
  highlightTypes: PropTypes.oneOf(Object.keys(highlightTypes))
};

HighlightIcon.defaultProps = {
  highlightTypes: highlightTypes.NONE
};

export default HighlightIcon;
