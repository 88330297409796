import React from 'react';
import { round } from 'lodash';
import { map, reduce } from 'lodash/collection';

import Input from '../../../../../../../../common/data-entry/Input';
import MultiSelect from '../../../../../../../../common/data-entry/MultiSelect/MultiSelect';
import Select from '../../../../../../../../common/data-entry/Select';

import './PatientDemographics.scss';

const heightsData = {
  availableFts: [
    { label: "2'", value: 2 },
    { label: "3'", value: 3 },
    { label: "4'", value: 4 },
    { label: "5'", value: 5 },
    { label: "6'", value: 6 },
    { label: "7'", value: 7 },
    { label: "8'", value: 8 }
  ],
  availableIns: [
    { label: '0"', value: 0 },
    { label: '1"', value: 1 },
    { label: '2"', value: 2 },
    { label: '3"', value: 3 },
    { label: '4"', value: 4 },
    { label: '5"', value: 5 },
    { label: '6"', value: 6 },
    { label: '7"', value: 7 },
    { label: '8"', value: 8 },
    { label: '9"', value: 9 },
    { label: '10"', value: 10 },
    { label: '11"', value: 11 }
  ]
};

export default function PatientDemographics({
  info,
  ethnicityCodes,
  raceCodes,
  handleInputChange,
  handleHeightChange,
  handleRacesChange,
  handleEthnicitiesChange,
  isRequiredField,
  getValidationMessage
}) {
  const { heightFt, heightIn, weight, races, ethnicities } = info;
  const filteredRacesValues = races?.map(race => raceCodes && raceCodes[race]);
  const filteredEthnicitiesValues = ethnicities?.map(ethnicity => ethnicityCodes && ethnicityCodes[ethnicity]);

  const bmiValue = calculateBMI(weight, heightFt, heightIn);

  return (
    <div className="row">
      <div className="col-12 apm-form-label">Patient Demographics</div>
      <div className="col-12 d-flex">
        <div className="col-3">
          <Select
            label="Height (ft)"
            name="heightFt"
            value={heightsData.availableFts.find(({ value }) => value === heightFt) || null}
            onChange={value => handleHeightChange(value, 'heightFt')}
            deselectOnSelectedOptionClick={false}
            dataSource={heightsData.availableFts}
            optionLabelKey="label"
            optionValueKey="value"
            required={isRequiredField('heightFt')}
            validationMessage={getValidationMessage('heightFt')}
          />
        </div>

        <div className="col-3">
          <Select
            label="Height (in)"
            name="heightIn"
            value={heightsData.availableIns.find(({ value }) => value === heightIn) || null}
            onChange={value => handleHeightChange(value, 'heightIn')}
            deselectOnSelectedOptionClick={false}
            dataSource={heightsData.availableIns}
            optionLabelKey="label"
            optionValueKey="value"
          />
        </div>

        <div className="col-3">
          <Input label="Weight (lbs)" name="weight" value={weight?.toString()} onChange={handleInputChange} />
        </div>

        <div className="col-3 d-flex align-items-center">
          <div className="add-or-edit-patient-info-modal-body-index-label">BMI: {bmiValue || '##.#'}</div>
        </div>
      </div>
      <div className="col-12 d-flex">
        <div className="col-6 add-or-edit-patient-info-modal-race-ethnicity-select">
          <MultiSelect
            label="Race"
            name="races"
            controlId={'id'}
            clearable={true}
            clearSearchOnSelection
            forceDefaultBrowserScrolling={true}
            optionValueKey={'name'}
            focusToSelectedValue={false}
            dataSource={withFilteredOptions(raceCodes, races)}
            onChange={handleRacesChange}
            value={filteredRacesValues}
            searchable
          />
        </div>

        <div className="col-6 add-or-edit-patient-info-modal-race-ethnicity-select">
          <MultiSelect
            label="Ethnicity"
            name="ethnicities"
            controlId={'id'}
            clearable={true}
            clearSearchOnSelection
            forceDefaultBrowserScrolling={true}
            optionValueKey={'name'}
            focusToSelectedValue={false}
            dataSource={withFilteredOptions(ethnicityCodes, ethnicities)}
            onChange={handleEthnicitiesChange}
            value={filteredEthnicitiesValues}
            searchable
          />
        </div>
      </div>
    </div>
  );
  function withFilteredOptions(entityCodes, values) {
    const currDisabledOptions =
      entityCodes &&
      reduce(
        values,
        function(accumulator, value) {
          const { disabledIds } = entityCodes[value];
          return { ...accumulator, ...disabledIds };
        },
        {}
      );

    return map(entityCodes, function(value) {
      if (currDisabledOptions[value.id]) return { ...value, disabled: true };
      return value;
    });
  }
}

// TODO: Move to separated file
export function calculateBMI(weight, heightFt, heightIn) {
  let height = heightFt * 12 + heightIn;
  let bmi = (weight / height ** 2) * 703;
  return round(bmi, 1);
}
