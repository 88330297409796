import React from 'react';
import cx from 'classnames';

import Button from '../general/Button';
import Icon from '../general/Icon';

import './FloatingPanel.scss';

export default function FloatingPanel({ children, show, close, className }) {
  return (
    <>
      {show && (
        <div className={cx('floating-panel', { 'floating-panel-show': show, 'floating-panel-hide': !show }, className)}>
          <div className="floating-panel-container">
            <Button priority="low" size="h40" className={'close-floating-panel'} onClick={close}>
              <Icon>close</Icon>
            </Button>
            {children}
          </div>
        </div>
      )}
    </>
  );
}
