import React, { useCallback, useContext, useMemo } from 'react';

import MultiSelect from '../../../../../../common/data-entry/MultiSelect/MultiSelect';
import { StartEndDateSelector } from '../../../../finance/NewInvoice/InvoiceTopSection/InvoiceFilter/StartEndDateSelector/StartEndDateSelector';
import { WorklistContext } from '../../WorklistContext';

import { StudyPCNSiteWorklistSelector } from './StudyPCNSiteWorklistSelector/StudyPCNSiteWorklistSelector';
import { prescreenFailedStatus, prescreenStatuses } from './WorklistFilterConstants';

import './WorklistFilters.scss';

export const WorklistFilters = () => {
  const { workListFilteringData, setWorkListFilteringData, studySiteList } = useContext(WorklistContext);

  const setFewFilterProperty = useCallback(
    filters => {
      setWorkListFilteringData(prevFilters => ({
        ...prevFilters,
        ...filters
      }));
    },
    [setWorkListFilteringData]
  );

  const onStudyChange = useCallback(
    study => {
      if (study) {
        const { uniqueIdentifier, studyName, projectCode } = study;
        setFewFilterProperty({ studyId: uniqueIdentifier, studyName: studyName, projectCode: projectCode });
      } else {
        setFewFilterProperty({ studyId: null, studyName: null });
      }
    },
    [setFewFilterProperty]
  );

  const onPcnChange = useCallback(
    (study, sites) => {
      if (study) {
        const { uniqueIdentifier, studyName, projectCode } = study;
        setFewFilterProperty({ studyId: uniqueIdentifier, studyName: studyName, projectCode: projectCode });
        if (sites.length === 1 && !workListFilteringData.siteId) {
          const { uniqueIdentifier, siteName } = sites[0];
          setFewFilterProperty({ siteId: uniqueIdentifier, siteName: siteName });
        }
      } else {
        setFewFilterProperty({ studyId: null, studyName: null, projectCode: null });
      }
    },
    [workListFilteringData.siteId, setFewFilterProperty]
  );

  const onSiteChange = useCallback(
    site => {
      if (site) {
        const { uniqueIdentifier, siteName } = site;
        setFewFilterProperty({ siteId: uniqueIdentifier, siteName: siteName });
      } else {
        setFewFilterProperty({ siteId: null, siteName: null });
      }
    },
    [setFewFilterProperty]
  );

  const handlePrescreenStatusChange = useCallback(
    prescreenStatus => {
      setFewFilterProperty({ prescreenStatus });
    },
    [setFewFilterProperty]
  );

  const onChangeStartDate = useCallback(
    moment => {
      const startDateMoment = moment.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      setFewFilterProperty({ startDate: startDateMoment });
    },
    [setFewFilterProperty]
  );

  const onChangeEndDate = useCallback(
    moment => {
      const endDateMoment = moment.set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
      setFewFilterProperty({ endDate: endDateMoment });
    },
    [setFewFilterProperty]
  );

  const startDate = useMemo(() => {
    return workListFilteringData?.startDate;
  }, [workListFilteringData]);

  const endDate = useMemo(() => {
    return workListFilteringData?.endDate;
  }, [workListFilteringData]);

  return (
    <div className="worklist-filters">
      <div className="general-header-wrapper">
        <StudyPCNSiteWorklistSelector
          onSiteChange={onSiteChange}
          onStudyChange={onStudyChange}
          onPcnChange={onPcnChange}
          studyId={workListFilteringData.studyId}
          siteId={workListFilteringData.siteId}
          pcnFlag={true}
          enableAutoCompleteSite={true}
          setFewFilterProperty={setFewFilterProperty}
          studySiteList={studySiteList}
        />
        <MultiSelect
          label="Prescreen Status"
          clearSearchOnSelection
          searchable
          dataSource={[...prescreenStatuses, prescreenFailedStatus]}
          onChange={handlePrescreenStatusChange}
          value={workListFilteringData.prescreenStatus}
          validate={false}
          data-testid="prescreen-status-selector"
        />
      </div>
      <StartEndDateSelector
        endDate={endDate}
        startDate={startDate}
        onChangeEndDate={onChangeEndDate}
        onChangeStartDate={onChangeStartDate}
      />
    </div>
  );
};
