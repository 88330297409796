import React, { useState } from 'react';
import { isEmpty } from 'lodash/lang';

import Input from '../../../../../../../common/data-entry/Input';
import TextArea from '../../../../../../../common/data-entry/TextArea';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import { UNABLE_TO_SIGN_IN } from '../../../../../../../constants/notificationMessages';
import ReviewDataResolver from '../../ReviewDataResolver';
import { getRowKeysForSignature } from '../reviewControlService';

import { authenticateAuth0User } from './authApi';

import './SmPiReviewSignModal.scss';

export default function SmPiReviewSignModal({
  modalBox,
  itemGroupSnapshotReviewStates,
  logAndLogCheckSnapshotReviewStates,
  ssuPatientId,
  patientEncounterId,
  reviewType,
  signHandlerFn,
  showReason,
  currentDate,
  groupAssignId
}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [reason, setReason] = useState('');
  const [loader, setLoader] = useState(false);
  const [isValidForm, setIsValidForm] = useState(true);

  return (
    <>
      <ModalBoxes.Header>I reviewed and approve the data collected as of {currentDate}</ModalBoxes.Header>
      <ModalBoxes.Body>
        <form id="signForm" onSubmit={handleSubmit}>
          <Input
            label="Username"
            name="username"
            autoFocus
            onChange={o => setEmail(o.target.value)}
            value={email}
            placeholder="Enter email"
          />
          <Input
            label="Password"
            type="password"
            onChange={o => setPassword(o.target.value)}
            value={password}
            placeholder="Password"
          />
          {showReason && (
            <TextArea
              id={'reason-input'}
              name={'reason'}
              label={'Reason'}
              value={reason}
              style={{ resize: 'none' }}
              required
              validationMessage={isValidForm ? '' : 'Reason is required'}
              onChange={({ target }) => {
                !!target.value && setIsValidForm(true);
                setReason(target.value);
              }}
            />
          )}
        </form>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <Button form="signForm" loading={loader} disabled={isEmpty(email) || isEmpty(password)}>
          SUBMIT
        </Button>
      </ModalBoxes.Footer>
    </>
  );

  function onFailure(err) {
    const errMessage = err?.response?.data?.message;
    NotificationManager.error(errMessage ? errMessage : UNABLE_TO_SIGN_IN);
    console.error(err);
    setLoader(false);
  }

  function onSign(token) {
    const data = {
      groupAssignId,
      ssuPatientId,
      patientEncounterId,
      request: getRowKeysForSignature(itemGroupSnapshotReviewStates, logAndLogCheckSnapshotReviewStates, reason),
      token
    };
    ReviewDataResolver.signReview(data, reviewType)
      .then(res => {
        modalBox.close();
        signHandlerFn(res);
      })
      .catch(onFailure);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (loader) {
      return;
    }
    if (showReason && !reason) {
      setIsValidForm(false);
      return;
    }
    setIsValidForm(true);
    setLoader(true);
    const callBack = {
      onSuccess: result => onSign(result.getIdToken().getJwtToken()),
      onAuth0Success: result => onSign(result.access_token),
      onFailure: onFailure
    };
    authenticateAuth0User(email.trim().toLowerCase(), password, callBack);
  }
}

SmPiReviewSignModal.className = 'eds-review-sign-modal-box';
SmPiReviewSignModal.size = 'w650';
