import { useEffect, useMemo, useState } from 'react';
import { last, uniq } from 'lodash/array';
import { isEqual } from 'lodash/lang';
import { userHasRole } from 'services/auth';
import { useActiveTaskUpdate } from 'store/activeTask';

import { rolesAccessToPageMap } from 'components/root/Container/CurrentUserContainer';
import { generateUrlByKey } from 'components/root/router';

import { getRouteByPathname } from './taskServices';

export default function useTaskForm(allStudySites) {
  const updateActiveTask = useActiveTaskUpdate();
  const [taskFormIsLoading, setTaskFormIsLoading] = useState(true);
  const [taskForm, setTaskForm] = useState({});
  const [originTaskForm, setOriginTaskForm] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [taskFormValidationMap, setTaskFormValidationMap] = useState({});
  const lastLink = last(taskForm.taskLinks)?.link;
  const patientUniqueIdentifier = taskForm.patientUniqueIdentifier;
  const sitePatientId = taskForm.sitePatientId;
  const sitePatientEncounterId = taskForm.sitePatientEncounterId;

  const associatedTaskLinks = useMemo(
    function() {
      if (!lastLink) return null;
      return getAssociatedTaskLinks(lastLink, patientUniqueIdentifier, sitePatientId, sitePatientEncounterId);
    },
    [lastLink, patientUniqueIdentifier, sitePatientId, sitePatientEncounterId]
  );

  const linkAccess = associatedTaskLinks?.access;
  const patientStudiesPageLink = associatedTaskLinks?.patientStudiesPageLink;
  const patientEncounterPageLink = associatedTaskLinks?.patientEncounterPageLink;

  const { study, site } = taskForm;

  const studyId = study?.id;
  const siteId = site?.id;

  const matchedSsuIds = useMemo(
    function() {
      if (!studyId && !siteId) return null;
      return allStudySites
        .filter(function({ study, site }) {
          const matchedByStudyIdIfNotNull = !studyId || studyId === study?.id;
          const matchedBySiteIdIfNotNull = !siteId || siteId === site?.id;
          return matchedByStudyIdIfNotNull && matchedBySiteIdIfNotNull;
        })
        .map(({ id }) => id);
    },
    [allStudySites, studyId, siteId]
  );

  useEffect(
    function() {
      if (isEqual(taskForm, originTaskForm)) {
        setIsChanged(false);
      } else {
        setIsChanged(true);
      }
    } /* TODO: fires two times, can be optimized */,
    [taskForm, originTaskForm]
  );

  useEffect(
    function() {
      updateActiveTask({ isChanged });
    },
    [isChanged, updateActiveTask]
  );

  const {
    updateTaskForm,
    setTopic,
    setTopicTitle,
    setDescription,
    setAssignees,
    setDueDate,
    setEncounter,
    setItemGroup,
    setStatus,
    setTaskCommentField,
    setStudySite
  } = useMemo(function() {
    return {
      updateTaskForm,
      setTopic,
      setTopicTitle,
      setDescription,
      setAssignees,
      setDueDate,
      setEncounter,
      setItemGroup,
      setStatus,
      setTaskCommentField,
      setStudySite
    };

    function updateTaskForm(taskForm) {
      setTaskForm(taskForm);
      setOriginTaskForm(taskForm);
    }

    function setTopic(value) {
      const topic = value?.id || null;
      setTaskForm(function(taskForm) {
        return { ...taskForm, topic, topicTitle: null };
      });
    }

    function setTopicTitle({ target }) {
      setTaskForm(function(taskForm) {
        return { ...taskForm, topicTitle: target.value };
      });
    }

    function setDescription({ target }) {
      setTaskForm(function(taskForm) {
        return { ...taskForm, description: target.value };
      });
    }

    function setAssignees(assignees) {
      setTaskForm(function(taskForm) {
        return { ...taskForm, assignees };
      });
    }

    function setDueDate(date) {
      setTaskForm(function(taskForm) {
        return { ...taskForm, dueDate: date ? date.format('DD/MMM/YYYY') : null };
      });
    }

    function setEncounter(protocolEncounter) {
      setTaskForm(function(taskForm) {
        return { ...taskForm, protocolEncounter };
      });
    }

    function setItemGroup(protocolItemGroup) {
      setTaskForm(function(taskForm) {
        return { ...taskForm, protocolItemGroup };
      });
    }

    function setStatus(status) {
      setTaskForm(function(taskForm) {
        return { ...taskForm, status };
      });
    }

    function setTaskCommentField(taskCommentField) {
      setTaskForm(function(taskForm) {
        return { ...taskForm, taskCommentField };
      });
    }

    function setStudySite(state) {
      setTaskForm(function(taskForm) {
        return { ...taskForm, ...state };
      });
    }
  }, []);

  return useMemo(
    function() {
      return {
        taskForm,
        originTaskForm,
        isChanged,
        taskFormIsLoading,
        setTaskFormIsLoading,
        taskFormValidationMap,
        setTaskFormValidationMap,
        linkAccess,
        matchedSsuIds,
        patientStudiesPageLink,
        patientEncounterPageLink,
        updateTaskForm,
        setTopic,
        setTopicTitle,
        setDescription,
        setAssignees,
        setDueDate,
        setEncounter,
        setItemGroup,
        setStatus,
        setTaskCommentField,
        setStudySite
      };
    },
    [
      taskForm,
      originTaskForm,
      isChanged,
      taskFormIsLoading,
      taskFormValidationMap,
      linkAccess,
      matchedSsuIds,
      patientStudiesPageLink,
      patientEncounterPageLink,
      updateTaskForm,
      setTopic,
      setTopicTitle,
      setDescription,
      setAssignees,
      setDueDate,
      setEncounter,
      setItemGroup,
      setStatus,
      setTaskCommentField,
      setStudySite
    ]
  );
}

function getAssociatedTaskLinks(lastLink, patientUniqueIdentifier, sitePatientId, sitePatientEncounterId) {
  const route = getRouteByPathname(lastLink);
  const associatedLinks = extractAssociatedLinks(patientUniqueIdentifier, sitePatientId, sitePatientEncounterId);
  return {
    access: route?.access,
    ...associatedLinks
  };
}

function extractAssociatedLinks(patientId, ssuPatientId, patientEncounterId) {
  const associatedLinks = { patientStudiesPageLink: null, patientEncounterPageLink: null };
  if (patientId && ssuPatientId) {
    associatedLinks.patientStudiesPageLink = getLinkByAccess(
      generateUrlByKey(`Patients.Patient Profile.Patient Studies`, {
        patientId,
        ssuPatientId
      })
    );
    if (patientEncounterId) {
      associatedLinks.patientEncounterPageLink = getLinkByAccess(
        generateUrlByKey(`Patients.Patient Profile.Patient Studies.Encounter Details`, {
          patientId,
          ssuPatientId,
          patientEncounterId
        })
      );
    }
  }
  return associatedLinks;
}

function getLinkByAccess(link) {
  const route = getRouteByPathname(link);
  if (!checkRouteAccess(route.access || route.parent?.access)) {
    return null;
  }
  return link;
}

function checkRouteAccess(access) {
  const allowedRoles = uniq(access?.flatMap(a => rolesAccessToPageMap[a]));
  return userHasRole(allowedRoles);
}
