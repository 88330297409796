import React from 'react';
import cx from 'classnames';

import Icon from '../../../../../../../../../common/general/Icon';

import './ColapseButton.scss';

const CollapseButton = ({ onClick, collapsed }) => {
  const buttonClassName = cx('collapse-button', {
    hidden: collapsed
  });

  return (
    <div className={buttonClassName} onClick={onClick}>
      <Icon className="collapse-icon">{collapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</Icon>
    </div>
  );
};

export default CollapseButton;
