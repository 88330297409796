import React from 'react';
import * as PropTypes from 'prop-types';

import Common from '../../../../common/common';
import Select from '../../../../common/data-entry/Select';

export function GenericLegacyFilter(props) {
  const { list, onChange, label, clearable } = props;
  const commonProperties = {
    onChange: onChange,
    dataSource: list ? list.slice().sort(Common.generateSortFuncObjectFieldAlphabetical('name')) : [],
    label: label,
    clearSearchOnSelection: true,
    searchable: true,
    closeOnSelectedOptionClick: false,
    deselectOnSelectedOptionClick: false,
    keepOpenOnSelection: false,
    clearable: clearable,
    validate: false
  };

  return <Select {...commonProperties} data-testid={props['data-testid']} />;
}

GenericLegacyFilter.propTypes = {
  list: PropTypes.arrayOf(PropTypes.any),
  onChange: PropTypes.func,
  label: PropTypes.string,
  clearable: PropTypes.bool
};

GenericLegacyFilter.defaultProps = { list: [], clearable: false };
