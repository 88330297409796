import React from 'react';

import Input from '../../../../../../../../common/data-entry/Input';

export default function GuardianDetails({ handleInputChange, getValidationMessage, info }) {
  const { guardianFirstName, guardianLastName, guardianPhone, guardianEmail } = info;
  return (
    <div className="row">
      <div className="col-12 apm-form-label">Guardian Details</div>
      <div className="col-4">
        <Input label="First Name" name="guardianFirstName" value={guardianFirstName} onChange={handleInputChange} />
      </div>
      <div className="col-4 mr-3">
        <Input label="Last Name" name="guardianLastName" value={guardianLastName} onChange={handleInputChange} />
      </div>
      <div className="col-4">
        <Input
          label="Phone"
          name="guardianPhone"
          value={guardianPhone}
          onChange={handleInputChange}
          validationMessage={getValidationMessage('guardianPhone')}
        />
      </div>
      <div className="col-4">
        <Input
          label="Email"
          name="guardianEmail"
          value={guardianEmail}
          onChange={handleInputChange}
          validationMessage={getValidationMessage('guardianEmail')}
        />
      </div>
    </div>
  );
}
