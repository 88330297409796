const logger = {
  error
};

function error(err) {
  if (process.env.NODE_ENV !== 'production') {
    console.error(err);
  }
}

export default logger;
