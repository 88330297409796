import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LogRowStatusHistoryApi from '../../../../../api/patient/LogRowStatusHistoryApi';
import Input from '../../../../../common/data-entry/Input';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup/ButtonGroup';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { onRequestError } from '../../../../../services/handlers';
import { generateUrlByKey, useCurrentRoute } from '../../../../root/router';

import './CancelAdverseEventLogRowModal.scss';

function CancelAdverseEventLogRowModal({
  modalBox,
  studyId,
  ssuPatientId,
  itemGroupTemplateId,
  itemGroupTemplateVersionId,
  rowId,
  itemGroupName
}) {
  const [reason, setReason] = useState('');
  const [validationError, setValidationError] = useState(false);

  const navigate = useNavigate();
  const currentRoute = useCurrentRoute();

  const handleInputChange = e => {
    const text = e.target.value;
    setReason(text);
    setValidationError(false);
  };

  const closeRow = e => {
    if (!reason) {
      e.preventDefault();
      setValidationError(true);
      return;
    }
    LogRowStatusHistoryApi.cancelAdverseEventLogRow(
      studyId,
      ssuPatientId,
      itemGroupTemplateId,
      itemGroupTemplateVersionId,
      rowId,
      { reason }
    )
      .then(() => {
        modalBox.close();
        navigate(generateUrlByKey(currentRoute.parent.key, currentRoute.params));
        NotificationManager.success('Row Canceled successfully');
      })
      .catch(err => onRequestError(err, { customMessage: 'Something went wrong' }));
  };

  return (
    <>
      <ModalBoxes.Header>Cancel Adverse Event?</ModalBoxes.Header>
      <ModalBoxes.Body>
        <div className="mt-4">
          <h4>Adverse Event</h4>
          <p>{itemGroupName}</p>
        </div>
      </ModalBoxes.Body>
      <div className="reason-comment-div">
        <Input
          label="Reason"
          onChange={handleInputChange}
          value={reason}
          required={true}
          valid={!validationError}
          validationMessage={validationError ? 'Comment is required' : null}
        />
      </div>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            NO
          </Button>
          <Button onClick={closeRow}>YES</Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
}

CancelAdverseEventLogRowModal.className = 'cancel-ae-log-row-modal';
CancelAdverseEventLogRowModal.size = 'w650';

export default CancelAdverseEventLogRowModal;
