import axios from 'axios';

const ROOT = 'api/v1/patient-sources';

const PatientSourceApi = {
  getAllByTypeSource() {
    return axios.get(`${ROOT}/`);
  }
};

export default PatientSourceApi;
