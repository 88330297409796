import React, { useContext, useEffect, useRef, useState } from 'react';
import { separateThreeDigitsWithCommas } from 'services/financial';
import { onRequestError } from 'services/handlers';

import { paymentTypeMap } from 'components/pages/patient-source/Patients/constants';

import { PrepaidApi, StudyApi } from '../../../../../../../api';
import Button from '../../../../../../../common/general/Button';
import { PatientInfoContext } from '../../PatientInfoContext';

import './CardBalance.scss';

export default function CardBalance() {
  const { patientInfo, updatePatientInfo } = useContext(PatientInfoContext);
  const cardId = patientInfo.cardInfo?.id;
  const paymentType = patientInfo.cardInfo?.paymentType;
  const isCardIdRegistered = patientInfo.cardInfo?.registered;
  const patientId = patientInfo.id;

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [cardBalance, setCardBalance] = useState(null);
  const [statusMsg, setStatusMsg] = useState(null);
  const buttonRef = useRef(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    function handleClickOutside(event) {
      if (buttonRef.current && buttonRef.current.contains(event.target)) {
        return;
      }
      if (wrapperRef.current && wrapperRef.current.contains(event.target)) {
        return;
      }
      setShow(false);
    }
  }, [wrapperRef, buttonRef]);

  return (
    <>
      <div
        className="eds-card-balance"
        onClick={e => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
      >
        <span>Card balance </span>
        <Button
          size="h28"
          priority="low"
          ref={buttonRef}
          disabled={!cardId}
          title={!cardId ? 'PID not defined' : 'Show card balance'}
          onClick={() => {
            if (!show) {
              loadBalance();
            }
            setShow(!show);
          }}
        >
          Show
        </Button>
        {show && (
          <div ref={wrapperRef} className="eds-card-balance-tooltip">
            {getCardBalance()}
          </div>
        )}
      </div>
      <div className="eds-card-type">
        Card Type: <b>{paymentTypeMap[paymentType] || 'N/A'}</b>
      </div>
    </>
  );

  function getCardBalance() {
    if (loading) {
      return (
        <span className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </span>
      );
    }

    if (isCardIdRegistered && cardBalance) {
      return `$ ${separateThreeDigitsWithCommas(cardBalance)}`;
    }

    if (isCardIdRegistered && statusMsg) {
      return statusMsg;
    }

    return (
      <React.Fragment>
        Card is not registered{' '}
        <Button
          size="h28"
          priority="low"
          onClick={() => {
            updateCardStatus(cardId, patientInfo.id);
          }}
          className="ml-2"
        >
          Retry
        </Button>
      </React.Fragment>
    );
  }

  function updateCardStatus(proxyNumber, patientUniqueIdentifier) {
    setLoading(true);
    StudyApi.retryCard(proxyNumber, patientUniqueIdentifier, true)
      .then(() => {
        updatePatientInfo();
      }, onRequestError)
      .finally(function() {
        setLoading(false);
      });
  }

  function loadBalance() {
    if (!isCardIdRegistered) {
      return;
    }
    setLoading(true);
    setCardBalance(null);
    PrepaidApi.getCardBalanceByProxy(cardId, patientId, true)
      .then(function({ data: cardBalance }) {
        setCardBalance(cardBalance.toLocaleString());
      })
      .catch(function() {
        setStatusMsg('Error while receiving card balance, please try again later');
      })
      .finally(function() {
        setLoading(false);
      });
  }
}
