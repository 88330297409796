import axios from 'axios';

const ROOT = `api/v1/ssu-patients`;

const PatientStipendApi = {
  getBySitePatientId(ssuPatientId) {
    return axios.get(`${ROOT}/${ssuPatientId}/stipends`, { hideLoader: false });
  },
  getSituationalEncounterInfoBySitePatient(ssuPatientId) {
    return axios.get(`${ROOT}/${ssuPatientId}/stipends/situationals`, { hideLoader: false });
  },
  triggerStipendPayment(ssuPatientId, triggerRequest) {
    return axios.post(`${ROOT}/${ssuPatientId}/stipends/triggers`, triggerRequest, { hideLoader: false });
  }
};

export default PatientStipendApi;
