import { useMemo, useReducer } from 'react';
import { some } from 'lodash/collection';

import { FPAT_PS } from './findPatientServices';

const defaultState = {
  activeTab: FPAT_PS,
  isLoading: true,
  patients: [],
  isRecentlyViewed: false
};

export default function usePatients() {
  const [state, dispatch] = useReducer(reducer, defaultState);
  const methods = useMemo(
    function() {
      return {
        updateState(payload) {
          dispatch({ type: 'updateState', payload });
        },
        setMorePatients(payload) {
          dispatch({ type: 'setMorePatients', payload });
        }
      };
    },
    [dispatch]
  );
  return { ...state, ...methods };
}

function reducer(state, action) {
  if (action.type === 'updateState') {
    return updateState(state, action.payload);
  }
  if (action.type === 'setMorePatients') {
    return {
      ...state,
      patients: [...state.patients, ...action.payload.filter(patient => !some(state.patients, patient))]
    };
  }
  return state;
}

function updateState(state, payload) {
  const nextState = { ...state, ...payload };
  if (nextState.isLoading || state.activeTab !== nextState.activeTab) {
    nextState.patients = [];
    nextState.isRecentlyViewed = defaultState.isRecentlyViewed;
  }
  return nextState;
}
