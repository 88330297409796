import React, { Component } from 'react';
import { sortBy } from 'lodash/collection';
import { isFunction } from 'lodash/lang';

import StatusApiService from '../../api/patient/PatientStatusApi';
import MultiSelect from '../../common/data-entry/MultiSelect/MultiSelect';
import Select from '../../common/data-entry/Select';
import ApplyAndResetButtons from '../ApplyAndResetButtons/ApplyAndResetButtons';

import './PatientDetailsSSUFilter.scss';

class PatientDetailsSSUFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statuses: [],
      selectedStatuses: []
    };
  }

  componentDidMount() {
    StatusApiService.getAllStatuses().then(({ data: statuses }) => {
      this.setState({ statuses: sortBy(statuses, ['name']) });

      const globalStateStatuses = isFunction(this.props.statusIdProvider) ? this.props.statusIdProvider() : [];
      const selectedStatuses = this.state.statuses.filter(status => globalStateStatuses.includes(status.id));
      this.setState({ selectedStatuses: selectedStatuses });
    });
  }

  render() {
    let {
      studies,
      sites,
      handleSiteChange,
      handleStudyChange,
      handleStatusChange,
      initialStudy,
      initialSite
    } = this.props;
    return (
      <div className="general-header-group-container general-header-wrapper">
        <Select
          label="Study"
          clearSearchOnSelection
          dataSource={studies}
          optionLabelKey="studyName"
          optionValueKey="uniqueIdentifier"
          onChange={handleStudyChange}
          searchable
          value={initialStudy}
          validate={false}
        />
        <Select
          label="Site"
          clearSearchOnSelection
          optionLabelKey="siteName"
          optionValueKey="uniqueIdentifier"
          dataSource={sites}
          onChange={handleSiteChange}
          searchable
          value={initialSite}
          validate={false}
        />
        <MultiSelect
          label="Status"
          clearSearchOnSelection
          dataSource={this.state.statuses}
          onChange={selectedStatuses => this.setState({ selectedStatuses: selectedStatuses })}
          searchable
          value={this.state.selectedStatuses}
          validate={false}
        />
        <ApplyAndResetButtons
          applyClassName="pd-ssu-filter-button"
          onApply={() => handleStatusChange(this.state.selectedStatuses)}
        />
        {this.props.children}
      </div>
    );
  }
}
export default PatientDetailsSSUFilter;
