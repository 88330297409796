import { replace } from 'lodash/string';

import EncounterTableApi from '../../../../../../../api/study-data/EncounterTableApi';

export default function saveEncounterTableItemGroup(
  isEditMod,
  ssuPatientId,
  patientEncounterId,
  patientItemGroupId,
  encounterTableItemGroup
) {
  return EncounterTableApi[isEditMod ? 'updateEncounterTableItemGroup' : 'saveEncounterTableItemGroup'](
    ssuPatientId,
    patientEncounterId,
    patientItemGroupId,
    encounterTableItemGroup
  ).catch(function(err) {
    if (err?.response?.data?.message) {
      err.response.data.message = replace(err.response.data.message, '[', '');
      err.response.data.message = replace(err.response.data.message, ']', '');
    }
    throw err;
  });
}
