import { setIn } from 'formik';
import { isEqual } from 'lodash/lang';
import { has } from 'lodash/object';

import setAddressType from './stateProcessors/setAddressType';
import setCity from './stateProcessors/setCity';

export function reducer(previousState, action) {
  const processedState = processState(previousState, action);
  return compareAndFix(previousState, processedState, action.type, action.payload?.value);
}

function processState(state, action) {
  const { type, payload } = action;
  if (type === 'SET_ADDRESS_FIELD') {
    return setAddressField(state, action);
  }
  if (type === 'SET_ADDRESS_FIELDS') {
    return setAddressFields(state, action);
  }
  if (type === 'SET_COUNTRY_ID') {
    return setCountryId(state, action);
  }
  if (type === 'SET_CITY') {
    return setCity(state, action);
  }
  if (type === 'SET_ADDRESS_TYPE') {
    return setAddressType(state, action);
  }

  return state;
}

function compareAndFix(previousState, processedState, type, value = {}) {
  if (previousState !== processedState) {
    if (!has(value, 'valid')) {
      doValidRecalculation(previousState, processedState);
    }
  }
  return processedState;
}

function doValidRecalculation(previousState, processedState) {
  if (compare(previousState, processedState)) {
    processedState.valid = false;
  }
}

function compare(a, b) {
  return !isEqual(
    {
      countryId: a.countryId,
      regionId: a.regionId,
      city: a.city,
      address1: a.address1,
      zipCode: a.zipCode
    },
    {
      countryId: b.countryId,
      regionId: b.regionId,
      city: b.city,
      address1: b.address1,
      zipCode: b.zipCode
    }
  );
}

function setAddressFields(state, { type, payload: { value } }) {
  return {
    ...state,
    ...value
  };
}

function setAddressField(state, { type, payload: { path, value } }) {
  return setIn(state, path, value);
}

function setCountryId(state, { type, payload: { value } }) {
  return {
    ...state,
    countryId: value,
    regionId: '',
    city: '',
    address1: '',
    address2: '',
    zipCode: ''
  };
}
