import React, { Component } from 'react';
import { map } from 'lodash/collection';
import { isArray, isEmpty, isString, isUndefined } from 'lodash/lang';
import { get } from 'lodash/object';
import moment from 'moment';

import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';

import {
  EncounterApi,
  PatientStatusApi,
  ReactRouteOldMetaDataApi,
  SitePatientEncounterApi,
  StudyApi,
  UnexpectedEncounterApi,
  UserApiApi
} from '../../../../../api';
import Common from '../../../../../common/common';
import Input from '../../../../../common/data-entry/Input';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { NON_PROTOCOL, UNEXPECTED } from '../../../../../constants/encounterConstants';
import { FILL_REQUIRED, ITEM_REMOVED, SOMETHING_WENT_WRONG } from '../../../../../constants/notificationMessages';
import { VIEW_PROTOCOL_ENCOUNTER_DATA, VIEW_SSU_PATIENT_INFO } from '../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../services/auth';
import { onRequestDefaultError, onRequestError } from '../../../../../services/handlers';
import { generateUrlByKey, withCurrentRoute } from '../../../../root/router';
import { withNavigate, withParams } from '../../../../root/router/legacyComponentCompatability';
import { getRouteKey } from '../NewEncounterPage/NewEncounterPageService';
import { cancelSitePatientEncounter, reopenSitePatientEncounter } from '../services';

import EncounterDetailsHeader from './EncounterDetailsHeader/EncounterDetailsHeader';
import EncounterPatientInfo from './EncounterPatientInfo/EncounterPatientInfo';
import {
  isAbleToEditEncounter,
  isCancelEncounterMenuAvailable,
  isCloseEncounterMenuAvailable,
  isEncounterStartedAndIncomplete,
  prepareEncounterStudyManagers
} from './encounterDetailsService';
import ItemGroup from './ItemGroup';

import 'react-datetime/css/react-datetime.css';

class EncounterDetails extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      metadata: {
        patientEncounterGroupId: null,
        ssuId: null,
        studyId: null,
        encounterType: null
      },
      currentPatient: {},
      elementSearchTxt: '',
      encounterDetailsList: [],
      unexpectedEncounter: false, // need to rename, it is boolean
      unexpectedEncounterName: '',
      isUnexpectedEncounterExists: false,
      selectedItemGroups: [],
      encounter: null,
      encounterStudyManagers: [],
      currentEncounterStudyManagerUniqueIdentifier: null,
      currentEdcVisitDate: null,
      isNonProtocolEncounter: false,
      isCloseEncounterMenuAvailable: false,
      isCancelEncounterMenuAvailable: false,
      isEncounterStartedAndIncomplete: false,
      isEncounterCanceled: false
    };
  }

  addItemGroup = (event, isUnexpected, itemGroup) => {
    if (isUnexpected && itemGroup.formUniqueIdentifier === 'N/A') {
      const array = [...this.state.selectedItemGroups];
      const index = array.indexOf(itemGroup);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ selectedItemGroups: array });
      } else {
        this.setState({ selectedItemGroups: [...this.state.selectedItemGroups, itemGroup] });
      }
      this.render();
    }
  };

  getUnexpectedEncounterDetails = () => {
    const { ssuPatientId, patientEncounterId } = this.props.params;
    const { studyId } = this.state.metadata;
    const ssuPatientEncounterId = this.props.params.patientEncounterId;
    EncounterApi.getUnexpectedEncounters(studyId, ssuPatientId, ssuPatientEncounterId).then(res => {
      const currentPatient = get(res, 'data.userInfoResponse');
      this.getSsuPatientEncounterMetaInfo(ssuPatientId, patientEncounterId)
        .then(encounterMetaInfoRes => {
          const currentEncounterMetaInfo = encounterMetaInfoRes.data;
          res.data &&
            res.data.response &&
            res.data.response.forEach(itemGroup => {
              itemGroup.url = generateLinkToItemGroupPage(
                itemGroup,
                currentPatient.uniqueIdentifier,
                this.props.currentRoute?.key
              );
            });
          this.setState({ currentPatient });
          if (res && res.data && res.data.response) {
            const data = res.data.response;
            const unexpectedEncounterName = currentEncounterMetaInfo?.encounterName;
            let currentEncounterStudyManagerUniqueIdentifier = currentEncounterMetaInfo?.studyManagerUniqueIdentifier;
            if (!currentEncounterStudyManagerUniqueIdentifier) {
              currentEncounterStudyManagerUniqueIdentifier = this.state.currentEncounterStudyManagerUniqueIdentifier;
            }
            this.setState({
              currentEncounterStudyManagerUniqueIdentifier,
              encounterDetailsList: data,
              unexpectedEncounterName,
              isUnexpectedEncounterExists: unexpectedEncounterName?.length > 0,
              selectedItemGroups: [],
              encounter: currentEncounterMetaInfo,
              currentEdcVisitDate: this.getEdcVisitDate(currentEncounterMetaInfo)
            });
          }
          LoadingOverlay.hideLoader();
        })
        .then(this.getEncounterStatus);
    });
  };

  getSsuPatientEncounterMetaInfo = (ssuPatientId, ssuPatientEncounterId) => {
    //After this endpoint clean up it was found out that meta data was always retrieved for unexpected encounter,
    // even it was not created yet. The response was empty in such cases. Added this to not break the old logic.
    if (ssuPatientEncounterId) {
      return SitePatientEncounterApi.getSsuPatientEncounterMetaInfo(ssuPatientId, ssuPatientEncounterId);
    } else {
      //added to leave current behavior as it is.
      return Promise.resolve({});
    }
  };

  saveItemGroups = () => {
    if (!this.isAllowedToAddOrSaveUnexpectedEncounter()) {
      NotificationManager.warning(FILL_REQUIRED);
      return;
    }

    const { selectedItemGroups, unexpectedEncounterName } = this.state;
    const { currentRoute, navigate, params } = this.props;
    const { ssuPatientId, patientId, patientEncounterId } = params;
    const { event } = this.props.currentRoute.searchParams;

    const request = {
      itemGroupIds: map(selectedItemGroups, 'uniqueIdentifier')
    };

    if (currentRoute?.name === 'Create Unexpected Encounter') {
      request.unexpectedEncounterName = unexpectedEncounterName.trim();
      request.studyManagerId = this.state.currentEncounterStudyManagerUniqueIdentifier;
      request.edcVisitDate = this.state.currentEdcVisitDate;
      request.appointmentId = event;
      UnexpectedEncounterApi.createUnexpectedEncounter(ssuPatientId, request)
        .then(({ data: patientEncounterId }) => {
          navigate(
            generateUrlByKey(`${currentRoute?.parent?.key}.Encounter Details`, {
              patientId,
              ssuPatientId,
              patientEncounterId
            })
          );
        })
        .catch(err => onRequestError(err));
      return;
    }

    UnexpectedEncounterApi.addItemGroupsToUnexpectedEncounter(ssuPatientId, patientEncounterId, request).then(
      this.getUnexpectedEncounterDetails
    );
  };

  componentDidMount() {
    UserApiApi.getListOfStudyManagers(this.props.params.ssuPatientId).then(({ data: { response: activeUsers } }) => {
      if (isArray(activeUsers)) {
        this.setState({ encounterStudyManagers: prepareEncounterStudyManagers(activeUsers) });
      }
    }, onRequestDefaultError);

    if (this.props.currentRoute?.name === 'Create Unexpected Encounter') {
      const { ssuId, studyId } = this.props.currentRoute.searchParams;
      this.setState(
        {
          metadata: {
            patientEncounterGroupId: `UN${Common.getRandomNumber()}`,
            ssuId,
            studyId,
            encounterType: UNEXPECTED
          }
        },
        () => {
          this.initialLoadData();
        }
      );
      return;
    }

    ReactRouteOldMetaDataApi.getRouteOldMetadataForEncounterDetailsPage(this.props.params.patientEncounterId).then(
      ({ data }) => {
        const { patientEncounterGroupId, studyId, ssuId, encounterType } = data;
        this.setState(
          {
            metadata: {
              patientEncounterGroupId,
              ssuId,
              studyId,
              encounterType
            }
          },
          () => {
            this.initialLoadData();
          }
        );
      }
    );
  }

  getEdcVisitDate = encounter => {
    if (encounter && encounter.edcVisitDate) {
      return encounter.edcVisitDate;
    }
    if (!encounter && this.state.metadata.encounterType === UNEXPECTED) {
      return moment().format('YYYY-MM-DD');
    }
    return null;
  };

  initialLoadData = () => {
    const { ssuPatientId, patientEncounterId } = this.props.params;
    if (this.state.metadata.encounterType === UNEXPECTED) {
      this.setState({
        unexpectedEncounter: true
      });
      this.getUnexpectedEncounterDetails();
    } else {
      SitePatientEncounterApi.getSsuPatientEncounterMetaInfo(ssuPatientId, patientEncounterId)
        .then(res => {
          const currentEncounterMetaInfo = res.data;
          const isNonProtocolEncounter = currentEncounterMetaInfo.nonProtocol;
          this.setState({
            currentEncounterStudyManagerUniqueIdentifier: currentEncounterMetaInfo?.studyManagerUniqueIdentifier,
            encounter: currentEncounterMetaInfo,
            currentEdcVisitDate: this.getEdcVisitDate(currentEncounterMetaInfo),
            isNonProtocolEncounter: isNonProtocolEncounter
          });
        })
        .then(() => {
          this.loadEncounterDetailsBoxes(this.props.params.ssuPatientId, this.props.params.patientEncounterId);
        });
    }
  };

  getEncounterStatus = () => {
    const { ssuPatientId, patientEncounterId } = this.props.params;
    if (ssuPatientId) {
      PatientStatusApi.getSitePatientStatus(ssuPatientId).then(res => {
        const status = res.data.name;
        this.setState({
          isCloseEncounterMenuAvailable: isCloseEncounterMenuAvailable(this.state.isNonProtocolEncounter, status)
        });
      });
      this.setState({
        isCancelEncounterMenuAvailable: isCancelEncounterMenuAvailable(this.state.isNonProtocolEncounter)
      });
    }
    this.setState({
      isEncounterStartedAndIncomplete: isEncounterStartedAndIncomplete(this.state?.encounterDetailsList)
    });
    if (ssuPatientId && patientEncounterId) {
      SitePatientEncounterApi.getSitePatientEncounterStatus(ssuPatientId, patientEncounterId).then(res => {
        this.setState({
          isEncounterCanceled: res.data === 'CANCELED'
        });
      });
    }
  };

  loadEncounterDetailsBoxes = (ssuPatientId, ssuPatientEncounterId) => {
    StudyApi.getPatientEncounters(ssuPatientId, ssuPatientEncounterId)
      .then(res => {
        const currentPatient = get(res, 'data.userInfoResponse'),
          encounterDetailsList = get(res, 'data.response');

        isArray(encounterDetailsList) &&
          encounterDetailsList.forEach(itemGroup => {
            itemGroup.url = generateLinkToItemGroupPage(
              itemGroup,
              currentPatient.uniqueIdentifier,
              this.props.currentRoute?.key
            );
          });

        this.setState({
          currentPatient,
          encounterDetailsList
        });
      })
      .then(this.getEncounterStatus);
  };

  onElementSearch = e => {
    e.preventDefault();
    this.setState({ elementSearchTxt: e.target.value });
  };

  onChangeName = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  removeItemGroup = encounter => {
    ModalBoxes.confirm({
      content: 'Are you sure you want to delete this record?',
      confirmButton: 'Yes',
      cancelButton: 'No'
    }).then(
      () => {
        UnexpectedEncounterApi.removeItemGroupFromUnexpectedEncounter(
          encounter.sitePatientIdentifier,
          encounter.patientEncounterId,
          encounter.formUniqueIdentifier
        ).then(res => {
          if (res.status === 200) {
            NotificationManager.success(ITEM_REMOVED);
            this.getUnexpectedEncounterDetails();
          } else {
            NotificationManager.error(SOMETHING_WENT_WRONG);
          }
        }, onRequestError);
      },
      () => {}
    );
  };

  onAfterSaveEncounterStudyManager = currentEncounterStudyManagerUniqueIdentifier => {
    this.setState({ currentEncounterStudyManagerUniqueIdentifier });
  };

  onAfterSaveEdcVisitDate = date => {
    this.setState({ currentEdcVisitDate: date });
  };

  isAllowedToAddOrSaveUnexpectedEncounter = () => {
    const {
      unexpectedEncounter: isUnexpectedEncounter,
      isUnexpectedEncounterExists,
      currentEncounterStudyManagerUniqueIdentifier,
      selectedItemGroups,
      unexpectedEncounterName
    } = this.state;
    if (!isUnexpectedEncounter) {
      return false;
    }
    if (
      !isUnexpectedEncounterExists &&
      (isEmpty(currentEncounterStudyManagerUniqueIdentifier) || !isString(currentEncounterStudyManagerUniqueIdentifier))
    ) {
      return false;
    }
    return !isEmpty(selectedItemGroups) && !isEmpty(unexpectedEncounterName?.trim());
  };

  cancelSitePatientEncounter = () => {
    cancelSitePatientEncounter(
      this.props.params.patientEncounterId,
      this.props.params.ssuPatientId,
      this.state.encounter.encounterIdentifier,
      () => {
        this.loadEncounterDetailsBoxes(this.props.params.ssuPatientId, this.props.params.patientEncounterId);
      },
      this.state.encounter.encounterName
    );
  };

  reopenEncounter = () => {
    reopenSitePatientEncounter(this.props.params.ssuPatientId, this.props.params.patientEncounterId, () => {
      this.loadEncounterDetailsBoxes(this.props.params.ssuPatientId, this.props.params.patientEncounterId);
    });
  };

  render() {
    const { state } = this;
    let {
      encounterDetailsList,
      currentPatient,
      isUnexpectedEncounterExists,
      unexpectedEncounter: isUnexpectedEncounter,
      encounter
    } = state;

    if (this.state.elementSearchTxt) {
      encounterDetailsList = encounterDetailsList.filter(row => {
        return (
          row.elementName &&
          row.elementName
            .toLowerCase()
            .indexOf(this.state.elementSearchTxt && this.state.elementSearchTxt.toLowerCase().trim()) > -1
        );
      });
    }

    const assignedList =
      isUnexpectedEncounter &&
      encounterDetailsList.filter(e => e.formUniqueIdentifier !== 'N/A' || e.elementName === 'Comments');
    const notAssignedList =
      isUnexpectedEncounter &&
      encounterDetailsList.filter(e => e.formUniqueIdentifier === 'N/A').filter(e => e.elementName !== 'Comments');

    const isUnexpectedEncounterNotYetExists = isUnexpectedEncounter && !isUnexpectedEncounterExists;
    const isEditMode = assignedList.length > 1;

    const isAbleToManageEncounterData = () => {
      return isAbleToEditEncounter(this?.state?.encounter?.nonProtocol, currentPatient?.studySiteStatus);
    };

    if (this.state.metadata.encounterType) {
      if (
        !userHasAccessTo(
          this.state.metadata.encounterType === NON_PROTOCOL ? VIEW_SSU_PATIENT_INFO : VIEW_PROTOCOL_ENCOUNTER_DATA
        )
      ) {
        window.location.replace('');
      }
    }

    return (
      <div className="full-screen-layout eds-encounter-details-page  footpadb">
        <EncounterPatientInfo infoLabel={this.state.encounter?.encounterName} />
        {isUnexpectedEncounter && (
          <>
            {isUndefined(encounter) && isUnexpectedEncounterNotYetExists && (
              <section className="unexpected-enc mb-2">
                <div className="border px-3 pt-2 m-0 d-flex flex-column">
                  <div>
                    <h5 className="c-p pt-2">Unexpected Encounter Details</h5>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <Input
                      style={{ minWidth: '238px' }}
                      label={'Encounter Name'}
                      disabled={isUnexpectedEncounterExists}
                      name="unexpectedEncounterName"
                      value={this.state.unexpectedEncounterName || ''}
                      onChange={this.onChangeName}
                      required
                    />
                    {isAbleToManageEncounterData() && (
                      <Button
                        className="mt-2"
                        disabled={!this.isAllowedToAddOrSaveUnexpectedEncounter()}
                        onClick={this.saveItemGroups}
                      >
                        {isEditMode ? 'Add' : 'Save'}
                      </Button>
                    )}
                  </div>
                </div>
              </section>
            )}

            <section className="encounter-details border mx-3 px-3 pb-2 py-2 my-2">
              <EncounterDetailsHeader
                patientEncounterGroupId={this.state.metadata?.patientEncounterGroupId}
                isNonProtocolEncounter={this.state.isNonProtocolEncounter}
                ancestorUpdateOnly={isUnexpectedEncounterNotYetExists}
                isUnexpectedEncounter={this.state.unexpectedEncounter}
                encounter={this.state.encounter}
                onElementSearch={this.onElementSearch}
                encounterStudyManagers={this.state.encounterStudyManagers}
                currentEncounterStudyManagerUniqueIdentifier={this.state.currentEncounterStudyManagerUniqueIdentifier}
                currentEdcVisitDate={this.state.currentEdcVisitDate}
                onAfterSaveEncounterStudyManager={this.onAfterSaveEncounterStudyManager}
                showMoreOptions={this.state.isCancelEncounterMenuAvailable}
                showCloseEncounterOption={this.state.isCloseEncounterMenuAvailable}
                onAfterSaveEdcVisitDate={this.onAfterSaveEdcVisitDate}
                isAbleToEditEncounter={isAbleToManageEncounterData}
                cancelEncounterAvailable={!this.state.isEncounterCanceled}
                notPerformAllItemsAvailable={this.state.isEncounterStartedAndIncomplete}
                cancelEncounter={this.cancelSitePatientEncounter}
                reopenEncounter={this.reopenEncounter}
                currentPatient={currentPatient}
                elementSearchTxt={this.state.elementSearchTxt}
                isAllowedToAddOrSaveUnexpectedEncounter={this.isAllowedToAddOrSaveUnexpectedEncounter}
                saveItemGroups={this.saveItemGroups}
                isEditMode={isEditMode}
              />
              {assignedList && assignedList.length > 0 && (
                <div className="row px-3">
                  {assignedList.map(encounter => (
                    <ItemGroup
                      unexpectedEncounter={this.state.unexpectedEncounter}
                      selectedItemGroups={this.state.selectedItemGroups}
                      addItemGroup={this.addItemGroup}
                      key={encounter.uniqueIdentifier}
                      removeItemGroup={this.removeItemGroup}
                      encounter={encounter}
                      patientId={this.state.metadata?.patientEncounterGroupId}
                      loadEncounterDetailData={this.loadEncounterDetailsBoxes}
                      unExpectedEncounterData={this.state}
                      disabled={this.state.isEncounterCanceled}
                      isAbleToEditEncounter={isAbleToManageEncounterData}
                      studyId={this.state?.metadata?.studyId}
                      currentPatient={currentPatient}
                    />
                  ))}
                </div>
              )}
            </section>

            <section className="encounter-details border mx-3 px-3 pb-3 py-4 my-2">
              {notAssignedList && notAssignedList.length > 0 && (
                <div className="row px-3">
                  {notAssignedList.map(encounter => (
                    <ItemGroup
                      unexpectedEncounter={this.state.unexpectedEncounter}
                      selectedItemGroups={this.state.selectedItemGroups}
                      addItemGroup={this.addItemGroup}
                      key={encounter.uniqueIdentifier}
                      encounter={encounter}
                      patientId={this.state.metadata?.patientEncounterGroupId}
                      loadEncounterDetailData={this.loadEncounterDetailsBoxes}
                      unExpectedEncounterData={this.state}
                      disabled={this.state.isEncounterCanceled}
                      studyId={this.state?.metadata?.studyId}
                      currentPatient={currentPatient}
                    />
                  ))}
                </div>
              )}
            </section>
          </>
        )}
      </div>
    );
  }
}

function generateLinkToItemGroupPage(itemGroup, patientId, encounterDetailsPageKey) {
  const { formUniqueIdentifier, sitePatientIdentifier, patientEncounterId } = itemGroup;

  if (!patientEncounterId || encounterDetailsPageKey.includes('Edit Unexpected Encounter')) {
    return '#';
  }

  return generateUrlByKey(getRouteKey(encounterDetailsPageKey, itemGroup), {
    patientId,
    ssuPatientId: sitePatientIdentifier,
    patientEncounterId: patientEncounterId,
    patientItemGroupId: formUniqueIdentifier,
    reviewPatientItemGroupId: formUniqueIdentifier
  });
}

export default withCurrentRoute(withNavigate(withParams(EncounterDetails)));
