import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash/lang';

import { StudyStatusSetupApi } from '../../../../api';
import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../common/general/Button';
import Icon from '../../../../common/general/Icon';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import { STATUS_CONFIG_CHANGES_CANCELED } from '../../../../constants/notificationMessages';
import { MANAGE_STUDY_DETAILS, VIEW_STUDY_DETAILS } from '../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../services/auth';

import StatusesSetupModal from './StatusesSetupModal';

import './StatusesSetup.scss';

const StatusesSetup = ({ studyId, status, studyName }) => {
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    if (studyId) {
      StudyStatusSetupApi.getByStudyId(studyId).then(({ data }) => {
        setStatuses(data);
      });
    }
  }, [studyId]);

  const selectedExpected = statuses
    .filter(item => item.active && !item.dropOffStatus)
    .map((item, index) => {
      return (
        <li key={`${index}_status_expected`} className="mb-3">
          {item.statusName}
          {item.optional && <span className="optional-status"> (Optional)</span>}
        </li>
      );
    });

  const selectedDropOff = statuses
    .filter(item => item.active && item.dropOffStatus)
    .map((item, index) => {
      return (
        <li key={`${index}_status_drop_off`} className="mb-3">
          {item.statusName}
        </li>
      );
    });

  const selectedInvalid = statuses
    .filter(item => !item.active)
    .map((item, index) => {
      return (
        <li key={`${index}_status_invalid`} className="mb-3">
          {item.statusName}
        </li>
      );
    });

  const editStatusesSetup = () => {
    ModalBoxes.open({
      component: <StatusesSetupModal initialStatuses={statuses} studyId={studyId} updateStatuses={setStatuses} />,
      onClose: isChanged => {
        if (isChanged) {
          NotificationManager.error(STATUS_CONFIG_CHANGES_CANCELED);
        }
      }
    });
  };

  return (
    <>
      {userHasAccessTo(VIEW_STUDY_DETAILS) && (
        <section>
          <div className="setup-wrapper p-3 m-0 mt-2 border">
            <div className="statuses-header border-bottom-dotted">
              <p>
                Patient Statuses: <span>{studyName}</span>{' '}
              </p>
              {status === 'Inactive' ? (
                <p className="edit-statuses-inactive">Study inactive</p>
              ) : (
                <p className="edit-statuses">
                  {userHasAccessTo(MANAGE_STUDY_DETAILS) && (
                    <Button size="h28" priority="low" onClick={editStatusesSetup}>
                      <Icon suit="glyphicon">edit</Icon>
                    </Button>
                  )}
                </p>
              )}
            </div>
            <div className="selected-statuses">
              <div className="selected-column">
                <p className="selected-statuses-header">Expected Progression</p>
                <ul>{selectedExpected}</ul>
              </div>
              <div className="selected-column">
                <p className="selected-statuses-header">Final Disposition</p>
                <ul>{selectedDropOff}</ul>
              </div>
              <div className="selected-column invalid">
                {!isEmpty(selectedInvalid) && <p className="selected-statuses-header">Invalid for Study</p>}
                <ul>{selectedInvalid}</ul>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default StatusesSetup;
