import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { afterburnerApi } from '../../../../../api/patient/AftrburnerApi';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { SOMETHING_WENT_WRONG } from '../../../../../constants/notificationMessages';
import {
  ROLE_PRINCIPAL_INVESTIGATOR,
  ROLE_STUDY_MANAGER,
  ROLE_SYSTEM_ADMINISTRATOR
} from '../../../../../constants/userRoles';
import { userHasRole } from '../../../../../services/auth';
import { isAdverseEventLog } from '../../../../../services/itemGroupQuestionService';
import { withParams } from '../../../../root/router/legacyComponentCompatability';
import WithAutoDirection from '../NewEncounterPage/WithAutoDirection/WithAutoDirection';
import { TABLE } from '../Review/reviewConstants';
import { generateResearchConnectStatus } from '../Review/ReviewContent/reviewContentService';
import { AE_NOT_REPORTED } from '../Worklist/constants';

import { EditorAndDateSelect } from './EditorAndDateSelect';

import './EncounterElementHeader.scss';

class EncounterElementHeader extends Component {
  static propTypes = {
    formData: PropTypes.any,
    whenWasDoneDate: PropTypes.string,
    whenWasDoneDateOnChange: PropTypes.func,
    whoDidItList: PropTypes.array,
    whoDidItOnChange: PropTypes.func,
    whoDidItUniqueIdentifier: PropTypes.string,
    isAbleToSave: PropTypes.func,
    isAbleToSign: PropTypes.func,
    onSaveData: PropTypes.func,
    afterburnerEnabled: PropTypes.bool,
    isAnyQuestionAnswerChanged: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      saveIconDisabled: false
    };
  }

  render() {
    const { props } = this,
      {
        formData,
        whenWasDoneDate,
        whenWasDoneDateOnChange,
        whoDidItList,
        whoDidItOnChange,
        whoDidItUniqueIdentifier,
        isAbleToSave,
        isAbleToSign,
        onSaveData,
        onInvestigatorSign,
        onPushToInvestigator,
        onSmConfirm,
        adverseEventLogRowStatus,
        checkedInEncountersForPatient,
        encounterOnChange,
        selectedEncounterId,
        showEncounterSelect,
        elementIcon,
        isDetailRowOpen,
        validationError,
        afterburnerEnabled,
        params,
        isAnyQuestionAnswerChanged,
        noSkippedQuestions
      } = props;

    const isEncounterElementHeaderVisible = () => {
      const isCommentLogs = formData && formData.hasOwnProperty('comments');
      const isTable = elementIcon === TABLE;

      if (isCommentLogs || isTable) {
        return !!isDetailRowOpen;
      }
      return true;
    };

    const isPushToInvestigatorShown = () => {
      return !!!adverseEventLogRowStatus?.piStatus && adverseEventLogRowStatus?.smStatus;
    };

    const isMarkToCompleteShown = () => {
      return adverseEventLogRowStatus?.smStatus && adverseEventLogRowStatus.smStatus === 'SM_REVIEW';
    };

    const isSignButtonNotShown = () => {
      return (
        !isAnyQuestionAnswerChanged() &&
        ((adverseEventLogRowStatus?.smStatus === 'SM_REVIEW' && adverseEventLogRowStatus?.piStatus === 'COMPLETE') ||
          (adverseEventLogRowStatus?.smStatus === 'COMPLETE' && adverseEventLogRowStatus?.piStatus === 'COMPLETE'))
      );
    };

    const showSaveButtonForSmAndSysAdmin = () => {
      return (
        !!!adverseEventLogRowStatus?.piStatus || (isAnyQuestionAnswerChanged() && adverseEventLogRowStatus?.piStatus)
      );
    };

    const showSaveButtonShownForPi = () => {
      return (
        !!!adverseEventLogRowStatus?.piStatus ||
        adverseEventLogRowStatus?.piStatus === 'DATA_REQUIRED' ||
        (adverseEventLogRowStatus?.piStatus &&
          isAnyQuestionAnswerChanged() &&
          !['DATA_REQUIRED'].includes(adverseEventLogRowStatus.piStatus))
      );
    };

    const isAELogCanceled = () => {
      return (
        adverseEventLogRowStatus &&
        adverseEventLogRowStatus?.smStatus === 'CANCELED' &&
        adverseEventLogRowStatus?.piStatus === 'CANCELED'
      );
    };

    const isAeNotReported = () => {
      return (
        adverseEventLogRowStatus?.piStatus === AE_NOT_REPORTED && adverseEventLogRowStatus?.smStatus === AE_NOT_REPORTED
      );
    };

    const saveHeader = isAdverseEventLog(formData?.domainCode, formData?.elementIcon) ? 'Save' : 'Submit';

    const renderSystemAdminButtons = () => {
      return (
        <div className="item-group-buttons">
          {isAbleToSave() && !isAeNotReported() && (
            <Button
              size={'h28'}
              onClick={() => {
                this.setState({ saveIconDisabled: true }, () => {
                  onSaveData().then(() => this.setState({ saveIconDisabled: false }));
                });
              }}
              disabled={this.state.saveIconDisabled || !showSaveButtonForSmAndSysAdmin() || isAELogCanceled()}
            >
              {saveHeader}
            </Button>
          )}
          {isPushToInvestigatorShown() && (
            <Button
              size={'h28'}
              onClick={() => {
                onPushToInvestigator();
              }}
              disabled={isAELogCanceled()}
            >
              Push To Investigator
            </Button>
          )}
          {!isSignButtonNotShown() && !isAeNotReported() && (
            <Button
              size={'h28'}
              disabled={isAnyQuestionAnswerChanged() || !noSkippedQuestions || isAELogCanceled()}
              onClick={() => {
                onInvestigatorSign();
              }}
            >
              Sign
            </Button>
          )}
          {isMarkToCompleteShown() && (
            <Button
              size={'h28'}
              disabled={isAnyQuestionAnswerChanged() || isAELogCanceled()}
              onClick={() => {
                onSmConfirm();
              }}
            >
              Mark Complete
            </Button>
          )}
        </div>
      );
    };

    const renderSmButtons = () => {
      return (
        <div className="item-group-buttons">
          {isAbleToSave() && !isAeNotReported() && (
            <Button
              size={'h28'}
              onClick={() => {
                this.setState({ saveIconDisabled: true }, () => {
                  onSaveData().then(() => this.setState({ saveIconDisabled: false }));
                });
              }}
              disabled={this.state.saveIconDisabled || !showSaveButtonForSmAndSysAdmin() || isAELogCanceled()}
            >
              {saveHeader}
            </Button>
          )}
          {isMarkToCompleteShown() && (
            <Button
              size={'h28'}
              disabled={!isAbleToSign() || isAnyQuestionAnswerChanged() || isAELogCanceled()}
              onClick={() => {
                onSmConfirm();
              }}
            >
              Mark Complete
            </Button>
          )}
          {isPushToInvestigatorShown() && (
            <Button
              size={'h28'}
              onClick={() => {
                onPushToInvestigator();
              }}
              disabled={isAELogCanceled()}
            >
              Push To Investigator
            </Button>
          )}
        </div>
      );
    };

    const renderPiButtons = () => {
      return (
        <div className="item-group-buttons">
          {isAbleToSave() && !isAeNotReported() && (
            <Button
              size={'h28'}
              onClick={() => {
                this.setState({ saveIconDisabled: true }, () => {
                  onSaveData().then(() => this.setState({ saveIconDisabled: false }));
                });
              }}
              disabled={this.state.saveIconDisabled || !showSaveButtonShownForPi() || isAELogCanceled()}
            >
              {saveHeader}
            </Button>
          )}
          {!isAeNotReported() && (
            <Button
              size={'h28'}
              disabled={
                isSignButtonNotShown() || isAnyQuestionAnswerChanged() || !noSkippedQuestions || isAELogCanceled()
              }
              onClick={() => {
                onInvestigatorSign();
              }}
            >
              Sign
            </Button>
          )}
        </div>
      );
    };

    const resolveItemGroupButtonsByRoles = () => {
      if (userHasRole(ROLE_SYSTEM_ADMINISTRATOR) && isAdverseEventLog(formData?.domainCode, formData?.elementIcon)) {
        return renderSystemAdminButtons();
      } else if (userHasRole(ROLE_STUDY_MANAGER) && isAdverseEventLog(formData?.domainCode, formData?.elementIcon)) {
        return renderSmButtons();
      } else if (
        userHasRole(ROLE_PRINCIPAL_INVESTIGATOR) &&
        isAdverseEventLog(formData?.domainCode, formData?.elementIcon)
      ) {
        return renderPiButtons();
      } else {
        return (
          <div className="item-group-buttons">
            {isAbleToSave() && !isAeNotReported() && (
              <Button
                size={'h28'}
                onClick={() => {
                  this.setState({ saveIconDisabled: true }, () => {
                    onSaveData().then(() => this.setState({ saveIconDisabled: false }));
                  });
                }}
                disabled={this.state.saveIconDisabled || isAeNotReported() || isAELogCanceled()}
              >
                {saveHeader}
              </Button>
            )}
          </div>
        );
      }
    };

    return (
      <WithAutoDirection>
        <div
          className={cx(
            'encounter-element-header py-3',
            isAdverseEventLog(formData?.domainCode, formData?.elementIcon) ? 'adverse' : ''
          )}
        >
          {isEncounterElementHeaderVisible() && (
            <EditorAndDateSelect
              showEncounterSelect={showEncounterSelect}
              encounterOnChange={encounterOnChange}
              selectedEncounterId={selectedEncounterId}
              checkedInEncountersForPatient={checkedInEncountersForPatient}
              whenWasDoneDate={whenWasDoneDate}
              whenWasDoneDateOnChange={whenWasDoneDateOnChange}
              whoDidItList={whoDidItList}
              whoDidItUniqueIdentifier={whoDidItUniqueIdentifier}
              whoDidItOnChange={whoDidItOnChange}
              isAllowedToManage={isAbleToSave}
              disableEncounterSelector={true}
              validationError={validationError}
            />
          )}
          <ButtonGroup>
            {afterburnerEnabled &&
              formData.elementIcon !== 'Table' &&
              params?.patientEncounterId &&
              !isAeNotReported() && (
                <Button
                  size={'h28'}
                  onClick={() => sendToResearchConnect(params.ssuPatientId, params.patientItemGroupId)}
                  disabled={isAELogCanceled()}
                >
                  Send to Research Connect
                </Button>
              )}
            {resolveItemGroupButtonsByRoles()}
          </ButtonGroup>
        </div>
      </WithAutoDirection>
    );
  }
}

const sendToResearchConnect = (ssuPatientId, sitePatientItemGroupId) => {
  afterburnerApi
    .sendToAfterburnerFromItemGroupPage(ssuPatientId, sitePatientItemGroupId)
    .then(({ data }) => {
      const afterburnerStatus = generateResearchConnectStatus(data);
      if (afterburnerStatus) {
        NotificationManager.info(afterburnerStatus);
      }
    })
    .catch(() => {
      NotificationManager.error(SOMETHING_WENT_WRONG);
    });
};

const mapStateToProps = state => {
  return {
    afterburnerEnabled: state.appInfo.features.afterburnerEnabled
  };
};

export const EncounterElementHeaderWithRouter = withParams(EncounterElementHeader);
export default connect(mapStateToProps, null)(EncounterElementHeaderWithRouter);
