import React, { useContext, useMemo } from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash/lang';
import * as PropTypes from 'prop-types';

import { ReviewContext } from '../../../ReviewContext';
import { useReviewContentElement } from '../../ReviewContentElementContext/ReviewContentElementContext';
import * as reviewContentService from '../../reviewContentService';
import WhenWasItDoneAndWhoDidIt from '../../WhenWasItDoneAndWhoDidIt/WhenWasItDoneAndWhoDidIt';
import CommentSection from '../CommentSection/CommentSection';

import QuestionAnswer from './QuestionAnswer/QuestionAnswer';

import './ItemGroupProcedure.scss';

export default function ItemGroupProcedure({ snapshotAnswers, itemGroupSnapshotState }) {
  const {
    patientEncounterId,
    ssuPatientId,
    loadData,
    reviewType,
    isHistoricalData,
    reviewMetaData,
    updateItemGroupData,
    requiredAttentionItemGroupsConfirmStatuses
  } = useContext(ReviewContext);

  const { isAllowSign, commentIsReadonly } = useReviewContentElement();

  const {
    rows: [
      {
        rowRef,
        rowSnapshotFormData,
        rowSnapshotKey: {
          rowKey: { studyId }
        },
        rowReviewStatus
      }
    ]
  } = itemGroupSnapshotState;
  const { form: procedureFormData, rowId, rowVersion } = rowSnapshotFormData;
  const patientItemGroupId = rowRef.itemGroupRef.patientItemGroupId;
  const answers = procedureFormData?.answers;
  const commentAnswer = answers.find(answer => reviewContentService.isCommentAnswer(answer));
  const procedureFormRow = itemGroupSnapshotState?.rows[0];
  const editedQuestionIds = procedureFormRow?.editedQuestionIds;
  const editedAfterSignQuestionIds = procedureFormRow?.editedAfterSignQuestionIds;
  populateCommentEditFlags(commentAnswer, editedQuestionIds, editedAfterSignQuestionIds);
  const answersToRender = useMemo(() => prepareAnswersToRender(answers, itemGroupSnapshotState), [
    answers,
    itemGroupSnapshotState
  ]);
  const formHighlightStyle = reviewContentService.getFormHighlightStyleBasedOnRowReviewStatusAndReviewType(
    rowReviewStatus,
    reviewType,
    isAllowSign
  ).className;
  const answerHighlightStyle = reviewContentService.getAnswerHighlightStyleClassByAnswerAndReviewType(
    commentAnswer,
    reviewType,
    isAllowSign
  ).className;

  const isItemGroupConfirmed = requiredAttentionItemGroupsConfirmStatuses[patientItemGroupId];

  return (
    <div className="erc-item-group-procedure">
      <div className={cx('erc-item-group-procedure-q', formHighlightStyle)}>
        {!isEmpty(answersToRender) &&
          answersToRender.map((answer, key) => {
            return (
              <QuestionAnswer
                isItemGroupConfirmed={isItemGroupConfirmed}
                snapshotAnswers={snapshotAnswers}
                answer={answer}
                key={key}
              />
            );
          })}
      </div>
      <div className={cx('procedure-comment', answerHighlightStyle, formHighlightStyle)}>
        <CommentSection
          context={{
            patientEncounterId,
            ssuPatientId,
            loadData,
            reviewType,
            isHistoricalData,
            studySiteStatus: reviewMetaData.studySiteStatus
          }}
          updateData={updateItemGroupData}
          snapshotData={{ ...procedureFormData, rowId, rowVersion, commentAnswer, studyId }}
          patientItemGroupId={patientItemGroupId}
          readonly={commentIsReadonly}
        />
      </div>
      <WhenWasItDoneAndWhoDidIt
        whenWasItDone={procedureFormData.whenWasItDone}
        whoDidItName={procedureFormData.whoDidItName}
      />
    </div>
  );
}

function prepareAnswersToRender(answers, itemGroupSnapshotState) {
  const questions = itemGroupSnapshotState?.itemGroupTemplateVersion?.itemGroupVersionQuestions;
  const procedureFormRow = itemGroupSnapshotState?.rows[0];
  const editedQuestionIds = procedureFormRow?.editedQuestionIds;
  const editedAfterSignQuestionIds = procedureFormRow?.editedAfterSignQuestionIds;
  const answersToRender = answers
    ?.filter(answer => !reviewContentService.isCommentAnswer(answer))
    .filter(answer => !reviewContentService.isAnswerAffectedByConditionalLogic(answer)); // filter conditional logic
  populateQuestionMetaInfoForAnswers(answersToRender, questions);
  populateAnswersEditFlags(answersToRender, editedQuestionIds, editedAfterSignQuestionIds);

  return answersToRender;

  function populateAnswersEditFlags(answers, editedQuestionIds, editedAfterSignQuestionIds) {
    answers.forEach(answer => {
      answer.isEdited = editedQuestionIds?.includes(answer.itemGroupQuestionId);
      answer.isEditedAfterSign = editedAfterSignQuestionIds?.includes(answer.itemGroupQuestionId);
    });
  }

  function populateQuestionMetaInfoForAnswers(answers, questions) {
    answers.forEach(answer => {
      answer.question = questions.find(question => question.questionId === answer.itemGroupQuestionId);
    });
  }
}

function populateCommentEditFlags(comment, editedQuestionIds, editedAfterSignQuestionIds) {
  if (!comment) {
    return;
  }
  comment.isEdited = editedQuestionIds?.includes(comment.itemGroupQuestionId);
  comment.isEditedAfterSign = editedAfterSignQuestionIds?.includes(comment.itemGroupQuestionId);
}

ItemGroupProcedure.propTypes = {
  itemGroupSnapshotState: PropTypes.object.isRequired
};
