import React, { useEffect, useState } from 'react';
import { sortBy } from 'lodash';
import { isEmpty } from 'lodash/lang';
import moment from 'moment';

import InformedConsentApi from '../../../../../api/patient/InformedConsentApi';
import DatePicker from '../../../../../common/data-entry/DatePicker';
import Input from '../../../../../common/data-entry/Input';
import Select from '../../../../../common/data-entry/Select';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import {
  INFORMED_CONSENT_FORM_SAVE_FAILED,
  INFORMED_CONSENT_FORM_SAVED
} from '../../../../../constants/notificationMessages';
import MultipleFileTemplate from '../../../setup/shared/GenerateHtml/MultipleFileTemplate';

const UploadICFModal = ({ types, languages, activeTemplates, modalBox, ssuId, updateForms }) => {
  const [type, setType] = useState({});
  const [language, setLanguage] = useState({});
  const [versionDate, setVersionDate] = useState(null);
  const [invalidTypeAndLanguageCombination, setInvalidTypeAndLanguageCombination] = useState(false);
  const [file, setFile] = useState({
    name: '',
    data: ''
  });
  const [version, setVersion] = useState('');
  const [uploadEnabled, setUploadEnabled] = useState(false);
  const today = moment();

  function onClose() {
    resetAll();
    modalBox.close();
  }

  const onSave = () => {
    if (activeTemplates.some(template => template.typeId === type.id && template.languageId === language.id)) {
      InformedConsentApi.uploadForm(ssuId, {
        type: type.id,
        language: language.id,
        version,
        versionDate,
        file
      })
        .then(() => {
          updateForms();
          NotificationManager.success(INFORMED_CONSENT_FORM_SAVED);
        })
        .catch(() => {
          NotificationManager.error(INFORMED_CONSENT_FORM_SAVE_FAILED);
        })
        .finally(() => {
          resetAll();
          modalBox.close();
        });
    } else {
      setInvalidTypeAndLanguageCombination(true);
    }
  };

  const onChangeType = type => {
    setInvalidTypeAndLanguageCombination(false);
    setType(type);
  };

  const onChangeLanguage = language => {
    setInvalidTypeAndLanguageCombination(false);
    setLanguage(language);
  };

  const disableFutureDates = current => {
    return current.isBefore(today);
  };

  const onChangeDate = date => {
    if (date) {
      setVersionDate(date.format('DD/MMM/YYYY'));
    } else {
      setVersionDate(null);
    }
  };

  const onChangeVersion = version => {
    setVersion(version);
  };

  const resetAll = () => {
    setType({});
    setLanguage({});
    setVersionDate(null);
    resetFile();
    setVersion('');
  };

  const resetFile = () => {
    setFile({
      name: '',
      data: ''
    });
  };

  const onChangeFileHandler = target => {
    const file = target;

    if (!file) {
      resetFile();
      return;
    }

    const reader = new FileReader();
    reader.onload = upload => {
      const data = upload.target.result && upload.target.result.split(',');
      setFile({
        name: file.name,
        data: data[1]
      });
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    const requiredFieldsFilled = !isEmpty(type) && !isEmpty(language) && !isEmpty(versionDate);
    const fileSelected = !isEmpty(file.data) && !isEmpty(file.name);
    requiredFieldsFilled && fileSelected ? setUploadEnabled(true) : setUploadEnabled(false);
  }, [type, language, versionDate, file]);

  return (
    <>
      <ModalBoxes.Header onClose={onClose}>New ICF Upload</ModalBoxes.Header>
      <ModalBoxes.Body>
        <div style={{ display: 'flex' }}>
          <div style={{ width: 400 }}>
            <Select
              name="type"
              dataSource={sortBy(types, ['type'])}
              value={type}
              label="Type of ICF"
              onChange={onChangeType}
              optionLabelKey="type"
              required={true}
            />
            <Select
              name="language"
              dataSource={sortBy(languages, ['language'])}
              value={language}
              label="Language"
              onChange={onChangeLanguage}
              optionLabelKey="language"
              required={true}
            />
            <DatePicker.Manual
              className="mt-3"
              dateOnly={true}
              id={'version-date'}
              label={'ICF IRB Approval Date'}
              value={versionDate}
              onChange={onChangeDate}
              required
              validate={true}
              isValidDate={disableFutureDates}
            />
            <Input
              label="Version"
              name="version"
              value={version}
              onChange={({ target }) => onChangeVersion(target.value)}
            />
            <MultipleFileTemplate
              templateData={{}}
              onChangeFileHandler={target => {
                onChangeFileHandler(target[0].originalFile);
              }}
              acceptUploadTypes={['.pdf', 'application/pdf']}
              isAbleToEditFunction={() => true}
              multiple={false}
              isFilePreviewAvailable={false}
              askForCertificateCopy={false}
              onCloseSelectedFile={() => onChangeFileHandler(null)}
            />
          </div>
          {invalidTypeAndLanguageCombination && (
            <div
              style={{
                marginTop: 60,
                justifyContent: 'center',
                display: 'flex',
                width: 300,
                color: 'red',
                textAlign: 'center'
              }}
            >
              No item group configured for <br />
              {type ? type.type : 'Hello'} {language ? language.language : 'World'}
            </div>
          )}
        </div>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority={'medium'} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onSave} disabled={!uploadEnabled}>
            Save
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
};

UploadICFModal.className = 'informed-consent-item-modal';
UploadICFModal.size = 'w800';

export default UploadICFModal;
