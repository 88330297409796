import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, MenuGroup, MenuItem } from '@szhsin/react-menu';
import { isEmpty } from 'lodash';

import { SitePatientEncounterApi } from '../../../../../../../api';
import SituationalEncounterApi from '../../../../../../../api/calendar/SituationalEncounterApi';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import Icon from '../../../../../../../common/general/Icon';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import { onRequestError } from '../../../../../../../services/handlers';
import { generateUrlByKey, useCurrentRoute } from '../../../../../../root/router';
import { PatientInfoContext } from '../../PatientInfoContext';
import AddNoAppointmentsEncounterModal from '../AddWorkflowEncounterModal/AddNoAppointmentsEncounterModal';
import { useEncountersSection } from '../EncountersSectionContext';
import ScheduleAppointmentModal from '../ScheduleAppointmentModal/ScheduleAppointmentModal';

import './EncountersSectionMenu.scss';

export default function EncountersSectionMenu({ reloadTab }) {
  const currentRoute = useCurrentRoute();
  const { currentStudy } = useContext(PatientInfoContext);
  const [situationalEncounters, setSituationalEncounters] = useState([]);
  const navigate = useNavigate();
  const patientId = currentRoute.params?.patientId;
  const ssuPatientId = currentRoute.params?.ssuPatientId;
  const currentRouteKey = currentRoute?.key;
  const ssuId = currentStudy?.ssuId;
  const studyId = currentStudy?.studyId;
  const siteId = currentStudy?.siteId;
  const siteName = currentStudy?.siteName;

  const { noAppointmentsEpochs, loadStudyNoAppointmentsEncounters } = useEncountersSection();

  const noAppointmentsEncounters = useMemo(
    function() {
      return noAppointmentsEpochs.flatMap(epoch => epoch.encounters);
    },
    [noAppointmentsEpochs]
  );

  const { isAllowedToManageEncounters, isAllowedToManageUnexpectedEncounters } = useEncountersSection();

  const notAllowedToAddEncounter = useMemo(
    function() {
      if (!currentStudy?.granted) {
        return true;
      }

      return currentStudy?.statusCategory === 'DROP_FROM_STATUS';
    },
    [currentStudy]
  );

  useEffect(() => {
    if (studyId && isAllowedToManageEncounters) {
      SituationalEncounterApi.getSituationalEncounterByStudyId(studyId).then(res => {
        setSituationalEncounters(res.data);
      });
    }
  }, [isAllowedToManageEncounters, studyId]);

  const createUnexpectedEncounterDetailsPageUrl = useMemo(
    function() {
      return generateUrlByKey(
        `${currentRouteKey}.Create Unexpected Encounter`,
        {
          patientId,
          ssuPatientId
        },
        { ssuId, studyId }
      );
    },
    [patientId, currentRouteKey, ssuPatientId, ssuId, studyId]
  );

  return (
    isAllowedToManageEncounters && (
      <Menu
        align="start"
        direction="left"
        portal={true}
        menuButton={
          <Button size="h28">
            <Icon>add</Icon>
            Add
          </Button>
        }
      >
        <MenuGroup>
          {isAllowedToManageEncounters && (
            <MenuItem disabled={notAllowedToAddEncounter} onClick={scheduleAppointment}>
              Appointment
            </MenuItem>
          )}
          {isAllowedToManageEncounters && (
            <MenuItem
              disabled={notAllowedToAddEncounter || isEmpty(noAppointmentsEncounters)}
              onClick={addNoAppointmentsEncounter}
            >
              Workflow Encounter
            </MenuItem>
          )}
          {isAllowedToManageEncounters &&
            situationalEncounters.map(enc => (
              <MenuItem
                key={enc.id}
                disabled={notAllowedToAddEncounter}
                onClick={() => createSituationalEncounter(enc.id)}
                title={enc.name.length > 50 ? enc.name : null}
                className="bold-text"
              >
                {enc.name}
              </MenuItem>
            ))}
          {isAllowedToManageUnexpectedEncounters && (
            <MenuItem disabled={!currentStudy?.granted}>
              <Link to={createUnexpectedEncounterDetailsPageUrl}>Unexpected Encounter</Link>
            </MenuItem>
          )}
        </MenuGroup>
        )
      </Menu>
    )
  );

  function addNoAppointmentsEncounter() {
    if (noAppointmentsEncounters.length > 1) {
      const modalBox = ModalBoxes.open({
        component: (
          <AddNoAppointmentsEncounterModal
            epochs={noAppointmentsEpochs}
            studyId={studyId}
            ssuPatientId={ssuPatientId}
            onSave={function(encounterKey) {
              createNoAppointmentsEncounter(encounterKey).then(modalBox.close);
            }}
          />
        )
      });
      return;
    }
    createNoAppointmentsEncounter(noAppointmentsEncounters[0].encounterKey);
  }

  function createNoAppointmentsEncounter(encounterKey) {
    return SitePatientEncounterApi.createNoAppointmentsEncounter(ssuPatientId, encounterKey).then(res => {
      const ssuPatientEncounterId = res.data;
      navigate(getUrlToEncounterDetailsPage(ssuPatientId, patientId, ssuPatientEncounterId, currentRoute));
      NotificationManager.success('No Appointments encounter was created');
      loadStudyNoAppointmentsEncounters();
      reloadTab();
    }, onRequestError);
  }

  function createSituationalEncounter(encounterKey) {
    return SitePatientEncounterApi.situationalCheckIn(ssuPatientId, encounterKey).then(res => {
      const ssuPatientEncounterId = res.data;
      navigate(getUrlToEncounterDetailsPage(ssuPatientId, patientId, ssuPatientEncounterId, currentRoute));
      NotificationManager.success('Situational encounter was created');
      loadStudyNoAppointmentsEncounters();
      reloadTab();
    }, onRequestError);
  }

  function scheduleAppointment() {
    ModalBoxes.open({
      component: (
        <ScheduleAppointmentModal
          patientId={patientId}
          ssuPatientId={ssuPatientId}
          studyId={studyId}
          sourcePage={currentRouteKey}
          siteId={siteId}
          siteName={siteName}
        />
      )
    });
  }
  function getUrlToEncounterDetailsPage(ssuPatientId, patientId, ssuPatientEncounterId, currentRoute) {
    return generateUrlByKey(`${currentRoute.key}.Encounter Details`, {
      patientId,
      ssuPatientId,
      patientEncounterId: ssuPatientEncounterId
    });
  }
}
