import { PATIENT_BULK_UPDATE } from '../../../../constants/accessToPages';

import { PatientBulkUpdate } from './PatientBulkUpdate';

export const patientBulkUpdateRouterConfig = {
  name: 'Patient Bulk Update',
  path: '/patient-bulk-update',
  component: PatientBulkUpdate,
  access: [PATIENT_BULK_UPDATE]
};
