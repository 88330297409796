import React, { useState } from 'react';

import { SitePatientEncounterApi } from '../../../../../../api';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import { VISIT_DATE_CONFIRMED } from '../../../../../../constants/notificationMessages';
import EdcVisitDatePicker from '../../EncounterDescNew/EncounterDetailsHeader/EdcVisitDatePicker/EdcVisitDatePicker';

import './EdcVisitDateConfirmModal.scss';

export default function EdcVisitDateConfirmModal({ modalBox, ssuPatientId, patientEncounterId, originEdcVisitDate }) {
  const [selectedEdcVisitDate, setSelectedEdcVisitDate] = useState(originEdcVisitDate);

  const onConfirm = () => {
    if (selectedEdcVisitDate !== originEdcVisitDate) {
      SitePatientEncounterApi.updateEdcVisitDate(ssuPatientId, patientEncounterId, {
        edcVisitDate: selectedEdcVisitDate
      }).then(({ data }) => {
        NotificationManager.success(VISIT_DATE_CONFIRMED);
      });
    } else {
      NotificationManager.success(VISIT_DATE_CONFIRMED);
    }
    modalBox.close();
  };

  return (
    <>
      <ModalBoxes.Header>Confirm Encounter Visit Date</ModalBoxes.Header>
      <ModalBoxes.Body>
        <span className="title">
          Before you complete this encounter, please confirm that the visit date is correct.
        </span>
        <div className="edc-visit-date-picker-layout">
          <span className="label">Visit Date:</span>
          <EdcVisitDatePicker
            value={selectedEdcVisitDate}
            onChange={date => {
              setSelectedEdcVisitDate(date.format('YYYY-MM-DD'));
            }}
            isAllowedToManage={true}
          />
        </div>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <Button onClick={onConfirm}>Confirm</Button>
      </ModalBoxes.Footer>
    </>
  );
}

EdcVisitDateConfirmModal.className = 'eds-confirm-edc-date-modal';
EdcVisitDateConfirmModal.size = 'w800';
