import { isUndefined } from 'lodash/lang';

import { ROLE_PRINCIPAL_INVESTIGATOR, ROLE_SYSTEM_ADMINISTRATOR } from '../constants/userRoles';

import { userHasRole } from './auth';

export function filtrateByInvestigatorFieldIfNeed(elements, formDefinition) {
  const isUserPi = userHasRole(ROLE_PRINCIPAL_INVESTIGATOR);
  const isUserSa = userHasRole(ROLE_SYSTEM_ADMINISTRATOR);
  if (
    !isUserPi &&
    !isUserSa &&
    !isUndefined(formDefinition) &&
    isInvestigatorFieldAvailable(formDefinition.domainCode)
  ) {
    return elements.filter(
      element =>
        element.investigatorField === undefined ||
        element.investigatorField === null ||
        element.investigatorField === false
    );
  } else {
    return elements;
  }
}

export function checkOnInvestigatorFieldIfNeeded(element, domainCode) {
  const isPi = userHasRole(ROLE_PRINCIPAL_INVESTIGATOR);
  const isAdmin = userHasRole(ROLE_SYSTEM_ADMINISTRATOR);
  if (!isPi && !isAdmin && isInvestigatorFieldAvailable(domainCode)) {
    return (
      element.investigatorField === undefined ||
      element.investigatorField === null ||
      element.investigatorField === false
    );
  }
  return true;
}

export function isNotNewAdverseEventLog(rowId, domainCode, elementType) {
  return rowId && rowId !== 'newRow' && isAdverseEventLog(domainCode, elementType);
}

export function isAdverseEventLog(domainCode, elementIcon) {
  return domainCode === 'AE' && elementIcon === 'Logs';
}

export function isInvestigatorFieldAvailable(domainCode) {
  return !['EPS', 'EPSTP', 'EDO', 'ECR', 'EREVSM', 'EREVPI'].includes(domainCode);
}
