import { PatientEncounterReviewApi } from '../../../../../api';

import { isCraReview, isPiReview, isSmReview } from './reviewService';

const ReviewDataResolver = {
  getItemGroupsAndLogsByType(reviewType, { ssuPatientId, patientEncounterId, patientItemGroupId }) {
    if (isSmReview(reviewType)) {
      return PatientEncounterReviewApi.getSmReview(ssuPatientId, patientEncounterId, patientItemGroupId);
    }
    if (isPiReview(reviewType)) {
      return PatientEncounterReviewApi.getPiReview(ssuPatientId, patientEncounterId, patientItemGroupId);
    }
    if (isCraReview(reviewType)) {
      return PatientEncounterReviewApi.getCraReview(ssuPatientId, patientEncounterId, patientItemGroupId);
    }
    return Promise.reject(new Error('Incorrect review type'));
  },
  getItemGroupRowSnapshotReviewStateByTypeAndSignatureId(
    reviewType,
    signatureId,
    { ssuPatientId, patientEncounterId, itemGroupId },
    hideLoader = true
  ) {
    if (signatureId) {
      return PatientEncounterReviewApi.getSignedItemGroupRowSnapshotReviewState(
        ssuPatientId,
        patientEncounterId,
        itemGroupId,
        signatureId,
        hideLoader
      );
    }

    if (isSmReview(reviewType)) {
      return PatientEncounterReviewApi.getItemGroupRowSnapshotSmReviewState(
        ssuPatientId,
        patientEncounterId,
        itemGroupId,
        hideLoader
      );
    }

    if (isPiReview(reviewType)) {
      return PatientEncounterReviewApi.getItemGroupRowSnapshotPiReviewState(
        ssuPatientId,
        patientEncounterId,
        itemGroupId,
        hideLoader
      );
    }

    if (isCraReview(reviewType)) {
      return PatientEncounterReviewApi.getItemGroupRowSnapshotCraReviewState(
        ssuPatientId,
        patientEncounterId,
        itemGroupId,
        hideLoader
      );
    }

    return Promise.reject(new Error('Incorrect review type'));
  },

  getLogCheckSnapshotReviewStateByTypeAndSignatureId(
    reviewType,
    signatureId,
    { ssuPatientId, patientEncounterId, reviewItemGroupId, patientItemGroupId },
    hideLoader = true
  ) {
    if (signatureId) {
      return PatientEncounterReviewApi.getSignedLogCheckSnapshotReviewState(
        ssuPatientId,
        patientEncounterId,
        patientItemGroupId,
        signatureId,
        hideLoader
      );
    }
    if (isSmReview(reviewType)) {
      return PatientEncounterReviewApi.getLogCheckSnapshotSmReviewState(
        ssuPatientId,
        patientEncounterId,
        patientItemGroupId,
        hideLoader
      );
    }

    if (isPiReview(reviewType)) {
      return PatientEncounterReviewApi.getLogCheckSnapshotPIReviewState(
        ssuPatientId,
        patientEncounterId,
        patientItemGroupId,
        hideLoader
      );
    }

    if (isCraReview(reviewType)) {
      return PatientEncounterReviewApi.getLogCheckSnapshotSraReviewState(
        ssuPatientId,
        patientEncounterId,
        patientItemGroupId,
        hideLoader
      );
    }

    return Promise.reject(new Error('Incorrect review type'));
  },

  getLogSnapshotReviewStateByTypeAndSignatureId(
    reviewType,
    signatureId,
    { ssuPatientId, patientEncounterId, itemGroupTemplateId },
    hideLoader = true
  ) {
    if (signatureId) {
      return PatientEncounterReviewApi.getSignedLogSnapshotReviewState(
        ssuPatientId,
        patientEncounterId,
        itemGroupTemplateId,
        signatureId,
        hideLoader
      );
    }

    if (isSmReview(reviewType)) {
      return PatientEncounterReviewApi.getLogSnapshotSmReviewState(
        ssuPatientId,
        patientEncounterId,
        itemGroupTemplateId,
        hideLoader
      );
    }

    if (isPiReview(reviewType)) {
      return PatientEncounterReviewApi.getLogSnapshotPiReviewState(
        ssuPatientId,
        patientEncounterId,
        itemGroupTemplateId,
        hideLoader
      );
    }

    if (isCraReview(reviewType)) {
      return PatientEncounterReviewApi.getLogSnapshotCraReviewState(
        ssuPatientId,
        patientEncounterId,
        itemGroupTemplateId,
        hideLoader
      );
    }
    return Promise.reject(new Error('Incorrect review type'));
  },

  getReviewHistoryByReviewType(reviewType, ssuPatientId, patientEncounterId) {
    if (isSmReview(reviewType)) {
      return PatientEncounterReviewApi.getSmHistory(ssuPatientId, patientEncounterId);
    }
    if (isPiReview(reviewType)) {
      return PatientEncounterReviewApi.getPiHistory(ssuPatientId, patientEncounterId);
    }
    if (isCraReview(reviewType)) {
      return PatientEncounterReviewApi.getCraHistory(ssuPatientId, patientEncounterId);
    }
    return Promise.reject(new Error('Incorrect review type'));
  },

  signReview({ ssuPatientId, patientEncounterId, patientItemGroupId, request, token }, reviewType) {
    if (isSmReview(reviewType)) {
      return PatientEncounterReviewApi.signSmReview(
        ssuPatientId,
        patientEncounterId,
        patientItemGroupId,
        token,
        request
      );
    }
    if (isPiReview(reviewType)) {
      return PatientEncounterReviewApi.signPiReview(
        ssuPatientId,
        patientEncounterId,
        patientItemGroupId,
        token,
        request
      );
    }
    return Promise.reject(new Error('Unsupported review type'));
  }
};

export default ReviewDataResolver;
