import React from 'react';

import { StepHeader } from '../StepHeader/StepHeader';

import { MissedBudgetFilters } from './MissedBudgetFilters/MissedBudgetFilters';
import { MissedBudgetTable } from './MissedBudgetTable/MissedBudgetTable';

import './SelectMissedBudgetItem.scss';

export function SelectMissedBudgetItem() {
  return (
    <div className="select-missed-budget-item">
      <StepHeader>Select Budget Event</StepHeader>
      <MissedBudgetFilters />
      <MissedBudgetTable />
    </div>
  );
}
