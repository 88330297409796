import React, { Component } from 'react';
import { sortBy } from 'lodash';
import { uniqBy } from 'lodash/array';
import get from 'lodash/get';
import { isArray, isFunction } from 'lodash/lang';

import { StudySiteApi } from '../../api';
import Common from '../../common/common';
import { CANCELED } from '../../constants/ssuStatuses';

import { SSUFilterRepresentation } from './SSUFilterRepresentation';

export class SSUFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studySites: [],
      selectedStudy: null,
      selectedSite: null
    };
  }

  getProvidedSsuOrLoadFromServer = () => {
    const resolveStudySitesMethod = this.props.enrollmentMode
      ? StudySiteApi.getStudySitesEnrollment()
      : StudySiteApi.getAllStudySitesAndMap();
    return isFunction(this.props.ssuProvider)
      ? Promise.resolve(this.props.ssuProvider())
      : resolveStudySitesMethod.then(response => response.data);
  };
  getInitialStudyAndSite = (studySites, { ssuIdProvider, studyIdProvider, siteIdProvider, isStudyRequired }) => {
    let selectedStudy;
    let selectedSite;
    if (ssuIdProvider && ssuIdProvider()) {
      const selectedSsu = this.findSsuById(studySites, ssuIdProvider);
      selectedStudy = selectedSsu.study;
      selectedSite = selectedSsu.site;
    } else {
      selectedStudy = this.findStudyById(studySites, studyIdProvider);
      selectedSite = this.findSiteById(studySites, siteIdProvider);
    }

    if ((selectedStudy === undefined || selectedStudy === null) && isStudyRequired) {
      selectedStudy = this.getFirstStudy(studySites);
    }
    return { selectedStudy, selectedSite };
  };

  componentDidMount() {
    this.ssuFilterPrepareData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.handleSsuProviderUpdate && prevProps.ssuProvider().length !== this.props.ssuProvider().length) {
      this.ssuFilterPrepareData();
    }

    if (this.props.resetFilterIfNoValue && someItemIsSelected(prevProps) && undefinedInitialValue(this.props)) {
      this.ssuFilterPrepareData();
    }

    function someItemIsSelected({ ssuIdProvider, studyIdProvider, siteIdProvider }) {
      return (
        (isFunction(ssuIdProvider) && ssuIdProvider()) ||
        (isFunction(studyIdProvider) && studyIdProvider()) ||
        (isFunction(siteIdProvider) && siteIdProvider())
      );
    }

    function undefinedInitialValue({ ssuIdProvider, studyIdProvider, siteIdProvider }) {
      return (
        !(isFunction(ssuIdProvider) && ssuIdProvider()) &&
        !(isFunction(studyIdProvider) && studyIdProvider()) &&
        !(isFunction(siteIdProvider) && siteIdProvider())
      );
    }
  }

  ssuFilterPrepareData() {
    this.getProvidedSsuOrLoadFromServer().then(studySites => {
      const nonCanceledStudySites = isArray(studySites) ? studySites.filter(ssu => ssu.siteStatus !== CANCELED) : [];
      this.setState({ studySites: nonCanceledStudySites });
      const { selectedStudy, selectedSite } = this.getInitialStudyAndSite(nonCanceledStudySites, this.props);
      this.updateStateEmitSSUChangeUp(selectedStudy, selectedSite);
    });
  }

  findSsuById(ssus, ssuIdProvider) {
    if (!isFunction(ssuIdProvider)) {
      return;
    }
    const id = ssuIdProvider();
    return id ? ssus.find(ssu => ssu.uniqueIdentifier === id) : null;
  }

  findSiteById(ssus, idProvider) {
    if (!isFunction(idProvider)) {
      return null;
    }
    const id = idProvider();
    return id ? ssus.find(ssu => ssu.site.uniqueIdentifier === id)?.site : null;
  }

  findStudyById(ssus, idProvider) {
    if (!isFunction(idProvider)) {
      return null;
    }
    const id = idProvider();
    return id ? ssus.find(ssu => ssu.study.uniqueIdentifier === id)?.study : null;
  }

  redundantStudyChangeTriggered(selectedStudy) {
    return (
      (selectedStudy &&
        this.state.selectedStudy &&
        selectedStudy.uniqueIdentifier === this.state.selectedStudy.uniqueIdentifier) ||
      (this.state.selectedStudy === null && selectedStudy === undefined) ||
      (this.state.selectedStudy === undefined && selectedStudy === undefined)
    );
  }

  redundantSiteChangeTriggered(selectedSite) {
    return (
      (selectedSite &&
        this.state.selectedSite &&
        selectedSite.uniqueIdentifier === this.state.selectedSite.uniqueIdentifier) ||
      (this.state.selectedSite === null && selectedSite === undefined) ||
      (this.state.selectedSite === undefined && selectedSite === undefined)
    );
  }

  handleStudyChange = selectedStudy => {
    if (this.redundantStudyChangeTriggered(selectedStudy)) return;
    const sites = this.getSiteFilter(this.state.studySites, selectedStudy);
    let selectedSite =
      selectedStudy && !this.state.selectedSite ? this.getIfSingleSite(sites) : this.state.selectedSite;
    this.updateStateEmitSSUChangeUp(selectedStudy, selectedSite);
  };

  handleSiteChange = selectedSite => {
    if (this.redundantSiteChangeTriggered(selectedSite)) return;
    const studies = this.getStudyFilter(this.state.studySites, selectedSite);
    let selectedStudy =
      selectedSite && !this.state.selectedStudy && !this.props.noStudyAutoSelect
        ? this.getIfSingleStudy(studies)
        : this.state.selectedStudy;
    this.updateStateEmitSSUChangeUp(selectedStudy, selectedSite);
  };

  updateStateEmitSSUChangeUp(selectedStudy, selectedSite) {
    this.setState({ selectedStudy, selectedSite }, () => {
      this.props.handleSSUFilterChange(
        this.getFilteredSSU(this.state.studySites, selectedStudy, selectedSite),
        selectedStudy,
        selectedSite
      );
    });
  }

  getIfSingleStudy = studies => {
    return studies.length === 1 ? studies[0] : null;
  };

  getIfSingleSite = sites => {
    return sites.length === 1 ? sites[0] : null;
  };

  getFirstStudy = studySites => {
    const sortedStudySites = this.sortStudyInAlphabeticalOrder(studySites);
    return sortedStudySites.length > 0 ? sortedStudySites[0].study : null;
  };

  sortStudyInAlphabeticalOrder(studySites) {
    return sortBy(studySites, [
      function(studyName) {
        return studyName.study.studyName;
      }
    ]);
  }

  getFilteredSSU(studySites, selectedStudy, selectedSite) {
    const bySelectedStudyIfAny = ssu => !selectedStudy || ssu.studyIdentifier === selectedStudy.uniqueIdentifier;
    const bySelectedSiteIfAny = ssu => !selectedSite || ssu.siteIdentifier === selectedSite.uniqueIdentifier;

    return isArray(studySites) && studySites.filter(bySelectedStudyIfAny).filter(bySelectedSiteIfAny);
  }

  byStudyIfAny = studyId => ssu => !studyId || ssu.studyIdentifier === studyId;

  bySiteIfAny = siteId => ssu => !siteId || ssu.siteIdentifier === siteId;

  getSiteFilter = (studySites, selectedStudy) => {
    const selectedStudyId = get(selectedStudy, 'uniqueIdentifier');
    return (
      isArray(studySites) &&
      uniqBy(studySites.filter(this.byStudyIfAny(selectedStudyId)), 'siteIdentifier')
        .filter(({ study }) => !this.props?.studiesToReject?.includes(study.uniqueIdentifier))
        .map(ssu => ssu.site)
        .sort(Common.generateSortFuncObjectFieldAlphabetical('siteName'))
    );
  };

  getStudyFilter = (studySites, selectedSite) => {
    const selectedSiteId = get(selectedSite, 'uniqueIdentifier');
    return (
      isArray(studySites) &&
      uniqBy(studySites.filter(this.bySiteIfAny(selectedSiteId)), 'studyIdentifier')
        .map(ssu => ssu.study)
        .filter(study => !this.props?.studiesToReject?.includes(study.uniqueIdentifier))
        .sort(Common.generateSortFuncObjectFieldAlphabetical('studyName'))
    );
  };
  renderChildren = () => {
    const props = {
      studies: this.getStudyFilter(this.state.studySites, this.state.selectedSite),
      sites: this.getSiteFilter(this.state.studySites, this.state.selectedStudy),
      handleStudyChange: this.handleStudyChange,
      handleSiteChange: this.handleSiteChange,
      initialStudy: this.state.selectedStudy,
      initialSite: this.state.selectedSite,
      isStudyRequired: this.props.isStudyRequired,
      clearable: this.props.clearable
    };

    if (this.props.children) {
      return React.Children.map(this.props.children, child => {
        return React.cloneElement(child, props);
      });
    }
    return SSUFilterRepresentation(props);
  };

  render() {
    return this.renderChildren();
  }
}
