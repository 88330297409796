import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';

export default function ViewNoteModal({ row, modalBox }) {
  return (
    <>
      <ModalBoxes.Header>Note</ModalBoxes.Header>
      <ModalBoxes.Body>
        <Box sx={{ flexGrow: 1 }} component="form">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">{row.note || `Note wasn't added`}</Typography>
            </Grid>
          </Grid>
        </Box>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            Close
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
}

ViewNoteModal.size = 'w500';
