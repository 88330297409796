import React from 'react';
import { get } from 'lodash/object';

import Common from '../../../../../../common/common';
import { composePatientName, hasUserAccessToProtectedPatientInfo } from '../../../CalendarUtils';

import { getCanceledEventStyle, getDisabledEventStyle } from './EventsUtils';

import './Events.scss';

export function Event({ event }) {
  const elements = [
    <div className="custom-event-label">
      {Common.getTimefromDate(event.start, 12).toUpperCase()} - {Common.getTimefromDate(event.end, 12).toUpperCase()}:{' '}
      <strong>{event.title}</strong>
      <strong key={1} className={'no-wrap study-name-container'}>
        {event?.study && event?.study?.name ? event.study.name : 'No Study'}
      </strong>
    </div>
  ];
  if (event.patient) {
    elements.push(getAllPatientName(event));
  }
  return elements;
}

export function EventShort({ event }) {
  return <div className="rbc-event-short">{event.children}</div>;
}

export function EventDisabled({ event }) {
  return <div style={getDisabledEventStyle(event)} />;
}

function getAllPatientName(event) {
  return (
    <strong key={2} className={'no-wrap patient-name'}>
      {composePatientName(event, get(event, 'patient'), get(event, 'patientSubjectId'))}
      {hasUserAccessToProtectedPatientInfo(event) && event.patient?.preferredName && (
        <span className="patient-preferred-name"> ({event.patient.preferredName})</span>
      )}
    </strong>
  );
}

export function EventCanceled({ event }) {
  let ev = event.event;
  const elements = [];
  if (ev.patient) {
    elements.push(getAllPatientName(ev));
  }
  return (
    <EventWithoutDragAndDropAndResize
      onClick={event.onClick}
      style={getCanceledEventStyle(event)}
      className="rbc-event rbc-event-canceled"
      key={event.event.id}
      content={[event.event.title, ...elements]}
    />
  );
}

export function EventWithoutDragAndDropAndResize(props) {
  return <div {...props}>{props.content}</div>;
}
