import React from 'react';
import cx from 'classnames';
import moment from 'moment';

import { ROLE_OPERATIONS_ANALYST } from '../../../../constants/userRoles';
import { useCurrentUser } from '../../../root/Container/CurrentUserContainer';
import { generateUrlByKey } from '../../../root/router';
import {
  MILESTONE_EVENT,
  NON_PATIENT_EVENT,
  PATIENT_ENCOUNTER_EVENT,
  PATIENT_SITUATIONAL_ENCOUNTER_EVENT,
  PATIENT_UNEXPECTED_ENCOUNTER_EVENT
} from '../CalendarEventType';
import { hasUserAccessToProtectedPatientInfo } from '../CalendarUtils';

import { MilestoneAppointmentViewContent } from './MilestoneAppointmentViewContent/MilestoneAppointmentViewContent';
import { NonPatientAppointmentViewContent } from './NonPatientAppointmentViewContent/NonPatientAppointmentViewContent';
import { PatientAppointmentViewContent } from './PatientAppointmentViewContent/PatientAppointmentViewContent';
import { PatientEncounterAppointmentViewContent } from './PatientEncounterAppointmentViewContent/PatientEncounterAppointmentViewContent';
import { PatientSituationalEncounterViewContent } from './PatientSituationalEncounterViewContent/PatientSituationalEncounterViewContent';
import { CancelingDetails } from './CancelingDetails';

import '../AppointmentEdit.scss';
import './AppointmentView.scss';

export default function AppointmentView({ initialAppointment = {}, children }) {
  const currentUser = useCurrentUser();
  const composeButtonBar = () => {
    if (children) {
      return React.Children.map(children, child => {
        return React.cloneElement(child, {
          ...child.props,
          onCancel: (reasonId, comment) =>
            child.props.onCancel(initialAppointment.eventId, initialAppointment.type, reasonId, comment)
        });
      });
    }
  };

  const start = moment(initialAppointment.start).format('DD/MMM/YY, h:mm A');
  const end = moment(initialAppointment.start)
    .clone()
    .add(initialAppointment.duration)
    .format('h:mm A');
  const durationString = initialAppointment.duration.format('h [hrs] m [min]', { trim: 'all' });
  const abilityToCreateOrCancel = hasUserAccessToProtectedPatientInfo(initialAppointment);
  const type = initialAppointment.type;
  const isComposeButtonBarVisible =
    type !== MILESTONE_EVENT && !initialAppointment.canceled && abilityToCreateOrCancel && children;
  const isCreateOrCancelButtonsVisible =
    currentUser.activeRole === ROLE_OPERATIONS_ANALYST
      ? isComposeButtonBarVisible && type === NON_PATIENT_EVENT
      : isComposeButtonBarVisible;
  return (
    <div className="appointment-component">
      <div className="appointment-view-header">
        {initialAppointment.canceled ? 'Canceled Appointment' : 'Appointment Details'}
      </div>
      <div
        className={cx('appointment-view-content', {
          'with-buttons': type !== MILESTONE_EVENT && !initialAppointment.canceled && abilityToCreateOrCancel
        })}
      >
        {initialAppointment.canceled && <CancelingDetails appointment={initialAppointment} />}
        <div className="pb-4">
          {type === MILESTONE_EVENT && <span>{moment(initialAppointment.start).format('DD/MMM/YY')}</span>}
          {type !== MILESTONE_EVENT && (
            <>
              <span>{`${start} - ${end}`}</span>
              <span className="lightgrey pl-2">{durationString}</span>
            </>
          )}
        </div>
        {type === PATIENT_ENCOUNTER_EVENT && (
          <PatientEncounterAppointmentViewContent appointment={initialAppointment} />
        )}
        {type === NON_PATIENT_EVENT && <NonPatientAppointmentViewContent appointment={initialAppointment} />}
        {type === PATIENT_UNEXPECTED_ENCOUNTER_EVENT && (
          <PatientAppointmentViewContent appointment={initialAppointment} />
        )}
        {type === PATIENT_SITUATIONAL_ENCOUNTER_EVENT && (
          <PatientSituationalEncounterViewContent appointment={initialAppointment} />
        )}
        {type === MILESTONE_EVENT && <MilestoneAppointmentViewContent appointment={initialAppointment} />}
      </div>
      {isCreateOrCancelButtonsVisible && <div className="action-buttons">{composeButtonBar()}</div>}
    </div>
  );
}

export function generateLinkToPatientInfoPage(patientId, ssuPatientId, routerRootKey) {
  if (!patientId || !ssuPatientId) {
    return '#';
  }
  const parentRoute = routerRootKey && routerRootKey !== 'Patient Profile' ? routerRootKey : 'Patients';
  return generateUrlByKey(`${parentRoute}.Patient Profile.Patient Studies`, {
    patientId,
    ssuPatientId
  });
}
