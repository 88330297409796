import axios from 'axios';

const ROOT = 'api/v1/events';

const EventApi = {
  createPatientSituationalEncounterEvent(event, ssuPatientId) {
    return axios.post(`${ROOT}/patient-situational-encounter-event/${ssuPatientId}`, event);
  },
  updatePatientSituationalEncounterEvent(event, ssuPatientId) {
    return axios.put(`${ROOT}/patient-situational-encounter-event/${ssuPatientId}/${event.id}`, event);
  },
  cancelPatientSituationalEncounterEvent(id, ssuPatientId, data) {
    return axios.put(`${ROOT}/patient-situational-encounter-event/${ssuPatientId}/${id}/cancel`, data);
  },
  createPatientEncounterEvent(event, ssuPatientId) {
    return axios.post(`${ROOT}/patient-encounter-event/${ssuPatientId}`, event);
  },
  updatePatientEncounterEvent(event, ssuPatientId) {
    return axios.put(`${ROOT}/patient-encounter-event/${ssuPatientId}/${event.id}`, event);
  },
  cancelPatientEncounterEvent(id, ssuPatientId, data) {
    return axios.put(`${ROOT}/patient-encounter-event/${ssuPatientId}/${id}/cancel`, data);
  },
  createPatientEvent(event, ssuPatientId) {
    return axios.post(`${ROOT}/patient-event/${ssuPatientId}`, event);
  },
  updatePatientEvent(event, ssuPatientId) {
    return axios.put(`${ROOT}/patient-event/${ssuPatientId}/${event.id}`, event);
  },
  cancelPatientEvent(id, ssuPatientId, data) {
    return axios.put(`${ROOT}/patient-event/${ssuPatientId}/${id}/cancel`, data);
  },
  createNonPatientEvent(event) {
    return axios.post(`${ROOT}/non-patient-event`, event);
  },
  updateNonPatientEvent(event) {
    return axios.put(`${ROOT}/non-patient-event/${event.id}`, event);
  },
  cancelNonPatientEvent(id, data) {
    return axios.put(`${ROOT}/non-patient-event/${id}/cancel`, data);
  },
  getNonPatientEventTypes(hideLoader) {
    return axios.get(`${ROOT}/non-patient/types`, { hideLoader: hideLoader || false });
  }
};

export default EventApi;
