import { getStudySiteValidationRulesByType } from './NonPatientAppointmentValidatioRulesProvider';

export default class NonPatientAppointmentValidator {
  validate(appointment) {
    return {
      subject: { validationMessage: appointment.subject ? undefined : 'Event Name is required' },
      studySite: {
        validationMessage: this.validateStudySite(appointment.nonPatientEventTypeCode, appointment.studySite)
      },
      timeDuration: {
        validationMessage: this.validateTimeDuration(appointment.timeDuration)
      },
      nonPatientEventTypeCode: {
        validationMessage: this.validateNonPatientEventTypeCode(appointment.nonPatientEventTypeCode)
      }
    };
  }

  validateStudySite(nonPatientEventTypeCode, studySite) {
    return {
      study: this.validateStudy(nonPatientEventTypeCode, studySite?.study),
      site: this.validateSite(nonPatientEventTypeCode, studySite?.site)
    };
  }

  validateStudy(nonPatientEventTypeCode, study) {
    const studySiteValidationRules = getStudySiteValidationRulesByType(nonPatientEventTypeCode);
    if (!studySiteValidationRules.isStudyRequired || study) {
      return null;
    }

    return 'Study is Required';
  }

  validateSite(nonPatientEventTypeCode, site) {
    const studySiteValidationRules = getStudySiteValidationRulesByType(nonPatientEventTypeCode);

    if (!studySiteValidationRules.isSiteRequired || site) {
      return null;
    }

    return 'Site is Required';
  }

  validateTimeDuration(timeDuration) {
    return timeDuration && timeDuration.start && timeDuration.duration ? undefined : 'Start and duration are required';
  }

  validateNonPatientEventTypeCode(eventType) {
    return eventType ? undefined : 'Meeting type is required';
  }

  isValid(appointment) {
    const validation = this.validate(appointment);

    return isNoValidationErrors(validation);

    function isNoValidationErrors(validation) {
      return !(
        validation?.subject?.validationMessage ||
        validation?.timeDuration?.validationMessage ||
        validation?.nonPatientEventTypeCode?.validationMessage ||
        validation?.studySite?.validationMessage?.study ||
        validation?.studySite?.validationMessage?.site
      );
    }
  }
}
