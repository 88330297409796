import React, { useEffect } from 'react';
import { find, reject } from 'lodash/collection';
import { isEmpty, isFunction } from 'lodash/lang';
import moment from 'moment';

import Common from '../../../../../common/common';
import DatePicker from '../../../../../common/data-entry/DatePicker';
import Select from '../../../../../common/data-entry/Select';
import { useCurrentUser } from '../../../../root/Container/CurrentUserContainer';

import './EditorAndDateSelect.scss';

export function EditorAndDateSelect({
  whenWasDoneDate,
  whenWasDoneDateOnChange,
  whoDidItList,
  whoDidItOnChange,
  whoDidItUniqueIdentifier,
  checkedInEncountersForPatient,
  encounterOnChange,
  selectedEncounterId,
  showEncounterSelect,
  isAllowedToManage,
  disableEncounterSelector,
  validationError
}) {
  const currentUser = useCurrentUser();
  const personnelIdentifier = currentUser?.personnelIdentifier;
  const whoDidIt = getWhoDidItByUniqueIdentifier(whoDidItList, whoDidItUniqueIdentifier);
  const encounter = getEncounterById(checkedInEncountersForPatient, selectedEncounterId);
  const preparedWhoDidItListForView = prepareWhoDidItListForView(whoDidItList, whoDidItUniqueIdentifier);
  const isDisabled = !(isFunction(isAllowedToManage) ? isAllowedToManage() : isAllowedToManage);
  const isEncounterSelectorDisabled = isFunction(disableEncounterSelector)
    ? disableEncounterSelector()
    : disableEncounterSelector;

  useEffect(
    function() {
      if (!isEmpty(preparedWhoDidItListForView) && !isEmpty(personnelIdentifier) && isEmpty(whoDidItUniqueIdentifier)) {
        whoDidItOnChange(getWhoDidItByUniqueIdentifier(preparedWhoDidItListForView, personnelIdentifier), true);
      }
    },
    [whoDidItOnChange, whoDidItUniqueIdentifier, preparedWhoDidItListForView, personnelIdentifier]
  );

  function valid(current) {
    const today = moment();
    return current.isBefore(today);
  }

  return (
    <div className="eds-editor-date-select">
      {showEncounterSelect && (
        <Select
          label={'Encounter'}
          dataSource={checkedInEncountersForPatient}
          onChange={encounterOnChange}
          searchable
          keepOpenOnSelection={false}
          closeOnSelectedOptionClick
          deselectOnSelectedOptionClick={false}
          validate={false}
          value={encounter}
          required={true}
          disabled={isEncounterSelectorDisabled}
        />
      )}
      <DatePicker
        label={'When was it done?'}
        required
        isValidDate={valid}
        value={Common.formatDate(whenWasDoneDate)}
        validate={false}
        disabled={isDisabled}
        onChange={whenWasDoneDateOnChange}
      />
      <Select
        label={'Who did it?'}
        dataSource={preparedWhoDidItListForView}
        onChange={whoDidItOnChange}
        searchable
        keepOpenOnSelection={false}
        closeOnSelectedOptionClick
        deselectOnSelectedOptionClick={false}
        clearable={false}
        disabled={isDisabled}
        validate={true}
        validationMessage={validationError && !whoDidIt ? 'Please make a selection' : ''}
        value={whoDidIt}
        className="who-did-it-picker"
        optionValueKey="uniqueIdentifier"
        required={true}
      />
    </div>
  );
}

function getWhoDidItByUniqueIdentifier(whoDidItList, whoDidItUniqueIdentifier) {
  return find(whoDidItList, r => r.uniqueIdentifier === whoDidItUniqueIdentifier);
}

function getEncounterById(checkedInEncountersForPatient, selectedEncounterId) {
  return find(checkedInEncountersForPatient, r => r.id === selectedEncounterId);
}

export function prepareWhoDidItListForView(whoDidItList, whoDidItUniqueIdentifier) {
  return reject(whoDidItList, p => p.deleteFlag === 1 && p.uniqueIdentifier !== whoDidItUniqueIdentifier);
}
