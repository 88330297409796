import React from 'react';

import { toFormattedFinanceBill } from '../../../../../../../../services/financial';
import { stylesContent } from '../../EncounterInvoiceTemplateStyles';

export const SubItem = ({ item }) => {
  return (
    <div style={stylesContent.invoiceSubItem} className="row-item" data-testid="ledger-event-item">
      {`${item.name}: Rate ${toFormattedFinanceBill(item.invoiceAmount)}`}
    </div>
  );
};
