import axios from 'axios';

const ROOT = 'api/v1/pending-schedule-worklist';

const SchedulingWorklistApi = {
  getProjectedEncounter(data) {
    return axios.post(`${ROOT}/projected`, data, { hideLoader: false });
  },
  filterWorkListFutureAppointmentEvents(data) {
    return axios.post(`${ROOT}/appointments`, data, { hideLoader: false });
  },
  getFilterEncountersAccessibleForUser(data) {
    return axios.post(`${ROOT}/encounters`, data, { hideLoader: false });
  },
  export(data) {
    return axios.post(`${ROOT}/download`, data, { responseType: 'arraybuffer' });
  },
  getSmsDataForWorklist(eventIds) {
    return axios.post(`api/v1/sms/schedule-worklist/`, eventIds, { hideLoader: true });
  }
};

export default SchedulingWorklistApi;
