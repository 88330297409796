import axios from 'axios';

const ROOT = 'api/v1/finance/transaction-ledger-events';

const FinTransactionLedgerEventApi = {
  getCountOfPendingLedgerEvents(filterProperty) {
    return axios.post(`${ROOT}/pending/count`, filterProperty);
  },
  exportElligoRevenue(filterProperty) {
    return axios.post(`${ROOT}/download`, filterProperty, {
      responseType: 'arraybuffer'
    });
  },
  changePatientStipendPaymentStatus(changeStatusRequest, eventId) {
    return axios.put(`${ROOT}/${eventId}/payment-status`, changeStatusRequest);
  },
  makePatientStipendEventVisibleForPayment(finLedgerEventId) {
    return axios.put(`${ROOT}/${finLedgerEventId}/repayments`);
  }
};

export default FinTransactionLedgerEventApi;
