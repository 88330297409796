import React from 'react';
import uuid from 'uuid/v4';

import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import NotificationManager from '../../../../../../../../common/notifications/NotificationManager';
import { FINAL_OPTION_LIST_REQUIRED } from '../../../../../../../../constants/notificationMessages';
import { useCurrentRoute } from '../../../../../../../root/router';
import EditFormAnswerFields from '../../../EditFormAnswerFields';

import './EditFormAnswerView.scss';

export function EditFormAnswerView({
  isExtensible,
  updateInputsAndPreviewData,
  item,
  labelIndex,
  index,
  inputs,
  editMode,
  terminologyVersionGroupNumber,
  custom
}) {
  const {
    params: { protocolIdentity },
    name
  } = useCurrentRoute();
  switch (isExtensible) {
    case 1:
      return (
        <i
          className="glyphicon glyphicon-edit pr-1 pb-2 editicn editAnsSetup"
          title="Edit Controlled Term List"
          onClick={() => onEditFormAnswerFields(item, labelIndex, index, custom ? 'custom' : 'optional')}
        />
      );
    case 2:
      return (
        <i
          className="glyphicon glyphicon-cog pr-1 editicn editAns"
          title="Custom Options List"
          onClick={() => onEditFormAnswerFields(item, labelIndex, index, custom ? 'custom' : 'optional')}
        />
      );
    case 3:
      return (
        <i
          className="glyphicon glyphicon-check pr-1 editicn editAns"
          title="Subset Options List"
          onClick={() => onEditFormAnswerFields(item, labelIndex, index, 'optional')}
        />
      );
    default:
      return null;
  }
  function onEditFormAnswerFields(item, labelIndex, questionIndex, type) {
    const modalBox = ModalBoxes.open({
      component: (
        <EditFormAnswerFields
          pageName={name}
          terminologyVersionGroupNumber={terminologyVersionGroupNumber}
          onSave={data => {
            try {
              let foundCustom = [];
              let editedFoundStandard = [];
              if (data) {
                const filterFromOrignalData = [];
                if (data && data.finalOptions && data.finalOptions.length > 0) {
                  data.finalOptions.forEach(element => {
                    const tempCodeDefObj = {
                      codeId: 0,
                      codeOid: '',
                      codedValue: null,
                      decode: element.value,
                      id: uuid(),
                      inputType: ['dropdown', 'dropdownCT'].includes(item.inputType) ? 'dropdown' : 'multiselect',
                      language: null,
                      name: element.value,
                      sequence: element.sequence
                    };
                    if (element.hasOwnProperty('type') && element.type === 'custom') {
                      tempCodeDefObj.type = element.type;
                    }
                    filterFromOrignalData.push(tempCodeDefObj);
                  });
                  inputs[0][type][labelIndex][type === 'custom' ? 'customItemDefinationList' : 'itemDefinitionList'][
                    questionIndex
                  ].codeDefinationList = filterFromOrignalData.slice();
                  inputs[0][type][labelIndex][type === 'custom' ? 'customItemDefinationList' : 'itemDefinitionList'][
                    questionIndex
                  ].ctListName = data.selectedControlTermListItem;
                  if (type !== 'custom') {
                    foundCustom = data.finalOptions.filter(k => {
                      return k.type === 'custom';
                    });
                    editedFoundStandard = data.finalOptions.filter(k => {
                      return !k.hasOwnProperty('type');
                    });
                    inputs[0][type][labelIndex].itemDefinitionList[questionIndex].customOptionList = foundCustom;
                    inputs[0][type][labelIndex].itemDefinitionList[
                      questionIndex
                    ].editedFoundStandard = editedFoundStandard;
                  }
                } else {
                  return NotificationManager.error(FINAL_OPTION_LIST_REQUIRED);
                }
                updateInputsAndPreviewData(inputs, () => {
                  ModalBoxes.close(modalBox.id);
                });
              }
            } catch (e) {
              console.log(e);
            }
          }}
          hideModal={() => {
            ModalBoxes.close(modalBox.id);
          }}
          itemDefinationData={item}
          fromEdit={editMode}
          protocolId={protocolIdentity}
        />
      )
    });
  }
}
