import React, { useMemo } from 'react';
import { includes } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

import './MultiSelectList.scss';

export const ControlledMultiselectList = ({
  height,
  options,
  label,
  selectedOptions,
  onChangeSidebarOption,
  toggleAllOptions
}) => {
  const allOptionsSelected = useMemo(
    () => options.every(option => includes(selectedOptions, option)) && !isEmpty(options),
    [options, selectedOptions]
  );

  return (
    <div
      className={`eds-multi-select-list py-3 ${height ? 'eds-multi-select-list-fixed-height' : ''}`}
      style={{ height: height }}
    >
      <div className="eds-multi-select-list-header">
        <div
          className={`eds-multi-select-list-option cursor-pointer ${allOptionsSelected ? 'selected' : 'text-muted'}`}
          onClick={() => toggleAllOptions(allOptionsSelected)}
        >
          <i className="material-icons">done_all</i>
          Select All
        </div>
      </div>
      <div className="eds-multi-select-list-options">
        {options.map((option, index) => {
          return (
            <div
              className={`eds-multi-select-list-option cursor-pointer ${
                includes(selectedOptions, option) ? 'selected' : 'text-muted'
              }`}
              key={index}
              onClick={() => onChangeSidebarOption(option)}
            >
              <i className="material-icons">done</i>
              {option[label]}
            </div>
          );
        })}
      </div>
    </div>
  );
};
