import React from 'react';

import { StepHeader } from '../StepHeader/StepHeader';

import { MissedEventsCalendars } from './MissedEventsCalendars/MissedEventsCalendars';
import { MissedEventsTable } from './MissedEventsTable/MissedEventsTable';

import './MissedEvents.scss';
export function MissedEvents() {
  return (
    <div className="missed-events">
      <StepHeader>Select Missing Revenue Events</StepHeader>
      <MissedEventsCalendars />
      <MissedEventsTable />
    </div>
  );
}
