import React, { useContext } from 'react';
import { first } from 'lodash/array';
import * as PropTypes from 'prop-types';

import { PatientEncounterReviewApi } from '../../../../../../../api';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import {
  ROLE_CLINICAL_QUALITY_COORDINATOR,
  ROLE_FINANCE_ANALYST,
  ROLE_OPERATION_MANAGER,
  ROLE_OPERATIONS_ANALYST,
  ROLE_STUDY_LEAD
} from '../../../../../../../constants/userRoles';
import { userHasRole } from '../../../../../../../services/auth';
import { ReviewContext } from '../../ReviewContext';
import { isSmOrPiReview } from '../../reviewService';
import ElementHistoryLogCheckModal from '../HistoryLogCheckModal/ElementHistoryLogCheckModal';
import ItemGroupNotPerformed from '../ItemGroupElement/ItemGroupNotPerformed/ItemGroupNotPerformed';
import ItemGroupElementHeader from '../ItemGroupElementHeader/ItemGroupElementHeader';
import WhenWasItDoneAndWhoDidIt from '../WhenWasItDoneAndWhoDidIt/WhenWasItDoneAndWhoDidIt';

import LogItemGroupContent from './LogItemGroupContent/LogItemGroupContent';

export default function LogItemGroupElement({
  logAndLogCheckSnapshotReviewState,
  isLogPresent,
  isLogCheckPresent,
  logCheckItem
}) {
  const {
    reviewType,
    isHistoricalData,
    requiredAttentionItemGroupsConfirmStatuses,
    setRequiredAttentionItemGroupsConfirmStatuses
  } = useContext(ReviewContext);
  const itemGroupTemplateId = logAndLogCheckSnapshotReviewState?.logFormRef.itemGroupTemplateId;

  const logName = logAndLogCheckSnapshotReviewState?.logFormRef?.name;
  const isLogItemGroupRequiredAttention = requiredAttentionItemGroupsConfirmStatuses.hasOwnProperty(
    itemGroupTemplateId
  );
  const showLogCheckHistoryButton =
    logAndLogCheckSnapshotReviewState?.encounterLogCheckSnapshotKey?.recordVersion >= 1 && !isHistoricalData;

  const userWithReadOnlyAccess = [
    ROLE_STUDY_LEAD,
    ROLE_OPERATION_MANAGER,
    ROLE_FINANCE_ANALYST,
    ROLE_OPERATIONS_ANALYST,
    ROLE_CLINICAL_QUALITY_COORDINATOR
  ];

  const showItemGroupConfirmButton =
    isSmOrPiReview(reviewType) &&
    isLogItemGroupRequiredAttention &&
    !isHistoricalData &&
    !userHasRole(userWithReadOnlyAccess);
  const isItemGroupConfirmed = requiredAttentionItemGroupsConfirmStatuses[itemGroupTemplateId];

  return (
    <>
      {logAndLogCheckSnapshotReviewState && (
        <ItemGroupElementHeader
          itemGroupId={logAndLogCheckSnapshotReviewState?.logFormRef?.itemGroupId}
          itemGroupName={`${logName} - Log Check`}
          itemGroupType="Logs"
          isItemGroupConfirmed={isItemGroupConfirmed}
          disableItemGroupConfirmButton={false}
          showItemGroupConfirmButton={showItemGroupConfirmButton}
          onConfirmItemGroup={onConfirmLogItemGroup}
          showHistoryButton={showLogCheckHistoryButton}
          getAnswersHistory={getLogCheckAnswersHistory}
          statusChip={getStatusChip()}
          itemGroupTemplateId={itemGroupTemplateId}
        />
      )}
      {logAndLogCheckSnapshotReviewState?.logCheckSnapshot?.notPerformed && (
        <ItemGroupNotPerformed
          notPerformedReason={logAndLogCheckSnapshotReviewState.logCheckSnapshot.reasonCommentForNotPerformed}
          completionDate={logAndLogCheckSnapshotReviewState.logCheckSnapshot.lastUpdateDate}
          lastUpdatedByName={logAndLogCheckSnapshotReviewState.logCheckSnapshot.lastUpdatedByName}
        />
      )}
      {isLogCheckPresent && (
        <WhenWasItDoneAndWhoDidIt
          whenWasItDone={logAndLogCheckSnapshotReviewState?.logCheckSnapshot.whenWasItDone}
          whoDidItName={logAndLogCheckSnapshotReviewState?.logCheckSnapshot.whoDidItName}
        />
      )}
      {isLogPresent && <LogItemGroupContent logAndLogCheckSnapshotReviewState={logAndLogCheckSnapshotReviewState} />}
    </>
  );

  function onConfirmLogItemGroup() {
    setRequiredAttentionItemGroupsConfirmStatuses(prevState => ({
      ...prevState,
      [itemGroupTemplateId]: !isItemGroupConfirmed
    }));
  }

  function getLogCheckAnswersHistory() {
    const onLoadData = () =>
      PatientEncounterReviewApi.getLogCheckAnswerHistory(
        logAndLogCheckSnapshotReviewState?.encounterLogCheckSnapshotKey?.encounterLogCheckKey?.ssuPatientId,
        logAndLogCheckSnapshotReviewState?.encounterLogCheckSnapshotKey?.encounterLogCheckKey?.sitePatientEncounterId,
        logCheckItem?.patientItemGroupId
      );

    ModalBoxes.open({
      component: <ElementHistoryLogCheckModal onLoadData={onLoadData} />,
      title: logCheckItem?.patientItemGroupName
    });
  }

  function getStatusChip() {
    if (logAndLogCheckSnapshotReviewState?.logCheckSnapshot?.notPerformed) {
      return 'Not Performed';
    }
    const latestLogCheckAnswer = first(logAndLogCheckSnapshotReviewState?.logCheckSnapshot?.logCheckAnswers);

    if (latestLogCheckAnswer?.logCheckValue) {
      return latestLogCheckAnswer.logCheckValue;
    }
    return null;
  }
}

LogItemGroupElement.propTypes = {
  logAndLogCheckSnapshotReviewState: PropTypes.object,
  isLogPresent: PropTypes.bool,
  isLogCheckPresent: PropTypes.bool,
  logCheckItem: PropTypes.object
};
