import { useMemo, useRef } from 'react';
import cx from 'classnames';

import './ReviewContentElement.scss';

export default function ReviewContentElement({ className, rcHeight, lastElementId, scrollId, children }) {
  const elementRef = useRef(null);
  const $element = elementRef?.current;

  const minHeight = useMemo(
    function() {
      if (!$element || !rcHeight || lastElementId !== scrollId) return null;
      const computedStyle = window.getComputedStyle($element);
      return rcHeight - (parseInt(computedStyle?.marginTop, 10) + parseInt(computedStyle?.marginBottom, 10) || 0);
    },
    [$element, rcHeight, lastElementId, scrollId]
  );

  return (
    <div
      className={cx('erc-element', className)}
      id={scrollId}
      ref={elementRef}
      style={{ minHeight: minHeight ? `${minHeight}px` : null }}
    >
      {children}
    </div>
  );
}
