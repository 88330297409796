import React, { useMemo } from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash/lang';
import PropTypes from 'prop-types';

import Icon from '../../../../../../../../common/general/Icon';
import {
  ALLOW_EDIT,
  ALLOW_READ,
  ALLOW_SIGN,
  DENY_ALL,
  getPermissionOptionsByGroupType,
  permissionStatusMap
} from '../../permissionType';
import { useProtocolGroupsSetupActions, useProtocolGroupsSetupState } from '../../ProtocolGroupsSetupContext';

import './GroupsSetupTableCell.scss';

function GroupsSetupTableCell({ cellKey, selectedGroupType }) {
  const { groupsConfigurationMap } = useProtocolGroupsSetupState(); /*TODO: need to check performance*/
  const {
    changeAccessForEncountersItemGroupsConfiguration
  } = useProtocolGroupsSetupActions(); /*TODO: need to check performance*/

  const [currentCellData, validCellKey] = useMemo(
    function() {
      if (!groupsConfigurationMap) return [];

      const data = groupsConfigurationMap[cellKey];

      if (data) {
        return [data, cellKey];
      }

      const validCellKey = cellKey.replace(/_.*/, '_EMPTY_KEY');

      const newData = groupsConfigurationMap[validCellKey];

      if (newData?.logItemGroupType) {
        return [newData, validCellKey];
      }
      return [];
    },
    [groupsConfigurationMap, cellKey]
  );

  if (!currentCellData) return null;
  const { permissionType } = currentCellData;
  const cellAccessible = isCellAccessible(
    currentCellData?.linkedToEncounterKeys,
    currentCellData?.selectedInUnexpectedConfig,
    currentCellData?.removedFromEncounterSetup,
    currentCellData?.logItemGroupType,
    cellKey.replace(/.*?_/, '')
  );

  if (!cellAccessible) return null;

  return (
    <div
      className={cx('groups-setup-table-cell', {
        'groups-setup-table-cell-read': permissionType === ALLOW_READ,
        'groups-setup-table-cell-edit': permissionType === ALLOW_EDIT,
        'groups-setup-table-cell-sign': permissionType === ALLOW_SIGN,
        'groups-setup-table-cell-deny': permissionType === DENY_ALL
      })}
    >
      <div className="groups-setup-table-cell-status">{permissionStatusMap[permissionType]}</div>
      <div className="groups-setup-table-cell-actions">
        {getPermissionOptionsByGroupType(selectedGroupType).map(function({ key, icon }) {
          return (
            <Icon
              key={key}
              className="groups-setup-table-cell-action"
              onClick={function() {
                return changeAccessForEncountersItemGroupsConfiguration(validCellKey, key);
              }}
            >
              {icon}
            </Icon>
          );
        })}
      </div>
    </div>
  );
}

export function isCellAccessible(
  linkedToEncounterKeys,
  selectedInUnexpectedConfig,
  removedFromEncounterSetup,
  isLogType,
  encounterId
) {
  if (encounterId !== 'EMPTY_KEY' && !isEmpty(linkedToEncounterKeys) && !linkedToEncounterKeys?.includes(encounterId)) {
    return false;
  }

  if (encounterId === 'EMPTY_KEY' && isLogType && !selectedInUnexpectedConfig && !removedFromEncounterSetup) {
    return false;
  }

  if (
    encounterId !== 'EMPTY_KEY' &&
    isLogType &&
    selectedInUnexpectedConfig &&
    isEmpty(linkedToEncounterKeys) &&
    !removedFromEncounterSetup
  ) {
    return false;
  }

  return true;
}

GroupsSetupTableCell.propTypes = {
  cellKey: PropTypes.string,
  selectedGroupType: PropTypes.string
};

export default GroupsSetupTableCell;
