import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash/lang';

import { OptionalItemGroupApi } from '../../../../../api';
import OptionalReviewApi from '../../../../../api/finance/OptionalReviewApi';
import withSkeleton from '../../../../../common/hocs/withSkeleton';
import { onRequestError } from '../../../../../services/handlers';
import ItemGroupsEncountersTable from '../../../../ItemGroupsEncountersTable/ItemGroupsEncountersTable';
import ItemGroupsEncounterTableSkeleton from '../../../../ItemGroupsEncountersTable/ItemGroupsEncounterTableSkeleton/ItemGroupsEncounterTableSkeleton';
import { PageInfoHeader } from '../../../../PageInfoHeader/PageInfoHeader';

import OptionalItemGroupsSetupTableCell from './OptionalItemGroupsSetupTableCell/OptionalItemGroupsSetupTableCell';
import withOptionalItemGroupsSetup from './withOptionalItemGroupsSetup';

import './OptionalItemGroupSetup.scss';

const OptionalSetupTable = withSkeleton(
  ItemGroupsEncounterTableSkeleton,
  withOptionalItemGroupsSetup(ItemGroupsEncountersTable)
);
const OptionalSetupTableReview = withSkeleton(
  ItemGroupsEncounterTableSkeleton,
  withOptionalItemGroupsSetup(ItemGroupsEncountersTable)
);

function OptionalItemGroupSetup() {
  const { id: budgetId } = useParams();

  const [state, setState] = useState({});
  const [configurationMap, setConfigurationMap] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { budgetName, studyName, client, version, editPossibility, epochs, itemGroups, reviewGroups } = state;

  useEffect(() => {
    setIsLoading(true);
    OptionalItemGroupApi.getOptionalBudgetDetailsExclude(budgetId).then(function({ data }) {
      if (data) {
        setConfigurationMap(generateMapConfiguration(data.optionalMap));
        setState(data);
      }
      setIsLoading(false);
    });
  }, [budgetId]);

  const _setConfigurationMap = useCallback(function(cellKey, modifier) {
    setConfigurationMap(state => ({ ...state, [cellKey]: modifier(state[cellKey]) }));
  }, []);

  const _toggleExclude = useCallback(
    function(key, Api, makeRequired, makeOptional) {
      _setConfigurationMap(key, function(currentCell) {
        const { encounterKey, rowKey, exclude } = currentCell;

        Api[exclude ? makeRequired : makeOptional](budgetId, encounterKey, rowKey).then(function({ data }) {
          _setConfigurationMap(key, currentCell => ({ ...currentCell, loading: false, exclude: !!data?.id }));
        }, onRequestError);

        return { ...currentCell, loading: true };
      });
    },
    [_setConfigurationMap, budgetId]
  );

  const toggleExclude = useCallback(
    function(key) {
      _toggleExclude(key, OptionalItemGroupApi, 'makeItemGroupRequired', 'makeItemGroupOptional');
    },
    [_toggleExclude]
  );

  const toggleExcludeReview = useCallback(
    function(key) {
      _toggleExclude(key, OptionalReviewApi, 'makeItemGroupReviewRequired', 'makeItemGroupReviewOptional');
    },
    [_toggleExclude]
  );

  return (
    <>
      <PageInfoHeader
        objectRecordLabel={budgetName}
        pageInfo={
          <PageInfoHeader.CollapsibleList>
            <PageInfoHeader.AdditionalInfo tooltip="Study Name">{studyName}</PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo tooltip="Client">{client}</PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo tooltip="Version">{version}</PageInfoHeader.AdditionalInfo>
          </PageInfoHeader.CollapsibleList>
        }
      />
      <div className="optional-item-groups-container">
        <div className="optional-item-groups-container-description">
          Mark item groups which are excluded from the criteria to trigger a completed financial encounter.
        </div>
        <OptionalSetupTable
          className="optional-setup-table-legacy"
          skeletonLoading={isLoading}
          epochs={epochs}
          itemGroups={itemGroups}
          Cell={function({ cellKey }) {
            return (
              <OptionalItemGroupsSetupTableCell
                editDeny={!editPossibility}
                cellKey={cellKey}
                configurationMap={configurationMap}
                toggleExclude={toggleExclude}
              />
            );
          }}
          RowTitleCell={function({ itemGroup }) {
            const { elementName } = itemGroup;
            return (
              <>
                <div />
                <div title={elementName} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {elementName}
                </div>
              </>
            );
          }}
          EncounterCell={function({ encounter }) {
            const { encounterName } = encounter;
            return <div>{encounterName}</div>;
          }}
        />
        {(isLoading || !isEmpty(reviewGroups)) && (
          <OptionalSetupTableReview
            className="optional-setup-table-legacy"
            skeletonLoading={isLoading}
            epochs={epochs}
            itemGroups={reviewGroups}
            rowKey="groupKey"
            HeaderCell={function() {
              return (
                <>
                  <div />
                  <div>Review</div>
                </>
              );
            }}
            Cell={function({ cellKey }) {
              return (
                <OptionalItemGroupsSetupTableCell
                  editDeny={!editPossibility}
                  cellKey={cellKey}
                  configurationMap={configurationMap}
                  toggleExclude={toggleExcludeReview}
                />
              );
            }}
            RowTitleCell={function({ itemGroup }) {
              const { groupName } = itemGroup;
              return (
                <>
                  <div />
                  <div title={groupName} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {groupName}
                  </div>
                </>
              );
            }}
            EncounterCell={function({ encounter }) {
              const { encounterName } = encounter;
              return <div>{encounterName}</div>;
            }}
          />
        )}
      </div>
    </>
  );
}

function generateMapConfiguration(optionalMap) {
  return optionalMap.reduce(function(accumulator, v) {
    const { rowKey, encounterKey } = v;
    return { ...accumulator, [`${rowKey}_${encounterKey}`]: v };
  }, {});
}

export default OptionalItemGroupSetup;
