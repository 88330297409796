import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#691E44'
    }
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          ':focus': {
            outline: 'none'
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '18px'
        }
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        }
      }
    }
  }
});

export default theme;
