import axios from 'axios';

const ROOT = 'api/v1/informed-consent';

const InformedConsentApi = {
  getItemsByStudyId(studyId, props = {}) {
    return axios.get(`${ROOT}/${studyId}/items`, { hideLoader: true, ...props });
  },
  addType(studyId, type) {
    return axios.post(`${ROOT}/${studyId}/type`, { type }, { hideLoader: false });
  },
  editType(studyId, typeId, type) {
    return axios.put(`${ROOT}/${studyId}/type/${typeId}`, { type }, { hideLoader: false });
  },
  addLanguage(studyId, language) {
    return axios.post(`${ROOT}/${studyId}/language`, { language }, { hideLoader: false });
  },
  editLanguage(studyId, languageId, language) {
    return axios.put(`${ROOT}/${studyId}/language/${languageId}`, { language }, { hideLoader: false });
  },
  uploadForm(ssuId, form) {
    return axios.post(`${ROOT}/${ssuId}/forms`, form, { hideLoader: false });
  },
  getFormsBySsuId(ssuId) {
    return axios.get(`${ROOT}/${ssuId}/forms`, { hideLoader: true });
  },
  getNewAndCompletedForms(ssuId, ssuPatientId) {
    return axios.get(`${ROOT}/${ssuId}/${ssuPatientId}/forms/by-status`, { hideLoader: true });
  },
  getCompletedForms(ssuPatientId) {
    return axios.get(`${ROOT}/${ssuPatientId}/completed-forms`, { hideLoader: true });
  },
  downloadForm(ssuId, formId) {
    return axios.get(`${ROOT}/${ssuId}/forms/${formId}/download`, {
      hideLoader: true,
      responseType: 'blob'
    });
  },
  downloadSignedForm(ssuId, icfAnswerId) {
    return axios.get(`${ROOT}/study-sites/${ssuId}/forms/answers/${icfAnswerId}/download`, {
      hideLoader: true,
      responseType: 'blob'
    });
  },
  getCompletedIcfAnswers(sitePatientId, typeId, languageId) {
    return axios.get(`${ROOT}/ssu-patients/${sitePatientId}/type/${typeId}/language/${languageId}`, {
      hideLoader: true
    });
  }
};

export default InformedConsentApi;
