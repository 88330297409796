import axios from 'axios';

const ROOT = 'api/v1/ssu-patients';

const SitePatientEncounterApi = {
  getSitePatientEncounterDetails(ssuPatientId, ssuPatientEncounterId) {
    return axios.get(`${ROOT}/${ssuPatientId}/encounters/${ssuPatientEncounterId}/details`);
  },
  checkIn(ssuPatientId, patientEncounterEventId) {
    return axios.post(
      `${ROOT}/${ssuPatientId}/encounters/regular/appointmentId/${patientEncounterEventId}/checkin`,
      {},
      { hideLoader: false }
    );
  },
  checkInSituational(ssuPatientId, patientEncounterEventId) {
    return axios.post(
      `${ROOT}/${ssuPatientId}/encounters/situational/appointmentId/${patientEncounterEventId}/checkin`,
      {},
      { hideLoader: false }
    );
  },
  cancelSitePatientEncounter(ssuPatientId, ssuPatientEncounterId, payload) {
    return axios.post(`${ROOT}/${ssuPatientId}/encounters/${ssuPatientEncounterId}/cancel`, payload);
  },

  checkinAndCancelSitePatientEncounter(ssuPatientId, patientEncounterEventId, request) {
    return axios.post(
      `${ROOT}/${ssuPatientId}/encounters/regular/appointmentId/${patientEncounterEventId}/checkin-and-cancel`,
      request
    );
  },

  reopenSitePatientEncounter(ssuPatientId, ssuPatientEncounterId) {
    return axios.post(`${ROOT}/${ssuPatientId}/encounters/${ssuPatientEncounterId}/reopen`);
  },

  getSitePatientEncounterStatus(ssuPatientId, ssuPatientEncounterId) {
    return axios.get(`${ROOT}/${ssuPatientId}/encounters/${ssuPatientEncounterId}/status`);
  },

  getSsuPatientEncounterMetaInfo(ssuPatientId, ssuPatientEncounterId) {
    if (ssuPatientEncounterId) {
      return axios.get(`${ROOT}/${ssuPatientId}/encounters/${ssuPatientEncounterId}/meta-info`);
    } else {
      //added to leave current behavior as it is.
      return Promise.resolve({});
    }
  },

  updateEdcVisitDate(ssuPatientId, ssuPatientEncounterId, saveData) {
    return axios.put(`${ROOT}/${ssuPatientId}/encounters/${ssuPatientEncounterId}/edc-visit-date`, saveData);
  },

  savePatientEncounterStudyManager(ssuPatientId, ssuPatientEncounterId, studyManagerId) {
    return axios.put(`${ROOT}/${ssuPatientId}/encounters/${ssuPatientEncounterId}/study-manager`, {
      studyManagerId
    });
  },

  createNoAppointmentsEncounter(ssuPatientId, protocolEncounterKey) {
    return axios.post(
      `${ROOT}/${ssuPatientId}/encounters/regular/no-appointments/${protocolEncounterKey}`,
      {},
      { hideLoader: false }
    );
  },

  situationalCheckIn(ssuPatientId, patientSituationalEncounterEventId) {
    return axios.post(
      `${ROOT}/${ssuPatientId}/encounters/situational/no-appointments/${patientSituationalEncounterEventId}`,
      {},
      { hideLoader: false }
    );
  }
};

export default SitePatientEncounterApi;
