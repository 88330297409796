import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { debounce } from 'lodash/function';
import { isEmpty } from 'lodash/lang';

import Loader from '../../../../../../../common/elements/Loader/Loader';

import FindPatientPopupItem from './FindPatientPopupItem/FindPatientPopupItem';
import { fetchPatients, FPAT_PP, FPAT_PS } from './findPatientServices';
import usePatients from './usePatients';

import './FindPatientPopup.scss';

const FindPatientPopup = forwardRef(({ show }, ref) => {
  const searchInputRef = useRef(null);
  const [searchString, setSearchString] = useState('');
  const trimmedSearchString = searchString.trim();

  const { activeTab, isLoading, patients, isRecentlyViewed, updateState, setMorePatients } = usePatients();

  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const onChange = useCallback(function({ target }) {
    if (target.value?.length > 30) return;
    setSearchString(target.value);
  }, []);

  const onSearch = useMemo(function() {
    return debounce(cb => cb(), 1000);
  }, []);

  const onScroll = useMemo(
    function() {
      return debounce(function(event) {
        if (totalPages - (page + 1) < 1) return;
        const scrollOffset = event.target.scrollHeight - event.target.scrollTop - event.target.offsetHeight;
        if (scrollOffset < 30) {
          fetchPatients(activeTab, trimmedSearchString, page + 1).then(function(data) {
            setMorePatients(data.patients);
            setPage(data.page);
            setTotalPages(data.totalPages);
          });
        }
      }, 500);
    },
    [totalPages, page, activeTab, trimmedSearchString, setMorePatients]
  );

  useEffect(
    function() {
      let canceledCall = false;
      if (trimmedSearchString.length !== 0 && trimmedSearchString.length < 3) return;
      updateState({ isLoading: true });
      onSearch(function() {
        fetchPatients(activeTab, trimmedSearchString).then(function(data) {
          if (canceledCall) return;
          updateState({ patients: data?.patients, isRecentlyViewed: data?.isRecent, isLoading: false });
          setPage(data?.page);
          setTotalPages(data?.totalPages);
        });
      });
      return function() {
        canceledCall = true;
      };
    },
    [trimmedSearchString, activeTab, onSearch, updateState]
  );

  useEffect(
    function() {
      if (show && searchInputRef?.current) {
        searchInputRef.current.focus();
      }
    },
    [show, activeTab, searchInputRef]
  );

  if (!show) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={cx('eds-find-patient-popup', {
        'eds-find-patient-popup-hidden': false
      })}
    >
      <label className="eds-find-patient-popup-input">
        <input ref={searchInputRef} type="text" onChange={onChange} value={searchString} placeholder="Find Patient" />
        <span>Find Patient</span>
        {searchString.length > 0 && (
          <i
            className="eds-find-patient-popup-input-clear-btn"
            aria-label="Clear Search Field"
            onClick={() => setSearchString('')}
          />
        )}
      </label>
      <div className="eds-find-patient-popup-tabs">
        <div
          className={cx('eds-find-patient-popup-tab', {
            'eds-find-patient-popup-tab-active': activeTab === FPAT_PS
          })}
          onClick={() => setActiveTab(FPAT_PS)}
        >
          Patient Studies
        </div>
        <div
          className={cx('eds-find-patient-popup-tab', {
            'eds-find-patient-popup-tab-active': activeTab === FPAT_PP
          })}
          onClick={() => setActiveTab(FPAT_PP)}
        >
          Patient Profiles
        </div>
      </div>
      {isRecentlyViewed ? <div className="eds-find-patient-popup-recent-notice">RECENTLY VIEWED PATIENTS</div> : ''}
      <div className="eds-find-patient-popup-list" onScroll={onScroll}>
        {isLoading ? (
          <Loader dark />
        ) : isEmpty(patients) ? (
          <div className="eds-find-patient-popup-list-no-result">No Result Available</div>
        ) : (
          patients.map(function(patient) {
            return (
              <FindPatientPopupItem
                key={`${patient?.patientUniqueIdentifier}${patient?.sitePatientIds}`}
                patient={patient}
                searchString={trimmedSearchString}
                isPatientProfile={activeTab === FPAT_PP}
                onLinkClick={() => setSearchString('')}
              />
            );
          })
        )}
      </div>
    </div>
  );

  function setActiveTab(activeTab) {
    updateState({ activeTab });
  }
});
export default FindPatientPopup;
