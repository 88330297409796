import React, { useImperativeHandle, useRef } from 'react';
import cx from 'classnames';
import { isFunction } from 'lodash/lang';
import { omit } from 'lodash/object';
import * as PropTypes from 'prop-types';

import { pendoTrack } from '../../../../pendo/PendoUtils';

import CheckboxGroup from './_CheckboxGroup';

import './Checkbox.scss';

const Checkbox = React.forwardRef(function(props, ref) {
  const restProps = omit(
    props,
    'value',
    'label',
    'disabled',
    'onChange',
    'className',
    'indeterminate',
    'checked',
    'className',
    'title',
    'color'
  );
  const { checked, title, value, label, disabled, onChange: onChangeCallback, className, indeterminate, color } = props;
  const inputRef = useRef();
  useImperativeHandle(ref, () => inputRef.current);
  return (
    <label
      className={cx(
        'eui-input-selectors eui-checkbox',
        { 'eui-input-selectors-without-label': !label, 'eui-input-selector-disabled': disabled },
        className
      )}
      style={{ '--eui-input-color': color }}
      title={title}
      key={value}
    >
      <input
        ref={inputRef}
        {...restProps}
        checked={!indeterminate && checked}
        indeterminate={indeterminate ? indeterminate.toString() : undefined}
        type="checkbox"
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      <span>
        <em />
        {label}
      </span>
    </label>
  );
  function onChange(e) {
    e.persist();
    pendoTrack('Click on checkbox', {
      pathName: window.location.pathname,
      value: e.target.value
    });
    if (isFunction(onChangeCallback)) {
      onChangeCallback(e);
    }
  }
});

Checkbox.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string
};

Checkbox.isCheckbox = true;
Checkbox.Group = CheckboxGroup;
export default Checkbox;
