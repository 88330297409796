import React from 'react';

import Input from '../../../../../../../../common/data-entry/Input';
import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';

import TableItemGroupPreview from './TableItemGroupPreview';

import './TablePreviewModal.scss';

const TablePreviewModal = ({
  modalBox,
  encounterTableItemGroupForPreview,
  conditionalLogicAndRequiredFieldsValidation
}) => {
  return (
    <>
      <ModalBoxes.Header>Item Group Preview</ModalBoxes.Header>
      <ModalBoxes.Body>
        <div className="item-group-info">
          <Input
            label="Name"
            name="name"
            value={encounterTableItemGroupForPreview?.itemGroupName}
            disabled
            className="info-item"
          />
          <Input
            label="Domain"
            name="domain"
            value={encounterTableItemGroupForPreview?.domain}
            disabled
            className="info-item"
          />
          <Input
            label="Type"
            name="type"
            value={encounterTableItemGroupForPreview?.type}
            disabled
            className="info-item"
          />
        </div>
        <TableItemGroupPreview
          encounterTableItemGroupForPreview={encounterTableItemGroupForPreview}
          conditionalLogicAndRequiredFieldsValidation={conditionalLogicAndRequiredFieldsValidation}
        />
      </ModalBoxes.Body>
      <ModalBoxes.Footer />
    </>
  );
};

TablePreviewModal.className = 'table-preview-modal';

export default TablePreviewModal;
