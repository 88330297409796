import React, { Fragment, useImperativeHandle, useRef } from 'react';
import cx from 'classnames';
import { omit } from 'lodash/object';

import Icon from '../../general/Icon';

import Input from './Input';
import inputPropTypes from './types';

import './_File.scss';

const defaultAcceptedUploadTypes = ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.csv', '.png', '.jpg', '.jpeg'];
const _File = React.forwardRef(function(props, ref) {
  const { fileName, className, acceptedUploadTypes, onChangeFileHandler, iconsAfter, name } = props;

  const inputRef = useRef();
  useImperativeHandle(ref, () => inputRef.current);

  const restProps = omit(props, 'type', 'iconsAfter', 'onChangeFileHandler', 'fileName', 'acceptedUploadTypes');

  return (
    <div className="eui-file-template">
      <Input
        ref={inputRef}
        label="File"
        className={className}
        type="file"
        name={name}
        accept={acceptedUploadTypes || defaultAcceptedUploadTypes.join()}
        onChange={event => onChangeFileHandler && onChangeFileHandler(event)}
        validate={false}
        iconsAfter={
          <Fragment>
            {iconsAfter}
            <Icon
              type="input-button"
              onClick={e => {
                e.preventDefault();
                setReactInputValue(inputRef.current, '');
              }}
              className={cx({ 'clear-button-hidden': !fileName })}
            >
              cancel
            </Icon>
            <Icon>upload</Icon>
          </Fragment>
        }
        {...restProps}
      />
      <div className="file-layout">
        <span className="file-name">{fileName}</span>
      </div>
    </div>
  );
});

function setReactInputValue(input, value) {
  const previousValue = input.value;
  input.value = value;
  const tracker = input._valueTracker;
  if (tracker) {
    tracker.setValue(previousValue);
  }
  input.dispatchEvent(new Event('change', { bubbles: true }));
}

_File.propTypes = inputPropTypes;

export default _File;
