import React, { useState } from 'react';

import Input from '../../../../../../../../../common/data-entry/Input';
import Button from '../../../../../../../../../common/general/Button';

const OptionInput = ({ addNewCustomOptionInFinalList, label }) => {
  const [newCustomListValue, setNewCustomListValue] = useState('');

  return (
    <div className="new-option-list">
      <div className="new-option-label">{label}</div>
      <Input
        className="new-option-input"
        type="text"
        validate={false}
        value={newCustomListValue}
        onChange={({ target: { value } }) => {
          setNewCustomListValue(value);
        }}
      />
      <Button
        onClick={() => {
          addNewCustomOptionInFinalList(newCustomListValue.trim());
          setNewCustomListValue('');
        }}
        disabled={!newCustomListValue.trim()}
      >
        Add
      </Button>
    </div>
  );
};

export default OptionInput;
