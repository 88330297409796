import uuid from 'uuid/v4';

import EuiDropzone from '../EuiDropzone/EuiDropzone';

import { useEuiFileUpload } from './EuiFileUploadProvider';

export default function EuiFileUploadDropzone({ multiple, accept, error }) {
  const euiFileUpload = useEuiFileUpload();
  return (
    <EuiDropzone
      multiple={multiple}
      accept={accept}
      onDrop={function(acceptedFiles) {
        euiFileUpload.addFiles(
          acceptedFiles.map(function(fileInstance) {
            return {
              id: uuid(),
              fileName: fileInstance.name,
              fileType: fileInstance.type,
              fileInstance
            };
          })
        );
      }}
      error={error}
    />
  );
}
