import axios from 'axios';

const ROOT = 'api/v1/notifications';

const NotificationApi = {
  getNumberOfNewNotifications() {
    return axios.get(`${ROOT}/count`, { hideLoader: true });
  },
  getPersonnelNotifications(pageNumber, readStatus) {
    return axios.get(`${ROOT}/?pageNumber=${pageNumber}&readStatus=${readStatus}`, {
      hideLoader: true
    });
  },
  changeNotificationStatus(notificationIds, status) {
    return axios.post(`${ROOT}/change-status/${status}`, notificationIds, { hideLoader: true });
  },
  markAllBeforeDateAsRead(lastVisibleEventId) {
    return axios.post(`${ROOT}/change-status/read-all/${lastVisibleEventId}`, null, { hideLoader: true });
  },
  markAllNewStatusAsViewed() {
    return axios.post(`${ROOT}/change-status/new-status`, null, { hideLoader: true });
  }
};

export default NotificationApi;
