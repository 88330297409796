import { PI_WORK_LIST, PL } from '../../../../constants/accessToPages';
import { generatePatientInfoRouterConfig } from '../../../root/router/sharedConfigs';

import PIWorkListNew from './InvestigatorWorklistNew';

export const investigatorWorklistNewRouterConfig = {
  name: 'Investigator Worklist',
  path: '/pi-work-list',
  component: PIWorkListNew,
  access: [PI_WORK_LIST],
  inherited: [...generatePatientInfoRouterConfig(PL, PI_WORK_LIST)]
};
