import React from 'react';

import Icon from '../../../../../common/general/Icon';

function ReminderTemplate({ customClassObject, templateData, styleForItem, showInvestigatorFieldIcon }) {
  return (
    <div className={customClassObject?.containerClass ?? ''}>
      <div className="" style={{ marginLeft: styleForItem?.paddingLeft || 0, display: 'flex', alignItems: 'center' }}>
        {showInvestigatorFieldIcon && (
          <Icon suit="material" style={{ fontSize: '25px' }}>
            medical_services
          </Icon>
        )}
        <i className="glyphicon glyphicon-ok ml-2"> </i>&nbsp; {templateData.question}
      </div>
    </div>
  );
}

export default ReminderTemplate;
