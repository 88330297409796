import React, { useContext, useEffect, useMemo, useState } from 'react';
import { sortBy } from 'lodash';
import { reject } from 'lodash/collection';
import { isString } from 'lodash/lang';
import Collapse from 'rc-collapse';

import { LoadingHandler } from '../../../../../../common/elements/Loader/LoadingHandler';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import Icon from '../../../../../../common/general/Icon';
import { CLOSED } from '../../../../../../constants/ssuStatuses';
import { MANAGE_PATIENT_OVERALL_INFORMATION } from '../../../../../../constants/userOperations';
import { ROLE_SYSTEM_ADMINISTRATOR } from '../../../../../../constants/userRoles';
import { userHasAccessTo, userHasRole } from '../../../../../../services/auth';
import { PatientInfoContext } from '../PatientInfoContext';

import AddOrEditPatientInfoModal from './AddOrEditPatientInfoModal/AddOrEditPatientInfoModal';
import DuplicatePatientModal from './AddOrEditPatientInfoModal/DuplicatePatientModal';
import CardBalance from './CardBalance/CardBalance';
import { InstructionContext } from './PatientContent/InstructionContextProvider';
import PatientContentTabs from './PatientContent/PatientContentTabs';
import StudyHistory from './StudyHistory/StudyHistory';

import './PatientInfoMainSection.scss';

export default function PatientInfoMainSection() {
  const {
    studyHistoryLoading,
    patientInfoLoading,
    patientInfo,
    updatePatientInfo,
    currentStudy,
    uniqueSites
  } = useContext(PatientInfoContext);

  const [alternatePhoneIsDisplayed, showAlternatePhone] = useState(false);
  const [activeKey, setActiveKey] = useState(-1);
  const { instructions } = useContext(InstructionContext);
  const isAllowedToEdit = useMemo(() => {
    return (
      userHasRole(ROLE_SYSTEM_ADMINISTRATOR) ||
      (currentStudy?.studySiteStatus !== CLOSED && userHasAccessTo(MANAGE_PATIENT_OVERALL_INFORMATION))
    );
  }, [currentStudy]);

  const { ssn, cardInfo, address, phonesInfo, email, isDeceased } = patientInfo || {};

  const uniqueSitesSortedAsc = useMemo(() => {
    return sortBy(uniqueSites, ['siteName']);
  }, [uniqueSites]);

  useEffect(() => {
    setActiveKey(activeKey => (instructions.length > 0 ? 1 : activeKey));
  }, [instructions]);

  const dataLoaded = !studyHistoryLoading && !patientInfoLoading;

  return (
    <React.Fragment>
      {activeKey && (
        <Collapse
          activeKey={activeKey}
          onChange={key => setActiveKey(key)}
          expandIcon={({ isActive }) => (
            <span className="rc-collapse-header-expand-icon material-icons">
              {`keyboard_arrow_${isActive ? 'up' : 'down'}`}
            </span>
          )}
          className="eds-patient-info-main-section"
        >
          <Collapse.Panel
            header={
              <React.Fragment>
                <div className="rc-collapse-header-title">INFO</div>
                {dataLoaded && (
                  <div className="rc-collapse-header-content">
                    {!isDeceased ? (
                      <>
                        <IgnoreClickEvents>
                          <span className="eds-patient-info-main-section-patient-contact-status">
                            {getPrimaryContact(phonesInfo)}
                          </span>
                        </IgnoreClickEvents>
                        {!phonesInfo.neverCallAgain && phonesInfo.voicemailNotAllowed && (
                          <Icon className="voicemail-not-allowed-icon" title="Voicemail Not Allowed">
                            voicemail
                          </Icon>
                        )}
                      </>
                    ) : (
                      <IgnoreClickEvents>
                        <span className="eds-patient-info-main-section-patient-contact-status">Deceased</span>
                      </IgnoreClickEvents>
                    )}
                    <CardBalance />
                  </div>
                )}
              </React.Fragment>
            }
            key="1"
          >
            <LoadingHandler complete={dataLoaded} dark>
              {() => {
                return (
                  <React.Fragment>
                    <section className="ims-info-container">
                      <div className="ims-info">
                        <header>
                          <h3>Overall</h3>
                          {isAllowedToEdit && (
                            <Button onClick={editPatientInfo} size="h28" priority="low">
                              Edit
                            </Button>
                          )}
                        </header>
                        <div className="ims-content">
                          <div className="ims-content-table">
                            <div>
                              <div>
                                {address?.country === 'CA' ? 'SIN' : 'SSN'}
                                <span>&nbsp;&nbsp;{ssn}</span>
                              </div>
                            </div>
                            <div>
                              <div>
                                PID
                                <span>&nbsp;&nbsp;{cardInfo?.id}</span>
                              </div>
                            </div>
                            <div>
                              <div className="pb-0">Sites</div>
                            </div>
                            <ul>
                              {uniqueSitesSortedAsc.map(({ siteId, siteName }) => (
                                <li key={siteId}>{siteName}</li>
                              ))}
                            </ul>
                          </div>
                          <div className="ims-content-table">
                            <div>
                              <div>
                                Primary
                                <span>&nbsp;&nbsp;{getPrimaryContact(phonesInfo)}</span>
                                {!phonesInfo.neverCallAgain && phonesInfo.voicemailNotAllowed && (
                                  <div>
                                    <Icon className="voicemail-not-allowed-icon">voicemail</Icon>
                                    Voicemail Not Allowed
                                  </div>
                                )}
                              </div>
                            </div>
                            {!phonesInfo.neverCallAgain && (
                              <div>
                                <div>
                                  Alternate
                                  <span style={{ position: 'relative' }}>
                                    {phonesInfo.alternative &&
                                      (alternatePhoneIsDisplayed ? (
                                        <span>&nbsp;&nbsp;{phonesInfo.alternative}</span>
                                      ) : (
                                        <Button
                                          className="show-alternate-phone"
                                          size="h28"
                                          priority="low"
                                          onClick={showAlternatePhone}
                                        >
                                          Show
                                        </Button>
                                      ))}
                                  </span>
                                </div>
                              </div>
                            )}
                            <div>
                              <div>
                                E-mail
                                <span>&nbsp;&nbsp;{email}</span>
                              </div>
                            </div>
                            <div>
                              <div className="pb-0">Address</div>
                            </div>
                            <div>
                              <span className="nowrap pr-3">{getAddress(address)}</span>
                              <span className="nowrap">{getCityAndState(address)}</span>
                            </div>
                          </div>
                          <div />
                        </div>
                      </div>
                      <PatientContentTabs />
                    </section>
                    <StudyHistory />
                  </React.Fragment>
                );
              }}
            </LoadingHandler>
          </Collapse.Panel>
        </Collapse>
      )}
    </React.Fragment>
  );

  function onDuplicate(currentStudy, duplicateInfo, existsInStudy, patientInfo) {
    const duplicatePatientModal = ModalBoxes.open({
      component: (
        <DuplicatePatientModal
          currentStudy={currentStudy}
          duplicateInfo={duplicateInfo}
          existsInStudy={existsInStudy}
          patientInfo={patientInfo}
          onSave={function() {
            updatePatientInfo();
            duplicatePatientModal.close();
          }}
        />
      ),
      title: 'Duplicate Patient Found'
    });
  }

  function editPatientInfo() {
    const addOrEditPatientInfoModal = ModalBoxes.open({
      component: (
        <AddOrEditPatientInfoModal
          currentStudy={currentStudy}
          patientInfo={patientInfo}
          onDuplicate={onDuplicate}
          onSave={function() {
            updatePatientInfo();
            addOrEditPatientInfoModal.close();
          }}
        />
      ),
      title: 'Edit Patient Info'
    });
  }
}
function IgnoreClickEvents({ children }) {
  return (
    <div
      onClick={e => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }}
    >
      {children}
    </div>
  );
}

function getAddress(address) {
  const fullAddress = reject([address.addressLine1, address.addressLine2], v => !isString(v) || !v.length).join(', ');
  return fullAddress && fullAddress;
}

function getCityAndState(address) {
  let cityAndState = reject([address.city, address.state], v => !isString(v) || !v.length).join(', ');

  if (address.zipCode) {
    cityAndState = `${cityAndState} ${address.zipCode}`;
  }
  return `${cityAndState && cityAndState + ', '}${address.country}`;
}

function getPrimaryContact(phoneInfo) {
  if (phoneInfo.neverCallAgain) {
    return 'Never Call Again';
  }
  if (phoneInfo.primary) {
    return phoneInfo.primary;
  }
  return '';
}
