import { dataMappingRouterConfig } from '../DataMapping/dataMappingRouterConfig';
import { afterburnerRequestLogRouterConfig } from '../MappingRequestLog/afterburnerRequestLogRouterConfig';
import { mappingTemplatesRouterConfig } from '../MappingTemplates/mappingTemplatesRouterConfig';
import { platformConfigurationRouterConfig } from '../PlatformConfiguration/platformConfigurationRouterConfig';

import { accountRouterConfig } from './AccountsNew/routerConfig';
import { budgetRouterConfig } from './Budget/routerConfig';
import { itemGroupTemplatesRouterConfig } from './ItemGroupTemplates/routerConfig';
import { protocolRouterConfig } from './Protocol/routerConfig';
import { publishBudgetRouterConfig } from './PublishBudget/routerConfig';

export const setupRouterConfig = [
  itemGroupTemplatesRouterConfig,
  protocolRouterConfig,
  budgetRouterConfig,
  publishBudgetRouterConfig,
  accountRouterConfig,
  platformConfigurationRouterConfig,
  mappingTemplatesRouterConfig,
  dataMappingRouterConfig,
  afterburnerRequestLogRouterConfig
];
