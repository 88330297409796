import React, { useContext } from 'react';

import Divider from '../../../../common/elements/Divider/Divider';
import useAppInfo from '../../../../common/hooks/useAppInfo';
import { ACCESS_TO_ADVERSE_EVENT_INVESTIGATOR_OPERATIONS } from '../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../services/auth';
import { PageInfoHeader } from '../../../PageInfoHeader/PageInfoHeader';
import { ADVERSE_EVENTS_WIDGET } from '../shared/Worklist/constants';
import WorklistChips from '../shared/Worklist/WorklistChips';
import { WorklistFilters } from '../shared/Worklist/WorklistFilters';
import { WorklistStatusMessageAndSearchField } from '../shared/Worklist/WorklistStatusMessageAndSearchField';
import { WorklistWidget } from '../shared/Worklist/WorklistWidget';

import { colors, investigatorAdverseEventsStatusesProvider, investigatorStatusesProvider } from './constants';
import InvestigatorWorklistFilterContext, { InvestigatorWorklistContext } from './InvestigatorWorklistFilterContext';
import { InvestigatorWorklistTableNew } from './InvestigatorWorklistTableNew';

import '../shared/Worklist/WorklistStyles.scss';

const InvestigatorWorklistNew = () => {
  const appInfo = useAppInfo();
  const Context = useContext(InvestigatorWorklistContext);
  return (
    <div className={'worklist-new'}>
      <div className="worklist-header">
        <PageInfoHeader>
          <WorklistFilters Context={Context} />
          <WorklistChips Context={Context} />
        </PageInfoHeader>
        <div className={'worklist-widget-container'}>
          <WorklistWidget
            Context={Context.EncounterWidgetContext}
            colors={colors}
            statusProvider={investigatorStatusesProvider}
          />
          {userHasAccessTo(ACCESS_TO_ADVERSE_EVENT_INVESTIGATOR_OPERATIONS) && (
            <WorklistWidget
              Context={Context.AdverseEventsWidgetContext}
              colors={colors}
              statusProvider={investigatorAdverseEventsStatusesProvider}
              widgetType={ADVERSE_EVENTS_WIDGET}
            />
          )}
        </div>
      </div>
      <Divider className={'worklist-divider'} />
      <WorklistStatusMessageAndSearchField Context={Context} statusesProvider={investigatorStatusesProvider} />
      <InvestigatorWorklistTableNew />
    </div>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function() {
  return (
    <InvestigatorWorklistFilterContext>
      <InvestigatorWorklistNew />
    </InvestigatorWorklistFilterContext>
  );
}
