import { NavLink, useNavigate } from 'react-router-dom';

import Icon from '../../../../../common/general/Icon';
import { generateUrlByKey, useCurrentRoute } from '../../../../root/router';

import { usePatientProfile } from './PatientProfileContext';
import { showConfirmDialog } from './service';

import './GoToPatientStudiesButton.scss';

export default function GoToPatientStudiesButton() {
  const { studyHistory, hasUnsavedInstructions, setHasUnsavedInstructions } = usePatientProfile();
  const currentRoute = useCurrentRoute();
  const navigate = useNavigate();
  const lastSsuPatientIdMap = JSON.parse(sessionStorage.getItem('LAST_SSU_PATIENT_ID_MAP'));
  const lastSsuPatientId =
    lastSsuPatientIdMap && lastSsuPatientIdMap[currentRoute.params.patientId]
      ? lastSsuPatientIdMap[currentRoute.params.patientId]
      : studyHistory[0].ssuPatientId;
  const validKey = currentRoute.isNested ? currentRoute.parent.key : currentRoute.key;
  if (!validKey || !lastSsuPatientId) return null;

  const handleNavigation = event => {
    if (hasUnsavedInstructions) {
      event.preventDefault();
      showConfirmDialog('patient instruction')
        .then(() => {
          navigate(
            generateUrlByKey(`${validKey}.Patient Studies`, {
              ...currentRoute.params,
              ssuPatientId: lastSsuPatientId
            })
          );
          setHasUnsavedInstructions(false);
        })
        .catch(() => {
          return;
        });
    }
  };

  return (
    <div className="patient-profile-subheader">
      <NavLink
        onClick={handleNavigation}
        className="go-to-patient-studies-button"
        to={generateUrlByKey(`${validKey}.Patient Studies`, {
          ...currentRoute.params,
          ssuPatientId: lastSsuPatientId
        })}
      >
        <Icon>arrow_back</Icon>
        Navigate to Patient Studies
      </NavLink>
    </div>
  );
}
