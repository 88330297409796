import React, { useCallback, useEffect } from 'react';
import { isEmpty, isEqual } from 'lodash/lang';
import { get, pick } from 'lodash/object';

import { TaskApi } from '../../../../api';
import { getUniqSitesFromSsus, getUniqStudiesFromSsus } from '../../../../services/studySites';
import { useCurrentUser } from '../../../root/Container/CurrentUserContainer';

export const TaskWorklistContext = React.createContext(null);

function TaskWorklistProvider({ children }) {
  const user = useCurrentUser();
  const [sites, setSites] = React.useState([]);
  const [studies, setStudies] = React.useState([]);
  const [ssus, setSsus] = React.useState([]);

  const [worklistFilters, setWorklistFilters] = React.useState(null);

  const updateWorklistFilter = useCallback(
    function(ssus) {
      const storedFilters = get(
        JSON.parse(localStorage.getItem('taskWorklistFilter')),
        `${user.personnelIdentifier}_${user.activeRole}`
      );
      if (storedFilters && !isEmpty(ssus)) {
        const storedSsuList = ssus.filter(e => (storedFilters.ssuIds ? storedFilters.ssuIds.includes(e.id) : true));
        const storedStudyList = getUniqStudiesFromSsus(storedSsuList);
        const storedSiteList = getUniqSitesFromSsus(storedSsuList);
        const newFilter = {
          ...storedFilters,
          ssuIds: storedSsuList.map(e => e.id),
          // in this case we ignore actually stored studyIds & siteIds because we take it from stored ssus filtered for logged user
          // check only if they where selected(on UI)
          studies: !isEmpty(storedFilters.studyIds) ? storedStudyList : [],
          sites: !isEmpty(storedFilters.siteIds) ? storedSiteList : [],
          pageSize: storedFilters.pageSize || 25
        };
        !isEqual(newFilter, worklistFilters) && setWorklistFilters(newFilter);
      }
    },
    [user.activeRole, user.personnelIdentifier, worklistFilters]
  );

  useEffect(
    function() {
      TaskApi.getAllStudySites().then(({ data: ssus }) => {
        updateWorklistFilter(ssus);
        setSsus(ssus);
        setStudies(getUniqStudiesFromSsus(ssus));
        setSites(getUniqSitesFromSsus(ssus));
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  function updateLocalStorageFilters(filters) {
    const storedFilters = JSON.parse(localStorage.getItem('taskWorklistFilter')) || {};
    storedFilters[`${user.personnelIdentifier}_${user.activeRole}`] = {
      ...pick(filters, ['assigneeIds', 'page', 'pageSize', 'sortedBy', 'ssuIds', 'taskStatuses', 'taskTopics']),
      studyIds: filters.studies.map(e => e.id),
      siteIds: filters.sites.map(e => e.id)
    };
    localStorage.setItem('taskWorklistFilter', JSON.stringify(storedFilters));
  }

  return (
    <TaskWorklistContext.Provider
      value={{
        sites,
        studies,
        ssus,
        worklistFilters,
        setWorklistFilters(filters) {
          updateLocalStorageFilters(filters);
          setWorklistFilters(filters);
        }
      }}
    >
      {children}
    </TaskWorklistContext.Provider>
  );
}

export function withTasksWorklistProvider(Component) {
  return function WrapperComponent(props) {
    return (
      <TaskWorklistProvider>
        <Component {...props} />
      </TaskWorklistProvider>
    );
  };
}
