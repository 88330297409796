import React from 'react';
import { connect } from 'react-redux';
import { memoize } from 'lodash/function';
import moment from 'moment';

import { getStudyBudgets } from '../../../../actions/finance/budget/getStudyBudgetsAction';
import { addBudgetSite } from '../../../../actions/finance/budgetSite/addBudgetSiteAction';
import { getBudgetSites } from '../../../../actions/finance/budgetSite/getBudgetSitesAction';
import { StudySiteApi } from '../../../../api';
import DatePicker from '../../../../common/data-entry/DatePicker';
import Select from '../../../../common/data-entry/Select';
import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../common/general/Button';
import { ADD_NEW_BUDGET_SITE_MAPPING } from '../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../services/auth';
import { PageInfoHeader } from '../../../PageInfoHeader/PageInfoHeader';
import { SSUFilter } from '../../../SSUFilter/SSUFilter';
import { SSUPCNFilter } from '../../../SSUFilter/SSUPCNFilter';

import BudgetSiteList from './BudgetSiteList/BudgetSiteList';

import './PublishBudget.scss';

export class PublishBudget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      studyOptionsList: [],
      finSites: [],
      chosenStudyId: null,
      chosenBudgetId: null,
      chosenBudgetComment: null,
      chosenSiteId: null,
      effectiveDate: moment()
    };
    this.studySitePromise = StudySiteApi.getStudySitesWithProtocolAssigned().then(({ data }) => data);
    this.props.getBudgetSites();
    this.props.getStudyBudgets();
  }

  convertToBudgetOptions = memoize(list =>
    list.map(el => {
      return {
        id: el.id,
        name: `${el.version} - ${el.description}`,
        studyId: el.studyId,
        comment: el.comment
      };
    })
  );

  onSSUFilterChange = (ssuList, selectedStudy, selectedSite) => {
    this.setState({
      chosenSiteId: selectedSite?.uniqueIdentifier,
      chosenStudyId: selectedStudy?.uniqueIdentifier
    });
  };

  onBudgetChange = option => {
    this.setState({ chosenBudgetId: option?.id, chosenBudgetComment: option?.comment });
  };

  onEffectiveDateChange = moment => {
    this.setState({ effectiveDate: moment._d });
  };

  addNewBudgetSiteMapping = () => {
    if (!this.state.chosenBudgetComment) {
      return ModalBoxes.confirm({
        title: 'Alert',
        content: 'Please enter a budget version comment prior to adding a budget.',
        confirmButton: 'Ok',
        cancelButton: false
      }).then(
        () => {},
        () => {}
      );
    }
    const chosenBudgetSiteMapping = {
      finBudgetId: this.state.chosenBudgetId,
      siteId: this.state.chosenSiteId,
      effectiveDate: moment(this.state.effectiveDate).format('YYYY-MM-DD')
    };

    if (
      chosenBudgetSiteMapping.finBudgetId &&
      chosenBudgetSiteMapping.siteId &&
      chosenBudgetSiteMapping.effectiveDate
    ) {
      this.props.addBudgetSite(chosenBudgetSiteMapping);
    }
  };

  allRequiredFieldsAreFilled = () => {
    return this.state.chosenBudgetId && this.state.chosenSiteId && this.state.effectiveDate && this.state.chosenStudyId;
  };

  render() {
    const { chosenStudyId, effectiveDate } = this.state;
    const { studySitePromise } = this;
    let budgetOptions = this.convertToBudgetOptions(this.props.budgetList);
    if (chosenStudyId) {
      budgetOptions = budgetOptions.filter(el => el.studyId === chosenStudyId);
    }
    return (
      <div className="footpadb study-budget-site">
        <PageInfoHeader />
        {userHasAccessTo(ADD_NEW_BUDGET_SITE_MAPPING) && (
          <section className="add-section">
            <h5>New Budget-Site mapping</h5>
            <div className="general-header-group-container general-header-wrapper">
              <SSUFilter handleSSUFilterChange={this.onSSUFilterChange} ssuProvider={() => studySitePromise}>
                <SSUPCNFilter />
              </SSUFilter>
              <Select
                value={budgetOptions.find(({ id }) => this.state.chosenBudgetId === id)}
                label="Budget"
                onChange={this.onBudgetChange}
                dataSource={budgetOptions}
                clearable
                searchable
                validate={false}
                data-testid="budget-select"
              />
              <DatePicker
                label="Effective date"
                value={effectiveDate}
                required={true}
                defaultValue={moment()}
                onChange={event => this.onEffectiveDateChange(event)}
                validate={false}
              />
              <Button
                className="ml-3"
                disabled={!this.allRequiredFieldsAreFilled()}
                type="button"
                onClick={this.addNewBudgetSiteMapping}
                size="h56"
              >
                Add
              </Button>
            </div>
          </section>
        )}
        <BudgetSiteList budgetSitesList={this.props.budgetSitesList} studyOptions={() => studySitePromise} />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getStudyBudgets: () => {
      dispatch(getStudyBudgets());
    },
    getBudgetSites: () => {
      dispatch(getBudgetSites());
    },
    addBudgetSite: budgetSite => {
      dispatch(addBudgetSite(budgetSite));
    }
  };
}

function mapStateToProps(state) {
  return {
    budgetList: state.budgetList,
    budgetSitesList: state.budgetSitesList
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PublishBudget);
