import React from 'react';

import { toFormattedFinanceBill } from '../../../../../../../../services/financial';
import { stylesContent } from '../../EncounterInvoiceTemplateStyles';

export const SubHeader = ({ item }) => {
  return (
    <div style={stylesContent.groupSubHeader} className="row-item" data-testid="sub-group-amount-calculation">
      {`${item.subGroupName}: Subtotal ${toFormattedFinanceBill(item.subGroupAmount)}`}
    </div>
  );
};
