/**
 *
 * @summary Protocol encounter setup for assign elements to encounters
 * @author Ankur Vishwakarma,Poonam Banode,Mangesh Pimprikar,Irfan Bagwan
 */

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { findIndex } from 'lodash/array';
import { find, orderBy } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';
import Tooltip from 'rc-tooltip';

import { ProtocolApi } from '../../../../../../api';
import Section from '../../../../../../common/data-display/Section/Section';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../common/general/ButtonGroup';
import { withAppInfo } from '../../../../../../common/hocs/withAppInfo';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import { ENCOUNTER_MISSING_ELEMENT, SETUP_SAVED } from '../../../../../../constants/notificationMessages';
import { onRequestDefaultError } from '../../../../../../services/handlers';
import { PageInfoHeader } from '../../../../../PageInfoHeader/PageInfoHeader';
import { generateUrlByKey } from '../../../../../root/router';
import { withNavigate, withParams } from '../../../../../root/router/legacyComponentCompatability';
import { TABLE_SETUP } from '../../../shared/ElementSetupNew/TableSetup/TableSetupConstants';
import { EncounterEpochTableTemplate } from '../../EncounterEpochTableTemplate/EncounterEpochTableTemplate';
import SituationalEncounterModal from '../SituationalEncounterModal/SituationalEncounterModal';

import ChangeProtocolEncounterOrderingModal from './ChangeProtocolEncounterOrderingModal/ChangeProtocolEncounterOrderingModal';
import {
  SITUATIONAL_ENCOUNTERS_EPOCH_ID,
  sortEpochsAndEncountersByOriginSequence,
  sortEpochsAndEncountersByProtocolSequence
} from './services';

import './ProtocolEncounterSetup.scss';

class ProtocolEncounterSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      epochData: [],
      elements: [],
      definedElementList: [],
      selectedElement: null,
      protocolVersion: '',
      versionId: '',
      fullResponse: '',
      fullEpochData: '',
      epochElementList: [],
      situationalEncounters: [],
      protocolName: '',
      studyName: '',
      protocolId: null
    };
    this.oldEpochData = [];
    this.currentDragIndex = null;
  }

  componentDidMount() {
    localStorage.setItem('fromMenu', false);

    try {
      ProtocolApi.getPreviewProtocol(this.props.params.studyId, this.props.params.protocolIdentity, 'Draft').then(
        res => {
          if (res.status === 200 && res.data && typeof res.data === 'object') {
            const sortedEpochData = sortEpochsAndEncountersByProtocolSequence(res.data.epoch);
            this.oldEpochData = JSON.stringify(sortedEpochData);

            const itemGroupsFromServer = res.data.unexpectedEncountersSetup || [];
            this.setState({
              epochData: sortedEpochData,
              protocolVersion: res.data.version,
              versionId: res.data.versionId,
              fullResponse: res.data,
              fullEpochData: JSON.stringify(res.data.epoch),
              studyName: res.data.studyName,
              protocolName: res.data.name,
              protocolId: res.data.protocolId,
              situationalEncounters: res.data.situationalEncountersSetup || []
            });

            ProtocolApi.getAllItemGroupsByStudy(this.props.params.studyId).then(resEle => {
              if (
                resEle.data.responsecode === 200 &&
                resEle.data.status === 'success' &&
                resEle.data.response &&
                typeof resEle.data.response === 'object'
              ) {
                const actualResEle = resEle.data.response;
                const prevEpochEle = this.state.epochElementList;
                const prevSituationalEle = this.state.situationalEncounters?.flatMap(enc => enc.elements);
                let existingElement = [];
                let addedElements = [];
                sortedEpochData.forEach(epElx => {
                  if (epElx.encounters && epElx.encounters.length) {
                    epElx.encounters.forEach((encInfo, encIdx) => {
                      if (encInfo.elements && encInfo.elements.length) {
                        encInfo.elements.forEach(encElementItr => {
                          const eleFoundIdx = findIndex(prevEpochEle, f => {
                            return f.elementName === encElementItr.elementName;
                          });
                          if (eleFoundIdx === -1) {
                            prevEpochEle.push(encElementItr);
                          }
                        });
                      }
                    });
                  }
                });
                actualResEle.forEach(ig => this.enrichAllElementsByUnexpectedFlag(ig, itemGroupsFromServer));
                // update the original response
                actualResEle &&
                  actualResEle.forEach((originalEle, originalEleIdx) => {
                    const tmpFoundEleIdx = findIndex(prevEpochEle, p => {
                      return p.elementName === originalEle.elementName && p.hasOwnProperty('itemGroupSequence');
                    });
                    const tmpSituationalEleIdx = findIndex(prevSituationalEle, p => {
                      return p.elementName === originalEle.elementName && p.hasOwnProperty('sequence');
                    });
                    if (tmpFoundEleIdx > -1) {
                      originalEle.itemGroupSequence = prevEpochEle[tmpFoundEleIdx].itemGroupSequence;
                      existingElement.push(originalEle);
                    } else if (originalEle.useForUnexpected) {
                      existingElement.push(originalEle);
                    } else if (tmpSituationalEleIdx > -1) {
                      originalEle.itemGroupSequence = prevSituationalEle[tmpSituationalEleIdx].sequence;
                      existingElement.push(originalEle);
                    } else {
                      addedElements.push(originalEle);
                    }
                  });

                existingElement = orderBy(existingElement, 'itemGroupSequence', 'asc');
                addedElements = orderBy(addedElements, 'elementName', 'asc');
                const allElemetnsList = existingElement.concat(addedElements);
                this.setState({ elements: allElemetnsList });
              }
            }, onRequestDefaultError);
          }
        },
        onRequestDefaultError
      );
    } catch (e) {}
  }

  enrichAllElementsByUnexpectedFlag(ig, itemGroupsFromServer) {
    const itemGroupExistForUnexpected = itemGroupsFromServer.find(e => e.itemGroupTemplateId === ig.itemGroupKey);
    ig.useForUnexpected = !!itemGroupExistForUnexpected;
    if (!!itemGroupExistForUnexpected) {
      ig.itemGroupSequence = itemGroupExistForUnexpected.itemGroupSequence;
    }
  }

  updateEpochEncounterDetails = (epochId, encounterId, element, itemGroupSequence) => {
    const prevSt = this.state.epochData;
    const elements = prevSt[epochId].encounters[encounterId].elements;
    const eleIdx = findIndex(elements, o => {
      return o.elementName === element.elementName;
    });
    if (eleIdx > -1) {
      deleteElementOnIndex(elements, eleIdx);
    } else {
      createNewElement(elements, element, itemGroupSequence);
    }
    this.setState({ epochData: prevSt });
  };

  updateSituationalEncounterDetails = (elements, element, itemGroupSequence) => {
    const eleIdx = findIndex(elements, e => {
      return e.itemGroupTemplateVersionId === element.uniqueIdentifier;
    });
    if (eleIdx > -1) {
      deleteElementOnIndex(elements, eleIdx);
    } else {
      createNewSituationalElement(elements, element, itemGroupSequence);
    }
    this.setState(prevState => ({ ...prevState }));
  };

  // build the url params
  buildUrlParams(el) {
    if (this.props.params) {
      const element_identifier = el.uniqueIdentifier;
      const pagePath =
        el.elementIcon === TABLE_SETUP
          ? 'Setup Protocol.Source Data Setup.Encounter Setup.Table Edit'
          : 'Setup Protocol.Source Data Setup.Encounter Setup.Item Group Edit';
      return generateUrlByKey(pagePath, {
        studyId: this.props.params.studyId,
        protocolIdentity: this.props.params.protocolIdentity,
        element_identifier
      });
    }
    return '';
  }

  renderElementListItemForEpoch(ele, itemGrpIdx) {
    const elementEncounterList = [];
    elementEncounterList.push(this.renderItemGroupLink(ele));

    this.state.epochData.forEach((epoch, epochId) => {
      epoch.encounters.forEach((encounter, encounterId) => {
        const uniqueKey = `${epoch.nodeKey}-${epoch.name}-${epochId}-${encounterId}`;
        const encounterItemGroup = (
          <td
            className={checkForElementClass(encounter.elements, ele)}
            key={uniqueKey}
            onClick={() => this.updateEpochEncounterDetails(epochId, encounterId, ele, itemGrpIdx)}
          >
            {isElementsContains(encounter.elements, ele) && <i className={'glyphicon glyphicon-ok white-text'} />}
            <div
              className="optional-ig-triangle-toggle"
              onClick={event => this.toggleOptionalItemGroup(event, epochId, encounterId, ele)}
            />
          </td>
        );
        elementEncounterList.push(encounterItemGroup);
      });
    });
    elementEncounterList.push(
      <td
        className={checkForUnexpectedElementClass(ele)}
        key={'uniqueUnexpectedEncIg'}
        onClick={() => {
          ele.useForUnexpected = !ele.useForUnexpected;
          ele.itemGroupSequence = itemGrpIdx;
          this.setState(prevState => ({ ...prevState }));
        }}
      >
        {ele.useForUnexpected && <i className={'glyphicon glyphicon-ok white-text'} />}
        <div className="unexpected-ig-triangle-toggle" />
      </td>
    );
    this.state.situationalEncounters?.forEach((encounter, index) => {
      const uniqueKey = `situational-${encounter.name}-${index}`;
      const encounterItemGroup = (
        <td
          className={checkForElementClass(encounter.elements, ele, findElementById)}
          key={uniqueKey}
          onClick={() => {
            this.updateSituationalEncounterDetails(encounter.elements, ele, itemGrpIdx);
          }}
        >
          {isElementsContains(encounter.elements, ele, findElementById) && (
            <i className={'glyphicon glyphicon-ok white-text'} />
          )}
          <div
            className="optional-ig-triangle-toggle"
            onClick={event => this.toggleOptionalSituationalItemGroup(event, index, ele)}
          />{' '}
        </td>
      );
      elementEncounterList.push(encounterItemGroup);
    });
    return elementEncounterList;
  }

  renderItemGroupLink(ele) {
    let toolTipTxt = '';
    let itemGropTooltip = '';
    itemGropTooltip = ele.elementName;
    if (ele.domainName === 'Custom Domain') {
      toolTipTxt = 'CD-Custom Domain';
    } else if (ele.domainName === 'Elligo Reminder') {
      toolTipTxt = 'ER-Elligo Reminder';
    } else {
      toolTipTxt = ele.domainName + (ele.description ? `-${ele.description}` : '');
    }

    let tmpSt = {};
    if (this.refs.enTable) {
      const tableRef = this.refs.enTable;
      const tbodyScrollPos = tableRef.children[1].scrollLeft;
      tmpSt = tbodyScrollPos > 0 ? { left: `${tbodyScrollPos}px` } : {};
    }

    const urlParams = this.buildUrlParams(ele);
    return (
      <td
        title=""
        onMouseOver={e => {
          this.elementNameMouseOver(e);
        }}
        key={Math.floor(Math.random() * 10000 + 1)}
        className="eett-sticky-column eett-header eett-grey-cell eett-right-align"
        style={tmpSt}
      >
        <Tooltip
          placement="top"
          mouseEnterDelay={0}
          mouseLeaveDelay={0.1}
          destroyTooltipOnHide={false}
          trigger={Object.keys({ hover: 1 })}
          onVisibleChange={this.onVisibleChange}
          overlay={
            <div className="tooltipOver">
              ItemGroup Domain: {toolTipTxt || 'Domain'}
              <div>ItemGroup Name: {itemGropTooltip}</div>
            </div>
          }
          align={{
            offset: [0, -3]
          }}
        >
          <label className="encounter_elements">
            <NavLink to={urlParams}> {ele.elementName}</NavLink>
          </label>
        </Tooltip>
      </td>
    );
  }

  hasElementOnUnexpectedEncounter() {
    return this.state.elements.filter(e => e.useForUnexpected).length > 0;
  }

  hasElementOnEachSituationalEncounter() {
    const situationalEncounters = this.state.situationalEncounters;
    if (isEmpty(situationalEncounters)) {
      return true;
    }
    return situationalEncounters.every(enc => !isEmpty(enc.elements));
  }

  hasElementOnEachEncounter() {
    const validationArry = [];
    if (this.state.epochData.length > 0) {
      this.state.epochData.forEach((epc, epIdx) => {
        const hasEncounter = !!epc.encounters.length;
        const hasEpochConnected = !!(epc.parent.length && epc.child.length);
        if (hasEncounter && hasEpochConnected) {
          validationArry.push(true);
        } else {
          validationArry.push(false);
        }

        if (epc.encounters.length) {
          epc.encounters.forEach((elx, inx) => {
            elx.elements.length ? validationArry.push(true) : validationArry.push(false);
          });
        }
      });

      if (validationArry.indexOf(false) > -1) {
        return false;
      }
      return true;
    }
    return false;
  }

  // function for the saving the prefrence
  saveProtocolEncounterSetup() {
    try {
      if (
        this.hasElementOnEachEncounter() &&
        this.hasElementOnUnexpectedEncounter() &&
        this.hasElementOnEachSituationalEncounter() &&
        this.state.epochData.length > 0 &&
        this.state.elements
      ) {
        const prevEpochs = this.state.epochData;
        this.state.elements.forEach((eIterator, eIteratorIdx) => {
          prevEpochs.forEach((epochInfo, epochId) => {
            if (epochInfo.encounters && epochInfo.encounters.length) {
              epochInfo.encounters.forEach((encInfo, encIdx) => {
                if (encInfo.elements && encInfo.elements.length) {
                  const foundIdx = findIndex(encInfo.elements, f => {
                    return !f.hasOwnProperty('itemGroupSequence');
                  });
                  if (foundIdx > -1) {
                    encInfo.elements[foundIdx].itemGroupSequence = eIteratorIdx;
                  }
                }
              });
            }
          });
        });

        this.setState({ epochData: prevEpochs }, () => {
          const sortedEpochData = sortEpochsAndEncountersByOriginSequence(
            this.state.epochData,
            this.state.fullResponse.epoch
          );
          const data = {};
          data.protocolId = this.state.protocolId;
          data.studyIdentifier = this.props.params.studyId;
          data.uniqueIdentifier = this.props.params.protocolIdentity;
          data.sourceIdentifier = null;
          data.language = 'EN';
          data.version = this.state.protocolVersion;
          data.name = this.state.fullResponse && this.state.fullResponse.name ? this.state.fullResponse.name : '';
          data.status = 'Draft';
          data.lastModifiedOn = new Date();
          data.lastModifiedBy = 'Admin';
          data.epoch = sortedEpochData;
          data.unexpectedEncountersSetup = this.filterUnexpectedEncounterElementsAndMap();
          data.situationalEncountersSetup = this.state.situationalEncounters;
          data.versionId = this.state.versionId;
          this.oldEpochData = JSON.stringify(this.state.epochData);

          ProtocolApi.saveEpochProtocol(this.props.params.studyId, this.props.params.protocolIdentity, data)
            .then(res => {
              if (res.status === 200) {
                NotificationManager.success(SETUP_SAVED);
              }
            })
            .catch(onRequestDefaultError);
        });
      } else {
        NotificationManager.warning(ENCOUNTER_MISSING_ELEMENT);
      }
    } catch (e) {}
  }

  filterUnexpectedEncounterElementsAndMap() {
    return (
      this.state.elements
        .filter(e => e.useForUnexpected)
        .map(e => {
          return {
            itemGroupTemplateId: e.itemGroupKey,
            itemGroupTemplateVersionId: e.uniqueIdentifier,
            elementIcon: e.elementIcon,
            itemGroupSequence: e.itemGroupSequence
          };
        }) || []
    );
  }

  // for cancel the setup
  cancelProtocolEncounterSetup() {
    const oldData = this.oldEpochData && JSON.parse(this.oldEpochData);
    const validationArr = [];
    this.state.epochData &&
      this.state.epochData.forEach((edt, idx) => {
        edt.encounters &&
          edt.encounters.forEach((en, inx) => {
            const currLength = en.elements && en.elements.length;
            const oldLength =
              oldData && oldData[idx].encounters[inx].elements && oldData[idx].encounters[inx].elements.length;
            if (currLength !== oldLength) {
              validationArr.push(false);
            } else {
              validationArr.push(true);
            }
          });
      });

    if (validationArr.indexOf(false) > -1) {
      this.checkForRedirect();
    } else {
      NotificationManager.info('Redirecting to Protocol Setup page.');
      setTimeout(() => {
        const pagePath = generateUrlByKey('Setup Protocol.Source Data Setup', {
          studyId: this.props.params.studyId,
          protocolIdentity: this.props.params.protocolIdentity
        });
        this.props.navigate(pagePath);
      }, 400);
    }
  }

  // for redirecting page
  checkForRedirect() {
    ModalBoxes.confirm({
      content:
        'You have entered the data on this page. If you navigate away from this page without first saving your data, the changes will be lost',
      title: 'Are you sure?',
      confirmButton: 'Yes',
      cancelButton: 'No'
    }).then(
      () => {
        this.props.navigate(-1);
      },
      () => {}
    );
  }

  // onTrDragStart
  onTrDragStart = (e, dgIdx) => {
    e.dataTransfer.setData('text', 'dragRow');
    if (dgIdx != null) {
      e.dataTransfer.effectAllowed = 'move';
      this.currentDragIndex = dgIdx;
    }
  };

  // onTrDragOver
  onTrDragOver = (event, idx) => {
    event.preventDefault();
  };

  onTrDrop = (e, l, _dindex, ele) => {
    document.getElementById(_dindex).classList.remove('over');
    try {
      if (_dindex != null && this.currentDragIndex != null && _dindex !== this.currentDragIndex) {
        const prevElemSt = this.state.elements;
        const prevEpochInfo = this.state.epochData;
        const prevSituationalInfo = this.state.situationalEncounters;
        const refEl = this.state.elements[this.currentDragIndex];
        prevElemSt.splice(this.currentDragIndex, 1);
        prevElemSt.splice(_dindex, 0, refEl);
        prevElemSt.forEach((eIterator, eIteratorIdx) => {
          if (eIterator.useForUnexpected) {
            eIterator.itemGroupSequence = eIteratorIdx;
          }
          prevEpochInfo.forEach((epochInfo, epochId) => {
            if (epochInfo.encounters && epochInfo.encounters.length) {
              epochInfo.encounters.forEach((encInfo, encIdx) => {
                if (encInfo.elements && encInfo.elements.length) {
                  const foundIdx = findIndex(encInfo.elements, f => {
                    return f.elementName === eIterator.elementName;
                  });
                  if (foundIdx > -1) {
                    encInfo.elements[foundIdx].itemGroupSequence = eIteratorIdx;
                  }
                }
              });
            }
          });
          prevSituationalInfo.forEach(enc => {
            if (enc.elements && enc.elements.length) {
              const foundIdx = findIndex(enc.elements, f => {
                return f.elementName === eIterator.elementName;
              });
              if (foundIdx > -1) {
                enc.elements[foundIdx].sequence = eIteratorIdx;
              }
            }
          });
        });

        // updating changes on state.
        this.setState(
          { elements: prevElemSt, epochData: prevEpochInfo, situationalEncounters: prevSituationalInfo },
          () => {
            this.currentDragIndex = null;
          }
        );
      }
      e.preventDefault();
    } catch (e) {}
  };

  // dragenter
  onTrDragEnter = (e, idx) => {
    document.getElementById(idx).classList.add('over');
  };

  // onTrDragLeave
  onTrDragLeave = (e, idx) => {
    document.getElementById(idx).classList.remove('over');
  };

  // adding and removing title on element name hover
  elementNameMouseOver = e => {
    if (e.target.tagName === 'TD') {
      e.target.title = 'Drag & Drop ItemGroup to reorder';
    } else {
      e.target.title = '';
    }
  };

  openChangeProtocolEncounterOrderingModal = () => {
    ModalBoxes.open({
      component: (
        <ChangeProtocolEncounterOrderingModal
          epochs={this.state.epochData}
          situationalEncounters={this.state.situationalEncounters}
          onConfirm={epochData => {
            const epochWithoutSituational = epochData.filter(
              epoch => epoch.epochIdentifier !== SITUATIONAL_ENCOUNTERS_EPOCH_ID
            );
            const situationalEncounters =
              epochData.find(epoch => epoch.epochIdentifier === SITUATIONAL_ENCOUNTERS_EPOCH_ID)?.encounters || [];
            this.setState({ epochData: epochWithoutSituational, situationalEncounters: situationalEncounters });
          }}
        />
      )
    });
  };

  openSituationalEncounterModal = () => {
    ModalBoxes.open({
      component: (
        <SituationalEncounterModal
          situationalEncounters={this.state.situationalEncounters}
          updateSituationalEncounter={newEncounter => {
            this.setState(prevState => ({
              situationalEncounters: [...prevState.situationalEncounters, newEncounter]
            }));
          }}
        />
      )
    });
  };

  render() {
    const { state } = this;
    const style = { textAlign: 'center' };
    return (
      <>
        <PageInfoHeader
          objectRecordLabel={state.studyName}
          pageInfo={
            <PageInfoHeader.CollapsibleList>
              <PageInfoHeader.AdditionalInfo tooltip="Source Data Name">
                {state.protocolName}
              </PageInfoHeader.AdditionalInfo>
              <PageInfoHeader.AdditionalInfo tooltip="Source Data Version">
                {state.protocolVersion}
              </PageInfoHeader.AdditionalInfo>
            </PageInfoHeader.CollapsibleList>
          }
          right={
            <ButtonGroup>
              <Button priority="low" onClick={this.cancelProtocolEncounterSetup.bind(this)}>
                Cancel
              </Button>
              <Button onClick={this.saveProtocolEncounterSetup.bind(this)}>Save</Button>
            </ButtonGroup>
          }
        />
        {this.props.appInfo.features.situationalEncountersEnabled && (
          <div className="situational-enc-section">
            <Button size={'h28'} priority="low" onClick={this.openSituationalEncounterModal}>
              Add situational encounter
            </Button>
          </div>
        )}
        <Section className="protocol-encounter-setup-page">
          <div className="encounter-setup-table-wrap">
            <EncounterEpochTableTemplate
              epochs={this.state.epochData}
              encounters={this.state.epochData.flatMap(({ encounters }) => encounters)}
              openChangeProtocolEncounterOrderingModal={this.openChangeProtocolEncounterOrderingModal}
              situationalEncounters={this.state.situationalEncounters}
              updateSituationalEncounters={situationalEncounters =>
                this.setState({ situationalEncounters: [...situationalEncounters] })
              }
            >
              {state.elements && state.elements.length > 0 ? (
                state.elements.map((ele, idx) => {
                  return (
                    <tr
                      className="draggable-component"
                      draggable="true"
                      onDragStart={e => this.onTrDragStart(e, idx)}
                      onDragOver={e => this.onTrDragOver(e, idx)}
                      onDrop={e => this.onTrDrop(e, 'wip', idx, ele)}
                      onDragEnter={e => this.onTrDragEnter(e, idx)}
                      onDragLeave={e => this.onTrDragLeave(e, idx)}
                      key={idx}
                      id={idx}
                    >
                      {this.renderElementListItemForEpoch(ele, idx)}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={state.epochData.reduce((ac, currentV) => {
                      return ac + currentV.encounters.length;
                    }, 0)}
                    style={style}
                  >
                    No record found.
                  </td>
                </tr>
              )}
            </EncounterEpochTableTemplate>
          </div>
        </Section>
      </>
    );
  }

  toggleOptionalItemGroup = (event, epochId, encounterId, element) => {
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();

    const prevSt = this.state.epochData;
    const elements = prevSt[epochId].encounters[encounterId].elements;
    const eleIdx = findIndex(elements, o => {
      return o.elementName === element.elementName;
    });
    if (eleIdx > -1) {
      elements[eleIdx].optional = !elements[eleIdx].optional;
    }

    this.setState({ epochData: prevSt });
  };

  toggleOptionalSituationalItemGroup = (event, encounterId, element) => {
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();

    const prevSt = this.state.situationalEncounters;
    const elements = prevSt[encounterId].elements;
    const eleIdx = findIndex(elements, o => {
      return o.itemGroupTemplateVersionId === element.uniqueIdentifier;
    });
    if (eleIdx > -1) {
      elements[eleIdx].optional = !elements[eleIdx].optional;
    }

    this.setState({ situationalEncounters: prevSt });
  };
}

function deleteElementOnIndex(elements, eleIdx) {
  elements.splice(eleIdx, 1);
}

function createNewElement(elements, element, itemGroupSequence) {
  elements.push({
    domainName: element.domainName,
    elementName: element.elementName,
    elementUniqueIdentifier: element.uniqueIdentifier,
    elementIcon: element.elementIcon,
    itemGroupSequence,
    optional: false
  });
}

function createNewSituationalElement(elements, element, itemGroupSequence) {
  elements.push({
    elementName: element.elementName,
    itemGroupType: element.elementIcon,
    itemGroupTemplateVersionId: element.uniqueIdentifier,
    itemGroupTemplateId: element.itemGroupKey,
    sequence: itemGroupSequence,
    optional: false
  });
}

function findElementByName(elements, element) {
  const predicate = e => e.elementName === element.elementName;
  return find(elements, predicate);
}

function findElementById(elements, element) {
  const predicate = e => e.itemGroupTemplateVersionId === element.uniqueIdentifier;
  return find(elements, predicate);
}

function isElementsContains(elements, element, findElement = findElementByName) {
  const elementInElements = findElement(elements, element);
  return !!elementInElements;
}

function checkForElementClass(elements, element, findElement = findElementByName) {
  const existedElement = findElement(elements, element);
  let classNames = '';
  if (!!existedElement) {
    classNames += 'selected-ig align-middle text-center';
    if (existedElement.optional) {
      classNames += ' ';
      classNames += 'optional-ig';
    }
  }
  return classNames;
}

function checkForUnexpectedElementClass(element) {
  let classNames = '';
  if (!!element.useForUnexpected) {
    classNames += 'selected-ig align-middle text-center';
  }
  return classNames;
}

export default withAppInfo(withParams(withNavigate(ProtocolEncounterSetup)));
