import React from 'react';
import { isEmpty } from 'lodash/lang';
import PropTypes from 'prop-types';

import fieldPropTypes from '../fieldPropTypes';

export default function TextBlockField({ field }) {
  return <div className="text-block-field">{field.fieldValue}</div>;
}

TextBlockField.propTypes = {
  field: PropTypes.shape({
    ...fieldPropTypes,
    fieldType: PropTypes.oneOf(['label']).isRequired,
    fieldValue: function(props, propName, componentName) {
      const value = props[propName];
      if (isEmpty(value)) {
        return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed.`);
      }
    }
  })
};
