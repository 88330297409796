import React, { useContext, useMemo } from 'react';
import ReactTable from 'react-table';
import moment from 'moment/moment';

import Icon from '../../../../../../common/general/Icon';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../../constants/dateFormat';
import { Amount } from '../../../shared/amount-view/Amount/Amount';
import { InvoiceContext } from '../../NewInvoiceContext';
import { resolveEventSuffix } from '../CreateInvoiceServices';

import './CreateInvoiceTable.scss';

export const CreateInvoiceTable = ({ invoiceCreated, generateWithholdingPDF, mainLedgerEvents }) => {
  const { createInvoiceItems, setCreateInvoiceItems } = useContext(InvoiceContext);
  const columns = useMemo(() => {
    return [
      {
        Header: 'Event ID',
        id: 'adjustmentVersionSequenceId',
        width: 100,
        sortMethod: (a, b) => {
          if (a.eventNumber === b.eventNumber) {
            return a.adjustmentSequence > b.adjustmentSequence ? 1 : -1;
          } else {
            return a.eventNumber > b.eventNumber ? 1 : -1;
          }
        },
        Cell: row => {
          if (row.original.adjustmentSequence === 0) {
            return resolveEventSuffix(row.original.eventNumber, row.original.invoiceType);
          }
          return resolveEventSuffix(
            `${row.original.eventNumber}.${row.original.adjustmentSequence}`,
            row.original.invoiceType
          );
        }
      },
      {
        Header: 'Event Date',
        accessor: 'eventDate',
        Cell: row => {
          return row.original.eventDate ? moment(row.original.eventDate).format(DD_SLASH_MMM_SLASH_YYYY) : '—';
        },
        width: 100
      },
      {
        Header: 'Subject Id',
        accessor: 'subjectNumber',
        Cell: row => {
          return row.original?.subjectNumber ? row.original?.subjectNumber : '—';
        },
        width: 100
      },
      {
        Header: 'Type',
        accessor: 'budgetEventType',
        width: 100
      },
      {
        Header: 'Event Name',
        accessor: 'name'
      },
      {
        Header: 'Amount',
        className: 'amount-value',
        headerClassName: 'amount-value',
        accessor: 'invoiceAmount',
        Cell: ({ value }) => {
          return <Amount coinsAmount={value} showDollarSign={true} />;
        },
        width: 100
      },
      {
        width: 40,
        resize: false,
        show: !invoiceCreated && !generateWithholdingPDF,
        Cell: ({ original }) => {
          return (
            <Icon
              onClick={() => {
                if (createInvoiceItems?.originalLedgerEvents?.length > 1) {
                  setCreateInvoiceItems(prevState => ({
                    ...prevState,
                    originalLedgerEvents: prevState.originalLedgerEvents.filter(
                      ledgerEvent => ledgerEvent.id !== original.id
                    ),
                    withholdingLedgerEvents: prevState.withholdingLedgerEvents.filter(
                      ledgerEvent => ledgerEvent.id !== original.id
                    ),
                    creditMemoLedgerEvents: prevState.creditMemoLedgerEvents.filter(
                      ledgerEvent => ledgerEvent.eventNumber !== original.eventNumber
                    ),
                    debitMemoLedgerEvents: prevState.debitMemoLedgerEvents.filter(
                      ledgerEvent => ledgerEvent.eventNumber !== original.eventNumber
                    )
                  }));
                }
              }}
            >
              close
            </Icon>
          );
        }
      }
    ];
  }, [createInvoiceItems?.originalLedgerEvents?.length, generateWithholdingPDF, invoiceCreated, setCreateInvoiceItems]);

  return (
    <ReactTable
      data={mainLedgerEvents || []}
      columns={columns}
      minRows={1}
      sortable={false}
      showPagination={false}
      className="eds-create-invoice-table"
      nextText=">>"
      previousText="<<"
      noDataText="No Record Found"
      pageSize={Number.MAX_VALUE}
    />
  );
};
