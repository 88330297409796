import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactTable from 'react-table';
import { ControlledMenu, MenuItem, useMenuState } from '@szhsin/react-menu';
import { isEmpty } from 'lodash/lang';
import moment from 'moment';

import { ItemGroupTemplatesApi } from '../../../../api';
import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../common/general/Button';
import Icon from '../../../../common/general/Icon';
import useSessionStorage from '../../../../common/hooks/useSessionStorage';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import { TEMPLATE_DELETED, TEMPLATE_NOT_DELETED } from '../../../../constants/notificationMessages';
import { pendoTrackDefaultSortingChange } from '../../../../pendo/PendoUtils';
import { generateUrlByKey } from '../../../root/router';
import { customOptionTerminologyVersion } from '../shared/ElementSetupNew/ItemGroupSetup/itemGroupSetupRenderHelpService';

import { FILTERS_KEYS, NAME, TERMINOLOGY_VERSION } from './constants';

const initialMenuSetting = {
  position: {
    x: 0,
    y: 0
  },
  id: null,
  open: false
};
const TEMPLATE_TABLE_DATA = 'TEMPLATE_TABLE_DATA';
export default function TemplatesTable({ filters, templates, getAllTemplates, versions }) {
  const [sessionStorage, setSessionStorage] = useSessionStorage(TEMPLATE_TABLE_DATA, {});

  const [pageSize, setPageSize] = useState(sessionStorage.pageSize || 25);
  const { openMenu, closeMenu, toggleMenu, ...menuProps } = useMenuState();
  const navigate = useNavigate();
  const [menuSetting, setMenuSetting] = useState(initialMenuSetting);
  const filterTemplatesCallback = useCallback(filterTemplates, [filters, templates]);
  const filteredTemplates = useMemo(filterTemplatesCallback, [filterTemplatesCallback]);
  const setClose = useCallback(function() {
    setMenuSetting(initialMenuSetting);
  }, []);

  useEffect(
    function() {
      if (menuSetting) {
        const { open } = menuSetting;
        if (open) {
          openMenu();
        } else {
          closeMenu();
        }
      }
    },
    [closeMenu, menuSetting, openMenu]
  );
  const reactTable = useMemo(
    function() {
      const columns = getColumns(versions, setMenuSetting, menuSetting);
      return (
        <ReactTable
          data={filteredTemplates}
          columns={columns}
          onPageSizeChange={pageSize => {
            setSessionStorage({ pageSize: pageSize });
            setPageSize(pageSize);
          }}
          minRows={1}
          multiSort
          onSortedChange={pendoTrackDefaultSortingChange}
          className="eui-table igt-table"
          showPagination
          nextText=">>"
          previousText="<<"
          noDataText="No Record Found"
          pageSizeOptions={[25, 50, 100]}
          defaultPageSize={pageSize}
          getTrProps={getTrProps}
        />
      );
      function getTrProps(event, details) {
        return {
          onClick: () => {
            const {
              original: { itemGroupTemplateId }
            } = details;
            if (details.original.type === 'New Table') {
              navigate(generateUrlByKey('Item Group Templates.Table Template Edit', { itemGroupTemplateId }));
            } else {
              navigate(generateUrlByKey('Item Group Templates.Template Edit', { itemGroupTemplateId }));
            }
          }
        };
      }
    },
    [filteredTemplates, navigate, menuSetting, pageSize, setSessionStorage, versions]
  );

  const controlledMenu = useMemo(() => {
    return (
      <ControlledMenu
        viewScroll="close"
        position="anchor"
        align="start"
        direction="left"
        {...menuProps}
        anchorPoint={menuSetting.position}
        className="igt-menu"
        onClose={() => setClose()}
      >
        <MenuItem onClick={createCopy}>Create Copy</MenuItem>
        <MenuItem onClick={deleteTemplate}>Delete Template</MenuItem>
      </ControlledMenu>
    );
    function createCopy() {
      if (menuSetting.type === 'New Table') {
        navigate(generateUrlByKey('Item Group Templates.Table Template Copy', { itemGroupTemplateId: menuSetting.id }));
      } else {
        navigate(generateUrlByKey('Item Group Templates.Template Copy', { itemGroupTemplateId: menuSetting.id }));
      }
    }
    function deleteTemplate() {
      ModalBoxes.confirm({
        title: 'Delete Item Group Template',
        content: `Are you sure you want to delete the ${
          templates.find(({ itemGroupTemplateId }) => itemGroupTemplateId === menuSetting.id)?.name
        }  template?`,
        confirmButton: 'Yes',
        cancelButton: 'No'
      }).then(
        function() {
          ItemGroupTemplatesApi.deleteItemGroupTemplate(menuSetting.id)
            .then(function() {
              NotificationManager.success(TEMPLATE_DELETED);
              getAllTemplates();
            })
            .catch(() => NotificationManager.error(TEMPLATE_NOT_DELETED));
        },
        () => {}
      );
    }
  }, [
    menuProps,
    menuSetting.position,
    menuSetting.type,
    menuSetting.id,
    setClose,
    navigate,
    templates,
    getAllTemplates
  ]);

  function filterTemplates() {
    return templates.filter(template => {
      return FILTERS_KEYS.every(key => {
        const filterValue = filters[key];
        const templateValue = template[key];
        if (key === TERMINOLOGY_VERSION) {
          if (!filterValue) {
            return true;
          } else {
            return templateValue === filterValue;
          }
        }
        if (key !== NAME) {
          return isEmpty(filterValue) || templateValue.toUpperCase() === filterValue.toUpperCase();
        } else {
          return (
            isEmpty(filterValue) ||
            templateValue === filterValue ||
            templateValue.toUpperCase().includes(filterValue.toUpperCase())
          );
        }
      });
    });
  }
  return (
    <>
      {reactTable}
      {controlledMenu}
    </>
  );
}
function getColumns(versions, setMenuSetting, menuSetting) {
  return [
    {
      Header: 'Name',
      accessor: 'name'
    },
    {
      Header: 'Domain',
      accessor: 'domainName'
    },
    {
      Header: 'Type',
      accessor: 'type'
    },
    {
      Header: 'Items',
      accessor: 'numberOfItems'
    },
    {
      Header: 'Date edited',
      id: 'lastModifiedOn',
      accessor: ({ lastModifiedOn }) => {
        return moment(lastModifiedOn);
      },
      Cell: ({ value }) => value.format('DD/MMM/YYYY')
    },
    {
      Header: 'Terminology Version',
      accessor: 'terminologyVersionGroupNumber',
      Cell: ({ value }) => {
        const version = versions.find(version => version.number === value);
        return version && customOptionTerminologyVersion(version, { display: 'flex', flexDirection: 'column' });
      }
    },
    {
      Header: 'Last edited by',
      accessor: 'lastModifiedBy'
    },
    {
      Header: '',
      sortable: false,
      accessor: 'itemGroupTemplateId',
      Cell: row => {
        const { value } = row;
        return (
          <Button
            onClick={onClick}
            style={{
              userSelect: 'none',
              pointerEvents: menuSetting.open && value === menuSetting.id ? 'none' : 'inherit'
            }}
            className="igt-button"
            priority="low"
          >
            <Icon style={{ pointerEvents: 'none' }}>more_vert</Icon>
          </Button>
        );
        function onClick(e) {
          e.stopPropagation();
          const boundingClientRect = e.target.getBoundingClientRect();
          setMenuSetting({
            id: value,
            position: { x: boundingClientRect.x + 30, y: boundingClientRect.y + 28 },
            open: true,
            type: row.original.type
          });
        }
      }
    }
  ];
}
