import React, { useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import * as PropTypes from 'prop-types';

import { ReviewContext } from '../../../../ReviewContext';
import { isSmOrPiReview } from '../../../../reviewService';
import * as reviewContentService from '../../../reviewContentService';
import CommentSection from '../../CommentSection/CommentSection';
import { isAnswerEditedAfterSign } from '../ItemGroupNewTableService';

import './CommentItemGroupNewTableContent.scss';

export default function CommentItemGroupNewTableContent({ itemGroupSnapshotState }) {
  const [commentAnswerField, setCommentAnswerField] = useState([]);
  const [commentHighlightStyle, setCommentHighlightStyle] = useState(null);

  const {
    reviewType,
    reviewMetaData,
    isHistoricalData,
    patientItemGroupId: reviewItemGroupId,
    rowReviewStatus,
    updateItemGroupData
  } = useContext(ReviewContext);

  const {
    general: { rowSnapshotFormData, rowSnapshotKey },
    itemGroupTemplateVersion
  } = itemGroupSnapshotState;

  useEffect(() => {
    const answer = getCommentAnswerField(itemGroupTemplateVersion, rowSnapshotFormData);
    setCommentAnswerField(answer);
    setCommentHighlightStyle(
      getFormHighlightStyleBasedOnCommentRowReviewStatusAndReviewType(
        answer,
        itemGroupSnapshotState?.general,
        rowReviewStatus,
        reviewType
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemGroupSnapshotState, itemGroupTemplateVersion, rowSnapshotFormData]);

  return (
    <div className={cx('new-table-comment', commentHighlightStyle)}>
      <CommentSection
        context={{
          patientEncounterId: itemGroupSnapshotState.itemGroupRef.patientEncounterId,
          reviewItemGroupId,
          ssuPatientId: rowSnapshotKey?.rowKey?.ssuPatientId,
          reviewType,
          isHistoricalData,
          studySiteStatus: reviewMetaData.studySiteStatus
        }}
        updateData={updateItemGroupData}
        snapshotData={{
          ...rowSnapshotFormData?.form,
          rowId: rowSnapshotFormData?.rowId,
          rowVersion: rowSnapshotFormData?.rowVersion,
          commentAnswer: commentAnswerField,
          studyId: rowSnapshotKey?.rowKey?.studyId
        }}
        patientItemGroupId={itemGroupSnapshotState.itemGroupRef.patientItemGroupId}
      />
    </div>
  );

  function getCommentAnswerField(itemGroupTemplateVersion, rowSnapshotFormData) {
    const commentQuestionFiled = itemGroupTemplateVersion?.itemGroupVersionQuestions?.find(question => {
      return question.name === 'Comment' && question.label === 'Comment';
    });
    return rowSnapshotFormData?.form?.answers?.find(
      answer => answer?.itemGroupQuestionId === commentQuestionFiled?.questionId
    );
  }

  function getFormHighlightStyleBasedOnCommentRowReviewStatusAndReviewType(
    answer,
    general,
    rowReviewStatus,
    reviewType
  ) {
    if (!isSmOrPiReview(reviewType)) {
      return {};
    }

    if (isAnswerEditedAfterSign(answer, general) || general?.rowReviewStatus === 'NEVER_SIGNED') {
      return 'new-not-signed-data-bg';
    }

    return reviewContentService.getFormHighlightStyleBasedOnRowReviewStatusAndReviewType(rowReviewStatus, reviewType)
      ?.className;
  }
}

CommentItemGroupNewTableContent.propTypes = {
  itemGroupSnapshotState: PropTypes.object
};
