import React, { useEffect } from 'react';
import { isEmpty } from 'lodash/lang';

import Checkbox from '../../../../../../../../common/data-entry/InputSelectors/Checkbox';
import Select from '../../../../../../../../common/data-entry/Select';
import Icon from '../../../../../../../../common/general/Icon';
import StyledTooltip from '../../../../../../../../common/widgets/StyledToolTip';

import './ItemGroupMappingTemplateItem.scss';

const ItemGroupMappingTemplateItem = ({ template, toggleSelected, mappingExistsHighlight }) => {
  useEffect(() => {
    if (!isEmpty(mappingExistsHighlight)) {
      toggleSelected(template.protocolItemIdentifier, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template.selected]);
  return (
    <div className="template-item">
      <Checkbox
        onChange={() => toggleSelected(template.protocolItemIdentifier)}
        checked={template.selected}
        disabled={!isEmpty(mappingExistsHighlight)}
      />
      <Select
        value={{ name: template.name, value: template.name }}
        optionLabelKey="name"
        optionValueKey="value"
        label="IntElligo Field"
        className="preview-input"
        clearable={false}
        disabled={true}
      />
      <Select
        value={{ name: template.mappingKeyValue, value: template.mappingKeyValue }}
        optionLabelKey="name"
        optionValueKey="value"
        label="Mapping Key Value"
        className="preview-input"
        clearable={false}
        disabled={true}
      />
      <Select
        value={{ name: template.mappingType, value: template.mappingType }}
        optionLabelKey="name"
        optionValueKey="value"
        label="Mapping Type"
        className="preview-input"
        clearable={false}
        disabled={true}
      />
      {!isEmpty(mappingExistsHighlight) && (
        <StyledTooltip
          className="tooltip-note-value"
          overlay={
            <div className="mapping-alert-value">
              <span className="alert-header">Mapping for this field already exists</span>
              <span className="alert-value-name">Mapping Key Value: </span>
              <span>{mappingExistsHighlight[0].mappingKeyValue}</span>
            </div>
          }
          placement="top"
        >
          <Icon className="already-exist">warning</Icon>
        </StyledTooltip>
      )}
    </div>
  );
};

export default ItemGroupMappingTemplateItem;
