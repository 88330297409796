import React from 'react';

import Input from '../../../../../../common/data-entry/Input';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import {
  AT_LEAST_ONE_ITEM_FORM_SETUP,
  DUPLICATE_PREDEFINED_COLUMN_VALUES_NOT_ALLOWED,
  FIELD_REQUIRED_FOR_TABLE_TYPE_ERROR,
  SEQUENCE_BLANK_ERROR,
  UNIQUE_SEQUENCE_ERROR
} from '../../../../../../constants/notificationMessages';
import ElementPreviewModal from '../../ItemGroupTemplatePreviewModal/ItemGroupTemplatePreviewModal';
import { checkForDuplicate } from '../itemGroupSetupService';

import { RenderForm } from './RenderForm';

export function ItemGroupPreviewOpen({
  previewDataList,
  displayTableType,
  isLogType,
  elementIcon,
  inputs,
  logCheckTemplate,
  logTypeDataArray,
  isAnyItemQuestionCheck,
  combinedLabelList,
  itemGroupName,
  directions
}) {
  return (
    <div className="row col-12 m-0 p-0 justify-content-between border-bottom-dotted">
      <div className="col-8 p-0">
        <h5 className=" pb-2 px-0 c-p">Item Setup</h5>
      </div>
      <div className="col-auto text-right my-1 add-btn-bg">
        <span onClick={openElementPreviewModal}>
          <span className="float-right c-w cursor-pointer">ItemGroup Preview</span>
          <span className="add-btn">
            <i className="material-icons">add</i>
          </span>
        </span>
      </div>
    </div>
  );
  function openElementPreviewModal() {
    const labelList = combinedLabelList.map(label => {
      if (label.hasOwnProperty('customItemDefinationList')) {
        return label.customItemDefinationList[0];
      } else {
        return label;
      }
    });
    const previewShowObj = {
      ...previewDataList,
      labelList: labelList,
      directions: directions,
      logCheckOptions: logCheckTemplate.rows,
      logCheckInstruction: logCheckTemplate.instruction
    };

    const isSequenceEmpty =
      previewShowObj &&
      previewShowObj.labelList &&
      previewShowObj.labelList.filter(item => {
        return item.sequence === '';
      });
    const valueArr =
      previewShowObj &&
      previewShowObj.labelList &&
      previewShowObj.labelList.map(function(item) {
        return item.sequence;
      });
    const isDuplicate = valueArr.some(function(item, idx) {
      return valueArr.indexOf(item) !== idx;
    });

    isAnyItemQuestionCheck =
      previewShowObj &&
      previewShowObj.labelList &&
      previewShowObj.labelList.filter(item => {
        return item.isChecked && item.isChecked === true;
      });

    logTypeDataArray =
      previewShowObj &&
      previewShowObj.labelList &&
      previewShowObj.labelList.filter(item => {
        return item.isTable && item.isTable === true;
      });
    if (isSequenceEmpty && isSequenceEmpty.length > 0) {
      NotificationManager.error(SEQUENCE_BLANK_ERROR);
    } else if (!isDuplicate) {
      if (isAnyItemQuestionCheck.length > 0) {
        if (isLogType || displayTableType) {
          if (logTypeDataArray.length > 0) {
            if (displayTableType) {
              if (inputs[0] && inputs[0].predefinedColumnName && inputs[0].predefinedColumnValues) {
                const isDuplicatePredefinedValue = checkForDuplicate(inputs[0].predefinedColumnValues);
                if (isDuplicatePredefinedValue) {
                  NotificationManager.error(DUPLICATE_PREDEFINED_COLUMN_VALUES_NOT_ALLOWED);
                } else {
                  goToElementPreviewModal(previewShowObj);
                }
              } else {
                NotificationManager.error(FIELD_REQUIRED_FOR_TABLE_TYPE_ERROR);
              }
            } else {
              goToElementPreviewModal(previewShowObj);
            }
          } else {
            previewShowObj.columnList = previewShowObj.labelList
              .filter(e => !['textBlockLong', 'label'].includes(e.inputType))
              .map(e => e.name);
            goToElementPreviewModal(previewShowObj);
          }
        } else if (elementIcon === 'Table') {
          if (inputs[0] && inputs[0].predefinedColumnName && inputs[0].predefinedColumnValues) {
            goToElementPreviewModal(previewShowObj);
          } else {
            NotificationManager.error(FIELD_REQUIRED_FOR_TABLE_TYPE_ERROR);
          }
        } else {
          goToElementPreviewModal(previewShowObj);
        }
      } else {
        NotificationManager.error(AT_LEAST_ONE_ITEM_FORM_SETUP);
      }
    } else {
      NotificationManager.error(UNIQUE_SEQUENCE_ERROR);
    }
  }
  function goToElementPreviewModal(previewShowObj) {
    ModalBoxes.open({
      component: (
        <ElementPreviewModal
          renderFormData={
            <React.Fragment>
              <div className="d-flex">
                <div className="mr-2">
                  <Input label="Name" name="name" value={itemGroupName} disabled />
                </div>
                <div className="mx-2">
                  <Input label="Domain" name="domain" value={previewShowObj?.domainName} disabled />
                </div>
                <div className="mx-2">
                  <Input label="Type" name="type" value={elementIcon} disabled />
                </div>
              </div>
              <RenderForm
                formData={previewShowObj}
                displayTableType={displayTableType}
                elementIcon={elementIcon}
                inputs={inputs}
                isLogCheckSetupEnabled={isLogType}
              />
            </React.Fragment>
          }
        />
      ),
      title: `Item Group Preview`,
      size: 'w1000'
    });
  }
}
