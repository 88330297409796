import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Section from '../../../../../../common/data-display/Section/Section';
import {
  TableSetupContext,
  withTableSetupContext,
  withTableTemplateContext
} from '../../../../../../contexts/TableSetupContext';
import { generateUrlByKey, useCurrentRoute } from '../../../../../root/router';

import GeneralSettings from './GeneralSettings/GeneralSettings';
import ItemGroupsDomainConfigurationTableSetup from './ItemGroupDomainConfigurationTableSetup/ItemGroupDomainConfigurationTableSetup';
import ItemGroupsDetailsTableSetup from './ItemGroupsDetailsTableSetup/ItemGroupsDetailsTableSetup';
import TableField from './TableField/TableField';
import TablePreview from './TablePreview/TablePreview';
import TableSettings from './TableSettings/TableSettings';
import { TABLE_SETUP } from './TableSetupConstants';

import './TableSetupTemplate.scss';

const TableSetupTemplate = () => {
  const navigate = useNavigate();
  const tableSetupContext = useContext(TableSetupContext);
  const currentRoute = useCurrentRoute();

  const currentRouteParentKey = currentRoute.parent.key;
  const currentRouteParams = currentRoute.params;

  useEffect(() => {
    if (tableSetupContext.selectedType && tableSetupContext.selectedType?.name !== TABLE_SETUP) {
      const itemGroupSetupParameters = {
        selectedDomain: tableSetupContext.selectedDomain,
        elementName: tableSetupContext.itemGroupName,
        selectedType: tableSetupContext.selectedType,
        terminologyVersionGroupNumber: tableSetupContext.selectedTerminologyVersionGroups
      };
      if (tableSetupContext.isTemplateMode) {
        navigate(generateUrlByKey(`${currentRouteParentKey}.Template Setup`, currentRouteParams), {
          state: { itemGroupSetupParameters }
        });
      } else {
        navigate(generateUrlByKey(`${currentRouteParentKey}.Item Group Setup`, currentRouteParams), {
          state: { itemGroupSetupParameters }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableSetupContext.selectedType]);

  return (
    <>
      <ItemGroupsDetailsTableSetup />
      <Section className="new-table">
        <ItemGroupsDomainConfigurationTableSetup />
        {tableSetupContext.selectedType?.name === TABLE_SETUP && (
          <>
            <TablePreview />
            <TableSettings />
            <GeneralSettings />
            <TableField />
          </>
        )}
      </Section>
    </>
  );
};

export default withTableSetupContext(TableSetupTemplate);
export const TableTemplates = withTableTemplateContext(TableSetupTemplate);
