const LIST_REGEX = /^\["(.*)"]/gm;
const UNAVAILABLE = 'UNAVAILABLE';
const NOT_ASKED = 'NOT_ASKED';

export function findQuestionLabel(questionId, itemGroupTemplateVersion) {
  const question = itemGroupTemplateVersion?.itemGroupVersionQuestions?.find(
    question => question?.questionId === questionId
  );
  return {
    label: question?.label || "Question title isn't found",
    questionId: question?.questionId
  };
}

export function sortBySequence(a, b) {
  return a.sequence - b.sequence;
}

export function isAnswerEdited(answer, itemGroupSnapshotStateRows) {
  const editedQuestionIds = itemGroupSnapshotStateRows?.editedQuestionIds;
  return editedQuestionIds?.includes(answer.itemGroupQuestionId);
}

export function isAnswerEditedAfterSign(answer, itemGroupSnapshotStateRows) {
  const editedAfterSignQuestionIds = itemGroupSnapshotStateRows?.editedAfterSignQuestionIds;
  return editedAfterSignQuestionIds?.includes(answer?.itemGroupQuestionId);
}

export function getFieldValue(inputType, stringValue) {
  if ((inputType === 'multiselect' || inputType === 'multiselectCT') && stringValue) {
    return mapListToStringFormat(stringValue);
  }
  return stringValue;
}

function mapListToStringFormat(listValue) {
  if (listValue) {
    return listValue
      ?.replace('[', '')
      ?.replace(']', '')
      ?.replaceAll('"', '');
  }
  return listValue;
}

export function filterMetaDataComment(response) {
  const updatedMetaData = response?.data?.metadata?.filter(item => item?.id !== 'COVAL');
  return {
    ...response,
    data: {
      ...response.data,
      metadata: updatedMetaData
    }
  };
}

export function processMultiselectAnswerValues(response) {
  response?.data?.tableData?.forEach(data => {
    Object.entries(data).forEach(entry => {
      if (LIST_REGEX.exec(entry[1]?.value)) {
        data[entry[0]]['value'] = mapListToStringFormat(entry[1]?.value);
      } else if ([UNAVAILABLE, NOT_ASKED].includes(entry[1]?.performedStatus)) {
        data[entry[0]]['value'] = getValueBasedOnPerformedStatus(entry[1]?.performedStatus, entry[1]?.comment);
      }
    });
  });
  return response;
}

function getValueBasedOnPerformedStatus(performedStatus, comment) {
  const commentToDisplay = comment ? ': ' + comment : '';
  if (performedStatus === NOT_ASKED) {
    return `Not Asked${commentToDisplay}`;
  }
  if (performedStatus === UNAVAILABLE) {
    return `Unavailable${commentToDisplay}`;
  }
  return undefined;
}
