import React, { useCallback, useEffect, useRef, useState } from 'react';
import RelativePortal from 'react-relative-portal';
import cx from 'classnames';

import useOutsideClickDetector from '../../../../common/hooks/useOutsideClickDetector';

import './TaskStatusChangePortal.scss';

export default function TaskStatusChangePortal({ row, taskStatuses, onChangeStatus }) {
  const ref = useRef();
  const [showMenu, setShowMenu] = useState(false);

  const getStatusName = useCallback(
    function() {
      return row.original.status ? taskStatuses.find(e => e.code === row.original.status)?.name : null;
    },
    [row, taskStatuses]
  );

  const [label, setLabel] = useState(getStatusName());

  useEffect(
    function() {
      if (!row.original.allowStatusEdit) {
        setLabel(getStatusName());
      }
    },
    [row, taskStatuses, getStatusName]
  );

  useOutsideClickDetector(ref, () => setShowMenu(false));

  const statusChange = function() {
    if (row.original.allowStatusEdit) {
      setShowMenu(true);
    }
  };

  return (
    <React.Fragment>
      <div className={cx('current-status-cell', { active: row.original.allowStatusEdit })} onClick={statusChange}>
        {label}
      </div>
      {showMenu && (
        <RelativePortal onOutClick={() => setShowMenu(false)}>
          <div className="work-list-current-status-cell-portal" ref={ref}>
            {taskStatuses.map(status => (
              <div
                className={cx('status-el', { active: status.code === row.original.status })}
                key={status.code}
                onClick={() => {
                  onChangeStatus(status, row.original);
                  setLabel(status.name);
                  setShowMenu(false);
                }}
              >
                {status.name}
              </div>
            ))}
          </div>
        </RelativePortal>
      )}
    </React.Fragment>
  );
}
