import React from 'react';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';

export const NumericFormatCustomInput = React.forwardRef(function NumericFormatAdapter(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      getInputRef={ref}
      {...other}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
    />
  );
});

NumericFormatCustomInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
