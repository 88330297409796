import React, { Fragment, useImperativeHandle, useRef, useState } from 'react';
import { omit } from 'lodash/object';

import Icon from '../../general/Icon';

import Input from './Input';
import inputPropTypes from './types';

const _Password = React.forwardRef(function(props, ref) {
  const { iconsAfter } = props;

  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const inputRef = useRef();
  useImperativeHandle(ref, () => inputRef.current);

  const restProps = omit(props, 'type', 'iconsAfter');

  return (
    <Input
      ref={inputRef}
      type={passwordIsVisible ? 'text' : 'password'}
      iconsAfter={
        <Fragment>
          {iconsAfter}
          <Icon type="input-button" onClick={() => setReactInputValue(inputRef.current, '')}>
            cancel
          </Icon>
          <Icon type="input-button" onClick={togglePasswordVisibility}>
            {passwordIsVisible ? 'visibility_off' : 'visibility'}
          </Icon>
        </Fragment>
      }
      {...restProps}
    />
  );
  function togglePasswordVisibility() {
    setPasswordIsVisible(state => !state);
  }
});

function setReactInputValue(input, value) {
  const previousValue = input.value;
  input.value = value;
  const tracker = input._valueTracker;
  if (tracker) {
    tracker.setValue(previousValue);
  }
  input.dispatchEvent(new Event('change', { bubbles: true }));
}

_Password.propTypes = inputPropTypes;

export default _Password;
