import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import IconButton from '../../../../../../../../../common/buttons/IconButton';
import ModalBoxes from '../../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { TableSetupContext } from '../../../../../../../../../contexts/TableSetupContext';
import EditControlledTermListModal from '../EditControlledTermListModal/EditControlledTermListModal';
import EditCustomOptionListModal from '../EditCustomOptionListModal/EditCustomOptionListModal';

const EditFormAnswer = ({ itemIndex, inputType, isExtensible, general }) => {
  const tableSetupContext = useContext(TableSetupContext);
  const params = useParams();

  const editCustomOptionList = () => {
    ModalBoxes.open({
      component: (
        <EditCustomOptionListModal
          itemIndex={itemIndex}
          inputType={inputType}
          general={general}
          updateCustomOptionsList={tableSetupContext.updateCustomOptionsList}
          initialOptionsList={
            general
              ? tableSetupContext.generalFields[itemIndex].codeDefinationList
              : tableSetupContext.fieldList[itemIndex].codeDefinationList
          }
        />
      )
    });
  };

  const editControlledTermList = () => {
    ModalBoxes.open({
      component: (
        <EditControlledTermListModal
          itemIndex={itemIndex}
          inputType={inputType}
          general={general}
          initialControlledTermList={
            general
              ? tableSetupContext.generalFields[itemIndex].codeDefinationList
              : tableSetupContext.fieldList[itemIndex].codeDefinationList
          }
          protocolId={params.protocolIdentity}
          terminologyVersionId={tableSetupContext.selectedTerminologyVersionGroups}
          isTemplateMode={tableSetupContext.isTemplateMode}
          initialControlledListName={
            general
              ? tableSetupContext.generalFields[itemIndex].ctListName
              : tableSetupContext.fieldList[itemIndex].ctListName
          }
          updateControlledListAndName={tableSetupContext.updateControlledListAndName}
        />
      )
    });
  };

  switch (isExtensible) {
    case 1:
      return (
        <IconButton
          className="glyphicon-edit"
          title="Edit Controlled Term List"
          suit="glyphicon"
          color="blue"
          onClick={editControlledTermList}
        />
      );
    case 2:
      return (
        <IconButton
          className="glyphicon-cog"
          title="Custom Options List"
          suit="glyphicon"
          color="blue"
          onClick={editCustomOptionList}
        />
      );
    case 3:
      return <IconButton className="glyphicon-check" title="Subset Options List" suit="glyphicon" color="blue" />;
    default:
      return null;
  }
};

export default EditFormAnswer;
