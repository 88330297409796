import { useMemo, useState } from 'react';
import moment from 'moment-timezone';

import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../../../../constants/dateFormat';
import { toBill } from '../../../../../../../../services/financial';
import { reimbursementStatusesMap } from '../reimbursementConstants';

const encounterStatusMap = {
  OPEN: 'Open',
  SM_REVIEW: 'SM Review',
  PI_REVIEW: 'Investigator Review',
  COMPLETE: 'Complete',
  CANCELED: 'Canceled'
};

export default function useReimbursementGridData() {
  const [data, setData] = useState([]);
  const columns = useMemo(
    () => [
      {
        field: 'eventNumber',
        headerName: 'EventID',
        flex: 1
      },
      {
        field: 'submittedAt',
        headerName: 'Submission date',
        flex: 1,
        valueGetter({ value }) {
          return value ? moment(value).toDate() : null;
        },
        renderCell({ value }) {
          return value ? moment(value).format(DD_SLASH_MMM_SLASH_YYYY) : '';
        },
        type: 'date',
        align: 'center'
      },
      {
        field: 'submittedByName',
        headerName: 'Submitted By',
        flex: 1,
        renderCell({ value }) {
          return value ? value : '';
        }
      },
      {
        field: 'encounterDate',
        headerName: 'Encounter date',
        flex: 1,
        align: 'center',
        valueGetter({ value }) {
          return value ? moment(value).toDate() : null;
        },
        renderCell({ value, row }) {
          return value ? moment(value).format(DD_SLASH_MMM_SLASH_YYYY) : '';
        },
        type: 'date'
      },
      {
        field: 'encounterName',
        headerName: 'Encounter',
        flex: 1
      },
      {
        field: 'encounterStatus',
        headerName: 'Encounter Status',
        flex: 1,
        valueGetter({ value }) {
          return encounterStatusMap[value] || value;
        }
      },
      {
        field: 'amount',
        headerName: 'Amount',
        flex: 1,
        type: 'number',
        maxWidth: 100,
        valueGetter({ value }) {
          return toBill(value);
        },
        renderCell({ value }) {
          return `$${value}`;
        }
      },
      {
        field: 'numberOfItems',
        headerName: '# of items',
        flex: 1,
        type: 'number',
        maxWidth: 100,
        align: 'center'
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        minWidth: 180,
        valueGetter({ value }) {
          return reimbursementStatusesMap[value] || value;
        }
      }
    ],
    []
  );

  return [columns, data, setData];
}
