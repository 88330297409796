import { COMPLETE, OPEN, PI_REVIEW } from '../shared/Worklist/constants';

export const REVIEW_REQUIRED = 'REVIEW_REQUIRED';
export const DATA_REQUIRED = 'DATA_REQUIRED';
export const CANCELED = 'CANCELED';
export const WORKLIST_NAME = 'Investigator Worklist';

export const investigatorStatusesProvider = [
  { id: REVIEW_REQUIRED, name: 'Review Required', value: PI_REVIEW },
  { id: DATA_REQUIRED, name: 'Data Required', value: [OPEN] },
  { id: COMPLETE, name: 'Complete', value: COMPLETE }
];

export const investigatorAdverseEventsStatusesProvider = [
  { id: REVIEW_REQUIRED, name: 'Review Required', value: REVIEW_REQUIRED },
  { id: DATA_REQUIRED, name: 'Data Required', value: DATA_REQUIRED },
  { id: COMPLETE, name: 'Complete', value: COMPLETE },
  { id: CANCELED, name: 'Canceled', value: CANCELED }
];

export const colors = {
  DATA_REQUIRED: '#2D9CDB',
  REVIEW_REQUIRED: '#6523BB',
  COMPLETE: '#27AE60',
  CANCELED: '#6E6E6E'
};

export const sortingPath = {
  taskAge: ['taskAge'],
  highlight: ['highlight'],
  patientName: ['patientFirstName', 'patientLastName'],
  subjectId: ['subjectId'],
  studyName: ['studyName'],
  siteName: ['siteName'],
  encounterName: ['encounterName'],
  date: ['filterDate'],
  adverseEventName: ['adverseEventName']
};

export const INVESTIGATOR_WORKLIST_SORTED_BY = { id: 'patientName', desc: true };
export const INVESTIGATOR_WORKLIST_PAGE = 0;
export const INVESTIGATOR_WORKLIST_PAGE_SIZE = 25;

export const defaultInvestigatorFilters = {
  page: INVESTIGATOR_WORKLIST_PAGE,
  pageSize: INVESTIGATOR_WORKLIST_PAGE_SIZE,
  sorted: [INVESTIGATOR_WORKLIST_SORTED_BY],
  selectedStatuses: investigatorStatusesProvider.filter(({ id }) => id !== 'COMPLETE'),
  selectedEncounters: [],
  searchString: ''
};
