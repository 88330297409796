import React from 'react';
import cx from 'classnames';

function TextArea({ onChange, name, value, className, id, label, validationMessage }) {
  return (
    <div className={cx('form-group', className)}>
      <label htmlFor={id}>{label}</label>
      <textarea
        id={id}
        className="form-control"
        value={value || ''}
        name={name}
        onChange={event => onChange(event.target.value)}
      />
      <span className="text-danger">{validationMessage}</span>
    </div>
  );
}

export default TextArea;
