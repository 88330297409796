import React from 'react';
import { connect } from 'react-redux';
import { isEmpty, isEqual } from 'lodash/lang';
import moment from 'moment';

import { EventFinderApi } from '../../../api';

export default function WithMilestoneEvents(WrappedComponent) {
  class WithMilestoneEvents extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        datesWithLabel: [],
        currentMonth: moment(),
        milestoneTypes: null,
        eventStatuses: null,
        calendars: null
      };
    }
    loadMilestones = date => {
      const calendarRequest = this.createCalendarRequest(date);
      if (!isEmpty(calendarRequest.calendars)) {
        EventFinderApi.getMilestonesByFilterParams(calendarRequest).then(({ data: calendars }) => {
          this.setState({
            datesWithLabel: getMilestoneDates(calendars),
            currentMonth: date
          });
        });
      }
    };

    createCalendarRequest = currentMonth => {
      return {
        calendars: this.state.calendars ? this.state.calendars.filter(calendar => calendar.type !== 'USER') : [],
        startDate: currentMonth.clone().startOf('Y'),
        endDate: currentMonth.clone().endOf('Y'),
        milestoneTypes: this.state.milestoneTypes,
        eventStatuses: this.state.eventStatuses
      };
    };

    componentDidMount() {
      this.loadMilestones(moment());
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (!this.props.initialDate.isSame(nextProps.initialDate, 'year')) {
        this.loadMilestones(nextProps.initialDate);
      }
      if (
        !isEqual(this.state.milestoneTypes, nextProps.milestoneTypes) ||
        !isEqual(this.state.eventStatuses, nextProps.eventStatuses) ||
        !isEqual(this.state.calendars, nextProps.calendars)
      ) {
        this.setState(
          {
            milestoneTypes: nextProps.milestoneTypes,
            eventStatuses: nextProps.eventStatuses,
            calendars: nextProps.calendars
          },
          this.filterMilestones
        );
      }
    }

    filterMilestones = () => {
      if (this.state?.milestoneTypes && this.state?.eventStatuses && this.state.calendars) {
        const calendarRequest = this.createCalendarRequest(this.state.currentMonth);
        EventFinderApi.getMilestonesByFilterParams(calendarRequest).then(({ data: calendars }) => {
          this.setState({
            datesWithLabel: getMilestoneDates(calendars)
          });
        });
      }
    };

    onMonthChange = date => {
      if (!this.state.currentMonth.isSame(date, 'year')) {
        this.loadMilestones(date);
      }
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          datesWithLabel={this.state.datesWithLabel}
          onMonthChange={this.onMonthChange}
        />
      );
    }
  }
  const mapStateToProps = state => ({ currentUser: state.currentUser });
  return connect(mapStateToProps)(WithMilestoneEvents);
}

function getMilestoneDates(calendars) {
  return calendars.flatMap(e => e.events).map(e => e.milestoneDate);
}
