import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactTable from 'react-table';
import cx from 'classnames';
import { isArray, isFunction, isUndefined } from 'lodash/lang';
import { sum } from 'lodash/math';

import Icon from '../../../../../common/general/Icon';
import { pendoTrackDefaultSortingChange } from '../../../../../pendo/PendoUtils';

import { CustomCellHeader, defaultCellWidth } from './LogsTableService';

import './LogsTable.scss';

const buttonsWidth = 50;
const buttonSpace = {
  Header: '',
  width: 26,
  minWidth: 26,
  fixed: true
};
const responsiveBreakpointWidth = 1000;
export default function LogsTable({
  columns: columnList,
  data,
  defaultCellWidth,
  carouselPage,
  onCarouselPageChange,
  customTitle,
  ...otherProps
}) {
  const [columnsCount, setColumnsCount] = useState(0);
  const [page, setPage] = useState(carouselPage || 0);
  const [fixedColumnsWidth, setFixedColumnsWidth] = useState(0);

  useEffect(
    function() {
      setPage(carouselPage);
    },
    [carouselPage]
  );

  const ref = React.useRef();

  const getHideFixedColumns = () => {
    return !ref?.current?.offsetWidth || ref.current.offsetWidth <= responsiveBreakpointWidth;
  };

  const columns = useMemo(() => {
    return [
      ...columnList
        .filter(e => !!e)
        .filter(e => isUndefined(e.show) || e.show)
        .map(e => {
          const a = { ...e };
          a.fixed = a.fixed && !getHideFixedColumns() ? a.fixed : false;
          a.minWidth = a.fixed && !getHideFixedColumns() && a.minWidth ? a.minWidth : defaultCellWidth;
          if (a.fixed && !getHideFixedColumns()) {
            a.width = a.minWidth;
          }
          if (typeof a.Header === 'string') {
            const showSorting = a.hasOwnProperty('sortable')
              ? !!(otherProps.sortable && a.sortable)
              : otherProps.sortable;
            a.Header = (
              <CustomCellHeader title={a.Header} showSorting={showSorting} investigatorField={a.investigatorField} />
            );
          }
          return a;
        })
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnList, defaultCellWidth, otherProps.sortable, ref?.current?.offsetWidth]);

  const updateRef = useCallback(() => {
    if (ref && ref?.current?.offsetWidth) {
      let fixedColumnsWidth;
      let otherColumnsCount;
      if (getHideFixedColumns()) {
        fixedColumnsWidth = 1;
        otherColumnsCount = [...columns]?.length;
      } else {
        fixedColumnsWidth = sum([...columns.filter(e => e.fixed)].map(e => e.minWidth)) + 2;
        otherColumnsCount = [...columns].filter(e => !e.fixed)?.length;
      }
      let newMaxCount = Math.floor((ref.current.offsetWidth - buttonsWidth - fixedColumnsWidth) / defaultCellWidth);
      if (newMaxCount > otherColumnsCount) {
        newMaxCount = otherColumnsCount;
      }

      const newMaxPage = Math.ceil(otherColumnsCount / newMaxCount);

      let newPage = page > newMaxPage - 1 ? newMaxPage - 1 : page;
      newPage = newPage > 0 ? newPage : 0;
      if (newPage !== page) {
        setPage(newPage);
        isFunction(onCarouselPageChange) && onCarouselPageChange(newPage);
      }
      setColumnsCount(newMaxCount);
      setFixedColumnsWidth(fixedColumnsWidth);
    }
  }, [columns, onCarouselPageChange, page, defaultCellWidth]);

  const update = useCallback(() => {
    updateRef();
  }, [updateRef]);

  useEffect(
    function() {
      window.addEventListener('resize', update);
      return () => {
        window.removeEventListener('resize', update);
      };
    },
    [updateRef, update]
  );

  useEffect(
    function() {
      updateRef();
    },
    [ref?.current?.offsetWidth, updateRef]
  );

  let fixedColumns;
  if (getHideFixedColumns()) {
    fixedColumns = [];
  } else {
    fixedColumns = columns.filter(e => e.fixed);
  }

  let otherColumns;
  if (getHideFixedColumns()) {
    otherColumns = columns;
  } else {
    otherColumns = columns.filter(e => !e.fixed);
  }

  function goLeft() {
    if (page > 0) {
      const newPage = page - 1;
      setPage(newPage);
      isFunction(onCarouselPageChange) && onCarouselPageChange(newPage);
    }
  }

  function goRight() {
    if (page + 1 < Math.ceil(otherColumns?.length / columnsCount)) {
      const newPage = page + 1;
      setPage(newPage);
      isFunction(onCarouselPageChange) && carouselPage !== newPage && onCarouselPageChange(newPage);
    }
  }

  let start = columnsCount * page;
  let end = columnsCount * page + columnsCount;

  if (page + 1 === Math.ceil(otherColumns?.length / columnsCount)) {
    start = otherColumns?.length - columnsCount;
    end = otherColumns?.length;
  }
  otherColumns = otherColumns.map((value, index) => {
    if (index >= start && index < end) {
      return { ...value, show: true };
    } else {
      return { ...value, show: false };
    }
  });

  return (
    <div className={'logs-table'}>
      <div className={'column-pages'}>
        <div className={'custom-title'}>{customTitle}</div>
        {isArray(otherColumns) &&
          columnsCount > 0 &&
          otherColumns.length > columnsCount &&
          Array.apply({}, Array(Math.ceil(otherColumns.length / columnsCount))).map((e, index) => (
            <div
              onClick={() => {
                setPage(index);
                isFunction(onCarouselPageChange) && carouselPage !== index && onCarouselPageChange(index);
              }}
              key={index}
            >
              <div className={cx('column-page', { active: index === page })} />
            </div>
          ))}
      </div>

      <div
        style={{ position: 'relative' }}
        className={cx('', { 'with-pagination': otherProps.showPagination })}
        ref={ref}
      >
        <ReactTable
          data={data || []}
          columns={[...fixedColumns, buttonSpace, ...otherColumns]}
          minRows={1}
          onSortedChange={e => {
            pendoTrackDefaultSortingChange(e);
          }}
          noDataText="There is no data to display."
          resizable={false}
          defaultPageSize={10}
          nextText={<Icon>chevron_right</Icon>}
          previousText={<Icon>chevron_left</Icon>}
          {...otherProps}
        />
        <div
          className={cx('nav-button', { 'with-pagination': otherProps.showPagination })}
          style={{ left: `${fixedColumnsWidth}px` }}
          onClick={goLeft}
          aria-disabled={otherColumns.length === columnsCount}
        >
          <div className={cx('', { 'shadow-left': page > 0 })}>
            <Icon>chevron_left</Icon>
          </div>
        </div>
        <div
          className={cx('nav-button', { 'with-pagination': otherProps.showPagination })}
          style={{ right: '1px' }}
          onClick={goRight}
          aria-disabled={otherColumns.length === columnsCount}
        >
          <div
            className={cx('', {
              'shadow-right': page !== Math.ceil(otherColumns?.length / columnsCount) - 1
            })}
          >
            <Icon>chevron_right</Icon>
          </div>
        </div>
      </div>
    </div>
  );
}

LogsTable.defaultProps = {
  showPagination: false,
  defaultCellWidth: defaultCellWidth
};
