import { useMemo } from 'react';
import { uniq } from 'lodash/array';
import { isEmpty } from 'lodash/lang';

import {
  ROLE_CLINICAL_QUALITY_COORDINATOR,
  ROLE_OPERATIONS_ANALYST,
  ROLE_PATIENT_LIAISON
} from '../../../../../../constants/userRoles';
import { rolesAccessToPageMap } from '../../../CurrentUserContainer';

export default function useFilteredAssignees(allAssignees, access, matchedSsuIds) {
  return useMemo(
    function() {
      return filterAssigneesBySsuIdsIfPresent(filterAssigneesByAccess(allAssignees, access), matchedSsuIds);
    },
    [allAssignees, access, matchedSsuIds]
  );
}

function filterAssigneesByAccess(assignees, access) {
  const allowedRoles = uniq(access?.flatMap(a => rolesAccessToPageMap[a]));
  return assignees?.filter(({ roles }) => roles.some(({ role }) => allowedRoles.includes(role)));
}

function filterAssigneesBySsuIdsIfPresent(assignees, matchedSsuIds) {
  if (isEmpty(matchedSsuIds)) return assignees;
  return assignees?.filter(function({ ssuIds, roles }) {
    const skipByRole = roles.some(({ role }) =>
      [ROLE_CLINICAL_QUALITY_COORDINATOR, ROLE_OPERATIONS_ANALYST, ROLE_PATIENT_LIAISON].includes(role)
    );
    return skipByRole || ssuIds.includes(null) || matchedSsuIds.some(id => ssuIds.includes(id));
  });
}
