import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { isArray } from 'lodash/lang';
import moment from 'moment';

import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import LogsTable from '../../../../Patients/Logs/LogsTable';
import { CustomCell, defaultCellWidth } from '../../../../Patients/Logs/LogsTableService';

import './ElementHistoryLogModal.scss';

export default function ElementHistoryLogModal({ modalBox, itemGroupName, onLoadData }) {
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(loadData, []);

  if (columns && data) {
    columns.forEach(column => {
      if (data?.some(ans => ans[column.id]?.comment?.length > 15 || ans[column.id]?.value?.length > 15)) {
        column.className = 'text-left';
      }
    });
  }

  return (
    <>
      <ModalBoxes.Body>
        {columns.length > 0 && (
          <LogsTable
            columns={columns}
            data={data}
            sortable={false}
            showPagination={false}
            defaultCellWidth={defaultCellWidth}
          />
        )}
      </ModalBoxes.Body>

      <ModalBoxes.Footer>
        <Button priority="medium" onClick={modalBox.close}>
          Close
        </Button>
      </ModalBoxes.Footer>
    </>
  );

  function loadData() {
    onLoadData().then(({ data: { metadata, tableData } }) => {
      setColumns(getColumns(metadata));
      setData(tableData);
    });
  }

  function getColumns(metaData) {
    return metaData.map(columnInfo => {
      return {
        ...columnInfo,
        Header: columnInfo.header,
        minWidth: columnInfo.id === 'Last Updated Date' ? 182 : 100,
        getProps: (state, rowInfo, column) => {
          const isColumnChanged = rowInfo?.original?.[column.id]?.changed;
          return {
            className: cx('history-log-cell', { 'eds-review-history-changed-cell': isColumnChanged })
          };
        },
        Cell: ({ original, column }) => {
          if (original[column.id]) {
            const { value, changed, reasonForDataChange, performedStatus, comment } = original[column.id];
            let slittedValue = value ? value : '';
            if (column.id === 'Last Updated Date') {
              slittedValue = moment(slittedValue).format('DD-MMM-YYYY h:mm:ss a');
            } else if (column.id === 'When') {
              slittedValue = moment(slittedValue).format('DD/MMM/YYYY');
            }
            if (isArray(slittedValue)) {
              slittedValue = slittedValue.join(' ');
            }
            return (
              <React.Fragment>
                {slittedValue === 'Unavailable' && !reasonForDataChange ? (
                  <div>{''}</div>
                ) : (
                  <>
                    {slittedValue && (
                      <CustomCell shortAnswer={slittedValue} longAnswer={slittedValue} className={'text-left'} />
                    )}
                    {reasonForDataChange && changed && (
                      <CustomCell shortAnswer={reasonForDataChange} longAnswer={reasonForDataChange} />
                    )}
                    {!slittedValue && performedStatus && comment && (
                      <CustomCell shortAnswer={performedStatus} longAnswer={`${performedStatus}: ${comment}`} />
                    )}
                  </>
                )}
              </React.Fragment>
            );
          } else {
            return <div>Field not in template</div>;
          }
        }
      };
    });
  }
}

ElementHistoryLogModal.className = 'element-history-log-modal';
ElementHistoryLogModal.size = 'w1250';
