import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { reject } from 'lodash/collection';

import { STDT_TRAVEL, STTG_E_SOURCE } from '../../../../../../../constants/study/studyTypes';

export default function CheckboxSelect({ group, selectedTypeIds, setSelectedTypeIds, studyType }) {
  const { typeParent, types } = group;
  return (
    <FormGroup name={typeParent + '_LABEL_ID'}>
      {types
        ?.filter(e => (studyType === STTG_E_SOURCE ? e.name !== STDT_TRAVEL : true))
        .map(({ id, name }) => {
          return (
            <FormControlLabel
              key={id}
              control={
                <Checkbox
                  checked={selectedTypeIds.includes(id)}
                  onChange={() =>
                    setSelectedTypeIds(function(prevIds) {
                      return prevIds.includes(id) ? reject(prevIds, pId => pId === id) : [...prevIds, id].sort();
                    })
                  }
                />
              }
              label={name}
              sx={{ m: 0 }}
            />
          );
        })}
    </FormGroup>
  );
}
