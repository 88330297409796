import React, { useContext, useMemo } from 'react';
import cx from 'classnames';
import { isEmpty, isObject } from 'lodash/lang';
import { get } from 'lodash/object';
import moment from 'moment';

import { ButtonWithIcon } from '../../../../../common/buttons';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import Icon from '../../../../../common/general/Icon';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../constants/dateFormat';
import { CLOSED } from '../../../../../constants/ssuStatuses';
import { scBlue100, scYellow10 } from '../../../../../constants/systemColors';
import { MANAGE_LOG_DATA, SIGN_ADVERSE_EVENT_LOGS_GROUP } from '../../../../../constants/userOperations';
import {
  ROLE_CLINICAL_QUALITY_COORDINATOR,
  ROLE_CRA,
  ROLE_EXTERNAL_AUDITOR,
  ROLE_OPERATION_MANAGER,
  ROLE_OPERATIONS_ANALYST,
  ROLE_PRINCIPAL_INVESTIGATOR,
  ROLE_STUDY_MANAGER,
  ROLE_SYSTEM_ADMINISTRATOR
} from '../../../../../constants/userRoles';
import { pendoTrackDefaultSortingChange } from '../../../../../pendo/PendoUtils';
import { userHasAccessTo, userHasRole } from '../../../../../services/auth';
import { useCurrentUser } from '../../../../root/Container/CurrentUserContainer';
import { useCurrentRoute } from '../../../../root/router';
import EncounterPatientInfo from '../../shared/EncounterDescNew/EncounterPatientInfo/EncounterPatientInfo';
import { LogCheckNew } from '../../shared/EncounterDescNew/LogCheck/LogCheckNew';
import InvestigatorFieldsSignatureModal from '../../shared/Review/InvestigatorFieldsSignature/InvestigatorFieldsSignatureModal';

import AELogsHistoryPage from './AELogsHistoryPage';
import { DirectionSection } from './DirectionSection';
import { LogsContext, WithLogsPageContext } from './LogsPageContext';
import LogsTable from './LogsTable';
import { defaultCellWidth, getColumns } from './LogsTableService';

import './LogsPage.scss';

function LogsPage({ inheritedComponent }) {
  const currentRoute = useCurrentRoute();
  const currentUserRole = useCurrentUser();
  const userCraOrAuditor = [ROLE_CRA, ROLE_EXTERNAL_AUDITOR].includes(currentUserRole.activeRole);
  const {
    studySiteStatus,
    isInActiveProtocol,
    itemGroupData,
    showLogCheck,
    patientInfo,
    logCheck,
    addNewLog,
    logCheckAnswers,
    currentItemGroupName,
    showEncounter,
    currentStudySiteId,
    itemGroupDomainCode,
    onEditRow,
    onEditComment,
    longAnswers,
    signAll,
    sorted,
    setSorted,
    page,
    setPage,
    pageSize,
    setPageSize,
    historyForRow,
    setHistoryForRow,
    groupAssigns,
    directions
  } = useContext(LogsContext);

  const showSignButton = useMemo(() => {
    return (
      userHasAccessTo(SIGN_ADVERSE_EVENT_LOGS_GROUP) &&
      currentRoute.key.includes('Investigator Worklist.Adverse Event Log Review')
    );
  }, [currentRoute]);

  const isAllowedToManageRows = useMemo(() => {
    return !(
      currentRoute.key.includes('Investigator Review.Adverse Event Log') ||
      currentRoute.key.includes('SM Review.Adverse Event Log')
    );
  }, [currentRoute]);

  const isAllowedToManageLogs = useMemo(() => {
    if (currentRoute.key.includes('Patient Studies.Encounter Details.Logs')) {
      return false;
    }
    if (currentRoute.key.includes('Investigator Review.Adverse Event Log')) {
      return false;
    }
    if (currentRoute.key.includes('SM Review.Adverse Event Log')) {
      return false;
    }
    if (currentRoute.key.includes('Investigator Worklist.Adverse Event Log Review')) {
      return false;
    }
    if (userHasRole(ROLE_SYSTEM_ADMINISTRATOR)) {
      return true;
    }
    if (studySiteStatus === CLOSED) {
      return false;
    }
    if (currentRoute.key.includes('Patient Studies.Encounter Details.Logs')) {
      return userHasAccessTo(MANAGE_LOG_DATA);
    }

    return isInActiveProtocol && userHasAccessTo(MANAGE_LOG_DATA);
  }, [currentRoute, isInActiveProtocol, studySiteStatus]);

  const columns = useMemo(() => {
    return getColumns(itemGroupData?.columnList, showEncounter, itemGroupDomainCode);
  }, [itemGroupData?.columnList, itemGroupDomainCode, showEncounter]);

  const openSignAllReasonModal = () => {
    ModalBoxes.open({
      component: (
        <InvestigatorFieldsSignatureModal
          customSignFunction={(reason, token) => signAll(reason, token)}
          groupAssignList={groupAssigns}
          customTitle={'I reviewed and approve '
            .concat(itemGroupData?.tableData.filter(row => row.aeStatuses[row.rowId] === 'PI_REVIEW').length)
            .concat(' log entries on ')
            .concat(moment().format(DD_SLASH_MMM_SLASH_YYYY))}
        />
      ),
      className: 'eds-investigator-fields-sign-modal-box'
    });
  };
  const filteredOnlyReviewLogRows = useMemo(() => {
    if (!currentRoute.key.includes('Investigator Worklist.Adverse Event Log Review')) {
      return itemGroupData?.tableData;
    }
    return itemGroupData?.tableData?.filter(row => row.aeStatuses[row.rowId] === 'PI_REVIEW') || [];
  }, [itemGroupData?.tableData, currentRoute]);

  const isBulkSign = useMemo(() => {
    return (
      !isEmpty(filteredOnlyReviewLogRows) &&
      currentRoute.key.includes('Investigator Worklist.Adverse Event Log Review') &&
      filteredOnlyReviewLogRows.length > 1
    );
  }, [currentRoute, filteredOnlyReviewLogRows]);

  const historyColumn = {
    Header: 'History',
    fixed: false,
    sortable: false,
    id: 'History',
    show:
      itemGroupDomainCode === 'AE' &&
      (userHasRole(ROLE_SYSTEM_ADMINISTRATOR) ||
        userHasRole(ROLE_STUDY_MANAGER) ||
        userHasRole(ROLE_PRINCIPAL_INVESTIGATOR) ||
        userHasRole(ROLE_CLINICAL_QUALITY_COORDINATOR) ||
        userHasRole(ROLE_OPERATIONS_ANALYST) ||
        userHasRole(ROLE_OPERATION_MANAGER)),
    minWidth: 70,
    width: 70,
    Cell: row => {
      return (
        <Icon
          onClick={() => setHistoryForRow(row.original)}
          style={{ fontSize: '25px', color: scBlue100, cursor: 'pointer' }}
        >
          history
        </Icon>
      );
    }
  };
  return (
    <div className={cx('logs-page', { 'full-screen-layout': !showLogCheck })}>
      {patientInfo && !inheritedComponent && (
        <EncounterPatientInfo
          infoLabel={currentItemGroupName}
          withLabel={currentRoute.name !== 'Adverse Event Log Review'}
        />
      )}
      {historyForRow && (
        <section className="py-3 px-2 content-section mb-3">
          <AELogsHistoryPage />
        </section>
      )}
      <DirectionSection
        directions={directions}
        isLogsPage={currentRoute.key.includes('Patient Studies.Logs')}
        isCraOrAuditor={userCraOrAuditor}
      />
      {!historyForRow && showLogCheck && (
        <section className="log-check">
          {logCheck && logCheck.rows && (
            <LogCheckNew
              logCheckOptions={get(logCheck, 'rows') || []}
              logCheckAnswers={logCheckAnswers}
              addNewLog={addNewLog}
              studySiteId={currentStudySiteId}
              itemGroupDomainCode={itemGroupDomainCode}
            />
          )}
        </section>
      )}
      {!historyForRow && (
        <section className="p-3 content-section mb-3">
          {itemGroupDomainCode === 'AE' && currentRoute.name === 'Adverse Event Log Review' && (
            <div className="log-item-group-name-status">
              <h4 className={'item-group-name review'}>{currentItemGroupName}</h4>
            </div>
          )}
          {isAllowedToManageLogs && (
            <div>
              {itemGroupDomainCode !== 'AE' && <ButtonWithIcon onClick={addNewLog}>Add</ButtonWithIcon>}
              {itemGroupDomainCode === 'AE' && (
                <Button size={'h28'} className={'mr-2 mb-2'} onClick={addNewLog}>
                  AE Reported
                </Button>
              )}
            </div>
          )}
          {!isEmpty(itemGroupData) && (
            <LogsTable
              columns={[historyColumn, ...columns]}
              data={filteredOnlyReviewLogRows}
              onEditRow={onEditRow}
              longAnswers={longAnswers}
              onPageSizeChange={setPageSize}
              onPageChange={setPage}
              onSortedChange={sorted => {
                pendoTrackDefaultSortingChange(sorted);
                setSorted(sorted);
              }}
              pageSize={pageSize}
              page={page}
              defaultSorted={sorted}
              getTdProps={(state, rowInfo, column) => {
                let props = {};
                const columnId = column.id;
                if (
                  rowInfo &&
                  (rowInfo.row[columnId]?.question === 'Comment' ||
                    rowInfo.row[columnId]?.itemGroupQuestionId === 'COVAL')
                ) {
                  let commentValue = get(rowInfo, `row[${columnId}].answer`, '');
                  props.onClick = event => {
                    onEditComment(rowInfo, commentValue, event);
                  };
                }
                if (rowInfo && columnId !== 'Comment' && columnId !== 'History') {
                  props.onClick = () => isAllowedToManageRows && onEditRow(rowInfo.original);
                }
                if (
                  rowInfo &&
                  isObject(rowInfo.row) &&
                  rowInfo?.row[columnId]?.answer?.length > 1 &&
                  rowInfo?.row[columnId]?.attributeType !== 'file' &&
                  longAnswers.includes(rowInfo.row[columnId]?.name)
                ) {
                  props = { ...props, className: 'text-left' };
                }
                if (rowInfo?.original?.changedAnswers && rowInfo.original.changedAnswers.length) {
                  const column = rowInfo.row[columnId];
                  if (rowInfo.original.changedAnswers.includes(column?.attributeIdentifier)) {
                    props.style = { backgroundColor: scYellow10 };
                  }
                }
                return props;
              }}
              showPagination={true}
              defaultCellWidth={defaultCellWidth}
            />
          )}
          {showSignButton && (
            <Button className="sign-button" size="h28" priority="high" onClick={() => openSignAllReasonModal()}>
              {isBulkSign ? 'Bulk Sign' : 'Sign'}
            </Button>
          )}
        </section>
      )}
    </div>
  );
}

export default WithLogsPageContext(LogsPage);
