import React from 'react';
import { groupBy } from 'lodash';

import Button from '../../../../../../common/general/Button';
import { NOT_PERFORMED } from '../../../../patient-source/shared/EncounterDescNew/LogCheck/LogCheckNew';
import { resolveNotReported, resolveReported } from '../defaultLogCheckOptions';

import './LogCheckPreview.scss';

const translate = {
  DATA_COLLECTED: domain => resolveReported(domain),
  NO_DATA_TO_COLLECT: domain => resolveNotReported(domain)
};

export function LogCheckPreviewNew({ logCheckInstruction, logCheckOptions, domain }) {
  const groupedOptions = groupBy(
    logCheckOptions?.filter(e => e.checked),
    'rowType'
  );
  const groups = Object.keys(groupedOptions).filter(e => e !== NOT_PERFORMED);

  return (
    <>
      {(logCheckInstruction || groups.length > 0) && (
        <div className="log-check-preview py-2">
          <div className="log-check-items-container" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {groups.map((key, idx) => (
              <Button size={'h28'} className={'ml-3'} key={idx}>
                {translate[key](domain)}
              </Button>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
