import React from 'react';
import { isNull } from 'lodash/lang';
import PropTypes from 'prop-types';

import Icon from '../../../../../../../../common/general/Icon';
import fieldPropTypes from '../fieldPropTypes';

import './NoticeField.scss';

export default function NoticeField({ field }) {
  const { fieldName, fieldType } = field;
  return (
    <div className="notice-field">
      {fieldType === 'reminder' && (
        <Icon className="reminder-field" suit="glyphicon">
          ok
        </Icon>
      )}
      {fieldName}
    </div>
  );
}

NoticeField.propTypes = {
  field: PropTypes.shape({
    ...fieldPropTypes,
    fieldType: PropTypes.oneOf(['reminder', 'textBlockLong']).isRequired,
    fieldValue: function(props, propName, componentName) {
      const value = props[propName];
      if (!isNull(value)) {
        return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed.`);
      }
    }
  })
};
