import React, { useEffect, useMemo, useState } from 'react';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { ListItemButton, Paper, Typography } from '@mui/material';
import cx from 'classnames';
import { findIndex } from 'lodash/array';
import { isEmpty } from 'lodash/lang';
import Tooltip from 'rc-tooltip';

import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';
import { SITUATIONAL_ENCOUNTERS_EPOCH_ID } from '../services';

import DraggableVirtualList from './DraggableVirtualList/DraggableVirtualList';

import './ChangeProtocolEncounterOrderingModal.scss';

export default function ChangeProtocolEncounterOrderingModal({
  modalBox,
  epochs,
  epochId = null,
  onConfirm,
  situationalEncounters
}) {
  const [sortedEpochs, setSortedEpochs] = useState(null);
  const [selectedEpochId, setSelectedEpochId] = useState(epochId);
  const [sortedSituationalEncounters, setSortedSituationalEncounters] = useState(situationalEncounters);

  const selectedEpoch = useMemo(
    function() {
      if (!sortedEpochs || !selectedEpochId) return null;

      return sortedEpochs.find(({ epochIdentifier }) => epochIdentifier === selectedEpochId) || null;
    },
    [sortedEpochs, selectedEpochId]
  );

  useEffect(
    function() {
      if (isEmpty(epochs)) return;
      if (!isEmpty(sortedSituationalEncounters)) {
        const situationalEpoch = {
          name: 'Situational',
          encounters: sortedSituationalEncounters,
          epochIdentifier: SITUATIONAL_ENCOUNTERS_EPOCH_ID,
          nodeKey: SITUATIONAL_ENCOUNTERS_EPOCH_ID
        };
        setSortedEpochs([...epochs, situationalEpoch]);
      } else {
        setSortedEpochs(epochs);
      }

      setSelectedEpochId(function(selectedEpochId) {
        return selectedEpochId ? selectedEpochId : epochs[0].epochIdentifier;
      });
    },
    [epochs, sortedSituationalEncounters]
  );

  const sortedEncounters = selectedEpoch?.encounters;
  const selectedEpochName = selectedEpoch?.name;

  return (
    <>
      <ModalBoxes.Header>Change Protocol Encounter Ordering</ModalBoxes.Header>
      <ModalBoxes.Body>
        <Paper>
          <div className="cpo-paper-header">
            <Typography variant="h6">Epochs</Typography>
          </div>
          {!isEmpty(sortedEpochs) && (
            <DraggableVirtualList
              itemIdKey="nodeKey"
              data={sortedEpochs}
              onDragEnd={epochOnDragEnd}
              ItemContent={EpochItem}
              itemContentProps={function(index, item) {
                return {
                  onClick() {
                    setSelectedEpochId(item.epochIdentifier);
                  },
                  selected: item.epochIdentifier === selectedEpochId
                };
              }}
            />
          )}
        </Paper>
        <Paper>
          <div className="cpo-paper-header">
            <Typography variant="h6">Encounters {selectedEpochName ? `- ${selectedEpochName}` : ''}</Typography>
          </div>
          {!isEmpty(sortedEncounters) && (
            <DraggableVirtualList
              itemIdKey={selectedEpochId === SITUATIONAL_ENCOUNTERS_EPOCH_ID ? 'name' : 'enNodeKey'}
              data={sortedEncounters}
              onDragEnd={encounterOnDragEnd}
              ItemContent={EncounterItem}
            />
          )}
        </Paper>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            Cancel
          </Button>
          <Button
            onClick={function() {
              onConfirm(sortedEpochs);
              modalBox.close();
            }}
          >
            Confirm
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );

  function epochOnDragEnd({ destination, source }) {
    if (!destination) {
      return;
    }

    setSortedEpochs(function(sortedEpochs) {
      return reorderEpochs(sortedEpochs, source.index, destination.index);
    });
  }

  function encounterOnDragEnd({ destination, source }) {
    if (!destination) {
      return;
    }
    if (selectedEpochId === SITUATIONAL_ENCOUNTERS_EPOCH_ID) {
      const editedEncounters = reorderSituationalEncounters(
        source.index,
        destination.index,
        sortedSituationalEncounters
      );
      setSortedSituationalEncounters(editedEncounters);
    } else {
      setSortedEpochs(function(sortedEpochs) {
        return reorderEncounters(sortedEpochs, selectedEpochId, source.index, destination.index);
      });
    }
  }
}

ChangeProtocolEncounterOrderingModal.className = 'change-protocol-encounter-ordering-modal';
ChangeProtocolEncounterOrderingModal.size = 'w950';

function reorderEpochs(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

function reorderEncounters(epochs, epochId, startIndex, endIndex) {
  const clonedEpochs = Array.from(epochs);
  const epochIndex = findIndex(clonedEpochs, ['epochIdentifier', epochId]);
  clonedEpochs[epochIndex].encounters = Array.from(clonedEpochs[epochIndex].encounters);
  const [removed] = clonedEpochs[epochIndex].encounters.splice(startIndex, 1);
  clonedEpochs[epochIndex].encounters.splice(endIndex, 0, removed);

  return clonedEpochs;
}

function reorderSituationalEncounters(startIndex, endIndex, situationalEncounters) {
  if (startIndex === endIndex) return situationalEncounters;

  const element = situationalEncounters.splice(startIndex, 1)[0];
  situationalEncounters.splice(endIndex, 0, element);

  for (let i = Math.min(startIndex, endIndex); i <= Math.max(startIndex, endIndex); i++) {
    situationalEncounters[i].sequence = i;
  }
  return situationalEncounters;
}

function EpochItem({ provided, snapshot, item, onClick, selected }) {
  const { name, epochIdentifier } = item;
  return (
    <Item
      provided={provided}
      snapshot={snapshot}
      onClick={onClick}
      selected={selected}
      isAbleToChangeOrder={epochIdentifier !== SITUATIONAL_ENCOUNTERS_EPOCH_ID}
    >
      {name}
    </Item>
  );
}

function EncounterItem({ provided, snapshot, item }) {
  const { encounterName, displayName, shortName, name, nonProtocol } = item;
  return (
    <Item provided={provided} snapshot={snapshot}>
      <div className={'inner-item-container'}>
        <span
          className={cx('cpo-encounter-board', {
            'cpo-encounter-board-non-protocol': nonProtocol || isEmpty(encounterName)
          })}
        >
          {displayName || shortName}
        </span>
        <Tooltip
          destroyTooltipOnHide={false}
          trigger={(encounterName || name).length > 50 ? ['hover'] : []}
          placement="top"
          overlayClassName="eds-rc-tooltip over-modal"
          mouseEnterDelay={0}
          mouseLeaveDelay={0}
          overlay={<span>{encounterName || name}</span>}
        >
          <p className={'encounter-name'}>{encounterName || name}</p>
        </Tooltip>
      </div>
    </Item>
  );
}

function Item({ provided, snapshot, children, onClick, selected, isAbleToChangeOrder }) {
  return (
    <ListItemButton
      ref={provided.innerRef}
      className={cx('cpo-droppable-list-item', {
        'cpo-droppable-list-item-dragging': snapshot.isDragging
      })}
      onClick={onClick}
      selected={selected}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <Typography variant="body2">{children}</Typography>
      {isAbleToChangeOrder && <DragHandleIcon color="disabled" fontSize="large" />}
    </ListItemButton>
  );
}
