import React, { useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty, isEqual } from 'lodash/lang';

import Input from '../../../../../../../../common/data-entry/Input';
import Select from '../../../../../../../../common/data-entry/Select';
import StyledTooltip from '../../../../../../../../common/widgets/StyledToolTip';
import { selectedValueWishTooltip } from '../MappingSetupServices';

const MappingFieldItem = ({
  itemGroupName,
  protocolItemIdentifier,
  mappingKeyValue,
  mappingType,
  index,
  patientMapping,
  listOfItemGroups,
  invalidMappings,
  context
}) => {
  const {
    deleteFieldMappingRow,
    changeItemGroupMappingName,
    initialItemGroupMappings,
    changeItemGroupFieldName,
    changeReceivingPlatformFieldNameItemGroupMapping,
    changeItemGroupMappingType,
    listOfSelectedItemGroupMappingFields,
    removeItemGroupMappingFromInvalidList
  } = useContext(context);
  const mappingTypeList = [
    { name: 'String', value: 'String type' },
    { name: 'Number', value: 'Number type' },
    { name: 'Boolean', value: 'Boolean type' },
    { name: 'Base64 file', value: 'Base64 type' },
    { name: 'Base64 file array', value: 'Array of base64 files' }
  ];
  const [listOfFields, setListOfFields] = useState([]);

  const [selectedItemGroup, setSelectedItemGroup] = useState(null);
  const [selectedField, setSelectedField] = useState(null);
  const [selectedMapping, setSelectedMapping] = useState(null);

  useEffect(() => {
    const initialSelectedItemGroupValue = listOfItemGroups.filter(mapping => mapping.name === itemGroupName);
    const initialSelectedMappingType = mappingTypeList.filter(mapping => mapping.name === mappingType);
    if (!isEmpty(initialSelectedItemGroupValue)) {
      const initialItemGroup = initialItemGroupMappings.filter(
        mapping => mapping.itemGroupName === initialSelectedItemGroupValue[0].name
      );
      const initialListOfFields = initialItemGroup[0]?.protocolItemDefinitions.map(field => ({
        name: field.name,
        protocolItemIdentifier: field.protocolItemIdentifier,
        itemGroupKey: field.itemGroupKey
      }));
      setListOfFields(initialListOfFields);
      setSelectedItemGroup(initialSelectedItemGroupValue[0]);
      if (protocolItemIdentifier) {
        const initialSelectedField = initialListOfFields?.filter(
          mapping => mapping.protocolItemIdentifier === protocolItemIdentifier
        );
        setSelectedField(initialSelectedField[0]);
      }
    }
    setSelectedMapping(initialSelectedMappingType[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const usedFieldsInSelectedItemGroup = listOfSelectedItemGroupMappingFields
    .filter(mapping => mapping.itemGroupName === selectedItemGroup?.name)
    .map(element => element.protocolItemIdentifier);

  const onChangeItemGroup = newValue => {
    if (!isEqual(selectedItemGroup, newValue)) {
      setSelectedItemGroup(newValue);
      const newName = newValue?.name ? newValue.name : null;
      changeItemGroupMappingName(newName, index);
      if (newValue) {
        const initialItemGroup = initialItemGroupMappings.filter(mapping => mapping.itemGroupName === newValue.name);
        setListOfFields(
          initialItemGroup[0].protocolItemDefinitions.map(field => ({
            name: field.name,
            protocolItemIdentifier: field.protocolItemIdentifier,
            itemGroupKey: field.itemGroupKey
          }))
        );
        setSelectedField(null);
        changeItemGroupFieldName(null, index, null);
      } else {
        setListOfFields([]);
        setSelectedField(null);
        changeItemGroupFieldName(null, index, null);
      }
      removeItemGroupMappingFromInvalidList(index, 'protocolItemIdentifier');
    }
  };

  const notUsedItemGroups = useMemo(() => {
    return listOfItemGroups.filter(itemGroup => {
      const foolLength = initialItemGroupMappings.filter(item => item.itemGroupName === itemGroup.name)[0]
        .protocolItemDefinitions.length;
      const selectedLength = listOfSelectedItemGroupMappingFields.filter(item => item.itemGroupName === itemGroup.name)
        .length;
      return !(selectedLength >= foolLength) && itemGroup.name !== itemGroupName;
    });
  }, [initialItemGroupMappings, itemGroupName, listOfItemGroups, listOfSelectedItemGroupMappingFields]);

  return (
    <div className="mapping-field-item">
      <Select
        onChange={onChangeItemGroup}
        dataSource={notUsedItemGroups}
        value={selectedItemGroup}
        label="Item Group/Log"
        optionLabelKey="name"
        optionValueKey="value"
        searchable
        valid={!isEmpty(invalidMappings) ? !invalidMappings.includes('protocolItemIdentifier') : true}
        required={true}
        customSelectedValueTemplateFunction={selectedValueWishTooltip}
      />
      <Select
        onChange={newValue => {
          if (!isEqual(selectedItemGroup, newValue)) {
            setSelectedField(newValue);
            changeItemGroupFieldName(
              newValue?.protocolItemIdentifier ? newValue.protocolItemIdentifier : null,
              index,
              newValue?.itemGroupKey
            );
            removeItemGroupMappingFromInvalidList(index, 'protocolItemIdentifier');
          }
        }}
        dataSource={listOfFields.filter(field => !usedFieldsInSelectedItemGroup.includes(field.protocolItemIdentifier))}
        value={selectedField}
        label="IntElligo Field"
        optionLabelKey="name"
        optionValueKey="protocolItemIdentifier"
        searchable
        valid={!isEmpty(invalidMappings) ? !invalidMappings.includes('protocolItemIdentifier') : true}
        required={true}
        customSelectedValueTemplateFunction={selectedValueWishTooltip}
      />
      <StyledTooltip
        className="mapping-key-tooltip"
        overlay={<div className="note-value">{mappingKeyValue}</div>}
        placement="top"
        showArrow={false}
      >
        <Input
          className="mapping-input"
          label="Mapping Key Value"
          type="text"
          validate={false}
          value={mappingKeyValue}
          onChange={({ target: { value } }) => {
            changeReceivingPlatformFieldNameItemGroupMapping(value, index);
            removeItemGroupMappingFromInvalidList(index, 'mappingKeyValue');
          }}
          disabled={false}
          valid={!isEmpty(invalidMappings) ? !invalidMappings.includes('mappingKeyValue') : true}
          required={true}
        />
      </StyledTooltip>
      <Select
        onChange={newValue => {
          if (!isEqual(selectedItemGroup, newValue)) {
            setSelectedMapping(newValue);

            changeItemGroupMappingType(newValue?.name ? newValue.name : null, index);
          }
        }}
        dataSource={mappingTypeList}
        value={selectedMapping}
        label="Mapping Type"
        searchable
        optionLabelKey="name"
        optionValueKey="value"
        clearable={false}
        required={true}
      />
      <div className="delete-option-item" onClick={() => deleteFieldMappingRow(index, patientMapping)} />
    </div>
  );
};

export default MappingFieldItem;
