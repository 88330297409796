import React from 'react';
import { isFunction } from 'lodash/lang';
import * as PropTypes from 'prop-types';

import Button from '../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../common/general/ButtonGroup';
import { MANAGE_BUDGETS } from '../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../services/auth';

function BudgetEventControlButtons(props) {
  const { onClose, onSave, isValidForm, currentStudyBudget, onSaveAndContinue } = props;
  const isEditingForbidden =
    (currentStudyBudget && !currentStudyBudget.editPossibility) || !userHasAccessTo(MANAGE_BUDGETS);
  const contentOfNegativeButton = isEditingForbidden ? 'Close' : 'Cancel';
  return (
    <div className="eds-modal-box-footer">
      <ButtonGroup>
        <Button priority={isEditingForbidden ? 'high' : 'medium'} id="bem-cancel" onClick={onClose}>
          {contentOfNegativeButton}
        </Button>
        {!isEditingForbidden && (
          <>
            {isFunction(onSaveAndContinue) && (
              <Button priority="medium" id="bem-save" onClick={onSaveAndContinue} disabled={!isValidForm()}>
                Save and Add Another
              </Button>
            )}
            <Button id="bem-save" onClick={onSave} disabled={!isValidForm()}>
              Save
            </Button>
          </>
        )}
      </ButtonGroup>
    </div>
  );
}

BudgetEventControlButtons.propTypes = {
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  isValidForm: PropTypes.func,
  currentStudyBudget: PropTypes.object
};

export default BudgetEventControlButtons;
