import React, { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@mui/material';

import FinLedgerRevenueDateApi from '../../../../../../api/finance/FinLedgerRevenueDateApi';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import { REVENUE_DATE_ADDED } from '../../../../../../constants/notificationMessages';
import { MANAGE_REVENUE_EVENTS } from '../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../services/auth';
import { onRequestError } from '../../../../../../services/handlers';
import { generateUrlByKey } from '../../../../../root/router';
import RevenueAdjustmentHistoryModal from '../../RevenueAdjustment/RevenueAdjustmentHistoryModal/RevenueAdjustmentHistoryModal';
import { RevenueContext } from '../../RevenueContext';

import EnterRevenueDatesModal from './EnterRevenueDatesModal/EnterRevenueDatesModal';
import HistoryOfRevenueDatesModal from './HistoryOfRevenueDatesModal/HistoryOfRevenueDatesModal';

export const RevenueTableRowMenu = ({ row }) => {
  const { filterRevenueEvents } = useContext(RevenueContext);

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const enterRevenueDatesModalOnSave = useCallback(
    data => {
      return FinLedgerRevenueDateApi.changeRevenueDate(row.itemId, data)
        .then(function(res) {
          NotificationManager.success(REVENUE_DATE_ADDED);
          filterRevenueEvents();
          return res.data;
        })
        .catch(onRequestError);
    },
    [filterRevenueEvents, row.itemId]
  );

  const openRevenueDateModal = useCallback(() => {
    if (row.revenueDateEdited) {
      ModalBoxes.open({
        component: <HistoryOfRevenueDatesModal info={row} onSave={enterRevenueDatesModalOnSave} />,
        title: `History of Revenue Dates for ${row.elligoEventType} Budget Event`
      });
    } else {
      ModalBoxes.open({
        component: (
          <EnterRevenueDatesModal
            currentRevenueDate={row.revenueDate}
            elligoEventType={row.elligoEventType}
            onSave={enterRevenueDatesModalOnSave}
          />
        ),
        title: `Change Revenue Date for ${row.elligoEventType} Budget Event`
      });
    }
  }, [enterRevenueDatesModalOnSave, row]);

  const openAdjustmentHistoryModal = useCallback(() => {
    ModalBoxes.open({
      component: <RevenueAdjustmentHistoryModal eventNumber={row.eventNumber} />
    });
  }, [row.eventNumber]);

  return (
    <>
      <MoreVertIcon onClick={openMenu} data-testid={`event-row-menu-${row.eventId}`} />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        slotProps={{
          paper: {
            sx: {
              width: '175px'
            }
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem
          onClick={() => {
            navigate(
              generateUrlByKey('Revenue.Adjust Revenue', {
                eventNumber: row.eventNumber
              })
            );
            handleCloseMenu();
          }}
          disabled={!userHasAccessTo(MANAGE_REVENUE_EVENTS)}
        >
          Adjust Revenue
        </MenuItem>
        <MenuItem
          onClick={() => {
            openRevenueDateModal();
            handleCloseMenu();
          }}
          disabled={!userHasAccessTo(MANAGE_REVENUE_EVENTS)}
        >
          Revenue Date
        </MenuItem>
        {row.hasAdjustments && (
          <MenuItem
            onClick={() => {
              openAdjustmentHistoryModal();
              handleCloseMenu();
            }}
            disabled={!userHasAccessTo(MANAGE_REVENUE_EVENTS)}
          >
            Adjustment History
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
