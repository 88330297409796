import React from 'react';
import cx from 'classnames';
import { orderBy } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';
import moment from 'moment';

import Icon from '../../../../../common/general/Icon';
import { TasksTypes } from '../../../../root/Container/Layout/Tasks/Task/taskConstants';
import TasksSupervisor from '../../../../root/Container/Layout/Tasks/TasksSupervisor/TasksSupervisor';

import '../Widgets.scss';
import './TopTenTasksWidgetList.scss';

export default function TopTenTasksWidgetList({ groupedTasks, activeTaskId, showIcons, showColumnFlags }) {
  const sortedDays = orderBy(Object.keys(groupedTasks));
  return (
    <div className="new-task-container">
      {isEmpty(sortedDays) && <div className="no-data-message">No Data</div>}
      {sortedDays.map((date, dayIndex) => {
        const tasks = groupedTasks[date];

        return (
          <div style={{ position: 'relative' }} key={dayIndex}>
            <div style={{ position: 'absolute', top: '10px' }}>{moment(date).format('DD MMM')}</div>
            {tasks.map((task, index) => {
              return (
                <div
                  className={cx('task-el', {
                    highlight: task?.id === activeTaskId,
                    first: dayIndex === 0 && index === 0,
                    last: dayIndex === sortedDays.length - 1 && index === tasks.length - 1
                  })}
                  key={index}
                  onClick={() => {
                    TasksSupervisor.open(task.id, task.studySiteId);
                  }}
                >
                  {(showColumnFlags.includes('type') || showColumnFlags.includes('id')) && (
                    <strong>
                      {showColumnFlags.includes('type') ? TasksTypes.find(e => e.id === task.type)?.name + ' ' : ''}
                      {showColumnFlags.includes('id') ? task.sequenceNumber : ''}
                    </strong>
                  )}
                  {showColumnFlags.includes('title') && <div>{task.summary}</div>}
                  <div className="footer">
                    <div style={{ flexWrap: 'wrap' }}>
                      {showColumnFlags.includes('study') && (
                        <div className="icon-text">
                          {showIcons && <Icon suit="material-outline">school</Icon>} {task.studyName}
                        </div>
                      )}
                      {showColumnFlags.includes('site') && (
                        <div className="icon-text">
                          {showIcons && <Icon suit="material-outline">apartment</Icon>} {task.siteName}
                        </div>
                      )}
                      {showColumnFlags.includes('assignee') && task.assignee !== '-- --' && (
                        <div className="icon-text">
                          {showIcons && (
                            <Icon suit="material-outline">{task.assignee === 'Multiple' ? 'group' : 'person'}</Icon>
                          )}
                          {task.assignee}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
