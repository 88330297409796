import React, { useEffect, useState } from 'react';

import Input from '../../../../../../common/data-entry/Input';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import { FILL_REQUIRED } from '../../../../../../constants/notificationMessages';

export default function EpochDetailsModal(props) {
  const { onSave, selectedEpochData, modalBox } = props;
  const [name, setName] = useState('');

  useEffect(() => {
    if (selectedEpochData && selectedEpochData.length) {
      setName(selectedEpochData[0].name);
    }
  }, [selectedEpochData]);

  return (
    <>
      <ModalBoxes.Header>Epoch Name</ModalBoxes.Header>
      <ModalBoxes.Body>
        <Input label="Epoch name" type="text" name="name" value={name} onChange={textHandleChange} />
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            Cancel
          </Button>
          <Button onClick={onSaveEpoch}>Save</Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
  function textHandleChange(evt) {
    setName(evt.target.value);
  }
  function onSaveEpoch() {
    if (name !== '') {
      onSave && onSave({ name });
      modalBox.close();
    } else {
      NotificationManager.error(FILL_REQUIRED);
    }
  }
}

EpochDetailsModal.className = 'epoch-detail-modal';
EpochDetailsModal.size = 'w500';
