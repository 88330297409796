import React, { useContext, useState } from 'react';
import { TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { isEmpty } from 'lodash/lang';
import moment from 'moment';

import Button from '../../../../common/general/Button';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../constants/dateFormat';
import { toCoins, toFormattedBill } from '../../../../services/financial';
import { PageInfoHeader } from '../../../PageInfoHeader/PageInfoHeader';
import { Amount } from '../shared/amount-view/Amount/Amount';

import { NumericFormatCustomInput } from './ReconcileTable/NumericFormatCustomInput';
import { ReconcileTable } from './ReconcileTable/ReconcileTable';
import { ReconcileContext, withReconcileContext } from './ReconcileContext';

import './Reconcile.scss';

const Reconcile = () => {
  const {
    events,
    eventsForTable,
    datePosted,
    setDatePosted,
    depositNumber,
    setDepositNumber,
    onApplyOrClear,
    selectedItems,
    applyFullAmountPaid
  } = useContext(ReconcileContext);

  const [amount, setAmount] = useState('');
  const [comment, setComment] = useState('');

  const inputStyles = {
    minWidth: '250px',
    '& label.Mui-focused': {
      color: '#7d3559'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#7d3559'
    },
    '&.Mui-selected, .MuiButtonBase-root:focus': {
      color: '#691E44',
      outline: 'none'
    }
  };

  const handleAmountChange = event => {
    const { value } = event.target;
    const newValue = isEmpty(value) || +value === 0 ? null : value.replace(/,/g, '');
    setAmount(newValue);
  };

  return (
    <div className="invoice-reconcile">
      <PageInfoHeader
        pageInfo={
          <React.Fragment>
            <PageInfoHeader.AdditionalInfo title="Eligible">
              <Amount coinsAmount={calculateAmounts(events, 'invoiceAmount')} customCoinsFormat={toFormattedBill} />
            </PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo title="Amount Paid">
              <Amount
                coinsAmount={calculateAmounts(events, 'reconcileAmountPaid', false)}
                customCoinsFormat={toFormattedBill}
              />
            </PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo title="Variance">
              <Amount coinsAmount={calculateAmounts(eventsForTable, 'variance')} customCoinsFormat={toFormattedBill} />
            </PageInfoHeader.AdditionalInfo>
          </React.Fragment>
        }
      >
        <div className="reconcile-payments-header">
          <DesktopDatePicker
            label="Date Posted"
            value={datePosted}
            onChange={newValue => setDatePosted(newValue)}
            format={DD_SLASH_MMM_SLASH_YYYY}
            slotProps={{
              textField: { variant: 'standard', required: true },
              popper: {
                sx: {
                  '.MuiButtonBase-root.MuiPickersDay-root': {
                    '&:focus, &:active, &.Mui-selected': {
                      backgroundColor: '#691E44',
                      outline: 'none',
                      color: 'white'
                    }
                  }
                }
              }
            }}
            maxDate={moment()}
            sx={inputStyles}
          />
          <TextField
            label="Deposit Number"
            value={depositNumber}
            onChange={({ target: { value } }) => {
              setDepositNumber(value);
            }}
            maxLength={255}
            required
            variant="standard"
            sx={inputStyles}
          />
          <Button size="h28" onClick={() => applyFullAmountPaid()} disabled={!depositNumber || !datePosted}>
            Apply Full Variance
          </Button>
        </div>
      </PageInfoHeader>
      <div className="multiple-apply-section">
        <TextField
          label="Apply Amount Paid to Selected"
          value={amount}
          onChange={handleAmountChange}
          variant="standard"
          sx={inputStyles}
          type="text"
          InputProps={{
            inputComponent: NumericFormatCustomInput
          }}
        />
        <TextField
          label="Apply Comments to Selected"
          value={comment}
          onChange={({ target: { value } }) => {
            setComment(value);
          }}
          inputProps={{ maxLength: 255 }}
          variant="standard"
          sx={{
            width: '100%',
            ...inputStyles
          }}
        />
        <Button
          onClick={() => onApplyOrClear(amount, comment)}
          data-testid="reconcile-apply-button"
          disabled={isEmpty(selectedItems) || (isEmpty(amount) && isEmpty(comment.trim()))}
        >
          Apply
        </Button>
      </div>
      <ReconcileTable />
    </div>
  );
};

export const calculateAmounts = (events, fieldName, isCoins = true) => {
  return events.reduce((total, obj) => {
    const amount = Number(obj[fieldName]);
    return total + (isCoins ? amount : toCoins(amount));
  }, 0);
};

export default withReconcileContext(Reconcile);
