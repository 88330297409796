import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import './FvImageViewer.scss';

export function FvImageViewer({ file }) {
  const { url } = file,
    [imageData, setImageData] = useState({}),
    [w, setW] = useState(null),
    [scale, setScale] = useState(100),
    { width, height } = imageData;

  useEffect(
    function() {
      if (scale <= 0 || !width) {
        return;
      }
      setW((scale / 100) * width);
    },
    [scale, width]
  );

  return (
    <div className="eds-fv-image-viewer">
      <div className="eds-fv-image-viewer-zoom-control">
        {[25, 50, 75, 100, 125, 150, 200].map(p => (
          <button key={p} className={cx({ active: p === scale })} onClick={() => setScale(p)}>
            {p}%
          </button>
        ))}
      </div>
      <div className="eds-fv-image-viewer-scrollable">
        <div
          className={cx('eds-fv-image-viewer-container', { 'eds-fv-image-viewer-container-visible': w })}
          style={{ width: w }}
        >
          <img alt="alt_text" src={url} onLoad={onLoad} />
          <div style={{ paddingBottom: (height * 100) / width + '%' }} />
        </div>
      </div>
    </div>
  );

  function onLoad({ target }) {
    const { naturalHeight, naturalWidth } = target;
    setImageData({ width: naturalWidth, height: naturalHeight });
  }
}
