import React, { useCallback, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Card, Icon, Typography } from '@mui/material';
import cx from 'classnames';

import FinPayeeApi from '../../../../../../api/finance/FinPayeeApi';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import { MANAGE_PAYEE_ACCOUNTS } from '../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../services/auth';
import { onRequestError } from '../../../../../../services/handlers';

import './PayableToCard.scss';

export const PayableToCard = ({ payee, openPayableToModal, loadActivePayee }) => {
  const [open, setOpen] = useState(false);

  const deleteCard = useCallback(() => {
    if (!payee.default) {
      FinPayeeApi.deletePayee(payee.id)
        .then(() => {
          NotificationManager.success('Deleted');
          setOpen(false);
          loadActivePayee();
        })
        .catch(onRequestError);
    }
  }, [loadActivePayee, payee.default, payee.id]);

  const setAsDefault = useCallback(() => {
    if (!payee.default) {
      FinPayeeApi.markAsDefault(payee.id)
        .then(() => {
          NotificationManager.success('Default');
          setOpen(false);
          loadActivePayee();
        })
        .catch(onRequestError);
    }
  }, [loadActivePayee, payee.default, payee.id]);

  return (
    <Card
      data-testid="payable-to-card"
      className="payable-to-card"
      sx={{
        '&.MuiCard-root': {
          overflow: 'visible'
        }
      }}
    >
      <div className="payable-to-card-header">
        <Typography className="header">Payable to</Typography>
        <div>
          {payee.default && <div className="default-mark">Default</div>}
          {userHasAccessTo(MANAGE_PAYEE_ACCOUNTS) && (
            <Icon data-testid="card-sub-menu" onClick={() => setOpen(prevState => !prevState)}>
              more_vert
            </Icon>
          )}
        </div>
      </div>
      <Accordion disableGutters className="accordion-details">
        <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
          <Typography className="information-header">{payee?.name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="information">{payee?.address1}</Typography>
          <Typography className="information">{payee?.address2}</Typography>
          <Typography className="information">{`${payee?.city}, ${payee?.state} ${payee?.zipCode}`}</Typography>
          <br />
          <Typography className="information">{payee?.phone}</Typography>
          <Typography className="information email">{payee?.email}</Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion disableGutters className="accordion-details" defaultExpanded={true}>
        <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
          <Typography className="information-header">Banking Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="information">{payee?.bankName}</Typography>
          <Typography className="information">Routing: {payee?.bankRoutingNumber}</Typography>
          <Typography className="information">Account: {payee?.bankAccountNumber}</Typography>
          <Typography className="information">SWIFT: {payee?.bankSwiftCode}</Typography>
        </AccordionDetails>
      </Accordion>
      {open && (
        <div className="card-menu">
          <div
            onClick={() => {
              openPayableToModal('Edit', payee.id, loadActivePayee);
              setOpen(false);
            }}
          >
            Edit
          </div>
          <div className={cx({ disabled: payee.default })} onClick={deleteCard}>
            Delete
          </div>
          <div className={cx({ disabled: payee.default })} onClick={setAsDefault}>
            Set as default
          </div>
        </div>
      )}
    </Card>
  );
};
