import { useState } from 'react';
import { TransformComponent, TransformWrapper, useControls } from 'react-zoom-pan-pinch';
import cx from 'classnames';

import './EuiImageViewer.scss';

export default function EuiImageViewer({ file }) {
  const [scale, setScale] = useState(0.25);

  return (
    <div className="eui-image-viewer">
      <TransformWrapper
        minScale={0.25}
        centerZoomedOut={true}
        centerOnInit={true}
        onTransformed={function({ instance }) {
          return setScale(instance?.transformState?.scale || 0.25);
        }}
      >
        <Controls scale={scale} file={file} />
      </TransformWrapper>
    </div>
  );
}

function getS({ width, height }, r) {
  if (r === 90 || r === -90) {
    return { width: height, height: width };
  }
  return { width, height };
}

function Controls({ scale, file }) {
  const { fileUrl } = file;
  const { centerView } = useControls();
  const [imageData, setImageData] = useState({});
  const [rotation, setRotation] = useState(0);

  return (
    <>
      <div className="eui-image-viewer-zoom-control">
        {[0.25, 0.5, 0.75, 1, 1.25, 1.5, 2].map(p => (
          <button key={p} className={cx({ active: p === scale })} onClick={() => centerView(p)}>
            {p * 100}%
          </button>
        ))}
        <button
          onClick={() =>
            setRotation(function(r) {
              const newR = r + 90;
              if (newR < 360) return newR;
              return 0;
            })
          }
        >
          Rotate
        </button>
      </div>
      <TransformComponent contentStyle={getS(imageData, rotation)}>
        <img
          src={fileUrl}
          alt={file.fileName}
          onLoad={onLoad}
          style={{ transform: `translate(-50%, -50%) rotate(${rotation}deg)`, ...imageData }}
        />
      </TransformComponent>
    </>
  );
  function onLoad({ target }) {
    const { naturalHeight, naturalWidth } = target;
    setImageData({ width: naturalWidth, height: naturalHeight });
    setTimeout(function() {
      centerView();
    });
  }
}
