import React from 'react';
import { isFunction } from 'lodash/lang';

import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../common/general/ButtonGroup';

import './AdverseEventLogWrongGroupAssignConfigModal.scss';
export default function AdverseEventLogWrongGroupAssignConfigModal({ modalBox, onGroupSetupRedirect }) {
  return (
    <>
      <ModalBoxes.Header>Unable to Publish Protocol Setup</ModalBoxes.Header>
      <ModalBoxes.Body>
        <span className={'ae-group-assign-body-text-header'}>Adverse Event Setup has not been completed</span>
        <p className={'ae-group-assign-body-text'}>
          At least 1 Investigator and Study Manager Sign permission should be available for each Adverse Event Log item
          group. In order to publish this protocol setup, please review and update the group permissions.
        </p>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button
            priority="medium"
            onClick={() => {
              modalBox.close();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              modalBox.close();
              isFunction(onGroupSetupRedirect) && onGroupSetupRedirect();
            }}
            priority="high"
          >
            Group Setup
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
}

AdverseEventLogWrongGroupAssignConfigModal.className = 'eds-adverse-event-log-group-assign-modal-box';
AdverseEventLogWrongGroupAssignConfigModal.size = 'w650';
