import separateAxiosInstance from '../separateAxiosInstance';

const ROOT = 'api/v1/version';

const VersionApi = {
  getVersion() {
    return separateAxiosInstance.get(ROOT);
  }
};

export default VersionApi;
