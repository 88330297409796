import React from 'react';
import moment from 'moment/moment';

import { stylesContent } from '../../EncounterInvoiceTemplateStyles';

export const DefaultItem = ({ item }) => {
  return (
    <div style={stylesContent.invoiceItem} className="row-item" data-testid="ledger-event-item">
      {`Subject ID ${item?.subjectNumber ? item?.subjectNumber : '—'} ${
        item?.eventDate ? `(${moment(item.eventDate).format('DD-MMM-YYYY')})` : '—'
      }`}
    </div>
  );
};
