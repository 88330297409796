import * as React from 'react';
import { useCallback, useEffect, useLayoutEffect, useReducer, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField
} from '@mui/material';
import Button from '@mui/material/Button';
import { useGridApiContext } from '@mui/x-data-grid-premium';

import useLocalStorage from '../../../../../../common/hooks/useLocalStorage';

const demoReducer = (state, action) => {
  switch (action.type) {
    case 'createView': {
      const id = Math.random().toString();

      return {
        ...state,
        activeViewId: id,
        newViewLabel: '',
        views: {
          ...state.views,
          [id]: { label: state.newViewLabel, value: action.value }
        }
      };
    }

    case 'deleteView': {
      const views = Object.fromEntries(Object.entries(state.views).filter(([id]) => id !== action.id));

      let activeViewId;
      if (state.activeViewId !== action.id) {
        activeViewId = state.activeViewId;
      } else {
        const viewIds = Object.keys(state.views);

        if (viewIds.length === 0) {
          activeViewId = null;
        } else {
          activeViewId = viewIds[0];
        }
      }

      return {
        ...state,
        views,
        activeViewId
      };
    }

    case 'setActiveView': {
      return {
        ...state,
        activeViewId: action.id,
        isMenuOpened: false
      };
    }

    case 'setNewViewLabel': {
      return {
        ...state,
        newViewLabel: action.label
      };
    }

    case 'togglePopper': {
      return {
        ...state,
        isMenuOpened: !state.isMenuOpened,
        menuAnchorEl: action.element
      };
    }

    case 'closePopper': {
      return {
        ...state,
        isMenuOpened: false
      };
    }

    default: {
      return state;
    }
  }
};

const INITIAL_STATE = {
  views: {},
  newViewLabel: '',
  isMenuOpened: false,
  menuAnchorEl: null,
  activeViewId: null
};

function ViewListItem(props) {
  const { view, viewId, selected, onDelete, onSelect, ...other } = props;

  return (
    <MenuItem selected={selected} onClick={() => onSelect(viewId)} {...other}>
      {view.label}
      <IconButton
        edge="end"
        aria-label="delete"
        size="small"
        onClick={event => {
          event.stopPropagation();
          onDelete(viewId);
        }}
      >
        <DeleteIcon />
      </IconButton>
    </MenuItem>
  );
}

function NewViewListButton(props) {
  const { label, onLabelChange, onSubmit, isValid } = props;
  const [isAddingView, setIsAddingView] = React.useState(false);

  const handleSubmitForm = e => {
    onSubmit();
    setIsAddingView(false);
    e.preventDefault();
  };

  return (
    <React.Fragment>
      <Button endIcon={<AddIcon />} size="small" onClick={() => setIsAddingView(true)}>
        Save current view
      </Button>
      <Dialog onClose={() => setIsAddingView(false)} open={isAddingView}>
        <form onSubmit={handleSubmitForm}>
          <DialogTitle>New custom view</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              value={label}
              onChange={onLabelChange}
              margin="dense"
              size="medium"
              label="Custom view label"
              variant="standard"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button type="button" onClick={() => setIsAddingView(false)}>
              Cancel
            </Button>
            <Button type="submit" disabled={!isValid}>
              Create view
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

export function GridToolbarTableViewState({ localStorageTableName }) {
  const apiRef = useGridApiContext();
  const [
    stateFromLocalStorage,
    setStateFromLocalStorage
  ] = useLocalStorage(`${localStorageTableName}_TABLE_CUSTOM_VIEW`, { views: {} });
  const [createView, setCreateView] = useState(false);
  const [state, setState] = useReducer(demoReducer, {
    ...INITIAL_STATE,
    views: stateFromLocalStorage.views || {}
  });

  useEffect(() => {
    if (createView) {
      const updatedViews = { ...state.views };

      if (updatedViews[state.activeViewId]) {
        updatedViews[state.activeViewId].value.filter = {};
      }

      setStateFromLocalStorage({
        ...stateFromLocalStorage,
        activeViewId: state.activeViewId,
        views: updatedViews
      });
      setCreateView(false);
    }
  }, [state, createView, setStateFromLocalStorage, stateFromLocalStorage]);

  useLayoutEffect(() => {
    if (stateFromLocalStorage.views) {
      setState(stateFromLocalStorage);
    }
  }, [setState, stateFromLocalStorage]);

  const createNewView = () => {
    setState({
      type: 'createView',
      value: apiRef.current.exportState()
    });
    setCreateView(true);
  };

  const handleNewViewLabelChange = event => {
    setState({ type: 'setNewViewLabel', label: event.target.value });
  };

  const handleDeleteView = useCallback(
    viewId => {
      const updatedViews = { ...stateFromLocalStorage.views };
      delete updatedViews[viewId];
      setStateFromLocalStorage({ ...stateFromLocalStorage, views: updatedViews });
      setState({ type: 'deleteView', id: viewId });
    },
    [setState, setStateFromLocalStorage, stateFromLocalStorage]
  );

  const handleSetActiveView = viewId => {
    apiRef.current.restoreState(state.views[viewId].value);
    setState({ type: 'setActiveView', id: viewId });
  };

  const handlePopperAnchorClick = event => {
    setState({ type: 'togglePopper', element: event.currentTarget });
    event.stopPropagation();
  };

  const handleClosePopper = () => {
    setState({ type: 'closePopper' });
  };

  const isNewViewLabelValid = React.useMemo(() => {
    if (state.newViewLabel.length === 0) {
      return false;
    }

    return Object.values(state.views).every(view => view.label !== state.newViewLabel);
  }, [state.views, state.newViewLabel]);

  const canBeMenuOpened = state.isMenuOpened && Boolean(state.menuAnchorEl);
  const popperId = canBeMenuOpened ? 'transition-popper' : undefined;

  const handleListKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setState({ type: 'closePopper' });
    } else if (event.key === 'Escape') {
      setState({ type: 'closePopper' });
    }
  };

  return (
    <>
      <Button
        aria-describedby={popperId}
        type="button"
        size="small"
        id="custom-view-button"
        aria-controls={state.isMenuOpened ? 'custom-view-menu' : undefined}
        aria-expanded={state.isMenuOpened ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handlePopperAnchorClick}
      >
        Custom view ({Object.keys(state.views).length})
      </Button>
      <ClickAwayListener onClickAway={handleClosePopper}>
        <Popper
          id={popperId}
          open={state.isMenuOpened}
          anchorEl={state.menuAnchorEl}
          role={undefined}
          transition
          placement="bottom-start"
          sx={{ zIndex: 'modal' }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <MenuList
                  autoFocusItem={state.isMenuOpened}
                  id="custom-view-menu"
                  aria-labelledby="custom-view-button"
                  onKeyDown={handleListKeyDown}
                >
                  {Object.entries(state.views).map(([viewId, view]) => (
                    <ViewListItem
                      key={viewId}
                      view={view}
                      viewId={viewId}
                      selected={viewId === state.activeViewId}
                      onDelete={handleDeleteView}
                      onSelect={handleSetActiveView}
                    />
                  ))}
                </MenuList>
              </Paper>
            </Fade>
          )}
        </Popper>
      </ClickAwayListener>
      <NewViewListButton
        label={state.newViewLabel}
        onLabelChange={handleNewViewLabelChange}
        onSubmit={createNewView}
        isValid={isNewViewLabelValid}
      />
    </>
  );
}
