export const NON_PATIENT_EVENT = 'NON_PATIENT_EVENT';
export const MILESTONE_EVENT = 'MILESTONE_EVENT';
export const PATIENT_UNEXPECTED_ENCOUNTER_EVENT = 'PATIENT_UNEXPECTED_ENCOUNTER_EVENT';
export const PATIENT_ENCOUNTER_EVENT = 'PATIENT_ENCOUNTER_EVENT';
export const PATIENT_SITUATIONAL_ENCOUNTER_EVENT = 'PATIENT_SITUATIONAL_ENCOUNTER_EVENT';

export const APPOINTMENT_TYPES = [
  PATIENT_UNEXPECTED_ENCOUNTER_EVENT,
  PATIENT_ENCOUNTER_EVENT,
  NON_PATIENT_EVENT,
  PATIENT_SITUATIONAL_ENCOUNTER_EVENT
];
export const MILESTONE_TYPES = [MILESTONE_EVENT];
