import React from 'react';

import Icon from '../../../../../common/general/Icon';

import './WidgetDefaultStyles.scss';

export const CustomHeader = ({ title }) => {
  return (
    <div className="custom-sort-header">
      <div className="title">{title}</div>
      <div className="icons">
        <Icon>expand_less</Icon>
        <Icon>expand_more</Icon>
      </div>
    </div>
  );
};
