import { FinBudgetApi } from '../../../api';
import NotificationManager from '../../../common/notifications/NotificationManager';
import { STUDY_BUDGET_UPDATED } from '../../../constants/notificationMessages';
import { onRequestError } from '../../../services/handlers';
import { UPDATE_STUDY_BUDGET } from '../../actionTypes';

export const updateStudyBudgetAction = studyBudget => {
  return {
    type: UPDATE_STUDY_BUDGET,
    payload: {
      studyBudget
    }
  };
};

export const updateStudyBudget = studyBudget => dispatch => {
  return FinBudgetApi.update(studyBudget).then(resp => {
    dispatch(updateStudyBudgetAction(resp.data));
    NotificationManager.success(STUDY_BUDGET_UPDATED);
  }, onRequestError);
};

export const saveInvoiceDetails = (budgetId, invoiceDetails) => dispatch => {
  return FinBudgetApi.saveInvoiceDetails(budgetId, invoiceDetails).then(resp => {
    dispatch(updateStudyBudgetAction(resp.data));
  });
};
