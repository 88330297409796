import React, { useContext, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

import { YEAR_DASH_MONTH_DASH_DAY } from '../../../../../../constants/dateFormat';
import { getPatientFullName, getPatientPreferredName } from '../../../../../../services/patient';
import { PageInfoHeader } from '../../../../../PageInfoHeader/PageInfoHeader';
import { generateUrlByKey, useCurrentRoute } from '../../../../../root/router';
import { ReviewContext } from '../ReviewContext';
import { isSmOrPiReview } from '../reviewService';

export default function ReviewInfoHeader() {
  const {
    reviewType,
    reviewMetaData,
    patientItemGroupId: reviewPatientItemGroupId,
    ssuPatientId,
    patientEncounterId
  } = useContext(ReviewContext);

  const { params, parent } = useCurrentRoute();
  const { patientId } = params;
  const encounterDetailsKey = parent?.key;
  const patientInfoKey = parent?.parent?.key;

  const dateOfBirth = reviewMetaData?.dob ? getFormattedDOB(reviewMetaData.dob) : '---';
  const encounterName = reviewMetaData?.encounterName || '---';
  const studyName = reviewMetaData?.studyName;
  const siteName = reviewMetaData?.siteName;

  const urlForEncounterDetailsPage = useMemo(
    function() {
      if (!reviewPatientItemGroupId || !encounterDetailsKey) {
        return null;
      }
      return generateUrlByKey(encounterDetailsKey, {
        ssuPatientId,
        patientId,
        patientEncounterId,
        reviewPatientItemGroupId
      });
    },
    [encounterDetailsKey, ssuPatientId, patientId, patientEncounterId, reviewPatientItemGroupId]
  );

  const urlForPatientInfoPage = useMemo(
    function() {
      if (!patientInfoKey) {
        return null;
      }
      return generateUrlByKey(patientInfoKey, {
        ssuPatientId,
        patientEncounterId,
        reviewPatientItemGroupId,
        patientId
      });
    },
    [patientInfoKey, ssuPatientId, patientId, patientEncounterId, reviewPatientItemGroupId]
  );

  return (
    <PageInfoHeader
      objectRecordLabel={
        !!urlForEncounterDetailsPage ? (
          <NavLink to={urlForEncounterDetailsPage}>{encounterName}</NavLink>
        ) : (
          encounterName
        )
      }
      pageInfo={
        <PageInfoHeader.CollapsibleList>
          {isSmOrPiReview(reviewType) && (
            <PageInfoHeader.AdditionalInfo tooltip="Patient Name">
              <NavLink to={urlForPatientInfoPage}>
                {getPatientFullName(reviewMetaData) + getPatientPreferredName(reviewMetaData)}
              </NavLink>
            </PageInfoHeader.AdditionalInfo>
          )}
          {isSmOrPiReview(reviewType) && (
            <PageInfoHeader.AdditionalInfo title="Patient ID">
              {reviewMetaData?.patientID}
            </PageInfoHeader.AdditionalInfo>
          )}
          <PageInfoHeader.AdditionalInfo title="Subject ID">
            {reviewMetaData?.subjectId || 'No Subject ID'}
          </PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo tooltip="Date of Birth ">{dateOfBirth}</PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo tooltip="Study">{studyName}</PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo tooltip="Site">{siteName}</PageInfoHeader.AdditionalInfo>
        </PageInfoHeader.CollapsibleList>
      }
    />
  );
}

function getFormattedDOB(dob) {
  const age = moment().diff(moment(dob, YEAR_DASH_MONTH_DASH_DAY), 'years');
  return `${dob} (${age} ${age === 1 ? 'year' : 'years'})`;
}
