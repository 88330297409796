import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { NON_PROTOCOL, UNEXPECTED } from '../../../../../../../../../../../constants/encounterConstants';
import {
  VIEW_PRE_SCREEN_WORK_LIST,
  VIEW_PROTOCOL_ENCOUNTER_DATA
} from '../../../../../../../../../../../constants/userOperations';
import { ROLE_PATIENT_REPRESENTATIVE, ROLE_RECRUITER } from '../../../../../../../../../../../constants/userRoles';
import { userHasAccessTo } from '../../../../../../../../../../../services/auth';
import { generateUrlByKey, useCurrentRoute } from '../../../../../../../../../../root/router';
import WithMultiRoleCheck from '../../../../../../../../../../UserRoleSwitcher/WithMultiRoleCheck';
import { useEncounterSegment } from '../../EncounterSegment';

import './CardHeaderWrapperDefault.scss';

export default function CardHeaderWrapperDefault({ children }) {
  const currentRoute = useCurrentRoute();
  const { encounter } = useEncounterSegment();
  const { sitePatientEncounter, encounterType } = encounter;
  const isNonProtocol = encounterType === NON_PROTOCOL;
  const isEncounterDetailsAllowed = userHasAccessTo(
    isNonProtocol ? VIEW_PRE_SCREEN_WORK_LIST : VIEW_PROTOCOL_ENCOUNTER_DATA
  );
  const currentRouteKey = currentRoute?.key;
  const patientId = currentRoute.params?.patientId;
  const ssuPatientId = currentRoute.params?.ssuPatientId;
  const patientEncounterId = sitePatientEncounter?.id;

  const encounterDetailsPageUrl = useMemo(
    function() {
      if (!patientId || !ssuPatientId || !patientEncounterId) {
        return null;
      }
      return generateUrlByKey(`${currentRouteKey}.Encounter Details`, {
        patientId,
        ssuPatientId,
        patientEncounterId
      });
    },
    [currentRouteKey, patientId, ssuPatientId, patientEncounterId]
  );

  const excludedRoles = useMemo(
    function() {
      if (encounterType === UNEXPECTED) {
        return [ROLE_RECRUITER, ROLE_PATIENT_REPRESENTATIVE];
      }
      return [];
    },
    [encounterType]
  );

  if (!sitePatientEncounter || !isEncounterDetailsAllowed) {
    return children;
  }

  return (
    <WithMultiRoleCheck
      excludedRoles={excludedRoles}
      Component={NavLink}
      ssuPatientId={currentRoute.params.ssuPatientId}
      className="encounter-segment-card-header-nav-link"
      to={encounterDetailsPageUrl}
    >
      {children}
    </WithMultiRoleCheck>
  );
}
