export const ENCOUNTER_MAPPING = 'Encounter';
export const IG_MAPPING = 'IG/Log';

export const mappingTypes = [
  {
    name: ENCOUNTER_MAPPING,
    id: ENCOUNTER_MAPPING
  },
  {
    name: IG_MAPPING,
    id: IG_MAPPING
  }
];
