import React from 'react';

import './ReadOnlyCellTextField.scss';

export function ReadOnlyCellTextField({ value }) {
  return (
    <div className="read-only-cell-text-field">
      <div>{value}</div>
    </div>
  );
}
