import React from 'react';
import { get } from 'lodash/object';
import moment from 'moment';

import { FileInfoApi, UserMediaApi } from '../api';
import Icon from '../common/general/Icon';
import { DD_SLASH_MMM_SLASH_YYYY } from '../constants/dateFormat';
import { FILE_VIRUS_SCAN_STATUS } from '../constants/files';
import { scBlack, scGreen100, scRed100 } from '../constants/systemColors';

export function resolveFileIcon({ certifyCopy, virusScanStatus }) {
  if (virusScanStatus === FILE_VIRUS_SCAN_STATUS.INFECTED) {
    return (
      <Icon className="file-status-icon" suit="material-round" style={{ color: scRed100 }}>
        error
      </Icon>
    );
  }

  if (certifyCopy) {
    return (
      <Icon className="file-status-icon" suit="material" style={{ color: scGreen100 }}>
        task
      </Icon>
    );
  } else {
    return (
      <Icon className="file-status-icon" suit="material-outline" style={{ color: scBlack }}>
        insert_drive_file
      </Icon>
    );
  }
}

export function getFileTitle(file) {
  if (file.virusScanStatus === 'INFECTED') {
    return 'File contains a virus and cannot be downloaded';
  }
  if (file.certifyCopy) {
    const signedDate = moment(file.signedOn).format(DD_SLASH_MMM_SLASH_YYYY);
    return `Certified to be a true and exact copy of original\nSigned by: ${file.signedBy}\nDate: ${signedDate}`;
  }
  if (file.originalFile) {
    return 'Unsaved file';
  }
  if (!file.certifyCopy) {
    return 'Saved file';
  }
}

export function openFile(fileViewer, file) {
  return fileViewer.openFileByPromise(
    new Promise((resolve, reject) => {
      const { uniqueIdentifier, sitePatientIdentifier } = file;
      FileInfoApi.getFileInfo(uniqueIdentifier, sitePatientIdentifier, true).then(({ data }) => {
        UserMediaApi.downloadMedia(uniqueIdentifier, sitePatientIdentifier, true)
          .then(({ data: blob, headers, id }) => {
            resolve({
              data: blob,
              headers: {
                'content-disposition': headers['content-disposition']
              },
              fileInfo: data
            });
          })
          .catch(err => {
            const statusCode = get(err, 'response.status');
            if (statusCode === 502) {
              file.virusScanStatus = FILE_VIRUS_SCAN_STATUS.INFECTED;
            }
            reject(err);
          });
      });
    })
  );
}
