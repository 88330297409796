import React from 'react';
import { isEmpty } from 'lodash/lang';

import MultiSelect from '../../../../../common/data-entry/MultiSelect/MultiSelect';
import Button from '../../../../../common/general/Button';

import { getLabelFromService } from './WidgetDefaultService';

export default function WidgetDefaultFilters({
  selectedStudies,
  selectedSites,
  selectedEpochs,
  selectedEncounters,
  allStudies,
  allSites,
  allEpochs,
  allEncounters,
  studies,
  sites,
  epochs,
  encounters,
  onStudiesChange,
  onSitesChange,
  onEpochsChange,
  onEncountersChange,
  applyFilters,
  isFetching
}) {
  const getLabel = type => {
    return getLabelFromService(
      type,
      selectedStudies,
      selectedSites,
      selectedEpochs,
      selectedEncounters,
      allStudies,
      allSites,
      allEpochs,
      allEncounters
    );
  };

  return (
    <div className="px-3 filters-row">
      <MultiSelect
        controlId={'id'}
        clearable={false}
        disabled={isFetching || allStudies.length === 1}
        validate={false}
        clearSearchOnSelection
        forceDefaultBrowserScrolling={true}
        label={getLabel('Study')}
        optionValueKey={'name'}
        focusToSelectedValue={false}
        onChange={onStudiesChange}
        value={selectedStudies}
        searchable
        customSelectedValueTemplateFunction={() => <></>}
        dataSource={studies}
      />
      <MultiSelect
        controlId={'id'}
        clearable={false}
        disabled={isFetching || allSites.length === 1}
        validate={false}
        clearSearchOnSelection
        forceDefaultBrowserScrolling={true}
        optionValueKey={'name'}
        focusToSelectedValue={false}
        label={getLabel('Site')}
        onChange={onSitesChange}
        value={selectedSites}
        searchable
        customSelectedValueTemplateFunction={() => <></>}
        dataSource={sites}
      />
      {isEmpty(epochs) && (
        <MultiSelect
          controlId={'Epoch'}
          disabled={true}
          forceDefaultBrowserScrolling={true}
          label="Epoch (0)"
          className="ml-3"
        />
      )}
      {!isEmpty(epochs) && (
        <MultiSelect
          controlId={'Epoch'}
          clearable={false}
          disabled={isFetching || isEmpty(selectedSites)}
          validate={false}
          clearSearchOnSelection
          focusToSelectedValue={false}
          optionValueKey={'name'}
          forceDefaultBrowserScrolling={true}
          label={getLabel('Epoch')}
          onChange={onEpochsChange}
          value={selectedEpochs}
          searchable
          customSelectedValueTemplateFunction={() => <></>}
          dataSource={epochs}
        />
      )}
      {isEmpty(encounters) && (
        <MultiSelect
          controlId={'Encounter'}
          disabled={true}
          forceDefaultBrowserScrolling={true}
          label="Encounter (0)"
          className="ml-3"
        />
      )}
      {!isEmpty(encounters) && (
        <MultiSelect
          controlId={'id'}
          clearable={false}
          disabled={isFetching || isEmpty(selectedSites)}
          validate={false}
          optionValueKey={'name'}
          focusToSelectedValue={false}
          forceDefaultBrowserScrolling={true}
          label={getLabel('Encounter')}
          onChange={onEncountersChange}
          value={selectedEncounters}
          searchable
          customSelectedValueTemplateFunction={() => <></>}
          dataSource={encounters}
        />
      )}
      <Button size="h28" disabled={isFetching} onClick={applyFilters} priority="high">
        Apply
      </Button>
    </div>
  );
}
