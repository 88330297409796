import React from 'react';
import moment from 'moment/moment';

import { useEncounterSegment } from '../../EncounterSegment';

import './AppointmentWindow.scss';

export default function ProtocolWindow() {
  const { encounter } = useEncounterSegment();
  const { projectedEncounterInfo } = encounter;

  const { start, end } = { ...projectedEncounterInfo };
  const START_END_DATE_FORMAT = 'DD/MMM/YYYY';

  return (
    projectedEncounterInfo?.start && (
      <div className="appointment-window">
        <div className="appointment-window-title">Protocol Window</div>
        {projectedEncounterInfo && (
          <div className="projected-start-end-date">
            {start && moment(start).format(START_END_DATE_FORMAT)} {start && end && ' - '}
            {end && moment(end).format(START_END_DATE_FORMAT)}
          </div>
        )}
      </div>
    )
  );
}
