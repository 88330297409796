import React, { Component } from 'react';
import { isEqual } from 'lodash/lang';
import { get } from 'lodash/object';
import moment from 'moment';
import * as PropTypes from 'prop-types';

import { SitePatientEncounterApi } from '../../../../../../api';
import RoundIconButton from '../../../../../../common/buttons/RoundIconButton';
import SearchInput from '../../../../../../common/data-entry/Input/SearchInput/SearchInput';
import Select from '../../../../../../common/data-entry/Select';
import Button from '../../../../../../common/general/Button';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import { EDC_VISIT_DATE_SAVED, PATIENT_LOCKED } from '../../../../../../constants/notificationMessages';
import { withParams } from '../../../../../root/router/legacyComponentCompatability';
import { prepareWhoDidItListForView } from '../EditorAndDateSelect';
import { saveStudyManagerForEncounter } from '../encounterDetailsService';

import EdcVisitDatePicker from './EdcVisitDatePicker/EdcVisitDatePicker';
import { EncounterModificationMenu } from './EncounterModificationMenu';

import 'react-super-select/lib/react-super-select.css';
import './EncounterDetailsHeader.scss';

class EncounterDetailsHeader extends Component {
  static propTypes = {
    ancestorUpdateOnly: PropTypes.bool,
    isUnexpectedEncounter: PropTypes.bool,
    encounter: PropTypes.object,
    onElementSearch: PropTypes.func,
    encounterStudyManagers: PropTypes.array,
    currentEncounterStudyManagerUniqueIdentifier: PropTypes.string,
    currentEdcVisitDate: PropTypes.string,
    onAfterSaveEncounterStudyManager: PropTypes.func,
    reopenEncounter: PropTypes.func,
    currentPatient: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedEncounterStudyManager: null,
      selectedEdcVisitDate: null
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const edcVisitDate = this.props.currentEdcVisitDate;
    if (!this.state.selectedEdcVisitDate && edcVisitDate) {
      this.setState({ selectedEdcVisitDate: edcVisitDate });
      this.props.onAfterSaveEdcVisitDate(edcVisitDate);
    }
    if (prevProps.currentEdcVisitDate !== edcVisitDate) {
      this.setState({ selectedEdcVisitDate: edcVisitDate });
    }
    if (
      this.currentEncounterStudyManagerHasBeenChanged(prevProps) ||
      this.encounterStudyManagersHaveBeenChanged(prevProps)
    ) {
      let selectedEncounterStudyManager;
      const savedStudyManger = this.props.currentEncounterStudyManagerUniqueIdentifier;
      if (this.props.isUnexpectedEncounter && !savedStudyManger) {
        selectedEncounterStudyManager = this.findFirstActivePrimaryStudyManager();
        this.onEncounterSMChange(selectedEncounterStudyManager);
      } else {
        selectedEncounterStudyManager = this.getEncounterStudyManagerByUniqueIdentifier(savedStudyManger);
        this.setState({ selectedEncounterStudyManager });
      }
    }
  }

  findFirstActivePrimaryStudyManager() {
    return this.props.encounterStudyManagers.find(p => p.primaryStudyManagerForSsu === true && p.deleteFlag === 0);
  }

  encounterStudyManagersHaveBeenChanged(prevProps) {
    return prevProps.encounterStudyManagers !== this.props.encounterStudyManagers;
  }

  currentEncounterStudyManagerHasBeenChanged(prevProps) {
    return (
      prevProps.currentEncounterStudyManagerUniqueIdentifier !==
        this.props.currentEncounterStudyManagerUniqueIdentifier &&
      this.props.currentEncounterStudyManagerUniqueIdentifier !==
        get(this, 'state.selectedEncounterStudyManager.uniqueIdentifier')
    );
  }

  saveSmForUnexpectedEncounter = () => {
    if (this.props.ancestorUpdateOnly) {
      return;
    }
    const { ssuPatientId, patientEncounterId } = this.props.params;
    const studyManagerId = get(this.state, 'selectedEncounterStudyManager.uniqueIdentifier');
    saveStudyManagerForEncounter(ssuPatientId, patientEncounterId, studyManagerId)
      .then(({ data: uniqueIdentifier }) => this.props.onAfterSaveEncounterStudyManager(uniqueIdentifier))
      .catch(err => err);
  };

  saveEdcVisitDate = () => {
    const { ssuPatientId, patientEncounterId } = this.props.params;
    SitePatientEncounterApi.updateEdcVisitDate(ssuPatientId, patientEncounterId, {
      edcVisitDate: moment(this.state.selectedEdcVisitDate).format('YYYY-MM-DD')
    })
      .then(({ data }) => {
        this.props.onAfterSaveEdcVisitDate(data);
        NotificationManager.success(EDC_VISIT_DATE_SAVED);
      })
      .catch(() => {
        NotificationManager.error(PATIENT_LOCKED);
      });
  };

  onEncounterSMChange = encounterStudyManager => {
    if (isEqual(this.state.selectedEncounterStudyManager, encounterStudyManager)) {
      return;
    }
    const uniqueIdentifier = encounterStudyManager ? encounterStudyManager.uniqueIdentifier : undefined;
    this.setCurrentEncounterStudyManagerByUniqueIdentifier(uniqueIdentifier);
  };

  setCurrentEncounterStudyManagerByUniqueIdentifier(uniqueIdentifier) {
    if (this.props.ancestorUpdateOnly) {
      return this.props.onAfterSaveEncounterStudyManager(uniqueIdentifier);
    }
    this.setState({
      selectedEncounterStudyManager: this.getEncounterStudyManagerByUniqueIdentifier(uniqueIdentifier)
    });
  }

  getEncounterStudyManagerByUniqueIdentifier = uniqueIdentifier => {
    return this.props.encounterStudyManagers.find(sm => sm.uniqueIdentifier === uniqueIdentifier);
  };

  showSaveSMButton = () => {
    return (
      this.isEncounterSaved() &&
      this.props.currentEncounterStudyManagerUniqueIdentifier !==
        get(this, 'state.selectedEncounterStudyManager.uniqueIdentifier', null)
    );
  };

  showSaveEdcVisitDateButton = () => {
    const selectedEdcVisitDate = get(this, 'state.selectedEdcVisitDate', null);
    const isCreateUnexpected = this.props.isUnexpectedEncounter && !this.props.encounter;
    return (
      (!isCreateUnexpected && this.props.currentEdcVisitDate !== selectedEdcVisitDate) ||
      (!this.props.currentEdcVisitDate && selectedEdcVisitDate)
    );
  };

  isEncounterSaved = () => {
    return this.props.encounter;
  };

  render() {
    const { props, state } = this,
      { isUnexpectedEncounter, onElementSearch, encounterStudyManagers, isNonProtocolEncounter } = props,
      { selectedEncounterStudyManager } = state;
    const filteredEncounterStudyManagers = selectedEncounterStudyManager
      ? prepareWhoDidItListForView(encounterStudyManagers, selectedEncounterStudyManager.uniqueIdentifier)
      : encounterStudyManagers.filter(manager => manager.deleteFlag === 0);
    return (
      <div className="eds-encounter-details-header">
        <div>
          <h5 className="m-0 c-p">Encounter Details</h5>
          {this.props.isAbleToEditEncounter() && this.isEncounterSaved() && (
            <EncounterModificationMenu
              cancelEncounterAvailable={this.props.cancelEncounterAvailable}
              cancelEncounter={this.props.cancelEncounter}
              reopenEncounter={this.props.reopenEncounter}
              isUnexpectedEncounter={this.props.isUnexpectedEncounter}
              notPerformAllItemsAvailable={this.props.notPerformAllItemsAvailable}
              showCloseEncounterOption={this.props.showCloseEncounterOption}
              markItemGroupsNotPerformedAndNotAsked={this.props.markItemGroupsNotPerformedAndNotAsked}
            />
          )}
        </div>
        <div>
          <div className="d-flex flex-grow-1 pr-3">
            {!isNonProtocolEncounter && (
              <div className="edh-study-manager-select">
                <Select
                  disabled={!this.props.isAbleToEditEncounter()}
                  key="uniqueIdentifier"
                  label="Study Manager"
                  dataSource={filteredEncounterStudyManagers}
                  onChange={newSM => {
                    if (newSM) {
                      this.onEncounterSMChange(newSM);
                    }
                  }}
                  searchable
                  keepOpenOnSelection={false}
                  closeOnSelectedOptionClick
                  deselectOnSelectedOptionClick={false}
                  clearable={false}
                  optionValueKey="uniqueIdentifier"
                  value={selectedEncounterStudyManager}
                  required={true}
                />
                {this.showSaveSMButton() && (
                  <RoundIconButton onClick={this.saveSmForUnexpectedEncounter}>save</RoundIconButton>
                )}
              </div>
            )}
            <div className="edh-edc-visit-date-select">
              <EdcVisitDatePicker
                value={get(this, 'state.selectedEdcVisitDate')}
                onChange={date => {
                  this.setState({ selectedEdcVisitDate: date.format('YYYY-MM-DD') });
                  const isCreateUnexpected = this.props.isUnexpectedEncounter && !this.props.encounter;
                  isCreateUnexpected && this.props.onAfterSaveEdcVisitDate(date.format('YYYY-MM-DD'));
                }}
                isAllowedToManage={this.props.isAbleToEditEncounter}
              />
              {this.showSaveEdcVisitDateButton() && this.props.isAbleToEditEncounter() && (
                <RoundIconButton onClick={this.saveEdcVisitDate} className={'save-edc-visit-date'}>
                  save
                </RoundIconButton>
              )}
            </div>
            <SearchInput label={'Search'} onChange={onElementSearch} value={props.elementSearchTxt} />
          </div>
          {isUnexpectedEncounter && this.props.isAbleToEditEncounter() && !this.props.ancestorUpdateOnly && (
            <Button
              className="mt-2 ml-3"
              disabled={!this.props.isAllowedToAddOrSaveUnexpectedEncounter()}
              onClick={this.props.saveItemGroups}
            >
              {this.props.isEditMode ? 'Add' : 'Save'}
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default withParams(EncounterDetailsHeader);
