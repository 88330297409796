import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableViewIcon from '@mui/icons-material/TableView';

const iconMap = {
  'image/jpeg': ImageIcon,
  'image/jpg': ImageIcon,
  'image/png': ImageIcon,
  'application/pdf': PictureAsPdfIcon,
  'text/csv': TableViewIcon
};

export default function EuiFileUploadListIcon({ file }) {
  const Icon = iconResolver(file);
  return (
    <div className="eui-file-upload-list-file-icon">
      <Icon file={file} />
    </div>
  );
}

function iconResolver({ fileType }) {
  if (iconMap[fileType]) {
    return iconMap[fileType];
  }
  return InsertDriveFileIcon;
}
