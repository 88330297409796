import { DASHBOARDS } from '../../../constants/accessToPages';

import ModeReports from './ModeReports';

export const modeReportsDashboardsRouterConfig = {
  name: 'Dashboards',
  path: '/dashboards/:reportKey?',
  component: ModeReports,
  access: [DASHBOARDS]
};
