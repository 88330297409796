import React, { useCallback, useMemo } from 'react';
import { sortBy } from 'lodash/collection';
import { isEmpty, isEqual, isNull } from 'lodash/lang';
import { has } from 'lodash/object';
import PropTypes from 'prop-types';

import Select from '../../../../../../../../common/data-entry/Select';
import { useTableItemGroupActions, useTableItemGroupState } from '../../TableItemGroupContext/TableItemGroupContext';
import fieldPropTypes from '../fieldPropTypes';

export default function SelectField({ field }) {
  const { fieldValue, codeDefinition, fieldUid } = field;
  const { isPreviewOnly } = useTableItemGroupState();
  const { fieldOnChange } = useTableItemGroupActions();

  const selectedOption = useMemo(
    function() {
      return (
        codeDefinition.find(function({ decode }) {
          return decode === fieldValue;
        }) || null
      );
    },
    [fieldValue, codeDefinition]
  );

  const onChange = useCallback(
    function(option) {
      if ((!isNull(option) && !has(option, 'decode')) || isEqual(option, selectedOption)) {
        return;
      }

      fieldOnChange(option?.decode || null, fieldUid);
    },
    [selectedOption, fieldOnChange, fieldUid]
  );

  return (
    <SelectFieldReadonly field={field} onChange={onChange} onClear={() => onChange(null)} disabled={isPreviewOnly} />
  );
}

export function SelectFieldReadonly({ field, ...rest }) {
  const { fieldValue, codeDefinition } = field;

  const selectedOption = useMemo(
    function() {
      return (
        codeDefinition.find(function({ decode }) {
          return decode === fieldValue;
        }) || null
      );
    },
    [fieldValue, codeDefinition]
  );

  return (
    <Select
      optionValueKey="uniqueIdentifier"
      placeholder="Select"
      clearSearchOnSelection
      dataSource={sortBy(codeDefinition, 'sequence')}
      searchable
      clearable
      customOptionTemplateFunction={customOptionTemplateFunction}
      value={selectedOption}
      className="eds-dropdown-template select-field"
      legacyLook
      {...rest}
    />
  );
}

function customOptionTemplateFunction({ name }) {
  return <span>{name}</span>;
}

const propTypes = {
  field: PropTypes.shape({
    ...fieldPropTypes,
    fieldType: PropTypes.oneOf(['dropdown', 'dropdownCT']).isRequired,
    fieldValue: function(props, propName, componentName) {
      const value = props[propName];
      if (!isNull(value) && isEmpty(value)) {
        return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed.`);
      }
    }
  })
};

SelectFieldReadonly.defaultProps = {
  disabled: true
};

SelectFieldReadonly.propTypes = propTypes;
SelectField.propTypes = propTypes;
