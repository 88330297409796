import moment from 'moment';
import * as yup from 'yup';
import { array, string } from 'yup';

import {
  travelServiceTypes,
  travelStatuses,
  travelTimeList
} from '../../EncountersSection/TravelSection/travelConstants';

const addressSchema = yup.object({
  type: yup.string().required('Address type is required'),
  countryId: yup.string().required('Country is required'),
  regionId: yup.string().required(' is required'),
  city: yup.string().required('City is required'),
  address1: yup.string().required('Address 1 is required'),
  address2: yup.string().notRequired(),
  zipCode: yup.string().required('Zip Code is required')
});

export const validationSchema = yup.object({
  status: yup
    .string()
    .oneOf(travelStatuses.map(({ id }) => id))
    .required('Field is required'),
  visitDate: yup
    .date()
    .typeError('Invalid Date')
    .min(moment().startOf('day'), "Visit Date can't be in the past")
    .required('Field is required'),
  visitStartTime: yup
    .date()
    .typeError('Invalid Time')
    .required('Field is required'),
  visitEndTime: yup
    .date()
    .typeError('Invalid Time')
    .notRequired()
    .when('visitStartTime', {
      is(value) {
        return !!value;
      },
      then: schema => {
        return schema.min(yup.ref('visitStartTime'), "End Time can't be before Start Time");
      },
      otherwise: schema => {
        return schema;
      }
    }),
  requestedServices: array()
    .of(string().oneOf(travelServiceTypes.map(({ id }) => id)))
    .min(1, 'Select at least one service'),

  departureDate: yup
    .date()
    .typeError('Invalid Date')
    .notRequired(),
  returnDate: yup
    .date()
    .typeError('Invalid Date')
    .notRequired()
    .when('departureDate', {
      is(value) {
        return !!value;
      },
      then: schema => {
        return schema.min(yup.ref('departureDate'), "Return Date can't be before Departure Date");
      },
      otherwise: schema => {
        return schema;
      }
    }),
  preferredDepartureAirport: yup.string().notRequired(),
  preferredTravelTime: array()
    .of(string().oneOf(travelTimeList.map(({ id }) => id)))
    .notRequired(),

  pickupAddress: addressSchema,
  pickupAddressComments: yup.string('Enter Comments').notRequired(),

  dropOffAddress: addressSchema,
  dropOffAddressComments: yup.string('Enter Comments').notRequired(),

  comments: yup.string('Enter Comments').notRequired()
});
