import axios from 'axios';

const ROOT = 'api/v1/finance/site-bills';

const SiteBillApi = {
  getSiteBills(filters) {
    return axios.post(`${ROOT}/events`, filters);
  },

  createSiteBill(billEventsIds) {
    return axios.post(ROOT, { billEventsIds });
  },

  exportSiteBill(filterProperty) {
    return axios.post(`${ROOT}/download`, filterProperty, {
      responseType: 'arraybuffer'
    });
  }
};

export default SiteBillApi;
