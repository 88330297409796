import React, { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash/lang';
import moment from 'moment';
import { read, utils } from 'xlsx';

import { StudySiteApi } from '../../../../api';
import PatientUpdateApi from '../../../../api/patient/PatientUpdateApi';
import DatePicker from '../../../../common/data-entry/DatePicker';
import Input from '../../../../common/data-entry/Input';
import Select from '../../../../common/data-entry/Select';
import Button from '../../../../common/general/Button';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../constants/dateFormat';
import { CLOSED, TREATMENT } from '../../../../constants/ssuStatuses';
import { doesStringContainNullByte } from '../../../../services/string';
import { PageInfoHeader } from '../../../PageInfoHeader/PageInfoHeader';
import { SSUFilter } from '../../../SSUFilter/SSUFilter';
import { SSUPCNFilter } from '../../../SSUFilter/SSUPCNFilter';

import PatientBulkUpdateResult from './PatientBulkUpdateResult';

import './PatientBulkUpdate.scss';

const DESIRED_MAPPING = Object.freeze({
  PatientID: 'patientId'
});
const TAB_STATUS_UPDATE = 'Status';
const TAB_PROGRESS_NOTE_UPDATE = 'Progress Note';
const STATUSES_WITHOUT_REASON = ['IDENTIFIED', 'PRE-SCREENED'];
const ALLOWED_FORMATS = [
  '.xls',
  'application/vnd.ms-excel',
  '.xlsx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.csv',
  'text/csv'
];

export const PatientBulkUpdate = () => {
  const [ssu, setSsu] = useState(null);
  const hasFileBeenRead = useRef(false);
  const [file, setFile] = useState(null);
  const [patientIds, setPatientIds] = useState(null);
  const [statuses, setStatuses] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [reasons, setReasons] = useState(null);
  const [selectedReason, setSelectedReason] = useState(null);
  const [comment, setComment] = useState(null);
  const [progressNote, setProgressNote] = useState(null);
  const [changeDate, setChangeDate] = useState(moment());
  const [fileData, setFileData] = useState(null);
  const [response, setResponse] = useState(null);
  const [errors, setErrors] = useState([]);
  const [activeTab, setActiveTab] = useState(TAB_STATUS_UPDATE);
  const [uploadDisabled, setUploadDisabled] = useState(true);

  const readFile = (file, onRead) => {
    const reader = new FileReader();
    reader.onload = upload => {
      const data = upload.target.result?.split(',')[1];
      const workbook = read(data);
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      const rawJson = utils.sheet_to_json(firstSheet, { blankrows: true, headers: 1, raw: false });
      onRead(data, rawJson);
    };
    reader.readAsDataURL(file);
  };

  const cleanHeaders = useCallback(json => {
    const normalizeKey = key => key.toLowerCase().replace(/_/g, ' ');
    const expectedKeys = Object.values(DESIRED_MAPPING).map(key => key.toLowerCase());

    const transformKeys = obj =>
      Object.entries(obj).reduce((acc, [key, value]) => {
        if (key.startsWith('__EMPTY')) {
          throw new Error('Missing header row');
        }

        const normalizedKey = normalizeKey(key);
        acc[DESIRED_MAPPING[normalizedKey] || key] = value;
        return acc;
      }, {});
    const cleaned = json.map(transformKeys);
    if (!cleaned.some(row => Object.keys(row).some(key => expectedKeys.includes(key.toLowerCase())))) {
      throw new Error('Invalid header row');
    }
    return cleaned;
  }, []);

  const checkIfFileEmpty = useCallback(data => {
    return isEmpty(data);
  }, []);

  const addError = useCallback(
    errorType => {
      setErrors([...errors, errorType]);
    },
    [errors]
  );

  useEffect(() => {
    setSelectedStatus(null);
    if (ssu && ssu?.studyIdentifier) {
      PatientUpdateApi.getStatusesForBulkUpdate(ssu.studyIdentifier).then(({ data }) => {
        setStatuses(data);
      });
    }
  }, [ssu]);

  useEffect(() => {
    const shouldDisable = isEmpty(ssu) || !(file instanceof File) || !isEmpty(errors);
    setUploadDisabled(shouldDisable);
  }, [ssu, file, errors]);

  useEffect(() => {
    if (selectedStatus) {
      setReasons(selectedStatus.reasons);
    }
    if (isEmpty(selectedStatus?.reasons)) {
      setErrors(errors.filter(er => er !== 'Comment' && er !== 'Reason'));
      setComment(null);
    }
    setSelectedReason(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatus]);

  useEffect(() => {
    if (file instanceof File && !hasFileBeenRead.current && ALLOWED_FORMATS.includes(file?.type)) {
      readFile(file, (data, rawJson) => {
        setFileData(data);
        if (checkIfFileEmpty(rawJson)) {
          NotificationManager.error(
            'The file could not be read; ensure there is a header row present and that the file is not empty, and try again'
          );
          addError('File');
          return;
        }

        let cleaned;
        try {
          cleaned = cleanHeaders(rawJson);
        } catch {
          NotificationManager.error('The file is missing a header row. Please include headers and try again.');
          addError('File');
          return;
        }

        if (cleaned) {
          const trimmedData = cleaned.reduce((acc, row) => {
            if (!Object.keys(row).every(key => row[key] === '')) {
              for (const [key, value] of Object.entries(row)) {
                if (key.toLowerCase().trim() !== 'patientid') {
                  continue;
                }
                acc.push(value.trim());
              }
            }
            return acc;
          }, []);
          setPatientIds(trimmedData);
        }
      });
      hasFileBeenRead.current = true;
    }
  }, [file, cleanHeaders, checkIfFileEmpty, addError]);

  const loadSsuNotInTreatmentStatus = () => {
    return StudySiteApi.getAllStudySitesAndMap().then(({ data: ssus }) => {
      return ssus.filter(({ siteStatus }) => ![TREATMENT, CLOSED].includes(siteStatus));
    });
  };

  const selectSsu = ssus => {
    const selectedSSU = ssus.length === 1 ? ssus[0] : null;
    setSsu(selectedSSU);
  };

  const resetFileState = () => {
    setFile(null);
    setErrors(errors.filter(e => e !== 'File'));
    setFileData(null);
    setPatientIds(null);
  };

  const onChangeFileHandler = evt => {
    const file = evt.target.files[0];
    if (!file) {
      resetFileState();
      return;
    }

    if (!ALLOWED_FORMATS.includes(file?.type)) {
      NotificationManager.error('Wrong file format. Allowed formats are csv and Excel formats.');
      addError('File');
    }

    if (doesStringContainNullByte(file?.name)) {
      NotificationManager.error('The file name format is unacceptable. Please rename the file and re-upload');
      resetFileState();
      return;
    }
    hasFileBeenRead.current = false;
    setFile(file);
  };

  const validDate = function(current) {
    return current.isBefore(moment());
  };

  const validateInputs = () => {
    if (activeTab === TAB_STATUS_UPDATE) {
      let validationErrors = [];
      isEmpty(ssu) || (isEmpty(ssu?.uniqueIdentifier) && validationErrors.push('Study Site'));
      isEmpty(selectedStatus) && validationErrors.push('Status');
      isEmpty(selectedReason) &&
        !STATUSES_WITHOUT_REASON.includes(selectedStatus?.id) &&
        validationErrors.push('Reason');
      isEmpty(comment?.trim()) &&
        !STATUSES_WITHOUT_REASON.includes(selectedStatus?.id) &&
        validationErrors.push('Comment');
      isEmpty(changeDate) && validationErrors.push('Date');
      setErrors(validationErrors);
      return isEmpty(validationErrors);
    } else {
      let validationErrors = [];
      isEmpty(ssu) || (isEmpty(ssu?.uniqueIdentifier) && validationErrors.push('Study Site'));
      isEmpty(progressNote?.trim()) && validationErrors.push('Progress Note');
      isEmpty(changeDate) && validationErrors.push('Date');
      setErrors(validationErrors);
      return isEmpty(validationErrors);
    }
  };

  const buildBulkStatusUpdateRequest = () => {
    if (STATUSES_WITHOUT_REASON.includes(selectedStatus?.id)) {
      return {
        ssuId: ssu.uniqueIdentifier,
        status: selectedStatus.id,
        comment: comment?.trim(),
        changeDate: changeDate.toISOString(),
        note: !isEmpty(progressNote?.trim()) ? progressNote?.trim() : null,
        patientIds,
        file: {
          type: file.type,
          fileUploadName: file.name,
          fileData: fileData
        }
      };
    } else {
      return {
        ssuId: ssu.uniqueIdentifier,
        status: selectedStatus.id,
        comment: comment?.trim(),
        changeDate: changeDate.toISOString(),
        reasonId: selectedReason.id,
        note: !isEmpty(progressNote?.trim()) ? progressNote?.trim() : null,
        patientIds,
        file: {
          type: file.type,
          fileUploadName: file.name,
          fileData: fileData
        }
      };
    }
  };

  const buildBulkProgressNoteRequest = () => {
    return {
      ssuId: ssu.uniqueIdentifier,
      noteCreatedDate: changeDate.toISOString(),
      note: progressNote.trim(),
      patientIds,
      file: {
        type: file.type,
        fileUploadName: file.name,
        fileData: fileData
      }
    };
  };

  const processSubmit = () => {
    const isValid = validateInputs();
    if (isValid) {
      if (activeTab === TAB_STATUS_UPDATE) {
        PatientUpdateApi.bulkStatusUpdate(buildBulkStatusUpdateRequest()).then(({ data }) => setResponse(data));
      } else {
        PatientUpdateApi.bulkAddProgressNote(buildBulkProgressNoteRequest()).then(({ data }) => setResponse(data));
      }
    }
  };

  return (
    <div>
      <PageInfoHeader>
        <div className="patient-bulk-update-section-header-tabs">
          <div className="patient-bulk-update-section-header-tabs-group">
            {[TAB_STATUS_UPDATE, TAB_PROGRESS_NOTE_UPDATE].map(function(tab) {
              return (
                <button
                  key={tab}
                  className={cx({ 'active-tab': activeTab === tab })}
                  onClick={() => {
                    setActiveTab(tab);
                    setResponse(null);
                    setErrors([]);
                  }}
                >
                  {tab}
                </button>
              );
            })}
          </div>
        </div>
        <>
          <div
            className={cx(
              'general-header-group-container',
              activeTab === TAB_STATUS_UPDATE ? 'more-than-five-fields-wrapper' : 'general-header-wrapper'
            )}
          >
            <SSUFilter handleSSUFilterChange={selectSsu} ssuProvider={loadSsuNotInTreatmentStatus}>
              <SSUPCNFilter isRequired={true} />
            </SSUFilter>
            {activeTab === TAB_STATUS_UPDATE && (
              <Select
                label="Status"
                optionLabelKey="name"
                optionValueKey="id"
                searchable
                required={true}
                dataSource={statuses}
                value={selectedStatus}
                onChange={status => {
                  setSelectedStatus(status);
                  setErrors(errors.filter(er => er !== 'Status'));
                }}
                validationMessage={errors.includes('Status') ? 'Status is not selected' : ''}
              />
            )}
            <DatePicker
              onChange={date => {
                setChangeDate(date);
                setErrors(errors.filter(er => er !== 'Date'));
              }}
              isValidDate={validDate}
              value={changeDate}
              label={'Date'}
              timeFormat={false}
              required
              closeOnSelect
              dateFormat={DD_SLASH_MMM_SLASH_YYYY}
              validationMessage={errors.includes('Date') ? 'Date is not selected' : ''}
            />
            {activeTab === TAB_STATUS_UPDATE && (
              <Select
                label="Reason"
                optionLabelKey="name"
                optionValueKey="id"
                searchable
                disabled={STATUSES_WITHOUT_REASON.includes(selectedStatus?.id)}
                required={true}
                dataSource={reasons}
                value={selectedReason}
                onChange={reason => {
                  setSelectedReason(reason);
                  setErrors(errors.filter(er => er !== 'Reason'));
                }}
                validationMessage={errors.includes('Reason') ? 'Reason is not selected' : ''}
              />
            )}
            {activeTab === TAB_STATUS_UPDATE && (
              <Input
                id={'comment-input'}
                name={'comment'}
                label={'Comment'}
                disabled={STATUSES_WITHOUT_REASON.includes(selectedStatus?.id)}
                required
                value={comment}
                onChange={e => {
                  setComment(e.target.value);
                  setErrors(errors.filter(er => er !== 'Comment'));
                }}
                validationMessage={errors.includes('Comment') ? 'Comment is required' : ''}
              />
            )}
            <Input
              id={'progress-note-input'}
              name={'progress-note'}
              label={'Progress Note'}
              required={activeTab === TAB_PROGRESS_NOTE_UPDATE}
              value={progressNote}
              validationMessage={errors.includes('Progress Note') ? 'Progress Note is required' : ''}
              onChange={e => {
                setProgressNote(e.target.value);
                setErrors(errors.filter(er => er !== 'Progress Note'));
              }}
            />
            <Input.File
              name="patientsFileUpload"
              fileName={file?.name || ''}
              onChangeFileHandler={onChangeFileHandler}
              acceptedUploadTypes={[
                '.xls',
                'application/vnd.ms-excel',
                '.xlsx',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                '.csv',
                'text/csv'
              ].join()}
              required
              validate
              validationMessage={errors.includes('File') ? 'Invalid file' : ''}
            />
            <Button disabled={uploadDisabled} onClick={() => processSubmit()} size="h56">
              Upload File
            </Button>
          </div>
        </>
      </PageInfoHeader>
      {response && <PatientBulkUpdateResult response={response} />}
    </div>
  );
};
