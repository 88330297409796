import React, { useCallback, useContext, useEffect, useState } from 'react';
import { isEmpty } from 'lodash/lang';

import { scPurple20 } from '../../../../../../../constants/systemColors';
import { CellFormattedDate } from '../../../../../../CellFormattedDate/CellFormattedDate';
import { Amount } from '../../../../shared/amount-view/Amount/Amount';
import { AmountWithType } from '../../../../shared/amount-view/AmountWithType/AmountWithType';
import { FinanceTable } from '../../../../shared/FinanceTable/FinanceTable';
import { ALL, PATIENT_REIMBURSEMENT, PATIENT_STIPEND } from '../../AdjustmentConstants';
import { AdjustmentContext } from '../../AdjustmentContext';

import './LedgerEventsTable.scss';

export function LedgerEventsTable() {
  const { tableEvents, isPatientStipend, newTableEvents } = useContext(AdjustmentContext);
  const [tableRef, setTableRef] = useState(null);
  const scrollablePart = tableRef?.current.children[0].children[0].children[1];
  const scrollHeight = scrollablePart?.scrollHeight;

  const scrollToBottom = useCallback(
    function() {
      scrollablePart.scrollTop = scrollHeight;
    },
    [scrollablePart, scrollHeight]
  );

  useEffect(
    function() {
      if (!isEmpty(newTableEvents)) {
        scrollToBottom();
      }
    },
    [newTableEvents, scrollToBottom]
  );

  const columns = getColumns(isPatientStipend);
  const data = [...tableEvents, ...newTableEvents];
  return (
    <div className="ledger-events-table">
      <span className="let-header">Ledger events</span>
      <FinanceTable
        setRef={setTableRef}
        scrollInAnyPart
        data={data}
        columns={columns}
        minRows={1}
        multiSort
        className="ledger-events"
        showPagination={false}
        defaultPageSize={100}
        resizable={false}
        sortable={false}
        getTrProps={getTrProps}
      />
    </div>
  );
  function getTrProps(state, rowInfo) {
    if (rowInfo && rowInfo.original?.preview) {
      return {
        className: 'let-preview-event',
        style: {
          background: scPurple20
        }
      };
    }
    return {
      className: 'let-main-event'
    };
  }
}

function getColumns(isPatientStipend) {
  const columns = [
    {
      Header: 'Event ID',
      accessor: 'id',
      maxWidth: 125,
      className: 'let-event-id',
      headerClassName: 'let-event-id',
      style: { marginTop: 'auto', marginBottom: 'auto' },
      type: ALL,
      Cell({ value }) {
        const adjustmentVersionId = value.adjustmentVersionId;
        const newVar = adjustmentVersionId ? `${value.eventNumber}.${adjustmentVersionId}` : value.eventNumber;
        return <span>{newVar}</span>;
      }
    },
    {
      Header: 'Client',
      headerClassName: 'ft-end-leveling',
      className: 'ft-end-leveling',
      accessor: 'clientAmount',
      type: ALL,
      maxWidth: 100,
      Cell({ value }) {
        return <Amount highlightFractionalValue coinsAmount={value} defaultValue="--" showDollarSign />;
      }
    },
    {
      Header: 'Patient',
      accessor: 'patientAmount',
      headerClassName: 'ft-end-leveling',
      className: 'ft-end-leveling',
      maxWidth: 150,
      type: PATIENT_STIPEND,
      Cell({ value }) {
        return <AmountWithType coinsAmount={value?.amount} amountType={value?.type} defaultValue="--" showDollarSign />;
      }
    },
    {
      Header: 'Site',
      accessor: 'siteAmount',
      headerClassName: 'ft-end-leveling',
      className: 'ft-end-leveling',
      maxWidth: 150,
      Cell({ value }) {
        return <AmountWithType coinsAmount={value?.amount} amountType={value?.type} defaultValue="--" showDollarSign />;
      }
    },
    {
      Header: 'Vendor Name',
      accessor: 'vendorAmount',
      headerClassName: 'ft-center-leveling',
      className: 'ft-center-leveling',
      maxWidth: 500,
      Cell({ value }) {
        return <span className="ft-long-text">{value?.vendorName || '--'}</span>;
      }
    },
    {
      Header: 'Vendor',
      accessor: 'vendorAmount',
      headerClassName: 'ft-end-leveling',
      className: 'ft-end-leveling',
      maxWidth: 150,
      Cell({ value }) {
        return <AmountWithType coinsAmount={value?.amount} amountType={value?.type} defaultValue="--" showDollarSign />;
      }
    },
    {
      Header: 'Gross',
      accessor: 'gross',
      headerClassName: 'ft-end-leveling',
      className: 'ft-end-leveling',
      type: ALL,
      maxWidth: 100,
      Cell({ value }) {
        return <Amount highlightFractionalValue coinsAmount={value} showDollarSign />;
      }
    },
    {
      Header: 'Net',
      accessor: 'netRevenueAmount',
      headerClassName: 'ft-end-leveling',
      className: 'ft-end-leveling',
      type: ALL,
      maxWidth: 100,
      Cell({ value }) {
        return <Amount highlightFractionalValue coinsAmount={value} showDollarSign />;
      }
    },
    {
      Header: 'Revenue Date',
      accessor: 'revenueDate',
      headerClassName: 'ft-center-leveling',
      className: 'ft-center-leveling',
      type: ALL,
      maxWidth: 150,
      Cell: ({ value }) => <CellFormattedDate date={value} />
    },
    {
      Header: 'Reason',
      accessor: 'reason',
      type: ALL,
      className: 'display-flex',
      maxWidth: 400,
      Cell({ value }) {
        return (
          <span className="ft-long-text" title={value}>
            {value || '--'}
          </span>
        );
      }
    }
  ];
  function getCorrectFilterPredicateDependOnPatientStipend(columnParams) {
    const { type } = columnParams;
    if (isPatientStipend) {
      return type === PATIENT_STIPEND || type === PATIENT_REIMBURSEMENT || type === ALL;
    } else {
      return type !== PATIENT_STIPEND && type !== PATIENT_REIMBURSEMENT;
    }
  }

  return columns.filter(getCorrectFilterPredicateDependOnPatientStipend);
}
