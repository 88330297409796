import React, { useContext } from 'react';
import { findIndex } from 'lodash/array';
import { isEmpty, isNull } from 'lodash/lang';
import PropTypes from 'prop-types';

import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { FileViewerContext } from '../../../../../../../../contexts/FileViewerContext';
import { getFileTitle, openFile, resolveFileIcon } from '../../../../../../../../services/fileService';
import { useCurrentRoute } from '../../../../../../../root/router';
import MultipleFileTemplate from '../../../../../../setup/shared/GenerateHtml/MultipleFileTemplate';
import { useTableItemGroupActions, useTableItemGroupState } from '../../TableItemGroupContext/TableItemGroupContext';
import fieldPropTypes from '../fieldPropTypes';

import './FileUploadField.scss';
/** @deprecated **/
export default function FileUploadField({ field }) {
  const { fieldValue, fieldUid, fileList, filesResponse, fieldType } = field;
  const { isPreviewOnly, isCertifiedCopyWasNotConfirmed } = useTableItemGroupState();
  const { fileListOnChange, setIsCertifiedCopyWasNotConfirmed } = useTableItemGroupActions();
  const route = useCurrentRoute();

  function onChangeFileHandler(files) {
    const updateFileList = [];
    const fileReaders = [];
    files.forEach(file => {
      const reader = new FileReader();
      fileReaders.push(reader);
      reader.onload = function(e) {
        const data = e.target.result && e.target.result.split(',');
        updateFileList.push({
          content: data[1],
          type: file && file.originalFile.type,
          name: file && file.originalFile.name,
          isCertifyCopy: file.isCertifyCopy,
          isCertifyCopyConfirmed: !!(file.isCertifyCopy && file.isCertifyCopyConfirmed)
        });
        if (updateFileList.length === fileReaders.length) {
          if (fieldType === 'singleFile') {
            const value = `${updateFileList.map(file => file.name).join('; ')}`;
            fileListOnChange(updateFileList, value, null, fieldUid);
          } else {
            const value = !isEmpty(filesResponse)
              ? `${updateFileList.map(file => file.name).join('; ')}; ${filesResponse
                  .map(file => file.fileName)
                  .join('; ')}`
              : `${updateFileList.map(file => file.name).join('; ')}`;

            fileListOnChange(updateFileList, value, filesResponse, fieldUid);
          }
          setIsCertifiedCopyWasNotConfirmed([]);
        }
      };
      reader.readAsDataURL(file.originalFile);
    });
  }

  function deleteFile(a, b, uniqueIdentifier) {
    let updatedFilesResponse = filesResponse.filter(file => file.uniqueIdentifier !== uniqueIdentifier);
    let updatedFieldValue = deleteNameFromFieldValue(
      fieldValue,
      filesResponse[findIndex(filesResponse, ['uniqueIdentifier', uniqueIdentifier])].fileName
    );
    if (!updatedFieldValue) {
      updatedFieldValue = null;
    }
    if (isEmpty(updatedFilesResponse)) {
      updatedFilesResponse = null;
    }
    ModalBoxes.confirm({
      content: 'Are you sure you want to delete this file?',
      title: 'Confirmation',
      confirmButton: 'Yes',
      cancelButton: 'No'
    })
      .then(() => {
        fileListOnChange(fileList, updatedFieldValue, updatedFilesResponse, fieldUid);
      })
      .catch(() => {});
  }

  function onCloseSelectedFile(templateData, fileNameForDelete) {
    let updatedFileList = fileList.filter(file => file.name !== fileNameForDelete);
    let value = deleteNameFromFieldValue(fieldValue, fileNameForDelete);
    if (isEmpty(updatedFileList)) {
      updatedFileList = null;
    }
    if (!value) {
      value = null;
    }
    fileListOnChange(updatedFileList, value, filesResponse, fieldUid);
  }

  function deleteNameFromFieldValue(fieldValueForUpdate, fileNameForDelete) {
    return fieldValueForUpdate
      .replace(new RegExp(`${fileNameForDelete};? ?`), '')
      .replace(/; $/, '')
      .replace(/^; /, '');
  }

  const filesResponseWithSsuId = field.filesResponse
    ? field.filesResponse.map(file => ({
        ...file,
        sitePatientIdentifier: route.params.ssuPatientId
      }))
    : null;

  const templateData = { ...field, fileList: filesResponseWithSsuId };

  return (
    <div className="file-upload-field">
      <MultipleFileTemplate
        formGroupIdentifier={field.filesResponse ? field.filesResponse[0]?.formGroupIdentifier : null}
        deleteFile={deleteFile}
        templateData={templateData}
        onChangeFileHandler={onChangeFileHandler}
        isAbleToEditFunction={() => !isPreviewOnly}
        onCloseSelectedFile={onCloseSelectedFile}
        isSomeCertifyCopyWasNotConfirmedAfterSaving={isCertifiedCopyWasNotConfirmed.includes(fieldUid)}
        multiple={fieldType !== 'singleFile'}
      />
    </div>
  );
}

export function FileUploadFieldReadonly({ field }) {
  const { filesResponse } = field;
  const fileViewer = useContext(FileViewerContext);
  const {
    params: { ssuPatientId }
  } = useCurrentRoute();

  if (isEmpty(filesResponse)) {
    return null;
  }

  return filesResponse.map(file => {
    return (
      <button
        key={file.uniqueIdentifier}
        className="file-field-view"
        title={getFileTitle(file)}
        onClick={() =>
          openFile(fileViewer, { uniqueIdentifier: file.uniqueIdentifier, sitePatientIdentifier: ssuPatientId })
        }
      >
        {resolveFileIcon(file)}
        {file.fileName}
      </button>
    );
  });
}

const propTypes = {
  field: PropTypes.shape({
    ...fieldPropTypes,
    fieldType: PropTypes.oneOf(['file', 'singleFile']).isRequired,
    fieldValue: function(props, propName, componentName) {
      const value = props[propName];
      if (!isNull(value) && isEmpty(value)) {
        return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed.`);
      }
    },
    fileList: function(props, propName, componentName) {
      const value = props[propName];
      if (!isNull(value) && isEmpty(value)) {
        return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed.`);
      }
    },
    filesResponse: function(props, propName, componentName) {
      const value = props[propName];
      if (!isNull(value) && isEmpty(value)) {
        return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed.`);
      }
    }
  })
};

FileUploadFieldReadonly.defaultProps = {
  isAbleToEditFunction() {
    return false;
  }
};

FileUploadFieldReadonly.propTypes = propTypes;
FileUploadField.propTypes = propTypes;
