import React, { useContext, useMemo } from 'react';

import { MissingBudgetItemsApi } from '../../../../../../../../../api';
import DatePicker from '../../../../../../../../../common/data-entry/DatePicker';
import Button from '../../../../../../../../../common/general/Button';
import Icon from '../../../../../../../../../common/general/Icon';
import { AddLedgerContext } from '../../../../AddLedgerContext';

import './MissedEventsCalendars.scss';

export function MissedEventsCalendars() {
  const { finBudgetDetails, finBudgetRowId, range, setRange, setMissingBudgetItem } = useContext(AddLedgerContext);
  const isSearchCanBeApply = useMemo(
    function() {
      return !!range?.endDate && !!range.startDate;
    },
    [range]
  );
  return (
    <div className="missed-events-calendars">
      <DatePicker
        label="Start Date"
        timeFormat={false}
        closeOnSelect
        className="mec-calendar"
        iconsAfter={<Icon>insert_invitation</Icon>}
        onChange={startDate => {
          setRange(({ endDate }) => ({ endDate, startDate }));
        }}
        readOnly={true}
        dateFormat="DD/MMM/YYYY"
        value={range.startDate}
      />
      <DatePicker
        label="End Date"
        timeFormat={false}
        value={range.endDate}
        closeOnSelect
        iconsAfter={<Icon>insert_invitation</Icon>}
        className="mec-calendar"
        onChange={endDate => {
          setRange(({ startDate }) => ({ endDate, startDate }));
        }}
        readOnly={true}
        dateFormat="DD/MMM/YYYY"
      />
      <Button className="mec-search-button" size="h56" disabled={!isSearchCanBeApply} onClick={findMissingBudgetItems}>
        Search
      </Button>
    </div>
  );

  function findMissingBudgetItems() {
    MissingBudgetItemsApi.findMissingLedgerEvents(
      finBudgetDetails.budgetSite?.id,
      finBudgetRowId,
      range
    ).then(({ data }) => setMissingBudgetItem(data));
  }
}
